import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererBox',
    extends: RendererOptions,
    computed: {
        renderer () {
            const values = this.values
            if (!Object.keys(values).length) {
                return ''
            }

            return values['box'].code
        }
    }
}
