import {
    STATE_CURRENT,
    STATE_READY,
    STATE_WAIT
} from '../../../../app/utils/constants'
import {
    OBJECTS_CHAIN
} from '../constants/businessChain'

import axios from 'axios'
import Vue from 'vue'
import _find from 'lodash/find'
import _first from 'lodash/first'
import _filter from 'lodash/filter'
import _isEqual from 'lodash/isEqual'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _cloneDeep from 'lodash/cloneDeep'
import {
    COMPONENT_ID_COMPANY_DETAILS,
    COMPONENT_ID_PRIMARY_CONTACT,
    COMPONENT_ID_PLAN_OPTIONS,
    getObjectList
} from '../constants/businessObjectList'
import * as abstract from '../../../../storage/abstract'
import {minLength, required} from 'vuelidate/lib/validators'

export const EVENT_ADD_CONTACTS_TO_EYE = 'contacts:add-to-eye'
export const EVENT_CONTACT_LENSES_FOUND = 'contacts:lenses-found'

export const PLAN_COLORS = {
    "exam": {
        color: '#9cdbd9'
    }
    ,
    "bronze": {
        color: '#C24830'
    }
    ,
    "silver": {
        color: '#9B9B9D'
    }
    ,
    "gold": {
        color: '#BA902F'
    }
}

export const DEFAULT_BILLING_ADDRESS_FIELDS = {
    city: '',
    country: 'United States',
    postcode: '',
    region: '',
    street: ['', ''],
    company: ''
}

export const ADDRESS_TEMPLATE_DATA = [
    {
        name: 'first_name',
        label: 'First Name',
        required: true,
        type: 'text'
    },
    {
        name: 'last_name',
        label: 'Last Name',
        required: true,
        type: 'text'
    },
    {
        name: 'street',
        label: '',
        type: 'text',
        required: true,
        options: [
            {
                label: 'Address Line 1',
                type: 'text',
                required: true,
                name: 'address1'
            },
            {
                label: 'Address Line 2',
                type: 'text',
                required: false,
                name: 'address2'
            }
        ]
    },
    {
        name: 'country',
        label: 'Country',
        required: true,
        type: 'text',
        disabled: true
    },
    {
        name: 'city',
        label: 'City',
        required: true,
        type: 'text'
    },
    {
        name: 'state',
        label: 'State',
        required: true,
        type: 'select',
        options: []
    },
    {
        name: 'postal_code',
        label: 'Zip/Postal Code',
        required: true,
        type: 'text'
    }
]

export const COMPANY_TEMPLATE_DATA = [
    {
        name: 'company',
        label: 'Company Name',
        required: true,
        type: 'text'
    },
    {
        name: 'street',
        label: '',
        type: 'text',
        required: true,
        options: [
            {
                label: 'Address Line 1',
                type: 'text',
                required: true
            },
            {
                label: 'Address Line 2',
                type: 'text',
                required: false
            }
        ]
    },
    {
        name: 'country',
        label: 'Country',
        required: true,
        type: 'text',
        disabled: true
    },
    {
        name: 'city',
        label: 'City',
        required: true,
        type: 'text'
    },
    {
        name: 'region',
        label: 'State',
        required: true,
        type: 'select',
        options: []
    },
    {
        name: 'postcode',
        label: 'Zip/Postal Code',
        required: true,
        type: 'text'
    }
]

export const ADDRESS_VALIDATION_DATA = {
    company: {
        required,
        minLength: minLength(3)
    },
    street: {
        0: {
            required
        }
    },
    city: {
        required
    },
    postcode: {
        required
    },
    region: {
        required
    },
    country: {
        required
    }
}

export const DEFAULT_COMPANY_DATA = {
    invalid: false,
    validate: false,
    billing_address: {},
    current_billing_address: {
        invalid: false,
        ...DEFAULT_BILLING_ADDRESS_FIELDS
    }
}

export const state = () => ({
    ...abstract.state(),
    chain: _cloneDeep(OBJECTS_CHAIN),
    componentsList: [],
    businessStepsList: [],
    region_data: [],
    cardToken: {},
    plans: [],
    offeredPlans: [],
    history: [],
    selected: [],
    payer: '',
    fullList: [],
    isPlusCustomer: false,
    load: false,
    employeeId: '',
    companyTemplateData: _cloneDeep(COMPANY_TEMPLATE_DATA),
    addressTemplateData: _cloneDeep(ADDRESS_TEMPLATE_DATA),
    accountFormData: {
        countries: []
    },
    componentKeys: [
        COMPONENT_ID_COMPANY_DETAILS,
        COMPONENT_ID_PRIMARY_CONTACT,
        COMPONENT_ID_PLAN_OPTIONS
    ],
    company_data: _cloneDeep(DEFAULT_COMPANY_DATA),
    recurlyPublicKey: false,
    stripePubKey: null,
    business_id: null,
    planSelected: false,
    api: {
        urls: {
            getBusinessPlans: '/plus/group/getBusinessPlans',
            getSelectedBusinessPlans: '/plus/group/getSelectedBusinessPlans',
            isPlusCustomer: '/plus/vision_plan/isPlusCustomer',
            submitBusiness: '/plus/group/submitBusiness',
            submitEmployee: '/plus/group/submitEmployee',
            getBusiness: '/plus/employee_signup/getBusiness',
            addBusinessPlan: '/plus/cart/addBusinessPlan',
            getCountryRegionsUrl: '/contact-lenses/directory/getRegions'
        }
    }
})

export const getters = {
    region_data: state => state.region_data,
    getBusinessId: state => state.business_id,
    RECURLY_PUBLIC_KEY: (state, getters, rootState, rootGetters) => {
        if (!state.recurlyPublicKey) {
            state.recurlyPublicKey = rootGetters.rootState ? rootGetters.rootState.recurlyPublicKey : ''
        }
        return state.recurlyPublicKey
    },
    stripePubKey: (state, getters, rootState, rootGetters) => {
        if (!state.stripePubKey) {
            state.stripePubKey = rootGetters.rootState ? rootGetters.rootState.stripePubKey : ''
        }
        return state.stripePubKey
    },
    currentBillingAddress: state => state.company_data.current_billing_address,
    chain: state => state.chain,
    cardToken: state => state.cardToken,
    getLoad: state => state.load,
    componentsList: state => state.componentsList,
    businessStepsList: state => state.businessStepsList,
    plans: state => state.plans,
    employeeId: state => state.employeeId,
    offeredPlans: state => state.offeredPlans,
    componentsCount: state => _size(state.componentsList),
    firstComponent: (state, getters) => getters.getObjectConfig(_first(getters.chain).id),
    currentComponent: state => _find(state.componentsList, obj => obj.state === STATE_CURRENT),
    companyTemplateData: (state) => state.companyTemplateData,
    addressTemplateData: (state) => state.addressTemplateData,
    selected: (state) => state.selected,
    fullList: (state) => state.fullList,
    payer: (state) => state.payer,
    values: state => {
        const values = {}
        _map(state.componentsList, obj => {
            values[obj.id] = obj.value
        })
        return values
    },
    checkoutData: (state, getters) => {
        const values = getters.values
        const company = values['company-details'] || []
        company['employees'] = 0
        const contact = values['primary-contact']
        const planOptions = values['business-plans']
        const payment = values['payment-information']

        return {
            company,
            contact,
            planOptions,
            payment
        }
    },
    getObjectConfig: state => id => _find(state.componentsList, obj => obj.id === id),
    getNextChainElement: (state, getters) => chainObject => {
        const chainItem = _find(getters.chain, obj => obj.id === chainObject.id)
        let component = Object.values(chainItem.next)[0]
        if (getters.payer === 'Employee') {
            component = 'business-finish'
        }
        return getters.getObjectConfig(component)
    },
    planSelected: state => state.planSelected
}

export const actions = {
    ...abstract.actions,
    fetchStepsList: ({getters, commit}) => {
        if (!_size(getters.businessStepsList)) {
            const list = []
            _map(getters.componentsList, obj => {
                if (!list[obj.stepId]) {
                    list[obj.stepId] = {
                        label: `Step ${obj.stepId}`,
                        state: STATE_WAIT,
                        componentIds: [],
                        components: []
                    }
                }
                list[obj.stepId].componentIds.push(obj.id)
                list[obj.stepId].components.push(obj)
            })
            commit('FULL_LIST', list)
            commit('STEPS_LIST', list)
        }
        return getters.businessStepsList
    },
    fetchComponentsList: ({getters, commit, rootGetters}) => {
        if (!_size(getters.componentsList)) {
            const params = {
                assets_url: rootGetters['storeView/assets_url']
            }
            const componentsList = _map(getObjectList(params), obj => {
                const chainItem = _find(getters.chain, item => item.id === obj.id)
                return Object.assign(obj, {
                    value: '',
                    isValidValue: false,
                    state: STATE_WAIT,
                    stepId: chainItem.stepId
                })
            })
            commit('COMPONENT_LIST', componentsList)
            _map(getters.componentsList, obj => commit('COMPONENT_DATA', {
                id: obj.id,
                value: _cloneDeep(obj.default_value)
            }))
        }
        commit('LOAD', false)
    },
    updateComponents: ({state, getters, commit, dispatch}) => {
        if (!_size(getters.componentsList)) {
            dispatch('fetchComponentsList')
        }
        if (!getters.currentComponent) {
            commit('COMPONENT_DATA', {id: getters.firstComponent.id, state: STATE_CURRENT})
        }

        const current = getters.currentComponent

        if (current.isValidValue) {
            const nextChain = getters.getNextChainElement(current)
            if (nextChain) {
                state.history.push(current.id)
                commit('COMPONENT_DATA', {id: current.id, state: STATE_READY})
                commit('COMPONENT_DATA', {id: nextChain.id, state: STATE_CURRENT})
            }
        }

        _map(getters.componentsList, obj => {
            if (getters.currentComponent.id !== obj.id) {
                commit('COMPONENT_DATA', {id: obj.id, state: obj.isValidValue ? STATE_READY : STATE_WAIT})
            }
        })
        dispatch('updateStepsList')
    },
    updateStepsList: ({getters, dispatch, commit}) => {
        if (!_size(getters.businessStepsList)) {
            dispatch('fetchStepsList')
        }
        _map(getters.businessStepsList, step => {
            if (step) {
                let state = STATE_WAIT
                if (_find(step.components, o => o.state === STATE_READY)) {
                    state = STATE_READY
                }
                if (_find(step.components, o => o.state === STATE_CURRENT)) {
                    state = STATE_CURRENT
                }
                if (!_isEqual(step.state, state)) {
                    step.state = state
                }
            }
        })
    },
    setActiveStep: ({state, getters, dispatch}, stepId) => {
        if (getters.currentComponent.stepId > stepId) {
            const history = state.history
            for (let i = history.length - 1; i >= 0; i--) {
                if (parseInt(getters.currentComponent.stepId) !== stepId) {
                    dispatch('goBack')
                }
            }
        }
    },
    goBack: ({state, getters, commit}) => {
        const prevChainId = state.history.pop()
        if (prevChainId) {
            const current = getters.currentComponent
            const previous = getters.getObjectConfig(prevChainId)
            commit('COMPONENT_DATA', {
                id: current.id,
                value: current.default_value,
                isValidValue: false,
                state: STATE_WAIT
            })
            commit('COMPONENT_DATA', {
                id: previous.id,
                value: previous.default_value,
                isValidValue: false,
                state: STATE_CURRENT
            })
        }
    },
    // for retrieving all possible plans for a business to select from on business sign-up
    async fetchBusinessPlans({state, commit}, limit=0) {
        const urlWithParams = state.api.urls.getBusinessPlans + '?limit='+limit
        return axios.get(urlWithParams).then(({data, status}) => {
            if (status === 200) {
                _map(data, (plan, index) => {
                    data[index]['plan_contacts'] = plan.plan_contacts
                })
                commit('BUSINESS_PLANS', data)
                commit('SELECTED')
                commit('LOAD', false)
            }
        })
    },
    // for retrieving plans selected by business, to select from on employee sign-up
    fetchBusinessOfferedPlans: ({state, commit}) => {
        return axios.get(state.api.urls.getSelectedBusinessPlans).then(({data, status}) => {
            commit('OFFERED_PLANS', data.plans)
            commit('EMPLOYEE_ID', data.employee_id)
            commit('PLAN_SELECTED', data.plan)
        })
    },
    // update to business customer
    fetchIsPlusCustomer: ({state, commit, getters}) => {
        return getters.isPlusCustomer || axios.get(state.api.urls.isPlusCustomer).then(({data, status}) => {
            if (status === 200) {
                commit('IS_PLUS_CUSTOMER', data.plus)
                commit('IS_PLUS_QUOTE', data.plusItem)
            }
        })
    },
    submitBusiness: ({state, getters, commit}) => {
        const data = getters.checkoutData
        if (getters.payer === 'Company') data['payment']['token'] = getters.cardToken
        return axios.post(state.api.urls.submitBusiness, data).then(({data, status}) => {
            if (status === 201) {
                commit('LOAD', false)
            }
        }).catch(error => {
            console.error(error)
            commit('LOAD', false)
        })
    },
    submitEmployee: ({state, getters, rootGetters, commit}, values) => {
        values['business_id'] = getters.getBusinessId
        return axios.post(state.api.urls.submitEmployee, values).then(({data, status}) => {
            if (status === 201) {
                window.location.href = '/plus/account'
            }
        })
    },
    getBusiness: ({state, commit}) => {
        let url = state.api.urls.getBusiness
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        url += '?'
        url += urlParams
        return axios.get(url).then(({data, status}) => {
            if (status === 201) {
                commit('BUSINESS_ID', data.business_id)
            }
        })
    },
    // todo for prospective employee to add to cart chosen plan
    addCurrentPlanToCart: ({state, getters}) => {
        return axios.post(state.api.urls.addBusinessPlan, getters.checkoutData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    fetchCountryRegions: ({state, commit}) => {
        return axios.get(state.api.urls.getCountryRegionsUrl).then(({data, status}) => {
            if (status === 200) {
                commit('SET_REGIONS_DATA', data)
            }
            return {data, status}
        })
    }
}

export const mutations = {
    ...abstract.mutations,
    SET_REGIONS_DATA: (state, regions_data) => {
        state.region_data = regions_data
        const data = state.companyTemplateData
        _map(data, value => {
            if (value.name === 'region') {
                value.options = regions_data
            }
        })

        const address = state.addressTemplateData
        _map(address, value => {
            if (value.name === 'state') {
                value.options = regions_data
            }
        })

        state.companyTemplateData = data
        state.addressTemplateData = address
    },
    COMPONENT_LIST: (state, list = []) => state.componentsList = list,
    COMPONENT_DATA: (state, data = {}) => {
        const obj = _find(state.componentsList, obj => obj.id === data.id)
        _map(data, (value, key) => {
            if (obj && !_isEqual(value, obj[key])) {
                Vue.set(obj, key, value)
            }
        })
    },
    STEPS_LIST: (state, list = []) => {
        let steps = []

        if (state.payer === 'Company') {
            steps = _filter(list, (step, index) => {
                return index < 5
            })
        } else {
            steps = _filter(list, (step, index) => {
                return index < 4
            })
        }

        state.businessStepsList = steps
    },
    FULL_LIST: (state, full = []) => state.fullList = full,
    PLAN_SELECTED: (state, selected) => {
        state.planSelected = selected
    },
    OFFERED_PLANS: (state, plans = []) => {
        state.offeredPlans = plans
    },
    PLANS: (state, plans = []) => {
        _filter(plans, plan => plan.sku = plan.sku.replace(/-/g, '_'))
        state.plans = plans
    },
    UPDATE_SELECTED: (state, option) => {
        state.selected[option] = !state.selected[option]
    },
    UPDATE_PAYER: (state, option) => {
        state.payer = option
    },
    SELECTED: (state) => {
        const selected = {}
        _forEach(state.plans, plan => {
            selected[plan.sku] = false
        })
        state.selected = selected
    },
    BUSINESS_PLANS: (state, business_plans = []) => {
        _filter(business_plans, (business_plan, index) => {
            business_plan.sku = business_plan.sku.replace(/-/g, '_')
            business_plan.label = business_plan.label.replace(/-/g, ' ')
            business_plan.color = PLAN_COLORS[business_plan.label].color
        })
        const plans = business_plans
        const tmp = plans.splice(1, 1)
        plans.splice(1, 0, tmp[0])
        state.plans = plans
    },
    IS_PLUS_CUSTOMER: (state, plus = false) => state.isPlusCustomer = Boolean(plus),
    EMPLOYEE_ID: (state, id) => state.employeeId = id,
    LOAD: (state, load) => state.load = load,
    CARD: (state, cardToken) => state.cardToken = cardToken,
    BUSINESS_ID: (state, business_id) => state.business_id = business_id
}

export default (store = {}) => ({
    namespaced: true,
    state: state(),
    getters,
    actions,
    mutations
})
