<template>
    <CatalogProductView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogProductView from '../../../components/catalog/product/Goodr/CatalogProductBlockView.vue'

export default {
    name: 'RouterGoodrCatalogProductView',
    components: {
        CatalogProductView
    },
    extends: App
}
</script>
