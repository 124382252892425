<template>
    <div v-if="statusApi">
        <div>Content should be here</div>
    </div>
</template>

<style lang="less">
@import "../css/styles-m.less";
</style>

<style lang="less">
@import "../css/styles-l.less";
</style>

<script>
import {mapGetters, mapActions} from 'vuex'
import {translate} from './mixins'

export default {
    name: 'App',
    mixins: [translate],
    data: () => ({
        tabletWidth: 920,
        mobileWidth: 640,
        statusApi: false
    }),
    computed: {
        ...mapGetters(['getAppId']),
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet'])
    },
    beforeCreate () {
        this.$nextTick(() => {
            this.statusApi = this.$root.statusApi
            this.$store.dispatch('storeView/fetchDefaultData')
            this.$store.dispatch('lensablplus_customer/fetchDefaultData')
        })
    },
    mounted () {
        this.setWindowFunc()
        this.$store.dispatch('partner/resetPartnerState')
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        ...mapActions('lensablplus_customer', ['checkRedeemablePath']),
        setWindowFunc () {
            this.$nextTick(() => {
                window.addEventListener('resize', this.handleResize)
                this.handleResize()
            })
            // Set Cookie path current on page load
            window.addEventListener('pagehide', this.setCookie)
        },
        handleResize () {
            this.$store.commit('deviceProperty/IS_MOBILE', this.getWindowWidth() < this.mobileWidth)
            this.$store.commit('deviceProperty/IS_TABLET', !this.$store.getters['deviceProperty/isMobile'] && this.getWindowWidth() < this.tabletWidth)
        },
        getWindowWidth () {
            const winW = window.innerWidth
            const docW = document.compatMode === 'CSS1Compat' ? document.documentElement.clientWidth : document.body.clientWidth
            return winW > docW ? winW : docW
        },
        setCookie () {
            const redeemObj = JSON.parse(sessionStorage.getItem('redeem_path'))
            if (redeemObj === null) return
            redeemObj.previous = window.location.pathname
            const destinationUrl = document.activeElement ? document.activeElement.href : false
            if (destinationUrl) {
                redeemObj.current = destinationUrl
            }
            sessionStorage.setItem('redeem_path', JSON.stringify(redeemObj))
            this.checkRedeemablePath()
        }
    }
}
</script>
