var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Cuctomer Account Section")]),
    _vm._v("\n    category grid template "),
    _c("br"),
    _vm._v(" "),
    _c(
      "a",
      {
        attrs: { href: "javascript:void(0)" },
        on: { click: _vm.goToProductTestMethod }
      },
      [_vm._v("Go to Product (example link)")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }