<template>
    <div>
        <div class="spectacles-banner frames-banner bose-banner">
            <div class="sb-inline sb-inner-img  bose" :style="{ backgroundImage: 'url(' + getCategoryImage + ')' }">
            </div>
        </div>
        <div class="bose-header-links">
            <div class="bose-header-links-container">
                <div v-dompurify-html="breadcrumbsHtml" class="frames-breadcrumbs"></div>
                <p>Already have the frames and just need new lenses?<a href="order-lenses-bose">Click Here</a></p>
                <p><a href="mailto:support@lensabl.com?subject=Bose Frames Question">Need Help?</a></p>
            </div>
        </div>
        <div class="category-products frames-products bose">
            <PageLoader v-if="categoryLoader" :load="categoryLoader" />
            <template v-else>
                <div>
                    <div class="category-wrapper-inline category-wrapper-title"><span>The Latest Look</span></div>
                    <div class="products wrapper grid products-grid category-wrapper-inline">
                        <ul class="products list items product-items">
                            <li v-for="product in productsByPosition('1')" :key="product.url" class="item product product-item">
                                <CatalogCategoryListItem :product="product" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="category-wrapper-inline category-wrapper-title"><span>Sport Sunglasses</span></div>
                    <div class="products wrapper grid products-grid category-wrapper-inline">
                        <ul class="products list items product-items">
                            <li v-for="product in productsByPosition('2')" :key="product.url" class="item product product-item">
                                <CatalogCategoryListItem :product="product" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="category-wrapper-inline category-wrapper-title"><span>The Original Bose Sunglasses</span></div>
                    <div class="products wrapper grid products-grid category-wrapper-inline">
                        <ul class="products list items product-items">
                            <li v-for="product in productsByPosition('3')" :key="product.url" class="item product product-item">
                                <CatalogCategoryListItem :product="product" />
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import _filter from 'lodash/filter'
import PageLoader from '../../../loaders/PageLoader.vue'
import CatalogCategoryListItem from '../../product/list/Item.vue'

export default {
    name: 'BoseProductList',
    components: {
        PageLoader,
        CatalogCategoryListItem
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('category', [
            'breadcrumbsHtml',
            'categoryLoader',
            'categoryImage',
            'categoryMobileImage',
            'getProducts',
            'currentCategory'
        ]),
        categoryName () {
            return this.currentCategory.name || false
        },
        getCategoryImage () {
            if (this.isMobile) {
                return this.categoryMobileImage
            }
            return this.categoryImage
        }
    },
    created () {
        this.$store.dispatch('category/fetchDefaultData')
        this.$store.dispatch('category/fetchCategoryProducts')
    },
    methods: {
        ...mapActions('category', ['fetchCategoryProducts']),
        productsByPosition (position) {
            const products = this.getProducts
            return _filter(products, product => product.position === position)
        }
    }
}
</script>

<style scoped>

</style>
