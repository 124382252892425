<template>
    <div v-if="!memberShipEdited">
        <table v-if="redeemedItems.length" cellspacing="0" class="form-list redeem-items">
            <tbody>
                <tr v-for="(item, index) in redeemedItems" :key="`order-${item.type}-${index}`" :class="item.type" class="tr-line">
                    <td class="label" v-text="item.label"></td>
                    <td class="scope-label">
                        <strong v-if="item.hasOwnProperty('redeemed_date')" v-text="formatDate(item.redeemed_date)"></strong>
                    </td>
                    <td class="scope-label">
                        <strong v-if="item.hasOwnProperty('grand_total')" v-text="formatPrice(item.grand_total)"></strong>
                    </td>
                    <td class="scope-label">
                        <a :href="item.order_url" target="_blank" class="form-button">View Order</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <strong v-else>No Orders</strong>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import dateFormat from 'dateformat'

export default {
    name: 'LensabalplusMembershipRecentOrders',
    computed: {
        ...mapGetters('lensablPlus/membership', ['memberShipEdited', 'redeemedItems'])
    },
    methods: {
        formatDate (date) {
            return dateFormat(new Date(date), 'mmm dS, yyyy h:MM:ss TT')
        },
        formatPrice (price) {
            const formattedPrice = parseFloat(price || 0).toFixed(2)
            return `$${formattedPrice}`
        }
    }
}
</script>
