<template>
    <div v-show="isCurrent" :class="id">
        <h2 v-if="config.title" v-dompurify-html="config.title" class="title"></h2>

        <div id="company_address_fields" class="company-address">
            <div class="content">
                <div class="hor-scroll">
                    <table cellspacing="0" class="form-list">
                        <colgroup>
                            <col width="60%" />
                            <col width="40%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <label :for="'companyTemplateData[0].name'">
                                        {{ companyTemplateData[0].label }}:
                                    </label>
                                    <input
                                        :id="`membership-billing_address_${companyTemplateData[0].name}`"
                                        v-model="currentBillingAddress[companyTemplateData[0].name]"
                                        :class="{'required-entry': companyTemplateData[0].required, 'validation-failed': errors[companyTemplateData[0].name]}"
                                        class="input-text"
                                        type="text"
                                        @blur="validBlurField(companyTemplateData[0].name)"
                                    />
                                    <div v-if="errors[companyTemplateData[0].name]" :id="`advice-required-entry-${companyTemplateData[0].name}`" class="validation-advice" style="">This is a required field.</div>
                                </td>
                            </tr>
                            <tr v-for="(template, index) in companyTemplateData[1].options" :key="`row-${template.label}`">
                                <td colspan="2">
                                    <label :for="`membership-billing_address_${template.label}`">
                                        {{ template.label }}:
                                    </label>
                                    <input
                                        :id="`membership-billing_address_${template.label}`"
                                        v-model="currentBillingAddress.street[index]"
                                        :class="{'required-entry': template.required, 'validation-failed': index === 0 && errors['street']}"
                                        class="input-text"
                                        type="text"
                                        @blur="index === 0 ? validBlurField('street') : ''"
                                    />
                                    <div v-if="index === 0 && errors['street']" :id="`advice-required-entry-${template.label}`" class="validation-advice" style="">This is a required field.</div>
                                </td>
                            </tr>
                            <tr>
                                <template v-for="(template, index) in companyTemplateData">
                                    <td v-if="index > 1 && index < 4" :key="`row-${template.name}`">
                                        <label :for="`membership-billing_address_${template.name}`">
                                            {{ template.label }}:
                                        </label>
                                        <template>
                                            <input
                                                v-if="template.type === 'text'"
                                                :id="`membership-billing_address_${template.name}`"
                                                v-model="currentBillingAddress[template.name]"
                                                :class="{'required-entry': template.required, 'validation-failed': errors[template.name]}"
                                                :readonly="template.disabled"
                                                class="input-text"
                                                type="text"
                                                @blur="validBlurField(template.name)"
                                            />
                                        </template>
                                        <div v-if="errors[template.name]" :id="`advice-required-entry-${template.name}`" class="validation-advice" style="">This is a required field.</div>
                                    </td>
                                </template>
                            </tr>
                            <tr>
                                <template v-for="(template, index) in companyTemplateData">
                                    <td v-if="index >= 4 && index < 6" :key="`row-${template.name}`">
                                        <label :for="`membership-billing_address_${template.name}`">
                                            {{ template.label }}:
                                        </label>

                                        <template v-if="template.options">
                                            <select
                                                :id="`membership-billing_address_${template.name}`"
                                                v-model="currentBillingAddress.region"
                                                :class="{'required-entry': template.required, 'validation-failed': errors[template.name]}"
                                                class="select"
                                                @change="validBlurField(template.name)"
                                            >
                                                <template v-for="region in template.options">
                                                    <option :key="`${region.name}-option-${region.value}`" :value="region.value" v-text="region.label"></option>
                                                </template>
                                            </select>
                                        </template>

                                        <template v-else>
                                            <input
                                                v-if="template.type === 'text'"
                                                :id="`membership-billing_address_${template.name}`"
                                                v-model="currentBillingAddress[template.name]"
                                                :class="{'required-entry': template.required, 'validation-failed': errors[template.name]}"
                                                class="input-text"
                                                type="text"
                                                @blur="validBlurField(template.name)"
                                            />
                                        </template>

                                        <div v-if="errors[template.name]" :id="`advice-required-entry-${template.name}`" class="validation-advice" style="">This is a required field.</div>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton
                    class="button--copy-solid-white-blue button--small"
                    text="Continue"
                    @click.native="continueNxt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseButton from '../../base/BaseButton.vue'
import _map from 'lodash/map'
import {validationMixin} from 'vuelidate'

import {
    ADDRESS_VALIDATION_DATA
} from '../storage/lensablplus/business'

export default {
    name: 'CompanyDetails',
    components: {
        BaseButton
    },
    mixins: [validationMixin],
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        errors: {
            city: false,
            country: false,
            postcode: false,
            region: false,
            street: false,
            company: false
        }
    }),
    validations: {
        currentBillingAddress: {
            ...ADDRESS_VALIDATION_DATA
        }
    },
    computed: {
        ...mapGetters('lensablplus_business', ['getObjectConfig', 'currentComponent', 'companyTemplateData', 'currentBillingAddress']),
        config () {
            return this.getObjectConfig(this.id)
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        },
        addressInvalidFields () {
            const invalidFields = []
            _map(this.currentBillingAddress, (value, key) => {
                if (this.$v.currentBillingAddress.hasOwnProperty(key) && this.$v.currentBillingAddress[key].$invalid) {
                    invalidFields.push(key)
                }
            })
            return invalidFields
        },
        addressIsValid () {
            return !this.addressInvalidFields.length
        }
    },
    created () {
        this.$store.dispatch('lensablplus_business/fetchCountryRegions', 'US')
    },
    methods: {
        continueNxt () {
            if (this.addressIsValid) {
                this.$store.commit('lensablplus_business/COMPONENT_DATA', {id: this.id, value: this.currentBillingAddress, isValidValue: true})
            } else {
                _map(this.companyTemplateData, (value, key) => {
                    this.validBlurField(value['name'])
                })
            }
        },
        validBlurField (val) {
            this.errors[val] = this.$v.currentBillingAddress[val].$invalid
        }
    }
}
</script>

<style scoped>

</style>
