<template>
    <div :class="category.class">
        <a :href="category.url" :title="category.name" @click="nextCategory">
            <img v-if="category.class === 'hobieeyewearsunglasses'" src="/js/lensconfigurator/assets/Hobie-Baja.jpg" width="358" height="358" alt="" />
            <img v-else-if="category.class === 'hobieeyewearoptical'" src="/js/lensconfigurator/assets/Hobie-HO4509.jpg" width="358" height="358" alt="" />
            <img v-else :src="categoryImage" width="358" height="358" alt="" />
            <div class="header-main">
                <span>{{ getName }}</span>
                <h3 class="product-name">{{ categoryName }}</h3>
            </div>
        </a>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import _first from 'lodash/first'

export default {
    name: 'CategoryItem',
    props: {
        category: {
            type: [Object],
            default: () => {
            }
        }
    },
    computed: {
        ...mapGetters('partner', ['getParentCategory', 'getProducts', 'getName']),
        categoryName () {
            const name = this.category.name
            return name.replace(this.getName, '').trim()
        },
        categoryImage () {
            const firstProduct = _first(this.category.products)
            return firstProduct ? firstProduct.image : false
        }
    },
    methods: {
        nextCategory (event) {
            location = this.category.url
            event.preventDefault()
        }
    }
}
</script>
