<template>
    <WhyPlus v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import WhyPlus from '../../components/lensablplus/WhyPlus.vue'
export default {
    name: 'RouterWhyPlus',
    components: {
        WhyPlus
    },
    extends: App
}
</script>

<style scoped>

</style>
