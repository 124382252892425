<template>
    <v-container v-if="!success" grid-list-md anagram-container>
        <v-layout row wrap>
            <h3>File your vision insurance claim</h3>
            <div>
                <RadioLabel :active="hasInsurance" label="I have vision insurance" @click="changeRadioValue"/>
                <RadioLabel :active="!hasInsurance" label="I am covered under someone else’s vision insurance plan"
                            @click="changeRadioValue"/>
            </div>
            <span class="anagram-claim-text">Claim your insurance benefits for your purchase! Fill in the information below to get your ‘out-of-network’ reimbursement for your {{productsName}}.</span>
        </v-layout>
        <HasInsuranceForm v-if="hasInsurance" :form-model="getHasInsuranceForm"/>
        <HasNotInsuranceForm v-else :form-model="getHasNotInsuranceForm"/>
        <p v-if="error" class="error-text">{{errorText}}</p>
    </v-container>
    <v-container v-else grid-list-md anagram-container>
        <p>Your request has been submitted successfully.</p>
    </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import storageInsuranceAnagram from './storage/insuranceAnagram'
import RadioLabel from '../base/RadioLabel.vue'
import HasInsuranceForm from './forms/anagram/HasInsuranceForm.vue'
import HasNotInsuranceForm from './forms/anagram/HasNotInsuranceForm.vue'

export default {
    name: 'InsuranceAnagram',
    components: {
        RadioLabel,
        HasInsuranceForm,
        HasNotInsuranceForm
    },
    data: () => {
        return {
            hasInsurance: true,
        }
    },
    computed: {
        ...mapGetters('insuranceAnagram', ['getHasInsuranceForm', 'getHasNotInsuranceForm', 'productsName']),
        ...mapState('insuranceAnagram', ['success', 'error', 'errorText'])
    },
    beforeCreate() {
        if (!this.$store._modulesNamespaceMap['insuranceAnagram/']) {
            this.$store.registerModule('insuranceAnagram', storageInsuranceAnagram(this.$store))
        }
    },
    methods: {
        changeRadioValue() {
            this.hasInsurance = !this.hasInsurance
            this.$store.commit('insuranceAnagram/resetState')
        },
    }
}
</script>
