import {
    PRESCRIPTION_TYPE_UPLOAD
} from '../../../app/utils/constants'
import Finish from '../../configurator/Finish.vue'

import {mapGetters} from 'vuex'

export default {
    name: 'ContactsFinish',
    extends: Finish,
    props: {
        'order-pair-button-text': {
            type: String,
            default: 'Continue Shopping',
            required: false
        }
    },
    computed: {
        ...mapGetters('contacts', ['getOriginalEyeData', 'getLeftEyeChecked', 'getRightEyeChecked']),
        ...mapGetters('configurator/steps', ['getStepObject']),
        ...mapGetters('values', ['values']),
        ...mapGetters('summary', ['totalSummaryContacts', 'redeemDiscount']),
        prescriptionOwnerStep () {
            return this.getStepObject('prescription_owner')
        },
        verifyStep () {
            return this.getStepObject('verify')
        }
    },
    methods: {
        restart () {
            this.loadingRestart = true
            this.animationEnabled = true
            this.errorMessage = null
            this._addProductToCart()
                .then(() => {
                    this.animationEnabled = true
                    this.loadingRestart = false
                    this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', false)
                    this.$store.commit('contacts/RESET_EYE_DATA')
                    this.$store.commit('contacts/UPDATE_PRODUCT_ATC')
                    window.location.href = '/shop-contacts/brands'
                })
                .catch((error) => {
                    const response = error.response
                    if (response.status === 400){
                        this.errorMessage = response.data.message
                    }else{
                        console.error(error)
                    }
                    this.animationEnabled = false
                    this.loadingRestart = false
                })
        },
        checkout () {
            this.loadingCheckout = true
            this.errorMessage = null
            this._addProductToCart()
                .then(() => {
                    this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', false)
                    document.location = this.base_url + 'onepage'
                    this.$store.commit('configurator/SET_MOVING_TO_CHECKOUT', true)
                    const promise = new Promise((resolve) => {
                        setTimeout(() => {
                            this.$store.commit('configurator/SET_ANIMATION_SHOW', true)
                            this.loadingCheckout = false
                            resolve()
                        }, 50)
                    })
                    return promise
                })
                .then(() => {
                    this.$store.dispatch('values/resetCheckout')
                    this.$store.commit('contacts/UPDATE_PRODUCT_ATC')
                    this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', false)
                    this.$store.commit('contacts/RESET_EYE_DATA')
                })
                .catch((error) => {
                    const response = error.response
                    if (response.status === 400){
                        this.errorMessage = response.data.message
                    }else{
                        console.error(error)
                    }
                    this.loadingCheckout = false
                })
        },
        reset () {
            this.$store.commit('updateReset', true)
            this._configuratorReset()
            this.$store.commit('prescriptions/UPDATE_FIRST_NAME', '')
            this.$store.commit('prescriptions/UPDATE_LAST_NAME', '')
            this.$store.commit('prescriptions/RESET_DOCTOR')
            this.$set(this.values, 'prescription_owner', '')
            this.$set(this.prescriptionOwnerStep, 'value', '')
            this.$set(this.prescriptionOwnerStep, 'summaryLabel', '')
            this.$set(this.verifyStep, 'value', PRESCRIPTION_TYPE_UPLOAD)
            this.$set(this.verifyStep, 'summaryLabel', 'Upload Prescription')
            this.$set(this.values, 'verify', 'Upload Prescription')
            this.$store.commit('prescriptions/UPDATE_SELECTED_DOCTOR_ID', '')
            this.$store.commit('contacts/RESET_ORIGINAL_DATA', this.getOriginalEyeData)
            this.errorMessage = null
        },
        _addProductToCart () {
            let checkoutData = this.checkoutData
            if (this.isRedeemFlow && this.membershipId) {
                checkoutData = this._addPlusInfo(checkoutData)
            }
            checkoutData.member_program = this.prepareMemberDiscountCheckout
            this.addRetentionData()
            return this.cartService.addContactsProduct(checkoutData)
        },

        _addPlusInfo () {
            const checkoutData = this.checkoutData
            const total = this.totalSummaryContacts
            const data = checkoutData.data

            let qty = 0
            if (this.getLeftEyeChecked) {
                qty += parseInt(data.left.qty)
            }
            if (this.getRightEyeChecked) {
                qty += parseInt(data.right.qty)
            }
            checkoutData.plus_item = {
                'membership_id': this.membershipId,
                'plus_price': total / qty,
                'types': [],
                'benefit_total': this.redeemDiscount / qty
            }
            return checkoutData
        },
        addRetentionData() {
            let productView = {...this.getOriginalEyeData.left}
            let productPrice = productView.regPrice * productView.boxes

            const cart = {
                ImageURL: productView.image,
                Name: productView.brand,
                Price: productPrice,
                ProductID: productView.id,
                Quantity: productView.boxes,
                URL: document.URL,
            }
            var _learnq = _learnq || [];

            window._learnq.push(['track', 'Added to Cart', cart]);
            window.geq.addToCart(cart)
        }
    }
}
