<template>
    <li :class="{active: isActive, 'before-active': beforeActive, 'after-active': afterActive, 'no-border': option.show}" class="option" @click="optionSelect()">
        <div :class="{'has-price': option.price}" class="option-container">
            <div v-if="option.image" class="option-thumbnail">
                <div v-dompurify-html="option.image"></div>
            </div>
            <div class="option-descriptions">
                <div class="option-label">
                    <span class="label-text">{{ option.alias }}</span>
                    <HelpBubble v-if="componentHelpShow && option.component_help" :data="option.component_help" />
                </div>
            </div>

            <div v-if="option.price" class="option-price-container">
                <strong v-dompurify-html="(1 * option.price > 0) ? '+$' + option.price : `Free`" class="option-price"></strong>
            </div>

            <div :class="{active: isActive}" class="option-checkbox-container">
                <div class="option-checkbox"></div>
            </div>
        </div>
        <div v-if="option.desc" class="extra-component-container">
            <div v-dompurify-html="option.desc" class="extra-component"></div>
            <div v-if="option.disclaimer" class="extra-component-disclaimer">
                <span v-dompurify-html="option.disclaimer"></span>
            </div>
        </div>
    </li>
</template>

<script>
import DefaultOption from '../options/Option.vue'

export default {
    name: 'AntireflectiveOption',
    extends: DefaultOption,
    props: {
        index: {
            type: [String, Number],
            default: ''
        }
    },
    computed: {
        isActive () {
            return this.option.checked
        },
        beforeActive () {
            return false
        },
        afterActive () {
            return false
        }
    },
    methods: {
        optionSelect () {
            return this.$parent.changeValueARHandler(this.index)
        }
    }
}
</script>
