var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.productView.productInfoShow,
              expression: "productView.productInfoShow"
            }
          ],
          staticClass:
            "product-details-holder lensabl-frames-view frames-wrapper",
          class: { "product-layout-2columns": _vm.isDesktop }
        },
        [
          _c(
            "div",
            { staticClass: "product media" },
            [_c("Gallery", { class: { "vertical-thumbs": _vm.isDesktop } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-info-main" },
            [
              _c("div", { staticClass: "page-title-wrapper product" }, [
                _c("h1", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value:
                        _vm.product_name === "Mick And Keith Midnight Ramble"
                          ? "Mick & Keith Midnight Ramble"
                          : _vm.product_name,
                      expression:
                        "product_name === 'Mick And Keith Midnight Ramble' ? 'Mick & Keith Midnight Ramble' : product_name"
                    }
                  ],
                  staticClass: "page-title",
                  class: _vm.product_name,
                  attrs: { itemprop: "name" }
                })
              ]),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.productDescription,
                    expression: "productDescription"
                  }
                ]
              }),
              _vm._v(" "),
              _c("AdditionalAttributes", {
                class: {
                  inline: !_vm.isDesktop && !_vm.isMobile,
                  grid: _vm.isMobile
                },
                attrs: { "data-attributes": _vm.additionalAttributes }
              }),
              _vm._v(" "),
              _c("ProductAffirmInfo", {
                attrs: { startingTitle: "Starting from $" }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "shopnshop-time-text" }, [
                _vm._v(
                  "Lens production takes 4-7 business days on average from the date you've submitted all required Rx information. All lenses go through a detailed quality inspection before shipping out. If any issues are found during inspection, we will remake the lenses until they are perfect (this may cause slight delays in shipping time)"
                )
              ]),
              _vm._v(" "),
              _vm.colors.length
                ? [
                    _vm.isAvailable
                      ? _c("div", { staticClass: "product-options-bottom" }, [
                          _c(
                            "div",
                            { staticClass: "actionsproductCategoryName" },
                            [
                              _c(
                                "BaseButton",
                                {
                                  attrs: { text: "Choose Your Lenses" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.isMobile
                                        ? _vm.mobileSwitcher()
                                        : _vm.goToConfigurator()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Select Lenses\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isAvailable
                      ? _c(
                          "p",
                          {
                            staticClass: "unavailable stock",
                            attrs: { title: "Availability" }
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("This item is sold out")
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "product-border-divider" }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAvailable && _vm.productView.productConfiguratorShow,
              expression: "isAvailable && productView.productConfiguratorShow"
            }
          ],
          staticClass: "product-configurator-wrapper"
        },
        [_c("Configurator")],
        1
      ),
      _vm._v(" "),
      _vm.productView.productInfoShow ? _c("ReturnsInfo") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }