<template>
    <section class="understanding_measurememt-container vision-seo-accordion">
        <div class="understanding-demo-container">
            <div class="accordian-item">
                <div :class="{active: header1}" class="accordian-header" @click.prevent="header1 = !header1">
                    <h4>
                        Do I need a past prescription to take the online eye exam?
                    </h4>
                </div>
                <div class="accordian-content" :style="header1 ? 'display: block' : 'display: none'">
                    <p>The online eye test from Lensabl helps patients renew or update their existing prescriptions only.</p>
                </div>
            </div>
            <div class="accordian-item">
                <div :class="{active: header2}" class="accordian-header" @click.prevent="header2 = !header2">
                    <h4>
                        Who can take the online vision test?
                    </h4>
                </div>
                <div class="accordian-content" :style="header2 ? 'display: block' : 'display: none'">
                    <p>We provide tests in 37 states for patients between the ages of 18 and 50 who do not have health conditions such as diabetes or glaucoma.</p>
                </div>
            </div>
            <div class="accordian-item">
                <div :class="{active: header3}" class="accordian-header" @click.prevent="header3 = !header3">
                    <h4>
                        Will a real eye doctor review my exam?
                    </h4>
                </div>
                <div class="accordian-content" :style="header3 ? 'display: block' : 'display: none'">
                    <p>A certified ophthalmologist from your state will audit your virtual test and provide a prescription based on the results. </p>
                </div>
            </div>
            <div class="accordian-item">
                <div :class="{active: header4}" class="accordian-header" @click.prevent="header4 = !header4">
                    <h4>
                        Should I wear my contacts or glasses during the test?
                    </h4>
                </div>
                <div class="accordian-content" :style="header4 ? 'display: block' : 'display: none'">
                    <p>Patients need to remove their glasses or contacts before taking their virtual eye test.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'FaqAccordion',
    data: () => ({
        header1: false,
        header2: false,
        header3: false,
        header4: false
    })
}
</script>
