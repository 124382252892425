<template>
    <div id="back-button-container" class="back-button-container">
        <a class="back-button" href="javascript:void(0)" @click="goBack">Back</a>
    </div>
</template>

<script>
export default {
    name: 'Back',
    methods: {
        goBack () {
            if (window.location.pathname === '/plus/group') {
                this.$store.dispatch('lensablplus_business/goBack')
            } else {
                this.$store.dispatch('lensablPlus/goBack')
            }
        }
    }
}
</script>
