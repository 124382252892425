var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "featured-frames-container" },
    [
      _c("h3", [_vm._v("Most Popular Frames")]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "grid-list" } },
        [
          _vm.getLoading
            ? _c("PageLoader", { attrs: { load: _vm.getLoading } })
            : _vm._e(),
          _vm._v(" "),
          [
            _c(
              "div",
              {
                staticClass:
                  "products wrapper grid products-grid shop-frames-cat"
              },
              [
                _vm.load
                  ? _c("PageLoader", { attrs: { load: _vm.load } })
                  : [
                      _c(
                        "ul",
                        { staticClass: "products list items product-items" },
                        [
                          _vm._l(_vm.updatedProductList, function(
                            product,
                            index
                          ) {
                            return [
                              _c(
                                "li",
                                {
                                  key: product.url + index,
                                  staticClass: "item product product-item"
                                },
                                [
                                  _c("CatalogCategoryListItem", {
                                    attrs: { product: product }
                                  })
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ]
              ],
              2
            )
          ]
        ],
        2
      ),
      _vm._v(" "),
      [
        _c(
          "div",
          {
            staticClass:
              "c-product-slider-container featured-frames-slider-container"
          },
          [
            _c(
              "carousel",
              {
                staticClass: "c-homepage-slider",
                attrs: {
                  "per-page": 1,
                  "mouse-drag": false,
                  "touch-drag": true,
                  "navigation-enabled": true,
                  "pagination-enabled": false,
                  "center-mode": true,
                  "navigation-prev-label": "‹",
                  "navigation-next-label": "›"
                }
              },
              _vm._l(_vm.updatedProductList, function(product) {
                return _c(
                  "slide",
                  {
                    key: product.index,
                    staticClass: "c-homepage-slider-slide"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "featured-frames-slider" },
                      [
                        _c("CatalogCategoryListItem", {
                          attrs: { product: product }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }