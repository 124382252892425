<template>
    <AffirmConfirm v-if="statusApi" />
</template>

<script>
import App from '../../../../App.vue'
import AffirmConfirm from '../../../../components/checkout/payments/affirm/AffirmConfirm.vue'

export default {
    name: 'RouterAffirmConfirm',
    components: {
        AffirmConfirm
    },
    extends: App
}
</script>
