var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.notes,
          expression: "notes"
        }
      ],
      staticClass: "option-value",
      attrs: { placeholder: _vm.renderPlaceholder, cols: "30", rows: "5" },
      domProps: { value: _vm.notes },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.notes = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    !_vm.framesPartnerPage
      ? _c("span", { staticClass: "option-comments-disclaimer" }, [
          _vm._v(
            "For any special requests, please reach out to customer support so that we may modify your order."
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }