import {defaultOptions, framesTypeCustomize} from "./default";
import getHelper from "../helper";
import {LENS_TYPE_ID, LENS_TYPE_SECTION_ID, NON_PRESCRIPTION_ID, LENS_TYPE_ID_CLEAR_LENSES} from "../../../../../app/common/constants/lens_type";
import {LENS_OPTIONS_CODE} from "../lens_options";


const defaultLensType = {
    [LENS_TYPE_ID]: {
        [LENS_TYPE_ID]: LENS_TYPE_ID_CLEAR_LENSES,
    },
    [NON_PRESCRIPTION_ID]: {
        [NON_PRESCRIPTION_ID] : 'plano'
    },
}

export const LIMITERS_GOODR = {
    preDefined: {
        'ALL': defaultLensType
    }
}

export default function getGoodrStepList (params) {
    const defaultStepOptions = defaultOptions(params)
    const {product_config, frames_name, assets_url} = params
    const {DEFAULT_LAST_STEP} = getHelper(params)
    function assetUrl(filename) {
        return assets_url + filename
    }
    let lensOption = {
        'id': LENS_TYPE_ID,
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['computer_lens'],
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensType.sunglassesLens,
                defaultStepOptions.lensType.computerLens,
                defaultStepOptions.lensType.blueLightLens,

            ]
        }
    }



    return [
        defaultStepOptions.notesOption,
        defaultStepOptions.upgradesOption,
        defaultStepOptions.warrantyOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    {
                        'id': 'prescription',
                        'label': 'Prescription Lenses',
                        'summary_label': 'Prescription',
                        'description': 'Available in clear, sunglass and blue light blocking lenses.',
                        'thumbnail': assetUrl('product-configuration-chooselenses-prescription.jpg'),
                        'redeemable': true,
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Available in clear, sunglass and blue light blocking lenses.</p> '
                            }
                        }
                    },
                    defaultStepOptions.chooseLens.readingType,
                    {
                        'id': 'nonprescription',
                        'label': 'Non-Prescription',
                        'summary_label': 'Non-Prescription',
                        'description': 'Available in clear, sunglass and blue light blocking lenses. All lenses are polycarbonate.',
                        'thumbnail': assetUrl('product-configuration-chooselenses-sunglasses.jpg'),
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Available in clear, sunglass and blue light blocking lenses. All lenses are polycarbonate.</p> '
                            }
                        }
                    }
                ]
            }
        },
        defaultStepOptions.lensPower,
        {
            'id': 'nonprescription',
            'label': 'Choose Non-Prescription',
            'summary_label': 'Non-Prescription',
            'value_renderer': 'renderer-options',
            'default_value': 'sunglasses',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.nonPrescriptionTypes.sunglassesNonPrescription,
                    defaultStepOptions.nonPrescriptionTypes.computerNonPrescription,
                    defaultStepOptions.nonPrescriptionTypes.planoNonPrescription,
                ]
            }
        },
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription,
                    ...defaultStepOptions.prescriptionTypes.bifocalPrescription
                ]
            }
        },
        Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_near'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_near_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'bi_focal'}, {...DEFAULT_LAST_STEP}),
        lensOption,
        {
            'id': LENS_OPTIONS_CODE,
            'label': 'Select Lens Options',
            'summary_label': 'Lens Option',
            'value_renderer': 'renderer-options',
            'hide_for_product': ['sunglass_lens', 'transition_lens'],
            'always_visible': true,
            'component': {
                'name': 'selector-options-lens-option',
                'params': {
                    'product_config': product_config
                },
                'options': [
                    defaultStepOptions.lensOptions.cr39LensOption,
                    defaultStepOptions.lensOptions.polyLensOption,
                    defaultStepOptions.lensOptions.trivexLensOptions,
                    defaultStepOptions.lensOptions.hi167LensOption,
                    defaultStepOptions.lensOptions.hi174LensOption,
                ]
            }
        }
    ]
}
