var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "plans-list" },
    _vm._l(_vm.currentPlanInfo, function(plan, index) {
      return _c("li", { key: index, class: plan.id }, [
        typeof plan.thumbnail === "string"
          ? _c("div", { staticClass: "plan-info-container" }, [
              _c(
                "div",
                {
                  staticClass: "thumb",
                  on: {
                    click: function($event) {
                      return _vm.setActiveIndex(index)
                    }
                  }
                },
                [
                  typeof plan.thumbnail === "string"
                    ? _c("img", { attrs: { src: plan.thumbnail } })
                    : _vm._l(plan.thumbnail, function(thumbnail, thumbIndex) {
                        return _c("img", {
                          key: "thumbnail-" + thumbIndex,
                          class: plan.id + "-" + thumbIndex,
                          attrs: { src: thumbnail }
                        })
                      })
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c("h3", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: plan.title,
                      expression: "plan.title"
                    }
                  ],
                  staticClass: "tile",
                  class: {
                    "has-description":
                      plan.description ||
                      plan.contactsInfo(_vm.eyeData.left, _vm.eyeData.right),
                    active: _vm.activeIndex === index
                  },
                  on: {
                    click: function($event) {
                      return _vm.setActiveIndex(index)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeIndex === index,
                        expression: "activeIndex === index"
                      }
                    ],
                    staticClass: "dual-description-container"
                  },
                  [
                    plan.description
                      ? _c("div", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: plan.description,
                              expression: "plan.description"
                            }
                          ],
                          staticClass: "description"
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    plan.helpBubble
                      ? _c("HelpBubble", {
                          attrs: {
                            data: plan.helpBubbleParams,
                            "target-classes":
                              plan.helpBubbleParams.targetClasses
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    plan.contactsInfo(_vm.eyeData.left, _vm.eyeData.right)
                      ? _c("div", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: plan.contactsInfo(
                                _vm.eyeData.left,
                                _vm.eyeData.right
                              ),
                              expression:
                                "plan.contactsInfo(eyeData.left, eyeData.right)"
                            }
                          ],
                          staticClass: "description"
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "plan-info-container dual-options top-option" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "thumb",
                      on: {
                        click: function($event) {
                          return _vm.setActiveIndex(index)
                        }
                      }
                    },
                    [
                      _c("img", {
                        class: plan.id + "-0}",
                        attrs: { src: plan.thumbnail[0] }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _c("h3", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: plan.title[0],
                          expression: "plan.title[0]"
                        }
                      ],
                      staticClass: "tile",
                      class: {
                        "has-description": plan.description,
                        active: _vm.activeIndex === index
                      },
                      on: {
                        click: function($event) {
                          return _vm.setActiveIndex(index)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeIndex === index,
                            expression: "activeIndex === index"
                          }
                        ],
                        staticClass: "dual-description-container"
                      },
                      [
                        plan.description[0] !== ""
                          ? _c("div", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: plan.description[0],
                                  expression: "plan.description[0]"
                                }
                              ],
                              staticClass: "description"
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        plan.helpBubble
                          ? _c(
                              "span",
                              { staticClass: "description" },
                              [
                                _vm._v(
                                  "\n                            Frames from "
                                ),
                                _c("HelpBubble", {
                                  attrs: {
                                    data: plan.helpBubbleParams,
                                    "target-classes":
                                      plan.helpBubbleParams.targetClasses,
                                    placement: "bottom"
                                  }
                                }),
                                _vm._v(
                                  " available at extra charge.\n                            "
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c("i", [
                                  _vm._v(
                                    "* High-index (thin), Transitions, Sunglass lens and other options available for extra charge"
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._m(0, true),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "plan-info-container dual-options bottom-option"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "thumb",
                      on: {
                        click: function($event) {
                          return _vm.setActiveIndex(index)
                        }
                      }
                    },
                    [
                      _c("img", {
                        class: plan.id + "-1}",
                        attrs: { src: plan.thumbnail[1] }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _c("h3", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: plan.title[1],
                          expression: "plan.title[1]"
                        }
                      ],
                      staticClass: "tile second-option",
                      class: {
                        "has-description": plan.description,
                        active: _vm.activeIndex === index
                      },
                      on: {
                        click: function($event) {
                          return _vm.setActiveIndex(index)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeIndex === index,
                            expression: "activeIndex === index"
                          }
                        ],
                        staticClass: "dual-description-container"
                      },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: plan.description[1],
                              expression: "plan.description[1]"
                            }
                          ],
                          staticClass: "description"
                        })
                      ]
                    )
                  ])
                ]
              )
            ])
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [_c("p", [_vm._v("-or-")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }