import Vue from 'vue'

import getShippingService from '../../../../../app/utils/shipping-service'
import _size from 'lodash/size'
import _cloneDeep from 'lodash/cloneDeep'

export default {
    namespaced: true,
    state: () => ({
        data: {},
        shippingService: {}
    }),
    getters: {
        data: (state, getters, rootState, rootGetters) => {
            if (!_size(state.data)) {
                Vue.set(state, 'data', Object.assign({
                    address_type: '',
                    city: '',
                    company: null,
                    country_id: '',
                    customer_address_id: '',
                    customer_id: '',
                    email: '',
                    entity_id: '',
                    fax: null,
                    firstname: '',
                    html: '',
                    lastname: '',
                    middlename: null,
                    parent_id: '',
                    postcode: '',
                    prefix: null,
                    quote_address_id: null,
                    region: '',
                    region_id: '',
                    regions: [],
                    street: '',
                    street_1: '',
                    street_2: '',
                    suffix: null,
                    telephone: '',
                    vat_id: null,
                    vat_is_valid: null,
                    vat_request_date: null,
                    vat_request_id: null,
                    vat_request_success: null
                }, _cloneDeep(rootGetters.rootState.data)))
            }
            return state.data
        },
        regionsOptions: (state, getters) => getters.data.regions,
        shippingService: (state, getters, rootState, rootGetters) => {
            if (!_size(state.shippingService)) {
                state.shippingService = getShippingService({base_url: rootGetters['storeView/base_url']})
            }
            return state.shippingService
        }
    }
}
