import * as abstract from '../../../storage/abstract'

export const state = () => ({
    pre_selectRx: []
})

export const getters = {
    getPreSelectValue: state => state.pre_selectRx
}

export const mutations = {
    ...abstract.mutations,
    setPreSelectValue (state, data) {
        state.pre_selectRx = data
    }
}

export const actions = {
    fetchDefaultData: ({commit, rootGetters}) => {
        commit('UPDATE_STATE_DATA', rootGetters.rootState || {})
    }
}

export default () => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions
})
