export const COLOR_SECTION_INTERFACE = {
    checkout_value: '',
    colors: [],
    component: '',
    filter: [],
    has_match_color: false,
    id: '',
    label: ''
}

export const HELPER_COLORS = {
    'match-color': {
        'name': 'help-bubble',
        'params': {
            'text': 'Additional +$10 dollars. <br><p style="font-style: italic">*We are unable to match mirror coatings on lenses</p>'
        }
    }
}
