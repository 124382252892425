<template functional>
    <div
        :ref="data.ref"
        :class="[data.class, data.staticClass, {'loader__component-loading': props.loading}, 'loader__component sf-loader']"
        :style="[data.style, data.staticStyle]"
        v-bind="data.attrs"
        v-on="listeners"
    >
        <transition name="sf-fade" mode="out-in">
            <!--@slot Slot for the actual content being loaded -->
            <slot v-if="!props.loading"></slot>
            <div v-else class="loader__component-overlay sf-loader__overlay">
                <!--@slot Use this slot to replace the loader -->
                <slot name="loader">
                    <svg style="width: 36px; height: 8px;" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin=".1" /></circle><circle cx="18" cy="4" r="4"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin=".2" /></circle><circle cx="32" cy="4" r="4"><animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin=".3" /></circle></svg>
                </slot>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'BlockLoader',
    props: {
        /**
         * Shows the loader on top of the existing content
         */
        loading: {
            default: true,
            type: Boolean
        },
        data: {
            type: Object,
            default: () => {}
        }
    }
}
</script>
