var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadingProducts
        ? _c("div", { staticClass: "loading-filters" })
        : _vm._e(),
      _vm._v(" "),
      _vm.listTitle
        ? _c(
            "div",
            {
              staticClass: "filter-tab",
              class: { active: _vm.show },
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              !_vm.isMobile
                ? _c("span", { staticClass: "list-title" }, [
                    _vm._v(_vm._s(_vm.listTitle) + ":")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isMobile
                ? _c("span", { staticClass: "list-title" }, [
                    _vm._v(_vm._s(_vm.listTitle))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isMobile
                ? _c(
                    "span",
                    { staticClass: "reset-filters", on: { click: _vm.reset } },
                    [_vm._v("Reset Filters")]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile
        ? _c("div", { staticClass: "filter-tab" }, [
            _c("ul", { staticClass: "category-frames-grid" }, [
              _c("li", [
                _c(
                  "div",
                  { staticClass: "custom-select" },
                  [
                    _c("v-select", {
                      staticClass: "frame-dropdown",
                      attrs: {
                        items: _vm.sortCategoriesAlphabetical,
                        "item-text": "name",
                        "item-value": "url_key",
                        multiple: "",
                        label: "Brand",
                        "menu-props": {
                          closeOnContentClick: true
                        }
                      },
                      on: {
                        change: function($event) {
                          return _vm.changedValue("category")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var data = ref.data
                              var index = ref.index
                              return [
                                _vm.filter.category.length === 1
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.getSelectedName(
                                              _vm.sortCategoriesAlphabetical,
                                              _vm.filter.category
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ])
                                  : _vm.filter.category.length > 1 && index < 1
                                  ? _c("div", [_vm._v("2 or more    ")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2896002470
                      ),
                      model: {
                        value: _vm.filter.category,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "category", $$v)
                        },
                        expression: "filter.category"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "div",
                  { staticClass: "custom-select" },
                  [
                    _c("v-select", {
                      staticClass: "frame-dropdown",
                      attrs: {
                        items: _vm.filtersSize,
                        "item-text": "name",
                        "item-value": "value",
                        multiple: "",
                        label: "Frame Size",
                        "menu-props": {
                          closeOnContentClick: true
                        }
                      },
                      on: {
                        change: function($event) {
                          return _vm.changedValue("frame_size")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var data = ref.data
                              var index = ref.index
                              return [
                                _vm.filter.frame_size.length === 1
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.getSelectedName(
                                              _vm.filtersSize,
                                              _vm.filter.frame_size
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ])
                                  : _vm.filter.frame_size.length > 1 &&
                                    index < 1
                                  ? _c("div", [_vm._v("2 or more    ")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3989724075
                      ),
                      model: {
                        value: _vm.filter.frame_size,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "frame_size", $$v)
                        },
                        expression: "filter.frame_size"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "div",
                  { staticClass: "custom-select" },
                  [
                    _c("v-select", {
                      staticClass: "frame-dropdown",
                      attrs: {
                        items: _vm.filtersMaterial,
                        "item-text": "name",
                        "item-value": "value",
                        multiple: "",
                        label: "Material",
                        "menu-props": {
                          closeOnContentClick: true
                        }
                      },
                      on: {
                        change: function($event) {
                          return _vm.changedValue("material")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var data = ref.data
                              var index = ref.index
                              return [
                                _vm.filter.material.length === 1
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.getSelectedName(
                                              _vm.filtersMaterial,
                                              _vm.filter.material
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ])
                                  : _vm.filter.material.length > 1 && index < 1
                                  ? _c("div", [_vm._v("2 or more    ")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3217437321
                      ),
                      model: {
                        value: _vm.filter.material,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "material", $$v)
                        },
                        expression: "filter.material"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "div",
                  { staticClass: "custom-select reset-filters-container" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "reset-filters",
                        on: { click: _vm.reset }
                      },
                      [_vm._v("Reset Filters")]
                    )
                  ]
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.isMobile && _vm.show
          ? _c("div", { staticClass: "filter-form" }, [
              _c("div", { staticClass: "filter-list brands-list" }, [
                _c("h5", [_vm._v("Brands")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.sortCategoriesAlphabetical, function(cat) {
                    return _c("li", { key: "category-" + cat.url_key }, [
                      _c("label", { attrs: { for: cat.url_key } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.category,
                              expression: "filter.category"
                            }
                          ],
                          attrs: { id: cat.url_key, type: "checkbox" },
                          domProps: {
                            value: cat.url_key,
                            checked: Array.isArray(_vm.filter.category)
                              ? _vm._i(_vm.filter.category, cat.url_key) > -1
                              : _vm.filter.category
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.filter.category,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = cat.url_key,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.filter,
                                        "category",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.filter,
                                        "category",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.filter, "category", $$c)
                                }
                              },
                              function($event) {
                                return _vm.changedValue("category")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(cat.name))])
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-list frame-size-list" }, [
                _c("h5", [_vm._v("Frame Size")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.filtersSize, function(size) {
                    return _c("li", { key: "frame_size-" + size.value }, [
                      _c("label", { attrs: { for: size.value } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.frame_size,
                              expression: "filter.frame_size"
                            }
                          ],
                          attrs: { id: size.value, type: "checkbox" },
                          domProps: {
                            value: size.value,
                            checked: Array.isArray(_vm.filter.frame_size)
                              ? _vm._i(_vm.filter.frame_size, size.value) > -1
                              : _vm.filter.frame_size
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.filter.frame_size,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = size.value,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.filter,
                                        "frame_size",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.filter,
                                        "frame_size",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.filter, "frame_size", $$c)
                                }
                              },
                              function($event) {
                                return _vm.changedValue("frame_size")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(size.name))])
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-list material-list" }, [
                _c("h5", [_vm._v("Material")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.filtersMaterial, function(mat) {
                    return _c("li", { key: "material-" + mat.value }, [
                      _c("label", { attrs: { for: mat.value } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filter.material,
                              expression: "filter.material"
                            }
                          ],
                          attrs: { id: mat.value, type: "checkbox" },
                          domProps: {
                            value: mat.value,
                            checked: Array.isArray(_vm.filter.material)
                              ? _vm._i(_vm.filter.material, mat.value) > -1
                              : _vm.filter.material
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.filter.material,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = mat.value,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.filter,
                                        "material",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.filter,
                                        "material",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.filter, "material", $$c)
                                }
                              },
                              function($event) {
                                return _vm.changedValue("material")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(mat.name))])
                      ])
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }