<template>
    <ContactsSalesOrderEyeFormData v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import ContactsSalesOrderEyeFormData from '../../components/contacts/sales/order/eye/FormData.vue'

export default {
    name: 'RouterContactsSalesOrderEyeFormData',
    components: {
        ContactsSalesOrderEyeFormData
    },
    extends: App
}
</script>
