import AntireflectiveOption from '../antireflective/Option.vue'

export default {
    name: 'UpgradesOption',
    extends: AntireflectiveOption,
    methods: {
        optionSelect () {
            return this.$parent.addUpgradesToCart(this.option)
        }
    }
}
