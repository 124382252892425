var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.renderer
    ? _c("div", [
        _c("span", {
          directives: [
            {
              name: "dompurify-html",
              rawName: "v-dompurify-html",
              value: _vm.renderer,
              expression: "renderer"
            }
          ],
          staticClass: "option-value"
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }