<template>
    <div class="frames-breadcrumbs">
        <div class="breadcrumbs">
            <ul>
                <li class="frames">
                    <a :href="base_url + 'glasses-frames'" title="Go to Frame Shop">Frame Shop</a>
                    <span> &gt; </span>
                </li>
                <li v-for="(crumb, index) in currentCategory.breadcrumb" :key="index">
                    <strong v-if="crumb.link === ''">{{ label(crumb.label) }}</strong>
                    <span v-else>
                        <a :href="crumb.link" :title="crumb.label">{{ label(crumb.label) }}</a>
                        <span> &gt; </span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
    name: 'Breadcrumb',
    computed: {
        ...mapState('partner', ['currentCategory']),
        ...mapGetters('partner', ['getParentCategory']),
        ...mapGetters('storeView', ['base_url'])
    },
    methods : {
        label (crumb) {
            return crumb === this.getParentCategory.name ? crumb : crumb.replace(this.getParentCategory.name, '').trim()
        }
    }
}
</script>
