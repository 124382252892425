<template>
    <div class="swatch-attribute color">
        <div v-if="attributeTitle" class="swatch-attribute-title" v-text="attributeTitle"></div>
        <ul class="swatch-attribute-options">
            <template v-for="(item, index) in colors">
                <li :key="item.color" class="swatch-option color">
                    <span
                        :style="getBackgroundImage(getColorName(item.color))"
                        :class="{[optionItemClass]: optionItemClass, [getColorName(item.color)]: item.color, 'selected': index === checkedIndex}"
                        :title="item.color"
                        @click="$emit(EVENT_CHANGE_CURRENT_COLOR, index)"
                    >{{ item.color }}</span>
                    <p v-if="addLabel" style="color: #989898;font-size: 12px;">{{ getName(item.color) }}</p>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
    EVENT_CHANGE_CURRENT_COLOR
} from '../../../../app/utils/constants'

export default {
    name: 'ColorSwatches',
    props: {
        colors: {
            type: [Object, Array],
            default: () => {
            }
        },
        checkedIndex: {
            type: [String, Number],
            default: ''
        },
        attributeTitle: {
            type: String,
            default: ''
        },
        optionItemClass: {
            type: String,
            default: ''
        },
        addLabel: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            name: '',
            EVENT_CHANGE_CURRENT_COLOR: EVENT_CHANGE_CURRENT_COLOR
        }
    },
    computed: {
        ...mapGetters('storeView', ['base_url']),

    },
    methods: {
        getName (itemName) {
            const name = itemName.split(' ').last()
            return name
        },
        getBackgroundImage (color) {
            const urlImage = this.base_url + 'js/lensconfigurator/assets/' + color + '.jpg'

            return {
                'background-image': "url('" + urlImage + "')",
                'background-size' : 'contain'
            }
        },
        getColorName (color) {
            let colorName = color.toLowerCase()
            colorName = colorName.split(' ').join('-')
            return colorName
        }
    }
}
</script>
