var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.length > 0
    ? _c(
        "div",
        { staticClass: "subscription-order-container" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.subscriptions, function(subscription) {
            return [
              _c(
                "div",
                {
                  key: "subscription-" + subscription.uuid,
                  staticClass: "subscription-order-contents"
                },
                [
                  _c("div", { staticClass: "subscription-order-row" }, [
                    _c(
                      "div",
                      { staticClass: "subscription-order-cell--mobile" },
                      [_vm._v("Subscription")]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      subscription.products.left.product_name
                        ? _c("span", [
                            _vm._v(
                              _vm._s(subscription.products.left.product_name)
                            )
                          ])
                        : _vm._e(),
                      subscription.products.right.product_name
                        ? _c("span", [_vm._v(",")])
                        : _vm._e(),
                      _vm._v(" "),
                      subscription.products.right.product_name
                        ? _c("span", [
                            _vm._v(
                              _vm._s(subscription.products.right.product_name)
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "subscription-order-row" }, [
                    _c(
                      "div",
                      { staticClass: "subscription-order-cell--mobile" },
                      [_vm._v("Next Order Date")]
                    ),
                    _c("div", [_vm._v(_vm._s(subscription.period_ends_at))])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "subscription-order-row" }, [
                    _c(
                      "div",
                      { staticClass: "subscription-order-cell--mobile" },
                      [_vm._v("Who is it for?")]
                    ),
                    _c("div", [_vm._v(_vm._s(subscription.who_is_this_for))])
                  ]),
                  _vm._v(" "),
                  _vm._m(1, true),
                  _vm._v(" "),
                  _c("div", { staticClass: "subscription-order-row" }, [
                    _c("div", [
                      _c("span", { staticClass: "subscription-order-button" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/contact-lenses/subscription/view/id/" +
                                subscription.uuid
                            }
                          },
                          [_vm._v("Edit")]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          })
        ],
        2
      )
    : _c(
        "div",
        {
          staticClass: "subscription-order-container subscription-order-empty"
        },
        [
          _c("p", { staticClass: "subscription-order-empty--text" }, [
            _vm._v("You currently have no subscriptions")
          ]),
          _vm._v(" "),
          _vm._m(2)
        ]
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subscription-order-header" }, [
      _c("div", { staticClass: "subscription-order-cell" }, [
        _vm._v("Subscription")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "subscription-order-cell" }, [
        _vm._v("Next Order Date")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "subscription-order-cell" }, [
        _vm._v("Who is it for?")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "subscription-order-cell" }, [
        _vm._v("Quantity")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "subscription-order-cell" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subscription-order-row" }, [
      _c("div", { staticClass: "subscription-order-cell--mobile" }, [
        _vm._v("Quantity")
      ]),
      _c("div", [_vm._v(" Month Supply")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "/shop-contacts/brands", alt: "shop contacts" } },
      [
        _c("div", { staticClass: "button button--copy-icon account" }, [
          _c("div", { staticClass: "button__copy" }, [_vm._v("Shop contacts")])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }