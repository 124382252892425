var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "employee-signup" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.getLoad
        ? _c("PageLoader", { attrs: { load: _vm.getLoad } })
        : _c("div", { staticClass: "lensabl-plus-content company-details" }, [
            _c("h2", { staticClass: "title" }, [_vm._v("Enter Your Details")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "company-address",
                attrs: { id: "company_address_fields" }
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "hor-scroll" }, [
                    _c(
                      "table",
                      {
                        staticClass: "form-list",
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" }
                      },
                      [
                        _c("tbody", [
                          _c(
                            "tr",
                            [
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[0],
                                  error: _vm.errors[_vm.employee[0].name],
                                  colspan: "2",
                                  value: _vm.values[_vm.employee[0].name]
                                },
                                on: {
                                  blur: _vm.validBlurField,
                                  update: function($event) {
                                    _vm.values[_vm.employee[0].name] = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "tr",
                            [
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[1],
                                  error: _vm.errors[_vm.employee[1].name],
                                  value: _vm.values[_vm.employee[1].name]
                                },
                                on: {
                                  blur: _vm.validBlurField,
                                  update: function($event) {
                                    _vm.values[_vm.employee[1].name] = $event
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "employee-" + _vm.employee[2].name
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.employee[2].label) +
                                          ":\n                                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("date-picker", {
                                    staticClass: "input-text",
                                    class: {
                                      "required-entry":
                                        _vm.employee[2].required,
                                      "validation-failed":
                                        _vm.errors[_vm.employee[2].name]
                                    },
                                    attrs: {
                                      placeholder: _vm.employee[2].placeholder,
                                      "value-type": "format",
                                      type: "text",
                                      format: "MM-DD-YYYY"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.validBlurField(
                                          _vm.employee[2].name
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.values[_vm.employee[2].name],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.values,
                                          _vm.employee[2].name,
                                          $$v
                                        )
                                      },
                                      expression: "values[employee[2].name]"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors[_vm.employee[2].name]
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "validation-advice",
                                          attrs: {
                                            id:
                                              "advice-required-entry-" +
                                              _vm.employee[2].name
                                          }
                                        },
                                        [_vm._v("This is a required field.")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "tr",
                            [
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[3],
                                  error: _vm.errors[_vm.employee[3].name],
                                  colspan: "2",
                                  value: _vm.values[_vm.employee[3].name],
                                  maxlength: _vm.employee.length
                                },
                                on: {
                                  blur: _vm.validBlurField,
                                  update: function($event) {
                                    _vm.values[_vm.employee[3].name] = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "tr",
                            [
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[4],
                                  error: _vm.errors[_vm.employee[4].name],
                                  colspan: "2",
                                  value: _vm.values[_vm.employee[4].name]
                                },
                                on: {
                                  blur: _vm.validBlurField,
                                  update: function($event) {
                                    _vm.values[_vm.employee[4].name] = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "tr",
                            [
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[5],
                                  colspan: "2",
                                  value: _vm.values[_vm.employee[5]]
                                },
                                on: {
                                  update: function($event) {
                                    _vm.values[_vm.employee[5]] = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "table",
                      {
                        staticClass: "form-list",
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" }
                      },
                      [
                        _c("colgroup", [
                          _c("col", { attrs: { width: "60%" } }),
                          _vm._v(" "),
                          _c("col", { attrs: { width: "60%" } })
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c(
                            "tr",
                            [
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[6],
                                  error: _vm.errors[_vm.employee[6].name],
                                  value: _vm.values[_vm.employee[6].name]
                                },
                                on: {
                                  update: function($event) {
                                    _vm.values[_vm.employee[6].name] = $event
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[7],
                                  error: _vm.errors[_vm.employee[7].name],
                                  value: _vm.values[_vm.employee[7].name]
                                },
                                on: {
                                  blur: _vm.validBlurField,
                                  update: function($event) {
                                    _vm.values[_vm.employee[7].name] = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "tr",
                            [
                              _c("td", [
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "employee-" + _vm.employee[8].name
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(_vm.employee[8].label) +
                                        ":\n                                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.values[_vm.employee[8].name],
                                        expression: "values[employee[8].name]"
                                      }
                                    ],
                                    staticClass: "select",
                                    class: {
                                      "required-entry":
                                        _vm.employee[8].required,
                                      "validation-failed":
                                        _vm.errors[_vm.employee[8].name]
                                    },
                                    attrs: {
                                      id: "employee_" + _vm.employee[8].name
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.values,
                                            _vm.employee[8].name,
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          return _vm.validBlurField(
                                            _vm.employee[8].name
                                          )
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _vm._l(_vm.region_data, function(region) {
                                      return [
                                        _c("option", {
                                          key: "option-" + region.value,
                                          domProps: {
                                            value: region.value,
                                            textContent: _vm._s(region.label)
                                          }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.errors[_vm.employee[8].name]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "validation-advice",
                                        attrs: {
                                          id:
                                            "advice-required-entry-" +
                                            _vm.employee[8].name
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        This is a required field.\n                                    "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[9],
                                  error: _vm.errors[_vm.employee[9].name],
                                  value: _vm.values[_vm.employee[9].name]
                                },
                                on: {
                                  blur: _vm.validBlurField,
                                  update: function($event) {
                                    _vm.values[_vm.employee[9].name] = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "table",
                      {
                        staticClass: "form-list",
                        staticStyle: { width: "100%" },
                        attrs: { cellspacing: "0" }
                      },
                      [
                        _c("tbody", [
                          _c(
                            "tr",
                            [
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[10],
                                  error: _vm.errors[_vm.employee[10].name],
                                  value: _vm.values[_vm.employee[10].name]
                                },
                                on: {
                                  blur: _vm.validBlurField,
                                  update: function($event) {
                                    _vm.values[_vm.employee[10].name] = $event
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("Input", {
                                attrs: {
                                  employee: _vm.employee[11],
                                  error: _vm.errors[_vm.employee[11].name],
                                  value: _vm.values[_vm.employee[11].name]
                                },
                                on: {
                                  blur: _vm.validBlurField,
                                  update: function($event) {
                                    _vm.values[_vm.employee[11].name] = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "actions-toolbar" }, [
              _c(
                "div",
                { staticClass: "primary" },
                [
                  _c("BaseButton", {
                    staticClass: "button--copy-solid-white-blue button--small",
                    attrs: { text: "Sign up" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.continueNxt($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.errorMessage
                ? _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(_vm.errorMessage))
                  ])
                : _vm._e()
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "lensabl-plus-nav signup-nav" }, [
      _c("div", { staticClass: "nav-container" }, [
        _c(
          "a",
          {
            staticClass: "nav__logo",
            attrs: { href: "/", "aria-label": "Link to the homepage" }
          },
          [
            _c("img", {
              staticClass: "plus-logo",
              attrs: {
                src: "/js/lensconfigurator/assets/lensabl-plus-wordmark.svg"
              }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }