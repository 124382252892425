var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          ref: _vm.data.ref,
          class: [
            _vm.data.class,
            _vm.data.staticClass,
            { "loader__component-loading": _vm.props.loading },
            "loader__component sf-loader"
          ],
          style: [_vm.data.style, _vm.data.staticStyle]
        },
        "div",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _c(
        "transition",
        { attrs: { name: "sf-fade", mode: "out-in" } },
        [
          !_vm.props.loading
            ? _vm._t("default")
            : _c(
                "div",
                { staticClass: "loader__component-overlay sf-loader__overlay" },
                [
                  _vm._t("loader", [
                    _c(
                      "svg",
                      {
                        staticStyle: { width: "36px", height: "8px" },
                        attrs: { xmlns: "http://www.w3.org/2000/svg" }
                      },
                      [
                        _c("circle", { attrs: { cx: "4", cy: "4", r: "4" } }, [
                          _c("animate", {
                            attrs: {
                              attributeName: "opacity",
                              dur: "1s",
                              values: "0;1;0",
                              repeatCount: "indefinite",
                              begin: ".1"
                            }
                          })
                        ]),
                        _c("circle", { attrs: { cx: "18", cy: "4", r: "4" } }, [
                          _c("animate", {
                            attrs: {
                              attributeName: "opacity",
                              dur: "1s",
                              values: "0;1;0",
                              repeatCount: "indefinite",
                              begin: ".2"
                            }
                          })
                        ]),
                        _c("circle", { attrs: { cx: "32", cy: "4", r: "4" } }, [
                          _c("animate", {
                            attrs: {
                              attributeName: "opacity",
                              dur: "1s",
                              values: "0;1;0",
                              repeatCount: "indefinite",
                              begin: ".3"
                            }
                          })
                        ])
                      ]
                    )
                  ])
                ],
                2
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }