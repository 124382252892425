<template>
    <component :is="name" :step="step" :place="place" :class="name" />
</template>

<script>
import {
    PLACE_STEP_HEADER
} from '../../app/utils/constants'

import RendererOptions from './options/RendererOptions.vue'
import RendererAntireflective from './antireflective/RendererAntireflective.vue'
import RendererNotes from './notes/RendererNotes'
import RendererColor from './color/RendererColor'
import RendererBox from './box/RendererBox'
import RendererFrames from './frames/RendererFrames'
import RendererFramesColor from './frames_color/RendererFramesColor'
import RendererFramesValue from './frames_value/RendererFramesValue'
import RendererPrescriptionName from './prescription_name/RendererPrescriptionName'
import RendererReadablePrescription from './readable_prescription/RendererReadablePrescription.vue'

import {mapGetters} from 'vuex'

export default {
    name: 'Renderer',
    components: {
        RendererOptions,
        RendererAntireflective,
        RendererNotes,
        RendererColor,
        RendererBox,
        RendererFrames,
        RendererFramesColor,
        RendererFramesValue,
        RendererPrescriptionName,
        RendererReadablePrescription
    },
    props: {
        place: {
            type: String,
            default: PLACE_STEP_HEADER
        },
        name: {
            type: String,
            default: '',
            required: true
        },
        id: {
            type: String,
            default: '',
            required: true
        }
    },
    computed: {
        ...mapGetters('configurator/steps', ['getStepObject']),
        step () {
            return this.getStepObject(this.id)
        }
    }
}
</script>
