<template>
    <div>
        <div v-show="productView.productInfoShow" :class="{'product-layout-2columns': isDesktop}"
             class="product-details-holder lensabl-frames-view frames-wrapper">
            <div class="product media">
                <Gallery :class="{'vertical-thumbs': isDesktop}"/>
            </div>
            <div class="product-info-main">
                <div class="page-title-wrapper product">
                    <h1 v-dompurify-html="product_name === 'Mick And Keith Midnight Ramble' ? 'Mick & Keith Midnight Ramble' : product_name" :class="product_name" class="page-title" itemprop="name"></h1>
                </div>
                <p v-dompurify-html="productDescription"></p>
                <AdditionalAttributes :class="{'inline': !isDesktop && !isMobile, 'grid': isMobile}" :data-attributes="additionalAttributes" />

                <ProductAffirmInfo :startingTitle="`Starting from $`" />
                <p class="ae-time-text">Lens production takes 4-7 business days on average from the date you've submitted all required Rx information. All lenses go through a detailed quality inspection before shipping out. If any issues are found during inspection, we will remake the lenses until they are perfect (this may cause slight delays in shipping time)</p>
                <template v-if="colors.length">
                    <div v-if="isAvailable" class="product-options-bottom">
                        <div class="actionsproductCategoryName">
                            <BaseButton :text="'Choose Your Lenses'"
                                        @click.native="isMobile ? mobileSwitcher() : goToConfigurator()">
                                Select Lenses
                            </BaseButton>
                        </div>
                    </div>
                    <p v-if="!isAvailable" class="unavailable stock" title="Availability">
                        <span style="color:red">This item is sold out</span>
                    </p>
                </template>
            </div>
        </div>
        <div class="product-border-divider"></div>
        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator />
        </div>
        <ReturnsInfo v-if="productView.productInfoShow"/>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import CatalogProductBlockView from "../CatalogProductBlockView.vue";

export default {
    name: 'AeProductBlockView',
    extends: CatalogProductBlockView,
    methods: {
        goToConfigurator () {
            this.activateFirstVisibleStep();
            this.scrollToStepHead(this.visibleFirstStep.id);
        }
    },
    computed: {
        additionalAttributes () {
            return [
                // {
                //     label: 'Size',
                //     value: this.getCurrentProductAttribute('size')
                // },
                {
                    label: 'Material',
                    value: this.getCurrentProductAttribute('material')
                }
            ]
        },
        productDescription(){
            let desc;
            if (!this.hasSimpleDescription){
                desc = this.product_description
            }else{
                desc = this.currentImage.description
            }
           return this.formattedProductDescription(desc)
        }
    }
}
</script>

<style scoped>
.ae-time-text {
    font-size: 13px;
    font-style: italic;
    padding: 0px;
}
</style>