import _map from 'lodash/map'
import _cloneDeep from 'lodash/cloneDeep'
import {
    CONTACTS_RX_KEYS_INTERFACE
} from '../../../storage/contacts'
import * as abstract from '../../../../../storage/abstract'

export const CONTACTS_SUBSCRIPTIONS_FREQUENCIES = {
    'monthly6' : {
        'frequency': '6 months',
        'lens-type': 'Monthly',
        'amount': {
            '12-month': 2,
            '6-month': 1
        }
    },
    'dailies30' : {
        'frequency': '30 days',
        'lens-type': 'Daily',
        'amount': {
            '12-month': 12,
            '6-month': 6,
            '3-month': 3
        }
    },
    'dailies90' : {
        'frequency':'90 days',
        'lens-type': 'Daily',
        'amount': {
            '12-month': 4,
            '6-month': 2,
            '3-month': 1
        }
    },
    'biweekly6' : {
        'frequency':'90 days',
        'lens-type': 'Bi-Weekly',
        'amount': {
            '12-month': 4,
            '6-month': 2,
            '3-month': 1
        }
    },
    'biweekly24' : {
        'frequency': '6 months',
        'lens-type': 'Bi-Weekly',
        'amount': {
            '12-month': 2,
            '6-month': 1
        }
    }
}

let stateWasReset = false

const CONTACTS_EYE_INTERFACE = {
    ...CONTACTS_RX_KEYS_INTERFACE,
    color: '',
    rx_expiration_date: '',
    dropdownValues: {
        ...CONTACTS_RX_KEYS_INTERFACE,
        color: ''
    }
}

export const state = () => ({
    ...CONTACTS_RX_KEYS_INTERFACE,
    color: false,
    contacts_lens_type: '',
    default_box_quantity: '',
    default_sub_box_quantity: '',
    description: '',
    images: [],
    lens_number: '',
    manufacturer: '',
    material: '',
    package_details: '',
    color_options: '',
    price: '',
    product_id: '',
    product_name: '',
    product_sku: '',
    second_name: '',
    size: false,
    title: false,
    water_content: '',
    subscription_percentage: '',
    rebate_discount: '',
    rebate_number: '',
    rebate_expiration: '',
    plan_code: '',
    enable_subscription: '',
    frequency: _cloneDeep(CONTACTS_SUBSCRIPTIONS_FREQUENCIES),
    eyeData: {
        left: _cloneDeep(CONTACTS_EYE_INTERFACE),
        right: _cloneDeep(CONTACTS_EYE_INTERFACE)
    }
})

export const getters = {
    productView: state => state,
    deliveryFrequency: state => state.frequency.hasOwnProperty(state.plan_code) ? state.frequency[state.plan_code] : {}
}

export const mutations = {
    ...abstract.mutations,
    setProductInfoShow: (state, show = true) => state.productInfoShow = show,
    setProductConfiguratorShow: (state, show = true) => state.productConfiguratorShow = show,
    setCurrentImageIndex: (state, index = 0) => state.currentImageIndex = index,
    setCheckedIndex: (state, index = 0) => state.checkedIndex = index,

    RESET_CONTACTS_PRODUCT_STATE: (state, newState) => {
        _map(_cloneDeep(newState), (value, key) => {
            state[key] = value
        })
        stateWasReset = true
    }
}

export const actions = {
    fetchDefaultData: ({commit, rootGetters}) => {
        commit('UPDATE_STATE_DATA', rootGetters.rootState || {})
    },
    resetContactsProductState: ({commit, rootGetters}) => commit('RESET_CONTACTS_PRODUCT_STATE', rootGetters.rootState),
    fetchContactsProductState: ({getters, dispatch}) => {
        if (!stateWasReset) {
            dispatch('resetContactsProductState')
        }
        return getters.productView
    }
}

export default () => ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
