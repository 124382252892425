<template>
    <div class="lenses-seo-container">
        <div class="lenses-seo-prescription-container lenses-seo-section">
            <div class="lenses-seo-row lenses-seo-prescription">
                <div class="lenses-seo-text lenses-seo-prescription-text">
                    <h2>New Prescription Lenses<br />for Your Frames</h2>
                    <p>Like so many eyeglass wearers, you count on your glasses to improve your vision. But accidents happen, lenses break or get scratched, and your vision needs change over time. When it's time to replace your lenses, Lensabl makes the process easier than ever.<br /><br />Lensabl offers glasses lens replacements you can order online from the comfort of your home. Our process is the most convenient way to receive replacement prescription lenses anywhere in the United States and Canada. Count on Lensabl for fast, affordable prescription, non-prescription or <a href="/prescription-sunglass-lenses" title="Prescription Sunglass Lenses"><span class="seo-ourlenses-link">sunglass lens replacement</span></a>. Rates start at $77, so order lenses online from Lensabl today.</p>
                </div>
                <div class="lenses-seo-img"></div>
            </div>
        </div>
        <div class="lenses-seo-replacement-container lenses-seo-section">
            <div class="lenses-seo-row lenses-seo-replacement">
                <div class="lenses-seo-text lenses-seo-replacement-text">
                    <h2>How Lens Replacement Works</h2>
                    <p>The Lensabl eyeglass lens replacement process is the easiest way to swap your old lenses with brand new ones tailored to your vision needs. Here's how it works:</p>
                </div>
                <div class="lenses-seo-replacement-numbers-list">
                    <div class="lenses-seo-replacement-list-item">
                        <div class="lenses-seo-number">1</div>
                        <div class="lenses-seo-number-text"><span>Browse our site:</span><br />Visit our lens options page to see a full list of the lenses and lens coatings we carry.</div>
                    </div>
                    <div class="lenses-seo-replacement-list-item">
                        <div class="lenses-seo-number">2</div>
                        <div class="lenses-seo-number-text"><span>Select your lenses:</span><br />Choose the lens matching your prescription, frame design or stylistic preference.</div>
                    </div>
                    <div class="lenses-seo-replacement-list-item">
                        <div class="lenses-seo-number">3</div>
                        <div class="lenses-seo-number-text"><span>Mail us your frames:</span><br />You'll receive a free shipping label and pre-paid box to send your frames to our Los Angeles offices safely.</div>
                    </div>
                    <div class="lenses-seo-replacement-list-item">
                        <div class="lenses-seo-number">4</div>
                        <div class="lenses-seo-number-text"><span>Trust our experts:</span><br />When we receive your frames, our lens experts will swap your old lenses with the new ones.</div>
                    </div>
                    <div class="lenses-seo-replacement-list-item">
                        <div class="lenses-seo-number">5</div>
                        <div class="lenses-seo-number-text"><span>Receive your new lenses:</span><br />Your glasses will arrive at your door in about two weeks after our facility has received them.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="lenses-seo-lrtypes-container lenses-seo-section">
            <div class="lenses-seo-row seo-lrtypes">
                <div class="lenses-seo-lrtypes-img">
                </div>
                <div class="lenses-seo-text lenses-seo-lrtypes-text">
                    <h2>Types of Lenses We Replace</h2>
                    <p>
                        Lensabl makes online lens replacement accessible for any eyeglass wearer.<br />Our <a href="/our-lenses" title="Our Lenses"><span class="seo-ourlenses-link">lens options</span></a> include:
                    </p>
                    <ul class="lenses-seo-lrtypes-list">
                        <li><a href="/single-vision-lenses" title="Single Vision"><span class="seo-ourlenses-link">Single Vision</span></a></li>
                        <li><a href="/digital-hd-progressive-lenses" title="Digital HD Progressive"><span class="seo-ourlenses-link">Digital HD Progressive</span></a></li>
                        <li><a href="/computer-blue-light-blocking-lenses" title="Blue-light Blocking Computer Lenses"><span class="seo-ourlenses-link">Blue-light Blocking Computer Lenses</span></a></li>
                        <li><a href="/transitions-photochromic" title="Transitions® Light Intelligent Lenses™"><span class="seo-ourlenses-link">Transitions® Light Intelligent Lenses™</span></a></li>
                        <li><a href="/high-index-lenses" title="High Index Lenses"><span class="seo-ourlenses-link">High Index Lenses</span></a></li>
                        <li><a href="/bifocal-lenses" title="Bifocal Lenses"><span class="seo-ourlenses-link">Bifocal Lenses</span></a></li>
                        <li><a href="/reading-lenses" title="Reading Lenses"><span class="seo-ourlenses-link">Reading Lenses</span></a></li>
                        <li>Non-Rx and Rx Sunglasses</li>
                        <li>Rx and Non-Rx Polarized Sunglasses</li>
                        <li>Sunglass Lenses</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="lenses-seo-eyetest-container lenses-seo-section">
            <div class="lenses-seo-row seo-eyetest">
                <div class="lenses-seo-text seo-eyetest-text">
                    <h2>Get Quality Lenses at Lensabl</h2>
                    <p>Lensabl makes the process as simple, affordable and as fast as possible, so <a href="/choose-my-lenses" title="Choose My Lenses"><span class="seo-contact-link">order your replacement lenses today</span></a>!</p>
                </div>
                <a href="/choose-my-lenses" title="Choose My Lenses">
                    <div class="button button--copy-solid">
                        <div class="button__container">GET MY LENSES</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LensReplacementSeo'
}
</script>
