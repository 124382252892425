<template>
    <EmployeeSignup v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import EmployeeSignup from '../../components/lensablplus/EmployeeSignup.vue'
export default {
    name: 'RouterEmployeeSignup',
    components: {
        EmployeeSignup
    },
    extends: App
}
</script>

<style scoped>

</style>
