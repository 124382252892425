<template>
    <table v-if="memberShipEdited" cellspacing="0" class="form-list">
        <tbody>
            <tr>
                <td class="label"><label for="group_id">Group</label></td>
                <td class="value">
                    <select id="group_id" v-model="customerData.group_id" :disabled="customer.customerExists" class="required-entry select">
                        <template v-for="option in groupsData">
                            <option :key="option.value" v-dompurify-html="option.label" :value="option.value"></option>
                        </template>
                    </select>
                </td>
            </tr>
            <tr>
                <td class="label"><label for="email">Email</label></td>
                <td class="value">
                    <v-text-field id="email" v-model="customerData.email" :rules="emailRules" :class="{'validation-failed': validateField('email')}" class="validate-email input-text default" />
                    <div v-if="validateField('email')" :id="`advice-required-entry-${'email'}`" class="validation-advice" style="">This is a required field.</div>

                    <div v-if="customer.isPlusCustomer" class="error-text-rx">
                        <span v-text="isPlusCustomerErrorText"></span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'
import _cloneDeep from 'lodash/cloneDeep'
import _map from 'lodash/map'
import _size from 'lodash/size'
import {
    EVENT_SET_CUSTOMER_EXISTS,
    DEFAULT_CUSTOMER_DATA
} from '../../storage/lensablplus/membership'

export default {
    name: 'LensabalplusMembershipFormAccount',
    mixins: [validationMixin],
    data: () => ({
        isPlusCustomerErrorText: 'This account already has one Lensabl+ membership.',
        ajaxLoad: false,
        emailValidatePattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        customerData: {
            email: '',
            group_id: 1
        },
        email: '',
        group_id: ''
    }),
    validations: {
        customerData: {
            email: {
                required,
                email
            }
        }
    },
    computed: {
        ...mapState('lensablPlus/membership', ['accountFormData', 'customer']),
        ...mapGetters('lensablPlus/membership', ['memberShipEdited', 'getCustomerData']),
        validate () {
            return this.customer.validate
        },
        emailRules () {
            return [
                v => this.emailValidatePattern.test(v) || !this.validate || this.email === '' || 'E-mail must be valid'
            ]
        },
        groupsData () {
            const data = []
            _map(_size(this.accountFormData.groups_id) ? this.accountFormData.groups_id : {}, (label, value) => {
                if (value) {
                    data.push({label, value})
                }
            })
            return data
        }
    },
    watch: {
        customerData: {
            handler (data) {
                if (!this.$v.customerData.email.$invalid && ((this.customer.email !== data.email) || (this.customer.group_id !== data.group_id))) {
                    const intervalID = setInterval(() => {
                        this.fetchCustomerData(data.email).then(() => {
                            const customerData = this.getCustomerData(data.email)
                            const formData = {
                                order: {
                                    account: {
                                        email: data.email,
                                        group_id: customerData.customerExists ? customerData.data.group_id : data.group_id
                                    }
                                },
                                customer_id: customerData.customerExists ? customerData.data.entity_id : null
                            }
                            this.$store.commit('lensablPlus/membership/MEMBER_SHIP_FORM_DATA', formData)

                            this.$store.dispatch('lensablPlus/membership/updateQuote', formData)
                        })
                        clearInterval(intervalID)
                    }, 500)
                }
            },
            deep: true
        },
        customer: {
            handler (customerData) {
                this.$nextTick(() => {
                    if (!this.customerData.group_id) {
                        this.customerData.group_id = customerData.customerExists ? customerData.data.group_id : customerData.group_id
                    }
                    if (!this.customerData.email) {
                        this.customerData.email = customerData.email
                    }
                })
            },
            deep: true
        }
    },
    beforeCreate () {

    },
    created () {
        if (!this.accountFormData.email) {
            this.$store.dispatch('lensablPlus/membership/fetchAccountFormData').then(() => {
                if (!this.customerData.email) {
                    this.customerData.email = this.accountFormData.email
                    this.fetchCustomerData(this.customerData.email)
                }
            })
        }
        if (!this.customerData.email) {
            this.customerData.email = this.customer.email
            this.fetchCustomerData(this.customerData.email)
        }
    },
    methods: {
        fetchCustomerData (email = '') {
            if (email && (email.length > 0) && this.emailValidatePattern.test(email)) {
                return this.$store.dispatch('lensablPlus/membership/fetchCustomerData', email).then(() => {
                    const data = this.getCustomerData(email)
                    if (!data.customerExists) {
                        _map(_cloneDeep(DEFAULT_CUSTOMER_DATA), (value, key) => {
                            if (!data.hasOwnProperty(key)) {
                                data[key] = value
                            }
                        })
                    }
                    this.$store.commit('lensablPlus/membership/CUSTOMER', data)
                    this.$bus.$emit(EVENT_SET_CUSTOMER_EXISTS, data.customerExists)
                })
            }
        },
        validateField (id) {
            if (this.customer.validate) {
                return this.$v.customerData[id].$invalid
            }
            return false
        }
    }
}
</script>
