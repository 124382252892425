<template>
    <LensabalplusMembershipCreate v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import LensabalplusMembershipCreate from '../../components/lensablplus/membership/Create.vue'

export default {
    name: 'RouterLensabalplusMembershipCreate',
    components: {
        LensabalplusMembershipCreate
    },
    extends: App
}
</script>
