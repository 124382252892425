import Vue from 'vue'
import _filter from 'lodash/filter'

let optionsService = null

class OptionsService {
    constructor ({state, getters}) {
        this.saveServer = getters.optionsSaveService
        this.registry = state.registry
    }

    setValueOptions (index, value) {
        const oldValue = this.registry[index] ? this.registry[index] : {}
        const newValue = Object.assign(oldValue, value)

        Vue.set(this.registry, index, newValue)
    }

    deleteValueOptions (index, name) {
        if (this.registry[index].hasOwnProperty(name)) {
            delete this.registry[index][name]
        }
    }

    getValueOptions (index) {
        return this.registry[index]
    }

    saveOptions (index, data) {
        const optionsData = data
        const values = this.getValueOptions(index)
        if (!values) {
            return false
        }

        const newOptions = {}
        const uploadedData = {}
        let sendToServer = null
        const entriesData = Object.entries(values)

        _filter(entriesData, ([key, value]) => {
            if (key.indexOf('upload') !== -1) {
                Object.assign(uploadedData, {...value}, {...data})
            } else {
                if (key !== 'separate_pd' && (value && (Number.isInteger(value) || value.trim().length > 0))) {
                    if (['od_axis', 'os_axis', 'prescription_type'].indexOf(key) !== -1 && Object.keys(newOptions).length) {
                        newOptions[key] = value
                    } else if (key === 'segmentation_height' || key === 'pd_in_rx') {
                        newOptions[key] = value
                    } else if (key !== 'prescription_type') {
                        if (key === 'od_pd' || key === 'os_pd') {
                            if (values['separate_pd'] && values['separate_pd'] === true) {
                                if (value !== 0) {
                                    newOptions[key] = value
                                }
                            }
                        } else if (key === 'pd') {
                            if (values['separate_pd'] === false) {
                                if (value !== 0) {
                                    newOptions[key] = value
                                }
                            }
                        } else {
                            newOptions[key] = value
                        }
                    }
                }
            }
        })

        if (Object.keys(newOptions).length) {
            optionsData.options = newOptions
            sendToServer = this.saveServer.saveOptions(optionsData)
        }

        if (Object.keys(uploadedData).length) {
            sendToServer = this.saveServer.uploadImage(uploadedData)
        }

        return sendToServer
    }
}

export default function getOptionsService (options = {}) {
    if (!optionsService) {
        optionsService = new OptionsService(options)
    }
    return optionsService
}
