<template>
    <RxQR v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import RxQR from '../../components/rxreader/RxQR.vue'

export default {
    name: 'RouterRxReader',
    components: {
        RxQR
    },
    extends: App
}
</script>
