<template>
    <div>
        <div v-if="framesPartnerPage">
            <div class="totals-price-subtotal total-line subtotal">
                <span class="total-label label">Lenses</span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ currencySymbol }}</span>
                    <span class="total-value-amount">{{ subTotalAmountLenses }}</span>
                </span>
            </div>
            <div class="totals-price-subtotal total-line subtotal">
                <span class="total-label label">Frames</span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ currencySymbol }}</span>
                    <span class="total-value-amount">{{ subTotalAmountFrames }}</span>
                </span>
            </div>
        </div>
        <div v-else>
            <div class="totals-price-subtotal total-line" style="padding-top: 1em">
                <span class="total-label lplus-label">Lenses</span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ currencySymbol }}</span>
                    <span class="total-value-amount">{{ totalAmount }}</span>
                </span>
            </div>
        </div>
        <div v-if="isRedeemFlow" class="totals-price-subtotal total-line plus-benefit">
            <span class="total-label lplus-label">Lensabl+ Benefit</span>
            <span class="total-value value">
                <span class="total-value-currency">{{ getSignRedeemDiscount + currencySymbol }}</span>
                <span class="total-value-amount">{{ redeemDiscount }}</span>
            </span>
        </div>
        <div v-if="getPercentage('lens') && !isMemberBenefitGreater" class="totals-price-subtotal total-line plus-benefit-discount">
            <span class="total-label lplus-label">Lensabl+ Discount ({{ getPercentage('lens') }}%)</span>
            <span class="total-value value">
                <span class="total-value-currency">-{{ currencySymbol }}</span>
                <span class="total-value-amount">{{ totalRedeemSummary }}</span>
            </span>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import summary from './storage/summary'
export default {
    name: 'RedeemTotals',
    computed: {
        ...mapGetters('values', ['values', 'checkoutData', 'framesPartnerPage', 'currencySymbol']),
        ...mapGetters('configurator', ['isSpectacles']),
        ...mapGetters('summary', [
            'subTotalAmountLenses',
            'subTotalAmountFrames',
            'totalAmount',
            'totalSummary',
            'showFramesPrice',
            'discountAmount',
            'framesPrice',
            'totalSubscribedSummary',
            'subTotalAmountFrames',
            'subTotalAmountLenses',
            'totalRedeemSummary',
            'redeemDiscount']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow', 'getPercentage']),
        ...mapGetters('membershipProgram', ['isMemberBenefitGreater']),
        getSignRedeemDiscount () {
            return this.redeemDiscount > 0 ? '-' : ''
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['summary/']) {
            this.$store.registerModule('summary', summary(this.$store))
        }
        this.$store.dispatch('summary/fetchDefaultData')
    },
    methods: {
        ...mapMutations('values', ['setDataToValues'])
    }
}
</script>

<style scoped>

</style>
