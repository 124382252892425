var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.productCategoryName },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.productView.productInfoShow,
              expression: "productView.productInfoShow"
            }
          ],
          staticClass:
            "product-configurator-configuration product-details-holder row lensabl-wrapper frames-wrapper bose-container"
        },
        [
          _c(
            "div",
            { staticClass: "category-left" },
            [
              _c("div", { staticClass: "bose-header mobile" }, [
                _c("h1", { staticClass: "product-name-mobile" }, [
                  _c("span", [_vm._v(_vm._s(_vm.productName))])
                ])
              ]),
              _vm._v(" "),
              _c("Gallery", { class: { "vertical-thumbs": _vm.isDesktop } })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "look-info category-left" }, [
            _c("div", { staticClass: "product-name" }, [
              _c("div", { staticClass: "bose-header" }, [
                _c("h1", { staticClass: "product-name-desktop" }, [
                  _c("span", [_vm._v(_vm._s(_vm.productName))])
                ])
              ]),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.productDescription,
                    expression: "productDescription"
                  }
                ],
                staticClass: "bose-description"
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "lensabl-colors instructions" }, [
              _c(
                "div",
                { staticClass: "swatch-opt hyper-x" },
                [
                  _vm.product_name.toLowerCase() === "duo bells" ||
                  _vm.product_name.toLowerCase() === "duo lennox"
                    ? _c("div", { staticClass: "hobie-additional" }, [
                        _c("p", { staticClass: "hobie-clipons" }, [
                          _vm._v(
                            "Select more than one color below to add-on additional clip-ons ($40 each):"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ColorSwatches", {
                    attrs: {
                      colors: _vm.colors,
                      "checked-index": parseInt(_vm.checkedIndex),
                      "option-item-class": "decorate bigger img",
                      "data-swatch-product": _vm.product_name
                    },
                    on: _vm._d({}, [
                      _vm.EVENT_CHANGE_CURRENT_COLOR,
                      _vm.changeCurrentColor
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "hobie-additional" },
                [
                  _c("AdditionalAttributes", {
                    staticClass: "inline",
                    attrs: { "data-attributes": _vm.additionalAttributes }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.instructionHtml,
                    expression: "instructionHtml"
                  }
                ],
                staticClass: "p-instruction"
              }),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.affirmHtml,
                    expression: "affirmHtml"
                  }
                ],
                staticClass: "affirm-shop-frames"
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "accrue-frames-section",
                  style: { "margin-bottom": "0.5rem", "font-size": "15px" }
                },
                [_vm._m(0)]
              ),
              _vm._v(" "),
              _vm.isAvailable && !_vm.isDesktop
                ? _c("div", { staticClass: "product-options-bottom" }, [
                    _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: { text: "Choose Your Lenses" },
                            nativeOn: {
                              click: function($event) {
                                _vm.isMobile
                                  ? _vm.mobileSwitcher()
                                  : _vm.goToConfigurator()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            SELECT LENSES\n                        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isAvailable
                ? _c("div", { staticClass: "look-params" }, [_vm._m(1)])
                : _vm._e()
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("AnagramAdBar"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAvailable && _vm.productView.productConfiguratorShow,
              expression: "isAvailable && productView.productConfiguratorShow"
            }
          ],
          staticClass: "product-configurator-wrapper"
        },
        [_c("Configurator")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("or save up and earn "),
      _c("span", { staticClass: "accrue-percentage" }, [_vm._v("15%")]),
      _vm._v(" in cash rewards with "),
      _c("span", [
        _c("img", {
          staticClass: "accrue-logo-black-frames-img",
          attrs: { src: "/media/wysiwyg/accrue-logo-black.png" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "look-item" }, [
      _c(
        "span",
        { staticClass: "look-item-info", staticStyle: { color: "red" } },
        [_vm._v(" This item is sold out")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }