<template>
    <div>
        <EyePrescription :eye="currentEye" :edit-data="editData" />

        <div class="f-right">
            <button title="Send Order" type="button" class="scalable save">
                <span><span><span>Send Order</span></span></span>
            </button>

            <button title="Verify" type="button" class="scalable disabled" disabled="disabled">
                <span><span><span>Verify</span></span></span>
            </button>

            <button title="Edit" type="button" class="scalable" @click="saveRxData">
                <span><span><span v-text="editData ? `Save` : `Edit`"></span></span></span>
            </button>
        </div>

        <div v-if="currentEye !== 'left'" class="actions-toolbar">
            <DateRange :value="eyeData.rx_expiration_date" label="Rx Expiration Date:" class="flex" @save="saveRxExpirationDate" />
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import _extend from 'lodash/extend'
import productStorage from '../../../product/storage/product/view'
import salesStorage from '../../../../sales/order/storage/modules/sales'
import prescriptions from '../../../../prescriptions/storage/prescriptions'

import EyePrescription from './Prescription.vue'
import DateRange from '../../../../base/DateRange.vue'

import { setComputedData } from '../../../../../app/utils/buildData'
export default {
    name: 'ContactsSalesOrderEyeFormData',
    components: {
        EyePrescription,
        DateRange
    },
    data: () => {
        return {
            editData: false
        }
    },
    computed: {
        ...mapGetters('contacts', ['getEyeData']),
        ...mapGetters('contacts/productView', ['productView']),
        currentEye () {
            return this.$root.index
        },
        eyeData () {
            return this.productView.eyeData[this.currentEye]
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['contacts/productView/']) {
            this.$store.registerModule(['contacts', 'productView'], productStorage(this.$store), { preserveState: false })
        }

        if (!this.$store._modulesNamespaceMap['sales/']) {
            this.$store.registerModule('sales', salesStorage(this.$store))
        }

        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }

        // when routing is broken by multiple apps and rootstate has incorrect app data
        if(this.$root.configData) {
            setComputedData(this.$store.getters.rootState, this.$root.configData)
        }

        this.$store.dispatch('contacts/fetchDefaultData')
        this.$store.dispatch('contacts/productView/fetchDefaultData')
        this.$store.dispatch('sales/order/items/fetchDefaultData')
    },
    created () {
        this.$store.commit('contacts/SET_EYE_DATA', this.setEyeData(this.currentEye))
    },
    methods: {
        ...mapActions('sales/order/items', ['saveRxItemData']),
        saveRxData () {
            if (this.editData) {
                this.save()
            } else {
                this.editData = true
            }
        },
        saveRxExpirationDate (newDate) {
            if (new Date(newDate).getDate()) {
                this.save({rx_expiration_date: newDate})
            }
        },
        save (options = {}) {
            this.saveRxItemData({
                item_id: this.$root.item_id,
                options: {
                    ..._extend({}, this.getEyeData[this.currentEye].rx, {'contacts color' : this.getEyeData[this.currentEye].color}, options)
                }
            }).then(({status}) => {
                if (status === 200) {
                    this.editData = false
                }
            }).catch(error => {
                console.error(error)
            })
        },
        setEyeData (eye) {
            return {
                eye: eye,
                color: this.eyeData.color,
                rx_dropdown: {
                    bc: this.eyeData.bc,
                    dia: this.eyeData.dia,
                    power: this.eyeData.power,
                    cyl: this.eyeData.cyl,
                    axis: this.eyeData.axis,
                    add: this.eyeData.add
                },
                rx: {
                    bc: this.eyeData.bc,
                    dia: this.eyeData.dia,
                    power: this.eyeData.power,
                    cyl: this.eyeData.cyl,
                    axis: this.eyeData.axis,
                    add: this.eyeData.add
                }
            }
        },
        toFixedRxData (value) {
            if (value) {
                value = value.toFixed(2)
                value = value > 0 ? `+${value}` : value
            }
            return value
        }
    }
}
</script>

<style scoped>
    .actions-toolbar {
        clear: both;
        margin-top: .25rem;
        padding: .25rem;
    }
    .flex {
        display: flex;
    }
</style>
