<template>
    <v-text-field
        v-model="formModel[formKey]"
        :rules="[isValid]"
        :label="label"
    >
    </v-text-field>
</template>
<script>
export default {
    name: 'TextField',
    props: {
        formModel: {
            type: Object,
            default: () => {}
        },
        formKey: {
            type: String,
            default: 'memberId'
        },
        label: {
            type: String,
            default: 'Member ID or last four of SSN'
        },
        errText: {
            type: String,
            default: 'Member ID Required'
        },
        rules: {
            type: Function,
            default: (v) => {
                return  !!v
            }
        }
    },
    computed: {
       isValid(){
           const fieldValue = this.formModel[this.formKey]
           return this.rules(fieldValue) || this.errText
       }
    },
}
</script>
