import {setComputedArrayData} from '../../../../../app/utils/buildData'
import {
    LENS_OPTION_CODE_167,
    LENS_OPTION_CODE_174, LENS_OPTION_CODE_CR39, LENS_OPTION_CODE_POLY,
    LENS_OPTION_CODE_TRIVEX,
    LENS_OPTIONS_CODE
} from '../lens_options'

import {LIMITERS_DEFAULT_PPE} from './ppe_step_list'
import {LIMITERS_DEFAULT_RHEOS} from './rheos_step_list'
import {LIMITERS_DEFAULT_LESPECS} from './lespecs_step_list'
import {LIMITERS_DEFAULT_BOSE} from './bose_step_list'
import {LIMITERS_DEFAULT_OPTICAL} from './optical_step_list'
import {LIMITERS_DEFAULT_NIGHT} from './night_step_list'
import {SHOP_FRAMES_BRANDS} from '../../../../../app/utils/constants'
import {LIMITERS_DEFAULT_PARTNER} from './partner_step_list'
import {LIMITERS_DEFAULT_GAMERADVANTAGE} from './gameradvantage_step_list'
import {LIMITERS_DEFAULT_HOBIESUNGLASSES} from './hobie_sunglasses_step_list'
import {LIMITERS_DEFAULT_KIAURA} from './kiaura_step_list'
import {LIMITERS_JAMES_ORO} from "./jamesoro_step_list";
import {LIMITERS_GOODR} from "./goodr_step_list";
import {LENS_TYPE_ID, LENS_TYPE_ID_COMPUTER_LENSES, LENS_TYPE_ID_SUNGLASSES_LENSES, LENS_TYPE_ID_TRANSITIONS_LENSES} from "../../../../../app/common/constants/lens_type";
import {LIMITERS_DEFAULT_SUN} from "./sun_step_list";


export const LIMITERS_DEFAULT = {
    options_disable: {
        bi_focal: {
            [LENS_OPTIONS_CODE]: [
                LENS_OPTION_CODE_TRIVEX
            ]
        },
        progressive_standard:{
            color: {
                'grey-polarized': {
                    [LENS_OPTIONS_CODE]: [
                        LENS_OPTION_CODE_167
                    ]
                }
            },
            [LENS_TYPE_ID]: {
                [LENS_TYPE_ID_COMPUTER_LENSES]: {
                    [LENS_OPTIONS_CODE]: [
                        LENS_OPTION_CODE_TRIVEX,
                        LENS_OPTION_CODE_174,
                        LENS_OPTION_CODE_CR39
                    ]
                },
                [LENS_TYPE_ID_TRANSITIONS_LENSES]:{
                    [LENS_OPTIONS_CODE]: [
                        LENS_OPTION_CODE_TRIVEX,
                        LENS_OPTION_CODE_174,
                    ]
                }
            }
        },
        progressive_premium:{
            [LENS_TYPE_ID]: {
                [LENS_TYPE_ID_COMPUTER_LENSES]: {
                    [LENS_OPTIONS_CODE]: [
                        LENS_OPTION_CODE_174,
                        LENS_OPTION_CODE_CR39
                    ]
                },
            }
        }
    },
    lens_options_enable_by_lens_type: {
        progressive_standard: {
            'Sun Polarized': [LENS_OPTION_CODE_POLY]
        },
        progressive_premium: {
            'Sun Polarized': [
                LENS_OPTION_CODE_POLY,
                LENS_OPTION_CODE_167,
                LENS_OPTION_CODE_TRIVEX,
            ],
            'Sun Polarized Gradient':[
                LENS_OPTION_CODE_POLY,
            ]
        }
    },
}


const LIMITERS = {
    disabled_colors: {
        single_vision_reading_premium: {
            'Transition XTRActive': ['blue', 'gold', 'green', 'pink', 'red', 'silver', 'brown', 'graphite-green']
        },
        single_vision_distance_premium: {
            'Transition XTRActive': ['blue', 'gold', 'green', 'pink', 'red', 'silver', 'brown', 'graphite-green']
        },
        progressive_standard: {
            'Transition XTRActive': ['graphite-green'],
            'Sun Polarized': ['polarized-gradient-brown', 'polarized-gradient-grey', 'polarized-g15']
        },
        progressive_premium: {
            'Transition XTRActive': ['graphite-green']
        },
        bi_focal: {
            'Transition Non-Polarized': ['gen-8-amber', 'gen-8-amethyst', 'gen-8-sapphire', 'gen-8-emerald', 'gen-8-green'],
            'Sun Polarized': ['polarized-gradient-brown', 'polarized-gradient-grey']
        }
    },
}

export function getLimiters(param = {}) {
    let limiters = {}
    let {frames_name} = param
    frames_name = frames_name || ''

    switch (frames_name) {
        case 'personal protective eyewear':
            limiters = {...LIMITERS_DEFAULT_PPE}
            break
        case 'rheos':
            limiters = {...LIMITERS_DEFAULT_RHEOS}
            break
        case 'le-specs':
            limiters = {...LIMITERS_DEFAULT_LESPECS}
            break
        case 'bose':
            limiters = {...LIMITERS_DEFAULT_BOSE}
            break
        case 'optical':
            limiters = {...LIMITERS_DEFAULT_OPTICAL}
            break
        case 'night':
            limiters = {...LIMITERS_DEFAULT_NIGHT}
            break
        case 'sun':
            limiters = {...LIMITERS_DEFAULT_SUN}
            break
        case 'gamer_advantage':
            limiters = {...LIMITERS_DEFAULT_GAMERADVANTAGE}
            break
        case 'hobie_sunglasses':
            limiters = {...LIMITERS_DEFAULT_HOBIESUNGLASSES}
            break
        case 'kiaura':
            limiters = {...LIMITERS_DEFAULT_KIAURA}
            break
        default:
            if (SHOP_FRAMES_BRANDS.includes(frames_name)) {
                limiters = {...LIMITERS_DEFAULT_PARTNER}
            } else {
                limiters = {...LIMITERS_DEFAULT}
            }
            break
    }
    if (frames_name.indexOf('James Oro') !== -1){
        limiters = {...LIMITERS_JAMES_ORO}
    }
    if (frames_name.indexOf('goodr') !== -1){
        limiters = {...LIMITERS_GOODR}
    }
    return setComputedArrayData(LIMITERS, limiters)
}
