<template>
    <div>
        <div v-show="productView.productInfoShow" :class="{'product-layout-2columns': isDesktop}"
             class="product-details-holder lensabl-frames-view frames-wrapper">
            <div class="product media">
                <Gallery :class="{'vertical-thumbs': isDesktop}"/>
            </div>
            <div class="product-info-main">
                <div class="page-title-wrapper product">
                    <h1 v-dompurify-html="fullProductName" :class="product_name" class="page-title" itemprop="name"></h1>
                </div>
                <p style="font-size: 16px;padding: 0;margin: 1rem 0;" v-dompurify-html="product_description"></p>
                <AdditionalAttributes :class="{'inline': !isDesktop && !isMobile, 'grid': isMobile}" :data-attributes="additionalAttributes"/>

                <p v-if="isAvailable"style="font-weight: 600; font-size: 1.0rem" title="Backorder">
                    Due to high demand for this SKU, fulfillment times may vary directly from the manufacturer. Please note that any orders placed may experience delays. 
                    However, if RX lenses are ordered, rest assured that we will begin processing your lenses as soon as you provide all required RX information to minimize any potential wait time.
                </p>

                <ProductAffirmInfo :startingTitle="`Starting from $`"/>
                <template v-if="colors.length">
                    <div class="swatch-opt">
                        <ColorSwatches
                            :colors="colors"
                            :checked-index="checkedIndex"
                            :option-item-class="'decorate bigger img'"
                            :product-name="product_name.toLowerCase()"
                            @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColorAndReset"
                        />
                    </div>
                    <p v-if="!isAvailable" class="unavailable stock" title="Availability">
                        <span style="color:red">This item is sold out</span>
                    </p>
                    <p style="font-size: 12px;font-style: italic;padding: 0;" class="time-disclaimer">
                        Lens production takes 4-7 business days on average from the date you've submitted all required Rx information. All lenses go through a detailed quality inspection before
                        shipping out. If any issues are found during inspection, we will remake the lenses until they are perfect (this may cause slight delays in shipping time)
                    </p>
                    <div v-if="isAvailable && isMobile" class="product-options-bottom">
                        <div class="actionsproductCategoryName">
                            <BaseButton :text="'Choose Your Lenses'"
                                        @click.native="mobileSwitcher()">
                                Select Lenses
                            </BaseButton>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <AnagramAdBar />
        <ImageHelpBubble v-if="!plusMember" :ad-data="getBannerText" />
        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator :show-progress-bar="isMobile ? true : false" :custom-summary="true"/>
        </div>
        <ReturnsInfo v-if="productView.productInfoShow"/>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CatalogProductBlockView from "../CatalogProductBlockView.vue";
import AnagramAdBar from "../../../anagram/AnagramAdBar.vue";
import ImageHelpBubble from "../../../help_bubble/ImageHelpBubble.vue";
import {TEXT_PLUS_BANNER} from "../lensabl/constants/banners";

export default {
    name: 'RayBanMetaCatalogProductBlockView',
    components: {ImageHelpBubble, AnagramAdBar},
    extends: CatalogProductBlockView,
    computed: {
        ...mapGetters('lensablplus_customer', ['plusMember']),
        getBannerText(){
            return TEXT_PLUS_BANNER.shop_frames
        },
        fullProductName() {
            return this.productCategoryName + ' ' + this.product_name
        },
        additionalAttributes() {
            return [
                {
                    label: 'Size',
                    value: this.getCurrentProductAttribute('size'),
                    component_help: this.size_help,
                    help_placement: this.isDesktop ? 'left-center' : 'top'
                },
            ]
        }
    }
}
</script>
