<template>
    <div v-if="stepsCount" class="configuration-sequence">
        <template v-for="step in visibleList">
            <Step :id="step.id" :key="step.id" :index="step.index" :class="[step.id, step.stepState]" class="product-configurator__steps-item" @next-step="nextStep" @previous-step="previousStep" />
        </template>
        <slot></slot>
    </div>
</template>
<script>
import _last from 'lodash/last'
import _first from 'lodash/first'
import _map from 'lodash/map'

import {
    EVENT_STEP_NEXT,
    EVENT_STEP_PREVIOUS,
    EVENT_STEP_EDIT,
    EVENT_STEP_UPDATE,
    EVENT_SWITCH_STEP,
    EVENT_CONFIGURATOR_ACTIVATE_FIRST,
    STATE_READY
} from '../../app/utils/constants'

import {mapGetters, mapState, mapActions} from 'vuex'
import prescriptions from '../prescriptions/storage/prescriptions'
import configurator from './storage/configurator'
import steps from './storage/configurator/steps'
import ResetService from '../../app/mixins/reset-service'
import Step from './Step.vue'

export default {
    name: 'Steps',
    components: {
        Step
    },
    mixins: [ResetService],
    computed: {
        ...mapState('configurator/steps', ['animationLock']),
        ...mapGetters('configurator/steps', ['stepsCount', 'visibleList', 'stepList', 'getStepObject', 'getLeftAndRightStepIds']),
        ...mapGetters('deviceProperty', ['isMobileOnly'])
    },
    beforeCreate: function () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['configurator/']) {
            this.$store.registerModule('configurator', configurator(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['configurator/steps/']) {
            this.$store.registerModule(['configurator', 'steps'], steps(this.$store))
        }

        this.$store.dispatch('configurator/steps/fetchDefaultData')

        this.$store.dispatch('configurator/steps/updateVisibleList')
    },
    created () {
        this.$bus.$on(EVENT_SWITCH_STEP, this.switchStep)
        this.$bus.$on(EVENT_STEP_NEXT, this.nextStep)
        this.$bus.$on(EVENT_STEP_PREVIOUS, this.previousStep)
        this.$bus.$on(EVENT_STEP_EDIT, this.editStep)
        this.$bus.$on(EVENT_CONFIGURATOR_ACTIVATE_FIRST, this.editStep)

        this._activateFirst()
    },
    beforeDestroy () {
        this.$bus.$off(EVENT_SWITCH_STEP, this.switchStep)
        this.$bus.$off(EVENT_STEP_NEXT, this.nextStep)
        this.$bus.$off(EVENT_STEP_PREVIOUS, this.previousStep)
        this.$bus.$off(EVENT_STEP_EDIT, this.editStep)
    },
    methods: {
        ...mapActions('configurator/steps', ['setStepState', 'activateStep', 'scrollToStepHead', 'setAnimationLock', 'updateVisibleList', 'stepContinuePromise', 'stepEdit']),
        previousStep (step) {
            const [lefts] = this.getLeftAndRightStepIds(step.id)
            const prevStepId = _last(lefts)
            if (prevStepId) {
                const prevStep = this.getStepObject(prevStepId)
                this.$bus.$emit(EVENT_SWITCH_STEP, prevStep)

                this.$nextTick(() => {
                    this.scrollToStepHead(prevStep.id)
                })
            }
        },
        nextStep (step) {
            this.stepContinuePromise(step)
                .then(
                    () => {
                        const [, rights] = this.getLeftAndRightStepIds(step.id)
                        const nextStepId = _first(rights)

                        if (nextStepId) {
                            const nextStep = this.getStepObject(nextStepId) || {}
                            this.$bus.$emit(EVENT_SWITCH_STEP, nextStep)

                            this.$nextTick(() => {
                                this.scrollToStepHead(nextStep.id)
                                if (process.env.NODE_ENV !== 'production') {
                                    console.log('CurrentStep - ' + nextStep.id, nextStep)
                                }
                            })
                        } else {
                            this.setStepState({stepState: STATE_READY, list: [step.id]})
                            this.$bus.$emit(EVENT_STEP_UPDATE, step.id)
                        }
                    },
                    e => console.log(e)
                ).finally(() => this.updateStepData(step, {nextStepLoading: false}))
        },
        switchStep (step) {
            this.activateStep(step.id)
            this.$bus.$emit(EVENT_STEP_UPDATE, step.id)
            const [lefts, rights] = this.getLeftAndRightStepIds(step.id, true)
            _map(lefts, id => this.$bus.$emit(EVENT_STEP_UPDATE, id))
            _map(rights, id => this.$bus.$emit(EVENT_STEP_UPDATE, id))

            this.$store.commit('setValidation', false)
        },
        updateStepData (step, data = {}) {
            this.$store.dispatch('configurator/steps/updateStep', {
                id: step.id,
                data
            })
        },
        editStep (step) {
            this.stepEdit(step).then(() => this.$bus.$emit(EVENT_SWITCH_STEP, step))
        }
    }
}
</script>
