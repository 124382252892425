<template>
    <InsuranceAnagram v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import InsuranceAnagram from '../../components/insurances/InsuranceAnagram.vue'

export default {
    name: 'RouterInsuranceAnagram',
    components: {
        InsuranceAnagram
    },
    extends: App
}
</script>
