<template>
    <Account v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import Account from '../../components/membership/Account.vue'
export default {
    name: 'RouterMembershipAccount',
    components: {
        Account
    },
    extends: App
}
</script>

<style scoped>

</style>
