<template>
    <div class="loader-2 center">
        <span></span>
    </div>
</template>

<script>

export default {
    name: 'Loader'
}
</script>
