<template>
    <div class="custom-select">
        <label v-if="labelTitle" :for="id">{{ labelTitle }}</label>
        <select
            :id="id"
            :ref="refName"
            :value="value"
            :title="title"
            :disabled="disabled"
            :error="error"
            :class="{'required-entry': required, 'validation-failed': validationFailed}"
            @change="change"
        >
            <template v-for="option in options">
                <option :key="option.value" v-dompurify-html="option.label" :value="option.value" :selected="currentOption.value === option.value"></option>
            </template>
        </select>
        <slot></slot>
    </div>
</template>

<script>
import _find from 'lodash/find'
import _first from 'lodash/first'

export default {
    name: 'CustomSelect',
    props: {
        showLabel: {
            type: Boolean,
            default: false
        },
        labelTitle: {
            type: [Boolean, String],
            default: false
        },
        title: {
            type: [Boolean, String],
            default: false
        },
        id: {
            type: [Boolean, String],
            default: false
        },
        value: {
            type: [Boolean, String, Number],
            default: false
        },
        options: {
            type: [Array, Object],
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        validationFailed: {
            type: [Boolean, String, Array, Object],
            default: () => {}
        },
        refName: {
            type: [Boolean, String],
            default: false
        }
    },
    data () {
        return {
            currentValue: this.value
        }
    },
    computed: {
        currentOption () {
            return _find(this.options, {'value': this.value}) || _first(this.options)
        }
    },
    methods: {
        change (e) {
            this.$emit('change', e)
        }
    }
}
</script>
