<template>
    <div class="prescription-type form-access__header" style="margin: 2rem">
        <header class="prescription-type-header">
            <h3>Which state do you live in?</h3>
        </header>
        <div class="state-container">
            <v-select
                v-model="region"
                :items="REGIONS"
                item-text="label"
                item-value="value"
                outlined
            />
            <p v-if="$v.region.$error" class="help is-danger">Choose a region</p>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],
    validations: {
        region: {
            required
        }
    },
    props: {
        currentStep: {
            type: [Array, Object],
            default: () => {}
        }
    },
    data () {
        return {
            region: ''
        }
    },
    computed: {
        ...mapGetters('onlineExam', ['REGIONS', 'REGION', 'LOADING'])
    },
    watch: {
        $v: {
            handler:  function (val) {
                if (!val.region.$invalid) {
                    this.$emit('can-continue', {value: true})
                    this.$store.commit('onlineExam/SET_REGION', val.region.$model)
                } else {
                    this.$emit('can-continue', {value: false})
                    setTimeout(() => {
                        this.$emit('change-next', {nextBtnValue: false})
                    }, 3000)
                }
                this.$store.commit('onlineExam/SET_VALID', !val.region.$invalid)
            },
            deep: true
        }
    },
    mounted () {
        // updating regions
        this.$store.dispatch('onlineExam/GET_REGIONS')
        this.$store.commit('onlineExam/SET_LOADING', false)
    },
    activated () {
        this.region = this.REGION
        // check mounted data
        if (!this.$v.region.$invalid) {
            this.$emit('can-continue', {value: true})
            // this.$store.commit('onlineExam/SET_LOADING', true)
        } else {
            this.$emit('can-continue', {value: false})
        }
    },
    methods: {
        ...mapActions('onlineExam', ['GET_REGIONS'])
    }
}
</script>
