<template>
    <div>
        <CatalogCategoryViewBanner :class="[categoryClass + '-banner']" :category="currentCategory" :category-class="categoryClass" class="spectacles-banner frames-banner" />

        <LensesInclude />

        <TermsInformation />

        <CatalogNavigationCategories :list-title="`Filter:`" :categories="categories" :current-id="currentCategoryId" class="category-frames-tabs" @[EVENT_CATEGORY_SELECTED]="setCurrentCategory" />

        <CatalogProductList :load="categoryLoader" :products="products[currentCategory.name]" class="grid products-grid" />

        <ReturnsInfo />
    </div>
</template>

<script>
import Vue from 'vue'
import _find from 'lodash/find'
import _size from 'lodash/size'
import category from './storage/category/view'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CatalogCategoryViewBanner from './view/CatalogCategoryViewBanner.vue'
import CatalogNavigationCategories from '../navigation/CatalogNavigationCategories.vue'
import CatalogProductList from '../product/CatalogProductList.vue'
import ReturnsInfo from '../cms_info/ReturnsInfo.vue'
import LensesInclude from '../cms_info/LensesInclude.vue'
import TermsInformation from '../cms_info/TermsInformation.vue'
import VueCookies from 'vue-cookies'
import products from '../../lensablplus/storage/lensablplus/customer'
import {
    EVENT_CATEGORY_SELECTED
} from '../../../app/utils/constants'

Vue.use(VueCookies)

export default {
    name: 'CatalogCategoryView',
    components: {
        CatalogCategoryViewBanner,
        CatalogNavigationCategories,
        CatalogProductList,
        ReturnsInfo,
        LensesInclude,
        TermsInformation
    },
    data: () => ({
        isModalOpen: false,
        refresh: false,
        EVENT_CATEGORY_SELECTED: EVENT_CATEGORY_SELECTED
    }),
    computed: {
        ...mapGetters('category', ['currentCategory', 'currentCategoryId', 'categories', 'categoryLoader', 'getCatProducts', 'hasFilters']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow']),
        categoryClass () {
            return this.currentCategory.name.split(' ').join('').toLowerCase()
        },
        products () {
            if (!_size(this.currentCategory.products)) {
                this.fetchCategoryProducts(this.currentCategory)
            }
            if (!this.hasFilters) {
                this.updateAllProducts(this.currentCategory.products)
            }

            return this.currentCategory.products || {}
        },
        productsCount () {
            return this.products[this.currentCategory.name] ? _size(this.products[this.currentCategory.name]) : _size(this.products)
        },
        lensablProductsCount () {
            return this.getCatProducts ? _size(this.getCatProducts) : _size(this.getCatProducts)
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['category/']) {
            this.$store.registerModule('category', category(this.$store))
        }
        this.$store.dispatch('category/fetchDefaultData')

        if (!this.$store._modulesNamespaceMap['lensablplus_customer/']) {
            this.$store.registerModule(['lensablplus_customer'], products(this.$store), { preserveState: !!this.$store.state['lensablplus_customer'] })
        }
    },
    created () {
        window.onpopstate = () => {
            const url = window.location.href
            if (url) {
                const category = this.getCategoryByPath(url)
                if (category.id !== this.currentCategory.id) {
                    this.setCurrentCategoryId(category.id)
                }
            }
        }
        this.checkRedeemablePathCategory()
    },
    methods: {
        ...mapMutations('category', ['setCurrentCategoryId', 'updateAllProducts']),
        ...mapActions('category', ['fetchCategoryProducts']),
        ...mapActions('lensablplus_customer', ['checkRedeemablePath', 'leaving']),
        setCurrentCategory (category = {}) {
            this.setCurrentCategoryId(category.id)

            if (this.currentCategory.url) {
                history.pushState({}, null, this.currentCategory.url)
            }
        },
        getCategoryByPath (url = '') {
            return _find(this.categories, {url: url}) || {}
        },
        getCategoryByName (name = '') {
            return _find(this.categories, {name: name}) || {}
        },
        checkRedeemablePathCategory () {
            this.checkRedeemablePath()
            window.addEventListener('pagehide', this.leaving, true)
        }
    }
}
</script>
