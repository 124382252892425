var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ScrollToFixed",
    {
      attrs: {
        data: {
          ref: "membership-page-create-header",
          options: { baseClassName: "content-header-floating" }
        }
      }
    },
    [
      _c("div", { staticClass: "content-header" }, [
        _c("h3", { staticClass: "icon-head head-adminhtml-plus-membership" }, [
          _vm._v("Membership ID ")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-buttons" }, [
          _c(
            "button",
            {
              staticClass: "scalable back",
              attrs: { title: "Back", type: "button" },
              on: { click: _vm.goBack }
            },
            [_c("span", [_c("span", [_c("span", [_vm._v("Back")])])])]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "scalable",
              attrs: { title: "Save", type: "button" },
              on: { click: _vm.save }
            },
            [_c("span", [_c("span", [_c("span", [_vm._v("Save")])])])]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "scalable",
              attrs: {
                title: "Reset",
                type: "button",
                onclick: "setLocation(window.location.href)"
              }
            },
            [_c("span", [_c("span", [_c("span", [_vm._v("Reset")])])])]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }