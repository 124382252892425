<template>
    <div v-if="renderer">
        <span v-dompurify-html="renderer" class="option-value"></span>
    </div>
</template>

<script>
import {
    PLACE_STEP_HEADER,
    PLACE_SUMMARY
} from '../../../app/utils/constants'

import {mapGetters} from 'vuex'

export default {
    name: 'RendererOptions',
    props: {
        step: {
            type: Object,
            default: () => {}
        },
        place: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters('values', ['values', 'checkoutData']),
        renderer () {
            let label = ''
            if (this.place === PLACE_STEP_HEADER) {
                label = this.step.stepHeaderLabel
            } else if (this.place === PLACE_SUMMARY) {
                label = this.step.summaryLabel
            }
            return label
        }
    }
}
</script>
