<template>
    <ContactsConfigurator v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import ContactsConfigurator from '../../components/contacts/configurator/Configurator'
import {mapState, mapGetters} from 'vuex'
import products from '../../components/lensablplus/storage/lensablplus/customer'

export default {
    name: 'RouterLensblplusContacts',
    components: {
        ContactsConfigurator
    },
    extends: App,
    computed: {
        ...mapState('lensablplus_customer', ['redeemflow']),
        ...mapGetters('lensablplus_customer', ['getEyeData'])
    },
    created () {
        this.$store.dispatch('contacts/clearProductAddToCart')
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablplus_customer/']) {
            this.$store.registerModule(['lensablplus_customer'], products(this.$store), { preserveState: !!this.$store.state['lensablplus_customer'] })
        }
    },
    mounted () {
        const redeem_path = JSON.parse(sessionStorage.getItem('redeem_path'))

        if (redeem_path !== null) {
            if (!redeem_path.current.includes('plus/account') && !redeem_path.current.includes('redeem_contactlenses')) {
                this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', false)
                this.$store.commit('contacts/RESET_EYE_DATA')
            } else if (redeem_path.previous.includes('plus/account') || redeem_path.previous.includes('redeem_contactlenses')) {
                this.$store.dispatch('contacts/setCheckedEye', 'right')
                this.$store.dispatch('contacts/setCheckedEye', 'left')
                this.$store.dispatch('contacts/updateConfirmedEye')
                this.$store.commit('contacts/SET_EYE_DATA', this.getEyeData.right)
                this.$store.commit('contacts/SET_EYE_DATA', this.getEyeData.left)
            } else {
                this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', false)
                this.$store.commit('contacts/RESET_EYE_DATA')
            }
        }
    }
}
</script>

<style scoped>

</style>
