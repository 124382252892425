var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images && _vm.images.length
    ? _c("div", { staticClass: "c-product-gallery-desktop" }, [
        _c("div", { staticClass: "c-product-gallery-main" }, [
          _c("img", { attrs: { src: _vm.showMainimage, alt: _vm.name } })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "c-product-gallery-thumbnail" },
          [
            _vm._l(_vm.images, function(image, index) {
              return [
                index < 2
                  ? _c(
                      "div",
                      {
                        key: image.index,
                        staticClass: "c-product-gallery-thumbnail--item"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "c-product-gallery-thumbnail--item-container",
                            class: { active: index == _vm.imageIndex },
                            on: {
                              click: function($event) {
                                return _vm.updateMainImage(index)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: { src: image.thumbnail, alt: _vm.name }
                            })
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }