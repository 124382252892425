var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    { key: "row-" + _vm.employee.name },
    [
      _c("label", { attrs: { for: "employee-" + _vm.employee.name } }, [
        _vm._v("\n        " + _vm._s(_vm.employee.label) + ":\n    ")
      ]),
      _vm._v(" "),
      [
        _vm.employee.type === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              staticClass: "input-text",
              class: {
                "required-entry": _vm.employee.required,
                "validation-failed": _vm.error
              },
              attrs: {
                id: "employee-" + _vm.employee.name,
                readonly: _vm.employee.disabled,
                maxlength: _vm.employee.length,
                type: "checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.value)
                  ? _vm._i(_vm.value, null) > -1
                  : _vm.value
              },
              on: {
                blur: function($event) {
                  return _vm.validBlurField(_vm.employee.name)
                },
                keyup: function($event) {
                  return _vm.$emit("update", _vm.value)
                },
                change: function($event) {
                  var $$a = _vm.value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.value = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.value = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.value = $$c
                  }
                }
              }
            })
          : _vm.employee.type === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              staticClass: "input-text",
              class: {
                "required-entry": _vm.employee.required,
                "validation-failed": _vm.error
              },
              attrs: {
                id: "employee-" + _vm.employee.name,
                readonly: _vm.employee.disabled,
                maxlength: _vm.employee.length,
                type: "radio"
              },
              domProps: { checked: _vm._q(_vm.value, null) },
              on: {
                blur: function($event) {
                  return _vm.validBlurField(_vm.employee.name)
                },
                keyup: function($event) {
                  return _vm.$emit("update", _vm.value)
                },
                change: function($event) {
                  _vm.value = null
                }
              }
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value,
                  expression: "value"
                }
              ],
              staticClass: "input-text",
              class: {
                "required-entry": _vm.employee.required,
                "validation-failed": _vm.error
              },
              attrs: {
                id: "employee-" + _vm.employee.name,
                readonly: _vm.employee.disabled,
                maxlength: _vm.employee.length,
                type: _vm.employee.type
              },
              domProps: { value: _vm.value },
              on: {
                blur: function($event) {
                  return _vm.validBlurField(_vm.employee.name)
                },
                keyup: function($event) {
                  return _vm.$emit("update", _vm.value)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.value = $event.target.value
                }
              }
            })
      ],
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass: "validation-advice",
              attrs: { id: "advice-required-entry-" + _vm.employee.name }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.employee.message
                      ? _vm.employee.message
                      : "This is a required field."
                  ) +
                  "\n    "
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }