<template>
    <div class="prescription-table-wrapper">
        <table v-if="!isMobile" class="params-prescription-table" style="margin: 2em 0">
            <colgroup>
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <span>Sphere (SPH)</span>
                        <HelpBubble :data="help.sphere" />
                    </th>
                    <th>
                        <span>Cylinder (CYL)</span>
                        <HelpBubble :data="help.cylinder" />
                    </th>
                    <th>
                        <span>Axis</span>
                        <HelpBubble :data="help.axis" />
                    </th>
                    <th v-if="rxtype !== 'single_vision'">
                        <span>Add</span>
                        <HelpBubble :data="help.add" />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(eye, index) in prescriptions" :key="index">
                    <th>{{ getTitle(index) }} (OD)</th>
                    <template v-for="(value, key) in eye">
                        <td v-if="!rxValues.includes(key)" :key="`${index}-${key}-${eye[key]}`">
                            <CustomSelect :value="eye[key]" :options="getOptions(key)" @change="setPrescriptionValue($event.target.value, index, key)" />
                        </td>
                    </template>
                </tr>
                <tr>
                    <th>
                        <span>PUPILLARY DISTANCE (PD)</span>
                        <HelpBubble :data="help.pd" />
                    </th>
                    <td v-if="!separate_pd">
                        <CustomSelect :value="prescriptions.right_eye.pupillary_distance" :options="getOptions('pupillary_distance')" @change="setPrescriptionValue($event.target.value, 'right_eye', 'pupillary_distance')" />
                    </td>
                    <td v-if="separate_pd">
                        <CustomSelect :value="'Right'" :options="getOptions('ODPD')" @change="setPrescriptionValue($event.target.value, 'right_eye', 'pupillary_distance')" />
                    </td>
                    <td v-if="separate_pd">
                        <CustomSelect :value="'Left'" :options="getOptions('OSPD')" @change="setPrescriptionValue($event.target.value, 'left_eye', 'pupillary_distance')" />
                    </td>
                    <td class="pd_checkbox enter-it-online prescription-type">
                        <RadioLabel :active="separate_pd" label="I have two PD numbers" @click="separate_pd = !separate_pd" />
                    </td>
                </tr>
            </tbody>
        </table>
        <table v-else class="params-prescription-table" style="margin-bottom: 2em">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        RIGHT EYE (OD)
                    </th>
                    <th>
                        LEFT EYE (OS)
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        <span>SPH</span>
                        <HelpBubble :data="help.sphere" />
                    </th>
                    <td>
                        <CustomSelect :value="prescriptions.right_eye.sphere" :options="getOptions('sphere')" @change="setPrescriptionValue($event.target.value, 'right_eye', 'sphere')" />
                    </td>
                    <td>
                        <CustomSelect :value="prescriptions.left_eye.sphere" :options="getOptions('sphere')" @change="setPrescriptionValue($event.target.value, 'left_eye', 'sphere')" />
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>CYL</span>
                        <HelpBubble :data="help.cylinder" />
                    </th>
                    <td>
                        <CustomSelect :value="prescriptions.right_eye.cylinder" :options="getOptions('cylinder')" @change="setPrescriptionValue($event.target.value, 'right_eye', 'cylinder')" />
                    </td>
                    <td>
                        <CustomSelect :value="prescriptions.left_eye.cylinder" :options="getOptions('cylinder')" @change="setPrescriptionValue($event.target.value, 'left_eye', 'cylinder')" />
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>Axis</span>
                        <HelpBubble :data="help.axis" />
                    </th>
                    <td>
                        <CustomSelect :value="prescriptions.right_eye.axis" :options="getOptions('axis')" @change="setPrescriptionValue($event.target.value, 'right_eye', 'axis')" />
                    </td>
                    <td>
                        <CustomSelect :value="prescriptions.left_eye.axis" :options="getOptions('axis')" @change="setPrescriptionValue($event.target.value, 'left_eye', 'axis')" />
                    </td>
                </tr>
                <tr v-if="rxtype !== 'single_vision'">
                    <th>
                        <span>Add</span>
                        <HelpBubble :data="help.add" />
                    </th>
                    <td>
                        <CustomSelect :value="prescriptions.right_eye.add" :options="getOptions('add')" @change="setPrescriptionValue($event.target.value, 'right_eye', 'add')" />
                    </td>
                    <td>
                        <CustomSelect :value="prescriptions.left_eye.add" :options="getOptions('add')" @change="setPrescriptionValue($event.target.value, 'left_eye', 'add')" />
                    </td>
                </tr>
            </tbody>
        </table>
        <table v-if="isMobile" class="params-prescription-table" style="margin: 1em 0">
            <colgroup>
                <col width="50%" />
                <col width="50%" />
            </colgroup>
            <thead>
                <tr>
                    <th colspan="2" style="text-align: start!important;">
                        <span>PUPILLARY DISTANCE (PD)</span>
                        <HelpBubble :data="help.pd" />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td v-if="!separate_pd">
                        <CustomSelect :value="prescriptions.right_eye.pupillary_distance" :options="getOptions('pupillary_distance')" @change="setPrescriptionValue($event.target.value, 'right_eye', 'pupillary_distance')" />
                    </td>
                    <td v-if="!separate_pd">&nbsp;</td>
                    <td v-if="separate_pd">
                        <CustomSelect :value="'Right'" :options="getOptions('ODPD')" @change="setPrescriptionValue($event.target.value, 'right_eye', 'pupillary_distance')" />
                    </td>
                    <td v-if="separate_pd">
                        <CustomSelect :value="'Left'" :options="getOptions('OSPD')" @change="setPrescriptionValue($event.target.value, 'left_eye', 'pupillary_distance')" />
                    </td>
                </tr>
                <tr>
                    <td class="pd_checkbox enter-it-online prescription-type" colspan="2">
                        <RadioLabel :active="separate_pd" label="I have two PD numbers" @click="separate_pd = !separate_pd" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="showError" class="error-text-rx">
            <span v-text="errorText"></span>
        </div>
        <div class="form-action bottom">
            <BaseButton :text="'Back'" @click.native="closePrescription" />
            <BaseButton :text="'Continue'" class="next eyeglass-button" @click.native="addPrescription" />
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import _clone from 'lodash/clone'
import getHelper from '../../../configurator/storage/constants/helper'
import HelpBubble from '../../../help_bubble/HelpBubble.vue'
import CommonPrescriptionModel from '../../../../app/common/prescription_model'
import CustomSelect from '../../../base/Select.vue'
import BaseButton from '../../../base/BaseButton.vue'
import RadioLabel from '../../../../components/base/RadioLabel.vue'
import {
    PRESCRIPTION_ADD_ERROR,
    PRESCRIPTION_ADD_PROGRESSIVE_ERROR
} from '../../../../app/utils/constants'

const DEFAULT_VALUE = {
    sphere: '',
    cylinder: '',
    axis: '',
    add: '',
    pupillary_distance: 0
}

export default {
    components: {
        HelpBubble,
        CustomSelect,
        BaseButton,
        RadioLabel
    },
    extends: CommonPrescriptionModel,
    props: {
        nextStep: {
            type: Boolean,
            default: false
        },
        completeStep: {
            type: Boolean,
            default: false
        },
        rxtype: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            validate: false,
            prescriptions: {
                right_eye: DEFAULT_VALUE,
                left_eye: DEFAULT_VALUE
            },
            separate_pd: false
        }
    },
    computed: {
        ...mapGetters('onlineExam', ['TYPE']),
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        validateOnlinePrescriptionData () {
            return this.validatePrescriptionData(this.prescriptions, this.prescriptionData.prescription_type)
        },
        showError () {
            return this.validateOnlinePrescriptionData && this.errorText
        },
        help () {
            const {help_components} = getHelper(this.$store.getters)
            return help_components
        },
        rxValues () {
            if (this.rxtype === 'single_vision') {
                return ['add', 'pupillary_distance']
            } else {
                return ['pupillary_distance']
            }
        }
    },
    methods: {
        ...mapMutations('values', ['setDataToValues']),
        validatePrescriptionData (prescription, prescription_type) {
            let show = false
            const prescriptionType = _clone(prescription_type)

            if (!prescription || !prescriptionType || !this.validate) {
                return show
            }

            const progressiveProduct = prescriptionType.indexOf('progressive') !== -1 || prescriptionType.indexOf('focal') !== -1

            // Right Eye
            const tempODSph = (prescription.right_eye.sphere).toString().replace('PL/', '')
            const od_sphere = prescription.right_eye.sphere && parseFloat(tempODSph)
            const rightEyeCylinderInt = parseFloat((prescription.right_eye.cylinder).toString().replace('SPH/Blank/', ''))
            const rightEyeAxisInt = parseFloat((prescription.right_eye.axis).toString().replace('blank/', ''))
            const rightAddEyeInt = parseFloat((prescription.right_eye.add).toString().replace('blank/', ''))
            const rightSelected = !isNaN(od_sphere) || !isNaN(rightEyeCylinderInt) || !isNaN(rightEyeAxisInt) || !isNaN(rightAddEyeInt)

            // Left Eye
            const tempOSSph = (prescription.left_eye.sphere).toString().replace('PL/', '')
            const os_sphere = prescription.left_eye.sphere && parseFloat(tempOSSph)
            const leftEyeCylinderInt = parseFloat((prescription.left_eye.cylinder).toString().replace('SPH/Blank/', ''))
            const leftEyeAxisInt = parseFloat((prescription.left_eye.axis).toString().replace('blank/', ''))
            const leftAddEyeInt = parseFloat((prescription.left_eye.add).toString().replace('blank/', ''))
            const leftSelected = !isNaN(os_sphere) || !isNaN(leftEyeCylinderInt) || !isNaN(leftEyeAxisInt) || !isNaN(leftAddEyeInt)

            if (!rightSelected && !leftSelected) {
                show = true
            }

            if (rightSelected && ((isNaN(od_sphere) || isNaN(rightEyeCylinderInt) || isNaN(rightEyeAxisInt)) || (progressiveProduct && isNaN(rightAddEyeInt)))) {
                show = true
            }

            if (leftSelected && ((isNaN(os_sphere) || isNaN(leftEyeCylinderInt) || isNaN(leftEyeAxisInt)) || (progressiveProduct && isNaN(leftAddEyeInt)))) {
                show = true
            }

            if (show) {
                this.errorText = progressiveProduct ? PRESCRIPTION_ADD_PROGRESSIVE_ERROR : PRESCRIPTION_ADD_ERROR
            }

            if (!show && (rightEyeCylinderInt && leftEyeCylinderInt)) {
                const odCylSign = Math.sign(prescription.right_eye.cylinder)
                const osCylSign = Math.sign(prescription.left_eye.cylinder)
                if (!isNaN(odCylSign) && !isNaN(osCylSign)) {
                    show = !(odCylSign === osCylSign)
                }
            }
            return show
        },
        addPrescription () {
            const prescriptions = {}
            prescriptions['type'] = this.TYPE
            prescriptions['verification'] = 'unverified'
            this.validate = true

            let i = 0
            for (const [key, value] of Object.entries(this.prescriptions)) {
                // if(value['sphere']  || value['cylinder']   || value['axis'] ){
                i += 1
                prescriptions[key] = value
                // }
            }
            if (i > 0) {
                this.$store.commit('onlineExam/SET_PRESCRIPTIONS', [prescriptions])
            }
            if (!this.validateOnlinePrescriptionData) {
                this.$store.commit('onlineExam/SET_LOADING', true)
                this.$emit('update:nextStep', true)
                this.$emit('update:completeStep', true)
            }
        },
        closePrescription () {
            this.$emit('updaterxchoice', false)
            this.$store.commit('onlineExam/SET_PRESCRIPTIONS', [])
            this.$emit('update:nextStep', true)
            this.$store.commit('onlineExam/SET_SHOW_NAV', true)
        }
    }
}
</script>
