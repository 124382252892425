import axios from 'axios'

let shippingService = null

class ShippingService {
    constructor ({base_url}) {
        this.base_url = base_url
    }

    update (data) {
        return axios.post(this.url(), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    url () {
        const path = 'lensabl/order/updateAddress/'
        return this.base_url + path
    }
}

export default function getShippingService (options = false) {
    if (options) {
        shippingService = new ShippingService(options)
    }
    return shippingService
}
