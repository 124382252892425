var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "c-product-description" }, [
      _c("h2", { staticClass: "c-product-subheader" }, [
        _vm._v("Product Description")
      ]),
      _vm._v(" "),
      _c("p", {
        directives: [
          {
            name: "dompurify-html",
            rawName: "v-dompurify-html",
            value: _vm.description,
            expression: "description"
          }
        ],
        staticClass: "c-product-paragraph-text"
      })
    ]),
    _vm._v(" "),
    false
      ? _c("div", { staticClass: "c-product-details" }, [
          _c("h2", { staticClass: "c-product-subheader" }, [_vm._v("Details")]),
          _vm._v(" "),
          _c("table", { staticClass: "c-product-details-list" }, [
            _c("tr", [
              _vm._m(0),
              _vm._v(" "),
              _c("td", [
                _c("span", { staticClass: "c-product-paragraph-text" }, [
                  _vm._v(_vm._s(_vm.contacts_lens_type))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _vm._m(1),
              _vm._v(" "),
              _c("td", [
                _c("span", { staticClass: "c-product-paragraph-text" }, [
                  _vm._v(_vm._s(_vm.package_details))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _vm._m(2),
              _vm._v(" "),
              _c("td", [
                _c("span", { staticClass: "c-product-paragraph-text" }, [
                  _vm._v(_vm._s(_vm.material))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _vm._m(3),
              _vm._v(" "),
              _c("td", [
                _c("span", { staticClass: "c-product-paragraph-text" }, [
                  _vm._v(_vm._s(_vm.water_content))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _vm._m(4),
              _vm._v(" "),
              _c("td", [
                _c("span", { staticClass: "c-product-paragraph-text" }, [
                  _vm._v(_vm._s(_vm.manufacturer))
                ])
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "c-product-details-list-item" }, [
      _c("span", { staticClass: "c-product-third-header" }, [
        _vm._v("Lens Type:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "c-product-details-list-item" }, [
      _c("span", { staticClass: "c-product-third-header" }, [
        _vm._v("Package Details:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "c-product-details-list-item" }, [
      _c("span", { staticClass: "c-product-third-header" }, [
        _vm._v("Material and % of content:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "c-product-details-list-item" }, [
      _c("span", { staticClass: "c-product-third-header" }, [
        _vm._v("Water % of content")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "c-product-details-list-item" }, [
      _c("span", { staticClass: "c-product-third-header" }, [
        _vm._v("Manufacturer:")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }