var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-component" }, [
    _c("div", { staticClass: "search-container" }, [
      _c("div", { staticClass: "search-container-main" }, [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchBrand,
                expression: "searchBrand"
              }
            ],
            ref: "search",
            attrs: {
              id: "category-search",
              type: "text",
              placeholder: "Enter Brand"
            },
            domProps: { value: _vm.searchBrand },
            on: {
              focus: function($event) {
                _vm.searchResultsVisible = true
              },
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  return _vm.blur($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.highlightPrevious($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.highlightNext($event)
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.goToLink($event)
                }
              ],
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchBrand = $event.target.value
                },
                _vm.softRest
              ],
              keyup: _vm.performSearch,
              blur: function($event) {
                _vm.setHighlightIndex(), (_vm.searchResultsVisible = false)
              }
            }
          })
        ]),
        _vm._v(" "),
        _vm.searchBrand.length > 0 && _vm.searchResultsVisible
          ? _c("div", { staticClass: "results-dropdown" }, [
              _c(
                "ul",
                { attrs: { id: "results" } },
                [
                  _vm._l(_vm.searchResults, function(product, index) {
                    return _c(
                      "a",
                      {
                        key: index,
                        class: { active: index === _vm.highlightedIndex },
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                            _vm.searchResultsVisible = true
                          },
                          mouseover: function($event) {
                            return _vm.hover(index)
                          },
                          click: function($event) {
                            return _vm.chooseBrand(product)
                          }
                        }
                      },
                      [
                        _c("li", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.collection[index],
                              expression: "collection[index]"
                            }
                          ]
                        })
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.searchResults.length === 0
                    ? _c("li", { staticClass: "no-results" }, [
                        _vm._v("\n                        No results for "),
                        _c("strong", [_vm._v(_vm._s(_vm.searchBrand))])
                      ])
                    : _vm._e()
                ],
                2
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }