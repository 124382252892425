import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererNotes',
    extends: RendererOptions,
    computed: {
        renderer () {
            return ''
        }
    }
}
