<template>
    <table id="membership_information" cellspacing="0" class="form-list">
        <tbody v-if="memberShipEdited">
            <tr>
                <td class="label">Select Membership Type</td>
                <td class="value">
                    <select v-model="lplus_membership_type" disabled>
                        <template v-for="(name, index) in membershipTypes">
                            <option :key="index" :value="index" v-text="name"></option>
                        </template>
                    </select>
                </td>
            </tr>
            <tr>
                <td class="label"><label>Select Plan Name</label></td>
                <td class="value">
                    <select v-model="selectedPlanId" class="select">
                        <template v-for="(product, index) in plansList">
                            <option :key="index" :value="index" v-text="product.name"></option>
                        </template>
                    </select>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td class="label">Order Date</td>
                <td class="value">
                    <strong v-text="formatDate(currentMemberShipData.created_at)"></strong>
                </td>
            </tr>
            <tr>
                <td class="label">Customer OrderID</td>
                <td class="value">
                    <strong v-text="currentMemberShipData.order_increment_id"></strong>
                </td>
            </tr>
            <tr>
                <td class="label">Customer Name</td>
                <td class="value">
                    <strong v-text="`${currentMemberShipData.customer_firstname} ${currentMemberShipData.customer_lastname}`"></strong>
                </td>
            </tr>
            <tr>
                <td class="label">Customer Email</td>
                <td class="value">
                    <strong v-text="currentMemberShipData.email"></strong>
                </td>
            </tr>
            <tr>
                <td class="label"><label>Membership Status</label></td>
                <td class="value">
                    <strong v-text="membershipStatus"></strong>
                </td>
            </tr>
            <tr>
                <td class="label">Membership Expiry</td>
                <td class="value">
                    <strong v-text="formatDate(currentMemberShipData.ended_at)"></strong>
                    <strong v-if="currentMemberShipData.diff_date">{{ currentMemberShipData.diff_date }}</strong>
                </td>
            </tr>
            <tr>
                <td class="label">Membership Type</td>
                <td class="value">
                    <strong v-text="getMembershipType(currentMemberShipData.lplus_membership_type)"></strong>
                </td>
            </tr>
            <tr>
                <td class="label">Company Name</td>
                <td class="value">
                    <strong v-text="(currentMemberShipData.business || {}).company_name">-</strong>
                </td>
            </tr>
            <tr>
                <td class="label">Company Plan ID</td>
                <td class="value">
                    <strong v-text="(currentMemberShipData.business || {}).company_id">-</strong>
                </td>
            </tr>
            <tr>
                <td class="label">Plan Sku</td>
                <td class="value" v-text="currentMemberShipData.sku"></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import dateFormat from 'dateformat'
import _first from 'lodash/first'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _size from 'lodash/size'
import {
    COMPONENT_ID_GLASSES,
    COMPONENT_ID_PLAN_TYPE,
    COMPONENT_ID_SEARCH,
    PLAN_VALUES,
    GLASSES_VALUES
} from '../../storage/constants/objectList'
import {
    MEMBERSHIP_TYPES,
    MEMBERSHIP_STATUS,
    FREE_TRIAL_STATUS
} from '../../storage/lensablplus/membership'

export default {
    name: 'LensabalplusMembershipData',
    data: () => ({
        selectedPlanId: '',
        membershipTypes: MEMBERSHIP_TYPES,
        lplus_membership_type: 0
    }),
    computed: {
        ...mapState('lensablPlus', ['plans']),
        ...mapState('lensablPlus/membership', ['customer', 'memberShipData', 'salesQuote']),
        ...mapGetters('lensablPlus/membership', ['memberShipEdited', 'currentMemberShipData']),
        currentPlan () {
            return this.plans[this.selectedPlanId] || {}
        },
        plansList () {
            const list = {}
            _map(Object.keys(this.plans), id => {
                if (parseInt(this.plans[id]['membership_type']) === parseInt(this.lplus_membership_type)) {
                    list[id] = this.plans[id]
                }
            })
            return list
        },
        membershipStatus () {
            if (parseInt(this.currentMemberShipData.is_free)) {
                return this.getMembershipStatus(FREE_TRIAL_STATUS)
            } else {
                return this.getMembershipStatus(this.currentMemberShipData.is_annual_paid)
            }
        }
    },
    watch: {
        selectedPlanId: {
            handler (id) {
                if (this.memberShipEdited && id) {
                    const sku = this.currentPlan.sku || ''
                    this.$store.commit('lensablPlus/COMPONENT_DATA', {
                        id: COMPONENT_ID_GLASSES,
                        value: this.checkerValueType(GLASSES_VALUES, sku),
                        isValidValue: true
                    })

                    this.$store.commit('lensablPlus/COMPONENT_DATA', {
                        id: COMPONENT_ID_PLAN_TYPE,
                        value: this.checkerValueType(PLAN_VALUES, sku),
                        isValidValue: true,
                        sku
                    })

                    this.$store.commit('lensablPlus/SET_SELECTED_PLAN', this.currentPlan)

                    if (!this.currentPlan.has_contacts) {
                        this.$store.commit('lensablPlus/COMPONENT_DATA', {
                            id: COMPONENT_ID_SEARCH,
                            value: {
                                left: '',
                                right: ' '
                            },
                            isValidValue: true
                        })
                    }

                    this.$store.dispatch('lensablPlus/membership/updateQuote', {
                        customer_id: this.customer.data.entity_id || false,
                        payment: {
                            method: 'free'
                        },
                        reset_shipping: 1,
                        add_product: id,
                        item: {
                            [id]: {
                                qty: 1
                            }
                        }
                    }).then(() => {
                        if (_size(this.salesQuote.plan_item) && this.salesQuote.plan_item.item_id) {
                            this.$store.dispatch('lensablPlus/membership/updateQuote', {
                                customer_id: this.customer.data.entity_id || false,
                                payment: {
                                    method: 'free'
                                },
                                reset_shipping: 1,
                                update_items: 1,
                                item: {
                                    [this.salesQuote.plan_item.item_id]: {
                                        qty: 1
                                    }
                                }
                            })
                        }
                        this.$store.dispatch('lensablPlus/membership/fetchPaymentData')
                    })
                }
            },
            deep: true
        },
        plansList: {
            handler (list) {
                this.selectedPlanId = _first(_keys(list || {}) || []) || ''
            },
            deep: true
        }
    },
    created () {
        if (this.memberShipEdited && _size(this.plansList)) {
            this.selectedPlanId = _first(_keys(this.plansList || {}) || []) || ''
        }
    },
    methods: {
        formatDate (date = '') {
            return date ? dateFormat(new Date(date), 'mmm dS, yyyy h:MM:ss TT') : ''
        },
        formatDatePlus (date, year = 0) {
            date = new Date(date)
            date.setFullYear(date.getFullYear() + parseInt(year))
            return dateFormat(new Date(date), 'mmm dS, yyyy h:MM:ss TT')
        },
        getMembershipType (type) {
            return MEMBERSHIP_TYPES[type] || ''
        },
        getMembershipStatus (status) {
            return MEMBERSHIP_STATUS[status] || ''
        },
        checkerValueType (values, value) {
            for (let i = 0; i < values.length; i++) {
                if (value.indexOf(values[i]) > -1) {
                    return values[i]
                }
            }
            return ''
        }
    }
}
</script>
