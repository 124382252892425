import download from './download'
import address from './address'

import _size from 'lodash/size'

export default () => ({
    namespaced: true,
    state: () => ({
        order_id: null
    }),
    getters: {
        order_id: (state, getters, rootState, rootGetters) => {
            if (!_size(state.order_id)) {
                state.order_id = rootGetters.rootState.order_id
            }
            return state.order_id
        }
    },
    modules: {
        download,
        address
    }
})
