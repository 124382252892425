<template>
    <div>
        <div class="pd_selector">
            <div class="pd-parameters">
                <div class="pd_checkbox">
                    <label class="radio-label">
                        <div :class="{active: pdInRx}" class="option-checkbox-container">
                            <div class="option-checkbox">
                                <input v-model="pdInRx" class="prescription-lens-radio" type="checkbox" title="My prescription image has PD." />
                            </div>
                        </div>
                        <span>My Pupillary Distance is listed on my prescription.</span>
                        <HelpBubble :data="{'params': {'text': help}}" />
                    </label>
                </div>
            </div>
            <div v-if="pdInRx && (fittingbox_status === 'taken' || fittingbox_status === 'retaken')" class="fittingbox-warning-upload">
                <br />We have your previous photo which we will process soon.
                <br />You can upload another if you like, which will override it.
            </div>
            <div v-if="pdInRx && showError" class="my-prescription-form">
                <p class="error-text-rx">Please choose a new image!</p>
            </div>
        </div>
    </div>
</template>

<script>
import _map from 'lodash/map'
import {mapGetters} from 'vuex'
import prescriptions from '../../../components/prescriptions/storage/prescriptions'

import HelpBubble from '../../../components/help_bubble/HelpBubble.vue'

export default {
    name: 'Pdinrx',
    components: {
        HelpBubble
    },
    props: {
        index: {
            type: [String, Number],
            default: ''
        },
        fittingbox_status: {
            type: [String, Boolean],
            default: ''
        }
    },
    data: () => ({
        showError: false,
        pd_in_rx: null,
        pdInRx: false,
        help: '<p style="font-weight: bold; font-size: 14px">Example Prescription (Rx)</p><p style="font-weight: bolder;font-size: 13px">All glasses prescriptions require a Pupillary Distance (PD). If a PD is not listed on your Rx, we will send you instructions on how to submit a photo for us to measure for you.</p>' +
            '<img src="/js/configurator/assets/example-pd.png" alt="example pd" width="420px">' +
            '<p style="font-weight: bolder;font-size: 13px">Pupillary Distance measures the space between the pupils of your eyes in millimeters. Knowing this measurement is important to make sure your eyes match up with the optical center of your lenses.</p>'
    }),
    computed: {
        ...mapGetters('storeView', ['assets_url']),
        ...mapGetters('values', ['values', 'checkoutData']),
        ...mapGetters('prescriptions', ['getValueOptions'])
    },
    watch: {
        pdInRx (value) {
            this.values.pd_in_rx = !!value
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
    },
    mounted () {
        this.pdInRx = this.getPdInRxValue()
    },
    methods: {
        isChangingRxUpload () {
            const dataOptions = this.getValueOptions(this.index)
            if (!dataOptions) {
                return false
            }
            const keys = Object.keys(dataOptions)
            const changingArr = _map(keys, key => {
                return key.indexOf('upload') !== -1 && (key.indexOf('rx') !== -1 || key.indexOf('prescription') !== -1)
            })
            return changingArr.indexOf(true) !== -1
        },
        getPdInRxValue () {
            const values = this.checkoutData
            if (!values) {
                return false
            }
            return values.custom_options.pd_in_rx ? values.custom_options.pd_in_rx : false
        }
    }
}
</script>
