var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { class: _vm.optionName === "uploaded_pd" ? "pd-label-parent" : "" },
        [
          !_vm.prescriptionData.status_4
            ? _c("RadioLabel", {
                attrs: {
                  active: _vm.isActive,
                  label: _vm.title,
                  value: _vm.index
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("checked", _vm.index)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.optionName === "uploaded_pd"
            ? _c("span", [
                _c(
                  "a",
                  { attrs: { href: "/required-measurements", target: "new" } },
                  [_vm._v("Instructions on how to take a picture")]
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.isActive &&
                _vm.prescriptionData.type === _vm.prescriptionData.isPD &&
                (_vm.fittingbox_status === "taken" ||
                  _vm.fittingbox_status === "retaken"),
              expression:
                "isActive && prescriptionData.type === prescriptionData.isPD && (fittingbox_status === 'taken' || fittingbox_status === 'retaken')"
            }
          ],
          staticClass: "fittingbox-warning-upload"
        },
        [
          _c("br"),
          _vm._v(
            "We have your previous photo which we will process soon.\n        "
          ),
          _c("br"),
          _vm._v(
            "You can upload another if you like, which will override it.\n    "
          )
        ]
      ),
      _vm._v(" "),
      _vm.isActive
        ? _c("div", { staticClass: "upload-prescription" }, [
            _c("div", { staticClass: "upload-component" }, [
              _vm.hasNoFile
                ? _c(
                    "div",
                    {
                      staticClass: "file-input-box",
                      class: { "invalid-form": _vm.showInvalidFormBorder }
                    },
                    [
                      _c("input", {
                        staticClass: "file-input",
                        attrs: {
                          type: "text",
                          disabled: "disabled",
                          placeholder: "No file chosen"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "action primary file-input-button",
                          attrs: { type: "button", title: "Continue" }
                        },
                        [
                          _c("span", [_vm._v("Browse...")]),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              type: "file",
                              name: "prescription",
                              accept: "image/*,application/pdf"
                            },
                            on: { change: _vm.previewFiles }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showError
                ? _c("div", { staticClass: "error-hint" }, [_vm._m(0)])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasFile
                ? _c("div", [
                    _c("div", { staticClass: "uploaded-file" }, [
                      _c("span", {
                        domProps: { textContent: _vm._s("✓ " + _vm.fileName) }
                      }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "remove-link",
                          attrs: { href: "javascript:void(0)" },
                          on: { click: _vm.removeFile }
                        },
                        [_vm._v("Remove")]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isUploading
                ? _c("div", [
                    _c("div", { staticClass: "uploading-progress" }, [
                      _vm._v("Uploading...")
                    ])
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("Pdinrx", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isActive && !_vm.prescriptionData.isPD,
            expression: "isActive && !prescriptionData.isPD"
          }
        ],
        attrs: {
          index: _vm.indexSave,
          fittingbox_status: _vm.prescriptionData.fittingbox_status
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(
        "\n                    The image you’ve attempted to upload is too large, please retry with a smaller file size. "
      ),
      _c("br"),
      _vm._v("\n                    You can also contact "),
      _c("a", { attrs: { href: "mailto: sales@lensabl.com" } }, [
        _vm._v("sales@lensabl.com")
      ]),
      _vm._v(" with your photo and order number.\n                ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }