var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "struct-parallax-bg ap-parent-container ap-parent-container-form"
      },
      [
        _c(
          "div",
          { staticClass: "ap-container" },
          [
            !_vm.isRequestSent
              ? _c("ProviderForm")
              : _c("div", [
                  _vm.isRequestSuccessful
                    ? _c("div", { staticStyle: { padding: "2rem 0 1rem" } }, [
                        _vm.areBenefitsZeroAmount
                          ? _c("div", [
                              _c("h3", { staticClass: "ap-container-header" }, [
                                _vm._v("Your benefits are depleted")
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "ap-p",
                                  staticStyle: {
                                    color: "#8d8d8d",
                                    "margin-bottom": "2rem"
                                  }
                                },
                                [
                                  _vm._v(
                                    "Unfortunately you have used all of your benefits."
                                  )
                                ]
                              )
                            ])
                          : _c("div", [
                              _c("h3", { staticClass: "ap-container-header" }, [
                                _vm._v("Congratulations!")
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "ap-p",
                                  staticStyle: {
                                    color: "#8d8d8d",
                                    "margin-bottom": "2rem"
                                  }
                                },
                                [
                                  _vm._v(
                                    "You have benefits available, follow the steps below to use:"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "ap-benefit-list ap-success-list",
                                  staticStyle: { "grid-column-gap": "1rem" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ap-list-item ap-list-item-1"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ap-list-item-number" },
                                        [_vm._v("1")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ap-list-item-text",
                                          staticStyle: { "line-height": "1.4" }
                                        },
                                        [
                                          _vm._v("Shop "),
                                          _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                                "text-decoration": "underline"
                                              },
                                              attrs: {
                                                href:
                                                  _vm.base_url + "shop-frames"
                                              }
                                            },
                                            [_vm._v("frames")]
                                          ),
                                          _vm._v(", "),
                                          _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                                "text-decoration": "underline"
                                              },
                                              attrs: {
                                                href:
                                                  _vm.base_url +
                                                  "choose-my-lenses"
                                              }
                                            },
                                            [_vm._v("lens replacement")]
                                          ),
                                          _vm._v(
                                            " or\n                                    "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold",
                                                "text-decoration": "underline"
                                              },
                                              attrs: {
                                                href:
                                                  _vm.base_url +
                                                  "shop-contacts/brands"
                                              }
                                            },
                                            [_vm._v("contact lenses")]
                                          ),
                                          _vm._v(" and add to cart")
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ap-list-item ap-list-item-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ap-list-item-number" },
                                        [_vm._v("2")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ap-list-item-text",
                                          staticStyle: { "line-height": "1.4" }
                                        },
                                        [_vm._v("Complete checkout")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ap-list-item ap-list-item-3"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ap-list-item-number" },
                                        [_vm._v("3")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ap-list-item-text",
                                          staticStyle: { "line-height": "1.4" }
                                        },
                                        [
                                          _vm._v(
                                            "Fill out form on confirmation page\n                                    "
                                          ),
                                          _c("span", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "c-product-lplus-ad-block anagram-ad-info-section"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "lplus-ad-block_container",
                                                    on: {
                                                      click: _vm.openPlusAdModal
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "c-product-lplus-ad-link anagram-tooltip"
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "help-bubble-icon",
                                                            attrs: {
                                                              "data-original-title":
                                                                "null"
                                                            }
                                                          },
                                                          [_vm._v("?")]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "help-bubble-container lplus-help-bubble-container"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "help-component"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "help-bubble lplus-help-bubble",
                                                                class: _vm.showModalAd
                                                                  ? "open"
                                                                  : "",
                                                                attrs: {
                                                                  id:
                                                                    "help_bubble_text"
                                                                }
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "ap-tooltip-img",
                                                                  attrs: {
                                                                    src:
                                                                      "/media/wysiwyg/tool-tip-image.jpg"
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "close-button",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.closeModal(
                                                                          $event
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg",
                                                                      {
                                                                        attrs: {
                                                                          width:
                                                                            "18",
                                                                          height:
                                                                            "18",
                                                                          xmlns:
                                                                            "http://www.w3.org/2000/svg"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "path",
                                                                          {
                                                                            attrs: {
                                                                              id:
                                                                                "svg_1",
                                                                              d:
                                                                                "m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z",
                                                                              fill:
                                                                                "rgb(102, 102, 102)",
                                                                              "fill-rule":
                                                                                "evenodd"
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ap-list-item ap-list-item-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ap-list-item-number" },
                                        [_vm._v("4")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "ap-list-item-text",
                                          staticStyle: { "line-height": "1.4" }
                                        },
                                        [
                                          _vm._v(
                                            "Automatically claim out-of-network reimbursements"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("hr", {
                                staticStyle: {
                                  width: "200px",
                                  border: "1px solid #8d8d8d"
                                }
                              })
                            ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "ap-benefit-info-list",
                            staticStyle: {
                              "grid-column-gap": "1rem",
                              "margin-bottom": "1rem"
                            }
                          },
                          [
                            _c("p", { staticClass: "ap-p" }, [
                              _c("b", [_vm._v("Insurance:")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { color: "#8d8d8d" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getCompanyName(
                                        _vm.getPatientValue("insurance")
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "ap-p" }, [
                              _c("b", [_vm._v("Member:")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { color: "#8d8d8d" } },
                                [_vm._v(_vm._s(_vm.getPatientValue("member")))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "ap-p" }, [
                              _c("b", [_vm._v("Plan:")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { color: "#8d8d8d" } },
                                [_vm._v(_vm._s(_vm.getPatientValue("plan")))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "ap-p" }, [
                              _c("b", [_vm._v("Benefit(/s):")]),
                              _vm._v(" "),
                              _c("span", {
                                directives: [
                                  {
                                    name: "dompurify-html",
                                    rawName: "v-dompurify-html",
                                    value: _vm.getBenefitsHtml,
                                    expression: "getBenefitsHtml"
                                  }
                                ],
                                staticStyle: { color: "#8d8d8d" }
                              })
                            ])
                          ]
                        )
                      ])
                    : _c("div", { staticStyle: { padding: "2rem 0 1rem" } }, [
                        _c("h3", { staticClass: "ap-container-header" }, [
                          _vm._v("We couldn’t find your insurance")
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "ap-p",
                            staticStyle: {
                              color: "#8d8d8d",
                              "margin-bottom": "1rem"
                            }
                          },
                          [
                            _vm._v(
                              "But you can still claim your benefit with a reimbursement form below."
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "ap-p",
                            staticStyle: {
                              color: "#8d8d8d",
                              "margin-bottom": "1rem"
                            }
                          },
                          [
                            _c("b", [_vm._v("Don't see your provider?")]),
                            _vm._v(
                              " Contact your insurance company directly for instructions on applying for\n                            reimbursement."
                            )
                          ]
                        )
                      ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button button--copy-solid",
                      staticStyle: {
                        "max-width": "210px",
                        "margin-bottom": "2rem"
                      },
                      attrs: { href: _vm.base_url }
                    },
                    [_vm._v("Start shopping")]
                  )
                ])
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ap-parent-container ap-parent-container-top" },
      [
        _c(
          "div",
          { staticClass: "ap-container ap-container-none-background" },
          [
            _c("h3", [
              _vm._v("Get upwards of $150 reimbursed by your vision insurance")
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "ap-p", staticStyle: { color: "#8d8d8d" } },
              [
                _vm._v(
                  "Check your vision insurance benefit amount using the form below."
                )
              ]
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "struct-parallax-bg ap-parent-container ap-parent-container-bottom"
      },
      [
        _c("div", { staticClass: "ap-container" }, [
          _c("h3", { staticClass: "ap-container-header" }, [
            _vm._v("Supported insurers")
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "ap-p",
              staticStyle: { color: "#8d8d8d", "margin-bottom": "1rem" }
            },
            [
              _vm._v(
                "We are out-of-network with these vision plans, but we can still help you check your benefits and file your claims."
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ap-image-container" }, [
            _c("section", { staticClass: "ap-img-section" }, [
              _c("div", [
                _c("img", {
                  staticClass: "ap-img aetna-img",
                  attrs: {
                    src: "/media/wysiwyg/insurances/aetna-logo.png",
                    alt: "aetna-logo"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticClass: "ap-img blue-view-vision-img",
                  attrs: {
                    src: "/media/wysiwyg/insurances/blue-view-vision-logo.webp",
                    alt: "blue-view-vision-logo"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticClass: "ap-img cigna-img",
                  attrs: {
                    src: "/media/wysiwyg/insurances/cigna.png",
                    alt: "cigna-logo"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticClass: "ap-img davis-vision-img",
                  attrs: {
                    src: "/media/wysiwyg/insurances/davis-vision-logo.png",
                    alt: "davis-vision-logo"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticClass: "ap-img eye-med-img",
                  attrs: {
                    src: "/media/wysiwyg/insurances/eye-med-logo.png",
                    alt: "eye-med-logo"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticClass: "ap-img spectera-img",
                  attrs: {
                    src: "/media/wysiwyg/insurances/spectera-logo.png",
                    alt: "spectera-logo"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticClass: "ap-img superior-vision-img",
                  attrs: {
                    src: "/media/wysiwyg/insurances/superior-vision-logo.png",
                    alt: "superior-vision-logo"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  staticClass: "ap-img vsp-img",
                  attrs: {
                    src: "/media/wysiwyg/insurances/vsp-logo.png",
                    alt: "vsp-logo"
                  }
                })
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }