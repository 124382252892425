var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vision-seo-container" }, [
    _c("section", { staticClass: "vision-seo-section vision-faq-section" }, [
      _c(
        "div",
        { staticClass: "vision-seo-row vision-faq-row" },
        [_vm._m(0), _vm._v(" "), _c("FaqAccordion")],
        1
      )
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vision-seo-text vision-faq-text" }, [
      _c("h2", [_vm._v("Frequently Asked Questions")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Patients in various regions across the U.S. can renew their eye prescription online. Here are a few frequently asked questions to help you understand if an online vision test is right for you:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "vision-seo-section vision-requirements-section" },
      [
        _c("div", { staticClass: "vision-seo-row vision-requirements-row" }, [
          _c(
            "div",
            { staticClass: "vision-seo-text vision-requirements-text" },
            [
              _c("h2", [_vm._v("What You Need for the Test")]),
              _vm._v(" "),
              _c("p", [_vm._v("Here's what you'll need to take your test")])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "vision-requirements-images" }, [
            _c("div", { staticClass: "vision-requirements-image" }, [
              _c("img", {
                attrs: {
                  src: "/media/wysiwyg/vision-seo-rx.svg",
                  alt: "Rx icon"
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v("Your current prescription")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vision-requirements-image" }, [
              _c("img", {
                attrs: {
                  src: "/media/wysiwyg/vision-seo-computer.svg",
                  alt: "Rx icon"
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v("A computer or phone")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vision-requirements-image" }, [
              _c("img", {
                attrs: {
                  src: "/media/wysiwyg/vision-seo-card.svg",
                  alt: "Rx icon"
                }
              }),
              _vm._v(" "),
              _c("span", [_vm._v("Your driver's license or credit card")])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "our-labs__call-to-action vision-eyetest-section" },
      [
        _c("div", { staticClass: "custom-callout parallax-trigger" }, [
          _c("div", { staticClass: "struct-parallax-bg" }, [
            _c("div", { staticClass: "custom-callout__content" }, [
              _c("div", { staticClass: "custom-callout__layout" }, [
                _c("h2", [_vm._v("Start Your Online Eye Test")]),
                _vm._v(" "),
                _c("p", { staticClass: "vision-eyetest-subtext" }, [
                  _vm._v(
                    "Lensabl is the one-stop shop for all things optical, online. Use our site to renew or update your prescription in 24 hours or less."
                  ),
                  _c("span", [
                    _vm._v("For more information, "),
                    _c("a", { attrs: { href: "/contact" } }, [
                      _vm._v("contact us online")
                    ]),
                    _vm._v(" today!")
                  ])
                ]),
                _vm._v(" "),
                _c("a", { attrs: { href: "/online-eye-exam-qualification" } }, [
                  _c("div", { staticClass: "custom-callout__button" }, [
                    _c("div", { staticClass: "button button--copy-solid" }, [
                      _c("div", { staticClass: "button__container" }, [
                        _vm._v("Renew My Prescription")
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }