<template>
    <div v-show="isCurrent" :class="id">
        <h2 v-if="config.title" v-dompurify-html="config.title" class="title"></h2>

        <div id="contact_address_fields" class="contact">
            <div class="content">
                <div class="hor-scroll">
                    <table cellspacing="0" class="form-list">
                        <tbody>
                            <tr v-for="(template, index) in contact" :key="`row-${index}-${template.label}`">
                                <td>
                                    <label :for="'addressTemplateData[0].name'">
                                        {{ template.label }}:
                                    </label>
                                    <input
                                        :id="`membership-billing_address_${template.name}`"
                                        v-model="values[template.name]"
                                        :class="{'required-entry': template.required, 'validation-failed': errors[template.name]}"
                                        :type="template.type"
                                        :maxlength="template.length"
                                        class="input-text"
                                        @blur="validBlurField(template.name)"
                                    />
                                    <div v-if="errors[template.name]" :id="`advice-required-entry-${template.name}`" class="validation-advice">
                                        {{ template.message ? template.message : 'This is a required field.' }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton
                    class="button--copy-solid-white-blue button--small"
                    text="Continue"
                    @click.native="continueNxt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseButton from '../../base/BaseButton.vue'
import _map from 'lodash/map'
import {validationMixin} from 'vuelidate'
import {required, email, minLength, numeric, maxLength} from 'vuelidate/lib/validators'

export default {
    name: 'PrimaryContact',
    components: {
        BaseButton
    },
    mixins: [validationMixin],
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        errors: {
            name: false,
            number: false,
            email: false,
            password: false
        },
        contact: [
            {
                name: 'name',
                label: 'Full Name',
                required: true,
                type: 'text'
            },
            {
                name: 'number',
                label: 'Phone Number',
                required: true,
                type: 'text',
                message: 'Please exclude dashes',
                length: '10'
            },
            {
                name: 'email',
                label: 'Email Address (Account Name)',
                required: true,
                type: 'text',
                message: 'Please enter a valid email'
            },
            {
                name: 'password',
                label: 'Password',
                required: true,
                type: 'password'
            }
        ],
        values: {
            name: '',
            number: '',
            email: '',
            password: '',
            invalid: false
        }
    }),
    validations: {
        values: {
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(6)
            },
            name: {
                required,
                minLength: minLength(3)
            },
            number: {
                required,
                numeric,
                minLength: minLength(10),
                maxLength: maxLength(10)
            }
        }
    },
    computed: {
        ...mapGetters('lensablplus_business', ['getObjectConfig', 'currentComponent']),
        config () {
            return this.getObjectConfig(this.id)
        },
        options () {
            return this.config.component.options
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        },
        contactInvalidFields () {
            const invalidFields = []
            _map(this.values, (val, key) => {
                if (this.$v.values.hasOwnProperty(key) && this.$v.values[key].$invalid) {
                    invalidFields.push(key)
                }
            })
            return invalidFields
        },
        contactIsValid () {
            return !this.contactInvalidFields.length
        }
    },
    methods: {
        continueNxt () {
            if (this.contactIsValid) {
                this.$store.commit('lensablplus_business/COMPONENT_DATA', {id: this.id, value: this.values, isValidValue: true})
            } else {
                _map(this.contact, (value, key) => {
                    this.validBlurField(value['name'])
                })
            }
        },
        validBlurField (val) {
            this.errors[val] = this.$v.values[val].$invalid
        }
    }
}
</script>

<style scoped>

</style>
