<template>
    <div class="product">
        <div v-if="outOfStock" class="sold-out-block">
            <div class="sold-out">
                <p>Sold Out</p>
            </div>
        </div>
        <a v-if="currentImage" :title="product.name" :href="productUrl" class="product photo">
            <img :alt="product.name" :src="currentImage.path"/>
        </a>
        <div class="product details">
            <span v-if="showCategoryName" class="category-title">{{ product.category }}</span>
            <div class="item-details">
                <strong class="product name product-item-name">
                    <a v-dompurify-html="product.name" :title="product.name" :href="productUrl" class="product-item-link"></a>
                </strong>
                <div class="price-container">
                    <span v-if="showProductPrice" class="product-price">Price {{ currentImage.price || product.price }}</span>
                </div>
                <ColorSwatches
                    v-if="showSwatcher"
                    :colors="images"
                    :checked-index="checkedIndex"
                    :option-item-class="'decorate img'"
                    :data-swatch-product="product.name"
                    @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor"
                />
            </div>
        </div>
    </div>
</template>
<script>

import ProductItem from "../ProductItem.vue";

export default {
    name: 'ProductItem',
    extends: ProductItem
}
</script>
