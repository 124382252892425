var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.totalPrice
    ? _c(
        "div",
        { staticClass: "price-info" },
        [
          false
            ? [
                _c("div", { staticClass: "plan-price-total" }, [
                  _c("span", [
                    _vm._v(
                      "Total Vision Plan Cost (Per Year): " +
                        _vm._s(_vm.currencySymbol) +
                        _vm._s(_vm.formatPrice(_vm.totalPrice("totalPrice")))
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "price-label" }, [
                    _vm._v(
                      "Save " +
                        _vm._s(_vm.discount) +
                        "% if paid in full (annual) today"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("Toggle", {
                  attrs: { price: _vm.option },
                  on: { update: _vm.updatePrice }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "price" }, [
            _c("div", { staticClass: "total-value value" }, [
              _c("span", { staticClass: "total-value-currency" }, [
                _vm._v(_vm._s(_vm.currencySymbol))
              ]),
              _vm._v(" "),
              _c("span", {
                staticClass: "total-value-amount-dollars",
                domProps: { textContent: _vm._s(_vm.formatPrice(_vm.dollars)) }
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "total-value-amount-cents",
                domProps: { textContent: _vm._s("." + _vm.cents) }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "label" }, [
              _c("strong", [_vm._v("/per " + _vm._s(_vm.label))])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "plan-price-total" }, [
            _c("p", { staticClass: "price-label" }, [
              _vm._v(
                "\n            Starting at " +
                  _vm._s(_vm.affirmPrice) +
                  "/mo with\n            "
              ),
              _c(
                "span",
                {
                  staticClass:
                    "__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed"
                },
                [_vm._v("Affirm")]
              ),
              _vm._v(".\n            "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.affirmModalShow()
                    }
                  }
                },
                [_vm._v("Learn more")]
              )
            ])
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }