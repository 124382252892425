import {mapGetters, mapActions} from 'vuex'
import {
    EVENT_CONFIGURATOR_RESET,
    EVENT_CONFIGURATOR_ACTIVATE_FIRST,
    EVENT_CONFIGURATOR_RESTART
} from '../utils/constants'
import getSessionService from '../utils/session-service'

export default {
    data: () => ({
        session: getSessionService()
    }),
    computed: {
        ...mapGetters('values', ['values']),
        ...mapGetters('configurator/steps', ['visibleList', 'getStepObject', 'visibleFirstStep'])
    },
    methods: {
        ...mapActions('configurator/steps', ['setAnimationLock', 'activateFirstVisibleStep', 'scrollToStepHead', 'configuratorReset']),
        _configuratorReset () {
            this.session.reset()
            this.setAnimationLock(true)
            this.configuratorReset()
            this.setAnimationLock(false)
            this.$bus.$emit(EVENT_CONFIGURATOR_RESET)
            this._activateFirst()
        },
        _activateFirst () {
            this.activateFirstVisibleStep()
            this.$bus.$emit(EVENT_CONFIGURATOR_ACTIVATE_FIRST, this.visibleFirstStep)
            this.$nextTick(() => {
                // this.scrollToStepHead(this.visibleFirstStep.id)
            })
        },
        _restartSteps () {
            return new Promise((resolve) => {
                this.session.reset()
                this.configuratorReset()
                this.$bus.$emit(EVENT_CONFIGURATOR_RESTART)
                resolve()
            })
        }
    }
}
