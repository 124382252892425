import {
    DEFAULT_VALUES_OBJECT,
    STATE_CURRENT,
    STATE_READY,
    STATE_WAIT,
    CONTACTS_DEFAULT_VALUES
} from '../../../../app/utils/constants'

import {
    LENS_TYPE_ID
} from '../../../../app/common/constants/lens_type'

import Vue from 'vue'
import _first from 'lodash/first'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _extend from 'lodash/extend'
import _keys from 'lodash/keys'
import _size from 'lodash/size'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import _cloneDeep from 'lodash/cloneDeep'
import _union from 'lodash/union'
import {
    getStepList
} from '../constants/step_list/'
import {STEPS_CHAIN} from '../constants/chain'
import {getLimiters} from '../constants/step_list/limiters'
import * as abstract from '../../../../storage/abstract'
import {LIMITERS_JAMES_ORO} from "../constants/step_list/jamesoro_step_list";

export const state = () => ({
    chain: [],
    landing_config: {
        limiters: {
            disabled_colors: {},
            enabled_colors: {},
            disabled_lens_types: {},
            lens_type_disable: {},
            lens_options_disable: {},
            options_disable: {},
            options_enable: {}
        }
    },
    hidden_steps: [],
    labels: {},
    animationLock: false,
    stepStates: {},
    stepObjects: {},
    visibleList: [],
    stepsListChain: [],
    step_list: [],

    validation: false
})

export const getters = {
    chain: state => state.chain,
    landingConfig: state => state.landing_config,
    limiters: state => state.landing_config.limiters,
    hiddenSteps: state => state.hidden_steps,
    labels: state => state.labels,
    animationLock: state => state.animationLock,
    stepStates: state => state.stepStates,
    stepObjects: state => state.stepObjects,
    visibleList: state => state.visibleList,
    visibleFirstStep: (state, getters) => _find(getters.visibleList, step => !step.isHidden) || {},
    stepList: state => state.step_list,
    framesName: (state, getters, rootState, rootGetters) => {
        const frames_name = rootGetters.rootState.key === 'contacts' ? rootGetters.rootState.key : rootGetters['productView/frames_name']
        return rootGetters['productView/isClipOnType'] ? 'clipon' : frames_name
    },
    storeCode: (state, getters, rootState, rootGetters) => {
       return rootGetters.rootState['store_code'] || ''
    },

    stepsListChain: state => state.stepsListChain,

    getLimiters: (state, getters, rootState, rootGetters) => (id, type) => {
        let optionsLimiters = []
        const values = rootGetters['values/values']
        const productType = rootGetters['values/productType']
        const limiters = getters.limiters && getters.limiters.hasOwnProperty(type)
            ? getters.limiters[type].hasOwnProperty(productType)
                ? getters.limiters[type][productType]
                : {}
            : {}

        _map(limiters, (limiter, key) => {
            if (values.hasOwnProperty(key) && values[key]) {
                let value = values[key]
                if (typeof value === 'object') {
                    value = value.value
                }
                if (Array.isArray(limiter)) {
                    optionsLimiters = optionsLimiters.concat(limiter)
                }

                if (limiter.hasOwnProperty(value)) {
                    const options = limiter[value]
                    if (options.hasOwnProperty(id)) {
                        optionsLimiters = optionsLimiters.concat(options[id])
                    }
                }
            }
        })
        return optionsLimiters
    },
    getLimiterByType: (state, getters, rootState, rootGetters) => (type) =>{
        const productType = rootGetters['values/productType']
        const limiters = getters.limiters[type] || {}
        const limitersByType = limiters[productType] || {}
        return limitersByType
    },
    getLimitersEnabled: (state, getters) => id => getters.getLimiters(id, 'options_enable'),
    getLimitersAntiReflective: (state, getters, rootState, rootGetters) => (type = 'anti_reflective_enable') => {
        return getters.getLimiterByType(type)
    },
    getLimitersEnabledOptions: (state, getters)  => {
        return getters.getLimiterByType('lens_options_enable') || []
    },
    getLimitersEnabledByColor: (state, getters)  => {
        return getters.getLimiterByType('lens_options_enable_by_color') || []
    },
    getLimitersEnabledOptionsByLensType: (state, getters)  => {
        return getters.getLimiterByType('lens_options_enable_by_lens_type') || {}
    },
    getLimitersPreDefined: (state, getters)  => {
        return getters.limiters['preDefined'] || {}
    },
    getLimitersPreDefinedByFrames: (state, getters, rootState, rootGetters) => stepId=> {
        const predefinedObject =  getters.getLimitersPreDefined
        if (rootGetters['values/values'].frames_SKU){
            const framesName = rootGetters['values/values'].frames_SKU.toUpperCase()
            const framesOptions = predefinedObject[framesName]
            if (framesOptions){
                const stepOptions = framesOptions[stepId]
                if (stepOptions){
                    return stepOptions
                }
            } else if (predefinedObject['ALL'] != null) {
                const stepOptions = predefinedObject['ALL'][stepId]
                if(stepOptions) {
                    return stepOptions
                }
            }
        }
        return  {}
    },
    getLimitersDisabled: (state, getters, rootState, rootGetters) => id => {
        const productType = rootGetters['values/productType']
        const type = id + '_disable'

        const limiters = getters.getLimiters(id, 'options_disable')
        const limitersMain = getters.limiters && getters.limiters.hasOwnProperty(type)
            ? getters.limiters[type].hasOwnProperty(productType)
                ? getters.limiters[type][productType]
                : []
            : []

        const frameSku = rootGetters['values/values'].frames_SKU
            ? rootGetters['values/values'].frames_SKU.toUpperCase() : null;
        const limitersSku = frameSku && getters.limiters
            && getters.limiters.hasOwnProperty('remove_options_by_sku')
            && getters.limiters['remove_options_by_sku'][frameSku]
            && getters.limiters['remove_options_by_sku'][frameSku][id]
            ? getters.limiters['remove_options_by_sku'][frameSku][id]
            : []

        return _union(limitersMain, limiters, limitersSku)
    },
    getStepState: state => id => state.stepStates[id],
    getStepObject: state => id => state.stepObjects[id],
    stepsCount: state => _size(state.visibleList),
    currentStep: state => _find(state.visibleList, step => step.stepState === STATE_CURRENT),
    getStepConfig: state => id => _find(state.step_list, step => step.id === id),
    getLeftAndRightStepIds: (state, getters) => (id, hiddenInclude = false) => {
        const list = getters.visibleList
        if (!list) {
            return [[], []]
        }

        const leftSteps = []
        const rightSteps = []
        let stepFound = false
        const hiddenSteps = getters.hiddenSteps
        for (const step of list) {
            if (step.id !== id && hiddenSteps.indexOf(step.id) === -1) {
                if (hiddenInclude || !step.isHidden) {
                    if (!stepFound) {
                        leftSteps.push(step.id)
                    }
                    if (stepFound) {
                        rightSteps.push(step.id)
                    }
                }
            } else {
                stepFound = true
            }
        }
        return [leftSteps, rightSteps]
    },
    isProductConfigured: (state, getters) => {
        const steps = getters.visibleList
        let isConfigured = steps.length > 0
        for (const step of steps) {
            if (!step.isReady && isConfigured) {
                isConfigured = false
            }
        }
        return isConfigured
    },
    defaultValues: (state, getters, rootState, rootGetters) => (config = {}) => {
        const {defaults} = getters.landingConfig

        const defaultValues = {}
        _map(getters.stepList, step => defaultValues[step.id] = step.default_value || '')
        _extend(defaultValues, DEFAULT_VALUES_OBJECT, defaults || {}, config || {})

        defaultValues['anti_reflective'] = rootGetters['configurator/antireflective_sections'].anti_reflective_by_default
        defaultValues['anti_reflective_symbol'] = rootGetters['configurator/antireflective_sections'].anti_reflective_symbol

        defaultValues['frames_name'] = rootGetters['productView/frames_name']
        defaultValues['frame_price'] = rootGetters['productView/frame_price']
        defaultValues['product_sku'] = rootGetters['productView/product_sku']
        defaultValues['frames_value'] = rootGetters['productView/frames_value']
        defaultValues['frames_SKU'] = rootGetters['productView/frames_SKU']
        defaultValues['lensabl_frames'] = rootGetters['productView/lensabl_frames']

        return defaultValues
    },
    getContactsDefaults: (state, getters, rootState, rootGetters) => (config = {}) => {
        const {defaults} = getters.landingConfig
        const defaultValues = {}
        _map(getters.stepList, step => defaultValues[step.id] = step.default_value || '')
        _extend(defaultValues, CONTACTS_DEFAULT_VALUES, defaults || {}, config || {})

        defaultValues['anti_reflective'] = rootGetters['configurator/antireflective_sections'].anti_reflective_by_default
        defaultValues['anti_reflective_symbol'] = rootGetters['configurator/antireflective_sections'].anti_reflective_symbol

        return defaultValues
    },
    getNextDefaultValue: (state, getters) => id => {
        const defaultKeys = [
            'lens', 'prescription_type'
        ]

        const defaultValuesData = {}
        for (const defaultKey of defaultKeys) {
            const stepConfig = getters.getStepConfig(defaultKey)
            const {default_value = false} = stepConfig

            if (default_value) {
                defaultValuesData[defaultKey] = default_value
                continue
            }

            const {component} = stepConfig
            const {options} = component
            const first = _first(options)
            const {id} = first

            defaultValuesData[defaultKey] = id
        }
        return defaultValuesData[id]
    },
    getNextStepIdByNextObject: (state, getters, rootState, rootGetters) => (next, id = false) => {
        const values = rootGetters['values/values']
        const keys = _keys(next)

        if (keys.length > 1) {
            let value = values[id]

            if (!value) {
                value = getters.getNextDefaultValue(id)
            }

            if (!value) {
                return false
            }

            const nextObject = next[value]
            if (!nextObject) {
                return false
            }

            if (typeof nextObject === 'object') {
                return getters.getNextStepIdByNextObject(nextObject)
            } else {
                return nextObject
            }
        } else {
            const valueKey = _first(keys)
            return getters.getNextStepIdByNextObject(next[valueKey], valueKey)
        }
    },
    getNextChainElement: (state, getters) => (chainItem) => {
        const next = chainItem.next

        if (!next) {
            return false
        }

        let nextStepId
        if (typeof next === 'object') {
            nextStepId = getters.getNextStepIdByNextObject(next)
        } else {
            nextStepId = next
        }

        if (!nextStepId) {
            return false
        }

        return getters.chain.find(item => item.id === nextStepId)
    },

    getStepCurrentOption: (state, getters, rootState, rootGetters) => step => {
        const value = rootGetters['values/values'][step.id]

        if (!value) {
            return false
        }

        const {options} = step.component
        if (!options) {
            return false
        }

        return _find(options, option => option.id === value) || false
    },
    getStepContinuePromise: (state, getters) => step => {
        const option = getters.getStepCurrentOption(step)
        if (!option) {
            return false
        }
        return option.continue_promise || false
    },
    getCurrentExtraOptionSections: () => step => {
        const currentOption = _find(step.options, option => option.id === step.value)
        if (currentOption && currentOption.hasOwnProperty('component_extra_options')) {
            return (currentOption.component_extra_options.params || {}).sections
        }
        return false
    },
    getCurrentExtraOptionSection: (state, getters, rootState, rootGetters) => step => {
        const values = rootGetters['values/values']
        const colorValue = values.color.value
        const sections = getters.getCurrentExtraOptionSections(step)
        if (colorValue && sections) {
            return _find(sections, section => _find(section.colors, color => color.id === colorValue))
        }
        return false
    },
    hasExtraOptionSections: () => step => Boolean(_find(step.options, option => {
        if (option.hasOwnProperty('component_extra_options')) {
            return (option.component_extra_options.params || {}).sections
        }
        return false
    })),

    canEditStep: (state, getters) => id => {
        const step = getters.getStepObject(id) || {}
        return step.stepState === STATE_READY
    },
    isContactDetailsPage: (state, getters, rootState, rootGetters) => rootGetters.hasOwnProperty('contacts/isContactDetailsPage') && !!rootGetters['contacts/isContactDetailsPage']
}

export const actions = {
    fetchDefaultData: ({commit, dispatch, rootGetters}, defaultData = {}) => {
        const data = rootGetters.rootState || {}
        if (!_size(data.chain)) {
           data.chain = _cloneDeep(STEPS_CHAIN)
        }

        dispatch('values/fetchDefaultData', {}, { root: true })

        commit('UPDATE_STATE_DATA', data)
    },
    setAnimationLock: ({commit}, animation = false) => commit('ANIMATION_LOCK', animation),
    updateStepObject: ({commit}, stepConfig) => commit('STEP_OBJECT', stepConfig),

    setStepState: ({getters, dispatch}, {stepState, list}) => {
        for (const step_id of list) {
            const step = getters.getStepObject(step_id)
            if (step && step.stepState !== stepState) {
                dispatch('updateStep', {
                    id: step_id,
                    data: {
                        stepState: stepState,
                        canEdit: stepState === STATE_READY,
                        showControls: stepState === STATE_CURRENT,
                        isReady: stepState === STATE_READY,
                        isCurrent: stepState === STATE_CURRENT,
                        shouldHighlight: [STATE_READY, STATE_CURRENT].indexOf(stepState) !== -1
                    }
                })
            }
        }
    },
    activateStep: ({getters, dispatch}, id) => {
        const [lefts, rights] = getters.getLeftAndRightStepIds(id, true)
        dispatch('setStepState', {stepState: STATE_CURRENT, list: [id]})
        dispatch('setStepState', {stepState: STATE_READY, list: lefts})
        dispatch('setStepState', {stepState: STATE_WAIT, list: rights})
    },
    activateFirstVisibleStep: ({getters, dispatch}) => dispatch('activateStep', getters.visibleFirstStep.id),
    scrollToStepHead: ({state, getters, rootGetters}, id) => {
        const element = document.getElementById(`configurator_step_${id}`)
        if (element && !state.animationLock) {
            const pos = element.getBoundingClientRect()
            const magicSpace = rootGetters['deviceProperty/isMobile'] ? 94 : rootGetters['deviceProperty/isTablet'] ? 50 : 80
            window.scrollTo(0, pos.top + pageYOffset - magicSpace)
        }
    },
    updateStepsListChain: ({commit, getters, dispatch, rootGetters}) => {
        const stepList = []
        const chainList = _filter(getters.chain, chain => getters.getStepConfig(chain.id))
        if (!_size(chainList)) {
            return false
        }
        let chainItem = _first(chainList)
        const step = getters.getStepObject(chainItem.id)

        if (step) {
            stepList.push(step)
            chainItem = getters.getNextChainElement(chainItem)
            while (chainItem) {
                const step = getters.getStepObject(chainItem.id)
                let allowToPush = true
                const hideForProduct = step.hide_for_product
                const hideForRedeemFlow = step.hide_for_redeem
                const redeemFlow = rootGetters['lensablplus_customer/isRedeemFlow']

                if (hideForProduct) {
                    allowToPush = hideForProduct.indexOf(rootGetters['values/checkoutData'].product) === -1
                }

                if (allowToPush && !(hideForRedeemFlow && redeemFlow)) {
                    stepList.push(step)
                }
                chainItem = getters.getNextChainElement(chainItem)
            }
        }
        const filteredList = _filter(stepList, step => getters.hiddenSteps.indexOf(step.id) === -1)
        const differenceList = _filter(filteredList, (step, index) => (getters.stepsListChain[index] || {}).id !== filteredList[index].id)

        if (_size(differenceList) || (filteredList.length !== getters.stepsListChain.length)) {
            let sequence_number = 1
            for (const step of filteredList) {
                if (step.sequence_number !== sequence_number) {
                    dispatch('updateStep', {
                        id: step.id,
                        data: {sequence_number}
                    })
                }
                if (!step.isHidden) {
                    sequence_number++
                }
            }
            commit('STEPS_LIST_CHAIN', filteredList)
        }
    },
    updateVisibleList: ({commit, dispatch, getters}) => {
        dispatch('fetchStepList')
        dispatch('updateStepsListChain')

        const filteredList = _filter(getters.stepsListChain, step => getters.hiddenSteps.indexOf(step.id) === -1)
        const differenceList = _filter(filteredList, (step, index) => (getters.visibleList[index] || {}).id !== filteredList[index].id)
        let index = 1
        for (const step of filteredList) {
            dispatch('updateStep', {
                id: step.id,
                data: {index}
            })
            if (!step.isHidden) {
                index++
            }
        }

        if (_size(differenceList) || (filteredList.length !== getters.visibleList.length)) {
            const defaults = getters.isContactDetailsPage ? getters.getContactsDefaults() : getters.defaultValues()
            _map(filteredList, step => {
                if (!step.value && defaults[step.id]) {
                    dispatch('updateStep', {
                        id: step.id,
                        data: {
                            value: defaults[step.id]
                        }
                    })
                }
            })

            commit('VISIBLE_LIST', filteredList)
        }

        return getters.visibleList
    },
    fetchStepList: ({getters, commit, dispatch, rootGetters}) => {
        if (!_size(getters.stepList)) {
            const params = {
                frames_name: getters.framesName,
                store_code: getters.storeCode,
                product_config: rootGetters['configurator/product_config'],
                assets_url: rootGetters['storeView/assets_url'],
                sunglasses_sections: rootGetters['configurator/sunglasses_sections'],
                transitions_sections: rootGetters['configurator/transitions_sections'],
                antireflective_sections: rootGetters['configurator/antireflective_sections'],
                upgrades_section: rootGetters['configurator/upgrades_section'],
                warranty_section: rootGetters['configurator/warranty_section'],
                antireflective_price: rootGetters['configurator/antireflective_price']
            }
            const step_list = _map(getStepList(params), step => {
                if (rootGetters['lensablplus_customer/isRedeemFlow'] && step.id === 'prescription_type') {
                    // let type = rootState['lensablplus_customer'].prescription_ids[rootState['lensablplus_customer'].prescriptionType]
                    // step.default_value = type
                    // step.defaultValue = type
                }
                return Object.assign(step, {
                    value: '',
                    index: null,
                    sequence_number: null,
                    disabled: false,
                    stepState: STATE_WAIT,
                    showNextStepButton: false,
                    nextStepLoading: false,
                    options: {},
                    canEdit: false,
                    showControls: false,
                    shouldHighlight: false,
                    isReady: false,
                    isCurrent: false,
                    isHidden: false,
                    defaultValue: '',
                    hasOneOnlyOption: '',
                    continueLabel: 'Continue',
                    summaryLabel: '',
                    stepHeaderLabel: '',
                    redeemable: false
                })
            })

            dispatch('fetchLimiters')
            commit('STEP_LIST', step_list)

            _map(step_list, step => {
                dispatch('fetchStep', step.id)
            })
        }
    },
    fetchLimiters: ({state, getters, commit}) => {
        const {limiters} = state.landing_config
        if (!limiters) {
            commit('UPDATE_STATE_DATA', {
                landing_config: {
                    limiters: {}
                }
            })
        }
        _map(getLimiters({frames_name: getters.framesName}), (limiter, key) => {
            commit('UPDATE_STATE_DATA', {
                landing_config: {
                    limiters: {
                        [key]: limiter
                    }
                }
            })
        })
        return state.landing_config.limiters
    },
    fetchNewStepList: ({commit, getters, dispatch, rootGetters}) => {
        commit('STEP_LIST', [])
        commit('STEPS_LIST_CHAIN', [])
        commit('VISIBLE_LIST', [])
        dispatch('fetchStepList')

        _map(getters.stepList, step => {
            const stepConfig = getters.getStepConfig(step.id)
            if (stepConfig) {
                stepConfig.value = rootGetters['values/values'][step.id]
                dispatch('updateStep', {
                    id: step.id,
                    data: stepConfig
                })
            }
        })

        dispatch('updateVisibleList')
    },
    fetchStep: ({state, commit, getters, dispatch, rootGetters}, id) => {
        if (!getters.getStepObject(id)) {
            if (!_size(getters.stepList)) {
                dispatch('fetchStepList')
            }
            const stepConfig = getters.getStepConfig(id)
            if (stepConfig) {
                commit('STEP_OBJECT', {
                    ...stepConfig,
                    value: rootGetters['values/values'][id]
                })
            }
        }
    },
    updateStep: ({commit, getters, dispatch}, {id, data}) => {
        if (!getters.getStepObject(id)) {
            dispatch('fetchStep', id)
        }
        commit('UPDATE_STEP_OBJECT', {id, data})
    },
    stepContinuePromise: ({getters, dispatch, rootGetters}, step) => {
        return new Promise((resolve, reject) => {
            if (!step.nextStepLoading) {
                const continuePromise = step.continue_promise || getters.getStepContinuePromise(step)

                dispatch('updateStep', {
                    id: step.id,
                    data: {nextStepLoading: true}
                })

                if (!continuePromise) {
                    return resolve()
                }

                if (_isFunction(rootGetters['promiseService'][continuePromise])) {
                    return rootGetters['promiseService'][continuePromise]().then(resolve, reject)
                }
                return reject(continuePromise)
            }
        })
    },
    stepEdit: ({dispatch}, step) => {
        return new Promise((resolve) => {
            if (step.stepState === STATE_READY) {
                dispatch('activateStep', step.id)
                resolve(step)
            }
        })
    },
    configuratorReset: ({getters, commit, dispatch}) => {
        const defaults = getters.isContactDetailsPage ? getters.getContactsDefaults() : getters.defaultValues()

        commit('values/updateValues', defaults, { root: true })
        commit('UPDATE_STEPS_VALUES', defaults)
        dispatch('updateVisibleList')
        dispatch('activateFirstVisibleStep')
    },
    switchToStep: ({dispatch}, step) => {
        dispatch('setAnimationLock', true)
        dispatch('activateStep', step.id)
        dispatch('setAnimationLock', false)
    },
    updateNextStepsValues: ({getters, commit, dispatch, rootGetters}, stepId) => {
        const [, rights] = getters.getLeftAndRightStepIds(stepId)
        _map(rights, id => {
            const step = getters.getStepObject(id)
            if (id === LENS_TYPE_ID) {
                commit('values/setDataToValues', {[step.id]: step.defaultValue}, { root: true })

                dispatch('updateStep', {id, data: {value: step.defaultValue}})
            }
        })
    },
}

export const mutations = {
    ...abstract.mutations,
    ANIMATION_LOCK: (state, animation = false) => state.animationLock = animation || false,
    STEP_STATES: (state, {id, stepState}) => state.stepStates[id] = stepState,
    STEP_OBJECT: (state, stepConfig = {}) => stepConfig.id ? state.stepObjects[stepConfig.id] = stepConfig : false,
    UPDATE_STEP_OBJECT: (state, {id, data}) => {
        const stepObject = state.stepObjects[id]
        if (stepObject) {
            for (const [key, value] of Object.entries(data)) {
                if (!_isEqual(value, stepObject[key])) {
                    Vue.set(stepObject, key, value)
                }
            }
        }
    },
    UPDATE_STEPS_VALUES: (state, values = {}) => {
        _map(values, (value, id) => {
            const stepObject = state.stepObjects[id]
            if (stepObject && !_isEqual(value, stepObject.value)) {
                Vue.set(stepObject, 'value', value)
            }
        })
    },
    STEP_LIST: (state, stepList = []) => state.step_list = stepList,
    STEPS_LIST_CHAIN: (state, listChain = []) => state.stepsListChain = listChain,
    VISIBLE_LIST: (state, list = []) => state.visibleList = list,
    HIDDEN_STEPS: (state, hidden_steps = []) => state.hidden_steps = hidden_steps
}

export default () => ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
