<template>
    <CatalogProductBoseView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogProductBoseView from '../../../components/catalog/product/CatalogProductBoseView.vue'

export default {
    name: 'RouterCatalogProductBose',
    components: {
        CatalogProductBoseView
    },
    extends: App
}
</script>
