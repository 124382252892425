var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "membership-page-view" },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-col",
            [
              _c(
                "MembershipAbstract",
                {
                  attrs: {
                    "header-text":
                      "Membership ID " + _vm.currentMemberShipIncrementId
                  }
                },
                [
                  _c(
                    "BlockLoader",
                    { attrs: { loading: !_vm.loaded } },
                    [_c("MembershipData")],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "MembershipAbstract",
                { attrs: { "header-text": "Payment Information" } },
                [
                  _c(
                    "BlockLoader",
                    { attrs: { loading: !_vm.loaded } },
                    [_c("MembershipPayment")],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "MembershipAbstract",
                { attrs: { "header-text": "Billing Address" } },
                [
                  _c(
                    "BlockLoader",
                    { attrs: { loading: !_vm.loaded } },
                    [_c("MembershipFormAddress")],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "MembershipAbstract",
                { attrs: { "header-text": "Recent Orders" } },
                [
                  _c(
                    "BlockLoader",
                    { attrs: { loading: !_vm.loaded } },
                    [_c("MembershipRecentOrders")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        [
          _c(
            "v-col",
            [
              _c(
                "MembershipAbstract",
                { attrs: { "header-text": "Contact Lens Benefit" } },
                [
                  _c("MembershipContactLens", {
                    attrs: { "data-loaded": _vm.loaded }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "MembershipAbstract",
                { attrs: { "header-text": "Eye Exam Benefit" } },
                [
                  _c("MembershipEyeExam", {
                    attrs: {
                      "redeem-item-type": _vm.getRedeemTypeWithPrefix("exam"),
                      "data-loaded": _vm.loaded
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isBusinessMember
            ? _c(
                "v-col",
                [
                  _c(
                    "MembershipAbstract",
                    { attrs: { "header-text": "Lensabl+ Group Member" } },
                    [
                      _c("BusinessLensabalplusMembershipContactLens", {
                        attrs: {
                          "redeem-item-type": _vm.getRedeemTypeWithPrefix(
                            "frames"
                          ),
                          "data-loaded": _vm.loaded
                        }
                      }),
                      _vm._v(" "),
                      _c("BusinessNonLensabl", {
                        attrs: {
                          "redeem-item-type": _vm.getRedeemTypeWithPrefix(
                            "digital_voucher"
                          ),
                          "data-loaded": _vm.loaded
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-col",
                [
                  _c(
                    "MembershipAbstract",
                    {
                      attrs: {
                        "header-text": "Frame or Lens Replacement Benefit"
                      }
                    },
                    [
                      _c("MembershipFrameOrLensReplacement", {
                        attrs: {
                          "redeem-item-type": _vm.getRedeemTypeWithPrefix(
                            "frames"
                          ),
                          "data-loaded": _vm.loaded
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }