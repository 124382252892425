export const state = () => ({
    prescriptions: []
})

export const getters = {
    lens_prescriptions: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.allPrescriptions.lensPrescriptions
    },
    contacts_prescriptions: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.allPrescriptions.contactsPrescriptions
    },
    hideContactsTab: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.hideContactsList || false
    }
}

export default () => ({
    namespaced: true,
    state,
    getters
})
