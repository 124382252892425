var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDiscount
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "totals-price-subtotal total-line",
            class: _vm.className
          },
          [
            _c("span", { staticClass: "total-label lplus-label" }, [
              _vm._v(
                "Member Discount (" + _vm._s(_vm.discountLabelByAction) + ")"
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "total-value value" }, [
              _c("span", { staticClass: "total-value-currency" }, [
                _vm._v("-" + _vm._s(_vm.currencySymbol))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value-amount" }, [
                _vm._v(_vm._s(_vm.discountTotalByAction))
              ])
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }