var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "spectacles-banner frames-banner bose-banner" }, [
      _c("div", {
        staticClass: "sb-inline sb-inner-img  bose",
        style: { backgroundImage: "url(" + _vm.getCategoryImage + ")" }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bose-header-links" }, [
      _c("div", { staticClass: "bose-header-links-container" }, [
        _c("div", {
          directives: [
            {
              name: "dompurify-html",
              rawName: "v-dompurify-html",
              value: _vm.breadcrumbsHtml,
              expression: "breadcrumbsHtml"
            }
          ],
          staticClass: "frames-breadcrumbs"
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1)
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "category-products frames-products bose" },
      [
        _vm.categoryLoader
          ? _c("PageLoader", { attrs: { load: _vm.categoryLoader } })
          : [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "category-wrapper-inline category-wrapper-title"
                  },
                  [_c("span", [_vm._v("The Latest Look")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "products wrapper grid products-grid category-wrapper-inline"
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "products list items product-items" },
                      _vm._l(_vm.productsByPosition("1"), function(product) {
                        return _c(
                          "li",
                          {
                            key: product.url,
                            staticClass: "item product product-item"
                          },
                          [
                            _c("CatalogCategoryListItem", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "category-wrapper-inline category-wrapper-title"
                  },
                  [_c("span", [_vm._v("Sport Sunglasses")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "products wrapper grid products-grid category-wrapper-inline"
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "products list items product-items" },
                      _vm._l(_vm.productsByPosition("2"), function(product) {
                        return _c(
                          "li",
                          {
                            key: product.url,
                            staticClass: "item product product-item"
                          },
                          [
                            _c("CatalogCategoryListItem", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "category-wrapper-inline category-wrapper-title"
                  },
                  [_c("span", [_vm._v("The Original Bose Sunglasses")])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "products wrapper grid products-grid category-wrapper-inline"
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "products list items product-items" },
                      _vm._l(_vm.productsByPosition("3"), function(product) {
                        return _c(
                          "li",
                          {
                            key: product.url,
                            staticClass: "item product product-item"
                          },
                          [
                            _c("CatalogCategoryListItem", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ])
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Already have the frames and just need new lenses?"),
      _c("a", { attrs: { href: "order-lenses-bose" } }, [_vm._v("Click Here")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "a",
        {
          attrs: {
            href: "mailto:support@lensabl.com?subject=Bose Frames Question"
          }
        },
        [_vm._v("Need Help?")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }