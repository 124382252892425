import {isMobileOnly} from 'mobile-device-detect'

export const state = () => {
    const device = {
        isTouchDevice: 'ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch),
        isWinPhoneDevice: navigator.msPointerEnabled && /MSIE 10.*Touch/.test(navigator.userAgent),
        isMobileDevice: navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Opera Mini/i) || navigator.userAgent.match(/IEMobile/i),
        isTablet: false,
        isMobile: false,
        isMobileOnly: isMobileOnly,
        isRetina: window.devicePixelRatio === 2
    }
    if (navigator.msPointerEnabled && navigator.msDoNotTrack) {
        device.eventPress = 'MSPointerDown'
        device.eventMove = 'MSPointerMove'
        device.eventRelease = 'MSPointerUp'
    } else {
        device.eventPress = device.isTouchDevice ? 'touchstart' : 'mousedown'
        device.eventMove = device.isTouchDevice ? 'touchmove' : 'mousemove'
        device.eventRelease = device.isTouchDevice ? 'touchend' : 'mouseup'
    }

    return device
}

export const getters = {
    deviceProperty: state => state,
    isMobile: state => state.isMobile,
    isMobileOnly: state => state.isMobileOnly,
    isTablet: state => state.isTablet,
    isDesktop: state => !state.isMobile && !state.isTablet
}

const mutations = {
    IS_MOBILE: (state, isMobile) => state.isMobile = isMobile,
    IS_TABLET: (state, isTablet) => state.isTablet = isTablet
}

export default () => ({
    namespaced: true,
    state,
    getters,
    mutations
})
