var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      label: _vm.label,
      rules: [
        function(v) {
          return !!v || _vm.errText
        }
      ],
      items: _vm.options,
      value: _vm.formModel[_vm.formKey],
      "item-text": "label",
      "item-value": "value",
      height: "54"
    },
    model: {
      value: _vm.formModel[_vm.formKey],
      callback: function($$v) {
        _vm.$set(_vm.formModel, _vm.formKey, $$v)
      },
      expression: "formModel[formKey]"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }