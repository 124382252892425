import axios from 'axios'

const CONTROLLER = 'plus/freememebership/'
const getDefaultState = () => {
    return {
        createdSuccess:false,
        orderId: null,
        loading: false
    }
}

const state = getDefaultState()
const mutations = {
    SET_CREATED_SUCCESS: (state, createdSuccess = false) => state.createdSuccess = createdSuccess,
    SET_ORDER_ID : (state, orderId = false) => state.orderId = orderId,
    SET_LOADING : (state, loading = false) => state.loading = loading
}
const actions = {
    getLastOrderId:({rootGetters, commit}) => {
        const url = rootGetters['storeView/base_url'] + CONTROLLER + 'getLastOrderId'
        axios.get(url).then(({data, status}) => {
            if (status === 200) {
                const response = data.data
                commit('SET_ORDER_ID', response.order_id)

                setTimeout(() => {
                    commit('SET_LOADING', true)
                }, 3000)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    createMemebership: ({rootGetters, getters, commit}) => {
        const url = rootGetters['storeView/base_url'] + CONTROLLER
        const params = {order_id : getters.getOrderId}
        return axios.post(url, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                const response = data.data
                commit('SET_CREATED_SUCCESS', response.created_success)
            }
        }).catch(error => {
            console.error(error)
        })
    }
}
const getters = {
    getCreatedSuccess: state => state.createdSuccess,
    getOrderId: state => state.orderId,
    getLoading: state => state.loading
}

export default store => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
