import _map from 'lodash/map'
import _size from 'lodash/size'
import _isEqual from 'lodash/isEqual'
import Vue from 'vue'
import axios from 'axios'
import getPlansInfo from '../constants/plans'
import {
    CONTACTS_SUBSCRIPTIONS_FREQUENCIES
} from '../../../contacts/product/storage/product/view'

import {
    STATE_CURRENT,
    STATE_READY,
    STATE_WAIT
} from '../../../../app/utils/constants'
import {
    OBJECTS_CHAIN
} from '../constants/dashboardChain'
import {
    COMPONENT_ID_DASHBOARD_PLANS,
    COMPONENT_ID_DEPENDENTS,
    COMPONENT_ID_PLUS_DASHBOARD,
    COMPONENT_ID_DASHBOARD_PAYMENT,
    getObjectList
} from '../constants/dashboard'
import _cloneDeep from 'lodash/cloneDeep'
import _first from 'lodash/first'
import _find from 'lodash/find'
import * as abstract from '../../../../storage/abstract'

const CONTROLLER_ROOT = 'plus/'
const CONTROLLER = 'plus/account'
const AVAILABLE_URL_REDEEM_FRAMES = 'plus/shop-frames/'
const AVAILABLE_URL_REDEEM_CONTACTS = 'plus/redeem_contactlenses'
const AVAILABLE_URL_REDEEMS_FRAMES = [
    AVAILABLE_URL_REDEEM_FRAMES,
    'plus/account'
]
const AVAILABLE_URL_REDEEMS_LENS = 'choose-my-lenses'
const AVAILABLE_URL_REDEEMS_EXAM = 'online-eye-exam-qualification'
const AVAILABLE_URL_REDEEMS = [
    AVAILABLE_URL_REDEEMS_LENS,
    AVAILABLE_URL_REDEEMS_EXAM,
    AVAILABLE_URL_REDEEM_CONTACTS
]
export const DEFAULT_REDEEMABLE_PRODUCT_DATA = {
    has_redeemed: true,
    product_data: {},
    receipt_data: {}
}
export const STATUS_PENDING = 'pending'
export const STATUS_APPROVED = 'approved'
export const STATUS_COLLECTED = 'collected'
export const STATUS_REJECTED = 'rejected'
export const STATUS_FINISHED = 'finished'

// for employee payment information
export const ADDRESS_TEMPLATE_DATA = [
    {
        name: 'first_name',
        label: 'First Name',
        required: true,
        type: 'text'
    },
    {
        name: 'last_name',
        label: 'Last Name',
        required: true,
        type: 'text'
    },
    {
        name: 'street',
        label: '',
        type: 'text',
        required: true,
        options: [
            {
                label: 'Address Line 1',
                type: 'text',
                required: true,
                name: 'address1'
            },
            {
                label: 'Address Line 2',
                type: 'text',
                required: false,
                name: 'address2'
            }
        ]
    },
    {
        name: 'country',
        label: 'Country',
        required: true,
        type: 'text',
        disabled: true
    },
    {
        name: 'city',
        label: 'City',
        required: true,
        type: 'text'
    },
    {
        name: 'state',
        label: 'State',
        required: true,
        type: 'select',
        options: []
    },
    {
        name: 'postal_code',
        label: 'Zip/Postal Code',
        required: true,
        type: 'text'
    }
]

const getDefaultState = () => {
    return {
        ...abstract.state(),
        chainStep: _cloneDeep(OBJECTS_CHAIN),
        cookies_tag_business: 'redeem_business',
        componentsList: [],
        businessStepsList: [],
        componentKeys: [
            COMPONENT_ID_DASHBOARD_PLANS,
            COMPONENT_ID_DEPENDENTS,
            COMPONENT_ID_DASHBOARD_PAYMENT,
            COMPONENT_ID_PLUS_DASHBOARD
        ],
        plans: [],
        loggedIn: false,
        customerName: '',
        customerFullName: '',
        businessMember: false,
        businessEmployee: [],
        businessDependents: [],
        customerId: null,
        membership_id: null,
        free_membership: false,
        notifications: [],
        paidUp: false,
        plusMember: false,
        redeemable_products: {
            contacts_left: {
                ...DEFAULT_REDEEMABLE_PRODUCT_DATA
            },
            contacts_right: {
                ...DEFAULT_REDEEMABLE_PRODUCT_DATA
            }
        },
        redeemflow: false,
        redeemed: {
            contacts: false,
            lenses: false,
            vision: false,
            voucher: false
        },
        prescription_ids: {
            'progressive': 'progressive_standard',
            'single vision': 'single_vision'
        },
        only_lenses_discount: [],
        percentages: {
            lens: 0,
            contacts: 0
        },
        plansInfo: [],
        plan_price: {
            progressive_standard: 0,
            single_vision: 0,
            contacts: 0,
            non_lensabl: 0
        },
        btnTitles: [],
        btnDisabled: [],
        redeemedStyles: [],
        planItemId: '',
        history: [],
        plan: {},
        planSelected: false,
        giftcardConfigs: {},
        prefix: 'business_',
        employeePayment: true,
        days_before_deleting: null,
        component_help: {
            non_lensabl: {
                name: 'help-bubble',
                params: {
                    text: ''
                }
            },
            discount: {
                name: 'help-bubble',
                params: {
                    'text': ''
                }
            },
            trial: {
                name: 'help-bubble',
                targetClasses: 'help-bubble-text-link',
                containerClasses: 'help-component-container-link-blue',
                classes: 'help-bubble help-bubble-list',
                params: {
                    'text': '',
                    'targetContent': 'View All Plan Benefits'
                }
            }
        },
        trial_expiring_data: {},
        // Axios Ajax Loading
        accountDataLoading: false,
        loadingCard: false,
        configsGiftCardLoading: false,
        membershipInfoLoading: false,
        api: {
            urls: {
                giftCardConfigs: `/${CONTROLLER_ROOT}tremendous/getConfigs`,
                createRewardTremendous: `/${CONTROLLER_ROOT}tremendous/createOrLoadCard`,
                createRewardLensabl: `/${CONTROLLER_ROOT}ajax/sendEmailToCS`,
                getMembershipInfo: `/${CONTROLLER}/getMembershipInfo`,
                getMembershipConfigs: `/${CONTROLLER}/getPlanConfigs`,
                removeNotification: `/${CONTROLLER_ROOT}ajax/notificationRead`,
                getBtnTitlesStyles: `/${CONTROLLER_ROOT}ajax/getTitlesBtn`,
                cleanCart: `/${CONTROLLER_ROOT}cart/cleanCart`,
                getAccountData: `/${CONTROLLER_ROOT}account/getAccountData`,
                employeeSelectPlan: `/${CONTROLLER_ROOT}group/employeeSelectPlan`,
                setRedeemed: `/${CONTROLLER_ROOT}account/setRedeemed`,
                getRenewData: `/${CONTROLLER_ROOT}account/getRenewData`,
                addPlanCustomer: `/${CONTROLLER_ROOT}account/addPlanCustomer`,
                setSessionData: `/${CONTROLLER_ROOT}account/setSessionData`
            }
        }
    }
}
// initial state
const state = getDefaultState()

const mutations = {
    ...abstract.mutations,
    COMPONENT_LIST: (state, list = []) => state.componentsList = list,
    resetState: (state) => Object.assign(state, getDefaultState()),
    RESET_STATE: (state) => Object.assign(state, getDefaultState()),
    CUSTOMER_LOGGED_IN: (state, loggedIn = false) => state.loggedIn = Boolean(loggedIn),
    PLAN_ITEM_ID: (state, id = null) => state.planItemId = parseInt(id),
    CUSTOMER_NAME: (state, name = []) => state.customerName = name,
    CUSTOMER_FULL_NAME: (state, name = []) => state.customerFullName = name,
    BUSINESS_MEMBER: (state, member = false) => state.businessMember = member,
    BUSINESS_EMPLOYEE: (state, data = []) => state.businessEmployee = data,
    BUSINESS_DEPENDENTS: (state, data = []) => state.businessDependents = data,
    CUSTOMER_ID: (state, id = []) => state.customerId = id,
    MEMBERSHIP_ID: (state, id = null) => {
        state.membership_id = parseInt(id)
    },
    FREE_MEMBERSHIP: (state, free_membership = false) => state.free_membership = free_membership,
    NOTIFICATIONS: (state, data = []) => state.notifications = data,
    PAID_UP: (state, paid = false) => state.paidUp = Boolean(paid),
    PLUS_MEMBER: (state, plus = false) => state.plusMember = Boolean(plus),
    REDEEMABLE_PRODUCTS: (state, products = {}) => state.redeemable_products = products,
    DAYS_BEFORE_DELETING: (state, days = null) => state.days_before_deleting = days,
    TRIAL_EXPIRING_DATA: (state, trial_expiring_data = {}) => {
        state.component_help['trial']['params']['text'] = `<span>Your plan will include:</span>
        <ul class="">
        <li><b>A FREE Everyday Eyewear Frame with lenses OR lens replacement (${trial_expiring_data['plan_name']}, clear or blue-light blocking polycarbonate lenses for either choice)</b></li>
        <li>$60 Lensabl gift card for taking an eye exam</li>
        <li>25% off all other frames and lenses (or lens enhancements)</li>
        <li>15% off contact lenses</li>
        <li>Insider access to new frame and lens products</li>
        <li>Priority customer service</li>
        </ul>`
        state.trial_expiring_data = trial_expiring_data
    },
    UPDATE_REDEEMED: (state, data = {}) => {
        _map(data, (value, key) => {
            if (state.redeemed.hasOwnProperty(key) && !_isEqual(value, state.redeemed[key])) {
                Vue.set(state.redeemed, key, value)
            }
        })
    },
    COMPONENT_DATA: (state, data = {}) => {
        const obj = _find(state.componentsList, obj => obj.id === data.id)
        _map(data, (value, key) => {
            if (obj && !_isEqual(value, obj[key])) {
                Vue.set(obj, key, value)
            }
        })
    },
    STEPS_LIST: (state, list = []) => {
        list.splice(5, 1)
        state.businessStepsList = list
    },
    SELECTED_PLAN: (state, plan) => state.plan = plan,
    UPDATE_REDEEMED_FLOW: (state, redeem) => {
        state.redeemflow = redeem
    },
    PLANS_INFO: (state, info = []) => state.plansInfo = info,
    ONLY_LENSES_DISCOUNT: (state, data = []) => state.only_lenses_discount = data,
    PERCENTAGES: (state, percentages) => {
        state.percentages = percentages
        state.component_help['discount']['params']['text'] = `Save ${percentages['lens']}% on additional frames and lens enhancements and ${percentages['contacts']}% on contacts`
    },
    PLAN_PRICE: (state, planPrice = []) => {
        state.plan_price = planPrice
        state.component_help['non_lensabl']['params']['text'] = `If you make a frame or lens purchase outside of Lensabl, we will reimburse you up to the total value of that order, up to $${planPrice['non_lensabl']} on a digital Visa gift card. By using this "non-Lensabl+ benefit", you'll forgo your frame and contacts benefits. For any questions, please contact <a style="color:#79C0E9" href="malto:support@lensabl.com">support@lensabl.com</a>`
    },
    NOTIFICATION_READ: (state, index = null) => state.notifications[index].is_read = 1,
    BTN_TITLES: (state, btnTitles = []) => state.btnTitles = btnTitles,
    BTN_DISABLED: (state, btnDisabled = []) => state.btnDisabled = btnDisabled,
    REDEEMED_STYLES: (state, redeemedStyles = []) => state.redeemedStyles = redeemedStyles,
    UPDATE_REDEEM_ITEM_DATA: (state, {type = '', data = {}}) => {
        const redeemItemData = state.redeemable_products[type] || {}
        const receiptData = redeemItemData['receipt_data'] || {}
        _map(data, (value, key) => {
            if (receiptData.hasOwnProperty(key) && !_isEqual(value, receiptData[key])) {
                Vue.set(receiptData, key, value)
            }
        })
    },
    GIFTCARD_CONFIGS: (state, giftcardConfigs = {}) => state.giftcardConfigs = giftcardConfigs,
    SET_EMPLOYEE_PAYMENT: (state, value) => state.employeePayment = value,

    ACCOUNT_DATA_LOADING: (state, loading = false) => state.accountDataLoading = loading,
    LOADING_CARD: (state, loadingCard = false) => state.loadingCard = loadingCard,
    CONFIGS_GIFT_CARD_LOADING: (state, loading = false) => state.configsGiftCardLoading = loading,
    MEMBERSHIP_INFO_LOADING: (state, loading = false) => state.membershipInfoLoading = loading
}

const actions = {
    ...abstract.actions,
    configsGiftcard: ({state, commit}) => {
        if (!state.configsGiftCardLoading) {
            commit('LOADING_CARD', true)
            commit('CONFIGS_GIFT_CARD_LOADING', true)
            axios.get(state.api.urls.giftCardConfigs).then(({data, status}) => {
                if (status === 200) {
                    commit('GIFTCARD_CONFIGS', data.data)
                    commit('LOADING_CARD', false)
                    commit('CONFIGS_GIFT_CARD_LOADING', false)
                }
            }).catch(error => {
                commit('LOADING_CARD', false)
                commit('CONFIGS_GIFT_CARD_LOADING', false)
                console.error(error)
            })
        }
    },
    createRewardTremendous: ({state, getters, commit, rootGetters}, {value, type}) => {
        commit('LOADING_CARD', true)
        const params = {
            'membership_id': getters['membershipId'],
            'value': value,
            'type': type
        }
        axios.post(state.api.urls.createRewardTremendous, params).then(({data, status}) => {
            if (status === 200) {
                document.location = data.data.link
            }
        }).catch(error => {
            console.error(error)
            commit('LOADING_CARD', false)
        })
    },
    createRewardLensabl: ({state, getters, commit, rootGetters, dispatch}, {value, type}) => {
        commit('LOADING_CARD', true)
        const params = {
            'membership_id': getters['membershipId'],
            'value': value,
            'type': type
        }
        axios.post(state.api.urls.createRewardLensabl, params).then(({data, status}) => {
            if (status === 200) {
                commit('LOADING_CARD', false)
                dispatch('fetchData')
            }
        }).catch(error => {
            console.error(error)
            commit('LOADING_CARD', false)
        })
    },
    resetCustomerState: ({commit}) => {
        commit('RESET_STATE')
    },
    preparePlusFramesPrice: ({rootGetters}) => {
        const checkoutData = rootGetters['values/checkoutData']
        const framesPrice = rootGetters['summary/subTotalAmountFrames'] - rootGetters['summary/getRedeemFramesPercentDiscount']
        checkoutData.custom_options.frames_price = framesPrice
    },
    getMembershipInfo: ({state, commit}) => {
        // if (!state.membershipInfoLoading) {
        commit('MEMBERSHIP_INFO_LOADING', true)
        axios.get(state.api.urls.getMembershipInfo).then(({data, status}) => {
            if (status === 200) {
                commit('MEMBERSHIP_INFO_LOADING', false)
                commit('UPDATE_STATE_DATA', data)
                commit('SET_EMPLOYEE_PAYMENT', data['business_plan_payer'] === 'Employee')
            }
        }).catch(error => {
            commit('MEMBERSHIP_INFO_LOADING', false)
            console.error(error)
        })
        // }
    },
    getMembershipConfigs: ({state, commit}) => {
        axios.get(state.api.urls.getMembershipConfigs).then(({data, status}) => {
            if (status === 200) {
                _map(data, (value, key) => {
                    commit((key || '').toUpperCase(), value)
                })
                commit('UPDATE_STATE_DATA', data)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    REMOVE_NOTIFICATION: ({state, getters, commit, rootGetters}, {id}) => {
        const params = {}
        params.notification_id = id
        params.customer_id = getters.customerId
        const postData = 'post=' + JSON.stringify(params)
        return axios.post(state.api.urls.removeNotification, postData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                const index = getters.notifications.findIndex(obj => obj.notification_id === data.data.notification_id)
                commit('NOTIFICATION_READ', index)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    getBtnTitlesStyles: ({state, commit}) => {
        return axios.get(state.api.urls.getBtnTitlesStyles)
            .then(({data, status}) => {
                if (status === 200) {
                    commit('BTN_TITLES', data.titles)
                    commit('BTN_DISABLED', data.disabled)
                    commit('REDEEMED_STYLES', data.styles)
                }
            })
            .catch(error => console.error(error))
    },
    cleanCart: ({state}) => {
        return axios.get(state.api.urls.cleanCart)
            .catch(error => console.error(error))
    },
    fetchData: async ({state, commit, dispatch}) => {
        commit('BUSINESS_DEPENDENTS')
        commit('BUSINESS_EMPLOYEE')
        commit('ACCOUNT_DATA_LOADING', true)
        return await axios.get(state.api.urls.getAccountData)
            .then(({data, status}) => {
                if (status === 200) {
                    _map(data, (value, index) => {
                        commit(String(index).toUpperCase(), value)
                    })
                    commit('UPDATE_STATE_DATA', data)
                }
                dispatch('updateRedeemedData')
            }).finally(() => {
                commit('ACCOUNT_DATA_LOADING', false)
            })
    },
    updateRedeemedData: ({state, getters, commit}) => {
        const prefix = getters.getPrefixRedeem
        if (_size(state.redeemable_products[prefix + 'contacts_left']) || _size(state.redeemable_products[prefix + 'contacts_right'])) {
            commit('UPDATE_REDEEMED', {
                contacts: parseInt(state.redeemable_products[prefix + 'contacts_left'].has_redeemed) === 1 || parseInt(state.redeemable_products[prefix + 'contacts_right'].has_redeemed) === 1
            })
        }
    },
    leaving: ({commit, getters, dispatch}) => {
        const redeem_path = JSON.parse(sessionStorage.getItem('redeem_path'))
        if (redeem_path !== null) {
            if (getters.isRedeemFlow && redeem_path.previous === '/plus/account/') {
                commit('UPDATE_REDEEMED_FLOW', true)
            } else if (getters.isRedeemFlow && redeem_path.current.includes('plus/shop-frames') && redeem_path.previous.includes('plus/shop-frames')) {
                commit('UPDATE_REDEEMED_FLOW', true)
            } else if (getters.isRedeemFlow && redeem_path.current !== redeem_path.previous) {
                commit('UPDATE_REDEEMED_FLOW', false)
            }
        }
        dispatch('setCookies')
    },
    checkRedeemablePathBusiness: ({getters, commit, dispatch, state}, current_path) => {
        let isRedeemFlow = false
        const cookiesRedeem = JSON.parse(sessionStorage.getItem(state.cookies_tag_business)) || {}
        if (current_path.indexOf('contact') !== -1) {
            const contactRedeem = cookiesRedeem['contacts']
            if (contactRedeem && !getters.isContactsRedeemed) {
                isRedeemFlow = true
            }
        } else if (current_path.indexOf(AVAILABLE_URL_REDEEMS_LENS) !== -1 || current_path.indexOf(AVAILABLE_URL_REDEEM_FRAMES) !== -1) {
            const framesRedeem = cookiesRedeem['frames']
            if (!getters.isLensesRedeemed && framesRedeem) {
                isRedeemFlow = true
            }
        } else if (current_path.indexOf(AVAILABLE_URL_REDEEMS_EXAM) !== -1) {
            const examRedeem = cookiesRedeem['exam']
            if (examRedeem && !getters.isVisionRedeemed) {
                isRedeemFlow = true
            }
        }
        if (isRedeemFlow) {
            sessionStorage.setItem('redeemed_type', 'business')
        }
        commit('UPDATE_REDEEMED_FLOW', isRedeemFlow)
        dispatch('setCookies')
    },
    checkRedeemablePath: ({getters, commit, dispatch, state}) => {
        const redeem_path = JSON.parse(sessionStorage.getItem('redeem_path'))
        const current_path = window.location.pathname
        let availableUrl = false
        let availableUrlFrames = false
        if (getters.isBusinessMember) {
            dispatch('checkRedeemablePathBusiness', current_path)
            return
        }
        _map(AVAILABLE_URL_REDEEMS, url => {
            if (current_path.includes(url)) {
                availableUrl = true
            }
        })
        _map(AVAILABLE_URL_REDEEMS_FRAMES, url => {
            if (current_path.includes(url)) {
                availableUrlFrames = true
            }
        })

        if (getters.membershipId && getters.isRedeemFlow && availableUrl) {
            commit('UPDATE_REDEEMED_FLOW', true)
        } else if (getters.membershipId && availableUrlFrames) {
            commit('UPDATE_REDEEMED_FLOW', true)
        } else {
            commit('UPDATE_REDEEMED_FLOW', false)
        }

        if (redeem_path !== null && getters.isRedeemFlow && !getters.isBusinessMember) {
            if (!redeem_path.previous.includes('plus/account') && (!redeem_path.previous.includes(current_path) && !redeem_path.previous.includes('plus/shop-frames'))) {
                commit('UPDATE_REDEEMED_FLOW', false)
            } else if (!redeem_path.current.includes('plus/account') && (!redeem_path.current.includes(current_path) && !redeem_path.current.includes('plus/shop-frames'))) {
                commit('UPDATE_REDEEMED_FLOW', false)
            }
        }
        dispatch('setCookies')
    },
    fetchPlansInfo: ({commit, rootGetters}) => {
        const params = {
            assets_url: rootGetters['storeView/assets_url']
        }
        commit('PLANS_INFO', getPlansInfo(params))
    },
    setCookies: ({getters, state}, payload = {}) => {
        sessionStorage.setItem('is_redeemed_flow', getters.isRedeemFlow)
        let sendObj = {'is_redeemed_flow': getters.isRedeemFlow}

        if (getters.isBusinessMember) {
            sessionStorage.setItem('redeemed_type', 'business')
            sendObj.redeemed_type = 'business'
            const cookiesType = {}
            const type = payload.type
            if (type) {
                const value = payload.value
                cookiesType[type] = value
                sessionStorage.setItem(state.cookies_tag_business, JSON.stringify(cookiesType))
                sendObj.redeem_business = cookiesType
            }
        } else {
            sessionStorage.setItem('redeemed_type', getters.glassesType)
            sendObj.redeemed_type=getters.glassesType
        }
        actions.sendSessionData(sendObj)
    },
    sendSessionData: (payload = {}) => {
        const postData = JSON.stringify(payload)
        return axios.post(state.api.urls.setSessionData, postData, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(({data, status}) => {
            if (status === 200) {
            
            }
        }).catch(error => {
            console.error(error)
        })
    },
    // post selected employee plan
    setEmployeePlan: ({state, commit, rootGetters, getters, dispatch}) => {
        const values = getters.values
        const post = {}

        // if employee is responsible for payment
        if (getters.employeePayment) {
            post['payment'] = values['dashboard-payment']
            post['payment']['token'] = rootGetters['lensablplus_business/cardToken']
        }

        post['plan'] = getters.plan.sku
        post['employee_id'] = rootGetters['lensablplus_business/employeeId']
        post['dependents'] = values.dependents
        axios.post(state.api.urls.employeeSelectPlan, post).then(({data, status}) => {
            if (status === 201) {
                dispatch('getMembershipConfigs')
                dispatch('fetchData')
                commit('lensablplus_business/LOAD', false, {root: true})
            }
        }).catch(error => {
            console.error(error)
        })
    },
    // business onboarding steps
    fetchStepsList: ({getters, commit}) => {
        if (!_size(getters.businessStepsList)) {
            const list = []
            _map(getters.componentsList, obj => {
                if (!list[obj.stepId]) {
                    list[obj.stepId] = {
                        label: `Step ${obj.stepId}`,
                        state: STATE_WAIT,
                        componentIds: [],
                        components: []
                    }
                }
                list[obj.stepId].componentIds.push(obj.id)
                list[obj.stepId].components.push(obj)
            })
            commit('STEPS_LIST', list)
        }
        return getters.businessStepsList
    },
    fetchComponentsList: ({getters, commit, rootGetters}) => {
        if (!_size(getters.componentsList)) {
            const params = {
                assets_url: rootGetters['storeView/assets_url']
            }
            const componentsList = _map(getObjectList(params), obj => {
                const chainItem = _find(getters.chain, item => item.id === obj.id)
                return Object.assign(obj, {
                    value: '',
                    isValidValue: false,
                    state: STATE_WAIT,
                    stepId: chainItem.stepId
                })
            })
            commit('COMPONENT_LIST', componentsList)
            _map(getters.componentsList, obj => commit('COMPONENT_DATA', {
                id: obj.id,
                value: _cloneDeep(obj.default_value)
            }))
        }
    },
    updateComponents: ({state, getters, commit, dispatch}) => {
        if (!_size(getters.componentsList)) {
            dispatch('fetchComponentsList')
        }
        if (!getters.currentComponent) {
            commit('COMPONENT_DATA', {id: getters.firstComponent.id, state: STATE_CURRENT})
        }

        const current = getters.currentComponent

        if (current.isValidValue) {
            const nextChain = getters.getNextChainElement(current)
            if (nextChain) {
                state.history.push(current.id)
                commit('COMPONENT_DATA', {id: current.id, state: STATE_READY})
                commit('COMPONENT_DATA', {id: nextChain.id, state: STATE_CURRENT})
            }
        }

        _map(getters.componentsList, obj => {
            if (getters.currentComponent.id !== obj.id) {
                commit('COMPONENT_DATA', {id: obj.id, state: obj.isValidValue ? STATE_READY : STATE_WAIT})
            }
        })
        dispatch('updateStepsList')
    },
    updateStepsList: ({getters, dispatch, commit}) => {
        if (!_size(getters.businessStepsList)) {
            dispatch('fetchStepsList')
        }
        _map(getters.businessStepsList, step => {
            if (step) {
                let state = STATE_WAIT
                if (_find(step.components, o => o.state === STATE_READY)) {
                    state = STATE_READY
                }
                if (_find(step.components, o => o.state === STATE_CURRENT)) {
                    state = STATE_CURRENT
                }
                if (!_isEqual(step.state, state)) {
                    step.state = state
                }
            }
        })
    },
    setActiveStep: ({state, getters, dispatch}, stepId) => {
        if (getters.currentComponent.stepId > stepId) {
            const history = state.history
            for (let i = history.length - 1; i >= 0; i--) {
                if (parseInt(getters.currentComponent.stepId) !== stepId) {
                    dispatch('goBack')
                }
            }
        }
    },
    sentForRedeemed: ({state, getters, dispatch, rootGetters}, {type, status}) => {
        const postData = {
            'membership_id': getters['membershipId'],
            'types': type,
            'status': status
        }
        return axios.post(state.api.urls.setRedeemed, postData).then(({data, status}) => {
            if (status === 201) {
                dispatch('fetchData')
                dispatch('getMembershipConfigs')
                dispatch('getBtnTitlesStyles')
            }
        }).catch(error => {
            console.error(error)
        })
    },
    fetchTrialExpiringData: ({state, commit}) => {
        commit('ACCOUNT_DATA_LOADING', true)
        return axios.get(state.api.urls.getRenewData)
            .then(({data, status}) => {
                if (status === 201) {
                    commit('TRIAL_EXPIRING_DATA', data)
                }
            }).finally(() => {
                commit('ACCOUNT_DATA_LOADING', false)
            })
    },
    addRenewPlanCustomer: ({state}) => {
        return axios.get(state.api.urls.addPlanCustomer)
    }
}

const getters = {
    chain: state => state.chainStep,
    componentsList: state => state.componentsList,
    businessStepsList: state => state.businessStepsList,
    plans: state => state.plans,
    componentsCount: state => _size(state.componentsList),
    firstComponent: (state, getters) => getters.getObjectConfig(_first(getters.chain).id),
    currentComponent: state => state.businessMember && !state.plusMember ? _find(state.componentsList, obj => obj.state === STATE_CURRENT) : {id: ''},
    companyTemplateData: state => state.companyTemplateData,
    addressTemplateData: state => state.addressTemplateData,
    plan: state => state.plan,
    planSelected: state => state.planSelected,
    getDaysBeforeDeleting: state => state.days_before_deleting,
    getTrialExpiringData: state => state.trial_expiring_data,
    values: state => {
        const values = {}
        _map(state.componentsList, obj => {
            values[obj.id] = obj.value
        })
        return values
    },
    checkoutData: (state, getters) => {
        const values = getters.values
        const plan = values['dashboard-plans']
        const dependents = values['dependents']
        const payment = values['dashboard-payment']

        return {
            plan,
            dependents,
            payment
        }
    },
    getObjectConfig: state => id => _find(state.componentsList, obj => obj.id === id),
    getNextChainElement: (state, getters) => chainObject => {
        const chainItem = _find(getters.chain, obj => obj.id === chainObject.id)
        let component = Object.values(chainItem.next)[0]

        // if company is paying skip payment step
        if (!getters.employeePayment && component === 'dashboard-payment') {
            component = 'plus-dashboard'
        }

        return getters.getObjectConfig(component)
    },
    getHelpComponent: state => state.component_help,
    isBusinessMember: state => state.businessMember,
    getPercentages: state => state.percentages,
    getPercentage: state => type => parseFloat(state.percentages[type]),
    isLensablPlus: (state, getters, rootState, rootGetters) => (getters.isRedeemFlow || getters.membershipId),
    loggedIn: state => state.loggedIn,
    customerName: state => state.customerName,
    customerId: state => state.customerId,
    membershipId: state => state.membership_id,
    isFreeMembership: state => parseInt(state.free_membership),
    glassesType: (state, getters) => state.prescription_ids[getters.defaultPrescriptionType],
    planPrice: (state, getters) => (type = false) => {
        if(type != false) {
            return state.plan_price[type]
        } else {
            return state.plan_price[getters.glassesType]
        }
    },
    getContactsAllowance: (state, getters) => getters.planPrice('contacts'),
    getContactsVoucherAmount: (state, getters) => getters.planPrice('contacts_voucher'),
    getVoucherAmount: (state, getters) => getters.planPrice('voucher'),
    getNonLensablPlus: (state, getters) => getters.planPrice('non_lensabl'),
    notifications: state => state.notifications,
    paidUp: state => state.paidUp,
    plusMember: state => state.plusMember,
    getRedeemedStyles: state => statusId => state.redeemedStyles[statusId],
    getBtnDisabled: state => statusId => state.btnDisabled[statusId],
    getBtnTitlesById: state => statusId => state.btnTitles[statusId],
    getPrefixRedeem: (state, getters) => {
        if (getters.isBusinessMember) {
            return state.prefix
        } else {
            return ''
        }
    },
    prepareMemberDiscountCheckout: (state, getters, rootState, rootGetters) => {
        if (!rootGetters['membershipProgram/hasCustomerMembership']) {
            return []
        }
        if (!rootGetters['membershipProgram/isMemberBenefitGreater']) {
            return []
        }
        const discount = rootGetters['membershipProgram/discountTotalByAction']
        if (discount <= 0) return []
        const discountedItems = []

        if (rootGetters['values/isContactDetailsPage']) {
            const eyeData = rootGetters['contacts/getEyeData']
            Object.keys(eyeData).map(eye => {
                discountedItems.push(eyeData[eye].product_sku)
            })
        } else {
            const {frames_type} = rootGetters['values/values']
            if (frames_type !== 'frames_standard') {
                discountedItems.push(rootGetters['values/checkoutData'].product)
            }
            if (rootGetters['values/isFramesSeparated'] && rootGetters['values/values'].frames_SKU) {
                discountedItems.push(rootGetters['values/values'].frames_SKU)
            }
        }
        // const addUpgrades = rootGetters['values/values'].upgrades
        // Object.entries(addUpgrades).map(([key, value]) => {
        //     discountedItems.push(key)
        // })

        // if (rootGetters['values/values'].prism_qty > 0) {
        //     const qtyPrism = rootGetters['values/values'].prism_qty
        //     for (let i = qtyPrism; i > 0; i--) {
        //         discountedItems.push('prism-correction')
        //     }
        // }
        const countDiscountedItems = discountedItems.length
        const discountPerItem = Math.ceil(discount / countDiscountedItems * 100) / 100
        const items = {}
        let copyDiscount = discount
        discountedItems.map(item => {
            items[item] = Math.min(copyDiscount, discountPerItem)
            copyDiscount -= discountPerItem
        })
        return items
    },
    preparePlusDataCheckout: (state, getters, rootState, rootGetters) => {
        if (!getters.isRedeemFlow || !getters['membershipId'] || !rootGetters['summary/redeemDiscount']) {
            return {}
        }

        let total = rootGetters['summary/redeemTotalAmount']
        const percent = rootGetters['lensablplus_customer/getPercentage']('lens') / 100
        const addUpgrades = rootGetters['values/values'].upgrades
        Object.entries(addUpgrades).map(([key, value]) => {
            total -= parseInt(value) - parseInt(value) * percent
        })

        const addWarranty = rootGetters['values/values'].warranty
        Object.entries(addWarranty).map(([key, value]) => {
            total -= parseInt(value)
        })
        const checkoutData = rootGetters['values/checkoutData']
        const type = []
        const prefix = getters.getPrefixRedeem

        if (checkoutData.custom_options && checkoutData.custom_options.frames_name) {
            type.push(prefix + 'frames')
        } else {
            let typePrescription = getters.defaultPrescriptionType
            typePrescription = typePrescription.replace(' ', '_')
            if (!typePrescription && getters.isBusinessMember) {
                type.push(prefix + 'frames')
            } else {
                type.push(prefix + 'glasses_' + typePrescription)
            }
        }
        const framesPrice = rootGetters['summary/subTotalAmountFrames'] - rootGetters['summary/getRedeemFramesPercentDiscount']
        checkoutData.custom_options.frames_price = framesPrice
        if (total && framesPrice) {
            total -= framesPrice
        }

        return {
            'membership_id': getters['membershipId'],
            'plus_price': total,
            'types': type,
            'benefit_total': 0
        }
    },
    defaultPrescriptionType: state => {
        let prescriptionType = ''
        if (state.redeemable_products.hasOwnProperty('glasses_progressive')) {
            prescriptionType = (state.redeemable_products.glasses_progressive.product_data.title || '').toLowerCase()
        }
        if (state.redeemable_products.hasOwnProperty('glasses_single_vision')) {
            prescriptionType = (state.redeemable_products.glasses_single_vision.product_data.title || '').toLowerCase()
        }
        return prescriptionType
    },
    isRedeemFlow: state => state.redeemflow,
    getEyeData: state => {
        let right = {}
        let left = {}
        if (state.redeemable_products.hasOwnProperty('contacts_right') && _size(state.redeemable_products.contacts_right)) {
            right = state.redeemable_products.contacts_right.product_data
            right.label = 'Right Eye (OD)'
            right.eye = 'right'
            right.boxes = state.redeemable_products.contacts_right.product_data.default_box_quantity
            right.name = state.redeemable_products.contacts_right.product_data.brand
            const frequencies = CONTACTS_SUBSCRIPTIONS_FREQUENCIES
            if (frequencies.hasOwnProperty(right.plan_code)) {
                right.frequency = frequencies[right.plan_code]['amount']['12-month']
            }
        }
        if (state.redeemable_products.hasOwnProperty('contacts_left') && _size(state.redeemable_products.contacts_left)) {
            left = state.redeemable_products.contacts_left.product_data
            left.label = 'Left Eye (OS)'
            left.eye = 'left'
            left.boxes = state.redeemable_products.contacts_left.product_data.default_box_quantity
            left.name = state.redeemable_products.contacts_left.product_data.brand
            const frequencies = CONTACTS_SUBSCRIPTIONS_FREQUENCIES
            if (frequencies.hasOwnProperty(left.plan_code)) {
                left.frequency = frequencies[left.plan_code]['amount']['12-month']
            }
        }
        return {
            right,
            left
        }
    },
    plusRightEye: (state, getters) => getters.getEyeData.right,
    plusLeftEye: (state, getters) => getters.getEyeData.left,
    isContactsInPlan: (state, getters) => Boolean(_size(getters.getEyeData.right) || _size(getters.getEyeData.left)),
    isContactsRedeemed: state => state.redeemed.contacts,
    isLensesRedeemed: (state, getters) => {
        const prefix = getters.getPrefixRedeem
        state.redeemed.lenses = false
        if (getters.statusRedeemed(prefix + 'frames') === 1) {
            state.redeemed.lenses = true
        }
        if (getters.statusRedeemed(prefix + 'glasses_progressive') === 1) {
            state.redeemed.lenses = true
        }
        if (getters.statusRedeemed(prefix + 'glasses_single_vision') === 1) {
            state.redeemed.lenses = true
        }

        return state.redeemed.lenses
    },
    isVisionRedeemed: (state, getters) => {
        state.redeemed.vision = false
        if (getters.statusRedeemed(getters.getPrefixRedeem + 'exam') === 1) {
            state.redeemed.vision = true
        }
        return state.redeemed.vision
    },
    isVoucherRedeemed: (state, getters) => {
        state.redeemed.voucher = false
        if (getters.statusRedeemed(getters.getPrefixRedeem + 'voucher') === 1) {
            state.redeemed.voucher = true
        }
        return state.redeemed.voucher
    },
    isContactsVoucherRedeemed: (state, getters) => {
        state.redeemed.contacts_voucher = false
        const type = getters.getPrefixRedeem + 'contacts_voucher'
        if (getters.statusRedeemed(getters.getPrefixRedeem + 'contacts_voucher') === 1) {
            state.redeemed.contacts_voucher = true
        } else if (!getters.getRedeemItemData(type).has_redeemed) {
            state.redeemed.contacts_voucher = true
        }
        return state.redeemed.contacts_voucher
    },
    getRedeemItemData: state => type => state.redeemable_products[type] || {},
    statusRedeemed: (state, getters) => type => parseInt(getters.getRedeemItemData(type).has_redeemed || 0),
    getReceiptRewards: (state, getters) => type => getters.getRedeemItemData(type).receipt_data,
    getGiftcardConfigs: state => type => state.giftcardConfigs[type] || {'lensabl_value': '', 'tremendous_value': ''},
    loadingCard: state => state.loadingCard,
    employeePayment: state => state.employeePayment
}

export default store => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
