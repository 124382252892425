<template>
    <div>
        <div class="prescription-list-container">
            <div class="my-prescription-list">
                <div class="my-prescription-list__copy">
                    <p class="my-prescription-name">{{ prescription.prescription_owner }}</p>
                    <ul>
                        <li v-if="expiration_date">
                            <span>Expiration Date:</span>
                            <span>00/00/00</span>
                        </li>
                        <li v-if="prescription.doctor_name">
                            <span>Doctor:</span>
                            <span>{{ prescription.doctor_name }}</span>
                        </li>
                        <li v-else>
                            <span>Photo Verification</span>
                        </li>
                        <div class="order-group-rx">
                            <li v-if="prescription.last_order_date">
                                <span>Last Ordered:</span>
                                <span>{{ prescription.last_order_date }}</span>
                            </li>
                            <li v-if="prescription.order_id">
                                <span>Order ID:</span>
                                <span>{{ prescription.order_id }}</span>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="prescription-snapshot">
                    <div id="poupmaindiv" class="p">
                        <table v-if="!isMobile && lengthRight !== 0" class="prescription-data rx-data_my-account" border="0">
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header" rowspan="2">OD (Right)</td>
                                <td class="prescription-cell prescription-header">Power/SPH</td>
                                <td class="prescription-cell prescription-header">BC</td>
                                <td class="prescription-cell prescription-header">DIA</td>
                                <td v-if="right.cyl" class="prescription-cell prescription-header">CYL</td>
                                <td v-if="right.axis" class="prescription-cell prescription-header">Axis</td>
                                <td v-if="right.add" class="prescription-cell prescription-header">Add</td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell value">
                                    <span id="odpower" :value="right.power" class="prescription-input" type="text" name="od_power"><span v-if="right.power > 0">+</span>{{ right.power }} </span>
                                </td>
                                <td class="prescription-cell value">
                                    <span id="odbc" :value="right.bc" class="prescription-input" type="text" name="od_bc">{{ right.bc }}</span>
                                </td>
                                <td class="prescription-cell value">
                                    <span id="oddia" :value="right.dia" class="prescription-input" type="text" name="od_dia">{{ right.dia }}</span>
                                </td>
                                <td v-if="right.cyl" class="prescription-cell value">
                                    <span id="odcyl" :value="right.cyl" class="prescription-input" type="text" name="od_cyl">{{ right.cyl }}</span>
                                </td>
                                <td v-if="right.axis" class="prescription-cell value">
                                    <span id="odaxis" :value="right.axis" class="prescription-input" type="text" name="od_axis">{{ right.axis }}</span>
                                </td>
                                <td v-if="right.add" class="prescription-cell value">
                                    <span id="odadd" :value="right.add" class="prescription-input" type="text" name="od_axis">{{ right.add }}</span>
                                </td>
                            </tr>
                            <tr v-if="lengthRight !== 0" class="prescription-row">
                                <td v-dompurify-html="right.brand" :colspan="prescription.doctor_name ? (Object.keys(right).length - 3) : (Object.keys(right).length - 2)" class="prescription-cell value"></td>
                            </tr>
                        </table>
                        <table v-if="!isMobile && lengthLeft !== 0" class="prescription-data rx-data_my-account" border="0">
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header" rowspan="2">OS (Left)</td>
                                <td class="prescription-cell prescription-header">Power/SPH</td>
                                <td class="prescription-cell prescription-header">BC</td>
                                <td class="prescription-cell prescription-header">DIA</td>
                                <td v-if="left.cyl" class="prescription-cell prescription-header">CYL</td>
                                <td v-if="left.axis" class="prescription-cell prescription-header">Axis</td>
                                <td v-if="left.add" class="prescription-cell prescription-header">Add</td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell value">
                                    <span id="ospower" :value="left.power" class="prescription-input" type="text" name="od_power"><span v-if="left.power > 0">+</span>{{ left.power }}</span>
                                </td>
                                <td class="prescription-cell value">
                                    <span id="osbc" :value="left.bc" class="prescription-input" type="text" name="od_bc">{{ left.bc }}</span>
                                </td>
                                <td class="prescription-cell value">
                                    <span id="osdia" :value="left.dia" class="prescription-input" type="text" name="od_dia">{{ left.dia }}</span>
                                </td>

                                <td v-if="left.cyl" class="prescription-cell value">
                                    <span id="oscyl" :value="left.cyl" class="prescription-input" type="text" name="od_cyl">{{ left.cyl }}</span>
                                </td>
                                <td v-if="left.axis" class="prescription-cell value">
                                    <span id="osaxis" :value="left.axis" class="prescription-input" type="text" name="od_axis">{{ left.axis }}</span>
                                </td>
                                <td v-if="left.add" class="prescription-cell value">
                                    <span id="osadd" :value="left.add" class="prescription-input" type="text" name="od_axis">{{ left.add }}</span>
                                </td>
                            </tr>
                            <tr class="prescription-row">
                                <td v-dompurify-html="left.brand" :colspan="prescription.doctor_name ? (Object.keys(left).length - 3) : (Object.keys(left).length - 2)" class="prescription-cell value"></td>
                            </tr>
                        </table>
                        <table v-if="isMobile" class="prescription-data rx-data_my-account" border="0">
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header"></td>
                                <td v-if="lengthRight !== 0" class="prescription-cell prescription-header">OD (Right)</td>
                                <td v-if="lengthLeft !== 0" class="prescription-cell prescription-header">OD (Left)</td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">POWER/SPH</td>
                                <td v-if="lengthRight !== 0" class="prescription-cell value">
                                    <span :value="right.power" class="prescription-input" type="text" name="od_power">{{ right.power }}</span>
                                </td>
                                <td v-if="lengthLeft !== 0" class="prescription-cell value">
                                    <span :value="left.power" class="prescription-input" type="text" name="os_power">{{ left.power }}</span>
                                </td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">BC</td>
                                <td v-if="lengthRight !== 0" class="prescription-cell value">
                                    <span :value="right.bc" class="prescription-input" type="text" name="od_bc">{{ right.bc }}</span>
                                </td>
                                <td v-if="left.length !== 0" class="prescription-cell value">
                                    <span :value="left.bc" class="prescription-input" type="text" name="os_bc">{{ left.bc }}</span>
                                </td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">DIA</td>
                                <td v-if="lengthRight !== 0" class="prescription-cell value">
                                    <span :value="right.dia" class="prescription-input" type="text" name="od_dia">{{ right.dia }}</span>
                                </td>
                                <td v-if="lengthLeft !== 0" class="prescription-cell value">
                                    <span :value="left.dia" class="prescription-input" type="text" name="os_dia">{{ left.dia }}</span>
                                </td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">CYL</td>
                                <td v-if="right.cyl" class="prescription-cell value">
                                    <span :value="right.cyl" class="prescription-input" type="text" name="od_cyl">{{ right.cyl }}</span>
                                </td>
                                <td v-if="left.cyl" class="prescription-cell value">
                                    <span :value="left.cyl" class="prescription-input" type="text" name="os_cyl">{{ left.cyl }}</span>
                                </td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">Axis</td>
                                <td v-if="right.axis.length !== 0" class="prescription-cell value">
                                    <span :value="right.axis" class="prescription-input" type="text" name="od_axis">{{ right.axis }}</span>
                                </td>
                                <td v-if="left.axis.length !== 0" class="prescription-cell value">
                                    <span :value="left.axis" class="prescription-input" type="text" name="os_axis">{{ left.axis }}</span>
                                </td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">Axis</td>
                                <td v-if="right.add" class="prescription-cell value">
                                    <span :value="right.add" class="prescription-input" type="text" name="od_axis">{{ right.add }}</span>
                                </td>
                                <td v-if="left.add" class="prescription-cell value">
                                    <span :value="left.add" class="prescription-input" type="text" name="os_axis">{{ left.add }}</span>
                                </td>
                            </tr>

                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">Brands</td>
                                <td v-if="lengthRight !== 0" class="prescription-cell value"><span>{{ right.brand }}</span></td>
                                <td v-if="lengthLeft !== 0" class="prescription-cell value"><span>{{ left.brand }}</span></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="my-prescription-list__actions">
                    <ul class="my-prescription-list__actions-list">
                        <li v-if="prescription.order_id" class="my-prescription-edit-button">
                            <div class="button button--copy-border-sm edit">
                                <BaseButton :loader="reorderLoader" class="button__copy button-edit" text="Reorder" @click.native="reOrder" />
                            </div>
                            <p v-if="message">{{ message }}</p>
                        </li>
                        <li v-if="!prescription.order_id" class="my-prescription-edit-button">
                            <div class="button button--copy-border-sm edit">
                                <BaseButton class="button__copy button-edit" text="Add To Cart" @click.native="quantityModal = true" />
                            </div>
                            <p v-if="message">{{ message }}</p>
                        </li>
                    </ul>
                </div>
                <Modal v-if="quantityModal" @close="quantityModal = false">
                    <span class="modal-header">How many boxes?</span>
                    <div>
                        <br />
                        <input id="orderAmount" v-model="boxAmount" type="text" name="orderAmount" />
                        <br />
                        <BaseButton :loader="reorderLoader" text="Add To Cart" class="button__copy button-edit" @click.native="addNewContacts" />
                    </div>
                </Modal>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Modal from '../../contacts/Modal.vue'
import BaseButton from '../../../components/base/BaseButton.vue'

export default {
    name: 'ContactsList',
    components: {
        BaseButton,
        Modal
    },
    props: {
        'prescription': {
            type: Object,
            default: () => {},
            required: true
        },
        'items': {
            type: Array,
            default: () => [],
            required: true
        }
    },
    data: () => ({
        left: {},
        right: {},
        date: '',
        lengthLeft: 0,
        lengthRight: 0,
        expiration_date: false,
        reorderLoader: false,
        message: false,
        quantityModal: false,
        boxAmount: 1
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('prescriptions', ['prescriptionService'])
    },
    mounted () {
        let left = {}
        let right = {}
        let date = ''
        this.items.forEach(function (element) {
            const eye = element.info.eye
            if (eye === 'left') {
                left = element.info
                date = element.data.created_at.substr(0, (element.data.created_at).indexOf(' '))
            } else {
                right = element.info
            }
        })

        this.left = left
        this.right = right
        this.date = date

        this.lengthLeft = Object.keys(this.left).length
        this.lengthRight = Object.keys(this.right).length
    },
    methods: {
        reOrder () {
            this.reorderLoader = true
            this.prescriptionService.reorder(this.prescription)
                .then(response => {
                    this.reorderLoader = false
                    const returnedData = response.data
                    if (!returnedData.error) {
                        if (returnedData.message) {
                            this.message = returnedData.message
                        }
                        if (returnedData.redirect_url) {
                            document.location = returnedData.redirect_url
                        }
                    }
                })
                .catch((error) => {
                    this.reorderLoader = false
                    console.error(error)
                })
        },
        addNewContacts () {
            this.quantityModal = true
            this.reorderLoader = true
            const params = {
                'prescription_ids': (this.prescription.prescription_ids).join(','),
                'qty': this.boxAmount
            }
            this.prescriptionService.addToCart(params)
                .then(response => {
                    this.reorderLoader = false
                    const returnedData = response.data
                    if (!returnedData.error) {
                        if (returnedData.message) {
                            this.message = returnedData.message
                        }
                        if (returnedData.redirect_url) {
                            document.location = returnedData.redirect_url
                        }
                    }
                })
                .catch((error) => {
                    this.reorderLoader = false
                    console.error(error)
                })
        }
    }
}
</script>

<style scoped>

</style>
