var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.brand + "-seo-parent-container"] }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-prescription-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-prescription" }, [
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Hobie Eyewear")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "You live an active life, so your glasses should be able to meet every challenge as well. Hobie Eyewear produces some of the most durable glasses and sunglasses for people who love to get out. You'll find a wide selection of men's Hobie eyeglasses through Lensabl's online store. Order your new frames today for free standard delivery right to your door."
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-img" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-frames-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-frames" }, [
        _c("div", { staticClass: "shopframes-seo-frames-img" }),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Durable Hobie Frames for Active Lifestyles")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Hobie glasses are available in all your favorite styles. Shop online with Lensabl to find durable frames that look great on any occasion. "
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "We carry rectangular, square and round Hobie Optical frames that feature rugged materials like Memory Metal and acetate. Our selection of Hobie Sunglasses features the brand's most loved editions, like Baja, Dunes, Cabo and Broad. Each Hobie frame is lightweight and stylish for a pair of glasses that look and feel good to wear all day long."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "Unsure which size is right for you? We invite you to find your fit using our site's size finder tool before ordering your new Hobie frames."
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-designer-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-designer" }, [
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Custom Lenses for Hobie Eyeglasses")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Lensabl has a lens for any occasion to fill your Hobie frames. Our prescription lenses are available for single vision, progressive and bifocal correction. Choose a standard clear lens or opt for a blue-light-blocking or Transitions® coating. We also sell Hobie polarized and mirror sunglasses for your prescription. Order Hobie glasses through Lensabl for dependable vision correction and eye protection."
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-designer-img" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-eyeglass-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-eyeglass" }, [
        _c("div", { staticClass: "shopframes-seo-text seo-eyeglass-text" }, [
          _c("h2", [_vm._v("Shop Lensabl for Hobie Eyewear")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Are you looking for durable glasses you can trust out on the water for optical correction and glare prevention? A set of Hobie glasses is the perfect fit for your active lifestyle. Buy Hobie eyewear online through Lensabl for free standard delivery and other great perks."
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "/glasses-frames", title: "Glasses Frames" } },
          [
            _c("div", { staticClass: "button button--copy-solid" }, [
              _c("div", { staticClass: "button__container" }, [
                _vm._v("GET NEW FRAMES")
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }