<template>
    <section class="testimonial-slider-container">
        <carousel
            :per-page="1"
            :mouse-drag="true"
            :navigation-enabled="true"
            navigation-prev-label="‹"
            navigation-next-label="›"
            class="struct-graphic"
        >
            <slide class="testimonial-wrapper">
                <img src="https://www.lensabl.com/media/wysiwyg/Why-Lensabl-David.png" class="testimonial-person" />
                <div class="testimonial-review">
                    <p>"I don’t mess around when it comes to my glasses. I spent good money on two pairs of frames from another store, and want them to last as long as possible. That’s why I use Lensabl to just replace the lenses whenever I need to.”</p>
                    <p class="testimonial-review-sig">David J.</p>
                </div>
            </slide>
            <slide class="testimonial-wrapper">
                <img src="https://www.lensabl.com/media/wysiwyg/Why-Lensabl-Jennifer.png" class="testimonial-person" />
                <div class="testimonial-review">
                    <p>"I’m a longtime Lensabl customer and have had the lenses replaced in a bunch of frames. I was thrilled when they started offering contact lenses too. Now all of my vision stuff is handled in one place which makes my life easier.”</p>
                    <p class="testimonial-review-sig">Jennifer M.</p>
                </div>
            </slide>
            <slide class="testimonial-wrapper">
                <img src="https://www.lensabl.com/media/wysiwyg/Why-Lensabl-Eric.png" class="testimonial-person" />
                <div class="testimonial-review">
                    <p>"I’m your typical millennial, I do all my shopping online. I’ve owned Warby Parker and Felix Gray glasses and they’re great, but Lensabl’s Everyday Eyewear line offers the same quality but for a cheaper price. Easy decision.”</p>
                    <p class="testimonial-review-sig">Eric L.</p>
                </div>
            </slide>
        </carousel>
    </section>
</template>

<script>
import {mapState} from 'vuex'
import { Carousel, Slide } from 'vue-carousel'

export default {
    name: 'WhyLensablSlider',
    components: {
        Carousel,
        Slide
    },
    data: () => {
        return {
            slickOptions: {
                slidesToShow: 4,
                arrows: true,
                dots: true,
                draggable: true,
                edgeFriction: 0.30,
                swipe: true
            }
        }
    },
    computed: {
        ...mapState('featured_products', ['featuredProduct'])
    },
    beforeCreate () {
    },
    created () {
    }
}
</script>
