<template>
    <div>
        <div class="struct-parallax-bg" data-parallax="data-parallax" data-attrs-pos-start="50% -130%" data-attrs-pos-end="50% 60%" data-attrs-xlarge-pos-start="50% -100%" data-attrs-xlarge-pos-end="50% 0%" data-attrs-trigger-hook="1" style="text-align: center; padding: 2em; background-image: unset;">
            <h3 style="color: #2f3948; font-weight: bolder;" class="insurance-divider-text">-Or-</h3>
            <h3 style="color: #2f3948; font-weight: bolder;" class="insurance-divider-text">Use a Reimbursement Form</h3>
            <p style="color: #8d8d8d; font-size: 19px;">Can’t find your insurance or are out-of network? No problem. Select your provider below for details on how to submit your Lensabl receipt to your insurance company for reimbursement.</p>
            <div class="providers">
                <template v-for="(provider, index) in getProviders">
                    <div :key="index" class="provider" @click="modalInformation(index)">
                        <img
                            :class="provider.provider"
                            :src="provider.image"
                            :alt="provider.provider"
                            class="provider-image"
                            height="40px"
                        />
                    </div>
                </template>
            </div>
            <p style="color: #8d8d8d; font-size: 19px;">Don't see your provider? Contact your insurance company directly for instructions on applying for reimbursement.</p>
        </div>

        <div v-if="showProviderModal" class="insurance-modal">
            <div class="insurance-content">
                <div class="insurance-modal-image">
                    <img class="provider-modal-image" :src="getProviders[currentProvider].image" :alt="getProviders[currentProvider].name" width="150px" height="50px" />
                </div>
                <button class="md-close" @click="showProviderModal = false">X</button>
                <div class="insurance-modal-title">
                    <p class="im-paragraph" style="color: #8d8d8d; text-align: center; margin-bottom: 2em;">We’re not in-network with <span class="insurance-provider-name">{{ getProviders[currentProvider].provider }}</span>, however, you can apply for reimbursement on your prescription lenses and eye exam. Just follow the steps below:</p>
                </div>
                <div class="insurance-providers__options">
                    <ul>
                        <li>
                            <div class="insurance-title__container"><div class="number-container"><span class="number">1</span></div><p class="im-min"> Fill out claim form</p></div>
                            <div class="insurance-form-content">
                                <p class="insurance-form-information im-min">Complete the following claim form:</p>
                                <a class="insurance-form-link" :href="getProviders[currentProvider].form_input" target="_blank">
                                    <div class="button button--copy-solid">
                                        <div class="button__container"> DOWNLOAD FORM</div>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li>
                            <div class="insurance-title__container"><div class="number-container"><span class="number">2</span></div><p class="im-min"> Submit claim form and invoice</p></div>
                            <div class="insurance-form-content">
                                <p class="insurance-form-information im-min">Submit the completed claim form and submit along with your itemized invoice to this address:</p>
                                <div class="insurance-provider-information">
                                    <p class="insurance-provider-address-name" style="font-weight: bold">{{ getProviders[currentProvider].address_name }}</p>
                                    <p class="insurance-provider-address-attn">{{ getProviders[currentProvider].address_attn }}</p>
                                    <p class="insurance-provider-address-street">{{ getProviders[currentProvider].address_po_box }}</p>
                                    <p class="insurance-provider-address-city">{{ getProviders[currentProvider].address_city }}, {{ getProviders[currentProvider].address_state }} {{ getProviders[currentProvider].address_zipcode }}</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="insurance-title__container"><div class="number-container"><span class="number">3</span></div><p class="im-min"> Download itemized invoice</p></div>
                            <div class="insurance-form-content">
                                <p class="insurance-form-information im-min">You may download an itemized invoice by logging into <a href="/sales/order/history/"> your account</a>.</p>
                            </div>
                        </li>
                        <li>
                            <div class="insurance-title__container">
                                <div class="number-container"><span class="number">4</span></div><p class="im-min"> Get reimbursed</p>
                            </div>
                            <div class="insurance-form-content">
                                <p class="insurance-form-information im-min">After submitting your claim, you’ll typically be reimbursed within 2-3 weeks. (Depending on your provider, it can be faster or slower).</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'Providers',
    data: () => {
        return {
            currentProvider: 0,
            showProviderModal: false
        }
    },
    computed: {
        ...mapGetters('insurance', ['getProviders'])
    },
    methods: {
        modalInformation (index) {
            this.currentProvider = index
            this.showProviderModal = true
        }
    }
}
</script>

<style scoped>

</style>
