var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottom-disclaimer" }, [
    _c("p", [
      _c("strong", [_vm._v("Need Help?")]),
      _vm._v(" "),
      _vm.isGoodrDisclaimerText
        ? _c("span", [
            _vm._v(
              "\n            Selecting the best material for your lenses can depend on your prescription strength and the frame being purchased. If you'd like assistance, feel free to reach out to us via "
            ),
            _c("a", { attrs: { href: "/contact", target: "_blank" } }, [
              _vm._v("support")
            ]),
            _vm._v(" or live chat! \n        ")
          ])
        : _c("span", [
            _vm._v(
              "\n            Selecting the best material for your lenses can depend on your prescription strength, as well as the size/shape of your frames.\n            If you'd like assistance, feel free to reach out to us via "
            ),
            _c("a", { attrs: { href: "/contact", target: "_blank" } }, [
              _vm._v("support")
            ]),
            _vm._v(" or live chat!\n        ")
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }