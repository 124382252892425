var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "toggle-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "price-toggle" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.price,
            expression: "price"
          }
        ],
        staticClass: "p-toggle toggle-left",
        class: { "not-checked": _vm.price === "totalQuarterlyPrice" },
        attrs: {
          id: "toggle-on",
          name: "toggle",
          value: "totalAnnualPrice",
          type: "radio"
        },
        domProps: { checked: _vm._q(_vm.price, "totalAnnualPrice") },
        on: {
          change: function($event) {
            _vm.price = "totalAnnualPrice"
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "btn-toggle",
          attrs: { for: "toggle-on" },
          on: {
            click: function($event) {
              return _vm.update("totalAnnualPrice")
            }
          }
        },
        [_vm._v("Annual")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.price,
            expression: "price"
          }
        ],
        staticClass: "p-toggle toggle-right",
        class: { "not-checked": _vm.price === "totalAnnualPrice" },
        attrs: {
          id: "toggle-off",
          name: "toggle",
          value: "totalQuarterlyPrice",
          type: "radio"
        },
        domProps: { checked: _vm._q(_vm.price, "totalQuarterlyPrice") },
        on: {
          change: function($event) {
            _vm.price = "totalQuarterlyPrice"
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "btn-toggle",
          attrs: { for: "toggle-off" },
          on: {
            click: function($event) {
              return _vm.update("totalQuarterlyPrice")
            }
          }
        },
        [_vm._v("Every 3 Months")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("strong", [_vm._v("Choose Payment Frequency")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }