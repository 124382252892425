var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contacts_rx-dropdown-container" }, [
    _c(
      "div",
      {
        staticClass: "contacts_rx-dropdown-value",
        class: { active: _vm.value },
        on: {
          click: function($event) {
            return _vm.updateDropdown(_vm.index, $event)
          }
        }
      },
      [
        _vm.value
          ? _c("label", { staticClass: "contacts_rx-dropdown-label" }, [
              _vm._v(_vm._s(_vm.placeholder))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.type === "power" && _vm.value > 0
          ? _c("span", [_vm._v("+")])
          : _vm._e(),
        _vm._v(_vm._s(_vm.value || _vm.placeholder) + "\n    ")
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "contacts_rx-dropdown-list" }, [
      _vm.type === "power" && _vm.hasPlusMinusValues
        ? _c("div", { staticClass: "contacts_rx-dropdown-sign" }, [
            _c("div", { staticClass: "contacts_rx-dropdown-sign--item" }, [
              _vm._v("\n                -\n            ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "contacts_rx-dropdown-sign--item" }, [
              _vm._v("\n                +\n            ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "contacts_rx-dropdown-wrapper" },
        [
          _vm._l(_vm.values(_vm.index), function(option) {
            return [
              option === 0
                ? _c(
                    "div",
                    {
                      key: "0-" + option,
                      staticClass: "contacts_rx-dropdown-item",
                      on: {
                        click: function($event) {
                          return _vm.updateRxValue(
                            option,
                            _vm.index,
                            _vm.type,
                            $event
                          )
                        }
                      }
                    },
                    [_vm._v("0.00")]
                  )
                : _vm._e()
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "contacts_rx-dropdown-row" }, [
            _vm.type === "power" && _vm.hasPlusMinusValues
              ? _c(
                  "div",
                  { staticClass: "contacts_rx-dropdown-two-col" },
                  [
                    _vm._l(_vm.values(_vm.index), function(option, i) {
                      return [
                        option < 0
                          ? _c(
                              "div",
                              {
                                key: "power-" + i + "-" + option,
                                staticClass: "contacts_rx-dropdown-item",
                                class: {
                                  disabled: !_vm.checkAvailable(option)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.updateRxValue(
                                      option,
                                      _vm.index,
                                      _vm.type,
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(option) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "power" && _vm.hasPlusMinusValues
              ? _c(
                  "div",
                  { staticClass: "contacts_rx-dropdown-two-col" },
                  [
                    _vm._l(_vm.values(_vm.index), function(option, i) {
                      return [
                        option > 0
                          ? _c(
                              "div",
                              {
                                key: "power-" + i + "-" + option,
                                staticClass: "contacts_rx-dropdown-item",
                                class: {
                                  disabled: !_vm.checkAvailable(option)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.updateRxValue(
                                      option,
                                      _vm.index,
                                      _vm.type,
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            +" +
                                    _vm._s(option) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.type === "power" && !_vm.hasPlusMinusValues
            ? [
                _vm._l(_vm.values(_vm.index), function(option, i) {
                  return [
                    _c(
                      "div",
                      {
                        key: "power-" + i + "-" + option,
                        staticClass: "contacts_rx-dropdown-item",
                        class: { disabled: !_vm.checkAvailable(option) },
                        on: {
                          click: function($event) {
                            return _vm.updateRxValue(
                              option,
                              _vm.index,
                              _vm.type,
                              $event
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(option) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.values(_vm.index), function(option, i) {
            return [
              _vm.type !== "power"
                ? _c(
                    "div",
                    {
                      key: "notpower-" + i,
                      staticClass: "contacts_rx-dropdown-item",
                      class: { disabled: !_vm.checkAvailable(option) },
                      on: {
                        click: function($event) {
                          return _vm.updateRxValue(
                            option,
                            _vm.index,
                            _vm.type,
                            $event
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(option) +
                          "\n                "
                      )
                    ]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }