var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isTrialExpiring
    ? _c(
        "div",
        {
          staticClass: "plus-account_container attention plusdiscount-container"
        },
        [
          _vm.isMobile
            ? _c(
                "h4",
                {
                  class: { active: _vm.isShow },
                  on: { click: _vm.toggleContent }
                },
                [
                  _vm._v(
                    "Your " +
                      _vm._s(_vm.isFreeString) +
                      " Lensabl+ trial expires in "
                  ),
                  _c("b", [_vm._v("30 days.")])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide" } }, [
            !_vm.isMobile || (_vm.isShow && _vm.isMobile)
              ? _c("div", { staticClass: "plus-content_container" }, [
                  _c("div"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "redeem-description" },
                    [
                      _c("div", { staticClass: "description-attention" }, [
                        _vm._v(
                          "Your " +
                            _vm._s(_vm.isFreeString) +
                            " Lensabl+ trial expires in "
                        ),
                        _c("b", [
                          _vm._v(_vm._s(_vm.getDaysBeforeDeleting) + " days.")
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.isFreeMembership
                        ? _c("span", { staticClass: "description" }, [
                            _vm._v(
                              "Sign up for an annual plan and get a FREE frame or lens replacement plus all the other great benefits for just\n                    "
                            ),
                            _c(
                              "span",
                              { staticClass: "total-value subscribed" },
                              [
                                _c("b", [
                                  _vm._v(
                                    " $" +
                                      _vm._s(
                                        _vm.toFixedPrice(_vm.membershipCost)
                                      )
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "title-black" }, [
                              _c("b", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.toFixedPrice(
                                        _vm.membershipCostWithDiscount
                                      )
                                    )
                                )
                              ])
                            ])
                          ])
                        : _c("span", { staticClass: "description" }, [
                            _vm._v(
                              "Renew your membership now to make sure you don’t lose all of your great benefits. If you renew today, you get 15% off."
                            )
                          ]),
                      _vm._v(" "),
                      _c("HelpBubble", {
                        attrs: {
                          data: _vm.getHelpComponent["trial"],
                          classes: _vm.getHelpComponent["trial"]["classes"],
                          "target-classes":
                            _vm.getHelpComponent["trial"]["targetClasses"],
                          "container-classes":
                            _vm.getHelpComponent["trial"]["containerClasses"],
                          placement: "bottom"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "description-disclaimer" }, [
                        _vm._v(
                          "*Your new plan will begin the day after your " +
                            _vm._s(_vm.isFreeString) +
                            " trial expires."
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "secondary" },
                    [
                      _c("BaseButton", {
                        staticClass: "button button--copy-solid",
                        attrs: {
                          loader: _vm.buttonLoad,
                          text: _vm.btnText,
                          "data-size": "xs"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.addPlanInCart($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }