<template>
    <div class="notification-container dropdown-container-main">
        <div class="desktop-notification-card-top">
            <h4 :class="{active: resultsVisible}" @click="toggleSelected">
                Notifications <span class="notification-number">{{ newMessages > 0 ? '('+ newMessages + ' new)' : '' }}</span>
            </h4>
            <transition name="slide">
                <div v-if="isDesktop || resultsVisible && isMobile || isTablet" class="results-dropdown">
                    <div v-if="!paidUp" class="lplus-notification">
                        <a href="mailto:support@lensabl.com" class="button">Update Now</a>
                        <p>Update your account to access benefits. Contact support@lensabl.com.</p>
                    </div>
                    <ul class="notification-list">
                        <template v-if="notifications.length > 0">
                            <template v-for="(message, index) in notifications">
                                <li v-if="message.is_read == 0" :key="index">
                                    <div class="message-container">
                                        <div class="remove-container">
                                            <p v-dompurify-html="message.date"></p>
                                            <span class="remove" @click="removeNotification(message.notification_id)">×</span>
                                        </div>
                                        <span v-dompurify-html="message.message"></span>
                                    </div>
                                    <img class="mobile-close" src="/js/lensconfigurator/assets/mobile-close.svg" alt="mobile close sign" @click="removeNotification(message.notification_id)" />
                                </li>
                            </template>
                        </template>
                        <li v-if="emptyNotifications" class="no-results">
                            <span>You have no notifications at this time</span>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>
 <script>
import {mapGetters, mapState, mapActions} from 'vuex'

export default {
    name: 'NotificationsRedeem',
    data: () => ({
        resultsVisible: false,
        newMessages: 0
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet', 'isDesktop']),
        ...mapGetters('lensablplus_customer', ['paidUp', 'notifications']),
        ...mapState('gsap', ['previousvisit']),
        emptyNotifications () {
            return this.isEmptyNotifications()
        }
    },
    mounted () {
        this.isEmptyNotifications()
    },
    methods: {
        ...mapActions('lensablplus_customer', ['REMOVE_NOTIFICATION']),
        toggleSelected () {
            if (this.isMobile) {
                this.resultsVisible = !this.resultsVisible
            }
        },
        removeNotification (index) {
            this.$store.dispatch('lensablplus_customer/REMOVE_NOTIFICATION', {id: index})
        },
        isEmptyNotifications () {
            let isRead = true
            let newMessages = 0

            this.notifications.forEach(function (item) {
                if (item.is_read === '0') {
                    newMessages++
                    isRead = false
                }
            })
            this.newMessages = newMessages

            return isRead
        }
    }
}
</script>

<style lang="less" scoped>
    .lplus-notification {
        background: #e24545;
        color: #fff;
        margin: 1em 1em 0 1em;
        padding: .5em;
        font-size: 0.9rem;
        p {
            font-size: .8rem;
            margin: 0;
        }
        .button {
            float: right;
            border: 1px solid #fff;
            width: auto;
            line-height: 1rem;
            padding: 0 .5em;
            color: #fff;
            font-size: .8rem;
            font-weight: normal;
            margin: 1em 0;
            &:hover {
                color: #e24545;
                background: #fff;
            }
        }
    }
</style>
