import _map from 'lodash/map'
import _cloneDeep from 'lodash/cloneDeep'

let stateWasReset = false

export default () => ({
    namespaced: true,
    state: () => ({
        allOrders: {}
    }),
    getters: {
        orderView: state => state
    },
    mutations: {
        RESET_ORDERS: (state, newState) => {
            _map(_cloneDeep(newState), (value, key) => {
                state[key] = value
            })
            stateWasReset = true
        }
    },
    actions: {
        resetOrderState: ({commit, rootGetters}) => commit('RESET_ORDERS', rootGetters.rootState),
        fetchOrderState: ({getters, dispatch}) => {
            if (!stateWasReset) {
                dispatch('resetOrderState')
            }
            return getters.orderView
        }
    }
})
