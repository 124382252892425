<template>
    <td :key="`row-${field.name}`">
        <label :for="`membership-${ field.name }`">
            {{ field.label }}:
        </label>
        <template>
            <input
                :id="`membership-${ field.name }`"
                :value="value"
                :class="{'required-entry': field.required, 'validation-failed': error}"
                :readonly="field.disabled"
                :maxlength="field.length"
                :type="field.type"
                class="input-text"
                @blur="validBlurField(field.name)"
                @keyup="$emit('update',$event, field.name)"
            />
        </template>
        <div v-if="error" :id="`advice-required-entry-${field.name}`" class="validation-advice" style="">
            {{ field.message ? field.message : 'This is a required field.' }}
        </div>
        <div v-if="passwordError" class="validation-advice" :class="[confirmPwValidation, confirmPwIdentical]">
            <p style="text-align: left; margin: 0;">Password must be at least 8 characters
                and contain:</p>
            <ul style="text-align: left; list-style: circle; list-style-position: inside;">
                <li>at least 1 number</li>
                <li>at least 1 lowercase letter</li>
                <li>at least 1 uppercase letter</li>
                <li>at least 1 special characters _@!#$^%&*()+=-[ ]';,./{ }\|":<>?</li>
            </ul>
        </div>
    </td>
</template>

<script>
export default {
    name: 'SignupInput',
    props: {
        field: { // Component Id
            type: [Object],
            default: () => {}
        },
        error: { // Component Id
            type: [Boolean],
            default: false
        },
        value: {
            type: [String],
            default: ''
        },
        passwordError: {
            type: [Boolean],
            default: false
        }

    },
    methods: {
        validBlurField (val) {
            this.$emit('blur', val)
        }
    },
    computed: {
        confirmPwValidation() {
            return this.field.name === 'confirm_password' ? 'confirm_password-validation' : ''
        },
        confirmPwIdentical() {
            return this.field.name === 'confirm_password' && this.error ? 'confirm_password-validation-identical' : ''
        }
    }
}
</script>

<style scoped lang="less">
.confirm_password-validation {
    visibility: hidden;
}

.confirm_password-validation-identical {
    margin-top: -20px
}


</style>