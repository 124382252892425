<template>
    <div v-if="statusApi">
        <CatalogContactsCategoryView id="shop-contacts" />
    </div>
</template>

<script>
import App from '../../App.vue'
import CatalogContactsCategoryView from '../../components/contacts/category/CatalogCategoryView.vue'

export default {
    name: 'ContactsCategory',
    components: {
        CatalogContactsCategoryView
    },
    extends: App
}
</script>
