<template>
    <PlanChoice v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import PlanChoice from '../../components/lensablplus/ordertool/PlanChoice.vue'

export default {
    name: 'RouterLensablplusPlanChoice',
    components: {
        PlanChoice
    },
    extends: App
}
</script>
