var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showRedeem
    ? _c(
        "div",
        {
          staticClass:
            "plus-account_container plusdiscount-container offlensplus-container",
          class: { "redeemed-offlensplus-container": _vm.redeemedStyle }
        },
        [
          _vm.isMobile
            ? _c(
                "h4",
                {
                  class: { active: _vm.isShow },
                  on: { click: _vm.toggleContent }
                },
                [_vm._v("Off Lensabl+ Benefit")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide" } }, [
            !_vm.isMobile || (_vm.isShow && _vm.isMobile)
              ? _c("div", { staticClass: "plus-content_container" }, [
                  _c("div"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "redeem-description" },
                    [
                      _c("div", { staticClass: "description-attention" }, [
                        _vm._v("Off Lensabl+ Benefit")
                      ]),
                      _vm._v(" "),
                      _c("HelpBubble", {
                        attrs: { data: _vm.component_help.non_lensabl }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "description" }, [
                        _vm._v(_vm._s(_vm.description))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "secondary" },
                    [
                      _c("BaseButton", {
                        staticClass: "button button--copy-solid",
                        class: {
                          disable: !_vm.previousvisit,
                          "ladda-button": _vm.redeemedStyle,
                          disabledButton: _vm.redeemedStyle
                        },
                        attrs: {
                          disabled: !_vm.paidUp || _vm.redeemedStyle,
                          text: _vm.btnTitle,
                          "data-size": "xs"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.openModalRedeem($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("ExamCreditRedeem", {
            staticClass: "non-plus-benefit",
            attrs: {
              "is-modal-open": _vm.openModal,
              collect: _vm.collect,
              subtitle: "Off Lensabl+ Benefit",
              instruction: _vm.nonInstruction,
              "redeem-item-type": _vm.redeemItemType
            },
            on: {
              update: function($event) {
                _vm.openModal = false
              },
              continue: _vm.pendingBenefitCredit
            }
          }),
          _vm._v(" "),
          _c("GiftCreditRedeem", {
            attrs: {
              "is-modal-open": _vm.isGiftCardOpen,
              collect: _vm.collect,
              "redeem-item-type": _vm.redeemItemType
            },
            on: {
              update: function($event) {
                _vm.isGiftCardOpen = false
              },
              continue: _vm.pendingBenefitCredit
            }
          }),
          _vm._v(" "),
          _vm.loadingBlock ? _c("Loader") : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }