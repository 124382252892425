var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isPPEProduct && _vm.productView.productInfoShow
        ? _c(
            "div",
            {
              staticClass:
                "lensablFrames-breadcrumbs frames-breadcrumbs vue-breadcrumbs"
            },
            [
              _c("div", { staticClass: "breadcrumbs" }, [
                _c("ul", [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("li", { staticClass: "lensabl-product" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.product_name))])
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.productView.productInfoShow,
              expression: "productView.productInfoShow"
            }
          ],
          staticClass:
            "product-details-holder lensabl-frames-view frames-wrapper",
          class: { "product-layout-2columns": _vm.isDesktop }
        },
        [
          _c(
            "div",
            { staticClass: "product media" },
            [
              _c("Gallery", { class: { "vertical-thumbs": _vm.isDesktop } }),
              _vm._v(" "),
              _c("VirtualTryOn", {
                attrs: {
                  "current-color-item": _vm.colorItem,
                  "product-category-name": _vm.productCategoryName,
                  "frame-colors": _vm.colors,
                  "lens-colors":
                    _vm.productCategoryName === "sun" ? _vm.sunColors : {},
                  "checked-index": _vm.checkedIndex,
                  "checked-lens-index": _vm.sunIndex
                },
                on: _vm._d({}, [
                  _vm.EVENT_CHANGE_CURRENT_COLOR,
                  _vm.changeCurrentColor,
                  _vm.EVENT_CHANGE_CURRENT_COLOR_LENS,
                  _vm.changeCurrentLensColor
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-info-main" },
            [
              _c("div", { staticClass: "page-title-wrapper product" }, [
                _c("h1", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.product_name,
                      expression: "product_name"
                    }
                  ],
                  staticClass: "page-title",
                  class: _vm.product_name,
                  attrs: { itemprop: "name" }
                })
              ]),
              _vm._v(" "),
              _vm.colors.length
                ? [
                    !_vm.isRedeemFlow ? _c("Affirm") : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "swatch-opt" },
                      [
                        _c("ColorSwatches", {
                          attrs: {
                            colors: _vm.colors,
                            "checked-index": _vm.checkedIndex,
                            "option-item-class": "decorate bigger img",
                            "product-name": _vm.product_name.toLowerCase()
                          },
                          on: _vm._d({}, [
                            _vm.EVENT_CHANGE_CURRENT_COLOR,
                            _vm.changeCurrentColor
                          ])
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("AdditionalAttributes", {
                      class: {
                        inline: !_vm.isDesktop && !_vm.isMobile,
                        grid: _vm.isMobile
                      },
                      attrs: { "data-attributes": _vm.additionalAttributes }
                    }),
                    _vm._v(" "),
                    _vm.isAvailable && !_vm.isDesktop
                      ? _c("div", { staticClass: "product-options-bottom" }, [
                          _c(
                            "div",
                            { staticClass: "actions" },
                            [
                              _c(
                                "BaseButton",
                                {
                                  attrs: { text: "Choose Your Lenses" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.isMobile
                                        ? _vm.mobileSwitcher()
                                        : _vm.goToConfigurator()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Choose Your Lenses\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.stockStatus
                      ? _c(
                          "p",
                          {
                            staticClass: "unavailable stock",
                            attrs: { title: "Availability" }
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("This item is sold out")
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "product-border-divider" }),
      _vm._v(" "),
      _c("AnagramAdBar"),
      _vm._v(" "),
      !_vm.plusMember
        ? _c("ImageHelpBubble", { attrs: { "ad-data": _vm.getBannerText } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAvailable && _vm.productView.productConfiguratorShow,
              expression: "isAvailable && productView.productConfiguratorShow"
            }
          ],
          staticClass: "product-configurator-wrapper"
        },
        [
          _c("Configurator", {
            attrs: { "custom-summary": _vm.shopFramesSummary }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.productView.productInfoShow ? _c("ReturnsInfo") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "lensabl-frames" }, [
      _c(
        "a",
        { attrs: { href: "/shop-frames/", title: "Go to Lensabl Shop" } },
        [_vm._v("Lensabl Shop")]
      ),
      _vm._v(" "),
      _c("span", [_vm._v(" > ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "lensabl-category" }, [
      _c(
        "a",
        {
          attrs: {
            href: "/shop-frames/?category=everyday-eyewear",
            title: "Everyday Eyewear"
          }
        },
        [_vm._v("Everyday Eyewear")]
      ),
      _vm._v(" "),
      _c("span", [_vm._v(" > ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }