var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stepsCount
    ? _c("div", { staticClass: "progress-bar" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "sticky",
                rawName: "v-sticky",
                value: _vm.shouldStick,
                expression: "shouldStick"
              }
            ],
            staticClass: "steps",
            attrs: { "sticky-offset": _vm.stickyOffset, "sticky-z-index": 100 }
          },
          [
            _c(
              "div",
              {
                staticClass: "steps-bar",
                class: { "steps-bar-mobile": _vm.isMobile }
              },
              [
                _vm.isMobile
                  ? _c(
                      "div",
                      { staticClass: "steps-bar-mobile-summary" },
                      [
                        _c("ContactsTotalsPrice", {
                          staticClass: "totals-price"
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "action product-configurator--nav-link",
                            class: { active: _vm.mobileNavShow },
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function($event) {
                                return _vm.mobileNavToggle()
                              }
                            }
                          },
                          [_c("span", [_vm._v("Order Details")])]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.stepsCount > 1
                  ? [
                      _vm.isDesktop || _vm.isMobile
                        ? _c(
                            "div",
                            {
                              staticClass: "steps-bar-items",
                              class: { "steps-bar-mobile-items": _vm.isMobile }
                            },
                            [
                              _vm._l(_vm.visibleList, function(step) {
                                var _obj
                                return [
                                  !step.isHidden
                                    ? _c(
                                        "div",
                                        {
                                          key: step.id,
                                          staticClass: "item",
                                          class:
                                            ((_obj = {
                                              highlight: step.shouldHighlight,
                                              ready: step.isReady,
                                              current: step.isCurrent,
                                              editable: step.canEdit
                                            }),
                                            (_obj[step.id] = step.id),
                                            _obj),
                                          on: {
                                            click: function($event) {
                                              return _vm.editStep(step)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "item-bage" },
                                            [_vm._v(_vm._s(step.index))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "item-label" },
                                            [
                                              _vm._v(
                                                _vm._s(step.progress_label)
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isTablet &&
                      _vm.currentStep &&
                      !_vm.currentStep.isHidden
                        ? _c("div", { staticClass: "steps-bar-items" }, [
                            _c(
                              "div",
                              {
                                staticClass: "item",
                                class:
                                  ((_obj = {
                                    highlight: _vm.currentStep.shouldHighlight,
                                    ready: _vm.currentStep.isReady,
                                    current: _vm.currentStep.isCurrent,
                                    editable: _vm.currentStep.canEdit
                                  }),
                                  (_obj[_vm.currentStep.id] =
                                    _vm.currentStep.id),
                                  _obj)
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "arrow prev",
                                    attrs: {
                                      role: "navigation",
                                      "aria-label": "Edit Previous Step",
                                      title: "Edit Previous Step"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.previousStep(_vm.currentStep)
                                      }
                                    }
                                  },
                                  [_vm._v("Prev")]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "item-bage" }, [
                                  _vm._v(_vm._s(_vm.currentStep.index))
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "item-label" }, [
                                  _vm._v(_vm._s(_vm.currentStep.progress_label))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "arrow next",
                                    attrs: {
                                      role: "navigation",
                                      "aria-label": "Next Step",
                                      title: "Next Step"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.nextStep(_vm.currentStep)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.isMobile
                  ? [
                      _vm.isProductConfigured
                        ? [
                            _c("Finish", {
                              staticClass: "steps-bar-finish",
                              attrs: {
                                "proceed-to-checkout-button-text": "Checkout",
                                "order-pair-button-text": "Another Pair?"
                              }
                            })
                          ]
                        : [
                            _vm.mobileNavShow
                              ? _c("div", { staticClass: "summary_block" }, [
                                  _vm._m(0),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "summary_block-content" },
                                    [
                                      _c("SummaryTotalsInfo", {
                                        staticClass: "totals-info",
                                        attrs: { place: _vm.place }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ]
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.isMobile && _vm.mobileNavShow
              ? _c(
                  "div",
                  { staticClass: "steps-bar-order-details" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "action product-configurator--nav-toggle steps-bar-order-details-close",
                        class: { active: _vm.mobileNavShow },
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.mobileNavToggle()
                          }
                        }
                      },
                      [_c("span", [_vm._v("Close")])]
                    ),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "summary_block steps-bar-order-details-summary"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "summary_block-content" },
                          [
                            _c("SummaryContactsInfo"),
                            _vm._v(" "),
                            _c("SummaryTotalsInfo", {
                              staticClass: "totals-info",
                              attrs: { place: _vm.place }
                            }),
                            _vm._v(" "),
                            _vm.hasDiscount
                              ? _c("SummaryTotalsDiscount")
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.enable_subscription === 1 && _vm.showSubscribe
                              ? _c("Subscribe")
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.showFramesPrice
                              ? _c("ContactsTotalsPrice", {
                                  staticClass: "totals-price"
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.getSubscribed === "no"
                              ? _c("SummaryAffirm", {
                                  staticClass: "affirm-choose-my-lenses"
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showFramesPrice
                              ? _c("ContactsTotalsPrice", {
                                  staticClass: "totals-price"
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("SummaryShipping", {
                      staticClass: "standard-shipping-container"
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "summary_block-header" }, [
      _c("span", [_vm._v("Summary")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "steps-bar-order-details-header" }, [
      _c("span", [_vm._v("Order Details")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }