var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lenses-seo-container" }, [
      _c(
        "div",
        { staticClass: "lenses-seo-prescription-container lenses-seo-section" },
        [
          _c("div", { staticClass: "lenses-seo-row lenses-seo-prescription" }, [
            _c(
              "div",
              { staticClass: "lenses-seo-text lenses-seo-prescription-text" },
              [
                _c("h2", [
                  _vm._v("New Prescription Lenses"),
                  _c("br"),
                  _vm._v("for Your Frames")
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Like so many eyeglass wearers, you count on your glasses to improve your vision. But accidents happen, lenses break or get scratched, and your vision needs change over time. When it's time to replace your lenses, Lensabl makes the process easier than ever."
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "Lensabl offers glasses lens replacements you can order online from the comfort of your home. Our process is the most convenient way to receive replacement prescription lenses anywhere in the United States and Canada. Count on Lensabl for fast, affordable prescription, non-prescription or "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/prescription-sunglass-lenses",
                        title: "Prescription Sunglass Lenses"
                      }
                    },
                    [
                      _c("span", { staticClass: "seo-ourlenses-link" }, [
                        _vm._v("sunglass lens replacement")
                      ])
                    ]
                  ),
                  _vm._v(
                    ". Rates start at $77, so order lenses online from Lensabl today."
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "lenses-seo-img" })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "lenses-seo-replacement-container lenses-seo-section" },
        [
          _c("div", { staticClass: "lenses-seo-row lenses-seo-replacement" }, [
            _c(
              "div",
              { staticClass: "lenses-seo-text lenses-seo-replacement-text" },
              [
                _c("h2", [_vm._v("How Lens Replacement Works")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "The Lensabl eyeglass lens replacement process is the easiest way to swap your old lenses with brand new ones tailored to your vision needs. Here's how it works:"
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "lenses-seo-replacement-numbers-list" }, [
              _c("div", { staticClass: "lenses-seo-replacement-list-item" }, [
                _c("div", { staticClass: "lenses-seo-number" }, [_vm._v("1")]),
                _vm._v(" "),
                _c("div", { staticClass: "lenses-seo-number-text" }, [
                  _c("span", [_vm._v("Browse our site:")]),
                  _c("br"),
                  _vm._v(
                    "Visit our lens options page to see a full list of the lenses and lens coatings we carry."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "lenses-seo-replacement-list-item" }, [
                _c("div", { staticClass: "lenses-seo-number" }, [_vm._v("2")]),
                _vm._v(" "),
                _c("div", { staticClass: "lenses-seo-number-text" }, [
                  _c("span", [_vm._v("Select your lenses:")]),
                  _c("br"),
                  _vm._v(
                    "Choose the lens matching your prescription, frame design or stylistic preference."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "lenses-seo-replacement-list-item" }, [
                _c("div", { staticClass: "lenses-seo-number" }, [_vm._v("3")]),
                _vm._v(" "),
                _c("div", { staticClass: "lenses-seo-number-text" }, [
                  _c("span", [_vm._v("Mail us your frames:")]),
                  _c("br"),
                  _vm._v(
                    "You'll receive a free shipping label and pre-paid box to send your frames to our Los Angeles offices safely."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "lenses-seo-replacement-list-item" }, [
                _c("div", { staticClass: "lenses-seo-number" }, [_vm._v("4")]),
                _vm._v(" "),
                _c("div", { staticClass: "lenses-seo-number-text" }, [
                  _c("span", [_vm._v("Trust our experts:")]),
                  _c("br"),
                  _vm._v(
                    "When we receive your frames, our lens experts will swap your old lenses with the new ones."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "lenses-seo-replacement-list-item" }, [
                _c("div", { staticClass: "lenses-seo-number" }, [_vm._v("5")]),
                _vm._v(" "),
                _c("div", { staticClass: "lenses-seo-number-text" }, [
                  _c("span", [_vm._v("Receive your new lenses:")]),
                  _c("br"),
                  _vm._v(
                    "Your glasses will arrive at your door in about two weeks after our facility has received them."
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "lenses-seo-lrtypes-container lenses-seo-section" },
        [
          _c("div", { staticClass: "lenses-seo-row seo-lrtypes" }, [
            _c("div", { staticClass: "lenses-seo-lrtypes-img" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lenses-seo-text lenses-seo-lrtypes-text" },
              [
                _c("h2", [_vm._v("Types of Lenses We Replace")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                    Lensabl makes online lens replacement accessible for any eyeglass wearer."
                  ),
                  _c("br"),
                  _vm._v("Our "),
                  _c(
                    "a",
                    { attrs: { href: "/our-lenses", title: "Our Lenses" } },
                    [
                      _c("span", { staticClass: "seo-ourlenses-link" }, [
                        _vm._v("lens options")
                      ])
                    ]
                  ),
                  _vm._v(" include:\n                ")
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "lenses-seo-lrtypes-list" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/single-vision-lenses",
                          title: "Single Vision"
                        }
                      },
                      [
                        _c("span", { staticClass: "seo-ourlenses-link" }, [
                          _vm._v("Single Vision")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/digital-hd-progressive-lenses",
                          title: "Digital HD Progressive"
                        }
                      },
                      [
                        _c("span", { staticClass: "seo-ourlenses-link" }, [
                          _vm._v("Digital HD Progressive")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/computer-blue-light-blocking-lenses",
                          title: "Blue-light Blocking Computer Lenses"
                        }
                      },
                      [
                        _c("span", { staticClass: "seo-ourlenses-link" }, [
                          _vm._v("Blue-light Blocking Computer Lenses")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/transitions-photochromic",
                          title: "Transitions® Light Intelligent Lenses™"
                        }
                      },
                      [
                        _c("span", { staticClass: "seo-ourlenses-link" }, [
                          _vm._v("Transitions® Light Intelligent Lenses™")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/high-index-lenses",
                          title: "High Index Lenses"
                        }
                      },
                      [
                        _c("span", { staticClass: "seo-ourlenses-link" }, [
                          _vm._v("High Index Lenses")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/bifocal-lenses",
                          title: "Bifocal Lenses"
                        }
                      },
                      [
                        _c("span", { staticClass: "seo-ourlenses-link" }, [
                          _vm._v("Bifocal Lenses")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/reading-lenses",
                          title: "Reading Lenses"
                        }
                      },
                      [
                        _c("span", { staticClass: "seo-ourlenses-link" }, [
                          _vm._v("Reading Lenses")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Non-Rx and Rx Sunglasses")]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Rx and Non-Rx Polarized Sunglasses")]),
                  _vm._v(" "),
                  _c("li", [_vm._v("Sunglass Lenses")])
                ])
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "lenses-seo-eyetest-container lenses-seo-section" },
        [
          _c("div", { staticClass: "lenses-seo-row seo-eyetest" }, [
            _c("div", { staticClass: "lenses-seo-text seo-eyetest-text" }, [
              _c("h2", [_vm._v("Get Quality Lenses at Lensabl")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lensabl makes the process as simple, affordable and as fast as possible, so "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/choose-my-lenses",
                      title: "Choose My Lenses"
                    }
                  },
                  [
                    _c("span", { staticClass: "seo-contact-link" }, [
                      _vm._v("order your replacement lenses today")
                    ])
                  ]
                ),
                _vm._v("!")
              ])
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "/choose-my-lenses", title: "Choose My Lenses" }
              },
              [
                _c("div", { staticClass: "button button--copy-solid" }, [
                  _c("div", { staticClass: "button__container" }, [
                    _vm._v("GET MY LENSES")
                  ])
                ])
              ]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }