var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pd_selector" }, [
    _c("div", { staticClass: "pd-parameters" }, [
      _c("div", { staticClass: "pd-label" }, [
        _c(
          "span",
          [
            _c("span", [_vm._v("Pupillary Distance (PD) ")]),
            _vm._v(" "),
            _c("HelpBubble", { attrs: { data: _vm.help.enter_pd } })
          ],
          1
        )
      ]),
      _vm._v(" "),
      !_vm.value.separate_pd
        ? _c(
            "div",
            { staticClass: "pd-input-container" },
            [
              _vm.showEditVersion
                ? _c("CustomSelect", {
                    staticClass: "pd-input",
                    attrs: { value: _vm.value.pd, options: _vm.eyePDOptions },
                    on: {
                      change: function($event) {
                        return _vm.setPrescriptionValue(
                          $event.target.value,
                          "pd"
                        )
                      }
                    }
                  })
                : _c("div", { staticClass: "pd-input" }, [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.value.pd) }
                    })
                  ])
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "pd-input-container separate-pd-values" },
            [
              _vm.showEditVersion
                ? _c("CustomSelect", {
                    staticClass: "pd-input",
                    attrs: {
                      value: _vm.value.od_pd,
                      options: _vm.eyeODPDOptions
                    },
                    on: {
                      change: function($event) {
                        return _vm.setPrescriptionValue(
                          $event.target.value,
                          "od_pd"
                        )
                      }
                    }
                  })
                : _c("div", { staticClass: "pd-input" }, [
                    _c("label", [_vm._v("RIGHT (OD)")]),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.value.od_pd) }
                    })
                  ]),
              _vm._v(" "),
              _vm.showEditVersion
                ? _c("CustomSelect", {
                    staticClass: "pd-input",
                    attrs: {
                      value: _vm.value.os_pd,
                      options: _vm.eyeOSPDOptions
                    },
                    on: {
                      change: function($event) {
                        return _vm.setPrescriptionValue(
                          $event.target.value,
                          "os_pd"
                        )
                      }
                    }
                  })
                : _c("div", { staticClass: "pd-input" }, [
                    _c("label", [_vm._v("LEFT (OS)")]),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.value.os_pd) }
                    })
                  ])
            ],
            1
          )
    ]),
    _vm._v(" "),
    _vm.showEditVersion
      ? _c(
          "div",
          { staticClass: "pd_checkbox enter-it-online" },
          [
            _c("RadioLabel", {
              attrs: {
                active: _vm.value.separate_pd,
                label: "I have two PD numbers"
              },
              on: {
                click: function($event) {
                  _vm.value.separate_pd = !_vm.value.separate_pd
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }