import productStorage from '../product/storage/product/view'
import Configurator from '../../configurator/Configurator.vue'

import Summary from '../summary/Summary.vue'
import ProgressBar from './ProgressBar.vue'
import Finish from './Finish'

export default {
    name: 'ContactsConfigurator',
    extends: Configurator,
    components: {
        Summary,
        ProgressBar,
        Finish
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['contacts/productView/']) {
            this.$store.registerModule(['contacts', 'productView'], productStorage(this.$store), { preserveState: false })
        }
    },
    // clear prescription owner/doctor info
    created () {
        this.$store.dispatch('contacts/fetchDefaultData')
        this.$store.dispatch('contacts/clearProductAddToCart')
        this.$store.commit('prescriptions/UPDATE_FIRST_NAME', '')
        this.$store.commit('prescriptions/UPDATE_LAST_NAME', '')
        this.$store.commit('prescriptions/RESET_DOCTOR')
        this.$store.commit('updateReset', false)
    }
}
