import axios from 'axios'

const controller = 'lensabl/order/'
let options_save = null

class OptionsSaveService {
    constructor (store) {
        this.base_url = store.getters['storeView/base_url']
    }

    saveOptions (data) {
        const url = this.base_url + controller + 'saveOptions'
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    prepareData (data) {
        const url = this.base_url + controller + 'prepareData'
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    uploadImage (data) {
        const url = this.base_url + controller + 'uploadImage'
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    checkPrevItem (item_id) {
        const url = this.base_url + controller + 'check/item_id/' + item_id
        return axios.get(url)
    }
}

export default function getOptionsSaveService (options = {}) {
    if (options) {
        options_save = new OptionsSaveService(options)
    }
    return options_save
}
