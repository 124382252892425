<template>
    <div>
        <div class="contacts_rx-container">
            <table class="prescription-data" border="0">
                <tbody>
                    <tr>
                        <td v-if="eye === 'left'" rowspan="2">{{ `OS (${eye})` }}</td>
                        <td v-else rowspan="2">{{ `OD (${eye})` }}</td>
                        <td>Power/SPH</td>
                        <td>BC</td>
                        <td>DIA</td>
                        <td>CYL</td>
                        <td>Axis</td>
                        <td>Add Power</td>
                        <td v-if="contactsColorData.length && contactsColorData[0].length !== 0">Color</td>
                    </tr>
                    <tr>
                        <td>
                            <span v-show="!editData">{{ contactsEyeData.rx.power }}</span>
                            <select v-show="editData" :value="contactsEyeData.rx.power" @change="updateRxValue($event.target.value, 'power')">
                                <option v-for="option in sortValues(dropdownValues.power)" :key="option" :value="option * 1">{{ option }}</option>
                            </select>
                        </td>
                        <td>
                            <span v-show="!editData">{{ contactsEyeData.rx.bc }}</span>
                            <select v-show="editData" :value="contactsEyeData.rx.bc" @change="updateRxValue($event.target.value, 'bc')">
                                <option v-for="option in sortValues(dropdownValues.bc)" :key="option" :value="option * 1">{{ option }}</option>
                            </select>
                        </td>
                        <td>
                            <span>{{ contactsEyeData.rx.dia }}</span>
                        </td>
                        <td>
                            <span v-show="!editData">{{ eyeData.cyl }}</span>
                            <select v-show="editData" :value="contactsEyeData.rx.cyl" @change="updateRxValue($event.target.value, 'cyl')">
                                <option v-for="option in sortValues(dropdownValues.cyl)" :key="option" :value="option * 1">{{ option }}</option>
                            </select>
                        </td>
                        <td>
                            <span v-show="!editData">{{ contactsEyeData.rx.axis }}</span>
                            <select v-show="editData" :value="contactsEyeData.rx.axis" @change="updateRxValue($event.target.value, 'axis')">
                                <option v-for="option in sortValues(dropdownValues.axis)" :key="option" :value="option * 1">{{ option }}</option>
                            </select>
                        </td>
                        <td>
                            <span v-show="!editData">{{ contactsEyeData.rx.add }}</span>
                            <select v-show="editData" :value="contactsEyeData.rx.add" @change="updateRxValue($event.target.value, 'add')">
                                <option v-for="option in sortValues(dropdownValues.add)" :key="option" :value="option * 1">{{ option }}</option>
                            </select>
                        </td>
                        <td v-if="contactsColorData.length && contactsColorData[0].length !== 0">
                            <span v-show="!editData">{{ contactsEyeData.color }}</span>
                            <select v-show="editData" :value="contactsEyeData.color" @change="updateRxValue($event.target.value, 'color')">
                                <option v-for="option in contactsColorData" :key="option" :value="option">{{ option }}</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import contactsPrescriptionModel from '../../../../../app/common/constants/contacts'

export default {
    name: 'ContactsSalesOrderEyePrescription',
    extends: contactsPrescriptionModel,
    props: {
        editData: {
            type: Boolean,
            default: false
        },
        eye: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState('contacts', ['contactsRXData']),
        ...mapGetters('contacts', ['isContactDetailsPage', 'getEyeData', 'getLeftEyeConfirmed', 'getRightEyeConfirmed']),
        ...mapGetters('contacts/productView', ['productView']),
        eyeData () {
            return this.productView.eyeData[this.eye]
        },
        contactsEyeData () {
            return this.getEyeData[this.eye]
        },
        dropdownValues () {
            return this.eyeData.dropdownValues
        },
        contactsColorData () {
            const allColors = this.dropdownValues.color ? this.dropdownValues.color.substring(0, this.dropdownValues.color.length - 1) : false
            const container = allColors ? allColors.split(',') : []
            const colors = container.reduce((unique, item) => unique.includes(item.trim()) ? unique : [...unique, item.trim()], [])
            for (let i = 0; i < colors.length; i++) {
                if (colors[i] === 'Null') {
                    colors.splice(i, 1)
                }
            }
            return colors
        }
    },
    methods: {
        updateRxValue (value, rx_type) {
            if (rx_type === 'color') {
                this.$store.commit('contacts/SET_EYE_DATA', {
                    eye: this.eye,
                    [rx_type]: value
                })
            } else {
                this.$store.commit('contacts/SET_EYE_RX_CHECKED', {
                    eye: this.eye,
                    rx: {
                        [rx_type]: value
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
