var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "pd_selector" }, [
      _c("div", { staticClass: "pd-parameters" }, [
        _c("div", { staticClass: "pd_checkbox" }, [
          _c(
            "label",
            { staticClass: "radio-label" },
            [
              _c(
                "div",
                {
                  staticClass: "option-checkbox-container",
                  class: { active: _vm.pdInRx }
                },
                [
                  _c("div", { staticClass: "option-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.pdInRx,
                          expression: "pdInRx"
                        }
                      ],
                      staticClass: "prescription-lens-radio",
                      attrs: {
                        type: "checkbox",
                        title: "My prescription image has PD."
                      },
                      domProps: {
                        checked: Array.isArray(_vm.pdInRx)
                          ? _vm._i(_vm.pdInRx, null) > -1
                          : _vm.pdInRx
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.pdInRx,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.pdInRx = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.pdInRx = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.pdInRx = $$c
                          }
                        }
                      }
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v("My Pupillary Distance is listed on my prescription.")
              ]),
              _vm._v(" "),
              _c("HelpBubble", {
                attrs: { data: { params: { text: _vm.help } } }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.pdInRx &&
      (_vm.fittingbox_status === "taken" || _vm.fittingbox_status === "retaken")
        ? _c("div", { staticClass: "fittingbox-warning-upload" }, [
            _c("br"),
            _vm._v(
              "We have your previous photo which we will process soon.\n            "
            ),
            _c("br"),
            _vm._v(
              "You can upload another if you like, which will override it.\n        "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.pdInRx && _vm.showError
        ? _c("div", { staticClass: "my-prescription-form" }, [
            _c("p", { staticClass: "error-text-rx" }, [
              _vm._v("Please choose a new image!")
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }