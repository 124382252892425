import axios from 'axios'

const CONTROLLER = 'lensablall/api_anagram'
const getDefaultState = () => {
    return {
        hasInsuranceForm: {
            company: null,
            memberId: null,
            memberBrthDay: null,
            sex: null,
            individual_plan_name: null,
            hasInsurance: true
        },
        hasNotInsuranceForm: {
            company: null,
            memberBrthDay: null,
            sex: null,
            primary: {
                firstName: null,
                lastName: null,
                memberId: null,
                memberBrthDay: null,
                phone: null,
                individual_plan_name: null,
                sex: null,
            },
            hasInsurance: false
        },
        companies: [
            {label: 'Aetna Vision', value: 'aetna_vision'},
            {label: 'Blue View', value: 'blue_view'},
            {label: 'VSP', value: 'vsp'},
            {label: 'Eyemed', value: 'eye_med'},
            {label: 'Spectera', value: 'spectera'},
            {label: 'Davis Vision', value: 'davis_vision'},
            {label: 'Cigna Vision', value: 'cigna_vision'},
            {label: 'Superior Vision', value: 'superior'},
        ],
        availableSex: [
            {label: 'Male', value: 'male'},
            {label: 'Female', value: 'female'},
        ],
        claimAdditions: ['vsp', 'cigna_vision'],
        success: false,
        error: false,
        errorText: 'We have encountered an issue. Please contact support@lensabl.com'
    }
}

const state = getDefaultState()
const mutations = {
    resetState: (state) => Object.assign(state, getDefaultState()),
    preselectHasInsuranceForm: (state, hasInsuranceForm = {}) => Object.assign(state.hasInsuranceForm, hasInsuranceForm),
    preselectHasNotInsuranceForm: (state, hasNotInsuranceForm = {}) => Object.assign(state.hasNotInsuranceForm, hasNotInsuranceForm),
    setSuccess: (state, success) => state.success = success,
    setError: (state, error) => state.error = error,
    setErrorText: (state, errorText = '') => state.errorText = errorText,
}
const actions = {
    preselectAdmin: ({commit, getters}) => {
        const adminPreSelectData = getters.adminPreSelectData

        if (getters.adminPreSelectHasInsurance) {
            commit('preselectHasInsuranceForm', adminPreSelectData)
        } else {
            commit('preselectHasNotInsuranceForm', adminPreSelectData)
        }
    },
    sendToAnagram: ({commit, rootGetters}, params = {}) => {
        commit('resetState')
        const url = rootGetters['storeView/base_url'] + CONTROLLER + ''
        return axios.post(url, params).then(({data, status}) => {
            if (status === 200) {
                commit('setSuccess', data.success)
            }
        }).catch(error => {
            const errorResp = error.response
            const data = errorResp.data
            console.error(error)
            commit('setSuccess', data.success)
            commit('setError', !data.success)
            if (errorResp.status === 400) {
                commit('setErrorText', `We have encountered issues: ${data.message}`)
            }
        })
    },
    sendToAnagramAdmin: ({commit, getters, rootGetters}, params = {}) => {
        params.order_id = getters.adminOrderId
        commit('setError', false)
        commit('setSuccess', false)
        // commit('resetState')
        return axios.post(`${getters.adminSavedUrl}?isAjax=true&form_key=${rootGetters['storeView/form_key']}`, params)
            .then(({data, status}) => {
                if (status === 200) {
                    commit('setSuccess', data.success)
                }
            }).catch(error => {
                const errorResp = error.response
                const data = errorResp.data
                console.error(error)
                commit('setSuccess', data.success)
                commit('setError', !data.success)
                commit('setErrorText', `We have encountered issues: ${data.message}`)
            })
    },
    awaitResponseFromAnagram: async ({commit, getters, rootGetters}, params = {}) => {
        let returnData = {}, i = 0
        while (true) {
            const res = await axios.post(params.url, params.data)
            returnData = res.data
            if (returnData.status !== 'processing') break;
            if (i > 60) {
                throw new Error('Too long delay')
            }
            i++
            await new Promise(r => setTimeout(() => r(), 1000))
        }
        return Promise.resolve(returnData)
    },
    sendToAnagramEligibilityRequest: async ({commit, dispatch, getters, rootGetters}, params = {}) => {
        const url = rootGetters['storeView/base_url'] + CONTROLLER + '/eligibility'
        const {data} = await axios.post(url, params)
        const res = await dispatch('awaitResponseFromAnagram', {data: {...params, ...data}, url: url + 'Result'})
        const resDetails = await axios.post(url + 'ResultDetails', {...res, ...data})
        const patientData = await dispatch('awaitResponseFromAnagram', {data: {...params, ...resDetails.data}, url: url + 'ResultRequestDetails'})
        return Promise.resolve(patientData)
    }
}
const getters = {
    getHasInsuranceForm: state => state.hasInsuranceForm,
    getHasNotInsuranceForm: state => state.hasNotInsuranceForm,
    productsName: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.productsName
    },
    adminPreSelectData: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.preSelectData
    },
    adminPreSelectHasInsurance: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.preSelectData.hasInsurance
    },
    adminOrderId: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.orderId
    },
    adminSavedUrl: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.saveUrl
    },
}

export default store => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
