<template>
    <div class="help-bubble-container lplus-help-bubble-container">
        <div class="help-component">
            <div id="help_bubble_text" :class="showModalAd ? 'open' : ''" class="help-bubble lplus-help-bubble">
                <span>
                    <p>Lensabl+ plans include an annual supply of contacts, a FREE frame with premium Rx lenses or lens replacement, and a reward for getting an eye exam (or take our online vision test for FREE). Save 15% on all additional contact lens purchases throughout the year. <span class="c-help-bubble-link"><a href="/plus/vision_plan">Customize your plan now</a></span></p>
                </span>
                <span class="close-button" @click.stop="closePlusAdModal">
                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                        <path id="svg_1" d="m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z" fill="rgb(102, 102, 102)" fill-rule="evenodd" />
                    </svg>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CatalogContactsProductViewHelpComponent',
    props: {
        showModalAd: {
            type: [Boolean],
            default: false
        }
    }
}
</script>
