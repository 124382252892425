import Vue from 'vue'
import _map from 'lodash/map'
import _union from 'lodash/union'
import _isEqual from 'lodash/isEqual'

export const encodeObjectToURI = function (obj) {
    let str = ''
    _map(obj || {}, (value, key) => {
        if (str !== '') {
            str += '&'
        }
        str += key + '=' + encodeURIComponent(value)
    })
    return str
}

export const buildFormData = function (formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        const value = data == null ? '' : data
        formData.append(parentKey, value)
    }
    return formData
}

export const jsonToFormData = function (data) {
    const formData = new FormData()
    return buildFormData(formData, data)
}

export const setComputedArrayData = function (obj, data, replaceData = false) {
    _map(data, (value, key) => {
        if (obj && (!obj.hasOwnProperty(key) || (obj.hasOwnProperty(key) && !_isEqual(value, obj[key])))) {
            if (!replaceData && obj[key] && (typeof obj[key] === 'object')) {
                if (Array.isArray(obj[key]) && Array.isArray(value)) {
                    Vue.set(obj, key, _union([].concat(obj[key], value)))
                } else {
                    setComputedArrayData(obj[key], value)
                }
            } else {
                Vue.set(obj, key, value)
            }
        }
    })
    return obj
}

export const setComputedData = function (obj, data, replaceData = false, computed = true) {
    _map(data, (value, key) => {
        if (obj && (!obj.hasOwnProperty(key) || (obj.hasOwnProperty(key) && !_isEqual(value, obj[key])))) {
            if (!replaceData && obj[key] && (typeof obj[key] === 'object')) {
                obj[key] = setComputedData(obj[key], value, replaceData, computed)
            } else {
                if (computed) {
                    Vue.set(obj, key, value)
                } else {
                    obj[key] = value
                }
            }
        }
    })
    return obj
}

export const updateStateData = function (state, data = {}, replaceData = false, computed = true) {
    const stateData = {}
    _map(data, (value, key) => {
        if (state.hasOwnProperty(key) && !_isEqual(value, state[key])) {
            stateData[key] = value
        }
    })
    return setComputedData(state, stateData, replaceData, computed)
}
