var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "c-product-slider struct-parallax-bg" }, [
      _c("h2", { staticClass: "c-product-slider-header" }, [
        _vm._v("All major contact lens brands are available")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "c-product-slider-subheader" }, [
        _vm._v(
          "Including spherical contacts, toric lenses for astigmatism and multifocal lenses for presbyopia."
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-product-slider-container" },
        [
          _c(
            "carousel",
            {
              staticClass: "c-homepage-slider",
              attrs: {
                "per-page": 3,
                "per-page-custom": [
                  [1, 1],
                  [768, 3]
                ],
                "mouse-drag": false,
                "touch-drag": true,
                "navigation-enabled": true,
                "navigation-prev-label": "‹",
                "navigation-next-label": "›"
              }
            },
            _vm._l(_vm.featuredProduct, function(product) {
              return _c(
                "slide",
                { key: product.index, staticClass: "c-homepage-slider-slide" },
                [
                  _c("a", { attrs: { href: product.url, alt: product.name } }, [
                    _c("img", {
                      attrs: { src: product.image, alt: product.name }
                    }),
                    _vm._v(" "),
                    _c("p", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: product.name,
                          expression: "product.name"
                        }
                      ],
                      staticClass: "c-product-slider-name"
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "c-product-slider-description" }, [
                      _vm._v(_vm._s(product.description))
                    ])
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    !_vm.plusMember
      ? _c("section", { staticClass: "c-lplus-ad-block" }, [
          _c("div", { staticClass: "lplus-ad-block_container" }, [
            _c("a", [
              _vm.isMobile
                ? _c("img", {
                    staticClass: "lplus-ad-block_img",
                    attrs: {
                      src: "/media/wysiwyg/lensabl-plus-ad-mobile.jpg",
                      alt:
                        "Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                    }
                  })
                : _c("img", {
                    staticClass: "lplus-ad-block_img",
                    attrs: {
                      src: "/media/wysiwyg/lensabl-plus-ad.webp",
                      alt:
                        "Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                    }
                  }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "help-bubble-container lplus-help-bubble-container"
                },
                [
                  _c("div", { staticClass: "help-component" }, [
                    _c(
                      "div",
                      {
                        staticClass: "help-bubble lplus-help-bubble",
                        attrs: { id: "help_bubble_text" }
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("span", { staticClass: "close-button" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "18",
                                height: "18",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  id: "svg_1",
                                  d:
                                    "m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z",
                                  fill: "rgb(102, 102, 102)",
                                  "fill-rule": "evenodd"
                                }
                              })
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { attrs: { href: "/shop-contacts/brands", alt: "Find Your Brand" } },
      [
        _c(
          "div",
          { staticClass: "c-product-slider-button button button--copy-solid" },
          [
            _c("div", { staticClass: "button__container" }, [
              _vm._v("View All")
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("p", [
        _vm._v(
          "A new, better alternative to traditional vision insurance plans, starting as low as "
        ),
        _c("b", [_vm._v("$7.99/month.")])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Lensabl+ plans include an annual supply of contacts, a FREE frame with Rx lenses or lens replacement, and a reward for getting an eye exam. Save hundreds of dollars out-of-pocket per year on your vision care needs. "
        ),
        _c("span", { staticClass: "c-help-bubble-link" }, [
          _vm._v("Customize your plan now")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }