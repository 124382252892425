<template>
    <div v-show="show" class="plus-wrapper" :class="{'business-redeem-wrapper' : isBusinessMember}">
        <Notification class="lplus-card notification-mobile" />
        <div id="main-cards" class="main-card-holder">
            <BusinessContactsRedeem v-if="isBusinessMember" :class="{'disabled': !paidUp}" class="lplus-card first" />
            <Contacts v-else :class="{'disabled': !paidUp}" class="lplus-card first" />
            <p id="animate-contacts-msg">To use a reward, click the "Redeem" button</p>
            <BusinessFramesRedeem v-if="isBusinessMember" :class="{'disabled': !paidUp}" class="lplus-card rest second" />
            <Glasses v-else-if="!isFreeMembership || isFreeMembership == 0" :class="{'disabled': !paidUp}" class="lplus-card rest second" />
            <EyeExam :class="{'disabled': !paidUp}" class="lplus-card rest third" />
            <PlusDiscountRedeem :class="{'disabled': !paidUp}" class="lplus-card rest last-card" />
            <TrialExpiring  v-if="!isBusinessMember" :class="{'disabled': !paidUp}" class="lplus-card rest last-card" />
            <BusinessNonLensablRedeem v-if="isBusinessMember" class="lplus-card rest last-card" />
        </div>
        <Notification class="notification-desktop" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Contacts from './ContactsRedeem.vue'
import Glasses from './GlassesRedeem.vue'
import EyeExam from './EyeExamRedeem.vue'
import Notification from './NotificationsRedeem.vue'
import PlusDiscountRedeem from './PlusDiscountRedeem.vue'
import BusinessFramesRedeem from './BusinessFramesRedeem.vue'
import BusinessContactsRedeem from './BusinessContactsRedeem.vue'
import BusinessNonLensablRedeem from './BusinessNonLensablRedeem.vue'
import TrialExpiring from './TrialExpiringRedeem.vue'

export default {
    name: 'PlusDashboard',
    components: {
        Notification,
        Contacts,
        Glasses,
        EyeExam,
        PlusDiscountRedeem,
        BusinessFramesRedeem,
        BusinessContactsRedeem,
        TrialExpiring,
        BusinessNonLensablRedeem
    },
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    computed: {
        ...mapGetters('lensablplus_customer', ['paidUp', 'isContactsInPlan', 'isBusinessMember', 'getObjectConfig', 'currentComponent', 'plusMember', 'isFreeMembership']),
        config () {
            return this.getObjectConfig(this.id)
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        },
        show () {
            return this.plusMember ? this.isCurrent : false
        }
    }
}
</script>

<style scoped>

</style>
