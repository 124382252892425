<template>
    <div sticky-container>
        <div class="summary_block contacts-summary-block">
            <div class="summary_block-header">
                <span>Summary</span>
            </div>
            <div v-sticky="shouldStick" :sticky-offset="stickyOffset" class="summary_block-content">
                <SummaryContactsInfo />
                <SummaryTotalsInfo :place="place" class="totals-info" />
                <SummaryTotalsDiscount v-if="hasDiscount" />
                <Subscribe v-if="enable_subscription === 1 && showSubscribe" />
                <SummaryTotalsPrice v-if="!showFramesPrice" class="totals-price" />
                <transition name="fade">
                    <SummaryAffirm v-if="getSubscribed == 'no'" class="affirm-choose-my-lenses" />
                </transition>
                <SummaryShipping class="standard-shipping-container" />
                <SummaryTotalsPrice v-if="showFramesPrice" class="totals-price" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Summary from '../../summary/Summary.vue'
import SummaryContactsInfo from './ContactsInfo.vue'
import SummaryTotalsPrice from './TotalsPrice.vue'
import SummaryAffirm from './Affirm.vue'
import Subscribe from '../Subscribe.vue'

export default {
    name: 'ContactsSummary',
    components: {
        SummaryTotalsPrice,
        SummaryContactsInfo,
        SummaryAffirm,
        Subscribe
    },
    extends: Summary,
    data: () => ({
        showSubscribe: true
    }),
    computed: {
        ...mapGetters('contacts', ['getSubscribed', 'getEyeData', 'getLeftEyeConfirmed', 'getRightEyeConfirmed']),
        ...mapGetters('configurator', ['enable_subscription']),
        ...mapGetters('lensablplus_customer', ['isLensablPlus', 'isRedeemFlow'])
    },
    mounted () {
        if (this.getLeftEyeConfirmed && this.getRightEyeConfirmed) {
            if (this.getEyeData.left.brand === this.getEyeData.right.brand) {
                this.showSubscribe = true
            } else {
                this.showSubscribe = false
            }
        }
    }
}
</script>
