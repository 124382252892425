<template>
    <div v-if="componentsCount" class="lensabl-plus-content-wrapper struct-parallax-bg" :class="{'load': getLoad}">
        <PageLoader v-if="getLoad" :load="getLoad" />
        <div v-else>
            <div class="lensabl-plus-content">
                <template v-for="object in componentsList">
                    <component :is="object.component.name" :id="object.id" :key="object.id" :class="['lensabl-plus-content ' + object.component.name]" />
                </template>
                <Back />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import lensablPlus from '../storage/lensablplus'
import Choice from './Choice.vue'
import ChoiceRadio from './ChoiceRadio.vue'
import SearchPrescribedContacts from './SearchPrescribedContacts.vue'
import Finish from './Finish.vue'
import Back from './Back.vue'
import PageLoader from '../../base/PageLoader.vue'

export default {
    name: 'PlanChoice',
    components: {
        Choice,
        ChoiceRadio,
        SearchPrescribedContacts,
        Finish,
        Back,
        PageLoader
    },
    computed: {
        ...mapGetters('lensablPlus', ['componentsList', 'componentsCount', 'values', 'firstComponent', 'currentComponent', 'checkoutData', 'getLoad'])
    },
    watch: {
        values: {
            handler () {
                this.updateComponents()
            },
            deep: true
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablPlus/']) {
            this.$store.registerModule('lensablPlus', lensablPlus(this.$store))
        }
        this.$store.dispatch('lensablPlus/fetchDefaultData')
        this.$store.commit('lensablPlus/LOAD', true)
        this.$store.dispatch('lensablPlus/updateComponents')
        this.$store.dispatch('lensablPlus/fetchPlans')
        this.$store.dispatch('lensablPlus/fetchMaxContactsSize')
    },
    methods: {
        ...mapActions('lensablPlus', ['updateComponents'])
    }
}
</script>

<style lang="less" scoped>

</style>
