var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "online-optometry", attrs: { "data-smooth-scroll": "" } },
    [
      _c("section", { staticClass: "online-optometry__hero" }, [
        _c("div", { staticClass: "online-optometry" }, [
          _c("div", { staticClass: "online-optometry__container" }, [
            _c("div", { staticClass: "hero-image__background" }, [
              _c("div", { staticClass: "hero-video-button__row" }, [
                _c("div", { staticClass: "online-optometry__layout" }, [
                  _c("div", { staticClass: "online-optometry__copy" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("h1", [_vm._v("Online Vision Test")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "online-optometry__copy-primary" }, [
                      _vm._v(
                        "Take an online vision test to renew your prescription from the comfort of your home through the user-friendly Lensabl website."
                      )
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    !_vm.plusMember
                      ? _c(
                          "section",
                          {
                            staticClass:
                              "lenses-lplus-ad-block_container vision-lplus-ad-block_container"
                          },
                          [
                            _vm._v(
                              "\n                                    or join "
                            ),
                            _vm._m(2),
                            _vm._v(" for a "),
                            _c("b", [_vm._v("FREE vision test —")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "lenses-lplus-ad-block" },
                              [
                                _c(
                                  "a",
                                  { staticClass: "lplus-ad-learn-link" },
                                  [_vm._v("Learn More")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "help-bubble-container lplus-help-bubble-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "help-component" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "help-bubble lplus-help-bubble",
                                            attrs: { id: "help_bubble_text" }
                                          },
                                          [
                                            _vm._m(3),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "close-button" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      width: "18",
                                                      height: "18",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        id: "svg_1",
                                                        d:
                                                          "m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z",
                                                        fill:
                                                          "rgb(102, 102, 102)",
                                                        "fill-rule": "evenodd"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(4)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "home__hero-btn home__hero-btn--arrow",
            attrs: { href: "#how-the-online-exam-works" }
          },
          [
            _c("div", { staticClass: "button button--icon" }, [
              _c("div", { staticClass: "button__icon button__icon--base" }, [
                _c("svg", [
                  _c("use", {
                    attrs: { "xlink:href": "#lensabl-arrow-medium-down" }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "button__icon button__icon--hover" }, [
                _c("svg", [
                  _c("use", {
                    attrs: { "xlink:href": "#lensabl-arrow-medium-down" }
                  })
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      _vm._m(7),
      _vm._v(" "),
      _c("OnlineVisionSeo"),
      _vm._v(" "),
      _vm._m(8)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { overflow: "hidden", height: "0", width: "0" } },
      [
        _c("meta", {
          attrs: {
            property: "og:image",
            content:
              "https://www.lensabl.com/media/wysiwyg/cms/lensabl-opternative-hero-image-2.jpg"
          }
        }),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src:
              "https://www.lensabl.com/media/wysiwyg/cms/lensabl-opternative-hero-image-2.jpg",
            alt: "lensabl-opternative-hero-image-2"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "online-optometry__copy-primary" }, [
      _vm._v(
        "Our online vision test for contacts and glasses gets you a fast, "
      ),
      _c("b", [_vm._v("affordable doctor-issued prescription for just $25 —")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/plus" } }, [
      _c("span", { staticStyle: { "text-decoration": "underline" } }, [
        _vm._v("Lensabl+")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("p", [
        _vm._v(
          "A new, better alternative to traditional vision insurance plans, starting as low as "
        ),
        _c("b", [_vm._v("$7.99/month.")])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Lensabl+ plans include a FREE online vision test, an annual supply of contacts, and a FREE frame with premium Rx lenses. Save hundreds of dollars out-of-pocket per year on your vision care needs. "
        ),
        _c("span", { staticClass: "c-help-bubble-link" }, [
          _vm._v("Customize your plan now")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-video-button__buttons" }, [
      _c("a", { attrs: { href: "/online-eye-exam-qualification" } }, [
        _c(
          "div",
          {
            staticClass: "hero-video-button__button-primary",
            staticStyle: { "text-align": "center" }
          },
          [
            _c("div", { staticClass: "button button--copy-solid-dark" }, [
              _c("div", { staticClass: "button__container" }, [
                _vm._v("Get Started Now")
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticClass: "custom-callout__content reverse",
        staticStyle: {
          "background-color": "#ffffff",
          padding: "4rem 0 0",
          margin: "0",
          "max-width": "100%"
        },
        attrs: { id: "how-the-online-exam-works" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "columns small-18 medium-push-3 medium-12" },
            [
              _c("h2", [_vm._v("How The Vision Test Works")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Lensabl makes it easier than ever to renew your vision prescription for contacts and glasses in 24 hours or less by following these steps:"
                )
              ])
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "opternative-icon-wrapper" }, [
      _c("div", { staticClass: "opternative-steps" }, [
        _c("div", { staticClass: "custom-content-block" }, [
          _c("div", {}, [
            _c("div", { staticClass: "opternative-icon step-image-1" })
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "span",
              {
                staticClass:
                  "custom-half-graphic-copy__colored-bg custom-half-graphic-copy__colored-bg--number custom-half-graphic-copy__colored-bg--green"
              },
              [_vm._v("Step 1")]
            ),
            _vm._v(" "),
            _c("h3", [_vm._v("Discover if you qualify")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "opternative-steps" }, [
        _c("div", { staticClass: "custom-content-block" }, [
          _c("div", {}, [
            _c("div", { staticClass: "opternative-icon step-image-2" })
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "span",
              {
                staticClass:
                  "custom-half-graphic-copy__colored-bg custom-half-graphic-copy__colored-bg--number custom-half-graphic-copy__colored-bg--green"
              },
              [_vm._v("Step 2")]
            ),
            _vm._v(" "),
            _c("h3", [
              _vm._v("Undergo the test from your computer or smartphone")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "opternative-steps" }, [
        _c("div", { staticClass: "custom-content-block" }, [
          _c("div", {}, [
            _c("div", { staticClass: "opternative-icon step-image-3" })
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "span",
              {
                staticClass:
                  "custom-half-graphic-copy__colored-bg custom-half-graphic-copy__colored-bg--number custom-half-graphic-copy__colored-bg--green"
              },
              [_vm._v("Step 3")]
            ),
            _vm._v(" "),
            _c("h3", [_vm._v("Await a response from a certified doctor")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "opternative-steps" }, [
        _c("div", { staticClass: "custom-content-block" }, [
          _c("div", {}, [
            _c("div", { staticClass: "opternative-icon step-image-4" })
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "span",
              {
                staticClass:
                  "custom-half-graphic-copy__colored-bg custom-half-graphic-copy__colored-bg--number custom-half-graphic-copy__colored-bg--green"
              },
              [_vm._v("Step 4")]
            ),
            _vm._v(" "),
            _c("h3", [_vm._v("Receive your prescription")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "our-labs__call-to-action" }, [
      _c("div", { staticClass: "custom-callout parallax-trigger" }, [
        _c("div", { staticClass: "struct-parallax-bg" }, [
          _c("div", { staticClass: "custom-callout__content" }, [
            _c("div", { staticClass: "custom-callout__layout" }, [
              _c("h2", { staticStyle: { color: "#2f3948" } }, [
                _vm._v("Ready to start seeing clearly?")
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "/online-eye-exam-qualification" } }, [
                _c("div", { staticClass: "custom-callout__button" }, [
                  _c("div", { staticClass: "button button--copy-solid" }, [
                    _c("div", { staticClass: "button__container" }, [
                      _vm._v("Get Started For FREE Now")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "our-labs__call-to-action" }, [
      _c("div", { staticClass: "custom-callout parallax-trigger" }, [
        _c("div", { staticClass: "struct-parallax-bg" }, [
          _c("div", { staticClass: "custom-callout__content" }, [
            _c("div", { staticClass: "custom-callout__layout" }, [
              _c("p", { staticStyle: { color: "#666666" } }, [
                _vm._v(
                  "Visibly is not a replacement for a comprehensive eye health examination. Our licensed Ophthalmologists and Optometrists use Visibly's online technology to evaluate a patient's visual acuity and a portion of the ocular health profile and issue a prescription for corrective eyewear, where clinically appropriate. Visibly's services are limited to patients between the ages of 18 and 50 who are in good health. Because Visibly's services are not a replacement for an eye health examination, we encourage everyone to obtain a comprehensive eye exam at least once every 2 years. We prohibit patients from taking a Visibly exam more than 4 consecutive years without certifying that they have received a comprehensive eye test first."
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "https://www.lensabl.com/choose-my-lenses" }
                  },
                  [_vm._v(" Get replacement lens")]
                ),
                _vm._v(" or "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.lensabl.com/prescription-sunglass-lenses"
                    }
                  },
                  [_vm._v("replacement sunglass lenses")]
                )
              ]),
              _vm._v(" "),
              _c("p", { staticStyle: { color: "#666666" } }, [
                _vm._v(
                  "Visibly Digital Acuity Product is manufactured and distributed by Visibly, Inc. and operates under the "
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "https://www.fda.gov/media/136733/download" }
                  },
                  [
                    _vm._v(
                      "Enforcement Policy for Remote Ophthalmic Assessment and Monitoring Devices during the CoronaVirus Disease (COVID-19) Public Health Emergency"
                    )
                  ]
                ),
                _vm._v(
                  " issued by the FDA in April 2020. This product has not been approved by the FDA. The data and recommendations made by the Visibly Digital Acuity Product are adjunctive (supporting) recommendations that will be used by an Eye Care Provider, along with your medical history and profile, prior corrective eyewear prescriptions, and subjective data provided about your vision. It is the responsibility of the patient to provide accurate information through the process as all of this information will be relied upon as accurate by the reviewing Eye Care Provider."
                )
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }