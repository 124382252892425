<template>
    <div v-if="statusApi">
        <ContactsSubscriptions />
    </div>
</template>

<script>
import App from '../../../App.vue'
import ContactsSubscriptions from '../../../components/contacts/myaccount/Subscriptions.vue'

export default {
    name: 'RouterContactsSubscriptions',
    components: {
        ContactsSubscriptions
    },
    extends: App
}
</script>
