<template>
    <div class="rxreader-wrapper">
        <section class="section text-center">
            <div class="container">
                <transition appear name="slide-fade" mode="out-in" @after-leave="afterLeave">
                    <!--
                    <PageLoader v-if="!getLoaded" key="loading" :load="!getLoaded">
                        <h1>Sit Tight</h1>
                        <p>We are loading up your Prescription Reader experience!</p>
                    </PageLoader> 
                    -->
                    <BaseLoader v-if=true key="loading" :load="true">
                        <h1>Oops!</h1>
                        <p>This feature is no longer available</p>
                    </BaseLoader>

                    <div v-else key="loaded">
                        <div id="glasseson"></div>
                    </div>
                </transition>
            </div>
        </section>

        <vue-modaltor
            :visible="isModalOpen"
            :default-width="'350px'"
            :bg-overlay="''"
            :bg-panel="''"
            :close-scroll="false"
            class="glasseson-modal small"
            :animation-panel="'modal-slide-right'"
            @hide="hideModal"
        >
            <p v-dompurify-html="modalMessage" class="error-text-rx"></p>
        </vue-modaltor>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import PageLoader from '../base/PageLoader.vue'
import ReaderStore from './storage/rxreader.store'
import FittingToolStore from './storage/fittingtool.store'
import VueModalTor from 'vue-modaltor'
import Vue from 'vue'
Vue.use(VueModalTor)

export default {
    name: 'RxQR',
    components: {
        PageLoader
    },
    data: () => {
        return {
            options: {
                // profileName: 'go_lenses',
                profileName: 'lenses_hub_en',
                fontFamily: 'proxima-nova,Helvetica Neue,Helvetica,Roboto,Arial,sans-serif'
            },
            resultsData: '',
            isModalOpen: false,
            modalMessage: ''
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('readerStore', ['getLoaded', 'getError'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['readerStore/']) {
            this.$store.registerModule('readerStore', ReaderStore(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['fittingToolStore/']) {
            this.$store.registerModule('fittingToolStore', FittingToolStore(this.$store))
        }
        this.$store.dispatch('fittingToolStore/fetchDefaultData')
    },
    mounted () {
        this.loadingScreen()
    },
    methods: {
        ...mapGetters('fittingToolStore', ['getSosClientId', 'getSosService', 'getSosServerUrl', 'getSosOptions']),
        ...mapActions('fittingToolStore', ['postRxReaderData']),
        loadingScreen () {
            setTimeout(function (scope) {
                scope.$store.commit('readerStore/SET_LOADED', true)
            }, 5111, this)
        },
        afterLeave (el) {
            window.glasseson_wc.init(this.getSosClientId(), this.getSosServerUrl(), this.options)
                .then(
                    response => {
                        window.glasseson_wc.setResultCallback(this.resultCallback)
                        window.glasseson_wc.setCloseCallback(this.closeCallback)
                        window.glasseson_wc.open()
                    },
                    error => {
                        this.showModal('We have encountered an issue with the app: <em>' + error + '</em>. <br/>Please contact support@lensabl.com')
                        console.error(error)
                    }
                )
        },
        closeCallback (result) {
            // Premature Close, reset experience
            if (result.isFlowComplete === false && result.is_glasses_oor === false) {
                this.showModal('Restarting Prescription Reader Experience.')
                setTimeout(function (scope) {
                    window.location.reload()
                }, 3500, this)
            }
        },
        resultCallback (result) {
            if (result.isFlowComplete) {
                const payload = {
                    'type': 'rx_reader_full',
                    'sos_user_id': window.glasseson_wc.getUserId() ? window.glasseson_wc.getUserId() : '',
                    'sos_session_id': window.glasseson_wc.getSessionId() ? window.glasseson_wc.getSessionId() : '',
                    'prescription': {
                        os_axis: result.prescription.l_axis,
                        os_cyl: result.prescription.l_cyl,
                        os_sph: result.prescription.l_sph,
                        os_add: '',
                        pd: result.prescription.pd,
                        od_axis: result.prescription.r_axis,
                        od_cyl: result.prescription.r_cyl,
                        od_sph: result.prescription.r_sph,
                        od_add: ''
                    }
                }

                this.postRxReaderData(payload).then(
                    response => {
                        window.glasseson_wc.close()
                        document.location = window.location.origin + '/prescription-scanner-results'
                    },
                    error => {
                        window.glasseson_wc.close()
                        console.error(error)
                        this.showModal('We have encountered an issue with your results: <em>' + error + '</em>. <br/> Please refresh the page and restart or contact support@lensabl.com')
                    }
                )

                return
            }

            console.log('Flow not completed')
        },
        hideModal () {
            this.isModalOpen = false
        },
        showModal (message) {
            this.isModalOpen = true
            this.modalMessage = message
        }
    }
}
</script>