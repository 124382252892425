var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "option",
      class: {
        active: _vm.isActive,
        "before-active": _vm.beforeActive,
        "after-active": _vm.afterActive,
        "no-border": _vm.option.show
      },
      on: {
        click: function($event) {
          return _vm.optionSelect()
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "option-container",
          class: { "has-price": _vm.option.price }
        },
        [
          _vm.option.image
            ? _c("div", { staticClass: "option-thumbnail" }, [
                _c("div", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.option.image,
                      expression: "option.image"
                    }
                  ]
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "option-descriptions" }, [
            _c(
              "div",
              { staticClass: "option-label" },
              [
                _c("span", { staticClass: "label-text" }, [
                  _vm._v(_vm._s(_vm.option.alias))
                ]),
                _vm._v(" "),
                _vm.componentHelpShow && _vm.option.component_help
                  ? _c("HelpBubble", {
                      attrs: { data: _vm.option.component_help }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.option.price
            ? _c("div", { staticClass: "option-price-container" }, [
                _c("strong", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value:
                        1 * _vm.option.price > 0
                          ? "+$" + _vm.option.price
                          : "Free",
                      expression:
                        "(1 * option.price > 0) ? '+$' + option.price : `Free`"
                    }
                  ],
                  staticClass: "option-price"
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "option-checkbox-container",
              class: { active: _vm.isActive }
            },
            [_c("div", { staticClass: "option-checkbox" })]
          )
        ]
      ),
      _vm._v(" "),
      _vm.option.desc
        ? _c("div", { staticClass: "extra-component-container" }, [
            _c("div", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.option.desc,
                  expression: "option.desc"
                }
              ],
              staticClass: "extra-component"
            }),
            _vm._v(" "),
            _vm.option.disclaimer
              ? _c("div", { staticClass: "extra-component-disclaimer" }, [
                  _c("span", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: _vm.option.disclaimer,
                        expression: "option.disclaimer"
                      }
                    ]
                  })
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }