var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "returns-frame-section" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n            *See "),
          _c("a", { attrs: { href: _vm.base_url + "returns" } }, [
            _vm._v("Return Policy")
          ]),
          _vm._v(" for more details\n        ")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n            **See "),
          _c("a", { attrs: { href: _vm.base_url + "returns" } }, [
            _vm._v("Warranty")
          ]),
          _vm._v(" for more details\n        ")
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }