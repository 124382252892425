var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BlockLoader",
    {
      staticClass: "nav__account_links",
      class: { open: _vm.dropdownOpen },
      attrs: { loading: _vm.loading }
    },
    [
      _c("div", { staticClass: "nav__account_links-box" }, [
        !_vm.loggedIn
          ? _c(
              "a",
              { staticClass: "nav__sign-in", attrs: { href: _vm.signInUrl } },
              [_vm._v("Sign in")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loggedIn
          ? _c(
              "div",
              {
                staticClass: "nav__sign-in",
                on: {
                  click: function($event) {
                    _vm.dropdownOpen = !_vm.dropdownOpen
                  }
                }
              },
              [
                _vm.plusMember
                  ? _c("img", {
                      attrs: {
                        src: _vm.assets_url + "lplus-icon.svg",
                        width: "40"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.plusMember
                  ? _c("span", [
                      _c("span", {
                        staticClass: "navbar-lplus-name",
                        domProps: { textContent: _vm._s(_vm.customerFullName) }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "navbar-lplus" }, [
                        _vm._v("Lensabl+ Member")
                      ])
                    ])
                  : _c("span", [_vm._v("My Account")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "nav__icon-dropdown",
                    class: {
                      "lplus-dropdown": _vm.plusMember,
                      open: _vm.dropdownOpen
                    }
                  },
                  [
                    _c("svg", [
                      _c("use", {
                        attrs: { "xlink:href": "#lensabl-arrow-small-down" }
                      })
                    ])
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loggedIn
          ? _c(
              "div",
              {
                staticClass: "nav__sub-nav-container nav__right-dropdown",
                class: {
                  "lplus-dropdown_container": _vm.plusMember,
                  open: _vm.dropdownOpen
                }
              },
              [
                _c(
                  "ul",
                  [
                    _vm._l(_vm.dropdownLinks, function(link, index) {
                      return _c("li", { key: index }, [
                        _c("a", {
                          attrs: { href: link.path },
                          domProps: { textContent: _vm._s(link.name) }
                        })
                      ])
                    }),
                    _vm._v(" "),
                    _vm.businessDependents.length
                      ? _c("li", { staticClass: "account-dropdown_wrapper" }, [
                          _c("span", { staticClass: "account-label" }, [
                            _vm._v("SWITCH ACCOUNT:")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "account-dropdown_container" },
                            [
                              _c("div", { staticClass: "account-dropdown" }, [
                                _c("span", {
                                  staticClass: "account-name",
                                  domProps: {
                                    textContent: _vm._s(_vm.customerFullName)
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "account-arrow-down",
                                  attrs: {
                                    src: _vm.assets_url + "arrow-down.svg"
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "results-dropdown" },
                                _vm._l(_vm.businessDependents, function(
                                  dependent,
                                  i
                                ) {
                                  return _c(
                                    "a",
                                    {
                                      key: dependent.name + "-" + i,
                                      attrs: {
                                        href:
                                          _vm.customerFullName ===
                                            dependent.name &&
                                          _vm.businessEmployee
                                            ? _vm.businessEmployee.url
                                            : dependent.url
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "account-holder" },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.customerFullName ===
                                                  dependent.name &&
                                                  _vm.businessEmployee
                                                  ? _vm.businessEmployee.name
                                                  : dependent.name
                                              ) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }