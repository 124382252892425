<template>
    <v-form ref="form" v-model="formValid" lazy-validation @submit.prevent="sendForm" class="anagram-form-container anagram-form2-container">
        <v-layout>
            <v-flex>
                <SelectField :form-model="formModel" :options="companies"/>
            </v-flex>
            <v-flex>
                <BrthDatePicker
                    :form-model="formModel"
                    :label="'Your Birthday (MM/DD/YYYY)'"
                    :form-key="'memberBrthDay'"
                    :err-text="'Your Birthday Required'"
                />
            </v-flex>
        </v-layout>
        <v-layout class="anagram-form-middle" v-if="needsAdditionalInfo">
            <v-flex>
                <SelectField
                    :form-model="formModel"
                    :options="availableSex"
                    :form-key="'sex'"
                    :label="'Your sex'"
                    :err-text="'Your Sex Required'"
                />
            </v-flex>
        </v-layout>
        <span class="anagram-form-divider"></span>
        <v-layout>
            <v-flex>
                <TextField :form-model="formModel.primary"
                           :form-key="'firstName'"
                           :label="'Primary Member’s First Name'"
                           :err-text="'Primary Member’s First Name Required'"/>
            </v-flex>
            <v-flex>
                <TextField :form-model="formModel.primary"
                           :form-key="'lastName'"
                           :label="'Primary Member’s Last Name'"
                           :err-text="'Primary Member’s Last Name Required'"/>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex>
                <TextField :form-model="formModel.primary"
                           :form-key="'memberId'"
                           :label="'Primary Member’s ID or last four of SSN'"
                           :err-text="'Primary Member ID Required'"/>
            </v-flex>
            <v-flex>
                <BrthDatePicker
                    :form-model="formModel.primary"
                    :label="primaryBthDayLabel"
                    :form-key="'memberBrthDay'"
                    :err-text="'Primary Member’s Birthday Required'"
                />
            </v-flex>
        </v-layout>
        <v-layout class="anagram-form-middle" v-if="needsAdditionalInfo">
            <v-flex>
                <SelectField
                    :form-model="formModel.primary"
                    :options="availableSex"
                    :form-key="'sex'"
                    :label="'Primary Member’s sex'"
                    :err-text="'Primary Member’s Sex Required'"
                />
            </v-flex>
            <v-flex>
                <TextField :form-model="formModel.primary"
                           :form-key="'individual_plan_name'"
                           :label="'Primary Member’s plan name'"
                           :err-text="'Primary Member’s plan name Required'"/>
            </v-flex>
        </v-layout>
        <v-layout class="anagram-form-bottom">
            <div>
                <v-btn
                    class="button button--copy-solid"
                    type="submit"
                    :disabled="!formValid || loading"
                >Continue</v-btn>
            </div>
        </v-layout>
    </v-form>
</template>
<script>
import SelectField from '../../../forms/anagram/fields/SelectField.vue'
import TextField from '../../../forms/anagram/fields/TextField.vue'
import BrthDatePicker from '../../../forms/anagram/fields/BrthDatePicker.vue'
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'HasNotInsuranceForm',
    components: {
        SelectField,
        TextField,
        BrthDatePicker
    },
    props: {
        formModel: {
            type: Object,
            default: () => {
            }
        }
    },
    data: () => {
        return {
            formValid: false,
            loading: false
        }
    },
    created() {
        if (this.$refs.form) {
            this.$refs.form.resetValidation()
        }
        this.$store.dispatch('storeView/fetchCountryRegionsWithCode')
    },
    computed: {
        ...mapGetters('storeView', ['regionsWithCode']),
        ...mapState('insuranceAdminAnagram', ['companies', 'claimAdditions', 'availableSex']),
        needsAdditionalInfo(){
            return this.claimAdditions.indexOf(this.formModel.company) !== -1
        },
        primaryBthDayLabel(){
            return 'Primary Member\'s Birthday (MM/YY/DD)'
        }
    },
    methods: {
        ...mapActions('insuranceAdminAnagram', ['sendToAnagramAdmin']),
        sendForm:  function () {
            if (this.$refs.form.validate()) {
                this.loading = true
                this.$refs.form.resetValidation()
                 this.sendToAnagramAdmin(this.formModel)
                this.loading = false
            }
        }
    }
}
</script>

