<template>
   <div :class="[brand + '-seo-parent-container']">
        <div class="shopframes-seo-prescription-container">
            <div class="shopframes-seo-row seo-prescription">
                <div class="shopframes-seo-text">
                    <h2>Kate Spade Glasses</h2>
                    <p>Your prescription glasses should fill you with confidence, which is why you should order a set of fashionable Kate Spade eyeglasses from Lensabl. We have the largest selection of popular Kate Spade styles, with lenses that meet your prescription needs. Browse Lensabl's Kate Spade eyeglass inventory today.</p>
                </div>
                <div class="shopframes-seo-img">
                </div>
            </div>
        </div>

        <div class="shopframes-seo-frames-container">
            <div class="shopframes-seo-row seo-frames">
                <div class="shopframes-seo-frames-img">
                </div>
                <div class="shopframes-seo-text">
                    <h2>Premium Kate Spade Eyeglass Frames</h2>
                    <p>Kate Spade leads the industry in women's eyewear with fashionable, durable frames. Our Kate Spade frames are available in materials like acetate, titanium, steel, plastic and more. We carry a wide selection of large, medium and small sizes — use our size finder tool to narrow your selection by the best fit.<br /><br />Have a style in mind? Order Kate Spade ladies' glasses through Lensabl for popular choices like Payton, Bendall, Rainey and more. We have Kate Spade frames to complement all your fashion preferences. We carry various color options, too, meaning you can find black, navy, tortoise or pink Kate Spade glasses online and have them delivered in day</p>
                </div>
            </div>
        </div>

        <div class="shopframes-seo-designer-container">
            <div class="shopframes-seo-row seo-designer">
                <div class="shopframes-seo-text">
                    <h2>Kate Spade Glasses That Fill Your Prescription</h2>
                    <p>Pair your Kate Spade frames with a lens that meets your precise optical needs. Lensabl has single vision, progressive and bifocal lenses for Kate Spade frames. You'll look and feel your best wearing corrective eyeglasses that complement your style.<br /><br />Buying Kate Spade frames for your prescription is easy thanks to Lensabl's convenient online services. <a href="/online-vision-prescription"><span class="seo-contact-link">Renew your prescription</span></a> through our website with a virtual appointment or <a href="/prescription-scanner-app/qr"><span class="seo-contact-link">scan your current glasses</span></a> for the most accurate online shopping experience.</p>
                </div>
            <div class="shopframes-seo-designer-img">
            </div>
            </div>
        </div>

        <div class="shopframes-seo-eyeglass-container">
            <div class="shopframes-seo-row seo-eyeglass">
                <div class="shopframes-seo-text seo-eyeglass-text">
                    <h2>Order Kate Spade Glasses Online</h2>
                    <p>Ready for glasses that give amazing style and vision? Kate Spade eyeglasses are a favorite among women's prescription eyewear, and you can order them online through Lensabl. Buy Kate Spade prescription glasses from Lensabl today and benefit from free standard shipping, risk-free returns, a 12-month warranty and more.</p>
                </div>
                <a href="/glasses-frames" title="Glasses Frames">
                    <div class="button button--copy-solid">
                        <div class="button__container">GET NEW FRAMES</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'KateSpadeSeo',
    props: {
        brand: {
            type: [String],
            default: ''
        }
    },
}
</script>
