<template>
    <CustomerPrescriptionNew v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CustomerPrescriptionNew from '../../../components/customer/prescription/New.vue'

export default {
    name: 'RouterCustomerPrescriptionNew',
    components: {
        CustomerPrescriptionNew
    },
    extends: App
}
</script>
