import axios from 'axios'

const getDefaultState = () => {
    return {
        redirect_link: null
    }
}

const state = getDefaultState()
const mutations = {
    SET_REDIRECT_LINK: (state, redirect_link = null) => state.redirect_link = redirect_link
}
const getters = {
    orderCreateUrl: (state, getters, rootState, rootGetters) => rootGetters.rootState.order_create_url,
    redirectLink: state => state.redirect_link
}
const actions = {
    createOrder: ({commit, getters}) => {
        return axios.post(getters.orderCreateUrl)
            .then(({data, status}) => {
                if (data.redirect_link) {
                    commit('SET_REDIRECT_LINK', data.redirect_link)
                }
            })
            .then(() => {
                location.href = getters.redirectLink
            })
            .catch(error => {
                console.error(error)
            })
    }
}

export default store => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
