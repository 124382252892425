var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ]
    },
    [
      _vm.config.title
        ? _c("h2", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.title,
                expression: "config.title"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          staticClass: "contacts-switcher",
          class: { "two-col": !_vm.sameContactsInBothEyes }
        },
        [
          _c(
            "v-col",
            [
              _c("SearchContacts", {
                attrs: { label: _vm.searchContactsRightLabel },
                on: _vm._d({}, [
                  _vm.event_contact_lenses_found,
                  _vm.setServiceMessageShown,
                  _vm.event_add_contacts_to_eye,
                  _vm.addContactsRight
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.sameContactsInBothEyes
            ? _c(
                "v-col",
                [
                  _c("SearchContacts", {
                    attrs: { label: _vm.searchContactsLeftLabel },
                    on: _vm._d({}, [
                      _vm.event_contact_lenses_found,
                      _vm.setServiceMessageShown,
                      _vm.event_add_contacts_to_eye,
                      _vm.addContactsLeft
                    ])
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.serviceMessageShow
        ? _c("div", { staticClass: "customer-service-container" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "actions-toolbar" }, [
              _c(
                "div",
                { staticClass: "primary" },
                [
                  _c("BaseButton", {
                    staticClass: "button--copy-solid-white-blue button--small",
                    attrs: { text: "Continue" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.goNext($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.errorMessageShow
                ? _c("p", {
                    staticClass: "error-text-rx",
                    domProps: { textContent: _vm._s(_vm.config.errorMessage) }
                  })
                : _vm._e()
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("Don't see your brand of contacts?")]),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "customer-service", attrs: { href: "/contact" } },
        [_vm._v("Contact customer service")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }