import {
    LENS_OPTION_CODE_POLY,
    LENS_OPTION_CODE_167,
} from '../lens_options'

export const LIMITERS_DEFAULT_PARTNER = {
    lens_options_enable: {
        single_vision_distance: [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167],
        single_vision_distance_premium: [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167],
        single_vision_reading: [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167],
        single_vision_reading_premium: [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167],
        progressive_standard: [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167],
        progressive_premium: [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167],
        bi_focal: [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167],
    },
    lens_options_enable_by_lens_type: {
        progressive_standard: {
            'Sun Polarized': [LENS_OPTION_CODE_POLY,],
            'Transition Polarized': [LENS_OPTION_CODE_POLY,]
        },
    },
    enabled_colors: {
        progressive_standard: {
            'Sun Polarized': ['polarized-brown', 'polarized-grey',]
        },
    },
    lens_options_enable_by_color: {
        single_vision_distance: {
            'Sun Polarized Gradient': {
                [LENS_OPTION_CODE_167]: ['Grey Gradient C', 'Brown Gradient C'],
            }
        },
        single_vision_distance_premium: {
            'Sun Polarized Gradient': {
                [LENS_OPTION_CODE_167]: ['Grey Gradient C', 'Brown Gradient C'],
            }
        },
        single_vision_reading: {
            'Sun Polarized Gradient': {
                [LENS_OPTION_CODE_167]: ['Grey Gradient C', 'Brown Gradient C'],
            }
        },
        single_vision_reading_premium: {
            'Sun Polarized Gradient': {
                [LENS_OPTION_CODE_167]: ['Grey Gradient C', 'Brown Gradient C'],
            }
        },
        progressive_standard: {
            'Sun Polarized Gradient': {
                [LENS_OPTION_CODE_167]: ['Grey Gradient C', 'Brown Gradient C'],
            }
        },
        progressive_premium: {
            'Sun Polarized Gradient': {
                [LENS_OPTION_CODE_167]: ['Grey Gradient C', 'Brown Gradient C'],
            }
        },
        bi_focal: {
            'Sun Polarized Gradient': {
                [LENS_OPTION_CODE_167]: ['Grey Gradient C', 'Brown Gradient C'],
            }
        },
    },
    anti_reflective_enable: {
        'single_vision_distance_premium': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR'],
            'Transition DriveWear': ['Standard AR', 'Empire Green Plus AR'],
        },
        'single_vision_distance': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR'],
            'Transition DriveWear': ['Standard AR', 'Empire Green Plus AR'],
        },
        'single_vision_reading_premium': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR'],
            'Transition DriveWear': ['Standard AR', 'Empire Green Plus AR'],
        },
        'single_vision_reading': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR'],
            'Transition DriveWear': ['Standard AR', 'Empire Green Plus AR'],
        },
        'progressive_standard': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR'],
            'Transition DriveWear': ['Standard AR', 'Empire Green Plus AR'],
        },
        'progressive_premium': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR'],
            'Transition DriveWear': ['Standard AR', 'Empire Green Plus AR'],
        },
    }
}
