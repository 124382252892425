<template>
    <div sticky-container>
        <div class="summary_block">
            <div class="summary_block-header">
                <span>Summary</span>
            </div>

            <div v-if="showCustomSummary" v-sticky="shouldStick" :sticky-offset="stickyOffset" class="summary_block-content">
                <SummaryTotalsInfo :place="place" :custom-summary="showCustomSummary" class="totals-info" />
                <SummaryTotalsDiscount v-if="hasDiscount" />
                <SummaryTotalsPrice v-if="!showFramesPrice && !isLensablPlus" class="totals-price" />
                <SummaryRedeem v-if="isLensablPlus" class="totals-price" :class-name="'plus-benefit-discount'" />
                <SummaryTotalsPrice v-if="showFramesPrice || isLensablPlus" class="totals-price" />
                <SummaryAffirm v-if="!isRedeemFlow" class="affirm-choose-my-lenses" />
                <SummaryNotice v-if="false" class="summary_notice" />
                <SummaryTermsInfo v-if="!isLensReplacementPage" />
                <SummaryLensesInclude :class="{'lensreplacement-summary-lensesinclude-container':isLensReplacementPage}" :is-lens-replacement-page="isLensReplacementPage" />
            </div>

            <div v-else v-sticky="shouldStick" :sticky-offset="stickyOffset" class="summary_block-content">
                <SummaryTotalsInfo :place="place" class="totals-info" />
                <SummaryTotalsDiscount v-if="hasDiscount" />
                <SummaryTotalsPrice v-if="!showFramesPrice && !isLensablPlus" class="totals-price" />
                <SummaryRedeem v-if="isLensablPlus" class="totals-price" :class-name="'plus-benefit-discount'" />
                <SummaryTotalsPrice v-if="showFramesPrice || isLensablPlus" class="totals-price" />
                <SummaryAffirm v-if="!isRedeemFlow" class="affirm-choose-my-lenses" />
                <SummaryShipping class="standard-shipping-container" />
                <SummaryNotice v-if="false" class="summary_notice" />
                <SummaryDisclaimer :category="framesPartnerPage" />
            </div>
        </div>
    </div>
</template>

<script>
import {
    PLACE_SUMMARY
} from '../../app/utils/constants'

import {mapGetters} from 'vuex'
import summary from './storage/summary'

import SummaryTotalsInfo from './TotalsInfo.vue'
import SummaryTotalsDiscount from './TotalsDiscount.vue'
import SummaryTotalsPrice from './TotalsPrice.vue'
import SummaryAffirm from './Affirm.vue'
import SummaryShipping from './Shipping.vue'
import SummaryNotice from './Notice.vue'
import SummaryDisclaimer from './Disclaimer.vue'
import SummaryRedeem from './RedeemTotals.vue'
import SummaryTermsInfo from './TermsInfo.vue'
import SummaryLensesInclude from './LensesInclude.vue'

import Sticky from 'vue-sticky-directive'

export default {
    name: 'Summary',
    directives: {Sticky},
    components: {
        SummaryTotalsInfo,
        SummaryTotalsDiscount,
        SummaryTotalsPrice,
        SummaryAffirm,
        SummaryShipping,
        SummaryNotice,
        SummaryDisclaimer,
        SummaryRedeem,
        SummaryTermsInfo,
        SummaryLensesInclude
    },
    props: {
        stickyAvailable: {
            type: Boolean,
            default: true
        },
        customSummary: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        place: PLACE_SUMMARY
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isDesktop']),
        ...mapGetters('summary', ['showFramesPrice', 'hasDiscount']),
        ...mapGetters('lensablplus_customer', ['isLensablPlus', 'isRedeemFlow']),
        ...mapGetters('values', ['values', 'framesPartnerPage', 'isLensReplacementPage']),
        shouldStick () {
            return this.stickyAvailable && this.isDesktop
        },
        stickyOffset () {
            let top = 0
            const header = document.querySelector('.nav__box-shadow')
            if (header) {
                top = header.offsetHeight
            }
            return '{top: ' + top + ', bottom: 0}'
        },
        showCustomSummary () {
            if (this.customSummary || (!this.framesPartnerPage && this.values.prescription_type !== 'contacts')) {
                return true
            } else {
                return false
            }
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['summary/']) {
            this.$store.registerModule('summary', summary(this.$store))
        }
        this.$store.dispatch('summary/fetchDefaultData')
    }
}
</script>
