var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasDiscount
    ? _c("div", { staticClass: "discount-container" }, [
        _c("div", { staticClass: "totals-price-discount total-line" }, [
          _c("span", { staticClass: "total-label" }, [_vm._v("Discount")]),
          _vm._v(" "),
          _c("span", { staticClass: "total-value" }, [
            _c("span", { staticClass: "total-value-currency currency" }, [
              _vm._v("$a")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "total-value-amount amount" }, [
              _vm._v(_vm._s(_vm.discountAmount))
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }