<template>
    <div>
        <RadioLabel v-if="!prescriptionData.status_4" :active="isActive" :label="title" :value="index" @click="$emit('checked', index)" />

        <div v-show="isActive" class="my-prescription-form__pupillary-distance-sh">
            <div class="my-prescription-form__dropdown-pupillary">
                <div class="two-pupillary-numbers">
                    <div class="sh-item">
                        <CustomSelect :value="value.od_sh" :options="eyeSHOptions" class="pd-input" @change="value.od_sh = $event.target.value" />
                        <span class="two-PD-numbers">OD (right eye)</span>
                    </div>
                    <div class="sh-item">
                        <CustomSelect :value="value.os_sh" :options="eyeSHOptions" class="pd-input" @change="value.os_sh = $event.target.value" />
                        <span class="two-PD-numbers">OS (left eye)</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _merge from 'lodash/merge'
import {mapGetters} from 'vuex'
import generateOptions from '../../../../../../app/utils/generate-options'
import SalesOrderViewPrescriptionRenderAbstract from './abstract'

export default {
    name: 'SalesOrderViewPrescriptionRenderEnter',
    mixins: [SalesOrderViewPrescriptionRenderAbstract],
    props: {
        title: {
            type: [Boolean, String],
            default: 'Enter Segmentation Height'
        }
    },
    data: () => ({
        value: {
            od_sh: '',
            os_sh: ''
        },
        eyeSHOptions: generateOptions(10, 50, 0.5)
    }),
    computed: {
        ...mapGetters('values', ['values']),
        prescription () {
            return this.values.prescriptionValues[this.prescriptionData.rx_type]
        }
    },
    watch: {
        value: {
            handler (data) {
                const index = this.indexSave
                this.setValueOptions({index, data})
            },
            deep: true
        }
    },
    created () {
        if (this.prescriptionData) {
            this.$set(this.item.prescriptions, this.prescriptionsType, _merge({
                type: this.prescriptionsType,
                od_sh: '',
                os_sh: '',
                status_4: false
            }, this.prescriptionData))
        }
    }
}
</script>
