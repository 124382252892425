<template>
    <div>
        <RadioLabel v-if="!prescriptionData.status_4" :active="isActive" :label="title" :value="index" @click="$emit('checked', index)" />
        <div v-if="isActive" class="my-prescription-form__pupillary-distance enter-online-rx">
            <div class="my-prescription-form__dropdown-options-container prescription-toggle">
                <p class="my-prescription-form__subheading prescription-toggle">Prescription:</p>

                <PrescriptionTable :show-edit-version="showEditVersion" :show-add="showAdd" :value="prescription" :show-error="showError" :help="help" />
            </div>
        </div>
    </div>
</template>
<script>
import _merge from 'lodash/merge'
import {mapState, mapGetters} from 'vuex'
import getHelper from '../../../../../configurator/storage/constants/helper'

import CommonPrescriptionModel from '../../../../../../app/common/prescription_model'
import SalesOrderViewPrescriptionRenderAbstract from './abstract'
import PrescriptionTable from '../../../../../../components/prescription/PrescriptionTable.vue'

export default {
    name: 'SalesOrderViewPrescriptionRenderEnterRx',
    components: {
        PrescriptionTable
    },
    extends: CommonPrescriptionModel,
    mixins: [SalesOrderViewPrescriptionRenderAbstract],
    props: {
        title: {
            type: [Boolean, String],
            default: 'Enter it Online'
        }
    },
    computed: {
        ...mapState('prescriptions', ['optionsAreValid']),
        ...mapGetters('values', ['values']),
        help () {
            const {help_components} = getHelper(this.$store.getters)
            return help_components
        },
        prescription () {
            return this.values.prescriptionValues[this.prescriptionData.rx_type]
        },
        showError () {
            return this.isActive ? this.validatePrescriptionData(this.prescription, this.prescriptionData.rx_type) : false
        },
        showAdd () {
            return this.showAddOptions(this.prescriptionData.rx_type)
        },
        showEditVersion () {
            return this.isPrescriptionEditing(this.prescriptionData, this.prescriptionData.status_4)
        }
    },
    watch: {
        prescription (data) {
            const index = this.indexSave
            this.setValueOptions({index, data})
        }
    },
    created () {
        if (this.prescriptionData) {
            this.$set(this.item.prescriptions, this.prescriptionsType, _merge({
                type: this.prescriptionsType,
                rx_type: 'single_vision_distance',
                index: 0,
                prescription: {},
                status_4: false
            }, this.prescriptionData))
        }
        this.setDataToValues({
            prescriptionValues: {
                [this.prescriptionData.rx_type]: _merge(this.prescription, this.prescriptionData.prescription)
            }
        })
    }
}
</script>
