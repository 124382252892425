import {
    COMPONENT_ID_DASHBOARD_PLANS,
    COMPONENT_ID_DEPENDENTS,
    COMPONENT_ID_PLUS_DASHBOARD,
    COMPONENT_ID_DASHBOARD_PAYMENT
} from './dashboard'

/**
 * Components Chain
 */
export const OBJECTS_CHAIN = [
    {
        'id': COMPONENT_ID_DASHBOARD_PLANS,
        'stepId': '1',
        'next': {
            COMPONENT_ID_DEPENDENTS
        }
    },
    {
        'id': COMPONENT_ID_DEPENDENTS,
        'stepId': '2',
        'next': {
            COMPONENT_ID_DASHBOARD_PAYMENT
        }
    },
    {
        'id': COMPONENT_ID_DASHBOARD_PAYMENT,
        'stepId': '3',
        'next': {
            COMPONENT_ID_PLUS_DASHBOARD
        }
    },
    {
        'id': COMPONENT_ID_PLUS_DASHBOARD,
        'stepId': '4',
        'next': {

        }
    }
]
