<template>
    <div v-if="getHtml" :class="getContainerClasses">
        <div class="help-component">
            <i
                v-if="!isMobileView"
                v-tooltip="{
                    content: getHtml,
                    placement: getPlacement,
                    classes: getClasses,
                    targetClasses: getTargetClasses,
                    offset: offset,
                    delay: getDelay,
                    popperOptions: {
                        preventOverflow: false
                    }
                }"
            >{{ getTargetContent }}</i>

            <template v-else>
                <vue-modaltor
                    :visible="isModalOpen"
                    :default-width="'100%'"
                    :bg-overlay="''"
                    :bg-panel="''"
                    :close-scroll="false"
                    @hide="hideModal"
                >
                    <div v-dompurify-html="getHtml"></div>
                </vue-modaltor>
                <span :class="getTargetClasses" @click="isModalOpen=true">{{ getTargetContent }}</span>
            </template>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VTooltip from 'v-tooltip'
import {mapGetters} from 'vuex'

Vue.use(VTooltip)

export default {
    name: 'HelpBubble',
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        targetContent: {
            type: String,
            default: '?'
        },
        classes: {
            type: String,
            default: ''
        },
        targetClasses: {
            type: String,
            default: ''
        },
        containerClasses: {
            type: String,
            default: null
        },
        placement: {
            type: String,
            default: ''
        },
        offset: {
            type: String,
            default: '30'
        },
        delay: {
            type: Object,
            default: () => {
            }
        }
    },
    data: () => {
        return {
            isModalOpen: false
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobile', 'isDesktop']),
        getPlacement () {
            return this.placement || (this.isDesktop ? 'right-center' : 'top')
        },
        defaultBoundariesElement () {
            return document.body
        },
        getHtml () {
            return (this.data && this.data.params) ? this.data.params.text : ''
        },
        getClasses () {
            return this.classes ? this.classes : ['help-bubble']
        },
        getTargetClasses () {
            return this.targetClasses ? this.targetClasses : 'help-bubble-icon'
        },
        getDelay () {
            return this.delay || {
                show: 0,
                hide: 0
            }
        },
        getTargetContent () {
            return (this.data && this.data.params.targetContent) ? this.data.params.targetContent : this.targetContent
        },
        getContainerClasses () {
            if (this.containerClasses) {
                return this.containerClasses
            }
            return (this.data.type === 'text') ? 'help-component-container-text' : 'help-component-container'
        },
        isMobileView () {
            return this.getWindowWidth() <= 768
        }
    },
    watch: {
        isModalOpen (val) {
            if (val) {
                document.body.classList.add('_has-modal')
            } else {
                document.body.classList.remove('_has-modal')
            }
        }
    },
    methods: {
        hideModal () {
            this.isModalOpen = false
        },
        getWindowWidth () {
            const winW = window.innerWidth
            const docW = document.compatMode === 'CSS1Compat' ? document.documentElement.clientWidth : document.body.clientWidth
            return winW > docW ? winW : docW
        }
    }
}
</script>
