import getHelper from '../helper'
import {
    LENS_TYPE_ID,
    LENS_TYPE_ID_CLEAR_LENSES
} from '../../../../../app/common/constants/lens_type'
import getLensOptionHelper, {
    LENS_OPTION_CODE_167,
    LENS_OPTION_CODE_POLY,
    LENS_OPTIONS_CODE
} from '../lens_options'
import {
    PRESCRIPTION_TYPE_LATER,
    PRESCRIPTION_TYPE_ONLINE,
    PRESCRIPTION_TYPE_UPLOAD
} from '../../../../../app/utils/constants'

export default function getSpectaclesGenStepList (param) {
    const {product_config, assets_url, antireflective_price, upgrades_section, warranty_section} = param
    const {help_components, DEFAULT_LAST_STEP} = getHelper(param)

    function assetUrl (filename) {
        return assets_url + filename
    }

    const SPECTACLES_GEN_LAST_STEP = {
        ...DEFAULT_LAST_STEP,
        'default_value': PRESCRIPTION_TYPE_LATER,
        'component': {
            'name': 'selector-options',
            'options': [
                {
                    'id': PRESCRIPTION_TYPE_LATER,
                    'label': 'Provide It Later',
                    'summary_label': 'Provide It Later',
                    'description': '',
                    'component_extra_options': {
                        'name': 'prescription-email-it-later'
                    }
                },
                {
                    'id': PRESCRIPTION_TYPE_UPLOAD,
                    'label': 'Upload a Photo',
                    'summary_label': 'Upload a Photo',
                    'description': '',
                    'continue_promise': 'validateUpload',
                    'component_extra_options': {
                        'name': 'prescription-upload',
                        'label_extra': '',
                        'summary_label_extra': ''
                    }
                },
                {
                    'id': PRESCRIPTION_TYPE_ONLINE,
                    'label': 'Enter it Online',
                    'summary_label': 'Enter it Online',
                    'description': '',
                    // 'continue_promise': 'validatePrescriptionParams',
                    'component_extra_options': {
                        'name': 'selector-prescription',
                        'params': {
                            'options': {
                                'params_component': 'params-prescription',
                                'help_components': help_components
                            }
                        }
                    }
                }
            ]
        }
    }

    const frames_type = {
        'id': 'frames_type',
        'label': 'Choose Lenses',
        'summary_label': 'Choose Lenses',
        'no_summary': true,
        'value_renderer': 'renderer-options',
        'default_value': 'frames_customize',
        'component': {
            'name': 'selector-options',
            'options': [
                {
                    'id': 'frames_customize',
                    'label': 'Add Prescription or Customize Lenses',
                    'summary_label': 'Customize lenses',
                    'description': 'Available in Single Vision, Progressive, Bifocal, or Non-Prescription',
                    'thumbnail': '',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Available in Single Vision, Progressive, Bifocal, or Non-Prescription</p>'
                        }
                    }
                }
            ]
        }
    }

    const lens_type = {
        'id': LENS_TYPE_ID,
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'default_value': LENS_TYPE_ID_CLEAR_LENSES,
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                {
                    'id': LENS_TYPE_ID_CLEAR_LENSES,
                    'label': 'Clear Lenses*',
                    'summary_label': 'Clear Lenses',
                    'description': 'Your everyday clear lenses.',
                    'checkout_value': 'Clear',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Your everyday clear lenses.</p>'
                        }
                    },
                    'thumbnail': assetUrl('product-configuration-lenstype-standardclear.jpg')
                }
            ]
        }
    }

    const lens_options = {
        'id': LENS_OPTIONS_CODE,
        'label': 'Select Lens Options',
        'summary_label': 'Lens Option',
        'value_renderer': 'renderer-options',
        'hide_for_product': [],
        'always_visible': true,
        'component': {
            'name': 'selector-options-lens-option',
            'params': {
                'product_config': product_config
            },
            'options': [
                {
                    'id': LENS_OPTION_CODE_POLY,
                    'label': 'Polycarbonate Lens',
                    'summary_label': 'Polycarbonate',
                    'checkout_value': 'Poly',
                    'description': getLensOptionHelper(LENS_OPTION_CODE_POLY, 'title'),
                    'popular': true,
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.</p>'
                        }
                    },
                    'small_description': true
                },
                {
                    'id': LENS_OPTION_CODE_167,
                    'label': '1.67 High-Index Lens',
                    'summary_label': '1.67 High-Index Lens',
                    'checkout_value': '167',
                    'description': getLensOptionHelper(LENS_OPTION_CODE_167, 'title'),
                    'popular': false,
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>For those looking for thinner, lighter lenses. Recommended for strong prescriptions (a total power of +/- 4 or greater)</p>'
                        }
                    },
                    'small_description': true,
                    'price' : false
                }
            ]
        }
    }

    const single_vision_distance = Object.assign({'id': 'single_vision_distance'}, {...SPECTACLES_GEN_LAST_STEP})
    const single_vision_near = Object.assign({'id': 'single_vision_reading'}, {...SPECTACLES_GEN_LAST_STEP})
    const progressive_standard = Object.assign({'id': 'progressive_standard'}, {...SPECTACLES_GEN_LAST_STEP})
    const progressive_premium = Object.assign({'id': 'progressive_premium'}, {...SPECTACLES_GEN_LAST_STEP})

    return [
        {
            'id': 'notes',
            'label': 'Frame Information',
            'no_summary': true,
            'summary_label': 'Comments',
            'value_renderer': 'renderer-notes',
            'continue_label': 'Continue',
            'default_value': null,
            'component': {
                'name': 'selector-notes'
            }
        },
        {
            'id': 'step_warranty',
            'label': 'Warranty',
            'summary_label': 'Warranty',
            'value_renderer': 'renderer-warranty',
            'continue_label': 'Continue',
            'default_value': null,
            'component': {
                'name': 'selector-warranty',
                'params': {
                    'assets_url': assets_url,
                    'warranty_section': warranty_section
                }
            }
        },
        {
            'id': 'step_antireflective',
            'label': 'Select Anti-Reflective Coating',
            'partner_label': 'Anti-Reflective Coating',
            'summary_label': 'Select Upgrades',
            'summary_partner_label': 'Upgrades',
            'value_renderer': 'renderer-antireflective',
            'continue_label': 'Continue',
            'always_visible': false,
            'component': {
                'name': 'selector-antireflective',
                'params': {
                    'antireflective_price': antireflective_price,
                    'upgrades_section': upgrades_section,
                    'warranty_section': warranty_section,
                    'assets_url': assets_url
                }
            }
        },
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    {
                        'id': 'prescription',
                        'label': 'Prescription Lenses',
                        'summary_label': 'Prescription',
                        'description': 'Available in Single Vision or Progressive',
                        'thumbnail': assetUrl('product-configuration-chooselenses-prescription.jpg')
                    },
                    {
                        'id': 'nonprescription',
                        'label': 'Non-Prescription',
                        'summary_label': 'Non-Prescription',
                        'description': 'Available in clear, sunglass, Transitions<sup>®</sup> and blue light blocking lenses. All lenses are polycarbonate.',
                        'thumbnail': assetUrl('product-configuration-chooselenses-sunglasses.jpg')
                    }
                ]
            }
        },
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    {
                        'id': 'single_vision_distance',
                        'label': 'For Distance',
                        'summary_label': 'Single Vision for Distance',
                        'description': 'Intended for distance correction. ',
                        'block_prescription_id': 'single_vision',
                        'single_vision_subtext': false,
                        'manufacturer': false,
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Intended for distance correction. </p>'
                            }
                        }
                    },
                    {
                        'id': 'single_vision_reading',
                        'label': 'For Reading',
                        'summary_label': 'Single Vision for Reading',
                        'description': 'Intended for close-up vision correction. ',
                        'block_prescription_id': 'single_vision',
                        'single_vision_subtext': false,
                        'manufacturer': false,
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Intended for close-up vision correction. </p>'
                            }
                        }
                    },
                    {
                        'id': 'progressive_standard',
                        'label': 'Standard Progressive',
                        'summary_label': 'Standard Progressive',
                        'block_prescription_id': 'progressive',
                        'manufacturer': '(Made by Essilor)',
                        'description': 'Our in-house free-form Digital HD lenses',
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Our in-house free-form Digital HD lenses made by Essilor.</p>'
                            }
                        }
                    },
                    {
                        'id': 'progressive_premium',
                        'label': 'Premium Progressive',
                        'summary_label': 'Premium Progressive',
                        'block_prescription_id': 'progressive',
                        'manufacturer': '(Shamir Autograph II+ lenses)',
                        'description': 'Digital HD lenses with wider intermediate and reading corridors for more comfort',
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Digital HD lenses with wider intermediate and reading corridors for more comfort. Shamir Autograph II+ lenses.</p>'
                            }
                        }
                    }
                ]
            }
        },
        single_vision_distance,
        single_vision_near,
        progressive_standard,
        progressive_premium,
        lens_type,
        lens_options,
        frames_type
    ]
}
