var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product" }, [
    _vm.outOfStock
      ? _c("div", { staticClass: "sold-out-block" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _vm.currentImage
      ? _c(
          "a",
          {
            staticClass: "product photo",
            attrs: { title: _vm.product.name, href: _vm.productUrl }
          },
          [
            _c("img", {
              attrs: { alt: _vm.product.name, src: _vm.currentImage.path }
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "product details" }, [
      _vm.showPPEAsEverydayEyewear
        ? _c("span", { staticClass: "category-title" }, [
            _vm._v("Everyday Eyewear")
          ])
        : _vm.showCategoryName ||
          _vm.extras.includes(_vm.product.category) ||
          _vm.getIsTitle
        ? _c("span", { staticClass: "category-title" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.everydayEyeWear.includes(_vm.product.category)
                    ? "Everyday Eyewear"
                    : _vm.product.category
                ) +
                "\n        "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-details" },
        [
          _c("strong", { staticClass: "product name product-item-name" }, [
            _c("a", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.product.name,
                  expression: "product.name"
                }
              ],
              staticClass: "product-item-link",
              attrs: { title: _vm.product.name, href: _vm.productUrl }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "price-container" }, [
            _vm.showProductPrice || _vm.showPrice
              ? _c("span", { staticClass: "product-price" }, [
                  _vm._v(
                    "Price " +
                      _vm._s(_vm.currentImage.price || _vm.product.price)
                  )
                ])
              : _vm.product.category.indexOf("Hobie") !== -1
              ? _c("span", { staticClass: "shopframes-price" }, [
                  _vm._v("From " + _vm._s(_vm.currentImage.price))
                ])
              : _vm.product.category.indexOf("HyperX") !== -1
              ? _c("span", { staticClass: "shopframes-price" }, [
                  _vm._v(
                    "\n                    From " +
                      _vm._s(_vm.product.price.replace(/(\.[0-9]*?)0+/g, "")) +
                      "\n                "
                  )
                ])
              : _vm.showShopFramesPrice ||
                _vm.product.category === "Willows Eyewear"
              ? _c("span", { staticClass: "shopframes-price" }, [
                  _vm._v(
                    "From " +
                      _vm._s(
                        _vm.product.name === "Hearts of Yang"
                          ? "$98.00"
                          : _vm.currentImage.price || _vm.product.price
                      )
                  )
                ])
              : _vm.product.category === "Everyday Eyewear" ||
                _vm.product.category.indexOf("Personal") !== -1
              ? _c("span", { staticClass: "shopframes-price" }, [
                  _vm._v("Free Frame with Lens Purchase")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.showSwatcher || _vm.showColorsSwatcher
            ? _c("ColorSwatches", {
                attrs: {
                  colors: _vm.images,
                  "checked-index": _vm.checkedIndex,
                  "option-item-class": "decorate img",
                  "product-name":
                    _vm.everydayEyeFrameName &&
                    _vm.productCategory === "Everyday Eyewear"
                      ? _vm.everydayEyeFrameName
                      : undefined,
                  "data-swatch-product": _vm.product.name
                },
                on: _vm._d({}, [
                  _vm.EVENT_CHANGE_CURRENT_COLOR,
                  _vm.changeCurrentColor
                ])
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sold-out" }, [
      _c("p", [_vm._v("Sold Out")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }