<template>
    <div>
        <div class="pd_checkbox checkbox-prism">
            <label class="radio-label">
                <div :class="{active: prescriptionValue.prism}" class="option-checkbox-container">
                    <div class="option-checkbox">
                        <input v-model="prescriptionValue.prism" class="prescription-lens-radio" type="checkbox" title="My prescription has a prism correction." />
                    </div>
                </div>
                <span class="text">{{ isMobile ? 'My Rx has a prism.' : 'My prescription has a prism correction.' }} <span v-dompurify-html="'(Pricing is $' + prismPrice + ' / eye.)'"></span></span>
            </label>
        </div>

        <div v-if="prescriptionValue.prism" class="prism-container">
            <div class="selector-progressive-container">
                <div class="selector-prescription">
                    <table v-if="!isMobile" class="params-prescription-table">
                        <colgroup>
                            <col width="22.5%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <span>Prism Horizontal</span>
                                </th>
                                <th>
                                    <span>Horizontal Direction</span>
                                </th>
                                <th>
                                    <span>Prism Vertical</span>
                                </th>
                                <th>
                                    <span>Vertical Direction</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Right Eye (OD)</th>
                                <td>
                                    <CustomSelect :value="prescriptionValue.od_prism_horizontal" :options="eyePrismHorizontalOptions" @change="setPrismValue($event.target.value, 'od_prism_horizontal')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.od_prism_horizontal_direction" :options="eyeHorizontalDirectionOptions" @change="setPrismValue($event.target.value, 'od_prism_horizontal_direction')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.od_prism_vertical" :options="eyePrismVerticalOptions" @change="setPrismValue($event.target.value, 'od_prism_vertical')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.od_prism_vertical_direction" :options="eyeVerticalDirectionOptions" @change="setPrismValue($event.target.value, 'od_prism_vertical_direction')" />
                                </td>
                            </tr>
                            <tr>
                                <th>Left Eye (OS)</th>
                                <td>
                                    <CustomSelect :value="prescriptionValue.os_prism_horizontal" :options="eyePrismHorizontalOptions" @change="setPrismValue($event.target.value, 'os_prism_horizontal')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.os_prism_horizontal_direction" :options="eyeHorizontalDirectionOptions" @change="setPrismValue($event.target.value, 'os_prism_horizontal_direction')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.os_prism_vertical" :options="eyePrismVerticalOptions" @change="setPrismValue($event.target.value, 'os_prism_vertical')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.os_prism_vertical_direction" :options="eyeVerticalDirectionOptions" @change="setPrismValue($event.target.value, 'os_prism_vertical_direction')" />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-else class="params-prescription-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    Right Eye (OD)
                                </th>
                                <th>
                                    Left Eye (OS)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th><span>Prism Horizontal</span></th>
                                <td>
                                    <CustomSelect :value="prescriptionValue.od_prism_horizontal" :options="eyePrismHorizontalOptions" @change="setPrismValue($event.target.value, 'od_prism_horizontal')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.os_prism_horizontal" :options="eyePrismHorizontalOptions" @change="setPrismValue($event.target.value, 'os_prism_horizontal')" />
                                </td>
                            </tr>
                            <tr>
                                <th><span>Horizontal Direction</span></th>
                                <td>
                                    <CustomSelect :value="prescriptionValue.od_prism_horizontal_direction" :options="eyeHorizontalDirectionOptions" @change="setPrismValue($event.target.value, 'od_prism_horizontal_direction')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.os_prism_horizontal_direction" :options="eyeHorizontalDirectionOptions" @change="setPrismValue($event.target.value, 'os_prism_horizontal_direction')" />
                                </td>
                            </tr>
                            <tr>
                                <th><span>Prism Vertical</span></th>
                                <td>
                                    <CustomSelect :value="prescriptionValue.od_prism_vertical" :options="eyePrismVerticalOptions" @change="setPrismValue($event.target.value, 'od_prism_vertical')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.os_prism_vertical" :options="eyePrismVerticalOptions" @change="setPrismValue($event.target.value, 'os_prism_vertical')" />
                                </td>
                            </tr>
                            <tr>
                                <th><span>Vertical Direction</span></th>
                                <td>
                                    <CustomSelect :value="prescriptionValue.od_prism_vertical_direction" :options="eyeVerticalDirectionOptions" @change="setPrismValue($event.target.value, 'od_prism_vertical_direction')" />
                                </td>
                                <td>
                                    <CustomSelect :value="prescriptionValue.os_prism_vertical_direction" :options="eyeVerticalDirectionOptions" @change="setPrismValue($event.target.value, 'os_prism_vertical_direction')" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import prismService from './storage/prism-service'
import generateOptions from '../../utils/generate-options'
import CustomSelect from '../../../components/base/Select.vue'

const DIRECTION_HORIZONTAL = [
    {value: null, label: '--'},
    {value:'IN', label: 'IN'},
    {value: 'OUT', label: 'OUT'}
]

const DIRECTION_VERTICAL = [
    {value: null, label: '--'},
    {value: 'UP', label: 'UP'},
    {value: 'DOWN', label: 'DOWN'}
]

const prismArray = generateOptions(0, 10, 0.05, 2, true, false)

export default {
    name: 'Prism',
    components: {
        CustomSelect
    },
    data: () => {
        return {
            eyePrismHorizontalOptions: prismArray,
            eyePrismVerticalOptions: prismArray,
            eyeHorizontalDirectionOptions: DIRECTION_HORIZONTAL,
            eyeVerticalDirectionOptions: DIRECTION_VERTICAL
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('prism-service', ['prescriptionValue', 'prismPrice'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prism-service/']) {
            this.$store.registerModule('prism-service', prismService(this.$store))
        }
    },
    methods: {
        setPrismValue (val, key) {
            this.$set(this.prescriptionValue, key, val)
        }
    }
}
</script>
