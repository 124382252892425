var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qualifications online-optometry" }, [
    _c(
      "section",
      { staticClass: "section text-center", attrs: { if: !_vm.LOADING } },
      [
        !_vm.finishedStep
          ? _c("div", { staticClass: "row progress-header-form" }, [
              _c("div", { staticClass: "progress-header" }, [
                _c("div", { staticClass: "progress-bg" }, [
                  _c("div", {
                    staticClass: "progress-bar",
                    style: { width: _vm.width }
                  })
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          _c("div", [
            _c(
              "div",
              [
                _c("Loader", { attrs: { loading: _vm.LOADING } }),
                _vm._v(" "),
                _c("Stepper", {
                  attrs: { steps: _vm.steps },
                  on: {
                    "completed-step": _vm.completedStep,
                    "active-step": _vm.isStepActive,
                    "stepper-finished": _vm.finish
                  }
                }),
                _vm._v(" "),
                _vm.ERROR
                  ? _c("p", { staticClass: "help is-danger" }, [
                      _vm._v("Please check entered information")
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }