<template>
    <div v-if="productView" class="spectacles-gen" :class="{'spectacles-gen-load': !productConfiguratorShow}">
        <div v-show="productView.productInfoShow" ref="spectacles-wrapper" class="product-configurator-configuration product-details-holder row spectacles-gen-banner"
             :class="{'spectacles-gen-banner-media': media && media.length}">
            <div v-show="!media || (media && !media.length)" class="product-name">
                <h1 v-dompurify-html="product_name" :class="product_name" class="spectacles-gen-title" itemprop="name"></h1>
            </div>

            <Gallery :gallery-thumbs-show="false" />
        </div>
        <AnagramAdBar />
        <div v-if="productConfiguratorShow" class="product-configurator-progress spectacles-gen-configurator">
            <Configurator :show-progress-bar="false">
                <template #summary>
                    <SpectaclesGenViewSummary  v-if="!isMobile && !isTablet" class="product-configurator__summary product-configurator-summary" />
                </template>
            </Configurator>
        </div>
        <div v-else class="loader-container">
            <div class="loader-el"></div>
            <div class="spectacles-terms"></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import _cloneDeep from 'lodash/cloneDeep'
import getSpectaclesService from '../../../app/utils/spectacles'
import product from './storage/product/view'
import configurator from '../../configurator/storage/configurator'
import steps from '../../configurator/storage/configurator/steps'
import {mapState, mapGetters, mapMutations} from 'vuex'
import Configurator from '../../configurator/Configurator.vue'
import SpectaclesGenViewSummary from './summary/SpectaclesGenViewSummary.vue'
import Gallery from './view/Gallery.vue'
import BaseButton from '../../base/BaseButton.vue'
import ResetService from '../../../app/mixins/reset-service'
import AnagramAdBar from '../../anagram/AnagramAdBar.vue'

export default {
    name: 'CatalogProductSpectaclesGenView',
    components: {
        Gallery,
        Configurator,
        SpectaclesGenViewSummary,
        BaseButton,
        AnagramAdBar
    },
    mixins: [ResetService],
    data: () => ({
        configApp: '',
        showApp: false,
        colors: {},
        valueAntiReflective: false
    }),
    computed: {
        ...mapState('productView', ['loading', 'product_id']),
        ...mapGetters('deviceProperty', ['isMobile', 'isTablet']),
        ...mapGetters('values', ['checkoutData']),
        ...mapGetters('productView', ['productView', 'product_name', 'product_description', 'default_antireflective', 'colorItem', 'colorName', 'currentProductDefaultDataUrl', 'media']),
        ...mapGetters('deviceProperty', ['isMobile']),
        checkedIndex () {
            return this.productView.checkedIndex
        },
        isAvailable () {
            return this.colorItem ? this.colorItem.qty : 0
        },
        additionalAttributes () {
            return [
                {
                    label: 'Color',
                    value: this.colorName.toLowerCase()
                }
            ]
        },
        productConfiguratorShow () {
            return !this.loading.currentProductDefaultData && this.productView.productConfiguratorShow
        }
    },
    watch: {
        colorItem (item) {
            this.setValuesService(item)
        },
        isMobile (mobile) {
            this.setProductInfoShow(true)
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['configurator/']) {
            this.$store.registerModule('configurator', configurator(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['productView/']) {
            this.$store.registerModule('productView', product(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['configurator/steps/']) {
            this.$store.registerModule(['configurator', 'steps'], steps(this.$store))
        }

        this.$store.dispatch('productView/fetchDefaultData')
    },
    created () {
        if (!this.loading.currentProductDefaultData && this.currentProductDefaultDataUrl) {
            this.$store.commit('productView/SET_CURRENT_PRODUCT_DEFAULT_DATA_LOADING', true)
            return axios.post(this.currentProductDefaultDataUrl, {product_id: this.product_id}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(({data, status}) => {
                if (status === 200) {
                    this.valueAntiReflective = this.default_antireflective
                    this.setProductConfiguratorShow(true)
                    this.$store.dispatch('productView/fetchDefaultData', data)
                }
                this._configuratorReset()
            }).finally(() => this.$store.commit('productView/SET_CURRENT_PRODUCT_DEFAULT_DATA_LOADING', false))
        }
    },
    mounted () {
        this.$store.commit('values/setDataToValues', {
            anti_reflective: this.default_antireflective
        })
    },
    methods: {
        ...mapMutations('productView', ['setCheckedIndex', 'setProductInfoShow', 'setProductConfiguratorShow']),
        ...mapMutations('values', ['setDataToValues']),
        changeCurrentColor (index) {
            this.setCheckedIndex(index)
        },
        toggleAntiReflective () {
            this.valueAntiReflective = !this.valueAntiReflective
            this.$store.commit('values/setDataToValues', {
                anti_reflective: this.valueAntiReflective
            })
        },
        setValuesService (item) {
            const values = _cloneDeep(this.checkoutData)
            const anti_reflective = this.valueAntiReflective

            if (values) {
                const lens_label = item.lens_type
                if (typeof values['anti_reflective'] === 'function') {
                    values['anti_reflective'](anti_reflective)
                } else {
                    values['anti_reflective'] = anti_reflective
                }
                if (typeof values['lens_type'] === 'function') {
                    const lens_type = {
                        'name' : lens_label,
                        'color' : item.color
                    }
                    values['lens_type'](lens_type)
                } else if (typeof values['lens_type'] === 'object') {
                    values['lens_type'].color = item.color
                    values['lens_type'].name = lens_label
                    this.$store.commit('productView/UPDATE_STATE_DATA', {
                        frames_SKU: item.frames_SKU
                    })
                }
            }
        },
        mobileSwitcher () {
            this.setProductInfoShow(false)
            this.setProductConfiguratorShow(true)
        }
    }
}
</script>
