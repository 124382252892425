<template>
    <ShippingDownloadLabel v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import ShippingDownloadLabel from '../../../components/shipping/download/Label.vue'

export default {
    name: 'RouterShippingDownloadLabel',
    components: {
        ShippingDownloadLabel
    },
    extends: App
}
</script>
