import {RootState} from '../../app/RootState'
import _merge from 'lodash/merge'

export default {
    getters: {
        registry: () => RootState.state.registry,
        getRegistry: () => index => RootState.state.registry[index]
    },
    actions: {
        setRegistry: ({}, {index, data}) => RootState.state.registry[index] = data,
        updateRegistry: ({}, {index, data}) => {
            if (RootState.state.registry[index]) {
                _merge(RootState.state.registry[index], data)
            } else {
                RootState.state.registry[index] = data
            }
        }
    }
}
