export default function getSizeHelper (param) {
    const {assets_url} = param

    const size_helpComponent = {
        'Venice': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}venice-measurements.jpg">`
            }
        },
        'Culver': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}culver-measurements.jpg">`
            }
        },
        'Malibu': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}malibu-measurements.jpg">`
            }
        },
        'Melrose': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}melrose-measurements.jpg">`
            }
        },
        'Sunset': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}sunset-measurements.jpg">`
            }
        },
        'Hollywood': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}hollywood-measurements.jpg">`
            }
        },
        'Beverly': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}beverly-measurements.jpg">`
            }
        },
        'Palms': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}palms-measurements.jpg">`
            }
        },
        'Montana': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}montana-measurements.jpg">`
            }
        },
        'Silverlake': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}silverlake-measurements.jpg">`
            }
        },
        'Bundy': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}bundy-measurements.jpg">`
            }
        },
        'Olympic': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}olympic-measurements.jpg">`
            }
        },
        'Robertson': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}robertson-measurements.jpg">`
            }
        },
        'Wilshire': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}wilshire-measurements.jpg">`
            }
        },
        'Palisades': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}palisades-measurements.jpg">`
            }
        },
        'Sawtelle': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}sawtelle-measurements.jpg">`
            }
        },
        'Marina': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}marina-measurements.jpg">`
            }
        },
        'Brentwood': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}brentwood-measurements.jpg">`
            }
        },
        'Cynthia': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}cynthia-measurements.jpg">`
            }
        },
        'Levica': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}levica-measurements.jpg">`
            }
        },
        'Alia': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}alia-measurements.jpg">`
            }
        },
        'Elmer': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}elmer-measurements.jpg">`
            }
        },
        'Kite II': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}kiteii-measurements.jpg">`
            }
        },
        'Barrera': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}barrera-measurements.jpg">`
            }
        },
        'Bossenga': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}bossenga-measurements.jpg">`
            }
        },
        'Kite': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}kite-measurements.jpg">`
            }
        },
        'Lavin': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}lavin-measurements.jpg">`
            }
        },
        'Marcolini': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}marcolini-measurements.jpg">`
            }
        },
        'Monarco': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}monarco-measurements.jpg">`
            }
        },
        'Queen': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}queen-measurements.jpg">`
            }
        },
        'Trojak': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}trojak-measurements.jpg">`
            }
        },
        'Uhlir': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}uhlir-measurements.jpg">`
            }
        },
        'Winek II': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}winek-ii-measurements.jpg">`
            }
        },
        'Wringley II (Kids)': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}wrigley-ii-frame-measurements.jpg">`
            }
        },
        'Akiko': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}akiko-measurements.jpg">`
            }
        },
        'Alexandria': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}alexandria-measurements.jpg">`
            }
        },
        'Carlyle': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}carlyle-measurements.jpg">`
            }
        },
        'Harper': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}harper-measurements.jpg">`
            }
        },
        'Laurel': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}laurel-measurements.jpg">`
            }
        },
        'Lincoln': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}lincoln-measurements.jpg">`
            }
        },
        'Martel': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}martel-measurements.jpg">`
            }
        },
        'Oscar': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}oscar-measurements.jpg">`
            }
        },
        'Sierra': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}sierra-measurements.jpg">`
            }
        },
        'Warhawk': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}warhawk-measurements.jpg">`
            }
        },
        'Addison': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}addison-measurements.jpg">`
            }
        },
        'Pimlico': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}pimlico-measurements.jpg">`
            }
        },
        'Ventura': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}ventura-measurements.jpg">`
            }
        },
        'Chandler': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}chandler-measurements.jpg">`
            }
        },
        'Camden': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}camden-measurements.jpg">`
            }
        },
        'Morrison': {
            'name': 'help-bubble',
            'params': {
                'text': `<img src="${assets_url}morrison-measurements.jpg">`
            }
        }
    }

    const PRICE = {
        optical: 97,
        sun: 127,
        night: 127
    }

    const SUN_COLORS = [
        {
            color: 'grey',
            id: 'grey',
            value: [0.063, 0.071, 0.078, 0.8]
        },
        {
            color: 'brown',
            id: 'brown',
            value: [0.196, 0.094, 0.055, 0.8]
        },
        {
            color: 'g15',
            id: 'g15',
            value: [0.059, 0.09, 0.047, 0.8]
        }
    ]

    const LPlus_Benefits = {
        'eliglible_categories': [
            'Optical',
            'Sun',
            'Night'
        ],
        'discount': 97.00
    }

    return {
        size_helpComponent : size_helpComponent,
        SUN_COLORS: SUN_COLORS,
        LPlus_Benefits: LPlus_Benefits,
        PRICE: PRICE
    }
}
