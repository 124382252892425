<template>
   <div class="rx-seo-container">
        <div class="shopframes-seo-prescription-container">
            <div class="shopframes-seo-row seo-prescription">
                <div class="shopframes-seo-text">
                    <h2>Sunglasses and Sunglass Lenses</h2>
                    <p>Shop with Lensabl for an extensive selection of sunglasses with prescription lenses for sale. Lensabl carries prescription sunglasses online for any patient's optic needs. We have designer sunglasses for men and women from numerous designer brands.</p>
                </div>
                <div class="shopframes-seo-img">
                </div>
            </div>
        </div>

        <div class="shopframes-seo-designer-container">
            <div class="shopframes-seo-row seo-designer">
                <div class="shopframes-seo-designer-img"></div>
                <div class="shopframes-seo-text">
                     <h2>Prescription Lenses for Sunglasses </h2>
                    <p>Lensabl makes it possible to find sunglasses that meet both your vision and lifestyle needs. You'll find polarized prescription sunglasses for sale that improve your vision for reading or distance. Lensabl also carries solid, gradient and mirrored prescription sunglasses with options like 100% ultraviolet (UV) protection, blue-light protection and anti-reflective or anti-scratch coatings. Turn to Lensabl for easy, online prescription sunglass lens replacement. We will also ship your new lenses to you for free!</p>
                </div>
            </div>
        </div>

        <div class="shopframes-seo-sunglass-container">
            <div class="shopframes-seo-row seo-sunglass">
                <div class="shopframes-seo-text">
                     <h2>Durable Frames for Sunglasses</h2>
                    <p>Order sunglass frames through Lensabl to find an option that meets your lifestyle needs. Choose our acetate frames for a lightweight, eco-friendly option that fits comfortably throughout the entire day. Our metal sunglass frames are the most durable option available in styles that complement any wardrobe choice.</p>
                </div>
            </div>
        </div>

        <div class="shopframes-seo-brands-container">
            <div class="shopframes-seo-row seo-brands">
                <div class="shopframes-seo-text">
                    <h2>Designer Sunglasses for Your Prescription</h2>
                    <p>Prescription sunglasses from Lensabl fit into durable frames from many of the most prominent designer brands. Shop with Lensabl to pair your prescription sunglasses with a frame from dependable designers like:</p>
                </div>
                <ul class="seo-brands-list">
                    <li>
                        <a href="/shop-frames/arnette">Arnette</a>
                    </li>
                     <li>
                        <a href="/shop-frames/kate-spade">Kate Spade</a>
                    </li>
                    <li>
                        <a href="/shop-frames/brooks-brothers">Brooks Brothers</a>
                    </li>
                    <li>
                        <a href="/shop-frames/marc-jacobs">Marc Jacobs</a>
                    </li>
                    <li>
                        <a href="/shop-frames/carrera">Carrera</a>
                    </li>
                    <li>
                        <a href="/shop-frames/oakley">Oakley</a>
                    </li>
                    <li>
                        <a href="/shop-frames/everyday-eyewear">Everyday Eyewear</a>
                    </li>
                    <li>
                        <a href="/shop-frames/ray-ban">Ray-Ban</a>
                    </li>
                    <li>
                        <a href="/shop-frames/hugo-boss">Hugo Boss</a>
                    </li>
                    <li>
                        <a href="/shop-frames/vogue">Vogue</a>
                    </li>
                </ul>
        </div>
        </div>

        <div class="shopframes-seo-eyeglass-container">
            <div class="shopframes-seo-row seo-eyeglass">
                <div class="shopframes-seo-text seo-eyeglass-text">
                   <h2>Buy Prescription Sunglasses Online With Lensabl</h2>
                    <p>Lensabl can help you find comfortable, durable sunglasses that fit your prescription. Order prescription sunglasses online for one of the most extensive selections of visual accommodations, styles and sizes.<br/><br/>Lensabl customers benefit from free delivery, 30-day risk-free returns and a 12-month warranty with every order. You can experience additional benefits when you <a href="/plus" title="plus"><span class="rx-seo-link">become a Lensabl+ member</span></a>, with monthly plans starting at $7.99.<br/><br/></p>
                </div>
                <a href="/prescription-sunglass-lenses">
                    <div class="button button--copy-solid">
                        <div class="button__container">Place your prescription sunglass order online today!</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RxSunglassSeo'
}
</script>
