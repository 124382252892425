var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plus-account_container glasses-container",
      class: { redeemed: _vm.redeemedStyle }
    },
    [
      _vm.isMobile
        ? _c(
            "h4",
            { class: { active: _vm.isShow }, on: { click: _vm.toggleContent } },
            [_vm._v("Rx Frame of Lens Replacement")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        !_vm.isMobile || (_vm.isShow && _vm.isMobile)
          ? _c(
              "div",
              { staticClass: "plus-content_container glasses-content" },
              [
                _c("div", { staticClass: "redeem-description" }, [
                  _c("div", { staticClass: "redeem-container" }, [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src:
                            "/js/lensconfigurator/assets/glasses_desktop.svg",
                          alt: "contact lenses"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "description" }, [
                          _vm._v(
                            "\n                            One (1) frame including prescriptions lenses (" +
                              _vm._s(_vm.defaultPrescriptionType) +
                              ")\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("HelpBubble", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.redeemedStyle,
                              expression: "!redeemedStyle"
                            }
                          ],
                          attrs: { data: _vm.component_help.frames }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "secondary" },
                      [
                        _c("BaseButton", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.redeemedStyle,
                              expression: "!redeemedStyle"
                            }
                          ],
                          staticClass: "button button--copy-solid",
                          class: { disable: !_vm.previousvisit },
                          attrs: {
                            disabled: !_vm.paidUp,
                            text: _vm.btnTitle(_vm.redeemItemType),
                            "data-size": "xs"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.openFrameRedeem($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "redeem-container" }, [
                    _c("div"),
                    _vm._v(" "),
                    _c("span", { staticClass: "separator" }, [_vm._v("-or-")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "secondary" },
                      [
                        _c("BaseButton", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.redeemedStyle,
                              expression: "redeemedStyle"
                            }
                          ],
                          staticClass: "button button--copy-solid ladda-button",
                          class: { disabledButton: _vm.isLensesRedeemed },
                          attrs: {
                            disabled: _vm.pending || _vm.isLensesRedeemed,
                            text: _vm.btnTitle(_vm.redeemItemType),
                            "data-size": "xs"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.openLensRedeem($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "redeem-container" }, [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: "/js/lensconfigurator/assets/lens_desktop.svg",
                          alt: "contact lenses"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "description" }, [
                          _vm._v(
                            "\n                            One (1) prescription lens replacement (" +
                              _vm._s(_vm.defaultPrescriptionType) +
                              ")\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("HelpBubble", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.redeemedStyle,
                              expression: "!redeemedStyle"
                            }
                          ],
                          attrs: { data: _vm.component_help.lenses }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "secondary" },
                      [
                        _c("BaseButton", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.redeemedStyle,
                              expression: "!redeemedStyle"
                            }
                          ],
                          staticClass: "button button--copy-solid",
                          class: {
                            disable: !_vm.previousvisit,
                            disabledButton: _vm.isLensesRedeemed && _vm.isMobile
                          },
                          attrs: {
                            disabled: !_vm.paidUp,
                            text: _vm.btnTitle("glasses_single_vision"),
                            "data-size": "xs"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.openLensRedeem($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isContactsInPlan,
              expression: "!isContactsInPlan"
            }
          ],
          staticClass: "desktop-notification-card-top-redeem"
        },
        [
          _c(
            "svg",
            {
              staticClass: "desktop-svg",
              attrs: {
                width: "350px",
                height: "25px",
                viewBox: "0 0 409 25",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Page-1",
                    stroke: "none",
                    "stroke-width": "1",
                    fill: "none",
                    "fill-rule": "evenodd"
                  }
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Lensabl+-Dashboard-0-Welcome-2",
                        transform: "translate(-1642.000000, -900.000000)",
                        fill: "#9BDBDA",
                        "fill-rule": "nonzero"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Line-5",
                          d:
                            "M1666,900.5 L1666,910.5 L2051,910.5 L2051,914.5 L1666,914.5 L1666,924.5 L1642,912.5 L1666,900.5 Z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "tablet-svg",
              attrs: {
                width: "350px",
                height: "50px",
                viewBox: "0 0 408 50",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Page-1",
                    stroke: "none",
                    "stroke-width": "1",
                    fill: "none",
                    "fill-rule": "evenodd"
                  }
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Lensabl+-Dashboard-0-Welcome-1",
                        transform: "translate(-1741.000000, -1114.000000)",
                        fill: "#9BDBDA",
                        "fill-rule": "nonzero"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Path-2",
                          d:
                            "M2147.42073,1160.18616 C2148.42248,1160.65151 2148.85732,1161.84084 2148.39197,1162.8426 C2147.92661,1163.84436 2146.73728,1164.2792 2145.73552,1163.81384 C2080.40435,1133.46493 2003.33382,1118.57493 1926.60961,1118.89802 C1860.01194,1119.17847 1798.7846,1131.24718 1762.12437,1150.36389 L1767.60992,1158.43017 L1741.01562,1162 L1754.11633,1138.58267 L1759.86075,1147.03397 C1797.12034,1127.45734 1859.16268,1115.18201 1926.59277,1114.89806 C2003.87546,1114.57262 2081.52311,1129.57411 2147.42073,1160.18616 Z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "animate-notification-msg" }, [
            _vm._v('To use a reward, click the "Redeem" button.')
          ])
        ]
      ),
      _vm._v(" "),
      _c("Redeem", {
        attrs: {
          requirements: _vm.requirements,
          instruction: _vm.instruction,
          "is-modal-open": _vm.isFramesOpen,
          subtitle: "Frames Benefit"
        },
        on: {
          continue: _vm.continueToFrames,
          update: function($event) {
            _vm.isFramesOpen = false
          }
        }
      }),
      _vm._v(" "),
      _c("Redeem", {
        attrs: {
          requirements: _vm.lens_requirements,
          instruction: _vm.instruction,
          "is-modal-open": _vm.isLensModalOpen,
          subtitle: "Lens Replacement Benefit"
        },
        on: {
          continue: _vm.continueToLens,
          update: function($event) {
            _vm.isLensModalOpen = false
          }
        }
      }),
      _vm._v(" "),
      _c("ExamCreditRedeem", {
        staticClass: "non-plus-modal-wrapper",
        attrs: {
          "is-modal-open": _vm.isEyeExamOpen,
          collect: _vm.collect,
          "redeem-item-type": _vm.redeemItemType,
          instruction: _vm.instruction,
          subtitle: "Non-Lensabl+ Frame or Lens Replacement Benefit"
        },
        on: {
          update: function($event) {
            _vm.isEyeExamOpen = false
          },
          continue: _vm.pendingBenefitCredit
        }
      }),
      _vm._v(" "),
      _c("GiftCreditRedeem", {
        attrs: {
          "is-modal-open": _vm.isGiftCardOpen,
          collect: _vm.collect,
          "redeem-item-type": "frames"
        },
        on: {
          update: function($event) {
            _vm.isGiftCardOpen = false
          },
          continue: _vm.pendingBenefitCredit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }