<template>
    <div v-if="show" class="prescription-list-container">
        <div class="my-prescription-list">
            <div class="my-prescription-list__copy">
                <p v-if="!edit" class="my-prescription-name">{{ prescription.name }}</p>
                <input
                    v-if="edit"
                    id="prescription-form__name"
                    :value="prescription.name"
                    class="my-prescription-form__name outline"
                    placeholder="Enter Prescription Name here..."
                    required="required"
                    @change="setPrescriptionValue($event.target.value, 'prescription_name')"
                />
                <ul>
                    <li>
                        <span>Prescription Type:</span>
                        <span v-if="!edit" class="display-prescription-type">{{ getRXTypes(prescription.prescription_type) }}</span>
                        <CustomSelect
                            v-if="edit"
                            :options="rx_types"
                            :value="prescription.prescription_type"
                            @change="updateRXType($event.target.value)"
                        />
                    </li>
                    <li v-if="prescription.order_id">
                        <span>Order ID:</span>
                        <span>{{ prescription.order_id }}</span>
                    </li>
                </ul>
            </div>
            <div class="prescription-snapshot">
                <div id="poupmaindiv" class="p">
                    <table v-if="!isMobile" class="prescription-data rx-data_my-account" border="0">
                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header" width="16%">RX</td>
                            <td class="prescription-cell prescription-header">SPH</td>
                            <td class="prescription-cell prescription-header">CYL</td>
                            <td class="prescription-cell prescription-header">AXIS</td>
                            <td v-if="hasAdd" class="prescription-cell prescription-header">Add</td>
                        </tr>
                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header">OD (Right)</td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" id="odsphere" :value="all_values['od_sphere']" class="prescription-input" type="text" name="od_sphere">
                                    {{ all_values['od_sphere'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeSphereOptions"
                                    :value="all_values['od_sphere']"
                                    @change="setPrescriptionValue($event.target.value, 'od_sphere')"
                                />
                            </td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" id="odcylinder" :value="all_values['od_cylinder']" class="prescription-input" type="text" name="od_cylinder">
                                    {{ all_values['od_cylinder'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyePowerOptions"
                                    :value="all_values['od_cylinder']"
                                    @change="setPrescriptionValue($event.target.value, 'od_cylinder')"
                                />
                            </td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" id="odaxis" :value="all_values['od_axis']" class="prescription-input" type="text" name="od_axis">
                                    {{ all_values['od_axis'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeAxisOptions"
                                    :value="all_values['od_axis']"
                                    @change="setPrescriptionValue($event.target.value, 'od_axis')"
                                />
                            </td>

                            <td v-if="hasAdd" class="prescription-cell value">
                                <span v-if="!edit" id="odadd" :value="all_values['od_add']" class="prescription-input" type="text" name="od_add">
                                    {{ all_values['od_add'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeAddOptions"
                                    :value="all_values['od_add']"
                                    @change="setPrescriptionValue($event.target.value, 'od_add')"
                                />
                            </td>
                        </tr>

                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header">OS (Left)</td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" id="ossphere" :value="all_values['os_sphere']" class="prescription-input" type="text" name="os_sphere">
                                    {{ all_values['os_sphere'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeSphereOptions"
                                    :value="all_values['os_sphere']"
                                    @change="setPrescriptionValue($event.target.value, 'os_sphere')"
                                />
                            </td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" id="oscylinder" :value="all_values['os_cylinder']" class="prescription-input" type="text" name="os_cylinder">
                                    {{ all_values['os_cylinder'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyePowerOptions"
                                    :value="all_values['os_cylinder']"
                                    @change="setPrescriptionValue($event.target.value, 'os_cylinder')"
                                />
                            </td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" id="osaxis" :value="all_values['os_axis']" class="prescription-input" type="text" name="os_axis">
                                    {{ all_values['os_axis'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeAxisOptions"
                                    :value="all_values['os_axis']"
                                    @change="setPrescriptionValue($event.target.value, 'os_axis')"
                                />
                            </td>
                            <td v-if="hasAdd" class="prescription-cell value">
                                <span v-if="!edit" id="osadd" :value="all_values['os_add']" class="prescription-input" type="text" name="os_add">
                                    {{ all_values['os_add'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeAddOptions"
                                    :value="all_values['os_add']"
                                    @change="setPrescriptionValue($event.target.value, 'os_add')"
                                />
                            </td>
                        </tr>

                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header" style="white-space: nowrap">Notes</td>
                            <td v-if="hasAdd" class="prescription-cell value" colspan="4">
                                Notes for the prescription...
                            </td>
                            <td v-if="!hasAdd" class="prescription-cell value" colspan="3">
                                Notes for the prescription...
                            </td>
                        </tr>
                    </table>
                    <table v-else class="prescription-data rx-data_my-account" border="0">
                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header" width="30%">RX</td>
                            <td class="prescription-cell prescription-header">OD (Right)</td>
                            <td class="prescription-cell prescription-header">OS (Left)</td>
                        </tr>
                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header">SPH</td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" :value="all_values['od_sphere']" class="prescription-input" type="text" name="od_sphere_mobile">
                                    {{ all_values['od_sphere'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeSphereOptions"
                                    :value="all_values['od_sphere']"
                                    @change="setPrescriptionValue($event.target.value, 'od_sphere')"
                                />
                            </td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" :value="all_values['os_sphere']" class="prescription-input" type="text" name="os_sphere_mobile">
                                    {{ all_values['os_sphere'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeSphereOptions"
                                    :value="all_values['os_sphere']"
                                    @change="setPrescriptionValue($event.target.value, 'os_sphere')"
                                />
                            </td>
                        </tr>
                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header">CYL</td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" :value="all_values['od_cylinder']" class="prescription-input" type="text" name="od_cylinder">
                                    {{ all_values['od_cylinder'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyePowerOptions"
                                    :value="all_values['od_cylinder']"
                                    @change="setPrescriptionValue($event.target.value, 'od_cylinder')"
                                />
                            </td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" :value="all_values['os_cylinder']" class="prescription-input" type="text" name="os_cylinder">
                                    {{ all_values['os_cylinder'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyePowerOptions"
                                    :value="all_values['os_cylinder']"
                                    @change="setPrescriptionValue($event.target.value, 'os_cylinder')"
                                />
                            </td>
                        </tr>
                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header">Axis</td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" :value="all_values['od_axis']" class="prescription-input" type="text" name="od_axis">
                                    {{ all_values['od_axis'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeAxisOptions"
                                    :value="all_values['od_axis']"
                                    @change="setPrescriptionValue($event.target.value, 'od_axis')"
                                />
                            </td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" :value="all_values['os_axis']" class="prescription-input" type="text" name="os_axis">
                                    {{ all_values['os_axis'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeAxisOptions"
                                    :value="all_values['os_axis']"
                                    @change="setPrescriptionValue($event.target.value, 'os_axis')"
                                />
                            </td>
                        </tr>

                        <tr v-if="hasAdd" class="prescription-row">
                            <td class="prescription-cell prescription-header">Add</td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" :value="all_values['od_add']" class="prescription-input" type="text" name="od_add">
                                    {{ all_values['od_add'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeAddOptions"
                                    :value="all_values['od_add']"
                                    @change="setPrescriptionValue($event.target.value, 'od_add')"
                                />
                            </td>
                            <td class="prescription-cell value">
                                <span v-if="!edit" :value="all_values['os_add']" class="prescription-input" type="text" name="os_add">
                                    {{ all_values['os_add'] }}
                                </span>
                                <CustomSelect
                                    v-if="edit"
                                    :options="eyeAddOptions"
                                    :value="all_values['os_add']"
                                    @change="setPrescriptionValue($event.target.value, 'os_add')"
                                />
                            </td>
                        </tr>

                        <tr class="prescription-row">
                            <td class="prescription-cell prescription-header" style="white-space: nowrap">Notes</td>
                            <td class="prescription-cell value" colspan="2">
                                Notes for the prescription...
                            </td>
                        </tr>
                    </table>
                    <RadioLabel
                        v-if="edit"
                        :label="`Does your PD have two numbers`"
                        :active="sepPds"
                        @click="toggle('sepPds')"
                    />
                    <div v-if="sepPds">
                        <table class="prescription-data pd-data_my-account" border="0">
                            <tr class="prescription-row">
                                <td v-if="isMobile" class="prescription-cell prescription-header PD-header" width="30%" rowspan="2">PD</td>
                                <td v-else class="prescription-cell prescription-header PD-header" width="16%" rowspan="2">PD</td>

                                <td class="prescription-cell value value-header">OD (Right)</td>
                                <td class="prescription-cell value value-header">OS (Left)</td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="odpd" :value="all_values['od_pd']" class="prescription-input" type="text" name="od_pd">
                                        {{ all_values['od_pd'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeODPDOptions"
                                        :value="all_values['od_pd']"
                                        @change="setPrescriptionValue($event.target.value, 'od_pd')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="ospd" :value="all_values['os_pd']" class="prescription-input" type="text" name="os_pd">
                                        {{ all_values['os_pd'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeOSPDOptions"
                                        :value="all_values['os_pd']"
                                        @change="setPrescriptionValue($event.target.value, 'os_pd')"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div v-else>
                        <table class="prescription-data pd-data_my-account" border="0">
                            <tr class="prescription-row">
                                <td v-if="isMobile" class="prescription-cell prescription-header PD-header" width="30%">PD</td>
                                <td v-else class="prescription-cell prescription-header PD-header" width="16%">PD</td>

                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="pd" :value="all_values['pd']" class="prescription-input" type="text" name="pd">
                                        {{ all_values['pd'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePDOptions"
                                        :value="all_values['pd']"
                                        @change="setPrescriptionValue($event.target.value, 'pd')"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <RadioLabel
                        v-if="edit"
                        :label="`Does your Rx have a Prism?`"
                        :active="hasPrism"
                        @click="toggle('hasPrism')"
                    />
                    <div v-if="hasPrism">
                        <table v-if="!isMobile" class="prescription-data pd-data_my-account" border="0">
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header" width="16%">Prism</td>
                                <td class="prescription-cell prescription-header">Prism Horiz.</td>
                                <td class="prescription-cell prescription-header">Horiz. Direction</td>
                                <td class="prescription-cell prescription-header">Prism Vert.</td>
                                <td class="prescription-cell prescription-header">Vert. Direction</td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">OD (Right)</td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="odprismhorizontal" :value="all_values['od_prism_horizontal']" class="prescription-input" type="text" name="od_prism_horizontal">
                                        {{ all_values['od_prism_horizontal'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePrismHorizontalOptions"
                                        :value="all_values['od_prism_horizontal']"
                                        @change="setPrescriptionValue($event.target.value, 'od_prism_horizontal')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="od_prism_horizontal_direction" :value="all_values['od_prism_horizontal_direction']" class="prescription-input" type="text" name="od_prism_horizontal_direction">
                                        {{ all_values['od_prism_horizontal_direction'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeHorizontalDirectionOptions"
                                        :value="all_values['od_prism_horizontal_direction']"
                                        @change="setPrescriptionValue($event.target.value, 'od_prism_horizontal_direction')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="od_prism_vertical" :value="all_values['od_prism_vertical']" class="prescription-input" type="text" name="od_prism_vertical">
                                        {{ all_values['od_prism_vertical'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePrismVerticalOptions"
                                        :value="all_values['od_prism_vertical']"
                                        @change="setPrescriptionValue($event.target.value, 'od_prism_vertical')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="od_prism_vertical_direction" :value="all_values['od_prism_vertical_direction']" class="prescription-input" type="text" name="od_prism_vertical_direction">
                                        {{ all_values['od_prism_vertical_direction'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeVerticalDirectionOptions"
                                        :value="all_values['od_prism_vertical_direction']"
                                        @change="setPrescriptionValue($event.target.value, 'od_prism_vertical_direction')"
                                    />
                                </td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">OS (Left)</td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="osprismhorizontal" :value="all_values['os_prism_horizontal']" class="prescription-input" type="text" name="os_prism_horizontal">
                                        {{ all_values['os_prism_horizontal'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePrismHorizontalOptions"
                                        :value="all_values['os_prism_horizontal']"
                                        @change="setPrescriptionValue($event.target.value, 'os_prism_horizontal')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="os_prism_horizontal_direction" :value="all_values['os_prism_horizontal_direction']" class="prescription-input" type="text" name="os_prism_horizontal_direction">
                                        {{ all_values['os_prism_horizontal_direction'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeHorizontalDirectionOptions"
                                        :value="all_values['os_prism_horizontal_direction']"
                                        @change="setPrescriptionValue($event.target.value, 'os_prism_horizontal_direction')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="os_prism_vertical" :value="all_values['os_prism_vertical']" class="prescription-input" type="text" name="os_prism_vertical">
                                        {{ all_values['os_prism_vertical'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePrismVerticalOptions"
                                        :value="all_values['os_prism_vertical']"
                                        @change="setPrescriptionValue($event.target.value, 'os_prism_vertical')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" id="os_prism_vertical_direction" :value="all_values['os_prism_vertical_direction']" class="prescription-input" type="text" name="os_prism_vertical_direction">
                                        {{ all_values['os_prism_vertical_direction'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeVerticalDirectionOptions"
                                        :value="all_values['os_prism_vertical_direction']"
                                        @change="setPrescriptionValue($event.target.value, 'os_prism_vertical_direction')"
                                    />
                                </td>
                            </tr>
                        </table>
                        <table v-else class="prescription-data pd-data_my-account" border="0">
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header" width="30%">PRISM</td>
                                <td class="prescription-cell prescription-header">OD (Right)</td>
                                <td class="prescription-cell prescription-header">OS (Left)</td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">PRISM HORIZ.</td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" :value="all_values['od_prism_horizontal']" class="prescription-input" type="text" name="od_prism_horizontal">
                                        {{ all_values['od_prism_horizontal'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePrismHorizontalOptions"
                                        :value="all_values['od_prism_horizontal']"
                                        @change="setPrescriptionValue($event.target.value, 'od_prism_horizontal')"
                                    />
                                </td>

                                <td class="prescription-cell value">
                                    <span v-if="!edit" :value="all_values['os_prism_horizontal']" class="prescription-input" type="text" name="os_prism_horizontal">
                                        {{ all_values['os_prism_horizontal'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePrismHorizontalOptions"
                                        :value="all_values['os_prism_horizontal']"
                                        @change="setPrescriptionValue($event.target.value, 'os_prism_horizontal')"
                                    />
                                </td>
                            </tr>

                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">HORIZ. DIRECTION</td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" :value="all_values['od_prism_horizontal_direction']" class="prescription-input" type="text" name="od_prism_horizontal_direction">
                                        {{ all_values['od_prism_horizontal_direction'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeHorizontalDirectionOptions"
                                        :value="all_values['od_prism_horizontal_direction']"
                                        @change="setPrescriptionValue($event.target.value, 'od_prism_horizontal_direction')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" :value="all_values['os_prism_horizontal_direction']" class="prescription-input" type="text" name="oS_prism_horizontal_direction">
                                        {{ all_values['os_prism_horizontal_direction'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeHorizontalDirectionOptions"
                                        :value="all_values['os_prism_horizontal_direction']"
                                        @change="setPrescriptionValue($event.target.value, 'os_prism_horizontal_direction')"
                                    />
                                </td>
                            </tr>
                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">PRISM VERT.</td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" :value="all_values['od_prism_vertical']" class="prescription-input" type="text" name="od_prism_vertical">
                                        {{ all_values['od_prism_vertical'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePrismVerticalOptions"
                                        :value="all_values['od_prism_vertical']"
                                        @change="setPrescriptionValue($event.target.value, 'od_prism_vertical')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" :value="all_values['os_prism_vertical']" class="prescription-input" type="text" name="os_prism_vertical">
                                        {{ all_values['os_prism_vertical'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyePrismVerticalOptions"
                                        :value="all_values['os_prism_vertical']"
                                        @change="setPrescriptionValue($event.target.value, 'os_prism_vertical')"
                                    />
                                </td>
                            </tr>

                            <tr class="prescription-row">
                                <td class="prescription-cell prescription-header">VERT. DIRECTION</td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" :value="all_values['od_prism_vertical_direction']" class="prescription-input" type="text" name="od_prism_vertical_direction">
                                        {{ all_values['od_prism_vertical_direction'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeVerticalDirectionOptions"
                                        :value="all_values['od_prism_vertical_direction']"
                                        @change="setPrescriptionValue($event.target.value, 'od_prism_vertical_direction')"
                                    />
                                </td>
                                <td class="prescription-cell value">
                                    <span v-if="!edit" :value="all_values['os_prism_vertical_direction']" class="prescription-input" type="text" name="os_prism_vertical_direction">
                                        {{ all_values['os_prism_vertical_direction'] }}
                                    </span>
                                    <CustomSelect
                                        v-if="edit"
                                        :options="eyeVerticalDirectionOptions"
                                        :value="all_values['os_prism_vertical_direction']"
                                        @change="setPrescriptionValue($event.target.value, 'os_prism_vertical_direction')"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="my-prescription-list__actions">
                <ul :class="{ edit: edit }" class="my-prescription-list__actions-list">
                    <li class="my-prescription-edit-button">
                        <div class="button button--copy-border-sm edit">
                            <div v-if="!edit" class="button__copy button-edit" @click="editRX(prescription.prescription_id)">edit</div>
                            <div v-if="edit" class="button__copy button-save" @click="sendForm()">save</div>
                        </div>
                    </li>
                    <li v-if="prescription.prescription_id && edit">
                        <div class="primary button-delete">
                            <BaseButton class="button--copy-solid-white button--copy-border-sm delete" data-size="xs" text="Delete" @click.native="deleteConfirmation = true" />
                        </div>
                    </li>
                    <li v-if="!edit && rxHistory[0]" class="my-prescription-list__history-container">
                        <ul>
                            <li>Last Edited: {{ getHistoryDate(rxHistory[rxHistory.length - 1].when) }}</li>
                            <li>Edited by: {{ rxHistory[rxHistory.length - 1].edited_by }}</li>
                        </ul>
                        <div class="button button--copy" @click="openModal()">
                            <div class="button__copy">View History</div>
                        </div>
                    </li>
                </ul>
            </div>

            <div v-if="deleteConfirmation" class="my-prescription-delete">
                <div class="my-prescription-delete__inner">
                    <p>Are you sure you want to delete this prescription?</p>
                    <div class="actions-toolbar">
                        <div class="secondary">
                            <BaseButton :loader="deleting" class="button--copy-solid-white button--copy-border-sm delete" data-size="xs" text="Yes, Delete" @click.native="deletePrescription" />
                        </div>
                        <div class="secondary">
                            <BaseButton class="button--copy-solid-white button--copy-border-sm keep" data-size="xs" text="No, Keep" @click.native="deleteConfirmation = false" />
                        </div>
                    </div>
                </div>
            </div>

            <History :history="history" :name="prescription.name" :rx-history="rxHistory" :type="getRXTypes(prescription.prescription_type)" @close="hideModal()" />
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import prescriptions from '../../prescriptions/storage/prescriptions'

import CommonPrescriptionModel from '../../../app/common/prescription_model'
import CustomSelect from '../../../components/base/Select.vue'
import BaseButton from '../../../components/base/BaseButton.vue'
import RadioLabel from '../../../components/base/RadioLabel.vue'
import History from './History.vue'

export default {
    name: 'RxTable',
    components: {
        CustomSelect,
        BaseButton,
        RadioLabel,
        History
    },
    extends: CommonPrescriptionModel,
    props: {
        'prescription': {
            type: Object,
            default: () => {},
            required: true
        },
        'all_values': {
            type: Object,
            default: () => {},
            required: true
        },
        rxHistory: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    data: () => ({
        edit: true,
        history: false,
        saving: false,
        ordering: false,
        deleting: false,
        deleteConfirmation: false,
        show: true,
        sepPds: false,
        hasPrism: false,
        hasAdd: false,
        rx_types: [
            {label: 'Single Vision Distance', value: 'single_vision_distance'},
            {label: 'Single Vision Reading', value: 'single_vision_reading'},
            {label: 'Progressive', value: 'progressive'},
            {label: 'Bi Focal', value: 'bi_focal'}
        ]
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('prescriptions', ['prescriptionService'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
    },
    mounted () {
        if (this.all_values['os_add'] || this.all_values['od_add']) {
            this.hasAdd = true
        }
        this.hasPrism = this.all_values.prism
        this.sepPds = this.all_values.separate_pd
        if (document.getElementById(this.prescription.prescription_id)) {
            this.edit = false
        }
    },
    methods: {
        updateRXType (type) {
            const addTypes = ['bi_focal', 'progressive']
            if (addTypes.includes(type)) {
                if (this.all_values['od_add'] === undefined) {
                    this.hasAdd = true
                    this.all_values['od_add'] = 0
                }
                if (this.all_values['os_add'] === undefined) {
                    this.hasAdd = true
                    this.all_values['os_add'] = 0
                }
                this.hasAdd = true
            } else {
                this.hasAdd = false
                if (this.all_values['od_add']) {
                    this.all_values['od_add'] = ''
                }
                if (this.all_values['os_add']) {
                    this.all_values['os_add'] = ''
                }
            }
            this.prescription.prescription_type = type
        },
        setPrescriptionValue (val, key) {
            if (key === 'prescription_name') {
                this.prescription.name = val
            } else {
                this.all_values[key] = val
            }
        },
        editRX (id) {
            this.edit = true
        },
        getRXTypes (type) {
            if (type) {
                let str = type
                str = str.replace(/_/g, ' ')

                return this.titleCase(str)
            }
        },
        titleCase (str) {
            const splitStr = str.toLowerCase().split(' ')
            for (let i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
            }
            return splitStr.join(' ')
        },
        getHistoryDate (str) {
            const splitStr = str.split(' ')
            return splitStr[2]
        },
        deletePrescription () {
            if (!this.prescription.prescription_id) {
                return false
            }
            this.deleting = true
            this.$emit('clicked', false)

            this.prescriptionService.remove(this.prescription.prescription_id)
                .then(() => {
                    this.show = false
                })
                .catch((error) => {
                    this.deleting = false
                    console.error(error)
                })
        },
        sendForm () {
            if (this.prescription.prescription_id) {
                this.prescription.value = this.all_values
            }
            const promise = this.prescription.prescription_id ? this.prescriptionService.update(this.prescription.prescription_id, this.prescription) : this.prescriptionService.create(this.prescription)
            this.saving = true

            promise
                .then(response => {
                    this.saving = false
                    this.prescription.prescription_id = response.data.id
                    const rxTable = document.querySelectorAll('.individual-rx_container:last-child')
                    rxTable.id = response.data.id
                    this.edit = false
                    this.$emit('clicked', false)
                })
                .catch(error => {
                    this.saving = false
                    console.error(error)
                })
        },
        toggle (key) {
            if (key === 'sepPds') {
                this.sepPds = !this.sepPds
                if (this.sepPds === true) {
                    this.all_values['separate_pd'] = true
                    this.all_values['pd'] = ''
                } else {
                    this.all_values['separate_pd'] = false
                    this.all_values['os_pd'] = ''
                    this.all_values['od_pd'] = ''
                }
            } else {
                this.hasPrism = !this.hasPrism
                if (this.hasPrism === true) {
                    this.all_values['prism'] = true
                } else {
                    this.all_values['prism'] = false
                    this.all_values['os_prism_horizontal'] = ''
                    this.all_values['os_prism_vertical'] = ''
                    this.all_values['os_prism_vertical_direction'] = ''
                    this.all_values['os_prism_horizontal_direction'] = ''
                }
            }
        },
        hideModal (value) {
            this.history = value
        },
        openModal () {
            this.history = true
        }
    }
}
</script>

<style scoped>

</style>
