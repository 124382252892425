<template>
    <div v-if="isStepCurrent">
        <div class="options_container upgrades">
            <div class="option-upgrades">
                <div v-if="info.label && !framesPartnerPage">
                    <div :class="{'has-thumbnail': !!info.image}" class="option-container upgrades-container">
                        <div v-if="info.image" class="option-thumbnail">
                            <div v-dompurify-html="info.image"></div>
                        </div>
                        <div class="option-descriptions title">
                            <span class="option-label">
                                <span v-dompurify-html="info.label" class="label-text"></span>
                            </span>
                        </div>
                        <div class="extra-component-container">
                            <div class="extra-component">
                                <span v-dompurify-html="info.desc"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="isMobile" :class="{'has-thumbnail': !!info.image}"
                         class="option-container upgrades-container">
                        <div class="option-descriptions title">
                            <span class="option-label">
                                <span v-dompurify-html="info.label" class="label-text"></span>
                            </span>
                        </div>
                    </div>
                </div>
                <ul class="options">
                    <template v-for="(option, index) in getOptions">
                        <Option v-if="option.show" :key="option.id" :component-help-show="isMobile" :option="option"
                                :index="index" class="step-configuration-content"/>
                    </template>
                </ul>
            </div>
        </div>
        <SelectorUpgrades v-if="!framesPartnerPage || lensabl_frames"/>
        <SelectorWarranty v-if="!isSpectaclesGen" :id="id"/>
    </div>
</template>

<script>
import _map from 'lodash/map'
import _first from 'lodash/first'
import _findIndex from 'lodash/findIndex'
import _cloneDeep from 'lodash/cloneDeep'
import {
    ANTIREFLECTIVE_STEP,
    ANTIREFLECTIVE_INFO,
    ENABLE_PRESCRIPTION_LENS
} from '../../../app/common/constants/antireflective'

import {mapGetters} from 'vuex'
import summary from '../../summary/storage/summary'
import Option from './Option.vue'
import SelectorUpgrades from '../upgrades/SelectorUpgrades.vue'
import SelectorWarranty from '../warranty/SelectorWarranty.vue'

export default {
    name: 'SelectorAntireflective',
    components: {
        Option,
        SelectorUpgrades,
        SelectorWarranty
    },
    props: {
        id: {
            // Step Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => {
        return {
            configs: [...ANTIREFLECTIVE_STEP],
            info: {...ANTIREFLECTIVE_INFO},
            options: []
        }
    },
    computed: {
        ...mapGetters('values', ['values']),
        ...mapGetters('storeView', ['assets_url']),
        ...mapGetters('configurator', ['antireflective_price', 'upgrades_section', 'warranty_section']),
        ...mapGetters('configurator/steps', ['getStepObject', 'limiters']),
        ...mapGetters('values', ['checkoutData', 'framesPartnerPage', 'productType']),
        ...mapGetters('summary', ['computePriceFromConfig']),
        ...mapGetters('productView', ['frames_name', 'lensabl_frames', 'isSpectaclesGen']),
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('configurator/steps', ['getLimitersAntiReflective']),

        step() {
            return this.getStepObject(this.id)
        },
        isStepCurrent() {
            return this.step.isCurrent
        },
        hideAntiFogOption() {
            const {lens_type = {}} = this.checkoutData
            if (this.step.isCurrent) {
                if (lens_type['name'] === 'Sun Mirrored Polarized' || lens_type['name'] === 'Transition XTRActive') {
                    return true
                }
            }
            return false
        },
        getOptions() {
            if (this.hideAntiFogOption) {
                _map(this.options, option => {
                    if (option.id === 'essilor_anti_fog_ar') {
                        option.show = false
                    }
                })
            }
            return this.options
        }
    },
    watch: {
        isStepCurrent(current) {
            if (current) {
                this.optionsUpdate()
            }
        }
    },
    beforeCreate() {
        if (!this.$store._modulesNamespaceMap['summary/']) {
            this.$store.registerModule('summary', summary(this.$store))
        }
        this.$store.dispatch('summary/fetchDefaultData')
    },
    created() {
        this.optionsUpdate()
    },
    methods: {
        hideARLimiters() {
            const limiterKeys = this.limiters

            if (limiterKeys && 'step_antireflective' in limiterKeys) {
                _map(this.options, (config, index) => {
                    if (!limiterKeys.step_antireflective.includes(config.id)) {
                        this.options[index].show = false
                    }
                })
            }
        },
        optionsUpdate() {
            this.changeValueStepVisible()
            this.updateCheckedValue()
            this.options = _cloneDeep(this.configs)
            this.hideARLimiters()
        },
        changeValueStepVisible() {
            const {product, lens_type = {}, lens_options} = this.checkoutData
            const defaultArOptions = ENABLE_PRESCRIPTION_LENS[product] || false
            const lensType = lens_type['name'] || false
            const prescriptionAR = {...defaultArOptions, ...this.getLimitersAntiReflective()}
            /**
             * keep || '' to resolve Error:
             * "[Vue warn]: Error in created hook: "TypeError: lensTypeColor.includes is not a function" line:162 "
             */
            const lensTypeColor = lens_type['color'] || ''
            const lensOptions = lens_options['name'] ? lens_options['name'].replace(' + Standard AR', '') : false
            _map(this.configs, config => {
                config.show = false
            })

            if (!prescriptionAR) {
                return false
            }

            if (!lensType || !lensOptions) {
                return false
            }
            let productAROptionsList;
            const lensTypeColorMirror = lensTypeColor.includes('Mirror') ? 'Mirror Color' : ''
            if (lensType === 'Transition XTRActive') {
                 productAROptionsList = lensTypeColorMirror
                    ? prescriptionAR[lensType][lensTypeColorMirror]
                    : prescriptionAR[lensType]['Default']
            } else {
                 productAROptionsList = prescriptionAR[lensType]
            }
            if (!productAROptionsList) {
                return false
            }
            _map(this.configs, config => {
                const lens_option = _first((lensOptions || '').split(' + '))
                const total = this.computePriceFromConfig({
                    product_type: product,
                    lens_type: lensType,
                    lens_option: lens_option && lens_option.indexOf(' + ') === -1 ? `${lens_option} + ${config.value}` : lens_option
                })
                config.show = Boolean(total) && productAROptionsList.indexOf(config.value) !== -1
            })
        },
        updateCheckedValue() {
            _map(this.configs, config => {
                config.checked = false
                config.price = this.antireflective_price ? this.antireflective_price[config.value] || config.price : config.price

                if (config.show && config.value === this.values.anti_reflective_symbol) {
                    config.checked = true
                }
            })
        },
        changeValueARHandler(key) {
            _map(this.options, val => val.checked = false)
            this.options[key].checked = true
            this.$store.commit('values/setDataToValues', {'anti_reflective_symbol': this.options[key].value})
        }
    }
}
</script>
