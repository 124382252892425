<template>
    <div id="contacts-grid">
        <label v-if="label" v-dompurify-html="label" class="search-title"></label>

        <v-text-field v-model="query" placeholder="Search (ie. Acuvue Moist)" persistent-hint class="search-field" />

        <ul v-if="!isMobile || query" class="products-grid">
            <li v-for="(product, index) in items" :key="`product-item-${index}`" class="item">
                <a
                    :class="{'active': activeSku === product.sku}"
                    href="javascript:void(0)"
                    class="item-box"
                    @click="addProduct(product)"
                >
                    <div class="item-image">
                        <img :src="product.thumbnail" :alt="product.name" class="thumbnail" />
                    </div>
                    <div class="item-content">
                        <h4 class="name">
                            <strong v-dompurify-html="product.defaultName"></strong>
                            <span v-dompurify-html="product.second_title"></span>
                        </h4>
                        <span v-dompurify-html="product.lensTypes" class="lens-types"></span>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import _first from 'lodash/first'
import {
    EVENT_ADD_CONTACTS_TO_EYE,
    EVENT_CONTACT_LENSES_FOUND
} from '../storage/lensablplus'

export default {
    name: 'SearchContacts',
    props: {
        label: {
            type: [String],
            default: ''
        },
        selectedClear: {
            type: [Boolean],
            default: true
        }
    },
    data: () => ({
        query: '',
        activeSku: ''
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablPlus', ['products', 'productsData', 'checkoutData', 'getContactsPage', 'getMaxContacts']),
        items () {
            return this.productsData.filter(item => {
                return item.name.toLowerCase().includes(this.query.toLowerCase())
            })
        },
        hint () {
            const item = this.query ? _first(this.items) : _first(this.productsData)
            return item ? item.name : ''
        },
        itemsFound () {
            return this.items.length >= 0 && this.query.length > 0
        }
    },
    watch: {
        itemsFound: {
            handler (data) {
                this.$emit(EVENT_CONTACT_LENSES_FOUND, data)
            },
            deep: true
        },
        selectedClear: {
            handler (clear) {
                if (clear) {
                    this.activeSku = ''
                }
            },
            deep: true
        }
    },
    methods: {
        addProduct (product = {}) {
            this.activeSku = product.sku
            this.query = product.name

            this.$emit(EVENT_ADD_CONTACTS_TO_EYE, product)
        }
    }
}
</script>

<style lang="less" scoped>

</style>
