var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "employee-signup" },
        [
          _c("nav", { staticClass: "lensabl-plus-nav signup-nav" }, [
            _c("div", { staticClass: "nav-container" }, [
              _vm.getLogoImage
                ? _c(
                    "div",
                    {
                      staticClass:
                        "nav-container-logo nav-container-employee-logo"
                    },
                    [
                      _c("img", {
                        staticClass: "lensabl-plus-employee-logo",
                        attrs: { src: _vm.getLogoImage }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._m(0)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "employee-signup-info" }, [
              _c("p", [
                _vm._v(
                  "\n                Taking care of your vision can be expensive and inconvenient. With Lensabl, you have easy access to\n                affordable designer frames, prescription lenses,\n                and contacts all available with the click of a button at "
                ),
                _c("a", { attrs: { href: _vm.base_url } }, [
                  _vm._v("Lensabl.com")
                ]),
                _vm._v(
                  ". And\n                now, Members get access to exclusive discounts on everything\n                we sell!\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("b", [
                  _vm._v("Save up to "),
                  !_vm.isDiscountTypePercent("replacement_discount")
                    ? _c("span", [_vm._v("$")])
                    : _vm._e(),
                  _c("span", [
                    _vm._v(_vm._s(_vm.getDiscountValue("replacement_discount")))
                  ]),
                  _vm.isDiscountTypePercent("replacement_discount")
                    ? _c("span", [_vm._v("%")])
                    : _vm._e(),
                  _vm._v(
                    " when you sign up for Lensabl’s\n                Discount Membership Program."
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("ul", { staticClass: "employee-signup-benefit-list" }, [
                _c("li", [
                  _c("img", { attrs: { src: "/media/wysiwyg/lenses.png" } }),
                  _vm._v(" "),
                  _c("span", [
                    !_vm.isDiscountTypePercent("replacement_discount")
                      ? _c("span", [_vm._v("$")])
                      : _vm._e(),
                    _vm._v(
                      _vm._s(_vm.getDiscountValue("replacement_discount"))
                    ),
                    _vm.isDiscountTypePercent("replacement_discount")
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(
                      " off Prescription and non-prescription lens replacement for your existing glasses"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", {
                    attrs: { src: "/media/wysiwyg/frames-icon.png" }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    !_vm.isDiscountTypePercent("designer_frames_discount")
                      ? _c("span", [_vm._v("$")])
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getDiscountValue("designer_frames_discount"))
                      )
                    ]),
                    _vm.isDiscountTypePercent("designer_frames_discount")
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(
                      " off Designer frame brands including Ray-Ban, Oakley, Nike, and many more"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", { attrs: { src: "/media/wysiwyg/contacts.png" } }),
                  _vm._v(" "),
                  _c("span", [
                    !_vm.isDiscountTypePercent("contacts_discount")
                      ? _c("span", [_vm._v("$")])
                      : _vm._e(),
                    _c("span", [
                      _vm._v(_vm._s(_vm.getDiscountValue("contacts_discount")))
                    ]),
                    _vm.isDiscountTypePercent("contacts_discount")
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" off Every major contact lens brand")
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", { attrs: { src: "/media/wysiwyg/savings.png" } }),
                  _vm._v(" "),
                  _c("span", [
                    !_vm.isDiscountTypePercent("replacement_discount")
                      ? _c("span", [_vm._v("$")])
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getDiscountValue("replacement_discount"))
                      )
                    ]),
                    _vm.isDiscountTypePercent("replacement_discount")
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(
                      " off Reading glasses, blue-light blocking lenses, Transitions lenses and many more specialty lens options"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("img", { attrs: { src: "/media/wysiwyg/exam.png" } }),
                  _vm._v(" "),
                  _c("span", [
                    !_vm.isDiscountTypePercent("online_vision_test_discount")
                      ? _c("span", [_vm._v("$")])
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getDiscountValue("online_vision_test_discount")
                        )
                      )
                    ]),
                    _vm.isDiscountTypePercent("online_vision_test_discount")
                      ? _c("span", [_vm._v("%")])
                      : _vm._e(),
                    _vm._v(" off An AI-powered online vision test")
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.loading
            ? _c("PageLoader", { attrs: { load: _vm.loading } })
            : _c(
                "div",
                { staticClass: "lensabl-plus-content company-details" },
                [
                  _c("h2", { staticClass: "title" }, [
                    _vm._v("Enter Your Details")
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "subtitle" }, [
                    _vm._v(
                      "Please complete the information below to create your Lensabl Discount Program\n            Account"
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "company-address",
                      attrs: { id: "company_address_fields" }
                    },
                    [
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "hor-scroll" }, [
                          _c(
                            "table",
                            {
                              staticClass: "form-list",
                              staticStyle: { width: "100%" },
                              attrs: { cellspacing: "0" }
                            },
                            [
                              _c("tbody", [
                                _c(
                                  "tr",
                                  [
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["name"],
                                        error:
                                          _vm.errors[_vm.fields["name"].name],
                                        colspan: "2",
                                        value:
                                          _vm.values[_vm.fields["name"].name]
                                      },
                                      on: {
                                        blur: _vm.validBlurField,
                                        update: _vm.updateValue
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  [
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["email"],
                                        error:
                                          _vm.errors[_vm.fields["email"].name],
                                        value:
                                          _vm.values[_vm.fields["email"].name]
                                      },
                                      on: {
                                        blur: _vm.validBlurField,
                                        update: _vm.updateValue
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for:
                                                "membership-" +
                                                _vm.fields["birthday"].name
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.fields["birthday"].label
                                                ) +
                                                ":\n                                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("date-picker", {
                                          staticClass: "input-text",
                                          class: {
                                            "required-entry":
                                              _vm.fields["birthday"].required,
                                            "validation-failed":
                                              _vm.errors[
                                                _vm.fields["birthday"].name
                                              ]
                                          },
                                          attrs: {
                                            placeholder: _vm.dateFormat,
                                            "value-type": "format",
                                            type: "text",
                                            format: _vm.dateFormat,
                                            "disabled-date": function(date) {
                                              return date >= new Date()
                                            }
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.validBlurField(
                                                _vm.fields["birthday"].name
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.values[
                                                _vm.fields["birthday"].name
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.values,
                                                _vm.fields["birthday"].name,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "values[fields['birthday'].name]"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.errors[_vm.fields["birthday"].name]
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "validation-advice",
                                                attrs: {
                                                  id:
                                                    "advice-required-entry-" +
                                                    _vm.fields["birthday"].name
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "This is a required field. Please use exact\n                                    format: " +
                                                    _vm._s(_vm.dateFormat) +
                                                    "\n                                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  [
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["telephone"],
                                        error:
                                          _vm.errors[
                                            _vm.fields["telephone"].name
                                          ],
                                        colspan: "2",
                                        value:
                                          _vm.values[
                                            _vm.fields["telephone"].name
                                          ],
                                        maxlength: _vm.fields.length
                                      },
                                      on: {
                                        blur: _vm.validBlurField,
                                        update: _vm.updateValue
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  [
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["street1"],
                                        error:
                                          _vm.errors[
                                            _vm.fields["street1"].name
                                          ],
                                        colspan: "2",
                                        value:
                                          _vm.values[_vm.fields["street1"].name]
                                      },
                                      on: {
                                        blur: _vm.validBlurField,
                                        update: _vm.updateValue
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  [
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["street2"],
                                        colspan: "2",
                                        value: _vm.values[_vm.fields["street2"]]
                                      },
                                      on: { update: _vm.updateValue }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticClass: "form-list",
                              staticStyle: { width: "100%" },
                              attrs: { cellspacing: "0" }
                            },
                            [
                              _c("colgroup", [
                                _c("col", { attrs: { width: "60%" } }),
                                _vm._v(" "),
                                _c("col", { attrs: { width: "60%" } })
                              ]),
                              _vm._v(" "),
                              _c("tbody", [
                                _c(
                                  "tr",
                                  [
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["country"],
                                        error:
                                          _vm.errors[
                                            _vm.fields["country"].name
                                          ],
                                        value:
                                          _vm.values[_vm.fields["country"].name]
                                      },
                                      on: { update: _vm.updateValue }
                                    }),
                                    _vm._v(" "),
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["city"],
                                        error:
                                          _vm.errors[_vm.fields["city"].name],
                                        value:
                                          _vm.values[_vm.fields["city"].name]
                                      },
                                      on: {
                                        blur: _vm.validBlurField,
                                        update: _vm.updateValue
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "tr",
                                  [
                                    _c("td", [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for:
                                              "employee-" +
                                              _vm.fields["region"].name
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.fields["region"].label
                                              ) +
                                              ":\n                                "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.values[
                                                  _vm.fields["region"].name
                                                ],
                                              expression:
                                                "values[fields['region'].name]"
                                            }
                                          ],
                                          staticClass: "select",
                                          class: {
                                            "required-entry":
                                              _vm.fields["region"].required,
                                            "validation-failed":
                                              _vm.errors[
                                                _vm.fields["region"].name
                                              ]
                                          },
                                          attrs: {
                                            id:
                                              "employee_" +
                                              _vm.fields["region"].name
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.values,
                                                  _vm.fields["region"].name,
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function($event) {
                                                return _vm.validBlurField(
                                                  _vm.fields["region"].name
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _vm._l(_vm.regions_data, function(
                                            region
                                          ) {
                                            return [
                                              _c("option", {
                                                key: "option-" + region.value,
                                                domProps: {
                                                  value: region.value,
                                                  textContent: _vm._s(
                                                    region.label
                                                  )
                                                }
                                              })
                                            ]
                                          })
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm.errors[_vm.fields["region"].name]
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "validation-advice",
                                              attrs: {
                                                id:
                                                  "advice-required-entry-" +
                                                  _vm.fields["region"].name
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    This is a required field.\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["postcode"],
                                        error:
                                          _vm.errors[
                                            _vm.fields["postcode"].name
                                          ],
                                        value:
                                          _vm.values[
                                            _vm.fields["postcode"].name
                                          ]
                                      },
                                      on: {
                                        blur: _vm.validBlurField,
                                        update: _vm.updateValue
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticClass: "form-list",
                              staticStyle: { width: "100%" },
                              attrs: { cellspacing: "0" }
                            },
                            [
                              _c("tbody", [
                                _c(
                                  "tr",
                                  [
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["password"],
                                        error:
                                          _vm.$v.values.password.pattern &&
                                          _vm.errors[
                                            _vm.fields["password"].name
                                          ],
                                        value:
                                          _vm.values[
                                            _vm.fields["password"].name
                                          ],
                                        passwordError: !_vm.$v.values.password
                                          .pattern
                                      },
                                      on: {
                                        blur: _vm.validBlurField,
                                        update: _vm.updateValue
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("Input", {
                                      attrs: {
                                        field: _vm.fields["confirm_password"],
                                        error:
                                          _vm.errors[
                                            _vm.fields["confirm_password"].name
                                          ],
                                        value:
                                          _vm.values[
                                            _vm.fields["confirm_password"].name
                                          ],
                                        passwordError: !_vm.$v.values.password
                                          .pattern
                                      },
                                      on: {
                                        blur: _vm.validBlurField,
                                        update: _vm.updateValue
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "actions-toolbar" }, [
                        _c(
                          "div",
                          { staticClass: "primary" },
                          [
                            _c("BaseButton", {
                              staticClass:
                                "button--copy-solid-white-blue button--small",
                              attrs: { disabled: _vm.sending, text: "Sign up" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.continueNxt($event)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.sendingError
                              ? _c("p", { staticClass: "error-text" }, [
                                  _vm._v(_vm._s(_vm.errorText))
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]
              ),
          _vm._v(" "),
          _vm.showLensablPlusPopup || _vm.showLensablWasAssignBeforePopup
            ? _c("SignInModal", [
                _c("div", [
                  _vm._v(
                    "You are already a Lensabl member. Please sign in to your account using the link below:"
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("a", { attrs: { href: "/customer/account/login" } }, [
                    _vm._v("Sign In")
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-container-logo" }, [
      _c("img", {
        staticClass: "lensabl-plus-nav-logo",
        attrs: { src: "/media/wysiwyg/lensabl-logo.svg" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Sign up for "),
      _c("b", [_vm._v("FREE")]),
      _vm._v(
        " using the enrollment form below and start using your member discount immediately. All\n                discounts will automatically be applied to your order at checkout.\n                Lensabl’s product offering and member discounts include:"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }