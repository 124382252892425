<template>
    <div class="category-frames-wrapper frames-wrapper summary-lensesinclude-container">
        <div class="summary-lensesinclude-header">
            <span>Lenses Include:</span>
        </div>
        <div class="category-frames-included lenses-section" :class="{'lr-lenses-section':isLensReplacementPage}">
            <div v-if="!isLensReplacementPage" class="lens-tab">
                <div class="icon blue-light"><span>Premium Blue-Light Protection</span></div>
            </div>
            <div class="lens-tab">
                <div class="icon ar"><span>Anti-Reflective Coating</span></div>
            </div>

            <div class="lens-tab">
                <div class="icon scratch"><span>Anti-Scratch Coating</span></div>
            </div>

            <div class="lens-tab">
                <div class="icon uv"><span>100% UV Protection</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'LensesInclude',
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('values', ['isLensReplacementPage'])
    }
}
</script>
