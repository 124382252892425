var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bose-dimensions" }, [
    _c("p", { staticClass: "bose-dimensions-copy" }, [
      _vm._v(
        "\n        Classic angular lenses, available in S/M and M/L.\n        "
      ),
      _c(
        "span",
        {
          staticClass: "bose-view-dimensions",
          on: {
            click: function($event) {
              return _vm.openModal()
            }
          }
        },
        [_vm._v("View dimensions")]
      )
    ]),
    _vm._v(" "),
    _c("div", { ref: "modal", staticClass: "bose-dimensions-modal" }, [
      _c("div", { staticClass: "bose-dimensions-model--content" }, [
        _c(
          "div",
          {
            staticClass: "bose-dimensions-modal-close",
            on: {
              click: function($event) {
                return _vm.closeModal()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: "/js/lensconfigurator/assets/close-icon.svg",
                alt: ""
              }
            })
          ]
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1)
      ])
    ]),
    _vm._v(" "),
    _c("div", {
      ref: "modal--overlay",
      staticClass: "bose-dimensions-modal--overlay",
      on: {
        click: function($event) {
          return _vm.closeModal()
        }
      }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bose-dimensions-modal-type" }, [
      _c("span", { staticClass: "bose-dimensions-modal-text" }, [
        _vm._v("Alto S/M")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bose-dimensions-modal-row" }, [
        _c("img", {
          attrs: {
            src: "/js/lensconfigurator/assets/Alto-SM-Front.png",
            alt: ""
          }
        }),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src: "/js/lensconfigurator/assets/Alto-SM-Side.png",
            alt: ""
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bose-dimensions-modal-row" }, [
      _c("div", { staticClass: "bose-dimensions-modal-type" }, [
        _c("span", { staticClass: "bose-dimensions-modal-text" }, [
          _vm._v("Alto M/L")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bose-dimensions-modal-row" }, [
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/assets/Alto-ML-Front.png",
              alt: ""
            }
          }),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/assets/Alto-ML-Side.png",
              alt: ""
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }