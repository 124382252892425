import {mapGetters, mapState, mapActions} from 'vuex'

export default {
    name: 'AbstractRedeem',
    data: () => ({
        redeemItemType: ''
    }),
    computed: {
        ...mapGetters('lensablplus_customer', ['getRedeemItemData']),
        ...mapState('lensablplus_customer', ['cookies_tag_business']),
        ...mapGetters('storeView', ['base_url']),
        showRedeem () {
            const redeem = this.getRedeemItemData(this.redeemItemType)
            return Object.keys(redeem).length > 0
        }
    },
    methods: {
        ...mapActions('lensablplus_customer', ['setCookies']),
        continueToNextPage (link, cookiesType) {
            this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', true)
            this.setCookies({type: cookiesType, value:true})
            window.location.href = link
        }
    }
}
