import * as abstract from "../abstract";
import _formatPrice from '../../app/utils/formatPrice'
import axios from "axios";

export const state = store => ({
    ...abstract.state(),
    discountData: {},
    hasCustomerMembership: false,
    isOnlyExam: false,
    api: {
        urls: {
            membershipDiscountData: '/membership/account/getMembershipDiscountData',
            hasCustomerMembership: '/membership/account/isCustomerAssigned',
        }
    }
})

export const getters = {
    discountData: state => state.discountData,
    hasCustomerMembership: state => state.hasCustomerMembership,
    isOnlyExam: state => state.isOnlyExam,
    discountByType: (store, getters, rootState, rootGetters) => {
        let discount = 0, discountAction = '';
        if (getters.isOnlyExam) {
            discount = getters.discountData['online_vision_test_discount']
            discountAction = getters.discountData['type_online_vision_test_discount']
        } else if (rootGetters['values/isLensReplacementPage']) {
            discount = getters.discountData['replacement_discount']
            discountAction = getters.discountData['type_replacement_discount']
        } else if (rootGetters['values/framesPartnerPage']) {
            discount = getters.discountData['designer_frames_discount']
            discountAction = getters.discountData['type_designer_frames_discount']
        } else if (rootGetters['contacts/isContactDetailsPage']) {
            discount = getters.discountData['contacts_discount']
            discountAction = getters.discountData['type_contacts_discount']
        }
        return {discount, discountAction}
    },
    isMemberBenefitGreater: (store, getters, rootState, rootGetters) => {
        if (rootGetters['lensablplus_customer/isRedeemFlow'] || rootGetters['lensablplus_customer/isLensablPlus']) {
            return false
        }
        return true
    },
    discountLabelByAction: (store, getters, rootState, rootGetters) => {
        const {discount, discountAction} = getters.discountByType
        if (discount <= 0) return ''
        const formattedDiscount = _formatPrice(discount)
        switch (discountAction) {
            case 'fixed':
                return rootGetters['values/currencySymbol'] + formattedDiscount
            case 'percent':
                return formattedDiscount + '%'
        }
    },
    productQty: (store, getters, rootState, rootGetters) => {
        if (rootGetters['contacts/isContactDetailsPage']) {
            const areContactsSubscribed = rootGetters['contacts/getSubscribed'] || false
            if (areContactsSubscribed &&
                areContactsSubscribed === 'yes') return 2
            let qty = 0
            const eyeData = rootGetters['contacts/getEyeData']
            Object.keys(eyeData).map(eye => {
                qty += parseInt(eyeData[eye].boxes)
            })
            return qty
        } else {
            return 1
        }

    },
    getCurrentTotal: (store, getters, rootState, rootGetters) => {
        let total = 0
        const withoutFramesPrice = rootGetters['configurator/isSpectacles'] ||
            rootGetters['productView/isBosePage'] ||
            rootGetters['productView/isWillowsPage']

        const areContactsSubscribed = rootGetters['contacts/getSubscribed'] || false
        if (rootGetters['lensablplus_customer/isLensablPlus']) {
            total = 0
        } else {
            total = rootGetters['summary/totalSummary']
        }

        if (withoutFramesPrice) {
            total -= rootGetters['summary/subTotalAmountFrames']
        }

        if (rootGetters['contacts/isContactDetailsPage'] &&
            areContactsSubscribed &&
            areContactsSubscribed === 'yes') {
            if (rootGetters['lensablplus_customer/isLensablPlus']) {
                total = 0
            } else {
                total = rootGetters['summary/computePriceForContacts'].regPrice
            }
        }
        return total
    },
    calculateDiscount: (store, getters, rootState, rootGetters) => ({total, discountAction, discount}) => {
        const qty = getters.productQty
        switch (discountAction) {
            case 'fixed':
                return _formatPrice(discount)
            case 'percent':
                const price = total / qty
                const discountPerItem = _formatPrice(price * discount / 100)
                return _formatPrice(discountPerItem * qty)
        }
    },
    discountTotalByAction: (store, getters, rootState, rootGetters) => {
        const {discount, discountAction} = getters.discountByType
        if (!getters.isMemberBenefitGreater) return 0
        if (discount <= 0) return 0

        const total = getters.getCurrentTotal
        if(!total) return 0
        return getters.calculateDiscount({total, discount, discountAction})
    },
    getDiscountExam: (store, getters, rootState, rootGetters) => ({total = 0}) => {
        if (!getters.isOnlyExam) return 0
        const {discount, discountAction} = getters.discountByType
        return getters.calculateDiscount({total, discount, discountAction})
    }
}

const mutations = {
    SET_DISCOUNT_DATA: (state, payload = {}) => state.discountData = payload,
    SET_HAS_CUSTOMER_MEMBERSHIP: (state, payload = false) => state.hasCustomerMembership = payload,
    SET_ONLINE_EXAM: (state, payload = false) => state.isOnlyExam = payload,
}

const actions = {
    ...abstract.actions,
    membershipDiscountData: ({state, getters, commit}) => {
        return axios.get(state.api.urls.membershipDiscountData).then(({data, status}) => {
            commit('SET_DISCOUNT_DATA', data)
        })
    },
    hasCustomerMembership: ({state, getters, commit}) => {
        return axios.get(state.api.urls.hasCustomerMembership).then(({data, status}) => {
            commit('SET_HAS_CUSTOMER_MEMBERSHIP', data.is_customer_assigned)
        })
    },
}

export default () => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions
})



