<template>
    <div style="padding: 1rem 3rem 1rem 3rem; text-align: left;">
        <div class="field">
            <div class="control form-access__header">
                <h3>What is your email address?</h3>
                <span v-if="!SIGN_IN" class="stepper-login"> Already have an account? <a id="show-modal" @click="showModal = true">Login</a></span>
                <br />
                <input v-model="email" :class="['input', ($v.email.$error) ? 'is-danger' : '']" type="text" placeholder="Email" class="stepper-email" />
            </div>
        </div>
        <div v-if="showModal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header">
                            <a class="modal-close-button" @click="showModal = false"></a>
                        </div>
                        <div class="modal-body">
                            <slot name="body">
                                <ul class="form-list">
                                    <li>
                                        <input
                                            id="email"
                                            v-model="login.email"
                                            type="text"
                                            placeholder="Email Address"
                                            title="Email Address"
                                        />
                                    </li>
                                    <li>
                                        <input
                                            id="password"
                                            v-model="login.password"
                                            type="password"
                                            placeholder="Password"
                                            title="Password"
                                        />
                                    </li>
                                    <li>
                                        <a class="f-right form-access__link" href="/customer/account/forgotpassword/">Forgot
                                            Your Password?</a>
                                    </li>
                                </ul>
                                <BaseButton :text="'Sign In'" @click.native="sendPost">
                                    Login
                                </BaseButton>
                                <p v-if="errorSignIn" class="help is-danger">Email or password is invalid!</p>
                            </slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required, email, minLength} from 'vuelidate/lib/validators'
import BaseButton from '../../base/BaseButton.vue'

export default {
    components: {
        BaseButton
    },
    mixins: [validationMixin],
    props: {
        currentStep: {
            type: [Array, Object],
            default: () => {
            }
        }
    },
    data () {
        return {
            email: '',
            login: {
                email: '',
                password: ''
            },
            showModal: false,
            errorSignIn: false
        }
    },
    validations: {
        email: {
            required,
            email
        },
        login: {
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(6)
            }
        }
    },
    computed: {
        ...mapGetters('storeView', ['base_url']),
        ...mapGetters('onlineExam', ['EMAIL', 'SIGN_IN'])
    },
    watch: {
        $v: {
            handler: function (val) {
                if (!val.email.$invalid) {
                    this.$emit('can-continue', {value: true})
                    this.$store.commit('onlineExam/SET_EMAIL', val.email.$model)
                } else {
                    this.$emit('can-continue', {value: false})
                    setTimeout(() => {
                        this.$emit('change-next', {nextBtnValue: false})
                    }, 3000)
                }
                this.$store.commit('onlineExam/SET_VALID', !val.email.$invalid)
            },
            deep: true
        },
        EMAIL: 'setEmail'
    },
    activated () {
        this.setEmail()
        if (!this.$v.email.$invalid) {
            this.$emit('can-continue', {value: true})
        } else {
            this.$emit('can-continue', {value: false})
        }
    },
    methods: {
        setEmail () {
            this.email = this.EMAIL
        },
        sendPost () {
            if (!this.$v.login.$invalid) {
                this.errorSignIn = false
                const params = this.login
                params.ajax = true
                axios.post(`${this.base_url}opternative/index/customerSignInAjax`, params)
                    .then(({data, status}) => {
                        if (data.error) {
                            this.errorSignIn = true
                        } else {
                            this.$store.dispatch('onlineExam/getCustomerData').then(() => this.showModal = false)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
            } else {
                this.errorSignIn = true
            }
        }
    }
}
</script>
<!--customer/account/loginPost/-->
