import axios from 'axios'

const controller_url = '/lensabl_cart/index/'

export default () => ({
    namespaced: true,
    state: () => ({
        prism_price: 0
    }),
    getters: {
        prescriptionValue: (store, getters, rootState, rootGetters) => {
            const values = rootGetters['values/values']
            return values.prescriptionValues[values.prescription_type] || false
        },
        prismPrice: (store, getters, rootState, rootGetters) => {
            if (!store.prism_price) {
                // axios.get(rootGetters['storeView/base_url'] + 'rest/V1/products-data/prism-correction')
                axios.get(rootGetters['storeView/base_url'] + controller_url + 'getPrice?sku=' + 'prism-correction')
                    .then(response => {
                        if (response.status === 200) {
                            store.prism_price = response.data.price
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
            return store.prism_price
        },
        prismQty: (store, getters, rootState, rootGetters) => {
            if (getters.getConfigKey !== 'contacts') {
                let qty = 0
                if (getters.prescriptionValue.prism) {
                    let osQty = 0
                    let odQty = 0
                    const os_prism = getters.prescriptionValue.os_prism_horizontal || getters.prescriptionValue.os_prism_vertical
                    const od_prism = getters.prescriptionValue.od_prism_horizontal || getters.prescriptionValue.od_prism_vertical
                    if (os_prism) {
                        osQty = osQty + 1
                    } else {
                        osQty = 0
                    }

                    if (od_prism) {
                        odQty = odQty + 1
                    } else {
                        odQty = 0
                    }
                    if (qty >= 2) {
                        return qty
                    }
                    qty = odQty + osQty
                }

                rootGetters['values/values'].prism_price = getters.prismPrice * (qty)
                rootGetters['values/values'].prism_qty = qty

                return qty
            }
        }
    }
})
