<template>
    <div v-if="statusApi">
        <div v-show="previousDoctors.length">
            <p>Previous Doctor(s)</p>
            <select :value="selectedDoctorId" @change="setDoctor($event.target.value)">
                <option value="" disabled selected>Previous Doctor</option>
                <template v-for="doctor in previousDoctors">
                    <option :key="doctor.doctor_id" :value="doctor.doctor_id">{{ `${doctor.doctor_first_name} ${doctor.doctor_last_name}` }}</option>
                </template>
            </select>
            <p>or enter new details:</p>
        </div>
        <DoctorDetailsForm :id="id" />
    </div>
</template>

<script>
import _find from 'lodash/find'
import {mapState, mapActions} from 'vuex'
import DoctorDetailsForm from './DoctorDetailsForm.vue'

export default {
    name: 'DoctorDetails',
    components: {
        DoctorDetailsForm
    },
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    data: () => {
        return {
            statusApi: true,
            loggedIn: false
        }
    },
    computed: {
        ...mapState('prescriptions', ['selectedDoctorId', 'previousDoctors', 'doctorClinicRelations', 'clinics'])
    },
    watch: {
        selectedDoctorId (id) {
            const doctor = _find(this.previousDoctors, doctor => doctor.doctor_id === id)
            if (doctor) {
                this.$store.commit('prescriptions/UPDATE_DOCTOR', {
                    firstName: doctor.doctor_first_name,
                    lastName: doctor.doctor_last_name
                })

                const doctorClinic = _find(this.doctorClinicRelations, item => item.doctor_id === id)
                if (doctorClinic) {
                    const clinic = _find(this.clinics, clinic => clinic.clinic_id === doctorClinic.clinic_id)
                    if (clinic) {
                        this.$store.commit('prescriptions/UPDATE_DOCTOR', {
                            city: clinic.clinic_city,
                            clinicState: clinic.clinic_state,
                            clinic: clinic.clinic_name,
                            clinicPhoneNumber: clinic.clinic_phone_1
                        })
                    }
                }
            }
        }
    },
    created () {
        this.fetchPreviousDoctors()

        this.isLoggedIn()
    },
    methods: {
        ...mapActions('prescriptions', ['fetchPreviousDoctors']),
        setDoctor (val) {
            this.$store.commit('prescriptions/UPDATE_SELECTED_DOCTOR_ID', val)
        },
        isLoggedIn () {
            this.$store.dispatch('prescriptions/customerSignIn')
                .then(() => {
                    this.statusApi = true
                    this.loggedIn = !!this.$store.state['prescriptions/customerSignIn']
                })
                .catch(err => {
                    this.statusApi = true
                    console.error(err)
                })
        }
    }
}
</script>

<style scoped>

</style>
