<template>
    <div>
        <div v-if="loadingProducts" class="loading-filters"></div>
        <div v-if="listTitle" :class="{active: show}" class="filter-tab" @click="show = !show">
            <span v-if="!isMobile" class="list-title">{{ listTitle }}:</span>
            <span v-if="isMobile" class="list-title">{{ listTitle }}</span>
            <span v-if="isMobile" class="reset-filters" @click="reset">Reset Filters</span>
        </div>
        <div v-if="!isMobile" class="filter-tab">
            <ul class="category-frames-grid">
                <li>
                    <div class="custom-select">
                        <v-select
                            v-model="filter.category"
                            :items="sortCategoriesAlphabetical"
                            item-text="name"
                            item-value="url_key"
                            multiple
                            label="Brand"
                            class="frame-dropdown"
                            :menu-props="{
                               closeOnContentClick: true,
                            }"
                            @change="changedValue('category')"
                        >
                            <template slot="selection" slot-scope="{ data, index }">
                                <div v-if="filter.category.length === 1">
                                    {{ getSelectedName(sortCategoriesAlphabetical, filter.category) }}
                                </div>
                                <div v-else-if="filter.category.length > 1 && index < 1">2 or more &nbsp;&nbsp;&nbsp;</div>
                            </template>
                        </v-select>
                    </div>
                </li>
                <li>
                    <div class="custom-select">
                        <v-select
                            v-model="filter.frame_size"
                            :items="filtersSize"
                            item-text="name"
                            item-value="value"
                            multiple
                            label="Frame Size"
                            class="frame-dropdown"
                            :menu-props="{
                               closeOnContentClick: true,
                            }"
                            @change="changedValue('frame_size')"
                        >
                            <template slot="selection" slot-scope="{ data, index }">
                                <div v-if="filter.frame_size.length === 1">
                                    {{ getSelectedName(filtersSize, filter.frame_size) }}
                                </div>
                                <div v-else-if="filter.frame_size.length > 1 && index < 1">2 or more &nbsp;&nbsp;&nbsp;</div>
                            </template>
                        </v-select>
                    </div>
                </li>
                <li>
                    <div class="custom-select">
                        <v-select
                            v-model="filter.material"
                            :items="filtersMaterial"
                            item-text="name"
                            item-value="value"
                            multiple
                            label="Material"
                            class="frame-dropdown"
                            :menu-props="{
                               closeOnContentClick: true,
                            }"
                            @change="changedValue('material')"
                        >
                            <template slot="selection" slot-scope="{ data, index }">
                                <div v-if="filter.material.length === 1">
                                    {{ getSelectedName(filtersMaterial, filter.material) }}
                                </div>
                                <div v-else-if="filter.material.length > 1 && index < 1">2 or more &nbsp;&nbsp;&nbsp;</div>
                            </template>
                        </v-select>
                    </div>
                </li>
                <li>
                    <div class="custom-select reset-filters-container">
                        <span class="reset-filters" @click="reset">Reset Filters</span>
                    </div>
                </li>
            </ul>
        </div>
        <transition name="slide">
            <div v-if="isMobile && show" class="filter-form">
                <div class="filter-list brands-list">
                    <h5>Brands</h5>
                    <ul>
                        <li v-for="cat in sortCategoriesAlphabetical" :key="`category-${cat.url_key}`">
                            <label :for="cat.url_key">
                                <input
                                    :id="cat.url_key"
                                    v-model="filter.category"
                                    :value="cat.url_key"
                                    type="checkbox"
                                    @change="changedValue('category')"
                                />
                                <span>{{ cat.name }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="filter-list frame-size-list">
                    <h5>Frame Size</h5>
                    <ul>
                        <li v-for="size in filtersSize" :key="`frame_size-${size.value}`">
                            <label :for="size.value">
                                <input
                                    :id="size.value"
                                    v-model="filter.frame_size"
                                    :value="size.value"
                                    type="checkbox"
                                    @change="changedValue('frame_size')"
                                />
                                <span>{{ size.name }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="filter-list material-list">
                    <h5>Material</h5>
                    <ul>
                        <li v-for="mat in filtersMaterial" :key="`material-${mat.value}`">
                            <label :for="mat.value">
                                <input
                                    :id="mat.value"
                                    v-model="filter.material"
                                    :value="mat.value"
                                    type="checkbox"
                                    @change="changedValue('material')"
                                />
                                <span>{{ mat.name }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import _find from 'lodash/find'
import _first from 'lodash/first'
import {mapState, mapGetters, mapActions} from 'vuex'
import {
    EVENT_CATEGORY_SELECTED
} from '../../../app/utils/constants'

export default {
    name: 'NavigationFilters',
    components: {

    },
    props: {
        categories: {
            type: [Object, Array],
            default: () => []
        },
        filtersMaterial: {
            type: [Object, Array],
            default: () => []
        },
        filtersSize: {
            type: [Object, Array],
            default: () => []
        },
        listTitle: {
            type: String,
            default: ''
        },
        currentId: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            show: false,
            filtersData: {
                category: [],
                lens_type: [],
                frame_size: [],
                material: []
            }
        }
    },
    computed: {
        ...mapState('partner', ['currentCategory', 'loadingProducts']),
        ...mapState('category', ['filter']),
        ...mapGetters('deviceProperty', ['isMobile', 'isDesktop']),
        ...mapGetters('category', ['hasFilters', 'currentCategoryId']),
        ...mapGetters('values', ['values']),
        ...mapGetters('partner', ['getCategoriesList']),
        getSelectedName () {
            return (items, id) => {
                let obj = _find(items, {value: _first(id)})
                if (!obj) {
                    obj = _find(items, {url_key: _first(id)})
                }
                return `${obj.name}`
            }
        },
        sortCategoriesAlphabetical () {
            const categoriesList = this.categories

            // if (this.categories[0].name !== 'Optical') {
            //     categoriesList.push({
            //         name: 'Willows Eyewear',
            //         url_key: 'willows',
            //     })
            // }
            //
            // categoriesList.sort(function (a, b) {
            //     const textA = a.name.toUpperCase()
            //     const textB = b.name.toUpperCase()
            //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
            // })

            const updatedCatList = categoriesList.map(object => {
                if (object.name === 'Ic! Berlin') {
                    return {...object, name: 'ic! berlin'}
                }
                return object
            })

            return updatedCatList
        }
    },
    watch: {
        currentCategory: {
            handler(category) {
                this.$nextTick().then(() => {
                    if (category.id) {
                        const _category = _find(this.getCategoriesList, {value: category.id})
                        if (this.currentCategoryId !== category.id) {
                            this.$store.commit('category/setCurrentCategoryId', category.id)
                            if (_category) {
                                this.updateSelectedCategoryFilters(_category)
                            }
                            this.addFiltersToHistory()
                        } else if (_category && !this.filter.category.includes(_category.url_key)) {
                            const parentCategory = category.parent_category
                            if (parentCategory.id) {
                                this.resetCategory()
                                this.addFiltersToHistory()
                            }
                        }
                    }
                })
            },
            deep: true
        },
        filters: {
            handler (data) {
                this.$nextTick().then(() => {
                    this.filtersData = data
                })
            },
            deep: true
        }
    },
    created () {
        this.$store.dispatch('partner/fetchDefaultData')
        this.changedValue('category')
    },
    methods: {
        ...mapActions('partner', ['addFiltersToHistory']),
        updateSelectedCategoryFilters (category) {
            this.$store.commit('category/updatedFiltered', {key: 'category', info: [category.url_key]})
        },
        changedValue (type) {
            this.$emit(EVENT_CATEGORY_SELECTED, type, this.filter[type])
        },
        reset () {
            if (this.currentCategory.parent_category.id && !(Array.isArray(this.currentCategory.child_categories) && this.currentCategory.child_categories.length)) {
                this.$store.commit('partner/setCurrentCategoryId', this.currentCategory.parent_category.id)
            }
            this.$store.dispatch('category/resetFilters').then(() => this.$emit(EVENT_CATEGORY_SELECTED, 'category', this.filter.category))
        },
        resetCategory() {
            this.$store.commit('partner/setCurrentCategoryId', this.currentCategory.parent_category.id)
            this.$store.commit('category/updatedFiltered', {key: 'category', info: []})
            this.$emit(EVENT_CATEGORY_SELECTED, 'category', this.filter.category)
        },
    }
}
</script>
