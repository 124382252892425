<template>
    <div v-if="showRedeem" class="plus-account_container plusdiscount-container offlensplus-container" :class="{'redeemed-offlensplus-container': redeemedStyle }">
        <h4 v-if="isMobile" :class="{active: isShow}" @click="toggleContent">Off Lensabl+ Benefit</h4>
        <transition name="slide">
            <div v-if="!isMobile || isShow && isMobile" class="plus-content_container">
                <div></div>
                <div class="redeem-description">
                    <div class="description-attention">Off Lensabl+ Benefit</div>
                    <HelpBubble :data="component_help.non_lensabl"/>
                    <span class="description">{{ description }}</span>
                </div>
                <div class="secondary">
                    <BaseButton
                        :disabled="!paidUp|| redeemedStyle"
                        :text="btnTitle"
                        :class="{disable: !previousvisit, 'ladda-button':redeemedStyle, 'disabledButton': redeemedStyle }"
                        data-size="xs"
                        class="button button--copy-solid"
                        @click.native="openModalRedeem"
                    />
                </div>
            </div>
        </transition>
        <ExamCreditRedeem
            :is-modal-open="openModal"
            :collect="collect"
            :subtitle="'Off Lensabl+ Benefit'"
            :instruction="nonInstruction"
            :redeem-item-type="redeemItemType"
            class="non-plus-benefit"
            @update="openModal = false"
            @continue="pendingBenefitCredit"
        />
        <GiftCreditRedeem
            :is-modal-open="isGiftCardOpen"
            :collect="collect"
            :redeem-item-type="redeemItemType"
            @update="isGiftCardOpen = false"
            @continue="pendingBenefitCredit"
        />
        <Loader v-if="loadingBlock"/>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import BaseButton from '../../../components/base/BaseButton.vue'
import AbstractRedeem from './AbstractRedeem'
import ExamCreditRedeem from './ExamCreditRedeem.vue'
import {
    STATUS_PENDING,
    STATUS_APPROVED, STATUS_FINISHED
} from '../storage/lensablplus/customer'
import Loader from '../../loaders/Loader.vue'
import GiftCreditRedeem from "./GiftCreditRedeem.vue";

export default {
    name: 'BusinessNonLensablRedeem',
    components: {
        GiftCreditRedeem,
        BaseButton,
        HelpBubble,
        ExamCreditRedeem,
        Loader
    },
    extends: AbstractRedeem,
    data: () => ({
        redeemItemType: 'business_digital_voucher',
        component_help: {
            non_lensabl: {
                name: 'help-bubble',
                params: {
                    text: ''
                }
            }
        },
        isShow: false,
        nonInstruction: 'Upload your receipt from your purchase for glasses or contacts:',
        openModal: false,
        loadingBlock: false,
        isGiftCardOpen: false,
        redirectTo: false,
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablplus_customer', [
            'isContactsRedeemed',
            'getGiftcardConfigs',
            'isRedeemFlow',
            'paidUp',
            'isVoucherRedeemed',
            'defaultPrescriptionType',
            'isLensesRedeemed',
            'isContactsInPlan',
            'statusRedeemed',
            'getBtnTitlesById',
            'getRedeemedStyles',
            'getReceiptRewards',
            'getNonLensablPlus',
            'getHelpComponent',
            'isContactsVoucherRedeemed'
        ]),
        ...mapGetters('storeView', ['base_url']),
        ...mapState('gsap', ['previousvisit']),
        ...mapState('lensablplus_customer', ['cookies_tag_business']),
        receiptRewards() {
            return this.getReceiptRewards(this.redeemItemType)
        },
        isRedeemed(){
            if ( this.statusRedeemed(this.redeemItemType) === 1) return true
            if (this.collect) return false
            if (this.pending) return false
            if ( this.statusRedeemed('business_frames') === 1) return true
            if ( this.statusRedeemed('business_contacts_left') === 1 || this.statusRedeemed('business_contacts_right') === 1) return true
            return false
        },
        btnTitle() {
            if (this.isRedeemed || this.finish) return 'REDEEMED'
            if (this.pending) return 'PENDING REVIEW'
            if (this.collect) return 'COLLECT REWARD'
            return 'REDEEM'
        },
        description () {
            return 'Decline both your contact lens allowance and frames/lens benefit and receive up to $' +
                this.getNonLensablPlus + ' towards contacts or glasses purchased outside of Lensabl.com'
        },
        redeemedStyle () {
            return (this.pending || this.isRedeemed) && !this.finish
        },
        pending () {
            return this.receiptRewards ? this.receiptRewards.status === STATUS_PENDING : false
        },
        collect () {
            return this.receiptRewards ? this.receiptRewards.status === STATUS_APPROVED : false
        },
        finish () {
            return this.receiptRewards ? this.receiptRewards.status === STATUS_FINISHED : false
        }
    },
    methods: {
        toggleContent () {
            if (this.isMobile) {
                this.isShow = !this.isShow
            }
        },
        pendingBenefitCredit () {

        },
        openModalRedeem() {
            if (this.finish) {
                this.$store.dispatch('lensablplus_customer/createRewardTremendous',{
                    value: this.getGiftcardConfigs(this.redeemItemType).tremendous_value,
                    type: this.redeemItemType
                })
            } else if (this.collect) {
                this.isGiftCardOpen = !this.isGiftCardOpen
            } else {
                this.openModal = !this.openModal
            }
        },
    }
}
</script>

<style scoped>
.plus-account_container .loader-2.center {
    z-index: 9999;
    width: 100%;
    height: 100%;
}
</style>
