<template>
    <CustomerHeaderLinks v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CustomerHeaderLinks from '../../../components/customer/header/Links.vue'

export default {
    name: 'RouterCustomerHeaderLinks',
    components: {
        CustomerHeaderLinks
    },
    extends: App
}
</script>
