<template>
    <div v-if="isStepCurrent" class="control-container input-container">
        <div :class="{active: firstName}" class="contacts_input">
            <input
                id="prescription_firstName"
                ref="firstName"
                v-model="firstName"
                :class="{'invalid-border': invalidFirst}"
                type="text"
                placeholder="First Name"
            />
            <label v-if="firstName" class="contacts_input-label">First Name</label>
            <div v-if="invalidFirst">
                <span class="validation-message">First Name Required</span>
            </div>
        </div>
        <div :class="{active: lastName}" class="contacts_input">
            <input
                id="prescription_lastName"
                ref="lastName"
                v-model="lastName"
                :class="{'invalid-border': invalidLast}"
                type="text"
                placeholder="Last Name"
            />
            <label v-if="lastName" class="contacts_input-label">Last Name</label>
            <div v-if="invalidLast">
                <span class="validation-message">Last Name Required</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'
import prescriptions from '../../prescriptions/storage/prescriptions'

import {MESSAGE_VALIDATE_RX_OWNER} from '../../../app/utils/constants'

export default {
    name: 'PrescriptionOwner',
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        showError: false,
        missing: false,
        isValidationFired: false,
        invalidFirst: false,
        invalidLast: false,
        help: '<p style="font-weight: bold; font-size: 14px">Why is this needed?</p>'
    }),
    computed: {
        ...mapState(['reset']),
        ...mapGetters('values', ['values']),
        ...mapGetters('configurator/steps', ['getStepObject']),
        ...mapGetters(['getErrors']),
        step () {
            return this.getStepObject(this.id)
        },
        isStepCurrent () {
            return this.step.isCurrent
        },
        name () {
            return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : ''
        },
        firstName: {
            get: function () {
                return this ? this.$store.getters['prescriptions/firstName'] : ''
            },
            set: function (name) {
                this.$store.commit('prescriptions/UPDATE_FIRST_NAME', name)
            }
        },
        lastName: {
            get: function () {
                return this ? this.$store.getters['prescriptions/lastName'] : ''
            },
            set: function (name) {
                this.$store.commit('prescriptions/UPDATE_LAST_NAME', name)
            }
        }
    },
    watch: {
        showError () {
            this.getError()
        },
        reset () {
            this.$store.commit('prescriptions/UPDATE_FIRST_NAME', '')
            this.$store.commit('prescriptions/UPDATE_LAST_NAME', '')
        },
        name (name) {
            this._updateValue(name)
        },
        isStepCurrent (current) {
            if (current) {
                this._updateValue(this.name)
            }
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
    },
    created () {
        this.$bus.$on(MESSAGE_VALIDATE_RX_OWNER, this._validateUpload)
    },
    beforeDestroy () {
        this.$bus.$off(MESSAGE_VALIDATE_RX_OWNER, this._validateUpload)
    },
    methods: {
        ...mapMutations(['updateErrors']),
        validate (resolve, reject) {
            if (this.isValidForm()) {
                resolve()
            } else {
                reject()
            }
        },
        isValidForm () {
            this.hasValue()
            return this.missing
        },
        hasValue () {
            if (!this.firstName) {
                this.missing = false
                this.invalidFirst = true
            } else if (!this.lastName) {
                this.missing = false
                this.invalidLast = true
            }
            if (this.lastName && this.firstName) {
                this.missing = true
                this.invalidLast = false
                this.invalidFirst = false
            }
        },
        getError () {
            if (!this.firstName) {
                this.invalidFirst = true
            } else {
                this.invalidFirst = false
            }
            if (!this.lastName) {
                this.invalidLast = true
            } else {
                this.invalidLast = false
            }
        },
        _validateUpload (promise, resolve, reject) {
            this.isValidationFired = true
            this.showError = true
            this.validate(resolve, reject)
        },
        _updateValue (value) {
            this.$store.dispatch('configurator/steps/updateStep', {
                id: this.id,
                data: {
                    value
                }
            })
            this.$store.commit('values/setDataToValues', {[this.id]: value})
        }
    }
}
</script>

<style scoped>

</style>
