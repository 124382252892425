<template>
    <div v-if="showRedeem" :class="{redeemed: redeemedStyle}" class="plus-account_container glasses-container">
        <h4 v-if="isMobile" :class="{active: isShow}" @click="toggleContent">Rx Frame of Lens Replacement</h4>
        <transition name="slide">
            <div v-if="!isMobile || isShow && isMobile" class="plus-content_container glasses-content">
                <div class="redeem-description">
                    <div class="redeem-container" :style="redeemedStyle ? null : 'align-items: start;'">
                        <div>
                            <img src="/js/lensconfigurator/assets/glasses_desktop.svg" alt="contact lenses"/>
                        </div>
                        <div>
                            <span style="font-size: 1.1em; margin-right: .4em;">New Frames with Lenses</span>
                            <HelpBubble v-show="!redeemedStyle" :data="component_help.frames"/>
                            <span class="description" style="display: block">One (1) frame including prescriptions lenses</span>
                        </div>
                        <div class="secondary">
                            <BaseButton
                                v-if="redeemedStyle"
                                v-show="redeemedStyle"
                                :disabled="pending || isLensesRedeemed"
                                :text="btnTitle(redeemItemType)"
                                :class="{disabledButton: isLensesRedeemed}"
                                data-size="xs"
                                class="button button--copy-solid ladda-button"
                                @click.native="openFrameRedeem"
                            />
                            <BaseButton
                                v-else
                                v-show="!redeemedStyle"
                                :disabled="!paidUp || usedExtraAllowance"
                                :text="btnTitle(redeemItemType)"
                                :class="{disable: !previousvisit}"
                                data-size="xs"
                                class="button button--copy-solid"
                                @click.native="openFrameRedeem"
                            />
                        </div>
                    </div>
                </div>
                <label v-if="showHasExtraAllowance" class="decline-benefit"
                       style="width: fit-content;">
                    <input id="extra-allowance" title="extra-allowance" type="radio" name="extra-allowance"
                           :checked="usedExtraAllowance" @click="updateAllowance"/>
                    <span class="extra-allowance-container">
                        <div class="extra-allowance-label">
                            <span>Decline this benefit and get an extra ${{getVoucherAmount}} added to your contact lenses</span>
                        </div>
                    </span>
                </label>
            </div>
        </transition>
        <div v-show="!isContactsInPlan" class="desktop-notification-card-top-redeem">
            <svg width="350px" height="25px" viewBox="0 0 409 25" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 class="desktop-svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Lensabl+-Dashboard-0-Welcome-2" transform="translate(-1642.000000, -900.000000)"
                       fill="#9BDBDA" fill-rule="nonzero">
                        <path id="Line-5"
                              d="M1666,900.5 L1666,910.5 L2051,910.5 L2051,914.5 L1666,914.5 L1666,924.5 L1642,912.5 L1666,900.5 Z"/>
                    </g>
                </g>
            </svg>
            <svg width="350px" height="50px" viewBox="0 0 408 50" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 class="tablet-svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Lensabl+-Dashboard-0-Welcome-1" transform="translate(-1741.000000, -1114.000000)"
                       fill="#9BDBDA" fill-rule="nonzero">
                        <path id="Path-2"
                              d="M2147.42073,1160.18616 C2148.42248,1160.65151 2148.85732,1161.84084 2148.39197,1162.8426 C2147.92661,1163.84436 2146.73728,1164.2792 2145.73552,1163.81384 C2080.40435,1133.46493 2003.33382,1118.57493 1926.60961,1118.89802 C1860.01194,1119.17847 1798.7846,1131.24718 1762.12437,1150.36389 L1767.60992,1158.43017 L1741.01562,1162 L1754.11633,1138.58267 L1759.86075,1147.03397 C1797.12034,1127.45734 1859.16268,1115.18201 1926.59277,1114.89806 C2003.87546,1114.57262 2081.52311,1129.57411 2147.42073,1160.18616 Z"/>
                    </g>
                </g>
            </svg>
            <p class="animate-notification-msg">To use a reward, click the "Redeem" button.</p>
        </div>

        <Redeem
            :subtitle="'Glasses'"
            :requirements="requirements"
            :instruction="instruction"
            :is-modal-open="isFramesOpen"
            @continue="continueToFrames"
            @update="isFramesOpen = false"
        />
        <GiftCreditRedeem
            :is-modal-open="isGiftCardOpen"
            :collect="collect"
            :redeem-item-type="redeemItemType"
            @update="isGiftCardOpen = false"
            @continue="pendingBenefitCredit"
        />
        <Loader v-if="loadingBlock"/>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import BaseButton from '../../../components/base/BaseButton.vue'
import Redeem from './Redeem.vue'
import AbstractRedeem from './AbstractRedeem'
import GiftCreditRedeem from './GiftCreditRedeem.vue'
import {
    STATUS_PENDING,
    STATUS_APPROVED,
    STATUS_FINISHED
} from '../storage/lensablplus/customer'
import Loader from '../../loaders/Loader.vue'

export default {
    name: 'BusinessFramesRedeem',
    components: {
        BaseButton,
        HelpBubble,
        Redeem,
        GiftCreditRedeem,
        Loader
    },
    extends: AbstractRedeem,
    data: () => ({
        redeemItemType: 'business_frames',
        component_help: {
            frames: {
                name: 'help-bubble',
                params: {
                    text: ''
                }
            }
        },
        isShow: false,
        isFramesOpen: false,
        lens_requirements: [
            'Select the lens options you want',
            'Confirm your details and checkout'
        ],
        requirements: [
            'Choose the style of frames you wish to get',
            'Select the lens options you want',
            'Confirm your details and checkout'
        ],
        instruction: 'You will need to follow the below steps to complete the redemption:',
        nonInstruction: 'Upload your receipt from your Non-Lensabl Frame or Lens Replacement Purchase:',
        isGiftCardOpen: false,
        redirectTo: false,
        loadingBlock: false
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablplus_customer', [
            'isContactsRedeemed',
            'getGiftcardConfigs',
            'isRedeemFlow',
            'isVoucherRedeemed',
            'defaultPrescriptionType',
            'isLensesRedeemed',
            'paidUp',
            'isContactsInPlan',
            'statusRedeemed',
            'getBtnTitlesById',
            'getRedeemedStyles',
            'getReceiptRewards',
            'getNonLensablPlus',
            'getHelpComponent',
            'getVoucherAmount'
        ]),
        ...mapGetters('storeView', ['base_url']),
        ...mapState('gsap', ['previousvisit']),
        ...mapState('lensablplus_customer', ['cookies_tag_business']),
        showHasExtraAllowance() {
            return !this.isContactsRedeemed &&
                !((this.isLensesRedeemed && !this.isVoucherRedeemed) || (!this.isLensesRedeemed && this.isVoucherRedeemed))
        },
        usedExtraAllowance() {
            return this.isLensesRedeemed && this.isVoucherRedeemed
        },
        receiptRewards() {
            return this.getReceiptRewards(this.redeemItemType)
        },
        redeemedStyle() {
            return this.isLensesRedeemed || this.getRedeemedStyles(this.statusRedeemed(this.redeemItemType)) || this.pending || this.collect
        },
        btnTitle() {
            return type => {
                if (this.isLensesRedeemed) return 'REDEEMED'
                if (this.pending) return 'PENDING REVIEW'
                if (!this.paidUp) return 'SUSPEND'
                if (this.collect) return 'COLLECT REWARD'
                const statusId = this.statusRedeemed(type)
                return (this.getBtnTitlesById(statusId) || '').toUpperCase()
            }
        },
        pending(){
            return this.receiptRewards ? this.receiptRewards.status === STATUS_PENDING : false
        },
        collect(){
            return this.receiptRewards ? this.receiptRewards.status === STATUS_APPROVED : false
        }
    },
    created() {
        this.redirectTo = this.receiptRewards ? this.receiptRewards.status === STATUS_FINISHED : false
        this.component_help.frames.params.text = '<p>Any frame of your choice from our Everyday Eyewear collection. Includes single vision polycarbonate lenses with Blue-Light blocking, Anti-Reflection, Anti-Scratch, and 100% UV protection coatings. All lenses and coatings are made by Essilor.</p><p>* High-index (thin), Transitions, Sunglass lens and other options available for extra charge.</p>'
    },
    methods: {
        toggleContent() {
            if (this.isMobile) {
                this.isShow = !this.isShow
            }
        },
        continueToFrames() {
            const link = this.base_url + 'plus/shop-frames/'
            this.continueToNextPage(link, 'frames')
        },
        openFrameRedeem() {
            if (this.redirectTo) {
                this.createRewardTremendous({
                    value: this.getGiftcardConfigs(this.redeemItemType).tremendous_value,
                    type: this.redeemItemType
                })
            } else if (this.collect) {
                this.isGiftCardOpen = !this.isGiftCardOpen
            } else if (!this.isLensesRedeemed && this.previousvisit && !this.collect) {
                this.isFramesOpen = !this.isFramesOpen
            }
        },
        pendingBenefitCredit() {
            this.pending = !this.pending
        },
        updateAllowance() {
            this.loadingBlock = true
            const status = !this.usedExtraAllowance
            this.$store.dispatch('lensablplus_customer/sentForRedeemed', {
                type: ['business_voucher', 'business_frames'],
                status: status
            })
                .then(() => this.loadingBlock = false)
        }
    }
}
</script>

<style scoped>
.plus-account_container .loader-2.center {
    z-index: 9999;
    width: 100%;
    height: 100%;
}
</style>
