import getHelper from '../helper'
import {
    LENS_OPTIONS_CODE,
    LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167,
} from '../lens_options'
import {
    LENS_TYPE_ID,
    LENS_TYPE_SECTION_ID
} from '../../../../../app/common/constants/lens_type'
import {defaultOptions, framesTypeCustomize} from "./default";


export const LIMITERS_DEFAULT_PPE = {
    lens_options_enable_by_lens_type: {
        progressive_standard: {
            'Sun Polarized': [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167]
        },
        progressive_premium: {
            'Transition Polarized': [LENS_OPTION_CODE_POLY]
        },
    },
    enabled_colors: {
        progressive_standard: {
            'Sun Polarized': ['polarized-brown', 'polarized-grey', 'polarized-g15']
        },
    },
    anti_reflective_enable: {
        single_vision_distance_premium: {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'single_vision_distance': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'single_vision_reading_premium': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'single_vision_reading': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'progressive_standard': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR']},
        'progressive_premium': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'plano_lens': {
            'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR']
        },
    }
}

export default function getPPEStepList(params) {
    const defaultStepOptions = defaultOptions(params)
    const {product_config} = params
    const {DEFAULT_LAST_STEP} = getHelper(params)


    const lens_type = {
        'id': LENS_TYPE_ID,
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['computer_lens', 'sunglass_lens'],
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensType.computerLens,
                defaultStepOptions.lensType.blueLightLens,
                defaultStepOptions.lensType.transitionLens,
                defaultStepOptions.lensType.sunglassesLens,
            ]
        }
    }

    const frames_type = {
        'id': 'frames_type',
        'label': 'Choose Lenses',
        'summary_label': 'Choose Lenses',
        'no_summary': true,
        'value_renderer': 'renderer-options',
        'default_value': 'frames_customize',
        'component': {
            'name': 'selector-options',
            'options': [
                framesTypeCustomize
            ]
        }
    }

    const lens_options = {
        'id': LENS_OPTIONS_CODE,
        'label': 'Select Lens Material',
        'summary_label': 'Lens Option',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['sunglass_lens', 'transition_lens'],
        'always_visible': true,
        'component': {
            'name': 'selector-options-lens-option',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensOptions.polyLensOption,
                defaultStepOptions.lensOptions.hi167LensOption,
            ]
        }
    }

    const single_vision_distance = Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP})
    const single_vision_distance_premium = Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP})
    const single_vision_near = Object.assign({'id': 'single_vision_reading'}, {...DEFAULT_LAST_STEP})
    const single_vision_near_premium = Object.assign({'id': 'single_vision_reading_premium'}, {...DEFAULT_LAST_STEP})
    const progressive_standard = Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP})
    const progressive_premium = Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP})

    const step_list = [
        defaultStepOptions.notesOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.chooseLens.prescriptionType,
                    defaultStepOptions.chooseLens.nonPrescriptionType
                ]
            }
        },
        {
            'id': 'nonprescription',
            'label': 'Choose Non-Prescription',
            'summary_label': 'Non-Prescription',
            'value_renderer': 'renderer-options',
            'default_value': 'plano',
            'always_visible': true,
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.nonPrescriptionTypes.planoNonPrescription,
                    defaultStepOptions.nonPrescriptionTypes.sunglassesNonPrescription
                ]
            }
        },
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription
                ]
            }
        },
        frames_type,
        single_vision_distance,
        single_vision_distance_premium,
        single_vision_near,
        single_vision_near_premium,
        progressive_standard,
        progressive_premium,
        lens_type,
        lens_options
    ]
    return step_list
}
