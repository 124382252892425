<template>
    <div>
        <span v-dompurify-html="renderer" class="option-value"></span>
        <RendererUpgrades :step="step" :place="place" :class="'upgrades'" />
        <RendererWarranty :step="step" :place="place" :class="'warranty'" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
    ANTIREFLECTIVE_STEP
} from '../../../app/common/constants/antireflective'
import _find from 'lodash/find'
import RendererOptions from '../options/RendererOptions.vue'
import RendererUpgrades from '../upgrades/RendererUpgrades'
import RendererWarranty from '../warranty/RendererWarranty'

export default {
    name: 'RendererAntireflective',
    components: {
        RendererUpgrades,
        RendererWarranty
    },
    extends: RendererOptions,
    computed: {
        ...mapGetters('values', ['antiReflective', 'antiReflectiveSymbol']),
        renderer () {
            let title = ''
            if (!this.step.isCurrent && !this.step.isReady) {
                return title
            }
            if (!this.antiReflective) {
                return title
            }
            const configs = [...ANTIREFLECTIVE_STEP]
            const aliasObject = _find(configs, {'value': this.antiReflectiveSymbol})
            if (!aliasObject) {
                return title
            }

            title = aliasObject.hasOwnProperty('alias') ? aliasObject.alias : ''

            return title
        }
    }
}
</script>
