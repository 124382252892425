<template>
    <div class="swatch-attribute color">
        <div v-if="attributeTitle" class="swatch-attribute-title" v-text="attributeTitle"></div>
        <ul class="swatch-attribute-options">
            <template v-for="(item, index) in colors">
                <li :key="item.color" class="swatch-option color">
                    <span
                        :class="{[optionItemClass]: optionItemClass, [item.color.toLowerCase()]: item.color, 'selected': checkedIndex.includes(index)}"
                        :title="item.color"
                        :style="{ 'background-image': getBackgroundImage(item.color)}"
                        @click="$emit(EVENT_CHANGE_CURRENT_COLOR, index)"
                    >{{ item.color }}</span>
                    <p v-if="addLabel" style="color: #989898;font-size: 12px;">{{ getName(item.color) }}</p>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>

import ColorSwatches from './ColorSwatches.vue'

export default {
    name: 'ColorSwatchesMulti',
    extends: ColorSwatches,
    props: {
        checkedIndex: {
            type: [Object, Array],
            default: () => {}
        }
    }
}
</script>
