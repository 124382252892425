import { render, staticRenderFns } from "./ProductList.vue?vue&type=template&id=9b8b3678&scoped=true&"
import script from "./ProductList.vue?vue&type=script&lang=js&"
export * from "./ProductList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b8b3678",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/tkim/projects/lensabl/lensconfigurator/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9b8b3678')) {
      api.createRecord('9b8b3678', component.options)
    } else {
      api.reload('9b8b3678', component.options)
    }
    module.hot.accept("./ProductList.vue?vue&type=template&id=9b8b3678&scoped=true&", function () {
      api.rerender('9b8b3678', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/catalog/category/Goodr/ProductList.vue"
export default component.exports