import axios from 'axios'

const CONTROLLER = 'opternative'

export default () => ({
    namespaced: true,
    state: () => ({
        type: '',
        birthdate: '',
        value: '',
        region: '',
        regions: [],
        sign_in: false,
        loading: false,
        qualifications: false,
        email: '',
        prescriptions: [],
        products: [],
        show_nav: true,
        error: false,
        valid: false,
        productsValue: {}
    }),
    getters: {
        REGION: state => state.region,
        REGIONS: state => state.regions,
        SIGN_IN: state => state.sign_in,
        EMAIL: state => state.email,
        TYPE: state => state.type,
        BIRTHDAY: state => state.birthdate,
        PRESCRIPTIONS: state => state.prescriptions,
        PRODUCTS: state => state.products,
        QUALIFICATIONS: state => state.qualifications,
        LOADING: state => state.loading,
        SHOW_NAV: state => state.show_nav,
        ERROR: state => state.error,
        VALID: state => state.valid,
        getProductValue: state => state.productsValue
    },
    mutations: {
        SET_REGIONS: (state, payload) => state.regions = payload,
        SET_REGION: (state, payload) => state.region = payload,
        SET_SIGN_IN: (state, payload) => state.sign_in = payload,
        SET_EMAIL: (state, payload) => state.email = payload,
        SET_TYPE: (state, payload) => state.type = payload,
        SET_BIRTHDAY: (state, payload) => state.birthdate = payload,
        SET_PRESCRIPTIONS: (state, payload) => state.prescriptions = payload,
        SET_PRODUCTS: (state, payload) => state.products = payload,
        SET_QUALIFICATIONS: (state, payload) => state.qualifications = payload,
        SET_ERROR: (state, payload) => state.error = payload,
        SET_VALID: (state, payload) => state.valid = payload,
        SET_LOADING: (state, payload) => state.loading = payload,
        SET_SHOW_NAV: (state, payload) => state.show_nav = payload,
        SET_PRODUCT_VALUE:  (state, payload={}) => state.productsValue = payload,
    },
    actions: {
        getProductValue: ({commit, rootGetters}) => {
            const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/index/getExamProduct`
            axios.get(url).then(({data, status}) => {
                if (status === 200) {
                    commit('SET_PRODUCT_VALUE', data)
                }
            }).catch(error => {
                console.error(error)

            })
        },
        GET_REGIONS: ({getters, commit, rootGetters}) => {
            commit('SET_LOADING', false)
            const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/index/getRegions?code=${rootGetters['storeView/country_code']}`
            axios.get(url).then(({data, status}) => {
                if (status === 200) {
                    commit('SET_REGIONS', data)
                    commit('SET_LOADING', true)
                }
            }).catch(error => {
                console.error(error)
                commit('SET_LOADING', true)
            })
        },
        CHECK_SIGN_IN: ({commit, rootGetters, dispatch}) => {
            const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/index/isCustomerAuth`
            axios.get(url).then(({data, status}) => {
                if (status === 200) {
                    commit('SET_SIGN_IN', data.sign_in)
                    if (data.sign_in) {
                        dispatch('getCustomerData')
                    } else {
                        commit('SET_LOADING', true)
                    }
                }
            }).catch(error => {
                console.error(error)
                commit('SET_LOADING', true)
            })
        },
        getCustomerData: ({commit, rootGetters}) => {
            const urlCustomerData = `${rootGetters['storeView/base_url']}${CONTROLLER}/index/getCustomerData`
            axios.get(urlCustomerData).then(({data, status}) => {
                if (status === 200) {
                    if (data.email) {
                        commit('SET_EMAIL', data.email)
                    }
                    if (data.region) {
                        commit('SET_REGION', data.region)
                    }
                    if (data.birthday) {
                        commit('SET_BIRTHDAY', data.birthday)
                    }
                    commit('SET_LOADING', true)
                }
            })
        },
        GET_PRODUCTS: ({commit, rootGetters}) => {
            commit('SET_LOADING', false)
            const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/index/getContactsPrescriptions`
            axios.get(url).then(({data, status}) => {
                if (status === 200) {
                    commit('SET_PRODUCTS', data.products)
                    commit('SET_LOADING', true)
                } else {
                    commit('SET_LOADING', true)
                }
            }).catch(error => {
                console.error(error)
                commit('SET_LOADING', true)
            })
        },
        GET_QUALIFICATIONS: ({getters, commit, rootGetters}) => {
            const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/api/qualifications`
            const params = {
                user: {
                    birthdate: getters.BIRTHDAY,
                    region: getters.REGION
                }
            }
            if (getters.PRESCRIPTIONS.length) {
                const prescriptions = getters.PRESCRIPTIONS
                for (const [, v] of Object.entries(prescriptions)) {
                    for (const [k2, v2] of Object.entries(v)) {
                        if (k2 === 'left_eye' || k2 === 'right_eye') {
                            for (const [k3, v3] of Object.entries(v2)) {
                                if (k3 === 'sphere' || k3 === 'cylinder' || k3 === 'axis' || k3 === 'add') {
                                    let tempV = v3.toString().replace('PL/', '')
                                    tempV = tempV.replace('SPH/Blank/', '')
                                    tempV = tempV.replace('blank/', '')
                                    v2[k3] = tempV
                                }
                            }
                        }
                    }
                }
                params['prescriptions'] = prescriptions
            }
            axios.post(url, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(({data, status}) => {
                if (status === 200) {
                    if (getters.PRESCRIPTIONS.length === 0) {
                        commit('SET_QUALIFICATIONS', false)
                        commit('SET_LOADING', true)
                    } else {
                        commit('SET_QUALIFICATIONS', data.qualifies)
                        commit('SET_LOADING', true)
                    }
                } else {
                    commit('SET_LOADING', true)
                }
            }).catch(error => {
                console.error(error)
                commit('SET_LOADING', true)
            })
        },
        ADD_TO_CART: ({getters, commit, rootGetters}) => {
            commit('SET_LOADING', false)
            const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/index/addProductToCart`
            const params = {
                type: getters.TYPE,
                email: getters.EMAIL,
                birthdate: getters.BIRTHDAY,
                region: getters.REGION,
                prescriptions: getters.PRESCRIPTIONS
            }
            if (rootGetters['lensablplus_customer/membershipId'] && rootGetters['lensablplus_customer/isRedeemFlow']) {
                const prefix = rootGetters['lensablplus_customer/getPrefixRedeem']
                params['plus_item'] = {
                    'membership_id': rootGetters['lensablplus_customer/membershipId'],
                    'plus_price': 0,
                    'types': [prefix + 'exam']
                }
            }

            if (rootGetters['membershipProgram/hasCustomerMembership']) {
                commit('membershipProgram/SET_ONLINE_EXAM', true, {root: true})
                const sku = getters.getProductValue[getters.TYPE].sku
                const total = getters.getProductValue[getters.TYPE].price
                const discountMembership = rootGetters['membershipProgram/getDiscountExam']({total})
                params['member_program'] = {
                    [sku]: discountMembership
                }
            }

            axios.post(url, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(({data, status}) => {
                if (status === 200) {
                    commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', false, {root: true})
                    document.location = data.redirect_url
                } else {
                    commit('SET_LOADING', true)
                }
                commit('membershipProgram/SET_ONLINE_EXAM', false, {root: true})
            }).catch(error => {
                console.error(error)
                commit('SET_LOADING', true)
                commit('membershipProgram/SET_ONLINE_EXAM', false, {root: true})
            })
        }
    }
})
