var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "membership-page-create" },
    [
      _c("ContentHeader", { on: { save: _vm.saveMembership } }),
      _vm._v(" "),
      _c(
        "v-flex",
        [
          _c(
            "MembershipAbstract",
            {
              staticClass: "account-information",
              attrs: { "header-text": "Account Information" }
            },
            [
              _c(
                "BlockLoader",
                { attrs: { loading: !_vm.loaded } },
                [_c("MembershipFormAccount")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.customer.isPlusCustomer,
              expression: "!customer.isPlusCustomer"
            }
          ]
        },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-col",
                [
                  _c(
                    "MembershipAbstract",
                    { attrs: { "header-text": "Membership" } },
                    [
                      _c(
                        "BlockLoader",
                        { attrs: { loading: !_vm.loaded } },
                        [_c("MembershipData")],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.selectedPlan && _vm.selectedPlan.has_contacts
                    ? _c(
                        "MembershipAbstract",
                        { attrs: { "header-text": "Contact Lens Benefit" } },
                        [
                          _c(
                            "BlockLoader",
                            { attrs: { loading: !_vm.loaded } },
                            [_c("MembershipContactLens")],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "MembershipAbstract",
                    { attrs: { "header-text": "Coupon Code" } },
                    [
                      _c(
                        "BlockLoader",
                        { attrs: { loading: !_vm.loaded } },
                        [_c("CouponCode")],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "MembershipAbstract",
                    { attrs: { "header-text": "Payment Method" } },
                    [
                      _c(
                        "BlockLoader",
                        { attrs: { loading: !_vm.loaded } },
                        [_c("MembershipPayment")],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.eyeExamShow
                    ? _c(
                        "MembershipAbstract",
                        { attrs: { "header-text": "Eye Exam Benefit" } },
                        [
                          _c(
                            "BlockLoader",
                            { attrs: { loading: !_vm.loaded } },
                            [
                              _c("MembershipEyeExam", {
                                attrs: {
                                  "redeem-item-type": _vm.getRedeemTypeWithPrefix(
                                    "exam"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "MembershipAbstract",
                    { attrs: { "header-text": "Billing Address" } },
                    [
                      _c(
                        "BlockLoader",
                        { attrs: { loading: !_vm.loaded } },
                        [_c("MembershipFormAddress")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("TotalsPrice"),
      _vm._v(" "),
      _vm.saveLoader ? _c("BaseLoader") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }