var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.memberShipEdited
    ? _c(
        "div",
        [
          _c("div", [
            _c(
              "table",
              { staticClass: "form-list", attrs: { cellspacing: "0" } },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "label" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("Everyday Eyewear Frame Redeemed?")
                      ]),
                      _vm._v(" "),
                      _c("strong", {
                        staticClass: "value",
                        domProps: { textContent: _vm._s(_vm.frameItemRedeemed) }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "label" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("Lens Replacement Benefit Used?")
                      ]),
                      _vm._v(" "),
                      _c("strong", {
                        staticClass: "value",
                        domProps: {
                          textContent: _vm._s(_vm.glassesItemRedeemed)
                        }
                      })
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _vm.messages.length
              ? _c(
                  "table",
                  {
                    staticClass: "form-list eye-exam",
                    attrs: { cellspacing: "0" }
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.messages, function(message, index) {
                        return _c(
                          "tr",
                          { key: "message-" + index, staticClass: "tr-line" },
                          [
                            _c("td", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: message.message,
                                  expression: "message.message"
                                }
                              ],
                              staticClass: "value"
                            }),
                            _vm._v(" "),
                            _c("td", { staticClass: "scope-label" }, [
                              _c("strong", {
                                domProps: {
                                  textContent: _vm._s(message.status)
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "scope-label" }, [
                              message.admin_firstname
                                ? _c("strong", {
                                    domProps: {
                                      textContent: _vm._s(
                                        "by " +
                                          message.admin_firstname +
                                          " " +
                                          message.admin_lastname
                                      )
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              message.admin_email
                                ? _c("small", {
                                    domProps: {
                                      textContent: _vm._s(
                                        "(" + message.admin_email + ")"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", {
                              staticClass: "scope-label",
                              domProps: {
                                textContent: _vm._s(message.created_at)
                              }
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.loader || !_vm.dataLoaded ? _c("BaseLoader") : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("History")]),
      _vm._v(" "),
      _c("th"),
      _vm._v(" "),
      _c("th"),
      _vm._v(" "),
      _c("th")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }