export const UPGRADES_INFO = {
    'label': 'Edges Options',
    'desc': 'For rimless glasses',
    'image': 'polish-edges-image.jpg'
}
export const UPGRADES_STEP = [
    {
        'price': 15,
        'desc': '',
        'checked': false,
        'value': 'polish-edges',
        'alias': 'Polish Edges',
        'image': false
    },
    {
        'price': 25,
        'desc': '',
        'checked': false,
        'value': 'polish-roll-edges',
        'alias': 'Polish & Roll Edges',
        'image': false
    }
]
