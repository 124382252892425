<template>
    <v-select
        :label="label"
        :rules="[v => !!v || errText]"
        :items="options"
        :value="formModel[formKey]"
        v-model="formModel[formKey]"
        item-text="label"
        item-value="value"
        height="54"
    />
</template>
<script>
export default {
    name: 'SelectField',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        formModel: {
            type: Object,
            default: () => {}
        },
        formKey:{
            type: String,
            default: 'company'
        },
        label: {
            type: String,
            default: 'Vision Insurance Company:'
        },
        errText: {
            type: String,
            default: 'Insurance Company Required'
        },
    }
}

</script>
