<template>
    <div class="category-frames-wrapper info-wrapper frames-wrapper">
        <div class="category-frames-included">
            <div class="lens-tab info-tab">
                <span>30 day risk free returns*</span>
            </div>
            <div class="lens-tab info-tab">
                <span>free standard shipping</span>
            </div>
            <div class="lens-tab info-tab">
                <span>12 month warranty included**</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsInformation'
}
</script>
