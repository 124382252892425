import {mapMutations, mapActions} from 'vuex'
import CustomSelect from '../../../../../base/Select.vue'
import RadioLabel from '../../../../../base/RadioLabel.vue'

export default {
    props: {
        item: {
            type: Object,
            default: {}
        },
        checked: {
            type: [Number, String, Boolean],
            default: ''
        },
        prescriptionsType: {
            type: String,
            default: ''
        },
        prescriptionIndex: {
            type: Number,
            default: 0
        },
        title: {
            type: [Boolean, String],
            default: ''
        }
    },
    components: {
        CustomSelect,
        RadioLabel
    },
    computed: {
        prescriptionData () {
            return this.item.prescriptions ? this.item.prescriptions[this.prescriptionsType] : {}
        },
        indexSave () {
            return this.item.order_id + '_' + this.item.item_id + '_' + this.prescriptionsType + this.prescriptionIndex
        },
        index () {
            const indexTitle = this.title.toLowerCase().split(' ').join('_')
            return indexTitle + this.indexSave
        },
        isActive () {
            return this.checked === this.index
        }
    },
    watch: {
        showError (show) {
            this.$emit('show-error', show)
        },
        isActive (active) {
            if (active) {
                this.validateData()
            }
        }
    },
    methods: {
        ...mapMutations('values', ['setDataToValues']),
        ...mapActions('prescriptions', ['setValueOptions']),
        validateData () {

        }
    }
}
