<template>
    <div v-if="isTrialExpiring" class="plus-account_container attention plusdiscount-container">
        <h4 v-if="isMobile" :class="{active: isShow}" @click="toggleContent">Your {{ isFreeString }} Lensabl+ trial expires in <b>30 days.</b></h4>
        <transition name="slide">
            <div v-if="!isMobile || isShow && isMobile" class="plus-content_container">
                <div></div>
                <div class="redeem-description">
                    <div class="description-attention">Your {{ isFreeString }} Lensabl+ trial expires in <b>{{ getDaysBeforeDeleting }} days.</b></div>
                    <span v-if="isFreeMembership" class="description">Sign up for an annual plan and get a FREE frame or lens replacement plus all the other great benefits for just
                        <span class="total-value subscribed"><b> ${{ toFixedPrice(membershipCost) }}</b></span> <span class="title-black"><b>${{ toFixedPrice(membershipCostWithDiscount) }}</b></span>
                    </span>
                    <span v-else class="description">Renew your membership now to make sure you don’t lose all of your great benefits. If you renew today, you get 15% off.</span>
                    <HelpBubble
                        :data="getHelpComponent['trial']"
                        :classes="getHelpComponent['trial']['classes']"
                        :target-classes="getHelpComponent['trial']['targetClasses']"
                        :container-classes="getHelpComponent['trial']['containerClasses']"
                        :placement="'bottom'"
                    />
                    <span class="description-disclaimer">*Your new plan will begin the day after your {{ isFreeString }} trial expires.</span>
                </div>
                <div class="secondary">
                    <BaseButton :loader="buttonLoad" :text="btnText" data-size="xs" class="button button--copy-solid" @click.native="addPlanInCart" />
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import BaseButton from '../../../components/base/BaseButton.vue'
import _formatPrice from '../../../app/utils/formatPrice'

export default {
    name: 'TrialExpiring',
    components: {
        BaseButton,
        HelpBubble
    },
    data: () => ({
        isShow: false,
        buttonLoad: false
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablplus_customer', ['getHelpComponent', 'isBusinessMember', 'isFreeMembership', 'getDaysBeforeDeleting', 'getTrialExpiringData']),
        ...mapGetters('storeView', ['base_url']),
        btnText () {
            return 'RENEW'
        },
        isFreeString () {
            return this.isFreeMembership ? 'FREE' : ''
        },
        isTrialExpiring () {
            if (this.days === false || this.getDaysBeforeDeleting === null) {
                return false
            }
            return (!this.isBusinessMember && this.days >= this.getDaysBeforeDeleting) || false
        },
        membershipCost () {
            return this.getTrialExpiringData.membership_cost || 0
        },
        membershipCostWithDiscount () {
            return this.getTrialExpiringData.membership_cost_with_discount || 0
        },
        days () {
            return this.getTrialExpiringData.before_renew_days || false
        },
        toFixedPrice () {
            return (price) => _formatPrice(price)
        }
    },
    methods: {
        ...mapActions('lensablplus_customer', ['addRenewPlanCustomer', 'cleanCart']),
        toggleContent () {
            if (this.isMobile) {
                this.isShow = !this.isShow
            }
        },
        addPlanInCart: async function () {
            this.buttonLoad = true
            await this.$store.dispatch('lensablplus_customer/cleanCart')
            await this.$store.dispatch('lensablplus_customer/addRenewPlanCustomer')
            document.location = this.base_url + 'onepage'
        }
    }
}
</script>

<style scoped>

</style>
