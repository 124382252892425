var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("RadioLabel", {
        attrs: { active: _vm.isActive, label: _vm.title, value: _vm.index },
        on: { click: _vm.openFittingBox }
      }),
      _vm._v(" "),
      _vm.isActive &&
      (_vm.fittingbox_status === "taken" || _vm.fittingbox_status === "retaken")
        ? _c("div", { staticClass: "fittingbox-button-container" }, [
            _c("br"),
            _vm._v(
              "We have your previous photo which we will process soon.\n        "
            ),
            _c("br"),
            _vm._v(
              "You can retake them if you like, and we will use the newest set.\n        "
            ),
            _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "button button--copy-border-sm fittingbox-start-button",
                  on: { click: _vm.openFittingBox }
                },
                [_vm._v("Retake Photo")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }