var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "swatch-attribute color" }, [
    _vm.options.length > 1
      ? _c("div", [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentIndex,
                  expression: "currentIndex"
                }
              ],
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.currentIndex = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.changeOption
                ]
              }
            },
            [
              _vm._l(_vm.options, function(option, index) {
                return [
                  _c("option", { key: index, domProps: { value: index } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(option.drop_down_options) +
                        "\n                "
                    )
                  ])
                ]
              })
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }