<template>
    <div class="footer__bottom footer__bottom-onepage-desktop footer__bottom-onepage-plus">
        <div class="footer__row">
            <div class="footer_row_sections">
                <div class="footer-copyright">
                    <p><span>© Copyright lensabl 2022. All rights reserved. &nbsp; </span></p>
                </div>
                <div class="footer-payments">
                    <div>
                        <img class="lazyload" src="https://www.lensabl.com/media/wysiwyg/1280px-Paypal-logo-black.png" alt="" />
                        <img class="lazyload" src="https://www.lensabl.com/media/wysiwyg/mastercard-logo.png" alt="" />
                        <img class="lazyload" src="https://www.lensabl.com/media/wysiwyg/visa.png" alt="" />
                        <img class="lazyload" src="https://www.lensabl.com/media/wysiwyg/amazon-logo.png" alt="" />
                        <img class="lazyload" src="https://www.lensabl.com/media/wysiwyg/1280px-Discover_Card_logo.png" alt="" />
                        <img class="lazyload" src="https://www.lensabl.com/media/wysiwyg/AXP_BlueBoxLogo_EXTRALARGEscale_RGB_DIGITAL_1600x1600.png" alt="" />
                        <img src="https://www.lensabl.com/skin/frontend/lensabl/default/images/positivessl_trust_seal_sm_124x32.png" alt="Postive SSL Trust Seal" class="trust-logo" />
                    </div>
                </div>
                <div class="footer-privacy">
                    <span><a href="/insurance-providers/"><b>Use Your Linneo Benefits</b></a> | <a href="https://www.lensabl.com/terms/">Terms &amp; Conditions</a> | <a href="https://www.lensabl.com/privacy-policy/">Privacy Policy</a></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    components: {

    }
}
</script>
