<template>
    <div v-show="isCurrent" :class="id">
        <h2 v-if="config.title" v-dompurify-html="config.title" class="title"></h2>
        <h3 v-dompurify-html="config.description" class="description"></h3>

        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton
                    class="button--copy-solid-white-blue button--small"
                    text="Return to Lensabl Home"
                    @click.native="continueNxt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '../../base/BaseButton.vue'
import {mapGetters} from 'vuex'

export default {
    name: 'BusinessFinish',
    components: {
        BaseButton
    },
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    computed: {
        ...mapGetters('lensablplus_business', ['getObjectConfig', 'currentComponent']),
        config () {
            return this.getObjectConfig(this.id)
        },
        options () {
            return this.config.component.options
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        }
    },
    methods: {
        continueNxt () {
            window.location.href = '/'
        }
    }
}
</script>
