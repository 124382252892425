var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-container dropdown-container-main" },
    [
      _c(
        "div",
        { staticClass: "desktop-notification-card-top" },
        [
          _c(
            "h4",
            {
              class: { active: _vm.resultsVisible },
              on: { click: _vm.toggleSelected }
            },
            [
              _vm._v("\n            Notifications "),
              _c("span", { staticClass: "notification-number" }, [
                _vm._v(
                  _vm._s(
                    _vm.newMessages > 0 ? "(" + _vm.newMessages + " new)" : ""
                  )
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide" } }, [
            _vm.isDesktop ||
            (_vm.resultsVisible && _vm.isMobile) ||
            _vm.isTablet
              ? _c("div", { staticClass: "results-dropdown" }, [
                  !_vm.paidUp
                    ? _c("div", { staticClass: "lplus-notification" }, [
                        _c(
                          "a",
                          {
                            staticClass: "button",
                            attrs: { href: "mailto:support@lensabl.com" }
                          },
                          [_vm._v("Update Now")]
                        ),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Update your account to access benefits. Contact support@lensabl.com."
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "notification-list" },
                    [
                      _vm.notifications.length > 0
                        ? [
                            _vm._l(_vm.notifications, function(message, index) {
                              return [
                                message.is_read == 0
                                  ? _c("li", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "message-container" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "remove-container" },
                                            [
                                              _c("p", {
                                                directives: [
                                                  {
                                                    name: "dompurify-html",
                                                    rawName: "v-dompurify-html",
                                                    value: message.date,
                                                    expression: "message.date"
                                                  }
                                                ]
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "remove",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeNotification(
                                                        message.notification_id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("×")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", {
                                            directives: [
                                              {
                                                name: "dompurify-html",
                                                rawName: "v-dompurify-html",
                                                value: message.message,
                                                expression: "message.message"
                                              }
                                            ]
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "mobile-close",
                                        attrs: {
                                          src:
                                            "/js/lensconfigurator/assets/mobile-close.svg",
                                          alt: "mobile close sign"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeNotification(
                                              message.notification_id
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  : _vm._e()
                              ]
                            })
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.emptyNotifications
                        ? _c("li", { staticClass: "no-results" }, [
                            _c("span", [
                              _vm._v("You have no notifications at this time")
                            ])
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }