/**
 * Components Ids
 */
export const COMPONENT_ID_COMPANY_DETAILS = 'company-details'
export const COMPONENT_ID_PRIMARY_CONTACT = 'primary-contact'
export const COMPONENT_ID_PLAN_OPTIONS = 'business-plans'
export const COMPONENT_ID_PAYMENT_INFORMATION = 'payment-information'
export const COMPONENT_ID_FINISH = 'business-finish'

export const getObjectList = () => {
    return [
        {
            id: COMPONENT_ID_COMPANY_DETAILS,
            title: 'Enter Company Details',
            default_value: null,
            component: {
                name: COMPONENT_ID_COMPANY_DETAILS
            }
        },
        {
            id: COMPONENT_ID_PRIMARY_CONTACT,
            title: 'Enter Primary Contact',
            default_value: null,
            component: {
                name: COMPONENT_ID_PRIMARY_CONTACT
            }
        },
        {
            id: COMPONENT_ID_PLAN_OPTIONS,
            title: 'Plan Options',
            default_value: null,
            component: {
                name: COMPONENT_ID_PLAN_OPTIONS
            }
        },
        {
            id: COMPONENT_ID_PAYMENT_INFORMATION,
            title: 'Payment Information',
            default_value: null,
            component: {
                name: COMPONENT_ID_PAYMENT_INFORMATION
            }
        },
        {
            id: COMPONENT_ID_FINISH,
            title: 'Welcome to Lensabl+',
            description: 'Thank you for signing up to Lensabl+. An email will be sent to you with further ' +
                'instructions to complete setup.',
            default_value: null,
            component: {
                name: COMPONENT_ID_FINISH
            }
        }
    ]
}
