<template>
    <div>
        <div v-if="newAddress || !savedAddress" class="subscription-billing-inputs">
            <div class="telephone-form billing-form-address__col half-width last">
                <input id="recurly-telephone" v-model="telephone" data-recurly="phone" type="tel" name="billing[telephone]" placeholder="Phone Number (Exclude Dashes)" value="" class="subscription-input input-text validate-length maximum-length-18 minimum-length-10 validate-digits" data-hj-whitelist @blur="validate_field('recurly-telephone')" />
            </div>
            <div class="billing-form-address__first-name billing-form-address__col half-width input-firstname">
                <input id="recurly-first_name" v-model="first_name" data-recurly="first_name" class="subscription-input required-entry input-text" type="text" name="billing[firstname]" placeholder="First Name" value="" data-hj-whitelist @blur="validate_field('recurly-first_name')" />
            </div>
            <div class="billing-form-address__last-name billing-form-address__col half-width last  input-lastname">
                <input id="recurly-last_name" v-model="last_name" data-recurly="last_name" class="subscription-input required-entry input-text" type="text" name="billing[lastname]" placeholder="Last Name" value="" data-hj-whitelist @blur="validate_field('recurly-last_name')" />
            </div>
            <div class="billing-form-address__col input-box input-address twothirds-width">
                <input id="recurly-address1" v-model="address1" data-recurly="address1" class="subscription-input required-entry input-text" type="text" name="billing[street]" placeholder="Address 1" value="" data-hj-whitelist @blur="validate_field('recurly-address1')" />
            </div>
            <div class="billing-form-address__col input-box input-address onethird-width onethird-width-unit last">
                <input id="recurly-address2" v-model="address2" data-recurly="address2" class="subscription-input input-text" type="text" name="billing[street]" placeholder="Address 2" value="" data-hj-whitelist @blur="validate_field('recurly-address2')" />
            </div>
            <div class="billing-form-address__col onethird-width input-box input-city">
                <input id="recurly-city" v-model="city" data-recurly="city" class="subscription-input required-entry input-text" type="text" name="billing[city]" placeholder="City" value="" data-hj-whitelist @blur="validate_field('recurly-city')" />
            </div>
            <div class="billing-form-address__col onethird-width osc-short input-box middle input-region input-region_id">
                <select id="recurly-state" v-model="state" data-recurly="state" class="subscription-input validate-select" @change="validate_field('recurly-state')">
                    <option value="" disabled selected>State</option>
                    <option v-for="(regionId, stateName) in states" :key="`recurly-state-${regionId}`" :value="regionId">{{ stateName }}</option>
                </select>
            </div>
            <div class="billing-form-address__col onethird-width input-box input-country">
                <select id="recurly-country" v-model="country" name="billing[country_id]" class="subscription-input validate-select" data-recurly="country" title="Country" @change="selectCountry($event)">
                    <option value="" disabled selected>Country</option>
                    <option value="CA">Canada</option>
                    <option value="US" selected="selected">United States</option>
                </select>
            </div>
            <div class="billing-form-address__col onethird-width last input-postcode">
                <input id="recurly-postal_code" v-model="postal_code" data-recurly="postal_code" type="text" placeholder="Zipcode" name="billing[postcode]" value="" class="validate-zip-international required-entry subscription-input input-text" @blur="validate_field('recurly-postal_code')" />
            </div>
        </div>
        <div v-if="addressType === 'shipping'" class="subscription-billing-button">
            <BaseButton
                :loader="updatingAddress"
                :text="buttonText"
                :disabled="updatingAddress"
                class="button button--copy-icon account"
                @click.native="submitAddressForm(addressType)"
            />
        </div>

        <div v-if="addCard">
            <h5>Card Information</h5>
            <div class="subscription-recurly-cc">
                <div class="subscription-recurly-cc-input">
                    <div class="subscription-input" data-recurly="card"></div>
                    <input type="hidden" name="recurly-token" data-recurly="token" />
                </div>
                <div class="subscription-recurly-cc-save">
                    <BaseButton
                        :loader="updatingAddress"
                        :text="buttonText"
                        :disabled="updatingAddress"
                        class="button button--copy-icon account"
                        @click.native="submitAddressForm(addressType)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
    US_STATE_CODES,
    CA_STATE_CODES
} from '../../../app/common/constants/region_codes'
import BaseButton from '../../base/BaseButton.vue'
import getSubscriptionService from '../../../app/utils/subscription-service'
import axios from 'axios'

export default {
    name: 'RecurlyAddressForm',
    components: {
        BaseButton
    },
    props: {
        addressType: {
            type: [String],
            default: ''
        },
        addCard: {
            type: [String, Boolean],
            default: false
        },
        shippingAddress: {
            type: [Array, Object, Boolean],
            default: false
        },
        accountId: {
            type: [String, Number],
            default: ''
        }
    },
    data: () => {
        return {
            newAddress: false,
            hasSavedAddresses: true,
            states: US_STATE_CODES,
            first_name: '',
            last_name: '',
            address1: '',
            address2: '',
            country: '',
            city: '',
            state: '',
            postal_code: '',
            telephone: '',
            updatingAddress: false,
            buttonText: 'Save'
        }
    },
    computed: {
        ...mapGetters(['billing_address', 'saved_billing_address', 'shipping_address', 'saved_shipping_address', 'recurly_auth_api_key', 'base_url']),
        savedAddress () {
            if (this.addressType === 'billing') {
                return this.billing_address
            }
            return this.shipping_address
        }
    },
    watch: {
        savedAddress: {
            handler (data) {
                this.hasSavedAddresses = data
            },
            deep: true
        }
    },
    mounted () {
        window.recurly.configure('ewr1-boA5fCVvhJgjXwCaJxWT7s')
        const el = this.addressType === 'billing' ? document.getElementById('billing-address-select') : document.getElementById('shipping-address-select')
        el.addEventListener('change', this.selectBilling)
    },
    methods: {
        validate_field (id) {
            return Validation.validate(id)
        },
        validate_form (inputs) {
            let isValid = true
            if (inputs.length > 0) {
                inputs.forEach(input => {
                    const id = input.id
                    const validated = this.validate_field(id)
                    if (!validated) {
                        isValid = false
                    }
                })
            }
            return isValid
        },
        selectCountry (event) {
            Validation.validate('recurly-country')
            const value = event.target.value
            if (value === 'CA') {
                this.states = CA_STATE_CODES
            } else {
                this.states = US_STATE_CODES
            }
        },
        selectBilling (e) {
            if (e.target.value === '') {
                this.newAddress = true
            } else {
                this.newAddress = false
            }
        },
        submitAddressForm (type) {
            this.updatingAddress = true
            const inputs = document.querySelectorAll('.subscription-input')
            const id = document.querySelector('.address-select').value
            const addressFields = type === 'billing' ? this.saved_billing_address : this.saved_shipping_address
            const field = addressFields.find(property => property.id === id)
            const splitAddress = field ? (field.street).split('\n') : ''
            let recurlyInfo
            let magentoInfo

            inputs.forEach(input => {
                input.classList.remove('error')
            })

            if (id) {
                recurlyInfo = { // fields taken from saved address in magento
                    first_name: field.firstname,
                    last_name: field.lastname,
                    city: field.city,
                    postal_code: field.postcode,
                    address1: splitAddress[0],
                    address2: splitAddress[1] === undefined ? '' : splitAddress[1],
                    state: field.state,
                    country: field.country_id,
                    telephone: field.telephone
                }
            } else { // fields taken from new address form
                recurlyInfo = {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    address1: this.address1,
                    address2: this.address2,
                    country: this.country,
                    city: this.city,
                    state: this.state,
                    postal_code: this.postal_code,
                    telephone: this.telephone
                }

                magentoInfo = {
                    address: {
                        firstname: this.first_name,
                        lastname: this.last_name,
                        street: [this.address1, this.address2],
                        country_id: this.country,
                        city: this.city,
                        region_id: this.state,
                        postcode: this.postal_code,
                        telephone: this.telephone
                    }
                }
            }

            if (type === 'billing') {
                this.updateBilling(recurlyInfo, magentoInfo)
            } else {
                this.updateShipping(recurlyInfo, magentoInfo)
            }
        },
        updateShipping (recurlyInfo, magentoInfo) {
            const inputs = document.querySelectorAll('.subscription-input')

            const shippingXml = `
                    <shipping_address>
                      <first_name>` + recurlyInfo.first_name + `</first_name>
                      <last_name>` + recurlyInfo.last_name + `</last_name>
                      <phone>` + recurlyInfo.telephone + `</phone>
                      <address1>` + recurlyInfo.address1 + `</address1>
                      <address2>` + recurlyInfo.address2 + `</address2>
                      <city>` + recurlyInfo.city + `</city>
                      <state>` + recurlyInfo.state + `</state>
                      <zip>` + recurlyInfo.postal_code + `</zip>
                      <country>` + recurlyInfo.country + `</country>
                    </shipping_address>`

            if (!this.validate_form(inputs)) {
                this.updatingAddress = false
                return
            }

            this.updateAddressInRecurly(shippingXml, magentoInfo, 'shipping')
        },
        updateBilling (recurlyInfo, magentoInfo) {
            const inputs = document.querySelectorAll('.subscription-input')

            window.recurly.token(recurlyInfo, (err, token) => {
                if (err) {
                    console.log(err)
                    err.fields.forEach((field, i) => {
                        if (field === 'number' || field === 'month' || field === 'year') {
                            field = 'card'
                            document.querySelector('[data-recurly=' + field + ']').classList.add('error')
                        }
                        this.updatingAddress = false
                    })

                    if (!this.validate_form(inputs)) {
                        this.updatingAddress = false
                        return null
                    }
                } else {
                    const billingXml = '<billing_info><token_id>' + token.id + '</token_id></billing_info>'
                    this.updateAddressInRecurly(billingXml, magentoInfo, 'billing')
                }
            })
        },
        updateAddressInRecurly (xml, magentoInfo, type) {
            const api_key = this.recurly_auth_api_key
            const id = document.querySelector('.address-select').value

            if (type === 'billing') {
                getSubscriptionService({api_key}).updateBilling(xml, this.accountId).then((response) => {
                    this.updatingAddress = false
                    this.updateButtonText()
                    this.$store.commit('SET_RECURLY_BILLING', response)

                    if (!id) {
                        this.saveAddressInMagento(magentoInfo)
                    }
                }, (error) => {
                    console.log(error)
                    this.updatingAddress = false
                })
            } else {
                getSubscriptionService({api_key}).updateShipping(xml).then((response) => {
                    this.updatingAddress = false
                    this.updateButtonText()
                    this.$store.commit('SET_RECURLY_SHIPPING', response)

                    if (!id) {
                        this.saveAddressInMagento(magentoInfo)
                    }
                }, (error) => {
                    console.log(error)
                    this.updatingAddress = false
                })
            }
        },
        saveAddressInMagento (magentoInfo) {
            // create/save new address in Magento account
            const createUrl = `${this.base_url}contact-lenses/subscription/saveAddress`
            const config = { headers: { 'Content-Type': 'application/json' }}

            axios.post(createUrl, JSON.stringify(magentoInfo), config).then((response) => {
                console.log(response)
            }, (error) => {
                console.log(error)
            })
        },
        updateButtonText () {
            this.buttonText = 'saved'
            setTimeout(() => this.buttonText = 'save', 2000)
        }
    }
}
</script>
