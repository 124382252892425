<template>
    <CatalogProductView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogProductView from '../../../components/catalog/product/Wh/CatalogProductBlockView.vue'

export default {
    name: 'RouterWhCatalogProductView',
    components: {
        CatalogProductView
    },
    extends: App
}
</script>
