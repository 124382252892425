<template>
    <div>
        <div class="modal-mask"></div>
        <div class="modal-container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SignInModal'
}
</script>

<style scoped>
.modal-container {
  max-width: 600px;
}
</style>