import axios from 'axios'

let sosService = null

class SosServices {
    constructor ({base_url}) {
        this.base_url = base_url
    }

    fetchFrameData (data) {
        const getUrl = `${this.base_url}prescription-scanner-app/ajax/retrieveResults`

        const getData = 'post=' + JSON.stringify(data)

        return axios.post(getUrl, getData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
    }

    postFrameResults (data) {
        const url = `${this.base_url}prescription-scanner-app/ajax/saveResults`

        const postData = 'post=' + JSON.stringify(data)

        return axios.post(url, postData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
    }

    postRxReaderResults (data) {
        const url = `${this.base_url}prescription-scanner-app/ajax/saveResults`

        const postData = 'post=' + JSON.stringify(data)

        return axios.post(url, postData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
    }
}

export default function getSosService (options = {}) {
    if (!sosService) {
        sosService = new SosServices(options)
    }
    return sosService
}
