<template>
    <CustomerAccount v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import CustomerAccount from '../../components/customer/CustomerAccount.vue'

export default {
    name: 'RouterCustomerAccount',
    components: {
        CustomerAccount
    },
    extends: App
}
</script>
