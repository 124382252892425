<template>
    <div class="spectacles-banner frames-banner" :class="getClass + '-banner'">
        <div class="sb-inner-wrapper">
            <div class="sb-inline" :class="currentCategory.class + '-inline'">
                <div class="sb-inner" :class="getClass">
                    <a :href="currentCategory.url">
                        <h1 :class="getClass + '-home'">{{ currentCategory.name }}</h1>
                    </a>
                </div>
                <div class="instructions">
                    <span v-dompurify-html="currentCategory.description"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
    name: 'HeaderCategory',
    computed: {
        ...mapState('partner', ['currentCategory']),
        ...mapGetters('partner', ['getClass'])
    }
}
</script>
