var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.productView.productInfoShow,
              expression: "productView.productInfoShow"
            }
          ],
          staticClass:
            "product-details-holder lensabl-frames-view frames-wrapper",
          class: { "product-layout-2columns": _vm.isDesktop }
        },
        [
          _c(
            "div",
            { staticClass: "product media" },
            [_c("Gallery", { class: { "vertical-thumbs": _vm.isDesktop } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-info-main" },
            [
              _c("div", { staticClass: "page-title-wrapper product" }, [
                _c("h1", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.fullProductName,
                      expression: "fullProductName"
                    }
                  ],
                  staticClass: "page-title",
                  class: _vm.product_name,
                  attrs: { itemprop: "name" }
                })
              ]),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.product_description,
                    expression: "product_description"
                  }
                ],
                staticStyle: {
                  "font-size": "16px",
                  padding: "0",
                  margin: "1rem 0"
                }
              }),
              _vm._v(" "),
              _c("AdditionalAttributes", {
                class: {
                  inline: !_vm.isDesktop && !_vm.isMobile,
                  grid: _vm.isMobile
                },
                attrs: { "data-attributes": _vm.additionalAttributes }
              }),
              _vm._v(" "),
              _vm.isAvailable
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-weight": "600",
                        "font-size": "1.0rem"
                      },
                      attrs: { title: "Backorder" }
                    },
                    [
                      _vm._v(
                        "\n                Due to high demand for this SKU, fulfillment times may vary directly from the manufacturer. Please note that any orders placed may experience delays. \n                However, if RX lenses are ordered, rest assured that we will begin processing your lenses as soon as you provide all required RX information to minimize any potential wait time.\n            "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("ProductAffirmInfo", {
                attrs: { startingTitle: "Starting from $" }
              }),
              _vm._v(" "),
              _vm.colors.length
                ? [
                    _c(
                      "div",
                      { staticClass: "swatch-opt" },
                      [
                        _c("ColorSwatches", {
                          attrs: {
                            colors: _vm.colors,
                            "checked-index": _vm.checkedIndex,
                            "option-item-class": "decorate bigger img",
                            "product-name": _vm.product_name.toLowerCase()
                          },
                          on: _vm._d({}, [
                            _vm.EVENT_CHANGE_CURRENT_COLOR,
                            _vm.changeCurrentColorAndReset
                          ])
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.isAvailable
                      ? _c(
                          "p",
                          {
                            staticClass: "unavailable stock",
                            attrs: { title: "Availability" }
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("This item is sold out")
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "time-disclaimer",
                        staticStyle: {
                          "font-size": "12px",
                          "font-style": "italic",
                          padding: "0"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Lens production takes 4-7 business days on average from the date you've submitted all required Rx information. All lenses go through a detailed quality inspection before\n                    shipping out. If any issues are found during inspection, we will remake the lenses until they are perfect (this may cause slight delays in shipping time)\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isAvailable && _vm.isMobile
                      ? _c("div", { staticClass: "product-options-bottom" }, [
                          _c(
                            "div",
                            { staticClass: "actionsproductCategoryName" },
                            [
                              _c(
                                "BaseButton",
                                {
                                  attrs: { text: "Choose Your Lenses" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.mobileSwitcher()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            Select Lenses\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("AnagramAdBar"),
      _vm._v(" "),
      !_vm.plusMember
        ? _c("ImageHelpBubble", { attrs: { "ad-data": _vm.getBannerText } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAvailable && _vm.productView.productConfiguratorShow,
              expression: "isAvailable && productView.productConfiguratorShow"
            }
          ],
          staticClass: "product-configurator-wrapper"
        },
        [
          _c("Configurator", {
            attrs: {
              "show-progress-bar": _vm.isMobile ? true : false,
              "custom-summary": true
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.productView.productInfoShow ? _c("ReturnsInfo") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }