<template>
    <InsuranceAnagram v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import InsuranceAnagram from '../../../components/insurances/adminhtml/InsuranceAnagram.vue'

export default {
    name: 'RouterAdminhtmlInsuranceAnagram',
    components: {
        InsuranceAnagram
    },
    extends: App
}
</script>
