import Cookie from 'js-cookie'

let sessionService = null

const KEY_STATES = 'lensabl_states'
const KEY_VALUES = 'lensabl_values'

if (window && !window.hasOwnProperty('sessionStorage')) {
    window.sessionStorage = {
        getItem: function (key) {
            return Cookie.get(key)
        },
        removeItem: function (key) {
            return Cookie.remove(key)
        },
        setItem: function (key, value) {
            return Cookie.set(key, value)
        }
    }
}

class SessionService {
    constructor ({namespace = false}) {
        this.namespace = namespace
        this.storage = sessionStorage
    }

    get values () {
        return this._get(KEY_VALUES)
    }

    set values (values) {
        this._set(KEY_VALUES, values)
    }

    set states (states) {
        this._set(KEY_STATES, states)
    }

    get states () {
        return this._get(KEY_STATES)
    }

    reset () {
        const keys = [
            KEY_STATES,
            KEY_VALUES
        ]

        for (const key of keys) {
            this.storage.removeItem(this._key(key))
        }
    }

    _get (key) {
        const dataKey = this._key(key)
        const storedString = this.storage.getItem(dataKey)
        if (!storedString) {
            return false
        }
        return JSON.parse(storedString)
    }

    _set (key, value) {
        const dataKey = this._key(key)
        const valueString = JSON.stringify(value)
        this.storage.setItem(dataKey, valueString)
    }

    _key (key) {
        const {namespace} = this
        return `${namespace}.${key}`
    }
}

export default function getSessionService (options = {}) {
    if (!sessionService) {
        sessionService = new SessionService(options)
    }
    return sessionService
}
