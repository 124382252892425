import getHelper from '../helper'

export default function getSpectaclesStepList (param) {
    const {frame_value, product_config, assets_url} = param
    const {DEFAULT_LAST_STEP} = getHelper(param)

    function assetUrl (filename) {
        return assets_url + filename
    }

    const lens = {
        'id': 'lens',
        'label': 'Spectacles',
        'summary_label': 'Frame',
        'value_renderer': 'renderer-options',
        'default_value': 'prescription',
        'component': {
            'name': 'selector-options',
            'options': [
                {
                    'id': 'prescription',
                    'label': 'Frame',
                    'summary_label': frame_value,
                    'description': '',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': ''
                        }
                    }
                }
            ]
        }
    }

    const prescription = {
        'id': 'prescription_type',
        'label': 'Choose Prescription Type',
        'summary_label': 'Rx Type',
        'value_renderer': 'renderer-options',
        'default_value': 'spectacles_single_vision',
        'component': {
            'name': 'selector-options',
            'options': [
                {
                    'id': 'spectacles_single_vision',
                    'label': 'Single Vision',
                    'summary_label': 'Single Vision',
                    'description': 'Intended for distance or near correction.',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Intended for distance or near correction.</p>'
                        }
                    }
                },
                {
                    'id': 'spectacles_progressive',
                    'label': 'Progressive',
                    'summary_label': 'Progressive',
                    'description': 'Multi-focal lenses with no visible line that give a seamless progression for distance, intermediate, and near vision correction.',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Multi-focal lenses with no visible line that give a seamless progression for distance, intermediate, and near vision correction.</p>'
                        }
                    }
                }
            ]
        }
    }

    const single_vision = Object.assign({'id': 'spectacles_single_vision'}, {...DEFAULT_LAST_STEP})
    const progressive = Object.assign({'id': 'spectacles_progressive'}, {...DEFAULT_LAST_STEP})

    const lens_type = {
        'id': 'lens_type',
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['computer_lens'],
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                {
                    'id': 'clear_lenses',
                    'label': 'Clear Lenses',
                    'summary_label': 'Clear Lenses',
                    'description': 'Your everyday lenses.',
                    'checkout_value': 'Clear',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Your everyday lenses.</p>'
                        }
                    },
                    'thumbnail': assetUrl('product-configuration-lenstype-standardclear.jpg')
                }
            ]
        }
    }

    const lens_options = {
        'id': 'lens_options',
        'label': 'Select Lens Options',
        'summary_label': 'Lens Option',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['sunglass_lens'],
        'component': {
            'name': 'selector-options-lens-option',
            'params': {
                'product_config': product_config
            },
            'options': [
                {
                    'id': 'poly',
                    'label': 'Polycarbonate Lens',
                    'summary_label': 'Polycarbonate',
                    'checkout_value': 'Poly',
                    'description': 'Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.</p>'
                        }
                    },
                    'small_description': true,
                    'thumbnail': assetUrl('lenses-active-polycarbonate.png')
                },
                {
                    'id': '167',
                    'label': '1.67 High-Index Lens',
                    'summary_label': '1.67 High-Index Lens',
                    'checkout_value': '167',
                    'description': 'For those looking for thinner, lighter lenses. Recommended for strong prescriptions (a total power of +/- 4 or greater)',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>For those looking for thinner, lighter lenses. Recommended for strong prescriptions (a total power of +/- 4 or greater)</p>'
                        }
                    },
                    'small_description': true,
                    'thumbnail': assetUrl('lenses-thin-167-high-index.png')
                }
            ]
        }
    }

    return [
        lens,
        prescription,
        single_vision,
        progressive,
        lens_type,
        lens_options
    ]
}
