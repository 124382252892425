<template>
    <label class="radio-label" @click="click">
        <div :class="{'active': active}" class="option-checkbox-container">
            <div class="option-checkbox">
                <input v-if="name" :name="name" :type="type" :value="value" :checked="active" />
            </div>
        </div>
        <slot><span>{{ label }}</span></slot>
    </label>
</template>

<script>
export default {
    name: 'RadioLabel',
    props: {
        active: {
            type: Boolean,
            default: false
        },
        label: {
            type: [Boolean, String],
            default: false
        },
        name: {
            type: [Boolean, String],
            default: false
        },
        type: {
            type: String,
            default: 'checkbox'
        },
        value: {
            type: [Boolean, String],
            default: false
        }
    },
    methods: {
        click (e) {
            this.$emit('click', e)
        }
    }
}
</script>
