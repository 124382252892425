<template>
    <ContactsConfigurator v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import ContactsConfigurator from '../../components/contacts/configurator/Configurator'

export default {
    name: 'RouterContactsLensesDetails',
    components: {
        ContactsConfigurator
    },
    extends: App
}
</script>
