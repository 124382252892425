<template>
    <CatalogProductClipOnView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogProductClipOnView from '../../../components/catalog/product/CatalogProductClipOnView.vue'

export default {
    name: 'RouterCatalogProductClipOn',
    components: {
        CatalogProductClipOnView
    },
    extends: App
}
</script>
