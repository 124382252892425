<template>
    <div>
        <template v-if="showDiscountSummary">
            <div class="totals-price-subtotal total-line" style="padding-top: 1em">
                <span class="total-label lplus-label">Total before discounts</span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ currencySymbol }}</span>
                    <span class="total-value-amount">{{ totalSummary }}</span>
                </span>
            </div>
            <div v-if="isRedeemFlow && isBusinessMember" class="totals-price-subtotal total-line plus-benefit">
                <span class="total-label lplus-label">Lensabl+ Benefit</span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ getSignRedeemDiscount + currencySymbol }}</span>
                    <span class="total-value-amount">{{ redeemDiscount }}</span>
                </span>
            </div>

            <div v-if="showLensablDiscount" class="totals-price-subtotal total-line plus-benefit-discount">
                <span class="total-label lplus-label">Lensabl+ Discount ({{ getPercentage('contacts') }}%)</span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ currencySymbol }}</span>
                    <span class="total-value-amount">{{ redeemDiscountPercent }}</span>
                </span>
            </div>
            <MemberDiscountSummary class="totals-price" :class-name="'plus-benefit-discount'"/>
            <div class="totals-price-regular total-line">
                <span class="total-label total-dan label">Total</span>
                <span class="total-value value">
                    <span class="total-value-currency currency">{{ currencySymbol }}</span>
                    <span class="total-value-amount amount">{{getTotalAmount}}</span>
                </span>
            </div>
        </template>
        <template v-else>
            <div class="totals-price-regular total-line">
                <span class="total-label total-dan label">Total</span>
                <div class="total-wrapper">
                    <span :class="{ subscribed: getSubscribed === 'yes' && isContactDetailsPage }" class="total-value value">
                        <span class="total-value-currency currency">{{ currencySymbol }}</span>
                        <span class="total-value-amount amount">{{getTotalAmount}}</span>
                    </span>
                    <span
                        :class="{ subscribed: getSubscribed === 'yes' && isContactDetailsPage }"
                        class="total-subscribed-value"
                    >
                        <span class="total-value-currency currency">{{ currencySymbol }}</span>
                        <span v-dompurify-html="subscribedTotalAmount" class="total-subscribed-amount"></span>
                    </span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TotalsPrice from '../../summary/TotalsPrice.vue'
import _formatPrice from '../../../app/utils/formatPrice'
import {EVENT_CONFIGURED_PRICE} from '../../../app/utils/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'ContactsTotalsPrice',
    extends: TotalsPrice,
    computed: {
        ...mapGetters('contacts', ['getSubscribed', 'isContactDetailsPage', 'getLeftEyeChecked', 'getRightEyeChecked']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow', 'isLensablPlus', 'getPercentage', 'planPrice', 'isBusinessMember']),
        ...mapGetters('membershipProgram', ['hasCustomerMembership', 'discountTotalByAction']),
        getTotalAmount () {
            const total = this.totalSummaryContactsDiscounted
            return total
        },
        redeemDiscountPercent () {
            return this.redeemDiscountContacts
        },
        totalAmount () {
            const total = this.totalSummary
            if (!total) {
                return 0
            }
            const grandTotal = Math.max(total + this.discountAmount, 0)
            const configuredPrice = _formatPrice(grandTotal)

            this.$bus.$emit(EVENT_CONFIGURED_PRICE, configuredPrice)
            return total
        },
        showLensablDiscount(){
            return this.isLensablPlus && this.getPercentage('contacts')
        },
        showDiscountSummary(){
            return (this.isLensablPlus || this.hasCustomerMembership) && this.isContactDetailsPage
        },
        subscribedTotalAmount () {
            let total = this.totalSubscribedSummary
            if (!total) {
                return 0
            }

            const grandTotal = Math.max(total + this.discountAmount, 0)
            if (this.hasCustomerMembership) {
                total -= this.discountTotalByAction || 0
            }
            const configuredPrice = _formatPrice(grandTotal)

            this.$bus.$emit(EVENT_CONFIGURED_PRICE, configuredPrice)
            return total
        },
        getSignRedeemDiscount () {
            return this.redeemDiscount > 0 ? '-' : ''
        }
    },
    mounted () {
        this.$store.dispatch('lensablplus_customer/checkRedeemablePath')
    }
}
</script>
