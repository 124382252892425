<template>
    <ul v-if="!complete" class="action-list">
        <li>
            <Above
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                @checked="$emit('checked', $event)"
            />
        </li>
        <li>
            <Enter
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                class="my-prescription-form"
                @checked="$emit('checked', $event)"
            />
        </li>
        <li>
            <Upload
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                option-name="uploaded_pd"
                class="upload-image-online"
                @checked="$emit('checked', $event)"
            />
        </li>
        <!-- <li>
            <Photo
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                :title="complete ? 'Retake Photo' : 'Use our app to measure your PD with a photo'"
                class="my-prescription-form"
                @checked="$emit('checked', $event)"
                @open-fitting-box="$emit('open-fitting-box', $event)"
            />
        </li> -->
    </ul>
</template>

<script>
import Above from './render/Above.vue'
import Enter from './render/Enter.vue'
import Upload from './render/Upload.vue'
import Photo from './render/Photo.vue'

export default {
    name: 'SalesOrderViewPrescriptionPdOptions',
    components: {
        Above,
        Enter,
        Upload,
        Photo
    },
    props: {
        item: {
            type: [Array, Object],
            default: () => {}
        },
        checked: {
            type: [Boolean, String],
            default: ''
        },
        prescriptionsType: {
            type: String,
            default: ''
        },
        prescriptionIndex: {
            type: Number,
            default: 0
        }
    },
    watch: {
        complete (complete) {
            if (complete) {
                this.$emit('hide-form', true)
            }
        }
    },
    computed: {
        fittingbox_status () {
            return this.item.prescriptions[this.prescriptionsType].fittingbox_status
        },
        complete () {
            return this.fittingbox_status === 'taken' || this.fittingbox_status === 'retaken'
        }
    },
    methods: {
        openVeeroPd() {
            console.log('foo bar')
            EyeSize.launch(this.eyeSizeCallback, this.eyeSizeExitCallback, 'yybzhvxF0', true)
        },
        eyeSizeCallback(results) {
            let binocular_pd = results["mono_pd"]*1000;
            let face_width = results["face_width"]*1000;
            let bridge_width = results["bridge_width"]*1000;
            let eye_width = results["eye_width"]*1000;
            let temple_length = results["temple_length"]*1000;

            console.log('hello', results)
        },
        eyeSizeExitCallback() {
            window.location.replace("/");
        }
    }
}
</script>
