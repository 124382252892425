var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.getLoadingProducts
        ? _c("PageLoader", { attrs: { load: _vm.getLoadingProducts } })
        : [
            _c("HeaderCategory"),
            _vm._v(" "),
            _c("Breadcrumb"),
            _vm._v(" "),
            _vm.countChildCategories
              ? _c("CategoryList", {
                  attrs: { categories: _vm.getChildCategories }
                })
              : _c(
                  "div",
                  {
                    staticClass: "category-products frames-products",
                    class: _vm.getClass
                  },
                  [
                    _c("CatalogProductList", {
                      attrs: {
                        products: _vm.currentCategory.products,
                        load: _vm.getLoadingProducts,
                        grid: true,
                        "category-class": _vm.getClass
                      }
                    })
                  ],
                  1
                )
          ],
      _vm._v(" "),
      _vm.showSeoPage
        ? _c("FramesPageSeo", { attrs: { brand: _vm.getClass } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }