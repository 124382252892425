var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.memberShipEdited
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "membership-choose-address",
              attrs: { id: "membership-billing_address_choice" }
            },
            [
              _vm._v("\n            Select from existing customer addresses:"),
              _c("br"),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.address_id,
                      expression: "address_id"
                    }
                  ],
                  staticClass: "select",
                  attrs: {
                    id: "membership-billing_address_customer_address_id"
                  },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.address_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Add New Address")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.addresses, function(address) {
                    return [
                      _c("option", {
                        key: address.entity_id,
                        domProps: {
                          value: address.entity_id,
                          textContent: _vm._s(_vm.addressStringify(address))
                        }
                      })
                    ]
                  })
                ],
                2
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "membership-address",
              attrs: { id: "membership-billing_address_fields" }
            },
            [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "hor-scroll" }, [
                  _c(
                    "table",
                    { staticClass: "form-list", attrs: { cellspacing: "0" } },
                    [
                      _c(
                        "tbody",
                        _vm._l(_vm.addressTemplateData, function(template) {
                          return _c("tr", { key: "row-" + template.name }, [
                            _c("td", { staticClass: "label" }, [
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for:
                                      "membership-billing_address_" +
                                      template.name
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(template.label) +
                                      " "
                                  ),
                                  template.required
                                    ? _c("span", { staticClass: "required" }, [
                                        _vm._v("*")
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "value" },
                              [
                                template.options
                                  ? [
                                      template.type === "text"
                                        ? _vm._l(template.options, function(
                                            option,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  template.name +
                                                  "-option-" +
                                                  index,
                                                staticClass: "multi-input"
                                              },
                                              [
                                                option.type === "text"
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .currentBillingAddress[
                                                              template.name
                                                            ][index],
                                                          expression:
                                                            "currentBillingAddress[template.name][index]"
                                                        }
                                                      ],
                                                      staticClass: "input-text",
                                                      class: {
                                                        "required-entry":
                                                          option.required,
                                                        "validation-failed": _vm.validateField(
                                                          template.name
                                                        )
                                                      },
                                                      attrs: {
                                                        id:
                                                          "membership-billing_address_" +
                                                          template.name +
                                                          "-" +
                                                          index,
                                                        name:
                                                          "billing[" +
                                                          template.name +
                                                          "]",
                                                        "data-recurly":
                                                          option.dataRecurly,
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm
                                                            .currentBillingAddress[
                                                            template.name
                                                          ][index]
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm
                                                              .currentBillingAddress[
                                                              template.name
                                                            ],
                                                            index,
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      template.type === "select"
                                        ? [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentBillingAddress[
                                                        template.name
                                                      ],
                                                    expression:
                                                      "currentBillingAddress[template.name]"
                                                  }
                                                ],
                                                staticClass: "select",
                                                class: {
                                                  "required-entry":
                                                    template.required,
                                                  "validation-failed": _vm.validateField(
                                                    template.name
                                                  )
                                                },
                                                attrs: {
                                                  id:
                                                    "membership-billing_address_" +
                                                    template.name,
                                                  name:
                                                    "billing[" +
                                                    template.name +
                                                    "]",
                                                  "data-recurly":
                                                    template.dataRecurly
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.currentBillingAddress,
                                                      template.name,
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                template.options.length
                                                  ? [
                                                      _vm._l(
                                                        template.options,
                                                        function(option) {
                                                          return [
                                                            option.value
                                                              ? _c("option", {
                                                                  key:
                                                                    template.name +
                                                                    "-option-" +
                                                                    option.value,
                                                                  domProps: {
                                                                    value:
                                                                      option.value,
                                                                    textContent: _vm._s(
                                                                      option.label
                                                                    )
                                                                  }
                                                                })
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            template.textValue
                                              ? [
                                                  template.textValue.type ===
                                                  "checkbox"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .currentBillingAddress[
                                                                template
                                                                  .textValue
                                                                  .name
                                                              ],
                                                            expression:
                                                              "currentBillingAddress[template.textValue.name]"
                                                          }
                                                        ],
                                                        attrs: {
                                                          name:
                                                            "billing[" +
                                                            template.textValue
                                                              .name +
                                                            "]",
                                                          "data-recurly":
                                                            template.textValue
                                                              .dataRecurly,
                                                          type: "checkbox"
                                                        },
                                                        domProps: {
                                                          checked: Array.isArray(
                                                            _vm
                                                              .currentBillingAddress[
                                                              template.textValue
                                                                .name
                                                            ]
                                                          )
                                                            ? _vm._i(
                                                                _vm
                                                                  .currentBillingAddress[
                                                                  template
                                                                    .textValue
                                                                    .name
                                                                ],
                                                                null
                                                              ) > -1
                                                            : _vm
                                                                .currentBillingAddress[
                                                                template
                                                                  .textValue
                                                                  .name
                                                              ]
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm
                                                                  .currentBillingAddress[
                                                                  template
                                                                    .textValue
                                                                    .name
                                                                ],
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.currentBillingAddress,
                                                                    template
                                                                      .textValue
                                                                      .name,
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.currentBillingAddress,
                                                                    template
                                                                      .textValue
                                                                      .name,
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.currentBillingAddress,
                                                                template
                                                                  .textValue
                                                                  .name,
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      })
                                                    : template.textValue
                                                        .type === "radio"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .currentBillingAddress[
                                                                template
                                                                  .textValue
                                                                  .name
                                                              ],
                                                            expression:
                                                              "currentBillingAddress[template.textValue.name]"
                                                          }
                                                        ],
                                                        attrs: {
                                                          name:
                                                            "billing[" +
                                                            template.textValue
                                                              .name +
                                                            "]",
                                                          "data-recurly":
                                                            template.textValue
                                                              .dataRecurly,
                                                          type: "radio"
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm
                                                              .currentBillingAddress[
                                                              template.textValue
                                                                .name
                                                            ],
                                                            null
                                                          )
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              _vm.currentBillingAddress,
                                                              template.textValue
                                                                .name,
                                                              null
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .currentBillingAddress[
                                                                template
                                                                  .textValue
                                                                  .name
                                                              ],
                                                            expression:
                                                              "currentBillingAddress[template.textValue.name]"
                                                          }
                                                        ],
                                                        attrs: {
                                                          name:
                                                            "billing[" +
                                                            template.textValue
                                                              .name +
                                                            "]",
                                                          "data-recurly":
                                                            template.textValue
                                                              .dataRecurly,
                                                          type:
                                                            template.textValue
                                                              .type
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .currentBillingAddress[
                                                              template.textValue
                                                                .name
                                                            ]
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.currentBillingAddress,
                                                              template.textValue
                                                                .name,
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                ]
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ]
                                  : [
                                      template.type === "text"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.currentBillingAddress[
                                                    template.name
                                                  ],
                                                expression:
                                                  "currentBillingAddress[template.name]"
                                              }
                                            ],
                                            staticClass: "input-text",
                                            class: {
                                              "required-entry":
                                                template.required,
                                              "validation-failed": _vm.validateField(
                                                template.name
                                              )
                                            },
                                            attrs: {
                                              id:
                                                "membership-billing_address_" +
                                                template.name,
                                              name:
                                                "billing[" +
                                                template.name +
                                                "]",
                                              "data-recurly":
                                                template.dataRecurly,
                                              type: "text"
                                            },
                                            domProps: {
                                              value:
                                                _vm.currentBillingAddress[
                                                  template.name
                                                ]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.currentBillingAddress,
                                                  template.name,
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                _vm._v(" "),
                                _vm.validateField(template.name)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "validation-advice",
                                        attrs: {
                                          id:
                                            "advice-required-entry-" +
                                            template.name
                                        }
                                      },
                                      [_vm._v("This is a required field.")]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: 0,
                      expression: "0"
                    }
                  ],
                  staticClass: "membership-save-in-address-book"
                },
                [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentBillingAddress.save_in_address_book,
                          expression:
                            "currentBillingAddress.save_in_address_book"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.currentBillingAddress.save_in_address_book
                        )
                          ? _vm._i(
                              _vm.currentBillingAddress.save_in_address_book,
                              null
                            ) > -1
                          : _vm.currentBillingAddress.save_in_address_book
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.currentBillingAddress.save_in_address_book,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.currentBillingAddress,
                                  "save_in_address_book",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.currentBillingAddress,
                                  "save_in_address_book",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.currentBillingAddress,
                              "save_in_address_book",
                              $$c
                            )
                          }
                        }
                      }
                    }),
                    _vm._v(" Save in address book")
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(0)
        ])
      : _c("address", {
          directives: [
            {
              name: "dompurify-html",
              rawName: "v-dompurify-html",
              value: _vm.currentMemberShipData.formated_billing_address,
              expression: "currentMemberShipData.formated_billing_address"
            }
          ]
        })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "overlay",
        staticStyle: { display: "none" },
        attrs: { id: "address-billing-overlay" }
      },
      [_c("span", [_vm._v("Shipping address selection is not applicable")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }