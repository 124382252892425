var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "testimonial-slider-container" },
    [
      _c(
        "carousel",
        {
          staticClass: "struct-graphic",
          attrs: {
            "per-page": 1,
            "mouse-drag": true,
            "navigation-enabled": true,
            "navigation-prev-label": "‹",
            "navigation-next-label": "›"
          }
        },
        [
          _c("slide", { staticClass: "testimonial-wrapper" }, [
            _c("img", {
              staticClass: "testimonial-person",
              attrs: {
                src:
                  "https://www.lensabl.com/media/wysiwyg/Why-Lensabl-David.png"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "testimonial-review" }, [
              _c("p", [
                _vm._v(
                  '"I don’t mess around when it comes to my glasses. I spent good money on two pairs of frames from another store, and want them to last as long as possible. That’s why I use Lensabl to just replace the lenses whenever I need to.”'
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "testimonial-review-sig" }, [
                _vm._v("David J.")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("slide", { staticClass: "testimonial-wrapper" }, [
            _c("img", {
              staticClass: "testimonial-person",
              attrs: {
                src:
                  "https://www.lensabl.com/media/wysiwyg/Why-Lensabl-Jennifer.png"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "testimonial-review" }, [
              _c("p", [
                _vm._v(
                  '"I’m a longtime Lensabl customer and have had the lenses replaced in a bunch of frames. I was thrilled when they started offering contact lenses too. Now all of my vision stuff is handled in one place which makes my life easier.”'
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "testimonial-review-sig" }, [
                _vm._v("Jennifer M.")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("slide", { staticClass: "testimonial-wrapper" }, [
            _c("img", {
              staticClass: "testimonial-person",
              attrs: {
                src:
                  "https://www.lensabl.com/media/wysiwyg/Why-Lensabl-Eric.png"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "testimonial-review" }, [
              _c("p", [
                _vm._v(
                  '"I’m your typical millennial, I do all my shopping online. I’ve owned Warby Parker and Felix Gray glasses and they’re great, but Lensabl’s Everyday Eyewear line offers the same quality but for a cheaper price. Easy decision.”'
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "testimonial-review-sig" }, [
                _vm._v("Eric L.")
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }