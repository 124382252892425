var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDiscountSummary
        ? [
            _c(
              "div",
              {
                staticClass: "totals-price-subtotal total-line",
                staticStyle: { "padding-top": "1em" }
              },
              [
                _c("span", { staticClass: "total-label lplus-label" }, [
                  _vm._v("Total before discounts")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "total-value value" }, [
                  _c("span", { staticClass: "total-value-currency" }, [
                    _vm._v(_vm._s(_vm.currencySymbol))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "total-value-amount" }, [
                    _vm._v(_vm._s(_vm.totalSummary))
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _vm.isRedeemFlow && _vm.isBusinessMember
              ? _c(
                  "div",
                  {
                    staticClass: "totals-price-subtotal total-line plus-benefit"
                  },
                  [
                    _c("span", { staticClass: "total-label lplus-label" }, [
                      _vm._v("Lensabl+ Benefit")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "total-value value" }, [
                      _c("span", { staticClass: "total-value-currency" }, [
                        _vm._v(
                          _vm._s(_vm.getSignRedeemDiscount + _vm.currencySymbol)
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "total-value-amount" }, [
                        _vm._v(_vm._s(_vm.redeemDiscount))
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showLensablDiscount
              ? _c(
                  "div",
                  {
                    staticClass:
                      "totals-price-subtotal total-line plus-benefit-discount"
                  },
                  [
                    _c("span", { staticClass: "total-label lplus-label" }, [
                      _vm._v(
                        "Lensabl+ Discount (" +
                          _vm._s(_vm.getPercentage("contacts")) +
                          "%)"
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "total-value value" }, [
                      _c("span", { staticClass: "total-value-currency" }, [
                        _vm._v(_vm._s(_vm.currencySymbol))
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "total-value-amount" }, [
                        _vm._v(_vm._s(_vm.redeemDiscountPercent))
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("MemberDiscountSummary", {
              staticClass: "totals-price",
              attrs: { "class-name": "plus-benefit-discount" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "totals-price-regular total-line" }, [
              _c("span", { staticClass: "total-label total-dan label" }, [
                _vm._v("Total")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value value" }, [
                _c("span", { staticClass: "total-value-currency currency" }, [
                  _vm._v(_vm._s(_vm.currencySymbol))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "total-value-amount amount" }, [
                  _vm._v(_vm._s(_vm.getTotalAmount))
                ])
              ])
            ])
          ]
        : [
            _c("div", { staticClass: "totals-price-regular total-line" }, [
              _c("span", { staticClass: "total-label total-dan label" }, [
                _vm._v("Total")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "total-wrapper" }, [
                _c(
                  "span",
                  {
                    staticClass: "total-value value",
                    class: {
                      subscribed:
                        _vm.getSubscribed === "yes" && _vm.isContactDetailsPage
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "total-value-currency currency" },
                      [_vm._v(_vm._s(_vm.currencySymbol))]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "total-value-amount amount" }, [
                      _vm._v(_vm._s(_vm.getTotalAmount))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "total-subscribed-value",
                    class: {
                      subscribed:
                        _vm.getSubscribed === "yes" && _vm.isContactDetailsPage
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "total-value-currency currency" },
                      [_vm._v(_vm._s(_vm.currencySymbol))]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: _vm.subscribedTotalAmount,
                          expression: "subscribedTotalAmount"
                        }
                      ],
                      staticClass: "total-subscribed-amount"
                    })
                  ]
                )
              ])
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }