var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isContactDetailsPage
        ? [
            _c("ul", { staticClass: "totals-info" }, [
              _vm.getRightEyeConfirmed
                ? _c("li", { staticClass: "item option-product-info" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("Right eye:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "renderer-options summary-value" },
                      [
                        _c("span", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.getEyeData.right.brand,
                              expression: "getEyeData.right.brand"
                            }
                          ],
                          staticClass: "option-value"
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "option-color" }, [
                          _vm._v(_vm._s(_vm.getEyeData.right.color))
                        ]),
                        _vm._v(" "),
                        !_vm.isRedeemFlow
                          ? _c("div", { staticClass: "option-quantity" }, [
                              _vm._v(_vm._s(_vm.getQuantity("right")))
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.getLeftEyeConfirmed
                ? _c("li", { staticClass: "item option-product-info" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("Left eye:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "renderer-options summary-value" },
                      [
                        _c("span", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.getEyeData.left.brand,
                              expression: "getEyeData.left.brand"
                            }
                          ],
                          staticClass: "option-value"
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "option-color" }, [
                          _vm._v(_vm._s(_vm.getEyeData.left.color))
                        ]),
                        _vm._v(" "),
                        !_vm.isRedeemFlow
                          ? _c("div", { staticClass: "option-quantity" }, [
                              _vm._v(_vm._s(_vm.getQuantity("left")))
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e()
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }