var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.listTitle
        ? _c(
            "div",
            {
              staticClass: "filter-tab",
              class: { active: _vm.show },
              on: {
                click: function($event) {
                  _vm.show = !_vm.show
                }
              }
            },
            [
              !_vm.isMobile
                ? _c("span", { staticClass: "list-title" }, [
                    _vm._v(_vm._s(_vm.listTitle) + ":")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isMobile
                ? _c("span", { staticClass: "list-title" }, [
                    _vm._v(_vm._s(_vm.listTitle))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isMobile
                ? _c(
                    "span",
                    { staticClass: "reset-filters", on: { click: _vm.reset } },
                    [_vm._v("Reset Filters")]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile
        ? _c("div", { staticClass: "filter-tab" }, [
            _c("ul", { staticClass: "category-frames-grid" }, [
              _c("li", [
                _c(
                  "div",
                  { staticClass: "custom-select" },
                  [
                    _c("v-select", {
                      staticClass: "frame-dropdown",
                      attrs: {
                        items: _vm.allCats,
                        "item-text": "name",
                        "item-value": "value",
                        multiple: "",
                        label: "Brand"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changedValue("category")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var data = ref.data
                              var index = ref.index
                              return [
                                _vm.getFiltered.category.length === 1
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.getFiltered.category[index]
                                          ) +
                                          "\n                            "
                                      )
                                    ])
                                  : _vm.getFiltered.category.length > 1 &&
                                    index < 1
                                  ? _c("div", [_vm._v("2 or more    ")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2680222064
                      ),
                      model: {
                        value: _vm.getFiltered.category,
                        callback: function($$v) {
                          _vm.$set(_vm.getFiltered, "category", $$v)
                        },
                        expression: "getFiltered.category"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              false
                ? _c("li", [
                    _c(
                      "div",
                      { staticClass: "custom-select" },
                      [
                        _c("v-select", {
                          staticClass: "frame-dropdown",
                          attrs: {
                            items: _vm.lens_type.choices,
                            "item-value": "value",
                            multiple: "",
                            label: "Lens Type"
                          },
                          on: {
                            change: function($event) {
                              return _vm.changedValue("lens_type")
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var data = ref.data
                                  var index = ref.index
                                  return [
                                    _vm.getFiltered.lens_type.length === 1
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.getFiltered.lens_type[index]
                                              ) +
                                              "\n                            "
                                          )
                                        ])
                                      : _vm.getFiltered.lens_type.length > 1 &&
                                        index < 1
                                      ? _c("div", [_vm._v("2 or more    ")])
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2012476531
                          ),
                          model: {
                            value: _vm.getFiltered.lens_type,
                            callback: function($$v) {
                              _vm.$set(_vm.getFiltered, "lens_type", $$v)
                            },
                            expression: "getFiltered.lens_type"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", [
                _c(
                  "div",
                  { staticClass: "custom-select" },
                  [
                    _c("v-select", {
                      staticClass: "frame-dropdown",
                      attrs: {
                        items: _vm.frame_size.choices,
                        "item-value": "value",
                        multiple: "",
                        label: "Frame Size"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changedValue("frame_size")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var data = ref.data
                              var index = ref.index
                              return [
                                _vm.getFiltered.frame_size.length === 1
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.getFiltered.frame_size[index]
                                          ) +
                                          "\n                            "
                                      )
                                    ])
                                  : _vm.getFiltered.frame_size.length > 1 &&
                                    index < 1
                                  ? _c("div", [_vm._v("2 or more    ")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3021082983
                      ),
                      model: {
                        value: _vm.getFiltered.frame_size,
                        callback: function($$v) {
                          _vm.$set(_vm.getFiltered, "frame_size", $$v)
                        },
                        expression: "getFiltered.frame_size"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "div",
                  { staticClass: "custom-select" },
                  [
                    _c("v-select", {
                      staticClass: "frame-dropdown",
                      attrs: {
                        items: _vm.material.choices,
                        "item-value": "value",
                        multiple: "",
                        label: "Material"
                      },
                      on: {
                        change: function($event) {
                          return _vm.changedValue("material")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var data = ref.data
                              var index = ref.index
                              return [
                                _vm.getFiltered.material.length === 1
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.getFiltered.material[index]
                                          ) +
                                          "\n                            "
                                      )
                                    ])
                                  : _vm.getFiltered.material.length > 1 &&
                                    index < 1
                                  ? _c("div", [_vm._v("2 or more    ")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3581161835
                      ),
                      model: {
                        value: _vm.getFiltered.material,
                        callback: function($$v) {
                          _vm.$set(_vm.getFiltered, "material", $$v)
                        },
                        expression: "getFiltered.material"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _c("div", { staticClass: "custom-select" }, [
                  _c(
                    "span",
                    { staticClass: "reset-filters", on: { click: _vm.reset } },
                    [_vm._v("Reset Filters")]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.isMobile && _vm.show
          ? _c("div", { staticClass: "filter-form" }, [
              _c("div", { staticClass: "filter-list brands-list" }, [
                _c("h5", [_vm._v("Brands")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.allCats, function(cat) {
                    return _c("li", { key: "category-" + cat.id }, [
                      _c("label", { attrs: { for: cat.id } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.getFiltered.category,
                              expression: "getFiltered.category"
                            }
                          ],
                          attrs: { id: cat.id, type: "checkbox" },
                          domProps: {
                            value: cat.name,
                            checked: Array.isArray(_vm.getFiltered.category)
                              ? _vm._i(_vm.getFiltered.category, cat.name) > -1
                              : _vm.getFiltered.category
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.getFiltered.category,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = cat.name,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getFiltered,
                                        "category",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getFiltered,
                                        "category",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.getFiltered, "category", $$c)
                                }
                              },
                              function($event) {
                                return _vm.changedValue("category")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(cat.name))])
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              false
                ? _c("div", { staticClass: "filter-list lens-type-list" }, [
                    _c("h5", [_vm._v("Lens Type")]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(_vm.lens_type.choices, function(type) {
                        return _c("li", { key: "lens_type-" + type }, [
                          _c("label", { attrs: { for: type } }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.getFiltered.lens_type,
                                  expression: "getFiltered.lens_type"
                                }
                              ],
                              attrs: { id: type, type: "checkbox" },
                              domProps: {
                                value: type,
                                checked: Array.isArray(
                                  _vm.getFiltered.lens_type
                                )
                                  ? _vm._i(_vm.getFiltered.lens_type, type) > -1
                                  : _vm.getFiltered.lens_type
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = _vm.getFiltered.lens_type,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = type,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.getFiltered,
                                            "lens_type",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.getFiltered,
                                            "lens_type",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.getFiltered,
                                        "lens_type",
                                        $$c
                                      )
                                    }
                                  },
                                  function($event) {
                                    return _vm.changedValue("lens_type")
                                  }
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(type))])
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "filter-list frame-size-list" }, [
                _c("h5", [_vm._v("Frame Size")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.frame_size.choices, function(size) {
                    return _c("li", { key: "frame_size-" + size }, [
                      _c("label", { attrs: { for: size } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.getFiltered.frame_size,
                              expression: "getFiltered.frame_size"
                            }
                          ],
                          attrs: { id: size, type: "checkbox" },
                          domProps: {
                            value: size,
                            checked: Array.isArray(_vm.getFiltered.frame_size)
                              ? _vm._i(_vm.getFiltered.frame_size, size) > -1
                              : _vm.getFiltered.frame_size
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.getFiltered.frame_size,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = size,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getFiltered,
                                        "frame_size",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getFiltered,
                                        "frame_size",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.getFiltered, "frame_size", $$c)
                                }
                              },
                              function($event) {
                                return _vm.changedValue("frame_size")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(size))])
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-list material-list" }, [
                _c("h5", [_vm._v("Material")]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.material.choices, function(mat) {
                    return _c("li", { key: "material-" + mat }, [
                      _c("label", { attrs: { for: mat } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.getFiltered.material,
                              expression: "getFiltered.material"
                            }
                          ],
                          attrs: { id: mat, type: "checkbox" },
                          domProps: {
                            value: mat,
                            checked: Array.isArray(_vm.getFiltered.material)
                              ? _vm._i(_vm.getFiltered.material, mat) > -1
                              : _vm.getFiltered.material
                          },
                          on: {
                            change: [
                              function($event) {
                                var $$a = _vm.getFiltered.material,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = mat,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.getFiltered,
                                        "material",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.getFiltered,
                                        "material",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.getFiltered, "material", $$c)
                                }
                              },
                              function($event) {
                                return _vm.changedValue("material")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(mat))])
                      ])
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }