<template>
    <div :class="{redeemed: redeemedStyle}" class="plus-account_container glasses-container">
        <h4 v-if="isMobile" :class="{active: isShow}" @click="toggleContent">Eye Exam Credit</h4>
        <transition name="slide">
            <div v-if="!isMobile || isShow && isMobile" :class="{redeemed: redeemedStyle}" class="plus-content_container glasses-content">
                <div class="redeem-description">
                    <div class="redeem-container">
                        <div>
                            <img src="/js/lensconfigurator/assets/eyeexam_desktop.svg" alt="contact lenses" />
                        </div>
                        <div class="redeem-description">
                            <span class="description">
                                {{ isBusinessMember ? 'Reward for taking an eye exam' : '$60 credit for getting an eye exam' }}
                            </span>
                            <HelpBubble v-show="!redeemedStyle" :data="component_help.exam" />
                        </div>
                        <div class="secondary">
                            <BaseButton
                                v-show="!redeemedStyle"
                                :disabled="btnDisabled('voucher') || pending"
                                :text="btnTitle('voucher')"
                                :class="{'disable': !previousvisit, 'disabledButton': pending}"
                                data-size="xs"
                                class="button button--copy-solid voucher"
                                @click.native="openEyeExamRedeem"
                            />
                        </div>
                    </div>
                    <div class="redeem-container">
                        <div></div>
                        <span class="separator">-or-</span>
                        <div class="secondary">
                            <BaseButton
                                v-show="redeemedStyle && !isMobile"
                                :class="{'disable': !previousvisit, 'disabledButton': btnDisabled(redeemItemType)}"
                                :disabled="btnDisabled(redeemItemType)"
                                :text="btnTitle(redeemItemType)"
                                data-size="xs"
                                class="button button--copy-solid exam"
                                @click.native="openVisionRedeem"
                            />
                        </div>
                    </div>
                    <div class="redeem-container">
                        <div>
                            <img src="/js/lensconfigurator/assets/online_exam.svg" alt="contact lenses" />
                        </div>
                        <div>
                            <span class="description">Online vision test for Rx renewal <span v-if="!isBusinessMember">(for glasses and contacts)</span></span>
                            <HelpBubble v-show="!redeemedStyle" :data="component_help.online" />
                        </div>
                        <div class="secondary">
                            <BaseButton
                                v-show="redeemedStyle && isMobile || !redeemedStyle"
                                :disabled="btnDisabled(redeemItemType)"
                                :text="btnTitle(redeemItemType)"
                                :class="{'disable': !previousvisit, 'disabledButton': isVisionRedeemed && isMobile}"
                                data-size="xs"
                                class="button button--copy-solid"
                                @click.native="openVisionRedeem"
                            />
                        </div>
                    </div>
                    <div v-if="!redeemedStyle" class="redeem-location-link">Need help finding a location? <a @click="redirectTDcctors">Click Here</a></div>
                </div>
            </div>
        </transition>
        <Redeem
            :title="'Vision Test'"
            :instruction="instruction"
            :is-modal-open="isVisionTestOpen"
            subtitle="Vision Test Benefit"
            @continue="continueToVisionTest"
            @update="isVisionTestOpen = false"
        />
        <ExamCreditRedeem
            :is-modal-open="isEyeExamOpen"
            :collect="collect"
            :redeem-item-type="redeemItemType"
            class="vision-exam-benefit"
            subtitle="Vision Exam Benefit"
            instruction="(includes either Eye Exam or Contact Lens fitting)"
            @update="isEyeExamOpen = false"
            @continue="pendingBenefitCredit"
        />
        <GiftCreditRedeem
            :is-modal-open="isGiftCardOpen"
            :collect.sync="collect"
            :redeem-item-type="redeemItemType"
            @update="isGiftCardOpen = false"
            @continue="pendingBenefitCredit"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import BaseButton from '../../../components/base/BaseButton.vue'
import Redeem from './Redeem.vue'
import ExamCreditRedeem from './ExamCreditRedeem.vue'
import GiftCreditRedeem from './GiftCreditRedeem.vue'
import AbstractRedeem from './AbstractRedeem'
import {
    STATUS_PENDING,
    STATUS_APPROVED,
    STATUS_FINISHED
} from '../storage/lensablplus/customer'

export default {
    name: 'EyeExamRedeem',
    components: {
        BaseButton,
        HelpBubble,
        Redeem,
        ExamCreditRedeem,
        GiftCreditRedeem
    },
    extends: AbstractRedeem,
    data: () => ({
        redeemItemType: 'exam',
        component_help: {
            exam: {
                name: 'help-bubble',
                params: {
                    text: '<p>Receive a $60 Lensabl credit by submitting a valid eye exam/contact fitting receipt. You can see any eye care provider you want.</p>'
                }
            },
            online: {
                name: 'help-bubble',
                params: {
                    text: '<p>The online vision test is not a replacement for a comprehensive eye health examination. Licensed Ophthalmologists and Optometrists use Visibly’s online technology to evaluate a patient’s visual acuity and a portion of the ocular health profile and issue a prescription for corrective eyewear, where clinically appropriate. This service is limited to patients between the ages of 18 and 50. Because this service is not a replacement for an eye health examination, we encourage everyone to obtain a comprehensive eye exam at least once every 2 years.</p>'
                }
            },
            other: {
                name: 'help-bubble',
                params: {
                    text: '<p>$25 cash cards from Visa or gift cards from some of the country&#39;s leading retailers including Amazon, Best Buy, CVS, iTunes, Zappos, and more.</p>',
                    targetContent: 'other rewards'
                }
            }
        },
        instruction: 'After clicking the button below, you will take a quiz to qualify for our online vision test to renew your prescription.',
        isShow: false,
        isVisionTestOpen: false,
        isEyeExamOpen: false,
        isGiftCardOpen: false,
        pending: false,
        collect: false,
        redirectTo: false
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablplus_customer', ['isVisionRedeemed', 'getGiftcardConfigs', 'paidUp', 'getBtnDisabled', 'statusRedeemed', 'getRedeemedStyles', 'getBtnTitlesById', 'getReceiptRewards', 'isBusinessMember', 'plusMember']),
        ...mapGetters('storeView', ['base_url']),
        ...mapState('gsap', ['previousvisit']),
        ...mapState('lensablplus_customer', ['cookies_tag_business']),
        isVisionRedeemed () {
            return this.statusRedeemed(this.redeemItemType) === 1
        },
        receiptRewards () {
            return this.getReceiptRewards(this.redeemItemType)
        },
        hideVisionRedeem () {
            if (this.pending) return false
            else if (this.collect) return false
            else return true
        },
        redeemedStyle () {
            return this.getRedeemedStyles(this.statusRedeemed(this.redeemItemType)) || this.pending || this.collect
        },
        btnTitle () {
            return type => {
                if (this.isVisionRedeemed) return 'REDEEMED'
                if (this.pending) return 'PENDING REVIEW'
                if (!this.paidUp) return 'SUSPEND'
                if (this.collect) return 'COLLECT REWARD'
                const statusId = this.statusRedeemed(type)
                return (this.getBtnTitlesById(statusId) || '').toUpperCase()
            }
        },
        btnDisabled () {
            return type => {
                const statusId = this.statusRedeemed(type)
                return !this.paidUp || this.getBtnDisabled(statusId)
            }
        }
    },
    watch: {
        isBusinessMember (value) {
            if (value) {
                this.redeemItemType = 'business_exam'
            }
        }
    },
    created () {
        if (this.isBusinessMember) {
            this.redeemItemType = 'business_exam'
        }

        if (this.plusMember && this.receiptRewards && this.statusRedeemed(this.redeemItemType) !== 1) {
            this.pending = this.receiptRewards.status === STATUS_PENDING
            this.collect = this.receiptRewards.status === STATUS_APPROVED
            this.redirectTo = this.receiptRewards.status === STATUS_FINISHED
        }
    },
    mounted () {
        if (this.isBusinessMember) {
            this.component_help.online.params.text = '<p>Renew your expired contacts or eyeglass Rx with our online vision test. The online vision test is not a replacement for a comprehensive eye health examination. Licensed Ophthalmologists and Optometrists use Visibly’s online technology to evaluate a patient’s visual acuity and a portion of the ocular health profile and issue a prescription for corrective eyewear, where clinically appropriate. This service is limited to patients between the ages of 18 and 50. Because this service is not a replacement for an eye health examination, we encourage everyone to obtain a comprehensive eye exam at least once every 2 years.</p>'
        }
    },
    methods: {
        ...mapActions('lensablplus_customer', ['createRewardTremendous']),
        toggleContent () {
            if (this.isMobile) {
                this.isShow = !this.isShow
            }
        },
        continueToVisionTest () {
            const link = this.base_url + 'online-eye-exam-qualification'
            this.continueToNextPage(link, 'exam')
        },
        openVisionRedeem () {
            if (this.redirectTo) {
                this.createRewardTremendous({
                    value: this.getGiftcardConfigs(this.redeemItemType).tremendous_value,
                    type: this.redeemItemType
                })
            } else if (this.collect) {
                this.isGiftCardOpen = !this.isGiftCardOpen
            } else if (this.hideVisionRedeem && !this.isVisionRedeemed && this.previousvisit) {
                this.isVisionTestOpen = !this.isVisionTestOpen
            }
        },
        openEyeExamRedeem () {
            if (!this.isVisionRedeemed) {
                this.isEyeExamOpen = !this.isEyeExamOpen
            }
        },
        pendingBenefitCredit () {
            this.pending = !this.pending
        },
        redirectTDcctors(){
            window.open('/plus/location','_blank')
        }
    }
}
</script>

<style scoped>

</style>
