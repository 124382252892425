import _first from 'lodash/first'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import _concat from 'lodash/concat'
import _size from 'lodash/size'
import _cloneDeep from 'lodash/cloneDeep'
import _find from 'lodash/find'
import _defaults from 'lodash/defaults'
import {eventBus} from '../../app/RootState'
import {
    PRESCRIPTION_TYPE_ONLINE,
    PRESCRIPTION_TYPE_UPLOAD,
    PRESCRIPTION_TYPE_SAVED,
    PRESCRIPTION_TYPE_LATER,
    DEFAULT_VALUES_OBJECT,
    EVENT_RESET_AFTER,
    PARTNER_WITH_SIZE
} from '../../app/utils/constants'
import {
    MATCH_COLOR_ID,
    MATCH_COLOR_LABEL,
    LENS_NIGHT_FRAME_COLOR,
    LENS_NIGHT_FRAME_CODE
} from '../constants/gradients_constants'
import {
    setComputedData
} from '../../app/utils/buildData'

import {
    LENS_TYPE_ID,
    LENS_TYPE_ID_SUNGLASSES_LENSES,
    LENS_TYPE_ID_TRANSITIONS_LENSES
} from '../../app/common/constants/lens_type'

const _capitalize = value => value.charAt(0).toUpperCase() + value.slice(1)
const _keyToLabel = key => {
    let parts = key.split('_')

    parts = _map(parts, (part) => {
        if (['od', 'os', 'pd'].indexOf(part) === -1) {
            return _capitalize(part)
        } else {
            return part.toUpperCase()
        }
    })

    parts = _concat(parts)

    return parts.join(' ')
}

export const state = () => ({
    values: _cloneDeep(DEFAULT_VALUES_OBJECT),
    validation: false,
    isSending: false
})

export const getters = {
    values: state => state.values,
    currencySymbol: state => state.values.currency_symbol,
    lensCategory: state => state.values.lens_category,
    isConfigurator: (state, getters, rootState) => rootState.hasOwnProperty('configurator'),
    isContactDetailsPage: (state, getters, rootState, rootGetters) => rootGetters.hasOwnProperty('contacts/isContactDetailsPage') && rootGetters['contacts/isContactDetailsPage'],
    framesPartnerPage: state => state.values.frames_name || false,
    antiReflective: state => state.values.anti_reflective || false,
    antiReflectiveSymbol: state => state.values.anti_reflective_symbol || null,
    wasPrescriptionChanged: state => {
        const {lens, prescription_type} = state.values
        if (lens !== 'prescription') {
            return false
        }
        return state.values[prescription_type] === PRESCRIPTION_TYPE_ONLINE
    },
    isFramesSeparated: (state, getters, rootState, rootGetters) =>{
        if (!getters.framesPartnerPage) return false
        const framesName = getters.values.frames_name
        return  framesName.indexOf('bose') === -1 &&
            framesName.indexOf('spectacles') === -1 &&
            framesName.indexOf('willows') === -1 &&
            !getters.values.lensabl_frames
    },
    lensTypeStep: (state, getters, rootState, rootGetters) => getters.isConfigurator ? rootGetters['configurator/steps/getStepObject'](LENS_TYPE_ID) : false,
    framesProduct: (state, getters) => {
        const {product_sku = ''} = getters.values
        return product_sku ? product_sku.split('-').join('_') : undefined
    },
    framesPrice: (state, getters) => {
        const {product_sku = ''} = getters.values
        return product_sku ? product_sku.split('-').join('_') : undefined
    },
    productType: (state, getters) => getters.checkoutData.product,
    lensType: (state, getters) => {
        const lensType = getters.checkoutData.lens_type || {}
        return lensType.name || false
    },
    lensOption: (state, getters) => {
        const lensOption = getters.checkoutData.lens_options || {}
        return lensOption.name || false
    },
    prescriptionData: state => {
        let value
        const {lens, prescription_type, prescriptionValues} = state.values
        const {name} = prescriptionValues

        if (lens === 'reading') {
            value = prescriptionValues['lens_power']
        } else {
            value = prescriptionValues[prescription_type]
        }
        return {name, lens, prescription_type, value}
    },
    lensTypeColor: state => state.values.color.value,
    checkoutData: (state, getters, rootState, rootGetters) => {
        if (getters.isContactDetailsPage && rootGetters.hasOwnProperty('contacts/getEyeData')) {
            const eyeData = rootGetters['contacts/getEyeData']
            const subscribed = rootGetters['contacts/getSubscribed']
            const prescriptionValues = state.values.prescriptionValues
            const isSAvedRx = state.values.prescription === 'use_saved_rx'

            // const product_sku = productView.product_sku || ''
            const data = {
                right: {
                    product: '',
                    eye: '',
                    prescription: '',
                    qty: '',
                    product_sku: '',
                    color: ''
                },
                left: {
                    product: '',
                    eye: '',
                    prescription: '',
                    qty: '',
                    product_sku: '',
                    color: ''
                }
            }
            Object.keys(eyeData).forEach((item, index) => {
                const eye = eyeData[item]
                if (index === 0) {
                    // see <products> in app/code/local/Lensabl/SoftLens/etc/config.xml
                    data.right.product = eye.brand // eg 'biofinity'
                    data.right.eye = 'right'
                    data.right.qty = subscribed === 'yes' ? eye.default_sub_box_quantity : eye.boxes
                    data.right.properties = eye.rx
                    data.right.product_sku = eye.product_sku
                    data.right.color = eye.color
                    data.right.prescription_id = prescriptionValues.id && isSAvedRx ? prescriptionValues.id : null
                } else {
                    data.left.product = eye.brand
                    data.left.eye = 'left'
                    data.left.qty = subscribed === 'yes' ? eye.default_sub_box_quantity : eye.boxes
                    data.left.properties = eye.rx
                    data.left.product_sku = eye.product_sku
                    data.left.color = eye.color
                    data.left.prescription_id = prescriptionValues.id && isSAvedRx ? prescriptionValues.id : null
                }
            })
            const product = 'contacts' // product sku
            // let contacts_options = {} // if there is a color
            const upload = state.values.upload ? state.values.upload.data_url : false
            const box = {}
            data.right.contacts_options = false
            data.left.contacts_options = false
            const prescription_owner = state.values.prescription_owner
            const doctor_details = state.values.doctor_details

            return {
                data,
                product,
                box,
                doctor_details,
                subscribed,
                prescription_owner,
                upload
            }
        } else {
            const values = state.values
            let {
                lens,
                nonprescription,
                prescription_type,
                lens_type,
                lens_options,
                anti_reflective,
                anti_reflective_symbol,
                frames_name,
                frames_value,
                lens_category,
                frames_type = '',
                product_sku = false,
                frames_SKU = false,
                lensabl_frames = false,
                frame_price = 0,
                segmentation_height = false
            } = values
            const isClipOn = rootGetters['productView/isClipOnType']

            if (Array.isArray(lens_type)) {
                if (lens_type.join('') === LENS_TYPE_ID_TRANSITIONS_LENSES) {
                    lens_type = LENS_TYPE_ID_TRANSITIONS_LENSES
                } else {
                    lens_type = LENS_TYPE_ID_SUNGLASSES_LENSES
                }
            }

            if (lens === 'nonprescription') {
                lens = nonprescription
            }
            if (lens === 'sunglasses') {
                lens_type = LENS_TYPE_ID_SUNGLASSES_LENSES
            }

            if (lens === 'transitions') {
                lens_type = LENS_TYPE_ID_TRANSITIONS_LENSES
            }

            let lens_type_label = getters.getLensTypeCheckoutLabel(lens_type)

            let lens_options_label = getters.getOptionValueForStep('lens_options', lens_options)

            let product = null
            const custom_options = {}
            if (rootGetters['configurator/isSpectacles']) {
                custom_options.frames_name = rootGetters['productView/product_name']
                frames_SKU = rootGetters['productView/frames_SKU']
            }

            if (frames_name && frames_value) {
                custom_options.frames_name = frames_name
                if (PARTNER_WITH_SIZE.indexOf(frames_name) !== -1) {
                    custom_options.frames_size = frames_value
                } else {
                    custom_options.frames_color = frames_value
                }
                custom_options.frames_value = frames_value
                custom_options.product_sku = product_sku
                custom_options.frames_SKU = frames_SKU
                custom_options.lensabl_frames = lensabl_frames
                custom_options.lens_category = lens_category
                custom_options.is_clipon = isClipOn
                custom_options.frames_price = frame_price
                if (lensabl_frames && segmentation_height) {
                    custom_options.os_sh = segmentation_height
                    custom_options.od_sh = segmentation_height
                }
            }

            if (lens === 'sunglasses' || lens === 'transitions') {
                lens_options_label = 'Poly'
            } else if (lens === 'computer' && lensabl_frames) {
                lens_options_label = 'Poly'
                lens_type_label = 'Blue-Light Blocking'
            } else if (lens === 'computer') {
                lens_options_label = 'Poly'
                lens_type_label = 'Clear'
            } else if (lens === 'plano' && lensabl_frames) {
                lens_options_label = 'Poly'
                lens_type_label = 'Blue-Light Blocking'
            } else if (lens === 'plano') {
                lens_options_label = 'Poly'
                lens_type_label = 'Clear'
            }
            const colorItem = rootGetters['productView/colorItem']
            if (!lens_type) {
                if (frames_name) {
                    if (typeof frames_name === 'string' && (rootGetters['configurator/isSpectacles'] || rootGetters['productView/isSpectaclesGen']) && colorItem) {
                        lens_type = {
                            'name': colorItem.lens_type,
                            'color': colorItem.color
                        }
                    }
                } else {
                    lens_type = {
                        'name': lens_type_label,
                        'color': getters.getLensTypeColorLabel(lens_type)
                    }
                }
            } else if (typeof lens_type === 'object') {
                if (Object.keys(lens_type).length < 2) {
                    lens_type = {
                        'name': lens_type_label,
                        'color': getters.getLensTypeColorLabel(lens_type)
                    }
                }
            } else if (getters.values.frames_name.toLowerCase().indexOf('goodr') !== -1 && getters.getLensTypeColorLabel(lens_type) === 'Red') {
                lens_type = {
                    'name': lens_type_label,
                    'color': getters.getLensTypeColorLabel(lens_type),
                    'color_name': 'Amber'
                }
            } else if (getters.values.frames_name.toLowerCase().indexOf('goodr') !== -1 && getters.getLensTypeColorLabel(lens_type) === 'Green') {
                lens_type = {
                    'name': lens_type_label,
                    'color': getters.getLensTypeColorLabel(lens_type),
                    'color_name': 'Teal'
                }
            } else {
                lens_type = {
                    'name': lens_type_label,
                    'color': getters.getLensTypeColorLabel(lens_type)
                }
            }

            const antiReflectiveLensFlag = anti_reflective
            const antiReflectiveSymbol = anti_reflective_symbol

            if (antiReflectiveLensFlag && !lens_options_label && rootGetters['configurator/isSpectacles']) {
                const label = 'Poly'
                lens_options_label = `${label} + Polarized`
            } else if (antiReflectiveLensFlag && lens_options_label && rootGetters['configurator/isSpectacles']) {
                lens_options_label = `${lens_options_label} + Polarized`
            } else if (antiReflectiveLensFlag && lens_options_label && !rootGetters['configurator/isSpectacles']) {
                lens_options_label = `${lens_options_label} + ${antiReflectiveSymbol}`
            }

            lens_options = {
                'name': lens_options_label
            }

            let upload = false
            let prescription = null

            if (['reading'].indexOf(lens) > -1) {
                product = `${lens}_lens`
                prescription = values.prescriptionValues['lens_power']
            } else if (lens === 'sunglasses') {
                product = 'sunglass_lens'
                prescription = {}
            } else if (lens === 'transitions') {
                product = 'transition_lens'
                prescription = {}
            } else if (lens === 'computer') {
                product = 'computer_lens'
                prescription = {}
            } else if (lens === 'plano') {
                product = 'plano_lens'
                prescription = {}
            } else if (frames_type === 'frames_standard' && (frames_name && frames_value)) {
                product = getters.framesProduct
                lens_type = {
                    'name': 'Frames',
                    'color': false
                }
                lens_options = {
                    'name': frames_value
                }
            } else {
                prescription_type = values['prescription_type']
                if (!prescription_type) {
                    prescription_type = getters.getFirstAvailableOptionId('prescription_type')
                }

                product = prescription_type
                prescription = values.prescriptionValues[prescription_type]
                const prescriptionValue = values[prescription_type]
                const prescriptionPrism = values.prescriptionValues[prescription_type] ? values.prescriptionValues[prescription_type].prism : false

                if (prescriptionValue === PRESCRIPTION_TYPE_LATER) {
                    prescription = {
                        'prescription': 'Provide It Later'
                    }
                } else if (prescriptionValue === PRESCRIPTION_TYPE_ONLINE || prescriptionValue === PRESCRIPTION_TYPE_SAVED) {
                    prescription = state.values.newPrescription ? state.values.newPrescription.value : values.prescriptionValues[prescription_type]
                    if (prescription) {
                        prescription.prescription_name = state.values.newPrescription
                            ? state.values.newPrescription.name
                            : state.values.prescriptionValues.name || 'Default'
                    }
                } else if (prescriptionValue === PRESCRIPTION_TYPE_UPLOAD) {
                    prescription = {}
                    upload = state.values.prescriptionValues.upload.data_url || false
                    custom_options.pd_in_rx = values.pd_in_rx
                } else {
                    prescription = values.prescriptionValues[prescriptionValue]
                }

                if (prescription && prescriptionPrism && prescriptionValue !== PRESCRIPTION_TYPE_ONLINE) {
                    const dataPrescription = state.values.newPrescription ? state.values.newPrescription.value : values.prescriptionValues[prescription_type]

                    dataPrescription.prescription_name = state.values.newPrescription
                        ? state.values.newPrescription.name
                        : state.values.prescriptionValues.name || 'Default'
                    Object.assign(prescription, dataPrescription)
                }
            }

            const properties = getters.convertPrescriptionIntoProperties(prescription)

            const box = state.values.box.code
            custom_options.notes = state.values.notes
            custom_options.prescription_id = values.prescriptionValues.id || ''

            custom_options.addition_items = {}
            const upgrades = Object.assign({}, state.values.upgrades)
            const warranty = Object.assign({}, state.values.warranty)
            const warrantyItem = Object.keys(warranty)
            if (warrantyItem && warrantyItem.length > 0) {
                custom_options.addition_items['warranty'] = warrantyItem
            }
            const upgradesItem = Object.keys(upgrades)
            if (upgradesItem && upgradesItem.length > 0) {
                custom_options.addition_items['upgrades'] = upgradesItem
            }

            if (prescription && Object.keys(prescription).length > 0) {
                const odPrism = prescription.od_prism
                if (odPrism && odPrism.length > 1) {
                    custom_options.addition_items['od_prism'] = 1
                }
                const osPrism = prescription.os_prism
                if (osPrism && osPrism.length > 1) {
                    custom_options.addition_items['os_prism'] = 1
                }
            }

            return {
                product,
                lens_type,
                lens_options,
                properties,
                upload,
                box,
                custom_options,
                upgrades,
                warranty
            }
        }
    },
    framesValue: (state, getters, rootState, rootGetters) => {
        const frames_name = rootGetters['frames_name'] || false
        const frames_value = rootGetters['frames_value'] || false
        const product_sku = rootGetters['product_sku'] || false
        const frames_SKU = rootGetters['frames_SKU'] || false
        if (frames_value && frames_name) {
            return {frames_name, frames_value, frames_SKU, product_sku}
        }
        return false
    },

    getLensTypeCheckoutLabel: (state, getters) => lens_type => {
        const step = getters.lensTypeStep

        if (!step) {
            return false
        }

        const {component} = step
        const options = component.options
        const option = _find(options, option => option.id === lens_type)

        if (!option) {
            return false
        }

        let checkoutLabel = option.checkout_value
        if (checkoutLabel) {
            return checkoutLabel
        }

        const subComponent = option.component_extra_options
        if (!subComponent) {
            return false
        }

        const sections = subComponent.params.sections
        if (!sections) {
            return false
        }

        const color_value = getters.lensTypeColor
        const section = _find(sections, section => {
            const colors = section.colors
            const color = _find(colors, color => color.id === color_value)
            if (color) {
                checkoutLabel = color.checkout_value
            }
            return !!color
        })

        if (!section) {
            return false
        }
        if (checkoutLabel) {
            return checkoutLabel
        }
        checkoutLabel = section.checkout_value

        const isMatchColor = color_value.indexOf(MATCH_COLOR_ID) !== -1
        const isPolarizedGradient = color_value.indexOf('polarized-gradient') !== -1

        checkoutLabel = isMatchColor ? checkoutLabel + MATCH_COLOR_LABEL : checkoutLabel

        if (isPolarizedGradient) {
            checkoutLabel = checkoutLabel + ' Gradient'
        }

        return checkoutLabel || false
    },
    getOptionValueForStep: (state, getters, rootState, rootGetters) => (step_id, option_id) => {
        const step = getters.isConfigurator ? rootGetters['configurator/steps/getStepObject'](step_id) : false
        if (!step) {
            return false
        }
        return getters.getOptionLabel(step.component, option_id)
    },
    getOptionLabel: () => (component, id) => {
        const options = component.options

        if (options && Array.isArray(options)) {
            const option = _find(options, option => option.id === id)
            if (option) {
                const {checkout_value, value} = option
                return checkout_value || value
            }
        }
    },
    getLensTypeColor: (state, getters) => lens_type => {
        lens_type = lens_type || state.values.lens_type
        const color_value = state.values.color ? state.values.color.value : null
        const color_level = state.values.color ? state.values.color.level : null
        const step = getters.lensTypeStep
        if (getters.isFramesEENight) {
            return LENS_NIGHT_FRAME_COLOR
        }
        if (!step) {
            return false
        }
        const options = step.component.options

        if (!options || !Array.isArray(options)) {
            return false
        }

        const option = _find(options, option => option.id === lens_type)
        if (!option) {
            return false
        }

        const extra_component = option.component_extra_options
        if (!extra_component) {
            return false
        }

        const extra_options = extra_component.params
        const sections = extra_options.sections
        if (!sections) {
            return false
        }

        for (const section of sections) {
            let color = false
            const colors = section.colors
            if (colors.length) {
                color = _find(colors, color => color.id === color_value)
            }

            if (color) {
                let label = color.label
                if (color_level) {
                    label += ' ' + color_level
                }
                return label
            }
        }

        return false
    },
    getLensTypeColorLabel: (state, getters) => lens_type => {
        return getters.getLensTypeColor(lens_type) || false
    },
    getFirstAvailableOption: () => step => {
        const options = step.component.options
        if (!options) {
            return false
        }

        // TODO: It will fail of we disable 'Clear' option
        const option = _first(options, (option) => option.id === step.value)
        if (!option) {
            return false
        }

        return option
    },
    getFirstAvailableOptionId: (state, getters, rootState, rootGetters) => stepId => {
        const step = getters.isConfigurator ? rootGetters['configurator/steps/getStepObject'](stepId) : false
        if (!step) {
            return false
        }
        const option = getters.getFirstAvailableOption(step)

        return option ? option.id : false
    },
    convertPrescriptionIntoProperties: () => prescription => {
        const properties = {}
        const keys = _keys(prescription)
        if (keys.length === 0) {
            return properties
        }

        const valueSeparatePd = prescription['separate_pd']

        if (valueSeparatePd) {
            prescription['pd'] = ''
        } else {
            prescription['os_pd'] = ''
            prescription['od_pd'] = ''
        }

        for (const key of keys) {
            if (key.indexOf('separate') !== -1) {
                continue
            }
            const value = prescription[key]

            const label = _keyToLabel(key)

            if (label.indexOf('PD') !== -1) {
                const pdValue = parseFloat(value)
                properties[label] = isNaN(pdValue) ? '' : value
            } else {
                properties[label] = value
            }
        }
        return properties
    },
    getPreparePrescriptionName: () => data => {
        let name = data.name
        if (!name) {
            name = 'Default'
        }
        data.name = name
        return data
    },
    isLensReplacementPage: (state, getters) => !getters.framesPartnerPage && state.values.prescription_type !== 'contacts',
    isFramesEENight: (state, getters) => getters.framesPartnerPage && getters.framesPartnerPage === LENS_NIGHT_FRAME_CODE
}

export const mutations = {
    updateValues: (state, values) => state.values = values,
    setDataToValues: (state, data = {}) => setComputedData(state.values, data),
    resetDataToValues: (state, data = {}) => setComputedData(state.values, data, true),

    IS_SENDING: (state, isSending) => state.isSending = isSending,
    START_VALIDATION: state => state.validation = true,
    END_VALIDATION: state => state.validation = false,
    setFramesName: (state, payload) => state.values.frames_name = payload,
    setNotes: (state, payload) => state.values.notes = payload
}

export const actions = {
    fetchDefaultData: ({commit, getters, rootGetters}) => {
        let values
        if (getters.isConfigurator) {
            values = !getters.isContactDetailsPage ? rootGetters['configurator/steps/defaultValues']() : rootGetters['configurator/steps/getContactsDefaults']()
        } else {
            values = _cloneDeep(DEFAULT_VALUES_OBJECT)
        }
        commit('setDataToValues', values)
    },
    setPrescription: ({commit, dispatch, getters, rootGetters}, data = {}) => {
        if (!_size(data)) {
            return this
        }
        const {id, name, lens, prescription_type, value} = data

        commit('setDataToValues', {
            prescriptionValues: {
                id: id,
                name: name
            }
        })

        if (lens === 'prescription') {
            commit('setDataToValues', {
                prescription_type: prescription_type
            })

            const lens_type = getters.values.lens_type
            if (!lens_type) {
                commit('setDataToValues', {
                    lens_type: getters.getFirstAvailableOptionId('lens_type')
                })
            }
            const lens_options = getters.values.lens_options
            if (!lens_options) {
                commit('setDataToValues', {
                    lens_options: getters.getFirstAvailableOptionId('lens_options')
                })
            }
        }

        const stateValues = getters.values
        if (lens) {
            stateValues.lens = lens
        }

        let switchTo = false

        if (lens === 'reading') {
            switchTo = 'lens_power'
            stateValues.prescriptionValues.lens_power = value
        } else {
            if (getters.isContactDetailsPage) {
                dispatch('contacts/setPrescription', value)
            } else {
                const defaults = _cloneDeep(DEFAULT_VALUES_OBJECT)
                switchTo = prescription_type
                const prescriptionDefaults = defaults.prescriptionValues[prescription_type]
                stateValues.prescriptionValues[prescription_type] = _defaults(value, prescriptionDefaults)
            }
        }

        commit('setDataToValues', stateValues)
        if (switchTo) {
            dispatch('configurator/steps/switchToStep', getters.isConfigurator ? rootGetters['configurator/steps/getStepObject'](switchTo) : {}, {root: true})
        }
    },
    savePrescription: ({commit, getters, rootGetters}) => {
        return new Promise((resolve, reject) => {
            const id = getters.values.prescriptionValues.id
            let data = getters.prescriptionData
            data = getters.getPreparePrescriptionName(data)

            commit('IS_SENDING', true)

            let savePromise
            if (id) {
                savePromise = rootGetters['prescriptions/service'].update(id, data)
            } else {
                savePromise = rootGetters['prescriptions/service'].create(data)
            }

            savePromise
                .then(response => {
                    if (response.id) {
                        commit('setDataToValues', {
                            prescriptionValues: {
                                id: response.id,
                                name: response.name
                            },
                            newPrescription: response
                        })
                    }
                    resolve(response)
                })
                .catch((error) => {
                    const {status} = error

                    if (status === 401) {
                        document.location.href = '/lensabl/prescription/login'
                    } else {
                        reject(error)
                    }
                })
        })
    },
    resetCheckout: () => {
        return new Promise((resolve) => {
            eventBus.$emit(EVENT_RESET_AFTER)
            resolve()
        })
    }
}

export default store => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
