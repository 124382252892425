<template>
    <div class="online-optometry" data-smooth-scroll>
        <section class="online-optometry__hero">
            <div class="online-optometry">
                <div class="online-optometry__container">
                    <div class="hero-image__background">
                        <div class="hero-video-button__row">
                            <div class="online-optometry__layout">
                                <div class="online-optometry__copy">
                                    <div style="overflow: hidden;height: 0;width: 0;">
                                        <meta property="og:image" content="https://www.lensabl.com/media/wysiwyg/cms/lensabl-opternative-hero-image-2.jpg" />
                                        <img src="https://www.lensabl.com/media/wysiwyg/cms/lensabl-opternative-hero-image-2.jpg" alt="lensabl-opternative-hero-image-2" />
                                    </div>
                                    <h1>Online Vision Test</h1>
                                    <p class="online-optometry__copy-primary">Take an online vision test to renew your prescription from the comfort of your home through the user-friendly Lensabl website.</p>
                                    <p class="online-optometry__copy-primary">Our online vision test for contacts and glasses gets you a fast, <b>affordable doctor-issued prescription for just $25 &mdash;</b></p>

                                    <section v-if="!plusMember" class="lenses-lplus-ad-block_container vision-lplus-ad-block_container">
                                        or join <a href="/plus"><span style="text-decoration: underline">Lensabl+</span></a> for a <b>FREE vision test &mdash;</b>
                                        <div class="lenses-lplus-ad-block">
                                            <a class="lplus-ad-learn-link">Learn More</a>
                                            <span class="help-bubble-container lplus-help-bubble-container">
                                                <div class="help-component">
                                                    <div id="help_bubble_text" class="help-bubble lplus-help-bubble">
                                                        <span>
                                                            <p>A new, better alternative to traditional vision insurance plans, starting as low as <b>$7.99/month.</b></p>
                                                            <p>Lensabl+ plans include a FREE online vision test, an annual supply of contacts, and a FREE frame with premium Rx lenses. Save hundreds of dollars out-of-pocket per year on your vision care needs. <span class="c-help-bubble-link">Customize your plan now</span></p>
                                                        </span>
                                                        <span class="close-button">
                                                            <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="svg_1" d="m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z" fill="rgb(102, 102, 102)" fill-rule="evenodd" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </section>
                                    <div class="hero-video-button__buttons">
                                        <a href="/online-eye-exam-qualification">
                                            <div class="hero-video-button__button-primary" style="text-align: center;">
                                                <div class="button button--copy-solid-dark">
                                                    <div class="button__container">Get Started Now</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="home__hero-btn home__hero-btn--arrow" href="#how-the-online-exam-works">
                <div class="button button--icon">
                    <div class="button__icon button__icon--base">
                        <svg>
                            <use xlink:href="#lensabl-arrow-medium-down" />
                        </svg>
                    </div>
                    <div class="button__icon button__icon--hover">
                        <svg>
                            <use xlink:href="#lensabl-arrow-medium-down" />
                        </svg>
                    </div>
                </div>
            </a>
        </section>
        <section id="how-the-online-exam-works" class="custom-callout__content reverse" style="background-color:#ffffff; padding: 4rem 0 0; margin: 0; max-width: 100%;">
            <div class="row">
                <div class="columns small-18 medium-push-3 medium-12">
                    <h2>How The Vision Test Works</h2>
                    <p>Lensabl makes it easier than ever to renew your vision prescription for contacts and glasses in 24 hours or less by following these steps:</p>
                </div>
            </div>
        </section>
        <section class="opternative-icon-wrapper">
            <div class="opternative-steps">
                <div class="custom-content-block">
                    <div class="">
                        <div class="opternative-icon step-image-1"></div>
                    </div>
                    <div class="">
                        <span class="custom-half-graphic-copy__colored-bg custom-half-graphic-copy__colored-bg--number custom-half-graphic-copy__colored-bg--green">Step 1</span>
                        <h3>Discover if you qualify</h3>
                    </div>
                </div>
            </div>
            <div class="opternative-steps">
                <div class="custom-content-block">
                    <div class="">
                        <div class="opternative-icon step-image-2"></div>
                    </div>
                    <div class="">
                        <span class="custom-half-graphic-copy__colored-bg custom-half-graphic-copy__colored-bg--number custom-half-graphic-copy__colored-bg--green">Step 2</span>
                        <h3>Undergo the test from your computer or smartphone</h3>
                    </div>
                </div>
            </div>
            <div class="opternative-steps">
                <div class="custom-content-block">
                    <div class="">
                        <div class="opternative-icon step-image-3"></div>
                    </div>
                    <div class="">
                        <span class="custom-half-graphic-copy__colored-bg custom-half-graphic-copy__colored-bg--number custom-half-graphic-copy__colored-bg--green">Step 3</span>
                        <h3>Await a response from a certified doctor</h3>
                    </div>
                </div>
            </div>
            <div class="opternative-steps">
                <div class="custom-content-block">
                    <div class="">
                        <div class="opternative-icon step-image-4"></div>
                    </div>
                    <div class="">
                        <span class="custom-half-graphic-copy__colored-bg custom-half-graphic-copy__colored-bg--number custom-half-graphic-copy__colored-bg--green">Step 4</span>
                        <h3>Receive your prescription</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="our-labs__call-to-action">
            <div class="custom-callout parallax-trigger">
                <div class="struct-parallax-bg">
                    <div class="custom-callout__content">
                        <div class="custom-callout__layout">
                            <h2 style="color:#2f3948">Ready to start seeing clearly?</h2>
                            <a href="/online-eye-exam-qualification">
                                <div class="custom-callout__button">
                                    <div class="button button--copy-solid">
                                        <div class="button__container">Get Started For FREE Now</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <OnlineVisionSeo />
        <section class="our-labs__call-to-action">
            <div class="custom-callout parallax-trigger">
                <div class="struct-parallax-bg">
                    <div class="custom-callout__content">
                        <div class="custom-callout__layout">
                            <p style="color:#666666">Visibly is not a replacement for a comprehensive eye health examination. Our licensed Ophthalmologists and Optometrists use Visibly's online technology to evaluate a patient's visual acuity and a portion of the ocular health profile and issue a prescription for corrective eyewear, where clinically appropriate. Visibly's services are limited to patients between the ages of 18 and 50 who are in good health. Because Visibly's services are not a replacement for an eye health examination, we encourage everyone to obtain a comprehensive eye exam at least once every 2 years. We prohibit patients from taking a Visibly exam more than 4 consecutive years without certifying that they have received a comprehensive eye test first.<a href="https://www.lensabl.com/choose-my-lenses"> Get replacement lens</a> or <a href="https://www.lensabl.com/prescription-sunglass-lenses">replacement sunglass lenses</a> </p>
                            <p style="color:#666666">Visibly Digital Acuity Product is manufactured and distributed by Visibly, Inc. and operates under the <a href="https://www.fda.gov/media/136733/download">Enforcement Policy for Remote Ophthalmic Assessment and Monitoring Devices during the CoronaVirus Disease (COVID-19) Public Health Emergency</a> issued by the FDA in April 2020. This product has not been approved by the FDA. The data and recommendations made by the Visibly Digital Acuity Product are adjunctive (supporting) recommendations that will be used by an Eye Care Provider, along with your medical history and profile, prior corrective eyewear prescriptions, and subjective data provided about your vision. It is the responsibility of the patient to provide accurate information through the process as all of this information will be relied upon as accurate by the reviewing Eye Care Provider.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import OnlineVisionSeo from '../seo/OnlineVision/OnlineVisionSeo.vue'

export default {
    name: 'OnlineVision',
    components: {
        OnlineVisionSeo
    },
    computed: {
        ...mapGetters('lensablplus_customer', ['plusMember'])
    }
}
</script>

<style scoped>

</style>
