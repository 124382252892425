<template>
    <div class="nonplus_container">
        <div>
            <h2>Join Lensabl+ Today!</h2>
            <p class="plus-description">The first all online vision benefits platform. Plans include an annual supply of contact lenses, a free pair of prescription glasses, and discounts for other optical products and services, including an eye exam.</p>
        </div>
        <div>
            <ul>
                <li class="plan-price">
                    <img src="/js/lensconfigurator/assets/dollar-sign.svg" alt="dollar sign" />
                    <p>Plans starting as low as <span>$7.99/month, saving you hundreds of dollars out-of-pocket per year compared to traditional vision insurance plans.</span></p>
                </li>
                <li class="plan-redeem">
                    <img src="/js/lensconfigurator/assets/computer.svg" alt="computer" />
                    <p>Online redemption of your benefits makes getting your glasses and contacts <span>delivered to your door fast and easy, all without leaving home.</span></p>
                </li>
                <li class="plan-glasses">
                    <img src="/js/lensconfigurator/assets/glasses.svg" alt="glasses" />
                    <p>Plans include a <span>FREE pair of prescription glasses,</span> including frames and polycarbonate lenses with <span>Blue-Light/UV protection, anti-reflective and anti-scratch coatings.</span></p>
                </li>
                <li class="plan-doctor">
                    <img src="/js/lensconfigurator/assets/contacts.svg" alt="contact lenses" />
                    <p>For contact lens wearers, our plans include a <span>full annual supply of your prescribed contact in addition to free glasses</span> -- no need to choose between one or the other.</p>
                </li>
                <li class="plan-contacts">
                    <img src="/js/lensconfigurator/assets/people.svg" alt="doctors" />
                    <p>Members can choose to go to <span>ANY eye doctor</span>, rather than being limited to a network or <span>take our online vision test from home.</span></p>
                </li>
            </ul>
        </div>
        <div class="secondary">
            <a href="/why-plus">
                <BaseButton data-size="xs" text="FIND OUT MORE" class="button button--copy-icon" />
            </a>
        </div>
        <div class="secondary">
            <a href="/plus/vision_plan">
                <BaseButton data-size="xs" text="GET STARTED" class="button button--copy-solid" />
            </a>
        </div>
    </div>
</template>

<script>
import BaseButton from '../../../components/base/BaseButton.vue'
export default {
    name: 'NonLensablPlus',
    components: {
        BaseButton
    }
}
</script>

<style scoped>

</style>
