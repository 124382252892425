var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ],
      staticClass: "lensabl-plus-content business-dashboard"
    },
    [
      _vm.config.title
        ? _c("h3", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.title,
                expression: "config.title"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "description" }, [
        _vm._v("\n        You have selected the 2021 "),
        _c("span", { staticClass: "plan-name" }, [
          _vm._v(" " + _vm._s(_vm.plan.label) + " ")
        ]),
        _vm._v(
          " Plan, you can add dependents below if you like or continue without any:\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pricing_container" }, [
        _c("table", [
          _vm._m(0),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n                        Only you\n                    "
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _c("strong", [_vm._v(" $" + _vm._s(_vm.plan.price) + " ")]),
                _vm._v(" per month\n                    ")
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n                        You + 1 dependent\n                    "
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _c("strong", [
                  _vm._v(
                    " $" +
                      _vm._s(
                        _vm.hasDecimal(_vm.dependentPricing["price_dependent"])
                          ? _vm.dependentPricing["price_dependent"].toFixed(2)
                          : _vm.dependentPricing["price_dependent"]
                      ) +
                      " "
                  )
                ]),
                _vm._v(" per month\n                    ")
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n                        You + 2 or more dependents\n                    "
                )
              ]),
              _vm._v(" "),
              _c("td", [
                _c("strong", [
                  _vm._v(
                    " $" +
                      _vm._s(
                        _vm.hasDecimal(_vm.dependentPricing["price_multiple"])
                          ? _vm.dependentPricing["price_multiple"].toFixed(2)
                          : _vm.dependentPricing["price_multiple"]
                      ) +
                      " "
                  )
                ]),
                _vm._v(" per month\n                    ")
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.num > 0
        ? _c("p", { staticClass: "dependent-optional" }, [
            _vm._v("\n        DEPENDENT DETAILS\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dependents_table" },
        [
          _vm.dependents.length > 1
            ? _c(
                "div",
                { staticClass: "dropdown-component contacts_rx-container" },
                [
                  _c(
                    "div",
                    { staticClass: "contacts_rx-dropdown-container" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "contacts_rx-dropdown-value",
                          on: { click: _vm.toggleDropdown }
                        },
                        [
                          _c(
                            "span",
                            { class: { active: _vm.showDependents } },
                            [
                              _vm._v(
                                "\n                        Dependent " +
                                  _vm._s(_vm.currentIndex + 1) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "slide" } }, [
                        _vm.showDependents
                          ? _c(
                              "div",
                              {
                                staticClass: "contacts_rx-dropdown-list",
                                class: { active: _vm.showDependents }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "contacts_rx-dropdown-wrapper"
                                  },
                                  _vm._l(_vm.dependents, function(
                                    dependent,
                                    index
                                  ) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "contacts_rx-dropdown-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.updateDisplayed(index)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "Dependent " + _vm._s(index + 1)
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dependents-table" },
            _vm._l(_vm.dependents, function(dependent, index) {
              return _c(
                "section",
                {
                  key: "section-" + index,
                  staticClass: "dependent-table",
                  class: { active: _vm.currentIndex === index }
                },
                [
                  _vm._l(dependent, function(d, key, i) {
                    return _c(
                      "div",
                      { key: index + "-" + key },
                      [
                        _c("label", { attrs: { for: index + "-" + key } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.dependent_table[i].label) +
                              ":\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        key !== "birthday"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: dependent[key],
                                  expression: "dependent[key]"
                                }
                              ],
                              staticClass: "input-text",
                              class: {
                                "required-entry":
                                  _vm.dependent_table[index].required,
                                "validation-failed": _vm.errors[index][key]
                              },
                              attrs: {
                                id:
                                  index + "-membership-billing_address_" + key,
                                type: "text"
                              },
                              domProps: { value: dependent[key] },
                              on: {
                                blur: function($event) {
                                  return _vm.validBlurField(index, key)
                                },
                                focus: function($event) {
                                  return _vm.updateDependent(index)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(dependent, key, $event.target.value)
                                }
                              }
                            })
                          : [
                              _c("date-picker", {
                                staticClass: "input-text",
                                attrs: {
                                  placeholder: "MM-DD-YYYY",
                                  "value-type": "format",
                                  "input-class": {
                                    "required-entry":
                                      _vm.dependent_table[i].required,
                                    "validation-failed": _vm.errors[index][key]
                                  },
                                  type: "text",
                                  format: "MM-DD-YYYY"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.validBlurField(
                                      index,
                                      _vm.dependent_table[i].name
                                    )
                                  },
                                  focus: function($event) {
                                    return _vm.updateDependent(index)
                                  }
                                },
                                model: {
                                  value: dependent.birthday,
                                  callback: function($$v) {
                                    _vm.$set(dependent, "birthday", $$v)
                                  },
                                  expression: "dependent.birthday"
                                }
                              })
                            ],
                        _vm._v(" "),
                        _vm.errors[index][key]
                          ? _c(
                              "div",
                              {
                                staticClass: "validation-advice",
                                attrs: {
                                  id:
                                    "advice-required-entry-" +
                                    _vm.errors[index][key]
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.dependent_table[i].message) +
                                    "\n                    "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "remove" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.removeDependent(index)
                          }
                        }
                      },
                      [_vm._v("REMOVE")]
                    )
                  ])
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text-center" }, [
            _c(
              "span",
              { staticClass: "add", on: { click: _vm.addDependent } },
              [_vm._v("+ ADD dependent")]
            ),
            _vm._v(" "),
            _vm.dependentError
              ? _c(
                  "span",
                  {
                    staticClass: "validation-advice",
                    attrs: { id: "advice-required-entry-dependent-error" }
                  },
                  [_vm._v("Finish adding dependent")]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "vue-modaltor",
            {
              staticClass: "small",
              attrs: {
                visible: _vm.showWarning,
                "default-width": "450px",
                "bg-overlay": "#000",
                "bg-panel": "#fff",
                "close-scroll": false
              },
              on: { hide: _vm.hideModal }
            },
            [
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "\n                    The dependents without emails will access their account through the main account holder's email address\n                "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("BaseButton", {
                staticClass: "button--small",
                attrs: { text: "CONFIRM" },
                nativeOn: {
                  click: function($event) {
                    return _vm.continueAction($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "actions-toolbar" }, [
        _c(
          "div",
          { staticClass: "primary" },
          [
            _c("BaseButton", {
              staticClass: "button--copy-solid-white-blue button--small",
              attrs: { text: "Continue" },
              nativeOn: {
                click: function($event) {
                  return _vm.continueNxt($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", { attrs: { colspan: "2" } }, [_vm._v("PRICING")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }