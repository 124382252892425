var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "color_selectors" },
      [
        _c(
          "ul",
          { staticClass: "color-selections" },
          [
            _vm._l(_vm.sections, function(section) {
              var _obj
              return [
                _c(
                  "li",
                  {
                    key: section.id,
                    staticClass: "section-btn",
                    class: [section.id + "-li"]
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "button button--section button--copy-solid-grey",
                        class:
                          ((_obj = {}),
                          (_obj[section.id] = section.id),
                          (_obj.active = section.id === _vm.chosenSection),
                          _obj),
                        attrs: { "data-name": section.label },
                        on: {
                          click: function($event) {
                            return _vm.chooseSectionDefault(section)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "button__container",
                            class: section.label
                          },
                          [
                            _c("span", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: section.label,
                                  expression: "section.label"
                                }
                              ]
                            })
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(_vm.currentSectionColorComponent, {
          tag: "component",
          class: _vm.currentSection.id,
          attrs: {
            id: _vm.id,
            sections: _vm.sections,
            "current-section": _vm.currentSection,
            "lens-prefix": _vm.lensPrefix
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }