<template>
    <Signup v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import Signup from '../../components/membership/Signup.vue'
export default {
    name: 'RouterMembershipSignup',
    components: {
        Signup
    },
    extends: App
}
</script>

<style scoped>

</style>
