<template>
    <section :class="[adData.containerClassName, window.width < 640 && productView.productConfiguratorShow ? 'hide-section' : '']">
        <div class="lplus-ad-block_container" @click="openPlusAdModal">
            <a :href="adLink">
                <img
                    v-if="isShopFramesBrand"
                    :src="adData.adImgUrl"
                    alt="Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                    class="lplus-ad-block_img"
                />
                <img
                    v-else
                    :src="window.width < 768 ? adData.adImgUrlMobile : adData.adImgUrl"
                    alt="Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                    class="lplus-ad-block_img"
                />
                <span class="help-bubble-container lplus-help-bubble-container">
                    <div class="help-component">
                        <div id="help_bubble_text" :class="showModalAd ? 'open' : ''" class="help-bubble lplus-help-bubble">
                            <span v-dompurify-html="adData.adhoverTextHtml"></span>
                            <span class="close-button" @click.stop="closePlusAdModal">
                                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                                    <path id="svg_1" d="m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z" fill="rgb(102, 102, 102)" fill-rule="evenodd" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </span>
            </a>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import {
    SHOP_FRAMES_BRANDS
} from '../../app/utils/constants'

export default {
    name: 'ImageHelpBubble',
    props: {
        adData: {
            type: Object,
            default: () => {}
        }
    },
    data: () => {
        return {
            showModalAd: false,
            adLink: '/plus/vision_plan',
            window: {
                width: 0,
                height: 0
            }
        }
    },
    computed: {
        ...mapGetters('productView', ['productCategoryName', 'productView']),

        // this is called in CatalogProductLensablView.vue so please make that computed property reactive in the future
        isShopFramesBrand () {
            const currentProductCategory = this.productCategoryName.toLowerCase()
            return Boolean(SHOP_FRAMES_BRANDS.includes(currentProductCategory))
        }
    },
    created () {
        window.addEventListener('resize', this.onResize)
        // to get initial window size
        this.onResize()
    },
    destroyed () {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        openPlusAdModal () {
            const windowRes = window.innerWidth

            if (windowRes < 768) {
                this.showModalAd = true
                this.adLink = null
            } else {
                this.showModalAd = false
                this.adLink = '/plus/vision_plan'
            }
        },
        closePlusAdModal () {
            this.showModalAd = false
        },
        onResize () {
            this.window.width = window.innerWidth
            this.window.height = window.innerHeight
        }
    }
}
</script>

<style scoped>
.hide-me {
    display: none;
}
</style>
