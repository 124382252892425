import {gsap, TimelineMax} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'

// @todo need to check
gsap.install(window)
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const tl = gsap.timeline()
const intro = gsap.timeline()
const drawtl = new TimelineMax({delay: 10})

const getDefaultState = () => {
    return {
        previousvisit: true
    }
}
// initial state
const state = getDefaultState()
const mutations = {
    UPDATE_PREVIOUS_VISIT: (state, data) => state.previousvisit = data
}
const getters = {}
const actions = {
    fetchPreviousLoad: ({commit}) => {
        tl.to('.notification-mobile', {
            opacity: 1,
            scale: 1,
            padding: 0,
            duration: 1,
            ease: 'linear'
        })
        tl.to('.desktop-notification-card-top', {
            opacity: 1,
            height: '100%',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('#animate-contacts-msg', {
            height: 0,
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.lplus-card', {
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '')
        tl.to('.customer-account_top', {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.account', {
            background: '#f6f6f6',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.struct-account-container', {
            position: 'relative',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.skip-desktop', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.square', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        tl.to('.billing__account-wrap', {
            borderColor: '#ececec',
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.main-card-notification-card', {
            opacity: 0,
            display: 'none',
            duration: 2,
            onComplete: () => {
                commit('UPDATE_PREVIOUS_VISIT', true)
            }
        }, '<')
    },
    fetchMobileFirstTime: ({commit}) => {
        const bgColor = '#9cdbd9'
        const easing = Power0.easeNone

        intro.to('.notification-mobile', {
            opacity: 0,
            duration: 2
        }, '+=4')

        intro.to('.lplus-card', {
            opacity: 0,
            duration: 2
        }, '<')

        intro.to('.account', {
            background: '#fff',
            duration: 2
        }, '<')

        intro.to('.customer-account_top', {
            opacity: 0,
            duration: 2
        }, '<')

        intro.to('.nav', {
            opacity: 0,
            duration: 2
        }, '<')

        intro.to('body', {
            background: '#fff',
            duration: 2
        }, '<')

        tl.to('.welcome-msg', {
            opacity: 4,
            duration: 3
        }, '+=7')
        tl.to('.desktop-notification-card-top', {
            padding: '1em',
            duration: 1
        }, '<')
        tl.to(window, {
            duration: 2,
            scrollTo: {y: '.notification-mobile', offsetY: 90}
        })
        if (window.innerWidth < 640) {
            tl.to('.notification-mobile', {
                opacity: 1,
                duration: 3
            })
        } else {
            tl.to('.notification-mobile', {
                opacity: 1,
                scale: 1,
                duration: 3,
                ease: 'linear'
            })
            tl.to('.desktop-notification-card-top', {
                opacity: 1,
                height: '100%',
                scale: 1,
                duration: 1,
                ease: 'linear'
            }, '<')
        }
        tl.to('.button-square', {
            display: 'block',
            duration: 1
        }, '<')
        tl.to('#vertical-arrow', {
            opacity: 1,
            scale: 1,
            duration: 2,
            ease: 'linear'
        })
        tl.to('#animate-notification-msg', {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        tl.to('.square', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '+=3')
        tl.to('.animate-notice', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('#contactsredeem', {
            padding: '1em',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.desktop-notification-card-top', {
            padding: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        tl.to('.first', {
            opacity: 1,
            scale: 1,
            duration: 3,
            ease: 'linear'
        })
        tl.to('#animate-contacts-msg', {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        tl.to('.button-square', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '+=3')
        tl.to('#contactsredeem', {
            padding: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('#animate-contacts-msg', {
            height: 0,
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.lplus-card', {
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '+=1')
        tl.to('.desktop-notification-card-top', {
            opacity: 1,
            height: '100%',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.welcome-msg', {
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.welcome-msg', {
            display: 'none',
            scale: 1,
            duration: 0,
            ease: 'linear'
        })
        tl.to('.customer-account_top', {
            opacity: 1,
            scale: 1,
            duration: 2,
            ease: 'linear'
        }, '<')
        tl.to('.account', {
            background: '#f6f6f6',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.struct-account-container', {
            position: 'relative',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.skip-desktop', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.billing__account-wrap', {
            borderColor: '#ececec',
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.nav', {
            opacity: 1,
            duration: 1
        }, '<')
        tl.to('.main-card-notification-card', {
            opacity: 0,
            display: 'none',
            duration: 2,
            onComplete: () => {
                commit('UPDATE_PREVIOUS_VISIT', true)
            }
        }, '<')

        drawtl.fromTo('.top-side', 1,
            {
                width: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                width: '100%',
                background: bgColor
            },
            '+=3'
        )

        drawtl.fromTo('.right-side', 1,
            {
                height: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                height: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.bottom-side', 1,
            {
                width: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                width: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.left-side', 1,
            {
                height: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                height: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.square-top', 1,
            {
                width: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                width: '100%',
                background: bgColor
            },
            '+=8'
        )

        drawtl.fromTo('.square-right', 1,
            {
                height: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                height: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.square-bottom', 1,
            {
                width: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                width: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.square-left', 1,
            {
                height: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                height: '100%',
                background: bgColor
            }
        )
    },
    fetchDesktopFirstTime: ({commit}) => {
        const bgColor = '#9cdbd9'
        const easing = Power0.easeNone

        intro.to('.desktop-notification-card-top', {
            opacity: 0,
            duration: 2
        }, '+=4')

        intro.to('.lplus-card', {
            opacity: 0,
            duration: 2
        }, '<')

        intro.to('.account', {
            background: '#fff',
            duration: 2
        }, '<')

        intro.to('.customer-account_top', {
            opacity: 0,
            duration: 2
        }, '<')

        intro.to('.nav', {
            opacity: 0,
            duration: 2
        }, '<')

        intro.to('body', {
            background: '#fff',
            duration: 2
        }, '<')

        tl.to('.welcome-msg', {
            opacity: 4,
            duration: 2.5
        }, '+=7')
        tl.to(window, {duration: 2, scrollTo: {y: '#main-cards', offsetY: 90}})
        tl.to('.main-card-holder', {
            padding: '1em 1em 0 1em',
            duration: 1
        }, '<')
        tl.to('.button-square', {
            display: 'none!important',
            opacity: 0,
            scale: 1,
            duration: 2,
            ease: 'power2'
        }, '<')
        tl.fromTo('.first', 1,
            {
                y: 50,
                immediateRender: false,
                autoRound: false,
                ease: 'linear'
            },
            {
                y: 0,
                opacity: 1
            },
            '<.5'
        )
        tl.fromTo('.second', 1,
            {
                y: 50,
                immediateRender: false,
                autoRound: false,
                ease: 'linear'
            },
            {
                y: 0,
                opacity: 1
            },
            '<.5'
        )
        tl.fromTo('.third', 1,
            {
                y: 50,
                immediateRender: false,
                autoRound: false,
                ease: 'linear'
            },
            {
                y: 0,
                opacity: 1
            },
            '<.5'
        )
        tl.fromTo('.last-card', 1,
            {
                y: 50,
                immediateRender: false,
                autoRound: false,
                ease: 'linear'
            },
            {
                y: 0,
                opacity: 1
            },
            '<.5'
        )
        tl.to('.desktop-notification-card-bottom', {
            opacity: 1,
            scale: 1,
            duration: 2,
            ease: 'power2'
        })
        tl.to('.desktop-notification-card-bottom', {
            opacity: 0,
            scale: 1,
            duration: 2,
            ease: 'power2'
        }, '+=2')
        tl.to('.square', {
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.square', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        tl.to('.desktop-notification-card-top-redeem', {
            opacity: 1,
            duration: 3
        })
        tl.to('.desktop-notification-card-top-redeem', {
            opacity: 0,
            duration: 2
        }, '+=2')
        tl.to('.lplus-card', {
            opacity: 0,
            duration: 1
        })
        tl.to('.desktop-notification-card-top', {
            opacity: 1,
            height: 'fit-content',
            duration: 1
        }, '<')
        tl.to('.main-card-notification-card', {
            opacity: 1,
            scale: 1,
            duration: 2,
            ease: 'power2'
        }, '+=1')
        tl.to('.main-card-notification-card', {
            opacity: 0,
            display: 'none',
            scale: 1,
            duration: 2,
            ease: 'power2'
        }, '+=3')
        tl.to('.button-square', {
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.button-square', {
            display: 'none!important',
            scale: 1,
            duration: 0,
            ease: 'linear'
        })
        tl.to('.lplus-card', {
            opacity: 1,
            // height: '100%',
            duration: 1,
            ease: 'linear'
        }, '<.5')
        tl.to('.notification-mobile', {
            padding: 0,
            height: '100%',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.welcome-msg', {
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        tl.to('.welcome-msg', {
            display: 'none',
            scale: 1,
            duration: 0,
            ease: 'linear'
        })
        tl.to('.customer-account_top', {
            opacity: 1,
            scale: 1,
            duration: 2,
            ease: 'linear'
        }, '<.5')
        tl.to('.account', {
            background: '#f6f6f6',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<.5')
        tl.to('.struct-account-container', {
            position: 'relative',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<.5')
        tl.to('.skip-desktop', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<.5')
        tl.to('.billing__account-wrap', {
            borderColor: '#ececec',
            duration: 1,
            ease: 'linear',
            onComplete: () => {
                commit('UPDATE_PREVIOUS_VISIT', true)
            }
        }, '<')
        tl.to('.nav', {
            opacity: 1,
            duration: 1
        }, '<')
        tl.to('.animate-notice', {
            display: 'none',
            scale: 1
        }, '<')

        drawtl.fromTo('.top-side', 1,
            {
                width: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                width: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.right-side', 1,
            {
                height: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                height: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.bottom-side', 1,
            {
                width: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                width: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.left-side', 1,
            {
                height: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                height: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.square-top', 1,
            {
                width: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                width: '100%',
                background: bgColor
            },
            '+=13'
        )

        drawtl.fromTo('.square-right', 1,
            {
                height: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                height: '100%',
                background: bgColor
            }
        )
        drawtl.fromTo('.square-bottom', 1,
            {
                width: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                width: '100%',
                background: bgColor
            }
        )

        drawtl.fromTo('.square-left', 1,
            {
                height: 0,
                background: bgColor,
                immediateRender: false,
                autoRound: false,
                ease: easing
            },
            {
                height: '100%',
                background: bgColor
            }
        )
        tl.to('.main-card-holder', {
            padding: '1em 1em 0 0',
            transform: 'none',
            duration: 1
        })
        tl.to('.lplus-card', {
            transform: 'none',
            duration: 1
        })
    },
    stopDesktop: ({commit}) => {
        tl.pause()
        intro.pause()
        drawtl.pause()

        const newTl = new gsap.timeline()

        newTl.to('.welcome-msg', {
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        newTl.to('.welcome-msg', {
            display: 'none',
            scale: 1,
            duration: 0,
            ease: 'linear'
        })
        newTl.to('.square', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.button-square', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.notification-mobile', {
            opacity: 1,
            scale: 1,
            padding: 0,
            duration: 1,
            ease: 'linear'
        })
        newTl.to('.animate-notice', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.first', {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('#animate-contacts-msg', {
            height: 0,
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.rest', {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.customer-account_top', {
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.account', {
            background: '#f6f6f6',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.struct-account-container', {
            position: 'relative',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.skip-desktop', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.desktop-notification-card-top', {
            opacity: 1,
            height: '100%',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.desktop-notification-card-bottom', {
            opacity: 0,
            height: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.desktop-notification-card-top-redeem', {
            opacity: 0,
            height: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.main-card-notification-card', {
            opacity: 0,
            height: 0,
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        newTl.to('.nav', {
            opacity: 1,
            duration: 1
        }, '<')
        newTl.to('.billing__account-wrap', {
            borderColor: '#ececec',
            duration: 1,
            ease: 'linear',
            onComplete: () => {
                commit('UPDATE_PREVIOUS_VISIT', true)
            }
        }, '<')
    },
    stopMobile: ({commit}) => {
        tl.pause()
        drawtl.pause()
        intro.pause()

        const NewTl = new gsap.timeline()

        NewTl.to('.welcome-msg', {
            opacity: 0,
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        NewTl.to('.welcome-msg', {
            display: 'none',
            scale: 1,
            duration: 0,
            ease: 'linear'
        })
        NewTl.to('.notification-mobile', {
            opacity: 1,
            scale: 1,
            padding: 0,
            duration: 1,
            ease: 'linear'
        })
        NewTl.to('.animate-notice', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        NewTl.to('.square', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        NewTl.to('.button-square', {
            display: 'none',
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        NewTl.to(
            '#animate-contacts-msg',
            {height: 0, opacity: 0, scale: 1, duration: 1, ease: 'linear'},
            '<'
        )
        NewTl.to('.rest', {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'linear'
        })
        NewTl.to('.first', {
            opacity: 1,
            scale: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        NewTl.to('.customer-account_top', {
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        NewTl.to('.account', {
            background: '#f6f6f6',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        NewTl.to('.struct-account-container', {
            position: 'relative',
            opacity: 1,
            duration: 1,
            ease: 'linear'
        }, '<')
        NewTl.to('.nav', {
            opacity: 1,
            duration: 1
        }, '<')
        NewTl.to('.billing__account-wrap', {
            borderColor: '#ececec',
            duration: 1,
            ease: 'linear',
            onComplete: () => {
                commit('UPDATE_PREVIOUS_VISIT', true)
            }
        }, '<')
    }
}

export default store => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
