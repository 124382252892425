var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "footer__bottom footer__bottom-onepage-desktop footer__bottom-onepage-plus"
      },
      [
        _c("div", { staticClass: "footer__row" }, [
          _c("div", { staticClass: "footer_row_sections" }, [
            _c("div", { staticClass: "footer-copyright" }, [
              _c("p", [
                _c("span", [
                  _vm._v("© Copyright lensabl 2022. All rights reserved.   ")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "footer-payments" }, [
              _c("div", [
                _c("img", {
                  staticClass: "lazyload",
                  attrs: {
                    src:
                      "https://www.lensabl.com/media/wysiwyg/1280px-Paypal-logo-black.png",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "lazyload",
                  attrs: {
                    src:
                      "https://www.lensabl.com/media/wysiwyg/mastercard-logo.png",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "lazyload",
                  attrs: {
                    src: "https://www.lensabl.com/media/wysiwyg/visa.png",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "lazyload",
                  attrs: {
                    src:
                      "https://www.lensabl.com/media/wysiwyg/amazon-logo.png",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "lazyload",
                  attrs: {
                    src:
                      "https://www.lensabl.com/media/wysiwyg/1280px-Discover_Card_logo.png",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "lazyload",
                  attrs: {
                    src:
                      "https://www.lensabl.com/media/wysiwyg/AXP_BlueBoxLogo_EXTRALARGEscale_RGB_DIGITAL_1600x1600.png",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "trust-logo",
                  attrs: {
                    src:
                      "https://www.lensabl.com/skin/frontend/lensabl/default/images/positivessl_trust_seal_sm_124x32.png",
                    alt: "Postive SSL Trust Seal"
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "footer-privacy" }, [
              _c("span", [
                _c("a", { attrs: { href: "/insurance-providers/" } }, [
                  _c("b", [_vm._v("Use Your Linneo Benefits")])
                ]),
                _vm._v(" | "),
                _c("a", { attrs: { href: "https://www.lensabl.com/terms/" } }, [
                  _vm._v("Terms & Conditions")
                ]),
                _vm._v(" | "),
                _c(
                  "a",
                  {
                    attrs: { href: "https://www.lensabl.com/privacy-policy/" }
                  },
                  [_vm._v("Privacy Policy")]
                )
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }