<template>
    <div class="billing-opc">
        <Loader v-if="LOADING_PAYMENT"/>
        <div v-else class="opc-block-section opc-block-section--payment">
            <div class="payment-block accordian-header">
                <h3>
                    Payment Options
                    <span>FSA/HSA Cards Accepted</span>
                </h3>
            </div>
            <div>
                <div class="payment-methods control">
                    <div id="checkout-payment-method-load">
                        <div
                            v-for="(payment, index) in getAvailablePaymentMethods"
                            :key="index"
                            :id="payment.code + '-wrapper'"
                            v-show="!payment.hidden_in_frontend"
                        >
                            <div class="payment-wrapper">
                                <label class="radio-label" :for="'p_method_' + payment.code">
                                    <input
                                        :id="'p_method_' + payment.code"
                                        v-model="SELECTED_PAYMENT_METHOD"
                                        :value="payment.code"
                                        type="radio"
                                        name="payment[method]"
                                        :title="payment.title"
                                        class="radio payment-radio"
                                    />
                                    <span v-dompurify-html="payment.title"
                                          :class="{'payment-title': payment.code === 'affirm'}"></span>
                                    <HelpBubble v-if="payment.hint" :data="getHelper(payment.hint)"/>
                                </label>
                            </div>
                            <div
                                v-if="payment.code === SELECTED_PAYMENT_METHOD"
                                :id="'container_payment_method_' + payment.code"
                                v-dompurify-html="payment.html"
                                class="payment-method"
                            >
                            </div>
                            <div v-for="(item, i) in ITEMS" v-show="IS_QUARTERLY_PAY && currenMethod.code === 'recurly'"
                                 :key="i" class="recurring-charge_subtitle">
                                <span>Your card will be charged <span
                                    v-dompurify-html="item.quarterly_price_html"></span> every 3 months</span>
                            </div>
                        </div>
                        <div v-if="ADDRESS_FORM_ERRORS.payment_method" class="validation-advice" style="">Payment method
                            is a required.
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showStripeButton">
                <ul class="form-list" id="payment_form_stripeButton">
                    <li class="form-alt">
                        <div>
                            <div id="payment-request-button">
                                <!-- A Stripe Element will be inserted here. -->
                            </div>
                        </div>
                    </li>
                </ul>
                <input type="hidden" name="payment[stripeButton_token]" id="stripeButton-token">
                <input type="hidden" name="payment[stripeButton_pi_id]" id="stripeButton-pi-id">
                <input type="hidden" name="payment[stripeButton_brand]" id="stripeButton-brand">
                <input type="hidden" name="payment[stripeButton_last4]" id="stripeButton-last4">
                <input type="hidden" name="payment[stripeButton_desc]" id="stripeButton-desc">
            </div>
        </div>
    </div>
</template>

<script>
import _size from 'lodash/size'
import {mapGetters, mapActions} from 'vuex'
import Loader from '../../loaders/Loader.vue'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import _find from 'lodash/find'

export default {
    name: 'Payment',
    components: {
        Loader,
        HelpBubble
    },
    data() {
        return {
            currenMethod: {},
            reloadStripeButton: false
        }
    },
    computed: {
        ...mapGetters('checkoutPlus', [
            'LOADING_PAYMENT',
            'PAYMENT_METHODS',
            'SELECTED_PAYMENT_METHOD',
            'RECURLY_PUBLIC_KEY',
            'ADDRESS_FORM_ERRORS',
            'ITEMS',
            'IS_QUARTERLY_PAY',
            'stripePubKey',
            'getPaymentRequestStripe',
            'getStripeCustomerSecret',
            'getStripePaymentIntent',
            'INVALID_FORM'
        ]),
        SELECTED_PAYMENT_METHOD: {
            get() {
                const paymentMethod = this.$store.getters['checkoutPlus/SELECTED_PAYMENT_METHOD']
                this.changePaymentMethod(paymentMethod)
                return paymentMethod
            },
            set(payment_method) {
                this.$store.commit('checkoutPlus/SET_SELECTED_PAYMENT_METHOD', payment_method)
                this.$store.dispatch('checkoutPlus/SET_PAYMENT_METHOD')
            }
        },
        showStripeButton() {
            return this.PAYMENT_METHODS['stripe_button'] ? true : false
        },
        getAvailablePaymentMethods(){
            this.initiateStripeButton()
            return this.PAYMENT_METHODS
        }
    },
    watch: {
        LOADING_PAYMENT: {
            handler(loading) {
                if (!loading && (_size(this.PAYMENT_METHODS) === 1)) {
                    const first = this.PAYMENT_METHODS[Object.keys(this.PAYMENT_METHODS)[0]] || {}
                    this.$store.commit('checkoutPlus/SET_SELECTED_PAYMENT_METHOD', first.code)
                    this.$store.dispatch('checkoutPlus/SET_PAYMENT_METHOD')
                }
            },
            deep: true
        }
    },
    created() {
        this.$store.dispatch('checkoutPlus/GET_PAYMENT_METHODS')

    },
    methods: {
        ...mapActions('checkoutPlus', ['GET_PAYMENT_METHODS', 'SET_PAYMENT_METHOD', 'fetchPaymentRequest', 'fetchClientSecret']),
        changePaymentMethod(payment_code) {
            this.currenMethod = _find(this.PAYMENT_METHODS, {code: payment_code})
            console.log(payment_code)
            if (payment_code === 'stripe') {
                this.setTimeIntervalMethod('payment_form_' + payment_code, this.initiateStripe)
            }
            if (payment_code === 'recurly') {
                this.setTimeIntervalMethod('payment_form_recurly', this.initiateRecurly)
            }
            if (payment_code === 'amazon_payments') {
                this.setTimeIntervalMethod('payment_form_amazon_payments', this.initiateAmazonButton)
                this.setTimeIntervalMethod(this.currenMethod.config.button_id, () => {
                    const el = document.querySelector('img[id^="OffAmazonPaymentsWidgets"]')
                    el.click()
                })
            }
        },
        setTimeIntervalMethod(selector, func) {
            const intervalID = setInterval(() => {
                if (document.getElementById(selector)) {
                    func()
                    clearInterval(intervalID)
                }
            }, 500)
        },
        initiateAmazonButton() {
            const config = this.currenMethod.config

            window.AmazonPaymentsCallbacks[config.button_id] = () => {
                const AmazonButtonOptions = {
                    type: config.type,
                    color: config.color,
                    size: config.size,
                    authorization: () => {
                        window.loginOptions = {
                            scope: config.scope,
                            response_type: 'token',
                            popup: config.popup
                        }
                        const url = config.popup ? config.login_url : config.checkout_url
                        window.authRequest = window.amazon.Login.authorize(window.loginOptions, url)
                    },
                    onError: function (error) {
                        console.log(error)
                    }
                }
                if (config.language) {
                    AmazonButtonOptions.language = config.language
                }
                if (config.token_enable) {
                    AmazonButtonOptions.agreementType = 'BillingAgreement'
                    AmazonButtonOptions.onSignIn = (billingAgreement) => {
                        const amazonBillingAgreementId = billingAgreement.getAmazonBillingAgreementId()
                        window.Mage.Cookies.set('amazonBillingAgreementId', amazonBillingAgreementId)
                    }
                }

                window.OffAmazonPayments.Button(config.button_id, config.seller_id, AmazonButtonOptions)
            }
            window.AmazonPaymentsCallbacks[config.button_id]()
        },
        initiateRecurly() {
            window.recurly.configure({
                publicKey: this.RECURLY_PUBLIC_KEY,
                required: ['cvv']
            })
            window.cc_type = ''
            window.recurly.on('change', (state) => {
                window.cc_type = state.fields.card.brand
            })
        },
        loadStripe() {
            if (!window.loadedStipe) {
                const loadedStipe = Stripe(this.stripePubKey, {locale: 'us'});
                window.loadedStipe = loadedStipe
            }
        },
        initiateStripe() {
            this.loadStripe()
            const style = {
                base: {
                    fontSize: '16px',
                },
            }
            const elements = window.loadedStipe.elements();
            window.stripeCard = elements.create('card', {
                hidePostalCode: true,
                style: style,
                disableLink: true
            });
            window.stripeCard.mount('#card-element');
        },
        initiateStripeButton: async function () {
            const _this = this;
            await this.fetchPaymentRequest()
            this.loadStripe()
            const paymentRequest = window.loadedStipe.paymentRequest(this.getPaymentRequestStripe);
            const elements = window.loadedStipe.elements();
            const prButton = elements.create('paymentRequestButton', {
                paymentRequest: paymentRequest,
            });

            prButton.on('click', function (event) {
                _this.$store.dispatch('checkoutPlus/VALIDATION_FORM_ADDRESS')
                if (_this.INVALID_FORM) {
                    _this.$store.commit('checkoutPlus/SET_SELECTED_PAYMENT_METHOD', null)
                    event.preventDefault();
                } else {
                    _this.$store.commit('checkoutPlus/SET_SELECTED_PAYMENT_METHOD', 'stripe_button')
                }
            })

            paymentRequest.on('cancel', function (ev) {
                _this.$store.commit('checkoutPlus/SET_SELECTED_PAYMENT_METHOD', 'stripe')
            });

            const result = await paymentRequest.canMakePayment();
            console.log(result)
            if (result) {
                prButton.mount('#payment-request-button')
                Object.keys(result).forEach(function(key, index) {
                    if (result[key]){
                        document.getElementById('stripeButton-desc').value = key
                    }
                });
            } else {
                const el = document.getElementById('payment-request-button')
                if (el) {
                    el.style.display = 'none';
                }
                if (!this.reloadStripeButton) {
                    this.reloadStripeButton = true
                    this.initiateStripeButton()
                }
                return
            }
            await this.fetchClientSecret()

            function savePaymentCard(paymentMethod) {
                document.getElementById('stripeButton-token').value = paymentMethod.id
                if (paymentMethod.card) {
                    document.getElementById('stripeButton-brand').value = paymentMethod.card.brand
                    document.getElementById('stripeButton-last4').value = paymentMethod.card.last4
                }
                document.getElementById('stripeButton-pi-id').value = _this.getStripePaymentIntent
                document.getElementById('place-order').click()
            }

            const clientSecret = this.getStripeCustomerSecret
            paymentRequest.on('paymentmethod', async (ev) => {
                _this.$store.commit('checkoutPlus/SET_SELECTED_PAYMENT_METHOD', 'stripe_button')
                const {paymentIntent, error: confirmError} = await window.loadedStipe.confirmCardPayment(
                    clientSecret,
                    {payment_method: ev.paymentMethod.id},
                    {handleActions: false}
                );

                if (confirmError) {
                    console.error(confirmResult.error)
                    ev.complete('fail');
                    alert("Please choose another payment method")
                } else {
                    ev.complete('success');
                    if (paymentIntent.status === "requires_source_action") {
                        const {error} = await window.loadedStipe.confirmCardPayment(clientSecret);
                        console.log(error, 'error')
                        if (error) {
                            console.error(error)
                            alert("Please choose another payment method")
                        } else {
                            savePaymentCard(ev.paymentMethod)
                        }
                    } else {
                        savePaymentCard(ev.paymentMethod)
                    }
                }
            });
        },
        getHelper(text) {
            return {
                'name': 'help-bubble',
                'params': {
                    'text': text
                }
            }
        }
    }
}
</script>
