var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.prescriptionData.status_4
        ? _c("RadioLabel", {
            attrs: { active: _vm.isActive, label: _vm.title, value: _vm.index },
            on: {
              click: function($event) {
                return _vm.$emit("checked", _vm.index)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isActive &&
      (_vm.fittingbox_status === "taken" || _vm.fittingbox_status === "retaken")
        ? _c("div", { staticClass: "fittingbox-warning-enter" }, [
            _c("br"),
            _vm._v(
              "We have your previous photo which we will process soon.\n        "
            ),
            _c("br"),
            _vm._v(
              "You can enter your PD below if you like, which will override it.\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive,
              expression: "isActive"
            }
          ],
          staticClass: "pd_selector my-prescription-form__pupillary-distance"
        },
        [
          _c("div", { staticClass: "pd-parameters" }, [
            _c(
              "div",
              {
                staticClass:
                  "pd-label my-prescription-form__dropdown-label pupillary"
              },
              [
                _c(
                  "span",
                  [
                    _c("span", [_vm._v("Pupillary Distance")]),
                    _vm._v(" "),
                    _c("HelpBubble", { attrs: { data: _vm.help.pd } })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            !_vm.prescription.separate_pd
              ? _c(
                  "div",
                  { staticClass: "pd-input-container" },
                  [
                    _c("CustomSelect", {
                      staticClass: "pd-input",
                      attrs: {
                        value: _vm.prescription.pd,
                        options: _vm.eyePDOptions
                      },
                      on: {
                        change: function($event) {
                          _vm.prescription.pd = $event.target.value
                        }
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "pd-input-container separate-pd-values" },
                  [
                    _c("CustomSelect", {
                      staticClass: "pd-input",
                      attrs: {
                        value: _vm.prescription.od_pd,
                        options: _vm.eyeODPDOptions
                      },
                      on: {
                        change: function($event) {
                          _vm.prescription.od_pd = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("CustomSelect", {
                      staticClass: "pd-input",
                      attrs: {
                        value: _vm.prescription.os_pd,
                        options: _vm.eyeOSPDOptions
                      },
                      on: {
                        change: function($event) {
                          _vm.prescription.os_pd = $event.target.value
                        }
                      }
                    })
                  ],
                  1
                )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pd_checkbox enter-it-online" },
            [
              _c("RadioLabel", {
                attrs: {
                  active: _vm.prescription.separate_pd,
                  label: "I have two PD numbers"
                },
                on: {
                  click: function($event) {
                    _vm.prescription.separate_pd = !_vm.prescription.separate_pd
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.showError
            ? _c("div", { staticClass: "error-text-rx" }, [
                _c("span", { domProps: { textContent: _vm._s(_vm.errorText) } })
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }