<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="ap-parent-container ap-parent-container-top">
            <div class="ap-container ap-container-none-background">
                <h3>Get upwards of $150 reimbursed by your vision insurance</h3>
                <p style="color: #8d8d8d;" class="ap-p">Check your vision insurance benefit amount using the form below.</p>
            </div>
        </div>
        <div class="struct-parallax-bg ap-parent-container ap-parent-container-form">
            <div class="ap-container">
                <ProviderForm v-if="!isRequestSent"/>
                <div v-else>
                    <div v-if="isRequestSuccessful" style="padding: 2rem 0 1rem;">
                        <div v-if="areBenefitsZeroAmount">
                            <h3 class="ap-container-header">Your benefits are depleted</h3>
                            <p style="color: #8d8d8d; margin-bottom: 2rem;" class="ap-p">Unfortunately you have used all of your benefits.</p>
                        </div>
                        <div v-else>
                            <h3 class="ap-container-header">Congratulations!</h3>
                            <p style="color: #8d8d8d; margin-bottom: 2rem;" class="ap-p">You have benefits available, follow the steps below to use:</p>
                            <div class="ap-benefit-list ap-success-list" style="grid-column-gap: 1rem;">
                                <div class="ap-list-item ap-list-item-1">
                                    <div class="ap-list-item-number">1</div>
                                    <p class="ap-list-item-text" style="line-height: 1.4;">Shop <a :href="base_url + `shop-frames`" style="font-weight: bold; text-decoration: underline;">frames</a>, <a
                                        :href="base_url+`choose-my-lenses`" style="font-weight: bold; text-decoration: underline;">lens replacement</a> or
                                        <a :href="base_url + `shop-contacts/brands`" style="font-weight: bold; text-decoration: underline;">contact lenses</a> and add to cart</p>
                                </div>
                                <div class="ap-list-item ap-list-item-2">
                                    <div class="ap-list-item-number">2</div>
                                    <p class="ap-list-item-text" style="line-height: 1.4;">Complete checkout</p>
                                </div>
                                <div class="ap-list-item ap-list-item-3">
                                    <div class="ap-list-item-number">3</div>
                                    <p class="ap-list-item-text" style="line-height: 1.4;">Fill out form on confirmation page
                                        <span>
                                        <div class="c-product-lplus-ad-block anagram-ad-info-section">
                                            <span class="lplus-ad-block_container" @click="openPlusAdModal">
                                                <span class="c-product-lplus-ad-link anagram-tooltip"><i data-original-title="null"
                                                                                                         class="help-bubble-icon">?</i></span>
                                                <div class="help-bubble-container lplus-help-bubble-container">
                                                    <div class="help-component">
                                                        <div id="help_bubble_text" :class="showModalAd ? 'open' : ''"
                                                             class="help-bubble lplus-help-bubble">
                                                            <img src="/media/wysiwyg/tool-tip-image.jpg" class="ap-tooltip-img"/>
                                                            <span class="close-button" @click.stop="closeModal">
                                                                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                                                                    <path id="svg_1"
                                                                          d="m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z"
                                                                          fill="rgb(102, 102, 102)" fill-rule="evenodd"/>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </span>
                                    </p>
                                </div>
                                <div class="ap-list-item ap-list-item-4">
                                    <div class="ap-list-item-number">4</div>
                                    <p class="ap-list-item-text" style="line-height: 1.4;">Automatically claim out-of-network reimbursements</p>
                                </div>
                            </div>
                            <hr style="width: 200px; border: 1px solid #8d8d8d;"/>
                        </div>
                        <div class="ap-benefit-info-list" style="grid-column-gap: 1rem; margin-bottom: 1rem;">
                            <p class="ap-p"><b>Insurance:</b> <span style="color: #8d8d8d;">{{ getCompanyName(getPatientValue('insurance')) }}</span></p>
                            <p class="ap-p"><b>Member:</b> <span style="color: #8d8d8d;">{{ getPatientValue('member') }}</span></p>
                            <p class="ap-p"><b>Plan:</b> <span style="color: #8d8d8d;">{{ getPatientValue('plan') }}</span></p>
                            <p class="ap-p"><b>Benefit(/s):</b> <span v-dompurify-html="getBenefitsHtml" style="color: #8d8d8d;"></span></p>
                        </div>
                    </div>
                    <div v-else style="padding: 2rem 0 1rem;">
                            <h3 class="ap-container-header">We couldn’t find your insurance</h3>
                            <p style="color: #8d8d8d; margin-bottom: 1rem;" class="ap-p">But you can still claim your benefit with a reimbursement form below.</p>
                            <p style="color: #8d8d8d; margin-bottom: 1rem;" class="ap-p"><b>Don't see your provider?</b> Contact your insurance company directly for instructions on applying for
                                reimbursement.</p>
                    </div>
                    <a class="button button--copy-solid" :href="base_url" style="max-width: 210px; margin-bottom: 2rem;">Start shopping</a>
                </div>
            </div>
        </div>
        <div class="struct-parallax-bg ap-parent-container ap-parent-container-bottom">
            <div class="ap-container">
                <h3 class="ap-container-header">Supported insurers</h3>
                <p style="color: #8d8d8d; margin-bottom: 1rem;" class="ap-p">We are out-of-network with these vision plans, but we can still help you check your benefits and file your claims.</p>
                <div class="ap-image-container">
                    <section class="ap-img-section">
                        <div>
                            <img src="/media/wysiwyg/insurances/aetna-logo.png" class="ap-img aetna-img" alt="aetna-logo"/>
                        </div>
                        <div>
                            <img src="/media/wysiwyg/insurances/blue-view-vision-logo.webp" alt="blue-view-vision-logo" class="ap-img blue-view-vision-img"/>
                        </div>
                        <div>
                            <img src="/media/wysiwyg/insurances/cigna.png" class="ap-img cigna-img" alt="cigna-logo"/>
                        </div>
                        <div>

                            <img src="/media/wysiwyg/insurances/davis-vision-logo.png" alt="davis-vision-logo" class="ap-img davis-vision-img"/>
                        </div>
                        <div>
                            <img src="/media/wysiwyg/insurances/eye-med-logo.png" class="ap-img eye-med-img" alt="eye-med-logo"/>
                        </div>
                        <div>
                            <img src="/media/wysiwyg/insurances/spectera-logo.png" class="ap-img spectera-img" alt="spectera-logo"/>
                        </div>
                        <div>
                            <img src="/media/wysiwyg/insurances/superior-vision-logo.png" alt="superior-vision-logo" class="ap-img superior-vision-img"/>
                        </div>
                        <div>
                            <img src="/media/wysiwyg/insurances/vsp-logo.png" class="ap-img vsp-img" alt="vsp-logo"/>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProviderForm from "./forms/anagram/ProviderForm.vue";
import {mapGetters, mapState} from "vuex";
import _filter from "lodash/filter";

export default {
    name: 'AnagramProvider',
    components: {ProviderForm},
    data: () => ({
        isRequestSuccessful: false,
        isRequestSent: false,
        areBenefitsZeroAmount: false,
        patient: {},
        showModalAd: false,
    }),
    computed: {
        ...mapState('insuranceAnagram', ['companies',]),
        ...mapGetters('storeView', ['base_url']),
        getCompanyName() {
            return (key) => {
                const filteredCompany = _filter(this.companies, {value: key})
                if (filteredCompany.length === 0) return ''
                const company = filteredCompany.first()
                return company.label || ''
            }
        },
        getPatientValue() {
            return (key) => this.patient[key] || ''
        },
        getBenefitsHtml(){
            if (!this.isBenefitAmount) return `$0`
            return `<span>Glasses up to $${this.getPatientValue('benefits').lens_sv }-$${this.getPatientValue('benefits').lens_prog}</span><br><span style="margin-left: 55px;"> Contacts up to $${this.getPatientValue('benefits').contacts}</span>`
        },
        isBenefitAmount(){
            return Object.keys(this.getPatientValue('benefits')).length > 0
        }
    },
    methods: {
        openPlusAdModal() {
            if (window.innerWidth < 768) {
                this.showModalAd = true
            } else {
                this.showModalAd = false
            }
        },
        closeModal() {
            this.showModalAd = false;
        }
    }
}
</script>

<style scoped lang="less">
.ap-parent-container-bottom {
    .ap-container {
        padding: 1.5rem 2rem 0;
        margin-bottom: 3rem;

        .ap-image-container .ap-img-section {
            flex-wrap: wrap;
            row-gap: 2rem;

            div {
                flex: 1 0 50%;

                @media screen and (min-width: 640px) {
                    flex: 1 0 21%;

                }

                .ap-img {
                    @media screen and (min-width: 520px) {
                        height: 30px;
                    }
                    @media screen and (min-width: 768px) {
                        height: 35px;
                    }
                }
            }
        }
    }
}

.ap-benefit-info-list {
    display: grid;
    justify-items: flex-start;
    row-gap: 1rem;
    max-width: 661px;
    margin: auto;

    @media screen and (min-width: 640px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
}
</style>
