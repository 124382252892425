<template>
    <HomepageSlider v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import HomepageSlider from '../../components/contacts/HomepageSlider.vue'

export default {
    name: 'RouterContactsHomepageSlider',
    components: {
        HomepageSlider
    },
    extends: App
}
</script>
