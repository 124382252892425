import _clone from 'lodash/clone'

/**
 * Step Status Constants
 */
export const STATE_WAIT = 'wait'
export const STATE_CURRENT = 'current'
export const STATE_READY = 'ready'

/**
 * Value Place Constants
 */
export const PLACE_STEP_HEADER = 'step_header'
export const PLACE_SUMMARY = 'summary'

/**
 * Prescription State Constants
 */
export const SOURCE_ENTER = 'enter'
export const SOURCE_SAVED = 'saved'
export const SOURCE_UPLOAD = 'upload'

/**
 * Prescription Types
 */
export const PRESCRIPTION_TYPE_ONLINE = 'enter_it_now'
export const PRESCRIPTION_TYPE_UPLOAD = 'upload'
export const PRESCRIPTION_TYPE_SAVED = 'saved_prescriptions'
export const PRESCRIPTION_TYPE_LATER = 'email_it_later'
export const PRESCRIPTION_TYPE_MANUAL = 'manual'
export const PRESCRIPTION_TYPE_DOCTOR_DETAILS = 'doctor_details'
export const PRESCRIPTION_ADD_ERROR = 'Prescription values invalid or missing. Please ensure that you have Cylinder and Axis values filled'
export const PRESCRIPTION_ADD_PROGRESSIVE_ERROR = 'Prescription values invalid or missing. Please ensure that you have both left and right eye ADD values filled'

/**
 *
 */
export const POSITION_FRONT = 'front'
export const POSITION_ANGLED = 'angled'

/**
 * Event Names Constants
 */
export const EVENT_SHOW_BUBBLE = 'show-bubble'
export const EVENT_SHOW_POPUP = 'show-popup'
export const EVENT_INIT_VALUE = 'init-value'
export const EVENT_NEED_TO_INIT_VALUE = 'need-to-init-value'
export const EVENT_STEP_NEXT = 'next-step'
export const EVENT_STEP_PREVIOUS = 'previous-step'
export const EVENT_STEP_EDIT = 'edit-step'
export const EVENT_STEP_UPDATE = 'steps-update'
export const EVENT_SWITCH_STEP = 'switch-step'
export const EVENT_SWITCH_OPTION = 'switch-option'
export const EVENT_CHANGE_CURRENT_COLOR = 'change-current-color'
export const EVENT_CHANGE_CURRENT_OPTION = 'change-current-option'
export const EVENT_CHANGE_CURRENT_COLOR_LENS = 'change-current-lens-color'
export const EVENT_KEY_PRESS = 'key-press'
export const EVENT_OPEN_ADD_WIDGET = 'open-add-widget'
export const EVENT_CLOSE_ADD_WIDGET = 'close-add-widget'
export const EVENT_OPTION_BACK_CLICK = 'option-back-button'
export const EVENT_ADD_TO_CART_CLICK = 'add-to-cart-click'
export const EVENT_RESET_AFTER = 'reset-after'
export const EVENT_CATEGORY_SELECTED = 'category-selected'
export const MESSAGE_VALIDATE_DOCTOR = 'msg-validate-doctor'
export const MESSAGE_VALIDATE_UPLOAD = 'msg-validate-upload'
export const MESSAGE_VALIDATE_RX_OWNER = 'msg-validate-rx-owner'
export const MESSAGE_VALIDATE_DOCTOR_DETAILS = 'msg-validate-doctor-details'
export const MESSAGE_VALIDATE_MANUAL_RX = 'msg-validate-manual-rx'
export const CHANGE_COLOR_LENSABL_FRAMES = 'change-color-lensabl-frames'
export const CHOOSE_NEW_RX_OPEN = 'choose-new-rx-open'
export const CHOOSE_NEW_RX_CLOSE = 'choose-new-rx-close'

export const EVENT_CONFIGURATOR_RESET = 'configurator:reset'
export const EVENT_CONFIGURATOR_ACTIVATE_FIRST = 'configurator:activate-first'
export const EVENT_CONFIGURATOR_RESTART = 'configurator:restart'

/**
 * Key Code Constants
 */
export const KEY_DOWN = 40
export const KEY_UP = 38
export const KEY_RIGHT = 39
export const KEY_LEFT = 37
export const KEY_ENTER = 13
export const KEY_ESCAPE = 27

/**
 * Shop Frames Brands
 *
 * @type {string[]}
 */
export const SHOP_FRAMES_BRANDS = [
    'arnette',
    'brooks brothers',
    'carrera',
    'hugo boss',
    'kate spade',
    'marc jacobs',
    'oakley',
    'ray-ban',
    'vogue',
    'prive revaux',
    'under armour',
    'tommy hilfiger',
    'rag and bone',
    'carolina herrera',
    'ic! berlin',
    'calvin klein optical',
    'columbia sun',
    'marchon optical',
    'flexon optical',
    'pure',
    'nike sun',
    'nike optical',
    'max mara',
    'adidas',
    'ermenegildo zegna',
    'guess',
    'ecko unltd.'
]

const SINGLE_VISION_PRESCRIPTION = {
    'od_sphere': ' ',
    'od_cylinder': ' ',
    'od_axis': ' ',
    'od_prism_horizontal': null,
    'od_prism_vertical': null,
    'od_prism_vertical_direction': null,
    'od_prism_horizontal_direction': null,
    'os_sphere': ' ',
    'os_cylinder': ' ',
    'os_axis': ' ',
    'os_prism_horizontal': '',
    'os_prism_vertical': '',
    'os_prism_vertical_direction': '',
    'os_prism_horizontal_direction': '',
    'separate_pd': false,
    'prism': false,
    'pd': '',
    'od_pd': '',
    'os_pd': ''
}

const PROGRESSIVE_PRESCRIPTION = {
    'od_sphere': ' ',
    'od_cylinder': ' ',
    'od_axis': ' ',
    'od_add': ' ',
    'od_prism_horizontal': null,
    'od_prism_vertical': null,
    'od_prism_vertical_direction': null,
    'od_prism_horizontal_direction': null,
    'os_sphere': ' ',
    'os_cylinder': ' ',
    'os_axis': ' ',
    'os_add': ' ',
    'os_prism_horizontal': '',
    'os_prism_vertical': '',
    'os_prism_vertical_direction': '',
    'os_prism_horizontal_direction': '',
    'separate_pd': false,
    'prism': false,
    'pd': '',
    'od_pd': '',
    'os_pd': ''
}

/**
 * Data Exchange Events
 */
export const EVENT_CONFIGURED_PRICE = 'min-price'

export const FRAMES_STEP_WITH_SIZE = [
    {
        'id': 'frames',
        'summary_label': 'Frame',
        'value_renderer': 'renderer-frames'
    },
    {
        'id': 'frames_value',
        'summary_label': 'Frame Size',
        'value_renderer': 'renderer-frames-color'
    }
]

export const FRAMES_STEP_WITH_ADDON = [
    {
        'id': 'frames',
        'summary_label': 'Frame',
        'value_renderer': 'renderer-frames'
    },
    {
        'id': 'frames_value',
        'summary_label': 'Add-on Color(s)',
        'value_renderer': 'renderer-frames-color'
    }
]

export const FRAMES_STEP = [
    {
        'id': 'frames',
        'summary_label': 'Frame',
        'value_renderer': 'renderer-frames'
    }
]

export const FRAMES_STEP_WITH_COLOR = [
    {
        'id': 'frames',
        'summary_label': 'Frame',
        'value_renderer': 'renderer-frames'
    },
    {
        'id': 'frames_value',
        'summary_label': 'Frame Color',
        'value_renderer': 'renderer-frames-color'
    }
]

export const DEFAULT_VALUES_OBJECT = {
    'box': {
        'code': null,
        'balance': 0
    },
    'prescriptionValues': {
        'id': null,
        'name': null,
        'lens_power': {
            'od': 0,
            'os': 0
        },
        'single_vision': _clone(SINGLE_VISION_PRESCRIPTION),
        'single_vision_distance_premium': _clone(SINGLE_VISION_PRESCRIPTION),
        'single_vision_distance': _clone(SINGLE_VISION_PRESCRIPTION),
        'single_vision_reading': _clone(PROGRESSIVE_PRESCRIPTION),
        'single_vision_reading_premium': _clone(PROGRESSIVE_PRESCRIPTION),
        'spectacles_single_vision': _clone(SINGLE_VISION_PRESCRIPTION),
        'progressive_standard': _clone(PROGRESSIVE_PRESCRIPTION),
        'progressive_wide': _clone(PROGRESSIVE_PRESCRIPTION),
        'progressive_premium': _clone(PROGRESSIVE_PRESCRIPTION),
        'spectacles_progressive': _clone(PROGRESSIVE_PRESCRIPTION),
        'spectacles_gen_single_vision_distance': _clone(SINGLE_VISION_PRESCRIPTION),
        'spectacles_gen_single_vision_reading': _clone(SINGLE_VISION_PRESCRIPTION),
        'spectacles_gen_progressive_standard': _clone(PROGRESSIVE_PRESCRIPTION),
        'spectacles_gen_progressive_premium': _clone(PROGRESSIVE_PRESCRIPTION),
        'bi_focal': _clone(PROGRESSIVE_PRESCRIPTION),
        'call_my_doctor': {
            'doctor_name': null,
            'doctor_state': null,
            'doctor_phone': null,
            'patient_name': null,
            'patient_birthday': null
        },
        'upload': {
            'data_url': null,
            'file_name': null
        }
    },
    'color': {
        'position': POSITION_FRONT,
        'value': null,
        'level': false
    },
    lens_type: null,
    lens_type_name: null,
    'anti_reflective': false,
    'upgrades': {},
    'warranty': [],
    'anti_reflective_symbol': 'AR',
    'notes': '',
    'frames_name': null,
    'lensabl_frames': false,
    'product_sku': null,
    'frames_value': null,
    'frames_SKU': false,
    'prism_price': null,
    'prism_qty': 0,
    'pd_in_rx': false,
    'segmentation_height': false,
    'frames_step': FRAMES_STEP_WITH_COLOR,
    'frame_price': 149.99,
    'currency_symbol': '$',
    'upload': {
        'data_url': null,
        'file_name': null
    },
    'newPrescription': false
}
export const PRESCRIPTION_DEFAULT_VALUES = {
    'power': 0,
    'cyl': 0,
    'axis': 0,
    'bc': 0,
    'dia': 0,
    'id': null,
    'rx_expiration_date': ''
}

export const CONTACTS_DEFAULT_VALUES = {
    'box': {
        'code': null
    },
    'prescriptionValues': PRESCRIPTION_DEFAULT_VALUES,
    'upload': {
        'data_url': null,
        'file_name': null
    },
    'contacts': {
        'upload': ''
    },
    'subscription': '',
    'prescription_owner': '',
    'currency_symbol': '$',
    'doctor_details': ''
}

export const CONTINUE_URL_KEY = 'continue_url'
export const UPDATE_CURRENT_IMAGE = 'update_current_image'

export const PARTNER_WITH_SIZE = [
    'bose'
]

export const STEP_DESCRIPTION = {
    'frames_customize': {
        'single_vision' : 'Available in Single Vision, Progressive or Bifocal',
        'progressive_standard' : 'Available in Progressive or Bifocal'
    }
}
