var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("section", { staticClass: "cbrands-lplus-ad-block" }, [
      !_vm.plusMember
        ? _c(
            "div",
            {
              staticClass: "lplus-ad-block_container",
              on: { click: _vm.openPlusAdModal }
            },
            [
              _c("a", { attrs: { href: _vm.adLink } }, [
                _c("img", {
                  staticClass: "lplus-ad-block_img",
                  attrs: {
                    src: "/media/wysiwyg/lensabl-plus-ad-shopcontacts.jpeg",
                    alt:
                      "Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "help-bubble-container lplus-help-bubble-container"
                  },
                  [
                    _c("div", { staticClass: "help-component" }, [
                      _c(
                        "div",
                        {
                          staticClass: "help-bubble lplus-help-bubble",
                          class: _vm.showModalAd ? "open" : "",
                          attrs: { id: "help_bubble_text" }
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "close-button",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.closePlusAdModal($event)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "18",
                                    height: "18",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      id: "svg_1",
                                      d:
                                        "m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z",
                                      fill: "rgb(102, 102, 102)",
                                      "fill-rule": "evenodd"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "filtered-product-component" }, [
      _c("div", { staticClass: "filter-product-container" }, [
        _c("div", { staticClass: "filter-options" }, [
          _c(
            "span",
            {
              attrs: { id: "filter-toggle" },
              on: {
                click: function($event) {
                  return _vm.openFilters()
                }
              }
            },
            [_vm._v("Filter")]
          )
        ]),
        _vm._v(" "),
        _vm.getLoader
          ? _c("div", { staticClass: "loader-circle" }, [
              _c(
                "svg",
                {
                  attrs: {
                    id: "L4",
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    x: "0px",
                    y: "0px",
                    viewBox: "0 0 100 100",
                    "enable-background": "new 0 0 0 0",
                    "xml:space": "preserve"
                  }
                },
                [
                  _c(
                    "circle",
                    {
                      attrs: {
                        fill: "#9cdbd9",
                        stroke: "none",
                        cx: "40",
                        cy: "40",
                        r: "20"
                      }
                    },
                    [
                      _c("animate", {
                        attrs: {
                          attributeName: "opacity",
                          dur: "1s",
                          values: "0;1;0",
                          repeatCount: "indefinite",
                          begin: "0.1"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "svg",
                {
                  attrs: {
                    id: "L4",
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    x: "0px",
                    y: "0px",
                    viewBox: "0 0 100 100",
                    "enable-background": "new 0 0 0 0",
                    "xml:space": "preserve"
                  }
                },
                [
                  _c(
                    "circle",
                    {
                      attrs: {
                        fill: "#9cdbd9",
                        stroke: "none",
                        cx: "40",
                        cy: "40",
                        r: "20"
                      }
                    },
                    [
                      _c("animate", {
                        attrs: {
                          attributeName: "opacity",
                          dur: "1s",
                          values: "0;1;0",
                          repeatCount: "indefinite",
                          begin: "0.2"
                        }
                      })
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "svg",
                {
                  attrs: {
                    id: "L4",
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    x: "0px",
                    y: "0px",
                    viewBox: "0 0 100 100",
                    "enable-background": "new 0 0 0 0",
                    "xml:space": "preserve"
                  }
                },
                [
                  _c(
                    "circle",
                    {
                      attrs: {
                        fill: "#9cdbd9",
                        stroke: "none",
                        cx: "40",
                        cy: "40",
                        r: "20"
                      }
                    },
                    [
                      _c("animate", {
                        attrs: {
                          attributeName: "opacity",
                          dur: "1s",
                          values: "0;1;0",
                          repeatCount: "indefinite",
                          begin: "0.3"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "products-grid-contacts" },
          [
            _vm.isMobile
              ? _vm._l(_vm.getProducts, function(product, index) {
                  return _c(
                    "li",
                    { key: product.url, class: index > 9 ? "hidden" : "" },
                    [
                      _c("a", { attrs: { href: product.url } }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: product.image,
                              expression: "product.image"
                            }
                          ],
                          attrs: { alt: product.name }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "product-name-container" }, [
                          _c("span", {
                            directives: [
                              {
                                name: "dompurify-html",
                                rawName: "v-dompurify-html",
                                value: product.name,
                                expression: "product.name"
                              }
                            ],
                            staticClass: "main-product-name"
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "secondary-product-name" },
                            [_vm._v(_vm._s(product.second_title))]
                          )
                        ])
                      ])
                    ]
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isMobile
              ? _vm._l(_vm.getProducts, function(product) {
                  return _c("li", { key: product.url }, [
                    _c("a", { attrs: { href: product.url } }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: product.image,
                            expression: "product.image"
                          }
                        ],
                        attrs: { alt: product.name }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-name-container" }, [
                        _c("span", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: product.name,
                              expression: "product.name"
                            }
                          ],
                          staticClass: "main-product-name"
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "secondary-product-name" }, [
                          _vm._v(_vm._s(product.second_title))
                        ])
                      ])
                    ])
                  ])
                })
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _vm.isMobile && _vm.getProducts.length >= 11 && _vm.getViewAll
          ? _c("div", { staticClass: "view-all-button" }, [
              _c(
                "span",
                {
                  staticClass: "button button--copy-solid",
                  on: { click: _vm.displayAllProducts }
                },
                [
                  _c("div", { staticClass: "button__container" }, [
                    _vm._v("View All Brands")
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          staticClass: "mobile-overlay",
          attrs: { id: "product_overlay" }
        })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("p", [
        _vm._v(
          "A new, better alternative to traditional vision insurance plans, starting as low as "
        ),
        _c("b", [_vm._v("$7.99/month.")])
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Lensabl+ plans include an annual supply of contacts, a FREE frame with Rx lenses or lens replacement, and a reward for getting an eye exam. Save hundreds of dollars out-of-pocket per year on your vision care needs. "
        ),
        _c("span", { staticClass: "c-help-bubble-link" }, [
          _c("a", { attrs: { href: "/plus/vision_plan" } }, [
            _vm._v("Customize your plan now")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }