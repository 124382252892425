<template>
    <div class="rbm-techinfo-container">
        <div class="rbm-specs-container">
            <div class="rbm-specs-row">
                <div class="rbm-specs-img-grid">
                    <img src="/media/catalog/category/ray-ban-meta/camera.jpg" class="rbm-specs-image" />
                    <img src="/media/catalog/category/ray-ban-meta/audio.jpg" class="rbm-specs-image" />
                    <img src="/media/catalog/category/ray-ban-meta/controls.jpg" class="rbm-specs-image" />
                    <img src="/media/catalog/category/ray-ban-meta/case.jpg" class="rbm-specs-image" />
                </div>
            </div>
        </div>
        <div class="rbm-mission-container">
            <div class="rbm-mission-text">
                <span class="rbm-mission-header">perspective is everything</span>
                <span class="rbm-mission-subtext">Listen, call, capture and livestream - all with your phone in your pocket.</span>
            </div>
        </div>
        <div class="rbm-order-container">
            <div class="rbm-order-text">
                <span class="rbm-order-header">Order Ray-Ban Meta Online From Lensabl</span>
                <span class="rbm-order-subtext">Lensabl offers great deals and fast, free delivery on any set of Ray-Ban Meta glasses with custom lenses. Order your new eyewear online today!</span>
                <a href="/ray-ban-meta-eyewear" title="Ray-Ban Meta Eyewear">
                    <div class="button button--copy-solid">
                        <div class="button__container">Place Your Ray-Ban Meta Order Online Today!</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TechInfo',
}
</script>
