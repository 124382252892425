<template>
    <div v-if="images && images.length" class="c-product-gallery-desktop">
        <div class="c-product-gallery-main">
            <img :src="showMainimage" :alt="name" />
        </div>
        <div class="c-product-gallery-thumbnail">
            <template v-for="(image, index) in images">
                <div v-if="index < 2" :key="image.index" class="c-product-gallery-thumbnail--item">
                    <div :class="{'active':(index == imageIndex)}" class="c-product-gallery-thumbnail--item-container" @click="updateMainImage(index)">
                        <img :src="image.thumbnail" :alt="name" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'DesktopGallery',
    props: {
        'name': {
            type: String,
            default: '',
            required: true
        }
    },
    data: () => {
        return {
            imageIndex: 0
        }
    },
    computed: {
        ...mapState('contacts/productView', ['images']),
        showMainimage () {
            return this.images[this.imageIndex].thumbnail
        }
    },
    methods: {
        updateMainImage (index) {
            this.imageIndex = index
        }
    }
}
</script>
