<template>
    <section class="search-component">
        <div class="search-component-container">
            <div v-if="confirmedEye === 'right'" class="left-dir">
                <h1>Now select your left eye</h1>
                <span @click="goToDeatils(right)">skip</span>
            </div>
            <div v-if="confirmedEye === 'left'" class="left-dir">
                <h1>Now select your right eye</h1>
                <span @click="goToDeatils(right)">skip</span>
            </div>
            <div v-else-if="confirmedEye === true">
                <h1>Contact Lenses</h1>
                <h2>At Amazingly Low Prices</h2>
            </div>
            <div class="search-container">
                <div class="search-container-main">
                    <div>
                        <input
                            id="category-search"
                            ref="search"
                            v-model="searchBrand"
                            type="text"
                            placeholder="Enter your prescribed contacts brand"
                            @focus="searchResultsVisible = true"
                            @keydown.esc="blur"
                            @input="softRest"
                            @keyup="performSearch"
                            @blur="setHighlightIndex(), searchResultsVisible = false"
                            @keydown.up.prevent="highlightPrevious"
                            @keydown.down.prevent="highlightNext"
                            @keydown.enter="goToLink"
                        />
                    </div>
                    <div v-if="searchBrand.length > 0 && searchResultsVisible" class="results-dropdown">
                        <ul id="results">
                            <a
                                v-for="(products, index) in searchResults"
                                :key="index"
                                :href="products.url"
                                :class="{active: index === highlightedIndex}"
                                @mousedown.prevent="searchResultsVisible = true"
                                @mouseover="hover(index)"
                            >
                                <li v-dompurify-html="collection[index]"></li>
                            </a>
                            <li
                                v-if="searchResults.length > 0"
                                :class="{active: highlightedIndex === searchResults.length}"
                                class="view-all"
                                @click="displayProducts"
                                @mousedown.prevent="searchResultsVisible = true"
                            >
                                View All Results
                            </li>
                            <li v-if="searchResults.length === 0" class="no-results">
                                No results for <strong>{{ searchBrand }}</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                <span class="button button--copy-solid" @click="displayProducts">
                    <div class="button__container">Search Brands</div>
                </span>
            </div>
            <div v-if="getSearched" class="search-results-display">Showing all results for <span>"{{ getSearched }}"</span></div>
        </div>
    </section>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'
import _size from 'lodash/size'
import Fuse from 'fuse.js'
export default {
    name: 'Search',
    data: () => {
        return {
            searchBrand: [],
            searchResultsVisible: false,
            searchResults: [],
            searchNames:[],
            highlightedIndex: -1,
            collection: [],
            options: {
                includeMatches: true,
                shouldSort: true,
                threshold: 0.5,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                    'name'
                ]
            },
            confirmedEye: ''
        }
    },
    computed: {
        ...mapGetters('category', ['parentCategory', 'categoryService']),
        ...mapGetters('contacts', ['getProducts', 'getRightEyeConfirmed', 'getLeftEyeConfirmed', 'getAllProducts', 'getSearched'])
    },
    created () {
        this.updateAllProducts()
        this.bothEyesConfirmed()
    },
    methods: {
        ...mapMutations([]),
        displayProducts () {
            this.$store.commit('contacts/UPDATE_SEARCHED', this.searchBrand)
            this.searchBrand = ''
            this.$store.commit('contacts/UPDATE_PRODUCTS', this.searchResults)
            this.blur()
        },
        updateAllProducts () {
            if (this.parentCategory && _size(this.parentCategory)) {
                this.categoryService.getContactsProductsCollection(this.parentCategory[0].id)
                    .then(({status, data}) => {
                        if (status === 200 && !data.error) {
                            this.$store.commit('contacts/UPDATE_ALL_PRODUCTS', data.products)
                        }
                        this.$store.commit('contacts/UPDATE_VIEW_ALL', true)
                    })
                    .catch((error) => {
                        if (error) {
                            console.error(error)
                        }
                    })
            }
        },
        setHighlightIndex () {
            this.highlightedIndex = -1
        },
        softRest () {
            this.searchResultsVisible = true
            this.setHighlightIndex()
        },
        blur () {
            this.$refs.search.blur()
        },
        performSearch () {
            const collection = []
            this.collection = []
            const fuse = new Fuse(this.getAllProducts, this.options)
            const results = fuse.search(this.searchBrand)
            results.forEach(item => {
                let text = item.matches[0].value
                if (text) {
                    let offset = 0
                    item.matches[0].indices.forEach(index => {
                        text = this.highlightText(text, index[0] + offset, index[1] + offset)
                        offset += 31
                    })
                    this.setValue(text)
                }
                collection.push(item.item)
            })
            this.searchResults = collection
        },
        highlightNext () {
            if (this.highlightedIndex < this.searchResults.length) {
                this.highlightedIndex = this.highlightedIndex + 1
                if (this.searchResultsVisible) {
                    const element = document.getElementById('results')
                    element.children[this.highlightedIndex].scrollIntoView({block: 'nearest'})
                }
            }
        },
        highlightPrevious () {
            if (this.highlightedIndex > -1) {
                this.highlightedIndex = this.highlightedIndex - 1
                if (this.searchResultsVisible) {
                    const element = document.getElementById('results')
                    element.children[this.highlightedIndex].scrollIntoView({block: 'nearest'})
                }
            }
        },
        goToLink () {
            if (this.searchResults[this.highlightedIndex]) {
                window.location = this.searchResults[this.highlightedIndex].url
            } else if (this.highlightedIndex >= this.searchResults.length) {
                this.displayProducts()
            }
        },
        hover (index) {
            this.highlightedIndex = index
        },
        highlightText (sourceString, startIndex, endIndex) {
            return sourceString.substring(0, startIndex) +
                '<span class="highlight">' +
                sourceString.substring(startIndex, endIndex + 1) +
                '</span>' +
                (sourceString.substring(endIndex + 1))
        },
        setValue (newValue) {
            this.collection.push(newValue)
        },
        bothEyesConfirmed () {
            if ((!this.getRightEyeConfirmed && !this.getLeftEyeConfirmed) || (this.getRightEyeConfirmed && this.getLeftEyeConfirmed)) {
                this.confirmedEye = true
            } else if (this.getRightEyeConfirmed) {
                this.confirmedEye = 'right'
            } else {
                this.confirmedEye = 'left'
            }
        },
        goToDeatils (index) {
            window.location.href = '/contact-lenses-details'
        }
    }
}
</script>

<style scoped>

</style>
