import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _first from 'lodash/first'
import _keys from 'lodash/keys'
import _map from 'lodash/map'
import {
    EVENT_STEP_UPDATE,
    EVENT_RESET_AFTER
} from '../../../../app/utils/constants'
import {mapGetters} from 'vuex'

import SelectorOptions from '../SelectorOptions.vue'

export default {
    name: 'SelectorOptionsLensType',
    extends: SelectorOptions,
    computed: {
        ...mapGetters('configurator', ['product_config'])
    },
    watch: {
        isStepCurrent (current) {
            if (current) {
                this._updateComputedOptions()
            }
        }
    },
    created () {
        this.options = this.computedOptionsWhichAreConfigured()
        this._initValue(this.value)
        this.$bus.$on(EVENT_RESET_AFTER, this._initValue)
        this.$bus.$on(EVENT_STEP_UPDATE, this._updateComputedOptions)
    },
    beforeDestroy () {
        this.$bus.$off(EVENT_RESET_AFTER, this._initValue)
        this.$bus.$off(EVENT_STEP_UPDATE, this._updateComputedOptions)
    },
    methods: {
        computedOptionsWhichAreConfigured () {
            const config = this.product_config || {}
            const productType = this.productType
            const productConfig = config[productType]
            if (!productConfig) {
                return []
            }

            const optionsConfig = productConfig.config || productConfig
            if (!optionsConfig) {
                return []
            }

            const configLabels = _map(optionsConfig, option => option.label)

            return _filter(this.step.options, option => {
                const extraOptions = option.component_extra_options
                if (extraOptions) {
                    const checkoutValues = _map(extraOptions.params.sections, section => section.checkout_value)
                    const filteredSectionLabels = _filter(checkoutValues, label => configLabels.indexOf(label) !== -1)
                    return !this.limitersDisabled.includes(option.id) && filteredSectionLabels.length > 0
                }
                return configLabels.indexOf(option.checkout_value) !== -1 && !this.limitersDisabled.includes(option.id)
            })
        },
        _updateComputedOptions () {
            this.options = this.computedOptionsWhichAreConfigured()
            if (!_find(this.options, option => option.id === this.step.value)) {
                if (this.step.defaultValue && !_find(this.options, option => option.id === this.step.defaultValue)) {
                    this.value = _keys(this.options).length ? _first(this.options).id : ''
                } else {
                    this.value = this.step.defaultValue
                }
            }
            return this.value
        }
    }
}
