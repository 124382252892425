<template>
    <div :class="productCategoryName">
        <div
            v-show="productView.productInfoShow"
            :class="{'bose-alto' : product_name.toLowerCase() === 'alto'}"
            class="product-configurator-configuration product-details-holder row lensabl-wrapper frames-wrapper bose-container"
        >
            <div class="category-left">
                <div class="bose-header mobile">
                    <span v-if="productCategoryName === 'bose'" class="product-name-mobile-pre">Bose Frames</span>
                    <h1 class="product-name-mobile"><span>{{ productName }}</span></h1>
                </div>

                <Gallery :class="{'vertical-thumbs': isDesktop}" />
                <div
                    v-if="productCategoryName === 'bose' && oldSkus.includes(product_name.toLowerCase())"
                    class="product media"
                >
                    <VirtualTryOn
                        :current-color-item="colorItem"
                        :product-category-name="productCategoryName"
                        :frame-colors="colors"
                        :frames-label="'Customize'"
                        :lens-colors="sunColors"
                        :checked-index="checkedIndex"
                        :checked-lens-index="sunIndex"
                        @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor"
                        @[EVENT_CHANGE_CURRENT_COLOR_LENS]="changeCurrentLensColor"
                    />
                </div>
            </div>
            <div class="look-info category-left">
                <div class="product-name">
                    <div class="bose-header">
                        <span v-if="productCategoryName === 'bose'" class="product-name-desktop-pre">Bose Frames</span>
                        <h1 class="product-name-desktop"><span>{{ productName }}</span></h1>
                    </div>
                </div>

                <div class="lensabl-colors instructions">
                    <BoseDimensionsAlto v-if="product_name.toLowerCase() === 'alto'" />

                    <div v-if="showAttr" class="swatch-opt hyper-x">
                        <div v-if="product_name.toLowerCase() === 'duo bells' || product_name.toLowerCase() === 'duo lennox'" class="hobie-additional">
                            <p class="hobie-clipons">Select more than one color below to add-on additional clip-ons ($40 each):</p>
                        </div>
                        <ColorSwatches
                            :colors="colors"
                            :checked-index="parseInt(checkedIndex)"
                            :option-item-class="'decorate bigger img'"
                            :data-swatch-product="product_name"
                            @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor"
                        />
                    </div>

                    <div v-if="productCategoryName.indexOf('hyperx gaming eyewear') !== -1 " class="hobie-additional">
                        <AdditionalAttributes :data-attributes="hyperxAdditionalAttributes" class="inline" />
                    </div>

                    <p v-dompurify-html="instructionHtml" class="p-instruction"></p>
                    <p v-dompurify-html="affirmHtml" class="affirm-shop-frames"></p>
                    <div class="accrue-frames-section" :style="{ 'margin-bottom': '0.5rem', 'font-size': '15px' }">
                        <span>or save up and earn <span class="accrue-percentage">15%</span> in cash rewards with <span><img src="/media/wysiwyg/accrue-logo-black.png" class="accrue-logo-black-frames-img" /></span></span>
                    </div>
                    <div v-if="productCategoryName === 'bose'" class="frames-params">
                        <div v-if="colors.length > 1">
                            <select v-model="frameSize" @change="updateSize()">
                                <template v-for="(color, index) in colors">
                                    <option :key="color.color" :value="index" :selected="index === 0">
                                        {{ color.color }}
                                    </option>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div v-if="isAvailable" class="product-options-bottom">
                        <div class="actions">
                            <BaseButton v-if="!isDesktop" :text="'Choose Your Lenses'" @click.native="isMobile ? mobileSwitcher() : goToConfigurator()">
                                <span v-if="productCategoryName === 'bose'">
                                    Choose Your Lenses
                                </span>
                                <span v-else>
                                    SELECT LENSES
                                </span>
                            </BaseButton>
                            <div v-if="productCategoryName === 'bose'" class="bose-product-links">
                                <div class="bose-header-links-container">
                                    <p>Have Bose frames and just need lenses?<a href="/order-lenses-bose">Click Here</a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p v-if="productCategoryName === 'bose'" style="font-size: 12px;font-style: italic;padding: 0;">
                        Lens production takes 4-7 business days on average from the date you've submitted all required Rx information. All lenses go through a detailed quality inspection before shipping out. If any issues are found during inspection, we will remake the lenses until they are perfect (this may cause slight delays in shipping time)
                    </p>

                    <p v-if="product_name.toLowerCase() === 'tempo'" style="font-size: 12px;font-style: italic;padding: 0;">
                        The Bose Tempo frame is a sport frame with a wrap style lens. Because of this we unfortunately cannot produce a Bifocal lens for this type of frame due to how the lens curves. We are only able to produce a Progressive (no-line bifocal) or a Single vision lens. We are also unable to make lenses for prescriptions with a total power (SPH + CYL) stronger than -3.00 or +2.50.
                    </p>

                    <div v-if="!isAvailable" class="look-params">
                        <div class="look-item">
                            <span class="look-item-info" style="color:red"> This item is sold out</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AnagramAdBar />
        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator />
        </div>
    </div>
</template>

<script>
import CatalogProductView from './CatalogProductView.vue'
import ResetService from '../../../app/mixins/reset-service'
import VirtualTryOn from './view/virtual_try_on/VirtualTryOn.vue'
import {mapGetters} from 'vuex'
import _map from 'lodash/map'
import getSizeHelper from './lensabl/constants/size'
import AdditionalAttributes from './view/AdditionalAttributes.vue'
import BoseDimensionsAlto from './view/bose/BoseDimensionsAlto.vue'
import AnagramAdBar from '../../anagram/AnagramAdBar.vue'
import {
    EVENT_CHANGE_CURRENT_COLOR_LENS
} from '../../../app/utils/constants'
import AnagramAdBarVue from '../../anagram/AnagramAdBar.vue'

export default {
    name: 'CatalogProductBoseView',
    components: {
        VirtualTryOn,
        AdditionalAttributes,
        BoseDimensionsAlto,
        AnagramAdBar
    },
    extends: CatalogProductView,
    mixins: [ResetService],
    data: () => {
        return {
            instructionHtml: '',
            affirmHtml: '',
            sizeIndex: '',
            frameSize: 0,
            oldSkus: ['alto', 'rondo', 'tempo', 'soprano', 'tenor'],
            EVENT_CHANGE_CURRENT_COLOR_LENS: EVENT_CHANGE_CURRENT_COLOR_LENS
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isDesktop', 'isMobile']),
        ...mapGetters('productView', ['productView', 'frame_price', 'images', 'productCategoryName', 'colorItem', 'getCurrentProductAttribute']),
        hyperxAdditionalAttributes () {
            return [
                {
                    label: 'Size',
                    value: this.getCurrentProductAttribute('size')
                },
                {
                    label: 'Description',
                    value: this.getCurrentProductAttribute('product_description')
                }
            ]
        },
        showAttr () {
            const frames = [
                'hyperx gaming',
                'le specs'
            ]
            const existArr = []
            frames.map((frame) => {
                existArr.push(this.productCategoryName.indexOf(frame) !== -1)
            })
            return existArr.includes(true)
        },
        productName () {
            return String(this.product_name).replace(/&#39;/g, "'")
        }
    },
    watch: {
        frame_price () {
            this.setInstructionText(this.frame_price)
            this.setAffirmPrice(this.frame_price)
        }
    },
    mounted () {
        this.setInstructionText(this.frame_price)
        this.setAffirmPrice(this.frame_price)
        this.getSizeFromUrl()
        this.updateSize()
    },
    created () {
        const {
            size_helpComponent
        } = getSizeHelper({assets_url: this.$store.getters['storeView/assets_url']})
        this.size_components = size_helpComponent
        this.sunIndex = 0
    },
    methods: {
        changeCurrentColor (index) {
            this.setCheckedIndex(index)
            this.frameSize = index
        },
        updateSize () {
            this.setValuesService(this.colors[this.frameSize])
            this.changeCurrentColor(this.frameSize)
        },
        setInstructionText (frame_price, delPrice = false) {
            this.instructionHtml = 'Starting from '
            if (delPrice) {
                this.instructionHtml += '<del><span class="amount">$' + delPrice + '</span></del>'
            }

            this.instructionHtml += '<span>$' + frame_price + '</span>'
        },
        setAffirmPrice (frame_price) {
            const dividedPrice = parseFloat(frame_price) / 3
            const affirmPayment = '$' + Math.round(dividedPrice)
            this.affirmHtml = 'or 3 payments of ' + affirmPayment + ' with <span class="__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed">Affirm</span>'
        },
        goToConfigurator () {
            this.scrollToStepHead(this.visibleFirstStep.id)
        },
        getSizeFromUrl () {
            const getUrlParams = url => {
                const urlWithParams = `${url}`.split('?')
                if (urlWithParams.length > 1) {
                    return urlWithParams[1].split('&').reduce(
                        (params, pair) => ((key, val) => key ? {
                            ...params,
                            [key]: val
                        } : params)(...`${pair}=`.split('=').map(decodeURIComponent)),
                        {}
                    )
                }
            }
            const query = getUrlParams(window.location)
            let indexSize = 0
            const sizes = _map(this.colors, item => (item.color).replace(/\s/g, '_').replace(/-/g, '').toLowerCase())

            Object.keys(sizes).forEach(key => {
                if (query && query.hasOwnProperty(sizes[key])) {
                    indexSize = key
                }
            })

            window.onload = () => this.setCheckedIndex(indexSize.toString())
        }
    }
}
</script>
