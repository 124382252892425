var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.productCategoryName },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.productView.productInfoShow,
              expression: "productView.productInfoShow"
            }
          ],
          staticClass:
            "product-configurator-configuration product-details-holder row lensabl-wrapper frames-wrapper bose-container",
          class: { "bose-alto": _vm.product_name.toLowerCase() === "alto" }
        },
        [
          _c(
            "div",
            { staticClass: "category-left" },
            [
              _c("div", { staticClass: "bose-header mobile" }, [
                _vm.productCategoryName === "bose"
                  ? _c("span", { staticClass: "product-name-mobile-pre" }, [
                      _vm._v("Bose Frames")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h1", { staticClass: "product-name-mobile" }, [
                  _c("span", [_vm._v(_vm._s(_vm.productName))])
                ])
              ]),
              _vm._v(" "),
              _c("Gallery", { class: { "vertical-thumbs": _vm.isDesktop } }),
              _vm._v(" "),
              _vm.productCategoryName === "bose" &&
              _vm.oldSkus.includes(_vm.product_name.toLowerCase())
                ? _c(
                    "div",
                    { staticClass: "product media" },
                    [
                      _c("VirtualTryOn", {
                        attrs: {
                          "current-color-item": _vm.colorItem,
                          "product-category-name": _vm.productCategoryName,
                          "frame-colors": _vm.colors,
                          "frames-label": "Customize",
                          "lens-colors": _vm.sunColors,
                          "checked-index": _vm.checkedIndex,
                          "checked-lens-index": _vm.sunIndex
                        },
                        on: _vm._d({}, [
                          _vm.EVENT_CHANGE_CURRENT_COLOR,
                          _vm.changeCurrentColor,
                          _vm.EVENT_CHANGE_CURRENT_COLOR_LENS,
                          _vm.changeCurrentLensColor
                        ])
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "look-info category-left" }, [
            _c("div", { staticClass: "product-name" }, [
              _c("div", { staticClass: "bose-header" }, [
                _vm.productCategoryName === "bose"
                  ? _c("span", { staticClass: "product-name-desktop-pre" }, [
                      _vm._v("Bose Frames")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("h1", { staticClass: "product-name-desktop" }, [
                  _c("span", [_vm._v(_vm._s(_vm.productName))])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "lensabl-colors instructions" },
              [
                _vm.product_name.toLowerCase() === "alto"
                  ? _c("BoseDimensionsAlto")
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAttr
                  ? _c(
                      "div",
                      { staticClass: "swatch-opt hyper-x" },
                      [
                        _vm.product_name.toLowerCase() === "duo bells" ||
                        _vm.product_name.toLowerCase() === "duo lennox"
                          ? _c("div", { staticClass: "hobie-additional" }, [
                              _c("p", { staticClass: "hobie-clipons" }, [
                                _vm._v(
                                  "Select more than one color below to add-on additional clip-ons ($40 each):"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("ColorSwatches", {
                          attrs: {
                            colors: _vm.colors,
                            "checked-index": parseInt(_vm.checkedIndex),
                            "option-item-class": "decorate bigger img",
                            "data-swatch-product": _vm.product_name
                          },
                          on: _vm._d({}, [
                            _vm.EVENT_CHANGE_CURRENT_COLOR,
                            _vm.changeCurrentColor
                          ])
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.productCategoryName.indexOf("hyperx gaming eyewear") !== -1
                  ? _c(
                      "div",
                      { staticClass: "hobie-additional" },
                      [
                        _c("AdditionalAttributes", {
                          staticClass: "inline",
                          attrs: {
                            "data-attributes": _vm.hyperxAdditionalAttributes
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("p", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.instructionHtml,
                      expression: "instructionHtml"
                    }
                  ],
                  staticClass: "p-instruction"
                }),
                _vm._v(" "),
                _c("p", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.affirmHtml,
                      expression: "affirmHtml"
                    }
                  ],
                  staticClass: "affirm-shop-frames"
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "accrue-frames-section",
                    style: { "margin-bottom": "0.5rem", "font-size": "15px" }
                  },
                  [_vm._m(0)]
                ),
                _vm._v(" "),
                _vm.productCategoryName === "bose"
                  ? _c("div", { staticClass: "frames-params" }, [
                      _vm.colors.length > 1
                        ? _c("div", [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.frameSize,
                                    expression: "frameSize"
                                  }
                                ],
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.frameSize = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function($event) {
                                      return _vm.updateSize()
                                    }
                                  ]
                                }
                              },
                              [
                                _vm._l(_vm.colors, function(color, index) {
                                  return [
                                    _c(
                                      "option",
                                      {
                                        key: color.color,
                                        domProps: {
                                          value: index,
                                          selected: index === 0
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(color.color) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAvailable
                  ? _c("div", { staticClass: "product-options-bottom" }, [
                      _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          !_vm.isDesktop
                            ? _c(
                                "BaseButton",
                                {
                                  attrs: { text: "Choose Your Lenses" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.isMobile
                                        ? _vm.mobileSwitcher()
                                        : _vm.goToConfigurator()
                                    }
                                  }
                                },
                                [
                                  _vm.productCategoryName === "bose"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                Choose Your Lenses\n                            "
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "\n                                SELECT LENSES\n                            "
                                        )
                                      ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.productCategoryName === "bose"
                            ? _c("div", { staticClass: "bose-product-links" }, [
                                _vm._m(1)
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.productCategoryName === "bose"
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "font-style": "italic",
                          padding: "0"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Lens production takes 4-7 business days on average from the date you've submitted all required Rx information. All lenses go through a detailed quality inspection before shipping out. If any issues are found during inspection, we will remake the lenses until they are perfect (this may cause slight delays in shipping time)\n                "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.product_name.toLowerCase() === "tempo"
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          "font-style": "italic",
                          padding: "0"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    The Bose Tempo frame is a sport frame with a wrap style lens. Because of this we unfortunately cannot produce a Bifocal lens for this type of frame due to how the lens curves. We are only able to produce a Progressive (no-line bifocal) or a Single vision lens. We are also unable to make lenses for prescriptions with a total power (SPH + CYL) stronger than -3.00 or +2.50.\n                "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isAvailable
                  ? _c("div", { staticClass: "look-params" }, [_vm._m(2)])
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("AnagramAdBar"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAvailable && _vm.productView.productConfiguratorShow,
              expression: "isAvailable && productView.productConfiguratorShow"
            }
          ],
          staticClass: "product-configurator-wrapper"
        },
        [_c("Configurator")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("or save up and earn "),
      _c("span", { staticClass: "accrue-percentage" }, [_vm._v("15%")]),
      _vm._v(" in cash rewards with "),
      _c("span", [
        _c("img", {
          staticClass: "accrue-logo-black-frames-img",
          attrs: { src: "/media/wysiwyg/accrue-logo-black.png" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bose-header-links-container" }, [
      _c("p", [
        _vm._v("Have Bose frames and just need lenses?"),
        _c("a", { attrs: { href: "/order-lenses-bose" } }, [
          _vm._v("Click Here")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "look-item" }, [
      _c(
        "span",
        { staticClass: "look-item-info", staticStyle: { color: "red" } },
        [_vm._v(" This item is sold out")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }