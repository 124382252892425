var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "selector-lens-power" },
      [
        _c("CustomSelect", {
          staticClass: "eye-power-container",
          attrs: {
            id: "eye_power_selector",
            "label-title": "Lens Magnification",
            value: (_vm.value.od = _vm.value.os),
            options: _vm.eyePowerReadingOptions
          },
          on: {
            change: function($event) {
              return _vm.setParamsLensPower($event.target.value)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }