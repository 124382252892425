<template>
    <div class="plus-account_container glasses-container">
        <h4 v-if="isMobile" :class="{active: isShow}" @click="toggleContent">{{ getTitle }}</h4>
        <transition name="slide">
            <div v-if="!isMobile || isShow && isMobile" class="plus-content_container glasses-content">
                <div class="redeem-description">
                    <div class="redeem-container">
                        <div>
                            <img :src="redeem.img"/>
                        </div>
                        <div>
                            <span class="description">
                              {{ getTitle }}
                            </span>
                        </div>
                        <div class="secondary">
                            <BaseButton
                                :text="redeem.btnText"
                                data-size="xs"
                                class="button button--copy-solid"
                                @click.native="redirectTo"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseButton from '../base/BaseButton.vue'

export default {
    name: 'Redeem',
    components: {
        BaseButton,
    },
    props: {
        redeem: {
            type: Object,
            default: () => {
            }
        },
        discount: {
            type: String,
            default: ''
        },
        btnText: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        isShow: false,
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        getTitle() {
            return this.redeem.text + this.discount
        }
    },
    methods: {
        toggleContent() {
            if (this.isMobile) {
                this.isShow = !this.isShow
            }
        },
        redirectTo() {
            window.location.href = this.redeem.redirectTo
        }

    }
}
</script>
