import getSosService from '../../../app/utils/sos-service'
import _isArray from 'lodash/isArray'
import * as abstract from '../../../storage/abstract'

export const state = () => ({
    ...abstract.state(),
    sos_client_id: '',
    customer_id: '',
    sos_server_url: '',
    frame_size: [],
    base_url: ''
})

export const getters = {
    getBaseUrl: (state, getters, rootState, rootGetters) => rootGetters['storeView/base_url'],
    getSosServerUrl: state => state.sos_server_url,
    getSosClientId: state => state.sos_client_id,
    getFrameSize: state => state.frame_size,
    getSosService: (state, getters, rootState, rootGetters) => getSosService({base_url: rootGetters['storeView/base_url']})
}

export const actions = {
    ...abstract.actions,
    fetchFrameData: ({getters, commit, dispatch}, data = {}) => {
        getters.getSosService.fetchFrameData(data).then(
            response => {
                return response.data
            },
            error => {
                console.error(error)
                return error
            })
    },
    postFrameResults: ({getters, commit, dispatch}, data = {}) => {
        getters.getSosService.postFrameResults(data).then(
            response => {
                commit('SET_FRAMESIZE', response.data.frame_size)
                commit('category/UPDATE_FRAME_SIZE', response.data.frame_size, { root: true })
            },
            error => {
                console.error(error)
            }
        )
    },
    postRxReaderData: ({getters, commit, dispatch}, data = {}) => {
        return getters.getSosService.postRxReaderResults(data)
    }
}

export const mutations = {
    ...abstract.mutations,
    SET_SOSCLIENTID : (state, sos_client_id = '') => state.sos_client_id = sos_client_id,
    SET_FRAMESIZE : (state, frame_size = []) => {
        if (!_isArray(frame_size)) {
            console.error('Incorrect format detected.')
        }
        state.frame_size = frame_size
    }
}

export default () => ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
