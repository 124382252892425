import {
    LENS_OPTION_CODE_167,
    LENS_OPTION_CODE_174,
    LENS_OPTION_CODE_CR39,
    LENS_OPTION_CODE_TRIVEX
} from '../../../../configurator/storage/constants/lens_options'

const DISABLED_BY_LENS_TYPE = {
    'Sun Tinted': [LENS_OPTION_CODE_174],
    'Sun Tinted Gradient': [LENS_OPTION_CODE_174],
    'Sun Tinted Match Color': [LENS_OPTION_CODE_174],
    'Sun Tinted Match Color Gradient': [LENS_OPTION_CODE_174],
    'Sun Mirrored Polarized': [LENS_OPTION_CODE_174, LENS_OPTION_CODE_TRIVEX],
    'Sun Polarized':[LENS_OPTION_CODE_174],
    'Sun Polarized Gradient':[LENS_OPTION_CODE_174]
}
export const DISABLE_LENS_OPTIONS_BY_RX_AND_LENS_TYPE = {
    'single_vision_distance_premium': {
        ...DISABLED_BY_LENS_TYPE, ...{
            'Blue-Light Blocking': [LENS_OPTION_CODE_174]
        }
    },
    'single_vision_distance': {
        ...DISABLED_BY_LENS_TYPE, ...{
            'Blue-Light Blocking': [LENS_OPTION_CODE_174]
        }
    },
    'single_vision_reading_premium': {
        ...DISABLED_BY_LENS_TYPE, ...{
            'Blue-Light Blocking': [LENS_OPTION_CODE_174]
        }
    },
    'single_vision_reading': {
        ...DISABLED_BY_LENS_TYPE, ...{
            'Blue-Light Blocking': [LENS_OPTION_CODE_174]
        }
    },
    'progressive_standard': {
        ...DISABLED_BY_LENS_TYPE, ...{
            'Sun Polarized': [LENS_OPTION_CODE_167]
        }
    },
    'progressive_premium': {
        ...DISABLED_BY_LENS_TYPE, ...{
            'Transition Non-Polarized': [LENS_OPTION_CODE_TRIVEX, LENS_OPTION_CODE_174],
            'Transition XTRActive': [LENS_OPTION_CODE_TRIVEX, LENS_OPTION_CODE_174],
            'Transition Polarized': [LENS_OPTION_CODE_TRIVEX, LENS_OPTION_CODE_174],
        }
    },
    'bi_focal': {...DISABLED_BY_LENS_TYPE, ...{'Sun Mirrored Polarized': [LENS_OPTION_CODE_CR39, LENS_OPTION_CODE_174], }},
}

const DISABLE_LENS_OPTIONS_BY_COLOR = {
    'Sun Polarized': {
        [LENS_OPTION_CODE_TRIVEX]: ['G15 C'],
    },
    'Sun Polarized Gradient':{
        [LENS_OPTION_CODE_TRIVEX]: ['Grey Gradient C', 'Brown Gradient C'],
        [LENS_OPTION_CODE_167] : [ 'Grey Gradient C', 'Brown Gradient C'],
    },
    'Transition Non-Polarized': {
        [LENS_OPTION_CODE_TRIVEX]: ['Graphite Green', 'Emerald', 'Sapphire', 'Amethyst', 'Amber'],
        [LENS_OPTION_CODE_174]: ['Graphite Green', 'Emerald', 'Sapphire', 'Amethyst', 'Amber'],
    },
    'Transition XTRActive': {
        [LENS_OPTION_CODE_TRIVEX]: [
            'Graphite Green',
            'Blue (Mirror)',
            'Gold (Mirror)',
            'Green (Mirror)',
            'Pink (Mirror)',
            'Red (Mirror)',
            'Silver (Mirror)'
        ],
        [LENS_OPTION_CODE_174]: [
            'Graphite Green',
            'Blue (Mirror)',
            'Gold (Mirror)',
            'Green (Mirror)',
            'Pink (Mirror)',
            'Red (Mirror)',
            'Silver (Mirror)'
        ],
        [LENS_OPTION_CODE_CR39]: [
            'Blue (Mirror)',
            'Gold (Mirror)',
            'Green (Mirror)',
            'Pink (Mirror)',
            'Red (Mirror)',
            'Silver (Mirror)'
        ]
    },
    'Transition Polarized': {
        [LENS_OPTION_CODE_TRIVEX]: ['Polarized Grey']
    }
}
export const DISABLE_LENS_OPTIONS_BY_RX_AND_COLORS = {
    'single_vision_distance_premium': {...DISABLE_LENS_OPTIONS_BY_COLOR},
    'single_vision_distance': {...DISABLE_LENS_OPTIONS_BY_COLOR},
    'single_vision_reading_premium': {...DISABLE_LENS_OPTIONS_BY_COLOR},
    'single_vision_reading': {...DISABLE_LENS_OPTIONS_BY_COLOR,},
    'progressive_standard': {
        ...DISABLE_LENS_OPTIONS_BY_COLOR, ...{
            'Transition XTRActive': {
                [LENS_OPTION_CODE_TRIVEX]: [
                    'Graphite Green',
                    'Blue (Mirror)',
                    'Gold (Mirror)',
                    'Green (Mirror)',
                    'Pink (Mirror)',
                    'Red (Mirror)',
                    'Silver (Mirror)'
                ],
                [LENS_OPTION_CODE_174]: [
                    'Graphite Green',
                    'Blue (Mirror)',
                    'Gold (Mirror)',
                    'Green (Mirror)',
                    'Pink (Mirror)',
                    'Red (Mirror)',
                    'Silver (Mirror)'
                ],
            }
        }
    },
    'progressive_premium': {...DISABLE_LENS_OPTIONS_BY_COLOR},
    'bi_focal': {...DISABLE_LENS_OPTIONS_BY_COLOR},
}
