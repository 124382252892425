var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.stepsList, function(step) {
        return [
          step.summary_label
            ? _c(
                "li",
                { key: step.id, staticClass: "item", class: step.id },
                [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.framesPartnerPage && step.summary_partner_label
                            ? step.summary_partner_label + ":"
                            : step.summary_label + ":"
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  step.id === "customShippingLabel"
                    ? _c(
                        "div",
                        { staticClass: "renderer-options summary-value" },
                        [
                          _c("b", [_vm._v("Free")]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "option-value free-shipping-label" },
                            [
                              _c("HelpBubble", {
                                attrs: {
                                  placement: _vm.placement,
                                  data: _vm.component_help
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _c("Renderer", {
                        class: "summary-value",
                        attrs: {
                          id: step.id,
                          name: step.value_renderer,
                          place: _vm.place
                        }
                      })
                ],
                1
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }