var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.prescriptionData.status_4
        ? _c("RadioLabel", {
            attrs: { active: _vm.isActive, label: _vm.title, value: _vm.index },
            on: {
              click: function($event) {
                return _vm.$emit("checked", _vm.index)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive,
              expression: "isActive"
            }
          ],
          staticClass: "my-prescription-form__pupillary-distance-sh"
        },
        [
          _c(
            "div",
            { staticClass: "my-prescription-form__dropdown-pupillary" },
            [
              _c("div", { staticClass: "two-pupillary-numbers" }, [
                _c(
                  "div",
                  { staticClass: "sh-item" },
                  [
                    _c("CustomSelect", {
                      staticClass: "pd-input",
                      attrs: {
                        value: _vm.value.od_sh,
                        options: _vm.eyeSHOptions
                      },
                      on: {
                        change: function($event) {
                          _vm.value.od_sh = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "two-PD-numbers" }, [
                      _vm._v("OD (right eye)")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "sh-item" },
                  [
                    _c("CustomSelect", {
                      staticClass: "pd-input",
                      attrs: {
                        value: _vm.value.os_sh,
                        options: _vm.eyeSHOptions
                      },
                      on: {
                        change: function($event) {
                          _vm.value.os_sh = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "two-PD-numbers" }, [
                      _vm._v("OS (left eye)")
                    ])
                  ],
                  1
                )
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }