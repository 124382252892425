<template>
    <div class="color-selector-container-filters-wrapper">
        <ul class="color-selector-container-filters">
            <li v-for="filter in filters" :key="`filter-${filter.id}`"
                :class="{'active': currentFilterId === filter.id}" @click="setCurrentFilterId(filter.id)">
                <span v-text="filter.label"></span>
            </li>
        </ul>
        <div class="color-selector-container color-selector-container-filters-content" :class="currentSection.id">
            <div v-if="(colorImg) && (lensPrefix !== 'transitions')" class="lens-img">
                <img :src="colorImg" :alt="color.value" :class="color.value"/>
            </div>
            <div :class="lensPrefix === 'transitions' ? 'transitions-color-picker-list' : 'color-selector'">
                <div class="lens-type">
                    <div v-if="currentFilterId === 'match_current'" class="match-color-btn">
                        Match Color
                    </div>
                    <div class="color-picker">
                        <div class="color-picker-toggle" :class="`color-picker-toggle-${currentSection.id}`">
                            <p v-if="currentSection.hint" v-dompurify-html="currentSection.hint"
                               class="color-selector-hint"></p>
                            <div v-if="currentSection.description" class="transitions-description">
                                <p v-dompurify-html="currentSection.description"></p>
                                <span
                                    v-if="(chosenSection === 'transitions-vantage' || chosenSection === 'transitions-drivewear')&& isBoseLanding"><i>*Not available with Tempo frame</i></span>
                            </div>
                            <div v-if="currentFilter.description" class="current-filter-description"
                                 :class="currentFilter.id">
                                <p v-dompurify-html="currentFilter.description"></p>
                            </div>

                            <ul v-if="!valueMatchColor" class="color-picker-list-filters"
                                :class="lensPrefix === 'transitions' ? 'transitions-color-picker-list' : 'color-picker-list'">
                                <template v-for="colour in colors">
                                    <li
                                        v-if="!colour.hidden"
                                        :key="colour.id"
                                        :title="colour.color_name || colour.label"
                                        :class="[
                                            lensPrefix === 'transitions' ? 'transitions-color-clicker' : 'color-clicker',
                                            {
                                                active: isActiveColor(colour.id),
                                                'disabled-color': colour.hidden,
                                                'has-description': colour.description,
                                                [colour.id]: colour.id,
                                                [chosenSection + '-list-item']: chosenSection
                                            }
                                        ]"
                                        class="color-clicker-filters"
                                        @click="chooseColor(colour.id)"
                                    >
                                        <div class="content">
                                            <img v-if="lensPrefix === 'transitions'" :src="colour.front[0].thumbnail"
                                                 :alt="colour.color_name || colour.label" :class="lensPrefix + '-img'"/>
                                            <span v-dompurify-html="colour.color_name || colour.label"
                                                  class="label"></span>
                                            <HelpBubble v-if="colour.component_help" :data="colour.component_help"/>
                                        </div>
                                        <div v-if="colour.description" v-dompurify-html="colour.description"
                                             class="description"></div>
                                    </li>
                                </template>
                            </ul>

                            <div v-if="hasAdditionSection && !valueMatchColor"
                                 class="color-selector-tint color-selector-tint-filters">
                                <div class="tint-level">
                                    <span>Tint Level</span>
                                </div>
                                <div class="slider-wrapper">
                                    <ul class="range-labels">
                                        <li v-if="issetColorLevel(3)" :class="{'third-level active': color.level === 3}"
                                            data-slider="3"
                                            @click="sliderValue(3)">
                                            <span class="range-label">Level 3*</span>
                                            <p>(80%)</p>
                                            <div class="tint-hint">
                                                <span>*Standard Sunglass tint level</span>
                                            </div>
                                        </li>
                                        <li v-if="issetColorLevel(2)"
                                            :class="{'second-level active': color.level === 2}" data-slider="2"
                                            @click="sliderValue(2)">
                                            <span class="range-label">Level 2</span>
                                            <p>(50%)</p>
                                        </li>
                                        <li v-if="issetColorLevel(1)" :class="{'first-level active': color.level === 1}"
                                            data-slider="1"
                                            @click="sliderValue(1)">
                                            <span class="range-label">Level 1</span>
                                            <p>(20%)</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _first from 'lodash/first'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import Standard from './Standard.vue'
import {mapGetters} from "vuex";
import {LENS_TYPE_ID} from "../../../../app/common/constants/lens_type";
import {MATCH_COLOR_FILTER_ID} from "../../../../app/common/gradients_constants";

export default {
    name: 'FilterColorView',
    extends: Standard,
    data: () => ({
        currentFilterId: false
    }),
    computed: {
        ...mapGetters('values', ['values']),
        filters() {
            return this.currentSection.filter
        },
        currentFilter() {
            if (this.currentFilterId === MATCH_COLOR_FILTER_ID && !this.valueMatchColor){
                this.toggleMatchColor()
            }
            return _find(this.filters, filter => filter.id === this.currentFilterId)
        },
        colors() {
            return _filter(this.currentSection.colors, color => ((this.currentFilter || {}).colors || []).includes(color.id))
        },
        preDefinedColor() {
            const predefinedObject = this.getLimitersPreDefinedByFrames(LENS_TYPE_ID)
            if (predefinedObject.color) {
                return predefinedObject.color
            }
            return {}
        }
    },
    created() {
        this.setCurrentFilterDefaultId()
    },
    methods: {
        issetColorLevel(currentLevel) {
            const filteredColors = _filter(this.currentSection.colors, {id: this.color.value})
            const color = _first(filteredColors)
            currentLevel = currentLevel + ''
            let levels = color.level + ''
            const levelArr = levels.split(',')
            return levelArr.indexOf(currentLevel) !== -1

        },
        setCurrentFilterId(id) {
            this.currentFilterId = id
            this.chooseColor(_first(this.colors).id)
            this.changeLevel(this.valueMatchColor ? false : this.color.level || 3)
        },
        setCurrentFilterDefaultId() {
            const predefinedColor = this.preDefinedColor
            if (predefinedColor.value) {
                const currentFilter = _find(this.filters, filter => {
                    return filter.colors.indexOf(predefinedColor.value) !== -1
                })
                this.setCurrentFilterId(currentFilter.id)
                this.chooseColor(predefinedColor.value)
                const colorLevel = predefinedColor.level || false
                if (colorLevel) {
                    this.changeLevel(colorLevel)
                }
            } else {
                this.setCurrentFilterId(_first(this.filters).id)
            }

        }
    }
}
</script>
