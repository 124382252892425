var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.prescriptionData.status_4
        ? _c("RadioLabel", {
            attrs: { active: _vm.isActive, label: _vm.title, value: _vm.index },
            on: {
              click: function($event) {
                return _vm.$emit("checked", _vm.index)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isActive
        ? _c(
            "div",
            {
              staticClass:
                "my-prescription-form__pupillary-distance enter-online-rx"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "my-prescription-form__dropdown-options-container prescription-toggle"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "my-prescription-form__subheading prescription-toggle"
                    },
                    [_vm._v("Prescription:")]
                  ),
                  _vm._v(" "),
                  _c("PrescriptionTable", {
                    attrs: {
                      "show-edit-version": _vm.showEditVersion,
                      "show-add": _vm.showAdd,
                      value: _vm.prescription,
                      "show-error": _vm.showError,
                      help: _vm.help
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }