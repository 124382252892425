<template>
    <ProvidersPage v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import ProvidersPage from "../../components/insurances/ProvidersPage.vue";

export default {
    name: 'RouterProvidersPage',
    components: {
        ProvidersPage
    },
    extends: App
}
</script>
