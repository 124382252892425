import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererPrescriptionName',
    extends: RendererOptions,
    computed: {
        renderer () {
            return this.values.prescriptionValues.name
        }
    }
}
