<template>
    <div :class="{redeemed: redeemedStyle}" class="plus-account_container glasses-container">
        <h4 v-if="isMobile" :class="{active: isShow}" @click="toggleContent">Rx Frame of Lens Replacement</h4>
        <transition name="slide">
            <div v-if="!isMobile || isShow && isMobile" class="plus-content_container glasses-content">
                <div class="redeem-description">
                    <div class="redeem-container">
                        <div>
                            <img src="/js/lensconfigurator/assets/glasses_desktop.svg" alt="contact lenses" />
                        </div>
                        <div>
                            <span class="description">
                                One (1) frame including prescriptions lenses ({{ defaultPrescriptionType }})
                            </span>
                            <HelpBubble v-show="!redeemedStyle" :data="component_help.frames" />
                        </div>
                        <div class="secondary">
                            <BaseButton
                                v-show="!redeemedStyle"
                                :disabled="!paidUp"
                                :text="btnTitle(redeemItemType)"
                                :class="{disable: !previousvisit}"
                                data-size="xs"
                                class="button button--copy-solid"
                                @click.native="openFrameRedeem"
                            />
                        </div>
                    </div>
                    <div class="redeem-container">
                        <div></div>
                        <span class="separator">-or-</span>
                        <div class="secondary">
                            <BaseButton
                                v-show="redeemedStyle"
                                :disabled="pending || isLensesRedeemed"
                                :text="btnTitle(redeemItemType)"
                                :class="{disabledButton: isLensesRedeemed}"
                                data-size="xs"
                                class="button button--copy-solid ladda-button"
                                @click.native="openLensRedeem"
                            />
                        </div>
                    </div>
                    <div class="redeem-container">
                        <div>
                            <img src="/js/lensconfigurator/assets/lens_desktop.svg" alt="contact lenses" />
                        </div>
                        <div>
                            <span class="description">
                                One (1) prescription lens replacement ({{ defaultPrescriptionType }})
                            </span>
                            <HelpBubble v-show="!redeemedStyle" :data="component_help.lenses" />
                        </div>
                        <div class="secondary">
                            <BaseButton
                                v-show="!redeemedStyle"
                                :disabled="!paidUp"
                                :text="btnTitle('glasses_single_vision')"
                                :class="{disable: !previousvisit, disabledButton: isLensesRedeemed && isMobile}"
                                data-size="xs"
                                class="button button--copy-solid"
                                @click.native="openLensRedeem"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div v-show="!isContactsInPlan" class="desktop-notification-card-top-redeem">
            <svg width="350px" height="25px" viewBox="0 0 409 25" version="1.1" xmlns="http://www.w3.org/2000/svg" class="desktop-svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Lensabl+-Dashboard-0-Welcome-2" transform="translate(-1642.000000, -900.000000)" fill="#9BDBDA" fill-rule="nonzero">
                        <path id="Line-5" d="M1666,900.5 L1666,910.5 L2051,910.5 L2051,914.5 L1666,914.5 L1666,924.5 L1642,912.5 L1666,900.5 Z" />
                    </g>
                </g>
            </svg>
            <svg width="350px" height="50px" viewBox="0 0 408 50" version="1.1" xmlns="http://www.w3.org/2000/svg" class="tablet-svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Lensabl+-Dashboard-0-Welcome-1" transform="translate(-1741.000000, -1114.000000)" fill="#9BDBDA" fill-rule="nonzero">
                        <path
                            id="Path-2"
                            d="M2147.42073,1160.18616 C2148.42248,1160.65151 2148.85732,1161.84084 2148.39197,1162.8426 C2147.92661,1163.84436 2146.73728,1164.2792 2145.73552,1163.81384 C2080.40435,1133.46493 2003.33382,1118.57493 1926.60961,1118.89802 C1860.01194,1119.17847 1798.7846,1131.24718 1762.12437,1150.36389 L1767.60992,1158.43017 L1741.01562,1162 L1754.11633,1138.58267 L1759.86075,1147.03397 C1797.12034,1127.45734 1859.16268,1115.18201 1926.59277,1114.89806 C2003.87546,1114.57262 2081.52311,1129.57411 2147.42073,1160.18616 Z"
                        />
                    </g>
                </g>
            </svg>
            <p class="animate-notification-msg">To use a reward, click the "Redeem" button.</p>
        </div>
        <Redeem
            :requirements="requirements"
            :instruction="instruction"
            :is-modal-open="isFramesOpen"
            subtitle="Frames Benefit"
            @continue="continueToFrames"
            @update="isFramesOpen = false"
        />
        <Redeem
            :requirements="lens_requirements"
            :instruction="instruction"
            :is-modal-open="isLensModalOpen"
            subtitle="Lens Replacement Benefit"
            @continue="continueToLens"
            @update="isLensModalOpen = false"
        />
        <ExamCreditRedeem
            :is-modal-open="isEyeExamOpen"
            :collect="collect"
            :redeem-item-type="redeemItemType"
            :instruction="instruction"
            class="non-plus-modal-wrapper"
            subtitle="Non-Lensabl+ Frame or Lens Replacement Benefit"
            @update="isEyeExamOpen = false"
            @continue="pendingBenefitCredit"
        />
        <GiftCreditRedeem
            :is-modal-open="isGiftCardOpen"
            :collect="collect"
            redeem-item-type="frames"
            @update="isGiftCardOpen = false"
            @continue="pendingBenefitCredit"
        />
    </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import BaseButton from '../../../components/base/BaseButton.vue'
import Redeem from './Redeem.vue'
import ExamCreditRedeem from './ExamCreditRedeem.vue'
import GiftCreditRedeem from './GiftCreditRedeem.vue'

import {
    STATUS_PENDING,
    STATUS_APPROVED,
    STATUS_FINISHED
} from '../storage/lensablplus/customer'

export default {
    name: 'GlassesRedeem',
    components: {
        BaseButton,
        HelpBubble,
        Redeem,
        ExamCreditRedeem,
        GiftCreditRedeem
    },
    data: () => ({
        redeemItemType: 'frames',
        component_help: {
            frames: {
                name: 'help-bubble',
                params: {
                    text: ''
                }
            },
            lenses: {
                name: 'help-bubble',
                params: {
                    text: ''
                }
            }
        },
        isShow: false,
        isFramesOpen: false,
        isLensModalOpen: false,
        lens_requirements: [
            'Select the options you want in our ordering tool',
            'Confirm your details and checkout'
        ],
        requirements: [
            'Choose the style of frames you wish to get',
            'Select the options you want in our ordering tool',
            'Confirm your details and checkout'
        ],
        instruction: 'You will need to follow the below steps to complete the redemption:',
        isEyeExamOpen: false,
        pending: false,
        collect: false,
        isGiftCardOpen: false,
        redirectTo: false
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablplus_customer', ['getGiftcardConfigs', 'isRedeemFlow', 'defaultPrescriptionType', 'isLensesRedeemed', 'paidUp', 'isContactsInPlan', 'statusRedeemed', 'getBtnTitlesById', 'getRedeemedStyles', 'getReceiptRewards']),
        ...mapGetters('storeView', ['base_url']),
        ...mapState('gsap', ['previousvisit']),
        receiptRewards () {
            return this.getReceiptRewards(this.redeemItemType)
        },
        redeemedStyle () {
            return this.isLensesRedeemed || this.getRedeemedStyles(this.statusRedeemed(this.redeemItemType)) || this.pending || this.collect
        },
        btnTitle () {
            return type => {
                if (this.isLensesRedeemed) return 'REDEEMED'
                if (this.pending) return 'PENDING REVIEW'
                if (!this.paidUp) return 'SUSPEND'
                if (this.collect) return 'COLLECT REWARD'
                const statusId = this.statusRedeemed(type)
                return (this.getBtnTitlesById(statusId) || '').toUpperCase()
            }
        }
    },
    created () {
        if (this.receiptRewards) {
            this.pending = this.receiptRewards.status === STATUS_PENDING
            this.collect = this.receiptRewards.status === STATUS_APPROVED
            this.redirectTo = this.receiptRewards.status === STATUS_FINISHED
        }

        this.component_help.frames.params.text = '<p>Any frame of your choice from our Everyday Eyewear collection. Includes ' + this.defaultPrescriptionType.charAt(0).toUpperCase() + this.defaultPrescriptionType.slice(1) + ' polycarbonate lenses with Blue-Light Blocking, Anti-Reflective, Anti-Scratch, and 100% UV Protection coatings. All lenses and coatings are made by Essilor.</p><p>* High-index (thin), Transitions, Sunglass lens and other options available for extra charge.</p>'
        this.component_help.lenses.params.text = '<p>Use your benefit to replace lenses in an existing frame of any brand you already own. Includes ' + this.defaultPrescriptionType.charAt(0).toUpperCase() + this.defaultPrescriptionType.slice(1) + ' polycarbonate lenses with Blue-Light Blocking, Anti-Reflective, Anti-Scratch, and 100% UV Protection coatings. All lenses and coatings are made by Essilor.</p><p> * High-index (thin), Transitions, Sunglass lens and other options available for extra charge.</p>'
    },
    methods: {
        ...mapActions('lensablplus_customer', ['setCookies', 'createRewardTremendous']),
        toggleContent () {
            if (this.isMobile) {
                this.isShow = !this.isShow
            }
        },
        continueToFrames () {
            this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', true)
            this.setCookies()
            window.location.href = this.base_url + 'plus/shop-frames/'
        },
        continueToLens () {
            this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', true)
            this.setCookies()
            window.location.href = this.base_url + 'choose-my-lenses'
        },
        openFrameRedeem () {
            if (!this.isLensesRedeemed && this.previousvisit) {
                this.isFramesOpen = !this.isFramesOpen
            }
        },
        openLensRedeem () {
            if (this.redirectTo) {
                this.createRewardTremendous({
                    value: this.getGiftcardConfigs(this.redeemItemType).tremendous_value,
                    type: this.redeemItemType
                })
            } else if (this.collect) {
                this.isGiftCardOpen = !this.isGiftCardOpen
            } else if (!this.isLensesRedeemed && this.previousvisit && !this.collect) {
                this.isLensModalOpen = !this.isLensModalOpen
            }
        },
        pendingBenefitCredit () {
            this.pending = !this.pending
        }
    }
}
</script>

<style scoped>

</style>
