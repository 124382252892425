var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getHtml
    ? _c("div", { class: _vm.getContainerClasses }, [
        _c(
          "div",
          { staticClass: "help-component" },
          [
            !_vm.isMobileView
              ? _c(
                  "i",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: _vm.getHtml,
                          placement: _vm.getPlacement,
                          classes: _vm.getClasses,
                          targetClasses: _vm.getTargetClasses,
                          offset: _vm.offset,
                          delay: _vm.getDelay,
                          popperOptions: {
                            preventOverflow: false
                          }
                        },
                        expression:
                          "{\n                content: getHtml,\n                placement: getPlacement,\n                classes: getClasses,\n                targetClasses: getTargetClasses,\n                offset: offset,\n                delay: getDelay,\n                popperOptions: {\n                    preventOverflow: false\n                }\n            }"
                      }
                    ]
                  },
                  [_vm._v(_vm._s(_vm.getTargetContent))]
                )
              : [
                  _c(
                    "vue-modaltor",
                    {
                      attrs: {
                        visible: _vm.isModalOpen,
                        "default-width": "100%",
                        "bg-overlay": "",
                        "bg-panel": "",
                        "close-scroll": false
                      },
                      on: { hide: _vm.hideModal }
                    },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: _vm.getHtml,
                            expression: "getHtml"
                          }
                        ]
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      class: _vm.getTargetClasses,
                      on: {
                        click: function($event) {
                          _vm.isModalOpen = true
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.getTargetContent))]
                  )
                ]
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }