<template>
    <div v-if="componentsCount" class="lensabl-plus-content-wrapper company-bg" :class="{'load': getLoad}">
        <PageLoader v-if="getLoad" :load="getLoad" />
        <div v-else>
            <div class="lensabl-plus-content">
                <template v-for="object in componentsList">
                    <component :is="object.component.name" :id="object.id" :key="object.id" :class="['lensabl-plus-content ' + object.component.name]" />
                </template>
                <Back v-if="show" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import lensablplusBusiness from '../storage/lensablplus/business'
import CompanyDetails from '../businessOrderTool/CompanyDetails.vue'
import BusinessPlans from '../businessOrderTool/BusinessPlans.vue'
import PrimaryContact from '../businessOrderTool/PrimaryContact.vue'
import BusinessFinish from '../businessOrderTool/Finish.vue'
import PaymentInformation from '../businessOrderTool/PaymentInformation.vue'
import Back from './Back.vue'
import PageLoader from '../../base/PageLoader.vue'

export default {
    name: 'BusinessPlanChoice',
    components: {
        CompanyDetails,
        BusinessPlans,
        PrimaryContact,
        PaymentInformation,
        BusinessFinish,
        Back,
        PageLoader
    },
    data: () => ({
    }),
    computed: {
        ...mapGetters('lensablplus_business', ['componentsList', 'componentsCount', 'values', 'firstComponent', 'currentComponent', 'checkoutData', 'getLoad', 'businessStepsList']),
        ...mapState('lensablplus_business', ['history']),
        show () {
            return parseInt(this.currentComponent.stepId) !== 1 && parseInt(this.currentComponent.stepId) !== 5
        }
    },
    watch: {
        values: {
            handler () {
                this.updateComponents()
            },
            deep: true
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablplus_business/']) {
            this.$store.registerModule('lensablplus_business', lensablplusBusiness(this.$store))
        }
        this.$store.dispatch('lensablplus_business/fetchDefaultData')
        this.$store.commit('lensablplus_business/LOAD', true)
        this.$store.dispatch('lensablplus_business/updateComponents')
        this.$store.dispatch('lensablplus_business/fetchBusinessPlans', 1)
    },
    methods: {
        ...mapActions('lensablplus_business', ['updateComponents'])
    }
}
</script>

<style scoped>
    .lensabl-plus-content-wrapper.company-bg .loader-circle {
        min-height: 66vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
