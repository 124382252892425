<template>
    <div class="product">
        <div v-if="outOfStock" class="sold-out-block">
            <div class="sold-out">
                <p>Sold Out</p>
            </div>
        </div>
        <a v-if="currentImage" :title="product.name" :href="productUrl" class="product photo">
            <img :alt="product.name" :src="currentImage.path"/>
        </a>
        <div class="product details">
            <span v-if="showCategoryName" class="category-title">
                {{ product.category }}
            </span>
            <div class="item-details">
                <strong class="product name product-item-name">
                    <a v-dompurify-html="product.name" :title="product.name" :href="productUrl"
                       class="product-item-link"></a>
                </strong>
                <div class="price-container">
                    <span v-if="showProductPrice" class="product-price">Price {{ currentImage.price || product.price }}</span>
                </div>
                <ColorSwatches
                    v-if="showSwatcher"
                    :colors="images"
                    :checked-index="checkedIndex"
                    :option-item-class="'decorate img'"
                    :data-swatch-product="product.name"
                    @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ColorSwatches from '../product/view/ColorSwatches.vue'
import {
    EVENT_CHANGE_CURRENT_COLOR
} from '../../../app/utils/constants'

export default {
    name: 'ProductItem',
    components: {
        ColorSwatches
    },
    props: {
        product: {
            type: [Array, Object],
            default: () => {
            }
        },
        showSwatcher: {
            default: false,
            type: Boolean
        },
        showProductPrice: {
            default: false,
            type: Boolean
        },
        showCategoryName: {
            default: false,
            type: Boolean
        }
    },
    data: () => ({
        checkedIndex: 0,
        EVENT_CHANGE_CURRENT_COLOR: EVENT_CHANGE_CURRENT_COLOR
    }),
    computed: {
        ...mapGetters('storeView', ['base_url']),
        outOfStock() {
            return !this.product.qty
        },
        images() {
            return this.product.images || []
        },
        currentImage() {
            return this.images[this.checkedIndex] || {path: this.product.image}
        },
        productUrl() {
            return this.product.url.indexOf('http') !== -1 ? this.product.url : this.base_url + this.product.url.replace(/^\/+/, '')
        },
        productCategory() {
            return this.product.category
        },
    },
    methods: {
        changeCurrentColor(index) {
            this.checkedIndex = index
        }
    }
}
</script>
