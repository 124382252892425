<template>
    <div
        :ref="data.ref"
        :class="[data.class]"
        :style="[data.style, data.staticStyle]"
        v-bind="data.attrs"
    >
        <transition name="sf-fade" mode="out-in">
            <!--@slot Slot for the actual content being loaded -->
            <slot></slot>
        </transition>
    </div>
</template>
<script>
import scrollToFixed from '../../app/utils/jquery.scrollToFixed'

export default {
    name: 'ScrollToFixed',
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    mounted () {
        if (this.data.ref) {
            scrollToFixed(this.$refs[this.data.ref], this.data.options || {})
        }
    }
}
</script>
