var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      { attrs: { href: "javascript:void(0)" }, on: { click: _vm.createPdf } },
      [_vm._v("Download Shipping Label")]
    ),
    _vm._v(" "),
    _vm.load
      ? _c("div", { staticClass: "loader-container" }, [
          _c("div", { staticClass: "loader-el" })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }