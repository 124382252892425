var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "affirm-wrapper" }, [
    _c("div", { staticClass: "affirm-body_container" }, [
      _c("div", { staticClass: "affirm-body_row" }, [
        _c("div", { staticClass: "affirm-body_row-loader" }, [_c("Loader")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "affirm-body_row-text" }, [
          _vm._v(
            "\n                We're processing your information\n            "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "affirm-body_row-subtext" }, [
          _vm._v(
            "\n                This may take up to 15 seconds.\n            "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }