<template>
    <div class="entry-edit">
        <div class="entry-edit-head">
            <h4 :class="headerCssClass" class="fieldset-legend">{{ headerText }}</h4>
        </div>
        <div class="fieldset">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LensabalplusMembershipAbstract',
    props: {
        headerCssClass: {
            type: String,
            default: 'icon-head'
        },
        headerText: {
            type: String,
            default: '',
            required: false
        }
    }
}
</script>
