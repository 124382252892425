var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "1rem 3rem 1rem 3rem", "text-align": "left" } },
    [
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "control form-access__header" }, [
          _c("h3", [_vm._v("What is your email address?")]),
          _vm._v(" "),
          !_vm.SIGN_IN
            ? _c("span", { staticClass: "stepper-login" }, [
                _vm._v(" Already have an account? "),
                _c(
                  "a",
                  {
                    attrs: { id: "show-modal" },
                    on: {
                      click: function($event) {
                        _vm.showModal = true
                      }
                    }
                  },
                  [_vm._v("Login")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            staticClass: "stepper-email",
            class: ["input", _vm.$v.email.$error ? "is-danger" : ""],
            attrs: { type: "text", placeholder: "Email" },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _vm.showModal
        ? _c("div", [
            _c("div", { staticClass: "modal-mask" }, [
              _c("div", { staticClass: "modal-wrapper" }, [
                _c("div", { staticClass: "modal-container" }, [
                  _c("div", { staticClass: "modal-header" }, [
                    _c("a", {
                      staticClass: "modal-close-button",
                      on: {
                        click: function($event) {
                          _vm.showModal = false
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _vm._t("body", [
                        _c("ul", { staticClass: "form-list" }, [
                          _c("li", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.login.email,
                                  expression: "login.email"
                                }
                              ],
                              attrs: {
                                id: "email",
                                type: "text",
                                placeholder: "Email Address",
                                title: "Email Address"
                              },
                              domProps: { value: _vm.login.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.login,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.login.password,
                                  expression: "login.password"
                                }
                              ],
                              attrs: {
                                id: "password",
                                type: "password",
                                placeholder: "Password",
                                title: "Password"
                              },
                              domProps: { value: _vm.login.password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.login,
                                    "password",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _vm._m(0)
                        ]),
                        _vm._v(" "),
                        _c(
                          "BaseButton",
                          {
                            attrs: { text: "Sign In" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.sendPost($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                                Login\n                            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errorSignIn
                          ? _c("p", { staticClass: "help is-danger" }, [
                              _vm._v("Email or password is invalid!")
                            ])
                          : _vm._e()
                      ])
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-footer" },
                    [_vm._t("footer")],
                    2
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "f-right form-access__link",
          attrs: { href: "/customer/account/forgotpassword/" }
        },
        [
          _vm._v(
            "Forgot\n                                        Your Password?"
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }