<template>
    <div>
        <CatalogCategoryViewBanner :class="[categoryClass + '-banner', currentCategory.url_key]" :category="categories[0]" :category-class="categoryClass" class="spectacles-banner frames-banner lensabl-frames-banner" />

        <LensesInclude />

        <TermsInformation />

        <CatalogNavigationCategories :list-title="`Filters`" :categories="categories" :current-id="currentCategoryId" class="category-frames-tabs" @[EVENT_CATEGORY_SELECTED]="updateFilteredProducts" />

        <template v-if="productsCount">
            <template v-for="(categoryProducts, categoryName) in getCatProducts">
                <CatalogCategoryLensablViewSubCategoryBanner v-if="parentCategory && false" :key="`banner-${categoryName}`" :category="getCategoryByName(categoryName)" class="subcategory-banner" />
                <CatalogProductList :key="`products-${categoryName}`" :load="categoryLoader" :products="categoryProducts" class="grid products-grid shop-frames-cat" />
            </template>
        </template>

        <PageLoader v-if="categoryLoader" :load="categoryLoader" />

        <section v-if="parentCategory" class="landing-page_ad-block">
            <div class="ad-block_container">
                <a href="/shop-contacts">
                    <img src="https://www.lensabl.com/media/wysiwyg/email/images/EMAIL_Contacts-Ad-update.jpg" alt="Shop Now button with text and woman holding contacts" />
                </a>
                <a href="/choose-my-lenses">
                    <img src="https://www.lensabl.com/media/wysiwyg/email/images/EMAIL_Lens-Replacement-Ad-update.jpg" alt="Shop Now button with woman holding a pair of sunglasses" />
                </a>
            </div>
        </section>

        <ReturnsInfo>
            <div v-if="!parentCategory && products" class="returns-frame-container">
                <h2>Everyday Eyewear includes premium lenses made by Essilor</h2>
                <div class="returns-included">
                    <div class="returns-icon blue-light">
                        <div>
                            <h3>Premium Blue-Light Protection</h3>
                            <span>Protects your eyes from screen-emitted rays that cause eye strain and headaches.</span>
                        </div>
                    </div>
                    <div class="returns-icon ar">
                        <h3>Anti-Reflective Coating</h3>
                        <span>Blocks reflections and glare that can strain your eyes.</span>
                    </div>
                    <div class="returns-icon scratch">
                        <h3>Anti-Scratch Coating</h3>
                        <span>Strengthens your lenses to protect from scratches or other damage.</span>
                    </div>
                    <div class="returns-icon uv">
                        <h3>100% UV Protection</h3>
                        <span>Acts as a sunscreen for your eyes blocking UV rays from passing through lenses. </span>
                    </div>
                </div>
            </div>
        </ReturnsInfo>
    </div>
</template>

<script>
import CatalogCategoryView from '../CatalogCategoryView.vue'
import CatalogCategoryLensablViewSubCategoryBanner from './view/SubCategoryBanner.vue'
import PageLoader from '../../../loaders/PageLoader.vue'
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'CatalogCategoryLensablView',
    components: {
        CatalogCategoryLensablViewSubCategoryBanner,
        PageLoader
    },
    extends: CatalogCategoryView,
    data: () => {
        return {
            filter: {
                category: [],
                lens_type: [],
                frame_size: [],
                material: []
            }
        }
    },
    computed: {
        ...mapGetters('category', ['getCatProducts']),
        parentCategory () {
            return this.categoryClass === 'lensablframes' || this.categoryClass === 'all'
        },
        showModal () {
            const query = window.location.search.substring(1)
            if (query) {
                return true
            }
            return false
        }
    },
    methods: {
        ...mapActions('category', ['fetchFilteredProducts']),
        updateFilteredProducts (id, key) {
            this.filter[id] = key
            this.fetchFilteredProducts(this.filter)
        }
    }
}
</script>
