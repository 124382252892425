var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billing-opc" },
    [
      _vm.LOADING_PAYMENT
        ? _c("Loader")
        : _c(
            "div",
            { staticClass: "opc-block-section opc-block-section--payment" },
            [
              _c("div", { staticClass: "payment-block accordian-header" }, [
                _c("h3", [
                  _vm._v("\n                Payment Options\n                "),
                  _c("span", [_vm._v("FSA/HSA Cards Accepted")])
                ])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "payment-methods control" }, [
                  _c(
                    "div",
                    { attrs: { id: "checkout-payment-method-load" } },
                    [
                      _vm._l(_vm.getAvailablePaymentMethods, function(
                        payment,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !payment.hidden_in_frontend,
                                expression: "!payment.hidden_in_frontend"
                              }
                            ],
                            key: index,
                            attrs: { id: payment.code + "-wrapper" }
                          },
                          [
                            _c("div", { staticClass: "payment-wrapper" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "radio-label",
                                  attrs: { for: "p_method_" + payment.code }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.SELECTED_PAYMENT_METHOD,
                                        expression: "SELECTED_PAYMENT_METHOD"
                                      }
                                    ],
                                    staticClass: "radio payment-radio",
                                    attrs: {
                                      id: "p_method_" + payment.code,
                                      type: "radio",
                                      name: "payment[method]",
                                      title: payment.title
                                    },
                                    domProps: {
                                      value: payment.code,
                                      checked: _vm._q(
                                        _vm.SELECTED_PAYMENT_METHOD,
                                        payment.code
                                      )
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.SELECTED_PAYMENT_METHOD =
                                          payment.code
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "dompurify-html",
                                        rawName: "v-dompurify-html",
                                        value: payment.title,
                                        expression: "payment.title"
                                      }
                                    ],
                                    class: {
                                      "payment-title": payment.code === "affirm"
                                    }
                                  }),
                                  _vm._v(" "),
                                  payment.hint
                                    ? _c("HelpBubble", {
                                        attrs: {
                                          data: _vm.getHelper(payment.hint)
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            payment.code === _vm.SELECTED_PAYMENT_METHOD
                              ? _c("div", {
                                  directives: [
                                    {
                                      name: "dompurify-html",
                                      rawName: "v-dompurify-html",
                                      value: payment.html,
                                      expression: "payment.html"
                                    }
                                  ],
                                  staticClass: "payment-method",
                                  attrs: {
                                    id:
                                      "container_payment_method_" + payment.code
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.ITEMS, function(item, i) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.IS_QUARTERLY_PAY &&
                                        _vm.currenMethod.code === "recurly",
                                      expression:
                                        "IS_QUARTERLY_PAY && currenMethod.code === 'recurly'"
                                    }
                                  ],
                                  key: i,
                                  staticClass: "recurring-charge_subtitle"
                                },
                                [
                                  _c("span", [
                                    _vm._v("Your card will be charged "),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "dompurify-html",
                                          rawName: "v-dompurify-html",
                                          value: item.quarterly_price_html,
                                          expression:
                                            "item.quarterly_price_html"
                                        }
                                      ]
                                    }),
                                    _vm._v(" every 3 months")
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _vm.ADDRESS_FORM_ERRORS.payment_method
                        ? _c("div", { staticClass: "validation-advice" }, [
                            _vm._v(
                              "Payment method\n                        is a required.\n                    "
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.showStripeButton
                ? _c("div", [
                    _c(
                      "ul",
                      {
                        staticClass: "form-list",
                        attrs: { id: "payment_form_stripeButton" }
                      },
                      [
                        _c("li", { staticClass: "form-alt" }, [
                          _c("div", [
                            _c("div", {
                              attrs: { id: "payment-request-button" }
                            })
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "payment[stripeButton_token]",
                        id: "stripeButton-token"
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "payment[stripeButton_pi_id]",
                        id: "stripeButton-pi-id"
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "payment[stripeButton_brand]",
                        id: "stripeButton-brand"
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "payment[stripeButton_last4]",
                        id: "stripeButton-last4"
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: "payment[stripeButton_desc]",
                        id: "stripeButton-desc"
                      }
                    })
                  ])
                : _vm._e()
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }