import {eventBus} from '../RootState'
import {
    MESSAGE_VALIDATE_DOCTOR,
    MESSAGE_VALIDATE_UPLOAD,
    MESSAGE_VALIDATE_RX_OWNER,
    MESSAGE_VALIDATE_DOCTOR_DETAILS,
    MESSAGE_VALIDATE_MANUAL_RX
} from './constants'

let promiseService = null

class PromiseService {
    constructor (store) {
        this.store = store
    }

    validateDoctor () {
        this._startValidation()

        let resolvePromise, rejectPromise
        const validationPromise = new Promise((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
        })

        eventBus.$emit(
            MESSAGE_VALIDATE_DOCTOR,
            validationPromise,
            resolvePromise,
            rejectPromise
        )

        return validationPromise
    }

    validateOwner () {
        this._startValidation()

        let resolvePromise, rejectPromise
        const validationPromise = new Promise((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
        })

        eventBus.$emit(
            MESSAGE_VALIDATE_RX_OWNER,
            validationPromise,
            resolvePromise,
            rejectPromise
        )

        return validationPromise
    }

    validateManualRx () {
        this._startValidation()

        let resolvePromise, rejectPromise
        const validationPromise = new Promise((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
        })

        eventBus.$emit(
            MESSAGE_VALIDATE_MANUAL_RX,
            validationPromise,
            resolvePromise,
            rejectPromise
        )

        return validationPromise
    }

    validateDoctorDetails () {
        this._startValidation()

        let resolvePromise, rejectPromise
        const validationPromise = new Promise((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
        })

        eventBus.$emit(
            MESSAGE_VALIDATE_DOCTOR_DETAILS,
            validationPromise,
            resolvePromise,
            rejectPromise
        )

        return validationPromise
    }

    validateUpload () {
        this._startValidation()

        let resolvePromise, rejectPromise
        const validationPromise = new Promise((resolve, reject) => {
            resolvePromise = resolve
            rejectPromise = reject
        })

        eventBus.$emit(
            MESSAGE_VALIDATE_UPLOAD,
            validationPromise,
            resolvePromise,
            rejectPromise
        )

        return validationPromise
    }

    _startValidation () {
        return this.store.commit('values/START_VALIDATION')
    }

    validatePrescriptionParams () {
        return this.store.dispatch('values/savePrescription')
    }
}

export default function getPromiseService (options = {}) {
    if (!promiseService) {
        promiseService = new PromiseService(options)
    }
    return promiseService
}
