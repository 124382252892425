<template>
    <div v-if="!memberShipEdited">
        <div>
            <table cellspacing="0" class="form-list">
                <tbody>
                    <tr>
                        <td class="label">
                            <span class="label">Frame benefit redeemed</span>
                            <strong class="value" v-text="frameItemRedeemed"></strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">
                            <span class="label">Opting out of frame benefit</span>
                            <strong class="value" v-text="voucherItemRedeemed"></strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="label">
                            <span class="label">Opting out of frame benefit by uploading receipt</span>
                            <strong class="value" v-text="uploadedReceiptItemRedeemed"></strong>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr />

            <table cellspacing="0" class="form-list eye-exam" v-if="hasFile">
                <tbody>
                    <tr>
                        <td class="label">Reciept Uploaded</td>
                        <td class="value" width="20%">
                            <div class="upload-prescription">
                                <div class="upload-component">
                                    <div v-if="isUploading">
                                        <div class="uploading-progress">Uploading...</div>
                                    </div>

                                    <div v-if="hasFile" class="uploaded-file">
                                        <a v-if="file_download_url" v-dompurify-html="fileData.fileName" :href="file_download_url" download></a>
                                        <span v-else v-dompurify-html="fileData.fileName"></span>
                                    </div>

                                    <div v-if="showError" class="error-hint">
                                        <span v-dompurify-html="errorHint"></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="label">
                            <div :class="{'invalid-form': showInvalidFormBorder}" class="file-input-box">
                                <a href="javascript:void(0)" type="button" title="Continue" class="action file-input-button">
                                    <button :disabled="!hasNoFile" :class="{'disabled': !hasNoFile}">Upload Reciept</button>
                                    <input v-if="hasNoFile" type="file" name="prescription" accept="image/*,application/pdf" @change="previewFiles" />
                                </a>
                                <button :disabled="approved" :class="{'disabled': approved}" class="approve" @click="approvedReceiptRewards">Approve (Sends Gift Card)</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr v-show="!approved && hasFile" />

            <table v-show="!approved && hasFile" cellspacing="0" class="form-list eye-exam">
                <tbody>
                    <tr>
                        <td class="value">
                            <textarea v-model="textMessage" rows="5" placeholder="Message..."></textarea>
                        </td>
                        <td class="scope-label">
                            <label>
                                <input v-model="rejection" type="checkbox" />
                                <span>Notify customer of rejection</span>
                            </label>
                        </td>
                        <td class="scope-label">
                            <button class="submit" @click="submitMessage">Submit</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr v-show="hasFile" />

            <table v-if="messages.length" cellspacing="0" class="form-list eye-exam">
                <thead>
                    <th>History</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="(message, index) in messages" :key="`message-${index}`" class="tr-line">
                        <td v-dompurify-html="message.message" class="value"></td>
                        <td class="scope-label">
                            <strong v-text="message.status"></strong>
                        </td>
                        <td class="scope-label">
                            <strong v-text="`by ${message.admin_firstname} ${message.admin_lastname}`"></strong>
                            <small v-text="`(${message.admin_email})`"></small>
                        </td>
                        <td class="scope-label" v-text="message.created_at"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <BaseLoader v-if="loader || !dataLoaded" />
    </div>
</template>

<script>
import MembershipEyeExam from './EyeExam.vue'
import {
    STATUS_APPROVED
} from '../../storage/lensablplus/customer'
import _cloneDeep from 'lodash/cloneDeep'
import {
    REJECTED_TEXT_MESSAGE_BUSINESS
} from '../../storage/constants/objectList'
const BOOLEAN_LABELS = {
    0: 'No',
    1: 'Yes'
}

export default {
    name: 'BusinessLensabalplusMembershipContactLens',
    extends: MembershipEyeExam,
    data: () => ({
        labels: BOOLEAN_LABELS
    }),
    computed: {
        frameItemRedeemed () {
            return this.labels[parseInt(this.getRedeemItemData(this.getRedeemTypeWithPrefix('frames')).has_redeemed) === 1 ? 1 : 0]
        },
        voucherItemRedeemed () {
            return this.labels[parseInt(this.getRedeemItemData(this.getRedeemTypeWithPrefix('voucher')).has_redeemed) !== 0 ? 1 : 0]
        },
        uploadedReceiptItemRedeemed () {
            const isReceiptUploaded = parseInt(this.getRedeemItemData(this.getRedeemTypeWithPrefix('frames')).has_redeemed) === 2
                || parseInt(this.getRedeemItemData(this.getRedeemTypeWithPrefix('voucher')).has_redeemed) !== 0
            return this.labels[isReceiptUploaded ? 1 : 0]
        },
        approved () {
            return this.receiptData['status'] === STATUS_APPROVED
        }
    },
    watch: {
        rejection: {
            handler (rejected) {
                if (rejected && !this.textMessage) {
                    this.textMessage = _cloneDeep(REJECTED_TEXT_MESSAGE_BUSINESS)
                } else if (this.textMessage === this.rejected_text_message) {
                    this.textMessage = ''
                }
            },
            deep: true
        }
    },
    methods: {
        getRedeemTypeWithPrefix (type) {
            return this.getPrefixRedeem + type
        },
        approvedReceiptRewards () {
            if (!this.approved && this.hasFile) {
                const status = STATUS_APPROVED
                return this.updateReceiptRewards(status)
            }
        }
    }
}
</script>
