var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.framesPartnerPage
      ? _c("div", [
          _c(
            "div",
            { staticClass: "totals-price-subtotal total-line subtotal" },
            [
              _c("span", { staticClass: "total-label label" }, [
                _vm._v("Lenses")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value value" }, [
                _c("span", { staticClass: "total-value-currency" }, [
                  _vm._v(_vm._s(_vm.currencySymbol))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "total-value-amount" }, [
                  _vm._v(_vm._s(_vm.subTotalAmountLenses))
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "totals-price-subtotal total-line subtotal" },
            [
              _c("span", { staticClass: "total-label label" }, [
                _vm._v("Frames")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value value" }, [
                _c("span", { staticClass: "total-value-currency" }, [
                  _vm._v(_vm._s(_vm.currencySymbol))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "total-value-amount" }, [
                  _vm._v(_vm._s(_vm.subTotalAmountFrames))
                ])
              ])
            ]
          )
        ])
      : _c("div", [
          _c(
            "div",
            {
              staticClass: "totals-price-subtotal total-line",
              staticStyle: { "padding-top": "1em" }
            },
            [
              _c("span", { staticClass: "total-label lplus-label" }, [
                _vm._v("Lenses")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value value" }, [
                _c("span", { staticClass: "total-value-currency" }, [
                  _vm._v(_vm._s(_vm.currencySymbol))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "total-value-amount" }, [
                  _vm._v(_vm._s(_vm.totalAmount))
                ])
              ])
            ]
          )
        ]),
    _vm._v(" "),
    _vm.isRedeemFlow
      ? _c(
          "div",
          { staticClass: "totals-price-subtotal total-line plus-benefit" },
          [
            _c("span", { staticClass: "total-label lplus-label" }, [
              _vm._v("Lensabl+ Benefit")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "total-value value" }, [
              _c("span", { staticClass: "total-value-currency" }, [
                _vm._v(_vm._s(_vm.getSignRedeemDiscount + _vm.currencySymbol))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value-amount" }, [
                _vm._v(_vm._s(_vm.redeemDiscount))
              ])
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.getPercentage("lens") && !_vm.isMemberBenefitGreater
      ? _c(
          "div",
          {
            staticClass:
              "totals-price-subtotal total-line plus-benefit-discount"
          },
          [
            _c("span", { staticClass: "total-label lplus-label" }, [
              _vm._v(
                "Lensabl+ Discount (" + _vm._s(_vm.getPercentage("lens")) + "%)"
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "total-value value" }, [
              _c("span", { staticClass: "total-value-currency" }, [
                _vm._v("-" + _vm._s(_vm.currencySymbol))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value-amount" }, [
                _vm._v(_vm._s(_vm.totalRedeemSummary))
              ])
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }