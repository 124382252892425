<template>
    <div class="opc-wrapper">
        <div class="opc-wrapper-summary">
            <div class="opc-form-header">
                <h1>Checkout</h1>
            </div>
            <div class="opc-container">
                <form id="onestepcheckout-form" method="post" :action="URL_FORM">
                    <div class="plus-opc-block">
                        <div class="plus-opc-block-left">
                            <div class="lensabl-type">
                                <LPlusTypes />
                            </div>
                        </div>
                        <div class="plus-opc-block-right">
                            <Billing />
                            <Payment />
                        </div>
                    </div>
                </form>
                <Footer />
            </div>
        </div>
        <div class="opc-wrapper-totals">
            <Summary />
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import lensablPlusStorage from './storage/lensablplus'
import valueExamService from '../govisibly/storage/valueExam'
import LPlusTypes from './fields/LPlusTypes.vue'
import Billing from './fields/Billing.vue'
import Payment from './fields/Payment.vue'
import Summary from './fields/Summary.vue'
import Footer from './fields/Footer.vue'

export default {
    name: 'CheckoutLPlus',
    components: {
        LPlusTypes,
        Billing,
        Payment,
        Summary,
        Footer
    },
    computed: {
        ...mapGetters('checkoutPlus', ['URL_FORM'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['checkoutPlus/']) {
            this.$store.registerModule('checkoutPlus', lensablPlusStorage(this.$store))
            this.$store.registerModule('onlineExam', valueExamService(this.$store))
        }
    }
}
</script>
