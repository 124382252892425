import Vue from 'vue'
import _size from 'lodash/size'
import _cloneDeep from 'lodash/cloneDeep'
import * as abstract from '../../../../storage/abstract'

export const state = () => ({
    data: {},
    id: '',
    order_url: '',
    status_4: '',
    canAddToOrder: false
})

export const getters = {
    prescriptionData: (state, getters, rootState, rootGetters) => {
        if (!_size(state.data)) {
            state.data = _cloneDeep(rootGetters.rootState.data)

            if (!state.data.lens) {
                Vue.set(state.data, 'lens', 'reading')
            }
        }

        if (state.data.lens === 'reading') {
            if (state.data.prescription_type) {
                Vue.set(state.data, 'prescription_type', null)
            }
        } else {
            if (!state.data.prescription_type) {
                Vue.set(state.data, 'prescription_type', 'single_vision_distance')
            }

            if ((state.data.value.od_pd || state.data.value.od_pd !== '') && (state.data.value.os_pd || state.data.value.os_pd !== '')) {
                Vue.set(state.data.value, 'separate_pd', true)
            }
            if (state.data.value.separate_pd) {
                Vue.set(state.data.value, 'pd', '')
            } else {
                Vue.set(state.data.value, 'od_pd', '')
                Vue.set(state.data.value, 'os_pd', '')
            }
        }
        return state.data
    },
    prescriptionId: (state, getters, rootState, rootGetters) => {
        if (!state.id) {
            state.id = rootGetters.rootState.id
        }
        return state.id
    },
    order_url: (state, getters, rootState, rootGetters) => {
        if (!state.order_url) {
            state.order_url = rootGetters.rootState.order_url
        }
        return state.order_url
    },
    status_4: (state, getters, rootState, rootGetters) => {
        if (!state.status_4) {
            state.status_4 = rootGetters.rootState.status_4
        }
        return state.status_4
    },
    canAddToOrder: (state, getters, rootState, rootGetters) => {
        if (!state.canAddToOrder) {
            state.canAddToOrder = rootGetters.rootState.canAddToOrder
        }
        return state.canAddToOrder
    }
}

export const mutations = {
    ...abstract.mutations
}

export const actions = {
    fetchDefaultData: ({commit, rootGetters}) => {
        commit('UPDATE_STATE_DATA', rootGetters.rootState || {})
    }
}

export default () => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions
})
