var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      staticClass: "control-container input-container sub-doctor-details",
      model: {
        value: _vm.isValid,
        callback: function($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c("v-combobox", {
        attrs: {
          items: [],
          value: _vm.doctorDetails.firstName,
          "return-object": false,
          label: "Doctor's First Name",
          "item-text": "doctor_first_name",
          outlined: ""
        },
        nativeOn: {
          input: function($event) {
            return _vm.updateDoctorData($event.target.value, "firstName")
          }
        }
      }),
      _vm._v(" "),
      _c("v-combobox", {
        attrs: {
          rules: [
            function() {
              return !!_vm.doctorDetails.lastName || "Last Name Required"
            }
          ],
          items: [],
          value: _vm.doctorDetails.lastName,
          "return-object": false,
          label: "Doctor's Last Name*",
          "item-text": "doctor_last_name",
          outlined: ""
        },
        nativeOn: {
          input: function($event) {
            return _vm.updateDoctorData($event.target.value, "lastName")
          }
        }
      }),
      _vm._v(" "),
      _c("v-combobox", {
        attrs: {
          items: [],
          value: _vm.doctorDetails.city,
          "return-object": false,
          label: "City",
          outlined: ""
        },
        nativeOn: {
          input: function($event) {
            return _vm.updateDoctorData($event.target.value, "city")
          }
        }
      }),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          label: _vm.doctorDetails.clinicState ? "" : "State*",
          rules: [
            function(v) {
              return !!v || "State Required"
            }
          ],
          items: _vm.regions_data,
          value: _vm.doctorDetails.clinicState,
          "item-text": "label",
          "item-value": "value",
          outlined: ""
        },
        on: {
          change: function($event) {
            return _vm.updateDoctorData($event, "clinicState")
          }
        }
      }),
      _vm._v(" "),
      _c("v-combobox", {
        attrs: {
          items: [],
          value: _vm.doctorDetails.clinic,
          "return-object": false,
          label: "Clinic",
          outlined: ""
        },
        nativeOn: {
          input: function($event) {
            return _vm.updateDoctorData($event.target.value, "clinic")
          }
        }
      }),
      _vm._v(" "),
      _c("v-combobox", {
        attrs: {
          rules: [
            function() {
              return (
                (_vm.checkIfNumber && !!_vm.doctorDetails.clinicPhoneNumber) ||
                "Enter 10 digit number"
              )
            },
            function() {
              return (
                !!_vm.doctorDetails.clinicPhoneNumber ||
                "Clinic Number Required"
              )
            }
          ],
          items: [],
          value: _vm.doctorDetails.clinicPhoneNumber,
          "return-object": false,
          maxlength: 10,
          label: "Clinic Phone Number (Exclude Dashes)*",
          outlined: ""
        },
        nativeOn: {
          input: function($event) {
            return _vm.updateDoctorData(
              $event.target.value,
              "clinicPhoneNumber"
            )
          }
        }
      }),
      _vm._v(" "),
      _c("p", [_vm._v("*Required Fields")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }