<template>
    <CatalogCategoryLensablView v-if="statusApi" />
</template>

<script>
import App from '../../../../App.vue'
import CatalogCategoryLensablView from '../../../../components/catalog/category/lensabl/CatalogCategoryLensablView.vue'

export default {
    name: 'RouterCatalogCategoryLensablView',
    components: {
        CatalogCategoryLensablView
    },
    extends: App
}
</script>
