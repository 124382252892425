<template>
    <div>
        <span v-if="isSummary" v-dompurify-html="prescriptionName" :class="{'fulfilled': hasPrescriptionName}" class="option-value"></span>
        <div v-if="isStepHeader" class="header-reading-value">
            <div class="reading-data">
                <div class="reading-data-header">Left</div>
                <div v-dompurify-html="osLabel" class="reading-data-value"></div>
            </div>

            <div class="reading-data">
                <div class="reading-data-header">Right</div>
                <div v-dompurify-html="odLabel" class="reading-data-value"></div>
            </div>
        </div>
    </div>
</template>

<script>
import formatLensValue from '../../../app/utils/lens-formatter'
import RendererAbstractPrescription from '../abstract_prescription/RendererAbstractPrescription'

export default {
    name: 'RendererReadablePrescription',
    extends: RendererAbstractPrescription,
    computed: {
        odLabel () {
            return this.getEyeLabelComputed('od')
        },
        osLabel () {
            return this.getEyeLabelComputed('os')
        }
    },
    methods: {
        getEyeLabelComputed (index, precision = 2) {
            const value = this.values.prescriptionValues.lens_power

            const {od, os} = value

            if ([od, os].indexOf(undefined) !== -1) {
                return false
            }
            return formatLensValue(value[index], precision)
        }
    }
}
</script>
