<template>
    <div v-show="isCurrent" class="lensabl-plus-content business-dashboard">
        <h3 v-if="config.title" v-dompurify-html="config.title" class="title"></h3>
        <p class="description">
            You have selected the 2021 <span class="plan-name"> {{ plan.label }} </span> Plan, you can add dependents below if you like or continue without any:
        </p>

        <div class="pricing_container">
            <table>
                <thead>
                    <tr>
                        <th colspan="2">PRICING</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Only you
                        </td>
                        <td>
                            <strong> ${{ plan.price }} </strong> per month
                        </td>
                    </tr>
                    <tr>
                        <td>
                            You + 1 dependent
                        </td>
                        <td>
                            <strong> ${{ hasDecimal(dependentPricing['price_dependent']) ? dependentPricing['price_dependent'].toFixed(2) : dependentPricing['price_dependent'] }} </strong> per month
                        </td>
                    </tr>
                    <tr>
                        <td>
                            You + 2 or more dependents
                        </td>
                        <td>
                            <strong> ${{ hasDecimal(dependentPricing['price_multiple']) ? dependentPricing['price_multiple'].toFixed(2) : dependentPricing['price_multiple'] }} </strong> per month
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p v-if="num > 0" class="dependent-optional">
            DEPENDENT DETAILS
        </p>

        <div class="dependents_table">
            <div v-if="dependents.length > 1" class="dropdown-component contacts_rx-container">
                <div class="contacts_rx-dropdown-container">
                    <div class="contacts_rx-dropdown-value" @click="toggleDropdown">
                        <span :class="{active: showDependents}">
                            Dependent {{ currentIndex + 1 }}
                        </span>
                    </div>
                    <transition name="slide">
                        <div v-if="showDependents" class="contacts_rx-dropdown-list" :class="{active: showDependents}">
                            <div class="contacts_rx-dropdown-wrapper">
                                <div v-for="(dependent, index) in dependents" :key="index">
                                    <span class="contacts_rx-dropdown-item" @click="updateDisplayed(index)">Dependent {{ index + 1 }}</span>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="dependents-table">
                <section v-for="(dependent, index) in dependents" :key="`section-${index}`" :class="{active: currentIndex === index}" class="dependent-table">
                    <div v-for="(d, key, i) in dependent" :key="`${index}-${key}`">
                        <label :for="`${index}-${key}`">
                            {{ dependent_table[i].label }}:
                        </label>
                        <input
                            v-if="key !== 'birthday'"
                            :id="`${index}-membership-billing_address_${key}`"
                            v-model="dependent[key]"
                            :class="{'required-entry': dependent_table[index].required, 'validation-failed': errors[index][key]}"
                            class="input-text"
                            type="text"
                            @blur="validBlurField(index, key)"
                            @focus="updateDependent(index)"
                        />
                        <template v-else>
                            <date-picker
                                v-model="dependent.birthday"
                                placeholder="MM-DD-YYYY"
                                value-type="format"
                                :input-class="{'required-entry': dependent_table[i].required, 'validation-failed': errors[index][key]}"
                                class="input-text"
                                type="text"
                                format="MM-DD-YYYY"
                                @input="validBlurField(index, dependent_table[i].name)"
                                @focus="updateDependent(index)"
                            />
                        </template>
                        <div v-if="errors[index][key]" :id="`advice-required-entry-${errors[index][key]}`" class="validation-advice" style="">
                            {{ dependent_table[i].message }}
                        </div>
                    </div>
                    <div class="remove">
                        <span @click="removeDependent(index)">REMOVE</span>
                    </div>
                </section>
            </div>
            <div class="text-center">
                <span class="add" @click="addDependent">+ ADD dependent</span>
                <span v-if="dependentError" :id="`advice-required-entry-dependent-error`" class="validation-advice">Finish adding dependent</span>
            </div>
            <vue-modaltor
                :visible="showWarning"
                :default-width="'450px'"
                :bg-overlay="'#000'"
                :bg-panel="'#fff'"
                :close-scroll="false"
                class="small"
                @hide="hideModal"
            >
                <p>
                    <strong>
                        The dependents without emails will access their account through the main account holder's email address
                    </strong>
                </p>
                <BaseButton
                    class="button--small"
                    text="CONFIRM"
                    @click.native="continueAction"
                />
            </vue-modaltor>
        </div>

        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton
                    class="button--copy-solid-white-blue button--small"
                    text="Continue"
                    @click.native="continueNxt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseButton from '../../../base/BaseButton.vue'
import DatePicker from 'vue2-datepicker'
import {validationMixin} from 'vuelidate'
import 'vue2-datepicker/index.css'
import {required, email, minLength} from 'vuelidate/lib/validators'
import _map from 'lodash/map'

export default {
    name: 'Dependents',
    components: {
        BaseButton,
        DatePicker
    },
    mixins: [validationMixin],
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        showWarning: false,
        showDependents: false,
        dependent: {},
        dependents: [],
        dependent_table: [
            {
                name: 'first_name',
                label: 'First Name',
                required: true,
                message: "Can't be left blank"
            },
            {
                name: 'last_name',
                label: 'Last Name',
                required: true,
                message: "Can't be left blank"
            },
            {
                name: 'email',
                label: 'Email',
                required: true,
                message: 'Enter a valid email'
            },
            {
                name: 'birthday',
                label: 'Birthday',
                type: 'text',
                placeholder: 'MM-DD-YYYY',
                required: true,
                message: 'Can\'t be left blank'
            }
        ],
        errors: [],
        dependentError: false,
        num: 0,
        currentIndex: 0
    }),
    validations: {
        dependent: {
            email: {
                required,
                email
            },
            first_name: {
                required,
                minLength: minLength(2)
            },
            last_name: {
                required,
                minLength: minLength(2)
            },
            birthday: {
                required
            }
        }
    },
    computed: {
        ...mapGetters('lensablplus_customer', ['getObjectConfig', 'currentComponent', 'values', 'plan', 'employeePayment']),
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet', 'isDesktop']),
        dependentInvalidFields () {
            const invalidFields = []
            _map(this.dependent, (value, key) => {
                if (this.$v.dependent.hasOwnProperty(key) && this.$v.dependent[key].$invalid) {
                    invalidFields.push(key)
                }
            })
            return invalidFields
        },
        dependentIsValid () {
            return !this.dependentInvalidFields.length
        },
        config () {
            return this.getObjectConfig(this.id)
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        },
        dependentPricing () {
            const price = {
                'price_dependent' : 0,
                'price_multiple' : 0
            }

            return Object.keys(this.plan).length > 0 ? this.plan['pricing_information']['dependent_pricing'] : price
        },
        isValid () {
            if (this.dependents.length > 1) {
                const errors = []
                this.errors.forEach((element, index) => {
                    _map(element, (value, key) => {
                        if (value) {
                            errors.push(true)
                        }
                    })
                })
                return errors.length === 0
            } else {
                return true
            }
        }
    },
    methods: {
        continueNxt () {
            if (this.dependentIsValid && this.isValid) {
                this.continueAction()
            } else {
                this.dependentError = true
                this.validateAll()
            }
        },
        addDependent () {
            if (this.dependentIsValid && this.isValid) {
                this.dependentError = false
                this.dependent = {first_name: '', last_name: '', email: '', birthday: ''}
                this.errors.push({first_name: false, last_name: false, email: false, birthday: false})
                this.dependents.push(this.dependent)
                this.currentIndex = (this.dependents.length - 1)
                this.num++
            } else {
                this.dependentError = true
                this.validateAll()
            }
        },
        removeDependent (index) {
            this.dependents.splice(index, 1)
            this.dependent = {}
            this.errors.splice(index, 1)
            this.num--
            this.dependentError = false
            this.currentIndex = (this.dependents.length - 1)
        },
        hasDecimal (n) {
            return (n - Math.floor(n)) !== 0
        },
        validBlurField (index, val) {
            this.errors[index][val] = this.$v.dependent[val].$invalid
        },
        updateDisplayed (index) {
            this.dependent = this.dependents[index]
            this.currentIndex = index
            this.toggleDropdown()
        },
        updateDependent (index) {
            this.dependent = this.dependents[index]
            this.currentIndex = index
        },
        validateAll () {
            this.dependents.forEach((element, index) => {
                this.dependent = element
                _map(this.dependent_table, (value, key) => {
                    this.validBlurField(index, value['name'])
                })
            })
        },
        hideModal () {
            this.showWarning = false
        },
        continueAction () {
            this.$store.commit('lensablplus_customer/COMPONENT_DATA', {id: this.id, value: this.dependents, isValidValue: true})

            if (!this.employeePayment) {
                this.$store.commit('lensablplus_business/LOAD', true)
                this.$store.dispatch('lensablplus_customer/setEmployeePlan')
            }
            this.$store.dispatch('lensablplus_customer/fetchData')
        },
        toggleDropdown () {
            this.showDependents = !this.showDependents
        }
    }
}
</script>

<style scoped lang="css">
    .mx-datepicker {
        border-radius: .3125rem;
        width: 100%;

    }

    input.mx-input {
        margin-bottom: 0 !important;
    }

    input::placeholder {
        font-style: normal!important;
    }

    h2 {
        text-transform: inherit;
    }
</style>
