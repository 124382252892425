<template>
    <CustomerOrder v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CustomerOrder from '../../../components/orders/CustomerOrder.vue'

export default {
    name: 'RouterCustomerOrder',
    components: {
        CustomerOrder
    },
    extends: App
}
</script>
