<template>
    <div v-if="affirmPrice" class="affirm-info">
        <div class="starting">
            {{startingTitle}}{{ affirmPrice }}
        </div>
        <div class="affirm-info">
            or 3 payments of ${{ affirmPayment }} with
            <span class="__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed">Affirm</span>
        </div>
        <div class="accrue-frames-section">
            <span>or save up and earn <span class="accrue-percentage">15%</span> in cash rewards with <span><img src="/media/wysiwyg/accrue-logo-black.png" class="accrue-logo-black-frames-img" /></span></span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import getSizeHelper from '../lensabl/constants/size'
import _formatPrice from '../../../../app/utils/formatPrice'

export default {
    name: 'ProductAffirmInfo',
    props: {
        startingTitle: {
            type: String,
            default: 'from $'
        }
    },
    data: () => ({
        price: ''
    }),
    computed: {
        ...mapGetters('productView', ['productCategoryName', 'frame_price']),
        affirmPayment () {
            return Math.ceil((parseFloat(this.affirmPrice) / 3) * 100) / 100
        },
        affirmPrice () {
            const price = this.price[this.productCategoryName]
                ? this.price[this.productCategoryName]
                : this.frame_price
            return _formatPrice(price)
        }
    },
    created () {
        const {PRICE} = getSizeHelper({assets_url: this.$store.getters['storeView/assets_url']})
        this.price = PRICE
    }
}
</script>