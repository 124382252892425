<template>
    <div>
        <div class="sb-inner-wrapper">
            <div class="sb-inline">
                <h1 v-if="category.description" v-dompurify-html="category.description"></h1>
                <h1 v-else v-text="category.name"></h1>
            </div>
            <div v-if="category.image" :class="['sb-inner-img' + categoryClass]" class="sb-inline sb-inner-img">
                <img id="category-img" :alt="category.name" :src="category.image" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CatalogCategoryViewBanner',
    props: {
        category: {
            type: [Object, Array],
            default: () => {}
        },
        categoryClass: {
            type: String,
            default: ''
        }
    }
}
</script>
