<template>
    <div v-if="data.length > 0" class="subscription-order-container">
        <div class="subscription-order-header">
            <div class="subscription-order-cell">Subscription</div>
            <div class="subscription-order-cell">Next Order Date</div>
            <div class="subscription-order-cell">Who is it for?</div>
            <div class="subscription-order-cell">Quantity</div>
            <div class="subscription-order-cell"></div>
        </div>
        <template v-for="subscription in subscriptions">
            <div :key="`subscription-${subscription.uuid}`" class="subscription-order-contents">
                <div class="subscription-order-row">
                    <div class="subscription-order-cell--mobile">Subscription</div>
                    <div>
                        <span v-if="subscription.products.left.product_name">{{ subscription.products.left.product_name }}</span><span v-if="subscription.products.right.product_name">,</span>
                        <span v-if="subscription.products.right.product_name">{{ subscription.products.right.product_name }}</span>
                    </div>
                </div>
                <div class="subscription-order-row">
                    <div class="subscription-order-cell--mobile">Next Order Date</div><div>{{ subscription.period_ends_at }}</div>
                </div>
                <div class="subscription-order-row">
                    <div class="subscription-order-cell--mobile">Who is it for?</div><div>{{ subscription.who_is_this_for }}</div>
                </div>
                <div class="subscription-order-row">
                    <div class="subscription-order-cell--mobile">Quantity</div><div> Month Supply</div>
                </div>
                <div class="subscription-order-row">
                    <div><span class="subscription-order-button"><a :href="'/contact-lenses/subscription/view/id/' + subscription.uuid">Edit</a></span></div>
                </div>
            </div>
        </template>
    </div>
    <div v-else class="subscription-order-container subscription-order-empty">
        <p class="subscription-order-empty--text">You currently have no subscriptions</p>
        <a href="/shop-contacts/brands" alt="shop contacts">
            <div class="button button--copy-icon account">
                <div class="button__copy">Shop contacts</div>
            </div>
        </a>
    </div>
</template>

<script>
// import getSubscriptionService from "../../../app/utils/subscription-service"
import {mapActions, mapGetters, mapState} from 'vuex'
import subscriptionStorage from '../storage/subscriptions'
import {CONTACTS_SUBSCRIPTIONS_FREQUENCIES} from '../product/storage/product/view'

export default {
    name: 'ContactsSubscriptions',
    data: () => ({
        subscriptions: '',
        frequencies: {
            ...CONTACTS_SUBSCRIPTIONS_FREQUENCIES
        }
    }),
    computed: {
        ...mapState('contacts/subscriptionView', ['data']),
        ...mapGetters(['recurly_auth_api_key'])
    },
    beforeCreate () {
        if (!subscriptionStorage.registered) {
            this.$store.registerModule(['contacts', 'subscriptionView'], subscriptionStorage, { preserveState: !!this.$store.state['contacts/subscriptionView'] })
            subscriptionStorage.registered = true
        }
    },
    created () {
        this.fetchSubscriptionState()
        this.formatSubscriptions()
    },
    methods: {
        ...mapActions('contacts/subscriptionView', ['fetchSubscriptionState']),
        formatSubscriptions () {
            const data = this.data
            data.forEach(object => {
                object.period_ends_at = this.formatDate(object.period_ends_at)
            })
            this.subscriptions = data
        },
        formatDate (date) {
            const oldDate = new Date(date)
            const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            const month = monthNames[oldDate.getMonth()]
            const day = oldDate.getDate()
            const year = oldDate.getFullYear()
            return `${month} ${day}, ${year}`
        }
        // fetchSubscriptions(){
        //     console.log('fetching subscriptions')
        //     let api_key = this.$store.getters.recurly_auth_api_key
        //     getSubscriptionService({api_key}).getSubscriptions().then((response) => {
        //         this.subscriptions = response
        //     })
        // }
    }
}
</script>
