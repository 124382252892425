<template>
    <div>
        <div v-if="formShow" class="shippingaddress-form">
            <form id="data-shippingaddress-form" data-shippingaddress-form="data-shippingaddress-form">
                <div class="shippingaddress-form__name-wrap">
                    <div class="shippingaddress__name-input-container">
                        <div class="billing-form-address__form-row">
                            <div class="billing-form-address__col half-width">
                                <input v-model="addressData.firstname" type="text" class="input-text required-entry" name="firstname" placeholder="First Name..." />
                            </div>
                            <div class="billing-form-address__col half-width">
                                <input v-model="addressData.lastname" type="text" class="input-text required-entry" name="lastname" placeholder="Last Name..." />
                            </div>
                        </div>

                        <div class="billing-form-address__form-row">
                            <input v-model="addressData.street_1" type="text" name="street[]" class="input-text required-entry" placeholder="Street Address 1" />
                        </div>
                        <div class="billing-form-address__form-row">
                            <input v-model="addressData.street_2" type="text" name="street[]" class="input-text" value="" placeholder="Street Address 2" />
                        </div>

                        <div class="billing-form-address__form-row">
                            <input v-model="addressData.city" type="text" class="input-text required-entry" required="required" />
                        </div>

                        <div class="billing-form-address__form-row">
                            <CustomSelect :value="addressData.region_id" :options="regionsOptions" @change="setCurrentRegion($event.target.value)" />
                        </div>

                        <div class="billing-form-address__form-row">
                            <div class="billing-form-address__col half-width">
                                <input v-model="addressData.postcode" type="text" class="input-text required-entry" required="required" />
                            </div>
                            <div class="billing-form-address__col half-width">
                                <input v-model="addressData.telephone" type="text" class="input-text required-entry" />
                            </div>
                        </div>

                        <div class="billing-form-address__form-row">
                            <div class="button button--copy-border-sm keep">
                                <div class="button__container">back</div>
                            </div>
                            <div class="button button--copy-border-sm" style="float:right">
                                <div class="button__container">save</div>
                            </div>
                        </div>

                        <div class="billing-form-address__form-row actions-toolbar">
                            <div class="secondary">
                                <BaseButton :disabled="saveLoader" class="button--copy-solid-white button--copy-border-sm keep" data-size="xs" text="Back" @click.native="closeForm" />
                            </div>

                            <div class="secondary">
                                <BaseButton :loader="saveLoader" class="button--copy-solid-white button--copy-border-sm" data-size="xs" text="Save" @click.native="saveForm" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="track-page-shipping-info">
            If you'd like to update your shipping address, please contact <a href="mailto:support@lensabl.com?subject=Change Shipping Address">support@lensabl.com</a>
        </div>
    </div>
</template>

<script>
import _find from 'lodash/find'
import {mapGetters} from 'vuex'
import shipping from '../storage/modules/shipping'
import BaseButton from '../../base/BaseButton.vue'
import CustomSelect from '../../base/Select.vue'
import $ from 'jquery'

export default {
    name: 'ShippingAddressUpdate',
    components: {
        BaseButton,
        CustomSelect
    },
    data: () => ({
        formShow: false,
        saveLoader: false
    }),
    computed: mapGetters('shipping/address', {
        addressData: 'data',
        regionsOptions: 'regionsOptions',
        shippingService: 'shippingService'
    }),
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['shipping/']) {
            this.$store.registerModule('shipping', shipping(this.$store))
        }
    },
    methods: {
        saveForm () {
            this.saveLoader = true

            this.shippingService.update(this.addressData)
                .then(({status, data}) => {
                    if (status === 200 && data.data.html) {
                        $('#shippingaddress').html(data.data.html)
                    }
                    this.closeForm()
                })
                .catch(error => {
                    if (error) {
                        $('.messages').remove()
                        $('.account__inner').prepend(`<ul class="messages"><li class="error-msg"><ul><li>${error}</li></ul></li></ul>`)
                    }
                    console.error(error)
                })
                .finally(() => this.saveLoader = false)
        },
        openForm () {
            this.formShow = true
        },
        closeForm () {
            this.formShow = false
        },
        setCurrentRegion (region_id) {
            const currentRegion = _find(this.regionsOptions, {'value': region_id}) || {}

            this.$set(this.addressData, 'region', currentRegion.title || '')
            this.$set(this.addressData, 'region_id', region_id)
        }
    }
}
</script>
