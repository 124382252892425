<template>
    <div>
        <div v-show="productView.productInfoShow" class="product-details-holder spectacles-wrapper look-wrapper frames-wrapper">
            <div class="page-title-wrapper product">
                <h1 v-dompurify-html="product_name" :class="product_name" class="page-title" itemprop="name"></h1>
            </div>
            <div class="product media">
                <Gallery />
            </div>

            <div class="product-info-main">
                <div v-if="product_description" class="look-desc">
                    <span v-dompurify-html="product_description"></span>
                </div>

                <div class="swatch-opt">
                    <ColorSwatches :colors="colors" :checked-index="checkedIndex" @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor" />
                </div>

                <p v-if="!stockStatus" class="unavailable stock" title="Availability">
                    <span style="color:red">This item is sold out</span>
                </p>

                <AdditionalAttributes :data-attributes="additionalAttributes" class="inline" />

                <div v-if="isAvailable && isMobile" class="product-options-bottom">
                    <div class="actions">
                        <BaseButton :text="'Select lenses'" @click.native="mobileSwitcher()">
                            <span v-text="isMobile? 'Select' : 'Select lenses'"></span>
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="configuratorShow" class="product-configurator-wrapper">
            <Configurator />
        </div>
    </div>
</template>

<script>
import product from './storage/product/view'
import {mapGetters, mapMutations, mapActions} from 'vuex'
import Configurator from '../../configurator/Configurator.vue'
import Gallery from './view/Gallery.vue'
import BaseButton from '../../base/BaseButton.vue'
import ColorSwatches from './view/ColorSwatches.vue'
import Affirm from './view/Affirm.vue'
import ProductAffirmInfo from './view/ProductAffirmInfo.vue'
import ReturnsInfo from '../cms_info/ReturnsInfo.vue'
import AdditionalAttributes from './view/AdditionalAttributes.vue'
import {
    EVENT_CONFIGURATOR_RESET,
    EVENT_CHANGE_CURRENT_COLOR,
    EVENT_CHANGE_CURRENT_OPTION
} from '../../../app/utils/constants'

export default {
    name: 'CatalogProductView',
    components: {
        Gallery,
        Configurator,
        BaseButton,
        ColorSwatches,
        AdditionalAttributes,
        Affirm,
        ProductAffirmInfo,
        ReturnsInfo
    },
    data: () => {
        return {
            size_components: {},
            sunColors: [],
            sunIndex: null,
            EVENT_CHANGE_CURRENT_COLOR: EVENT_CHANGE_CURRENT_COLOR,
            EVENT_CHANGE_CURRENT_OPTION: EVENT_CHANGE_CURRENT_OPTION
        }
    },
    computed: {
        ...mapGetters('values', ['values', 'checkoutData']),
        ...mapGetters('productView', ['productView', 'productCategoryName', 'product_name', 'product_description', 'stockStatus', 'colors', 'colorItem', 'colorName', 'getCurrentProductAttribute']),
        ...mapGetters('deviceProperty', ['isMobile', 'isDesktop']),
        checkedIndex () {
            return this.productView.checkedIndex
        },
        isAvailable () {
            return this.stockStatus
        },
        additionalAttributes () {
            return [
                {
                    label: 'Size',
                    value: this.getCurrentProductAttribute('size')
                },
                {
                    label: 'Material',
                    value: this.getCurrentProductAttribute('material')
                },
                {
                    label: 'Color',
                    value: this.colorName.toLowerCase()
                }
            ]
        },
        configuratorShow () {
            return this.isAvailable && this.productView.productConfiguratorShow
        },
        currentImage () {
            return this.colors[this.checkedIndex]
        },
    },
    watch: {
        colorItem (item) {
            this.setValuesService(item)
        },
        isMobile (mobile) {
            this.setProductInfoShow(true)
            this.setProductConfiguratorShow(!mobile)
        },
        configuratorShow (show) {
            this.$store.commit('configurator/SET_CONFIGURATOR_SHOW', show)
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['productView/']) {
            this.$store.registerModule('productView', product(this.$store))
        }

        this.$store.dispatch('productView/fetchDefaultData')
    },
    created () {
        this.$bus.$on(EVENT_CONFIGURATOR_RESET, () => {
            if (this.isMobile) {
                this.setProductInfoShow(true)
                this.setProductConfiguratorShow(!this.isMobile)
                window.scrollTo(0, 0)
            }
        })

        this.setProductConfiguratorShow(!this.isMobile)
        this.$nextTick(() => this.$store.commit('configurator/SET_CONFIGURATOR_SHOW', this.configuratorShow))
        this.$store.dispatch('lensablplus_customer/checkRedeemablePath')
    },
    methods: {
        ...mapMutations('productView', ['setCheckedIndex', 'setProductInfoShow', 'setProductConfiguratorShow', 'setFramePrice']),
        ...mapMutations('values', ['setDataToValues']),
        ...mapActions('lensablplus_customer', ['checkRedeemablePath']),
        changeCurrentColor (index) {
            this.setCheckedIndex(index)
        },
        setValuesService (item) {
            const values = this.checkoutData
            const {frames_name, frames_value} = values.custom_options
            if (frames_value && frames_name) {
                this.setDataToValues({
                    frames_value: item.color,
                    size: item.size || this.productView.size,
                    frames_SKU: item.frames_SKU,
                    frame_price: item.price
                })
            }
            if (item.price) {
                this.setFramePrice(item.price)
            }
        },
        mobileSwitcher () {
            this.setProductInfoShow(false)
            this.setProductConfiguratorShow(true)
        },

        changeCurrentLensColor (index) {
            this.sunIndex = index
        },
        imgExist (url) {
            const http = new XMLHttpRequest()
            http.open('HEAD', url, false)
            http.send()
            return http.status !== 404
        },
        formattedProductDescription(description){
            const txt = document.createElement('textarea')
            txt.innerHTML = description
            return txt.value
        }
    }
}
</script>
