import {
    COMPONENT_ID_COMPANY_DETAILS,
    COMPONENT_ID_PRIMARY_CONTACT,
    COMPONENT_ID_PLAN_OPTIONS,
    COMPONENT_ID_FINISH,
    COMPONENT_ID_PAYMENT_INFORMATION
} from './businessObjectList'

/**
 * Components Chain
 */
export const OBJECTS_CHAIN = [
    {
        'id': COMPONENT_ID_COMPANY_DETAILS,
        'stepId': '1',
        'next': {
            COMPONENT_ID_PRIMARY_CONTACT
        }
    },
    {
        'id': COMPONENT_ID_PRIMARY_CONTACT,
        'stepId': '2',
        'next': {
            COMPONENT_ID_PLAN_OPTIONS
        }
    },
    {
        'id': COMPONENT_ID_PLAN_OPTIONS,
        'stepId': '3',
        'next': {
            COMPONENT_ID_PAYMENT_INFORMATION
        }
    },
    {
        'id': COMPONENT_ID_PAYMENT_INFORMATION,
        'stepId': '4',
        'next': {
            COMPONENT_ID_FINISH
        }
    },
    {
        'id': COMPONENT_ID_FINISH,
        'stepId': '5',
        'next': ''
    }
]
