import {mapState, mapGetters} from 'vuex'
import product from '../../components/catalog/product/storage/product/view'
import generateOptions from '../utils/generate-options'
import _transform from 'lodash/transform'
import _clone from 'lodash/clone'
import {
    PRESCRIPTION_TYPE_ONLINE,
    PRESCRIPTION_ADD_ERROR,
    PRESCRIPTION_ADD_PROGRESSIVE_ERROR
} from '../utils/constants'

const delimiter = ' '

export default {
    name: 'CommonPrescriptionModel',
    data: () => {
        const zeroPrefixSPH = ['PL/', 'BAL/BALANCE']
        const zeroPrefixCYL = 'SPH/Blank/'
        const zeroPrefix = 'blank/'
        const prismArray = generateOptions(0, 10, 0.05, 2, true, false)
        const DIRECTION_HORIZONTAL = [
            {value: null, label: '--'},
            {value:'IN', label: 'IN'},
            {value: 'OUT', label: 'OUT'}
        ]

        const DIRECTION_VERTICAL = [
            {value: null, label: '--'},
            {value: 'UP', label: 'UP'},
            {value: 'DOWN', label: 'DOWN'}
        ]

        return {
            zeroPrefixSPH: zeroPrefixSPH,
            zeroPrefixCYL: zeroPrefixCYL,
            zeroPrefix: zeroPrefix,

            eyeSphereOptions: generateOptions(-20, 16, 0.25, 2, true, true, false, delimiter, zeroPrefixSPH),
            // CYLINDER OPTION
            eyePowerOptions: generateOptions(-12, 12, 0.25, 2, true, true, false, delimiter, zeroPrefixCYL),

            eyeAxisOptions: generateOptions(0, 180, 1, 0, true, true, 3, delimiter, zeroPrefix),
            eyeAddOptions: generateOptions(0, 6, 0.25, 2, true, true, false, delimiter),
            eyeODPDOptions: generateOptions(24, 40, 0.5, 1, true, false, false, 'Right'),
            eyeOSPDOptions: generateOptions(24, 40, 0.5, 1, true, false, false, 'Left'),
            // Populary Di
            eyePDOptions: generateOptions(50, 80, 0.5, 1, true, false),
            eyePowerReadingOptions: generateOptions(0, 3.5, 0.25),
            eyePrismHorizontalOptions: prismArray,
            eyePrismVerticalOptions: prismArray,
            eyeHorizontalDirectionOptions: DIRECTION_HORIZONTAL,
            eyeVerticalDirectionOptions: DIRECTION_VERTICAL,

            errorText: PRESCRIPTION_ADD_ERROR,

            // For GoVisibly
            eyeSphereVisiblyOptions: generateOptions(-10.00, 10.00, 0.25, 2, true, true, false, delimiter, zeroPrefixSPH),
            eyeCylinderVisiblyOptions: generateOptions(-4, 4, 0.25, 2, true, true, false, delimiter, zeroPrefixCYL),
            eyeAxisVisiblyOptions: generateOptions(0, 180, 1, 0, false, true, 3, delimiter, zeroPrefix),
            eyeAddVisiblyOptions: generateOptions(0, 3, 0.25, 2, true, true, false, delimiter, zeroPrefix),
            eyePupillarydistanceVisiblyOptions: generateOptions(55, 70, 0.5, 1, false, false),
            eyeODPDVisiblyOptions: generateOptions(24, 40, 0.5, 1, true, false, false, 'Right'),
            eyeOSPDVisiblyOptions: generateOptions(24, 40, 0.5, 1, true, false, false, 'Left'),
            // Only contacts for GoVisibly
            eyeBasecurveVisiblyOptions: generateOptions(7, 9, 0.1, 2, false, false),
            eyeDiameterVisiblyOptions: generateOptions(13, 16, 0.1, 2, false, false)
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['productView/']) {
            this.$store.registerModule('productView', product(this.$store))
        }

        this.$store.dispatch('productView/fetchDefaultData')
    },
    created () {
        this.getSphereOptions()
    },
    computed: {
        ...mapState('prescriptions', ['optionsValidate', 'optionsAreValid']),
        ...mapGetters('values', ['values', 'prescriptionData']),
        ...mapGetters('productView', ['product_name']),
        ...mapGetters('prescriptions', ['getValidatePrescriptionData']),

        validateOnlinePrescriptionData () {
            const editRx = this.isPrescriptionEditing(this.prescriptionData)
            if (!editRx) {
                return false
            }
            const typePrescription = this.values[this.prescriptionData.prescription_type] || ''
            if (!typePrescription || typePrescription !== PRESCRIPTION_TYPE_ONLINE) {
                return false
            }
            return this.validatePrescriptionData(this.prescriptionData.value, this.prescriptionData.prescription_type)
        }
    },
    methods: {
        getSphereOptions () {
            if (this.product_name === 'Tempo') {
                this.eyeSphereOptions = generateOptions(-4, 4, 0.25, 2, true, true, false, delimiter, this.zeroPrefixSPH)
                this.eyePowerOptions = generateOptions(-4, 4, 0.25, 2, true, true, false, delimiter, this.zeroPrefixSPH)
            }
        },
        validatePrescriptionData (prescription, prescription_type) {
            let show = false
            if (this.optionsValidate) {
                this.$store.dispatch('prescriptions/validatePrescriptionData', {prescription, prescription_type})
                const optionsAreValid = this.getValidatePrescriptionData(prescription, prescription_type)
                const _left = !optionsAreValid.left
                const _right = !optionsAreValid.right
                const value = {
                    ...prescription
                }
                const prescriptionType = _clone(prescription_type)

                if (!value || !prescriptionType) {
                    return show
                }

                const progressiveProduct = prescriptionType.indexOf('progressive') !== -1 || prescriptionType.indexOf('focal') !== -1
                if (_right || _left) {
                    if (progressiveProduct) {
                        this.errorText = PRESCRIPTION_ADD_PROGRESSIVE_ERROR
                    } else {
                        this.errorText = PRESCRIPTION_ADD_ERROR
                    }
                    show = true
                }
            }
            return show
        },
        _transformPrescription (values) {
            const result = {}

            _transform(values, (result, value, key) => {
                const splitKey = key.split('_')
                const indexKey = splitKey && splitKey.length ? splitKey.last() : key
                if (indexKey.indexOf('axis') !== -1) {
                    result[key] = value && value.length < 3 ? value.padStart(3, 0) : value
                } else if (key.indexOf('pd') !== -1) {
                    result[key] = Number(value) > 0 && key !== 'separate_pd' ? parseFloat(value).toFixed(1) : key !== 'separate_pd' ? '' : value

                    if (['os_pd', 'od_pd'].indexOf(key) !== -1) {
                        result['separate_pd'] = Number(value) > 0
                    }
                } else if (['sphere', 'cylinder', 'add'].indexOf(indexKey) !== -1) {
                    result[key] = value && value.trim().length
                        ? Number(value) !== 0
                            ? value > 0
                                ? '+' + parseFloat(value).toFixed(2)
                                : parseFloat(value).toFixed(2)
                            : value
                        : delimiter
                } else if (indexKey.indexOf('prism') !== -1 && !result['prism']) {
                    result['prism'] = value && value.length
                    result[key] = value
                } else {
                    result[key] = value
                }
            }, result)

            return result
        },
        isPrescriptionEditing (data, status4 = false) {
            const {name} = data
            let isEditing = true
            if (name && name.indexOf('Opternative') !== -1) {
                isEditing = false
            }
            if (isEditing && status4) {
                isEditing = false
            }
            return isEditing
        },
        showAddOptions (prescription_type) {
            return prescription_type.indexOf('single_vision_distance') === -1 && prescription_type !== 'spectacles_single_vision'
        },
        getTitle (title) {
            title = title.replace('_', ' ')
            return title.toUpperCase()
        },
        getOptions (key) {
            key = key.replace('_', '')
            const typeOption = key.charAt(0).toUpperCase() + key.slice(1)
            const functionName = `eye${typeOption}VisiblyOptions`
            return this[functionName]
        },
        setPrescriptionValue (value, eye, key) {
            const eyeValues = {...this.prescriptions[eye]}
            eyeValues[key] = value
            this.prescriptions[eye] = eyeValues
        }
    }
}
