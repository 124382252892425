var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataLoaded
    ? _c("div", { staticClass: "reward-loader" }, [_c("Loader")], 1)
    : _c(
        "div",
        { staticClass: "plus-account_wrappper" },
        [
          _vm.plusMember
            ? _c("PlusDashboard")
            : _vm.isBusinessMember
            ? _c("BusinessDashboard")
            : _c("div", { staticClass: "non-plus-wrapper" }, [_c("NonPlus")], 1)
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }