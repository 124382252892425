<template>
    <div>
        <div v-if="!showFramesPrice" class="notice">
            All lenses include specific coatings for:
            <ul class="lens-icon-list">
                <li class="icon anti-reflective"><span>Anti<br />Reflection</span></li>
                <li class="icon ultraviolet"><span>100% UV<br />Protection</span></li>
                <li class="icon scratch-resistant"><span>Scratch<br />Resistance</span></li>
            </ul>
            <p><b>We include a Case and Cleaning Kit<br />with all orders.</b></p>
        </div>
        <div v-if="isSpectacles" class="notice">Don't need a prescription?<br /><a href="https://www.spectacles.com">Return to Spectacles</a></div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import summary from './storage/summary'

export default {
    name: 'Notice',
    computed: {
        ...mapGetters('configurator', ['isSpectacles']),
        ...mapGetters('summary', ['showFramesPrice'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['summary/']) {
            this.$store.registerModule('summary', summary(this.$store))
        }
        this.$store.dispatch('summary/fetchDefaultData')
    }
}
</script>
