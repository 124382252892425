var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "return-button-container" }, [
      _c(
        "button",
        {
          staticClass: "return-button selected",
          on: {
            click: function($event) {
              return _vm.toggleReturn(_vm.returns, $event)
            }
          }
        },
        [_vm._v("Eyeglasses")]
      ),
      _vm._v(" "),
      !_vm.hideContactsTab
        ? _c(
            "button",
            {
              staticClass: "return-button",
              on: {
                click: function($event) {
                  return _vm.toggleReturn(_vm.everyday, $event)
                }
              }
            },
            [_vm._v("Contacts")]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "return-section", attrs: { id: "returns" } },
      [
        _c("List", {
          attrs: { prescriptions: _vm.lens_prescriptions, type: _vm.lens }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "return-section", attrs: { id: "everyday" } },
      [
        _c("List", {
          attrs: {
            prescriptions: _vm.contacts_prescriptions,
            type: _vm.contacts
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }