export default function (
    value,
    precision = 2,
    needSign = true,
    zeroFill = false,
    noneValue,
    type = false
) {
    if (typeof value.toFixed === 'undefined') {
        return value
    }

    if (value === 0 && type) {
        return type + value
    }

    if (value === 0) {
        return value
    }

    if (zeroFill) {
        let prefix = ''
        for (let i = 1; i < zeroFill; i++) {
            prefix += '0'
        }
        value = (prefix + value).slice(-zeroFill)
    } else {
        let sign = ''
        if (needSign) {
            sign = (value > 0) ? '+' : ''
        }
        value = sign + value.toFixed(precision)
    }
    return value
}
