var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "spectacles-banner frames-banner",
      class: _vm.getClass + "-banner"
    },
    [
      _c("div", { staticClass: "sb-inner-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "sb-inline",
            class: _vm.currentCategory.class + "-inline"
          },
          [
            _c("div", { staticClass: "sb-inner", class: _vm.getClass }, [
              _c("a", { attrs: { href: _vm.currentCategory.url } }, [
                _c("h1", { class: _vm.getClass + "-home" }, [
                  _vm._v(_vm._s(_vm.currentCategory.name))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "instructions" }, [
              _c("span", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.currentCategory.description,
                    expression: "currentCategory.description"
                  }
                ]
              })
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }