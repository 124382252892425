<template>
    <ul class="plans-list">
        <li v-for="(plan, index) in currentPlanInfo" :key="index" :class="plan.id">
            <div v-if="typeof plan.thumbnail === 'string'" class="plan-info-container">
                <div class="thumb" @click="setActiveIndex(index)">
                    <img v-if="typeof plan.thumbnail === 'string'" :src="plan.thumbnail" />
                    <template v-else>
                        <img v-for="(thumbnail, thumbIndex) in plan.thumbnail" :key="`thumbnail-${thumbIndex}`" :src="thumbnail" :class="`${plan.id}-${thumbIndex}`" />
                    </template>
                </div>
                <div class="content">
                    <h3 v-dompurify-html="plan.title" :class="{'has-description': plan.description || plan.contactsInfo(eyeData.left, eyeData.right), 'active': activeIndex === index}" class="tile" @click="setActiveIndex(index)"></h3>
                    <div v-show="activeIndex === index" class="dual-description-container">
                        <div v-if="plan.description" v-dompurify-html="plan.description" class="description"></div>
                        <HelpBubble v-if="plan.helpBubble" :data="plan.helpBubbleParams" :target-classes="plan.helpBubbleParams.targetClasses" />
                        <div v-if="plan.contactsInfo(eyeData.left, eyeData.right)" v-dompurify-html="plan.contactsInfo(eyeData.left, eyeData.right)" class="description"></div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="plan-info-container dual-options top-option">
                    <div class="thumb" @click="setActiveIndex(index)">
                        <img :src="plan.thumbnail[0]" :class="`${plan.id}-0}`" />
                    </div>
                    <div class="content">
                        <h3
                            v-dompurify-html="plan.title[0]"
                            :class="{'has-description': plan.description, 'active': activeIndex === index}"
                            class="tile"
                            @click="setActiveIndex(index)"
                        ></h3>
                        <div v-show="activeIndex === index" class="dual-description-container">
                            <div v-if="plan.description[0] !== ''" v-dompurify-html="plan.description[0]" class="description"></div>
                            <span v-if="plan.helpBubble" class="description">
                                Frames from <HelpBubble :data="plan.helpBubbleParams" :target-classes="plan.helpBubbleParams.targetClasses" :placement="'bottom'" /> available at extra charge.
                                <br />
                                <i>* High-index (thin), Transitions, Sunglass lens and other options available for extra charge</i>
                            </span>
                        </div>
                    </div>
                </div>
                <strong><p>-or-</p></strong>
                <div class="plan-info-container dual-options bottom-option">
                    <div class="thumb" @click="setActiveIndex(index)">
                        <img :src="plan.thumbnail[1]" :class="`${plan.id}-1}`" />
                    </div>
                    <div class="content">
                        <h3
                            v-dompurify-html="plan.title[1]"
                            :class="{'has-description': plan.description, 'active': activeIndex === index}"
                            class="tile second-option"
                            @click="setActiveIndex(index)"
                        ></h3>
                        <div v-show="activeIndex === index" class="dual-description-container">
                            <div v-dompurify-html="plan.description[1]" class="description"></div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import {mapGetters} from 'vuex'
import HelpBubble from '../../help_bubble/HelpBubble.vue'

export default {
    name: 'TotalsInfo',
    components: {
        HelpBubble
    },
    data: () => ({
        activeIndex: null
    }),
    computed: {
        ...mapGetters('lensablPlus', ['currentPlanInfo', 'values', 'eyeData'])
    },
    methods: {
        setActiveIndex (index) {
            this.activeIndex = (this.activeIndex !== index) ? index : null
        }
    }
}
</script>
