<template>
    <div>
        <div v-if="!memberShipEdited">
            <table cellspacing="0" class="form-list">
                <tr>
                    <td class="label">Selected contact for Left Eye (OS)</td>
                    <td class="label">Selected contact for Right Eye (OD)</td>
                </tr>
                <tr>
                    <td class="value">
                        <strong v-if="contactLensData.left.name" v-dompurify-html="`${contactLensData.left.name} - ${contactLensData.left.short_description}`"></strong>
                    </td>
                    <td class="value">
                        <strong v-if="contactLensData.right.name" v-dompurify-html="`${contactLensData.right.name} - ${contactLensData.right.short_description}`"></strong>
                    </td>
                </tr>
            </table>

            <hr />

            <table cellspacing="5" class="form-list">
                <tr>
                    <td class="label">First Redeemed</td>
                    <td class="value">
                        <strong v-text="contactsRedeemedAtDisplay"></strong>
                    </td>
                    <td>
                        <button
                            :class="getViewOrderButtonClass"
                            :disabled="!contactLensData['redeemed_order_id']"
                            class="scalable"
                            @click="goToContactsOrder"
                            v-text="contactsViewOrderDisplayText"
                        ></button>
                    </td>
                </tr>
            </table>

            <table cellspacing="5" class="form-list">
                <tr>
                    <td class="label">Prescription Expires</td>
                    <td class="value">
                        <strong v-text="contactsPrescriptionExpirationDisplay"></strong>
                    </td>
                    <td>
                        <button class="scalable send" @click="goToRxView">View Rx</button>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else class="lensabl-plus-content">
            <v-flex v-show="isContactsPlan" class="contacts-switcher two-col">
                <v-col>
                    <label for="contacts-switcher-eye-left" class="search-title">Left Eye:</label>
                    <select id="contacts-switcher-eye-left" v-model="contacts.left" class="contacts-switcher-select">
                        <template v-for="(product, index) in productsData">
                            <option :key="index" :value="product.sku" v-text="product.name"></option>
                        </template>
                    </select>
                </v-col>
                <v-col>
                    <label for="contacts-switcher-eye-right" class="search-title">Right Eye:</label>
                    <select id="contacts-switcher-eye-right" v-model="contacts.right" class="contacts-switcher-select">
                        <template v-for="(product, index) in productsData">
                            <option :key="index" :value="product.sku" v-text="product.name"></option>
                        </template>
                    </select>
                </v-col>
            </v-flex>
        </div>
        <BaseLoader v-if="!dataLoaded" />
        <div v-else-if="!isContactsPlan" class="overlay"></div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import _find from 'lodash/find'
import _first from 'lodash/first'
import dateFormat from 'dateformat'
import {
    EVENT_ADD_CONTACTS_TO_EYE,
    EVENT_CONTACT_LENSES_FOUND
} from '../../storage/lensablplus'
import {
    COMPONENT_ID_SEARCH,
    COMPONENT_ID_GLASSES,
    GLASSES_AND_CONTACTS_VALUE
} from '../../storage/constants/objectList'
import BaseLoader from '../../../base/BaseLoader.vue'

export default {
    name: 'LensabalplusMembershipContactLens',
    components: {
        BaseLoader
    },
    props: {
        dataLoaded: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data: () => ({
        contacts: {
            left: '',
            right: ''
        },
        event_add_contacts_to_eye: EVENT_ADD_CONTACTS_TO_EYE,
        event_contact_lenses_found: EVENT_CONTACT_LENSES_FOUND
    }),
    computed: {
        ...mapState('lensablPlus', ['selectedPlan']),
        ...mapGetters('lensablPlus', ['products', 'productsData', 'values']),
        ...mapGetters('lensablPlus/membership', ['memberShipEdited', 'currentMemberShipData']),
        contactLensData () {
            return this.currentMemberShipData.contact_lens
        },
        contactsRedeemedAtDisplay () {
            let redeemed = ''
            if (this.contactLensData['has_redeemed'] === false) {
                redeemed = 'Not Yet Redeemed'
            } else if (this.contactLensData['has_redeemed'] && this.contactLensData['contacts_order_created_at']) {
                redeemed = this.formatDate(this.contactLensData['contacts_order_created_at'])
            } else {
                redeemed = 'Redeemed'
            }
            return redeemed
        },
        contactsViewOrderDisplayText () {
            let redeemed = ''
            if (this.contactLensData['has_redeemed'] === false) {
                redeemed = 'Not Redeemed'
            } else if (this.contactLensData['has_redeemed'] && this.contactLensData['redeemed_order_id']) {
                redeemed = 'View Order'
            } else {
                redeemed = 'Redeemed'
            }
            return redeemed
        },
        contactsPrescriptionExpirationDisplay () {
            return this.contactLensData['rx_expiration_date'] || 'TBD'
        },
        isContactsPlan () {
            return this.selectedPlan.has_contacts || this.values[COMPONENT_ID_GLASSES] === GLASSES_AND_CONTACTS_VALUE
        },
        eyeProducts () {
            return {
                left: _find(this.productsData, product => product.sku === this.contacts.left),
                right: _find(this.productsData, product => product.sku === this.contacts.right)
            }
        },
        searchContactsRightLabel () {
            const product = this.eyeProducts.right || {}
            return `Right Eye: ${product.defaultName || ''}`
        },
        searchContactsLeftLabel () {
            const product = this.eyeProducts.left || {}
            return `Left Eye: ${product.defaultName || ''}`
        }
    },
    watch: {
        isContactsPlan: {
            handler (isContacts) {
                this.$store.commit('lensablPlus/COMPONENT_DATA', {
                    id: COMPONENT_ID_SEARCH,
                    value: isContacts ? this.contacts : {
                        left: '',
                        right: ' '
                    },
                    isValidValue: true
                })
            },
            deep: true
        },
        contacts: {
            handler (data) {
                this.$store.commit('lensablPlus/COMPONENT_DATA', {
                    id: COMPONENT_ID_SEARCH,
                    value: data,
                    isValidValue: true
                })
            },
            deep: true
        }
    },
    created () {
        this.contacts.left = (_first(this.productsData) || {}).sku
        this.contacts.right = (_first(this.productsData) || {}).sku
    },
    methods: {
        formatDate (date) {
            return dateFormat(new Date(date), 'mmm dS, yyyy h:MM:ss TT')
        },
        getViewOrderButtonClass () {
            return this.contactLensData['has_redeemed'] ? 'save' : 'back'
        },
        goToContactsOrder () {
            location.href = this.contactLensData['contacts_order_url']
        },
        goToRxView () {
            location.href = this.contactLensData['rx_view_url']
        },
        addContactsLeft (product) {
            this.contacts.left = product.sku
        },
        addContactsRight (product) {
            this.contacts.right = product.sku
        }
    }
}
</script>
