<template>
    <CatalogProductRheosView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogProductRheosView from '../../../components/catalog/product/CatalogProductRheosView.vue'

export default {
    name: 'RouterCatalogProductRheos',
    components: {
        CatalogProductRheosView
    },
    extends: App
}
</script>
