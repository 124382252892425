<template>
    <GamingGlassesLanding v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import GamingGlassesLanding from '../../components/gaming_glasses/GamingGlassesLanding.vue'

export default {
    name: 'RouterGamingGlasses',
    components: {
        GamingGlassesLanding
    },
    extends: App
}
</script>
