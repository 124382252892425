<template>
    <div>
        <div>
            <table cellspacing="0" class="form-list eye-exam">
                <tbody>
                    <tr>
                        <td class="label">Reciept Uploaded</td>
                        <td class="value" width="20%">
                            <div class="upload-prescription">
                                <div class="upload-component">
                                    <div v-if="isUploading">
                                        <div class="uploading-progress">Uploading...</div>
                                    </div>

                                    <div v-if="hasFile" class="uploaded-file">
                                        <a v-if="file_download_url" v-dompurify-html="fileData.fileName" :href="file_download_url" download></a>
                                        <span v-else v-dompurify-html="fileData.fileName"></span>
                                    </div>

                                    <div v-if="showError" class="error-hint">
                                        <span v-dompurify-html="errorHint"></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="label">
                            <div :class="{'invalid-form': showInvalidFormBorder}" class="file-input-box">
                                <a href="javascript:void(0)" type="button" title="Continue" class="action file-input-button">
                                    <button :disabled="!hasNoFile" :class="{'disabled': !hasNoFile}">Upload Reciept</button>
                                    <input v-if="hasNoFile" type="file" name="prescription" accept="image/*,application/pdf" @change="previewFiles" />
                                </a>
                                <button :disabled="approved || finished" :class="{'disabled': approved || finished}" class="approve" @click="approvedReceiptRewards">Approve (Sends Gift Card)</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr v-show="!approved" />

            <table v-show="!approved" cellspacing="0" class="form-list eye-exam">
                <tbody>
                    <tr>
                        <td class="value">
                            <textarea v-model="textMessage" rows="5" placeholder="Message..."></textarea>
                        </td>
                        <td class="scope-label">
                            <label>
                                <input v-model="rejection" type="checkbox" />
                                <span>Notify customer of rejection</span>
                            </label>
                        </td>
                        <td class="scope-label">
                            <button class="submit" @click="submitMessage">Submit</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr v-if="messages.length" />

            <table v-if="messages.length" cellspacing="0" class="form-list eye-exam">
                <thead>
                    <th>History</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="(message, index) in messages" :key="`message-${index}`" class="tr-line">
                        <td v-dompurify-html="message.message" class="value"></td>
                        <td class="scope-label">
                            <strong v-text="message.status"></strong>
                        </td>
                        <td class="scope-label">
                            <strong v-if="message.admin_firstname" v-text="`by ${message.admin_firstname} ${message.admin_lastname}`"></strong>
                            <small v-if="message.admin_email" v-text="`(${message.admin_email})`"></small>
                        </td>
                        <td class="scope-label" v-text="message.created_at"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <BaseLoader v-if="loader || !dataLoaded" />
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import _assign from 'lodash/assign'
import _cloneDeep from 'lodash/cloneDeep'
import {
    encodeObjectToURI
} from '../../../../app/utils/buildData'
import axios from 'axios'
import PrescriptionUploadAbstract from '../../../prescription/upload/PrescriptionUploadAbstract.vue'
import BaseLoader from '../../../base/BaseLoader.vue'
import {
    STATUS_PENDING,
    STATUS_APPROVED,
    STATUS_REJECTED,
    STATUS_FINISHED
} from '../../storage/lensablplus/customer'
import {
    REJECTED_TEXT_MESSAGE
} from '../../storage/constants/objectList'

export default {
    name: 'LensabalplusMembershipEyeExam',
    components: {
        BaseLoader
    },
    extends: PrescriptionUploadAbstract,
    props: {
        dataLoaded: {
            type: Boolean,
            default: true,
            required: false
        },
        redeemItemType: {
            type: String,
            default: 'exam',
            required: true
        }
    },
    data: () => ({
        uploaded: false,
        file_download_url: '',
        upload_id: '',
        textMessage: '',
        message: '',
        rejection: false,
        rejected_text_message: _cloneDeep(REJECTED_TEXT_MESSAGE)
    }),
    computed: {
        ...mapGetters('storeView', ['form_key']),
        ...mapState('lensablPlus/membership', ['accountFormData']),
        ...mapGetters('lensablPlus/membership', ['memberShipEdited', 'currentMemberShipData', 'getRedeemItemData', 'updateReceiptRewardsUrl']),
        ...mapGetters('lensablplus_customer', ['getPrefixRedeem', 'isBusinessMember']),
        isValidForm () {
            return !!this.fileData.dataURL
        },
        redeemItemData () {
            return this.getRedeemItemData(this.redeemItemType)
        },
        receiptData () {
            return this.redeemItemData['receipt_data'] || {}
        },
        hasNoFile () {
            return !this.hasFile || this.rejected
        },
        sendToServerData () {
            const {name, size, type} = this.fileData.file
            return {
                name,
                size,
                type,
                is_membership: this.currentMemberShipData['lplus_membership_id'],
                data: this.fileData.dataURL,
                item_id: this.currentMemberShipData['item_id']
            }
        },
        messages () {
            return (this.receiptData.messages || []).reverse()
        },
        rejected () {
            return this.receiptData['status'] === STATUS_REJECTED
        },
        approved () {
            return this.receiptData['status'] === STATUS_APPROVED
        },
        finished () {
            return this.receiptData['status'] === STATUS_FINISHED
        }
    },
    watch: {
        rejected: {
            handler (rejected) {
                if (rejected) {
                    this.setComponentData()
                }
            },
            deep: true
        },
        dataLoaded: {
            handler (loaded) {
                if (loaded) {
                    this.setComponentData()
                }
            },
            deep: true
        },
        rejection: {
            handler (rejected) {
                if (rejected && !this.textMessage) {
                    this.textMessage = this.rejected_text_message
                } else if (this.textMessage === this.rejected_text_message) {
                    this.textMessage = ''
                }
            },
            deep: true
        }
    },
    created () {
        this.setComponentData()
    },
    methods: {
        setComponentData () {
            const uploadData = this.receiptData['upload_data']

            if (uploadData && uploadData.upload_id) {
                this.upload_id = uploadData.upload_id
                this.message = this.rejected ? '' : this.receiptData.message || ''
                this.fileData.dataURL = this.rejected ? '' : uploadData.url
                this.fileData.fileName = this.rejected ? '' : uploadData.name
                this.file_download_url = this.rejected ? '' : uploadData['file_download_url']
            }
        },
        sendToServer (object) {
            this.loader = true
            this.isUploading = true
            this.upload_id = ''

            axios.post(this.uploadUrl, object, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    const {name, upload_id} = response.data['uploaded_data']

                    this.upload_id = upload_id
                    this.file_download_url = response.data['file_download_url']

                    this.fileData.fileName = name
                    this.isUploading = false
                    this.loader = false
                    this.uploadData = response

                    _assign(this.uploadData, response)

                    if (this.hasFile) {
                        this.updateReceiptRewards(this.receiptData['status'] === STATUS_APPROVED ? STATUS_APPROVED : STATUS_PENDING, 0)
                    }
                })
                .catch((error) => {
                    this.showError = true
                    this.removeFile()
                    console.error(error)
                })
        },
        updateReceiptRewards (status, addToHistory = true) {
            const urlData = {
                isAjax: 1,
                form_key: this.form_key,
                membership_id: this.currentMemberShipData['lplus_membership_id'],
                customer_id: this.currentMemberShipData['lensabl_customer_id'],
                item_id: this.currentMemberShipData['item_id'],
                upload_id: this.upload_id,
                message: this.textMessage,
                status: status || this.receiptData['status'],
                add_to_history: addToHistory,
                redeem_item_type: this.redeemItemType
            }
            this.loader = true
            return axios.post(`${this.updateReceiptRewardsUrl}?${encodeObjectToURI(urlData)}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(({status, data}) => {
                if (status === 200) {
                    this.$store.commit('lensablPlus/membership/UPDATE_REDEEMABLE_PRODUCTS_DATA', data['redeemable_products'] || {})

                    this.$store.commit('lensablplus_customer/UPDATE_REDEEM_ITEM_DATA', {
                        type: this.redeemItemType,
                        data: data.hasOwnProperty('receipt_data') ? data['receipt_data'] : {}
                    })
                    this.uploaded = true
                    this.textMessage = ''
                    this.rejection = false
                }
                // this.$store.dispatch('lensablPlus/membership/fetchRedeemsHistoryData')
                this.loader = false
            })
        },
        approvedReceiptRewards () {
            if (!this.approved && this.hasFile) {
                return this.updateReceiptRewards(STATUS_APPROVED)
            }
        },
        reject () {
            this.removeFile()
            return this.updateReceiptRewards(STATUS_REJECTED)
        },
        submitMessage () {
            if (this.rejection) {
                return this.reject()
            } else {
                return this.updateReceiptRewards()
            }
        }
    }
}
</script>
