import { render, staticRenderFns } from "./Finish.vue?vue&type=template&id=3f593851&"
import script from "./Finish.vue?vue&type=script&lang=js&"
export * from "./Finish.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/tkim/projects/lensabl/lensconfigurator/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f593851')) {
      api.createRecord('3f593851', component.options)
    } else {
      api.reload('3f593851', component.options)
    }
    module.hot.accept("./Finish.vue?vue&type=template&id=3f593851&", function () {
      api.rerender('3f593851', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/configurator/Finish.vue"
export default component.exports