<template>
    <div class="c-product-gallery-mobile">
        <carousel :per-page="1" :mouse-drag="true">
            <template v-for="(image, index) in images">
                <slide v-if="index < 2" :key="image.index" class="c-product-gallery-mobile-slide">
                    <img :src="image.thumbnail" :alt="name" />
                </slide>
            </template>
        </carousel>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import { Carousel, Slide } from 'vue-carousel'

export default {
    name: 'MobileGallery',
    components: {
        Carousel,
        Slide
    },
    props: {
        name: {
            type: String,
            default: '',
            required: true
        }
    },
    computed: {
        ...mapState('contacts/productView', ['images'])
    }
}
</script>
