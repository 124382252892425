var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.categoryName
    ? _c(
        "div",
        {
          class: ["banner-" + _vm.categoryName.toLowerCase()],
          style: {
            "background-image":
              "url('/js/lensconfigurator/assets/" +
              _vm.categoryName.toLowerCase() +
              ".png')"
          }
        },
        [
          _c("div", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.description,
                expression: "description"
              }
            ],
            staticClass: "banner-container"
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }