<template>
    <div>
        <Loader v-if="LOADING_TYPE" />
        <div v-else>
            <div>
                <div v-for="(item, index) in ITEMS" :key="index">
                    <div class="plus-type-content">
                        <p>Subscription Type: <span class="selected-type">{{ item.subscription_type }}</span></p>
                        <p>Glasses RX Type: <span class="selected-type">{{ item.glasses_rx_type }}</span></p>
                    </div>
                    <div v-if="item.contacts" class="plus-type-contacts">
                        <p>Your Contacts:</p>
                        <div v-for="(contact, key) in item.contacts" :key="key" :class="['plus-' + key]">
                            <div class="plus-contact-container">
                                <img :src="contact.image" :title="contact.name" />
                                <div>
                                    <h6 v-if="key.indexOf('right') !== -1" class="product-name">Right Eye - OD</h6>
                                    <h6 v-if="key.indexOf('left') !== -1" class="product-name">Left Eye - OS</h6>
                                    <h6 v-dompurify-html="`${contact.name}, ${contact.second_title}`" class="product-title"></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Loader from '../../loaders/Loader.vue'

export default {
    name: 'LPlusTypes',
    components: {
        Loader
    },
    computed: {
        ...mapGetters('checkoutPlus', ['ITEMS', 'LOADING_TYPE'])
    },
    mounted () {
        this.$store.dispatch('checkoutPlus/GET_ITEMS')
    },
    methods: {
        ...mapActions('checkoutPlus', ['GET_ITEMS'])
    }
}
</script>
