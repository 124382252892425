<template>
    <div class="subscription-recurly-modal">
        <div class="modal-mask" @click="closeButton()"></div>
        <div class="modal-container">
            <div class="modal-close-button" @click="closeButton()"></div>
            <div v-if="billing" class="subscription-recurly-billing-container">
                <span class="modal-header">Edit Payment Method</span>
                <form id="subscription-billing-form">
                    <h5>Billing Information</h5>
                    <p>Select a billing address from your address book or enter a new address.</p>

                    <div v-dompurify-html="billing_address"></div>

                    <RecurlyAddressForm :add-card="true" :account-id="accountId" address-type="billing" />
                </form>
            </div>

            <div v-if="shipping" class="subscription-recurly-shipping-container">
                <span class="modal-header">Edit Shipping Address</span>
                <form id="subscription-shipping-form">
                    <h5>Shipping Address</h5>
                    <p>Select a shipping address from your address book or enter a new address.</p>

                    <div v-dompurify-html="shipping_address"></div>

                    <RecurlyAddressForm :add-card="false" address-type="shipping" @update-shipping="updateShipping" />
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import RecurlyAddressForm from './RecurlyAddressForm.vue'

export default {
    name: 'RecurlyModal',
    components: {
        RecurlyAddressForm
    },
    props: {
        billing: {
            type: [Array, Object, Boolean],
            default: false
        },
        shipping: {
            type: [Array, Object, Boolean],
            default: false
        },
        accountId: {
            type: [String, Number],
            default: ''
        }
    },
    data: () => {
        return {}
    },
    computed: {
        ...mapGetters(['billing_address', 'shipping_address'])
    },
    methods: {
        closeButton () {
            this.$emit('close')
        },
        updateShipping (response) {
            this.$emit('update-shipping', response)
        }
    }
}
</script>
