<template>
    <Category v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import Category from '../../../components/catalog/category/Category.vue'

export default {
    name: 'RouterCategory',
    components: {
        Category
    },

    extends: App
}
</script>
