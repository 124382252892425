<template>
    <div class="gg-container">
        <section class="gg-banner-section">
            <div class="gg-banner-section_row gg-container-row">
                <div class="gg-banner-section_row-header">
                    <h1>We are experts in Gaming Eyewear</h1>
                    <h5>SHOP FROM OUR RANGE OF POPULAR GAMER FRAMES:</h5>
                    <div class="gg-banner-section_row-header-logos">
                        <a href="/gamer-advantage-eyewear">
                            <img src="media/wysiwyg/gaming-glasses-gamer-logo.png" alt="Gamer Advantage Logo" class="gg-gamer-logo" />
                        </a>
                        <a href="/hyperx-eyewear">
                            <img src="media/wysiwyg/gaming-glasses-hyperx-logo.png" alt="HyperX Logo" class="gg-hyperx-logo" />
                        </a>
                    </div>
                </div>
                <div class="gg-banner-section_row-subtext">
                    <span>You can add custom prescription lenses to any of these frames, but be sure to select Blue-Light lenses or Blue-Light protection coatings.</span>
                </div>
            </div>
        </section>
        <section class="gg-hev-section">
            <div class="gg-hev-section_row gg-container-row">
                <div class="gg-hev-section_row-header">
                    <h4>Maintain Your Competitive Edge by Protecting Your Eyes From Blue-Light</h4>
                    <p>HEV (high-energy visible rays) rays emitted from computer, TV, and phone screens that we spend 10 hours (on avg.) a day in front of cause our eyes to work harder and age faster.</p>
                </div>
            </div>
        </section>
        <section class="home__call-to-action-two gg-symptoms-section">
            <div class="blue-light-one">
                <div class="offset-container">
                    <section class="home-insurance-block" style="text-align: center">
                        <h4 style="color: #2f3948; font-weight: bolder; text-transform: uppercase;">Symptoms Of Blue Light</h4>
                        <ul class="symptoms-container">
                            <li class="symptom" style="border-radius: .3em;">
                                <img src="/js/lensconfigurator/assets/eye-strain.svg" alt="blurry vision" class="symptoms-img" />
                                <h3>Eye Strain</h3>
                            </li>
                            <li class="symptom" style="border-radius: .3em;">
                                <img src="/js/lensconfigurator/assets/headaches.svg" alt="blurry vision" class="symptoms-img" />
                                <h3>Headaches</h3>
                            </li>
                            <li class="symptom hard-time" style="border-radius: .3em;">
                                <img src="/js/lensconfigurator/assets/no-sleep.svg" alt="blurry vision" class="symptoms-img" />
                                <h3>Hard Time Sleeping</h3>
                            </li>
                            <li class="symptom" style="border-radius: .3em;">
                                <img src="/js/lensconfigurator/assets/blurry-vision.svg" alt="blurry vision" class="symptoms-img" />
                                <h3>Blurry Vision</h3>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </section>
        <section class="gg-frames-section">
            <div class="gg-frames-section_row gg-container-row">
                <div class="gg-frames-section_row-header">
                    <h4>YOU CAN ALSO ADD BLUE-LIGHT PROTECTION TO ANY OF THE FRAMES WE OFFER OR IN FRAMES YOU ALREADY OWN:</h4>
                    <div class="gg-frames-section_row-header-buttons">
                        <a href="/shop-frames" title="Shop Frames">
                            <div class="button button--copy-solid">
                                <div class="button__container">SHOP FRAMES</div>
                            </div>
                        </a>
                        <a href="/choose-my-lenses" title="Lens Replacement">
                            <div class="button button--copy-solid">
                                <div class="button__container seo-btn-lr">SHOP LENS REPLACEMENT</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="gg-spectrum-section home-insurance-block" style="text-align: center">
            <div class="gg-spectrum-section_header">
                <h4>Blue-Light Blocking Lenses Vs Coating</h4>
                <p>Lenses with Blue-Light blocking built in, protect your eyes from 80+% while lenses with a coating only block up to 20% of HEV rays.</p>
            </div>
            <h4 style="color: #2f3948; font-weight: bolder;text-transform: uppercase;">LIGHT Spectrum</h4>
            <ul class="symptoms-container">
                <img style="margin: 1em;" class="hev-spectrum" src="/media/wysiwyg/HEV-spectrum.jpg" alt="hev spectrum" />
            </ul>
        </section>
        <section class="home__call-to-action-two gg-lenses-section">
            <div class="struct-parallax-bg" data-parallax="data-parallax" data-attrs-pos-start="50% -130%" data-attrs-pos-end="50% 60%" data-attrs-xlarge-pos-start="50% -100%" data-attrs-xlarge-pos-end="50% 0%" data-attrs-trigger-hook="1">
                <section class="home-insurance-block gg-lenses-section_row" style="text-align: center">
                    <h2 style="color: #2f3948; font-weight: bolder;">All Lensabl Lenses Include</h2>
                    <ul class="blue-light-coatings gg-blue-light-coatings">
                        <li>
                            <span class="anti-reflective"></span>
                            <div class="coating-description">
                                <h5>Anti-Reflective Coating</h5>
                                <span>Blocks reflections and glare, i.e. from car headlamps and screens. Included for free on all lenses.</span>
                            </div>
                        </li>
                        <li>
                            <span class="ultraviolet"></span>
                            <div class="coating-description">
                                <h5>Ultra Violet Blocking Treatment</h5>
                                <span>A coating that acts as a sunscreen for your eyes, blocking UV rays from passing through lenses. Included for free on all lenses.</span>
                            </div>
                        </li>
                        <li>
                            <span class="scratch-resistant"></span>
                            <div class="coating-description">
                                <h5>Scratch Resistance</h5>
                                <span>A coating that strengthens your lenses to protect from scratches or other damage. Included for free on all lenses.</span>
                            </div>
                        </li>
                        <li>
                            <span class="hydrophobic">
                                <img src="/js/lensconfigurator/assets/hydrophobic.svg" alt="blurry vision" class="hydrophobic-img" />
                            </span>

                            <div class="coating-description">
                                <h5>Hydrophobic Coating</h5>
                                <span>
                                    Helps repel water, grease, smudges, and static from your lenses, making cleaning easier. Included for free on all lenses.
                                </span>
                            </div>
                        </li>
                    </ul>
                </section>
            </div>
        </section>
        <section class="gg-footer-section">
            <div class="gg-footer-section_row gg-container-row">
                <img src="/media/wysiwyg/gaming-glases-footer.jpg" alt="guy on computer" />
                <div class="gg-footer-section_row-text">
                    <p>Lensabl also offers a range of affordable frames to meet all of your gaming needs.</p>
                    <div class="gg-footer-section_row-text-buttons">
                        <a href="/shop-frames?category=everyday-eyewear">
                            <div class="button button--copy-solid">
                                <div class="button__container">SHOP EVERYDAY EYEWEAR</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'GamingGlassesLanding'
}
</script>
