var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-selector-container", class: _vm.currentSection.id },
    [
      _vm.colorImg && _vm.lensPrefix !== "transitions"
        ? _c("div", { staticClass: "lens-img" }, [
            _c("img", {
              class: _vm.color.value,
              attrs: { src: _vm.colorImg, alt: _vm.color.value }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class:
            _vm.lensPrefix === "transitions"
              ? "transitions-color-picker-list"
              : "color-selector"
        },
        [
          _c("div", { staticClass: "lens-type" }, [
            _c("div", { staticClass: "color-picker" }, [
              _c("div", { staticClass: "color-picker-toggle" }, [
                _c("p", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.currentSection.hint,
                      expression: "currentSection.hint"
                    }
                  ],
                  staticClass: "color-selector-hint"
                }),
                _vm._v(" "),
                _vm.currentSection.description
                  ? _c("div", { staticClass: "transitions-description" }, [
                      _c("p", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: _vm.currentSection.description,
                            expression: "currentSection.description"
                          }
                        ]
                      }),
                      _vm._v(" "),
                      (_vm.chosenSection === "transitions-vantage" ||
                        _vm.chosenSection === "transitions-drivewear") &&
                      _vm.isBoseLanding
                        ? _c("span", [
                            _c("i", [_vm._v("*Not available with Tempo frame")])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasMatchColorSection
                  ? _c(
                      "div",
                      {
                        staticClass: "range-label",
                        on: { click: _vm.toggleSelectColor }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "option-checkbox-container",
                            class: { active: !_vm.valueMatchColor }
                          },
                          [_c("div", { staticClass: "option-checkbox" })]
                        ),
                        _vm._v(" "),
                        _c("strong", [_vm._v("Select Lens Color")])
                      ]
                    )
                  : _c("div", { staticClass: "range-label wo-match" }, [
                      _c(
                        "div",
                        {
                          staticClass: "option-checkbox-container",
                          class: { active: !_vm.valueMatchColor }
                        },
                        [_c("div", { staticClass: "option-checkbox" })]
                      ),
                      _vm._v(" "),
                      _vm.chosenSection === "mirror"
                        ? _c("strong", [_vm._v("Select Mirror Color")])
                        : _c("strong", [_vm._v("Select Lens Color")])
                    ]),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    class:
                      _vm.lensPrefix === "transitions"
                        ? "transitions-color-picker-list"
                        : "color-picker-list"
                  },
                  [
                    _vm._l(_vm.colors, function(colour) {
                      var _obj
                      return [
                        !colour.hidden
                          ? _c(
                              "li",
                              {
                                key: colour.id,
                                class: [
                                  _vm.lensPrefix === "transitions"
                                    ? "transitions-color-clicker"
                                    : "color-clicker",
                                  ((_obj = {
                                    active: _vm.isActiveColor(colour.id),
                                    "disabled-color": colour.hidden,
                                    "has-description": colour.description
                                  }),
                                  (_obj[colour.id] = colour.id),
                                  (_obj[_vm.chosenSection + "-list-item"] =
                                    _vm.chosenSection),
                                  _obj)
                                ],
                                attrs: {
                                  title: colour.color_name || colour.label
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.chooseColor(colour.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "content" },
                                  [
                                    _vm.lensPrefix === "transitions"
                                      ? _c("img", {
                                          class: _vm.lensPrefix + "-img",
                                          attrs: {
                                            src: colour.front[0].thumbnail,
                                            alt:
                                              colour.color_name || colour.label
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "dompurify-html",
                                          rawName: "v-dompurify-html",
                                          value:
                                            colour.color_name || colour.label,
                                          expression:
                                            "colour.color_name || colour.label"
                                        }
                                      ],
                                      staticClass: "label"
                                    }),
                                    _vm._v(" "),
                                    colour.component_help
                                      ? _c("HelpBubble", {
                                          attrs: { data: colour.component_help }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                colour.description
                                  ? _c("div", {
                                      directives: [
                                        {
                                          name: "dompurify-html",
                                          rawName: "v-dompurify-html",
                                          value: colour.description,
                                          expression: "colour.description"
                                        }
                                      ],
                                      staticClass: "description"
                                    })
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.hasAdditionSection && !_vm.valueMatchColor
                  ? _c("div", { staticClass: "color-selector-tint" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "slider-wrapper" }, [
                        _c("ul", { staticClass: "range-labels" }, [
                          _c(
                            "li",
                            {
                              class: {
                                "third-level active": _vm.color.level === 3
                              },
                              attrs: { "data-slider": "3" },
                              on: {
                                click: function($event) {
                                  return _vm.sliderValue(3)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "range-label" }, [
                                _vm._v("Level 3*")
                              ]),
                              _vm._v(" "),
                              _c("p", [_vm._v("(80%)")]),
                              _vm._v(" "),
                              _vm._m(1)
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              class: {
                                "second-level active": _vm.color.level === 2
                              },
                              attrs: { "data-slider": "2" },
                              on: {
                                click: function($event) {
                                  return _vm.sliderValue(2)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "range-label" }, [
                                _vm._v("Level 2")
                              ]),
                              _vm._v(" "),
                              _c("p", [_vm._v("(50%)")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              class: {
                                "first-level active": _vm.color.level === 1
                              },
                              attrs: { "data-slider": "1" },
                              on: {
                                click: function($event) {
                                  return _vm.sliderValue(1)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "range-label" }, [
                                _vm._v("Level 1")
                              ]),
                              _vm._v(" "),
                              _c("p", [_vm._v("(20%)")])
                            ]
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasMatchColorSection
                  ? _c(
                      "div",
                      {
                        staticClass: "color-selector-tint match-color-section"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "range-label match-color-label",
                            on: {
                              click: function($event) {
                                return _vm.toggleMatchColor()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "option-checkbox-container",
                                class: { active: _vm.valueMatchColor }
                              },
                              [_c("div", { staticClass: "option-checkbox" })]
                            ),
                            _vm._v(" "),
                            _c("strong", [_vm._v("Match Current Lens Color ")]),
                            _vm._v(" "),
                            _c("span", [_vm._v("(+$10)")])
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(2)
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tint-level" }, [
      _c("span", [_vm._v("Tint Level")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tint-hint" }, [
      _c("span", [_vm._v("*Standard Sunglass tint level")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "color-match-disclaimer" }, [
      _c("p", [_vm._v("*We are unable to match mirror coatings on lenses")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }