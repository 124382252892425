<template>
    <div class="">
        <div class="top">
            <div class="divider-line" :style="{width: `${(100/(steps.length) * (steps.length - 1)) - 10}%`}"></div>
            <div v-if="showWrapper" class="steps-wrapper">
                <template v-if="topButtons">
                    <div v-if="currentStep.index > 0" class="stepper-button-top previous" @click="backStep()">
                        <i class="material-icons">keyboard_arrow_left</i>
                    </div>
                </template>
                <template v-for="(step, index) in steps">
                    <div :key="index" :class="['step', isStepActive(index, step)]" :style="{width: `${100 / steps.length}%`}">
                        <div class="circle">
                            <i class="material-icons md-18">
                                {{ step.completed ? 'done' : step.icon }}
                            </i>
                        </div>
                        <div class="step-title">
                            <h4>{{ step.title }}</h4>
                            <h5 class="step-subtitle">{{ step.subtitle }}</h5>
                        </div>
                    </div>
                </template>
                <div v-if="topButtons" :class="['stepper-button-top next', !canContinue ? 'deactivated' : '']" @click="nextStep()">
                    <i class="material-icons">keyboard_arrow_right</i>
                </div>
            </div>
        </div>
        <div class="content">
            <transition :enter-active-class="enterAnimation" :leave-active-class="leaveAnimation" mode="out-in">
                <!--If keep alive-->
                <keep-alive v-if="keepAliveData">
                    <component
                        :is="steps[currentStep.index].component"
                        :clicked-next="nextButton[currentStep.name]"
                        :current-step="currentStep"
                        @can-continue="proceed"
                        @active-next="activateNextStep"
                        @change-next="changeNextBtnValue"
                    />
                </keep-alive>
                <!--If not show component and destroy it in each step change-->
                <component
                    :is="steps[currentStep.index].component"
                    v-else
                    :clicked-next="nextButton[currentStep.name]"
                    :current-step="currentStep"
                    @can-continue="proceed"
                    @change-next="changeNextBtnValue"
                    @active-next="activateNextStep"
                />
            </transition>
        </div>
        <div v-if="!finalStep && SHOW_NAV" :class="['bottom', (currentStep.index > 0) ? '' : 'only-next']" class="form-action">
            <button v-if="currentStep.index > 0" class="button button--copy-solid-dark stepper-button previous" @click="backStep()">
                <span>{{ 'back' | translate(locale) }}</span>
            </button>
            <button :class="['stepper-button next']" class="button button--copy-solid-dark" @click="nextStepWithValid()">
                Continue
                <span>{{ (finalStep) ? 'finish' : '' | translate(locale) }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import HorizontalStepper from 'vue-stepper'

export default {
    extends: HorizontalStepper,
    data () {
        return {
            showWrapper: false
        }
    },
    computed: {
        ...mapGetters('onlineExam', ['SHOW_NAV', 'VALID', 'ERROR'])
    },
    methods: {
        activateNextStep () {
            this.nextStep()
        },
        nextStepWithValid () {
            this.$store.commit('onlineExam/SET_ERROR', !this.VALID)
            if (this.canContinue) {
                this.$store.commit('onlineExam/SET_VALID', false)
                this.$store.commit('onlineExam/SET_ERROR', false)
                this.nextStep()
            }
        }
    }
}
</script>
