var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("Loader")
    : _c(
        "v-form",
        {
          ref: "formProvider",
          staticClass: "anagram-form-container anagram-form2-container",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.updateForm($event)
            }
          },
          model: {
            value: _vm.formValid,
            callback: function($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid"
          }
        },
        [
          _c(
            "v-layout",
            [
              _c("v-flex", [
                _c(
                  "div",
                  {
                    staticClass: "flex-column",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("First Name:")
                    ]),
                    _vm._v(" "),
                    _c("TextField", {
                      attrs: {
                        "form-model": _vm.formModel,
                        "form-key": "first_name",
                        label: "",
                        "err-text": "First Name Required"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-flex", [
                _c(
                  "div",
                  {
                    staticClass: "flex-column",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Last Name:")
                    ]),
                    _vm._v(" "),
                    _c("TextField", {
                      attrs: {
                        "form-model": _vm.formModel,
                        "form-key": "last_name",
                        label: "",
                        rules: function(v) {
                          return !!v && v.length >= 2
                        },
                        "err-text": "Last Name Required"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            [
              _c("v-flex", [
                _c(
                  "div",
                  {
                    staticClass: "flex-column",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Date of Birth (MM/DD/YYYY):")
                    ]),
                    _vm._v(" "),
                    _c("BrthDatePicker", {
                      attrs: {
                        "form-model": _vm.formModel,
                        label: "",
                        "form-key": "dob",
                        "err-text": "Date of Birth Required"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-flex", [
                _c(
                  "div",
                  {
                    staticClass: "flex-column",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Last four digits of SSN:")
                    ]),
                    _vm._v(" "),
                    _c("TextField", {
                      attrs: {
                        "form-model": _vm.formModel,
                        "form-key": "ssn_last_four",
                        label: "",
                        rules: function(v) {
                          return !isNaN(parseInt(v)) && v.length === 4
                        },
                        "err-text": "Last four digits of SSN Required"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-layout", { staticClass: "anagram-form-top" }, [
            _c(
              "div",
              { staticClass: "flex-column", staticStyle: { width: "100%" } },
              [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v("Vision Plan Provider:")
                ]),
                _vm._v(" "),
                _c("SelectField", {
                  attrs: {
                    "form-model": _vm.formModel,
                    options: _vm.companies,
                    label: ""
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-layout",
            [
              _c("v-flex", [
                _c(
                  "div",
                  {
                    staticClass: "flex-column",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(
                        "Insurer Member Id" +
                          _vm._s(_vm.isMemberIdRequired ? "" : " (Optional)") +
                          ":"
                      )
                    ]),
                    _vm._v(" "),
                    _c("TextField", {
                      attrs: {
                        "form-model": _vm.formModel,
                        "form-key": "member_id",
                        label: "",
                        rules: function(v) {
                          return _vm.isMemberIdRequired ? !!v : true
                        },
                        "err-text": "Member Id Required"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-flex", [
                _c(
                  "div",
                  {
                    staticClass: "flex-column",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Zip code (Optional):")
                    ]),
                    _vm._v(" "),
                    _c("TextField", {
                      attrs: {
                        "form-model": _vm.formModel,
                        "form-key": "zip",
                        label: "",
                        rules: function(v) {
                          return true
                        },
                        "err-text": ""
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { staticClass: "anagram-form-top-btn-div" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "button button--copy-solid",
                  attrs: { type: "submit", disabled: _vm.isValid }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }