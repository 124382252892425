<template>
    <div>
        <h2>Cuctomer Account Section</h2>
        category grid template <br />

        <a href="javascript:void(0)" @click="goToProductTestMethod">Go to Product (example link)</a>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'CustomerPrescriptionNew',
    components: {

    },
    computed: {
        ...mapGetters([])
    },
    methods: {
        goToProductTestMethod () {
            this.$root.currentRoute = 'contacts-lenses/product'
        }
    }
}
</script>
