import getHelper from '../helper'
import {
    LENS_OPTION_CODE_167,
    LENS_OPTION_CODE_174,
    LENS_OPTION_CODE_CR39,
    LENS_OPTION_CODE_POLY,
    LENS_OPTION_CODE_TRIVEX,
    LENS_OPTIONS_CODE
} from '../lens_options'
import {
    LENS_TYPE_SECTION_ID,
    LENS_TYPE_ID,
    LENS_TYPE_ID_CLEAR_LENSES,
    LENS_TYPE_ID_SUNGLASSES_LENSES,
    LENS_TYPE_ID_TRANSITIONS_LENSES
} from '../../../../../app/common/constants/lens_type'
import {TRANSITIONS_SECTION_ID_SIGNATURE} from '../transitions_sections'
import {defaultOptions} from "./default";

const ENABLED_LIMITERS_COLOR = {
    'grey-polarized': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_CR39,
            LENS_OPTION_CODE_POLY,
            LENS_OPTION_CODE_167
        ]
    }
}

const DISABLED_LIMITERS_LENS_TYPE_SUNGLASSES = {
    'mirror': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_174,
            LENS_OPTION_CODE_TRIVEX
        ]
    },
    'gradient': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_174
        ]
    },
    'solid': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_174
        ]
    }
}

const DISABLED_LIMITERS_LENS_TYPE_SUNGLASSES_PROGRESS = {
    'polarized': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_167
        ]
    }
}

const ENABLED_LIMITERS_LENS_TYPE_SINGLE = {
    'polarized': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_167,
            LENS_OPTION_CODE_TRIVEX
        ]
    }
}

export const LIMITERS_DEFAULT_HOBIESUNGLASSES = {
    options_disable: {
        single_vision_distance: {
            [LENS_TYPE_SECTION_ID]: DISABLED_LIMITERS_LENS_TYPE_SUNGLASSES,
            color: {
                'graphite-green': {
                    [LENS_OPTIONS_CODE]: [
                        LENS_OPTION_CODE_174
                    ]
                }
            },
            [LENS_TYPE_ID]: {
                [LENS_TYPE_ID_CLEAR_LENSES]: {
                    [LENS_OPTIONS_CODE]: [
                        LENS_OPTION_CODE_TRIVEX
                    ]
                }
            }
        },
        single_vision_reading: {
            [LENS_TYPE_SECTION_ID]: DISABLED_LIMITERS_LENS_TYPE_SUNGLASSES,
            [LENS_TYPE_ID]: {
                [LENS_TYPE_ID_CLEAR_LENSES]: {
                    [LENS_OPTIONS_CODE]: [
                        LENS_OPTION_CODE_TRIVEX
                    ]
                }
            }
        },
        progressive_standard: {
            [LENS_TYPE_SECTION_ID]: Object.assign(DISABLED_LIMITERS_LENS_TYPE_SUNGLASSES, DISABLED_LIMITERS_LENS_TYPE_SUNGLASSES_PROGRESS),
            [LENS_OPTIONS_CODE]: [LENS_OPTION_CODE_TRIVEX]
        },
        progressive_premium: {
            [LENS_TYPE_SECTION_ID]: {
                [TRANSITIONS_SECTION_ID_SIGNATURE]: {
                    [LENS_OPTIONS_CODE]: [
                        LENS_OPTION_CODE_174
                    ]
                }
            },
            [LENS_TYPE_SECTION_ID]: DISABLED_LIMITERS_LENS_TYPE_SUNGLASSES,
            [LENS_OPTIONS_CODE]: [LENS_OPTION_CODE_TRIVEX]
        },
        bi_focal: {
            [LENS_OPTIONS_CODE]: [
                LENS_OPTION_CODE_TRIVEX
            ]
        }
    },
    options_enable: {
        single_vision_distance: {
            color: ENABLED_LIMITERS_COLOR,
            [LENS_TYPE_SECTION_ID]: ENABLED_LIMITERS_LENS_TYPE_SINGLE
        },
        single_vision_reading: {
            color: ENABLED_LIMITERS_COLOR,
            [LENS_TYPE_SECTION_ID]: ENABLED_LIMITERS_LENS_TYPE_SINGLE
        },
        progressive_standard: {
            color: ENABLED_LIMITERS_COLOR
        },
        progressive_premium: {
            color: ENABLED_LIMITERS_COLOR
        }
    }
}

export default function getHobieSunglassesStepList (params) {
    const defaultStepOptions = defaultOptions(params)
    const {product_config} = params
    const {DEFAULT_LAST_STEP} = getHelper(params)


    const lens_type = {
        'id': LENS_TYPE_ID,
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'default_value': LENS_TYPE_ID_SUNGLASSES_LENSES,
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensType.sunglassesLens,
                defaultStepOptions.lensType.computerLens,
                defaultStepOptions.lensType.transitionLens,
            ]
        }
    }

    const lens_options = {
        'id': LENS_OPTIONS_CODE,
        'label': 'Select Lens Material',
        'summary_label': 'Lens Option',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['sunglass_lens', 'transition_lens'],
        'always_visible': true,
        'component': {
            'name': 'selector-options-lens-option',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensOptions.polyLensOption,
                defaultStepOptions.lensOptions.hi167LensOption,
                defaultStepOptions.lensOptions.trivexLensOptions,
            ]
        }
    }

    const single_vision_distance = Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP})
    const single_vision_distance_premium = Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP})
    const single_vision_near = Object.assign({'id': 'single_vision_reading'}, {...DEFAULT_LAST_STEP})
    const single_vision_near_premium = Object.assign({'id': 'single_vision_reading_premium'}, {...DEFAULT_LAST_STEP})
    const progressive_standard = Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP})
    const progressive_premium = Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP})

    const step_list = [
        defaultStepOptions.notesOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.chooseLens.prescriptionType,
                ]
            }
        },
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription,
                ]
            }
        },
        single_vision_distance,
        single_vision_distance_premium,
        single_vision_near,
        single_vision_near_premium,
        progressive_standard,
        progressive_premium,
        lens_type,
        lens_options
    ]
    return step_list
}
