<template>
    <div>
        <table cellspacing="5" class="form-list">
            <tr>
                <td></td>
                <td>
                    <select v-model="selectedYear" class="select">
                        <template v-for="(year, index) in getYears">
                            <option :key="index" :value="year"
                                    v-text="year"></option>
                        </template>
                    </select>
                </td>
            </tr>
            <tr v-for="(redeem, index) in getRedeemsByYear" :key="index">
                <td class="label">{{redeem.type}}</td>
                <td class="value">{{redeem.status}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import HelpBubble from '../../../help_bubble/HelpBubble.vue'

export default {
    name: 'RedeemsHistory',
    components: {
        HelpBubble
    },
    data: () => ({
        selectedYear: null,
    }),
    created() {
        this.selectedYear = new Date().getFullYear()
    },
    computed: {
        ...mapGetters('lensablPlus/membership', ['getRedeemsHistory']),
        getYears() {
            const years = Object.keys(this.getRedeemsHistory)
            this.selectedYear = years[0]
            return years
        },
        getRedeemsByYear() {
            return this.getRedeemsHistory[this.selectedYear] || {}
        }
    },
    methods: {
        getHelper(text) {
            return {
                'name': 'help-bubble',
                'params': {
                    'text': text
                }
            }
        }
    }
}
</script>

