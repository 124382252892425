import { mapGetters } from 'vuex'
import SavedPrescriptions from '../prescriptions/SavedPrescriptions.vue'

export default {
    name: 'UsedSavedRx',
    extends: SavedPrescriptions,
    computed: {
        ...mapGetters('configurator', ['isContactDetailsPage']),
        ...mapGetters('contacts', ['getProductSku']),
        prescription_type () {
            if (this.isContactDetailsPage) {
                return this.getProductSku //  use this as 'prescription_type' value when contacts RX is saved
            } else {
                return this.id
            }
        }
    },
    methods: {
        cachedList () {
            return this.prescriptionService.cachedList(this.prescription_type)
        }
    }
}
