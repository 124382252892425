var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ],
      class: _vm.id
    },
    [
      _vm.config.title
        ? _c("h2", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.title,
                expression: "config.title"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.config.description
        ? _c("div", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.description,
                expression: "config.description"
              }
            ],
            staticClass: "description"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.options
        ? _c(
            "ul",
            { staticClass: "actions-toolbar" },
            [
              _vm._l(_vm.options, function(option, index) {
                return [
                  _c(
                    "li",
                    {
                      key: _vm.config.id + "-option-" + index,
                      staticClass: "col",
                      class: { "has-thumbnail": option.thumbnail }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn-choice",
                          class: {
                            "has-thumbnail": option.thumbnail,
                            active: _vm.activeIndex === option.value
                          },
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              return _vm.setValue(option)
                            }
                          }
                        },
                        [
                          option.thumbnail
                            ? _c("span", {
                                directives: [
                                  {
                                    name: "dompurify-html",
                                    rawName: "v-dompurify-html",
                                    value: option.thumbnail,
                                    expression: "option.thumbnail"
                                  }
                                ],
                                staticClass: "thumbnail",
                                class: option.label
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", {
                            directives: [
                              {
                                name: "dompurify-html",
                                rawName: "v-dompurify-html",
                                value: option.label,
                                expression: "option.label"
                              }
                            ],
                            staticClass: "label"
                          })
                        ]
                      )
                    ]
                  )
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "actions-toolbar" }, [
        _c(
          "div",
          { staticClass: "primary" },
          [
            _c("BaseButton", {
              staticClass: "button--copy-solid-white-blue button--small",
              attrs: { text: "Continue" },
              nativeOn: {
                click: function($event) {
                  return _vm.continueNxt($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }