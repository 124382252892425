var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isStepCurrent
    ? _c("div", { staticClass: "control-container input-container" }, [
        _c(
          "div",
          { staticClass: "contacts_input", class: { active: _vm.firstName } },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.firstName,
                  expression: "firstName"
                }
              ],
              ref: "firstName",
              class: { "invalid-border": _vm.invalidFirst },
              attrs: {
                id: "prescription_firstName",
                type: "text",
                placeholder: "First Name"
              },
              domProps: { value: _vm.firstName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.firstName = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.firstName
              ? _c("label", { staticClass: "contacts_input-label" }, [
                  _vm._v("First Name")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.invalidFirst
              ? _c("div", [
                  _c("span", { staticClass: "validation-message" }, [
                    _vm._v("First Name Required")
                  ])
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "contacts_input", class: { active: _vm.lastName } },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lastName,
                  expression: "lastName"
                }
              ],
              ref: "lastName",
              class: { "invalid-border": _vm.invalidLast },
              attrs: {
                id: "prescription_lastName",
                type: "text",
                placeholder: "Last Name"
              },
              domProps: { value: _vm.lastName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.lastName = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.lastName
              ? _c("label", { staticClass: "contacts_input-label" }, [
                  _vm._v("Last Name")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.invalidLast
              ? _c("div", [
                  _c("span", { staticClass: "validation-message" }, [
                    _vm._v("Last Name Required")
                  ])
                ])
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }