<template>
    <div>
        <PageLoader v-if="getLoadingProducts" :load="getLoadingProducts" />
        <template v-else>
            <HeaderCategory />
            <Breadcrumb />
            <CategoryList v-if="countChildCategories" :categories="getChildCategories" />
            <div v-else class="category-products frames-products" :class="getClass">
                <CatalogProductList :products="currentCategory.products" :load="getLoadingProducts" :grid="true" :category-class="getClass" />
            </div>
        </template>
        <FramesPageSeo v-if="showSeoPage" :brand="getClass"/> 
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import HeaderCategory from '../page/HeaderCategory.vue'
import Breadcrumb from '../page/Breadcrumb.vue'
import PageLoader from '../../loaders/PageLoader.vue'
import CategoryList from '../product/list/CategoryList.vue'
import CatalogProductList from '../product/CatalogProductList.vue'
import category from './storage/category/view'
import _size from 'lodash/size'
import FramesPageSeo from '../../../components/seo/FramesPageSeo.vue'

export default {
    name: 'Category',
    components: {
        HeaderCategory,
        Breadcrumb,
        PageLoader,
        CategoryList,
        CatalogProductList,
        FramesPageSeo
    },
    computed: {
        ...mapState('partner', ['currentCategory']),
        ...mapGetters('partner', ['getChildCategories', 'getLoadingProducts', 'getClass']),
        countChildCategories () {
            return _size(this.getChildCategories)
        },
        showSeoPage () {
            if (this.currentCategory.name === "Hobie Eyewear Optical" || this.currentCategory.name === "Hobie Eyewear Sunglasses") {
                return true
            } else {
                return false
            }
        },
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['category/']) {
            this.$store.registerModule('category', category(this.$store))
        }
        this.$store.dispatch('category/fetchDefaultData')
        this.$store.dispatch('partner/fetchDefaultData')
    },
    created () {
        this.$store.commit('partner/setLoadingProducts', this.currentCategory.id || true)
        this.getCategories()
    },
    methods: {
        ...mapActions('partner', ['getCategories'])
    }
}
</script>
