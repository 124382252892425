import axios from 'axios'
import _findIndex from 'lodash/findIndex'
import * as abstract from '../../../storage/abstract'

const CONTROLLER = 'plus/checkout'

export const PAY_FULL_TODAY_DISCOUNT = 0.25

export const state = store => ({
    ...abstract.state(),
    loadingType: false,
    loadingSignIn: false,
    loadingBilling: false,
    loadingPayment: false,
    loadingSummary: false,
    // Billing
    has_saved_addresses: true,
    default_saved_address_id: '',
    saved_addresses: [],
    saved_addresses_options: [],
    billing_address: {},
    // Account
    signIn: false,
    customer: {
        email: '',
        password: ''
    },
    // LPlusType
    items: [],
    // Payment Methods
    payment_methods: [],
    selected_payment_method: false,
    recurlyPublicKey: false,
    url_set_methods: '',
    stripePaymentRequest : null,
    stripeCustomerSecret : null,
    stripetPaymentIntent : null,
    affirmPrice: 0,
    // Summary
    totals_html: '',
    quarterly_items: {},
    // FORM
    url_form: '',
    invalid_form: false,
    addressErrors: {
        telephone: false,
        first_name: false,
        last_name: false,
        address1: false,
        address2: false,
        city: false,
        postal_code: false,
        region: false,
        state: false,
        email: false,
        payment_method: false,
        password: false
    },
    isPlusCustomer: false,
    plusCustomers: {},
    isPlusQuote: false,
    // Discount
    giftcards: [],
    coupons: [],
    totalAnnualDiscount: store.getters.rootState['totalAnnualDiscount'] || PAY_FULL_TODAY_DISCOUNT,
    api: {
        urls: {
            isCustomerAuth: '/opternative/index/isCustomerAuth',
            getCustomerData: '/opternative/index/getCustomerData',
            getAddresses: `/${CONTROLLER}/getAddresses`,
            getQuoteItems: `/${CONTROLLER}/getQuoteItems`
        }
    }
})

export const getters = {
    LOADING_TYPE: state => state.loadingType,
    LOADING_SIGN_IN: state => state.loadingSignIn,
    LOADING_BILLING: state => state.loadingBilling,
    LOADING_PAYMENT: state => state.loadingPayment,
    LOADING_SUMMARY: state => state.loadingSummary,
    // Billing
    HAS_SAVED_ADDRESSES: state => state.has_saved_addresses,
    DEFAULT_SAVED_ADDRESS_ID: state => state.default_saved_address_id,
    SAVED_ADDRESSES: state => state.saved_addresses,
    SAVED_ADDRESSESES_OPTIONS: state => state.saved_addresses_options,
    BILLING_ADDRESS: state => state.billing_address,
    // Account
    SIGN_IN: state => state.signIn,
    CUSTOMER: state => state.customer,
    // LPlusType
    ITEMS: state => state.items,
    // Payment Methods
    PAYMENT_METHODS: state => state.payment_methods,
    SELECTED_PAYMENT_METHOD: state => state.selected_payment_method,
    RECURLY_PUBLIC_KEY: (state, getters, rootState, rootGetters) => {
        if (!state.recurlyPublicKey) {
            state.recurlyPublicKey = rootGetters.rootState ? rootGetters.rootState.recurlyPublicKey : ''
        }
        return state.recurlyPublicKey
    },
    stripePubKey: (state, getters, rootState, rootGetters) => {
        return  rootGetters.rootState ? rootGetters.rootState.stripePubKey : ''
    },
    getPaymentRequestStripe: state => state.stripePaymentRequest,
    getStripeCustomerSecret: state => state.stripeCustomerSecret,
    getStripePaymentIntent: state => state.stripetPaymentIntent,
    getAffirmPrice: state => state.affirmPrice,
    URL_SET_METHODS: (state, getters, rootState, rootGetters) => {
        if (!state.url_set_methods) {
            state.url_set_methods = rootGetters.rootState ? rootGetters.rootState.url_set_methods : ''
        }
        return state.url_set_methods
    },
    // Summary
    getFormatPrice: () => price => `<span class="price">$${price}</span>`,
    TOTALS_HTML: state => state.totals_html,
    QUARTERLY_ITEMS: state => state.quarterly_items,
    // FORM
    URL_FORM: (state, getters, rootState, rootGetters) => {
        if (!state.url_form) {
            state.url_form = rootGetters.rootState ? rootGetters.rootState.url_form : ''
        }
        return state.url_form
    },
    INVALID_FORM: state => state.invalid_form,
    ADDRESS_FORM_ERRORS: state => state.addressErrors,
    isPlusCustomer: state => state.isPlusCustomer,
    isPlusQuote: state => state.isPlusQuote,
    placeOrderDisabled:  (state, getters, rootState, rootGetters) => {
        const trialExpiringData = rootGetters['lensablplus_customer/getTrialExpiringData'] || {}
        let isRenewQuote = false
        if (Object.keys(trialExpiringData).length) {
            isRenewQuote = trialExpiringData.before_renew_days >= rootGetters['lensablplus_customer/getDaysBeforeDeleting']
        }
        return (state.isPlusCustomer && !isRenewQuote && state.isPlusQuote)
    },
    getPlusCustomerByEmail: state => email => state.plusCustomers.hasOwnProperty(email) ? state.plusCustomers[email] : false,
    IS_QUARTERLY_PAY: (state, getters) => {
        for (const item in getters['QUARTERLY_ITEMS']) {
            if (getters['QUARTERLY_ITEMS'][item] === 1) {
                return true
            }
        }
        return false
    },
    // Discount
    GIFTCARDS: state => state.giftcards,
    COUPONS: state => state.coupons
}

export const mutations = {
    ...abstract.mutations,
    SET_LOADING_TYPE: (state, loadingType) => state.loadingType = loadingType,
    SET_LOADING_SIGN_IN: (state, loadingSignIn) => state.loadingSignIn = loadingSignIn,
    SET_LOADING_BILLING: (state, loadingBilling) => state.loadingBilling = loadingBilling,
    SET_LOADING_PAYMENT: (state, loadingPayment) => state.loadingPayment = loadingPayment,
    SET_LOADING_SUMMARY: (state, loadingSummary) => state.loadingSummary = loadingSummary,
    // Billing
    SET_HAS_SAVED_ADDRESSES: (state, payload) => state.has_saved_addresses = payload,
    SET_DEFAULT_SAVED_ADDRESS_ID: (state, payload) => state.default_saved_address_id = payload,
    SET_SAVED_ADDRESSES: (state, payload) => state.saved_addresses = payload,
    SET_SAVED_ADDRESSESES_OPTIONS: (state, payload) => state.saved_addresses_options = payload,
    SET_BILLING_ADDRESS: (state, payload) => state.billing_address = payload,
    // Account
    SET_SIGN_IN: (state, payload) => state.signIn = payload,
    SET_CUSTOMER: (state, payload) => state.customer = payload,
    // LPlusType
    SET_ITEMS: (state, payload) => state.items = payload,
    // Payment Methods
    SET_PAYMENT_METHODS: (state, payload) => state.payment_methods = payload,
    SET_SELECTED_PAYMENT_METHOD: (state, payload) => state.selected_payment_method = payload,
    setStripePaymentRequest:(state, payload) => state.stripePaymentRequest = payload,
    setStripeCustomerSecret:(state, payload) => state.stripeCustomerSecret = payload,
    setStripePaymentIntent:(state, payload) => state.stripetPaymentIntent = payload,
    setAffirmPrice:(state, payload) => state.affirmPrice = payload,
    // Summary
    SET_TOTALS_HTML: (state, payload) => state.totals_html = payload,
    SET_QUARTERLY_ITEMS: (state, payload) => state.quarterly_items = payload,
    // FORM
    SET_INVALID_FORM: (state, payload) => state.invalid_form = payload,
    SET_ADDRESS_FORM_ERRORS: (state, payload) => state.addressErrors = payload,
    IS_PLUS_CUSTOMER: (state, plus = false) => state.isPlusCustomer = Boolean(plus),
    IS_PLUS_QUOTE: (state, plus = false) => state.isPlusQuote = Boolean(plus),
    SET_PLUS_CUSTOMER: (state, {email = '', plus = false}) => state.plusCustomers[email] = Boolean(plus),
    // Discount
    SET_GIFTCARDS: (state, payload = []) => state.giftcards = payload,
    SET_COUPONS: (state, payload = []) => state.coupons = payload
}

export const actions = {
    ...abstract.actions,
    RELOAD: ({dispatch}) => {
        dispatch('GET_ITEMS')
        dispatch('GET_SIGN_IN')
        dispatch('GET_ADDRESSES')
        dispatch('GET_PAYMENT_METHODS')
        dispatch('GET_TOTALS')
    },
    GET_ADDRESSES: ({state, commit}) => {
        commit('SET_HAS_SAVED_ADDRESSES', false)
        commit('SET_LOADING_BILLING', true)
        return axios.get(state.api.urls.getAddresses).then(({data, status}) => {
            if (status === 200) {
                commit('SET_SAVED_ADDRESSESES_OPTIONS', data.options)
                commit('SET_SAVED_ADDRESSES', data.addresses)
                commit('SET_DEFAULT_SAVED_ADDRESS_ID', data.default_value)
                if (data.options.length) {
                    commit('SET_HAS_SAVED_ADDRESSES', true)
                } else {
                    commit('SET_HAS_SAVED_ADDRESSES', false)
                }
                commit('SET_BILLING_ADDRESS', data.address)
                commit('SET_LOADING_BILLING', false)
            }
        }).catch(error => {
            console.error(error)
            commit('SET_LOADING_BILLING', false)
        })
    },
    GET_SIGN_IN: ({state, getters, commit}) => {
        commit('SET_LOADING_SIGN_IN', true)
        axios.get(state.api.urls.isCustomerAuth).then(({data, status}) => {
            if (status === 200) {
                commit('SET_SIGN_IN', data.sign_in)
            }
            if (getters['SIGN_IN']) {
                axios.get(state.api.urls.getCustomerData).then(({data, status}) => {
                    if (status === 200) {
                        if (data.email) {
                            const customer = getters['CUSTOMER']
                            customer.email = data.email
                            commit('SET_CUSTOMER', customer)
                        }
                    }
                })
            }
            commit('SET_LOADING_SIGN_IN', false)
        }).catch(error => {
            console.error(error)
            commit('SET_SIGN_IN', false)
            commit('SET_LOADING_SIGN_IN', false)
        })
    },
    GET_ITEMS: ({state, commit}) => {
        commit('SET_LOADING_TYPE', true)
        axios.get(state.api.urls.getQuoteItems).then(({data, status}) => {
            if (status === 200) {
                commit('SET_ITEMS', data)
                commit('SET_LOADING_TYPE', false)
                const quarterlyItems = {}
                data.forEach((item) => {
                    quarterlyItems[item.id] = item.quarterly ? 1 : 0
                })
                commit('SET_QUARTERLY_ITEMS', quarterlyItems)
            }
        }).catch(error => {
            console.error(error)
            commit('SET_LOADING_TYPE', false)
        })
    },
    GET_PAYMENT_METHODS: ({getters, commit, rootGetters}) => {
        commit('SET_LOADING_PAYMENT', true)
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/getPaymentMethod`
        axios.get(url).then(({data, status}) => {
            if (status === 200) {
                commit('SET_PAYMENT_METHODS', data.payment_methods)
                commit('SET_SELECTED_PAYMENT_METHOD', data.payment_method)
                commit('SET_LOADING_PAYMENT', false)
            }
        }).catch(error => {
            console.error(error)
            commit('SET_LOADING_PAYMENT', false)
        })
    },
    SET_PAYMENT_METHOD: ({getters}) => {
        const params = new URLSearchParams();
        params.append('payment_method', getters['SELECTED_PAYMENT_METHOD']);
        axios.post(getters['URL_SET_METHODS'], params)
            .catch(error => {
                console.error(error)
            })
    },
    GET_TOTALS: ({commit, rootGetters}) => {
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/getTotals`
        axios.get(url).then(({data, status}) => {
            if (status === 200) {
                commit('SET_LOADING_SUMMARY', false)
                commit('SET_TOTALS_HTML', data.totals_html)
                commit('setAffirmPrice', data.affirm_price)
            }
        }).catch(error => {
            console.error(error)
            commit('SET_LOADING_SUMMARY', false)
        })
    },
    CHANGE_PAY_PERIOD: ({getters, dispatch, rootGetters}) => {
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/changePayPeriod`
        axios.post(url, {'items': getters['QUARTERLY_ITEMS']}).then(({data, status}) => {
            if (status === 200) {
                dispatch('GET_PAYMENT_METHODS')
                dispatch('GET_TOTALS')
            }
        }).catch(error => {
            console.error(error)
        })
    },
    VALIDATION_FORM_ADDRESS: ({getters, commit}) => {
        const validFields = getters['ADDRESS_FORM_ERRORS']
        let vForm = getters['BILLING_ADDRESS']
        if (!getters['SIGN_IN']) {
            vForm = Object.assign(vForm, getters['CUSTOMER'])
        }
        let invalidForm = false
        Object.keys(vForm).map(item => {
            if (item === 'password') return
            if (item === 'address2') return
            if (vForm[item] === '' || !vForm[item]) {
                validFields[item] = true
                invalidForm = true
            } else {
                validFields[item] = false
            }
        })
        commit('SET_ADDRESS_FORM_ERRORS', validFields)
        commit('SET_INVALID_FORM', invalidForm)
    },
    VALIDATION_FORM: ({getters, dispatch, commit}) => {
        dispatch('VALIDATION_FORM_ADDRESS')
        if (!getters['SELECTED_PAYMENT_METHOD'] || getters['SELECTED_PAYMENT_METHOD'] === ''){
            commit('SET_INVALID_FORM', true)
        }
    },
    checkIsPlusCustomer: ({commit, getters, rootGetters}, email) => {
        return getters.getPlusCustomerByEmail(email) || axios.post(`${rootGetters['storeView/base_url']}/plus/ajax/isPlusCustomerByEmal`, {email}).then(({data, status}) => {
            if (status === 200) {
                commit('SET_PLUS_CUSTOMER', {email, plus: data.plus})
                commit('IS_PLUS_QUOTE', data.plusItem)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    fetchIsPlusCustomer: ({commit, getters, rootGetters}) => {
        return getters.isPlusCustomer || axios.get(`${rootGetters['storeView/base_url']}/plus/vision_plan/isPlusCustomer`).then(({data, status}) => {
            if (status === 200) {
                commit('IS_PLUS_CUSTOMER', data.plus)
                commit('IS_PLUS_QUOTE', data.plusItem)
            }
        })
    },
    SAVE_BILLING: ({getters, commit, dispatch, rootGetters}) => {
        const billingAddress = getters['BILLING_ADDRESS']
        const checkoutForm = document.getElementById('onestepcheckout-form')
        const regionId = _findIndex(rootGetters['onlineExam/REGIONS'], {'value': billingAddress.state})
        const formData = new FormData(checkoutForm)
        const params = new URLSearchParams()

        params.append('billing[postcode]', billingAddress.postal_code)
        for (let [key, val] of formData.entries()) {
            if (key.indexOf('city') !== -1) {
                val = billingAddress.city
            }
            if (key.indexOf('postcode') !== -1) {
                val = billingAddress.postal_code
            }
            if (key.indexOf('[region]') !== -1) {
                val = billingAddress.state
            }
            if (key.indexOf('[street][0]') !== -1) {
                val = billingAddress.address1
            }
            if (key.indexOf('[street][1]') !== -1) {
                val = billingAddress.address2 ? billingAddress.address2 : ''
            }
            params.append(key, val)
        }

        params.append('billing[region_id]', regionId + 1)

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const url = `${rootGetters['storeView/base_url']}onepage/ajax/save_billing`
        axios.post(url, params, config).then(({data, status}) => {
            if (status === 200) {
                dispatch('GET_TOTALS')
                dispatch('GET_PAYMENT_METHODS')
            }
        }).catch(error => {
            console.error(error)
        })
    },
    GET_GIFTCARDS: ({getters, commit, rootGetters}) => {
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/getCheckoutGiftcard`
        axios.get(url).then(({data, status}) => {
            if (status === 200 && data.giftcards) {
                commit('SET_GIFTCARDS', data.giftcards)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    ADD_GIFTCARD: ({getters, commit, dispatch, rootGetters}, {setObject, alertMsg = true}) => {
        const url = `${rootGetters['storeView/base_url']}lensablall/gift/add_code`
        const params = new URLSearchParams()
        for (const key in setObject) {
            params.append(key, setObject[key].trim())
        }
        axios.post(url, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                if (!data.error) {
                    dispatch('GET_TOTALS')
                    dispatch('GET_GIFTCARDS')
                    dispatch('GET_PAYMENT_METHODS')
                }
                if (data.message && alertMsg) {
                    alert(data.message)
                }
            }
        }).catch(error => {
            console.error(error)
        })
    },
    GET_COUPONS: ({getters, commit, rootGetters}) => {
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/getCheckoutCoupons`
        axios.get(url).then(({data, status}) => {
            if (status === 200 && data.coupons) {
                commit('SET_COUPONS', data.coupons)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    ADD_COUPON: ({getters, commit, dispatch, rootGetters}, {setObject, alertMsg = true}) => {
        const url = `${rootGetters['storeView/base_url']}onepage/ajax/add_coupon`
        const params = new URLSearchParams()
        for (const key in setObject) {
            params.append(key, setObject[key].trim())
        }
        axios.post(url, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                if (!data.error) {
                    dispatch('GET_COUPONS')
                    dispatch('GET_TOTALS')
                    dispatch('GET_PAYMENT_METHODS')
                }
                if (data.message && alertMsg) {
                    alert(data.message)
                }
            }
        }).catch(error => {
            console.error(error)
        })
    },
    cancelLinneoRedemption: ({getters, commit, dispatch, rootGetters}) => {
        const url = `${rootGetters['storeView/base_url']}lensablall/api_linneo/remove`
        axios.get(url).then(({data, status}) => {
            if (status === 200) {
                dispatch('RELOAD')
            }
        }).catch(error => {
            console.error(error)
        })
    },
    fetchPaymentRequest: ({commit, rootGetters}) => {
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/getStripePaymentRequest`
        return axios.get(url).then(({data, status}) => {
            if (status === 200) {
                commit('setStripePaymentRequest', data.payment_request)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    fetchClientSecret: ({commit, rootGetters}) => {
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER}/getStripeCustomerSecret`
        return axios.get(url).then(({data, status}) => {
            if (status === 200) {
                commit('setStripeCustomerSecret', data.stripe_customer_secret)
                commit('setStripePaymentIntent', data.stripe_pi)
            }
        }).catch(error => {
            console.error(error)
        })
    },
}

export default (store = {}) => ({
    namespaced: true,
    state: state(store),
    getters,
    mutations,
    actions
})
