<template>
    <div v-show="isCurrent" :class="id">
        <h2 v-if="config.title" v-dompurify-html="config.title" class="title"></h2>
        <div v-if="config.description" v-dompurify-html="config.description" class="description"></div>
        <ul v-if="options" class="actions-toolbar-radio">
            <template v-for="(option, index) in options">
                <li :key="`${config.id}-option-${index}`" :class="{'has-thumbnail': option.thumbnail}" class="row">
                    <RadioLabel :active="currentValue === option.value" @click="setTypeOfEye(option)">
                        <span v-dompurify-html="option.label"></span>
                    </RadioLabel>
                </li>
            </template>
        </ul>
        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton class="button--copy-solid-white-blue button--small" text="Continue" @click.native="setValue" />
            </div>
        </div>
    </div>
</template>

<script>
import _isArray from 'lodash/isArray'
import _first from 'lodash/first'
import _find from 'lodash/find'
import Choice from './Choice.vue'
import RadioLabel from '../../base/RadioLabel.vue'
import BaseButton from '../../base/BaseButton.vue'

export default {
    name: 'ChoiceRadio',
    components: {
        RadioLabel,
        BaseButton
    },
    extends: Choice,
    data: () => ({
        currentValue: ''
    }),
    watch: {
        options (options) {
            this.checkCurrentValue(options)
        }
    },
    mounted () {
        this.checkCurrentValue(this.options)
    },
    methods: {
        checkCurrentValue (options) {
            if (_isArray(options) && !_find(options, option => this.currentValue === option.value)) {
                this.currentValue = _first(options).value
            }
        },
        setTypeOfEye (option) {
            this.currentValue = option.value
        },
        setValue () {
            if (this.currentValue) {
                this.$store.commit('lensablPlus/COMPONENT_DATA', {id: this.id, value: this.currentValue, isValidValue: true})
            }
        }
    }
}
</script>
