var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.LOADING_TYPE
        ? _c("Loader")
        : _c("div", [
            _c(
              "div",
              _vm._l(_vm.ITEMS, function(item, index) {
                return _c("div", { key: index }, [
                  _c("div", { staticClass: "plus-type-content" }, [
                    _c("p", [
                      _vm._v("Subscription Type: "),
                      _c("span", { staticClass: "selected-type" }, [
                        _vm._v(_vm._s(item.subscription_type))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("Glasses RX Type: "),
                      _c("span", { staticClass: "selected-type" }, [
                        _vm._v(_vm._s(item.glasses_rx_type))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  item.contacts
                    ? _c(
                        "div",
                        { staticClass: "plus-type-contacts" },
                        [
                          _c("p", [_vm._v("Your Contacts:")]),
                          _vm._v(" "),
                          _vm._l(item.contacts, function(contact, key) {
                            return _c(
                              "div",
                              { key: key, class: ["plus-" + key] },
                              [
                                _c(
                                  "div",
                                  { staticClass: "plus-contact-container" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: contact.image,
                                        title: contact.name
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("div", [
                                      key.indexOf("right") !== -1
                                        ? _c(
                                            "h6",
                                            { staticClass: "product-name" },
                                            [_vm._v("Right Eye - OD")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      key.indexOf("left") !== -1
                                        ? _c(
                                            "h6",
                                            { staticClass: "product-name" },
                                            [_vm._v("Left Eye - OS")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("h6", {
                                        directives: [
                                          {
                                            name: "dompurify-html",
                                            rawName: "v-dompurify-html",
                                            value:
                                              contact.name +
                                              ", " +
                                              contact.second_title,
                                            expression:
                                              "`${contact.name}, ${contact.second_title}`"
                                          }
                                        ],
                                        staticClass: "product-title"
                                      })
                                    ])
                                  ]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ])
              }),
              0
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }