import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'

export const vuePersist = {
    key: 'store',
    reducer: state => {
        const reducer = Object.assign({}, {
            contacts: {
                ...state.contacts,
                api: {}
            },
            prescriptions: {
                ...state.prescriptions,
                api: {}
            },
            lensablplus_customer: {
                ...state.lensablplus_customer,
                api: {}
            }
        })
        delete reducer.contacts.api.urls
        delete reducer.prescriptions.api.urls
        delete reducer.lensablplus_customer.api.urls

        if (state.adminHtml) {
            return {}
        }

        return reducer
    },
    storage: {
        getItem: key => sessionStorage.getItem(key),
        setItem: (key, value) => sessionStorage.setItem(key, value),
        removeItem: key => {
            sessionStorage.removeItem(key)
        }
    }
}

export default createPersistedState(vuePersist)
