<template>
    <div class="prescription-table-wrapper">
        <table v-if="!isMobile" class="params-prescription-table">
            <colgroup>
                <col width="17.5%" />
                <col width="20%" />
                <col width="20%" />
                <col width="20%" />
                <col v-if="showAdd" width="20%" />
            </colgroup>
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <span>Sphere (SPH)</span>
                        <HelpBubble :data="help.sphere" />
                    </th>
                    <th>
                        <span>Cylinder (CYL)</span>
                        <HelpBubble :data="help.cylinder" />
                    </th>
                    <th>
                        <span>Axis</span>
                        <HelpBubble :data="help.axis" />
                    </th>
                    <th v-if="showAdd">
                        <span>Add</span>
                        <HelpBubble :data="help.add" />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Right Eye (OD)</th>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.od_sphere" :options="eyeSphereOptions" @change="setPrescriptionValue($event.target.value, 'od_sphere')" />
                        <span v-else v-text="value.od_sphere"></span>
                    </td>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.od_cylinder" :options="eyePowerOptions" @change="setPrescriptionValue($event.target.value, 'od_cylinder')" />
                        <span v-else v-text="value.od_cylinder"></span>
                    </td>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.od_axis" :options="eyeAxisOptions" @change="setPrescriptionValue($event.target.value, 'od_axis')" />
                        <span v-else v-text="value.od_axis"></span>
                    </td>
                    <td v-if="showAdd">
                        <CustomSelect v-if="showEditVersion" :value="value.od_add" :options="eyeAddOptions" @change="setPrescriptionValue($event.target.value, 'od_add')" />
                        <span v-else v-text="value.od_add"></span>
                    </td>
                </tr>
                <tr>
                    <th>Left Eye (OS)</th>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.os_sphere" :options="eyeSphereOptions" @change="setPrescriptionValue($event.target.value, 'os_sphere')" />
                        <span v-else v-text="value.os_sphere"></span>
                    </td>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.os_cylinder" :options="eyePowerOptions" @change="setPrescriptionValue($event.target.value, 'os_cylinder')" />
                        <span v-else v-text="value.os_cylinder"></span>
                    </td>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.os_axis" :options="eyeAxisOptions" @change="setPrescriptionValue($event.target.value, 'os_axis')" />
                        <span v-else v-text="value.os_axis"></span>
                    </td>
                    <td v-if="showAdd">
                        <CustomSelect v-if="showEditVersion" :value="value.os_add" :options="eyeAddOptions" @change="setPrescriptionValue($event.target.value, 'os_add')" />
                        <span v-else v-text="value.os_add"></span>
                    </td>
                </tr>
            </tbody>
        </table>

        <table v-else class="params-prescription-table">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        Right Eye (OD)
                    </th>
                    <th>
                        Left Eye (OS)
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        <span>SPH</span>
                        <HelpBubble :data="help.sphere" />
                    </th>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.od_sphere" :options="eyeSphereOptions" @change="setPrescriptionValue($event.target.value, 'od_sphere')" />
                        <span v-else v-text="value.od_sphere"></span>
                    </td>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.os_sphere" :options="eyeSphereOptions" @change="setPrescriptionValue($event.target.value, 'os_sphere')" />
                        <span v-else v-text="value.os_sphere"></span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>CYL</span>
                        <HelpBubble :data="help.cylinder" />
                    </th>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.od_cylinder" :options="eyePowerOptions" @change="setPrescriptionValue($event.target.value, 'od_cylinder')" />
                        <span v-else v-text="value.od_cylinder"></span>
                    </td>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.os_cylinder" :options="eyePowerOptions" @change="setPrescriptionValue($event.target.value, 'os_cylinder')" />
                        <span v-else v-text="value.os_cylinder"></span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span>Axis</span>
                        <HelpBubble :data="help.axis" />
                    </th>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.od_axis" :options="eyeAxisOptions" @change="setPrescriptionValue($event.target.value, 'od_axis')" />
                        <span v-else v-text="value.od_axis"></span>
                    </td>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.os_axis" :options="eyeAxisOptions" @change="setPrescriptionValue($event.target.value, 'os_axis')" />
                        <span v-else v-text="value.os_axis"></span>
                    </td>
                </tr>
                <tr v-if="showAdd">
                    <th>
                        <span>Add</span>
                        <HelpBubble :data="help.add" />
                    </th>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.od_add" :options="eyeAddOptions" @change="setPrescriptionValue($event.target.value, 'od_add')" />
                        <span v-else v-text="value.od_add"></span>
                    </td>
                    <td>
                        <CustomSelect v-if="showEditVersion" :value="value.os_add" :options="eyeAddOptions" @change="setPrescriptionValue($event.target.value, 'os_add')" />
                        <span v-else v-text="value.os_add"></span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-if="showError" class="error-text-rx">
            <span v-text="errorText"></span>
        </div>
    </div>
</template>

<script>
import CommonPrescriptionModel from '../../app/common/prescription_model'
import HelpBubble from '../help_bubble/HelpBubble.vue'
import CustomSelect from '../../components/base/Select.vue'

import {mapGetters} from 'vuex'

export default {
    name: 'PrescriptionTable',
    components: {
        HelpBubble,
        CustomSelect
    },
    extends: CommonPrescriptionModel,
    props: {
        showEditVersion: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Array, Object],
            default: () => {}
        },
        help: {
            type: [Array, Object],
            default: () => {}
        },
        showError: {
            type: [Boolean],
            default: false
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobile'])
    },
    methods: {
        setPrescriptionValue (val, key) {
            this.$set(this.value, key, val)
        }
    }
}
</script>
