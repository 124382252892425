var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.brand + "-seo-parent-container"] }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-prescription-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-prescription" }, [
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Ray-Ban Glasses")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Lensabl has eyeglass frames from Ray-Ban and other leading designers. Complete your Ray-Ban frames with an accurate set of prescription lenses. Shop for well-made and stylish frames that look great, feel comfortable and offer the durability your life demands. Lensabl is the best place to find men's and women's Ray-Ban frames online. "
            ),
            _c("a", { attrs: { href: "/prescription-scanner-app/qr" } }, [
              _c("span", { staticClass: "seo-contact-link" }, [
                _vm._v("Scan your prescription")
              ])
            ]),
            _vm._v(" and order your new frames today.")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-img" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-frames-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-frames" }, [
        _c("div", { staticClass: "shopframes-seo-frames-img" }),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Ray-Bans for Your Prescription")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Lensabl's Ray-Ban prescription glasses will satisfy your exact optical needs. Our site makes it easy to search lenses by your prescription and match them with a set of Ray-Ban frames."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "Lensabl carries various types of prescription lenses, including single vision, bifocal, progressive and more. You can use Lensabl to "
            ),
            _c("a", { attrs: { href: "/online-vision-prescription" } }, [
              _c("span", { staticClass: "seo-contact-link" }, [
                _vm._v("renew your prescription online")
              ])
            ]),
            _vm._v(
              " and shop a wide selection of Ray-Ban glasses that will improve your vision."
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-designer-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-designer" }, [
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Durable Eyeglass Frames for All Styles")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "No matter your aesthetic preferences, you can match your prescription lenses with Ray-Ban frames that complement your taste. We carry the most popular styles, including Wayfarer, Clubmaster and Erika. Browse our selection of black, blue and red Ray-Ban prescription glasses, plus various other colors and patterns that will show your personality."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "Ray-Ban frames are available in numerous durable, lightweight materials like acetate, metal, plastic, propionate, steel and titanium. We encourage you to use the Lensabl size finder tool to choose a frame that looks great and fits comfortably all day.\n                 "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-designer-img" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-eyeglass-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-eyeglass" }, [
        _c("div", { staticClass: "shopframes-seo-text seo-eyeglass-text" }, [
          _c("h2", [_vm._v("Buy Ray-Ban Frames Online ")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Looking for fashionable prescription Ray-Bans? Lensabl puts any lens in any frame. Designers like Ray-Ban produce stylish eyewear, and with Lensabl, your glasses can offer pristine optical clarity. Order Ray-Ban frames from Lensabl for free standard shipping, a 30-day, risk-free return period and a 12-month warranty. You'll experience even more perks when you "
            ),
            _c("a", { attrs: { href: "/plus" } }, [
              _c("span", { staticClass: "seo-contact-link" }, [
                _vm._v("become a Lensabl+ member")
              ])
            ]),
            _vm._v(". Register for our online vision plan today.")
          ])
        ]),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "/glasses-frames", title: "Glasses Frames" } },
          [
            _c("div", { staticClass: "button button--copy-solid" }, [
              _c("div", { staticClass: "button__container" }, [
                _vm._v("GET NEW FRAMES")
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }