import _map from 'lodash/map'
import _cloneDeep from 'lodash/cloneDeep'

let stateWasReset = false

export default {
    namespaced: true,
    state: {
        data: ''
    },
    getters: {
        subscriptionView: state => state
    },
    mutations: {
        RESET_SUBSCRIPTION_STATE: (state, newState) => {
            _map(_cloneDeep(newState), (value, key) => {
                state[key] = value
            })
            stateWasReset = true
        }
    },
    actions: {
        resetSubscriptionState: ({commit, rootGetters}) => commit('RESET_SUBSCRIPTION_STATE', rootGetters.rootState),
        fetchSubscriptionState: ({getters, dispatch}) => {
            if (!stateWasReset) {
                dispatch('resetSubscriptionState')
            }
            return getters.subscriptionView
        }
    }
}
