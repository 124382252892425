<template>
    <div class="upload-component">
        <div v-show="hasNoFile" :class="{'invalid-form': showInvalidFormBorder}" class="file-input-box">
            <input type="text" disabled="disabled" class="file-input" placeholder="No file chosen" />
            <button type="button" title="Continue" class="action primary file-input-button">
                <span>Browse...</span>
                <input type="file" name="prescription" accept="image/*,application/pdf" @change="previewFiles" />
            </button>
        </div>

        <div v-if="showError" class="error-hint">
            <div v-if="showError" class="error-hint">
                <span v-dompurify-html="errorHint"></span>
            </div>
        </div>

        <div v-if="hasFile" class="uploaded-file">
            <div class="option-checkbox-container active"><div class="option-checkbox"></div></div>
            <span v-dompurify-html="fileData.fileName"></span>
            <a href="javascript:void(0)" class="remove-link" @click="removeFile">Remove</a>
        </div>

        <div v-if="isUploading">
            <div class="uploading-progress">Uploading...</div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import axios from 'axios'
import _assign from 'lodash/assign'
import {MESSAGE_VALIDATE_UPLOAD} from '../../../app/utils/constants'
import prescriptions from '../../prescriptions/storage/prescriptions'
import prescriptionsUpload from '../../prescriptions/storage/prescriptions/upload'

export default {
    name: 'PrescriptionUploadAbstract',
    data: () => ({
        loader: false,
        isUploading: false,
        isValidationFired: false,
        showError: false,
        fileData: {
            file: '',
            dataURL: '',
            fileName: ''
        },
        errorHint: 'The image you’ve attempted to upload is too large, please retry with a smaller file size. <br /> You can also contact <a href="mailto: sales@lensabl.com">sales@lensabl.com</a> with your photo and order number.',
        uploadData: {}
    }),
    computed: {
        ...mapGetters('values', ['values']),
        ...mapGetters('storeView', ['base_url', 'form_key']),
        ...mapGetters('prescriptions/upload', ['uploadUrl', 'dataURL', 'fileName']),
        isValidForm () {
            return !!this.fileData.dataURL
        },
        hasNoFile () {
            return !this.isValidForm && !this.isUploading
        },
        hasFile () {
            return this.isValidForm && !this.isUploading
        },
        showInvalidFormBorder () {
            return !this.isValidForm && this.isValidationFired
        },
        sendToServerData () {
            const {name, size, type} = this.fileData.file
            return {
                name,
                size,
                type,
                data: this.fileData.dataURL
            }
        }
    },
    created () {
        this.$bus.$on(MESSAGE_VALIDATE_UPLOAD, this._validateUpload)
    },
    beforeDestroy () {
        this.$bus.$off(MESSAGE_VALIDATE_UPLOAD, this._validateUpload)
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['prescriptions/upload/']) {
            this.$store.registerModule(['prescriptions', 'upload'], prescriptionsUpload(this.$store))
        }
        this.$nextTick(() => {
            this.$store.dispatch('prescriptions/upload/fetchDefaultData')
        })
    },
    methods: {
        ...mapActions('prescriptions/upload', ['updateDataUrl', 'updateFileName']),
        sendToServer (object) {
            this.loader = true
            this.isUploading = true

            return axios.post(this.uploadUrl, object, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(({data}) => {
                    const {name, hash = false} = data
                    this.updateDataUrl(hash)
                    this.updateFileName(name)
                    this.fileData.fileName = name
                    this.isUploading = false
                    this.loader = false

                    _assign(this.uploadData, object, data)
                })
                .catch((error) => {
                    this.showError = true
                    this.isUploading = false
                    this.removeFile()
                    console.error(error)
                })
        },
        validate (resolve, reject) {
            if (this.isValidForm) {
                resolve()
            } else {
                reject()
            }
        },
        removeFile (event) {
            if (event) {
                event.preventDefault()
            }
            this.updateDataUrl()
            this.updateFileName()
            this.fileData.file = ''
            this.fileData.dataURL = ''
            this.fileData.fileName = ''
        },
        previewFiles (event) {
            console.log('===uploaded===')
            event.preventDefault()
            const files = event.target.files
            if (files.length) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.fileData.dataURL = reader.result
                    if (this.fileData.dataURL) {
                        this.showError = false
                        this.sendToServer(this.sendToServerData)
                    }
                }

                if (files[0]) {
                    this.fileData.file = files[0]
                    reader.readAsDataURL(files[0])
                }
            }
        },
        _validateUpload (promise, resolve, reject) {
            this.isValidationFired = true
            this.validate(resolve, reject)
        }
    }
}
</script>
