var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", {
        directives: [
          {
            name: "dompurify-html",
            rawName: "v-dompurify-html",
            value: _vm.renderer,
            expression: "renderer"
          }
        ],
        staticClass: "option-value"
      }),
      _vm._v(" "),
      _c("RendererUpgrades", {
        class: "upgrades",
        attrs: { step: _vm.step, place: _vm.place }
      }),
      _vm._v(" "),
      _c("RendererWarranty", {
        class: "warranty",
        attrs: { step: _vm.step, place: _vm.place }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }