import {
    STATE_CURRENT,
    STATE_READY,
    STATE_WAIT
} from '../../../../app/utils/constants'
import {
    OBJECTS_CHAIN
} from '../constants/settings'

import axios from 'axios'
import Vue from 'vue'
import _assign from 'lodash/assign'
import _find from 'lodash/find'
import _first from 'lodash/first'
import _filter from 'lodash/filter'
import _extend from 'lodash/extend'
import _isArray from 'lodash/isArray'
import _isEqual from 'lodash/isEqual'
import _map from 'lodash/map'
import _size from 'lodash/size'
import _cloneDeep from 'lodash/cloneDeep'
import _isString from 'lodash/isString'
import {
    COMPONENT_ID_GLASSES,
    COMPONENT_ID_PLAN_TYPE,
    COMPONENT_ID_SEARCH,
    GLASSES_ONLY_VALUE,
    GLASSES_AND_CONTACTS_VALUE,
    PROGRESSIVE_VALUE,
    SINGLE_VISION_VALUE,
    CONTACTS_VALUE,
    getObjectList
} from '../constants/objectList'
import {
    URL_KEY_LENS_TYPE
} from '../../../contacts/storage/contacts'
import {PAY_FULL_TODAY_DISCOUNT} from '../../../checkout/storage/lensablplus'
import {CONTACTS_SUBSCRIPTIONS_FREQUENCIES} from '../../../contacts/product/storage/product/view'
import getPlansInfo from '../constants/plans'
import * as abstract from '../../../../storage/abstract'

export const EVENT_ADD_CONTACTS_TO_EYE = 'contacts:add-to-eye'
export const EVENT_CONTACT_LENSES_FOUND = 'contacts:lenses-found'

export const PLAN_INTERFACE = {
    absolute_price: '',
    entity_id: '',
    full_price: '',
    has_contacts: false,
    membership_type: 0,
    name: '',
    plan_contacts: 0,
    plan_progressive: '',
    price: '',
    sku: ''
}

export const state = store => ({
    ...abstract.state(),
    chain: _cloneDeep(OBJECTS_CHAIN),
    componentsList: [],
    stepsList: [],
    plans: [],
    plansInfo: [],
    products: [],
    history: [],
    isPlusCustomer: false,
    isPlusQuote: false,
    totalAnnualDiscount: store.getters.rootState['totalAnnualDiscount'] || PAY_FULL_TODAY_DISCOUNT,
    load: false,
    max_contacts: 0,
    selectedPlan: _cloneDeep(PLAN_INTERFACE),
    contactsPage: 1,
    api: {
        urls: {
            getPlans: '/plus/vision_plan/getPlans',
            getBusinessPlans: '/plus/vision_plan/getBusinessPlans',
            getPlansProducts: '/plus/vision_plan/getPlansProducts',
            getMaxContacts: '/plus/vision_plan/getMaxContacts',
            isPlusCustomer: '/plus/vision_plan/isPlusCustomer',
            addPlan: '/plus/cart/addPlan'
        }
    }
})

export const getters = {
    chain: state => state.chain,
    getMaxContacts: state => state.max_contacts,
    getContactsPage: state => state.contactsPage,
    getLoad: state => state.load,
    componentsList: state => state.componentsList,
    stepsList: state => state.stepsList,
    plans: state => state.plans,
    products: state => state.products,
    totalAnnualDiscount: state => state.totalAnnualDiscount,
    componentsCount: state => _size(state.componentsList),
    firstComponent: (state, getters) => getters.getObjectConfig(_first(state.chain).id),
    currentComponent: state => _find(state.componentsList, obj => obj.state === STATE_CURRENT),
    currentPlanInfo: (state, getters) => {
        return _filter(state.plansInfo, info => {
            if (_size(info.rules)) {
                const isRuleMatchCurrentPlan = _size(_filter(info.rules, (ruleValue, ruleName) => {
                    const isPlanRuleArray = Boolean(_isArray(getters.currentPlan[ruleName]))

                    // verify array has values
                    if (ruleName === CONTACTS_VALUE && isPlanRuleArray) {
                        const allEmpty = arr => arr.every(element => element === '')
                        getters.currentPlan[ruleName] = allEmpty(getters.currentPlan[ruleName]) ? 0 : getters.currentPlan[ruleName]
                    }

                    const planValue = isPlanRuleArray ? _size(getters.currentPlan[ruleName]) : Number(getters.currentPlan[ruleName])
                    return ruleValue === planValue
                }))

                return isRuleMatchCurrentPlan === _size(info.rules)
            }

            return info
        })
    },
    values: state => {
        const values = {}
        _map(state.componentsList, obj => {
            values[obj.id] = obj.value
        })
        return values
    },
    planType: (state, getters) => getters.values[COMPONENT_ID_PLAN_TYPE],
    isSingleVisionLenses: (state, getters) => getters.planType === SINGLE_VISION_VALUE,
    isProgressiveLenses: (state, getters) => getters.planType === PROGRESSIVE_VALUE,
    currentPlan: (state, getters) => {
        const values = getters.values
        const plan = `${values[COMPONENT_ID_PLAN_TYPE]}_${values[COMPONENT_ID_GLASSES]}`
        let sku

        if (values[COMPONENT_ID_GLASSES] === GLASSES_ONLY_VALUE) {
            sku = plan
        } else {
            const eyesData = getters.checkoutData.data

            if (eyesData.left.plan_sku && (eyesData.left.plan_sku === eyesData.right.plan_sku)) {
                sku = eyesData.left.plan_sku
            }
        }
        return _find(state.plans, plan => plan.sku === sku) || {
            ...PLAN_INTERFACE
        }
    },
    productsData: (state, getters) => {
        const data = []
        const products = []
        const searchEyeContacts = getters.values[COMPONENT_ID_SEARCH] || {}
        if (searchEyeContacts.left && searchEyeContacts.right) {
            _map(getters.plans, plan => {
                if (_find(plan.plan_contacts, sku => sku === searchEyeContacts.left)) {
                    _assign(products, plan.plan_contacts)
                }
            })
        }

        _map(getters.products, item => {
            if (Array.isArray(item)) {
                _map(item, _item => {
                    if (_size(products)) {
                        if (_find(products, sku => sku === _item.sku)) {
                            _map(getters.getProductData(_item), productData => data.push(productData))
                        }
                    } else {
                        _map(getters.getProductData(_item), productData => data.push(productData))
                    }
                })
            } else {
                if (_size(products)) {
                    if (_find(products, s => s === item.sku)) {
                        _map(getters.getProductData(item), productData => data.push(productData))
                    }
                } else {
                    _map(getters.getProductData(item), productData => data.push(productData))
                }
            }
        })

        return data
    },
    eyeData: (state, getters) => {
        const values = getters.values
        const searchEyeContacts = values[COMPONENT_ID_SEARCH] || {}
        const frequencies = CONTACTS_SUBSCRIPTIONS_FREQUENCIES

        let leftProductSku
        let rightProductSku
        if (searchEyeContacts.left && searchEyeContacts.right) {
            leftProductSku = searchEyeContacts.left
            rightProductSku = searchEyeContacts.right
        }

        const data = {
            left: {
                label: 'Left Contacts',
                product_sku: leftProductSku || '',
                price: 0,
                frequency: false
            },
            right: {
                label: 'Right Contacts',
                product_sku: rightProductSku || '',
                price: 0,
                frequency: false
            }
        }

        _map(data, eye => {
            const productsData = _find(getters.productsData, d => d.sku === eye.product_sku) || {}

            eye.id = productsData.id
            eye.name = productsData.name
            eye.boxes = productsData.boxes
            eye.plan_code = productsData.plan_code
            eye.regPrice = productsData.price
            eye.price = parseFloat(productsData.price) * parseInt(productsData.boxes) || 0

            if (frequencies.hasOwnProperty(eye.plan_code)) {
                eye.frequency = frequencies[eye.plan_code]['amount']['12-month']
            }
        })

        return data
    },
    checkoutData: (state, getters) => {
        const values = getters.values
        const plan = `${values[COMPONENT_ID_PLAN_TYPE]}_${values[COMPONENT_ID_GLASSES]}`
        const data = getters.eyeData

        let planId
        let planSku

        if (values[COMPONENT_ID_GLASSES] === GLASSES_ONLY_VALUE) {
            const currentPlan = getters.currentPlan

            data.left.plan_id = currentPlan.id
            data.left.plan_sku = plan
            data.right.plan_id = currentPlan.id
            data.right.plan_sku = plan
            currentPlan.plan_contacts = 0

            planSku = plan
            planId = currentPlan.id || currentPlan.entity_id
        } else {
            _map(data, (eye, index) => {
                const productsData = _find(getters.productsData, d => d.sku === eye.product_sku)
                let plan_id = ''
                let plan_sku = ''

                if (plan && productsData) {
                    _map(productsData.plans, sku => {
                        if (sku.indexOf(plan) !== -1) {
                            const obj = _find(getters.plans, o => o.sku === sku)
                            plan_sku = sku
                            plan_id = obj.id
                        }
                    })
                }

                data[index].plan_id = plan_id
                data[index].plan_sku = plan_sku

                planId = plan_id
                planSku = plan_sku
            })
        }

        const planData = _find(getters.plans, d => {
            if (data['left'].frequency >= 1) {
                d.plan_contacts = 1
            }
            return d.sku === planSku
        }) || {}

        const planMsrpPrice = parseFloat(planData.price || 0)

        const totalPrice = parseFloat((planMsrpPrice + getters.computePriceForContacts).toFixed(2))

        return {
            data,
            plan,
            planId,
            planSku,
            planMsrpPrice,
            totalPrice,
            totalAnnualPrice: parseFloat((totalPrice * (1 - state.totalAnnualDiscount)).toFixed(2)),
            totalQuarterlyPrice: parseFloat((totalPrice / 4).toFixed(2)),
            totalMonthlyPrice: parseFloat((totalPrice / 12).toFixed(2))
        }
    },
    computePriceForContacts: (store, getters) => parseFloat(getters.eyeData.left.price + getters.eyeData.right.price),
    getObjectConfig: state => id => _find(state.componentsList, obj => obj.id === id),
    getNextChainElement: (state, getters) => chainObject => {
        let nextChain
        const chainItem = _find(getters.chain, obj => obj.id === chainObject.id)
        if (chainObject.isValidValue && chainItem && chainItem.next) {
            if (_isString(chainItem.next)) {
                nextChain = getters.getObjectConfig(chainItem.next)
            } else if (_size(chainItem.next)) {
                nextChain = getters.getObjectConfig(chainItem.next[chainObject.value])
            }
        }
        return nextChain
    },
    getProductData: () => product => {
        const lensTypes = []
        const frequencies = CONTACTS_SUBSCRIPTIONS_FREQUENCIES
        if (frequencies.hasOwnProperty(product.plan_code)) {
            lensTypes.push(frequencies[product.plan_code][URL_KEY_LENS_TYPE])
        }

        _map(product.modality.hasOwnProperty(URL_KEY_LENS_TYPE) ? product.modality[URL_KEY_LENS_TYPE] : [], lensType => {
            if (!_find(lensTypes, name => lensType === name)) {
                lensTypes.push(lensType)
            }
        })

        return [_extend(_cloneDeep(product), {
            name: product.name.replace(/&(?:.|[\r\n])*?;/g, '') + ' ' + (product['second_title'] || ''),
            defaultName: product.name,
            lensTypes: lensTypes.join(', '),
            plans: [
                `${SINGLE_VISION_VALUE}_${GLASSES_AND_CONTACTS_VALUE}`,
                `${SINGLE_VISION_VALUE}_${GLASSES_ONLY_VALUE}`,
                `${PROGRESSIVE_VALUE}_${GLASSES_AND_CONTACTS_VALUE}`,
                `${PROGRESSIVE_VALUE}_${GLASSES_ONLY_VALUE}`
            ]
        })]
    },
    isPlusCustomer: state => state.isPlusCustomer,
    isPlusQuote: state => state.isPlusQuote,
    customerIsLoggedIn: (state, getters, rootState, rootGetters) => rootGetters.rootState.customerIsLoggedIn
}

export const actions = {
    ...abstract.actions,
    fetchStepsList: ({getters, commit}) => {
        if (!_size(getters.stepsList)) {
            const list = []
            _map(getters.componentsList, obj => {
                if (!list[obj.stepId]) {
                    list[obj.stepId] = {
                        label: `Step ${obj.stepId}`,
                        state: STATE_WAIT,
                        componentIds: [],
                        components: []
                    }
                }
                list[obj.stepId].componentIds.push(obj.id)
                list[obj.stepId].components.push(obj)
            })
            commit('STEPS_LIST', list)
        }
        return getters.stepsList
    },
    fetchComponentsList: ({getters, commit, rootGetters}) => {
        if (!_size(getters.componentsList)) {
            const params = {
                assets_url: rootGetters['storeView/assets_url']
            }
            const componentsList = _map(getObjectList(params), obj => {
                const chainItem = _find(getters.chain, item => item.id === obj.id)
                return Object.assign(obj, {
                    value: '',
                    isValidValue: false,
                    state: STATE_WAIT,
                    stepId: chainItem.stepId
                })
            })
            commit('COMPONENT_LIST', componentsList)
            _map(getters.componentsList, obj => commit('COMPONENT_DATA', {
                id: obj.id,
                value: _cloneDeep(obj.default_value)
            }))
        }
    },
    updateComponents: ({state, getters, commit, dispatch}) => {
        if (!_size(getters.componentsList)) {
            dispatch('fetchComponentsList')
        }
        if (!getters.currentComponent) {
            commit('COMPONENT_DATA', {id: getters.firstComponent.id, state: STATE_CURRENT})
        }

        const current = getters.currentComponent
        if (current.isValidValue) {
            const nextChain = getters.getNextChainElement(current)
            if (nextChain) {
                state.history.push(current.id)
                commit('COMPONENT_DATA', {id: current.id, state: STATE_READY})
                commit('COMPONENT_DATA', {id: nextChain.id, state: STATE_CURRENT})
            }
        }

        _map(getters.componentsList, obj => {
            if (getters.currentComponent.id !== obj.id) {
                commit('COMPONENT_DATA', {id: obj.id, state: obj.isValidValue ? STATE_READY : STATE_WAIT})
            }
        })
        dispatch('updateStepsList')
        dispatch('fetchPlansInfo')
    },
    updateStepsList: ({getters, dispatch}) => {
        if (!_size(getters.stepsList)) {
            dispatch('fetchStepsList')
        }
        _map(getters.stepsList, step => {
            if (step) {
                let state = STATE_WAIT
                if (_find(step.components, o => o.state === STATE_READY)) {
                    state = STATE_READY
                }
                if (_find(step.components, o => o.state === STATE_CURRENT)) {
                    state = STATE_CURRENT
                }
                if (!_isEqual(step.state, state)) {
                    step.state = state
                }
            }
        })
    },
    setActiveStep: ({state, getters, dispatch}, stepId) => {
        if (getters.currentComponent.stepId > stepId) {
            const history = state.history
            for (let i = history.length - 1; i >= 0; i--) {
                if (parseInt(getters.currentComponent.stepId) !== stepId) {
                    dispatch('goBack')
                }
            }
        }
    },
    goBack: ({state, getters, commit}) => {
        const prevChainId = state.history.pop()
        if (prevChainId) {
            const current = getters.currentComponent
            const previous = getters.getObjectConfig(prevChainId)
            commit('COMPONENT_DATA', {
                id: current.id,
                value: current.default_value,
                isValidValue: false,
                state: STATE_WAIT
            })
            commit('COMPONENT_DATA', {
                id: previous.id,
                value: previous.default_value,
                isValidValue: false,
                state: STATE_CURRENT
            })
        } else {
            window.location.href = '/plus'
        }
    },
    fetchPlans: ({state, commit}) => {
        return axios.get(state.api.urls.getPlans)
            .then(({data, status}) => {
                if (status === 200) {
                    _map(data, (plan, index) => {
                        data[index]['plan_contacts'] = plan.plan_contacts
                    })
                    commit('PLANS', data)
                }
            })
    },
    fetchBusinessPlans: ({state, commit}) => {
        return axios.get(state.api.urls.getBusinessPlans)
            .then(({data, status}) => {
                if (status === 200) {
                    _map(data, (plan, index) => {
                        data[index]['plan_contacts'] = plan.plan_contacts
                    })
                    commit('BUSINESS_PLANS', data)
                    commit('LOAD', false)
                }
            })
    },
    fetchPlansProducts: async ({state, commit, getters}) => {
        while (getters.getContactsPage <= getters.getMaxContacts) {
            await axios.get(`${state.api.urls.getPlansProducts}?page=` + getters.getContactsPage)
                .then(({data, status}) => {
                    if (status === 200) {
                        const products = [...getters.products, ...data]
                        commit('PRODUCTS', products)
                    }
                }).finally(() => commit('LOAD', false))
            commit('SET_CONTACTS_PAGE', getters.getContactsPage + 1)
        }
    },
    fetchMaxContactsSize: ({state, commit, dispatch}) => {
        axios.get(state.api.urls.getMaxContacts)
            .then(({data, status}) => {
                if (status === 200) {
                    commit('SET_MAX_CONTACTS', data.max_contacts_size)
                    dispatch('fetchPlansProducts')
                }
            })
    },
    fetchPlansInfo: ({commit, getters, rootGetters}) => {
        const values = getters.values
        const params = {
            assets_url: rootGetters['storeView/assets_url'],
            type: values.plan && values.plan.includes(PROGRESSIVE_VALUE) ? 'Progressive' : 'Single Vision'
        }
        commit('PLANS_INFO', getPlansInfo(params))
    },
    fetchIsPlusCustomer: ({state, commit, getters}) => {
        return getters.isPlusCustomer || axios.get(state.api.urls.isPlusCustomer)
            .then(({
                data,
                status
            }) => {
                if (status === 200) {
                    commit('IS_PLUS_CUSTOMER', data.plus)
                    commit('IS_PLUS_QUOTE', data.plusItem)
                }
            })
    },
    addCurrentPlanToCart: ({state, getters}) => {
        return axios.post(state.api.urls.addPlan, getters.checkoutData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}

export const mutations = {
    ...abstract.mutations,
    COMPONENT_LIST: (state, list = []) => state.componentsList = list,
    COMPONENT_DATA: (state, data = {}) => {
        const obj = _find(state.componentsList, obj => obj.id === data.id)
        _map(data, (value, key) => {
            if (obj && !_isEqual(value, obj[key])) {
                Vue.set(obj, key, value)
            }
        })
    },
    STEPS_LIST: (state, list = []) => state.stepsList = list,
    PLANS: (state, plans = []) => {
        _filter(plans, plan => plan.sku = plan.sku.replace(/-/g, '_'))
        state.plans = plans
    },
    BUSINESS_PLANS: (state, business_plans = []) => {
        _filter(business_plans, business_plan => business_plan.sku = business_plan.sku.replace(/-/g, '_'))
        state.business_plans = business_plans
    },
    PLANS_INFO: (state, info = []) => state.plansInfo = info,
    PRODUCTS: (state, products = []) => state.products = products,
    IS_PLUS_CUSTOMER: (state, plus = false) => state.isPlusCustomer = Boolean(plus),
    IS_PLUS_QUOTE: (state, plus = false) => state.isPlusQuote = Boolean(plus),
    LOAD: (state, load) => state.load = load,
    SET_MAX_CONTACTS: (state, max_contacts = 0) => state.max_contacts = max_contacts,
    SET_SELECTED_PLAN: (state, data = {}) => state.selectedPlan = Object.keys(data || {}).length ? data : _cloneDeep(PLAN_INTERFACE),
    SET_CONTACTS_PAGE: (state, contactsPage = 1) => state.contactsPage = contactsPage
}

export default (store = {}) => ({
    namespaced: true,
    state: state(store),
    getters,
    actions,
    mutations
})
