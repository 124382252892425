<template>
    <div v-if="showModal && getLoading">
        <div class="modal-mask">
            <div class="modal-wrapper lplus-modal-container">
                <div v-if="!getCreatedSuccess" class="modal-container lplus-modal-section">
                    <div class="modal-header">
                        <a class="modal-close-button" @click="stateModal"></a>
                    </div>
                    <div class="modal-body lplus-modal-body">
                        <div class="lplus-modal-text">
                            <h2>Claim Your</h2>
                            <h2><span>FREE</span> Gift</h2>
                            <p class="lplus-modal-header-subtext">A <b>FREE six months Lensabl+</b> <span class="lplus-header-span"><b>membership</b> that includes:</span></p>
                            <ul>
                                <li><b>25% off</b> lenses and frames</li>
                                <li><b>15% off</b> contact lenses</li>
                                <li><b>$60 Lensabl gift card</b> for taking an eye exam</li>
                                <li>Insider access to new frame and lens products</li>
                            </ul>
                            <div class="lplus-membership-text">
                                <p>Click the button below to be</p>
                                <p>automatically enrolled at no charge</p>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <BaseButton :id-name="'lplus-conversion-btn'" :loader="loadButton" text="join lensabl+" @click.native="createMembership" />
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="modal-container lplus-congrats-modal-section">
                    <div v-for="n in 149" :key="`confetti-${n}`" :class="'confetti-' + n"></div>
                    <div class="modal-header">
                        <a class="modal-close-button" @click="stateModal"></a>
                    </div>
                    <div class="modal-body lplus-modal-body">
                        <div class="lplus-modal-text">
                            <h2>Congratulations! &#127881;</h2>
                            <p>You are now a Lensabl+ Member.</p>
                            <p>You will soon receive an email with further details.</p>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer">
                                <BaseButton text="continue" @click.native="stateModal" />
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import membershipFree from './storage/lensablplus/membership_free'
import BaseButton from '../base/BaseButton.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
    name: 'LensablPlusFree',
    components: {
        BaseButton
    },
    data: () => ({
        showModal: true,
        loadButton: false,
        disabledButton: false
    }),
    computed: {
        ...mapGetters('membership_free', ['getCreatedSuccess', 'getLoading'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['membership_free/']) {
            this.$store.registerModule('membership_free', membershipFree(this.$store))
        }
    },
    created () {
        this.getLastOrderId()
    },
    methods: {
        ...mapActions('membership_free', ['createMemebership', 'getLastOrderId']),
        stateModal () {
            this.showModal = !this.showModal
        },
        createMembership () {
            this.loadButton = true
            this.disabledButton = true
            this.createMemebership().then(() => {
                this.loadButton = false
                this.disabledButton = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
body {
    margin: 0;
    overflow: hidden;
}

.wrapper {
    position: relative;
    min-height: 100vh;
}

[class|="confetti"] {
    position: absolute;
}

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 100 {
    $w: random(8);
    $l: random(98);
    .confetti-#{$i} {
        width: #{$w}px;
        height: #{$w*0.4}px;
        background-color: nth($colors, random(3));
        top: 0;
        left: unquote($l+"%");
        opacity: random() + 0.5;
        transform: rotate(#{random()*360}deg);
        animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") infinite;
    }

    @keyframes drop-#{$i} {
        100% {
            top: 100%;
        }
    }
}
</style>
