import Vue from 'vue'
import axios from 'axios'
import _size from 'lodash/size'
import _cloneDeep from 'lodash/cloneDeep'
import * as abstract from '../../../../../storage/abstract'

export const state = () => ({
    ...abstract.state(),
    frames_name: '',
    product_name: '',
    lensabl_category: '',
    frame_price: '',
    product_sku: '',
    frames_value: '',
    frames_SKU: '',
    product_id: '',
    description: '',
    lensabl_frames: '',
    default_antireflective: '',
    category: '',
    title: '',
    size: '',
    material: '',
    qty: {},
    css_param: '',
    sku: '',
    productInfoShow: true,
    productConfiguratorShow: true,
    colors: [],
    checkedIndex: 0,
    currentImageIndex: 0,
    categoryName: '',
    clipon: false,
    clipon_price: 0,
    boseLanding: false,
    willowsPage: false,
    loading: {
        currentProductDefaultData: false,
    },
    api: {
        urls: {
            currentProductDefaultDataUrl: ''
        }
    }
})

export const getters = {
    productView: state => state,
    frames_name: state => state.frames_name,
    product_name: state => state.product_name,
    lensabl_category: state => state.lensabl_category,
    default_antireflective: state => state.default_antireflective,
    frame_price: state => state.frame_price,
    product_sku: state => state.product_sku,
    frames_value: state => state.frames_value,
    frames_SKU: state => state.frames_SKU,
    category: state => state.category,
    product_id: state => state.product_id,
    product_description: state => state.description,
    lensabl_frames: state => state.lensabl_frames,
    image_title: state => state.title,
    size: state => state.size,
    material: state => state.material,
    qty: state => state.qty,
    css_param: state => state.css_param,
    sku: state => state.sku,
    colors: state => state.colors,
    colorItem: (state, getters) => getters.colors[state.checkedIndex],
    media: (state, getters) => {
        const item = getters.colorItem
        if ((getters.lensabl_frames || item && item.gallery[getters.lensabl_category]) && getters.productCategoryName.indexOf('personal') === -1) {
            return item ? item.gallery[getters.lensabl_category] : []
        }
        return item ? item.gallery : []
    },
    currentImage: (state, getters) => {
        const item = getters.colorItem
        return item ? item.gallery[state.currentImageIndex] : {}
    },
    colorName: (state, getters) => {
        const item = getters.colorItem
        return item ? item.color : {}
    },
    mainImageUrl: (state, getters) => getters.getImageUrl(getters.currentImage),
    getImageUrl: (state, getters, rootState, rootGetters) => (image = {}) => image ? rootGetters['deviceProperty/isRetina'] ? image.gallery : image.thumbnail : '',
    getCurrentProductAttribute: (state, getters) => (code = '') => {
        const item = getters.colorItem
        let value = getters[code]
        if (item && item[code]) {
            value = item[code]
        }
        return value
    },
    stockStatus: (state, getters) => {
        const color = getters.colorName
        if (getters.qty && state.qty.hasOwnProperty(color)) {
            const qty = state.qty[color] ? state.qty[color] : 0
            return qty > 0
        }
    },
    productCategoryName: state => state.categoryName,
    isClipOnType: state => state.clipon,
    getClipOnPrice: state => state.clipon_price,
    isBoseLanding: (state) => {
        const current = window.location.pathname
        state.boseLanding = current === '/order-lenses-bose'
        return state.boseLanding
    },
    isSpectaclesGen: state => state.sku.indexOf('spectacles_gen') !== -1,
    currentProductDefaultDataLoadind: state => state.loading.currentProductDefaultData,
    currentProductDefaultDataUrl: state => state.api.urls.currentProductDefaultDataUrl,
    isWillowsPage: (state) => {
        const current = window.location.pathname
        state.willowsPage = current.indexOf('willows') !== -1
        return state.willowsPage
    },
    isBosePage: (state) => {
        const current = window.location.pathname
        state.willowsPage = current.indexOf('bose') !== -1
        return state.willowsPage
    },
    is_only_lenses_discount: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState['only_lenses_discount'] || false
    }
}

export const actions = {
    ...abstract.actions,
    fetchDefaultDataBefore: ({state, commit, rootGetters}, defaultData = {}) => {
        const data = Object.assign(rootGetters.rootState || {}, defaultData)
        if (!_size(data.colors)) {
            Vue.set(data, 'colors', Object.assign([], _cloneDeep(data.images)))
        }
        if (!data.lensabl_category && data.frames_name) {
            Vue.set(data, 'lensabl_category', data.frames_name)
        }
        return data
    },
    getCurrentProductDefaultData: ({state, commit, dispatch}, objData = {}) => {
        if (!state.loading.currentProductDefaultData && state.api.urls.currentProductDefaultDataUrl) {
            if (state.product_id && !objData['product_id']) {
                objData['product_id'] = state.product_id
            }
            commit('SET_CURRENT_PRODUCT_DEFAULT_DATA_LOADING', true)
            return axios.post(state.api.urls.currentProductDefaultDataUrl, objData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(({data, status}) => {
                if (status === 200) {
                    dispatch('fetchDefaultData', data)
                }
            }).finally(() => commit('SET_CURRENT_PRODUCT_DEFAULT_DATA_LOADING', false))
        }
    },

    UPDATE_PRODUCT_CONFIG: ({getters, commit, dispatch, rootGetters}) => {
        if (getters.lensabl_frames) {
            const product_config = rootGetters.rootState.product_config[getters.lensabl_category]
            commit('configurator/SET_PRODUCT_CONFIG', product_config, { root: true })
            dispatch('configurator/steps/fetchNewStepList', null, { root: true })
        }
    }
}

export const mutations = {
    ...abstract.mutations,
    setProductInfoShow: (state, show = true) => state.productInfoShow = show,
    setProductConfiguratorShow: (state, show = true) => state.productConfiguratorShow = show,
    setCurrentImageIndex: (state, index = 0) => state.currentImageIndex = index,
    setCheckedIndex: (state, index = 0) => state.checkedIndex = index,
    setLensablCategory: (state, payload) => state.lensabl_category = payload,
    setFramesName: (state, payload) => state.frames_name = payload,
    setProductCategoryName: (state, payload) => state.categoryName = payload,
    setFramePrice: (state, payload) => state.frame_price = payload,
    SET_CURRENT_PRODUCT_DEFAULT_DATA_LOADING: (state, loading) => state.loading.currentProductDefaultData = loading
}

export default () => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions
})
