var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isStepCurrent
    ? _c("div", [
        _c("div", { staticClass: "step-description" }, [
          _vm._v("Choose one from the options below.")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "options_container" }, [
          _c(
            "ul",
            { staticClass: "options" },
            [
              _vm._l(_vm.groupedOptionsRenderer, function(group) {
                return [
                  _c(
                    "li",
                    {
                      key: group.label,
                      staticClass: "option",
                      class: { "active-option": _vm.isGroupActive(group) },
                      attrs: { for: group.label },
                      on: {
                        click: function($event) {
                          return _vm.changeLensType(group)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "prescription-type-descriptions",
                          class: { active: _vm.isGroupActive(group) }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "option-label" },
                            [
                              _c("span", { staticClass: "label-text" }, [
                                _vm._v(_vm._s(group.label))
                              ]),
                              _vm._v(" "),
                              _vm.isMobile && group.id === "progressive"
                                ? _c("HelpBubble", {
                                    style: { textTransform: "none" },
                                    attrs: { data: _vm.component_help }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          group.description
                            ? _c("div", {
                                directives: [
                                  {
                                    name: "dompurify-html",
                                    rawName: "v-dompurify-html",
                                    value: group.description,
                                    expression: "group.description"
                                  }
                                ],
                                staticClass: "option-description"
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "option-checkbox-container",
                              class: { active: _vm.isGroupActive(group) }
                            },
                            [_c("div", { staticClass: "option-checkbox" })]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isGroupActive(group)
                        ? _c("div", { staticClass: "chosen-container" }, [
                            _vm.groupedChildOptionsRenderer[group.id] &&
                            Object.keys(
                              _vm.groupedChildOptionsRenderer[group.id]
                            ).length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.groupedChildOptionsRenderer[group.id],
                                    function(groupedChild, key) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass:
                                            "prescription-type-descriptions transform-text-usual"
                                        },
                                        [
                                          _c(
                                            "ul",
                                            [
                                              _c("Option", {
                                                key: key,
                                                staticClass:
                                                  "prescription-type-option",
                                                attrs: {
                                                  option: groupedChild,
                                                  "manufacturer-show": true,
                                                  "component-help-show": false
                                                },
                                                on: {
                                                  "option-select":
                                                    _vm.selectFirstChild
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.isParentActive(groupedChild)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "prescription-type-descriptions child transform-text-usual"
                                                },
                                                _vm._l(
                                                  groupedChild.options,
                                                  function(option) {
                                                    return _c(
                                                      "ul",
                                                      [
                                                        _c("Option", {
                                                          key: option.id,
                                                          staticClass:
                                                            "prescription-type-option",
                                                          attrs: {
                                                            option: option,
                                                            "manufacturer-show": true,
                                                            "component-help-show": false
                                                          },
                                                          on: {
                                                            "option-select":
                                                              _vm.optionSelect
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c("div", [
                                  _c(
                                    "ul",
                                    [
                                      _vm._l(group.options, function(option) {
                                        return [
                                          _c("Option", {
                                            key: option.id,
                                            staticClass:
                                              "prescription-type-option",
                                            attrs: {
                                              option: option,
                                              "manufacturer-show": true,
                                              "component-help-show": false
                                            },
                                            on: {
                                              "option-select": _vm.optionSelect
                                            }
                                          })
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subtextShow(group) &&
                      _vm.isGroupActive(group) &&
                      (group.id === "single_vision" ||
                        group.id === "progressive") &&
                      _vm.values.frames_name === "rheos"
                        ? _c("div", { staticClass: "single-vision-subtext" }, [
                            _c("span", { style: { fontWeight: "bold" } }, [
                              _vm._v(
                                "Disclaimer: Lensabl cannot guarantee that any prescription with a power greater than +/-4.00 will float in water. Please contact "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "mailto:support@lensabl.com" }
                                },
                                [_vm._v("support@lensabl.com")]
                              ),
                              _vm._v(" with any questions.")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subtextShow(group) &&
                      _vm.isGroupActive(group) &&
                      (group.id === "single_vision" ||
                        group.id === "progressive" ||
                        group.id === "progressive") &&
                      _vm.values.frames_name === "willows"
                        ? _c("div", { staticClass: "single-vision-subtext" }, [
                            _c("span", { style: { fontWeight: "bold" } }, [
                              _vm._v(
                                "Disclaimer: This frame can only accommodate prescriptions with up to " +
                                  _vm._s(_vm.willowsSubText) +
                                  " SPH. Lensabl will not be able to fulfill orders with stronger prescriptions. Please contact "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "mailto:support@lensabl.com" }
                                },
                                [_vm._v("support@lensabl.com")]
                              ),
                              _vm._v(" with any questions.")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subtextShow(group) &&
                      _vm.isGroupActive(group) &&
                      _vm.values.frames_name === "James Oro Frames - Caviar"
                        ? _c("div", { staticClass: "single-vision-subtext" }, [
                            _c("span", { style: { fontWeight: "bold" } }, [
                              _vm._v(
                                "Disclaimer: This frame cannot accommodate any prescription with a power greater than +/- 4.00 SPH. Please contact "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "mailto:support@lensabl.com" }
                                },
                                [_vm._v("support@lensabl.com")]
                              ),
                              _vm._v(" with any questions.")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subtextShow(group) &&
                      _vm.isGroupActive(group) &&
                      _vm.values.frames_name === "James Oro Frames - Amour"
                        ? _c("div", { staticClass: "single-vision-subtext" }, [
                            _c("span", { style: { fontWeight: "bold" } }, [
                              _vm._v(
                                "Disclaimer: This frame cannot accommodate any prescription with a power greater than +/- 5.00 SPH. Please contact "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "mailto:support@lensabl.com" }
                                },
                                [_vm._v("support@lensabl.com")]
                              ),
                              _vm._v(" with any questions.")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subtextShow(group) &&
                      _vm.isGroupActive(group) &&
                      group.id === "single_vision"
                        ? _c(
                            "div",
                            { staticClass: "single-vision-subtext" },
                            [
                              _vm._v(
                                "\n                        For intermediate and computer lenses, please click "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "single-vision-link",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      _vm.contactUsModalShow = true
                                    }
                                  }
                                },
                                [_vm._v("here")]
                              ),
                              _vm._v(" or\n                        "),
                              _c(
                                "a",
                                {
                                  staticClass: "single-vision-link",
                                  attrs: { href: "/contact" }
                                },
                                [_vm._v("contact us")]
                              ),
                              _vm._v(" "),
                              _c(
                                "vue-modaltor",
                                {
                                  staticClass: "small",
                                  attrs: {
                                    visible: _vm.contactUsModalShow,
                                    "default-width": "50%",
                                    "bg-overlay": "",
                                    "bg-panel": "",
                                    "close-scroll": false,
                                    "animation-panel": "modal-slide-top"
                                  },
                                  on: { hide: _vm.contactUsModalHide }
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                                If you are looking to use these lenses for "
                                    ),
                                    _c("b", [
                                      _vm._v("intermediate/computer distance")
                                    ]),
                                    _vm._v(
                                      ", please make sure you have a specific prescription for this. This prescription is "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        "different from a single vision/multifocal"
                                      )
                                    ]),
                                    _vm._v(
                                      " prescription as it is tailored for viewing a computer screen anywhere from 20-40 inches away (your doctor should be able to provide this to you). "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _vm._v(
                                      "Once you confirm you have the correct prescription, please select single vision for distance. "
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _c("b", [
                                      _vm._v(
                                        "Once you get to step 6- Enter RX: Please either upload a photo copy of your intermediate distance RX or you can manually enter it into the RX grid."
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "options" },
            [
              _vm._l(_vm.options, function(option) {
                return [
                  _c("Option", {
                    key: option.id,
                    staticClass: "step-configuration-content",
                    attrs: { option: option },
                    on: { "option-select": _vm.optionSelect }
                  })
                ]
              })
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }