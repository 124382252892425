var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ],
      class: _vm.id
    },
    [
      _vm.config.title
        ? _c("h2", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.title,
                expression: "config.title"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "company-address",
          attrs: { id: "company_address_fields" }
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "hor-scroll" }, [
              _c(
                "table",
                { staticClass: "form-list", attrs: { cellspacing: "0" } },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c(
                            "label",
                            { attrs: { for: "companyTemplateData[0].name" } },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.companyTemplateData[0].label) +
                                  ":\n                                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentBillingAddress[
                                    _vm.companyTemplateData[0].name
                                  ],
                                expression:
                                  "currentBillingAddress[companyTemplateData[0].name]"
                              }
                            ],
                            staticClass: "input-text",
                            class: {
                              "required-entry":
                                _vm.companyTemplateData[0].required,
                              "validation-failed":
                                _vm.errors[_vm.companyTemplateData[0].name]
                            },
                            attrs: {
                              id:
                                "membership-billing_address_" +
                                _vm.companyTemplateData[0].name,
                              type: "text"
                            },
                            domProps: {
                              value:
                                _vm.currentBillingAddress[
                                  _vm.companyTemplateData[0].name
                                ]
                            },
                            on: {
                              blur: function($event) {
                                return _vm.validBlurField(
                                  _vm.companyTemplateData[0].name
                                )
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentBillingAddress,
                                  _vm.companyTemplateData[0].name,
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors[_vm.companyTemplateData[0].name]
                            ? _c(
                                "div",
                                {
                                  staticClass: "validation-advice",
                                  attrs: {
                                    id:
                                      "advice-required-entry-" +
                                      _vm.companyTemplateData[0].name
                                  }
                                },
                                [_vm._v("This is a required field.")]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.companyTemplateData[1].options, function(
                        template,
                        index
                      ) {
                        return _c("tr", { key: "row-" + template.label }, [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c(
                              "label",
                              {
                                attrs: {
                                  for:
                                    "membership-billing_address_" +
                                    template.label
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(template.label) +
                                    ":\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.currentBillingAddress.street[index],
                                  expression:
                                    "currentBillingAddress.street[index]"
                                }
                              ],
                              staticClass: "input-text",
                              class: {
                                "required-entry": template.required,
                                "validation-failed":
                                  index === 0 && _vm.errors["street"]
                              },
                              attrs: {
                                id:
                                  "membership-billing_address_" +
                                  template.label,
                                type: "text"
                              },
                              domProps: {
                                value: _vm.currentBillingAddress.street[index]
                              },
                              on: {
                                blur: function($event) {
                                  index === 0
                                    ? _vm.validBlurField("street")
                                    : ""
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.currentBillingAddress.street,
                                    index,
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            index === 0 && _vm.errors["street"]
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "validation-advice",
                                    attrs: {
                                      id:
                                        "advice-required-entry-" +
                                        template.label
                                    }
                                  },
                                  [_vm._v("This is a required field.")]
                                )
                              : _vm._e()
                          ])
                        ])
                      }),
                      _vm._v(" "),
                      _c(
                        "tr",
                        [
                          _vm._l(_vm.companyTemplateData, function(
                            template,
                            index
                          ) {
                            return [
                              index > 1 && index < 4
                                ? _c(
                                    "td",
                                    { key: "row-" + template.name },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for:
                                              "membership-billing_address_" +
                                              template.name
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(template.label) +
                                              ":\n                                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      [
                                        template.type === "text"
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.currentBillingAddress[
                                                      template.name
                                                    ],
                                                  expression:
                                                    "currentBillingAddress[template.name]"
                                                }
                                              ],
                                              staticClass: "input-text",
                                              class: {
                                                "required-entry":
                                                  template.required,
                                                "validation-failed":
                                                  _vm.errors[template.name]
                                              },
                                              attrs: {
                                                id:
                                                  "membership-billing_address_" +
                                                  template.name,
                                                readonly: template.disabled,
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.currentBillingAddress[
                                                    template.name
                                                  ]
                                              },
                                              on: {
                                                blur: function($event) {
                                                  return _vm.validBlurField(
                                                    template.name
                                                  )
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.currentBillingAddress,
                                                    template.name,
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      _vm._v(" "),
                                      _vm.errors[template.name]
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "validation-advice",
                                              attrs: {
                                                id:
                                                  "advice-required-entry-" +
                                                  template.name
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "This is a required field."
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "tr",
                        [
                          _vm._l(_vm.companyTemplateData, function(
                            template,
                            index
                          ) {
                            return [
                              index >= 4 && index < 6
                                ? _c(
                                    "td",
                                    { key: "row-" + template.name },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for:
                                              "membership-billing_address_" +
                                              template.name
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(template.label) +
                                              ":\n                                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      template.options
                                        ? [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.currentBillingAddress
                                                        .region,
                                                    expression:
                                                      "currentBillingAddress.region"
                                                  }
                                                ],
                                                staticClass: "select",
                                                class: {
                                                  "required-entry":
                                                    template.required,
                                                  "validation-failed":
                                                    _vm.errors[template.name]
                                                },
                                                attrs: {
                                                  id:
                                                    "membership-billing_address_" +
                                                    template.name
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.currentBillingAddress,
                                                        "region",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.validBlurField(
                                                        template.name
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _vm._l(
                                                  template.options,
                                                  function(region) {
                                                    return [
                                                      _c("option", {
                                                        key:
                                                          region.name +
                                                          "-option-" +
                                                          region.value,
                                                        domProps: {
                                                          value: region.value,
                                                          textContent: _vm._s(
                                                            region.label
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        : [
                                            template.type === "text"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .currentBillingAddress[
                                                          template.name
                                                        ],
                                                      expression:
                                                        "currentBillingAddress[template.name]"
                                                    }
                                                  ],
                                                  staticClass: "input-text",
                                                  class: {
                                                    "required-entry":
                                                      template.required,
                                                    "validation-failed":
                                                      _vm.errors[template.name]
                                                  },
                                                  attrs: {
                                                    id:
                                                      "membership-billing_address_" +
                                                      template.name,
                                                    type: "text"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.currentBillingAddress[
                                                        template.name
                                                      ]
                                                  },
                                                  on: {
                                                    blur: function($event) {
                                                      return _vm.validBlurField(
                                                        template.name
                                                      )
                                                    },
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.currentBillingAddress,
                                                        template.name,
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                      _vm._v(" "),
                                      _vm.errors[template.name]
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "validation-advice",
                                              attrs: {
                                                id:
                                                  "advice-required-entry-" +
                                                  template.name
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "This is a required field."
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    2
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "actions-toolbar" }, [
        _c(
          "div",
          { staticClass: "primary" },
          [
            _c("BaseButton", {
              staticClass: "button--copy-solid-white-blue button--small",
              attrs: { text: "Continue" },
              nativeOn: {
                click: function($event) {
                  return _vm.continueNxt($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { attrs: { width: "60%" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "40%" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }