var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-prescription-form" }, [
    _c("div", { staticClass: "my-prescription-form__name-wrap" }, [
      _vm.showEditVersion
        ? _c(
            "div",
            { staticClass: "my-prescription-form__name-input-container" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.prescriptionData.name,
                    expression: "prescriptionData.name"
                  }
                ],
                staticClass: "my-prescription-form__name outline",
                attrs: {
                  id: "prescription-form__name",
                  placeholder: "Enter Prescription Name here...",
                  required: "required"
                },
                domProps: { value: _vm.prescriptionData.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.prescriptionData, "name", $event.target.value)
                  }
                }
              })
            ]
          )
        : _c(
            "div",
            { staticClass: "my-prescription-form__name-input-container" },
            [
              _c("span", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.prescriptionData.name,
                    expression: "prescriptionData.name"
                  }
                ],
                staticClass: "my-prescription-form__name outline"
              })
            ]
          ),
      _vm._v(" "),
      _vm.showEditVersion
        ? _c(
            "label",
            {
              staticClass: "edit-name",
              attrs: { for: "prescription-form__name" }
            },
            [_vm._v("edit name")]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "my-prescription-form-options" }, [
      _c(
        "div",
        {
          staticClass:
            "my-prescription-form__lens-options-container my-prescription-form__layout-xlrg-left"
        },
        [
          _vm.showEditVersion
            ? _c("div", { staticClass: "my-prescription-form__lens-type" }, [
                _c("p", { staticClass: "my-prescription-form__subheading" }, [
                  _vm._v("Lenses:")
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("RadioLabel", {
                        attrs: {
                          active: _vm.prescriptionData.lens === "prescription",
                          label: "Prescription Lenses"
                        },
                        on: {
                          click: function($event) {
                            _vm.prescriptionData.lens = "prescription"
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("RadioLabel", {
                        attrs: {
                          active: _vm.prescriptionData.lens === "reading",
                          label: "Reading Lenses"
                        },
                        on: {
                          click: function($event) {
                            _vm.prescriptionData.lens = "reading"
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.prescriptionData.lens === "prescription" && _vm.showEditVersion
            ? _c(
                "div",
                {
                  staticClass:
                    "my-prescription-form__prescription-type prescription-toggle"
                },
                [
                  _c("p", { staticClass: "my-prescription-form__subheading" }, [
                    _vm._v("Choose Prescription Type:")
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c(
                      "li",
                      [
                        _c("RadioLabel", {
                          attrs: {
                            active:
                              _vm.prescriptionData.prescription_type ===
                              "single_vision_distance",
                            label: "Single Vision Distance"
                          },
                          on: {
                            click: function($event) {
                              _vm.prescriptionData.prescription_type =
                                "single_vision_distance"
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("RadioLabel", {
                          attrs: {
                            active:
                              _vm.prescriptionData.prescription_type ===
                              "single_vision_reading",
                            label: "Single Vision Near"
                          },
                          on: {
                            click: function($event) {
                              _vm.prescriptionData.prescription_type =
                                "single_vision_reading"
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("RadioLabel", {
                          attrs: {
                            active:
                              _vm.prescriptionData.prescription_type ===
                              "progressive",
                            label: "Progressive"
                          },
                          on: {
                            click: function($event) {
                              _vm.prescriptionData.prescription_type =
                                "progressive"
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c("RadioLabel", {
                          attrs: {
                            active:
                              _vm.prescriptionData.prescription_type ===
                              "bi_focal",
                            label: "Bi Focal"
                          },
                          on: {
                            click: function($event) {
                              _vm.prescriptionData.prescription_type =
                                "bi_focal"
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.prescriptionData.lens === "prescription" && !_vm.showEditVersion
            ? _c(
                "div",
                {
                  staticClass:
                    "my-prescription-form__prescription-type prescription-toggle"
                },
                [
                  _c("p", { staticClass: "my-prescription-form__subheading" }, [
                    _vm._v("Prescription Type:")
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: _vm.prescriptionType,
                        expression: "prescriptionType"
                      }
                    ],
                    staticClass: "my-prescription-form__name outline"
                  })
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "my-prescription-form__dropdown-options-wrap my-prescription-form__layout-xlrg-right",
          class: { "not-edit-form": !_vm.showEditVersion }
        },
        [
          _vm.prescriptionData.lens === "reading"
            ? _c(
                "div",
                {
                  staticClass:
                    "my-prescription-form__dropdown-options-container reading-toggle"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "my-prescription-form__subheading reading-toggle"
                    },
                    [_vm._v("Select Lens Power:")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "my-prescription-form__dropdown-row my-prescription-form__dropdown-row--reading"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "my-prescription-form__reading-eye" },
                        [_vm._v("Lens Magnification")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "my-prescription-form__dropdown my-prescription-form__dropdown--reading"
                        },
                        [
                          _c("CustomSelect", {
                            attrs: {
                              value: (_vm.prescriptionData.value.od =
                                _vm.prescriptionData.value.os),
                              options: _vm.eyePowerReadingOptions
                            },
                            on: {
                              change: function($event) {
                                _vm.prescriptionData.value.od = _vm.prescriptionData.value.os =
                                  $event.target.value
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.prescriptionData.lens === "prescription"
            ? _c(
                "div",
                {
                  staticClass:
                    "my-prescription-form__dropdown-options-container prescription-toggle"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "my-prescription-form__subheading prescription-toggle"
                    },
                    [_vm._v("Prescription:")]
                  ),
                  _vm._v(" "),
                  _c("PrescriptionTable", {
                    attrs: {
                      "show-edit-version": _vm.showEditVersion,
                      "show-add": _vm.showAdd,
                      value: _vm.prescriptionData.value,
                      help: {}
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.prescriptionData.source_pdf
            ? _c(
                "div",
                { staticClass: "my-prescription-form__pupillary-distance" },
                [_vm._m(0)]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-prescription-form__pd-prism__container" }, [
        _vm.prescriptionData.lens === "prescription"
          ? _c(
              "div",
              { staticClass: "my-prescription-form__pupillary-distance" },
              [
                _c("CustomerPrescriptionPupillaryDistance", {
                  attrs: {
                    "show-edit-version": _vm.showEditVersion,
                    "show-add": _vm.showAdd,
                    value: _vm.prescriptionData.value,
                    help: {}
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "my-prescription-form__prism" }, [
          _vm.prescriptionData.value.prism
            ? _c(
                "div",
                { staticClass: "my-prescription-form__dropdown-pupillary" },
                [_vm._m(1)]
              )
            : _c(
                "div",
                {
                  staticClass:
                    "my-prescription-form__checkbox-pupillary my-prescription-form__checkbox-prism"
                },
                [
                  _vm._v(
                    "\n                    If prescription has a prism correction, please "
                  ),
                  _c("a", { attrs: { href: "mailto:support@lensabl.com" } }, [
                    _vm._v("contact us")
                  ]),
                  _vm._v(" to update.\n                ")
                ]
              )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "my-prescription-form__bottom" }, [
      _vm.showError
        ? _c("div", { staticClass: "error-text-rx" }, [
            _c("span", { domProps: { textContent: _vm._s(_vm.errorText) } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "actions-toolbar" }, [
        _vm.prescriptionId && _vm.showEditVersion
          ? _c(
              "div",
              { staticClass: "primary" },
              [
                _c("BaseButton", {
                  staticClass:
                    "button--copy-solid-white button--copy-border-sm delete",
                  attrs: {
                    disabled: _vm.disabled,
                    text: "Delete",
                    "data-size": "xs"
                  },
                  nativeOn: {
                    click: function($event) {
                      _vm.deleteConfirmation = true
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "secondary" },
          [
            _vm.showEditVersion
              ? _c("BaseButton", {
                  staticClass: "button--copy-solid-hover-dark",
                  attrs: {
                    loader: _vm.saving,
                    disabled: _vm.disabled,
                    text: "Save"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.saveForm($event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.canAddToOrder
          ? _c(
              "div",
              { staticClass: "secondary" },
              [
                _c("BaseButton", {
                  staticClass:
                    "button--copy-solid-opposite button--copy-solid-hover-dark",
                  attrs: {
                    loader: _vm.ordering,
                    disabled: _vm.disabled,
                    text: "Order"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.orderGlasses($event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _vm.deleteConfirmation
      ? _c("div", { staticClass: "my-prescription-delete" }, [
          _c("div", { staticClass: "my-prescription-delete__inner" }, [
            _c("p", [
              _vm._v("Are you sure you want to delete this prescription?")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions-toolbar" }, [
              _c(
                "div",
                { staticClass: "secondary" },
                [
                  _c("BaseButton", {
                    staticClass:
                      "button--copy-solid-white button--copy-border-sm delete",
                    attrs: {
                      loader: _vm.deleting,
                      disabled: _vm.disabled,
                      "data-size": "xs",
                      text: "Yes, Delete"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.deletePrescription($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "secondary" },
                [
                  _c("BaseButton", {
                    staticClass:
                      "button--copy-solid-white button--copy-border-sm keep",
                    attrs: {
                      disabled: _vm.disabled,
                      "data-size": "xs",
                      text: "No, Keep"
                    },
                    nativeOn: {
                      click: function($event) {
                        _vm.deleteConfirmation = false
                      }
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Please contact "),
      _c(
        "a",
        {
          attrs: {
            href:
              "mailto:support@lensabl.com?subject=Eye%20Exam%20Prescription%20Request",
            title: "Email Customer Support for your Prescription"
          }
        },
        [_vm._v(" customer support")]
      ),
      _vm._v(" to receive a copy of your prescription.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "two-pupillary-numbers" }, [
      _c(
        "div",
        { staticClass: "my-prescription-form__dropdown-label pupillary" },
        [
          _vm._v(
            "\n                            Prism\n                        "
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }