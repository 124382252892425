<template>
    <div>
        <div class="modal-mask" @click="closeButton()"></div>
        <div class="modal-container">
            <div class="modal-close-button" @click="closeButton()"></div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    methods: {
        closeButton () {
            this.$emit('close')
        }
    }
}
</script>
