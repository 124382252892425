var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.step.isHidden
    ? _c(
        "div",
        {
          directives: [
            {
              name: "touch",
              rawName: "v-touch:swipe.left",
              value: _vm.swipeNext,
              expression: "swipeNext",
              arg: "swipe",
              modifiers: { left: true }
            },
            {
              name: "touch",
              rawName: "v-touch:swipe.right",
              value: _vm.swipePrev,
              expression: "swipePrev",
              arg: "swipe",
              modifiers: { right: true }
            }
          ],
          staticClass: "step-configuration",
          class:
            ((_obj = { "step-configuration-mobile": _vm.isMobileOnly }),
            (_obj[_vm.siblingsStepState] = _vm.siblingsStepState),
            _obj)
        },
        [
          _c(
            "div",
            {
              staticClass: "step-configuration-header",
              class:
                ((_obj$1 = {
                  editable: _vm.step.canEdit,
                  hidden: _vm.step.isHidden
                }),
                (_obj$1["status-" + _vm.step.stepState] = _vm.step.stepState),
                (_obj$1["step-configuration-mobile-header"] = _vm.isMobileOnly),
                _obj$1),
              attrs: {
                id: ["configurator_step_" + _vm.step.id],
                title: ["Select " + _vm.step.label]
              },
              on: { click: _vm.edit }
            },
            [
              _c(
                "span",
                { staticClass: "badge", class: [_vm.step.stepState] },
                [
                  _vm.isMobileOnly
                    ? _c("span", { staticClass: "label" }, [_vm._v("Step")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "number" }, [
                    _vm._v(_vm._s(_vm.step.index))
                  ])
                ]
              ),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.step.label,
                    expression: "step.label"
                  }
                ],
                staticClass: "step-title"
              }),
              _vm._v(" "),
              _vm.step.canEdit
                ? _c("Renderer", {
                    class: "step-selection--value",
                    attrs: { id: _vm.step.id, name: _vm.step.value_renderer }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.step.id === "lens_options" &&
              _vm.siblingsStepState === "ready-prev wait-next" &&
              !_vm.isMobileOnly
                ? _c(
                    "span",
                    [
                      _c("HelpBubble", {
                        attrs: {
                          data: _vm.component_help,
                          placement: "bottom",
                          classes: "lens_option_help_bubble"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step.canEdit
                ? _c("span", { staticClass: "step-edit-button-container" }, [
                    _vm._m(0)
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step.showControls || _vm.isMobileOnly,
                  expression: "step.showControls || isMobileOnly"
                }
              ]
            },
            [
              _vm.step.component
                ? _c(
                    "div",
                    {
                      staticClass: "step-configuration-content-wrapper",
                      class: [_vm.step.id]
                    },
                    [
                      _c(_vm.step.component.name, {
                        tag: "component",
                        class: [
                          "step-configuration-content " +
                            _vm.step.component.name
                        ],
                        attrs: { id: _vm.id }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.step.id === "lens_type" && _vm.values.frames_name === "bose"
                ? _c("div", { staticClass: "bose-note" }, [
                    _vm._v(
                      "*Disclaimer: While\n            Bose Frames support prescription lenses, the product has been designed as a sunglass product, not an\n            all-day prescription eyewear product.\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.step.showControls || _vm.isMobileOnly
                ? _c(
                    "div",
                    { staticClass: "step-actions" },
                    [
                      _vm.isMobileOnly && _vm.backButtonShow
                        ? _c("BaseButton", {
                            staticClass:
                              "button--copy-solid-grey next-step-button ladda-button",
                            attrs: { text: "Back" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.prev($event)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("BaseButton", {
                        staticClass: "next-step-button ladda-button",
                        attrs: {
                          loader: _vm.step.nextStepLoading,
                          text: _vm.step.continueLabel,
                          disabled: _vm.step.disabled
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.next($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "step-edit-button" }, [
      _c("span", [_vm._v("Edit")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }