<template>
    <FeaturedFramesView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import FeaturedFramesView from '../../../components/landing/FeaturedFramesView.vue'
export default {
    name: 'RouterFeaturedFramesView',
    components: {
        FeaturedFramesView
    },
    extends: App
}
</script>

<style scoped>

</style>
