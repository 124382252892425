var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.productView
    ? _c(
        "div",
        {
          staticClass: "spectacles-gen",
          class: { "spectacles-gen-load": !_vm.productConfiguratorShow }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.productView.productInfoShow,
                  expression: "productView.productInfoShow"
                }
              ],
              ref: "spectacles-wrapper",
              staticClass:
                "product-configurator-configuration product-details-holder row spectacles-gen-banner",
              class: {
                "spectacles-gen-banner-media": _vm.media && _vm.media.length
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.media || (_vm.media && !_vm.media.length),
                      expression: "!media || (media && !media.length)"
                    }
                  ],
                  staticClass: "product-name"
                },
                [
                  _c("h1", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: _vm.product_name,
                        expression: "product_name"
                      }
                    ],
                    staticClass: "spectacles-gen-title",
                    class: _vm.product_name,
                    attrs: { itemprop: "name" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("Gallery", { attrs: { "gallery-thumbs-show": false } })
            ],
            1
          ),
          _vm._v(" "),
          _c("AnagramAdBar"),
          _vm._v(" "),
          _vm.productConfiguratorShow
            ? _c(
                "div",
                {
                  staticClass:
                    "product-configurator-progress spectacles-gen-configurator"
                },
                [
                  _c("Configurator", {
                    attrs: { "show-progress-bar": false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "summary",
                          fn: function() {
                            return [
                              !_vm.isMobile && !_vm.isTablet
                                ? _c("SpectaclesGenViewSummary", {
                                    staticClass:
                                      "product-configurator__summary product-configurator-summary"
                                  })
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1267882622
                    )
                  })
                ],
                1
              )
            : _c("div", { staticClass: "loader-container" }, [
                _c("div", { staticClass: "loader-el" }),
                _vm._v(" "),
                _c("div", { staticClass: "spectacles-terms" })
              ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }