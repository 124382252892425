import Vue from 'vue'
import {mapGetters} from 'vuex'
import {StoreApp} from './storage'
import {Routes} from './routes/'
import NotFound from './routes/NotFound.vue'
import _map from 'lodash/map'
import {parseUrl} from 'query-string'
import VueModalTor from 'vue-modaltor'
import VueLadda from 'vue-ladda'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import vuetify from './app/plugins/vuetify'
import {
    eventBus,
    RootState
} from './app/RootState'
import {
    setComputedData
} from './app/utils/buildData'
const GlobalPlugins = {
    install (v) {
        // Event bus
        v.prototype.$bus = eventBus
    }
}

Vue.use(GlobalPlugins)
Vue.use(VueDOMPurifyHTML)
Vue.use(VueModalTor)
Vue.component('vue-ladda', VueLadda)

// Vue.config.errorHandler = function(err, vm, info) {
//     console.log(`Error: ${err.toString()}\nInfo: ${info}`)
// }
// Vue.config.warnHandler = function(msg, vm, trace) {
//     console.log(`Warn: ${msg}\nTrace: ${trace}`);
// }

let storage = new StoreApp()

const Index = () => _map(window.globalConfigurationData || {}, (data, id) => {
    if (document.getElementById(id)) {
        document.body.setAttribute('data-app', 'true')
        const path = data.currentRoute || window.location.pathname
        const appData = {
            ...parseUrl(path).query,
            currentRoute: parseUrl(path).url,
            configData: data.configData || null,
            statusApi: false
        }

        if (!RootState.state.hasOwnProperty(appData.currentRoute)) {
            Vue.set(RootState.state, appData.currentRoute, {})
        }

        if (appData.hasOwnProperty('appId') && appData.appId && !RootState.state[appData.currentRoute].hasOwnProperty(appData.appId)) {
            storage = new StoreApp()
            Vue.set(RootState.state[appData.currentRoute], appData.appId, {})
        }

        return new Vue({
            el: '#' + id,
            vuetify,
            store: storage.store,
            data: () => ({
                appId: 'single',
                ...appData
            }),
            computed: {
                ...mapGetters(['getAppId']),
                ViewComponent () {
                    return Routes[this.currentRoute] || NotFound
                }
            },
            beforeCreate () {
                this.$nextTick(() => {
                    if (this.configData) {
                        this.$store.commit('SET_CURRENT_ROUTE', this.currentRoute)
                        this.$store.commit('SET_ADMIN_HTML', this.configData.adminHtml)
                        if (this.appId) {
                            this.$store.commit('SET_APP_ID', this.appId)
                        }

                        if (this.getAppId) {
                            setComputedData(RootState.state[this.currentRoute], {[this.getAppId]: this.configData})
                        } else {
                            setComputedData(RootState.state[this.currentRoute], this.configData)
                        }
                    }

                    if (process.env.NODE_ENV !== 'test') {
                        // console.log('RootApp', this.$store._vm._uid, this)
                        // console.log('RootState', RootState.state)
                    }
                    this.statusApi = true
                })
            },
            render (h) { return h(this.ViewComponent) }
        })
    }
})

export default new Index()
