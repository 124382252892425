import getHelper from '../helper'
import {defaultOptions, framesTypeCustomize} from "./default";
import {LENS_OPTION_CODE_167, LENS_OPTION_CODE_POLY} from "../lens_options";

export const LIMITERS_DEFAULT_SUN = {
    lens_options_enable_by_lens_type: {
        progressive_standard: {
            'Sun Polarized': [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167]
        },
    },
    enabled_colors: {
        progressive_standard: {
            'Sun Polarized': ['polarized-brown', 'polarized-grey', 'polarized-g15']
        },
    },
}

export default function getSunStepList (params) {
    const defaultStepOptions = defaultOptions(params)
    const {product_config,assets_url} = params
    const {DEFAULT_LAST_STEP} = getHelper(params)

    const lens_type = {
        'id': 'lens_type',
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['computer_lens'],
        'default_value': 'sunglasses_lenses',
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensType.sunglassesLens,
            ]
        }
    }
    const lens_category = {
        'id': 'lens_category',
        'label': 'Choose Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'default_value': null,
        'component': {
            'name': 'selector-lens-category',
            'params': {
                'product_config': product_config
            },
            'options': [
                {
                    'id': 'optical',
                    'label': 'Optical',
                    'summary_label': 'Optical Lenses',
                    'description': 'Clear lenses with blue-light blocking protection',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Clear lenses with blue-light blocking protection</p>'
                        }
                    }
                },
                {
                    'id': 'sun',
                    'label': 'Sun',
                    'summary_label': 'Sun Lenses',
                    'description': 'Tinted and Mirrored lenses available in a variety of colors',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Tinted and Mirrored lenses available in a variety of colors</p>'
                        }
                    }
                },
                {
                    'id': 'night',
                    'label': 'Night',
                    'summary_label': 'Night Lenses',
                    'description': '50% orange tinted lenses with blue light protection to block the HEV rays emitted from digital screens and increase melatonin naturally before bed',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>50% orange tinted lenses with blue light protection to block the HEV rays emitted from digital screens and increase melatonin naturally before bed</p>'
                        }
                    }
                }
            ]
        }
    }
    const frames_type = {
        'id': 'frames_type',
        'label': 'Choose Lenses',
        'summary_label': 'Choose Lenses',
        'no_summary': true,
        'value_renderer': 'renderer-options',
        'default_value': 'frames_customize',
        'component': {
            'name': 'selector-options',
            'options': [
                framesTypeCustomize
            ]
        }
    }

    const lens_options = {
        'id': 'lens_options',
        'label': 'Select Lens Material',
        'summary_label': 'Lens Option',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['sunglass_lens', 'transition_lens'],
        'always_visible': true,
        'component': {
            'name': 'selector-options-lens-option',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensOptions.polyLensOption,
                defaultStepOptions.lensOptions.hi167LensOption,
            ]
        }
    }

    const single_vision_distance = Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP})
    const single_vision_distance_premium  = Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP})
    const single_vision_near = Object.assign({'id': 'single_vision_reading'}, {...DEFAULT_LAST_STEP})
    const single_vision_near_premium  = Object.assign({'id': 'single_vision_reading_premium'}, {...DEFAULT_LAST_STEP})
    const progressive_standard = Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP})
    const progressive_premium = Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP})

    const step_list = [
        lens_type,
        defaultStepOptions.notesOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.chooseLens.prescriptionType,
                    defaultStepOptions.chooseLens.nonPrescriptionType
                ]
            }
        },
        {
            'id': 'nonprescription',
            'label': 'Choose Non-Prescription',
            'summary_label': 'Non-Prescription',
            'value_renderer': 'renderer-options',
            'default_value': 'sunglasses',
            'component': {
                'name': 'selector-options',
                'options': [
                   defaultStepOptions.nonPrescriptionTypes.sunglassesNonPrescription
                ]
            }
        },

        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription
                ]
            }
        },
        frames_type,
        single_vision_distance,
        single_vision_distance_premium,
        single_vision_near,
        single_vision_near_premium,
        progressive_standard,
        progressive_premium,
        lens_options,
        lens_category
    ]
    return step_list
}
