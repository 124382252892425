var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMobile || _vm.isTablet || _vm.isProductConfigured
    ? _c(
        "div",
        {
          staticClass: "step-configuration configuration-finish-container",
          class: {
            "step-configuration-mobile": _vm.isMobileOnly,
            current: _vm.isProductConfigured,
            wait: !_vm.isProductConfigured
          }
        },
        [
          _c(
            "div",
            { staticClass: "step-configuration-content-wrapper finish" },
            [
              _vm.isProductConfigured
                ? [
                    _c("span", { staticClass: "finish-label" }, [
                      _vm._v("Finished!")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "finish-ready-caption" }, [
                      _vm._v("Ready To Order?")
                    ]),
                    _vm._v(" "),
                    _vm.errorMessage
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.errorMessage))
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm._t("content"),
              _vm._v(" "),
              _vm.isProductConfigured
                ? [
                    _c(
                      "div",
                      { staticClass: "finish-actions" },
                      [
                        _c("BaseButton", {
                          staticClass:
                            "restart-configurator final-button button--copy-solid-grey final-add-to-cart-button",
                          attrs: {
                            text: _vm.orderPairButtonText,
                            loader: _vm.loadingRestart
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.restart($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("BaseButton", {
                          staticClass:
                            "checkout final-button final-add-to-cart-button",
                          attrs: {
                            loader: _vm.loadingCheckout,
                            text: _vm.proceedToCheckoutButtonText,
                            disabled: _vm.loadingCheckout
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.checkout($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.isProductConfigured
            ? _c("div", { staticClass: "finish-reset-link-container" }, [
                _c(
                  "a",
                  {
                    staticClass: "finish-reset-link",
                    on: {
                      click: function($event) {
                        return _vm.reset()
                      }
                    }
                  },
                  [_vm._v("Reset")]
                )
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }