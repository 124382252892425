<template>
    <div v-if="showRedeem" :class="{redeemed: isContactsRedeemed}" class="plus-account_container contacts-container">
        <h4 v-if="isMobile" :class="{active: isShow}" @click="toggleContent">Contacts</h4>
        <transition name="slide">
            <div v-if="!isMobile || isShow && isMobile" class="plus-content_container">
                <div>
                    <img src="/js/lensconfigurator/assets/desktop_contacts.svg" alt="contact lenses"/>
                </div>
                <div>
                    <div class="redeem-description">
                        <span class="description">${{ getContactsAllowance }} allowance towards contacts purchase</span>
                        <HelpBubble v-show="!isContactsRedeemed && component_help.show" :data="component_help"/>
                    </div>
                </div>
                <div id="contactsredeem" class="secondary">
                    <BaseButton
                        :disabled="!paidUp"
                        :text="btnText"
                        data-size="xs"
                        :class="{disable: !previousvisit || isContactsRedeemed, 'ladda-button':isContactsRedeemed, 'disabledButton': isContactsRedeemed}"
                        class="button button--copy-solid"
                        @click.native="openContactsRedeem"
                    />
                    <div class="button-square">
                        <span class="square-left"></span>
                        <span class="square-top"></span>
                        <span class="square-right"></span>
                        <span class="square-bottom"></span>
                    </div>
                </div>
            </div>
        </transition>
        <div>
            <label v-if="showHasExtraAllowance" class="decline-benefit" style="width: fit-content;">
                <input id="extra-allowance" title="extra-allowance" type="radio" name="extra-allowance"
                       :checked="usedExtraAllowance" @click="updateAllowance"/>
                <span class="extra-allowance-container">
                                <div class="extra-allowance-label">
                                    <span>Decline this benefit and get an extra ${{getContactsVoucherAmount}} added to your frames/lenses</span>
                                </div>
                            </span>
            </label>
        </div>
        <div class="desktop-notification-card-top-redeem">
            <svg width="350px" height="25px" viewBox="0 0 409 25" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 class="desktop-svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Lensabl+-Dashboard-0-Welcome-2" transform="translate(-1642.000000, -900.000000)"
                       fill="#9BDBDA" fill-rule="nonzero">
                        <path id="Line-5"
                              d="M1666,900.5 L1666,910.5 L2051,910.5 L2051,914.5 L1666,914.5 L1666,924.5 L1642,912.5 L1666,900.5 Z"/>
                    </g>
                </g>
            </svg>
            <svg width="350px" height="50px" viewBox="0 0 408 50" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 class="tablet-svg">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Lensabl+-Dashboard-0-Welcome-1" transform="translate(-1741.000000, -1114.000000)"
                       fill="#9BDBDA" fill-rule="nonzero">
                        <path id="Path-2"
                              d="M2147.42073,1160.18616 C2148.42248,1160.65151 2148.85732,1161.84084 2148.39197,1162.8426 C2147.92661,1163.84436 2146.73728,1164.2792 2145.73552,1163.81384 C2080.40435,1133.46493 2003.33382,1118.57493 1926.60961,1118.89802 C1860.01194,1119.17847 1798.7846,1131.24718 1762.12437,1150.36389 L1767.60992,1158.43017 L1741.01562,1162 L1754.11633,1138.58267 L1759.86075,1147.03397 C1797.12034,1127.45734 1859.16268,1115.18201 1926.59277,1114.89806 C2003.87546,1114.57262 2081.52311,1129.57411 2147.42073,1160.18616 Z"/>
                    </g>
                </g>
            </svg>
            <p class="animate-notification-msg">To use a reward, click the "Redeem" button.</p>
        </div>
        <Redeem
            :title="'Contact Lens'"
            :instruction="instruction"
            :requirements="requirements"
            :is-modal-open="isContactsOpen"
            subtitle="Contacts Benefit"
            @continue="continueToContacts"
            @update="isContactsOpen = false"
        />
        <Loader v-if="loading" />
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import _find from 'lodash/find'
import _size from 'lodash/size'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import BaseButton from '../../../components/base/BaseButton.vue'
import Dropdown from './Dropdown.vue'
import Redeem from './Redeem.vue'
import AbstractRedeem from './AbstractRedeem'
import Loader from '../../loaders/Loader.vue'

export default {
    name: 'BusinessContactsRedeem',
    components: {
        BaseButton,
        Dropdown,
        HelpBubble,
        Redeem,
        Loader
    },
    extends: AbstractRedeem,
    data: () => ({
        component_help: {
            'name': 'help-bubble',
            'params': {
                'text': ''
            },
            'show': true
        },
        isShow: true,
        loading: false,
        redeemed: false,
        isContactsOpen: false,
        requirements: [
            'Your contact lens prescription',
            'Image of your prescription or doctor\'s information'
        ],
        msg: {},
        instruction: 'You will need the following information to complete the redemption:'
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('storeView', ['base_url']),
        ...mapGetters('lensablplus_customer', [
            'getEyeData',
            'plusRightEye',
            'plusLeftEye',
            'isContactsRedeemed',
            'paidUp',
            'getContactsAllowance',
            'getContactsVoucherAmount',
            'getPrefixRedeem',
            'getRedeemItemData',
            'isLensesRedeemed',
            'isContactsRedeemed',
            'isContactsVoucherRedeemed',
            'isVoucherRedeemed'
        ]),
        ...mapState('lensablplus_customer', ['plansInfo', 'cookies_tag_business']),
        ...mapState('gsap', ['previousvisit']),
        btnText() {
            if (!this.paidUp) return 'SUSPEND'
            if (this.isContactsRedeemed) return 'REDEEMED'
            return 'REDEEM'
        },
        contactsInfo() {
            const plan = _find(this.plansInfo, data => data.id === 'prescribed-contacts')
            let info = ''
            if (plan && plan.contactsInfo(this.plusLeftEye, this.plusRightEye)) {
                info = plan.contactsInfo(this.plusLeftEye, this.plusRightEye)
            }
            return '<p>' + info + '</p>'
        },
        showRedeem() {
            const prefix = this.getPrefixRedeem
            const redeemLeft = this.getRedeemItemData(prefix + 'contacts_left')
            const redeemRight = this.getRedeemItemData(prefix + 'contacts_right')
            return Object.keys(redeemRight).length > 0 || Object.keys(redeemLeft).length
        },
        showHasExtraAllowance() {
            return !this.isLensesRedeemed &&
                !this.isVoucherRedeemed &&
                !((this.isContactsRedeemed &&  !this.isContactsVoucherRedeemed) ||
                    (!this.isContactsRedeemed && this.isContactsVoucherRedeemed))
        },
        usedExtraAllowance() {
            return this.isContactsVoucherRedeemed
        }
    },
    watch: {
        // whenever question changes, this function will run
        isTablet: function () {
        }
    },
    beforeCreate() {
        this.$store.dispatch('lensablplus_customer/fetchPlansInfo')
    },
    mounted() {
        const right = (this.plusRightEye.brand || '').replace(/\w\S*/g, (t) => {
            return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
        })
        const left = (this.plusLeftEye.brand || '').replace(/\w\S*/g, (t) => {
            return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
        })
        const msg = {}
        const rightNumber = _size(this.getEyeData.right.images)
        const leftNumber = _size(this.getEyeData.left.images)
        msg['Right Eye (OD)'] = {
            title: right,
            image: rightNumber ? this.getEyeData.right.images[rightNumber - 1].thumbnail : ''
        }
        msg['Left Eye (OS)'] = {
            title: left,
            image: leftNumber ? this.getEyeData.left.images[leftNumber - 1].thumbnail : ''
        }
        this.msg = msg
        this.component_help.params.text = this.contactsInfo
        this.setCookies({type:'redeem_business_contacts', value: true})
    },
    methods: {
        toggleContent() {
            if (this.isMobile) {
                this.isShow = !this.isShow
            }
        },
        continueToContacts() {
            const link = this.base_url + '/contact-lenses'
            this.continueToNextPage(link, 'contacts')
        },
        openContactsRedeem() {
            if (!this.isContactsRedeemed && this.previousvisit) {
                this.isContactsOpen = !this.isContactsOpen
            }
        },
        updateAllowance() {
            this.loading = true
            this.$store.dispatch(
                'lensablplus_customer/sentForRedeemed',
                {type: ['business_contacts_voucher', 'business_contacts_left', 'business_contacts_right'],
                    status: !this.usedExtraAllowance})
                .then(() => this.loading = false)
        }
    }
}
</script>

<style scoped>

</style>
