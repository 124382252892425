var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.componentsCount
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.getLoad,
              expression: "!getLoad"
            }
          ],
          staticClass: "lensabl-plus-progress tabs-header-wrapper"
        },
        [
          _c(
            "ul",
            { ref: "progress", staticClass: "tabs-header" },
            [
              _vm._l(_vm.stepsList, function(tab, index) {
                return [
                  tab
                    ? _c(
                        "li",
                        {
                          key: "tab-" + index,
                          staticClass: "tab",
                          class: [tab.state]
                        },
                        [
                          _c("span", {
                            domProps: { textContent: _vm._s(tab.label) },
                            on: {
                              click: function($event) {
                                return _vm.setActiveStep(index)
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }