var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-category-view categorypath-jamesoro-eyewear" },
    [
      _c(
        "div",
        {
          staticClass: "spectacles-banner frames-banner",
          class: [_vm.categoryClass + "-banner"]
        },
        [
          _c("div", { staticClass: "sb-inner-wrapper" }, [
            _c("div", { staticClass: "sb-inline" }, [
              _c(
                "div",
                { staticClass: "sb-inner", class: [_vm.categoryClass] },
                [
                  _c("a", { attrs: { href: _vm.base_url } }, [
                    _c("h1", { class: [_vm.categoryClass + "-home"] }, [
                      _vm._v(_vm._s(_vm.categoryName))
                    ])
                  ])
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "dompurify-html",
            rawName: "v-dompurify-html",
            value: _vm.breadcrumbsHtml,
            expression: "breadcrumbsHtml"
          }
        ],
        staticClass: "frames-breadcrumbs"
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "category-products frames-products" },
        [
          _vm.categoryLoader
            ? _c("PageLoader", { attrs: { load: _vm.categoryLoader } })
            : [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "products wrapper grid products-grid" },
                    [
                      _c(
                        "ul",
                        { staticClass: "products list items product-items" },
                        _vm._l(_vm.getProducts, function(product) {
                          return _c(
                            "li",
                            {
                              key: product.url,
                              staticClass: "item product product-item"
                            },
                            [
                              _c("ProductItem", {
                                attrs: {
                                  product: product,
                                  showSwatcher: false,
                                  showProductPrice: true,
                                  "show-category-name": true
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ])
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }