var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "category-frames-wrapper frames-wrapper summary-lensesinclude-container"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "category-frames-included lenses-section",
          class: { "lr-lenses-section": _vm.isLensReplacementPage }
        },
        [
          !_vm.isLensReplacementPage
            ? _c("div", { staticClass: "lens-tab" }, [_vm._m(1)])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "summary-lensesinclude-header" }, [
      _c("span", [_vm._v("Lenses Include:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon blue-light" }, [
      _c("span", [_vm._v("Premium Blue-Light Protection")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lens-tab" }, [
      _c("div", { staticClass: "icon ar" }, [
        _c("span", [_vm._v("Anti-Reflective Coating")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lens-tab" }, [
      _c("div", { staticClass: "icon scratch" }, [
        _c("span", [_vm._v("Anti-Scratch Coating")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lens-tab" }, [
      _c("div", { staticClass: "icon uv" }, [
        _c("span", [_vm._v("100% UV Protection")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }