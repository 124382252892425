var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plus-account_container glasses-container" },
    [
      _vm.isMobile
        ? _c(
            "h4",
            { class: { active: _vm.isShow }, on: { click: _vm.toggleContent } },
            [_vm._v(_vm._s(_vm.getTitle))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        !_vm.isMobile || (_vm.isShow && _vm.isMobile)
          ? _c(
              "div",
              { staticClass: "plus-content_container glasses-content" },
              [
                _c("div", { staticClass: "redeem-description" }, [
                  _c("div", { staticClass: "redeem-container" }, [
                    _c("div", [_c("img", { attrs: { src: _vm.redeem.img } })]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "description" }, [
                        _vm._v(
                          "\n                          " +
                            _vm._s(_vm.getTitle) +
                            "\n                        "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "secondary" },
                      [
                        _c("BaseButton", {
                          staticClass: "button button--copy-solid",
                          attrs: {
                            text: _vm.redeem.btnText,
                            "data-size": "xs"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.redirectTo($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }