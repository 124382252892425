<template>
    <div class="category-products" :class="getClass">
        <ul class="products-grid" :class="getClass">
            <li v-for="(category, index) in categories" :key="index" class="item spectacles spectacles-category">
                <CategoryItem :category="category" />
            </li>
        </ul>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import CategoryItem from './CategoryItem.vue'

export default {
    name: 'CategoryList',
    components: {
        CategoryItem
    },
    props: {
        categories: {
            type: [Array],
            default: () => []
        }
    },
    computed: {
        ...mapGetters('storeView', ['base_url']),
        ...mapState('partner', ['currentCategory']),
        ...mapGetters('partner', ['getParentCategory', 'getClass'])
    }
}
</script>
