<template>
    <ul class="action-list">
        <li>
            <Above
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                @checked="$emit('checked', $event)"
            />
        </li>
        <li>
            <InputRender
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                title="Enter Ocular Center Height"
                prescription-key="ocular_center_height"
                @checked="$emit('checked', $event)"
            />
        </li>
        <li>
            <Upload
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                option-name="uploaded_och"
                class="upload-image-online"
                @checked="$emit('checked', $event)"
            />
        </li>
    </ul>
</template>

<script>
import Above from './render/Above.vue'
import InputRender from './render/Input.vue'
import Upload from './render/Upload.vue'

export default {
    name: 'SalesOrderViewPrescriptionOchOptions',
    components: {
        Above,
        InputRender,
        Upload
    },
    props: {
        item: {
            type: [Array, Object],
            default: () => {}
        },
        checked: {
            type: [Boolean, String],
            default: ''
        },
        prescriptionsType: {
            type: String,
            default: ''
        },
        prescriptionIndex: {
            type: Number,
            default: 0
        }
    }
}
</script>
