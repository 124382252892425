<template>
    <div sticky-container>
        <div class="summary_block">
            <div class="summary_block-header">
                <span>Summary</span>
            </div>
            <div v-sticky="shouldStick" :sticky-offset="stickyOffset" class="summary_block-content">
                <SummaryTotalsInfo :place="place" class="totals-info" />
                <SummaryShipping class="standard-shipping-container" />
                <SummaryTotalsDiscount v-if="hasDiscount" />
                <SummaryTotalsPrice v-if="!showFramesPrice && !isLensablPlus" class="totals-price" />
                <SummaryRedeem v-if="isLensablPlus" class="totals-price" />
                <SummaryTotalsPrice v-if="showFramesPrice || isLensablPlus" class="totals-price" />
                <SummaryAffirm v-if="!isRedeemFlow" class="affirm-choose-my-lenses" />
                <SummaryTermsInfo />
                <SummaryLensesInclude :class="{'lensreplacement-summary-lensesinclude-container':isLensReplacementPage}" :is-lens-replacement-page="isLensReplacementPage" />
            </div>
        </div>
    </div>
</template>

<script>
import Summary from '../../../summary/Summary.vue'

export default {
    name: 'SpectaclesGenViewSummary',
    extends: Summary
}
</script>
