<template>
    <div v-if="showDiscount">
        <div class="totals-price-subtotal total-line" :class="className">
            <span class="total-label lplus-label">Member Discount ({{ discountLabelByAction }})</span>
            <span class="total-value value">
                <span class="total-value-currency">-{{ currencySymbol }}</span>
                <span class="total-value-amount">{{ discountTotalByAction }}</span>
            </span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'MemberDiscountSummary',
    data: () => ({
        loading: false
    }),
    props:{
        className: {
            type: String,
            default: ''
      }
    },
    computed: {
        ...mapGetters('membershipProgram', [
            'hasCustomerMembership',
            'discountLabelByAction',
            'discountTotalByAction'
        ]),
        ...mapGetters('values', [
            'currencySymbol',
        ]),
        showDiscount() {
            return !this.loading && this.hasCustomerMembership && this.discountTotalByAction > 0
        }
    },
}
</script>
