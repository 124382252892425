import axios from 'axios'
import _size from 'lodash/size'
import _find from 'lodash/find'
import _map from 'lodash/map'
import {
    setComputedData
} from '../../app/utils/buildData'
import {
    CATEGORY_INTERFACE
} from '../../components/catalog/category/storage/category/view'
import * as abstract from '../abstract'

const MAPPING_FILTERS = {
    'category': 'category_ids',
    'frame_size': 'frames_size',
    'material': 'frames_material'
}
const CONTROLLER = 'lensablall/partner'
const getDefaultState = () => {
    return {
        ...abstract.state(),
        selectedFilters: {},
        childCategories: [],
        categoriesList: [],
        productsList: [],
        filters: {},
        currentCategory: {
            ...CATEGORY_INTERFACE,
            breadcrumb: {},
            class: '',
            max_page: 1,
            child_categories: [],
            parent_category: {
                ...CATEGORY_INTERFACE
            }
        },
        parentCategory: {},
        loading: false,
        loadingBatch: false,
        loadingProducts: false,
        current_id: null,
        page: 1,
        loadProduct: 0,
        isTitle: false,
        pagination:false,
        api: {
            urls: {
                getCategory: `/${CONTROLLER}/getCategory`,
                getCategoriesList: `/${CONTROLLER}/getCategoriesList`
            }
        }
    }
}

// initial state
const state = getDefaultState()

const mutations = {
    ...abstract.mutations,
    resetState: state => setComputedData(state, getDefaultState(), true),
    setLoading: (state, loading = false) => state.loading = loading,
    setLoadingBatch: (state, loadingBatch = false) => state.loadingBatch = loadingBatch,
    setLoadingProducts: (state, loadingProducts = false) => state.loadingProducts = loadingProducts,
    setCurrentCategory: (state, currentCategory = {}) => state.currentCategory = currentCategory,
    setChildCategories: (state, childCategories = []) => state.childCategories = childCategories,
    setCategoriesList: (state, categoriesList = []) => state.categoriesList = categoriesList,
    setProductsList: (state, productsList = []) => state.productsList = productsList,
    setFiltersList: (state, filters = {}) => state.filters = filters,
    setCurrentCategoryId: (state, id = null) => state.current_id = id,
    setIsTitle: (state, isTitle = false) => state.isTitle = isTitle,
    setPage: (state, page = 1) => state.page = page,
    setPagination: (state, pagination = false) => state.pagination = pagination,
    setLoadProduct: (state, loadProduct = 0) => state.loadProduct = loadProduct,
    setParentCategory: (state, parentCategory = {}) => state.parentCategory = parentCategory
}

const actions = {
    ...abstract.actions,
    resetPartnerState: ({commit}) => {
        commit('resetState')
    },
    getCategories: async ({state, getters, commit, rootGetters}) => {
        const filters = rootGetters['category/getFiltered']
        let currentCategoryId = getters.currentCategoryId
        const selectedFilters = {}
        if (filters) {
            _map(Object.entries(filters), ([key, value]) => {
                if (key === 'category') {
                    selectedFilters[MAPPING_FILTERS[key]] = []
                    // if (!value.length) {
                    //     const parentCategory = state.currentCategory.parent_category
                    //     console.log(parentCategory)
                    //     if (_size(parentCategory) && parentCategory.id) {
                    //         currentCategoryId = parentCategory.id
                    //         // selectedFilters[MAPPING_FILTERS[key]] = [currentCategoryId]
                    //     }
                    // }

                    _map(value, url => {
                        const category = _find(getters.getCategoriesList, {url_key: url})
                        if (category) {
                            selectedFilters[MAPPING_FILTERS[key]] = [parseInt(category.value)]
                        }
                    })
                    if (!_size(selectedFilters[MAPPING_FILTERS[key]])) {
                        selectedFilters[MAPPING_FILTERS[key]] = [currentCategoryId]
                    }
                } else {
                    if (_size(filters[key]) && MAPPING_FILTERS[key]) {
                        selectedFilters[MAPPING_FILTERS[key]] = value
                    }
                }
            })
        } else if (currentCategoryId) {
            selectedFilters['category_ids'] = [currentCategoryId]
        }

        if ((selectedFilters['category_ids'] || []).length) {
            const categoryId = parseInt(selectedFilters['category_ids'][selectedFilters['category_ids'].length - 1])
            if (getters.getLoadingBatch && categoryId !== state.loadingBatch) {
                commit('setLoadingBatch', categoryId)
            }
        }

        const params = {
            category_code: currentCategoryId,
            filters: selectedFilters,
            page: getters['getPage'],
            load_product: getters['getLoadProduct'],
            is_pagination: getters['getPagination']
        }
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }
        return await axios.post(state.api.urls.getCategory, params, axiosConfig).then(({data, status}) => {
            let shouldUpdate = true
            if (getters.getLoadingBatch && data.hasOwnProperty('current_category') && (state.loadingBatch !== parseInt(data.current_category.id))) {
                shouldUpdate = false
            }

            if (shouldUpdate) {
                if (status === 200) {
                    if (data.hasOwnProperty('current_category') && _size(data.current_category)) {
                        const productsList = getters.getProductsList
                        const addingProducts = data.current_category.products
                        commit('setCurrentCategory', data.current_category)
                        if (getters.currentCategoryId !== data.current_category.id) {
                            commit('setCurrentCategoryId', data.current_category.id)
                            commit('setProductsList', [...addingProducts])
                        } else {
                            commit('setProductsList', [...productsList, ...addingProducts])
                        }
                    }
                    if (data.hasOwnProperty('child_categories')) {
                        commit('setChildCategories', data.child_categories)
                    }
                    if (data.hasOwnProperty('parent_category')) {
                        commit('setParentCategory', data.parent_category)
                    }
                }
                commit('setLoadingProducts', false)
                commit('setLoadingBatch', false)
            }
        }).catch(error => {
            console.error(error)
            commit('setLoadingProducts', false)
            commit('setLoadingBatch', false)
        })
    },
    getNextProducts: ({state, getters, commit, dispatch}) => {
        if (getters.getPage < getters.getMaxPage) {
            commit('setPage', getters.getPage + 1)
            commit('setLoadingBatch', parseInt(state.currentCategory.id) || true)
            return dispatch('getCategories')
        }
    },
    fetchCategoriesList: async ({state, getters, commit}) => {
        try {
            const {data, status} = await axios.get(`${state.api.urls.getCategoriesList}?category_code=${getters['currentCategoryId']}`)
            if (status === 200) {
                commit('setCategoriesList', data.categories)
                if (_size(data.filters)) {
                    commit('setFiltersList', {categories: data.categories, ...data.filters})
                }
            }
            commit('setLoading', false)
            return data
        } catch (error) {
            throw new Error(error)
        }
    },
    addFiltersToHistory: ({state, getters, rootGetters}) => {
        const filters = rootGetters['category/getFiltered']
        const keys = Object.keys(filters)
        if (!state.currentCategory.url) {
            return
        }
        let url = state.currentCategory.url + '?'
        for (const key of keys) {
            if (key === 'category') {
                if (filters[key].length > 1) {
                    filters[key].splice(-filters[key].length + 1)
                }
            } else if (filters[key].length) {
                const part = key + '=' + filters[key] + '&'
                url = url.concat(part)
            }
        }
        history.pushState({}, 'Filter apply', url.substring(0, url.length - 1))

        const meta = getters.currentMetaData
        const metaKeys = Object.keys(meta)

        for (const key of metaKeys) {
            const metaTags = document.querySelectorAll('meta[property="' + key + '"') || {}
            const data = meta[key] || getters.parentMetaData[key] || ''

            if (Object.keys(metaTags).length) {
                metaTags.forEach(element => {
                    element.setAttribute('content', data)
                })
            } else if (key === 'title') {
                document.title = data
            } else {
                const metaTags = window.document.querySelectorAll('meta[name="' + key + '"')
                metaTags.forEach(element => {
                    element.setAttribute('content', data)
                })
            }
        }
    }
}

const getters = {
    currentCategory: state => state.currentCategory,
    getPage: state => state.page,
    getIsTitle: state => state.isTitle,
    getMaxPage: state => state.currentCategory.max_page,
    currentMetaData: state => state.currentCategory.meta_data,
    parentMetaData: state => _size(state.currentCategory.parent_category) ? state.currentCategory.parent_category.meta_data : {},
    getPagination: state => state.pagination,
    getLoadProduct: state => state.loadProduct,
    getChildCategories: state => state.childCategories,
    getParentCategory: state => state.parentCategory,
    getLoading: state => state.loading,
    getLoadingBatch: state => Boolean(state.loadingBatch),
    getLoadingProducts: state => Boolean(state.loadingProducts),
    getCategoriesList: state => state.categoriesList,
    getProductsList: state => state.productsList,
    getFiltersList: state => state.filters,
    getSelectedFilters: state => state.selectedFilters,
    currentCategoryId: state => state.current_id,
    getClass: (state, getters) => {
        return getters.getParentCategory.hasOwnProperty('class') ? getters.getParentCategory.class : state.currentCategory.class
    },
    getName: (state, getters) => {
        return getters.getParentCategory.hasOwnProperty('name') ? getters.getParentCategory.name : state.currentCategory.name
    }
}

export default () => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
