<template>
    <div v-if="stepsCount" class="progress-bar">
        <div v-sticky="shouldStick" :sticky-offset="stickyOffset" :sticky-z-index="100" class="steps">
            <div :class="{'steps-bar-mobile': isMobile}" class="steps-bar">
                <div v-if="isMobile" class="steps-bar-mobile-summary">
                    <ContactsTotalsPrice class="totals-price" />
                    <a :class="{active: mobileNavShow}" href="javascript:void(0)" class="action product-configurator--nav-link" @click="mobileNavToggle()"><span>Order Details</span></a>
                </div>

                <template v-if="stepsCount > 1">
                    <div v-if="isDesktop || isMobile" :class="{'steps-bar-mobile-items': isMobile}" class="steps-bar-items">
                        <template v-for="step in visibleList">
                            <div v-if="!step.isHidden" :key="step.id" :class="{highlight: step.shouldHighlight, ready: step.isReady, current: step.isCurrent, editable: step.canEdit, [step.id]: step.id }" class="item" @click="editStep(step)">
                                <span class="item-bage">{{ step.index }}</span>
                                <span class="item-label">{{ step.progress_label }}</span>
                            </div>
                        </template>
                    </div>

                    <div v-if="isTablet && currentStep && !currentStep.isHidden" class="steps-bar-items">
                        <div :class="{highlight: currentStep.shouldHighlight, ready: currentStep.isReady, current: currentStep.isCurrent, editable: currentStep.canEdit, [currentStep.id]: currentStep.id }" class="item">
                            <span class="arrow prev" role="navigation" aria-label="Edit Previous Step" title="Edit Previous Step" @click="previousStep(currentStep)">Prev</span>

                            <span class="item-bage">{{ currentStep.index }}</span>
                            <span class="item-label">{{ currentStep.progress_label }}</span>

                            <span class="arrow next" role="navigation" aria-label="Next Step" title="Next Step" @click="nextStep(currentStep)">Next</span>
                        </div>
                    </div>
                </template>

                <template v-if="isMobile">
                    <template v-if="isProductConfigured">
                        <Finish :proceed-to-checkout-button-text="'Checkout'" :order-pair-button-text="'Another Pair?'" class="steps-bar-finish" />
                    </template>
                    <template v-else>
                        <div v-if="mobileNavShow" class="summary_block">
                            <div class="summary_block-header">
                                <span>Summary</span>
                            </div>
                            <div class="summary_block-content">
                                <SummaryTotalsInfo :place="place" class="totals-info" />
                            </div>
                        </div>
                    </template>
                </template>
            </div>
            <div v-if="isMobile && mobileNavShow" class="steps-bar-order-details">
                <a :class="{active: mobileNavShow}" href="javascript:void(0)" class="action product-configurator--nav-toggle steps-bar-order-details-close" @click="mobileNavToggle()"><span>Close</span></a>
                <div class="steps-bar-order-details-header">
                    <span>Order Details</span>
                </div>
                <div class="summary_block steps-bar-order-details-summary">
                    <div class="summary_block-content">
                        <SummaryContactsInfo />
                        <SummaryTotalsInfo :place="place" class="totals-info" />
                        <SummaryTotalsDiscount v-if="hasDiscount" />
                        <Subscribe v-if="enable_subscription === 1 && showSubscribe" />
                        <ContactsTotalsPrice v-if="!showFramesPrice" class="totals-price" />
                        <SummaryAffirm v-if="getSubscribed === 'no'" class="affirm-choose-my-lenses" />
                        <ContactsTotalsPrice v-if="showFramesPrice" class="totals-price" />
                    </div>
                </div>
                <SummaryShipping class="standard-shipping-container" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ProgressBar from '../../configurator/ProgressBar.vue'
import ContactsTotalsPrice from '../summary/TotalsPrice.vue'
import SummaryAffirm from '../summary/Affirm.vue'
import Subscribe from '../Subscribe.vue'

export default {
    name: 'ContactsProgressBar',
    components: {
        ContactsTotalsPrice,
        SummaryAffirm,
        Subscribe
    },
    extends: ProgressBar,
    data: () => ({
        showSubscribe: true
    }),
    computed: {
        ...mapGetters('contacts', ['getSubscribed', 'getEyeData', 'getLeftEyeConfirmed', 'getRightEyeConfirmed']),
        ...mapGetters('configurator', ['enable_subscription']),
        stickyOffset () {
            const top = 0
            return `{top: ${top}, bottom: -1000}`
        }
    },
    mounted () {
        if (this.getLeftEyeConfirmed && this.getRightEyeConfirmed) {
            this.showSubscribe = this.getEyeData.left.brand === this.getEyeData.right.brand
        }
    }
}
</script>
