var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.componentsCount
    ? _c(
        "div",
        {
          staticClass: "lensabl-plus-content-wrapper struct-parallax-bg",
          class: { load: _vm.getLoad }
        },
        [
          _vm.getLoad
            ? _c("PageLoader", { attrs: { load: _vm.getLoad } })
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "lensabl-plus-content" },
                  [
                    _vm._l(_vm.componentsList, function(object) {
                      return [
                        _c(object.component.name, {
                          key: object.id,
                          tag: "component",
                          class: [
                            "lensabl-plus-content " + object.component.name
                          ],
                          attrs: { id: object.id }
                        })
                      ]
                    }),
                    _vm._v(" "),
                    _c("Back")
                  ],
                  2
                )
              ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }