var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "search-component" }, [
    _c("div", { staticClass: "search-component-container" }, [
      _vm.confirmedEye === "right"
        ? _c("div", { staticClass: "left-dir" }, [
            _c("h1", [_vm._v("Now select your left eye")]),
            _vm._v(" "),
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.goToDeatils(_vm.right)
                  }
                }
              },
              [_vm._v("skip")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.confirmedEye === "left"
        ? _c("div", { staticClass: "left-dir" }, [
            _c("h1", [_vm._v("Now select your right eye")]),
            _vm._v(" "),
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.goToDeatils(_vm.right)
                  }
                }
              },
              [_vm._v("skip")]
            )
          ])
        : _vm.confirmedEye === true
        ? _c("div", [
            _c("h1", [_vm._v("Contact Lenses")]),
            _vm._v(" "),
            _c("h2", [_vm._v("At Amazingly Low Prices")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "search-container" }, [
        _c("div", { staticClass: "search-container-main" }, [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchBrand,
                  expression: "searchBrand"
                }
              ],
              ref: "search",
              attrs: {
                id: "category-search",
                type: "text",
                placeholder: "Enter your prescribed contacts brand"
              },
              domProps: { value: _vm.searchBrand },
              on: {
                focus: function($event) {
                  _vm.searchResultsVisible = true
                },
                keydown: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.blur($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.highlightPrevious($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.highlightNext($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.goToLink($event)
                  }
                ],
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchBrand = $event.target.value
                  },
                  _vm.softRest
                ],
                keyup: _vm.performSearch,
                blur: function($event) {
                  _vm.setHighlightIndex(), (_vm.searchResultsVisible = false)
                }
              }
            })
          ]),
          _vm._v(" "),
          _vm.searchBrand.length > 0 && _vm.searchResultsVisible
            ? _c("div", { staticClass: "results-dropdown" }, [
                _c(
                  "ul",
                  { attrs: { id: "results" } },
                  [
                    _vm._l(_vm.searchResults, function(products, index) {
                      return _c(
                        "a",
                        {
                          key: index,
                          class: { active: index === _vm.highlightedIndex },
                          attrs: { href: products.url },
                          on: {
                            mousedown: function($event) {
                              $event.preventDefault()
                              _vm.searchResultsVisible = true
                            },
                            mouseover: function($event) {
                              return _vm.hover(index)
                            }
                          }
                        },
                        [
                          _c("li", {
                            directives: [
                              {
                                name: "dompurify-html",
                                rawName: "v-dompurify-html",
                                value: _vm.collection[index],
                                expression: "collection[index]"
                              }
                            ]
                          })
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.searchResults.length > 0
                      ? _c(
                          "li",
                          {
                            staticClass: "view-all",
                            class: {
                              active:
                                _vm.highlightedIndex ===
                                _vm.searchResults.length
                            },
                            on: {
                              click: _vm.displayProducts,
                              mousedown: function($event) {
                                $event.preventDefault()
                                _vm.searchResultsVisible = true
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            View All Results\n                        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.searchResults.length === 0
                      ? _c("li", { staticClass: "no-results" }, [
                          _vm._v(
                            "\n                            No results for "
                          ),
                          _c("strong", [_vm._v(_vm._s(_vm.searchBrand))])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "button button--copy-solid",
            on: { click: _vm.displayProducts }
          },
          [
            _c("div", { staticClass: "button__container" }, [
              _vm._v("Search Brands")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.getSearched
        ? _c("div", { staticClass: "search-results-display" }, [
            _vm._v("Showing all results for "),
            _c("span", [_vm._v('"' + _vm._s(_vm.getSearched) + '"')])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }