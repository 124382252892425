var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-product-gallery-mobile" },
    [
      _c(
        "carousel",
        { attrs: { "per-page": 1, "mouse-drag": true } },
        [
          _vm._l(_vm.images, function(image, index) {
            return [
              index < 2
                ? _c(
                    "slide",
                    {
                      key: image.index,
                      staticClass: "c-product-gallery-mobile-slide"
                    },
                    [
                      _c("img", {
                        attrs: { src: image.thumbnail, alt: _vm.name }
                      })
                    ]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }