<template>
    <div v-show="isCurrent">
        <h2 v-if="config.title" v-dompurify-html="config.title" class="title"></h2>

        <div class="plans-list-wrapper">
            <div class="border-1"></div>
            <div class="border-2"></div>
            <div class="plans-list-container">
                <TotalsInfo />
                <TotalsPrice />
                <SaveUp />

                <div class="actions-toolbar">
                    <BaseButton
                        :loader="ajaxLoad"
                        :disabled="disabledCheckout"
                        class="checkout final-button"
                        text="Sign Up"
                        @click.native="checkout"
                    />

                    <div v-if="disabledCheckout" class="error-text-rx">
                        <span v-text="errorText"></span>
                    </div>
                    <div v-if="isPlusQuote" class="error-text-rx">
                        <span v-text="isPlusQuoteErrorText"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import TotalsInfo from '../summary/TotalsInfo.vue'
import TotalsPrice from '../summary/TotalsPrice.vue'
import SaveUp from '../summary/SaveUp.vue'
import BaseButton from '../../base/BaseButton.vue'

export default {
    name: 'Finish',
    components: {
        TotalsInfo,
        TotalsPrice,
        SaveUp,
        BaseButton
    },
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        errorText: 'Each account can only have one Lensabl+ membership. To add another membership, please create a separate account.',
        isPlusQuoteErrorText: 'Each account can only have one Lensabl+ membership. To add another membership, please create a separate account.',
        ajaxLoad: false,
        disabledCheckout: false,
        activeIndex: null
    }),
    computed: {
        ...mapGetters('lensablPlus', ['getObjectConfig', 'currentComponent', 'currentPlan', 'isPlusCustomer', 'isPlusQuote']),
        config () {
            return this.getObjectConfig(this.id)
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        }
    },
    methods: {
        ...mapActions('lensablPlus', ['addCurrentPlanToCart']),
        checkout () {
            this.ajaxLoad = true
            this.$store.dispatch('lensablPlus/fetchIsPlusCustomer')
                .then(() => {
                    if (!this.isPlusQuote && !this.isPlusCustomer) {
                        this.addCurrentPlanToCart()
                            .then(() => {
                                document.location = '/onepage'
                            })
                            .catch(error => {
                                this.ajaxLoad = false
                                console.error(error)
                            })
                    } else {
                        this.ajaxLoad = false
                        if (!this.isPlusQuote) {
                            this.$nextTick(() => {
                                this.disabledCheckout = true
                            })
                        }
                    }
                })
                .catch(e => {
                    console.log(e)
                    this.ajaxLoad = false
                })
        }
    }
}
</script>

<style lang="less" scoped>
    @media only screen and (max-width: 919px) {
        .product-configurator .button, .action.primary {
            max-width: 24rem;
            width: 100%;
        }
    }
</style>
