import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererColor',
    extends: RendererOptions,
    computed: {
        renderer () {
            return this.checkoutData.lens_type ? this.checkoutData.lens_type.color_name ?  this.checkoutData.lens_type.color_name : this.checkoutData.lens_type.color : ''
        }
    }
}
