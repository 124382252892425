<template>
    <div>
        <div v-show="productView.productInfoShow" :class="{'product-layout-2columns': isDesktop}"
             class="product-details-holder lensabl-frames-view frames-wrapper">
            <div class="product media">
                <Gallery :class="{'vertical-thumbs': isDesktop}"/>
            </div>
            <div class="product-info-main">
                <div class="page-title-wrapper product">
                    <h1 v-dompurify-html="product_name" :class="product_name" class="page-title" itemprop="name"></h1>
                </div>
                <AdditionalAttributes :class="{'inline': !isDesktop && !isMobile, 'grid': isMobile}" :data-attributes="additionalAttributes" />

                <ProductAffirmInfo :startingTitle="`Starting from $`" />
                <template v-if="colors.length">
                    <div class="swatch-opt">
                        <ColorSwatches
                            :colors="colors"
                            :checked-index="checkedIndex"
                            :option-item-class="'decorate bigger img'"
                            :product-name="product_name.toLowerCase()"
                            @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColorAndReset"
                        />
                    </div>

                    <div v-if="isAvailable" class="product-options-bottom">
                        <div class="actionsproductCategoryName">
                            <BaseButton :text="'Choose Your Lenses'"
                                        @click.native="isMobile ? mobileSwitcher() : goToConfigurator()">
                                Select Lenses
                            </BaseButton>
                        </div>
                    </div>
                    <p v-if="!isAvailable" class="unavailable stock" title="Availability">
                        <span style="color:red">This item is sold out</span>
                    </p>
                </template>
            </div>
        </div>
        <div class="product-border-divider"></div>
        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator />
        </div>
        <ReturnsInfo v-if="productView.productInfoShow"/>
    </div>
</template>

<script>
import CatalogProductView from "./CatalogProductView.vue";
import HelpBubble from "../../help_bubble/HelpBubble.vue";
import {mapGetters} from "vuex";
import ResetService from "../../../app/mixins/reset-service";

export default {
    name: 'CatalogProductBlockView',
    components: {HelpBubble},
    mixins: [ResetService],
    extends: CatalogProductView,
    computed: {
        ...mapGetters('storeView', ['assets_url']),
        size_help () {
            const size = this.getCurrentProductAttribute('size')
            if (!size){
                return {}
            }
            const url = this.assets_url + size + '.jpg'
            if(this.imgExist(url)){
                return {
                    'name': 'help-bubble',
                    'params': {
                        'text': '<img src="' + url + '" />'
                    }
                }
            }else{
                return {}
            }
        },
        additionalAttributes () {
            return [
                {
                    label: 'Frame Color',
                    value: this.colorName.toLowerCase()
                },
                {
                    label: 'Size',
                    value: this.getCurrentProductAttribute('size'),
                    component_help: this.size_help,
                    help_placement: this.isDesktop ? 'left-center' : 'top'
                },
            ]
        },
    },
    methods: {
        changeCurrentColorAndReset(index){
            this.changeCurrentColor(index)
            this._restartSteps()
        }
    }
}
</script>
