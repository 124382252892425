var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.attributes.length
    ? _c(
        "ul",
        { staticClass: "additional-attributes" },
        [
          _vm._l(_vm.attributes, function(attribute) {
            return [
              attribute.value
                ? _c(
                    "li",
                    {
                      key: attribute.label,
                      staticClass: "item",
                      class: attribute.itemClassName
                    },
                    [
                      _c("span", {
                        staticClass: "label",
                        domProps: { textContent: _vm._s(attribute.label) }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: attribute.value,
                            expression: "attribute.value"
                          }
                        ],
                        staticClass: "data"
                      }),
                      _vm._v(" "),
                      _c("HelpBubble", {
                        attrs: {
                          placement: attribute.help_placement,
                          data: attribute.component_help
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }