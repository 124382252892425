var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", "anagram-container": "", admin: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("RadioLabel", {
            attrs: { active: _vm.hasInsurance, label: "has vision insurance" },
            on: { click: _vm.changeRadioValue }
          }),
          _vm._v(" "),
          _c("RadioLabel", {
            attrs: {
              active: !_vm.hasInsurance,
              label: "is covered under primary’s vision insurance plan"
            },
            on: { click: _vm.changeRadioValue }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasInsurance
        ? _c("HasInsuranceForm", {
            attrs: { "form-model": _vm.getHasInsuranceForm }
          })
        : _c("HasNotInsuranceForm", {
            attrs: { "form-model": _vm.getHasNotInsuranceForm }
          }),
      _vm._v(" "),
      _vm.error
        ? _c("p", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.errorText))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.success
        ? _c("p", { staticStyle: { color: "red" } }, [
            _vm._v("Customer's request has been submitted successfully.")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }