import axios from 'axios'

const CONTROLLER_ROOT = 'plus/location/'

const getDefaultState = () => {
    return {
        doctors: [],
        total: 0,
        page: 0,
        pages: 1
    }
}

const state = getDefaultState()
const mutations = {
    RESET_STATE: (state) => Object.assign(state, getDefaultState()),
    SET_DOCTORS: (state, doctors = []) => state.doctors = doctors,
    SET_TOTAL_DOCTORS: (state, total = 0) => state.total = total,
    SET_PAGE: (state, page = 0) => state.page = page,
    SET_PAGES: (state, pages = 1) => state.pages = pages
}
const actions = {
    resetDoctorsState: ({commit}) => {
        commit('RESET_STATE')
    },
    getDoctorsBy: ({getters, commit, rootGetters}, {zipcode, radius}) => {
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER_ROOT}find`
        const nextPages = getters.getPage + 1
        if (nextPages > getters.getPages) {
            return new Promise((resolve, reject) => {
                resolve()
            })
        }
        const params = {
            'zipcode': zipcode,
            'radius': radius,
            'page': nextPages
        }
        return axios.post(url, params).then(({data, status}) => {
            if (status === 201) {
                const doctors = getters.getDoctors
                commit('SET_DOCTORS', [...doctors, ...data.items])
                commit('SET_TOTAL_DOCTORS', data.totalRecords)
                commit('SET_PAGES', data.pages)
                commit('SET_PAGE', nextPages)
            }
        }).catch(error => {
            console.error(error)

        })
    },
    getCustomerData: ({getters, commit, rootGetters}) => {
        const url = `${rootGetters['storeView/base_url']}${CONTROLLER_ROOT}getCustomerData`
        return axios.get(url).catch(error => {
            console.error(error)
        })
    }
}
const getters = {
    getDoctors: state => state.doctors,
    getPage: state => state.page,
    getPages: state => state.pages
}

export default store => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
