<template>
    <div class="plus-totals-summary">
        <div class="summary-block-header">
            <h3>Order Summary</h3>
        </div>
        <div v-for="(item, index) in ITEMS" :key="index">
            <div class="plus-total-container">
                <div class="plus-total">
                    <span>Total Vision Plan Cost<span class="desktop"> (Per Year)</span>: </span><span
                    v-dompurify-html="item.full_price_html"></span>
                </div>
                <p class="price-label">
                    Starting at $<span v-dompurify-html="getAffirmPrice"></span>/mo with
                    <span
                        class="__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed">Affirm</span>.
                    <a href="javascript:void(0)" @click="affirmModalShow()">Learn more</a>
                </p>
                <!--<div class="plus-total">-->
                <!--    <p class="pay-subtitle">Save {{ discount }}% if paid in full (annual) today</p>-->
                <!--</div>-->
                <!--<div class="toggle-container">-->
                <!--    <span class="toggle-title"><strong>Choose Payment Frequency</strong></span>-->
                <!--    <div class="price-toggle">-->
                <!--        <input-->
                <!--            :id="'full' + item.id"-->
                <!--            v-model="QUARTERLY_ITEMS[item.id]"-->
                <!--            :class="{'not-checked': QUARTERLY_ITEMS[item.id] === 1}"-->
                <!--            :title="'full' + + item.id"-->
                <!--            :value="0"-->
                <!--            class="p-toggle toggle-left"-->
                <!--            name="toggle"-->
                <!--            type="radio"-->
                <!--            @change="() => changePayPeriod(item.id, 0)"-->
                <!--        />-->
                <!--        <label :for="'full' + item.id" class="btn-toggle">Annual</label>-->

                <!--        <input-->
                <!--            :id="'quarterly' + item.id"-->
                <!--            v-model="QUARTERLY_ITEMS[item.id]"-->
                <!--            :class="{'not-checked': QUARTERLY_ITEMS[item.id] === 0}"-->
                <!--            :title="'quarterly' + + item.id"-->
                <!--            class="p-toggle toggle-right"-->
                <!--            name="toggle"-->
                <!--            :value="1"-->
                <!--            type="radio"-->
                <!--            @change="() => changePayPeriod(item.id, 1)"-->
                <!--        />-->
                <!--        <label :for="'quarterly' + item.id" class="btn-toggle">Every 3 Months</label>-->
                <!--    </div>-->
                <!--    <div class="toggle-price-container">-->
                <!--        <p v-if="QUARTERLY_ITEMS[item.id] === 0" v-dompurify-html="item.annual_price_html"></p>-->
                <!--        <p v-else v-dompurify-html="item.quarterly_price_html"></p>-->
                <!--        <p v-if="QUARTERLY_ITEMS[item.id] === 0"  class="pay-subtitle">Equivalent to <span v-dompurify-html="item.month_annual_html"></span> per month</p>-->
                <!--        <p v-else  class="pay-subtitle">Equivalent to <span v-dompurify-html="item.month_price_html"></span> per month</p>-->
                <!--    </div>-->
                <!--</div>-->
            </div>
            <Coupon/>
        </div>
        <div class="totals">
            <div class="summary-totals">
                <div v-dompurify-html="TOTALS_HTML.total"></div>
                <div v-dompurify-html="TOTALS_HTML.total_footer"></div>
            </div>
        </div>
        <BaseButton
            text="Place order"
            idName="place-order"
            :disabled="isPlaceOrderDisabled"
            :loader="isLoadButton"
            @click.native="prepareCheckoutForm"
        />
        <p class="plus-disclaimer">By checking out you are agreeing to our <a href="/returns">terms &amp; conditions and
            cancellation policy</a></p>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import BaseButton from '../../base/BaseButton.vue'
import Coupon from './Coupon.vue'

export default {
    name: 'Summary',
    components: {
        BaseButton,
        Coupon
    },
    data: () => ({
        loadButton: false,
        disabledButton: false,
        option: 'totalAnnualPrice'
    }),
    computed: {
        ...mapState('checkoutPlus', ['totalAnnualDiscount']),
        ...mapGetters('checkoutPlus', [
            'LOADING_SUMMARY',
            'TOTALS_HTML',
            'ITEMS',
            'QUARTERLY_ITEMS',
            'SELECTED_PAYMENT_METHOD',
            'INVALID_FORM',
            'SIGN_IN',
            'isPlusCustomer',
            'isPlusQuote',
            'placeOrderDisabled',
            'getFormatPrice',
            'ADDRESS_FORM_ERRORS',
            'BILLING_ADDRESS',
            'getAffirmPrice'
        ]),
        discount() {
            return this.totalAnnualDiscount * 100
        },
        isLoadButton() {
            return this.loadButton || this.LOADING_SUMMARY
        },
        isPlaceOrderDisabled() {
            return this.disabledButton || this.isLoadButton || this.placeOrderDisabled || this.ADDRESS_FORM_ERRORS.email
        },
        label() {
            return this.option === 'totalAnnualPrice' ? 'year' : 'month'
        },
    },
    updated() {
        const elementLinneo = document.getElementById('linneo-cancel')
        if (elementLinneo) {
            elementLinneo.addEventListener('click', this.cancelLinneoRedemption)
        }
    },
    mounted() {
        this.$store.commit('checkoutPlus/SET_LOADING_SUMMARY', true)
        this.$store.dispatch('checkoutPlus/GET_TOTALS')
        this.$store.dispatch('checkoutPlus/fetchIsPlusCustomer')
    },
    methods: {
        ...mapActions('checkoutPlus', ['GET_TOTALS', 'cancelLinneoRedemption']),
        changePayPeriod(id, val) {
            const quarterlyItems = this.QUARTERLY_ITEMS
            quarterlyItems[id] = val
            this.$store.commit('checkoutPlus/SET_QUARTERLY_ITEMS', quarterlyItems)
            this.$store.dispatch('checkoutPlus/CHANGE_PAY_PERIOD')
        },
        getItemsList() {
            const itemList = []
            this.ITEMS.forEach((item) => {
                itemList.push(item.name)
            })
            return itemList.join(',')
        },
        prepareCheckoutForm() {
            this.$store.dispatch('checkoutPlus/VALIDATION_FORM')

            if (!this.INVALID_FORM && !this.isPlaceOrderDisabled) {
                const checkoutForm = document.getElementById('onestepcheckout-form')
                const _this = this
                if (this.SELECTED_PAYMENT_METHOD === 'recurly') {
                    document.querySelector("input[name='payment[cc_type]']").value = window.cc_type
                    window.recurly.token(checkoutForm, function (err, token) {
                        if (err) {
                            console.log(err)
                            const fields = (err.fields).join(', ')
                            alert('The following fields are invalid:' + ' ' + fields)
                        } else {
                            _this.loadButton = true
                            _this.submitCheckoutForm(checkoutForm)
                        }
                    })
                } else if (this.SELECTED_PAYMENT_METHOD === 'recurlypaypal') {
                    const paypal = window.recurly.PayPal({
                        display: {displayName: this.getItemsList()}
                    })
                    paypal.start()

                    paypal.on('error', function (err) {
                        console.log(err)
                        alert(err.message)
                    })

                    paypal.on('token', function (token) {
                        _this.loadButton = true
                        _this.submitCheckoutForm(checkoutForm)
                    })
                } else if (this.SELECTED_PAYMENT_METHOD === 'stripe') {
                    const billingAddress = {
                        name: `${this.BILLING_ADDRESS.first_name} ${this.BILLING_ADDRESS.last_name}`,
                        email: this.BILLING_ADDRESS.email,
                        phone: this.BILLING_ADDRESS.telephone,
                        address_line1: this.BILLING_ADDRESS.address1,
                        address_line2: this.BILLING_ADDRESS.address2,
                        address_city: this.BILLING_ADDRESS.city,
                        address_state: this.BILLING_ADDRESS.state,
                        address_country: this.BILLING_ADDRESS.country,
                        address_zip: this.BILLING_ADDRESS.postal_code,

                    }
                    window.loadedStipe.createToken(window.stripeCard, billingAddress)
                        .then(function (result) {
                            if (result.error) {
                                const errorElement = document.getElementById('card-errors');
                                errorElement.textContent = result.error.message;
                            } else {
                                if (result.token) {
                                    const token = result.token
                                    const card = token.card
                                    // token
                                    const tokenElement = document.getElementById('stripe-token');
                                    tokenElement.value = token.id;
                                    //brand
                                    const brandElement = document.getElementById('stripe-brand');
                                    brandElement.value = card.brand;
                                    // last4
                                    const last4Element = document.getElementById('stripe-last4');
                                    last4Element.value = card.last4;
                                    _this.loadButton = true
                                    _this.submitCheckoutForm(checkoutForm)
                                }
                            }
                        });

                } else {
                    _this.loadButton = true
                    _this.submitCheckoutForm(checkoutForm)
                }
            }
        },
        submitCheckoutForm(checkoutForm) {
            checkoutForm.submit()
        },
        affirmModalShow() {
            if (window.affirm) {
                const ui = window.affirm['ui'] || {}
                if (ui.openModal) {
                    ui.openModal()
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.toggle-container {
    display: grid;
    grid-template-columns: 239px 1fr;
    margin: 1em 0 1.5em;

    .price-toggle {
        grid-column-start: 1;
        @media screen and (max-width: 499px) {
            max-width: 228px;
        }
    }

    .toggle-title {
        color: #303949;
        grid-row-start: 1;
        grid-column-start: 1;
    }

    .toggle-price-container {
        align-self: center;

        p {
            margin-bottom: 0;
            text-align: right;
        }
    }
}
</style>
