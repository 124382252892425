export const ERROR_TOUCHED = {
    name: false,
    birthday: false,
    email: false,
    password: false,
    confirm_password: false,
    telephone: false,
    city: false,
    country: false,
    postcode: false,
    region: false,
    street1: false,
    street2: false
}

export const DEFAULT_VALUES = {
    name: '',
    email: '',
    birthday: '',
    telephone: '',
    street1: '',
    street2: '',
    country: 'United States',
    city: '',
    region: '',
    postcode: '',
    password: '',
    confirm_password: ''
}

export const FORM_OBJECT = {
    name: {
        name: 'name',
        label: 'Name',
        required: true,
        type: 'text'
    },
    email: {
        name: 'email',
        label: 'Email',
        required: true,
        type: 'text',
        message: 'Please enter a valid email'
    },
    birthday: {
        name: 'birthday',
        label: 'Birthday',
        required: true,
        type: 'text',
    },
    telephone: {
        name: 'telephone',
        label: 'Phone Number',
        required: true,
        type: 'text',
        message: 'Please exclude dashes',
        length: '10'
    },
    street1: {
        name: 'street1',
        label: 'Address Line 1',
        type: 'text',
        required: true
    },
    street2: {
        name: 'street2',
        label: 'Address Line 2',
        type: 'text',
        required: false
    },
    country: {
        name: 'country',
        label: 'Country',
        required: true,
        type: 'text',
        disabled: true
    },
    city: {
        name: 'city',
        label: 'City',
        required: true,
        type: 'text'
    },
    region: {
        name: 'region',
        label: 'State',
        required: true,
        type: 'select',
        options: []
    },
    postcode: {
        name: 'postcode',
        label: 'Zip/Postal Code',
        required: true,
        type: 'text'
    },
    password: {
        name: 'password',
        label: 'New Password',
        required: true,
        type: 'password'
    },
    confirm_password: {
        name: 'confirm_password',
        label: 'Confirm Password',
        required: true,
        type: 'password',
        message: 'Passwords must be identical.'
    }
}

export const REDEEMS = [
    {
        'text' : 'Member discount on replacement lenses for your glasses: ',
        'btnText' : 'REDEEM',
        'img': '/js/lensconfigurator/assets/lens_desktop.svg',
        'key': 'replacement_discount',
        'redirectTo' : '/choose-my-lenses'
    },
    {
        'text' : 'Member discount on designer frames with or without lenses: ',
        'btnText' : 'REDEEM',
        'img': '/js/lensconfigurator/assets/glasses_desktop.svg',
        'key': 'designer_frames_discount',
        'redirectTo' : '/shop-frames'
    },
    {
        'text' : 'Member discount on contact lenses: ',
        'btnText' : 'REDEEM',
        'img': '/js/lensconfigurator/assets/desktop_contacts.svg',
        'key': 'contacts_discount',
        'redirectTo' : '/contact-lenses'
    },
    {
        'text' : 'Member discount on an online vision test for Rx renewal: ',
        'btnText' : 'REDEEM',
        'img': '/js/lensconfigurator/assets/online_exam.svg',
        'key': 'online_vision_test_discount',
        'redirectTo' : '/online-vision-prescription'
    },
    {
        'text' : '$50 comprehensive eye exam',
        'btnText' : 'FIND LOCATIONS',
        'img': '/js/lensconfigurator/assets/eyeexam_desktop.svg',
        'key': null,
        'redirectTo' : '/plus/location'
    },
]
export const FIREFOX_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT = 'MM-DD-YYYY'
export const PASSWORD_REG = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_@!#$^%&*()+=\-\[\]';,./{}\\|":<>\\?])[A-Za-z\d_@!#$^%&*()+=\-\[\]';,./{}\\|":<>\\?]{8,}$/

