var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "struct-parallax-bg",
        staticStyle: {
          "text-align": "center",
          padding: "2em",
          "background-image": "unset"
        },
        attrs: {
          "data-parallax": "data-parallax",
          "data-attrs-pos-start": "50% -130%",
          "data-attrs-pos-end": "50% 60%",
          "data-attrs-xlarge-pos-start": "50% -100%",
          "data-attrs-xlarge-pos-end": "50% 0%",
          "data-attrs-trigger-hook": "1"
        }
      },
      [
        _c(
          "h3",
          {
            staticClass: "insurance-divider-text",
            staticStyle: { color: "#2f3948", "font-weight": "bolder" }
          },
          [_vm._v("-Or-")]
        ),
        _vm._v(" "),
        _c(
          "h3",
          {
            staticClass: "insurance-divider-text",
            staticStyle: { color: "#2f3948", "font-weight": "bolder" }
          },
          [_vm._v("Use a Reimbursement Form")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { color: "#8d8d8d", "font-size": "19px" } }, [
          _vm._v(
            "Can’t find your insurance or are out-of network? No problem. Select your provider below for details on how to submit your Lensabl receipt to your insurance company for reimbursement."
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "providers" },
          [
            _vm._l(_vm.getProviders, function(provider, index) {
              return [
                _c(
                  "div",
                  {
                    key: index,
                    staticClass: "provider",
                    on: {
                      click: function($event) {
                        return _vm.modalInformation(index)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "provider-image",
                      class: provider.provider,
                      attrs: {
                        src: provider.image,
                        alt: provider.provider,
                        height: "40px"
                      }
                    })
                  ]
                )
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { color: "#8d8d8d", "font-size": "19px" } }, [
          _vm._v(
            "Don't see your provider? Contact your insurance company directly for instructions on applying for reimbursement."
          )
        ])
      ]
    ),
    _vm._v(" "),
    _vm.showProviderModal
      ? _c("div", { staticClass: "insurance-modal" }, [
          _c("div", { staticClass: "insurance-content" }, [
            _c("div", { staticClass: "insurance-modal-image" }, [
              _c("img", {
                staticClass: "provider-modal-image",
                attrs: {
                  src: _vm.getProviders[_vm.currentProvider].image,
                  alt: _vm.getProviders[_vm.currentProvider].name,
                  width: "150px",
                  height: "50px"
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "md-close",
                on: {
                  click: function($event) {
                    _vm.showProviderModal = false
                  }
                }
              },
              [_vm._v("X")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "insurance-modal-title" }, [
              _c(
                "p",
                {
                  staticClass: "im-paragraph",
                  staticStyle: {
                    color: "#8d8d8d",
                    "text-align": "center",
                    "margin-bottom": "2em"
                  }
                },
                [
                  _vm._v("We’re not in-network with "),
                  _c("span", { staticClass: "insurance-provider-name" }, [
                    _vm._v(
                      _vm._s(_vm.getProviders[_vm.currentProvider].provider)
                    )
                  ]),
                  _vm._v(
                    ", however, you can apply for reimbursement on your prescription lenses and eye exam. Just follow the steps below:"
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "insurance-providers__options" }, [
              _c("ul", [
                _c("li", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "insurance-form-content" }, [
                    _c(
                      "p",
                      { staticClass: "insurance-form-information im-min" },
                      [_vm._v("Complete the following claim form:")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "insurance-form-link",
                        attrs: {
                          href:
                            _vm.getProviders[_vm.currentProvider].form_input,
                          target: "_blank"
                        }
                      },
                      [_vm._m(1)]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "insurance-form-content" }, [
                    _c(
                      "p",
                      { staticClass: "insurance-form-information im-min" },
                      [
                        _vm._v(
                          "Submit the completed claim form and submit along with your itemized invoice to this address:"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "insurance-provider-information" },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "insurance-provider-address-name",
                            staticStyle: { "font-weight": "bold" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getProviders[_vm.currentProvider]
                                  .address_name
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "insurance-provider-address-attn" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getProviders[_vm.currentProvider]
                                  .address_attn
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "insurance-provider-address-street" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getProviders[_vm.currentProvider]
                                  .address_po_box
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "insurance-provider-address-city" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getProviders[_vm.currentProvider]
                                  .address_city
                              ) +
                                ", " +
                                _vm._s(
                                  _vm.getProviders[_vm.currentProvider]
                                    .address_state
                                ) +
                                " " +
                                _vm._s(
                                  _vm.getProviders[_vm.currentProvider]
                                    .address_zipcode
                                )
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _vm._m(4)
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "insurance-title__container" }, [
      _c("div", { staticClass: "number-container" }, [
        _c("span", { staticClass: "number" }, [_vm._v("1")])
      ]),
      _c("p", { staticClass: "im-min" }, [_vm._v(" Fill out claim form")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button button--copy-solid" }, [
      _c("div", { staticClass: "button__container" }, [
        _vm._v(" DOWNLOAD FORM")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "insurance-title__container" }, [
      _c("div", { staticClass: "number-container" }, [
        _c("span", { staticClass: "number" }, [_vm._v("2")])
      ]),
      _c("p", { staticClass: "im-min" }, [
        _vm._v(" Submit claim form and invoice")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "insurance-title__container" }, [
        _c("div", { staticClass: "number-container" }, [
          _c("span", { staticClass: "number" }, [_vm._v("3")])
        ]),
        _c("p", { staticClass: "im-min" }, [
          _vm._v(" Download itemized invoice")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "insurance-form-content" }, [
        _c("p", { staticClass: "insurance-form-information im-min" }, [
          _vm._v("You may download an itemized invoice by logging into "),
          _c("a", { attrs: { href: "/sales/order/history/" } }, [
            _vm._v(" your account")
          ]),
          _vm._v(".")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "insurance-title__container" }, [
        _c("div", { staticClass: "number-container" }, [
          _c("span", { staticClass: "number" }, [_vm._v("4")])
        ]),
        _c("p", { staticClass: "im-min" }, [_vm._v(" Get reimbursed")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "insurance-form-content" }, [
        _c("p", { staticClass: "insurance-form-information im-min" }, [
          _vm._v(
            "After submitting your claim, you’ll typically be reimbursed within 2-3 weeks. (Depending on your provider, it can be faster or slower)."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }