<template>
    <Loader v-if="LOADING_BILLING" />
    <div v-else>
        <div class="billing-opc">
            <div class="opc-address-header-wrapper">
                <div class="opc-address-header opc-address-header--left toggle-billing-info">
                    <h3>Billing Information</h3>
                </div>
            </div>
            <div v-if="HAS_SAVED_ADDRESSES" class="opc-billing-container">
                <div
                    class="billing-form-address__col osc-short input-box input-region input-region_id"
                >
                    <span class="opc-address-label">Saved addresses</span>
                    <v-select
                        v-model="DEFAULT_SAVED_ADDRESS_ID"
                        :items="SAVED_ADDRESSESES_OPTIONS"
                        item-text="label"
                        item-value="value"
                        outlined
                        @change="setAddress"
                    />
                </div>
            </div>
            <div class="accordian-item">
                <div id="billing_address_list" class="billing-address-wrapper">
                    <div v-if="!SIGN_IN" class="plus-account">
                        <div v-if="showInvalidError" class="validation-advice-email" style="" v-text="invalidEmailText"></div>
                        <div v-if="showExistingError" class="validation-advice-email" style="" v-text="existingEmailText"></div>
                        <div class="osc-short input-box input-email billing-form-address__col">
                            <span class="opc-address-label">Email Address:</span>
                            <input
                                v-model.trim="address.email"
                                name="billing[email]"
                                type="text"
                                @blur="validBlurField('email')"
                            />
                        </div>
                    </div>
                    <div v-else>
                        <input type="hidden" name="billing[email]" :value="CUSTOMER.email" />
                    </div>
                    <div class="telephone-form osc-short input-box input-telephone billing-form-address__col">
                        <span class="opc-address-label">Phone Number (Exclude dashes):</span>
                        <input
                            v-model="address.telephone"
                            data-recurly="telephone"
                            type="tel"
                            name="billing[telephone]"
                            title="Telephone"
                            @blur="validBlurField('telephone')"
                        />
                        <div v-if="ADDRESS_FORM_ERRORS.telephone" class="validation-advice" style="">
                            This is a required
                            field.
                        </div>
                    </div>
                    <div class="billing-half-container">
                        <div
                            class="osc-short input-box input-firstname billing-form-address__col half-width"
                        >
                            <span class="opc-address-label">First Name:</span>
                            <input
                                v-model="address.first_name"
                                data-recurly="first_name"
                                class="required-entry input-text"
                                type="text"
                                name="billing[firstname]"
                                @blur="validBlurField('first_name')"
                            />
                            <div v-if="ADDRESS_FORM_ERRORS.first_name" class="validation-advice" style="">
                                This is a
                                required
                                field.
                            </div>
                        </div>

                        <div
                            class="billing-form-address__last-name billing-form-address__col input-lastname half-width"
                        >
                            <span class="opc-address-label">Last Name:</span>
                            <input
                                v-model="address.last_name"
                                data-recurly="last_name"
                                class="required-entry input-text"
                                type="text"
                                name="billing[lastname]"
                                @blur="validBlurField('last_name')"
                            />
                            <div v-if="ADDRESS_FORM_ERRORS.last_name" class="validation-advice" style="">
                                This is a
                                required
                                field.
                            </div>
                        </div>
                    </div>
                    <div class="billing-form-address__col input-box input-address">
                        <span class="opc-address-label">Address 1:</span>
                        <input
                            id="autocomplete-map"
                            v-model="address.address1"
                            type="text"
                            data-recurly="address1"
                            title="Street Address 1"
                            name="billing[street][0]"
                            placeholder="Enter a location"
                            @blur="validBlurField('address1')"
                        />
                        <div v-if="ADDRESS_FORM_ERRORS.address1" class="validation-advice" style="">
                            This is a required
                            field.
                        </div>
                    </div>
                    <div class="billing-form-address__col input-box input-address">
                        <span class="opc-address-label">Address 2:</span>
                        <input
                            v-model="address.address2"
                            type="text"
                            data-recurly="address2"
                            title="Street Address 2"
                            name="billing[street][1]"
                            class="input-text onestepcheckout-address-line"
                            @blur="validBlurField('address2')"
                        />
                        <div v-if="ADDRESS_FORM_ERRORS.address2" class="validation-advice" style="">
                            This is a required
                            field.
                        </div>
                    </div>
                    <div class="billing-half-container">
                        <div class="billing-form-address__col half-width input-box input-country">
                            <span class="opc-address-label">Country:</span>
                            <v-select
                                v-model="address.country"
                                :items="allowedCountries"
                                item-text="label"
                                item-value="value"
                                outlined
                                @blur="validBlurField('country')"
                            />
                            <input
                                type="hidden"
                                data-recurly="country"
                                name="billing[country_id]"
                                :value="address.country"
                            />
                        </div>
                        <div class="billing-form-address__col half-width input-box input-city">
                            <span class="opc-address-label">City:</span>
                            <input
                                v-model="address.city"
                                data-recurly="city"
                                type="text"
                                name="billing[city]"
                                title="City"
                                class="required-entry input-text"
                                @blur="validBlurField('city')"
                            />
                            <div v-if="ADDRESS_FORM_ERRORS.city" class="validation-advice" style="">
                                This is a required
                                field.
                            </div>
                        </div>
                    </div>

                    <div class="billing-half-container">
                        <div
                            class="billing-form-address__col half-width osc-short input-box input-region input-region_id"
                        >
                            <span class="opc-address-label">State:</span>
                            <v-select
                                v-model="address.state"
                                :items="REGIONS"
                                item-text="label"
                                item-value="value"
                                outlined
                                @blur="validBlurField('state')"
                            />
                            <div v-if="ADDRESS_FORM_ERRORS.state" class="validation-advice" style="">
                                This is a required
                                field.
                            </div>
                            <input type="hidden" data-recurly="state" name="billing[region]" :value="address.state" />
                        </div>

                        <div class="billing-form-address__col half-width last input-postcode">
                            <span class="opc-address-label">Zipcode:</span>
                            <input
                                v-model="address.postal_code"
                                data-recurly="postal_code"
                                type="text"
                                title="Zip Code"
                                name="billing[postcode]"
                                class="validate-zip-international required-entry input-text"
                                @blur="validBlurField('postal_code')"
                            />
                            <div v-if="ADDRESS_FORM_ERRORS.postal_code" class="validation-advice" style="">
                                This is a
                                required field.
                            </div>
                        </div>
                    </div>
                    <div class="no-display">
                        <input v-if="showFields" type="hidden" name="billing[save_in_address_book]" value="1" />
                        <input type="hidden" name="billing[use_for_shipping]" value="1" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Loader from '../../loaders/Loader.vue'
import {validationMixin} from 'vuelidate'
import {required, numeric, minLength, maxLength, email} from 'vuelidate/lib/validators'

const DEFAULT_FIELDS = {
    email: '',
    telephone: '',
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    postal_code: '',
    state: '',
    country: 'US'
}

export default {
    name: 'Billing',
    components: {
        Loader
    },
    mixins: [validationMixin],
    data () {
        return {
            existingEmailText: 'Each account can only have one Lensabl+ membership. To add another membership, please create a separate account.',
            invalidEmailText: 'This is a required field.',
            address: DEFAULT_FIELDS,
            allowedCountries: [
                {
                    'label': 'United States',
                    'value': 'US'
                }
                // {
                //     'label': 'Canada',
                //     'value': 'CA'
                // }
            ],
            autocomplete: false,
            addNewAddress: false
        }
    },
    validations: {
        address: {
            email: {
                required,
                email
            },
            telephone: {
                required,
                numeric,
                minLength: minLength(10),
                maxLength: maxLength(18)

            },
            first_name: {
                required,
                minLength: minLength(3)
            },
            last_name: {
                required,
                minLength: minLength(3)
            },
            address1: {
                required
            },
            city: {
                required
            },
            postal_code: {
                required
            },
            state: {
                required
            },
            country: {
                required
            }
        }

    },
    computed: {
        ...mapGetters('checkoutPlus', [
            'LOADING_BILLING',
            'HAS_SAVED_ADDRESSES',
            'DEFAULT_SAVED_ADDRESS_ID',
            'SAVED_ADDRESSES',
            'SAVED_ADDRESSESES_OPTIONS',
            'QUOTE_ADDRESS',
            'ADDRESS_FORM_ERRORS',
            'BILLING_ADDRESS',
            'LOADING_PAYMENT',
            'SIGN_IN',
            'LOADING_SIGN_IN',
            'CUSTOMER',
            'getPlusCustomerByEmail',
            'isPlusCustomer',
            'LOADING_SUMMARY',
            'placeOrderDisabled'
        ]),
        ...mapGetters('onlineExam', ['REGIONS']),
        ...mapGetters('storeView', ['country_code', 'base_url']),
        DEFAULT_SAVED_ADDRESS_ID: {
            get () {
                return this.$store.getters['checkoutPlus/DEFAULT_SAVED_ADDRESS_ID']
            },
            set (address_id) {
                this.$store.commit('checkoutPlus/SET_DEFAULT_SAVED_ADDRESS_ID', address_id)
            }
        },
        showFields () {
            if (!this.HAS_SAVED_ADDRESSES || this.addNewAddress) {
                return true
            }
            return false
        },
        showExistingError () {
            return this.placeOrderDisabled && !this.LOADING_SUMMARY && this.isPlusCustomer
        },
        showInvalidError () {
            return this.ADDRESS_FORM_ERRORS.email
        },
        state () {
            return this.address.state
        }
    },
    watch: {
        'address.state': function (newVal, oldVal) {
            if (!this.LOADING_BILLING && !this.LOADING_PAYMENT) {
                this.$store.dispatch('checkoutPlus/SAVE_BILLING')
            }
        },
        'address.email':function (newVal, oldVal) {
            if (!oldVal && newVal && newVal !== oldVal) {
                this.validBlurField ('email')
            }
        },
        address: {
            handler (data) {
                if (data.country !== this.country_code && data.country) {
                    this.$store.commit('storeView/SET_COUNTRY_CODE', data.country)
                    this.$store.dispatch('onlineExam/GET_REGIONS')
                }
            },
            deep: true,
            email: {
                handler (email) {
                    this.$store.commit('checkoutPlus/IS_PLUS_CUSTOMER', this.getPlusCustomerByEmail(email))
                }
            }
        },
        BILLING_ADDRESS: {
            handler (data) {
                this.address = data
            },
            deep: true
        },
        showFields (val) {
            if (val) {
                const intervalID = setInterval(() => {
                    if (document.getElementById('autocomplete-map')) {
                        this.initialize()
                        clearInterval(intervalID)
                    }
                }, 2000)
            }
        }
    },
    mounted () {
        this.$store.dispatch('onlineExam/GET_REGIONS')

        this.$store.dispatch('checkoutPlus/GET_ADDRESSES').then(() => {
            this.address = this.BILLING_ADDRESS
        })

        this.$store.dispatch('checkoutPlus/GET_SIGN_IN')
    },
    methods: {
        ...mapActions('checkoutPlus', ['GET_ADDRESSES', 'checkIsPlusCustomer', 'GET_SIGN_IN']),
        ...mapActions('onlineExam', ['GET_REGIONS']),
        validBlurField (val) {
            const error = this.ADDRESS_FORM_ERRORS
            if (this.$v.address[val]) {
                error[val] = this.$v.address[val].$invalid
                if (val === 'postal_code') {
                    this.$store.dispatch('checkoutPlus/SAVE_BILLING')
                }
            }
            this.$store.commit('checkoutPlus/SET_ADDRESS_FORM_ERRORS', error)

            if (this.$v.address[val] && !this.$v.address[val].$invalid) {
                if (val === 'email') {
                    this.$store.commit('checkoutPlus/SET_CUSTOMER', {
                        email: this.address.email
                    })

                    this.$store.commit('checkoutPlus/SET_LOADING_SUMMARY', true)
                    this.checkIsPlusCustomer(this.address.email).then(() => {
                        const isPlusCustomer = this.getPlusCustomerByEmail(this.address.email)
                        this.$store.commit('checkoutPlus/IS_PLUS_CUSTOMER', isPlusCustomer)
                        this.$store.commit('checkoutPlus/SET_LOADING_SUMMARY', false)
                    })
                }
            } else {
                if (val === 'email') {
                    this.$store.commit('checkoutPlus/SET_CUSTOMER', {
                        email: null
                    })
                    this.$store.commit('checkoutPlus/IS_PLUS_CUSTOMER', false)
                }
            }
        },
        initialize () {
            const countries = this.allowedCountries.map(country => country.value)
            if (window.google) {
                this.autocomplete = new window.google.maps.places.Autocomplete(
                    document.getElementById('autocomplete-map'),
                    {
                        types: ['address'],
                        componentRestrictions: {
                            country: countries
                        }
                    }
                )
                window.google.maps.event.addListener(this.autocomplete, 'place_changed', this.fillInAddress)
            }
        },
        fillInAddress () {
            this.address.address1 = ''
            // Get the place details from the autocomplete object.
            const address = this.autocomplete.getPlace()

            this.address.address1 = address.name

            if (!address.address_components) {
                return
            }

            address.address_components.each(component => {
                if (component.types.indexOf('country') !== -1) {
                    this.address.country = component.short_name
                }
                if (component.types.indexOf('locality') !== -1) {
                    this.address.city = component.long_name
                }
                if (component.types.indexOf('postal_code') !== -1) {
                    this.address.postal_code = component.long_name
                }
                if (component.types.indexOf('administrative_area_level_1') !== -1) {
                    this.address.state = component.short_name
                }
            })
            this.$store.dispatch('checkoutPlus/SAVE_BILLING')
        },
        setAddress (address_id) {
            this.addNewAddress = false
            if (address_id !== '') {
                this.address = this.SAVED_ADDRESSES[address_id]
            } else {
                this.addNewAddress = true
                this.address = DEFAULT_FIELDS
            }
            this.$store.commit('checkoutPlus/SET_BILLING_ADDRESS', this.address)
            this.$store.dispatch('checkoutPlus/SAVE_BILLING')
        }
    }
}
</script>
<style lang="less" scoped>
    .hide {
        display: none;
    }
</style>
