<template>
    <div v-if="totalPrice" class="price-info">
        <div class="price">
            <div class="total-value value">
                <span class="total-value-currency">{{ currencySymbol }}</span>
                <span class="total-value-amount-dollars" v-text="formatPrice(dollars)"></span>
                <span class="total-value-amount-cents" v-text="`.${cents}`"></span>
            </div>
            <div class="label">
                <strong>/per {{ label }}</strong>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import TotalsPrice from '../../summary/TotalsPrice.vue'

export default {
    name: 'TotalsPrice',
    extends: TotalsPrice,
    data: () => {
        return {
            option: 'totalPrice'
        }
    },
    computed: {
        ...mapState('lensablPlus', ['totalAnnualDiscount']),
        ...mapGetters('lensablPlus', ['checkoutData'])
    },
    methods: {
        totalPrice (price) {
            return parseFloat(this.checkoutData[price]).toFixed(2) || 0
        },
        updatePrice (value) {
            this.option = value
        },
        formatPrice (price) {
            return price > 999 ? price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : price
        }
    }
}
</script>
