var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", [_vm._v("Free Standard Shipping")]),
      _vm._v(" "),
      _c("HelpBubble", {
        attrs: {
          placement: _vm.placement,
          data: _vm.component_help,
          offset: _vm.offset
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }