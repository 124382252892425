import _find from 'lodash/find'
import _first from 'lodash/first'
import _formatPrice from '../../../app/utils/formatPrice'
import getCartService from '../../../app/utils/cart-service'
import {
    PARTNER_WITH_SIZE
} from '../../../app/utils/constants'
import {
    ANTIREFLECTIVE_STEP
} from '../../../app/common/constants/antireflective'
import * as abstract from '../../../storage/abstract'

export const state = () => ({
    applied_frames_discount: false,
    adjustment_frames_price: false
})

export const getters = {
    isApliedFramesDiscount: state => state.applied_frames_discount,
    isAdjustmentFramesPrice: state => state.adjustment_frames_price,
    cartService: (state, getters, rootState, rootGetters) => getCartService({base_url: rootGetters['storeView/base_url']}),
    showFramesPrice: (store, getters, rootState, rootGetters) => rootGetters['configurator/isSpectacles'] || rootGetters['values/framesPartnerPage'],
    hasDiscount: (store, getters, rootState, rootGetters) => {
        if (rootGetters['configurator/isContactDetailsPage']) {
            return 0
        } else {
            return rootGetters['values/values'].box.balance !== 0
        }
    },
    discountAmount: (store, getters, rootState, rootGetters) => {
        let balance = rootGetters['values/values'].box.balance
        if (rootGetters['configurator/isContactDetailsPage']) {
            balance = 0
        }
        return -Math.min(balance, getters.totalSummary)
    },
    totalSummary: (store, getters) => getters.getComputedTotalSummary(),
    totalSubscribedSummary: (store, getters) => getters.computePriceForContacts.subPrice,
    retailTotalSummary: (store, getters) => getters.getComputedTotalSummary('retail_price'),
    totalSummaryContactsWithBenefits: (store, getters) => {
        const total = getters.totalSummary - getters.redeemDiscount
        return Math.max(0, total)
    },
    totalSummaryContacts: (store, getters, rootState, rootGetters) => {
        const totalWithBenefit = getters.totalSummaryContactsWithBenefits
        const discount = totalWithBenefit > 0 ? totalWithBenefit - getters.redeemDiscountContacts : 0
        return (discount).toFixed(2)
    },
    totalSummaryContactsDiscounted: (store, getters, rootState, rootGetters) => {
        let total = getters.totalSummaryContacts
        if (rootGetters['membershipProgram/hasCustomerMembership']) {
            total -= rootGetters['membershipProgram/discountTotalByAction'] || 0
        }
        total = Math.max(total, 0)
        return _formatPrice(total)
    },
    framesPrice: (store, getters, rootState, rootGetters) => {
        if (rootGetters['configurator/isContactDetailsPage']) {
            return 0
        }
        const values = rootGetters['values/checkoutData']
        const {frames_name, frames_value, lensabl_frames} = values.custom_options
        if (!frames_value || !frames_name || lensabl_frames) {
            return 0
        }
        const product_config = rootGetters['configurator/product_config']
        if (!product_config) {
            return 0
        }
        const framesProduct = rootGetters['values/framesProduct']
        const configFrames = product_config[framesProduct] ? product_config[framesProduct].config : false
        if (!configFrames) {
            return 0
        }
        const framesOptions = _find(configFrames, {'label': 'Frames'})
        if (!framesOptions) {
            return 0
        }
        if (rootGetters['productView/isClipOnType']) {
            return parseFloat(rootGetters['values/values'].frame_price)
        }
        const colorPrice = framesOptions.options ? _find(framesOptions.options, {'label': frames_value}) : false
        if (!colorPrice) {
            return 0
        }
        return colorPrice.price || 0
    },
    redeemDiscountContacts: (store, getters, rootState, rootGetters) => {
        if (rootGetters['lensablplus_customer/isRedeemFlow'] && !rootGetters['lensablplus_customer/isBusinessMember']) {
            return 0
        }
        const checkoutData = rootGetters['values/checkoutData']
        const data = checkoutData.data

        let qty = 0
        if (rootGetters['contacts/getLeftEyeChecked']) {
            qty += parseInt(data.left.qty)
        }
        if (rootGetters['contacts/getRightEyeChecked']) {
            qty += parseInt(data.right.qty)
        }
        const percent = rootGetters['lensablplus_customer/getPercentage']('contacts')
        const total = getters.totalSummaryContactsWithBenefits
        const totalPerItem = _formatPrice(total / qty)
        const totalDiscount = _formatPrice(totalPerItem * percent / 100)
        const discount = _formatPrice(totalDiscount * qty)
        return discount
    },
    getComputedTotalSummary: (store, getters, rootState, rootGetters) => (price_type) => {
        const product_type = rootGetters['values/productType']
        const lens_type = rootGetters['values/lensType']
        const lens_option = rootGetters['values/lensOption']
        const redeem_flow = rootGetters['lensablplus_customer/isRedeemFlow']

        let total = product_type === 'contacts'
            ? getters.computePriceForContacts.regPrice
            : getters.computePriceFromConfig({product_type, lens_type, lens_option, price_type})

        const limiters = rootGetters['configurator/steps/limiters'] && rootGetters['configurator/steps/limiters'].hasOwnProperty('lens_price')
            ? rootGetters['configurator/steps/limiters'].lens_price
            : false

        total = redeem_flow && product_type === 'contacts' && !rootGetters['lensablplus_customer/isBusinessMember']
            ? rootGetters['lensablplus_customer/planPrice']('contacts')
            : total
        const values = rootGetters['values/checkoutData']

        if (rootGetters['configurator/isContactDetailsPage']) {
            return total
        }

        const addUpgrades = rootGetters['values/values'].upgrades
        Object.entries(addUpgrades).map(([key, cost]) => {
            total += parseInt(cost)
        })

        const {frames_name} = values.custom_options

        if (limiters && limiters[product_type] && !frames_name && !values.custom_options.lensabl_frames) {
            const limiterTotal = limiters[product_type].hasOwnProperty(lens_type)
                ? limiters[product_type][lens_type].hasOwnProperty(lens_option)
                    ? limiters[product_type][lens_type][lens_option]
                    : false
                : false
            if (limiterTotal !== false) {
                return limiterTotal
            }
        }
        if (!frames_name) {
            return total
        }
        if (product_type === rootGetters['values/framesProduct']) {
            return total
        }
        if (!getters.framesPrice) {
            return total
        }

        if (values.custom_options.lensabl_frames) {
            return total
        }

        if (frames_name === 'le-specs' || frames_name === 'hobie') {
            return total
        }
        if (getters.isAdjustmentFramesPrice || rootGetters['configurator/isSpectacles']) {
            return total
        }
        return total + parseFloat(getters.framesPrice)
    },
    computePriceForContacts: (store, getters, rootState, rootGetters) => {
        let leftEyePrice = 0
        let leftEyeQuantity = 0
        let rightEyePrice = 0
        let rightEyeQuantity = 0
        let leftEyeSubscribedPrice = 0
        let rightEyeSubscribedPrice = 0
        const eyeData = rootGetters['contacts/getEyeData']

        if (rootGetters['contacts/getSubscribed'] === 'yes') {
            leftEyeQuantity = parseFloat(eyeData.left.default_sub_box_quantity)
            rightEyeQuantity = parseFloat(eyeData.right.default_sub_box_quantity)
        } else {
            leftEyeQuantity = parseFloat(eyeData.left.boxes)
            rightEyeQuantity = parseFloat(eyeData.right.boxes)
        }

        if (rootGetters['contacts/getLeftEyeConfirmed']) {
            leftEyePrice = parseFloat(eyeData.left.regPrice) * leftEyeQuantity
            leftEyeSubscribedPrice = parseFloat(eyeData.left.subPrice) * leftEyeQuantity
        }

        if (rootGetters['contacts/getRightEyeConfirmed']) {
            rightEyePrice = parseFloat(eyeData.right.regPrice) * rightEyeQuantity
            rightEyeSubscribedPrice = parseFloat(eyeData.right.subPrice) * rightEyeQuantity
        }
        let regPrice = (leftEyePrice + rightEyePrice).toFixed(2)
        let subPrice = (leftEyeSubscribedPrice + rightEyeSubscribedPrice).toFixed(2)

        return {
            regPrice: regPrice,
            subPrice: subPrice
        }
    },
    computePriceFromConfig: (store, getters, rootState, rootGetters) => ({
                                                                             product_type,
                                                                             lens_type = false,
                                                                             lens_option = false,
                                                                             price_type = 'price'
                                                                         }) => {
        const config = rootGetters['configurator/product_config']

        if (!config) {
            return false
        }

        if (!config[product_type]) {
            return false
        }
        const productConfig = config[product_type] // returns object 'single_vision_distance' with price + array

        const productConfigOptions = productConfig.config || productConfig
        if (!lens_type) {
            lens_type = getters.getDefaultLensType(productConfigOptions)
        }
        const lensType = _find(productConfigOptions, lensTypeConfig => lensTypeConfig.label === lens_type)
        if (!lensType) {
            return false
        }

        if (!lens_option) {
            lens_option = getters.getDefaultLensOption(product_type, lensType)
        }

        let price = 0
        const lensTypeColor = (rootGetters['values/values'] || {}).color || {}

        if (lensTypeColor) {
            price += lensTypeColor.hasOwnProperty(price_type) ? parseFloat(lensTypeColor[price_type]) : 0
        }

        let lensOption = _find(lensType.options, lensOptionConfig => lensOptionConfig.label === lens_option)
        if (!lensOption) {
            lensOption = _first(lensType.options)
        }
        if (!lensOption) {
            return price
        }

        price += parseFloat(lensOption[price_type])

        if (rootGetters['prism-service/prismQty']) {
            price += rootGetters['values/values'].prism_price
        }
        return price
    },
    totalAmountDiscounted: (store, getters, rootState, rootGetters) => {
        let total = getters.totalAmount
        if (!total) {
            return 0
        }
        if (rootGetters['membershipProgram/hasCustomerMembership']) {
            total -= rootGetters['membershipProgram/discountTotalByAction'] || 0
        }
        total = Math.max(total, 0)
        return _formatPrice(total)
    },
    totalAmount: (store, getters, rootState, rootGetters) => {
        let total = getters.totalSummary
        if (!total) {
            return 0
        }
        const addWarranty = rootGetters['values/values'].warranty
        Object.entries(addWarranty).map(([key, cost]) => {
            total += parseInt(cost)
        })

        const grandTotal = Math.max(total + getters.discountAmount, 0)

        return _formatPrice(grandTotal)
    },
    retailTotalAmount: (store, getters, rootState, rootGetters) => {
        let total = getters.retailTotalSummary
        if (!total) {
            return 0
        }

        const addUpgrades = rootGetters['values/values'].upgrades
        Object.entries(addUpgrades).map(([key, value]) => {
            total += parseInt(value)
        })

        const addWarranty = rootGetters['values/values'].warranty
        Object.entries(addWarranty).map(([key, value]) => {
            total += parseInt(value)
        })

        const grandTotal = Math.max(total + getters.discountAmount, 0)

        return _formatPrice(grandTotal)
    },
    subTotalAmountFrames: (store, getters, rootState, rootGetters) => {
        const {frames_name} = rootGetters['values/checkoutData'].custom_options || {}

        if (rootGetters['configurator/isSpectacles'] || PARTNER_WITH_SIZE.indexOf(frames_name) !== -1) {
            return rootGetters['values/values'].frame_price
        }
        return _formatPrice(getters.framesPrice)
    },
    subTotalAmountLenses: (store, getters) => {
        const total = getters.totalAmount
        if (!total) {
            return 0
        }
        const grandTotal = Math.max(total + getters.discountAmount, 0)
        const framesPrice = parseFloat(getters.subTotalAmountFrames)
        const configuredPrice = _formatPrice(grandTotal)
        return _formatPrice(configuredPrice - framesPrice)
    },

    getDefaultLensType: (store, getters, rootState, rootGetters) => productConfig => {
        const lensType = rootGetters['values/lensTypeStep']
        const defaultOption = _find(lensType.options, {'id': lensType.defaultValue})
        let defaultLensType = defaultOption ? defaultOption.label : null
        if (defaultOption && defaultOption.component_extra_options) {
            const sections = defaultOption.component_extra_options.params.sections
            const defaultSection = _first(sections)
            defaultLensType = defaultSection.checkout_value
        }
        const lensTypeExist = _find(productConfig, {'label': defaultLensType})
        if (!lensTypeExist) {
            const firstElement = _first(productConfig)
            return firstElement && firstElement.label ? firstElement.label : 'Clear'
        }
        return lensTypeExist ? defaultLensType : 'Clear'
    },
    getDefaultLensOption: (store, getters, rootState, rootGetters) => (product_type, lens_type) => {
        const antiReflective = rootGetters['values/values'].anti_reflective
        const antiReflectiveSymbol = rootGetters['values/values'].anti_reflective_symbol
        let lensOption = ''
        // TODO: Should be fixed
        if (product_type === 'sunglass_lens') {
            lensOption = 'Poly'
        } else if (product_type === 'computer_lens') {
            lensOption = 'Blue Light Lens'
        } else if (product_type === 'plano_lens') {
            lensOption = 'Poly'
        } else {
            lensOption = 'Bi-Focal'
        }

        const options = lens_type.options
        const lensTypeLabel = lens_type.label
        const firstOption = _first(options)

        if (firstOption) {
            lensOption = firstOption.label
        }

        if (lensOption && antiReflective && lensTypeLabel !== 'Frames') {
            lensOption = `${lensOption} + ${antiReflectiveSymbol}`
        }
        return lensOption
    },
    totalRedeemSummary: (state, getters, rootState, rootGetters) => {
        const customerData = rootState['lensablplus_customer']
        const total = parseFloat(getters.subTotalAmountLenses)
        let totalFrames = parseFloat(getters.subTotalAmountFrames)
        if (customerData.only_lenses_discount.includes(rootGetters['productView/frames_name']) ||
            rootGetters['productView/is_only_lenses_discount']) {
            totalFrames = 0
        }
        if (!total && !totalFrames) {
            return 0
        }
        let totalWithoutReedemDiscount = Math.max(total + totalFrames - getters.redeemDiscount, 0)
        const addWarranty = rootGetters['values/values'].warranty
        Object.entries(addWarranty).map(([key, value]) => {
            totalWithoutReedemDiscount -= parseFloat(value)
        })
        const percent = rootGetters['lensablplus_customer/getPercentage']('lens') / 100
        const price = totalWithoutReedemDiscount * percent
        return _formatPrice(price)
    },
    getRedeemFramesPercentDiscount: (store, getters, rootState, rootGetters) => {
        if (!rootGetters['lensablplus_customer/isRedeemFlow'] && !getters.isApliedFramesDiscount) {
            return 0
        }
        const total = parseFloat(getters.subTotalAmountFrames)

        if (!total) {
            return 0
        }
        const percent = rootGetters['lensablplus_customer/getPercentage']('lens') / 100
        const price = total * percent
        return _formatPrice(price)
    },
    redeemTotalAmountUpgraded: (store, getters, rootState, rootGetters) => {
        const total = parseFloat(getters.totalSummary)
        if (!total) {
            return 0
        }
        const grandTotal = Math.max(total + getters.discountAmount, 0)
        let totalWithBenefits = Math.max((grandTotal - getters.redeemDiscount), 0)
        return  totalWithBenefits
    },
    redeemTotalAmountUpgradedWithDiscount: (store, getters, rootState, rootGetters) => {
        let total = getters.redeemTotalAmountUpgraded
        if (!total) {
            return 0
        }
        return  total - getters.totalRedeemSummary
    },
    redeemTotalAmount: (store, getters, rootState, rootGetters) => {
        let total = getters.redeemTotalAmountUpgradedWithDiscount
        if (!total) {
            return 0
        }
        const addWarranty = rootGetters['values/values'].warranty
        Object.entries(addWarranty).map(([key, cost]) => {
            total += parseFloat(cost)
        })
        return _formatPrice(total)
    },
    approvedRedeemPackage: (store, getters, rootState, rootGetters) => {
        if (!rootGetters['lensablplus_customer/isRedeemFlow']) {
            return false
        }
        const product_type = rootGetters['values/productType']
        const lplustype = rootGetters['lensablplus_customer/glassesType']
        if (product_type.indexOf(lplustype) === -1) {
            return false
        }
        const stepLens = rootGetters['configurator/steps/getStepObject']('lens')
        if (!stepLens || !stepLens.redeemable) {
            return false
        }
        const stepPrescriptionType = rootGetters['configurator/steps/getStepObject']('prescription_type')
        if (!stepPrescriptionType || !stepPrescriptionType.redeemable) {
            return false
        }
        const stepLensType = rootGetters['configurator/steps/getStepObject']('lens_type')
        if (!stepLensType || !stepLensType.redeemable) {
            return false
        }
        const stepLensOptions = rootGetters['configurator/steps/getStepObject']('lens_options')
        if (!stepLensOptions || !stepLensOptions.redeemable) {
            return false
        }
        const addUpgrades = rootGetters['values/values'].upgrades
        if (Object.keys(addUpgrades).length > 0) {
            return false
        }

        const selectedAntireflective = _find([...ANTIREFLECTIVE_STEP], {'value': rootGetters['values/antiReflectiveSymbol']})
        if (!selectedAntireflective || !selectedAntireflective.redeemable) {
            return false
        }
        return true
    },
    redeemDiscount: (store, getters, rootState, rootGetters) => {
        if (!rootGetters['lensablplus_customer/isRedeemFlow']) {
            return 0
        }
        const isBusinessMember = rootGetters['lensablplus_customer/isBusinessMember']
        let planPrice = 0
        if (isBusinessMember && rootGetters['configurator/isContactDetailsPage'] && !rootGetters['lensablplus_customer/isContactsRedeemed']) {
            planPrice = parseFloat(rootGetters['lensablplus_customer/planPrice']('contacts'))
        } else {
            if (!getters.subTotalAmountLenses) {
                return 0
            }
            if (isBusinessMember) {
                planPrice = parseFloat(rootGetters['lensablplus_customer/planPrice']('single_vision'))
            } else {
                planPrice = parseFloat(rootGetters['lensablplus_customer/planPrice']())
            }
        }
        return _formatPrice(planPrice)
    }
}

export const actions = {
    fetchDefaultData: ({commit, rootGetters}) => {
        const data = rootGetters.rootState || {}

        commit('UPDATE_STATE_DATA', data)
    }
}

export const mutations = {
    ...abstract.mutations
}

export default () => ({
    strict: true,
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
