var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.brand + "-seo-parent-container"] }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-prescription-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-prescription" }, [
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Oakley Glasses")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Lensabl carries men's and women's Oakley sports glasses that work and play as hard as you do. Purchase Oakley prescription glasses for free standard delivery and other perks."
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-img" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-frames-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-frames" }, [
        _c("div", { staticClass: "shopframes-seo-frames-img" }),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Oakley Eyeglasses for Your Prescription")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "You live an active life and need eyeglasses that match your pace. We carry Oakley prescription glasses for everyday use and sunglasses that offer superb eye protection alongside vision correction."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "Browse our inventory to find Oakley prescription shades or clear glasses you can wear everywhere. You can fit your Oakleys with single vision, bifocal or progressive lenses. If your eyes require more assistance than most, you can get high-index lenses for the strongest prescriptions. We encourage you to "
            ),
            _c("a", { attrs: { href: "/prescription-scanner-app/qr" } }, [
              _c("span", { staticClass: "seo-contact-link" }, [
                _vm._v("use our prescription reader")
              ])
            ]),
            _vm._v(" for the most accurate lens readings — or "),
            _c("a", { attrs: { href: "/online-vision-prescription" } }, [
              _c("span", { staticClass: "seo-contact-link" }, [
                _vm._v("renew your prescription online")
              ])
            ]),
            _vm._v(" with Lensabl.")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-designer-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-designer" }, [
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Fashionable Oakley Frames for All Styles")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                    Lensabl has an Oakley frame to fit everyone's style. Shop our inventory to find men's and women's Oakley eyeglasses from the brand's most prominent lines, including Airdrop, Gascan, Feedback, Pitchman, Fuller and more. Oakley uses high-quality materials like acetate, plastic, steel, titanium and propionate for durable eyewear that looks great in all settings."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "You can choose from many frame colors when you shop for Oakley glasses on Lensabl. Check out our inventory to find everything from bold, attention-grabbing Oakley frames to more subtle styles. A stylish set of sunglasses or clear glasses can give you confidence on the go.\n                 "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-designer-img" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-eyeglass-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-eyeglass" }, [
        _c("div", { staticClass: "shopframes-seo-text seo-eyeglass-text" }, [
          _c("h2", [
            _vm._v("Order Oakley Prescription Frames Through Lensabl")
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "As a Lensabl customer, you'll receive free standard shipping, a 12-month warranty and a 30-day return period with every order. Buy Oakley eyewear online today to experience these perks! You'll enjoy even more benefits if you join Lensabl+. "
            ),
            _c("a", { attrs: { href: "/plus/vision_plan" } }, [
              _c("span", { staticClass: "seo-contact-link" }, [
                _vm._v("Enroll in a plan")
              ])
            ]),
            _vm._v(
              " that meets your needs to get clear vision and so much more."
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "/glasses-frames", title: "Glasses Frames" } },
          [
            _c("div", { staticClass: "button button--copy-solid" }, [
              _c("div", { staticClass: "button__container" }, [
                _vm._v("GET NEW FRAMES")
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }