var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ],
      staticClass: "lensabl-plus-content business-dashboard"
    },
    [
      _c("h3", [_vm._v("Welcome to Lensabl+!")]),
      _vm._v(" "),
      _c("p", { staticClass: "description" }, [
        _vm._v("\n        Select which plan you would like:\n        "),
        _c("br"),
        _vm._v(" "),
        _c("a", { attrs: { href: _vm.groupPlanPdfLink, download: "" } }, [
          _vm._v("Download PDF to view all plan details")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "plans_container" },
        _vm._l(_vm.planSelection, function(plan, index) {
          return _c(
            "label",
            {
              key: plan.id,
              staticClass: "plan-option",
              class: { active: _vm.selected === plan.id },
              attrs: { for: plan.id }
            },
            [
              _c("input", {
                attrs: {
                  id: plan.id,
                  title: plan.name,
                  type: "radio",
                  name: "plans"
                },
                on: {
                  click: function($event) {
                    return _vm.togglePlans(plan.id, index)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "plan-container" }, [
                _c("div", { staticClass: "plan-label" }, [
                  _c("span", [
                    _c(
                      "strong",
                      {
                        staticClass: "plan-name",
                        style: { color: plan.color }
                      },
                      [_vm._v("2021 " + _vm._s(plan.label))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("$" + _vm._s(plan.price) + " per month")])
                ])
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "validation-advice" }, [
            _vm._v("Please choose a plan.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "actions-toolbar" }, [
        _c(
          "div",
          { staticClass: "primary" },
          [
            _c("BaseButton", {
              staticClass: "button--copy-solid-white-blue button--small",
              attrs: { text: "Continue" },
              nativeOn: {
                click: function($event) {
                  return _vm.continueNxt($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }