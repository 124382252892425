<template>
    <GoVisibly v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import GoVisibly from '../../../components/govisibly/GoVisibly.vue'

export default {
    name: 'RouterGoVisibly',
    components: {
        GoVisibly
    },
    extends: App
}
</script>
