var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "save-up" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.saveUpPrice,
            expression: "saveUpPrice"
          }
        ],
        staticClass: "learn"
      },
      [
        _c("strong", {
          directives: [
            {
              name: "dompurify-html",
              rawName: "v-dompurify-html",
              value: "Save up to $" + _vm.saveUpPrice.toFixed(2),
              expression: "`Save up to $${saveUpPrice.toFixed(2)}`"
            }
          ]
        }),
        _vm._v(" out-of-pocket when compared to other Vision Plans\n        "),
        _c(
          "span",
          {
            staticClass: "learn-more",
            class: { active: _vm.descriptionShow },
            on: { click: _vm.toggleInfo }
          },
          [_vm._v("Learn More")]
        ),
        _vm._v(" "),
        _vm.descriptionShow
          ? _c("span", { on: { click: _vm.toggleInfo } }, [
              _c(
                "svg",
                {
                  attrs: {
                    id: "Layer_1",
                    "enable-background": "new 0 0 100 100",
                    version: "1.1",
                    viewBox: "0 0 100 100",
                    "xml:space": "preserve",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink"
                  }
                },
                [
                  _c("polygon", {
                    attrs: {
                      fill: "#010101",
                      points:
                        "77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 "
                    }
                  })
                ]
              )
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.descriptionShow,
            expression: "descriptionShow"
          }
        ],
        staticClass: "description"
      },
      [
        _c("table", [
          _vm._m(0),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("th", {
                domProps: {
                  textContent: _vm._s(
                    _vm.annualPremiumPrice > 0 ? "Annual Premium" : ""
                  )
                }
              }),
              _vm._v(" "),
              _c("td", {
                domProps: {
                  textContent: _vm._s(
                    _vm.annualPremiumPrice > 0
                      ? "$" +
                          _vm.formatPrice(
                            parseFloat(_vm.annualPremiumPrice).toFixed(2)
                          )
                      : ""
                  )
                }
              }),
              _vm._v(" "),
              _c("td", { staticClass: "lens-plus" }, [_vm._v("included")])
            ]),
            _vm._v(" "),
            _vm.examPrice > 0
              ? _c("tr", [
                  _c("th", [_vm._v("Exam")]),
                  _vm._v(" "),
                  _c("td", {
                    domProps: {
                      textContent: _vm._s(
                        "$" +
                          _vm.formatPrice(parseFloat(_vm.examPrice).toFixed(2))
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("td", { staticClass: "lens-plus" }, [_vm._v("included")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.frameLensPrice > 1
              ? _c("tr", [
                  _c("th", [_vm._v("Frame/Lens")]),
                  _vm._v(" "),
                  _c("td", {
                    domProps: {
                      textContent: _vm._s(
                        "$" +
                          _vm.formatPrice(
                            parseFloat(_vm.frameLensPrice).toFixed(2)
                          )
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("td", { staticClass: "lens-plus" }, [_vm._v("included")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.contactsPrice > 0
              ? _c("tr", [
                  _c("th", [_vm._v("Contacts")]),
                  _vm._v(" "),
                  _c("td", {
                    domProps: {
                      textContent: _vm._s(
                        "$" +
                          _vm.formatPrice(
                            parseFloat(_vm.contactsPrice).toFixed(2)
                          )
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("td", { staticClass: "lens-plus" }, [_vm._v("included")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.allowancePriceShow && Math.abs(_vm.allowancePrice) > 0
              ? _c("tr", [
                  _c("th", [_vm._v("Allowance")]),
                  _vm._v(" "),
                  _c("td", {
                    domProps: {
                      textContent: _vm._s(
                        "($" + Math.abs(_vm.allowancePrice) + ")"
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("td", { staticClass: "lens-plus" }, [_vm._v("included")])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("tfoot", [
            _c("tr", [
              _c("th", [_vm._v("Total Out-of-Pocket Cost")]),
              _vm._v(" "),
              _c("td", {
                domProps: {
                  textContent: _vm._s(
                    "$" +
                      _vm.formatPrice(
                        parseFloat(_vm.totalOutOfPrice).toFixed(2)
                      )
                  )
                }
              }),
              _vm._v(" "),
              _c("td", {
                staticClass: "total-price",
                domProps: {
                  textContent: _vm._s(
                    "$" + _vm.formatPrice(parseFloat(_vm.totalPrice).toFixed(2))
                  )
                }
              })
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Other Vision Plans")]),
        _vm._v(" "),
        _c("th", { staticClass: "lens-plus" }, [_vm._v("Lensabl+")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }