<template>
    <CatalogCategoryView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogCategoryView from '../../../components/catalog/category/CatalogCategoryView.vue'

export default {
    name: 'RouterCatalogCategoryView',
    components: {
        CatalogCategoryView
    },
    extends: App
}
</script>
