<template>
    <CatalogProductLensablView v-if="statusApi" />
</template>

<script>
import App from '../../../../App.vue'
import CatalogProductLensablView from '../../../../components/catalog/product/lensabl/CatalogProductLensablView.vue'

export default {
    name: 'RouterCatalogProductLensablView',
    components: {
        CatalogProductLensablView
    },
    extends: App
}
</script>
