import axios from 'axios'
import $ from 'jquery'
import {decode} from 'base64-arraybuffer'

let shipstation = null

class ShipstationService {
    constructor ({order_id, base_url, request}) {
        this.order_id = order_id
        this.base_url = base_url
        this.request = request
    }

    getLabelFromShipstation () {
        return axios.get(this.base_url + '/lensabl/order/label/order_id/' + this.order_id)
    }

    getOrderUrl () {
        return axios.get(this.base_url + '/shiped/index/order/order_id/' + this.order_id)
    }

    openPdfInNewWindow (pdf) {
        let win

        if (this.request) {
            win = window.open((window.URL || window.webkitURL).createObjectURL(new Blob([decode($.trim(pdf))], {type: 'application/pdf'})), '_self')
        } else {
            win = window.open(pdf, '_new')
        }

        if (!win) {
            alert('Allow pop-ups for this site.')
        } else {
            win.focus()
        }
    }
}

export default function getShipstationService (options = false) {
    if (options) {
        shipstation = new ShipstationService(options)
    }
    return shipstation
}
