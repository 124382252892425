<template>
   <div :class="[brand + '-seo-parent-container']">
        <div class="shopframes-seo-prescription-container">
            <div class="shopframes-seo-row seo-prescription">
                <div class="shopframes-seo-text">
                    <h2>Ray-Ban Glasses</h2>
                    <p>Lensabl has eyeglass frames from Ray-Ban and other leading designers. Complete your Ray-Ban frames with an accurate set of prescription lenses. Shop for well-made and stylish frames that look great, feel comfortable and offer the durability your life demands. Lensabl is the best place to find men's and women's Ray-Ban frames online. <a href="/prescription-scanner-app/qr"><span class="seo-contact-link">Scan your prescription</span></a> and order your new frames today.</p>
                </div>
                <div class="shopframes-seo-img">
                </div>
            </div>
        </div>

        <div class="shopframes-seo-frames-container">
            <div class="shopframes-seo-row seo-frames">
                <div class="shopframes-seo-frames-img">
                </div>
                <div class="shopframes-seo-text">
                    <h2>Ray-Bans for Your Prescription</h2>
                    <p>Lensabl's Ray-Ban prescription glasses will satisfy your exact optical needs. Our site makes it easy to search lenses by your prescription and match them with a set of Ray-Ban frames.<br /><br />Lensabl carries various types of prescription lenses, including single vision, bifocal, progressive and more. You can use Lensabl to <a href="/online-vision-prescription"><span class="seo-contact-link">renew your prescription online</span></a> and shop a wide selection of Ray-Ban glasses that will improve your vision.</p>
                </div>
            </div>
        </div>

        <div class="shopframes-seo-designer-container">
            <div class="shopframes-seo-row seo-designer">
                <div class="shopframes-seo-text">
                    <h2>Durable Eyeglass Frames for All Styles</h2>
                    <p>No matter your aesthetic preferences, you can match your prescription lenses with Ray-Ban frames that complement your taste. We carry the most popular styles, including Wayfarer, Clubmaster and Erika. Browse our selection of black, blue and red Ray-Ban prescription glasses, plus various other colors and patterns that will show your personality.<br /><br />Ray-Ban frames are available in numerous durable, lightweight materials like acetate, metal, plastic, propionate, steel and titanium. We encourage you to use the Lensabl size finder tool to choose a frame that looks great and fits comfortably all day.
                    </p>
                </div>
            <div class="shopframes-seo-designer-img">
            </div>
            </div>
        </div>

        <div class="shopframes-seo-eyeglass-container">
            <div class="shopframes-seo-row seo-eyeglass">
                <div class="shopframes-seo-text seo-eyeglass-text">
                    <h2>Buy Ray-Ban Frames Online </h2>
                    <p>Looking for fashionable prescription Ray-Bans? Lensabl puts any lens in any frame. Designers like Ray-Ban produce stylish eyewear, and with Lensabl, your glasses can offer pristine optical clarity. Order Ray-Ban frames from Lensabl for free standard shipping, a 30-day, risk-free return period and a 12-month warranty. You'll experience even more perks when you <a href="/plus"><span class="seo-contact-link">become a Lensabl+ member</span></a>. Register for our online vision plan today.</p>
                </div>
                <a href="/glasses-frames" title="Glasses Frames">
                    <div class="button button--copy-solid">
                        <div class="button__container">GET NEW FRAMES</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RayBanSeo',
    props: {
        brand: {
            type: [String],
            default: ''
        }
    },
}
</script>
