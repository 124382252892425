var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAvailable
    ? _c(
        "div",
        {
          staticClass: "product-configurator",
          attrs: { "sticky-container": "" }
        },
        [
          _vm.showProgressBar
            ? _c("ProgressBar", {
                staticClass: "product-configurator__progress-bar",
                attrs: { "custom-summary": _vm.customSummary }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "product-configurator--configurator",
              class: {
                "product-configurator--configurator-mobile": _vm.isMobileOnly
              }
            },
            [
              _c(
                "Steps",
                {
                  staticClass: "product-configurator__steps",
                  class: {
                    "product-configurator__steps-mobile": _vm.isMobileOnly
                  }
                },
                [
                  _vm.isMobile || _vm.isTablet || _vm.isProductConfigured
                    ? _c("Finish", {
                        staticClass: "product-configurator__steps-item",
                        attrs: { id: "configurator_step_final" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _vm.isTablet
                                    ? _c("Summary", {
                                        staticClass:
                                          "product-configurator__summary product-configurator-summary",
                                        attrs: {
                                          "custom-summary": _vm.customSummary
                                        }
                                      })
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2105089998
                        )
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("summary", [
                !_vm.isMobile && !_vm.isTablet
                  ? _c("Summary", {
                      staticClass:
                        "product-configurator__summary product-configurator-summary",
                      attrs: {
                        "sticky-available": _vm.configuratorShow,
                        "custom-summary": _vm.customSummary
                      }
                    })
                  : _vm._e()
              ])
            ],
            2
          ),
          _vm._v(" "),
          _vm.movingToCheckout
            ? _c("div", { staticClass: "move-to-checkout" }, [
                _c(
                  "div",
                  {
                    staticClass: "overlay-screen",
                    class: { visible: _vm.animationEnabled }
                  },
                  [_vm._m(0)]
                )
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "moving-message" }, [
      _c("div", { staticClass: "progress" }, [
        _c("div", { staticClass: "progress-line" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }