export const LENS_OPTIONS_CODE = 'lens_options'
export const LENS_OPTION_CODE_CR39 = 'cr39'
export const LENS_OPTION_CODE_POLY = 'poly'
export const LENS_OPTION_CODE_167 = '167'
export const LENS_OPTION_CODE_174 = '174'
export const LENS_OPTION_CODE_GLASS = 'glass'
export const LENS_OPTION_CODE_TRIVEX = 'trivex'

export const lensOptionContent = {
    [LENS_OPTION_CODE_CR39]: {
        'title': 'For basic prescriptions. Sharp optics at an affordable price point. Not suitable for rimless or semi-rimless frames.',
        'hint': '<p>For basic prescriptions. Sharp optics at an affordable price point. Not suitable for rimless or semi-rimless frames.</p>'
    },
    [LENS_OPTION_CODE_POLY]: {
        'title': 'Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.',
        'hint': '<p>Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.</p>'
    },
    [LENS_OPTION_CODE_167]: {
        'title': 'For those looking for thinner, lighter lenses. Recommended for strong prescriptions (a total power of +/- 4 or greater)',
        'hint': '<p>For those looking for thinner, lighter lenses. Recommended for strong prescriptions (a total power of +/- 4 or greater)</p>'
    },
    [LENS_OPTION_CODE_174]: {
        'title': 'For those looking for the thinnest, lightest lenses. Recommended for very strong prescriptions (a total power of +/-7 or greater)',
        'hint': '<p>For those looking for the thinnest, lightest lenses. Recommended for very strong prescriptions (a total power of +/-7 or greater)</p>'
    },
    [LENS_OPTION_CODE_GLASS]: {
        'title': 'Glass lenses are optically clearer than plastic, and are extremely scratch resistant. Not suitable for rimless or semi-rimless frames.<br/>Please allow up to 21 days for lens production.',
        'hint': '<p>Glass lenses are optically clearer than plastic, and are extremely scratch resistant. Not suitable for rimless or semi-rimless frames.<br/>Please allow up to 21 days for lens production.</p>'
    },
    [LENS_OPTION_CODE_TRIVEX]: {
        'title': 'Impact/Shatter resistant lenses that are lighter than polycarbonate, with greater optical clarity',
        'hint': '<p>Impact/Shatter resistant lenses that are lighter than polycarbonate, with greater optical clarity</p>'
    }
}

export default function getLensOptionHelper (lens_options, type) {
    const options = lensOptionContent[lens_options]
    if (!options) {
        return ''
    }
    return options[type] ? options[type] : ''
}
