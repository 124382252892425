<template>
    <div class="plus-account_container plusdiscount-container">
        <h4 v-if="isMobile" :class="{active: isShow}" @click="toggleContent">Member Discount</h4>
        <transition name="slide">
            <div v-if="!isMobile || isShow && isMobile" class="plus-content_container">
                <div>
                    <img src="/js/lensconfigurator/assets/save_money_desktop.svg" alt="contact lenses" />
                </div>
                <div class="redeem-description">
                    <span class="description">Up to <span>{{lensPercent}}%</span> discount on additional Lensabl purchases</span>
                    <HelpBubble :data="getHelpComponent['discount']" />
                </div>

                <div class="secondary">
                    <BaseButton :disabled="!paidUp" :text="btnText" data-size="xs" :class="{disable: !previousvisit}" class="button button--copy-solid" @click.native="continueToShop" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import BaseButton from '../../../components/base/BaseButton.vue'

export default {
    name: 'PlusDiscountRedeem',
    components: {
        BaseButton,
        HelpBubble
    },
    data: () => ({
        isShow: false
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablplus_customer', ['paidUp', 'isBusinessMember', 'getPercentages', 'getHelpComponent']),
        ...mapGetters('storeView', ['base_url']),
        ...mapState('gsap', ['previousvisit']),
        btnText () {
            if (!this.paidUp) return 'SUSPEND'
            else if (this.isBusinessMember) return 'SHOP NOW'
            else return 'REDEEM'
        },
        lensPercent(){
            return this.getPercentages['lens']
        }
    },
    methods: {
        toggleContent () {
            if (this.isMobile) {
                this.isShow = !this.isShow
            }
        },
        continueToShop () {
            if (this.previousvisit) {
                window.location.href = this.base_url + 'shop-frames'
            }
        }
    }
}
</script>

<style scoped>

</style>
