<template>
    <div>
        <div class="c-product-content-bg">
            <div class="c-product-content-container">
                <div class="c-product-content-half">
                    <h1 class="c-product-title">
                        <div v-dompurify-html="product_name"></div>
                    </h1>
                    <p class="c-product-price"><span :class="{ subscribed: getSubscribed === 'yes'}" class="c-product-price--main">${{ getContactsPrice }}</span> <span :class="{ subscribed: getSubscribed === 'yes' }" class="c-product-price--subscription">${{ getContactsSubscriptionPrice }}</span> <span class="c-product-price--text">per box</span> | {{ lens_number }} lenses per box</p>
                    <p v-if="valid" class="c-product-rebate">Buy {{ rebate_number }} boxes and save <span>{{ rebate_discount }}</span> <a href="/alcon-rebate">after rebate.</a></p>

                    <!--  Mobile Gallery -->
                    <MobileGallery :name="product_name" />

                    <!--  Monthly Supply Amount Boxes -->
                    <div class="supply-amount_container">
                        <label v-for="(amount, index) in deliveryFrequency['amount']" :key="index" :class="{ active: active_el.active === amount, disabled: getSubscribed === 'yes'}" :for="amount" class="supply-amount">
                            <input :id="amount" ref="supply_amount" :title="index + 'supply'" :disabled="getSubscribed === 'yes' || supplyDisable" type="radio" name="supply_amount" @click="updateQuantity(amount)" />
                            <span class="supply-label-container">
                                <div>
                                    <span> {{ index.replace('-', ' ') }} Supply</span>
                                    <br />
                                    <span v-if="amount > 1">{{ amount }} boxes per eye</span>
                                    <span v-else>{{ amount }} box per eye</span>
                                </div>
                            </span>
                        </label>
                    </div>

                    <!--  Left Eye / Right Eye dropdown  -->
                    <div class="c-product-lr">
                        <div class="c-product-lr-right">
                            <label :class="{ disabled: isRightDisabled }" class="c-product-lr-label">
                                <div :class="{ active: rightEyeChecked && !isRightDisabled }" class="c-product-lr-checkbox-container">
                                    <div class="c-product-lr-checkbox">
                                        <input :disabled="isRightDisabled" type="checkbox" title="Right Eye (OD)" @click="checkRadioButton('right')" />
                                    </div>
                                </div>
                                <span>Right Eye (OD)</span>
                            </label>
                            <div class="c-product-lr-select">
                                <select id="right" :disabled="isRightDisabled" name="right" @change="onBoxDropdown($event, 'right')">
                                    <option
                                        v-for="i in 25"
                                        :key="i"
                                        v-dompurify-html="isRightDisabled ? getEyeData.right.brand.substring(0, 7) + '...' : i "
                                        :selected="i === parseInt(default_box_quantity)"
                                        :value="i"
                                    ></option>
                                </select>
                                <label for="right">Number of Boxes</label>
                            </div>
                        </div>
                        <div class="c-product-lr-left">
                            <label :class="{ disabled: isLeftDisabled }" class="c-product-lr-label">
                                <div :class="{ active: leftEyeChecked && !isLeftDisabled }" class="c-product-lr-checkbox-container">
                                    <div class="c-product-lr-checkbox">
                                        <input :disabled="isLeftDisabled" type="checkbox" title="Left Eye (OD)" @click="checkRadioButton('left')" />
                                    </div>
                                </div>
                                <span>Left Eye (OS)</span>
                            </label>
                            <div class="c-product-lr-select">
                                <select id="left" :disabled="isLeftDisabled" name="left" @change="onBoxDropdown($event, 'left')">
                                    <option
                                        v-for="i in 25"
                                        :key="i"
                                        v-dompurify-html="isLeftDisabled ? getEyeData.left.brand.substring(0, 7) + '...' : i"
                                        :value="i"
                                        :selected="i === parseInt(default_box_quantity)"
                                    ></option>
                                </select>
                                <label for="right">Number of Boxes</label>
                            </div>
                        </div>
                    </div>

                    <div v-if="(colors[0] || []).length !== 0" class="c-product-lr-color">
                        <span class="c-product-color-label">Select Color</span>
                        <div class="c-product-lr-select">
                            <select id="color" v-model="selectedColor" :class="{ error: showError === true}" name="color" @change="selectColor()">
                                <option v-for="color in colors" :key="color" :value="color">{{ color }}</option>
                            </select>
                            <label for="color">Color</label>
                        </div>
                    </div>

                    <div class="c-product-continue button button--copy-solid">
                        <div class="button__container" @click="confirmSelection()">Continue</div>
                    </div>

                    <div v-if="!plusMember" class="c-product-lplus-ad-block">
                        <p>
                            <b>Save big</b> on an annual supply by joining Lensabl+
                            <span class="lplus-ad-block_container" @click="openPlusAdModal">
                                <a :href="adLink">
                                    <span class="c-product-lplus-ad-link">Learn More</span>
                                    <CatalogContactsProductViewHelpComponent :show-modal-ad="showModalAd" />
                                </a>
                            </span>
                        </p>
                    </div>
                </div>

                <!-- Desktop Gallery -->
                <div class="c-product-content-half">
                    <DesktopGallery :name="product_name" />
                </div>
            </div>
        </div>

        <Guarantee />

        <!-- Modal -->
        <Modal v-if="showModal" @close="showModal = false ">
            <span class="modal-header">Do you need a different brand for your left/right eye?</span>
            <div class="modal-product-button-container">
                <div class="button button--copy-solid">
                    <div class="button__container" @click="selectYes()">Yes</div>
                </div>
                <div class="button button--copy-solid button--copy-solid-grey">
                    <div class="button__container" @click="selectNo()">No</div>
                </div>
            </div>
        </Modal>

        <!--   Product description  -->
        <CatalogContactsProductViewDescription class="c-product-secondary-block" />
    </div>
</template>

<script>
import _size from 'lodash/size'
import {mapState, mapGetters, mapActions} from 'vuex'
import productStorage from './storage/product/view'
import {
    CONTACTS_RX_KEYS_INTERFACE
} from '../storage/contacts'

import Guarantee from './view/Guarantee.vue'
import CatalogContactsProductViewDescription from './view/Description.vue'
import CatalogContactsProductViewHelpComponent from './view/HelpComponent.vue'
import DesktopGallery from '../DesktopGallery.vue'
import MobileGallery from '../MobileGallery.vue'
import Modal from '../Modal.vue'

export default {
    name: 'CatalogContactsProductView',
    components: {
        Guarantee,
        CatalogContactsProductViewDescription,
        CatalogContactsProductViewHelpComponent,
        DesktopGallery,
        MobileGallery,
        Modal
    },
    data: () => ({
        showModal: false,
        isRightDisabled: false,
        isLeftDisabled: false,
        disableLeft: false,
        disableRight: false,
        selectedColor: '',
        showSubscribe: '',
        colors: [],
        valid: false,
        showError: false,
        active_el: {
            'active': 0,
            'original': 0
        },
        supplyDisable: false,
        showModalAd: false,
        adLink: '/plus/vision_plan'
    }),
    computed: {
        ...mapState('contacts', ['leftEyeChecked', 'rightEyeChecked', 'leftEyeConfirmed', 'rightEyeConfirmed', 'originalEyeData']),
        ...mapState('contacts/productView', ['product_name', 'second_name', 'price', 'lens_number', 'bc', 'dia', 'power', 'cyl', 'axis', 'add', 'default_box_quantity', 'images', 'product_sku', 'color_options', 'subscription_percentage', 'rebate_expiration', 'rebate_discount', 'rebate_number', 'enable_subscription', 'default_sub_box_quantity']),
        ...mapGetters('contacts', ['getEyeData', 'isContactProductPage', 'getSubscribed', 'getContactsPrice', 'getContactsSubscriptionPrice']),
        ...mapGetters('contacts/productView', ['deliveryFrequency']),
        ...mapGetters('lensablplus_customer', ['plusMember'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['contacts/productView/']) {
            this.$store.registerModule(['contacts', 'productView'], productStorage(this.$store), { preserveState: false })
        }
    },
    created () {
        this.fetchContactsProductState()
        this.$store.dispatch('contacts/clearProductAddToCart')
        this.setDisabledEye()
        const allColors = this.color_options ? this.color_options.substring(0, this.color_options.length - 1) : false
        const container = allColors ? allColors.split(',') : []
        this.colors = container.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])
        for (let i = 0; i < this.colors.length; i++) {
            this.colors[i] = (this.colors[i] || '').trim()
            if (this.colors[i] === 'Null') {
                this.colors.splice(i, 1)
            }
        }
        if (!this.selectedColor && (this.colors[0] || []).length !== 0) {
            this.selectedColor = this.colors[0]
        }
    },
    mounted () {
        this.getExpirationDate()
        if (this.rightEyeConfirmed && this.leftEyeConfirmed) {
            this.$store.dispatch('contacts/checkProductDetailsStep')
            this.isLeftDisabled = false
            this.isRightDisabled = false
        }
        this.$store.commit('contacts/UPDATE_PRODUCT_PRICE', this.price)
        this.$store.commit('contacts/UPDATE_PRODUCT_SUBSCRIPTION_PRICE', {regPrice: this.price, subscriptionPercent: this.subscription_percentage})

        const exist = Object.values(this.deliveryFrequency['amount']).includes(parseInt(this.default_box_quantity))

        if (exist) {
            this.active_el.active = parseInt(this.default_box_quantity)
            this.active_el.original = parseInt(this.default_box_quantity)
            document.getElementById(this.default_box_quantity).checked = true
        }

        if (this.leftEyeConfirmed || this.rightEyeConfirmed) {
            this.$store.commit('contacts/SET_SUBSCRIBE', false)
            this.showSubscribe = false
        } else {
            this.showSubscribe = true
        }
    },
    methods: {
        ...mapActions('contacts/productView', ['fetchContactsProductState']),
        setDisabledEye () {
            if (this.leftEyeConfirmed) {
                this.isLeftDisabled = true
                this.$store.dispatch('contacts/setCheckedEye', 'right')
            }

            if (this.rightEyeConfirmed) {
                this.isRightDisabled = true
                this.$store.dispatch('contacts/setCheckedEye', 'left')
            }
        },
        selectColor () {
            this.showError = false
        },
        checkRadioButton (eye) {
            const left_eye = document.getElementById('left')
            const right_eye = document.getElementById('right')
            this.$store.dispatch('contacts/toggleCheckedEye', eye)
            if (eye === 'left') {
                if (this.leftEyeChecked) {
                    left_eye.value = this.default_box_quantity
                } else {
                    left_eye.value = 0
                    left_eye.disabled = false
                }

                if (this.leftEyeChecked && this.getSubscribed === 'yes') {
                    left_eye.disabled = true
                    left_eye.value = 1
                }
            } else {
                if (this.rightEyeChecked) {
                    right_eye.value = this.default_box_quantity
                } else {
                    right_eye.value = 0
                    right_eye.disabled = false
                }
                if (this.rightEyeChecked && this.getSubscribed === 'yes') {
                    right_eye.disabled = true
                    right_eye.value = 1
                }
            }

            // logic for monthly supply boxes, should move into a method

            if (this.getSubscribed === 'no') {
                if (this.leftEyeChecked && !this.rightEyeChecked) {
                    const exist = Object.values(this.deliveryFrequency['amount']).includes(parseInt(document.getElementById('left').value))

                    if (exist) {
                        this.active_el.active = document.getElementById('left').value
                        this.active_el.original = document.getElementById('left').value
                        this.supplyDisable = false
                        document.getElementById(document.getElementById('left').value).checked = true
                    }
                } else if (!this.leftEyeChecked && this.rightEyeChecked) {
                    const exist = Object.values(this.deliveryFrequency['amount']).includes(parseInt(document.getElementById('right').value))

                    if (exist) {
                        this.active_el.active = document.getElementById('right').value
                        this.active_el.original = document.getElementById('right').value
                        this.supplyDisable = false
                        document.getElementById(document.getElementById('right').value).checked = true
                    }
                }

                if (this.leftEyeConfirmed && !this.rightEyeChecked) {
                    this.supplyDisable = false
                    document.getElementById(this.active_el.original).checked = false
                    this.active_el.active = 0
                    this.active_el.original = 0
                } else if (this.rightEyeConfirmed && !this.leftEyeChecked) {
                    this.supplyDisable = false
                    document.getElementById(this.active_el.original).checked = false
                    this.active_el.active = 0
                    this.active_el.original = 0
                } else if (!this.leftEyeChecked && !this.rightEyeChecked) {
                    this.supplyDisable = true
                    if (document.getElementById(this.active_el.original)) {
                        document.getElementById(this.active_el.original).checked = false
                        this.active_el.active = 0
                        this.active_el.original = 0
                    }
                } else if (this.leftEyeConfirmed && this.rightEyeChecked) {
                    const exist = Object.values(this.deliveryFrequency['amount']).includes(parseInt(document.getElementById('right').value))
                    if (exist) {
                        this.active_el.active = parseInt(document.getElementById('right').value)
                        this.active_el.original = parseInt(document.getElementById('right').value)
                        this.supplyDisable = false
                        document.getElementById(document.getElementById('right').value).checked = true
                    }
                } else if (this.rightEyeConfirmed && this.leftEyeChecked) {
                    const exist = Object.values(this.deliveryFrequency['amount']).includes(parseInt(document.getElementById('left').value))
                    if (exist) {
                        this.active_el.active = parseInt(document.getElementById('left').value)
                        this.active_el.original = parseInt(document.getElementById('left').value)
                        this.supplyDisable = false
                        document.getElementById(document.getElementById('left').value).checked = true
                    }
                } else if (this.leftEyeChecked && this.rightEyeChecked) {
                    const exist = Object.values(this.deliveryFrequency['amount']).includes(parseInt(document.getElementById('left').value))
                    if (document.getElementById('left').value === document.getElementById('right').value && exist) {
                        this.active_el.active = parseInt(document.getElementById('left').value)
                        this.active_el.original = parseInt(document.getElementById('left').value)
                        this.supplyDisable = false
                        document.getElementById(document.getElementById('left').value).checked = true
                    } else {
                        if (document.getElementById(this.active_el.original)) {
                            document.getElementById(this.active_el.original).checked = false
                            this.active_el.active = 0
                            this.active_el.original = 0
                            this.supplyDisable = false
                        }
                    }
                }
            }
        },
        onBoxDropdown (event, eye) {
            this.active_el.active = 0
            this.active_el.original = 0
            const radList = this.$refs['supply_amount']

            for (let i = 0; i < radList.length; i++) {
                if (radList[i].checked) {
                    document.getElementById(radList[i].id).checked = false
                }
            }

            const exist = Object.values(this.deliveryFrequency['amount']).includes(parseInt(event.target.value))

            if (this.getSubscribed === 'no') {
                if (this.leftEyeChecked && this.rightEyeChecked) {
                    if (document.getElementById('left').value === document.getElementById('right').value && exist) {
                        this.active_el.active = parseInt(event.target.value)
                        this.active_el.original = parseInt(event.target.value)

                        document.getElementById(event.target.value).checked = true
                    }
                } else {
                    if (exist) {
                        this.active_el.active = parseInt(event.target.value)
                        this.active_el.original = parseInt(event.target.value)

                        document.getElementById(event.target.value).checked = true
                    }
                }

                if ((this.leftEyeConfirmed || this.rightEyeConfirmed) && exist) {
                    this.active_el.active = parseInt(event.target.value)
                    this.active_el.original = parseInt(event.target.value)

                    document.getElementById(event.target.value).checked = true
                }

                if (event.target.value > 0) {
                    this.$store.dispatch('contacts/setCheckedEye', eye)
                    eye === 'left' ? this.disableLeft = true : this.disableRight = true

                    if (this.leftEyeChecked && !this.leftEyeConfirmed && this.getSubscribed === 'yes') {
                        document.getElementById('left').disabled = true
                        document.getElementById('left').value = this.default_box_quantity
                    }
                    if (this.rightEyeChecked && !this.rightEyeConfirmed && this.getSubscribed === 'yes') {
                        document.getElementById('right').disabled = true
                        document.getElementById('right').value = this.default_box_quantity
                    }
                } else {
                    this.$store.dispatch('contacts/toggleCheckedEye', eye)
                }
            }
        },
        confirmSelection () {
            this.storeEyeData()

            if ((this.leftEyeChecked && this.rightEyeChecked) || (this.leftEyeConfirmed || this.rightEyeConfirmed)) {
                if ((this.colors[0] || []).length !== 0 && this.selectedColor === '') {
                    this.showError = true
                    return null
                } else {
                    this.$store.commit('contacts/UPDATE_DETAILS_STEP')
                    this.$store.dispatch('contacts/updateConfirmedEye')
                    window.location.href = '/contact-lenses-details'
                }
            } else if (!this.leftEyeChecked && !this.rightEyeChecked) {
                return null
            } else {
                this.toggleModal()
            }
        },
        toggleModal () {
            this.showModal = !this.showModal
        },
        storeEyeData () {
            if ((!this.leftEyeConfirmed && this.leftEyeChecked)) {
                this.$store.commit('contacts/SET_EYE_DATA', this.setEyeData('left'))
                this.$store.commit('contacts/SET_ORIGINAL_DATA', this.setEyeData('left'))
            }

            if ((!this.rightEyeConfirmed && this.rightEyeChecked)) {
                this.$store.commit('contacts/SET_EYE_DATA', this.setEyeData('right'))
                this.$store.commit('contacts/SET_ORIGINAL_DATA', this.setEyeData('right'))
            }
        },
        setEyeData (eye) {
            return {
                eye: eye,
                brand: this.product_name,
                second_name: this.second_name,
                image: _size(this.images) ? this.images[1].thumbnail : '',
                boxes: document.getElementById(eye).value,
                regPrice: this.price,
                subPrice: this.getContactsSubscriptionPrice,
                product_sku: this.product_sku,
                default_box_quantity: this.default_box_quantity,
                default_sub_box_quantity: this.default_sub_box_quantity,
                color: this.selectedColor,
                rx_dropdown: {
                    ...CONTACTS_RX_KEYS_INTERFACE,
                    bc: this.isEmptyVal(this.bc),
                    dia: this.isEmptyVal(this.dia),
                    power: this.power,
                    cyl: this.isEmptyVal(this.cyl),
                    axis: this.isEmptyVal(this.axis),
                    add: this.isEmptyVal(this.add)
                },
                rx: {
                    ...CONTACTS_RX_KEYS_INTERFACE,
                    bc: this.bc && this.bc.split(',').length === 2 ? this.bc.replace(',', '') : '',
                    dia: this.dia && this.dia.split(',').length === 2 ? this.dia.replace(',', '') : ''
                }
            }
        },
        isEmptyVal (val) {
            const array = val ? val.split(',') : []
            if (array.length === 2 && parseFloat(array[0]) === 0) {
                return ''
            } else {
                return val
            }
        },
        selectYes () {
            this.storeEyeData()
            this.$store.dispatch('contacts/updateConfirmedEye')
            window.location.href = '/shop-contacts/brands'
        },
        selectNo () {
            this.storeEyeData()
            this.$store.commit('contacts/UPDATE_DETAILS_STEP')
            this.$store.dispatch('contacts/updateConfirmedEye')
            window.location.href = '/contact-lenses-details'
        },
        getExpirationDate () {
            const q = new Date()
            const m = q.getMonth() + 1
            const d = q.getDay()
            const y = q.getFullYear()

            const date = new Date(y, m, d)

            const exp = new Date(this.rebate_expiration)
            if (date > exp) {
                this.valid = false
            }
        },
        updateSubscribe (val) {
            this.active_el.active = val
        },
        updateQuantity (val) {
            this.active_el.active = val
            this.active_el.original = val
            if (val !== 0) {
                if (this.leftEyeChecked) {
                    document.getElementById('left').value = val
                }
                if (this.rightEyeChecked) {
                    document.getElementById('right').value = val
                }
            }
        },
        openPlusAdModal () {
            if (window.innerWidth < 768) {
                this.showModalAd = true
                this.adLink = null
            } else {
                this.showModalAd = false
                this.adLink = '/plus/vision_plan'
            }
        },
        closePlusAdModal () {
            this.showModalAd = false
        }
    }
}
</script>
