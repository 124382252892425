var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "radio-label", on: { click: _vm.click } },
    [
      _c(
        "div",
        {
          staticClass: "option-checkbox-container",
          class: { active: _vm.active }
        },
        [
          _c("div", { staticClass: "option-checkbox" }, [
            _vm.name
              ? _c("input", {
                  attrs: { name: _vm.name, type: _vm.type },
                  domProps: { value: _vm.value, checked: _vm.active }
                })
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _vm._t("default", [_c("span", [_vm._v(_vm._s(_vm.label))])])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }