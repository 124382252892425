export const WARRANTY_INFO = {
    'label': 'Protection Plan',
    'desc': 'Lenses are covered for scratches made during normal use and any coating defects for the first 3 months from the date you receive your lenses. The lens protection plan extends this coverage from 3 months to 12 months.',
    'image': 'scratch.svg'
}

export const WARRANTY_STEP = [
    {
        'price': 10,
        'desc': '',
        'checked': false,
        'value': 'warranty',
        'alias': 'Lens Protection',
        'label': 'Lens Protection',
        'image': false,
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Lenses are covered for scratches made during normal use and any coating defects for the first 3 months from the date you receive your lenses. The lens protection plan extends this coverage from 3 months to 12 months.</p> '
            }
        }
    }
]

export const WARRANTY_PRICE_CHANGE = {
    'single_vision_distance': {
        'CR39': {
            price: WARRANTY_STEP[0].price,
            sku: 'warranty'
        },
        'Poly': {
            price: WARRANTY_STEP[0].price,
            sku: 'warranty'
        },
        'Trivex': {
            price: WARRANTY_STEP[0].price,
            sku: 'warranty'
        },
        '167': {
            price: WARRANTY_STEP[0].price + 5,
            sku: 'warranty-sv-high-index'
        },
        '174': {
            price: WARRANTY_STEP[0].price + 5,
            sku: 'warranty-sv-high-index'
        }
    },
    'progressive_standard': {
        'CR39': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        'Poly': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        'Trivex': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        '167': {
            price: WARRANTY_STEP[0].price + 15,
            sku: 'warranty-multi-high-index'
        },
        '174': {
            price: WARRANTY_STEP[0].price + 15,
            sku: 'warranty-multi-high-index'
        }
    },
    'progressive_premium': {
        'CR39': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        'Poly': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        'trivex': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        '167': {
            price: WARRANTY_STEP[0].price + 15,
            sku: 'warranty-multi-high-index'
        },
        '174': {
            price: WARRANTY_STEP[0].price + 15,
            sku: 'warranty-multi-high-index'
        }
    },
    'bi_focal': {
        'CR39': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        'Poly': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        'Trivex': {
            price: WARRANTY_STEP[0].price + 10,
            sku: 'warranty-multi-regular'
        },
        '167': {
            price: WARRANTY_STEP[0].price + 15,
            sku: 'warranty-multi-high-index'
        },
        '174': {
            price: WARRANTY_STEP[0].price + 15,
            sku: 'warranty-multi-high-index'
        }
    }
}
