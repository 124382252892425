var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      {
        ref: _vm.data.ref,
        class: [_vm.data.class],
        style: [_vm.data.style, _vm.data.staticStyle]
      },
      "div",
      _vm.data.attrs,
      false
    ),
    [
      _c(
        "transition",
        { attrs: { name: "sf-fade", mode: "out-in" } },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }