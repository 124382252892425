<template>
    <div v-if="hasDiscount" class="discount-container">
        <div class="totals-price-discount total-line">
            <span class="total-label">Discount</span>
            <span class="total-value">
                <span class="total-value-currency currency">$a</span>
                <span class="total-value-amount amount">{{ discountAmount }}</span>
            </span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import summary from './storage/summary'

export default {
    name: 'TotalsDiscount',
    computed: {
        ...mapGetters('summary', ['hasDiscount', 'discountAmount'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['summary/']) {
            this.$store.registerModule('summary', summary(this.$store))
        }
        this.$store.dispatch('summary/fetchDefaultData')
    }
}
</script>
