<template>
    <div>
        <div v-show="productView.productInfoShow" :class="{'product-layout-2columns': isDesktop}"
             class="product-details-holder lensabl-frames-view frames-wrapper">
            <div class="product media">
                <Gallery :class="{'vertical-thumbs': isDesktop}"/>
            </div>
            <div class="product-info-main">
                <div class="page-title-wrapper product">
                    <h1 v-dompurify-html="product_name" :class="product_name" class="page-title" itemprop="name"></h1>
                </div>
                <AdditionalAttributes :class="{'inline': !isDesktop && !isMobile, 'grid': isMobile}" :data-attributes="additionalAttributes" />

                <ProductAffirmInfo :startingTitle="`Starting from $`" />
                <p class="shopnshop-time-text">Lens production takes 4-7 business days on average from the date you've submitted all required Rx information. All lenses go through a detailed quality inspection before shipping out. If any issues are found during inspection, we will remake the lenses until they are perfect (this may cause slight delays in shipping time)</p>
                <template v-if="colors.length">
                    <div class="swatch-opt">
                        <ColorSwatches
                            :colors="colors"
                            :checked-index="checkedIndex"
                            :option-item-class="'decorate bigger img'"
                            :product-name="product_name.toLowerCase()"
                            @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColorAndReset"
                        />
                    </div>

                    <div v-if="isAvailable" class="product-options-bottom">
                        <div class="actionsproductCategoryName">
                            <BaseButton :text="'Choose Your Lenses'"
                                        @click.native="isMobile ? mobileSwitcher() : goToConfigurator()">
                                Select Lenses
                            </BaseButton>
                        </div>
                    </div>
                    <p v-if="!isAvailable" class="unavailable stock" title="Availability">
                        <span style="color:red">This item is sold out</span>
                    </p>
                </template>
            </div>
        </div>
        <div class="product-border-divider">
            <p v-if="!isMobile">Already have the frames and just need new lenses? <a href="/choose-my-lenses">Click Here</a></p>
            <p v-if="isMobile">Have James Oro Frames and just need lenses? <a href="/choose-my-lenses">Click Here</a></p>
            <p v-if="isMobile">Lens production takes 4-7 business days on average from the date you've submitted all required Rx information.
                All lenses go through a detailed quality inspection before shipping out. if any issues are found during inspection, we will remake
            the lenses until they are perfect (this may slight delays in shipping time)</p>
        </div>
        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator />
        </div>
        <ReturnsInfo v-if="productView.productInfoShow"/>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CatalogProductBlockView from "../CatalogProductBlockView.vue";

export default {
    name: 'JamesOroCatalogProductBlockView',
    extends: CatalogProductBlockView,
    methods: {
        goToConfigurator () {
            this.activateFirstVisibleStep();
            this.scrollToStepHead(this.visibleFirstStep.id);
        }
    }
}
</script>