<template>
    <div v-if="categoryName" :class="['banner-' + categoryName.toLowerCase()]" :style="{'background-image': `url('/js/lensconfigurator/assets/${categoryName.toLowerCase()}.png')`}">
        <div v-dompurify-html="description" class="banner-container"></div>
    </div>
</template>

<script>
import {
    BANNER_TEXT
} from '../constants/description'

export default {
    name: 'CatalogCategoryLensablViewSubCategoryBanner',
    props: {
        category: {
            type: [Object, Array],
            default: () => {}
        }
    },
    data: () => ({
        descriptions: BANNER_TEXT
    }),
    computed: {
        categoryName () {
            return this.category ? this.category.name : ''
        },
        description () {
            return this.descriptions[this.category.name] || this.category.description
        }
    }
}
</script>
