<template>
    <JamesOroCatalogProductBlockView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import JamesOroCatalogProductBlockView from '../../../components/catalog/product/JamesOro/CatalogProductBlockView.vue'

export default {
    name: 'RouterJamesOroCatalogProductBlockView',
    components: {
        JamesOroCatalogProductBlockView
    },
    extends: App
}
</script>
