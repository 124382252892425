<template>
    <v-flex class="ui-date-box">
        <label v-if="label" v-dompurify-html="label"></label>
        <span v-if="!editDate" v-dompurify-html="date" class="datepicker-text"></span>
        <v-flex v-else xs12 lg6 datepicker-selectbox>
            <v-col>
                <select v-model="dateData.month" :class="{'validation-failed': !checkMonth}" title="Month">
                    <template v-for="month in dateOptions.months">
                        <option :key="`month-${month.value}`" :value="month.value">{{ month.label }}</option>
                    </template>
                </select> /
            </v-col>
            <v-col>
                <select v-model="dateData.day" :class="{'validation-failed': !checkDay}" title="Day">
                    <template v-for="day in dateOptions.days">
                        <option :key="`day-${day.value}`" :value="day.value">{{ day.label }}</option>
                    </template>
                </select> /
            </v-col>
            <v-col>
                <select v-model="dateData.year" :class="{'validation-failed': !checkYear}" title="Year">
                    <template v-for="year in dateOptions.years">
                        <option :key="`year-${year.value}`" :value="year.value">{{ year.label }}</option>
                    </template>
                </select>
            </v-col>
        </v-flex>
        <v-col>
            <button title="Edit" type="button" class="scalable" @click="saveDate">
                <span><span><span v-text="editDate ? `Update` : `Edit`"></span></span></span>
            </button>
        </v-col>
    </v-flex>
</template>

<script>
import _find from 'lodash/find'

export default {
    name: 'DateRange',
    props: {
        label: {
            type: [Boolean, String],
            default: false
        },
        value: {
            type: [String],
            default: ''
        }
    },
    data: () => ({
        date: '00/00/00',
        dateData: {
            day: '',
            month: '',
            year: ''
        },
        editDate: false
    }),
    computed: {
        dateOptions () {
            const days = {}
            const months = {}
            const years = {}
            for (let i = 0; i < 10; i++) {
                const year = new Date().getFullYear() + i
                years[year] = {
                    label: year,
                    value: year
                }
            }
            for (let i = 1; i <= 12; i++) {
                months[i] = {
                    label: i < 10 ? `0${i}` : i,
                    value: i
                }
            }
            for (let i = 1; i <= this.getDaysInMonth(this.dateData.month, this.dateData.year); i++) {
                days[i] = {
                    label: i < 10 ? `0${i}` : i,
                    value: i
                }
            }

            return {
                days,
                months,
                years
            }
        },
        checkDay () {
            return new Date(this.date).getDate()
        },
        checkMonth () {
            const date = new Date(this.date)
            return date.getMonth() === 0 || date.getMonth()
        },
        checkYear () {
            return new Date(this.date).getFullYear()
        }
    },
    watch: {
        dateData: {
            handler (date) {
                this.date = `${String(date.month).padStart(2, '0')}/${String(date.day).padStart(2, '0')}/${date.year}`
            },
            deep: true
        },
        dateOptions: {
            handler (date) {
                if (this.dateData.year && !_find(date.years, year => year.value === this.dateData.year)) {
                    this.dateData.year = ''
                }
                if (this.dateData.month && !_find(date.months, month => month.value === this.dateData.month)) {
                    this.dateData.month = ''
                }
                if (this.dateData.day && !_find(date.days, day => day.value === this.dateData.day)) {
                    this.dateData.day = ''
                }
            },
            deep: true
        }
    },
    created () {
        if (this.value) {
            const date = new Date(this.value)
            this.dateData.year = date.getFullYear()
            this.dateData.month = date.getMonth() ? date.getMonth() + 1 : ''
            this.dateData.day = date.getDate()
        }
    },
    methods: {
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        getDaysInMonth (month, year) {
            return new Date(year, month, 0).getDate()
        },
        saveDate () {
            if (!this.editDate) {
                this.editDate = true
            } else if (new Date(this.date).getDate()) {
                this.$emit('save', this.date)
                this.editDate = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
    label {
        font-weight: bold;
    }
    .flex {
        display: flex;
        .col {
            white-space: nowrap;
            &:not(:first-child) {
                margin-left: 3px;
            }
        }
    }
    .datepicker {
        &-text,
        &-selectbox {
            margin: 0 1rem;
        }
    }
    select.validation-failed {
        background: #fef0ed;
        border: 1px dashed #d6340e;
    }
</style>
