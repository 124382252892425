<template>
    <AnagramAdBar v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import AnagramAdBar from '../../components/anagram/AnagramAdBar.vue'

export default {
    name: 'RouterAnagramAdBar',
    components: {
        AnagramAdBar
    },
    extends: App
}
</script>
