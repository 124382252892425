import { render, staticRenderFns } from "./Description.vue?vue&type=template&id=fa6a965c&"
import script from "./Description.vue?vue&type=script&lang=js&"
export * from "./Description.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/tkim/projects/lensabl/lensconfigurator/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fa6a965c')) {
      api.createRecord('fa6a965c', component.options)
    } else {
      api.reload('fa6a965c', component.options)
    }
    module.hot.accept("./Description.vue?vue&type=template&id=fa6a965c&", function () {
      api.rerender('fa6a965c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/contacts/product/view/Description.vue"
export default component.exports