import getHelper from '../helper'
import {
    LENS_OPTIONS_CODE
} from '../lens_options'
import getOpticalStepList from './optical_step_list'
import getNightStepList from './night_step_list'
import getSunStepList from './sun_step_list'
import getBoseStepList from './bose_step_list'
import getRheosStepList from './rheos_step_list'
import getSpectaclesStepList from './spectacles_step_list'
import getSpectaclesGenStepList from './spectacles_gen_step_list'
import getContactsStepList from './contacts_step_list'
import getHyperXStepList from './hyperx_step_list'
import getLeSpecsStepList from './lespecs_step_list'
import getHobieSunglassesStepList from './hobie_sunglasses_step_list'
import getHobieOpticalStepList from './hobie_optical_step_list'
import getHobieClipOnStepList from './hobie_clipon_step_list'
import getPPEStepList from './ppe_step_list'
import getGamerAdvantageStepList from './gameradvantage_step_list'
import getKiauraStepList from './kiaura_step_list'
import {
    LENS_TYPE_ID
} from '../../../../../app/common/constants/lens_type'
import {framesTypeStep, defaultOptions} from "./default";
import getGoodrStepList from "./goodr_step_list";
import getAeStepList from "./ae_step_list";
import getWhStepList from "./wh_step_list";

export function getStepList(params = {}) {
    const defaultStepOptions = defaultOptions(params)
    let {
        frames_name = '',
        product_config,
        store_code = ''
    } = params

    if (frames_name && typeof frames_name === 'string') {
        if (frames_name.toLowerCase().indexOf('spectacles gen') !== -1 || frames_name.toLowerCase().indexOf('spectacles avalon') !== -1 || frames_name.toLowerCase().indexOf('spectacles matador') !== -1) {
            frames_name = 'spectacles_gen'
        } else if (frames_name.toLowerCase().indexOf('spectacles') !== -1) {
            frames_name = 'spectacles'
        } else if (frames_name.toLowerCase().indexOf('personal') !== -1) {
            frames_name = 'ppe'
        } else if (store_code.indexOf('goodr') !== -1) {
            frames_name = store_code
        }
        const stepListObject = {
            'optical': getOpticalStepList(params),
            'night': getNightStepList(params),
            'sun': getSunStepList(params),
            'hyperx': getHyperXStepList(params),
            'hobie': getLeSpecsStepList(params),
            'le-specs': getLeSpecsStepList(params),
            'bose': getBoseStepList(params),
            'contacts': getContactsStepList(params),
            'spectacles': getSpectaclesStepList(params),
            'spectacles_gen': getSpectaclesGenStepList(params),
            'hobie_sunglasses': getHobieSunglassesStepList(params),
            'hobie_optical': getHobieOpticalStepList(params),
            'clipon': getHobieClipOnStepList(params),
            'rheos': getRheosStepList(params),
            'gamer_advantage': getGamerAdvantageStepList(params),
            'ppe': getPPEStepList(params),
            'kiaura': getKiauraStepList(params),
            'goodr': getGoodrStepList(params),
            'ae': getAeStepList(params),
            'wh': getWhStepList(params),
        }
        if (stepListObject[frames_name]) {
            return stepListObject[frames_name]
        }
    }

    const {DEFAULT_LAST_STEP} = getHelper(params)

    const lens_type = {
        'id': LENS_TYPE_ID,
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['computer_lens'],
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensType.computerLens,
                defaultStepOptions.lensType.blueLightLens,
                defaultStepOptions.lensType.sunglassesLens,
                defaultStepOptions.lensType.transitionLens,
            ]
        }
    }

    const lens_options = {
        'id': LENS_OPTIONS_CODE,
        'label': 'Select Lens Options',
        'summary_label': 'Lens Option',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['sunglass_lens', 'transition_lens'],
        'always_visible': true,
        'component': {
            'name': 'selector-options-lens-option',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensOptions.cr39LensOption,
                defaultStepOptions.lensOptions.polyLensOption,
                defaultStepOptions.lensOptions.trivexLensOptions,
                defaultStepOptions.lensOptions.hi167LensOption,
                defaultStepOptions.lensOptions.hi174LensOption,
                defaultStepOptions.lensOptions.glassLensOption
            ]
        }
    }

    const single_vision_distance = Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP})
    const single_vision_distance_premium = Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP})
    const single_vision_reading = Object.assign({'id': 'single_vision_reading'}, {...DEFAULT_LAST_STEP})
    const single_vision_reading_premium = Object.assign({'id': 'single_vision_reading_premium'}, {...DEFAULT_LAST_STEP})
    const progressive_wide = Object.assign({'id': 'progressive_wide'}, {...DEFAULT_LAST_STEP})
    const progressive_premium = Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP})
    const progressive_standard = Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP})
    const bi_focal = Object.assign({'id': 'bi_focal'}, {...DEFAULT_LAST_STEP})

    const step_list = [
        defaultStepOptions.notesOption,
        defaultStepOptions.upgradesOption,
        defaultStepOptions.warrantyOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.chooseLens.prescriptionType,
                    defaultStepOptions.chooseLens.readingType,
                    defaultStepOptions.chooseLens.nonPrescriptionType
                ]
            }
        },
        {
            'id': 'nonprescription',
            'label': 'Choose Non-Prescription',
            'summary_label': 'Non-Prescription',
            'value_renderer': 'renderer-options',
            'default_value': 'sunglasses',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.nonPrescriptionTypes.sunglassesNonPrescription,
                    defaultStepOptions.nonPrescriptionTypes.computerNonPrescription,
                    defaultStepOptions.nonPrescriptionTypes.planoNonPrescription,
                    defaultStepOptions.nonPrescriptionTypes.transitionNonPrescription
                ]
            }
        },
        defaultStepOptions.lensPower,
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription,
                    ...defaultStepOptions.prescriptionTypes.bifocalPrescription
                ]
            }
        },
        single_vision_distance,
        single_vision_distance_premium,
        single_vision_reading,
        single_vision_reading_premium,
        progressive_standard,
        progressive_wide,
        progressive_premium,
        bi_focal,
        lens_type,
        lens_options,
        framesTypeStep
    ]
    return step_list
}
