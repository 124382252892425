import Vue from 'vue'
import axios from 'axios'
import _cloneDeep from 'lodash/cloneDeep'
import _merge from 'lodash/merge'
import _extend from 'lodash/extend'
import _map from 'lodash/map'
import _keys from 'lodash/keys'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _isEqual from 'lodash/isEqual'
import {required, numeric, minLength, maxLength, email} from 'vuelidate/lib/validators'
import {
    encodeObjectToURI,
    jsonToFormData,
    setComputedData
} from '../../../../app/utils/buildData'
import * as abstract from '../../../../storage/abstract'

export const EVENT_SET_CUSTOMER_EXISTS = 'set-customer-exists'
export const ADDRESS_TEMPLATE_DATA = [
    {
        name: 'firstname',
        dataRecurly: 'first_name',
        label: 'First Name',
        required: true,
        type: 'text'
    },
    {
        name: 'lastname',
        dataRecurly: 'last_name',
        label: 'Last Name',
        required: true,
        type: 'text'
    },
    {
        name: 'company',
        dataRecurly: 'company',
        label: 'Company',
        required: false,
        type: 'text'
    },
    {
        name: 'street',
        label: 'Street Address',
        type: 'text',
        required: true,
        options: [
            {
                type: 'text',
                dataRecurly: 'address1',
                required: true
            },
            {
                type: 'text',
                dataRecurly: 'address2',
                required: false
            }
        ]
    },
    {
        name: 'city',
        dataRecurly: 'city',
        label: 'City',
        required: true,
        type: 'text'
    },
    {
        name: 'country_id',
        dataRecurly: 'country',
        label: 'Country',
        required: true,
        type: 'select',
        options: []
    },
    {
        name: 'region_id',
        label: 'State/Province',
        required: true,
        type: 'select',
        options: [],
        textValue: {
            name: 'region',
            dataRecurly: 'state',
            type: 'hidden'
        }
    },
    {
        name: 'postcode',
        dataRecurly: 'postal_code',
        label: 'Zip/Postal Code',
        required: true,
        type: 'text'
    },
    {
        name: 'telephone',
        dataRecurly: 'telephone',
        label: 'Telephone',
        required: false,
        type: 'text'
    }
]
export const ADDRESS_VALIDATION_DATA = {
    email: {
        required,
        email
    },
    telephone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(18)
    },
    firstname: {
        required,
        minLength: minLength(3)
    },
    lastname: {
        required,
        minLength: minLength(3)
    },
    street: {
        0: {
            required
        }
    },
    city: {
        required
    },
    postcode: {
        required
    },
    region_id: {
        required
    },
    country_id: {
        required
    }
}
export const DEFAULT_BILLING_ADDRESS_FIELDS = {
    entity_id: '',
    city: '',
    country_id: 'US',
    firstname: '',
    lastname: '',
    postcode: '',
    region: '',
    region_id: '',
    street: ['', ''],
    telephone: '',
    company: '',
    save_in_address_book: 1,
    use_for_shipping: 1
}
export const DEFAULT_CUSTOMER_DATA = {
    invalid: false,
    validate: false,
    billing_address: {},
    current_billing_address: {
        invalid: false,
        ...DEFAULT_BILLING_ADDRESS_FIELDS
    },
    customerExists: false,
    data: {
        ...DEFAULT_BILLING_ADDRESS_FIELDS,
        default_billing: '',
        email: '',
        entity_id: '',
        groups_id: ''
    },
    email: '',
    group_id: 1
}
export const DEFAULT_MEMBER_SHIP_DATA = {
    created_at: '',
    customer_firstname: '',
    customer_lastname: '',
    ended_at: '',
    diff_date: '',
    subscription_state: '',
    is_annual_paid: '',
    lplus_membership_type: '',
    name: '',
    sku: '',
    redeemable_products: '',
    formated_billing_address: '',
    order_increment_id: '',
    payments: {},
    contact_lens: {
        left: {},
        right: {}
    },
    item_id: '',
    business: {
        company_id: '',
        company_name: '',
        employee_plan_sku: ''
    }
}
export const RECENT_ORDERS_DATA = {
    contacts_left: {
        label: 'Contact SKU'
    },
    contacts_right: {
        label: 'Contact SKU'
    },
    exam: {
        label: 'Exam'
    },
    frames: {
        label: 'Frame or Lens Replacement Benefit'
    },
    glasses_single_vision: {
        label: 'Single Vision Prescription'
    },
    voucher: {
        label: 'Voucher'
    }
}
export const MEMBERSHIP_TYPES = {
    0: 'Customer',
    1: 'Business'
}
export const MEMBERSHIP_STATUS = {
    0: 'Active',
    1: 'Paid',
    2: 'Trial'
}

export const FREE_TRIAL_STATUS = 2

export const state = () => ({
    ...abstract.state(),
    accountFormData: {
        email: '',
        group_id: '',
        groups_id: {},
        customers: {},
        countries: []
    },
    memberShipData: {
        canEdit: false,
        edited: false,
        currentMemberShipId: '',
        currentMemberShipIncrementId: '',
        data: {},
        formData: {
            account_type: 'checking',
            customer_id: '',
            form_key: '',
            coupon_code: '',
            isValidCouponCode: '',
            update_items: 0,
            item: {},
            payment: {
                method: '',
                recurly_token: '',
                cc_type: '',
                cc_save: 1
            },
            order: {
                account: {
                    group_id: 1,
                    email: ''
                },
                billing_address: {
                    ...DEFAULT_BILLING_ADDRESS_FIELDS,
                    customer_address_id: ''
                },
                store_id: 1
            },
            store_id: 1,
            reset_shipping: 1
        }
    },
    salesQuote: {},
    paymentData: {
        recurlyPublicKey: '',
        payment_method: '',
        payment_methods: {}
    },

    customer: _cloneDeep(DEFAULT_CUSTOMER_DATA),
    addressTemplateData: _cloneDeep(ADDRESS_TEMPLATE_DATA),
    redeemsHistory: {},
    api: {
        urls: {
            saveUrl: '',
            accountFormDataUrl: '/plus_membership/getAccountFormData',
            customerDataUrl: '/plus_membership/getCustomerData',
            memberShipDataUrl: '/plus_membership/getMemberShipData',
            memberRedeemsHistoryUrl: '/plus_membership/getRedeemsHistory',
            updateReceiptRewardsUrl: '',
            paymentDataUrl: '',
            updateQuoteUrl: ''
        }
    }
})

export const getters = {
    updateReceiptRewardsUrl: state => state.api.urls.updateReceiptRewardsUrl,
    currentBillingAddress: state => state.customer.current_billing_address,
    getCustomerData: state => email => {
        return state.accountFormData.customers.hasOwnProperty(email) ? state.accountFormData.customers[email] : {email, customerExists: false, billing_address: {}, data: {}, load: false}
    },
    currentMemberShipId: state => state.memberShipData.currentMemberShipId,
    currentMemberShipIncrementId: state => state.memberShipData.currentMemberShipIncrementId,
    canEditMemberShip: state => state.memberShipData.canEdit,
    memberShipEdited: state => state.memberShipData.edited,
    currentMemberShipData: (state, getters) => _merge({}, DEFAULT_MEMBER_SHIP_DATA, state.memberShipData.data[getters.currentMemberShipId] || {}),
    currentPlanData: (state, getters, rootState, rootGetters) => _find((rootGetters['lensablPlus/plans'] || {}), plan => plan.sku === (getters.currentMemberShipData.sku || '').replace(/-/g, '_')),
    paymentMethods: state => state.paymentData.payment_methods,
    recurlyPublicKey: state => state.paymentData.recurlyPublicKey,
    getRedeemsHistory: state => state.redeemsHistory,
    redeemItems: (state, getters) => _merge({}, RECENT_ORDERS_DATA, getters.currentMemberShipData['redeemable_products'] || {}),
    redeemedItems: (state, getters) => _filter(getters.redeemItems, item => item.hasOwnProperty('redeemed_order_id') && item.redeemed_order_id),

    addressTemplateData: (state, getters) => {
        const data = _cloneDeep(ADDRESS_TEMPLATE_DATA)
        const countries = state.accountFormData.countries
        _map(data, value => {
            if (value.name === 'country_id') {
                value.options = countries
            }
            if (value.name === 'region_id') {
                const country = _find(countries, c => getters.currentBillingAddress.country_id === c.value) || {}
                value.options = country.regions || []
            }
        })
        return data
    },
    getRedeemItemData: (state, getters) => type => getters.redeemItems[type] || {},
    getCustomerBillingAddresses: (state, getters) => email => {
        let addresses = {}
        const customerData = getters.getCustomerData(email)
        if (customerData.customerExists) {
            addresses = customerData.billing_address
        }
        return addresses
    }
}

export const actions = {
    ...abstract.actions,
    fetchAccountFormData: ({state, commit}) => {
        return axios.get(state.api.urls.accountFormDataUrl).then(({data, status}) => {
            if (status === 200) {
                commit('ACCOUNT_FORM_DATA', data)
            }
        })
    },
    fetchCustomerData: ({state, commit, getters, rootGetters}, email) => {
        const data = {
            isAjax: 1,
            form_key: rootGetters['storeView/form_key'],
            email: email
        }

        return getters.getCustomerData(email).load || axios.post(`${state.api.urls.customerDataUrl}?${encodeObjectToURI(data)}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                data['load'] = true
                commit('CUSTOMER_DATA', data)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    fetchRedeemsHistoryData: ({state, commit, getters, rootGetters}) => {
        const urlData = {
            isAjax: 1,
            form_key: rootGetters['storeView/form_key'],
            membership_id: getters.currentMemberShipId
        }

        return axios.post(`${state.api.urls.memberRedeemsHistoryUrl}?${encodeObjectToURI(urlData)}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                commit('SET_REDEEMS_HISTORY', data)
            }
        }).catch(error => {
            console.error(error)
        })
    },
    fetchMemberShipData: ({state, commit, getters, rootGetters}, id) => {
        const urlData = {
            isAjax: 1,
            form_key: rootGetters['storeView/form_key'],
            membership_id: id || getters.currentMemberShipId
        }

        return axios.post(`${state.api.urls.memberShipDataUrl}?${encodeObjectToURI(urlData)}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                commit('ACCOUNT_FORM_DATA', data['accountFormData'] || {})
                commit('UPDATE_PAYMENT_DATA', data['paymentData'] || {})
                commit('MEMBER_SHIP_DATA', {
                    membership_id: urlData.membership_id,
                    data: data.info
                })
                commit('lensablPlus/PLANS', data['plans_list'] || {}, { root: true })
                commit('lensablPlus/PRODUCTS', data['products'] || {}, { root: true })
                commit('UPDATE_PAYMENT_DATA', data['paymentData'] || {})

                commit('UPDATE_SALES_QUOTE', {
                    ...(data['sales_quote'] || {}),
                    plan_item: data['plan_item'] || {}
                })
                commit('lensablplus_customer/BUSINESS_MEMBER', data['is_business'], { root: true })
            }
        }).catch(error => {
            console.error(error)
        })
    },
    salesQuoteToData: ({state, commit}) => {
        const salesQuote = state.salesQuote
        commit('CUSTOMER', {
            email: salesQuote.customer_email,
            group_id: salesQuote.customer_group_id
        })
    },
    currentBillingAddressUpdate: ({state, commit, getters, rootGetters}, data = {}) => {
        return _map(state.customer.current_billing_address, (value, key) => {
            if (data.hasOwnProperty(key) && !_isEqual(value, data[key])) {
                Vue.set(state.customer.current_billing_address, key, data[key])
            }
        })
    },
    updateMemberShipFormData: ({commit}, data = {}) => commit('MEMBER_SHIP_FORM_DATA', data),
    updateQuote: ({state, commit, getters, dispatch, rootGetters}, quoteData = {}) => {
        const data = _extend(
            {
                ...state.memberShipData.formData
            },
            {
                form_key: rootGetters['storeView/form_key'],
                json: 1
            },
            quoteData
        )

        commit('MEMBER_SHIP_FORM_DATA', {
            form_key: rootGetters['storeView/form_key']
        })

        return axios.post(`${state.api.urls.updateQuoteUrl}?isAjax=1`, jsonToFormData(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                data['load'] = true
                commit('MEMBER_SHIP_FORM_DATA', {
                    ...quoteData,
                    isValidCouponCode: data['isValidCouponCode']
                })
                commit('UPDATE_SALES_QUOTE', {
                    ...(data['sales_quote'] || {}),
                    plan_item: data['plan_item'] || {}
                })
            }
        }).catch(error => {
            console.error(error)
        })
    },
    fetchPaymentData: ({state, commit, rootGetters}) => {
        if (state.api.urls.paymentDataUrl) {
            const data = {
                isAjax: 1,
                form_key: rootGetters['storeView/form_key']
            }

            return axios.post(`${state.api.urls.paymentDataUrl}?${encodeObjectToURI(data)}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(({data, status}) => {
                if (status === 200) {
                    commit('UPDATE_PAYMENT_DATA', data)
                }
            }).catch(error => {
                console.error(error)
            })
        }
    },
    setPaymentMethod: ({state, commit, getters, rootGetters}) => {
        if (state.api.urls.paymentDataUrl) {
            const data = {
                isAjax: 1,
                form_key: rootGetters['storeView/form_key'],
                payment_method: state.paymentData.payment_method
            }

            return axios.post(`${state.api.urls.paymentDataUrl}?${encodeObjectToURI(data)}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(({data, status}) => {
                if (status === 200) {
                    console.log('setPaymentMethod', data)
                }
            }).catch(error => {
                console.error(error)
            })
        }
    },
    saveMemberShipData: ({state, commit, dispatch}, redirect = false) => {
        commit('MEMBER_SHIP_FORM_DATA', {
            payment: {
                cc_type: window.cc_type
            }
        })
        return dispatch('submitCheckoutForm', jsonToFormData(state.memberShipData.formData)).then(({redirect_url}) => {
            if (redirect && redirect_url) {
                window.location.href = redirect_url
            }
        })
    },
    submitCheckoutForm: ({state}, formData) => {
        return axios.post(`${state.api.urls.saveUrl}?isAjax=1`, formData || jsonToFormData(state.memberShipData.formData), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(({data, status}) => {
            if (status === 200) {
                return data
            }
        }).catch(error => {
            console.error(error)
        })
    }
}

export const mutations = {
    ...abstract.mutations,
    ACCOUNT_FORM_DATA: (state, data = {}) => {
        _map(data, (value, key) => {
            if (key === 'customers') {
                _map(value, _data => {
                    _data['load'] = true
                    Vue.set(state.accountFormData.customers, _data.email, _data)
                })
            } else if (!_isEqual(value, state.accountFormData[key])) {
                Vue.set(state.accountFormData, key, value)
            }
        })
    },
    CUSTOMER_DATA: (state, data = {}) => Vue.set(state.accountFormData.customers, data.email, data),
    CUSTOMER: (state, data = {}) => {
        _map(data, (value, key) => {
            if (!_isEqual(value, state.customer[key])) {
                Vue.set(state.customer, key, value)
            }
        })
    },
    MEMBER_SHIP_EDITED: (state, edited = false) => state.memberShipData.edited = edited,
    SET_REDEEMS_HISTORY: (state, payload = {}) => state.redeemsHistory = payload,
    MEMBER_SHIP_DATA: (state, {membership_id, data = {}}) => {
        if (state.memberShipData.data.hasOwnProperty(membership_id)) {
            _map(data, (value, key) => {
                if (!_isEqual(value, state.memberShipData.data[membership_id][key])) {
                    Vue.set(state.memberShipData.data[membership_id], key, value)
                }
            })
        } else {
            Vue.set(state.memberShipData.data, membership_id, data)
        }
    },
    MEMBER_SHIP_FORM_DATA: (state, data = {}) => {
        if (data.hasOwnProperty('item')) {
            const itemIds = _keys(state.memberShipData.formData.item) || []
            if ((itemIds.length > 1) || (itemIds.shift() !== _keys(data.item).shift())) {
                state.memberShipData.formData.item = data.item
            }
        }
        _merge(state.memberShipData.formData, data)
    },
    UPDATE_SALES_QUOTE: (state, data = {}) => {
        _map(data, (value, key) => {
            if (!_isEqual(value, state.salesQuote[key])) {
                Vue.set(state.salesQuote, key, value)
            }
        })
    },
    UPDATE_PAYMENT_DATA: (state, data = {}) => {
        _map(data, (value, key) => {
            if (!_isEqual(value, state.paymentData[key])) {
                Vue.set(state.paymentData, key, value)
            }
        })
    },
    UPDATE_REDEEMABLE_PRODUCTS_DATA: (state, data = {}) => {
        const currentMemberShipData = state.memberShipData.data[state.memberShipData.currentMemberShipId] || {}
        if (currentMemberShipData.hasOwnProperty('redeemable_products')) {
            const redeemableProducts = currentMemberShipData['redeemable_products'] || {}
            _map(data, (value, key) => {
                if (redeemableProducts.hasOwnProperty(key) && !_isEqual(value, redeemableProducts[key])) {
                    setComputedData(redeemableProducts[key], value)
                }
            })
        }
    }
}

export default store => ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
