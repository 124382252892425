export default function formatPrice (price) {
    const formattedPrice = parseFloat(price || 0).toFixed(2)
    const ceilPrice = Math.ceil(formattedPrice)
    const pricesDiff = formattedPrice - ceilPrice

    if (pricesDiff) {
        return formattedPrice
    } else {
        return ceilPrice
    }
}
