import _size from 'lodash/size'

export default () => ({
    namespaced: true,
    state: () => ({
        loaded: false,
        error: false,
        base_url: ''
    }),
    getters: {
        getLoaded: state => {
            return state.loaded
        },
        getError: state => state.error,
        getBaseUrl: (state, getters, rootstate, rootGetters) => {
            if (!_size(state.base_url)) {
                state.base_url = rootGetters['storeView/base_url']
            }
            return state.base_url
        }
    },
    mutations: {
        SET_LOADED: (state, payload) => state.loaded = payload,
        SET_ERROR: (state, payload) => state.error = payload
    },
    actions: {

    }
})
