<template>
    <div>
        <RadioLabel :active="isActive" :label="title" :value="index" @click="openFittingBox" />

        <div v-if="isActive && (fittingbox_status === 'taken' || fittingbox_status === 'retaken')" class="fittingbox-button-container">
            <br />We have your previous photo which we will process soon.
            <br />You can retake them if you like, and we will use the newest set.
            <div>
                <span class="button button--copy-border-sm fittingbox-start-button" @click="openFittingBox">Retake Photo</span>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import SalesOrderViewPrescriptionRenderAbstract from './abstract'

export default {
    name: 'SalesOrderViewPrescriptionRenderEnter',
    mixins: [SalesOrderViewPrescriptionRenderAbstract],
    props: {
        title: {
            type: [Boolean, String],
            default: 'Take a Photo'
        },
        is_pd: {
            type: [Boolean],
            default: false
        }
    },
    computed: {
        ...mapGetters('values', ['values']),
        prescription () {
            return this.values.prescriptionValues[this.prescriptionData.rx_type]
        },
        fittingbox_status () {
            return this.item.prescriptions[this.prescriptionsType].fittingbox_status
        }
    },
    methods: {
        openFittingBox () {
            this.$emit('checked', this.index)
            this.$emit('open-fitting-box', this.item.item_id)
        }
    }
}
</script>
