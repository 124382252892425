var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isStepCurrent
    ? _c(
        "div",
        [
          _vm.showSavedPrescriptions
            ? _c("SavedPrescriptions", {
                staticClass: "saved-prescriptions-container",
                attrs: { id: _vm.id }
              })
            : _c("div", { staticClass: "step-description" }, [
                _vm._v("\n        Select Magnification.\n    ")
              ]),
          _vm._v(" "),
          _c(_vm.options.params_component, {
            tag: "component",
            staticClass: "enter-prescription-block",
            class: [_vm.options.params_component + "-component"],
            attrs: { id: _vm.id, data: _vm.options }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }