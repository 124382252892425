<template>
    <div class="plus-account_wrappper">
        <div class="plus-wrapper">
            <PageLoader v-if="loading" :load="loading"/>
            <div v-else class="main-card-holder">
                <template v-for="(redeem, index) in redeems">
                    <Redeem
                        :redeem="redeem"
                        :key="index"
                        :discount="getDiscount(redeem.key)"
                        :btn-text="redeem.btnText"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import membershipStore from "./storage";
import PageLoader from "../base/PageLoader.vue";
import Redeem from "./Redeem.vue";
import {REDEEMS} from "./storage/constants";
import {mapGetters} from "vuex";

export default {
    name: 'Account',
    components: {
        Redeem,
        PageLoader
    },
    data: () => ({
        loading: false,
        redeems: REDEEMS
    }),
    beforeCreate() {
        if (!this.$store._modulesNamespaceMap['membership/']) {
            this.$store.registerModule('membership', membershipStore(this.$store))
        }
    },
    created: async function () {
        this.loading = true
        await this.$store.dispatch('membershipProgram/membershipDiscountData')
        this.loading = false
    },
    computed: {
        ...mapGetters('membershipProgram', ['discountData']),
    },
    methods: {
        getDiscount(type) {
            if (!this.discountData[type]) return ''
            const discountType = `type_${type}`
            if (this.discountData[discountType] === 'fixed') {
                return '$' + this.discountData[type]
            } else {
                return this.discountData[type] + '%'
            }
        }
    }
}
</script>
