import getShipstationService from '../../../../../app/utils/shipstation'
import _size from 'lodash/size'

export default {
    namespaced: true,
    state: () => ({
        request: null,
        shipstationService: {}
    }),
    getters: {
        request: (state, getters, rootState, rootGetters) => {
            if (!state.request) {
                state.request = rootGetters.rootState.request
            }
            return state.request
        },
        shipstationService: (state, getters, rootState, rootGetters) => {
            if (!_size(state.shipstationService)) {
                state.shipstationService = getShipstationService({
                    order_id: rootGetters.rootState.order_id,
                    base_url: rootGetters['storeView/base_url'],
                    request: getters.request
                })
            }
            return state.shipstationService
        }
    }
}
