<template>
    <div>
        <span>Free Standard Shipping</span>
        <HelpBubble :placement="placement" :data="component_help" :offset="offset"/>
    </div>
</template>

<script>
import HelpBubble from '../help_bubble/HelpBubble.vue'

export default {
    name: 'Shipping',
    components: {
        HelpBubble
    },
    data: () => {
        return {
            component_help: {
                name: 'help-bubble',
                params: {
                    'text': 'Standard: 1 - 5 business days each way.<br>Priority: 1 - 3 business days each way.<br>Overnight: 1 business day each way.<br><span style="font-size:11px">**Lensabl is based in Los Angeles, CA</span>'
                }
            },
            placement: 'top',
            offset: '-40'
        }
    }
}
</script>
