<template>
    <div v-if="totalPrice" class="price-info">
        <template v-if="false">
            <div class="plan-price-total">
                <span>Total Vision Plan Cost (Per Year): {{ currencySymbol }}{{ formatPrice(totalPrice('totalPrice')) }}</span>
                <p class="price-label">Save {{ discount }}% if paid in full (annual) today</p>
            </div>
            <Toggle
                :price="option"
                @update="updatePrice"
            />
        </template>
        <div class="price">
            <div class="total-value value">
                <span class="total-value-currency">{{ currencySymbol }}</span>
                <span class="total-value-amount-dollars" v-text="formatPrice(dollars)"></span>
                <span class="total-value-amount-cents" v-text="`.${cents}`"></span>
            </div>
            <div class="label">
                <strong>/per {{ label }}</strong>
            </div>
        </div>
        <div class="plan-price-total">
            <p class="price-label">
                Starting at {{ affirmPrice }}/mo with
                <span class="__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed">Affirm</span>.
                <a href="javascript:void(0)" @click="affirmModalShow()">Learn more</a>
            </p>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import Toggle from './Toggle.vue'
import _formatPrice from '../../../app/utils/formatPrice'

export default {
    name: 'TotalsPrice',
    components: {
        Toggle
    },
    data: () => {
        return {
            option: 'totalPrice'
        }
    },
    computed: {
        ...mapState('lensablPlus', ['totalAnnualDiscount']),
        ...mapGetters('lensablPlus', ['checkoutData']),
        discount () {
            return this.totalAnnualDiscount * 100
        },
        currencySymbol () {
            return '$'
        },
        dollars () {
            return Math.floor(this.totalPrice(this.option))
        },
        cents () {
            const price = parseFloat(this.totalPrice(this.option)).toFixed(2)
            const priceData = price.split('.')
            return priceData[1]
        },
        label () {
            return 'year'
        },
        affirmPrice () {
            const affirmPrice = this.totalPrice(this.option) / 11
            return '$' + _formatPrice(Math.ceil(affirmPrice))
        }
    },
    methods: {
        totalPrice (price) {
            return parseFloat(this.checkoutData[price]).toFixed(2) || 0
        },
        updatePrice (value) {
            this.option = value
        },
        formatPrice (price) {
            return price > 999 ? price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : price
        },
        affirmModalShow () {
            if (window.affirm) {
                const ui = window.affirm['ui'] || {}
                if (ui.openModal) {
                    ui.openModal()
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .price-label {
        color: #8d8d8d;
        margin: 0;
        text-align: center;
        font-weight: normal;
        font-style: italic;
    }
    @media screen and (max-width: 499px) {
        .label span {
            display: none!important;
        }
    }
</style>
