<template>
    <div class="vision-seo-container">
        <section class="vision-seo-section vision-faq-section">
            <div class="vision-seo-row vision-faq-row">
                <div class="vision-seo-text vision-faq-text">
                    <h2>Frequently Asked Questions</h2>
                    <p>Patients in various regions across the U.S. can renew their eye prescription online. Here are a few frequently asked questions to help you understand if an online vision test is right for you:</p>
                </div>
                <FaqAccordion />
            </div>
        </section>
        <section class="vision-seo-section vision-requirements-section">
            <div class="vision-seo-row vision-requirements-row">
                <div class="vision-seo-text vision-requirements-text">
                    <h2>What You Need for the Test</h2>
                    <p>Here's what you'll need to take your test</p>
                </div>
                <div class="vision-requirements-images">
                    <div class="vision-requirements-image">
                        <img src="/media/wysiwyg/vision-seo-rx.svg" alt="Rx icon" />
                        <span>Your current prescription</span>
                    </div>
                    <div class="vision-requirements-image">
                        <img src="/media/wysiwyg/vision-seo-computer.svg" alt="Rx icon" />
                        <span>A computer or phone</span>
                    </div>
                    <div class="vision-requirements-image">
                        <img src="/media/wysiwyg/vision-seo-card.svg" alt="Rx icon" />
                        <span>Your driver's license or credit card</span>
                    </div>
                </div>
            </div>
        </section>
        <section class="our-labs__call-to-action vision-eyetest-section">
            <div class="custom-callout parallax-trigger">
                <div class="struct-parallax-bg">
                    <div class="custom-callout__content">
                        <div class="custom-callout__layout">
                            <h2>Start Your Online Eye Test</h2>
                            <p class="vision-eyetest-subtext">Lensabl is the one-stop shop for all things optical, online. Use our site to renew or update your prescription in 24 hours or less.<span>For more information, <a href="/contact">contact us online</a> today!</span></p>
                            <a href="/online-eye-exam-qualification">
                                <div class="custom-callout__button">
                                    <div class="button button--copy-solid">
                                        <div class="button__container">Renew My Prescription</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import FaqAccordion from './FaqAccordion.vue'
export default {
    name: 'OnlineVisionSeo',
    components: {
        FaqAccordion
    }
}
</script>
