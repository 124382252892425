<template>
    <div class="affirm-wrapper">
        <!--        <div class="affirm-header_container">-->
        <!--            <div class="nav__box-shadow"></div>-->
        <!--            <div class="affirm-header_row">-->
        <!--                <div class="affirm-header_row-cancel-btn">-->
        <!--                    <span>X</span>Cancel-->
        <!--                </div>-->
        <!--                <div class="affirm-header_row-logo">-->
        <!--                    <img src="/skin/frontend/lensabl/default/images/affirm-logo-updated.svg" alt="Affirm Logo"/>-->
        <!--                </div>-->
        <!--                <div class="affirm-header_row-help-btn">-->
        <!--                    ?-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="affirm-body_container">
            <div class="affirm-body_row">
                <div class="affirm-body_row-loader">
                    <Loader />
                </div>
                <div class="affirm-body_row-text">
                    We're processing your information
                </div>
                <div class="affirm-body_row-subtext">
                    This may take up to 15 seconds.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import lensablAffirm from './../../storage/affirm'
import Loader from '../../../loaders/Loader.vue'

export default {
    name: 'AffirmConfirm',
    components: {
        Loader
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablAffirm/']) {
            this.$store.registerModule('lensablAffirm', lensablAffirm(this.$store))
        }
    },
    created () {
        this.$store.dispatch('lensablAffirm/createOrder')
    }
}
</script>
