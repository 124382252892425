var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-category-view categorypath-ae-eyewear" },
    [
      _c("div", { staticClass: "whframes-banner frames-banner" }, [
        _c("div", { staticClass: "sb-inner-wrapper" }, [
          _c("div", { staticClass: "sb-inline" }, [
            _c("div", { staticClass: "sb-inner", class: [_vm.categoryClass] })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "category-products frames-products" },
        [
          _vm.categoryLoader
            ? _c("PageLoader", { attrs: { load: _vm.categoryLoader } })
            : [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "products wrapper grid products-grid" },
                    [
                      _c(
                        "ul",
                        { staticClass: "products list items product-items" },
                        _vm._l(_vm.getProducts, function(product) {
                          return _c(
                            "li",
                            {
                              key: product.url,
                              staticClass: "item product product-item"
                            },
                            [
                              _c("CatalogCategoryListItem", {
                                attrs: {
                                  product: product,
                                  showSwatcher: true,
                                  showProductPrice: true
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ])
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }