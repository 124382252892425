<template>
    <WhyLensablSlider v-if="statusApi" />
</template>

<script>
import App from '../App.vue'
import WhyLensablSlider from '../components/landing/WhyLensablSlider.vue'

export default {
    name: 'RouterWhyLensablSlider',
    components: {
        WhyLensablSlider
    },
    extends: App
}
</script>
