<template>
    <RayBanMetaCatalogProductBlockView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import RayBanMetaCatalogProductBlockView from '../../../components/catalog/product/RayBanMeta/CatalogProductBlockView.vue'

export default {
    name: 'RouterRayBanMetaCatalogProductBlockView',
    components: {
        RayBanMetaCatalogProductBlockView
    },
    extends: App
}
</script>
