var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-modaltor",
    {
      staticClass: "redeem-modal",
      class: { wrapper: _vm.isModalOpen },
      attrs: {
        visible: _vm.isModalOpen,
        "default-width": "100%",
        "bg-overlay": "",
        "bg-panel": "",
        "close-scroll": false
      },
      on: { hide: _vm.hideModal }
    },
    [
      _c("div", { staticClass: "redeem-container exam-container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.uploaded,
                expression: "!uploaded"
              }
            ]
          },
          [
            _c("h2", { domProps: { textContent: _vm._s(_vm.title) } }),
            _vm._v(" "),
            _c("h3", { domProps: { textContent: _vm._s(_vm.subtitle) } }),
            _vm._v(" "),
            _c("div", { staticClass: "border-container" }, [
              _vm.instruction
                ? _c("span", {
                    staticClass: "subtitle",
                    domProps: { textContent: _vm._s(_vm.instruction) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "requirements-container eye-exam-container",
                  class: { "has-file": _vm.hasFile }
                },
                [
                  _c("div", { staticClass: "upload-prescription" }, [
                    _c("div", { staticClass: "upload-component" }, [
                      !_vm.isUploading
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.hasNoFile,
                                  expression: "hasNoFile"
                                }
                              ],
                              staticClass: "file-input-box",
                              class: {
                                "invalid-form": _vm.showInvalidFormBorder
                              }
                            },
                            [
                              _c("input", {
                                staticClass: "file-input",
                                attrs: {
                                  type: "text",
                                  disabled: "disabled",
                                  placeholder: "No file chosen"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "action primary file-input-button",
                                  attrs: { type: "button", title: "Continue" }
                                },
                                [
                                  _c("span", [_vm._v("Browse...")]),
                                  _vm._v(" "),
                                  _c("input", {
                                    attrs: {
                                      type: "file",
                                      name: "prescription",
                                      accept: "image/*,application/pdf"
                                    },
                                    on: { change: _vm.previewFiles }
                                  })
                                ]
                              )
                            ]
                          )
                        : _c("div", [
                            _c("div", { staticClass: "uploading-progress" }, [
                              _vm._v("Uploading...")
                            ])
                          ]),
                      _vm._v(" "),
                      _vm.hasFile
                        ? _c("div", { staticClass: "uploaded-file" }, [
                            _c(
                              "div",
                              {
                                staticClass: "option-checkbox-container active"
                              },
                              [_c("div", { staticClass: "option-checkbox" })]
                            ),
                            _vm._v(" "),
                            _c("span", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: _vm.fileData.fileName,
                                  expression: "fileData.fileName"
                                }
                              ]
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "remove-link",
                                attrs: { href: "javascript:void(0)" },
                                on: { click: _vm.removeFile }
                              },
                              [_vm._v("Remove")]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showError
                        ? _c("div", { staticClass: "error-hint" }, [
                            _c("span", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: _vm.errorHint,
                                  expression: "errorHint"
                                }
                              ]
                            })
                          ])
                        : _vm._e()
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("BaseButton", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasFile,
                  expression: "hasFile"
                }
              ],
              attrs: {
                loader: _vm.loader,
                type: "submit",
                text: "SUBMIT",
                tabindex: "3"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.updateReceiptRewards($event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.uploaded,
                expression: "uploaded"
              }
            ],
            staticClass: "uploaded-success"
          },
          [
            _c("h2", [_vm._v("Thank You 🎉")]),
            _vm._v(" "),
            _c("h5", [
              _c("strong", [
                _vm._v("Your receipt has been submitted successfully.")
              ])
            ]),
            _vm._v(" "),
            _c("h5", [
              _vm._v(
                "Once our customer service team verifies it, you will receive an email explaining how to claim your benefit."
              )
            ]),
            _vm._v(" "),
            _c("BaseButton", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasFile,
                  expression: "hasFile"
                }
              ],
              attrs: {
                loader: _vm.loader,
                type: "submit",
                text: "RETURN TO ACCOUNT",
                tabindex: "3"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.returnTo($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }