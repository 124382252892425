<template>
    <div v-if="getAffirmPrice" class="affirm-shop-frames">
        <div :data-amount="getAffirmPrice" class="affirm-as-low-as" data-page-type="product">
            <span>Starting at</span>
            <span v-dompurify-html="getAffirmPrice" class="affirm-ala-price"></span>/mo with <span class="__affirm-logo">Affirm</span>
            <a href="javascript:void(0)" @click="affirmModalShow()">Learn more</a>
        </div>
        <div class="accrue-section">
            <span>or save up and earn <span class="accrue-percentage-summary">15%</span> in cash rewards with <span><img src="/media/wysiwyg/accrue-logo-black.png" class="accrue-logo-black-img" /></span></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Affirm',
    props: {
        price: {
            type: String,
            default: ''
        }
    },
    computed: {
        getAffirmPrice () {
            const dividedPrice = parseFloat(this.price) / 11
            const affirmPayment = '$' + Math.round(dividedPrice)
            return affirmPayment
        }
    },
    methods: {
        affirmModalShow () {
            if (window.affirm) {
                const ui = window.affirm['ui'] || {}
                if (ui.openModal) {
                    ui.openModal()
                }
            }
        }
    }
}
</script>
