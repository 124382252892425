var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bose-seo-container" }, [
      _c("div", { staticClass: "shopframes-seo-prescription-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-prescription" }, [
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Bose Eyewear")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "The nation's favorite audio equipment manufacturer has hit the sunglass market with a series of Bluetooth-compatible shades that play your favorite songs and podcasts. Buy Bose audio glasses online from Lensabl for great rates, free delivery and a selection of customizable sunglass lenses."
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "shopframes-seo-img" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-designer-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-designer" }, [
          _c("div", { staticClass: "shopframes-seo-designer-img" }),
          _vm._v(" "),
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Sound and Sight Converge")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Bose sunglasses pair great outdoor vision with incredible audio quality. This signature line of audio sunglasses features built-in speakers for open-ear audio, meaning you can enjoy the soundtrack to your life while keeping your ears free. You can fill Bose sunglasses with prescription or non-prescription lenses that fit your lifestyle."
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-sunglass-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-sunglass" }, [
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Streetworthy Frames You Can Trust ")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Bose sunglasses complement crisp audio quality with fashionable frames for all styles. Each of Bose's original frames has a rugged construction with the most durable yet comfortable materials for a pair of sunglasses you can wear for hours on end."
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                "You can choose from Bose's latest looks, like the elegant Soprano or refined Tenor. If you want a classic style, select the Alto. Tempo is a sharp, functional option for the most active wearers. Lensabl has a Bose sunglass frame for everyone's preferred style."
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-designer2-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-designer" }, [
          _c("div", { staticClass: "shopframes-seo-designer-img" }),
          _vm._v(" "),
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Bose Eyeglasses With Prescription Lenses")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You can equip your Bose sunglasses with lenses that meet your prescription needs. Browse the Lensabl inventory to find Bose Bluetooth prescription glasses with single vision, progressive or bifocal lenses. You can "
              ),
              _c("a", { attrs: { href: "/prescription-scanner-app/qr" } }, [
                _c("span", { staticClass: "seo-contact-link" }, [
                  _vm._v("use the Lensabl online prescription reader")
                ])
              ]),
              _vm._v(
                " to narrow your search and find prescription Bose glasses that cater to your optical needs. Shop with Lensabl for Bose glasses with prescription lenses and a solid color, gradient or Transitions® tint of your choice."
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-eyeglass-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-eyeglass" }, [
          _c("div", { staticClass: "shopframes-seo-text seo-eyeglass-text" }, [
            _c("h2", [_vm._v("Order Bose Sunglasses Online From Lensabl")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Lensabl offers great deals and fast, free delivery on any set of Bose sunglasses with custom lenses. Order your new eyewear online today!"
              )
            ])
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: "/bose-eyewear" } }, [
            _c("div", { staticClass: "button button--copy-solid" }, [
              _c("div", { staticClass: "button__container" }, [
                _vm._v("Place your Bose order online today!")
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }