var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product" }, [
    _vm.outOfStock
      ? _c("div", { staticClass: "sold-out-block" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _vm.currentImage
      ? _c(
          "a",
          {
            staticClass: "product photo",
            attrs: { title: _vm.product.name, href: _vm.productUrl }
          },
          [
            _c("img", {
              attrs: { alt: _vm.product.name, src: _vm.currentImage.path }
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "product details" }, [
      _vm.showCategoryName
        ? _c("span", { staticClass: "category-title" }, [
            _vm._v("\n            A&E Frames\n        ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-details" },
        [
          _c("strong", { staticClass: "product name product-item-name" }, [
            _c("a", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.product.name,
                  expression: "product.name"
                }
              ],
              staticClass: "product-item-link",
              attrs: { title: _vm.product.name, href: _vm.productUrl }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "price-container" }, [
            _vm.showProductPrice
              ? _c("span", { staticClass: "product-price" }, [
                  _vm._v(_vm._s(_vm.currentImage.price || _vm.product.price))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.showSwatcher
            ? _c("ColorSwatches", {
                attrs: {
                  colors: _vm.images,
                  "checked-index": _vm.checkedIndex,
                  "option-item-class": "decorate img",
                  "data-swatch-product": _vm.product.name
                },
                on: _vm._d({}, [
                  _vm.EVENT_CHANGE_CURRENT_COLOR,
                  _vm.changeCurrentColor
                ])
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sold-out" }, [
      _c("p", [_vm._v("Sold Out")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }