var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help-bubble-container lplus-help-bubble-container" },
    [
      _c("div", { staticClass: "help-component" }, [
        _c(
          "div",
          {
            staticClass: "help-bubble lplus-help-bubble",
            class: _vm.showModalAd ? "open" : "",
            attrs: { id: "help_bubble_text" }
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "close-button",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closePlusAdModal($event)
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "18",
                      height: "18",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        id: "svg_1",
                        d:
                          "m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z",
                        fill: "rgb(102, 102, 102)",
                        "fill-rule": "evenodd"
                      }
                    })
                  ]
                )
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("p", [
        _vm._v(
          "Lensabl+ plans include an annual supply of contacts, a FREE frame with premium Rx lenses or lens replacement, and a reward for getting an eye exam (or take our online vision test for FREE). Save 15% on all additional contact lens purchases throughout the year. "
        ),
        _c("span", { staticClass: "c-help-bubble-link" }, [
          _c("a", { attrs: { href: "/plus/vision_plan" } }, [
            _vm._v("Customize your plan now")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }