var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statusApi
    ? _c("SalesOrderViewPrescription", {
        attrs: {
          "order-id": _vm.$root.order_id,
          "item-id": _vm.$root.item_id,
          "prescriptions-type": _vm.$root.prescriptions_type
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }