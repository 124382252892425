<template>
    <div class="toggle-container">
        <h5><strong>Choose Payment Frequency</strong></h5>
        <div class="price-toggle">
            <input id="toggle-on" v-model="price" :class="{'not-checked': price === 'totalQuarterlyPrice'}" class="p-toggle toggle-left" name="toggle" value="totalAnnualPrice" type="radio" />
            <label for="toggle-on" class="btn-toggle" @click="update('totalAnnualPrice')">Annual</label>
            <input id="toggle-off" v-model="price" :class="{'not-checked': price === 'totalAnnualPrice'}" class="p-toggle toggle-right" name="toggle" value="totalQuarterlyPrice" type="radio" />
            <label for="toggle-off" class="btn-toggle" @click="update('totalQuarterlyPrice')">Every 3 Months</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Toggle',
    props: {
        price: {
            type: String,
            default: '',
            required: true
        }
    },
    methods: {
        update (val) {
            this.$emit('update', val)
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
