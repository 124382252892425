import axios from 'axios'

const url_basic = '/lensabl_cart'

class SpectaclesService {
    getColors (product_id) {
        return axios.get(url_basic + '/spectacles/getColors/product_id/' + product_id, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    getConfig (product_id) {
        return axios.get(url_basic + '/spectacles/getConfig/product_id/' + product_id, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    getConfigFrames (product_name, color) {
        return axios.get(url_basic + '/spectacles/getConfigFrames/?product_name=' + product_name + '&color=' + color, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}

export default function getSpectaclesService () {
    return new SpectaclesService()
}
