<template>
    <div class="online-optometry__container">
        <div class="hero-image__background success_wink">
            <div class="hero-video-button__row">
                <div class="online-optometry__layout">
                    <div class="online-optometry__copy">
                        <div v-if="QUALIFICATIONS" class="form-action btn-vertical qualify-section">
                            <h1>Congrats, You Qualify!</h1>
                            <p>You are eligible to take our online eye exam. To continue, click <i>get started with your exam</i> below, and after you checkout you can proceed with your online vision test.</p>

                            <p v-if="showPrice" class="hero-video-button__copy-secondary">$25.00 — for a limited time!</p>
                            <button class="button btn-full-inline button--copy-solid-dark" @click="ADD_TO_CART">
                                <span>Get Started with your Exam</span>
                            </button>
                        </div>
                        <div v-if="!QUALIFICATIONS" class="form-action not-qualified">
                            <h1>Unfortunately you do not qualify<br />to take the vision test &#128532;</h1>

                            <p>Based on your responses, it has been determined that we are unable to issue you an Rx virtually, and do recommend you see a doctor in-person for a new Rx.  </p>
                            <p>With that said, if you are in need of new contacts, frames, or lenses, we do have some good news for you! You can save big by shopping at Lensabl.</p>

                            <p class="hero-video-button__copy-secondary">Click below and your discount will be displayed at the top of the page:</p>
                            <button class="button btn-full-inline button--copy-solid-dark">
                                <a href="/shop-contacts" title="">
                                    <span>SHOP CONTACTS</span>
                                </a>
                            </button>
                            <button class="button btn-full-inline button--copy-solid-dark">
                                <a href="/shop-frames">
                                    <span>SHOP FRAMES</span>
                                </a>
                            </button>
                            <button class="button btn-full-inline button--copy-solid-dark">
                                <a href="/choose-my-lenses">
                                    <span>SHOP LENS REPLACEMENT</span>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
    props: {
        currentStep: {
            type: [Array, Object],
            default: () => {}
        }
    },
    data () {
        return {
            isLensInCart: false,
            successQualification: false
        }
    },
    computed: {
        ...mapGetters(['base_url']),
        ...mapGetters('onlineExam', ['TYPE', 'QUALIFICATIONS']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow']),
        ...mapGetters('membershipProgram', ['hasCustomerMembership',]),
        showPrice (){
            console.log(!this.hasCustomerMembership && !this.isRedeemFlow)
            return !this.hasCustomerMembership && !this.isRedeemFlow
        }
    },
    mounted () {
        this.$store.dispatch('onlineExam/GET_QUALIFICATIONS')
        this.$store.dispatch('lensablplus_customer/getMembershipInfo')
    },
    activated () {
        this.$store.commit('onlineExam/SET_LOADING', false)
        this.$emit('can-continue', {value: true})
    },
    methods: {
        ...mapActions('onlineExam', ['GET_QUALIFICATIONS', 'ADD_TO_CART'])
    }
}
</script>
