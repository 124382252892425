var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "frames-breadcrumbs" }, [
    _c("div", { staticClass: "breadcrumbs" }, [
      _c(
        "ul",
        [
          _c("li", { staticClass: "frames" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.base_url + "glasses-frames",
                  title: "Go to Frame Shop"
                }
              },
              [_vm._v("Frame Shop")]
            ),
            _vm._v(" "),
            _c("span", [_vm._v(" > ")])
          ]),
          _vm._v(" "),
          _vm._l(_vm.currentCategory.breadcrumb, function(crumb, index) {
            return _c("li", { key: index }, [
              crumb.link === ""
                ? _c("strong", [_vm._v(_vm._s(_vm.label(crumb.label)))])
                : _c("span", [
                    _c(
                      "a",
                      { attrs: { href: crumb.link, title: crumb.label } },
                      [_vm._v(_vm._s(_vm.label(crumb.label)))]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(" > ")])
                  ])
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }