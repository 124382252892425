<template>
    <div class="membership-page-create">
        <ContentHeader @save="saveMembership" />
        <v-flex>
            <MembershipAbstract class="account-information" header-text="Account Information">
                <BlockLoader :loading="!loaded">
                    <MembershipFormAccount />
                </BlockLoader>
            </MembershipAbstract>
        </v-flex>

        <div v-show="!customer.isPlusCustomer">
            <v-flex>
                <v-col>
                    <MembershipAbstract header-text="Membership">
                        <BlockLoader :loading="!loaded">
                            <MembershipData />
                        </BlockLoader>
                    </MembershipAbstract>

                    <MembershipAbstract v-if="selectedPlan && selectedPlan.has_contacts" header-text="Contact Lens Benefit">
                        <BlockLoader :loading="!loaded">
                            <MembershipContactLens />
                        </BlockLoader>
                    </MembershipAbstract>

                    <MembershipAbstract header-text="Coupon Code">
                        <BlockLoader :loading="!loaded">
                            <CouponCode />
                        </BlockLoader>
                    </MembershipAbstract>

                    <MembershipAbstract header-text="Payment Method">
                        <BlockLoader :loading="!loaded">
                            <MembershipPayment />
                        </BlockLoader>
                    </MembershipAbstract>

                    <MembershipAbstract v-if="eyeExamShow" header-text="Eye Exam Benefit">
                        <BlockLoader :loading="!loaded">
                            <MembershipEyeExam :redeem-item-type="getRedeemTypeWithPrefix('exam')" />
                        </BlockLoader>
                    </MembershipAbstract>
                </v-col>
                <v-col>
                    <MembershipAbstract header-text="Billing Address">
                        <BlockLoader :loading="!loaded">
                            <MembershipFormAddress />
                        </BlockLoader>
                    </MembershipAbstract>
                </v-col>
            </v-flex>
        </div>

        <TotalsPrice />

        <BaseLoader v-if="saveLoader" />
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {
    COMPONENT_ID_GLASSES,
    COMPONENT_ID_PLAN_TYPE,
    COMPONENT_ID_SEARCH,
    GLASSES_BUSINESS_EXAM_ONLY_VALUE
} from '../storage/constants/objectList'
import lensablPlus from '../storage/lensablplus'
import membership from '../storage/lensablplus/membership'
import ContentHeader from './create/ContentHeader.vue'
import TotalsPrice from './create/TotalsPrice.vue'
import CouponCode from './create/CouponCode.vue'
import MembershipPayment from './form/Payment.vue'
import MembershipAbstract from './Abstract.vue'
import MembershipFormAccount from './form/Account.vue'
import MembershipData from './form/Data.vue'
import MembershipFormAddress from './form/Address.vue'
import MembershipEyeExam from './form/EyeExam.vue'
import MembershipContactLens from './form/ContactLens.vue'
import BaseLoader from '../../base/BaseLoader.vue'
import BlockLoader from '../../base/BlockLoader.vue'

export default {
    name: 'LensabalplusMembershipCreate',
    components: {
        ContentHeader,
        MembershipAbstract,
        MembershipFormAccount,
        MembershipFormAddress,
        MembershipContactLens,
        MembershipEyeExam,
        MembershipData,
        MembershipPayment,
        TotalsPrice,
        CouponCode,
        BaseLoader,
        BlockLoader
    },
    data: () => ({
        loaded: false,
        saveLoader: false,
        redirectAfterSave: true
    }),
    computed: {
        ...mapState('lensablPlus', ['selectedPlan']),
        ...mapState('lensablPlus/membership', ['customer', 'paymentData']),
        ...mapGetters('lensablPlus', ['values', 'checkoutData']),
        ...mapGetters('lensablPlus/membership', ['currentMemberShipId', 'currentBillingAddress']),
        ...mapGetters('lensablplus_customer', ['getPrefixRedeem']),
        eyeExamShow () {
            return this.values[COMPONENT_ID_GLASSES] === GLASSES_BUSINESS_EXAM_ONLY_VALUE
        }
    },
    watch: {
        checkoutData: {
            handler (data) {
                this.$store.commit('lensablPlus/membership/MEMBER_SHIP_FORM_DATA', {
                    checkoutData: data
                })
            },
            deep: true
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablPlus/']) {
            this.$store.registerModule('lensablPlus', lensablPlus(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['lensablPlus/membership']) {
            this.$store.registerModule(['lensablPlus', 'membership'], membership(this.$store))
        }
        this.$store.dispatch('lensablPlus/membership/fetchDefaultData')

        this.$store.dispatch('lensablPlus/fetchDefaultData')
        this.$store.dispatch('lensablPlus/membership/fetchDefaultData')
        this.$store.dispatch('lensablPlus/membership/fetchMemberShipData', this.currentMemberShipId).then(() => {
            this.$store.commit('lensablPlus/COMPONENT_LIST', {
                [COMPONENT_ID_GLASSES]: {
                    id: COMPONENT_ID_GLASSES,
                    value: ''
                },
                [COMPONENT_ID_PLAN_TYPE]: {
                    id: COMPONENT_ID_PLAN_TYPE,
                    value: ''
                },
                [COMPONENT_ID_SEARCH]: {
                    id: COMPONENT_ID_SEARCH,
                    value: {
                        left: '',
                        right: ''
                    }
                }
            })
            this.$store.commit('lensablPlus/membership/MEMBER_SHIP_EDITED', true)
            this.$store.dispatch('lensablPlus/membership/salesQuoteToData').then(() => {
                this.loaded = true
            })
        })
    },
    mounted () {
        window.recurly.configure('ewr1-boA5fCVvhJgjXwCaJxWT7s')
    },
    methods: {
        saveMembership () {
            this.$store.commit('lensablPlus/membership/CUSTOMER', {
                validate: true
            })

            if (!this.currentBillingAddress.invalid && this.customer.email) {
                const membershipForm = this.$el.closest('form')
                if (this.paymentData.payment_method === 'recurly') {
                    document.querySelector("input[name='payment[cc_type]']").value = window.cc_type
                    window.recurly.token(membershipForm, (err, token) => {
                        if (err) {
                            console.log(err)
                            const fields = (err.fields).join(', ')
                            alert('The following fields are invalid:' + ' ' + fields)
                        } else {
                            this.$store.dispatch('lensablPlus/membership/updateMemberShipFormData', {
                                payment: {
                                    recurly_token: token.id,
                                    cc_type: window.cc_type
                                },
                                billing_info: {
                                    ...token,
                                    token: token,
                                    token_id: (token || {}).id,
                                    recurly_token: token,
                                    cc_type: window.cc_type
                                }
                            }).then(() => {
                                this.submitCheckoutForm()
                            })
                        }
                    })
                } else {
                    this.submitCheckoutForm()
                }
            }

            return false
        },
        getRedeemTypeWithPrefix (type) {
            return this.getPrefixRedeem + type
        },
        submitCheckoutForm () {
            return this.$store.dispatch('lensablPlus/membership/saveMemberShipData', this.redirectAfterSave).then(() => {
                // this.saveLoader = !!this.redirectAfterSave
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .flex {
        .col {
            width: 50%;
            margin: 0 5px;
            .entry-edit {
                width: 100%;
            }
        }
    }
    .account-information {
        width: 100%;
    }
</style>
