var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nonplus_container" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "secondary" }, [
      _c(
        "a",
        { attrs: { href: "/why-plus" } },
        [
          _c("BaseButton", {
            staticClass: "button button--copy-icon",
            attrs: { "data-size": "xs", text: "FIND OUT MORE" }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "secondary" }, [
      _c(
        "a",
        { attrs: { href: "/plus/vision_plan" } },
        [
          _c("BaseButton", {
            staticClass: "button button--copy-solid",
            attrs: { "data-size": "xs", text: "GET STARTED" }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h2", [_vm._v("Join Lensabl+ Today!")]),
      _vm._v(" "),
      _c("p", { staticClass: "plus-description" }, [
        _vm._v(
          "The first all online vision benefits platform. Plans include an annual supply of contact lenses, a free pair of prescription glasses, and discounts for other optical products and services, including an eye exam."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("ul", [
        _c("li", { staticClass: "plan-price" }, [
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/assets/dollar-sign.svg",
              alt: "dollar sign"
            }
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v("Plans starting as low as "),
            _c("span", [
              _vm._v(
                "$7.99/month, saving you hundreds of dollars out-of-pocket per year compared to traditional vision insurance plans."
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "plan-redeem" }, [
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/assets/computer.svg",
              alt: "computer"
            }
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Online redemption of your benefits makes getting your glasses and contacts "
            ),
            _c("span", [
              _vm._v(
                "delivered to your door fast and easy, all without leaving home."
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "plan-glasses" }, [
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/assets/glasses.svg",
              alt: "glasses"
            }
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v("Plans include a "),
            _c("span", [_vm._v("FREE pair of prescription glasses,")]),
            _vm._v(" including frames and polycarbonate lenses with "),
            _c("span", [
              _vm._v(
                "Blue-Light/UV protection, anti-reflective and anti-scratch coatings."
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "plan-doctor" }, [
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/assets/contacts.svg",
              alt: "contact lenses"
            }
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v("For contact lens wearers, our plans include a "),
            _c("span", [
              _vm._v(
                "full annual supply of your prescribed contact in addition to free glasses"
              )
            ]),
            _vm._v(" -- no need to choose between one or the other.")
          ])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "plan-contacts" }, [
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/assets/people.svg",
              alt: "doctors"
            }
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v("Members can choose to go to "),
            _c("span", [_vm._v("ANY eye doctor")]),
            _vm._v(", rather than being limited to a network or "),
            _c("span", [_vm._v("take our online vision test from home.")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }