<template>
    <vue-modaltor
        :visible="isModalOpen"
        :default-width="'100%'"
        :bg-overlay="''"
        :bg-panel="''"
        :close-scroll="false"
        class="redeem-modal"
        :class="{'wrapper': isModalOpen}"
        @hide="hideModal"
    >
        <div class="redeem-container exam-container">
            <div v-show="!uploaded">
                <h2 v-text="title"></h2>
                <h3 v-text="subtitle"></h3>
                <div class="border-container">
                    <span v-if="instruction" class="subtitle" v-text="instruction"></span>
                    <div :class="{'has-file': hasFile}" class="requirements-container eye-exam-container">
                        <div class="upload-prescription">
                            <div class="upload-component">
                                <div v-if="!isUploading" v-show="hasNoFile" :class="{'invalid-form': showInvalidFormBorder}" class="file-input-box">
                                    <input type="text" disabled="disabled" class="file-input" placeholder="No file chosen" />
                                    <button type="button" title="Continue" class="action primary file-input-button">
                                        <span>Browse...</span>
                                        <input type="file" name="prescription" accept="image/*,application/pdf" @change="previewFiles" />
                                    </button>
                                </div>

                                <div v-else>
                                    <div class="uploading-progress">Uploading...</div>
                                </div>

                                <div v-if="hasFile" class="uploaded-file">
                                    <div class="option-checkbox-container active"><div class="option-checkbox"></div></div>
                                    <span v-dompurify-html="fileData.fileName"></span>
                                    <a href="javascript:void(0)" class="remove-link" @click="removeFile">Remove</a>
                                </div>

                                <div v-if="showError" class="error-hint">
                                    <span v-dompurify-html="errorHint"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BaseButton v-show="hasFile" :loader="loader" type="submit" text="SUBMIT" tabindex="3" @click.native="updateReceiptRewards" />
            </div>

            <div v-show="uploaded" class="uploaded-success">
                <h2>Thank You 🎉</h2>
                <h5><strong>Your receipt has been submitted successfully.</strong></h5>
                <h5>Once our customer service team verifies it, you will receive an email explaining how to claim your benefit.</h5>
                <BaseButton v-show="hasFile" :loader="loader" type="submit" text="RETURN TO ACCOUNT" tabindex="3" @click.native="returnTo" />
            </div>
        </div>
    </vue-modaltor>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import Vue from 'vue'
import VueModalTor from 'vue-modaltor'
import BaseButton from '../../../components/base/BaseButton.vue'
import PrescriptionUploadAbstract from '../../../components/prescription/upload/PrescriptionUploadAbstract.vue'

Vue.use(VueModalTor)
export default {
    name: 'ExamCreditRedeem',
    components: {
        BaseButton
    },
    extends: PrescriptionUploadAbstract,
    props: {
        title: {
            type: String,
            default: 'Redeeming your',
            required: false
        },
        subtitle: {
            type: String,
            default: '',
            required: false
        },
        instruction: {
            type: String,
            default: '',
            required: false
        },
        redeemItemType: {
            type: String,
            default: '',
            required: true
        },
        isModalOpen: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data: () => ({
        uploaded: false
    }),
    computed: {
        ...mapState('lensablplus_customer', ['planItemId']),
        ...mapGetters('lensablplus_customer', ['membershipId']),
        hasNoFile () {
            return !this.hasFile
        },
        sendToServerData () {
            const {name, size, type} = this.fileData.file
            return {
                name,
                size,
                type,
                is_membership: this.membershipId,
                item_id: this.planItemId,
                data: this.fileData.dataURL,
                redeem_item_type: this.redeemItemType
            }
        }
    },
    methods: {
        hideModal () {
            this.$emit('update', false)
            if (this.uploadData.upload_id) {
                this.$emit('continue', true)
            }
        },
        returnTo () {
            this.$emit('update', false)
            this.$emit('continue', true)
        },
        updateReceiptRewards () {
            if (this.uploadData.upload_id) {
                this.loader = true
                this.$store.dispatch('prescriptions/upload/updateReceiptRewards', this.uploadData).then(({data, status}) => {
                    if ((status === 200) && data.hasOwnProperty('notifications')) {
                        if (data.hasOwnProperty('notifications')) {
                            this.$store.commit('lensablplus_customer/NOTIFICATIONS', data['notifications'])
                        }

                        this.$store.commit('lensablplus_customer/REDEEMABLE_PRODUCTS', data['redeemable_products'] || {})
                        this.$store.commit('lensablplus_customer/UPDATE_REDEEM_ITEM_DATA', {
                            type: this.redeemItemType,
                            data: data.hasOwnProperty('receipt_data') ? data['receipt_data'] : {}
                        })
                    }
                    this.$store.dispatch('lensablplus_customer/fetchData')
                    this.uploaded = true
                    this.loader = false
                })
                    .then(async () =>{
                        await this.$store.dispatch('lensablplus_customer/fetchData')
                    })
            }
        }
    }
}
</script>

<style scoped lang="scss">
    body {
        margin: 0;
        overflow: hidden;
    }

    .wrapper {
        position: relative;
        min-height: 100vh;
    }

    [class|="confetti"] {
        position: absolute;
    }

    $colors: (#d13447, #ffbf00, #263672);

    @for $i from 0 through 150 {
        $w: random(8);
        $l: random(100);
        .confetti-#{$i} {
            width: #{$w}px;
            height: #{$w*0.4}px;
            background-color: nth($colors, random(3));
            top: -10%;
            left: unquote($l+"%");
            opacity: random() + 0.5;
            transform: rotate(#{random()*360}deg);
            animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") infinite;
        }

        @keyframes drop-#{$i} {
            100% {
                top: 110%;
                left: unquote($l+random(15)+"%");
            }
        }
    }
</style>
