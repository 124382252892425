<template>
    <div class="struct-parallax-bg" style="padding-bottom: 0;">
        <AnagramProvider/>
        <Providers/>
        <LinneoProvider/>
    </div>
</template>
<script>
import Providers from "./Providers.vue";
import AnagramProvider from "./AnagramProvider.vue";
import LinneoProvider from "./LinneoProvider.vue";
import storageInsurance from "./storage/insurance";
import storageInsuranceAnagram from "./storage/insuranceAnagram";

export default {
    name: 'ProvidersPage',
    components: {
        LinneoProvider,
        Providers,
        AnagramProvider
    },
    beforeCreate() {
        if (!this.$store._modulesNamespaceMap['insurance/']) {
            this.$store.registerModule('insurance', storageInsurance(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['insuranceAnagram/']) {
            this.$store.registerModule('insuranceAnagram', storageInsuranceAnagram(this.$store))
        }
    },
}
</script>
