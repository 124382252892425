/**
 * Components Ids
 */
export const COMPONENT_ID_DASHBOARD_PLANS = 'dashboard-plans'
export const COMPONENT_ID_DEPENDENTS = 'dependents'
export const COMPONENT_ID_PLUS_DASHBOARD = 'plus-dashboard'
export const COMPONENT_ID_DASHBOARD_PAYMENT = 'dashboard-payment'

export const getObjectList = () => {
    return [
        {
            id: COMPONENT_ID_DASHBOARD_PLANS,
            title: 'Welcome to Lensabl+!',
            default_value: null,
            component: {
                name: COMPONENT_ID_DASHBOARD_PLANS
            }
        },
        {
            id: COMPONENT_ID_DEPENDENTS,
            title: 'Will you be adding any dependents?',
            default_value: null,
            component: {
                name: COMPONENT_ID_DEPENDENTS
            }
        },
        {
            id: COMPONENT_ID_PLUS_DASHBOARD,
            default_value: null,
            component: {
                name: COMPONENT_ID_PLUS_DASHBOARD
            }
        },
        {
            id: COMPONENT_ID_DASHBOARD_PAYMENT,
            title: 'Payment Information',
            default_value: null,
            component: {
                name: COMPONENT_ID_DASHBOARD_PAYMENT
            }
        }
    ]
}
