var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.sameProduct
      ? _c("div", [
          _c("div", { staticClass: "product-details" }, [
            _c("h2", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.sameName,
                  expression: "sameName"
                }
              ]
            }),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.sameSecond_Name))]),
            _vm._v(" "),
            _c("img", { attrs: { src: _vm.sameImage, alt: _vm.sameName } }),
            _vm._v(" "),
            _vm.sameImage
              ? _c("p", { staticClass: "rx-instrutions" }, [
                  _vm._v(
                    "Your prescription is written on the side of your box."
                  )
                ])
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.sameProduct
      ? _c(
          "div",
          { staticClass: "product-details-container" },
          _vm._l(_vm.eyeData, function(products, index) {
            return _c(
              "div",
              { key: index, staticClass: "product-details", class: index },
              [
                _c("h2", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: products.brand,
                      expression: "products.brand"
                    }
                  ]
                }),
                _vm._v(" "),
                _c("h3", [_vm._v(_vm._s(products.second_name))]),
                _vm._v(" "),
                _c("img", {
                  attrs: { src: products.image, alt: products.brand }
                }),
                _vm._v(" "),
                products.image
                  ? _c("p", { staticClass: "rx-instrutions" }, [
                      _vm._v(
                        "\n                Your prescription is written on the side of your box.\n            "
                      )
                    ])
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "contacts_rx-container" },
      _vm._l(_vm.eyeData, function(products, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "edit-section" }, [
            _c("h4", [_vm._v(_vm._s(products.label))]),
            _vm._v(" "),
            !_vm.isRedeemFlow
              ? _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.removeProduct(index)
                      }
                    }
                  },
                  [_vm._v("Change Brand")]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "rx-container",
              class: index,
              attrs: { id: "addValue-" + index }
            },
            [
              _c("div", { staticClass: "contacts_rx-values" }, [
                _vm.showColorOptions(index)
                  ? _c(
                      "li",
                      [
                        _c("PrescriptionDropdown", {
                          attrs: {
                            index: index,
                            values: _vm.colorOptions,
                            value: products.color,
                            products: products,
                            type: "color",
                            placeholder: "Select Color"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("PrescriptionDropdown", {
                      attrs: {
                        index: index,
                        values: _vm.powerOptions,
                        value: products.rx.power,
                        products: products,
                        type: "power",
                        placeholder: "Select Power/Sphere"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    !_vm.isDropdown(products.rx_dropdown.bc, index, "bc")
                      ? _c("div", { staticClass: "contacts_rx_set" }, [
                          _c("span", [_vm._v("BC")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                products.rx.bc
                                  ? products.rx.bc.slice(0, -1)
                                  : products.rx.bc
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isDropdown(products.rx_dropdown.bc)
                      ? _c("PrescriptionDropdown", {
                          attrs: {
                            index: index,
                            values: _vm.bcOptions,
                            value: products.rx.bc,
                            products: products,
                            "available-values": _vm.contactsRXData[index].bc,
                            type: "bc",
                            placeholder: "BC"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isDropdown(products.rx_dropdown.dia, index, "dia")
                  ? _c("li", [
                      _c("div", { staticClass: "contacts_rx_set" }, [
                        _c("span", [_vm._v("DIA")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              products.rx.dia
                                ? products.rx.dia.slice(0, -1)
                                : products.rx.dia
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                products.rx_dropdown.cyl &&
                _vm.checkSum(products.rx_dropdown.cyl)
                  ? _c(
                      "li",
                      [
                        _c("PrescriptionDropdown", {
                          attrs: {
                            index: index,
                            values: _vm.cylOptions,
                            value: products.rx.cyl,
                            products: products,
                            "available-values": _vm.contactsRXData[index].cyl,
                            placeholder: "CYL",
                            type: "cyl"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                products.rx_dropdown.axis &&
                _vm.checkSum(products.rx_dropdown.axis)
                  ? _c(
                      "li",
                      [
                        _c("PrescriptionDropdown", {
                          attrs: {
                            index: index,
                            values: _vm.axisOptions,
                            value: products.rx.axis,
                            products: products,
                            "available-values": _vm.contactsRXData[index].axis,
                            placeholder: "Axis",
                            type: "axis"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                products.rx_dropdown.add &&
                _vm.checkSum(products.rx_dropdown.add)
                  ? _c(
                      "li",
                      [
                        _c("PrescriptionDropdown", {
                          attrs: {
                            index: index,
                            values: _vm.addOptions,
                            value: products.rx.add,
                            products: products,
                            "available-values": _vm.contactsRXData[index].add,
                            placeholder: "Add Power",
                            type: "add"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              attrs: { id: "add-image-" + index },
              on: {
                click: function($event) {
                  return _vm.goToCatPage(index)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  alt: "edit button",
                  src: "/js/lensconfigurator/assets/add.svg"
                }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("Add " + _vm._s(index) + " Eye")])
            ]
          ),
          _vm._v(" "),
          _vm.showModal
            ? _c("div", { staticClass: "manual-modal modal-vue" }, [
                _c("div", { staticClass: "modal-vue-overlay" }),
                _vm._v(" "),
                _c("div", { staticClass: "modal-exit" }, [
                  _c("div", { staticClass: "modal-close-contacts" }, [
                    _c("img", {
                      staticClass: "modal-close-icon",
                      attrs: {
                        src: "/js/lensconfigurator/assets/close-icon.svg"
                      },
                      on: { click: _vm.closeModal }
                    })
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("Are you sure?")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "If you delete this product you will lose any Rx that you entered."
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "rx-button_container" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button button--copy-icon",
                        on: {
                          click: function($event) {
                            return _vm.addNewProduct(_vm.current)
                          }
                        }
                      },
                      [_vm._v("Yes")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button button--copy-solid",
                        on: { click: _vm.closeModal }
                      },
                      [_vm._v("No")]
                    )
                  ])
                ])
              ])
            : _vm._e()
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm.isNotAvailableValue
      ? _c("div", { staticClass: "contacts_rx-error-container" }, [
          _c("p", { staticClass: "contacts_rx-error-msg" }, [
            _vm._v("**Warning: The selected configuration is not available.**")
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showErrorMessage
      ? _c("div", { staticClass: "contacts_rx-error-container" }, [
          _c("p", { staticClass: "contacts_rx-error-msg" }, [
            _vm._v(
              "\n            **Warning: You didn’t enter all of the values neccesary to complete your order. Please try again.**\n        "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }