import getHelper from '../helper'
import {
    LENS_OPTION_CODE_167,
    LENS_OPTION_CODE_POLY,
    LENS_OPTIONS_CODE,
} from '../lens_options'

import {
    LENS_TYPE_ID,
    LENS_TYPE_ID_CLEAR_LENSES, LENS_TYPE_SECTION_ID,
} from '../../../../../app/common/constants/lens_type'

import {defaultOptions, framesTypeCustomize} from "./default";



export const LIMITERS_DEFAULT_BOSE = {
    lens_options_enable_by_lens_type: {
        progressive_standard: {
            'Sun Polarized': [LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_167]
        },
    },
    enabled_colors: {
        progressive_standard: {
            'Sun Polarized': ['polarized-brown', 'polarized-grey', 'polarized-g15']
        },
    },
    anti_reflective_enable: {
        'single_vision_distance_premium': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'single_vision_distance': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'single_vision_reading_premium': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'single_vision_reading': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'progressive_standard': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
        'progressive_premium': {'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']},
    }
}

export default function getBoseStepList (params) {
    const defaultStepOptions = defaultOptions(params)
    const {product_config,assets_url} = params
    const {DEFAULT_LAST_STEP} = getHelper(params)
    function assetUrl(filename) {
        return assets_url + filename
    }


    return [
        defaultStepOptions.notesOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.chooseLens.prescriptionType,
                    defaultStepOptions.chooseLens.nonPrescriptionType
                ]
            }
        },
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription
                ]
            }
        },
        Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_near'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_near_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP}),
        {
            'id': LENS_TYPE_ID,
            'label': 'Select Lens Type',
            'summary_label': 'Lens Type',
            'value_renderer': 'renderer-options',
            'default_value': 'sunglasses_lenses',
            'component': {
                'name': 'selector-options-lens-type',
                'params': {
                    'product_config': product_config
                },
                'options': [
                    defaultStepOptions.lensType.sunglassesLens,
                    {
                        'id': LENS_TYPE_ID_CLEAR_LENSES,
                        'label': 'Clear Lenses*',
                        'summary_label': 'Clear Lenses',
                        'description': 'Your everyday clear lenses.',
                        'checkout_value': 'Clear',
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Your everyday clear lenses.</p>'
                            }
                        },
                        'thumbnail': assetUrl('product-configuration-lenstype-standardclear.jpg')
                    },
                    defaultStepOptions.lensType.blueLightLens,
                    defaultStepOptions.lensType.transitionLens,
                ]
            }
        },
        {
            'id': LENS_OPTIONS_CODE,
            'label': 'Select Lens Material',
            'summary_label': 'Lens Option',
            'value_renderer': 'renderer-options',
            'hide_for_product': ['sunglass_lens', 'transition_lens'],
            'always_visible': true,
            'component': {
                'name': 'selector-options-lens-option',
                'params': {
                    'product_config': product_config
                },
                'options': [
                    defaultStepOptions.lensOptions.cr39LensOption,
                    defaultStepOptions.lensOptions.polyLensOption,
                    defaultStepOptions.lensOptions.trivexLensOptions,
                    defaultStepOptions.lensOptions.hi167LensOption,
                ]
            }
        },
        {
            'id': 'frames_type',
            'label': 'Choose Lenses',
            'summary_label': 'Choose Lenses',
            'no_summary': true,
            'value_renderer': 'renderer-options',
            'default_value': 'frames_customize',
            'component': {
                'name': 'selector-options',
                'options': [
                    framesTypeCustomize
                ]
            }
        }
    ]
}
