<template>
    <div>
        <textarea v-model="notes" :placeholder="renderPlaceholder" class="option-value" cols="30" rows="5"></textarea>
        <span v-if="!framesPartnerPage" class="option-comments-disclaimer">For any special requests, please reach out to customer support so that we may modify your order.</span>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

const NOTES_PLACEHOLDER = 'Please tell us about the frames for this lens order (Make/Model/Color)'
const NOTES_PLACEHOLDER_PARTNER = 'Please tell us any special needs or requests'

export default {
    name: 'SelectorNotes',
    computed: {
        ...mapGetters('values', ['values', 'framesPartnerPage']),
        renderPlaceholder() {
            return this.framesPartnerPage ? NOTES_PLACEHOLDER_PARTNER : NOTES_PLACEHOLDER
        },
        notes: {
            get() {
                return this.values.notes
            },
            set(value) {
                this.$store.commit('values/setNotes', value)
            }
        }
    }
}
</script>
