<template>
    <div v-if="isNext">
        <RadioLabel :active="checked === `pd_above${index}`" :value="`pd_above${index}`" label="Use PD above" @click="$emit('checked', `pd_above${index}`)" />
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import SalesOrderViewPrescriptionRenderAbstract from './abstract'

export default {
    name: 'SalesOrderViewPrescriptionRenderAbove',
    mixins: [SalesOrderViewPrescriptionRenderAbstract],
    data: () => ({
        isNext: false
    }),
    computed: {
        ...mapGetters('sales/order/items', ['isNextItem', 'getPrevItemId', 'firstItemId']),
        ...mapGetters('prescriptions', ['optionsSaveService'])
    },
    created () {
        this.isNext = this.isNextItem(this.item.item_id)
        if (this.isNext) {
            this.checkPrevItem()
        }
    },
    methods: {
        ...mapActions('prescriptions', ['setValueOptions']),
        checkPrevItem () {
            const prevItemId = this.getPrevItemId(this.item.item_id)
            if (prevItemId) {
                this.optionsSaveService
                    .checkPrevItem(prevItemId)
                    .then(({data, status}) => {
                        this.isNext = status === 200 && Object.keys(data.data).length > 0
                        if (this.isNext) {
                            this.$emit('checked', `pd_above${this.index}`)
                            this.setValueOptions(this.index, data.data)
                        }
                    })
            }
        }
    }
}
</script>
