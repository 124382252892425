import Vue from 'vue'
import Vuex from 'vuex'
import _map from 'lodash/map'
import {RootState} from '../app/RootState'

import getPromiseService from '../app/utils/promise-service'

import storeView from './modules/store'
import membershipProgram from './modules/membershipProgram'
import deviceProperty from './modules/deviceProperty'
import registry from './modules/registry'
import contacts from '../components/contacts/storage/contacts'
import valuesService from './modules/values'
import persistedState from './persisted-state'
import lensablplus_customer from '../components/lensablplus/storage/lensablplus/customer'
import salesStorage from '../components/sales/order/storage/modules/sales'
import prescriptions from '../components/prescriptions/storage/prescriptions'
import prescriptionsUpload from '../components/prescriptions/storage/prescriptions/upload'
import partnerStorage from './modules/partner'

Vue.use(Vuex)

export class StoreApp {
    constructor (modules = {}) {
        this.store = this.getStore()
        this.store.registerModule('storeView', storeView())
        this.store.registerModule('membershipProgram', membershipProgram(this.store))
        this.store.registerModule('deviceProperty', deviceProperty(this.store))
        this.store.registerModule('values', valuesService(this.store))
        this.store.registerModule('sales', salesStorage(this.store))
        this.store.registerModule('prescriptions', prescriptions(this.store))
        this.store.registerModule(['prescriptions', 'upload'], prescriptionsUpload(this.store))

        _map(modules, (module, index) => {
            if (!this.store.state.hasOwnProperty(index)) {
                const path = index.split('/')
                let _rootState = false
                let _moduleState = this.store.state
                for (const i in path) {
                    _moduleState = _moduleState && _moduleState[path[i]] ? _moduleState[path[i]] : false
                    _rootState = _moduleState && _moduleState[path[parseInt(i) + 1]] ? _moduleState : _rootState
                }

                if (_rootState) {
                    delete _rootState[path.slice(-1).pop()]
                }

                this.store.registerModule(path, module(this.store))
            }
        })
    }

    getStore () {
        const store = new Vuex.Store({
            // strict: process.env.NODE_ENV !== 'production',
            state: () => ({
                adminHtml: false,
                currentRoute: '',
                appId: 'single',
                reset: false,
                errors: [],
                validation: true
            }),
            getters: {
                promiseService: () => getPromiseService(store),
                currentRoute: state => state.currentRoute,
                getAppId: state => state.appId,
                rootState: (state, getters) => {
                    const rootState = !getters.currentRoute ? RootState.state : RootState.state[getters.currentRoute]
                    if (getters.getAppId) {
                        return rootState[getters.getAppId] || {}
                    }
                    return rootState
                },
                getErrors: state => state.errors
            },
            mutations: {
                SET_ADMIN_HTML: (state, adminHtml) => state.adminHtml = adminHtml,
                SET_CURRENT_ROUTE: (state, route) => state.currentRoute = route,
                SET_APP_ID: (state, id) => state.appId = id,
                updateErrors: (state, errors) => {
                    state.errors = errors
                },
                updateReset: (state, reset) => {
                    state.reset = reset
                },
                setValidation: (state, validation) => {
                    state.validation = validation
                }
            },
            modules: {
                registry,
                contacts: contacts(),
                lensablplus_customer: lensablplus_customer(),
                partner: partnerStorage()
            },
            plugins: [persistedState]
        })
        return store
    }
}

export default function getStore (modules = {}) {
    const app = new StoreApp(modules)
    return app.store
}
