var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "modal-mask",
      on: {
        click: function($event) {
          return _vm.closeButton()
        }
      }
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-container scg-modal-container" },
      [
        _c("div", {
          staticClass: "modal-close-button scg-modal-close-button",
          on: {
            click: function($event) {
              return _vm.closeButton()
            }
          }
        }),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }