var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "prescription-type form-access__header",
      staticStyle: { margin: "2rem" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "state-container" },
        [
          _c("v-select", {
            attrs: {
              items: _vm.REGIONS,
              "item-text": "label",
              "item-value": "value",
              outlined: ""
            },
            model: {
              value: _vm.region,
              callback: function($$v) {
                _vm.region = $$v
              },
              expression: "region"
            }
          }),
          _vm._v(" "),
          _vm.$v.region.$error
            ? _c("p", { staticClass: "help is-danger" }, [
                _vm._v("Choose a region")
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "prescription-type-header" }, [
      _c("h3", [_vm._v("Which state do you live in?")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }