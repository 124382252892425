import getHelper from '../helper'
import {defaultOptions} from "./default";


export const LIMITERS_DEFAULT_NIGHT = {
    anti_reflective_enable: {
        'single_vision_distance_premium': {'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR']},
        'single_vision_distance': {'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR']},
        'single_vision_reading_premium':{'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR']},
        'single_vision_reading': {'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR']},
        'progressive_standard': {'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR',  'Blue-Light Blocking AR']},
        'progressive_premium': {'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR']},
    }
}
export default function getNightStepList (param) {
    const defaultStepOptions = defaultOptions(param)
    const {product_config, assets_url, antireflective_price, upgrades_section} = param
    const {DEFAULT_LAST_STEP} = getHelper(param)

    function assetUrl (filename) {
        return assets_url + filename
    }

    const lens_type = {
        'id': 'lens_type',
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'default_value': 'clear_lenses',
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                {
                    'id': 'clear_lenses',
                    'label': 'Sleep Lenses',
                    'summary_label': 'Sleep Lenses',
                    'description': 'Your everyday clear lenses.',
                    'checkout_value': 'Blue-Light Blocking',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Your everyday clear lenses.</p>'
                        }
                    },
                    'thumbnail': assetUrl('product-configuration-lenstype-standardclear.jpg'),
                    'redeemable': true
                }
            ]
        }
    }
    const frames_type = {
        'id': 'frames_type',
        'label': 'Choose Lenses',
        'summary_label': 'Choose Lenses',
        'no_summary': true,
        'value_renderer': 'renderer-options',
        'default_value': 'frames_customize',
        'component': {
            'name': 'selector-options',
            'options': [
                {
                    'id': 'frames_customize',
                    'label': 'Prescription Lenses',
                    'summary_label': 'Customize lenses',
                    'description': 'Available in Single Vision or Progressive',
                    'thumbnail': ''
                }
            ]
        }
    }

    const lens_options = {
        'id': 'lens_options',
        'label': 'Select Lens Material',
        'summary_label': 'Lens Option',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['sunglass_lens', 'transition_lens'],
        'always_visible': true,
        'component': {
            'name': 'selector-options-lens-option',
            'params': {
                'product_config': product_config
            },
            'options': [
                {
                    'id': 'poly',
                    'label': 'Polycarbonate Lens',
                    'summary_label': 'Polycarbonate',
                    'checkout_value': 'Poly',
                    'description': 'Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.',
                    'popular': true,
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.</p>'
                        }
                    },
                    'small_description': true,
                    'redeemable': true
                },
                {
                    'id': '167',
                    'label': '1.67 High-Index Lens',
                    'summary_label': '1.67 High-Index Lens',
                    'checkout_value': '167',
                    'description': 'For those looking for thinner, lighter lenses. Recommended for strong prescriptions (a total power of +/- 4 or greater)',
                    'popular': false,
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>For those looking for thinner, lighter lenses. Recommended for strong prescriptions (a total power of +/- 4 or greater)</p>'
                        }
                    },
                    'small_description': true,
                    'price' : '+$50'
                }
            ]
        }
    }

    const lens_category = {
        'id': 'lens_category',
        'label': 'Choose Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'default_value': null,
        'component': {
            'name': 'selector-lens-category',
            'params': {
                'product_config': product_config
            },
            'options': [
                {
                    'id': 'optical',
                    'label': 'Optical',
                    'summary_label': 'Optical Lenses',
                    'description': 'Clear lenses with blue-light blocking protection',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Clear lenses with blue-light blocking protection</p>'
                        }
                    }
                },
                {
                    'id': 'sun',
                    'label': 'Sun',
                    'summary_label': 'Sun Lenses',
                    'description': 'Tinted and Mirrored lenses available in a variety of colors',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>Tinted and Mirrored lenses available in a variety of colors</p>'
                        }
                    }
                },
                {
                    'id': 'night',
                    'label': 'Night',
                    'summary_label': 'Night Lenses',
                    'description': '50% orange tinted lenses with blue light protection to block the HEV rays emitted from digital screens and increase melatonin naturally before bed',
                    'component_help': {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<p>50% orange tinted lenses with blue light protection to block the HEV rays emitted from digital screens and increase melatonin naturally before bed</p>'
                        }
                    }
                }
            ]
        }
    }

    const single_vision_distance = Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP})
    const single_vision_distance_premium  = Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP})
    const single_vision_near = Object.assign({'id': 'single_vision_reading'}, {...DEFAULT_LAST_STEP})
    const single_vision_near_premium  = Object.assign({'id': 'single_vision_reading_premium'}, {...DEFAULT_LAST_STEP})
    const progressive_standard = Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP})
    const progressive_premium = Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP})

    const step_list = [
        lens_category,
        {
            'id': 'notes',
            'label': 'Frame Information',
            'no_summary': true,
            'summary_label': 'Comments',
            'value_renderer': 'renderer-notes',
            'continue_label': 'Continue',
            'default_value': null,
            'component': {
                'name': 'selector-notes'
            }
        },
        {
            'id': 'step_antireflective',
            'label': 'Select Upgrades',
            'partner_label': 'Anti-Reflective Coating',
            'summary_label': 'Select Upgrades',
            'summary_partner_label': 'Anti-Reflective Coating',
            'value_renderer': 'renderer-antireflective',
            'continue_label': 'Continue',
            'default_value': null,
            'component': {
                'name': 'selector-antireflective',
                'params': {
                    'antireflective_price': antireflective_price,
                    'upgrades_section': upgrades_section,
                    'assets_url': assets_url
                }
            }
        },
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'always_visible': true,
            'component': {
                'name': 'selector-options',
                'options': [
                    {
                        'id': 'prescription',
                        'label': 'Prescription Lenses',
                        'summary_label': 'Prescription',
                        'description': 'Available in clear, sunglass, Transitions<sup>®</sup> and blue light blocking lenses.',
                        'thumbnail': assetUrl('product-configuration-chooselenses-prescription.jpg'),
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Available in clear, sunglass, Transitions<sup>®</sup> and blue light blocking lenses.</p>'
                            }
                        }
                    },
                    {
                        'id': 'nonprescription',
                        'label': 'Non-Prescription',
                        'summary_label': 'Non-Prescription',
                        'description': 'Perfect for people with 20/20 vision or who wear contacts',
                        'thumbnail': assetUrl('product-configuration-chooselenses-sunglasses.jpg'),
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Perfect for people with 20/20 vision or who wear contacts</p>'
                            }
                        }
                    }
                ]
            }
        },
        {
            'id': 'nonprescription',
            'label': 'Choose Non-Prescription',
            'summary_label': 'Non-Prescription',
            'value_renderer': 'renderer-options',
            'default_value': 'plano',
            'always_visible': true,
            'component': {
                'name': 'selector-options',
                'options': [
                    {
                        'id': 'plano',
                        'label': 'Blue-Light Blocking Lenses',
                        'summary_label': 'Sleep Lenses',
                        'description': 'Reduce glare and eyestrain caused by digital screens and artificial light. Virtually clear with a nearly imperceptible yellow hue.',
                        'thumbnail': assetUrl('product-configuration-chooselenses-computer.jpg'),
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Reduce glare and eyestrain caused by digital screens and artificial light. Virtually clear with a nearly imperceptible yellow hue.</p>'
                            }
                        }
                    }
                ]
            }
        },
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription,
                ]
            }
        },
        frames_type,
        single_vision_distance,
        single_vision_distance_premium,
        single_vision_near,
        single_vision_near_premium,
        progressive_standard,
        progressive_premium,
        lens_type,
        lens_options
    ]
    return step_list
}
