<template>
    <div class="products wrapper" :class="grid ? ' grid products-grid' : ''">
        <PageLoader v-if="load" :load="load" />
        <template v-else>
            <ul v-if="productsCount" :class="categoryClass" class="products list items product-items">
                <template v-for="(product, index) in products">
                    <li :key="product.url + index" class="item product product-item">
                        <CatalogCategoryListItem :product="product" />
                    </li>
                </template>
            </ul>
            <div v-else class="sorry">
                <p>Sorry... there are no products matching your selection.</p>
                <p>Please try again.</p>
            </div>
        </template>
    </div>
</template>

<script>
import CatalogCategoryListItem from './list/Item.vue'
import PageLoader from '../../loaders/PageLoader.vue'
import {mapGetters} from 'vuex'

export default {
    name: 'CatalogProductList',
    components: {
        CatalogCategoryListItem,
        PageLoader
    },
    props: {
        load: {
            type: [Boolean],
            default: false
        },
        grid: {
            type: [Boolean],
            default: false
        },
        products: {
            type: [Object, Array],
            default: () => []
        },
        categoryClass: {
            type: [String],
            default: ''
        }
    },
    computed: {
        ...mapGetters('category', ['getCatProducts']),
        productsCount () {
            return this.products ? this.products.length : 0
        }
    }
}
</script>
