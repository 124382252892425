var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-ladda",
    {
      ref: _vm.nodeRef,
      attrs: {
        id: _vm.idName,
        type: _vm.type,
        "button-class": "action primary",
        disabled: _vm.disabled,
        loading: _vm.loader
      }
    },
    [_vm._t("default", [_vm._v(_vm._s(_vm.text))])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }