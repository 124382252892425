var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "options_container upgrades" }, [
    _c("div", { staticClass: "option-upgrades" }, [
      _vm.info.label
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "option-container upgrades-container",
                class: { "has-thumbnail": !!_vm.info.image }
              },
              [
                _vm.info.image
                  ? _c("div", { staticClass: "option-thumbnail" }, [
                      _c("img", {
                        attrs: { src: _vm.assets_url + _vm.info.image }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "option-descriptions title" }, [
                  _c("span", { staticClass: "option-label" }, [
                    _c("span", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: _vm.info.label,
                          expression: "info.label"
                        }
                      ],
                      staticClass: "label-text"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "label-optional" }, [
                      _vm._v("(optional)")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "extra-component-container" }, [
                  _c("div", { staticClass: "extra-component" }, [
                    _c("span", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: _vm.info.desc,
                          expression: "info.desc"
                        }
                      ]
                    })
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "options" },
        [
          _vm._l(_vm.steps_upgrades, function(option) {
            return [
              _c("Option", {
                key: option.id,
                staticClass: "step-configuration-content",
                attrs: { "component-help-show": _vm.isMobile, option: option }
              })
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }