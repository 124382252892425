<template>
    <div>
        <div class="email-it-later">
            <span>We will email you after you place your order for your Prescription.</span>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    name: 'PrescriptionEmailItLater',
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    created () {
        this.updateStep({
            id: this.id,
            data: {disabled: false}
        })
    },
    methods: {
        ...mapActions('configurator/steps', ['updateStep'])
    }
}
</script>
