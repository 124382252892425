var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNext
    ? _c(
        "div",
        [
          _c("RadioLabel", {
            attrs: {
              active: _vm.checked === "pd_above" + _vm.index,
              value: "pd_above" + _vm.index,
              label: "Use PD above"
            },
            on: {
              click: function($event) {
                return _vm.$emit("checked", "pd_above" + _vm.index)
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }