var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "onestepcheckout-coupons discount-block" }, [
    _c(
      "div",
      { staticClass: "clearfix" },
      [
        _c("div", {
          staticStyle: { display: "none" },
          attrs: { id: "coupon-notice" }
        }),
        _vm._v(" "),
        _vm.getOpenForm
          ? _c("div", [
              _c("span", { staticClass: "gift-card-question" }, [
                _vm._v("Have a promo code?")
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "gift-card-here",
                  on: {
                    click: function($event) {
                      _vm.showInput = true
                    }
                  }
                },
                [_vm._v("Click here")]
              )
            ])
          : _c(
              "div",
              { staticClass: "collapse-block", attrs: { id: "coupon_block" } },
              [
                _c("span", { staticClass: "discount-label" }, [
                  _vm._v("Enter Promo Code")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.coupon,
                      expression: "coupon"
                    }
                  ],
                  staticClass: "onestepcheckout-couponcode input-text",
                  attrs: {
                    type: "text",
                    name: "onestepcheckout-couponcode",
                    autocomplete: "off"
                  },
                  domProps: { value: _vm.coupon },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.coupon = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button apply-coupon",
                    attrs: { id: "onestepcheckout-coupon-add", type: "button" },
                    on: { click: _vm.applyCoupon }
                  },
                  [_vm._m(0)]
                )
              ]
            ),
        _vm._v(" "),
        _vm._l(_vm.COUPONS, function(coupon_code, index) {
          return _c("p", { key: index, staticClass: "coupon-added" }, [
            _c("span", { domProps: { textContent: _vm._s(coupon_code) } }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "coupon-remove",
                on: {
                  click: function() {
                    return _vm.removeCoupon(coupon_code)
                  }
                }
              },
              [_vm._v("Remove")]
            )
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("span", [_vm._v("Apply")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }