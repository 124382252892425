var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.productView.productInfoShow,
            expression: "productView.productInfoShow"
          }
        ],
        staticClass:
          "product-details-holder spectacles-wrapper look-wrapper frames-wrapper"
      },
      [
        _c("div", { staticClass: "page-title-wrapper product" }, [
          _c("h1", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.product_name,
                expression: "product_name"
              }
            ],
            staticClass: "page-title",
            class: _vm.product_name,
            attrs: { itemprop: "name" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product media" }, [_c("Gallery")], 1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "product-info-main" },
          [
            _vm.product_description
              ? _c("div", { staticClass: "look-desc" }, [
                  _c("span", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: _vm.product_description,
                        expression: "product_description"
                      }
                    ]
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "swatch-opt" },
              [
                _c("ColorSwatches", {
                  attrs: {
                    colors: _vm.colors,
                    "checked-index": _vm.checkedIndex
                  },
                  on: _vm._d({}, [
                    _vm.EVENT_CHANGE_CURRENT_COLOR,
                    _vm.changeCurrentColor
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            !_vm.stockStatus
              ? _c(
                  "p",
                  {
                    staticClass: "unavailable stock",
                    attrs: { title: "Availability" }
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("This item is sold out")
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("AdditionalAttributes", {
              staticClass: "inline",
              attrs: { "data-attributes": _vm.additionalAttributes }
            }),
            _vm._v(" "),
            _vm.isAvailable && _vm.isMobile
              ? _c("div", { staticClass: "product-options-bottom" }, [
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { text: "Select lenses" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.mobileSwitcher()
                            }
                          }
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.isMobile ? "Select" : "Select lenses"
                              )
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.configuratorShow,
            expression: "configuratorShow"
          }
        ],
        staticClass: "product-configurator-wrapper"
      },
      [_c("Configurator")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }