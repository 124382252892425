var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLensablPlus
        ? [
            _c("div", { staticClass: "totals-price-regular total-line" }, [
              _c("span", { staticClass: "total-label total-dan label" }, [
                _vm._v("Total")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value value" }, [
                _c("span", { staticClass: "total-value-currency currency" }, [
                  _vm._v(_vm._s(_vm.currencySymbol))
                ]),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: String(_vm.redeemTotalAmount),
                      expression: "String(redeemTotalAmount)"
                    }
                  ],
                  staticClass: "total-value-amount amount"
                })
              ])
            ])
          ]
        : !_vm.showFramesPrice
        ? [
            _vm.subTotalAmountLenses > 0 && _vm.hasCustomerMembership
              ? _c(
                  "div",
                  { staticClass: "totals-price-subtotal total-line subtotal" },
                  [
                    _c("span", { staticClass: "total-label label" }, [
                      _vm._v("Lenses")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "total-value value" }, [
                      _c("span", { staticClass: "total-value-currency" }, [
                        _vm._v(_vm._s(_vm.currencySymbol))
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: _vm.subTotalAmountLenses,
                            expression: "subTotalAmountLenses"
                          }
                        ],
                        staticClass: "total-value-amount"
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("MemberDiscountSummary", {
              attrs: { "class-name": "plus-benefit-discount" }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "totals-price-regular total-line main-label" },
              [
                _c("span", { staticClass: "total-label total-dan label" }, [
                  _vm._v("Total")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "total-value value" }, [
                  _c("span", { staticClass: "total-value-currency currency" }, [
                    _vm._v(_vm._s(_vm.currencySymbol))
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: String(_vm.totalAmountDiscounted),
                        expression: "String(totalAmountDiscounted)"
                      }
                    ],
                    staticClass: "total-value-amount amount"
                  })
                ])
              ]
            )
          ]
        : !_vm.isLensablPlus
        ? [
            _vm.subTotalAmountLenses > 0
              ? _c(
                  "div",
                  { staticClass: "totals-price-subtotal total-line subtotal" },
                  [
                    _c("span", { staticClass: "total-label label" }, [
                      _vm._v("Lenses")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "total-value value" }, [
                      _c("span", { staticClass: "total-value-currency" }, [
                        _vm._v(_vm._s(_vm.currencySymbol))
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: _vm.subTotalAmountLenses,
                            expression: "subTotalAmountLenses"
                          }
                        ],
                        staticClass: "total-value-amount"
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "totals-price-subtotal total-line subtotal" },
              [
                _c("span", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.framesPartnerPage ? "Frames" : "Spectacles",
                      expression: "framesPartnerPage ? 'Frames' : 'Spectacles'"
                    }
                  ],
                  staticClass: "total-label label"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "total-value value" }, [
                  _c("span", { staticClass: "total-value-currency" }, [
                    _vm._v(_vm._s(_vm.currencySymbol))
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: _vm.subTotalAmountFrames
                          ? _vm.subTotalAmountFrames
                          : "0",
                        expression:
                          "subTotalAmountFrames ? subTotalAmountFrames : '0'"
                      }
                    ],
                    staticClass: "total-value-amount"
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("MemberDiscountSummary", {
              attrs: { "class-name": "plus-member-discount" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "total-line frame-total" }, [
              _c("span", { staticClass: "total-label total-dan label" }, [
                _vm._v("Total")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "total-value value" }, [
                _c("span", { staticClass: "total-value-currency currency" }, [
                  _vm._v(_vm._s(_vm.currencySymbol))
                ]),
                _vm._v(" "),
                !_vm.isLensablPlus
                  ? _c("span", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: _vm.totalAmountDiscounted,
                          expression: "totalAmountDiscounted"
                        }
                      ],
                      staticClass: "total-value-amount amount"
                    })
                  : _c("span", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: _vm.redeemTotalAmount,
                          expression: "redeemTotalAmount"
                        }
                      ],
                      staticClass: "total-value-amount amount"
                    })
              ])
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }