<template>
    <div class="employee-signup">
        <nav class="lensabl-plus-nav signup-nav">
            <div class="nav-container">
                <a class="nav__logo" href="/" aria-label="Link to the homepage">
                    <img class="plus-logo" src="/js/lensconfigurator/assets/lensabl-plus-wordmark.svg" />
                </a>
            </div>
        </nav>
        <PageLoader v-if="getLoad" :load="getLoad" />
        <div v-else class="lensabl-plus-content company-details">
            <h2 class="title">Enter Your Details</h2>
            <div id="company_address_fields" class="company-address">
                <div class="content">
                    <div class="hor-scroll">
                        <table cellspacing="0" class="form-list" style="width: 100%">
                            <tbody>
                                <tr>
                                    <Input
                                        :employee="employee[0]"
                                        :error="errors[employee[0].name]"
                                        colspan="2"
                                        :value="values[employee[0].name]"
                                        @blur="validBlurField"
                                        @update="values[employee[0].name] = $event"
                                    />
                                </tr>
                                <tr>
                                    <Input
                                        :employee="employee[1]"
                                        :error="errors[employee[1].name]"
                                        :value="values[employee[1].name]"
                                        @blur="validBlurField"
                                        @update="values[employee[1].name] = $event"
                                    />
                                    <td>
                                        <label :for="`employee-${ employee[2].name }`">
                                            {{ employee[2].label }}:
                                        </label>
                                        <date-picker
                                            v-model="values[employee[2].name]"
                                            :placeholder="employee[2].placeholder"
                                            value-type="format"
                                            :class="{'required-entry': employee[2].required, 'validation-failed': errors[employee[2].name]}"
                                            class="input-text"
                                            type="text"
                                            format="MM-DD-YYYY"
                                            @input="validBlurField(employee[2].name)"
                                        />
                                        <div v-if="errors[employee[2].name]" :id="`advice-required-entry-${employee[2].name}`" class="validation-advice" style="">This is a required field.</div>
                                    </td>
                                </tr>
                                <tr>
                                    <Input
                                        :employee="employee[3]"
                                        :error="errors[employee[3].name]"
                                        colspan="2"
                                        :value="values[employee[3].name]"
                                        :maxlength="employee.length"
                                        @blur="validBlurField"
                                        @update="values[employee[3].name] = $event"
                                    />
                                </tr>
                                <tr>
                                    <Input
                                        :employee="employee[4]"
                                        :error="errors[employee[4].name]"
                                        colspan="2"
                                        :value="values[employee[4].name]"
                                        @blur="validBlurField"
                                        @update="values[employee[4].name] = $event"
                                    />
                                </tr>
                                <tr>
                                    <Input
                                        :employee="employee[5]"
                                        colspan="2"
                                        :value="values[employee[5]]"
                                        @update="values[employee[5]] = $event"
                                    />
                                </tr>
                            </tbody>
                        </table>
                        <table cellspacing="0" class="form-list" style="width: 100%">
                            <colgroup>
                                <col width="60%" />
                                <col width="60%" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <Input
                                        :employee="employee[6]"
                                        :error="errors[employee[6].name]"
                                        :value="values[employee[6].name]"
                                        @update="values[employee[6].name] = $event"
                                    />
                                    <Input
                                        :employee="employee[7]"
                                        :error="errors[employee[7].name]"
                                        :value="values[employee[7].name]"
                                        @blur="validBlurField"
                                        @update="values[employee[7].name] = $event"
                                    />
                                </tr>
                                <tr>
                                    <td>
                                        <label :for="`employee-${ employee[8].name }`">
                                            {{ employee[8].label }}:
                                        </label>
                                        <select
                                            :id="`employee_${employee[8].name}`"
                                            v-model="values[employee[8].name]"
                                            :class="{'required-entry': employee[8].required, 'validation-failed': errors[employee[8].name]}"
                                            class="select"
                                            @change="validBlurField(employee[8].name)"
                                        >
                                            <template v-for="region in region_data">
                                                <option :key="`option-${region.value}`" :value="region.value" v-text="region.label"></option>
                                            </template>
                                        </select>
                                        <div v-if="errors[employee[8].name]" :id="`advice-required-entry-${employee[8].name}`" class="validation-advice" style="">
                                            This is a required field.
                                        </div>
                                    </td>
                                    <Input
                                        :employee="employee[9]"
                                        :error="errors[employee[9].name]"
                                        :value="values[employee[9].name]"
                                        @blur="validBlurField"
                                        @update="values[employee[9].name] = $event"
                                    />
                                </tr>
                            </tbody>
                        </table>
                        <table cellspacing="0" class="form-list" style="width: 100%">
                            <tbody>
                                <tr>
                                    <Input
                                        :employee="employee[10]"
                                        :error="errors[employee[10].name]"
                                        :value="values[employee[10].name]"
                                        @blur="validBlurField"
                                        @update="values[employee[10].name] = $event"
                                    />
                                    <Input
                                        :employee="employee[11]"
                                        :error="errors[employee[11].name]"
                                        :value="values[employee[11].name]"
                                        @blur="validBlurField"
                                        @update="values[employee[11].name] = $event"
                                    />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="actions-toolbar">
                <div class="primary">
                    <BaseButton
                        class="button--copy-solid-white-blue button--small"
                        text="Sign up"
                        @click.native="continueNxt"
                    />
                </div>
                <span  v-if="errorMessage" style="color: red">{{errorMessage}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import _map from 'lodash/map'
import lensablplusBusiness from './storage/lensablplus/business'
import PageLoader from '../base/PageLoader.vue'
import {validationMixin} from 'vuelidate'
import BaseButton from '../base/BaseButton.vue'
import Input from './SignupInput.vue'
import {required, email, minLength, numeric, maxLength, sameAs} from 'vuelidate/lib/validators'

export default {
    name: 'EmployeeSignup',
    components: {
        BaseButton,
        Input,
        DatePicker,
        PageLoader
    },
    mixins: [validationMixin],
    data: () => ({
        errors: {
            name: false,
            birthday: false,
            email: false,
            password: false,
            confirm_password: false,
            number: false,
            city: false,
            country: false,
            postcode: false,
            region: false,
            street1: false,
            street2: false
        },
        employee: [
            {
                name: 'name',
                label: 'Name',
                required: true,
                type: 'text'
            },
            {
                name: 'email',
                label: 'Email',
                required: true,
                type: 'text',
                message: 'Please enter a valid email'
            },
            {
                name: 'birthday',
                label: 'Birthday',
                required: true,
                type: 'text',
                placeholder: 'MM-DD-YYYY'
            },
            {
                name: 'number',
                label: 'Phone Number',
                required: true,
                type: 'text',
                message: 'Please exclude dashes',
                length: '10'
            },
            {
                name: 'street1',
                label: 'Address Line 1',
                type: 'text',
                required: true
            },
            {
                name: 'street2',
                label: 'Address Line 2',
                type: 'text',
                required: false
            },
            {
                name: 'country',
                label: 'Country',
                required: true,
                type: 'text',
                disabled: true
            },
            {
                name: 'city',
                label: 'City',
                required: true,
                type: 'text'
            },
            {
                name: 'region',
                label: 'State',
                required: true,
                type: 'select',
                options: []
            },
            {
                name: 'postcode',
                label: 'Zip/Postal Code',
                required: true,
                type: 'text'
            },
            {
                name: 'password',
                label: 'New Password',
                required: true,
                type: 'password'
            },
            {
                name: 'confirm_password',
                label: 'Confirm Password',
                required: true,
                type: 'password',
                message: 'Passwords must be identical.'
            }
        ],
        values: {
            name: '',
            email: '',
            birthday: '',
            number: '',
            street1: '',
            street2: '',
            country: 'United States',
            city: '',
            region: '',
            postcode: '',
            password: '',
            confirm_password: ''
        },
        final: {
            name: '',
            email: '',
            birthday: '',
            number: '',
            street: '',
            country: 'United States',
            city: '',
            region: '',
            postcode: '',
            password: ''
        },
        errorMessage: false
    }),
    validations: {
        values: {
            email: {
                required,
                email
            },
            street1: {
                required
            },
            password: {
                required,
                minLength: minLength(6)
            },
            name: {
                required,
                minLength: minLength(3)
            },
            confirm_password: {
                sameAsPassword: sameAs('password'),
                required,
                minLength: minLength(6)
            },
            postcode: {
                required
            },
            region: {
                required
            },
            city: {
                required
            },
            country: {
                required
            },
            birthday: {
                required
            },
            number: {
                required,
                numeric,
                minLength: minLength(10),
                maxLength: maxLength(10)
            }
        }
    },
    computed: {
        ...mapState('lensablplus_business', ['region_data']),
        ...mapGetters('lensablplus_business', ['getLoad']),
        employeeInvalidFields () {
            const invalidFields = []
            _map(this.values, (value, key) => {
                if (this.$v.values.hasOwnProperty(key) && this.$v.values[key].$invalid) {
                    invalidFields.push(key)
                }
            })
            return invalidFields
        },
        employeeIsValid () {
            return !this.employeeInvalidFields.length
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablplus_business/']) {
            this.$store.registerModule('lensablplus_business', lensablplusBusiness(this.$store))
        }
        this.$store.dispatch('lensablplus_business/fetchDefaultData')
    },
    created () {
        this.$store.dispatch('lensablplus_business/fetchCountryRegions', 'US')
        this.$store.dispatch('lensablplus_business/getBusiness')
    },
    methods: {
        continueNxt () {
            if (this.employeeIsValid) {
                _map(this.final, (id, index) => {
                    this.final[index] = this.values[index]
                })
                this.final['street'] = `${this.values['street1']} ${this.values['street2']}`
                this.$store.commit('lensablplus_business/LOAD', true)
                this.$store.dispatch('lensablplus_business/submitEmployee', this.final)
                    .catch(error => {
                        const response = error.response
                        if (response.status === 400){
                            this.$store.commit('lensablplus_business/LOAD', false)
                            this.errorMessage = response.data.message
                        }
                })
            } else {
                _map(this.employee, (value, key) => {
                    if (value['required'] === true) {
                        this.validBlurField(value['name'])
                    }
                })
            }
        },
        validBlurField (val) {
            this.errors[val] = this.$v.values[val].$invalid
        }
    }
}
</script>

<style scoped lang="less">
.mx-datepicker {
    border-radius: .3125rem;
    width: 100%;

}

input.mx-input {
    margin-bottom: 0 !important;
}

input::placeholder {
    font-style: normal!important;
}

select {
    border: 0;
}

.employee-signup .loader-circle {
    min-height: 66vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
