var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.complete
    ? _c("ul", { staticClass: "action-list" }, [
        _c(
          "li",
          [
            _c("Above", {
              attrs: {
                item: _vm.item,
                "prescriptions-type": _vm.prescriptionsType,
                "prescription-index": _vm.prescriptionIndex,
                checked: _vm.checked
              },
              on: {
                checked: function($event) {
                  return _vm.$emit("checked", $event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("Enter", {
              staticClass: "my-prescription-form",
              attrs: {
                item: _vm.item,
                "prescriptions-type": _vm.prescriptionsType,
                "prescription-index": _vm.prescriptionIndex,
                checked: _vm.checked
              },
              on: {
                checked: function($event) {
                  return _vm.$emit("checked", $event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("Upload", {
              staticClass: "upload-image-online",
              attrs: {
                item: _vm.item,
                "prescriptions-type": _vm.prescriptionsType,
                "prescription-index": _vm.prescriptionIndex,
                checked: _vm.checked,
                "option-name": "uploaded_pd"
              },
              on: {
                checked: function($event) {
                  return _vm.$emit("checked", $event)
                }
              }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }