<template>
    <div class="bose-dimensions">
        <p class="bose-dimensions-copy">
            Classic angular lenses, available in S/M and M/L.
            <span class="bose-view-dimensions" @click="openModal()">View dimensions</span>
        </p>

        <div ref="modal" class="bose-dimensions-modal">
            <div class="bose-dimensions-model--content">
                <div class="bose-dimensions-modal-close" @click="closeModal()">
                    <img src="/js/lensconfigurator/assets/close-icon.svg" alt="" />
                </div>
                <div class="bose-dimensions-modal-type">
                    <span class="bose-dimensions-modal-text">Alto S/M</span>
                    <div class="bose-dimensions-modal-row">
                        <img src="/js/lensconfigurator/assets/Alto-SM-Front.png" alt="" />
                        <img src="/js/lensconfigurator/assets/Alto-SM-Side.png" alt="" />
                    </div>
                </div>
                <div class="bose-dimensions-modal-row">
                    <div class="bose-dimensions-modal-type">
                        <span class="bose-dimensions-modal-text">Alto M/L</span>
                        <div class="bose-dimensions-modal-row">
                            <img src="/js/lensconfigurator/assets/Alto-ML-Front.png" alt="" />
                            <img src="/js/lensconfigurator/assets/Alto-ML-Side.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref="modal--overlay" class="bose-dimensions-modal--overlay" @click="closeModal()"></div>
    </div>
</template>

<script>
export default {
    name: 'BoseDimensionsAlto',
    methods: {
        openModal () {
            this.$refs['modal'].style.display = 'block'
            this.$refs['modal--overlay'].style.display = 'block'
        },
        closeModal () {
            this.$refs['modal'].style.display = 'none'
            this.$refs['modal--overlay'].style.display = 'none'
        }
    }
}
</script>
