<template>
    <div>
        <div v-if="listTitle" :class="{active: show}" class="filter-tab" @click="show = !show">
            <span v-if="!isMobile" class="list-title">{{ listTitle }}:</span> <span v-if="isMobile" class="list-title">{{ listTitle }}</span> <span v-if="isMobile" class="reset-filters" @click="reset">Reset Filters</span>
        </div>
        <div v-if="!isMobile" class="filter-tab">
            <ul class="category-frames-grid">
                <li>
                    <div class="custom-select">
                        <v-select
                            v-model="getFiltered.category"
                            :items="allCats"
                            item-text="name"
                            item-value="value"
                            multiple
                            label="Brand"
                            class="frame-dropdown"
                            @change="changedValue('category')"
                        >
                            <template slot="selection" slot-scope="{ data, index }">
                                <div v-if="getFiltered.category.length === 1">
                                    {{ getFiltered.category[index] }}
                                </div>
                                <div v-else-if="getFiltered.category.length > 1 && index < 1">2 or more &nbsp;&nbsp;&nbsp;</div>
                            </template>
                        </v-select>
                    </div>
                </li>
                <li v-if="false">
                    <div class="custom-select">
                        <v-select
                            v-model="getFiltered.lens_type"
                            :items="lens_type.choices"
                            item-value="value"
                            multiple
                            label="Lens Type"
                            class="frame-dropdown"
                            @change="changedValue('lens_type')"
                        >
                            <template slot="selection" slot-scope="{ data, index }">
                                <div v-if="getFiltered.lens_type.length === 1">
                                    {{ getFiltered.lens_type[index] }}
                                </div>
                                <div v-else-if="getFiltered.lens_type.length > 1 && index < 1">2 or more &nbsp;&nbsp;&nbsp;</div>
                            </template>
                        </v-select>
                    </div>
                </li>
                <li>
                    <div class="custom-select">
                        <v-select
                            v-model="getFiltered.frame_size"
                            :items="frame_size.choices"
                            item-value="value"
                            multiple
                            label="Frame Size"
                            class="frame-dropdown"
                            @change="changedValue('frame_size')"
                        >
                            <template slot="selection" slot-scope="{ data, index }">
                                <div v-if="getFiltered.frame_size.length === 1">
                                    {{ getFiltered.frame_size[index] }}
                                </div>
                                <div v-else-if="getFiltered.frame_size.length > 1 && index < 1">2 or more &nbsp;&nbsp;&nbsp;</div>
                            </template>
                        </v-select>
                    </div>
                </li>
                <li>
                    <div class="custom-select">
                        <v-select
                            v-model="getFiltered.material"
                            :items="material.choices"
                            item-value="value"
                            multiple
                            label="Material"
                            class="frame-dropdown"
                            @change="changedValue('material')"
                        >
                            <template slot="selection" slot-scope="{ data, index }">
                                <div v-if="getFiltered.material.length === 1">
                                    {{ getFiltered.material[index] }}
                                </div>
                                <div v-else-if="getFiltered.material.length > 1 && index < 1">2 or more &nbsp;&nbsp;&nbsp;</div>
                            </template>
                        </v-select>
                    </div>
                </li>
                <li>
                    <div class="custom-select">
                        <span class="reset-filters" @click="reset">Reset Filters</span>
                    </div>
                </li>
            </ul>
        </div>
        <transition name="slide">
            <div v-if="isMobile && show" class="filter-form">
                <div class="filter-list brands-list">
                    <h5>Brands</h5>
                    <ul>
                        <li v-for="cat in allCats" :key="`category-${cat.id}`">
                            <label :for="cat.id">
                                <input :id="cat.id" v-model="getFiltered.category" :value="cat.name" type="checkbox" @change="changedValue('category')" />
                                <span>{{ cat.name }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div v-if="false" class="filter-list lens-type-list">
                    <h5>Lens Type</h5>
                    <ul>
                        <li v-for="type in lens_type.choices" :key="`lens_type-${type}`">
                            <label :for="type">
                                <input :id="type" v-model="getFiltered.lens_type" :value="type" type="checkbox" @change="changedValue('lens_type')" />
                                <span>{{ type }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="filter-list frame-size-list">
                    <h5>Frame Size</h5>
                    <ul>
                        <li v-for="size in frame_size.choices" :key="`frame_size-${size}`">
                            <label :for="size">
                                <input :id="size" v-model="getFiltered.frame_size" :value="size" type="checkbox" @change="changedValue('frame_size')" />
                                <span>{{ size }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="filter-list material-list">
                    <h5>Material</h5>
                    <ul>
                        <li v-for="mat in material.choices" :key="`material-${mat}`">
                            <label :for="mat">
                                <input :id="mat" v-model="getFiltered.material" :value="mat" type="checkbox" @change="changedValue('material')" />
                                <span>{{ mat }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {
    EVENT_CATEGORY_SELECTED
} from '../../../app/utils/constants'

export default {
    name: 'CatalogNavigationCategories',
    components: {

    },
    props: {
        categories: {
            type: [Object, Array],
            default: () => []
        },
        listTitle: {
            type: String,
            default: ''
        },
        currentId: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            show: false,
            allCats: [],
            category: [],
            lens_type: {chosen: [], choices: ['Night', 'Sun', 'Optical']},
            frame_size: {chosen: [], choices: ['Small', 'Medium', 'Large']},
            material: {chosen: [], choices: ['Acetate', 'Metal']}
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobile', 'isDesktop']),
        ...mapGetters('category', ['hasFilters', 'getFiltered']),
        ...mapGetters('values', ['values'])
    },
    created () {
        this.$store.dispatch('partner/fetchDefaultData')
    },
    mounted () {
        this.categories.forEach(element => {
            if (element.name !== 'All') {
                this.allCats.push(element)
            }
        })
    },
    methods: {
        changedValue (category) {
            this.$emit(EVENT_CATEGORY_SELECTED, category, this.getFiltered[category])
        },
        reset () {
            this.getFiltered.category = []
            this.$emit(EVENT_CATEGORY_SELECTED, 'category', this.getFiltered.category)

            this.getFiltered.lens_type = []
            this.$emit(EVENT_CATEGORY_SELECTED, 'lens_type', this.getFiltered.lens_type)

            this.getFiltered.frame_size = []
            this.$emit(EVENT_CATEGORY_SELECTED, 'frame_size', this.getFiltered.frame_size)

            this.getFiltered.material = []
            this.$emit(EVENT_CATEGORY_SELECTED, 'material', this.getFiltered.material)
        }
    }
}
</script>
