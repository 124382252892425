<template>
    <td :key="`row-${employee.name}`">
        <label :for="`employee-${ employee.name }`">
            {{ employee.label }}:
        </label>
        <template>
            <input
                :id="`employee-${ employee.name }`"
                v-model="value"
                :class="{'required-entry': employee.required, 'validation-failed': error}"
                :readonly="employee.disabled"
                :maxlength="employee.length"
                :type="employee.type"
                class="input-text"
                @blur="validBlurField(employee.name)"
                @keyup="$emit('update',value)"
            />
        </template>
        <div v-if="error" :id="`advice-required-entry-${employee.name}`" class="validation-advice" style="">
            {{ employee.message ? employee.message : 'This is a required field.' }}
        </div>
    </td>
</template>

<script>
export default {
    name: 'SignupInput',
    props: {
        employee: { // Component Id
            type: [Object],
            default: () => {}
        },
        error: { // Component Id
            type: [Boolean],
            default: false
        },
        value: {
            type: [String],
            default: ''
        }

    },
    methods: {
        validBlurField (val) {
            this.$emit('blur', val)
        }
    }
}
</script>

<style scoped>

</style>
