<template>
    <div v-if="componentsCount" v-show="!getLoad" class="lensabl-plus-progress tabs-header-wrapper">
        <ul ref="progress" class="tabs-header">
            <template v-for="(tab, index) in stepsList">
                <li v-if="tab" :key="`tab-${index}`" :class="[tab.state]" class="tab">
                    <span @click="setActiveStep(index)" v-text="tab.label"></span>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import lensablPlus from '../storage/lensablplus'

export default {
    name: 'ProgressBar',
    computed: {
        ...mapGetters('lensablPlus', ['stepsList', 'componentsCount', 'getLoad'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablPlus/']) {
            this.$store.registerModule('lensablPlus', lensablPlus(this.$store))
        }
        this.$store.dispatch('lensablPlus/fetchDefaultData')
    },
    methods: {
        ...mapActions('lensablPlus', ['setActiveStep'])
    }
}
</script>

<style lang="less" scoped>

</style>
