<template>
    <div>
        <div class="upload-prescription">
            <div class="upload-component">
                <div v-show="hasNoFile" :class="{'invalid-form': showInvalidFormBorder}" class="file-input-box">
                    <input type="text" disabled="disabled" class="file-input" placeholder="No file chosen" />
                    <button title="Continue" class="action primary file-input-button">
                        <span>Browse...</span>
                        <input type="file" name="prescription" accept="image/*,application/pdf" @change="previewFiles" />
                    </button>
                </div>

                <div v-if="showError" class="error-hint">
                    <span>
                        The image you’ve attempted to upload is too large, please retry with a smaller file size. <br />
                        You can also contact <a href="mailto: sales@lensabl.com">sales@lensabl.com</a> with your photo and order number.
                    </span>
                </div>

                <div v-if="hasFile" class="uploaded-file">
                    <div class="option-checkbox-container active"><div class="option-checkbox"></div></div>
                    <span v-dompurify-html="fileData.fileName"></span>
                    <a href="javascript:void(0)" class="remove-link" @click="removeFile">Remove</a>
                </div>

                <div v-if="isUploading">
                    <div class="uploading-progress">Uploading...</div>
                </div>
            </div>
        </div>

        <Pdinrx v-if="false && !isContactDetailsPage" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Pdinrx from '../../../app/common/pdinrx/Pdinrx.vue'
import PrescriptionUploadAbstract from './PrescriptionUploadAbstract.vue'

export default {
    name: 'PrescriptionUpload',
    components: {
        Pdinrx
    },
    extends: PrescriptionUploadAbstract,
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    computed: {
        ...mapGetters('prescriptions/upload', ['isContactDetailsPage']),
        sendToServerData () {
            const {name, size, type} = this.fileData.file
            return {
                name,
                size,
                type,
                is_contacts: !!this.isContactDetailsPage,
                data: this.fileData.dataURL
            }
        }
    },
    watch: {
        isValidForm (valid) {
            this.updateStepData({disabled: !valid})
        }
    },
    created () {
        this.updateStepData({disabled: !this.isValidForm})
    },
    methods: {
        updateStepData (data = {}) {
            if (this.id) {
                this.$store.dispatch('configurator/steps/updateStep', {id: this.id, data: data})
            }
        }
    }
}
</script>
