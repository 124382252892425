<template>
    <GoOnline v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import GoOnline from '../../components/govisibly/OnlineVision.vue'

export default {
    name: 'OnlineVision',
    components: {
        GoOnline
    },
    extends: App
}
</script>

<style scoped>

</style>
