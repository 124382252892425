export const STANDARD_COLORS = [
    {
        'color': 'Grey',
        'img': '/media/wysiwyg/grey-3.png',
        'alt': 'Grey lenses',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/Grey/1-solid-levels-outside.jpg',
                'alt': 'Solid Levels 1-3 (Outside)',
                'spanTitle': 'Solid Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Grey/2-solid-levels-inside.jpg',
                'alt': 'Solid Levels 1-3 (Inside)',
                'spanTitle': 'Solid Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Grey/3-solid-polarized-outside.jpg',
                'alt': 'Solid Polarized (Outside)',
                'spanTitle': 'Solid Polarized<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Grey/4-solid-polarized-inside.jpg',
                'alt': 'Solid Polarized (Inside)',
                'spanTitle': 'Solid Polarized<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Grey/5-gradient-levels-outside.jpg',
                'alt': 'Gradient Levels 1-3 (Outside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Grey/6-gradient-levels-inside.jpg',
                'alt': 'Gradient Levels 1-3 (Inside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Grey/7-gradient-polarized-outside.jpg',
                'alt': 'Gradient Polarized (Outside)',
                'spanTitle': 'Gradient Polarized<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Grey/8-gradient-polarized-inside.jpg',
                'alt': 'Gradient Polarized (Inside)',
                'spanTitle': 'Gradient Polarized<span>(Inside)</span>'
            }
        ]
    },
    {
        'color': 'Brown',
        'img': '/media/wysiwyg/brown-3.png',
        'alt': 'Brown lenses',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/Brown/1-solid-levels-outside.jpg',
                'alt': 'Solid Levels 1-3 (Outside)',
                'spanTitle': 'Solid Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Brown/2-solid-levels-inside.jpg',
                'alt': 'Solid Levels 1-3 (Inside)',
                'spanTitle': 'Solid Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Brown/3-solid-polarized-outside.jpg',
                'alt': 'Solid Polarized (Outside)',
                'spanTitle': 'Solid Polarized<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Brown/4-solid-polarized-inside.jpg',
                'alt': 'Solid Polarized (Inside)',
                'spanTitle': 'Solid Polarized<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Brown/5-gradient-levels-outside.jpg',
                'alt': 'Gradient Levels 1-3 (Outside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Brown/6-gradient-levels-inside.jpg',
                'alt': 'Gradient Levels 1-3 (Inside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Brown/7-gradient-polarized-outside.jpg',
                'alt': 'Gradient Polarized (Outside)',
                'spanTitle': 'Gradient Polarized<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Brown/8-gradient-polarized-inside.jpg',
                'alt': 'Gradient Polarized (Inside)',
                'spanTitle': 'Gradient Polarized<span>(Inside)</span>'
            }
        ]
    },
    {
        'color': 'G15',
        'img': '/media/wysiwyg/g15-3.png',
        'alt': 'Green G15 lenses',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/G15/1-solid-levels-outside.jpg',
                'alt': 'Solid Levels 1-3 (Outside)',
                'spanTitle': 'Solid Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/G15/2-solid-levels-inside.jpg',
                'alt': 'Solid Levels 1-3 (Inside)',
                'spanTitle': 'Solid Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/G15/3-solid-polarized-outside.jpg',
                'alt': 'Solid Polarized (Outside)',
                'spanTitle': 'Solid Polarized<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/G15/4-solid-polarized-inside.jpg',
                'alt': 'Solid Polarized (Inside)',
                'spanTitle': 'Solid Polarized<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/G15/5-gradient-levels-outside.jpg',
                'alt': 'Gradient Levels 1-3 (Outside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/G15/6-gradient-levels-inside.jpg',
                'alt': 'Gradient Levels 1-3 (Inside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Inside)</span>'
            }
        ]
    }
]

export const FASHION_COLORS = [
    {
        'color': 'Blue',
        'img': '/media/wysiwyg/blue-3.png',
        'alt': 'Blue lenses',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/Blue/1-solid-levels-outside.jpg',
                'alt': 'Solid Levels 1-3 (Outside)',
                'spanTitle': 'Solid Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Blue/2-solid-levels-inside.jpg',
                'alt': 'Solid Levels 1-3 (Inside)',
                'spanTitle': 'Solid Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Blue/3-gradient-levels-outside.jpg',
                'alt': 'Gradient Levels 1-3 (Outside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Outside)'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Blue/4-gradient-levels-inside.jpg',
                'alt': 'Gradient Levels 1-3 (Inside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Inside)</span>'
            }
        ]
    },
    {
        'color': 'Pink',
        'img': '/media/wysiwyg/pink-3.png',
        'alt': 'Pink lenses',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/Pink/1-solid-levels-outside.jpg',
                'alt': 'Solid Levels 1-3 (Outside)',
                'spanTitle': 'Solid Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Pink/2-solid-levels-inside.jpg',
                'alt': 'Solid Levels 1-3 (Inside)',
                'spanTitle': 'Solid Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Pink/3-gradient-levels-outside.jpg',
                'alt': 'Gradient Levels 1-3 (Outside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Outside)'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Pink/4-gradient-levels-inside.jpg',
                'alt': 'Gradient Levels 1-3 (Inside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Inside)</span>'
            }
        ]
    },
    {
        'color': 'Red',
        'img': '/media/wysiwyg/red-3.png',
        'alt': 'Red lenses',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/Red/1-solid-levels-outside.jpg',
                'alt': 'Solid Levels 1-3 (Outside)',
                'spanTitle': 'Solid Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Red/2-solid-levels-inside.jpg',
                'alt': 'Solid Levels 1-3 (Inside)',
                'spanTitle': 'Solid Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Red/3-gradient-levels-outside.jpg',
                'alt': 'Gradient Levels 1-3 (Outside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Outside)'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Red/4-gradient-levels-inside.jpg',
                'alt': 'Gradient Levels 1-3 (Inside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Inside)</span>'
            }
        ]
    },
    {
        'color': 'Yellow',
        'img': '/media/wysiwyg/yellow-3.png',
        'alt': 'Yellow lenses',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/Yellow/1-solid-levels-outside.jpg',
                'alt': 'Solid Levels 1-3 (Outside)',
                'spanTitle': 'Solid Levels 1-3<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Yellow/2-solid-levels-inside.jpg',
                'alt': 'Solid Levels 1-3 (Inside)',
                'spanTitle': 'Solid Levels 1-3<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Yellow/3-gradient-levels-outside.jpg',
                'alt': 'Gradient Levels 1-3 (Outside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Outside)'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Yellow/4-gradient-levels-inside.jpg',
                'alt': 'Gradient Levels 1-3 (Inside)',
                'spanTitle': 'Gradient Levels 1-3<span>(Inside)</span>'
            }
        ]
    }
]

export const MIRROR_COLORS = [
    {
        'color': 'Mirror',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/1-blue-outside.jpg',
                'alt': 'Blue Mirror Polarized (Outside)',
                'spanTitle': 'Blue Mirror<span>Polarized (Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/2-blue-inside.jpg',
                'alt': 'Blue Mirror Polarized (Inside)',
                'spanTitle': 'Blue Mirror<span>Polarized (Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/3-gold-outside.jpg',
                'alt': 'Gold Mirror Polarized (Outside)',
                'spanTitle': 'Gold Mirror<span>Polarized (Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/4-gold-inside.jpg',
                'alt': 'Gold Mirror Polarized (Inside)',
                'spanTitle': 'Gold Mirror<span>Polarized (Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/5-green-outside.jpg',
                'alt': 'Green Mirror Polarized (Outside)',
                'spanTitle': 'Green Mirror<span>Polarized (Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/6-green-inside.jpg',
                'alt': 'Green Mirror Polarized (Inside',
                'spanTitle': 'Green Mirror<span>Polarized (Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/7-pink-outside.jpg',
                'alt': 'Pink Mirror Polarized (Outside)',
                'spanTitle': 'Pink Mirror<span>Polarized (Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/8-pink-inside.jpg',
                'alt': 'Pink Mirror Polarized (Inside)',
                'spanTitle': 'Pink Mirror<span>Polarized (Inside)</span>'
            },
            {
                'src': 'media/wysiwyg/suncolorguide/Mirror/9-purple-outside.jpg',
                'alt': 'Purple Mirror Polarized (Outside)',
                'spanTitle': 'Purple Mirror<span>Polarized (Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/10-purple-inside.jpg',
                'alt': 'Purple Mirror Polarized (Inside)',
                'spanTitle': 'Purple Mirror<span>Polarized (Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/11-red-outside.jpg',
                'alt': 'Red Mirror Polarized (Outside)',
                'spanTitle': 'Red Mirror Polarized<span>(Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/12-red-inside.jpg',
                'alt': 'Red Mirror Polarized (Inside)',
                'spanTitle': 'Red Mirror Polarized<span>(Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/13-silver-outside.jpg',
                'alt': 'Silver Mirror Polarized (Outside)',
                'spanTitle': 'Silver Mirror<span>Polarized (Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/14-silver-inside.jpg',
                'alt': 'Silver Mirror Polarized (Inside)',
                'spanTitle': 'Silver Mirror<span>Polarized (Inside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/15-sky-outside.jpg',
                'alt': 'Sky Blue Mirror Polarized (Outside)',
                'spanTitle': 'Sky Blue Mirror<span>Polarized (Outside)</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Mirror/16-sky-inside.jpg',
                'alt': 'Sky Blue Mirror Polarized (Inside)',
                'spanTitle': 'Sky Blue Mirror<span>Polarized (Inside)</span>'
            }
        ]
    }
]

export const TRANSITIONS_COLORS = [
    {
        'color': 'Transitions',
        'images': [
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/1-drivewear.jpg',
                'alt': 'Transitions Drivewear',
                'spanTitle': 'Transitions<span>Drivewear'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/2-sig-amber.jpg',
                'alt': 'Transitions Signature Amber',
                'spanTitle': 'Transitions Signature<span>Amber</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/3-sig-amethyst.jpg',
                'alt': 'Transitions Signature Amethyst',
                'spanTitle': 'Transitions Signature<span>Amethyst</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/4-sig-brown.jpg',
                'alt': 'Transitions Signature Brown',
                'spanTitle': 'Transitions Signature<span>Brown</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/5-sig-emerald.jpg',
                'alt': 'Transitions Signature Emerald',
                'spanTitle': 'Transitions Signature<span>Emerald</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/6-sig-grey.jpg',
                'alt': 'Transitions Signature Grey',
                'spanTitle': 'Transitions Signature<span>Grey</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/7-xtra-brown.jpg',
                'alt': 'Transitions XtrActive Brown',
                'spanTitle': 'Transitions XtrActive<span>Brown</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/8-xtra-gold.jpg',
                'alt': 'Transitions XtrActive Gold Mirror',
                'spanTitle': 'Transitions XtrActive<span>Gold Mirror</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/9-xtra-blue.jpg',
                'alt': 'Transitions XtrActive Blue Mirror',
                'spanTitle': 'Transitions XtrActive<span>Blue Mirror</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/10-vantage.jpg',
                'alt': 'Transitions Vantage',
                'spanTitle': 'Transitions Vantage<span></span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/11-sig-sapphire.jpg',
                'alt': 'Transitions Signture Sapphire',
                'spanTitle': 'Transitions Signture<span>Sapphire</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/12-xtra-g15.jpg',
                'alt': 'Transitions XtrActive G15',
                'spanTitle': 'Transitions XtrActive<span>G15</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/13-xtra-green.jpg',
                'alt': 'Transitions XtrActive Green Mirror',
                'spanTitle': 'Transitions XtrActive<span>Green Mirror</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/14-xtra-grey.jpg',
                'alt': 'Transitions XtrActive Grey',
                'spanTitle': 'Transitions XtrActive<span>Grey</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/15-xtra-pink.jpg',
                'alt': 'Transitions XtrActive Pink Mirror',
                'spanTitle': 'Transitions XtrActive<span>Pink Mirror</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/16-xtra-red.jpg',
                'alt': 'Transitions XtrActive Red Mirror',
                'spanTitle': 'Transitions XtrActive<span>Red Mirror</span>'
            },
            {
                'src': '/media/wysiwyg/suncolorguide/Transitions/17-xtra-silver.jpg',
                'alt': 'Transitions XtrActive Silver Mirror',
                'spanTitle': 'Transitions XtrActive<span>Silver Mirror</span>'
            }
        ]
    }
]
