<template>
    <div class="prescription-type form-access__header" style="margin: 2rem">
        <header class="prescription-type-header">
            <h3>What is this prescription for? </h3>
        </header>
        <div class="prescription-type-content">
            <label>
                <input id="contacts-test" :value="contacts" name="type" type="radio" @change="setTypeContacts" />
                <span>Contacts</span>
            </label>
            <label>
                <input :id="glasses" :value="glasses" name="type" type="radio" @change="setTypeGlasses" />
                <span>Eye Glasses</span>
            </label>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        currentStep: {
            type: [Array, Object],
            default: () => {}
        }
    },
    data () {
        return {
            contacts: 'contacts',
            glasses: 'glasses'
        }
    },
    computed: {
        ...mapGetters('onlineExam', ['TYPE'])
    },
    methods: {
        setTypeContacts () {
            this.$store.commit('onlineExam/SET_TYPE', this.contacts)
            this.$emit('can-continue', {value: true})
        },
        setTypeGlasses () {
            this.$store.commit('onlineExam/SET_TYPE', this.glasses)
            this.$emit('can-continue', {value: true})
        }
    }
}
</script>
