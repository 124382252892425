<template>
    <section>
        <section class="cbrands-lplus-ad-block">
            <div v-if="!plusMember" class="lplus-ad-block_container" @click="openPlusAdModal">
                <a :href="adLink">
                    <img
                        src="/media/wysiwyg/lensabl-plus-ad-shopcontacts.jpeg"
                        alt="Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                        class="lplus-ad-block_img"
                    />
                    <span class="help-bubble-container lplus-help-bubble-container">
                        <div class="help-component">
                            <div id="help_bubble_text" :class="showModalAd ? 'open' : ''" class="help-bubble lplus-help-bubble">
                                <span>
                                    <p>A new, better alternative to traditional vision insurance plans, starting as low as <b>$7.99/month.</b></p>
                                    <p>Lensabl+ plans include an annual supply of contacts, a FREE frame with Rx lenses or lens replacement, and a reward for getting an eye exam. Save hundreds of dollars out-of-pocket per year on your vision care needs. <span class="c-help-bubble-link"><a href="/plus/vision_plan">Customize your plan now</a></span></p>
                                </span>
                                <span class="close-button" @click.stop="closePlusAdModal">
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                                        <path id="svg_1" d="m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z" fill="rgb(102, 102, 102)" fill-rule="evenodd" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </span>
                </a>
            </div>
        </section>
        <section class="filtered-product-component">
            <div class="filter-product-container">
                <div class="filter-options">
                    <span id="filter-toggle" @click="openFilters()">Filter</span>
                </div>
                <div v-if="getLoader" class="loader-circle">
                    <svg id="L4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                        <circle fill="#9cdbd9" stroke="none" cx="40" cy="40" r="20">
                            <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
                        </circle>
                    </svg>
                    <svg id="L4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                        <circle fill="#9cdbd9" stroke="none" cx="40" cy="40" r="20">
                            <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
                        </circle>
                    </svg>
                    <svg id="L4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                        <circle fill="#9cdbd9" stroke="none" cx="40" cy="40" r="20">
                            <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
                        </circle>
                    </svg>
                </div>
                <ul class="products-grid-contacts">
                    <template v-if="isMobile">
                        <li v-for="(product, index) in getProducts" :key="product.url" :class="index > 9 ? 'hidden' : ''">
                            <a :href="product.url">
                                <img v-lazy="product.image" :alt="product.name" />
                                <div class="product-name-container">
                                    <span v-dompurify-html="product.name" class="main-product-name"></span>
                                    <span class="secondary-product-name">{{ product.second_title }}</span>
                                </div>
                            </a>
                        </li>
                    </template>
                    <template v-if="!isMobile">
                        <li v-for="product in getProducts" :key="product.url">
                            <a :href="product.url">
                                <img v-lazy="product.image" :alt="product.name" />
                                <div class="product-name-container">
                                    <span v-dompurify-html="product.name" class="main-product-name"></span>
                                    <span class="secondary-product-name">{{ product.second_title }}</span>
                                </div>
                            </a>
                        </li>
                    </template>
                </ul>
                <div v-if="isMobile && getProducts.length >= 11 && getViewAll" class="view-all-button">
                    <span class="button button--copy-solid" @click="displayAllProducts">
                        <div class="button__container">View All Brands</div>
                    </span>
                </div>
                <div id="product_overlay" class="mobile-overlay"></div>
            </div>
        </section>
    </section>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

export default {
    name: 'Products',
    components: {

    },
    data: () => {
        return {
            isActive: false,
            selected: { name: 'Most Popular', id: 0 },
            options: [
                { name: 'Most Popular', id: 0 },
                { name: 'A - Z', id: 1 }
            ],
            chosenId: '',
            showModalAd: false,
            adLink: '/plus/vision_plan'
        }
    },
    computed: {
        ...mapGetters('category', ['categoryService', 'currentCategoryId']),
        ...mapGetters('contacts', ['getProducts', 'getLoader', 'getViewAll']),
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('lensablplus_customer', ['plusMember'])
    },
    created () {
        if (this.currentCategoryId) {
            this.initialProducts(this.currentCategoryId)
            this.$store.commit('contacts/UPDATE_SEARCHED', '')
        }
    },
    methods: {
        ...mapMutations([]),
        openFilters () {
            const element = document.getElementById('sidebar')
            const overlay = document.getElementById('product_overlay')
            element.addClassName('open')
            overlay.addClassName('open')
        },
        toggle () {
            this.isActive = !this.isActive
        },
        updateSelected (index) {
            this.selected = this.options[index]
            this.isActive = false
        },
        choseCategory (id) {
            this.chosenId(id)
        },
        displayAllProducts () {
            this.$store.commit('contacts/UPDATE_VIEW_ALL', false)
            const elements = document.getElementsByClassName('hidden')
            let i
            for (i = 0; i < elements.length; i++) {
                elements[i].classList.remove('hidden')
            }
        },
        initialProducts (category_id) {
            this.$store.commit('contacts/UPDATE_PRODUCTS', '')
            this.$store.commit('contacts/UPDATE_RUNNER', true)
            this.categoryService.getContactsProductsCollection(category_id)
                .then(({status, data}) => {
                    if (status === 200 && !data.error) {
                        this.$store.commit('contacts/UPDATE_PRODUCTS', data.products)
                    }
                    this.$store.commit('contacts/UPDATE_RUNNER', false)
                })
                .catch(error => {
                    if (error) {
                        console.error(error)
                    }
                    this.$store.commit('contacts/UPDATE_RUNNER', false)
                })
        },
        openPlusAdModal () {
            const windowRes = window.innerWidth

            if (windowRes < 768) {
                this.showModalAd = true
                this.adLink = null
            } else {
                this.showModalAd = false
                this.adLink = '/plus/vision_plan'
            }
        },
        closePlusAdModal () {
            this.showModalAd = false
        }
    }
}
</script>
