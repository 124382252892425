import _find from 'lodash/find'
import _first from 'lodash/first'
import {
    EVENT_STEP_UPDATE
} from '../../../../app/utils/constants'

import {mapGetters} from 'vuex'

export default {
    name: 'ColorViewAbstract',
    props: {
        id: { // Step Id
            type: String,
            default: ''
        }
    },
    data: () => ({
        lens_color: false
    }),
    computed: {
        ...mapGetters('values', ['values']),
        ...mapGetters('configurator/steps', ['getLimitersPreDefinedByFrames',]),
        color () {
            return this.values.color
        },
        colors () {
            return this.currentSection.colors
        },
        chosenSection () {
            return this.currentSection.id
        }
    },
    methods: {
        updateColor (data = {}) {
            this.$store.commit('values/setDataToValues', {
                color: {
                    ...data
                }
            })
        },
        chooseColor (value) {
            const color = _find(this.colors, color => color.id === value) || {}
            this.updateColor({
                value: value,
                price: parseFloat(color.price || 0)
            })
            this.lens_color = this.getColorLabelById(this.color.value)
        },
        chooseSectionDefault (data) {
            if (data.id !== this.chosenSection) {
                const firstColor = _first(data.colors)
                this.chooseColor(firstColor.id)
                if (firstColor.level) {
                    this.updateColor({level: 3})
                } else {
                    this.updateColor({level: false})
                }
                this.$bus.$emit(EVENT_STEP_UPDATE, this.id)
            }
        },
        getColorObject (id) {
            for (const section of this.sections) {
                const color = section.colors.find((color) => color.id === id)
                if (color) {
                    return color
                }
            }
            return false
        },
        getColorLabelById (id) {
            const colorObject = this.getColorObject(id)
            if (!colorObject) {
                return false
            }
            return colorObject.label
        }
    }
}
