import { render, staticRenderFns } from "./AdditionalAttributes.vue?vue&type=template&id=de742ed4&"
import script from "./AdditionalAttributes.vue?vue&type=script&lang=js&"
export * from "./AdditionalAttributes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/tkim/projects/lensabl/lensconfigurator/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de742ed4')) {
      api.createRecord('de742ed4', component.options)
    } else {
      api.reload('de742ed4', component.options)
    }
    module.hot.accept("./AdditionalAttributes.vue?vue&type=template&id=de742ed4&", function () {
      api.rerender('de742ed4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/catalog/product/view/AdditionalAttributes.vue"
export default component.exports