<template>
    <v-menu
        v-model="openCalendar"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                readonly
                :value="computedDateFormatted"
                :rules="[v => !!v || errText]"
                v-on="on"
                flat
            >
                <template v-slot:label>
                    <div v-dompurify-html="label"></div>
                </template>
            </v-text-field>
        </template>
        <v-date-picker
            locale="en-in"
            v-model="formModel[formKey]"
            :max="maxDate"
            no-title
            @input="openCalendar = false"
        ></v-date-picker>
    </v-menu>
</template>
<script>
export default {
    name: 'BrthDatePicker',
    props: {
        formModel: {
            type: Object,
            default: () => {}
        },
        formKey: {
            type: String,
            default: 'memberBrthDay'
        },
        label: {
            type: String,
            default: 'Member’s Birthday (MM/DD/YYYY)'
        },
        errText: {
            type: String,
            default: 'Member’s Birthday Required'
        },
    },
    data: () => {
        return {
            openCalendar: false,
        }
    },
    computed: {
        computedDateFormatted() {
            return this.formatDate(this.formModel[this.formKey])
        },
        maxDate(){
            const date = new Date()
            return date.toISOString().substring(0, 10)
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
    }
}
</script>
