<template>
    <div class="featured-frames-container">
        <h3>Most Popular Frames</h3>
        <div id="grid-list">
            <PageLoader v-if="getLoading" :load="getLoading" />
            <template>
                <div class="products wrapper grid products-grid shop-frames-cat">
                    <PageLoader v-if="load" :load="load" />
                    <template v-else>
                        <ul class="products list items product-items">
                            <template v-for="(product, index) in updatedProductList">
                                <li :key="product.url + index" class="item product product-item">
                                    <CatalogCategoryListItem :product="product" />
                                </li>
                            </template>
                        </ul>
                    </template>
                </div>
            </template>
        </div>
        <template>
            <div class="c-product-slider-container featured-frames-slider-container">
                <carousel
                    :per-page="1"
                    :mouse-drag="false"
                    :touch-drag="true"
                    :navigation-enabled="true"
                    :pagination-enabled="false"
                    :center-mode="true"
                    navigation-prev-label="‹"
                    navigation-next-label="›"
                    class="c-homepage-slider"
                >
                    <slide v-for="product in updatedProductList" :key="product.index" class="c-homepage-slider-slide">
                        <div class="featured-frames-slider">
                            <CatalogCategoryListItem :product="product" />
                        </div>
                    </slide>
                </carousel>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import PageLoader from '../../components/loaders/PageLoader.vue'
import CatalogCategoryListItem from '../../components/catalog/product/list/Item.vue'

export default {
    name: 'FeaturedFramesView',
    components: {
        PageLoader,
        CatalogCategoryListItem,
        Carousel,
        Slide
    },
    data: () => {
        return {
            load: false
        }
    },
    computed: {
        ...mapGetters('partner', [
            'getLoading',
            'getProductsList'
        ]),
        updatedProductList () {
            const productList = this.getProductsList || []
            const shopframesurl = '/shop-frames'

            for (let i = 0; i < productList.length; i++) {
                const categoryurl = productList[i].category.replace(/\s+/g, '-').toLowerCase()
                const categoryurlUpdated = categoryurl.replace(/!/g, '')
                const skuUpdated = productList[i].sku === 'half-jacket-2.0-xl' ? 'half-jacket-2-0-xl' : productList[i].sku.replace(/\./g, '')


                productList[i].url = shopframesurl + '/' + categoryurlUpdated + '/' + skuUpdated
            }

            return productList
        }
    },
    created () {
        this.$store.commit('partner/setLoadProduct', 1)
        this.$store.commit('partner/setIsTitle', true)
        this.$store.commit('partner/setLoading', true)
        this.$store.commit('partner/setPagination', true)
        this.$store.dispatch('partner/fetchDefaultData')

        this.fetchCategoriesList()
        this.getCategories()
        // this.fetchCategoryProducts()
    },
    methods: {
        ...mapActions('partner', ['getCategories', 'fetchCategoriesList'])
    }
}
</script>
