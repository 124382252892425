var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "prescription-type form-access__header",
      staticStyle: { margin: "2rem" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "prescription-type-content" }, [
        _c("label", [
          _c("input", {
            attrs: { id: "contacts-test", name: "type", type: "radio" },
            domProps: { value: _vm.contacts },
            on: { change: _vm.setTypeContacts }
          }),
          _vm._v(" "),
          _c("span", [_vm._v("Contacts")])
        ]),
        _vm._v(" "),
        _c("label", [
          _c("input", {
            attrs: { id: _vm.glasses, name: "type", type: "radio" },
            domProps: { value: _vm.glasses },
            on: { change: _vm.setTypeGlasses }
          }),
          _vm._v(" "),
          _c("span", [_vm._v("Eye Glasses")])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "prescription-type-header" }, [
      _c("h3", [_vm._v("What is this prescription for? ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }