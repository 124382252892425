var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.history
    ? _c(
        "vue-modaltor",
        {
          staticClass: "prescription-history-container small",
          attrs: {
            visible: _vm.history,
            "default-width": "350px",
            "bg-overlay": "",
            "bg-panel": "",
            "close-scroll": false
          },
          on: { hide: _vm.hideModal }
        },
        [
          _c("h5", [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c("p", [_vm._v("Prescription Type: " + _vm._s(_vm.type))]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-history" }, [
            _c(
              "ul",
              [
                _c("li", { staticClass: "title" }, [_vm._v("Edited by")]),
                _vm._v(" "),
                _vm._l(_vm.rxHistory, function(rxName, index) {
                  return _c("li", { key: index + "-" + rxName.edited_by }, [
                    _vm._v(_vm._s(rxName.edited_by))
                  ])
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "ul",
              [
                _c("li", { staticClass: "title" }, [_vm._v("When")]),
                _vm._v(" "),
                _vm._l(_vm.rxHistory, function(when, index) {
                  return _c("li", { key: index + "-" + when.when }, [
                    _vm._v(_vm._s(when.when))
                  ])
                })
              ],
              2
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }