var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _vm.componentsCount && _vm.show
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.getLoad,
                expression: "!getLoad"
              }
            ],
            staticClass:
              "lensabl-plus-progress tabs-header-wrapper business-progress"
          },
          [
            _c(
              "ul",
              { ref: "progress", staticClass: "tabs-header" },
              [
                _vm._l(_vm.businessStepsList, function(tab, index) {
                  return [
                    tab
                      ? _c(
                          "li",
                          {
                            key: "tab-" + index,
                            staticClass: "tab group-plan-tab",
                            class: [tab.state]
                          },
                          [
                            _c("span", {
                              domProps: { textContent: _vm._s(tab.label) },
                              on: {
                                click: function($event) {
                                  return _vm.setActiveStep(index)
                                }
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "lensabl-plus-nav" }, [
      _c("div", { staticClass: "nav-container" }, [
        _c(
          "a",
          {
            staticClass: "nav__logo",
            attrs: { href: "/", "aria-label": "Link to the homepage" }
          },
          [
            _c("img", {
              staticClass: "plus-logo",
              attrs: {
                src: "/js/lensconfigurator/assets/lensabl-plus-wordmark.svg"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("ul", { staticClass: "business_link" }, [
          _c("li", { staticClass: "nav__main-li" }, [
            _c("span", [_vm._v("GROUP PLANS")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }