import {mapGetters} from 'vuex'
import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererFrames',
    extends: RendererOptions,
    computed: {
        ...mapGetters('productView', ['product_name']),
        renderer () {
            return this.product_name === 'Mick And Keith Midnight Ramble' ? 'Mick & Keith Midnight Ramble' : this.product_name
        }
    }
}
