<template>
    <SalesOrderViewPrescription
        v-if="statusApi"
        :order-id="$root.order_id"
        :item-id="$root.item_id"
        :prescriptions-type="$root.prescriptions_type"
    />
</template>

<script>
import App from '../../../../App.vue'
import SalesOrderViewPrescription from '../../../../components/sales/order/view/Prescription.vue'

export default {
    name: 'RouterSalesOrderViewPrescription',
    components: {
        SalesOrderViewPrescription
    },
    extends: App
}
</script>
