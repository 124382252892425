<template>
    <div v-if="showVTO()" class="virtual-try-on">
        <vue-modaltor
            :visible="isModalOpen"
            :default-width="'100%'"
            :bg-overlay="''"
            :bg-panel="''"
            :close-scroll="false"
            @hide="hideModal"
        >
            <div class="modal-vue-title">Virtual Try-On</div>
            <div id="fitmix-container" class="fitmix-container">
                <img src="./assets/WEBSITE_Fit-Mix-Compatability.jpg" alt="" />
            </div>

            <div class="swatch-actions">
                <div v-if="frameColors.length > 1" :class="{'transition-height' : showColors}" class="swatch-opt">
                    <ColorSwatches
                        :colors="frameColors"
                        :checked-index="checkedIndex"
                        :attribute-title="lensColors.length > 1 ? framesLabel : ''"
                        :option-item-class="'decorate bigger img'"
                        :add-label="addLabel"
                        class="vertical"
                        @[EVENT_CHANGE_CURRENT_COLOR]="changeColor"
                    />
                </div>
                <div v-if="lensColors.length > 1" :class="{'transition-width' : showColors}" class="swatch-opt">
                    <ColorSwatches
                        :colors="lensColors"
                        :checked-index="checkedLensIndex"
                        :attribute-title="'Lens Colors'"
                        :option-item-class="'decorate bigger img'"
                        class="horizontal"
                        @[EVENT_CHANGE_CURRENT_COLOR]="changeLensColor"
                    />
                </div>

                <div v-if="lensColors.length > 1 || frameColors.length > 1" :class="{'active': showColors}" class="swatch-color" @click="showColors = !showColors">
                    <SwatchColorIcon class="icon" width="40" height="40" view-box="0 0 30 30" />
                    <span class="label" v-text="productCategoryName !== 'sun' ? framesLabel : 'Customize'"></span>
                </div>
            </div>
        </vue-modaltor>

        <BaseButton :class="{'button--copy-solid-dark': isDesktop, 'button--copy-solid-white': !isDesktop}" :text="'Virtual try-on'" @click.native="showModal()">
            <span class="icon-eye"></span>
            <span>virtual try-on</span>
        </BaseButton>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import ColorSwatches from '../ColorSwatches.vue'
import SwatchColorIcon from './assets/colors.svg'
import BaseButton from '../../../../base/BaseButton.vue'
import {
    EVENT_CHANGE_CURRENT_COLOR,
    EVENT_CHANGE_CURRENT_COLOR_LENS
} from '../../../../../app/utils/constants'

export default {
    name: 'VirtualTryOn',
    components: {
        ColorSwatches,
        BaseButton,
        SwatchColorIcon
    },
    props: {
        currentColorItem: {
            type: [Object, Array],
            default: () => ({
                color: '',
                frames_SKU: '',
                gallery: [],
                price: '',
                size: false,
                vto_sku: ''
            })
        },
        productCategoryName: {
            type: String,
            default: ''
        },
        frameColors: {
            type: [Object, Array],
            default: () => {}
        },
        lensColors: {
            type: [Object, Array],
            default: () => {}
        },
        checkedIndex: {
            type: [String, Number],
            default: ''
        },
        checkedLensIndex: {
            type: [String, Number],
            default: ''
        },
        framesLabel: {
            type: [String, Number],
            default: 'Frames Colors'
        }
    },
    data: () => {
        return {
            isModalOpen: false,
            fitmixWidget: false,
            showColors: false,
            addLabel: false,
            currentVto: false,
            EVENT_CHANGE_CURRENT_COLOR: EVENT_CHANGE_CURRENT_COLOR,
            EVENT_CHANGE_CURRENT_COLOR_LENS: EVENT_CHANGE_CURRENT_COLOR_LENS
        }
    },
    computed: {
        ...mapGetters('productView', ['css_param']),
        ...mapGetters('storeView', ['fitmixApiKey', 'base_url']),
        ...mapGetters('deviceProperty', ['isDesktop'])
    },
    watch: {
        checkedIndex () {
            this.selectFrame()
        },
        checkedLensIndex () {
            this.selectFrame()
        }
    },
    created () {
        if (this.productCategoryName === 'bose') {
            this.addLabel = true
        }
    },
    methods: {
        showModal () {
            if (this.fitmixWidget) {
                this.fitmixWidget.resumeStream()
            }
            this.fitmixWidget = false
            this.selectFrame()
            this.isModalOpen = true
        },
        hideModal () {
            if (this.fitmixWidget) {
                this.fitmixWidget.stopStream()
            }
            this.isModalOpen = false
        },
        tryOnWidget (vtoSku) {
            if (this.productCategoryName === 'bose' && window.ga) {
                window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'VTO',
                    eventAction: 'click',
                    eventLabel: 'Try On'
                })
            }

            if (window.FitMix && vtoSku && document.getElementById('fitmix-container')) {
                const widget = window.FitMix.createWidget('fitmix-container', {
                    apiKey: this.fitmixApiKey,
                    css: this.base_url + 'configurator/src/components/category/lensabl_frames/frames_view/assets/widget.css',
                    lang: 'en',
                    sku: vtoSku
                }, () => {
                    this.setLensColor(widget)
                })
                return widget
            }
            return false
        },
        showVTO () {
            if (!this.currentColorItem) {
                return false
            }
            const vtoSku = this.currentColorItem.vto_sku
            if (!vtoSku) {
                return false
            }
            return true
        },
        selectFrame () {
            if (!this.currentColorItem) {
                return false
            }
            const vtoSku = this.currentColorItem.vto_sku
            if (!vtoSku) {
                return false
            }

            if (!this.fitmixWidget || !this.currentVto) {
                this.currentVto = vtoSku
                this.fitmixWidget = this.tryOnWidget(vtoSku)
            } else {
                if (this.currentVto !== vtoSku) {
                    this.fitmixWidget.setFrame(vtoSku)
                    this.currentVto = vtoSku
                }
                setTimeout(() => this.setLensColor(this.fitmixWidget), 2 * 1000)
            }
            return true
        },
        setLensColor (widget) {
            // widget.restoreLensesInitialMaterial()
            if (this.productCategoryName === 'night') {
                widget.setLensesInTintedMode(0.845, 0.596, 0.369, 0.7)
            }

            if (this.productCategoryName === 'optical') {
                widget.restoreLensesInitialMaterial()
                widget.setLensesAntireflection(true)
            }
            if (this.productCategoryName === 'sun' || this.productCategoryName === 'bose') {
                const colorLens = this.lensColors[this.checkedLensIndex] || {}
                const arg = colorLens.value

                if (arg) {
                    widget.setLensesInTintedMode(...arg)
                }
            }
        },
        changeColor (index) {
            this.$emit(EVENT_CHANGE_CURRENT_COLOR, index)
        },
        changeLensColor (index) {
            this.$emit(EVENT_CHANGE_CURRENT_COLOR_LENS, index)
        }
    }
}
</script>
