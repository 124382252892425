var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.memberShipEdited
    ? _c(
        "div",
        [
          _c("div", [
            _c(
              "table",
              { staticClass: "form-list", attrs: { cellspacing: "0" } },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "label" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("Frame benefit redeemed")
                      ]),
                      _vm._v(" "),
                      _c("strong", {
                        staticClass: "value",
                        domProps: { textContent: _vm._s(_vm.frameItemRedeemed) }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "label" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("Opting out of frame benefit")
                      ]),
                      _vm._v(" "),
                      _c("strong", {
                        staticClass: "value",
                        domProps: {
                          textContent: _vm._s(_vm.voucherItemRedeemed)
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "label" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(
                          "Opting out of frame benefit by uploading receipt"
                        )
                      ]),
                      _vm._v(" "),
                      _c("strong", {
                        staticClass: "value",
                        domProps: {
                          textContent: _vm._s(_vm.uploadedReceiptItemRedeemed)
                        }
                      })
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.hasFile
              ? _c(
                  "table",
                  {
                    staticClass: "form-list eye-exam",
                    attrs: { cellspacing: "0" }
                  },
                  [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "label" }, [
                          _vm._v("Reciept Uploaded")
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "value", attrs: { width: "20%" } },
                          [
                            _c("div", { staticClass: "upload-prescription" }, [
                              _c("div", { staticClass: "upload-component" }, [
                                _vm.isUploading
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "uploading-progress" },
                                        [_vm._v("Uploading...")]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasFile
                                  ? _c(
                                      "div",
                                      { staticClass: "uploaded-file" },
                                      [
                                        _vm.file_download_url
                                          ? _c("a", {
                                              directives: [
                                                {
                                                  name: "dompurify-html",
                                                  rawName: "v-dompurify-html",
                                                  value: _vm.fileData.fileName,
                                                  expression:
                                                    "fileData.fileName"
                                                }
                                              ],
                                              attrs: {
                                                href: _vm.file_download_url,
                                                download: ""
                                              }
                                            })
                                          : _c("span", {
                                              directives: [
                                                {
                                                  name: "dompurify-html",
                                                  rawName: "v-dompurify-html",
                                                  value: _vm.fileData.fileName,
                                                  expression:
                                                    "fileData.fileName"
                                                }
                                              ]
                                            })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showError
                                  ? _c("div", { staticClass: "error-hint" }, [
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "dompurify-html",
                                            rawName: "v-dompurify-html",
                                            value: _vm.errorHint,
                                            expression: "errorHint"
                                          }
                                        ]
                                      })
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "label" }, [
                          _c(
                            "div",
                            {
                              staticClass: "file-input-box",
                              class: {
                                "invalid-form": _vm.showInvalidFormBorder
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "action file-input-button",
                                  attrs: {
                                    href: "javascript:void(0)",
                                    type: "button",
                                    title: "Continue"
                                  }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      class: { disabled: !_vm.hasNoFile },
                                      attrs: { disabled: !_vm.hasNoFile }
                                    },
                                    [_vm._v("Upload Reciept")]
                                  ),
                                  _vm._v(" "),
                                  _vm.hasNoFile
                                    ? _c("input", {
                                        attrs: {
                                          type: "file",
                                          name: "prescription",
                                          accept: "image/*,application/pdf"
                                        },
                                        on: { change: _vm.previewFiles }
                                      })
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "approve",
                                  class: { disabled: _vm.approved },
                                  attrs: { disabled: _vm.approved },
                                  on: { click: _vm.approvedReceiptRewards }
                                },
                                [_vm._v("Approve (Sends Gift Card)")]
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("hr", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.approved && _vm.hasFile,
                  expression: "!approved && hasFile"
                }
              ]
            }),
            _vm._v(" "),
            _c(
              "table",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.approved && _vm.hasFile,
                    expression: "!approved && hasFile"
                  }
                ],
                staticClass: "form-list eye-exam",
                attrs: { cellspacing: "0" }
              },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "value" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.textMessage,
                            expression: "textMessage"
                          }
                        ],
                        attrs: { rows: "5", placeholder: "Message..." },
                        domProps: { value: _vm.textMessage },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.textMessage = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "scope-label" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.rejection,
                              expression: "rejection"
                            }
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.rejection)
                              ? _vm._i(_vm.rejection, null) > -1
                              : _vm.rejection
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.rejection,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.rejection = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.rejection = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.rejection = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Notify customer of rejection")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "scope-label" }, [
                      _c(
                        "button",
                        {
                          staticClass: "submit",
                          on: { click: _vm.submitMessage }
                        },
                        [_vm._v("Submit")]
                      )
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("hr", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasFile,
                  expression: "hasFile"
                }
              ]
            }),
            _vm._v(" "),
            _vm.messages.length
              ? _c(
                  "table",
                  {
                    staticClass: "form-list eye-exam",
                    attrs: { cellspacing: "0" }
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.messages, function(message, index) {
                        return _c(
                          "tr",
                          { key: "message-" + index, staticClass: "tr-line" },
                          [
                            _c("td", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: message.message,
                                  expression: "message.message"
                                }
                              ],
                              staticClass: "value"
                            }),
                            _vm._v(" "),
                            _c("td", { staticClass: "scope-label" }, [
                              _c("strong", {
                                domProps: {
                                  textContent: _vm._s(message.status)
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "scope-label" }, [
                              _c("strong", {
                                domProps: {
                                  textContent: _vm._s(
                                    "by " +
                                      message.admin_firstname +
                                      " " +
                                      message.admin_lastname
                                  )
                                }
                              }),
                              _vm._v(" "),
                              _c("small", {
                                domProps: {
                                  textContent: _vm._s(
                                    "(" + message.admin_email + ")"
                                  )
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", {
                              staticClass: "scope-label",
                              domProps: {
                                textContent: _vm._s(message.created_at)
                              }
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.loader || !_vm.dataLoaded ? _c("BaseLoader") : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("th", [_vm._v("History")]),
      _vm._v(" "),
      _c("th"),
      _vm._v(" "),
      _c("th"),
      _vm._v(" "),
      _c("th")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }