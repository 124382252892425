import Vue from 'vue'
import _cloneDeep from 'lodash/cloneDeep'
import _first from 'lodash/first'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import axios from 'axios'
import * as abstract from '../../../../../../../../storage/abstract'

export const state = () => ({
    ...abstract.state(),
    list: {},
    sequence: [],
    save_rx_item_url: ''
})

export const getters = {
    sequence: state => state.sequence,
    save_rx_item_url: state => state.save_rx_item_url,
    isNextItem: (state, getters) => id => !!getters.sequence.slice(0, getters.sequence.indexOf(id)).length,
    firstItemId: (state, getters) => _first(getters.sequence),
    getPrevItemId: (state, getters) => id => {
        let prevId = null
        if (getters.isNextItem(id)) {
            const prevItems = _filter(getters.sequence.slice(0, getters.sequence.indexOf(id)), item => item ? item : false).slice(-1)
            prevId = prevItems.length ? prevItems[0] : prevId
        }
        return prevId
    },
    getItem: (state, getters, rootState, rootGetters) => id => {
        if (!state.list[id] && rootGetters.rootState.hasOwnProperty('sales')) {
            const item = _find(rootGetters.rootState.sales.order.items, {'item_id': id})
            Vue.set(state.list, id, _cloneDeep(item))
        }
        return state.list[id]
    },
    getItemPrescriptions: (state, getters) => item_id => {
        const item = getters.getItem[item_id]
        return item ? item.prescriptions : {}
    },
    getItemPrescription: (state, getters, rootState, rootGetters) => ({item_id, prescriptions_type}) => {
        const prescriptions = getters.getItemPrescriptions(item_id)
        if (!prescriptions[prescriptions_type] && rootGetters.rootState.hasOwnProperty('sales')) {
            const item = rootGetters.rootState.sales.order.items[item_id]
            if (item) {
                Vue.set(prescriptions, prescriptions_type, _cloneDeep(item.prescriptions[prescriptions_type]))
            }
        }
        return prescriptions[prescriptions_type]
    }
}

export const mutations = {
    ...abstract.mutations
}

export const actions = {
    ...abstract.actions,
    fetchDefaultDataBefore: ({commit, rootGetters}) => {
        let defaultData = _cloneDeep(rootGetters.rootState)
        if (defaultData.hasOwnProperty('sales')) {
            defaultData = Object.assign(defaultData, defaultData.sales.order)
        }
        commit('UPDATE_STATE_DATA', defaultData)
    },
    saveRxItemData: ({ dispatch, getters, rootGetters }, itemData = {}) => {
        return new Promise((resolve, reject) => {
            axios.post(`${getters.save_rx_item_url}?isAjax=true&itemid=${itemData['item_id']}&form_key=${rootGetters['storeView/form_key']}`, itemData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response)
                    console.log(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
