<template>
    <div v-if="dataLoaded" class="reward-loader">
        <Loader />
    </div>
    <div v-else class="plus-account_wrappper">
        <PlusDashboard v-if="plusMember" />
        <BusinessDashboard v-else-if="isBusinessMember" />
        <div v-else class="non-plus-wrapper">
            <NonPlus />
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import PlusDashboard from '../lensablplus/customer/PlusDashboard.vue'
import NonPlus from '../lensablplus/customer/Non-LensablPlus.vue'
import gsap from '../../components/lensablplus/storage/lensablplus/gsap'
import BusinessDashboard from '../lensablplus/customer/BusinessDashboard.vue'
import lensablplusBusiness from '../lensablplus/storage/lensablplus/business'
import Loader from '../loaders/Loader.vue'

export default {
    name: 'LensablPlusCustomerAccount',
    components: {
        NonPlus,
        PlusDashboard,
        BusinessDashboard,
        Loader
    },
    data: () => ({
        dataLoaded: false
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablplus_customer', ['customerName', 'plusMember', 'isContactsInPlan', 'paidUp', 'isBusinessMember', 'isFreeMembership'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['gsap/']) {
            this.$store.registerModule(['gsap'], gsap(this.$store), {preserveState: !!this.$store.state['gsap']})
        }
        if (!this.$store._modulesNamespaceMap['lensablplus_business/']) {
            this.$store.registerModule('lensablplus_business', lensablplusBusiness(this.$store), {preserveState: !!this.$store.state['lensablplus_business']})
        }

        this.$store.dispatch('lensablplus_business/fetchDefaultData')
    },
    created: async function () {
        this.dataLoaded = true
        await this.$store.dispatch('lensablplus_customer/fetchData')
        await this.$store.dispatch('lensablplus_customer/getMembershipConfigs')
        await this.$store.dispatch('lensablplus_customer/getBtnTitlesStyles')
        await this.$store.dispatch('lensablplus_customer/configsGiftcard')
        this.$store.dispatch('lensablplus_customer/fetchTrialExpiringData')
            .finally(() => {
                this.dataLoaded = false
                this.$nextTick(() => {
                    this.fetchPreviousLoad()
                })
            })
    },
    methods: {
        ...mapActions('gsap', ['fetchPreviousLoad', 'fetchMobileFirstTime', 'fetchDesktopFirstTime', 'stopDesktop', 'stopMobile'])
    }
}
</script>
