<template>
    <div class="scg-container">
        <section class="scg-container-banner">
            <div class="scg-container-banner-content">
                <h1>Lens Color Guide</h1>
                <a href="/choose-my-lenses" title="Lens Replacement">
                    <div class="button button--copy-solid scg-shopnow-btn">
                        <div class="button__container">SHOP NOW</div>
                    </div>
                </a>
            </div>
        </section>
        <div class="scg-container-colors-container">
            <div class="scg-container-colors scg-color-standard">
                <h2 class="scg-colors-header">Standards Colors</h2>
                <template v-for="color in standardColors">
                    <section :key="color.color" :class="`scg-colors-row scg-${color.color}-row`">
                        <div class="scg-colors-row_title-row">
                            <h2 class="scg-colors-header">{{ color.color }}</h2>
                            <img :src="color.img" :alt="color.alt" class="scg-title-img" />
                        </div>
                        <div :class="`scg-colors-row_lens-img-row ${color.color}-lens-img-row`">
                            <div v-for="(image, index) in color.images" :key="index" class="scg-lens-item">
                                <img :src="image.src" :alt="image.alt" class="scg-lens-img" @click="toggleModal" />
                                <p v-dompurify-html="image.spanTitle"></p>
                            </div>
                        </div>
                    </section>
                </template>
            </div>
            <div class="scg-container-colors scg-color-fashion">
                <h2 class="scg-colors-header">Fashion Colors</h2>
                <template v-for="color in fashionColors">
                    <section :key="color.color" :class="`scg-colors-row scg-${color.color}-row`">
                        <div class="scg-colors-row_title-row">
                            <h2 class="scg-colors-header">{{ color.color }}</h2>
                            <img :src="color.img" :alt="color.alt" class="scg-title-img" />
                        </div>
                        <div class="scg-colors-row_lens-img-row fashion-lens-img-row">
                            <div v-for="(image, index) in color.images" :key="index" class="scg-lens-item">
                                <img :src="image.src" class="scg-lens-img" :alt="image.alt" @click="toggleModal" />
                                <p v-dompurify-html="image.spanTitle"></p>
                            </div>
                        </div>
                    </section>
                </template>
            </div>
            <div class="scg-container-colors scg-container-mirror">
                <h2 class="scg-colors-header">Mirror</h2>
                <template v-for="color in mirrorColors">
                    <section :key="color.color" class="scg-colors-row scg-mirror-row">
                        <div class="scg-colors-row_lens-img-row">
                            <div v-for="(image, index) in color.images" :key="index" class="scg-lens-item">
                                <img :src="image.src" class="scg-lens-img" :alt="image.alt" @click="toggleModal" />
                                <p v-dompurify-html="image.spanTitle"></p>
                            </div>
                        </div>
                    </section>
                </template>
            </div>
            <div class="scg-container-colors scg-container-transitions">
                <h2 class="scg-colors-header">Transitions</h2>
                <template v-for="color in transitionsColors">
                    <section :key="color.color" class="scg-colors-row scg-transitions-row">
                        <div class="scg-colors-row_lens-img-row">
                            <div v-for="(image, index) in color.images" :key="index" class="scg-lens-item">
                                <img :src="image.src" :alt="image.alt" class="scg-lens-img" @click="toggleModal" />
                                <p v-dompurify-html="image.spanTitle"></p>
                            </div>
                        </div>
                    </section>
                </template>
            </div>
            <Modal v-if="showModal" @close="showModal = false">
                <div class="scg-modal-content">
                    <img :src="selectedModalImage.src" class="scg-lens-img" />
                    <span>{{ selectedModalImage.title }}</span>
                </div>
            </Modal>
        </div>
    </div>
</template>

<script>
import Modal from './Modal.vue'
import {
    STANDARD_COLORS,
    FASHION_COLORS,
    MIRROR_COLORS,
    TRANSITIONS_COLORS
} from '../../app/common/constants/suncolorguide'

export default {
    name: 'SunColorGuideLanding',
    components: {
        Modal
    },
    data: () => ({
        showModal: false,
        selectedModalImage: {},
        standardColors: STANDARD_COLORS,
        fashionColors: FASHION_COLORS,
        mirrorColors: MIRROR_COLORS,
        transitionsColors: TRANSITIONS_COLORS
    }),
    methods: {
        toggleModal (event) {
            this.selectedModalImage.src = event.target.src
            this.selectedModalImage.title = event.target.alt
            this.showModal = !this.showModal
        }
    }
}
</script>
