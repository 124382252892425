<template>
    <div class="color-selector-container" :class="currentSection.id">
        <div v-if="(colorImg) && (lensPrefix !== 'transitions')" class="lens-img">
            <img :src="colorImg" :alt="color.value" :class="color.value" />
        </div>
        <div :class="lensPrefix === 'transitions' ? 'transitions-color-picker-list' : 'color-selector'">
            <div class="lens-type">
                <div class="color-picker">
                    <div class="color-picker-toggle">
                        <p v-dompurify-html="currentSection.hint" class="color-selector-hint"></p>
                        <div v-if="currentSection.description" class="transitions-description">
                            <p v-dompurify-html="currentSection.description"></p>
                            <span v-if="(chosenSection === 'transitions-vantage' || chosenSection === 'transitions-drivewear')&& isBoseLanding"><i>*Not available with Tempo frame</i></span>
                        </div>
                        <div v-if="hasMatchColorSection" class="range-label" @click="toggleSelectColor">
                            <div :class="{active: !valueMatchColor}" class="option-checkbox-container">
                                <div class="option-checkbox"></div>
                            </div>
                            <strong>Select Lens Color</strong>
                        </div>
                        <div v-else class="range-label wo-match">
                            <div :class="{active: !valueMatchColor}" class="option-checkbox-container">
                                <div class="option-checkbox"></div>
                            </div>
                            <strong v-if="chosenSection === 'mirror'">Select Mirror Color</strong>
                            <strong v-else>Select Lens Color</strong>
                        </div>

                        <ul :class="lensPrefix === 'transitions' ? 'transitions-color-picker-list' : 'color-picker-list'">
                            <template v-for="colour in colors">
                                <li
                                    v-if="!colour.hidden"
                                    :key="colour.id"
                                    :title="colour.color_name || colour.label"
                                    :class="[
                                        lensPrefix === 'transitions' ? 'transitions-color-clicker' : 'color-clicker',
                                        {
                                            active: isActiveColor(colour.id),
                                            'disabled-color': colour.hidden,
                                            'has-description': colour.description,
                                            [colour.id]: colour.id,
                                            [chosenSection + '-list-item']: chosenSection
                                        }
                                    ]"
                                    @click="chooseColor(colour.id)"
                                >
                                    <div class="content">
                                        <img v-if="lensPrefix === 'transitions'" :src="colour.front[0].thumbnail" :alt="colour.color_name || colour.label" :class="lensPrefix + '-img'" />
                                        <span v-dompurify-html="colour.color_name || colour.label" class="label"></span>
                                        <HelpBubble v-if="colour.component_help" :data="colour.component_help" />
                                    </div>
                                    <div v-if="colour.description" v-dompurify-html="colour.description" class="description"></div>
                                </li>
                            </template>
                        </ul>

                        <div v-if="hasAdditionSection && !valueMatchColor" class="color-selector-tint">
                            <div class="tint-level">
                                <span>Tint Level</span>
                            </div>
                            <div class="slider-wrapper">
                                <ul class="range-labels">
                                    <li :class="{'third-level active': color.level === 3}" data-slider="3" @click="sliderValue(3)">
                                        <span class="range-label">Level 3*</span>
                                        <p>(80%)</p>
                                        <div class="tint-hint">
                                            <span>*Standard Sunglass tint level</span>
                                        </div>
                                    </li>
                                    <li :class="{'second-level active': color.level === 2}" data-slider="2" @click="sliderValue(2)">
                                        <span class="range-label">Level 2</span>
                                        <p>(50%)</p>
                                    </li>
                                    <li :class="{'first-level active': color.level === 1}" data-slider="1" @click="sliderValue(1)">
                                        <span class="range-label">Level 1</span>
                                        <p>(20%)</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="hasMatchColorSection" class="color-selector-tint match-color-section">
                            <div class="range-label match-color-label" @click="toggleMatchColor()">
                                <div :class="{active: valueMatchColor}" class="option-checkbox-container">
                                    <div class="option-checkbox"></div>
                                </div>
                                <strong>Match Current Lens Color </strong> <span>(+$10)</span>
                            </div>
                            <div class="color-match-disclaimer">
                                <p>*We are unable to match mirror coatings on lenses</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _first from 'lodash/first'

import {
    MATCH_COLOR_ID
} from '../../../../app/common/gradients_constants'
import {
    COLOR_SECTION_INTERFACE
} from '../constants'

import ColorViewAbstract from './abstract'
import HelpBubble from '../../../help_bubble/HelpBubble.vue'
import {mapGetters} from 'vuex'

export default {
    name: 'StandardColorView',
    components: {
        HelpBubble
    },
    mixins: [ColorViewAbstract],
    props: {
        sections: {
            type: Array,
            default: () => ([])
        },
        currentSection: {
            type: Object,
            default: () => ({
                ...COLOR_SECTION_INTERFACE
            }),
            required: true
        },
        lensPrefix: {
            type: String,
            default: () => ('')
        }
    },
    computed: {
        ...mapGetters('productView', ['isBoseLanding']),
        hasAdditionSection () {
            return !!(_first(this.currentSection.colors) || {}).level || false
        },
        hasMatchColorSection () {
            return !!this.currentSection.has_match_color
        },
        valueMatchColor () {
            const color = this.color.value || ''
            return color.indexOf(MATCH_COLOR_ID) !== -1
        },
        colorImg () {
            const level = this.color.level
            const colorObj = this.getColorObject(this.color.value)
            const imgArr = colorObj[this.color.position]
            if (!imgArr) {
                return false
            }
            let img
            if (level) {
                const imgIndex = level - 1
                img = imgArr[imgIndex] ? imgArr[imgIndex] : false
            }
            if (!img) {
                img = _first(imgArr)
            }
            if (img) {
                return img.thumbnail
            }
            return false
        }
    },
    methods: {
        toggleMatchColor () {
            const section = this.currentSection
            if (!this.valueMatchColor) {
                this.updateColor({
                    value: MATCH_COLOR_ID + '-' + section.id,
                    level: false
                })
            } else {
                this.chooseSectionDefault(section)
            }
        },
        toggleSelectColor () {
            this.updateColor({
                value: (_first(this.currentSection.colors) || {}).id || '',
                level: 3
            })
        },
        isActivePosition (position) {
            return position === this.color.position
        },
        isActiveColor (color) {
            return color === this.color.value
        },
        sliderValue (level) {
            this.changeLevel(level)
        },
        changeLevel (level) {
            this.updateColor({level: level})
        },
        getPrevSection (section) {
            const sectionId = this.getSectionByOffset(section, -1)
            return this.getSectionById(sectionId)
        },
        getNextSection (section) {
            const sectionId = this.getSectionByOffset(section, 1)
            return this.getSectionById(sectionId)
        },
        getSectionByOffset (section, offset) {
            const id = section.id ? section.id : section
            const ids = this.sections.map((color) => color.id)
            let index = ids.indexOf(id)
            if (index === -1) {
                return false
            }
            index = index + offset
            return ids[index] || false
        },
        getSectionById (sectionId) {
            return this.sections.find((section) => section.id === sectionId)
        }
    }
}
</script>
