var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.category.class }, [
    _c(
      "a",
      {
        attrs: { href: _vm.category.url, title: _vm.category.name },
        on: { click: _vm.nextCategory }
      },
      [
        _vm.category.class === "hobieeyewearsunglasses"
          ? _c("img", {
              attrs: {
                src: "/js/lensconfigurator/assets/Hobie-Baja.jpg",
                width: "358",
                height: "358",
                alt: ""
              }
            })
          : _vm.category.class === "hobieeyewearoptical"
          ? _c("img", {
              attrs: {
                src: "/js/lensconfigurator/assets/Hobie-HO4509.jpg",
                width: "358",
                height: "358",
                alt: ""
              }
            })
          : _c("img", {
              attrs: {
                src: _vm.categoryImage,
                width: "358",
                height: "358",
                alt: ""
              }
            }),
        _vm._v(" "),
        _c("div", { staticClass: "header-main" }, [
          _c("span", [_vm._v(_vm._s(_vm.getName))]),
          _vm._v(" "),
          _c("h3", { staticClass: "product-name" }, [
            _vm._v(_vm._s(_vm.categoryName))
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }