var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "c-product-content-bg" }, [
        _c("div", { staticClass: "c-product-content-container" }, [
          _c(
            "div",
            { staticClass: "c-product-content-half" },
            [
              _c("h1", { staticClass: "c-product-title" }, [
                _c("div", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.product_name,
                      expression: "product_name"
                    }
                  ]
                })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "c-product-price" }, [
                _c(
                  "span",
                  {
                    staticClass: "c-product-price--main",
                    class: { subscribed: _vm.getSubscribed === "yes" }
                  },
                  [_vm._v("$" + _vm._s(_vm.getContactsPrice))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "c-product-price--subscription",
                    class: { subscribed: _vm.getSubscribed === "yes" }
                  },
                  [_vm._v("$" + _vm._s(_vm.getContactsSubscriptionPrice))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "c-product-price--text" }, [
                  _vm._v("per box")
                ]),
                _vm._v(" | " + _vm._s(_vm.lens_number) + " lenses per box")
              ]),
              _vm._v(" "),
              _vm.valid
                ? _c("p", { staticClass: "c-product-rebate" }, [
                    _vm._v(
                      "Buy " + _vm._s(_vm.rebate_number) + " boxes and save "
                    ),
                    _c("span", [_vm._v(_vm._s(_vm.rebate_discount))]),
                    _vm._v(" "),
                    _c("a", { attrs: { href: "/alcon-rebate" } }, [
                      _vm._v("after rebate.")
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("MobileGallery", { attrs: { name: _vm.product_name } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "supply-amount_container" },
                _vm._l(_vm.deliveryFrequency["amount"], function(
                  amount,
                  index
                ) {
                  return _c(
                    "label",
                    {
                      key: index,
                      staticClass: "supply-amount",
                      class: {
                        active: _vm.active_el.active === amount,
                        disabled: _vm.getSubscribed === "yes"
                      },
                      attrs: { for: amount }
                    },
                    [
                      _c("input", {
                        ref: "supply_amount",
                        refInFor: true,
                        attrs: {
                          id: amount,
                          title: index + "supply",
                          disabled:
                            _vm.getSubscribed === "yes" || _vm.supplyDisable,
                          type: "radio",
                          name: "supply_amount"
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateQuantity(amount)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "supply-label-container" }, [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(index.replace("-", " ")) + " Supply"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          amount > 1
                            ? _c("span", [
                                _vm._v(_vm._s(amount) + " boxes per eye")
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(amount) + " box per eye")
                              ])
                        ])
                      ])
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "c-product-lr" }, [
                _c("div", { staticClass: "c-product-lr-right" }, [
                  _c(
                    "label",
                    {
                      staticClass: "c-product-lr-label",
                      class: { disabled: _vm.isRightDisabled }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "c-product-lr-checkbox-container",
                          class: {
                            active: _vm.rightEyeChecked && !_vm.isRightDisabled
                          }
                        },
                        [
                          _c("div", { staticClass: "c-product-lr-checkbox" }, [
                            _c("input", {
                              attrs: {
                                disabled: _vm.isRightDisabled,
                                type: "checkbox",
                                title: "Right Eye (OD)"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.checkRadioButton("right")
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Right Eye (OD)")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-product-lr-select" }, [
                    _c(
                      "select",
                      {
                        attrs: {
                          id: "right",
                          disabled: _vm.isRightDisabled,
                          name: "right"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onBoxDropdown($event, "right")
                          }
                        }
                      },
                      _vm._l(25, function(i) {
                        return _c("option", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.isRightDisabled
                                ? _vm.getEyeData.right.brand.substring(0, 7) +
                                  "..."
                                : i,
                              expression:
                                "isRightDisabled ? getEyeData.right.brand.substring(0, 7) + '...' : i "
                            }
                          ],
                          key: i,
                          domProps: {
                            selected: i === parseInt(_vm.default_box_quantity),
                            value: i
                          }
                        })
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "right" } }, [
                      _vm._v("Number of Boxes")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "c-product-lr-left" }, [
                  _c(
                    "label",
                    {
                      staticClass: "c-product-lr-label",
                      class: { disabled: _vm.isLeftDisabled }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "c-product-lr-checkbox-container",
                          class: {
                            active: _vm.leftEyeChecked && !_vm.isLeftDisabled
                          }
                        },
                        [
                          _c("div", { staticClass: "c-product-lr-checkbox" }, [
                            _c("input", {
                              attrs: {
                                disabled: _vm.isLeftDisabled,
                                type: "checkbox",
                                title: "Left Eye (OD)"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.checkRadioButton("left")
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Left Eye (OS)")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "c-product-lr-select" }, [
                    _c(
                      "select",
                      {
                        attrs: {
                          id: "left",
                          disabled: _vm.isLeftDisabled,
                          name: "left"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onBoxDropdown($event, "left")
                          }
                        }
                      },
                      _vm._l(25, function(i) {
                        return _c("option", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.isLeftDisabled
                                ? _vm.getEyeData.left.brand.substring(0, 7) +
                                  "..."
                                : i,
                              expression:
                                "isLeftDisabled ? getEyeData.left.brand.substring(0, 7) + '...' : i"
                            }
                          ],
                          key: i,
                          domProps: {
                            value: i,
                            selected: i === parseInt(_vm.default_box_quantity)
                          }
                        })
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "right" } }, [
                      _vm._v("Number of Boxes")
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              (_vm.colors[0] || []).length !== 0
                ? _c("div", { staticClass: "c-product-lr-color" }, [
                    _c("span", { staticClass: "c-product-color-label" }, [
                      _vm._v("Select Color")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "c-product-lr-select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedColor,
                              expression: "selectedColor"
                            }
                          ],
                          class: { error: _vm.showError === true },
                          attrs: { id: "color", name: "color" },
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedColor = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.selectColor()
                              }
                            ]
                          }
                        },
                        _vm._l(_vm.colors, function(color) {
                          return _c(
                            "option",
                            { key: color, domProps: { value: color } },
                            [_vm._v(_vm._s(color))]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "color" } }, [
                        _vm._v("Color")
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "c-product-continue button button--copy-solid" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "button__container",
                      on: {
                        click: function($event) {
                          return _vm.confirmSelection()
                        }
                      }
                    },
                    [_vm._v("Continue")]
                  )
                ]
              ),
              _vm._v(" "),
              !_vm.plusMember
                ? _c("div", { staticClass: "c-product-lplus-ad-block" }, [
                    _c("p", [
                      _c("b", [_vm._v("Save big")]),
                      _vm._v(
                        " on an annual supply by joining Lensabl+\n                        "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "lplus-ad-block_container",
                          on: { click: _vm.openPlusAdModal }
                        },
                        [
                          _c(
                            "a",
                            { attrs: { href: _vm.adLink } },
                            [
                              _c(
                                "span",
                                { staticClass: "c-product-lplus-ad-link" },
                                [_vm._v("Learn More")]
                              ),
                              _vm._v(" "),
                              _c("CatalogContactsProductViewHelpComponent", {
                                attrs: { "show-modal-ad": _vm.showModalAd }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-product-content-half" },
            [_c("DesktopGallery", { attrs: { name: _vm.product_name } })],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("Guarantee"),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "Modal",
            {
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("span", { staticClass: "modal-header" }, [
                _vm._v("Do you need a different brand for your left/right eye?")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-product-button-container" }, [
                _c("div", { staticClass: "button button--copy-solid" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button__container",
                      on: {
                        click: function($event) {
                          return _vm.selectYes()
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "button button--copy-solid button--copy-solid-grey"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "button__container",
                        on: {
                          click: function($event) {
                            return _vm.selectNo()
                          }
                        }
                      },
                      [_vm._v("No")]
                    )
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("CatalogContactsProductViewDescription", {
        staticClass: "c-product-secondary-block"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }