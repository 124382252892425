import {PLACE_STEP_HEADER, PLACE_SUMMARY} from '../../../app/utils/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'RendereRendererAbstractPrescription',
    props: ['step', 'place'],
    computed: {
        ...mapGetters('values', ['values']),
        hasPrescriptionName () {
            return this.values.prescriptionValues.name
        },
        prescriptionName () {
            return this.values.prescriptionValues.name || ' '
        },
        isSummary () {
            return this.place === PLACE_SUMMARY
        },
        isStepHeader () {
            return this.place === PLACE_STEP_HEADER
        }
    }
}
