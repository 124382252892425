var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ],
      class: _vm.id
    },
    [
      _vm.config.title
        ? _c("h2", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.title,
                expression: "config.title"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "contact", attrs: { id: "contact_address_fields" } },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "hor-scroll" }, [
              _c(
                "table",
                { staticClass: "form-list", attrs: { cellspacing: "0" } },
                [
                  _c(
                    "tbody",
                    _vm._l(_vm.contact, function(template, index) {
                      return _c(
                        "tr",
                        { key: "row-" + index + "-" + template.label },
                        [
                          _c("td", [
                            _c(
                              "label",
                              { attrs: { for: "addressTemplateData[0].name" } },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(template.label) +
                                    ":\n                                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            template.type === "checkbox"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.values[template.name],
                                      expression: "values[template.name]"
                                    }
                                  ],
                                  staticClass: "input-text",
                                  class: {
                                    "required-entry": template.required,
                                    "validation-failed":
                                      _vm.errors[template.name]
                                  },
                                  attrs: {
                                    id:
                                      "membership-billing_address_" +
                                      template.name,
                                    maxlength: template.length,
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.values[template.name]
                                    )
                                      ? _vm._i(
                                          _vm.values[template.name],
                                          null
                                        ) > -1
                                      : _vm.values[template.name]
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.validBlurField(template.name)
                                    },
                                    change: function($event) {
                                      var $$a = _vm.values[template.name],
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.values,
                                              template.name,
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.values,
                                              template.name,
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.values, template.name, $$c)
                                      }
                                    }
                                  }
                                })
                              : template.type === "radio"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.values[template.name],
                                      expression: "values[template.name]"
                                    }
                                  ],
                                  staticClass: "input-text",
                                  class: {
                                    "required-entry": template.required,
                                    "validation-failed":
                                      _vm.errors[template.name]
                                  },
                                  attrs: {
                                    id:
                                      "membership-billing_address_" +
                                      template.name,
                                    maxlength: template.length,
                                    type: "radio"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.values[template.name],
                                      null
                                    )
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.validBlurField(template.name)
                                    },
                                    change: function($event) {
                                      return _vm.$set(
                                        _vm.values,
                                        template.name,
                                        null
                                      )
                                    }
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.values[template.name],
                                      expression: "values[template.name]"
                                    }
                                  ],
                                  staticClass: "input-text",
                                  class: {
                                    "required-entry": template.required,
                                    "validation-failed":
                                      _vm.errors[template.name]
                                  },
                                  attrs: {
                                    id:
                                      "membership-billing_address_" +
                                      template.name,
                                    maxlength: template.length,
                                    type: template.type
                                  },
                                  domProps: {
                                    value: _vm.values[template.name]
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.validBlurField(template.name)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.values,
                                        template.name,
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                            _vm._v(" "),
                            _vm.errors[template.name]
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "validation-advice",
                                    attrs: {
                                      id:
                                        "advice-required-entry-" + template.name
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          template.message
                                            ? template.message
                                            : "This is a required field."
                                        ) +
                                        "\n                                "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "actions-toolbar" }, [
        _c(
          "div",
          { staticClass: "primary" },
          [
            _c("BaseButton", {
              staticClass: "button--copy-solid-white-blue button--small",
              attrs: { text: "Continue" },
              nativeOn: {
                click: function($event) {
                  return _vm.continueNxt($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }