var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showEditVersion
      ? _c(
          "div",
          { staticClass: "selector-progressive-container" },
          [
            _c(
              "div",
              { staticClass: "selector-prescription" },
              [
                _c("PrescriptionTable", {
                  attrs: {
                    "show-edit-version": _vm.showEditVersion,
                    "show-add": _vm.showAdd,
                    value: _vm.value,
                    help: _vm.help
                  }
                }),
                _vm._v(" "),
                _vm.showError
                  ? _c("div", { staticClass: "error-text-rx" }, [
                      _c("span", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: _vm.errorText,
                            expression: "errorText"
                          }
                        ]
                      })
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("PrescriptionPupillaryDistance", {
              attrs: {
                "show-edit-version": _vm.showEditVersion,
                "show-add": _vm.showAdd,
                value: _vm.value,
                help: _vm.help
              }
            }),
            _vm._v(" "),
            _c("Prism", { staticClass: "prism-component" })
          ],
          1
        )
      : _c(
          "div",
          [
            _c("ParamsNotEdit", {
              staticClass: "selector-progressive-container",
              attrs: { help: _vm.help }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }