import getHelper from '../helper'
import {
    LENS_OPTIONS_CODE,
    LENS_OPTION_CODE_CR39,
    LENS_OPTION_CODE_POLY,
    LENS_OPTION_CODE_167
} from '../lens_options'
import {
    LENS_TYPE_ID,
    LENS_TYPE_SECTION_ID
} from '../../../../../app/common/constants/lens_type'
import {defaultOptions, framesTypeCustomize} from "./default";

const ENABLED_LIMITERS_COLOR = {
    'grey-polarized': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_CR39,
            LENS_OPTION_CODE_POLY,
            LENS_OPTION_CODE_167
        ]
    }
}

const DISABLED_LIMITERS_LENS_TYPE = {
    'transitions-xtractive': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_167
        ]
    }
}

export const LIMITERS_DEFAULT_LESPECS = {
    options_disable: {
        single_vision_distance: {
            [LENS_TYPE_SECTION_ID]: DISABLED_LIMITERS_LENS_TYPE
        },
        single_vision_reading: {
            [LENS_TYPE_SECTION_ID]: DISABLED_LIMITERS_LENS_TYPE
        },
        progressive_standard: {
            [LENS_TYPE_SECTION_ID]: DISABLED_LIMITERS_LENS_TYPE
        }
    },
    options_enable: {
        single_vision_distance: {
            color: ENABLED_LIMITERS_COLOR
        },
        single_vision_reading: {
            color: ENABLED_LIMITERS_COLOR
        },
        progressive_standard: {
            color: ENABLED_LIMITERS_COLOR
        },
        progressive_premium: {
            color: ENABLED_LIMITERS_COLOR
        }
    }
}

export default function getLeSpecsStepList (params) {
    const defaultStepOptions = defaultOptions(params)
    const {product_config,assets_url} = params
    const {DEFAULT_LAST_STEP} = getHelper(params)

    const frames_type = {
        'id': 'frames_type',
        'label': 'Choose Lenses',
        'summary_label': 'Choose Lenses',
        'no_summary': true,
        'value_renderer': 'renderer-options',
        'default_value': 'frames_customize',
        'component': {
            'name': 'selector-options',
            'options': [
                framesTypeCustomize
            ]
        }
    }

    const lens_type = {
        'id': LENS_TYPE_ID,
        'label': 'Select Lens Type',
        'summary_label': 'Lens Type',
        'value_renderer': 'renderer-options',
        'default_value': 'sunglasses_lenses',
        'component': {
            'name': 'selector-options-lens-type',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensType.sunglassesLens,
                defaultStepOptions.lensType.computerLens,
                defaultStepOptions.lensType.blueLightLens,
                defaultStepOptions.lensType.transitionLens,
            ]
        }
    }

    const lens_options = {
        'id': LENS_OPTIONS_CODE,
        'label': 'Select Lens Material',
        'summary_label': 'Lens Option',
        'value_renderer': 'renderer-options',
        'hide_for_product': ['sunglass_lens', 'transition_lens'],
        'always_visible': true,
        'component': {
            'name': 'selector-options-lens-option',
            'params': {
                'product_config': product_config
            },
            'options': [
                defaultStepOptions.lensOptions.polyLensOption,
                defaultStepOptions.lensOptions.hi167LensOption,
            ]
        }
    }

    const single_vision_distance = Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP})
    const single_vision_distance_premium  = Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP})
    const single_vision_near = Object.assign({'id': 'single_vision_reading'}, {...DEFAULT_LAST_STEP})
    const single_vision_near_premium  = Object.assign({'id': 'single_vision_reading_premium'}, {...DEFAULT_LAST_STEP})
    const progressive_standard = Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP})
    const progressive_premium = Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP})

    const step_list = [
        defaultStepOptions.notesOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.chooseLens.prescriptionType,
                    defaultStepOptions.chooseLens.nonPrescriptionType
                ]
            }
        },
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription
                ]
            }
        },
        single_vision_distance,
        single_vision_distance_premium,
        single_vision_near,
        single_vision_near_premium,
        progressive_standard,
        progressive_premium,
        lens_type,
        lens_options,
        frames_type
    ]
    return step_list
}
