<template>
    <CatalogProductView v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import CatalogProductView from '../../components/contacts/product/CatalogProductView.vue'

export default {
    name: 'RouterContactsProductView',
    components: {
        CatalogProductView
    },
    extends: App
}
</script>
