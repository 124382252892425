var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        _vm.adData.containerClassName,
        _vm.window.width < 640 && _vm.productView.productConfiguratorShow
          ? "hide-section"
          : ""
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "lplus-ad-block_container",
          on: { click: _vm.openPlusAdModal }
        },
        [
          _c("a", { attrs: { href: _vm.adLink } }, [
            _vm.isShopFramesBrand
              ? _c("img", {
                  staticClass: "lplus-ad-block_img",
                  attrs: {
                    src: _vm.adData.adImgUrl,
                    alt:
                      "Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                  }
                })
              : _c("img", {
                  staticClass: "lplus-ad-block_img",
                  attrs: {
                    src:
                      _vm.window.width < 768
                        ? _vm.adData.adImgUrlMobile
                        : _vm.adData.adImgUrl,
                    alt:
                      "Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                  }
                }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "help-bubble-container lplus-help-bubble-container"
              },
              [
                _c("div", { staticClass: "help-component" }, [
                  _c(
                    "div",
                    {
                      staticClass: "help-bubble lplus-help-bubble",
                      class: _vm.showModalAd ? "open" : "",
                      attrs: { id: "help_bubble_text" }
                    },
                    [
                      _c("span", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: _vm.adData.adhoverTextHtml,
                            expression: "adData.adhoverTextHtml"
                          }
                        ]
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "close-button",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.closePlusAdModal($event)
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "18",
                                height: "18",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  id: "svg_1",
                                  d:
                                    "m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z",
                                  fill: "rgb(102, 102, 102)",
                                  "fill-rule": "evenodd"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }