var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plus-account_wrappper" }, [
    _c(
      "div",
      { staticClass: "plus-wrapper" },
      [
        _vm.loading
          ? _c("PageLoader", { attrs: { load: _vm.loading } })
          : _c(
              "div",
              { staticClass: "main-card-holder" },
              [
                _vm._l(_vm.redeems, function(redeem, index) {
                  return [
                    _c("Redeem", {
                      key: index,
                      attrs: {
                        redeem: redeem,
                        discount: _vm.getDiscount(redeem.key),
                        "btn-text": redeem.btnText
                      }
                    })
                  ]
                })
              ],
              2
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }