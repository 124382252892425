var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "swatch-attribute color" }, [
    _vm.attributeTitle
      ? _c("div", {
          staticClass: "swatch-attribute-title",
          domProps: { textContent: _vm._s(_vm.attributeTitle) }
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "swatch-attribute-options" },
      [
        _vm._l(_vm.colors, function(item, index) {
          var _obj
          return [
            _c("li", { key: item.color, staticClass: "swatch-option color" }, [
              _c(
                "span",
                {
                  class:
                    ((_obj = {}),
                    (_obj[_vm.optionItemClass] = _vm.optionItemClass),
                    (_obj[_vm.getColorName(item.color)] = item.color),
                    (_obj["selected"] = index === _vm.checkedIndex),
                    _obj),
                  style: _vm.getBackgroundImage(_vm.getColorName(item.color)),
                  attrs: { title: item.color },
                  on: {
                    click: function($event) {
                      return _vm.$emit(_vm.EVENT_CHANGE_CURRENT_COLOR, index)
                    }
                  }
                },
                [_vm._v(_vm._s(item.color))]
              ),
              _vm._v(" "),
              _vm.addLabel
                ? _c(
                    "p",
                    { staticStyle: { color: "#989898", "font-size": "12px" } },
                    [_vm._v(_vm._s(_vm.getName(item.color)))]
                  )
                : _vm._e()
            ])
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }