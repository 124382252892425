import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererFramesValue',
    extends: RendererOptions,
    computed: {
        renderer () {
            const data = this.checkoutData
            return data.custom_options.frames_value
        }
    }
}
