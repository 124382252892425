<template>
    <v-container grid-list-md anagram-container admin>
        <v-layout row wrap>
            <RadioLabel :active="hasInsurance" label="has vision insurance" @click="changeRadioValue"/>
            <RadioLabel :active="!hasInsurance" label="is covered under primary’s vision insurance plan"
                        @click="changeRadioValue"/>
        </v-layout>
        <HasInsuranceForm v-if="hasInsurance" :form-model="getHasInsuranceForm"/>
        <HasNotInsuranceForm v-else :form-model="getHasNotInsuranceForm"/>
        <p v-if="error" style="color: red">{{errorText}}</p>
        <p v-if="success" style="color: red">Customer's request has been submitted successfully.</p>
    </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import storageInsuranceAnagram from '../storage/insuranceAnagram'
import RadioLabel from '../../base/RadioLabel.vue'
import HasInsuranceForm from "./forms/anagram/HasInsuranceForm.vue";
import HasNotInsuranceForm from "./forms/anagram/HasNotInsuranceForm.vue";

export default {
    name: 'InsuranceAnagram',
    components: {
        HasNotInsuranceForm, HasInsuranceForm,
        RadioLabel,
    },
    data: () => {
        return {
            hasInsurance: true,
        }
    },
    computed: {
        ...mapGetters('insuranceAdminAnagram', ['getHasInsuranceForm', 'getHasNotInsuranceForm', 'productsName', 'adminPreSelectHasInsurance']),
        ...mapState('insuranceAdminAnagram', ['success', 'error', 'errorText'])
    },
    beforeCreate() {
        if (!this.$store._modulesNamespaceMap['insuranceAdminAnagram/']) {
            this.$store.registerModule('insuranceAdminAnagram', storageInsuranceAnagram(this.$store))
        }
    },
    created(){
        this.hasInsurance = this.adminPreSelectHasInsurance
        this.$store.dispatch('insuranceAdminAnagram/preselectAdmin')
    },
    methods: {
        changeRadioValue() {
            this.hasInsurance = !this.hasInsurance
            this.$store.commit('insuranceAdminAnagram/resetState')
        },
    }
}
</script>
