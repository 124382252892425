<template>
    <div v-if="prescriptionKey">
        <RadioLabel v-if="!prescriptionData.status_4" :active="isActive" :label="title" :value="index" @click="$emit('checked', index)" />

        <div v-if="isActive" class="my-prescription-form__pupillary-distance">
            <div class="two-pupillary-numbers">
                <input v-model="value" class="prescription-prism-input" />
            </div>
        </div>

        <div v-if="prescriptionData.status_4" class="my-prescription-form__pupillary-distance">
            <div class="two-pupillary-numbers">
                <p>SH: <span class="prescription-prism-input" v-text="value"></span></p>
            </div>
        </div>
    </div>
</template>
<script>
import SalesOrderViewPrescriptionRenderAbstract from './abstract'

export default {
    name: 'SalesOrderViewPrescriptionRenderInput',
    mixins: [SalesOrderViewPrescriptionRenderAbstract],
    props: {
        title: {
            type: [Boolean, String],
            default: 'Enter Height'
        },
        prescriptionKey: {
            type: [Boolean, String],
            default: false
        }
    },
    data: () => ({
        value: ''
    }),
    watch: {
        value: {
            handler (value) {
                const data = {}
                const index = this.indexSave
                data[this.prescriptionKey] = value
                this.setValueOptions({index, data})
            },
            deep: true
        }
    },
    created () {
        if (this.prescriptionKey) {
            this.value = this.prescriptionData[this.prescriptionKey] || ''
        }
    }
}
</script>
