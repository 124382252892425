import axios from 'axios'

let category = null

class CategoryService {
    constructor ({base_url}) {
        this.base_url = base_url
    }

    getFilteredProductsCollection (data = null, query = false) {
        query = query || 'getFilteredProducts?data=' + data

        const url = this.base_url + 'lensabl/order/' + query
        return axios.get(url)
    }

    getProductsCollection (id = null, query = false) {
        query = query || 'getProducts?category_id=' + id

        const url = this.base_url + 'lensabl/order/' + query
        return axios.get(url)
    }

    getContactsProductsCollection (id = null, query = false) {
        query = query || 'getContactsProducts?category_id=' + id

        const url = this.base_url + 'contact-lenses/ajax/' + query
        return axios.get(url)
    }
}

export default function getCategoryService (options = {}) {
    if (options) {
        category = new CategoryService(options)
    }
    return category
}
