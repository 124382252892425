var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sidebar-filter-component", attrs: { id: "sidebar" } },
    [
      _c(
        "div",
        { staticClass: "sidebar-container" },
        [
          _c(
            "span",
            {
              staticClass: "close-filter",
              on: {
                click: function($event) {
                  return _vm.closeFilters()
                }
              }
            },
            [_vm._v("×")]
          ),
          _vm._v(" "),
          _vm._l(_vm.categories, function(subcategory, index) {
            return _c("div", { key: _vm.subcategories[index] }, [
              _c("h4", [_vm._v(_vm._s(_vm.subcategories[index]))]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(subcategory, function(category) {
                  return _c(
                    "li",
                    {
                      key: category.id,
                      staticClass: "cateogry-links",
                      class: [
                        category.name,
                        { active: category.id === _vm.chosenId }
                      ]
                    },
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.choseCategory(category.id, index)
                            }
                          }
                        },
                        [_c("span", [_vm._v(_vm._s(category.name))])]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }