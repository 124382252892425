<template>
    <ul v-if="attributes.length" class="additional-attributes">
        <template v-for="attribute in attributes">
            <li v-if="attribute.value" :key="attribute.label" :class="attribute.itemClassName" class="item">
                <span class="label" v-text="attribute.label"></span>
                <span v-dompurify-html="attribute.value" class="data"></span>
                <HelpBubble :placement="attribute.help_placement" :data="attribute.component_help" />
            </li>
        </template>
    </ul>
</template>

<script>
import HelpBubble from '../../../help_bubble/HelpBubble.vue'

export default {
    name: 'AdditionalAttributes',
    components: {
        HelpBubble
    },
    props: {
        dataAttributes: {
            type: [Object, Array],
            default: () => []
        }
    },
    computed: {
        attributes () {
            return this.dataAttributes || []
        }
    }
}
</script>
