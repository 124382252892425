var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ]
    },
    [
      _vm.config.title
        ? _c("h2", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.title,
                expression: "config.title"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "plans-list-wrapper" }, [
        _c("div", { staticClass: "border-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "border-2" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "plans-list-container" },
          [
            _c("TotalsInfo"),
            _vm._v(" "),
            _c("TotalsPrice"),
            _vm._v(" "),
            _c("SaveUp"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "actions-toolbar" },
              [
                _c("BaseButton", {
                  staticClass: "checkout final-button",
                  attrs: {
                    loader: _vm.ajaxLoad,
                    disabled: _vm.disabledCheckout,
                    text: "Sign Up"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.checkout($event)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.disabledCheckout
                  ? _c("div", { staticClass: "error-text-rx" }, [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.errorText) }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isPlusQuote
                  ? _c("div", { staticClass: "error-text-rx" }, [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.isPlusQuoteErrorText)
                        }
                      })
                    ])
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }