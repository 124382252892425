var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isStepCurrent
    ? _c(
        "div",
        [
          _c("div", { staticClass: "options_container upgrades" }, [
            _c("div", { staticClass: "option-upgrades" }, [
              _vm.info.label && !_vm.framesPartnerPage
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "option-container upgrades-container",
                        class: { "has-thumbnail": !!_vm.info.image }
                      },
                      [
                        _vm.info.image
                          ? _c("div", { staticClass: "option-thumbnail" }, [
                              _c("div", {
                                directives: [
                                  {
                                    name: "dompurify-html",
                                    rawName: "v-dompurify-html",
                                    value: _vm.info.image,
                                    expression: "info.image"
                                  }
                                ]
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "option-descriptions title" },
                          [
                            _c("span", { staticClass: "option-label" }, [
                              _c("span", {
                                directives: [
                                  {
                                    name: "dompurify-html",
                                    rawName: "v-dompurify-html",
                                    value: _vm.info.label,
                                    expression: "info.label"
                                  }
                                ],
                                staticClass: "label-text"
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "extra-component-container" },
                          [
                            _c("div", { staticClass: "extra-component" }, [
                              _c("span", {
                                directives: [
                                  {
                                    name: "dompurify-html",
                                    rawName: "v-dompurify-html",
                                    value: _vm.info.desc,
                                    expression: "info.desc"
                                  }
                                ]
                              })
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                : _c("div", [
                    _vm.isMobile
                      ? _c(
                          "div",
                          {
                            staticClass: "option-container upgrades-container",
                            class: { "has-thumbnail": !!_vm.info.image }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "option-descriptions title" },
                              [
                                _c("span", { staticClass: "option-label" }, [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "dompurify-html",
                                        rawName: "v-dompurify-html",
                                        value: _vm.info.label,
                                        expression: "info.label"
                                      }
                                    ],
                                    staticClass: "label-text"
                                  })
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "options" },
                [
                  _vm._l(_vm.getOptions, function(option, index) {
                    return [
                      option.show
                        ? _c("Option", {
                            key: option.id,
                            staticClass: "step-configuration-content",
                            attrs: {
                              "component-help-show": _vm.isMobile,
                              option: option,
                              index: index
                            }
                          })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          !_vm.framesPartnerPage || _vm.lensabl_frames
            ? _c("SelectorUpgrades")
            : _vm._e(),
          _vm._v(" "),
          !_vm.isSpectaclesGen
            ? _c("SelectorWarranty", { attrs: { id: _vm.id } })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }