<template>
    <div v-if="!loading" class="employee-signup">
        <nav class="lensabl-plus-nav signup-nav">
            <div class="nav-container">
                <div v-if="getLogoImage" class="nav-container-logo nav-container-employee-logo">
                    <img class="lensabl-plus-employee-logo" :src="getLogoImage"/>
                </div>
                <div class="nav-container-logo">
                    <img src="/media/wysiwyg/lensabl-logo.svg" class="lensabl-plus-nav-logo"/>
                </div>
            </div>
            <div class="employee-signup-info">
                <p>
                    Taking care of your vision can be expensive and inconvenient. With Lensabl, you have easy access to
                    affordable designer frames, prescription lenses,
                    and contacts all available with the click of a button at <a :href="base_url">Lensabl.com</a>. And
                    now, Members get access to exclusive discounts on everything
                    we sell!
                </p>
                <p><b>Save up to <span
                    v-if="!isDiscountTypePercent('replacement_discount')">$</span><span>{{
                        getDiscountValue('replacement_discount')
                    }}</span><span
                    v-if="isDiscountTypePercent('replacement_discount')">%</span> when you sign up for Lensabl’s
                    Discount Membership Program.</b></p>
                <p>Sign up for <b>FREE</b> using the enrollment form below and start using your member discount immediately. All
                    discounts will automatically be applied to your order at checkout.
                    Lensabl’s product offering and member discounts include:</p>
                <ul class="employee-signup-benefit-list">
                    <li>
                        <img src="/media/wysiwyg/lenses.png" />
                        <span>
                        <span
                            v-if="!isDiscountTypePercent('replacement_discount')">$</span>{{
                                getDiscountValue('replacement_discount')
                            }}<span
                            v-if="isDiscountTypePercent('replacement_discount')">%</span> off Prescription and non-prescription lens replacement for your existing glasses</span>
                    </li>
                    <li>
                        <img src="/media/wysiwyg/frames-icon.png" />
                        <span>
                        <span
                            v-if="!isDiscountTypePercent('designer_frames_discount')">$</span><span>{{
                            getDiscountValue('designer_frames_discount')
                        }}</span><span
                        v-if="isDiscountTypePercent('designer_frames_discount')">%</span> off Designer frame brands including Ray-Ban, Oakley, Nike, and many more</span>
                    </li>
                    <li>
                        <img src="/media/wysiwyg/contacts.png" />
                        <span>
                        <span
                            v-if="!isDiscountTypePercent('contacts_discount')">$</span><span>{{
                            getDiscountValue('contacts_discount')
                        }}</span><span
                        v-if="isDiscountTypePercent('contacts_discount')">%</span> off Every major contact lens brand</span>
                    </li>
                    <li>
                        <img src="/media/wysiwyg/savings.png" />
                        <span>
                        <span
                            v-if="!isDiscountTypePercent('replacement_discount')">$</span><span>{{
                            getDiscountValue('replacement_discount')
                        }}</span><span
                        v-if="isDiscountTypePercent('replacement_discount')">%</span> off Reading glasses, blue-light blocking lenses, Transitions lenses and many more specialty lens options</span>
                    </li>
                    <li>
                        <img src="/media/wysiwyg/exam.png" />
                        <span>
                        <span
                            v-if="!isDiscountTypePercent('online_vision_test_discount')">$</span><span>{{
                            getDiscountValue('online_vision_test_discount')
                        }}</span><span
                        v-if="isDiscountTypePercent('online_vision_test_discount')">%</span> off An AI-powered online vision test</span>
                    </li>
                </ul>
            </div>

        </nav>
        <PageLoader v-if="loading" :load="loading"/>
        <div v-else class="lensabl-plus-content company-details">
            <h2 class="title">Enter Your Details</h2>
            <h2 class="subtitle">Please complete the information below to create your Lensabl Discount Program
                Account</h2>
            <div id="company_address_fields" class="company-address">
                <div class="content">
                    <div class="hor-scroll">
                        <table cellspacing="0" class="form-list" style="width: 100%">
                            <tbody>
                            <tr>
                                <Input
                                    :field="fields['name']"
                                    :error="errors[fields['name'].name]"
                                    colspan="2"
                                    :value="values[fields['name'].name]"
                                    @blur="validBlurField"
                                    @update="updateValue"
                                />
                            </tr>
                            <tr>
                                <Input
                                    :field="fields['email']"
                                    :error="errors[fields['email'].name]"
                                    :value="values[fields['email'].name]"
                                    @blur="validBlurField"
                                    @update="updateValue"
                                />
                                <td>
                                    <label :for="`membership-${ fields['birthday'].name }`">
                                        {{ fields['birthday'].label }}:
                                    </label>
                                    <date-picker
                                        v-model="values[fields['birthday'].name]"
                                        :placeholder="dateFormat"
                                        value-type="format"
                                        :class="{'required-entry': fields['birthday'].required, 'validation-failed': errors[fields['birthday'].name]}"
                                        class="input-text"
                                        type="text"
                                        :format="dateFormat"
                                        @input="validBlurField(fields['birthday'].name)"
                                        :disabled-date="(date) => date >= new Date()"
                                    />
                                    <div v-if="errors[fields['birthday'].name]"
                                         :id="`advice-required-entry-${fields['birthday'].name}`"
                                         class="validation-advice" style="">This is a required field. Please use exact
                                        format: {{ dateFormat }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <Input
                                    :field="fields['telephone']"
                                    :error="errors[fields['telephone'].name]"
                                    colspan="2"
                                    :value="values[fields['telephone'].name]"
                                    :maxlength="fields.length"
                                    @blur="validBlurField"
                                    @update="updateValue"
                                />
                            </tr>
                            <tr>
                                <Input
                                    :field="fields['street1']"
                                    :error="errors[fields['street1'].name]"
                                    colspan="2"
                                    :value="values[fields['street1'].name]"
                                    @blur="validBlurField"
                                    @update="updateValue"
                                />
                            </tr>
                            <tr>
                                <Input
                                    :field="fields['street2']"
                                    colspan="2"
                                    :value="values[fields['street2']]"
                                    @update="updateValue"
                                />
                            </tr>
                            </tbody>
                        </table>
                        <table cellspacing="0" class="form-list" style="width: 100%">
                            <colgroup>
                                <col width="60%"/>
                                <col width="60%"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <Input
                                    :field="fields['country']"
                                    :error="errors[fields['country'].name]"
                                    :value="values[fields['country'].name]"
                                    @update="updateValue"
                                />
                                <Input
                                    :field="fields['city']"
                                    :error="errors[fields['city'].name]"
                                    :value="values[fields['city'].name]"
                                    @blur="validBlurField"
                                    @update="updateValue"
                                />
                            </tr>
                            <tr>
                                <td>
                                    <label :for="`employee-${ fields['region'].name }`">
                                        {{ fields['region'].label }}:
                                    </label>
                                    <select
                                        :id="`employee_${fields['region'].name}`"
                                        v-model="values[fields['region'].name]"
                                        :class="{'required-entry': fields['region'].required, 'validation-failed': errors[fields['region'].name]}"
                                        class="select"
                                        @change="validBlurField(fields['region'].name)"
                                    >
                                        <template v-for="region in regions_data">
                                            <option :key="`option-${region.value}`" :value="region.value"
                                                    v-text="region.label"></option>
                                        </template>
                                    </select>
                                    <div v-if="errors[fields['region'].name]"
                                         :id="`advice-required-entry-${fields['region'].name}`"
                                         class="validation-advice" style="">
                                        This is a required field.
                                    </div>
                                </td>
                                <Input
                                    :field="fields['postcode']"
                                    :error="errors[fields['postcode'].name]"
                                    :value="values[fields['postcode'].name]"
                                    @blur="validBlurField"
                                    @update="updateValue"
                                />
                            </tr>
                            </tbody>
                        </table>
                        <table cellspacing="0" class="form-list" style="width: 100%">
                            <tbody>
                            <tr>
                                <Input
                                    :field="fields['password']"
                                    :error="$v.values.password.pattern && errors[fields['password'].name]"
                                    :value="values[fields['password'].name]"
                                    @blur="validBlurField"
                                    @update="updateValue"
                                    :passwordError="!$v.values.password.pattern"
                                />
                                <Input
                                    :field="fields['confirm_password']"
                                    :error="errors[fields['confirm_password'].name]"
                                    :value="values[fields['confirm_password'].name]"
                                    @blur="validBlurField"
                                    @update="updateValue"
                                    :passwordError="!$v.values.password.pattern"
                                />
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div class="actions-toolbar">
                    <div class="primary">
                        <BaseButton
                            :disabled="sending"
                            class="button--copy-solid-white-blue button--small"
                            text="Sign up"
                            @click.native="continueNxt"
                        />
                        <p v-if="sendingError" class="error-text">{{ errorText }}</p>
                    </div>
                </div>
            </div>
        </div>
        <SignInModal v-if="showLensablPlusPopup || showLensablWasAssignBeforePopup">
            <div>You are already a Lensabl member. Please sign in to your account using the link below:<br/> <a
                href="/customer/account/login">Sign In</a></div>
        </SignInModal>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import membershipStore from './storage/index'

import {validationMixin} from 'vuelidate'

import PageLoader from "../base/PageLoader.vue";
import {
    ERROR_TOUCHED,
    FIREFOX_DATE_FORMAT,
    DATE_FORMAT,
    FORM_OBJECT,
    PASSWORD_REG
} from "./storage/constants";
import {email, maxLength, minLength, numeric, required, sameAs, helpers} from "vuelidate/lib/validators";
import _map from "lodash/map";
import Input from "./SignupInput.vue";
import BaseButton from "../base/BaseButton.vue";
import SignInModal from './SignInModal.vue'

const passwordPattern = helpers.regex('containAllRules', PASSWORD_REG);

export default {
    name: 'Signup',
    components: {
        BaseButton,
        Input,
        PageLoader,
        DatePicker,
        SignInModal
    },
    mixins: [validationMixin],
    data: () => ({
        loading: false,
        sending: false,
        errors: ERROR_TOUCHED,
        fields: FORM_OBJECT,
        sendingError: false,
        showLensablPlusPopup: false,
        showLensablWasAssignBeforePopup: false,
        errorText: 'We have encountered an issue. Please contact support@lensabl.com'
    }),
    validations: {
        values: {
            email: {
                required,
                email
            },
            street1: {
                required
            },
            name: {
                required,
                minLength: minLength(3)
            },
            password: {
                required,
                pattern: passwordPattern
            },
            confirm_password: {
                required,
                sameAsPassword: sameAs('password'),
                pattern: passwordPattern
            },
            postcode: {
                required
            },
            region: {
                required
            },
            city: {
                required
            },
            country: {
                required
            },
            birthday: {
                required
            },
            telephone: {
                required,
                numeric,
                minLength: minLength(10),
                maxLength: maxLength(10)
            }
        }
    },
    computed: {
        ...mapGetters('storeView', ['regions_data', 'media_url']),
        ...mapGetters('membership', ['values', 'membershipData']),
        ...mapGetters('storeView', ['base_url']),
        membershipInvalidFields() {
            const invalidFields = []
            _map(this.values, (value, key) => {
                if (this.$v.values.hasOwnProperty(key) && this.$v.values[key].$invalid) {
                    invalidFields.push(key)
                    this.errors[key] = true
                }
            })
            return invalidFields
        },
        membershipIsValid() {
            return !this.membershipInvalidFields.length
        },
        getLogoImage() {
            return this.membershipData.logo_image ? this.media_url + this.membershipData.logo_image : null
        },
        dateFormat() {
            return DATE_FORMAT
        },
        isDiscountTypePercent() {
            return (discount) => {
                return this.getDiscountType(discount) === 'percent'
            }
        },
        getDiscountType() {
            return (discount) => {
                return this.membershipData['type_' + discount]
            }
        },
        getDiscountValue() {
            return (discount) => {
                return this.membershipData[discount]
            }
        }
    },
    beforeCreate() {
        if (!this.$store._modulesNamespaceMap['membership/']) {
            this.$store.registerModule('membership', membershipStore(this.$store))
        }
    },
    created: async function () {
        this.loading = true
        await this.$store.dispatch('storeView/fetchCountryRegions')
        await this.$store.dispatch('membership/getMembershipData')
        this.loading = false
    },
    methods: {
        formattedDateAndSetFinal() {
            const values = this.values
            this.$store.commit('membership/SET_FINAL', values)
        },
        continueNxt() {
            this.sending = false
            if (this.membershipIsValid) {
                this.formattedDateAndSetFinal()
                this.sending = true
                this.$store.dispatch('membership/submitCustomer')
                    .catch(error => {
                        console.log(error)
                        this.sendingError = true
                        const response = error.response
                        if (response.status === 401) {
                            this.errorText = response.data.message
                            this.sending = false
                        }
                        if (response.status === 403) {
                            this.showLensablPlusPopup = true
                            this.errorText = ''
                        }
                        if (response.status === 406) {
                            this.showLensablWasAssignBeforePopup = true
                            this.errorText = ''
                        }
                    })
            }
        },
        validBlurField(val) {
            this.errors[val] = this.$v.values[val].$invalid
        },
        updateValue(event, key) {
            const newValues = this.values
            newValues[key] = event.target.value
            this.$store.commit('membership/SET_VALUES', newValues)
        }
    }
}
</script>

<style scoped lang="less">
.mx-datepicker {
    border-radius: .3125rem;
    width: 100%;

}
input.mx-input {
    margin-bottom: 0 !important;
}

input::placeholder {
    font-style: normal !important;
}

select {
    border: 0;
}

.employee-signup .loader-circle {
    min-height: 66vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitle {
    font-size: .9375rem;
    color: #8d8d8d;
    max-width: 35rem;
    line-height: 1.5 !important;
    margin: -30px auto 1.5rem;

    @media screen and (min-width: 640px) {
        font-size: 1.0625rem;
    }
}
.employee-signup-info {
    font-size: .9375rem;
    color: #8d8d8d;
    line-height: 1.5 !important;
    padding: 1rem 2rem 2rem;
    max-width: 900px;
    margin: 0 auto;

    @media screen and (min-width: 640px) {
        font-size: 1.0625rem;
    }
    @media screen and (min-width: 1024px) {
        padding: 1rem 0 2rem;
    }

    p {
        text-align: center;

        a {
            color: #79c0e9;
        }
    }
    .employee-signup-benefit-list {
        margin: 0;
        color:#2f3948;
        max-width: 800px;
        margin: auto;
        
        @media screen and (min-width: 768px) {
            padding: 0 1rem;
        }

        li {
            font-weight: bold;
            display: flex;
            align-items: center;
            margin: 1rem 0;
    
            img {
                height: auto;
                width: 60px;
                margin-right: 0.5rem;
            }
    
            > span {
                display: block;
                line-height: 1.6;
            }
        }
    
    }
}

</style>

