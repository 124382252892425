var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "prescription-list-container" }, [
        _c(
          "div",
          { staticClass: "my-prescription-list" },
          [
            _c("div", { staticClass: "my-prescription-list__copy" }, [
              !_vm.edit
                ? _c("p", { staticClass: "my-prescription-name" }, [
                    _vm._v(_vm._s(_vm.prescription.name))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.edit
                ? _c("input", {
                    staticClass: "my-prescription-form__name outline",
                    attrs: {
                      id: "prescription-form__name",
                      placeholder: "Enter Prescription Name here...",
                      required: "required"
                    },
                    domProps: { value: _vm.prescription.name },
                    on: {
                      change: function($event) {
                        return _vm.setPrescriptionValue(
                          $event.target.value,
                          "prescription_name"
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ul", [
                _c(
                  "li",
                  [
                    _c("span", [_vm._v("Prescription Type:")]),
                    _vm._v(" "),
                    !_vm.edit
                      ? _c(
                          "span",
                          { staticClass: "display-prescription-type" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.getRXTypes(
                                  _vm.prescription.prescription_type
                                )
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.edit
                      ? _c("CustomSelect", {
                          attrs: {
                            options: _vm.rx_types,
                            value: _vm.prescription.prescription_type
                          },
                          on: {
                            change: function($event) {
                              return _vm.updateRXType($event.target.value)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.prescription.order_id
                  ? _c("li", [
                      _c("span", [_vm._v("Order ID:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.prescription.order_id))])
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "prescription-snapshot" }, [
              _c(
                "div",
                { staticClass: "p", attrs: { id: "poupmaindiv" } },
                [
                  !_vm.isMobile
                    ? _c(
                        "table",
                        {
                          staticClass: "prescription-data rx-data_my-account",
                          attrs: { border: "0" }
                        },
                        [
                          _c("tr", { staticClass: "prescription-row" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header",
                                attrs: { width: "16%" }
                              },
                              [_vm._v("RX")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("SPH")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("CYL")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("AXIS")]
                            ),
                            _vm._v(" "),
                            _vm.hasAdd
                              ? _c(
                                  "td",
                                  {
                                    staticClass:
                                      "prescription-cell prescription-header"
                                  },
                                  [_vm._v("Add")]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "prescription-row" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("OD (Right)")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          id: "odsphere",
                                          value: _vm.all_values["od_sphere"],
                                          type: "text",
                                          name: "od_sphere"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.all_values["od_sphere"]
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyeSphereOptions,
                                        value: _vm.all_values["od_sphere"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "od_sphere"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          id: "odcylinder",
                                          value: _vm.all_values["od_cylinder"],
                                          type: "text",
                                          name: "od_cylinder"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.all_values["od_cylinder"]
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyePowerOptions,
                                        value: _vm.all_values["od_cylinder"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "od_cylinder"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          id: "odaxis",
                                          value: _vm.all_values["od_axis"],
                                          type: "text",
                                          name: "od_axis"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.all_values["od_axis"]) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyeAxisOptions,
                                        value: _vm.all_values["od_axis"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "od_axis"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.hasAdd
                              ? _c(
                                  "td",
                                  { staticClass: "prescription-cell value" },
                                  [
                                    !_vm.edit
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "prescription-input",
                                            attrs: {
                                              id: "odadd",
                                              value: _vm.all_values["od_add"],
                                              type: "text",
                                              name: "od_add"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.all_values["od_add"]
                                                ) +
                                                "\n                            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.edit
                                      ? _c("CustomSelect", {
                                          attrs: {
                                            options: _vm.eyeAddOptions,
                                            value: _vm.all_values["od_add"]
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setPrescriptionValue(
                                                $event.target.value,
                                                "od_add"
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "prescription-row" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("OS (Left)")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          id: "ossphere",
                                          value: _vm.all_values["os_sphere"],
                                          type: "text",
                                          name: "os_sphere"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.all_values["os_sphere"]
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyeSphereOptions,
                                        value: _vm.all_values["os_sphere"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "os_sphere"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          id: "oscylinder",
                                          value: _vm.all_values["os_cylinder"],
                                          type: "text",
                                          name: "os_cylinder"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.all_values["os_cylinder"]
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyePowerOptions,
                                        value: _vm.all_values["os_cylinder"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "os_cylinder"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          id: "osaxis",
                                          value: _vm.all_values["os_axis"],
                                          type: "text",
                                          name: "os_axis"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.all_values["os_axis"]) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyeAxisOptions,
                                        value: _vm.all_values["os_axis"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "os_axis"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.hasAdd
                              ? _c(
                                  "td",
                                  { staticClass: "prescription-cell value" },
                                  [
                                    !_vm.edit
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "prescription-input",
                                            attrs: {
                                              id: "osadd",
                                              value: _vm.all_values["os_add"],
                                              type: "text",
                                              name: "os_add"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.all_values["os_add"]
                                                ) +
                                                "\n                            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.edit
                                      ? _c("CustomSelect", {
                                          attrs: {
                                            options: _vm.eyeAddOptions,
                                            value: _vm.all_values["os_add"]
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setPrescriptionValue(
                                                $event.target.value,
                                                "os_add"
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "prescription-row" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header",
                                staticStyle: { "white-space": "nowrap" }
                              },
                              [_vm._v("Notes")]
                            ),
                            _vm._v(" "),
                            _vm.hasAdd
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "prescription-cell value",
                                    attrs: { colspan: "4" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Notes for the prescription...\n                        "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.hasAdd
                              ? _c(
                                  "td",
                                  {
                                    staticClass: "prescription-cell value",
                                    attrs: { colspan: "3" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            Notes for the prescription...\n                        "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    : _c(
                        "table",
                        {
                          staticClass: "prescription-data rx-data_my-account",
                          attrs: { border: "0" }
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("tr", { staticClass: "prescription-row" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("SPH")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          value: _vm.all_values["od_sphere"],
                                          type: "text",
                                          name: "od_sphere_mobile"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.all_values["od_sphere"]
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyeSphereOptions,
                                        value: _vm.all_values["od_sphere"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "od_sphere"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          value: _vm.all_values["os_sphere"],
                                          type: "text",
                                          name: "os_sphere_mobile"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.all_values["os_sphere"]
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyeSphereOptions,
                                        value: _vm.all_values["os_sphere"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "os_sphere"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "prescription-row" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("CYL")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          value: _vm.all_values["od_cylinder"],
                                          type: "text",
                                          name: "od_cylinder"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.all_values["od_cylinder"]
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyePowerOptions,
                                        value: _vm.all_values["od_cylinder"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "od_cylinder"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          value: _vm.all_values["os_cylinder"],
                                          type: "text",
                                          name: "os_cylinder"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.all_values["os_cylinder"]
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyePowerOptions,
                                        value: _vm.all_values["os_cylinder"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "os_cylinder"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", { staticClass: "prescription-row" }, [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("Axis")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          value: _vm.all_values["od_axis"],
                                          type: "text",
                                          name: "od_axis"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.all_values["od_axis"]) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyeAxisOptions,
                                        value: _vm.all_values["od_axis"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "od_axis"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                !_vm.edit
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "prescription-input",
                                        attrs: {
                                          value: _vm.all_values["os_axis"],
                                          type: "text",
                                          name: "os_axis"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.all_values["os_axis"]) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.edit
                                  ? _c("CustomSelect", {
                                      attrs: {
                                        options: _vm.eyeAxisOptions,
                                        value: _vm.all_values["os_axis"]
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.setPrescriptionValue(
                                            $event.target.value,
                                            "os_axis"
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm.hasAdd
                            ? _c("tr", { staticClass: "prescription-row" }, [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "prescription-cell prescription-header"
                                  },
                                  [_vm._v("Add")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "prescription-cell value" },
                                  [
                                    !_vm.edit
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "prescription-input",
                                            attrs: {
                                              value: _vm.all_values["od_add"],
                                              type: "text",
                                              name: "od_add"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.all_values["od_add"]
                                                ) +
                                                "\n                            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.edit
                                      ? _c("CustomSelect", {
                                          attrs: {
                                            options: _vm.eyeAddOptions,
                                            value: _vm.all_values["od_add"]
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setPrescriptionValue(
                                                $event.target.value,
                                                "od_add"
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "prescription-cell value" },
                                  [
                                    !_vm.edit
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "prescription-input",
                                            attrs: {
                                              value: _vm.all_values["os_add"],
                                              type: "text",
                                              name: "os_add"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.all_values["os_add"]
                                                ) +
                                                "\n                            "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.edit
                                      ? _c("CustomSelect", {
                                          attrs: {
                                            options: _vm.eyeAddOptions,
                                            value: _vm.all_values["os_add"]
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.setPrescriptionValue(
                                                $event.target.value,
                                                "os_add"
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._m(1)
                        ]
                      ),
                  _vm._v(" "),
                  _vm.edit
                    ? _c("RadioLabel", {
                        attrs: {
                          label: "Does your PD have two numbers",
                          active: _vm.sepPds
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggle("sepPds")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.sepPds
                    ? _c("div", [
                        _c(
                          "table",
                          {
                            staticClass: "prescription-data pd-data_my-account",
                            attrs: { border: "0" }
                          },
                          [
                            _c("tr", { staticClass: "prescription-row" }, [
                              _vm.isMobile
                                ? _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header PD-header",
                                      attrs: { width: "30%", rowspan: "2" }
                                    },
                                    [_vm._v("PD")]
                                  )
                                : _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header PD-header",
                                      attrs: { width: "16%", rowspan: "2" }
                                    },
                                    [_vm._v("PD")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "prescription-cell value value-header"
                                },
                                [_vm._v("OD (Right)")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "prescription-cell value value-header"
                                },
                                [_vm._v("OS (Left)")]
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", { staticClass: "prescription-row" }, [
                              _c(
                                "td",
                                { staticClass: "prescription-cell value" },
                                [
                                  !_vm.edit
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "prescription-input",
                                          attrs: {
                                            id: "odpd",
                                            value: _vm.all_values["od_pd"],
                                            type: "text",
                                            name: "od_pd"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(_vm.all_values["od_pd"]) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.edit
                                    ? _c("CustomSelect", {
                                        attrs: {
                                          options: _vm.eyeODPDOptions,
                                          value: _vm.all_values["od_pd"]
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setPrescriptionValue(
                                              $event.target.value,
                                              "od_pd"
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "prescription-cell value" },
                                [
                                  !_vm.edit
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "prescription-input",
                                          attrs: {
                                            id: "ospd",
                                            value: _vm.all_values["os_pd"],
                                            type: "text",
                                            name: "os_pd"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(_vm.all_values["os_pd"]) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.edit
                                    ? _c("CustomSelect", {
                                        attrs: {
                                          options: _vm.eyeOSPDOptions,
                                          value: _vm.all_values["os_pd"]
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setPrescriptionValue(
                                              $event.target.value,
                                              "os_pd"
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    : _c("div", [
                        _c(
                          "table",
                          {
                            staticClass: "prescription-data pd-data_my-account",
                            attrs: { border: "0" }
                          },
                          [
                            _c("tr", { staticClass: "prescription-row" }, [
                              _vm.isMobile
                                ? _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header PD-header",
                                      attrs: { width: "30%" }
                                    },
                                    [_vm._v("PD")]
                                  )
                                : _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header PD-header",
                                      attrs: { width: "16%" }
                                    },
                                    [_vm._v("PD")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "prescription-cell value" },
                                [
                                  !_vm.edit
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "prescription-input",
                                          attrs: {
                                            id: "pd",
                                            value: _vm.all_values["pd"],
                                            type: "text",
                                            name: "pd"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(_vm.all_values["pd"]) +
                                              "\n                                "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.edit
                                    ? _c("CustomSelect", {
                                        attrs: {
                                          options: _vm.eyePDOptions,
                                          value: _vm.all_values["pd"]
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setPrescriptionValue(
                                              $event.target.value,
                                              "pd"
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]),
                  _vm._v(" "),
                  _vm.edit
                    ? _c("RadioLabel", {
                        attrs: {
                          label: "Does your Rx have a Prism?",
                          active: _vm.hasPrism
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggle("hasPrism")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasPrism
                    ? _c("div", [
                        !_vm.isMobile
                          ? _c(
                              "table",
                              {
                                staticClass:
                                  "prescription-data pd-data_my-account",
                                attrs: { border: "0" }
                              },
                              [
                                _vm._m(2),
                                _vm._v(" "),
                                _c("tr", { staticClass: "prescription-row" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header"
                                    },
                                    [_vm._v("OD (Right)")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                id: "odprismhorizontal",
                                                value:
                                                  _vm.all_values[
                                                    "od_prism_horizontal"
                                                  ],
                                                type: "text",
                                                name: "od_prism_horizontal"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "od_prism_horizontal"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyePrismHorizontalOptions,
                                              value:
                                                _vm.all_values[
                                                  "od_prism_horizontal"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "od_prism_horizontal"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                id:
                                                  "od_prism_horizontal_direction",
                                                value:
                                                  _vm.all_values[
                                                    "od_prism_horizontal_direction"
                                                  ],
                                                type: "text",
                                                name:
                                                  "od_prism_horizontal_direction"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "od_prism_horizontal_direction"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyeHorizontalDirectionOptions,
                                              value:
                                                _vm.all_values[
                                                  "od_prism_horizontal_direction"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "od_prism_horizontal_direction"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                id: "od_prism_vertical",
                                                value:
                                                  _vm.all_values[
                                                    "od_prism_vertical"
                                                  ],
                                                type: "text",
                                                name: "od_prism_vertical"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "od_prism_vertical"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyePrismVerticalOptions,
                                              value:
                                                _vm.all_values[
                                                  "od_prism_vertical"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "od_prism_vertical"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                id:
                                                  "od_prism_vertical_direction",
                                                value:
                                                  _vm.all_values[
                                                    "od_prism_vertical_direction"
                                                  ],
                                                type: "text",
                                                name:
                                                  "od_prism_vertical_direction"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "od_prism_vertical_direction"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyeVerticalDirectionOptions,
                                              value:
                                                _vm.all_values[
                                                  "od_prism_vertical_direction"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "od_prism_vertical_direction"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("tr", { staticClass: "prescription-row" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header"
                                    },
                                    [_vm._v("OS (Left)")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                id: "osprismhorizontal",
                                                value:
                                                  _vm.all_values[
                                                    "os_prism_horizontal"
                                                  ],
                                                type: "text",
                                                name: "os_prism_horizontal"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "os_prism_horizontal"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyePrismHorizontalOptions,
                                              value:
                                                _vm.all_values[
                                                  "os_prism_horizontal"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "os_prism_horizontal"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                id:
                                                  "os_prism_horizontal_direction",
                                                value:
                                                  _vm.all_values[
                                                    "os_prism_horizontal_direction"
                                                  ],
                                                type: "text",
                                                name:
                                                  "os_prism_horizontal_direction"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "os_prism_horizontal_direction"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyeHorizontalDirectionOptions,
                                              value:
                                                _vm.all_values[
                                                  "os_prism_horizontal_direction"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "os_prism_horizontal_direction"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                id: "os_prism_vertical",
                                                value:
                                                  _vm.all_values[
                                                    "os_prism_vertical"
                                                  ],
                                                type: "text",
                                                name: "os_prism_vertical"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "os_prism_vertical"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyePrismVerticalOptions,
                                              value:
                                                _vm.all_values[
                                                  "os_prism_vertical"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "os_prism_vertical"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                id:
                                                  "os_prism_vertical_direction",
                                                value:
                                                  _vm.all_values[
                                                    "os_prism_vertical_direction"
                                                  ],
                                                type: "text",
                                                name:
                                                  "os_prism_vertical_direction"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "os_prism_vertical_direction"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyeVerticalDirectionOptions,
                                              value:
                                                _vm.all_values[
                                                  "os_prism_vertical_direction"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "os_prism_vertical_direction"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          : _c(
                              "table",
                              {
                                staticClass:
                                  "prescription-data pd-data_my-account",
                                attrs: { border: "0" }
                              },
                              [
                                _vm._m(3),
                                _vm._v(" "),
                                _c("tr", { staticClass: "prescription-row" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header"
                                    },
                                    [_vm._v("PRISM HORIZ.")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                value:
                                                  _vm.all_values[
                                                    "od_prism_horizontal"
                                                  ],
                                                type: "text",
                                                name: "od_prism_horizontal"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "od_prism_horizontal"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyePrismHorizontalOptions,
                                              value:
                                                _vm.all_values[
                                                  "od_prism_horizontal"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "od_prism_horizontal"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                value:
                                                  _vm.all_values[
                                                    "os_prism_horizontal"
                                                  ],
                                                type: "text",
                                                name: "os_prism_horizontal"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "os_prism_horizontal"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyePrismHorizontalOptions,
                                              value:
                                                _vm.all_values[
                                                  "os_prism_horizontal"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "os_prism_horizontal"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("tr", { staticClass: "prescription-row" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header"
                                    },
                                    [_vm._v("HORIZ. DIRECTION")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                value:
                                                  _vm.all_values[
                                                    "od_prism_horizontal_direction"
                                                  ],
                                                type: "text",
                                                name:
                                                  "od_prism_horizontal_direction"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "od_prism_horizontal_direction"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyeHorizontalDirectionOptions,
                                              value:
                                                _vm.all_values[
                                                  "od_prism_horizontal_direction"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "od_prism_horizontal_direction"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                value:
                                                  _vm.all_values[
                                                    "os_prism_horizontal_direction"
                                                  ],
                                                type: "text",
                                                name:
                                                  "oS_prism_horizontal_direction"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "os_prism_horizontal_direction"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyeHorizontalDirectionOptions,
                                              value:
                                                _vm.all_values[
                                                  "os_prism_horizontal_direction"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "os_prism_horizontal_direction"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("tr", { staticClass: "prescription-row" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header"
                                    },
                                    [_vm._v("PRISM VERT.")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                value:
                                                  _vm.all_values[
                                                    "od_prism_vertical"
                                                  ],
                                                type: "text",
                                                name: "od_prism_vertical"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "od_prism_vertical"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyePrismVerticalOptions,
                                              value:
                                                _vm.all_values[
                                                  "od_prism_vertical"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "od_prism_vertical"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                value:
                                                  _vm.all_values[
                                                    "os_prism_vertical"
                                                  ],
                                                type: "text",
                                                name: "os_prism_vertical"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "os_prism_vertical"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyePrismVerticalOptions,
                                              value:
                                                _vm.all_values[
                                                  "os_prism_vertical"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "os_prism_vertical"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("tr", { staticClass: "prescription-row" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "prescription-cell prescription-header"
                                    },
                                    [_vm._v("VERT. DIRECTION")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                value:
                                                  _vm.all_values[
                                                    "od_prism_vertical_direction"
                                                  ],
                                                type: "text",
                                                name:
                                                  "od_prism_vertical_direction"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "od_prism_vertical_direction"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyeVerticalDirectionOptions,
                                              value:
                                                _vm.all_values[
                                                  "od_prism_vertical_direction"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "od_prism_vertical_direction"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "prescription-cell value" },
                                    [
                                      !_vm.edit
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "prescription-input",
                                              attrs: {
                                                value:
                                                  _vm.all_values[
                                                    "os_prism_vertical_direction"
                                                  ],
                                                type: "text",
                                                name:
                                                  "os_prism_vertical_direction"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.all_values[
                                                      "os_prism_vertical_direction"
                                                    ]
                                                  ) +
                                                  "\n                                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.edit
                                        ? _c("CustomSelect", {
                                            attrs: {
                                              options:
                                                _vm.eyeVerticalDirectionOptions,
                                              value:
                                                _vm.all_values[
                                                  "os_prism_vertical_direction"
                                                ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setPrescriptionValue(
                                                  $event.target.value,
                                                  "os_prism_vertical_direction"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                      ])
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "my-prescription-list__actions" }, [
              _c(
                "ul",
                {
                  staticClass: "my-prescription-list__actions-list",
                  class: { edit: _vm.edit }
                },
                [
                  _c("li", { staticClass: "my-prescription-edit-button" }, [
                    _c(
                      "div",
                      { staticClass: "button button--copy-border-sm edit" },
                      [
                        !_vm.edit
                          ? _c(
                              "div",
                              {
                                staticClass: "button__copy button-edit",
                                on: {
                                  click: function($event) {
                                    return _vm.editRX(
                                      _vm.prescription.prescription_id
                                    )
                                  }
                                }
                              },
                              [_vm._v("edit")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit
                          ? _c(
                              "div",
                              {
                                staticClass: "button__copy button-save",
                                on: {
                                  click: function($event) {
                                    return _vm.sendForm()
                                  }
                                }
                              },
                              [_vm._v("save")]
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.prescription.prescription_id && _vm.edit
                    ? _c("li", [
                        _c(
                          "div",
                          { staticClass: "primary button-delete" },
                          [
                            _c("BaseButton", {
                              staticClass:
                                "button--copy-solid-white button--copy-border-sm delete",
                              attrs: { "data-size": "xs", text: "Delete" },
                              nativeOn: {
                                click: function($event) {
                                  _vm.deleteConfirmation = true
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.edit && _vm.rxHistory[0]
                    ? _c(
                        "li",
                        {
                          staticClass: "my-prescription-list__history-container"
                        },
                        [
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "Last Edited: " +
                                  _vm._s(
                                    _vm.getHistoryDate(
                                      _vm.rxHistory[_vm.rxHistory.length - 1]
                                        .when
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "Edited by: " +
                                  _vm._s(
                                    _vm.rxHistory[_vm.rxHistory.length - 1]
                                      .edited_by
                                  )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "button button--copy",
                              on: {
                                click: function($event) {
                                  return _vm.openModal()
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "button__copy" }, [
                                _vm._v("View History")
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _vm.deleteConfirmation
              ? _c("div", { staticClass: "my-prescription-delete" }, [
                  _c("div", { staticClass: "my-prescription-delete__inner" }, [
                    _c("p", [
                      _vm._v(
                        "Are you sure you want to delete this prescription?"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "actions-toolbar" }, [
                      _c(
                        "div",
                        { staticClass: "secondary" },
                        [
                          _c("BaseButton", {
                            staticClass:
                              "button--copy-solid-white button--copy-border-sm delete",
                            attrs: {
                              loader: _vm.deleting,
                              "data-size": "xs",
                              text: "Yes, Delete"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.deletePrescription($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "secondary" },
                        [
                          _c("BaseButton", {
                            staticClass:
                              "button--copy-solid-white button--copy-border-sm keep",
                            attrs: { "data-size": "xs", text: "No, Keep" },
                            nativeOn: {
                              click: function($event) {
                                _vm.deleteConfirmation = false
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("History", {
              attrs: {
                history: _vm.history,
                name: _vm.prescription.name,
                "rx-history": _vm.rxHistory,
                type: _vm.getRXTypes(_vm.prescription.prescription_type)
              },
              on: {
                close: function($event) {
                  return _vm.hideModal()
                }
              }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "prescription-row" }, [
      _c(
        "td",
        {
          staticClass: "prescription-cell prescription-header",
          attrs: { width: "30%" }
        },
        [_vm._v("RX")]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "prescription-cell prescription-header" }, [
        _vm._v("OD (Right)")
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "prescription-cell prescription-header" }, [
        _vm._v("OS (Left)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "prescription-row" }, [
      _c(
        "td",
        {
          staticClass: "prescription-cell prescription-header",
          staticStyle: { "white-space": "nowrap" }
        },
        [_vm._v("Notes")]
      ),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "prescription-cell value", attrs: { colspan: "2" } },
        [
          _vm._v(
            "\n                            Notes for the prescription...\n                        "
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "prescription-row" }, [
      _c(
        "td",
        {
          staticClass: "prescription-cell prescription-header",
          attrs: { width: "16%" }
        },
        [_vm._v("Prism")]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "prescription-cell prescription-header" }, [
        _vm._v("Prism Horiz.")
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "prescription-cell prescription-header" }, [
        _vm._v("Horiz. Direction")
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "prescription-cell prescription-header" }, [
        _vm._v("Prism Vert.")
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "prescription-cell prescription-header" }, [
        _vm._v("Vert. Direction")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "prescription-row" }, [
      _c(
        "td",
        {
          staticClass: "prescription-cell prescription-header",
          attrs: { width: "30%" }
        },
        [_vm._v("PRISM")]
      ),
      _vm._v(" "),
      _c("td", { staticClass: "prescription-cell prescription-header" }, [
        _vm._v("OD (Right)")
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "prescription-cell prescription-header" }, [
        _vm._v("OS (Left)")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }