var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "contacts-grid" } },
    [
      _vm.label
        ? _c("label", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.label,
                expression: "label"
              }
            ],
            staticClass: "search-title"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("v-text-field", {
        staticClass: "search-field",
        attrs: {
          placeholder: "Search (ie. Acuvue Moist)",
          "persistent-hint": ""
        },
        model: {
          value: _vm.query,
          callback: function($$v) {
            _vm.query = $$v
          },
          expression: "query"
        }
      }),
      _vm._v(" "),
      !_vm.isMobile || _vm.query
        ? _c(
            "ul",
            { staticClass: "products-grid" },
            _vm._l(_vm.items, function(product, index) {
              return _c(
                "li",
                { key: "product-item-" + index, staticClass: "item" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "item-box",
                      class: { active: _vm.activeSku === product.sku },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function($event) {
                          return _vm.addProduct(product)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "item-image" }, [
                        _c("img", {
                          staticClass: "thumbnail",
                          attrs: { src: product.thumbnail, alt: product.name }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-content" }, [
                        _c("h4", { staticClass: "name" }, [
                          _c("strong", {
                            directives: [
                              {
                                name: "dompurify-html",
                                rawName: "v-dompurify-html",
                                value: product.defaultName,
                                expression: "product.defaultName"
                              }
                            ]
                          }),
                          _vm._v(" "),
                          _c("span", {
                            directives: [
                              {
                                name: "dompurify-html",
                                rawName: "v-dompurify-html",
                                value: product.second_title,
                                expression: "product.second_title"
                              }
                            ]
                          })
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: product.lensTypes,
                              expression: "product.lensTypes"
                            }
                          ],
                          staticClass: "lens-types"
                        })
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }