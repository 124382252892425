<template>
    <div>
        <template v-if="isLensablPlus">
            <div class="totals-price-regular total-line">
                <span class="total-label total-dan label">Total</span>
                <span class="total-value value">
                    <span class="total-value-currency currency">{{ currencySymbol }}</span>
                    <span v-dompurify-html="String(redeemTotalAmount)" class="total-value-amount amount"></span>
                </span>
            </div>
        </template>
        <template v-else-if="!showFramesPrice">
            <div v-if="subTotalAmountLenses > 0 && hasCustomerMembership" class="totals-price-subtotal total-line subtotal">
                <span class="total-label label">Lenses</span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ currencySymbol }}</span>
                    <span v-dompurify-html="subTotalAmountLenses" class="total-value-amount"></span>
                </span>
            </div>
            <MemberDiscountSummary :class-name="'plus-benefit-discount'"/>
            <div class="totals-price-regular total-line main-label">
                <span class="total-label total-dan label">Total</span>
                <span class="total-value value">
                    <span class="total-value-currency currency">{{ currencySymbol }}</span>
                    <span v-dompurify-html="String(totalAmountDiscounted)" class="total-value-amount amount"></span>
                </span>
            </div>
        </template>
        <template v-else-if="!isLensablPlus">
            <div v-if="subTotalAmountLenses > 0" class="totals-price-subtotal total-line subtotal">
                <span class="total-label label">Lenses</span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ currencySymbol }}</span>
                    <span v-dompurify-html="subTotalAmountLenses" class="total-value-amount"></span>
                </span>
            </div>
            <div class="totals-price-subtotal total-line subtotal">
                <span v-dompurify-html="framesPartnerPage ? 'Frames' : 'Spectacles'" class="total-label label"></span>
                <span class="total-value value">
                    <span class="total-value-currency">{{ currencySymbol }}</span>
                    <span v-dompurify-html="subTotalAmountFrames ? subTotalAmountFrames : '0'" class="total-value-amount"></span>
                </span>
            </div>
            <MemberDiscountSummary :class-name="'plus-member-discount'"/>
            <div class="total-line frame-total">
                <span class="total-label total-dan label">Total</span>
                <span class="total-value value">
                    <span class="total-value-currency currency">{{ currencySymbol }}</span>
                    <span v-if="!isLensablPlus" v-dompurify-html="totalAmountDiscounted" class="total-value-amount amount"></span>
                    <span v-else v-dompurify-html="redeemTotalAmount" class="total-value-amount amount"></span>
                </span>
            </div>
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import summary from './storage/summary'
import MemberDiscountSummary from "./MemberDiscountSummary.vue";

export default {
    name: 'TotalsPrice',
    components: {
        MemberDiscountSummary
    },
    props: {
        place: {
            type: [String],
            default: ''
        }
    },
    computed: {
        ...mapGetters('values', ['values', 'checkoutData', 'framesPartnerPage', 'currencySymbol']),
        ...mapGetters('configurator', ['isSpectacles']),
        ...mapGetters('summary', [
            'totalSummaryContacts',
            'redeemDiscountContacts',
            'redeemDiscount',
            'totalAmountDiscounted',
            'totalSummary',
            'showFramesPrice',
            'discountAmount',
            'framesPrice',
            'totalSubscribedSummary',
            'subTotalAmountFrames',
            'subTotalAmountLenses',
            'redeemTotalAmount',
            'totalSummaryContactsDiscounted'
        ]),
        ...mapGetters('membershipProgram', [
            'hasCustomerMembership',
        ]),
        ...mapGetters('lensablplus_customer', ['isLensablPlus'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['summary/']) {
            this.$store.registerModule('summary', summary(this.$store))
        }
        this.$store.dispatch('summary/fetchDefaultData')
    }
}
</script>
