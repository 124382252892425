<template>
    <Loader v-if="loading"/>
    <v-form v-else ref="formProvider" v-model="formValid" lazy-validation @submit.prevent="updateForm"
            class="anagram-form-container anagram-form2-container">
        <v-layout>
            <v-flex>
                <div class="flex-column" style="width: 100%">
                    <label class="form-label">First Name:</label>
                    <TextField :form-model="formModel"
                               :form-key="'first_name'"
                               :label="''"
                               :err-text="'First Name Required'"/>
                </div>
            </v-flex>
            <v-flex>
                <div class="flex-column" style="width: 100%">
                    <label class="form-label">Last Name:</label>
                    <TextField :form-model="formModel"
                               :form-key="'last_name'"
                               :label="''"
                               :rules="v => (!!v && v.length >= 2)"
                               :err-text="'Last Name Required'"/>
                </div>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex>
                <div class="flex-column" style="width: 100%">
                    <label class="form-label">Date of Birth (MM/DD/YYYY):</label>
                    <BrthDatePicker
                        :form-model="formModel"
                        :label="''"
                        :form-key="'dob'"
                        :err-text="'Date of Birth Required'"
                    />
                </div>
            </v-flex>
            <v-flex>
                <div class="flex-column" style="width: 100%">
                    <label class="form-label">Last four digits of SSN:</label>
                    <TextField :form-model="formModel"
                               :form-key="'ssn_last_four'"
                               :label="''"
                               :rules="v => (!isNaN(parseInt(v)) && v.length === 4)"
                               :err-text="'Last four digits of SSN Required'"/>
                </div>
            </v-flex>
        </v-layout>
        <v-layout class="anagram-form-top">
            <div class="flex-column" style="width: 100%">
                <label class="form-label">Vision Plan Provider:</label>
                <SelectField
                    :form-model="formModel"
                    :options="companies"
                    :label="''"
                />
            </div>
        </v-layout>
        <v-layout>
            <v-flex>
                <div class="flex-column" style="width: 100%">
                    <label class="form-label">Insurer Member Id{{ isMemberIdRequired ? ``:` (Optional)`}}:</label>
                    <TextField :form-model="formModel"
                               :form-key="'member_id'"
                               :label="''"
                               :rules="v=>{
                                   return isMemberIdRequired ? !!v :true
                               }"
                               :err-text="'Member Id Required'"/>
                </div>

            </v-flex>
            <v-flex>
                <div class="flex-column" style="width: 100%">
                    <label class="form-label">Zip code (Optional):</label>
                    <TextField :form-model="formModel"
                               :form-key="'zip'"
                               :label="''"
                               :rules="v=>true"
                               :err-text="''"/>
                </div>
            </v-flex>
        </v-layout>
        <v-layout class="anagram-form-top-btn-div">
            <v-btn class="button button--copy-solid" type="submit" :disabled="isValid">Submit</v-btn>
        </v-layout>
    </v-form>
</template>
<script>
import SelectField from './fields/SelectField.vue'
import TextField from './fields/TextField.vue'
import BrthDatePicker from './fields/BrthDatePicker.vue'
import {mapActions, mapGetters, mapState} from "vuex";
import Loader from "../../../loaders/Loader.vue";

export default {
    name: 'ProviderForm',
    components: {
        Loader,
        SelectField,
        TextField,
        BrthDatePicker
    },
    data: () => {
        return {
            formValid: false,
            loading: false,
            formModel: {
                first_name: '',
                last_name: '',
                dob: null,
                ssn_last_four: '',
                member_id: '',
                zip: '',
                company: null
            }
        }
    },
    created() {
        if (this.$refs.formProvider) {
            this.$refs.formProvider.resetValidation()
        }
    },
    computed: {
        ...mapState('insuranceAnagram', ['companies',]),
        isValid() {
            return !this.formValid
        },
        isMemberIdRequired(){
            return this.formModel.company === 'superior'
        }
    },
    methods: {
        ...mapActions('insuranceAnagram', ['sendToAnagramEligibilityRequest']),
        updateForm: async function () {
            if (this.$refs.formProvider.validate()) {
                this.loading = true
                this.$refs.formProvider.resetValidation()
                try {
                    const data = await this.sendToAnagramEligibilityRequest(this.formModel)
                    this.$parent.$data.patient = data
                    if (Object.keys(data.benefits).length <= 0){
                        this.$parent.$data.areBenefitsZeroAmount = true
                    }
                    this.$parent.$data.isRequestSuccessful = true
                }catch (e) {
                    this.$parent.$data.isRequestSuccessful = false
                }
                this.loading = false
                this.$parent.$data.isRequestSent = true
            }
        }
    }
}
</script>
<style scoped lang="less">
.form-label {
    text-transform: uppercase;
}
.anagram-form-top-btn-div {
    justify-content: center;

    .button.button--copy-solid {
        max-width: 210px;
        margin-top: 1rem;
    }
}
</style>

