<template>
    <v-form ref="form" v-model="formValid" lazy-validation @submit.prevent="updateForm" class="anagram-form-container">
        <v-layout class="anagram-form-top">
            <v-flex>
                <SelectField :form-model="formModel" :options="companies"/>
            </v-flex>
        </v-layout>
        <v-layout class="anagram-form-middle">
            <v-flex>
                <TextField :form-model="formModel"/>
            </v-flex>
            <v-flex>
                <BrthDatePicker :form-model="formModel"/>
            </v-flex>
        </v-layout>
        <v-layout class="anagram-form-middle" v-if="needsAdditionalInfo">
            <v-flex>
                <SelectField
                    :form-model="formModel"
                    :options="availableSex"
                    :form-key="'sex'"
                    :label="'Member’s sex'"
                    :err-text="'Member’s Sex Required'"
                />
            </v-flex>
            <v-flex>
                <TextField :form-model="formModel"
                           :form-key="'individual_plan_name'"
                           :label="'Member’s plan name'"
                           :err-text="'Member’s plan name Required'"/>
            </v-flex>
        </v-layout>
        <v-layout class="anagram-form-bottom">
            <div>
                <h5>Estimated Reimbursement:</h5>
                <span>Coverage with a vision plan can reimburse upwards of $150 if you are eligible.</span>
            </div>
            <div>
                <v-btn class="button button--copy-solid" type="submit" :disabled="!formValid || loading">Continue</v-btn>
            </div>
        </v-layout>
    </v-form>
</template>
<script>
import SelectField from './fields/SelectField.vue'
import TextField from './fields/TextField.vue'
import BrthDatePicker from './fields/BrthDatePicker.vue'
import {mapActions, mapState} from "vuex";

export default {
    name: 'HasInsuranceForm',
    components: {
        SelectField,
        TextField,
        BrthDatePicker
    },
    props: {
        formModel: {
            type: Object,
            default:() => {}
        }
    },
    data: () => {
        return {
            formValid: false,
            loading: false
        }
    },

    created() {
        if(this.$refs.form){
            this.$refs.form.resetValidation()
        }
    },
    computed: {
        ...mapState('insuranceAnagram', ['companies', 'claimAdditions', 'availableSex']),
        needsAdditionalInfo(){
            return this.claimAdditions.indexOf(this.formModel.company) !== -1
        }
    },
    methods: {
        ...mapActions('insuranceAnagram', ['sendToAnagram']),
        updateForm: async function () {
            if (this.$refs.form.validate()) {
                this.loading = true
                this.$refs.form.resetValidation()
                await this.sendToAnagram(this.formModel)
                this.loading = false
            }
        }
    }
}
</script>

