<template>
    <div v-if="memberShipEdited">
        <div v-for="(payment, index) in paymentMethods" :key="index">
            <div :id="payment.code + '-wrapper'" class="payment-wrapper">
                <label class="radio-label" :for="'p_method_' + payment.code">
                    <input
                        :id="'p_method_' + payment.code"
                        v-model="selectedPaymentMethod"
                        :value="payment.code"
                        type="radio"
                        name="payment[method]"
                        :title="payment.title"
                        class="radio payment-radio"
                    />
                    <span v-dompurify-html="payment.title"></span>
                    <HelpBubble v-if="payment.hint" :data="getHelper(payment.hint)" />
                </label>
            </div>
            <div
                v-show="payment.code === selectedPaymentMethod"
                :id="'container_payment_method_' + payment.code"
                v-dompurify-html="payment.html"
                class="payment-method"
            >
            </div>
        </div>
    </div>
    <table v-else-if="payments.length" id="membership_payment" cellspacing="0" class="form-list payment">
        <tbody>
            <tr v-for="(item, index) in payments" :key="`payments-${index}`" class="tr-line">
                <td class="label">
                    <div :class="paymentSphereClass(item)" class="payment-sphere"></div>
                    <div class="payment-inline" v-text="item.date"></div>
                </td>
                <td class="value">
                    <strong v-text="item.status"></strong>
                </td>
                <td class="label" v-text="item.total"></td>
            </tr>
        </tbody>
    </table>
    <strong v-else>No Payments</strong>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import _keys from 'lodash/keys'
import _find from 'lodash/find'
import _map from 'lodash/map'

import HelpBubble from '../../../help_bubble/HelpBubble.vue'

export default {
    name: 'LensabalplusMembershipPayment',
    components: {
        HelpBubble
    },
    data: () => ({
        currentMethod: {}
    }),
    computed: {
        ...mapState('lensablPlus/membership', ['paymentData']),
        ...mapGetters('lensablPlus/membership', ['memberShipEdited', 'currentMemberShipData', 'paymentMethods', 'recurlyPublicKey']),
        payments () {
            const obj = this.currentMemberShipData.payments || {}
            return _map(_keys(obj).sort().reverse(), key => ({...obj[key], key}))
        },
        selectedPaymentMethod: {
            get () {
                const paymentMethod = this.paymentData.payment_method
                this.changePaymentMethod(paymentMethod)
                return paymentMethod
            },
            set (payment_method) {
                this.$store.commit('lensablPlus/membership/UPDATE_PAYMENT_DATA', {payment_method})
                this.$store.dispatch('lensablPlus/membership/updateQuote', {
                    payment: {
                        method: payment_method,
                        recurly_token: '',
                        cc_type: '',
                        cc_save: 1
                    }
                })
            }
        }
    },
    methods: {
        paymentSphereClass (payment) {
            const _class = (payment['status'] || '').toLowerCase()
            if ((_class === 'paid') || (_class === 'failed')) {
                return _class
            }
        },
        changePaymentMethod (payment_code) {
            this.currentMethod = _find(this.paymentMethods, {code: payment_code})

            if (payment_code === 'recurly') {
                this.initiateRecurly()
                this.setTimeIntervalMethod('payment_form_recurly', this.initiateRecurly)
            }
            if (payment_code === 'amazon_payments') {
                this.setTimeIntervalMethod('payment_form_amazon_payments', this.initiateAmazonButton)
                this.setTimeIntervalMethod(this.currentMethod.config.button_id, () => {
                    const el = document.querySelector('img[id^="OffAmazonPaymentsWidgets"]')
                    el.click()
                })
            }
        },
        setTimeIntervalMethod (selector, func) {
            const intervalID = setInterval(() => {
                if (document.getElementById(selector)) {
                    func()
                    clearInterval(intervalID)
                }
            }, 500)
        },
        initiateRecurly () {
            const recurly = window.recurly
            recurly.configure({
                publicKey: this.recurlyPublicKey,
                required: ['cvv']
            })
            window.cc_type = ''
            recurly.on('change', state => {
                window.cc_type = state.fields.card.brand
            })
        },
        initiateAmazonButton () {
            const config = this.currentMethod.config

            window.AmazonPaymentsCallbacks[config.button_id] = () => {
                const AmazonButtonOptions = {
                    type: config.type,
                    color: config.color,
                    size: config.size,
                    authorization: () => {
                        window.loginOptions = {
                            scope: config.scope,
                            response_type: 'token',
                            popup: config.popup
                        }
                        const url = config.popup ? config.login_url : config.checkout_url
                        window.authRequest = window.amazon.Login.authorize(window.loginOptions, url)
                    },
                    onError: function (error) {
                        console.log(error)
                    }
                }
                if (config.language) {
                    AmazonButtonOptions.language = config.language
                }
                if (config.token_enable) {
                    AmazonButtonOptions.agreementType = 'BillingAgreement'
                    AmazonButtonOptions.onSignIn = (billingAgreement) => {
                        const amazonBillingAgreementId = billingAgreement.getAmazonBillingAgreementId()
                        window.Mage.Cookies.set('amazonBillingAgreementId', amazonBillingAgreementId)
                    }
                }

                window.OffAmazonPayments.Button(config.button_id, config.seller_id, AmazonButtonOptions)
            }
            window.AmazonPaymentsCallbacks[config.button_id]()
        },
        getHelper (text) {
            return {
                'name': 'help-bubble',
                'params': {
                    'text': text
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>

</style>
