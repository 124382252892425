<template>
    <div v-if="!memberShipEdited">
        <div>
            <table cellspacing="0" class="form-list">
                <tbody>
                    <tr>
                        <td class="label">
                            <span class="label">Everyday Eyewear Frame Redeemed?</span>
                            <strong class="value" v-text="frameItemRedeemed"></strong>
                        </td>
                        <td class="label">
                            <span class="label">Lens Replacement Benefit Used?</span>
                            <strong class="value" v-text="glassesItemRedeemed"></strong>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td class="label" colspan="2">
                            <span class="label">Non-Lensabl Frame Benefit Redeemed</span>
                            <strong class="value" v-text="nonLensablFrameItemRedeemed"></strong>
                        </td>
                    </tr> -->
                </tbody>
            </table>

            <!-- <hr />

            <table cellspacing="0" class="form-list eye-exam">
                <tbody>
                    <tr>
                        <td class="label">Reciept Uploaded</td>
                        <td class="value" width="20%">
                            <div class="upload-prescription">
                                <div class="upload-component">
                                    <div v-if="isUploading">
                                        <div class="uploading-progress">Uploading...</div>
                                    </div>

                                    <div v-if="hasFile" class="uploaded-file">
                                        <a v-if="file_download_url" v-dompurify-html="fileData.fileName" :href="file_download_url" download></a>
                                        <span v-else v-dompurify-html="fileData.fileName"></span>
                                    </div>

                                    <div v-if="showError" class="error-hint">
                                        <span v-dompurify-html="errorHint"></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="label">
                            <div :class="{'invalid-form': showInvalidFormBorder}" class="file-input-box">
                                <a href="javascript:void(0)" type="button" title="Continue" class="action file-input-button">
                                    <button :disabled="!hasNoFile" :class="{'disabled': !hasNoFile}">Upload Reciept</button>
                                    <input v-if="hasNoFile" type="file" name="prescription" accept="image/*,application/pdf" @change="previewFiles" />
                                </a>
                                <button :disabled="approved" :class="{'disabled': approved}" class="approve" @click="approvedReceiptRewards">Approve (Sends Gift Card)</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr v-show="!approved" />

            <table v-show="!approved" cellspacing="0" class="form-list eye-exam">
                <tbody>
                    <tr>
                        <td class="value">
                            <textarea v-model="textMessage" rows="5" placeholder="Message..."></textarea>
                        </td>
                        <td class="scope-label">
                            <label>
                                <input v-model="rejection" type="checkbox" />
                                <span>Notify customer of rejection</span>
                            </label>
                        </td>
                        <td class="scope-label">
                            <button class="submit" @click="submitMessage">Submit</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr /> -->

            <table v-if="messages.length" cellspacing="0" class="form-list eye-exam">
                <thead>
                    <th>History</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="(message, index) in messages" :key="`message-${index}`" class="tr-line">
                        <td v-dompurify-html="message.message" class="value"></td>
                        <td class="scope-label">
                            <strong v-text="message.status"></strong>
                        </td>
                        <td class="scope-label">
                            <strong v-if="message.admin_firstname" v-text="`by ${message.admin_firstname} ${message.admin_lastname}`"></strong>
                            <small v-if="message.admin_email" v-text="`(${message.admin_email})`"></small>
                        </td>
                        <td class="scope-label" v-text="message.created_at"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <BaseLoader v-if="loader || !dataLoaded" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import MembershipEyeExam from './EyeExam.vue'
const BOOLEAN_LABELS = {
    0: 'No',
    1: 'Yes'
}

const STATUS_REDEEMED = [2, 5, 1]

export default {
    name: 'LensabalplusMembershipContactLens',
    extends: MembershipEyeExam,
    data: () => ({
        labels: BOOLEAN_LABELS
    }),
    computed: {
        ...mapGetters('lensablPlus/membership', ['']),
        frameItemRedeemed () {
            return this.labels[parseInt(this.getRedeemItemData('frames').has_redeemed) === 1 ? 1 : 0]
        },
        glassesItemRedeemed () {
            const redeemed = this.getRedeemItemData('glasses_progressive').has_redeemed || this.getRedeemItemData('glasses_single_vision').has_redeemed
            return this.labels[parseInt(redeemed) === 1 ? 1 : 0]
        },
        nonLensablFrameItemRedeemed () {
            const redeemStatus = parseInt(this.getRedeemItemData('frames').has_redeemed)
            return this.labels[STATUS_REDEEMED.indexOf(redeemStatus) !== -1 ? 1 : 0]
        }
    }
}
</script>
