<template>
    <div>
        <Search />
        <div class="contacts-filtered-product-section">
            <SideBar />
            <Products />
        </div>
    </div>
</template>

<script>
import CatalogCategoryView from '../../catalog/category/CatalogCategoryView.vue'
import Products from '../Products.vue'
import SideBar from '../SideBar.vue'
import Search from '../Search.vue'

export default {
    name: 'CatalogContactsCategoryView',
    components: {
        SideBar,
        Products,
        Search
    },
    extends: CatalogCategoryView,
    created () {
        this.$store.dispatch('contacts/clearProductAddToCart')
    }
}
</script>
