<template>
    <div class="not-found">
        <p>Component not found</p>
    </div>
</template>

<script>
import App from '../App.vue'

export default {
    name: 'NotFound',
    extends: App
}
</script>
