<template>
    <div class="open-order">
        <paginate ref="paginator" :list="orders" :per="4" name="orders" class="paginate-list">
            <div v-for="order in paginated('orders')" :key="order.incrementId">
                <div v-if="hasOrders">
                    <div class="open-order_container">
                        <div class="open-order_header">
                            <div class="open-order__number">
                                <div class="order-date open-order_header_item date order-number">
                                    <span>{{ order.orderDate }}</span>
                                    <br />
                                    <span id="order-number"># {{ order.incrementId }}</span>
                                </div>
                            </div>
                            <div class="open-order__items">
                                <div class="open-order_header_item details">
                                    <ul>
                                        <li v-for="(item, index) in order.items" :key="`key-${item.name}-${index}`">
                                            <ul>
                                                <li class="open-orders-single-view__bold-item">
                                                    <span v-dompurify-html="item.name === 'goodr Frames - Mick And Keith Midnight Ramble' ? 'goodr Frames - Mick & Keith Midnight Ramble' : item.name"></span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="open-order__status">
                                <div class="order-status open-order_header_item message">
                                    <div v-if="order.displayMissingMessage || order.orderMessage === 'complete_3_missing'">
                                        <span class="status-missing-rx">Missing Information </span>
                                        <div v-for="(missing, index) in order.missing" :key="`${order.incrementId}-${missing}-${index}`">
                                            <span class="status-missing"> - {{ missing }} is required to process your order</span>
                                        </div>
                                        <span class="status-missing-rx--detail">Please click the View button and update your prescription</span>
                                    </div>
                                    <div v-else-if="order.isPol && order.status === 'complete_1'">
                                        <span>Please print your label</span>
                                    </div>
                                    <div v-else>
                                        <span>{{ order.orderMessage }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="open-order__view">
                                <div class="order-number open-order_header_item view-btn">
                                    <ul class="header-menu-list">
                                        <li>
                                            <a :href="order.viewUrl" title="View Order Details">View</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="overview-no-data">
                        <p class="overview-no-data__copy">You Do Not Have Any Orders.</p>
                        <a href="/choose-my-lenses">
                            <div class="button button--copy-solid">
                                <div class="button__container">shop our lenses now</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </paginate>
        <div class="page-number-display">
            <span>Page</span>
            <paginate-links for="orders" />
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import orders from '../orders/storage/orders'

export default {
    name: 'CustomerOrder',
    data: () => ({
        paginate: ['orders']
    }),
    computed: {
        ...mapState('orders', ['allOrders']),
        orders () {
            return this.allOrders.orders
        },
        hasOrders () {
            return this.orders.length > 0
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['orders/']) {
            this.$store.registerModule('orders', orders(this.$store), {preserveState: false})
        }
    },
    created () {
        this.fetchOrderState()
    },
    methods: {
        ...mapActions('orders', ['fetchOrderState'])
    }
}
</script>

<style scoped>

</style>
