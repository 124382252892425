<template>
    <div v-if="affirmPrice">
        <div :data-amount="affirmPrice" class="affirm-as-low-as" data-page-type="product">
            <span>Starting at</span>
            <span v-dompurify-html="affirmPayment" class="affirm-ala-price"></span>/mo with <span class="__affirm-logo">Affirm</span>
            <a href="javascript:void(0)" @click="affirmModalShow()">Learn more</a>
        </div>
        <div class="accrue-section">
            <span>or save up and earn <span class="accrue-percentage-summary">15%</span> in cash rewards with <span><img src="/media/wysiwyg/accrue-logo-black.png" class="accrue-logo-black-img" /></span></span>
        </div>
    </div>
</template>

<script>
import TotalsPrice from './TotalsPrice.vue'
import {mapGetters} from 'vuex'

export default {
    name: 'Affirm',
    extends: TotalsPrice,
    computed: {
        ...mapGetters('lensablplus_customer', ['isLensablPlus']),
        ...mapGetters('summary', ['redeemTotalAmount']),
        affirmPayment () {
            return '$' + Math.round(this.affirmPrice / 11 / 100)
        },
        affirmPrice () {
            const configuredPrice = this.totalAmount
            if (!configuredPrice) {
                return 0
            }
            return this.isLensablPlus ? this.redeemTotalAmount * 100 : this.totalAmount * 100
        }
    },
    watch: {
        totalAmount () {
            this.$nextTick(() => {
                this.refreshAffirmUi()
            })
        }
    },
    mounted () {
        this.refreshAffirmUi()
    },
    methods: {
        refreshAffirmUi () {
            if (window.affirm) {
                const ui = window.affirm['ui'] || {}
                if (ui.refresh) {
                    ui.refresh()
                }
            }
        },
        affirmModalShow () {
            if (window.affirm) {
                const ui = window.affirm['ui'] || {}
                if (ui.openModal) {
                    ui.openModal()
                }
            }
        }
    }
}
</script>
