<template>
    <div class="swatch-attribute color">
        <div v-if="options.length>1">
            <select v-model="currentIndex" @change="changeOption">
                <template v-for="(option, index) in options">
                    <option :key="index" :value="index">
                        {{ option.drop_down_options }}
                    </option>
                </template>
            </select>
        </div>
    </div>
</template>
<script>
import {
    EVENT_CHANGE_CURRENT_OPTION
} from '../../../../app/utils/constants'

export default {
    name: 'DropDown',
    props: {
        options: {
            type: [Object, Array],
            default: () => {
            }
        },
        currentOption: {
            type: [String, Number],
            default: ''
        },
    },
    data: () => {
        return {
            currentIndex: null,
            EVENT_CHANGE_CURRENT_OPTION: EVENT_CHANGE_CURRENT_OPTION
        }
    },
    created() {
      this.currentIndex = this.currentOption
    },
    methods: {
        changeOption(){
            this.$emit(EVENT_CHANGE_CURRENT_OPTION, this.currentIndex)
        }
    }
}
</script>
