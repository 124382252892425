import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import {
    DISABLED_LENS_FRAMES
} from '../../../../app/common/constants/antireflective'

import SelectorOptions from '../lens_type/SelectorOptionsLensType'
import {
    DISABLE_LENS_OPTIONS_BY_RX_AND_COLORS, DISABLE_LENS_OPTIONS_BY_RX_AND_LENS_TYPE,
} from './contants/lens_options'

export default {
    name: 'SelectorOptionsLensOption',
    extends: SelectorOptions,
    methods: {
        computedOptionsWhichAreConfigured () {
            const config = this.product_config || {}
            const product_config = config[this.productType]
            if (!product_config) {
                return []
            }

            let lens_type = this.lensType
            if (!lens_type) {
                lens_type = 'Clear'
            }

            const optionsConfig = product_config.config || product_config
            const lensType = _find(optionsConfig, lensTypeConfig => lensTypeConfig.label === lens_type)
            if (!lensType) {
                return []
            }

            const availableOptions = lensType.options
            if (!availableOptions) {
                return []
            }

            const availableOptionValues = _map(availableOptions, option => !option.hidden ? option.label : false)

            // filter Hobie lens options
            if (this.framesPartnerPage === 'hobie_sunglasses') {
                if (lens_type === 'Sun Polarized' && (this.productType === 'single_vision_reading' || this.productType === 'single_vision_distance')) {
                    const hobieSku = this.values.product_sku
                    const isHobie = hobieSku.includes('bluefin') || hobieSku.includes('coastal') || hobieSku.includes('mojo') || hobieSku.includes('monarch')

                    if (isHobie) {
                        this.options = _filter(this.options, {id: 'trivex'})
                    } else {
                        this.options = _filter(this.options, (option) => option.id !== 'trivex')
                    }
                }
            }
            // end Hobie

            // filter Bose lens options
            if (this.values.frames_SKU === 'tempo' && this.productType === 'progressive_premium' && lens_type === 'Sun Mirrored Polarized') {
                this.options = _filter(this.options, (option) => option.id !== '167')
            }
            // end Bose

            const disabledByRX = DISABLE_LENS_OPTIONS_BY_RX_AND_COLORS[this.productType] || {}
            const disabledByColor = disabledByRX[this.lensType] || {}
            const color = this.checkoutData.lens_type ? this.checkoutData.lens_type.color : null


            // filter sunglasses on lens replacement
            const disabledByRXAndLens = DISABLE_LENS_OPTIONS_BY_RX_AND_LENS_TYPE[this.productType] || {}
            const disabledByLensType = disabledByRXAndLens[this.lensType] || []

            //filter partner enable options
            const enabledByPartner = this.getLimitersEnabledOptions || []
            const enabledByPartnerByColor = this.getLimitersEnabledByColor || {}
            const enabledByPartnerAndLensType = this.getLimitersEnabledOptionsByLensType || {}


            _filter(this.options, option => option.hidden = false)
            return _filter(this.options, option => {
                if (this.framesPartnerPage && DISABLED_LENS_FRAMES.indexOf(option.id) !== -1) {
                    option.hidden = true
                }
                if (disabledByLensType.indexOf(option.id) !== -1){
                    option.hidden = true
                }
                const disabledColors = disabledByColor[option.id] || []
                if (disabledColors.length && disabledColors.indexOf(color) !== -1){
                    option.hidden = true
                }
                if (enabledByPartner.length){
                    if (enabledByPartner.indexOf(option.id) !== -1){
                        option.hidden = false
                    }else{
                        option.hidden = true
                    }
                }
                if (Object.keys(enabledByPartnerAndLensType).length){
                    const enabledOptionsByLensType = enabledByPartnerAndLensType[this.lensType] || []
                    if (enabledOptionsByLensType.length){
                        if(enabledOptionsByLensType.indexOf(option.id) !== -1){
                            option.hidden = false
                        }else{
                            option.hidden = true
                        }
                    }
                }
                if (color && Object.keys(enabledByPartnerByColor).length){
                    const enabledOptionsByColor = enabledByPartnerByColor[this.lensType] || {}
                    if (enabledOptionsByColor[option.id] && disabledColors.indexOf(color) !== -1){
                        option.hidden = true
                    }
                }

                return availableOptionValues.indexOf(option.checkout_value) !== -1 &&
                    !option.hidden &&
                    (!this.limitersDisabled.includes(option.id) || this.limitersEnabled.includes(option.id))
            })
        }
    }
}
