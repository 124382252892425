export const LENS_TYPE_ID = 'lens_type'
export const LENS_TYPE_SECTION_ID = 'lens_type_name'
export const NON_PRESCRIPTION_ID ='nonprescription'
export const LENS_TYPE_DESCRIPTION = {
    'progressive': 'Multi-focal lenses with no visible line that give a seamless progression for distance, intermediate, and near vision correction.'
}

export const LENS_TYPE_ID_SUNGLASSES_LENSES = 'sunglasses_lenses'
export const LENS_TYPE_ID_CLEAR_LENSES = 'clear_lenses'
export const LENS_TYPE_ID_COMPUTER_LENSES = 'computer_lenses'
export const LENS_TYPE_ID_TRANSITIONS_LENSES = 'transitions_lenses'
export const CHILD_BLOCK_DESCRIPTION = {
    single_vision_premium:{
        id: 'single_vision_premium',
        label: 'Premium Lenses',
        description: 'Digital HD lenses for sharper optics',
        'manufacturer': '(Shamir Spectrum lenses)',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<b>Shamir Spectrum Lenses.</b><br/>Digital Single Vision Lenses for sharper optics. <br/><br/>Provides clear, crisp vision all with an aspheric/atoric back-side. Aspheric/atoric lenses can be lighter due to decreased center thickness, and for those with high prescriptions, an aspheric/atoric lens means better clarity because of minimized off-center errors.',
            }
        },

    },
    single_vision_standard: {
        id: 'single_vision_standard',
        label: 'Standard Lenses',
        description: 'Our in-house single vision lens',
        'manufacturer': '(Essilor lenses)',
    }
}
