var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "online-optometry__container" }, [
    _c("div", { staticClass: "hero-image__background success_wink" }, [
      _c("div", { staticClass: "hero-video-button__row" }, [
        _c("div", { staticClass: "online-optometry__layout" }, [
          _c("div", { staticClass: "online-optometry__copy" }, [
            _vm.QUALIFICATIONS
              ? _c(
                  "div",
                  { staticClass: "form-action btn-vertical qualify-section" },
                  [
                    _c("h1", [_vm._v("Congrats, You Qualify!")]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm.showPrice
                      ? _c(
                          "p",
                          { staticClass: "hero-video-button__copy-secondary" },
                          [_vm._v("$25.00 — for a limited time!")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "button btn-full-inline button--copy-solid-dark",
                        on: { click: _vm.ADD_TO_CART }
                      },
                      [_c("span", [_vm._v("Get Started with your Exam")])]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.QUALIFICATIONS
              ? _c("div", { staticClass: "form-action not-qualified" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Based on your responses, it has been determined that we are unable to issue you an Rx virtually, and do recommend you see a doctor in-person for a new Rx.  "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "With that said, if you are in need of new contacts, frames, or lenses, we do have some good news for you! You can save big by shopping at Lensabl."
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "hero-video-button__copy-secondary" },
                    [
                      _vm._v(
                        "Click below and your discount will be displayed at the top of the page:"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4)
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "You are eligible to take our online eye exam. To continue, click "
      ),
      _c("i", [_vm._v("get started with your exam")]),
      _vm._v(
        " below, and after you checkout you can proceed with your online vision test."
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _vm._v("Unfortunately you do not qualify"),
      _c("br"),
      _vm._v("to take the vision test 😔")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "button btn-full-inline button--copy-solid-dark" },
      [
        _c("a", { attrs: { href: "/shop-contacts", title: "" } }, [
          _c("span", [_vm._v("SHOP CONTACTS")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "button btn-full-inline button--copy-solid-dark" },
      [
        _c("a", { attrs: { href: "/shop-frames" } }, [
          _c("span", [_vm._v("SHOP FRAMES")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "button btn-full-inline button--copy-solid-dark" },
      [
        _c("a", { attrs: { href: "/choose-my-lenses" } }, [
          _c("span", [_vm._v("SHOP LENS REPLACEMENT")])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }