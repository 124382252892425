var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plus-account_container glasses-container",
      class: { redeemed: _vm.redeemedStyle }
    },
    [
      _vm.isMobile
        ? _c(
            "h4",
            { class: { active: _vm.isShow }, on: { click: _vm.toggleContent } },
            [_vm._v("Eye Exam Credit")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        !_vm.isMobile || (_vm.isShow && _vm.isMobile)
          ? _c(
              "div",
              {
                staticClass: "plus-content_container glasses-content",
                class: { redeemed: _vm.redeemedStyle }
              },
              [
                _c("div", { staticClass: "redeem-description" }, [
                  _c("div", { staticClass: "redeem-container" }, [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src:
                            "/js/lensconfigurator/assets/eyeexam_desktop.svg",
                          alt: "contact lenses"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "redeem-description" },
                      [
                        _c("span", { staticClass: "description" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.isBusinessMember
                                  ? "Reward for taking an eye exam"
                                  : "$60 credit for getting an eye exam"
                              ) +
                              "\n                        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("HelpBubble", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.redeemedStyle,
                              expression: "!redeemedStyle"
                            }
                          ],
                          attrs: { data: _vm.component_help.exam }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "secondary" },
                      [
                        _c("BaseButton", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.redeemedStyle,
                              expression: "!redeemedStyle"
                            }
                          ],
                          staticClass: "button button--copy-solid voucher",
                          class: {
                            disable: !_vm.previousvisit,
                            disabledButton: _vm.pending
                          },
                          attrs: {
                            disabled: _vm.btnDisabled("voucher") || _vm.pending,
                            text: _vm.btnTitle("voucher"),
                            "data-size": "xs"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.openEyeExamRedeem($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "redeem-container" }, [
                    _c("div"),
                    _vm._v(" "),
                    _c("span", { staticClass: "separator" }, [_vm._v("-or-")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "secondary" },
                      [
                        _c("BaseButton", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.redeemedStyle && !_vm.isMobile,
                              expression: "redeemedStyle && !isMobile"
                            }
                          ],
                          staticClass: "button button--copy-solid exam",
                          class: {
                            disable: !_vm.previousvisit,
                            disabledButton: _vm.btnDisabled(_vm.redeemItemType)
                          },
                          attrs: {
                            disabled: _vm.btnDisabled(_vm.redeemItemType),
                            text: _vm.btnTitle(_vm.redeemItemType),
                            "data-size": "xs"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.openVisionRedeem($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "redeem-container" }, [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: "/js/lensconfigurator/assets/online_exam.svg",
                          alt: "contact lenses"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "description" }, [
                          _vm._v("Online vision test for Rx renewal "),
                          !_vm.isBusinessMember
                            ? _c("span", [_vm._v("(for glasses and contacts)")])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("HelpBubble", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.redeemedStyle,
                              expression: "!redeemedStyle"
                            }
                          ],
                          attrs: { data: _vm.component_help.online }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "secondary" },
                      [
                        _c("BaseButton", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                (_vm.redeemedStyle && _vm.isMobile) ||
                                !_vm.redeemedStyle,
                              expression:
                                "redeemedStyle && isMobile || !redeemedStyle"
                            }
                          ],
                          staticClass: "button button--copy-solid",
                          class: {
                            disable: !_vm.previousvisit,
                            disabledButton: _vm.isVisionRedeemed && _vm.isMobile
                          },
                          attrs: {
                            disabled: _vm.btnDisabled(_vm.redeemItemType),
                            text: _vm.btnTitle(_vm.redeemItemType),
                            "data-size": "xs"
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.openVisionRedeem($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.redeemedStyle
                    ? _c("div", { staticClass: "redeem-location-link" }, [
                        _vm._v("Need help finding a location? "),
                        _c("a", { on: { click: _vm.redirectTDcctors } }, [
                          _vm._v("Click Here")
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("Redeem", {
        attrs: {
          title: "Vision Test",
          instruction: _vm.instruction,
          "is-modal-open": _vm.isVisionTestOpen,
          subtitle: "Vision Test Benefit"
        },
        on: {
          continue: _vm.continueToVisionTest,
          update: function($event) {
            _vm.isVisionTestOpen = false
          }
        }
      }),
      _vm._v(" "),
      _c("ExamCreditRedeem", {
        staticClass: "vision-exam-benefit",
        attrs: {
          "is-modal-open": _vm.isEyeExamOpen,
          collect: _vm.collect,
          "redeem-item-type": _vm.redeemItemType,
          subtitle: "Vision Exam Benefit",
          instruction: "(includes either Eye Exam or Contact Lens fitting)"
        },
        on: {
          update: function($event) {
            _vm.isEyeExamOpen = false
          },
          continue: _vm.pendingBenefitCredit
        }
      }),
      _vm._v(" "),
      _c("GiftCreditRedeem", {
        attrs: {
          "is-modal-open": _vm.isGiftCardOpen,
          collect: _vm.collect,
          "redeem-item-type": _vm.redeemItemType
        },
        on: {
          "update:collect": function($event) {
            _vm.collect = $event
          },
          update: function($event) {
            _vm.isGiftCardOpen = false
          },
          continue: _vm.pendingBenefitCredit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }