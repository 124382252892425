<template>
    <SunColorGuideLanding v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import SunColorGuideLanding from '../../components/suncolorguide/SunColorGuideLanding.vue'

export default {
    name: 'RouterSunglassColorGuide',
    components: {
        SunColorGuideLanding
    },
    extends: App
}
</script>
