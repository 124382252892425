var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "grid-list" } },
    [
      _vm.getLoading
        ? _c("PageLoader", { attrs: { load: _vm.getLoading } })
        : [
            _c("CatalogCategoryViewBanner", {
              staticClass:
                "spectacles-banner frames-banner lensabl-frames-banner",
              class: [
                _vm.getClass + "-banner",
                _vm.currentCategory.url_key,
                _vm.getClass === "ic!berlin" ? "icberlin-banner" : ""
              ],
              attrs: {
                category: _vm.currentCategory,
                "category-class": _vm.getClass
              }
            }),
            _vm._v(" "),
            _c("LensesInclude"),
            _vm._v(" "),
            _c("TermsInformation"),
            _vm._v(" "),
            _c("NavigationFilters", {
              staticClass: "category-frames-tabs",
              attrs: {
                "list-title": "Filters",
                load: _vm.getLoadingProducts,
                categories: _vm.categoriesList,
                "filters-material": _vm.getFiltersList["frames_material"],
                "filters-size": _vm.getFiltersList["frames_size"],
                "selected-filters": _vm.getSelectedFilters,
                "current-id": _vm.currentCategoryId
              },
              on: _vm._d({}, [
                _vm.EVENT_CATEGORY_SELECTED,
                _vm.updateFilteredProducts
              ])
            }),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "pagination-grid" } },
              [
                _c("CatalogProductList", {
                  key: "products-" + _vm.getClass,
                  staticClass: "grid products-grid shop-frames-cat",
                  attrs: {
                    load: _vm.getLoadingProducts,
                    products: _vm.modifiedEEProductList
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.getLoadingBatch ? _c("div", [_c("Loader")], 1) : _vm._e(),
            _vm._v(" "),
            _c("section", { staticClass: "landing-page_ad-block" }, [
              _c("div", { staticClass: "ad-block_container" }, [
                _c("a", { attrs: { href: "/shop-contacts" } }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.lensabl.com/media/wysiwyg/email/images/EMAIL_Contacts-Ad-update.jpg",
                      alt:
                        "Shop Now button with text and woman holding contacts"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("a", { attrs: { href: "/choose-my-lenses" } }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://www.lensabl.com/media/wysiwyg/email/images/EMAIL_Lens-Replacement-Ad-update.jpg",
                      alt:
                        "Shop Now button with woman holding a pair of sunglasses"
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("FramesPageSeo", { attrs: { brand: _vm.getClass } })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }