<template>
    <div>
        <template v-if="isContactDetailsPage">
            <ul class="totals-info">
                <li v-if="getRightEyeConfirmed" class="item option-product-info">
                    <div class="label">Right eye:</div>
                    <div class="renderer-options summary-value">
                        <span v-dompurify-html="getEyeData.right.brand" class="option-value"></span>
                        <div class="option-color">{{ getEyeData.right.color }}</div>
                        <div v-if="!isRedeemFlow" class="option-quantity">{{ getQuantity('right') }}</div>
                    </div>
                </li>
                <li v-if="getLeftEyeConfirmed" class="item option-product-info">
                    <div class="label">Left eye:</div>
                    <div class="renderer-options summary-value">
                        <span v-dompurify-html="getEyeData.left.brand" class="option-value"></span>
                        <div class="option-color">{{ getEyeData.left.color }}</div>
                        <div v-if="!isRedeemFlow" class="option-quantity">{{ getQuantity('left') }}</div>
                    </div>
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'ContactsInfo',
    computed: {
        ...mapGetters('contacts', ['isContactDetailsPage', 'getEyeData', 'getLeftEyeConfirmed', 'getRightEyeConfirmed', 'getSubscribed']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow'])
    },
    methods: {
        getQuantity (eye) {
            let quantity
            if (this.getSubscribed === 'yes') {
                quantity = this.getEyeData[eye].default_sub_box_quantity
            } else {
                quantity = this.getEyeData[eye].boxes
            }
            return quantity > 1 ? `(${quantity} Boxes)` : `(${quantity} Box)`
        }
    }
}
</script>
