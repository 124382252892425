<template>
    <div>
        <PageLoader v-if="getLoad" :load="getLoad" />
        <div v-else-if="planSelected">
            <PlusDashboard />
        </div>
        <div v-else-if="componentsCount">
            <div class="lensabl-plus-content">
                <template v-for="object in componentsList">
                    <component :is="object.component.name" :id="object.id" :key="object.id" :class="['lensabl-plus-content ' + object.component.name]" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardPlans from './businessDashboard/DashboardPlans.vue'
import Dependents from './businessDashboard/Dependents.vue'
import DashboardPayment from './businessDashboard/DashboardPayment.vue'
import PlusDashboard from './PlusDashboard.vue'
import PageLoader from '../../base/PageLoader.vue'
import lensablplusBusiness from '../storage/lensablplus/business'
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'BusinessDashboard',
    components: {
        DashboardPlans,
        Dependents,
        PlusDashboard,
        PageLoader,
        DashboardPayment
    },
    computed: {
        ...mapGetters('lensablplus_customer', ['componentsList', 'componentsCount', 'values', 'firstComponent', 'currentComponent', 'businessStepsList']),
        ...mapGetters('lensablplus_business', ['getLoad', 'planSelected'])
    },
    watch: {
        values: {
            handler () {
                this.updateComponents()
            },
            deep: true
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablplus_business/']) {
            this.$store.registerModule('lensablplus_business', lensablplusBusiness(this.$store))
        }

        this.$store.dispatch('lensablplus_business/fetchDefaultData')
        this.$store.commit('lensablplus_business/LOAD', true)
        this.$store.commit('lensablplus_customer/STEPS_LIST')
        this.$store.commit('lensablplus_customer/COMPONENT_LIST')
        this.$store.dispatch('lensablplus_business/fetchBusinessPlans')
        this.$store.dispatch('lensablplus_business/fetchBusinessOfferedPlans').then(response => {
            this.$store.dispatch('lensablplus_customer/updateComponents')
        })
    },
    methods: {
        ...mapActions('lensablplus_customer', ['updateComponents'])
    }
}
</script>

<style scoped>
    .plus-account_wrappper .loader-circle {
        min-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>
