<template>
    <div>
        <RadioLabel v-if="!prescriptionData.status_4" :active="isActive" :label="title" :value="index" @click="$emit('checked', index)" />
        <div v-if="isActive && (fittingbox_status === 'taken' || fittingbox_status === 'retaken')" class="fittingbox-warning-enter">
            <br />We have your previous photo which we will process soon.
            <br />You can enter your PD below if you like, which will override it.
        </div>

        <div v-show="isActive" class="pd_selector my-prescription-form__pupillary-distance">
            <div class="pd-parameters">
                <div class="pd-label my-prescription-form__dropdown-label pupillary">
                    <span>
                        <span>Pupillary Distance</span>
                        <HelpBubble :data="help.pd" />
                    </span>
                </div>

                <div v-if="!prescription.separate_pd" class="pd-input-container">
                    <CustomSelect :value="prescription.pd" :options="eyePDOptions" class="pd-input" @change="prescription.pd = $event.target.value" />
                </div>

                <div v-else class="pd-input-container separate-pd-values">
                    <CustomSelect :value="prescription.od_pd" :options="eyeODPDOptions" class="pd-input" @change="prescription.od_pd = $event.target.value" />
                    <CustomSelect :value="prescription.os_pd" :options="eyeOSPDOptions" class="pd-input" @change="prescription.os_pd = $event.target.value" />
                </div>
            </div>
            <div class="pd_checkbox enter-it-online">
                <RadioLabel
                    :active="prescription.separate_pd"
                    label="I have two PD numbers"
                    @click="prescription.separate_pd = !prescription.separate_pd"
                />
            </div>
            <div v-if="showError" class="error-text-rx">
                <span v-text="errorText"></span>
            </div>
        </div>
    </div>
</template>
<script>
import _merge from 'lodash/merge'
import {mapState, mapGetters} from 'vuex'
import generateOptions from '../../../../../../app/utils/generate-options'
import getHelper from '../../../../../configurator/storage/constants/helper'

import SalesOrderViewPrescriptionRenderAbstract from './abstract'
import HelpBubble from '../../../../../help_bubble/HelpBubble.vue'

export default {
    name: 'SalesOrderViewPrescriptionRenderEnter',
    components: {
        HelpBubble
    },
    mixins: [SalesOrderViewPrescriptionRenderAbstract],
    props: {
        title: {
            type: [Boolean, String],
            default: 'Enter it Online'
        }
    },
    data: () => ({
        eyePDOptions: generateOptions(50, 80, 0.5, 1, true, false),
        eyeODPDOptions: generateOptions(24, 37, 0.5, 1, true, false, false, 'Right'),
        eyeOSPDOptions: generateOptions(24, 37, 0.5, 1, true, false, false, 'Left'),
        errorText: 'Prescription values invalid or missing.'
    }),
    computed: {
        ...mapState('prescriptions', ['optionsValidate']),
        ...mapGetters('values', ['values']),
        ...mapGetters('prescriptions', ['getValidatePrescriptionData']),
        help () {
            const {help_components} = getHelper(this.$store.getters)
            return help_components
        },
        prescription () {
            return this.values.prescriptionValues[this.prescriptionData.rx_type]
        },
        showError () {
            return this.validatePrescriptionData(this.prescription, this.prescriptionData.rx_type)
        },
        fittingbox_status () {
            return this.item.prescriptions[this.prescriptionsType].fittingbox_status
        }
    },
    watch: {
        prescription: {
            handler (data) {
                const index = this.indexSave
                if (data.separate_pd) {
                    data.pd = ''
                } else {
                    data.od_pd = ''
                    data.os_pd = ''
                }
                this.setValueOptions({index, data})
                this.validateData()
            },
            deep: true
        },
        showError () {
            this.validateData()
        }
    },
    created () {
        if (this.prescriptionData) {
            this.$set(this.item.prescriptions, this.prescriptionsType, _merge({
                type: this.prescriptionsType,
                rx_type: 'single_vision_distance',
                od_pd: '',
                os_pd: '',
                pd: '',
                separate_pd: false,
                status_4: false
            }, this.prescriptionData))
        }

        this.setDataToValues({
            prescriptionValues: {
                [this.prescriptionData.rx_type]: _merge(this.prescription, this.prescriptionData.prescription)
            }
        })
    },
    methods: {
        validatePrescriptionData (prescription, prescription_type) {
            let show = false
            if (this.optionsValidate) {
                if (prescription.separate_pd && (!prescription.od_pd || !prescription.os_pd)) {
                    show = true
                } else if (!prescription.separate_pd && !prescription.pd) {
                    show = true
                }
            }
            return show
        },
        validateData () {
            if (this.isActive) {
                let left = true
                let right = true
                if (this.prescription.separate_pd && (!this.prescription.od_pd || !this.prescription.os_pd)) {
                    left = Boolean(this.prescription.os_pd)
                    right = Boolean(this.prescription.od_pd)
                } else if (!this.prescription.separate_pd && !this.prescription.pd) {
                    left = false
                    right = false
                }

                this.$store.commit('prescriptions/OPTIONS_ARE_VALID', {
                    left,
                    right
                })
            }
        }
    }
}
</script>
