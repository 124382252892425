var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.success
    ? _c(
        "v-container",
        { attrs: { "grid-list-md": "", "anagram-container": "" } },
        [
          _c("v-layout", { attrs: { row: "", wrap: "" } }, [
            _c("h3", [_vm._v("File your vision insurance claim")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("RadioLabel", {
                  attrs: {
                    active: _vm.hasInsurance,
                    label: "I have vision insurance"
                  },
                  on: { click: _vm.changeRadioValue }
                }),
                _vm._v(" "),
                _c("RadioLabel", {
                  attrs: {
                    active: !_vm.hasInsurance,
                    label:
                      "I am covered under someone else’s vision insurance plan"
                  },
                  on: { click: _vm.changeRadioValue }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "anagram-claim-text" }, [
              _vm._v(
                "Claim your insurance benefits for your purchase! Fill in the information below to get your ‘out-of-network’ reimbursement for your " +
                  _vm._s(_vm.productsName) +
                  "."
              )
            ])
          ]),
          _vm._v(" "),
          _vm.hasInsurance
            ? _c("HasInsuranceForm", {
                attrs: { "form-model": _vm.getHasInsuranceForm }
              })
            : _c("HasNotInsuranceForm", {
                attrs: { "form-model": _vm.getHasNotInsuranceForm }
              }),
          _vm._v(" "),
          _vm.error
            ? _c("p", { staticClass: "error-text" }, [
                _vm._v(_vm._s(_vm.errorText))
              ])
            : _vm._e()
        ],
        1
      )
    : _c(
        "v-container",
        { attrs: { "grid-list-md": "", "anagram-container": "" } },
        [_c("p", [_vm._v("Your request has been submitted successfully.")])]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }