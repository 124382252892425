var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "back-button-container",
      attrs: { id: "back-button-container" }
    },
    [
      _c(
        "a",
        {
          staticClass: "back-button",
          attrs: { href: "javascript:void(0)" },
          on: { click: _vm.goBack }
        },
        [_vm._v("Back")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }