<template>
    <div v-show="isCurrent" :class="id">
        <h2 v-if="config.title" v-dompurify-html="config.title" class="title"></h2>
        <div v-if="config.description" v-dompurify-html="config.description" class="description"></div>
        <ul v-if="options" class="actions-toolbar">
            <template v-for="(option, index) in options">
                <li :key="`${config.id}-option-${index}`" :class="{'has-thumbnail': option.thumbnail}" class="col">
                    <a href="javascript:void(0)" class="btn-choice" :class="{'has-thumbnail': option.thumbnail, 'active': activeIndex === option.value}" @click="setValue(option)">
                        <span v-if="option.thumbnail" v-dompurify-html="option.thumbnail" class="thumbnail" :class="option.label"></span>
                        <span v-dompurify-html="option.label" class="label"></span>
                    </a>
                </li>
            </template>
        </ul>
        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton
                    class="button--copy-solid-white-blue button--small"
                    text="Continue"
                    @click.native="continueNxt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseButton from '../../base/BaseButton.vue'

export default {
    name: 'Choice',
    components: {
        BaseButton
    },
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        activeIndex: null
    }),
    computed: {
        ...mapGetters('lensablPlus', ['getObjectConfig', 'currentComponent']),
        config () {
            return this.getObjectConfig(this.id)
        },
        options () {
            return this.config.component.options
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        }
    },
    methods: {
        setValue (option) {
            this.activeIndex = option.value
        },
        continueNxt () {
            this.$store.commit('lensablPlus/COMPONENT_DATA', {id: this.id, value: this.activeIndex, isValidValue: true})
        }
    }
}
</script>

<style lang="less" scoped>
    .flex {
        display: flex;
    }
</style>
