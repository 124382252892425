<template>
    <div>
        <a href="javascript:void(0)" @click="createPdf">Download Shipping Label</a>
        <div v-if="load" class="loader-container">
            <div class="loader-el"></div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import shipping from '../storage/modules/shipping'

export default {
    name: 'ShippingDownloadLabel',
    data: () => {
        return {
            load: false
        }
    },
    computed: mapGetters('shipping/download', ['request', 'shipstationService']),
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['shipping/']) {
            this.$store.registerModule('shipping', shipping(this.$store))
        }
    },
    created () {
        if (this.request) {
            this.createPdf()
        }
    },
    methods: {
        createPdf () {
            this.load = true
            this.shipstationService.getLabelFromShipstation()
                .then(({status, data}) => {
                    if (status === 200 && data.pdf) {
                        this.shipstationService.openPdfInNewWindow(data.pdf)
                    }
                })
                .catch(error => console.error(error))
                .finally(() => this.load = false)
        }
    }
}
</script>
