<template>
    <div class="product">
        <div v-if="outOfStock" class="sold-out-block">
            <div class="sold-out">
                <p>Sold Out</p>
            </div>
        </div>
        <a v-if="currentImage" :title="product.name" :href="productUrl" class="product photo">
            <img :alt="product.name" :src="currentImage.path" />
        </a>
        <div class="product details">
            <span v-if="showPPEAsEverydayEyewear" class="category-title">Everyday Eyewear</span>
            <span v-else-if="showCategoryName || extras.includes(product.category) || getIsTitle" class="category-title">
                {{ everydayEyeWear.includes(product.category) ? 'Everyday Eyewear' : product.category }}
            </span>
            <div class="item-details">
                <strong class="product name product-item-name">
                    <a v-dompurify-html="product.name" :title="product.name" :href="productUrl" class="product-item-link"></a>
                </strong>
                <div class="price-container">
                    <span v-if="showProductPrice || showPrice" class="product-price">Price {{ currentImage.price || product.price }}</span>
                    <span v-else-if="product.category.indexOf('Hobie') !== -1" class="shopframes-price">From {{ currentImage.price }}</span>
                    <span v-else-if="product.category.indexOf('HyperX') !== -1" class="shopframes-price">
                        From {{ product.price.replace(/(\.[0-9]*?)0+/g, "") }}
                    </span>
                    <span v-else-if="showShopFramesPrice || product.category === 'Willows Eyewear'" class="shopframes-price">From {{ product.name === 'Hearts of Yang' ? '$98.00' : currentImage.price || product.price }}</span>
                    <span v-else-if="product.category === 'Everyday Eyewear' || product.category.indexOf('Personal') !== -1" class="shopframes-price">Free Frame with Lens Purchase</span>
                </div>
                <ColorSwatches
                    v-if="showSwatcher || showColorsSwatcher"
                    :colors="images"
                    :checked-index="checkedIndex"
                    :option-item-class="'decorate img'"
                    :product-name="everydayEyeFrameName && productCategory === 'Everyday Eyewear' ? everydayEyeFrameName : undefined"
                    :data-swatch-product="product.name"
                    @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ColorSwatches from '../view/ColorSwatches.vue'
import getSizeHelper from '../lensabl/constants/size'
import {
    SHOP_FRAMES_BRANDS,
    EVENT_CHANGE_CURRENT_COLOR
} from '../../../../app/utils/constants'

export default {
    name: 'CatalogCategoryListItem',
    components: {
        ColorSwatches
    },
    props: {
        product: {
            type: [Array, Object],
            default: () => {
            }
        },
        showSwatcher:{
            default: false,
            type: Boolean
        },
        showProductPrice:{
            default: false,
            type: Boolean
        },
        showCategoryTitle : {
            default: false,
            type: Boolean
        }
    },
    data: () => ({
        checkedIndex: 0,
        lplusFrames: [],
        everydayEyeWear: ['Optical', 'Sun', 'Night'],
        lensablFrames: [
            'Optical',
            'Sun',
            'Night',
            'Saint Laurent',
            'Stella McCartney',
            'Gucci',
            'Puma',
            'Bottegaveneta',
            'Tomas Maier',
            'Celine',
            'Alexander McQueen',
            'Brioni',
            'Lensabl Frames'
        ],
        extras: [
            'Le Specs Eyewear',
            'Gamer Advantage Eyewear',
            'Kiaura Eyewear',
            'HyperX Gaming Eyewear',
            'Hobie Eyewear',
            'Saint Laurent',
            'Bose',
            'Stella McCartney',
            'Gucci',
            'Puma',
            'Bottegaveneta',
            'Tomas Maier',
            'Celine',
            'Alexander McQueen',
            'Brioni',
            'Optical',
            'Sun',
            'Night',
            'Willows Eyewear',
            'James Oro Frames'
        ],
        EVENT_CHANGE_CURRENT_COLOR: EVENT_CHANGE_CURRENT_COLOR
    }),
    computed: {
        ...mapGetters('storeView', ['base_url']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow']),
        ...mapGetters('partner', ['getIsTitle']),
        outOfStock () {
            return !this.product.qty
        },
        images () {
            return this.product.images || []
        },
        currentImage () {
            if (this.product.category === 'Bose') {
                return {
                    path: this.product.image,
                    price: this.product.price
                }
            } else if (this.product.category === 'Personal Protective Eyewear' && window.location.pathname !== '/personal-protective-eyewear') {
                return this.ppeShopFramesAlternateImg
            } else {
                return this.images[this.checkedIndex] || {path: this.product.image}
            }
        },
        productUrl () {
            if (this.product.category === 'Ray-Ban Meta') {
                let updatedUrl = `${this.base_url}ray-ban-meta-eyewear/${this.product.sku}`
                return updatedUrl
            } else {
                return this.product.url.indexOf('http') !== -1 ? this.product.url : this.base_url + this.product.url.replace(/^\/+/, '')
            }
        },
        productCategory () {
            const options = ['Optical', 'Sun', 'Night', 'Lensabl Frames']

            if (options.includes(this.product.category)) {
                return 'Everyday Eyewear'
            } else {
                return this.product.category
            }
        },
        everydayEyeFrameName () {
            if (this.productCategory === 'Everyday Eyewear') {
                const latestEverydayEyeFrames = ['martel', 'carlyle', 'akiko', 'harper', 'warhawk', 'oscar', 'alexandria', 'lincoln', 'laurel', 'sierra']
                const everydayEyeFrameName = this.product.name.split(' ')[0].toLowerCase()

                if (latestEverydayEyeFrames.includes(everydayEyeFrameName)) {
                    return everydayEyeFrameName
                }
            }
            return ''
        },
        showShopFramesPrice () {
            const currentProductCategory = this.product.category.toLowerCase()
            return Boolean(SHOP_FRAMES_BRANDS.includes(currentProductCategory))
        },
        showPrice () {
            return this.product.category === 'Le Specs Eyewear' ||
                this.product.category === 'Bose' ||
                this.product.category.indexOf('RHEOS') !== -1 ||
                this.product.category.indexOf('Gamer') !== -1 ||
                this.product.category.indexOf('Kiaura') !== -1
        },
        showCategoryName () {
            return this.showCategoryTitle || this.product.category.indexOf('RHEOS') !== -1
        },
        showPPEAsEverydayEyewear () {
            return this.product.category.indexOf('Personal') !== -1
        },
        showColorsSwatcher () {
            return this.product.category !== 'Bose'
                && this.product.category.indexOf('Gamer') === -1
                && this.product.category.indexOf('Willows') === -1
                && this.product.category.indexOf('James Oro') === -1
        },
        ppeShopFramesAlternateImg () {
            const ppeAlternativeImages = {
                'Bruin': {
                    'Charcoal': '/partners/category/ppe-bruin-charcoal.jpg',
                    'Crystal': '/partners/category/ppe-bruin-crystal.jpg'
                },
                'Cedar': {
                    'Charcoal': '/partners/category/ppe-cedar-charcoal.jpg',
                    'Crystal': '/partners/category/ppe-cedar-crystal.jpg'
                }
            }

            const ppeProductName = this.product.name
            const ppeImagesObj = this.images[this.checkedIndex] || {}

            const alternativeImgPath = ppeAlternativeImages[ppeProductName][ppeImagesObj.color]
            ppeImagesObj.path = alternativeImgPath
            return ppeImagesObj
        }
    },
    created () {
        const {LPlus_Benefits} = getSizeHelper({assets_url: this.$store.getters['storeView/assets_url']})
        this.lplusFrames = LPlus_Benefits
    },
    methods: {
        changeCurrentColor (index) {
            this.checkedIndex = index
        }
    }
}
</script>
