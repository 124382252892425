<template>
    <DoctorsTools v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import DoctorsTools from '../../components/lensablplus/businessDoctorsTool/DoctorsTools.vue'

export default {
    name: 'RouterLensablPlusBusinessDoctors',
    components: {
        DoctorsTools
    },
    extends: App
}
</script>
