var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showRedeem
    ? _c(
        "div",
        {
          staticClass: "plus-account_container contacts-container",
          class: { redeemed: _vm.isContactsRedeemed }
        },
        [
          _vm.isMobile
            ? _c(
                "h4",
                {
                  class: { active: _vm.isShow },
                  on: { click: _vm.toggleContent }
                },
                [_vm._v("Contacts")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide" } }, [
            !_vm.isMobile || (_vm.isShow && _vm.isMobile)
              ? _c("div", { staticClass: "plus-content_container" }, [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: "/js/lensconfigurator/assets/desktop_contacts.svg",
                        alt: "contact lenses"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "redeem-description" },
                      [
                        _c("span", { staticClass: "description" }, [
                          _vm._v(
                            "$" +
                              _vm._s(_vm.getContactsAllowance) +
                              " allowance towards contacts purchase"
                          )
                        ]),
                        _vm._v(" "),
                        _c("HelpBubble", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.isContactsRedeemed &&
                                _vm.component_help.show,
                              expression:
                                "!isContactsRedeemed && component_help.show"
                            }
                          ],
                          attrs: { data: _vm.component_help }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "secondary",
                      attrs: { id: "contactsredeem" }
                    },
                    [
                      _c("BaseButton", {
                        staticClass: "button button--copy-solid",
                        class: {
                          disable: !_vm.previousvisit || _vm.isContactsRedeemed,
                          "ladda-button": _vm.isContactsRedeemed,
                          disabledButton: _vm.isContactsRedeemed
                        },
                        attrs: {
                          disabled: !_vm.paidUp,
                          text: _vm.btnText,
                          "data-size": "xs"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.openContactsRedeem($event)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "button-square" }, [
                        _c("span", { staticClass: "square-left" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "square-top" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "square-right" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "square-bottom" })
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", [
            _vm.showHasExtraAllowance
              ? _c(
                  "label",
                  {
                    staticClass: "decline-benefit",
                    staticStyle: { width: "fit-content" }
                  },
                  [
                    _c("input", {
                      attrs: {
                        id: "extra-allowance",
                        title: "extra-allowance",
                        type: "radio",
                        name: "extra-allowance"
                      },
                      domProps: { checked: _vm.usedExtraAllowance },
                      on: { click: _vm.updateAllowance }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "extra-allowance-container" }, [
                      _c("div", { staticClass: "extra-allowance-label" }, [
                        _c("span", [
                          _vm._v(
                            "Decline this benefit and get an extra $" +
                              _vm._s(_vm.getContactsVoucherAmount) +
                              " added to your frames/lenses"
                          )
                        ])
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desktop-notification-card-top-redeem" }, [
            _c(
              "svg",
              {
                staticClass: "desktop-svg",
                attrs: {
                  width: "350px",
                  height: "25px",
                  viewBox: "0 0 409 25",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Page-1",
                      stroke: "none",
                      "stroke-width": "1",
                      fill: "none",
                      "fill-rule": "evenodd"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Lensabl+-Dashboard-0-Welcome-2",
                          transform: "translate(-1642.000000, -900.000000)",
                          fill: "#9BDBDA",
                          "fill-rule": "nonzero"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            id: "Line-5",
                            d:
                              "M1666,900.5 L1666,910.5 L2051,910.5 L2051,914.5 L1666,914.5 L1666,924.5 L1642,912.5 L1666,900.5 Z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "tablet-svg",
                attrs: {
                  width: "350px",
                  height: "50px",
                  viewBox: "0 0 408 50",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Page-1",
                      stroke: "none",
                      "stroke-width": "1",
                      fill: "none",
                      "fill-rule": "evenodd"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Lensabl+-Dashboard-0-Welcome-1",
                          transform: "translate(-1741.000000, -1114.000000)",
                          fill: "#9BDBDA",
                          "fill-rule": "nonzero"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            id: "Path-2",
                            d:
                              "M2147.42073,1160.18616 C2148.42248,1160.65151 2148.85732,1161.84084 2148.39197,1162.8426 C2147.92661,1163.84436 2146.73728,1164.2792 2145.73552,1163.81384 C2080.40435,1133.46493 2003.33382,1118.57493 1926.60961,1118.89802 C1860.01194,1119.17847 1798.7846,1131.24718 1762.12437,1150.36389 L1767.60992,1158.43017 L1741.01562,1162 L1754.11633,1138.58267 L1759.86075,1147.03397 C1797.12034,1127.45734 1859.16268,1115.18201 1926.59277,1114.89806 C2003.87546,1114.57262 2081.52311,1129.57411 2147.42073,1160.18616 Z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "animate-notification-msg" }, [
              _vm._v('To use a reward, click the "Redeem" button.')
            ])
          ]),
          _vm._v(" "),
          _c("Redeem", {
            attrs: {
              title: "Contact Lens",
              instruction: _vm.instruction,
              requirements: _vm.requirements,
              "is-modal-open": _vm.isContactsOpen,
              subtitle: "Contacts Benefit"
            },
            on: {
              continue: _vm.continueToContacts,
              update: function($event) {
                _vm.isContactsOpen = false
              }
            }
          }),
          _vm._v(" "),
          _vm.loading ? _c("Loader") : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }