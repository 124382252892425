<template>
    <div class="subscription-detail-container">
        <div class="subscription-detail-row">
            <div class="subscription-detail-cell">
                <h3>Product Name</h3>
                <p>secondary name</p>
                <img class="subscription-detail-img" src="http://loc-lensabl.com:81/media/catalog/product//a/v/avairavitality_toric_6pk_frontface_3dillus.jpg" />
            </div>
            <div class="subscription-detail-cell">
                <h3>Product Name</h3>
                <p>secondary name</p>
                <img class="subscription-detail-img" src="http://loc-lensabl.com:81/media/catalog/product//a/v/avairavitality_toric_6pk_frontface_3dillus.jpg" />
            </div>
        </div>
        <div class="subscription-detail-row">
            <div class="subscription-detail-cell">
                Right Eye (OD)
            </div>
            <div class="subscription-detail-cell">
                Left Eye (OS)
            </div>
        </div>
        <div class="subscription-detail-row">
            <div class="subscription-detail-cell">
                <span class="subscription-detail-quantity">1 Box</span>
            </div>
            <div class="subscription-detail-cell">
                <span class="subscription-detail-quantity">1 Box</span>
            </div>
        </div>
        <div class="subscription-detail-row"><span class="subscription-detail-approx">This quantity can last you approximately 6 months.</span></div>

        <div class="subscription-detail-bottom">
            <div class="subscription-detail-row">
                <div class="subscription-detail-cell">
                    <div class="subscription-detail-shipment">
                        <div class="subscription-detail-shipment-col">
                            <h4>Next Shipment Date:</h4> <span>{{ subscription.next_shipment_date }}</span>
                        </div>
                    </div>
                    <p class="subscription-detail-shipment-text">If you would like to postpone your next shipment, <a target="_blank" href="mailto:hello@lensabl.com">Contact us</a></p>
                    <p class="subscription-detail-shipment--small">**Your card will be charged 5 days before the shipment date.</p>
                </div>
                <div class="subscription-detail-cell subscription-detail-cell--right">
                    <div class="subscription-detail-history-top">
                        <h4>Subscription History:</h4>
                        <a class="subscription-detail-button" @click="openModal('history')">
                            <div class="button button--copy-border-sm edit">
                                <div class="button__copy">View</div>
                            </div>
                        </a>
                    </div>
                    <p>Order received <span class="subscription-detail-dots"></span> <span class="subscription-detail-history">{{ subscription.period_started_at }}</span></p>
                    <p>Subscription started <span class="subscription-detail-dots"></span> <span class="subscription-detail-history">{{ subscription.term_started_at }}</span></p>
                </div>
            </div>
            <div class="subscription-detail-row">
                <div class="subscription-detail-info">
                    <div class="subscription-detail-cell subscription-detail-cell--outer">
                        <div class="subscription-detail-cell--half">
                            <h4>Who is it for?</h4>
                        </div>
                        <div class="subscription-detail-cell--half">
                            <h4 class="subscription-detail-name">{{ subscription.who_is_this_for }}</h4>
                        </div>
                    </div>
                    <div class="subscription-detail-cell subscription-detail-cell--outer">
                        <div class="subscription-detail-cell--half">
                            <h4>Payment Method</h4>
                        </div>
                        <div class="subscription-detail-cell--half">
                            <div class="subscription-detail-billing">
                                <div class="subscription-detail-billing--info">
                                    <div class="subscription-detail-billing-item">
                                        <p>{{ recurly_billing_address.billing_info.card_type }}</p>
                                    </div>
                                    <div class="subscription-detail-billing-item">
                                        <p>Ending in {{ recurly_billing_address.billing_info.last_four }}</p>
                                        <p>Expires {{ recurly_billing_address.billing_info.month }}/{{ recurly_billing_address.billing_info.year }}</p>
                                    </div>
                                </div>
                                <div class="subscription-detail-billing-item">
                                    <img id="edit-pencil" alt="edit button" src="/js/lensconfigurator/assets/pencil-edit-button.svg" @click="openModal('billing')" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="subscription-detail-cell subscription-detail-shipping subscription-detail-cell--outer">
                        <div class="subscription-detail-cell--half">
                            <h4>Shipping Address</h4>
                        </div>
                        <div class="subscription-detail-cell--half">
                            <div class="subscription-detail-shipping">
                                <div v-if="recurly_shipping_address" class="subscription-detail-shipping-cell">
                                    <p>{{ recurly_shipping_address.shipping_address.address1 }}</p>
                                    <p v-if="recurly_shipping_address.shipping_address.address2">{{ recurly_shipping_address.shipping_address.address2 }}</p>
                                    <p>{{ recurly_shipping_address.shipping_address.city }}, {{ recurly_shipping_address.shipping_address.state }}</p>
                                    <p>{{ recurly_shipping_address.shipping_address.zip }}</p>
                                </div>
                                <div class="subscription-detail-shipping-cell">
                                    <img id="edit-pencil" alt="edit button" src="/js/lensconfigurator/assets/pencil-edit-button.svg" @click="openModal('shipping')" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subscription-detail-info">
                    <div class="subscription-detail-totals-wrapper">
                        <div class="subscription-detail-totals">
                            <div class="subscription-detail-cell--half">
                                <p>Subtotal:</p>
                            </div>
                            <div class="subscription-detail-cell--half">
                                <p>$00.00</p>
                            </div>
                        </div>
                        <div class="subscription-detail-totals">
                            <div class="subscription-detail-cell--half">
                                <p>Shipping:</p>
                            </div>
                            <div class="subscription-detail-cell--half">
                                <p>FREE</p>
                            </div>
                        </div>
                        <div class="subscription-detail-totals">
                            <div class="subscription-detail-cell--half">
                                <p>Discount:</p>
                            </div>
                            <div class="subscription-detail-cell--half">
                                <p>-$00.00</p>
                            </div>
                        </div>
                        <div class="subscription-detail-totals">
                            <div class="subscription-detail-cell--half">
                                <p>Taxes:</p>
                            </div>
                            <div class="subscription-detail-cell--half">
                                <p>$00.00</p>
                            </div>
                        </div>
                        <div class="subscription-detail-totals">
                            <div class="subscription-detail-cell--half">
                                <p>TOTAL:</p>
                            </div>
                            <div class="subscription-detail-cell--half">
                                <p>$00.00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="subscription-detail-options">
            <a class="open-orders__button" href="http://loc-lensabl.com:81/contact-lenses/subscription/">
                <div class="button__container">
                    <svg>
                        <use xlink:href="#lensabl-arrow-small-down" />
                    </svg>
                    <span class="order-back-text">Back</span>
                </div>
            </a>
        </div>
        <!-- Billing Modal -->
        <RecurlyModal v-if="showBillingModal" :billing="true" :account-id="subscription.account_code" @close="showBillingModal = false" />

        <!-- Shipping Modal -->
        <RecurlyModal v-if="showShippingModal" :shipping="true" @close="showShippingModal = false" />

        <Modal v-if="showHistoryModal" class="subscription-history-container" @close="showHistoryModal = false">
            <slot>
                <span class="modal-header">Subscription History</span>
                <div class="subscription-history-row">
                    <p class="subscription-history-row--label">Order received</p><div class="dots"></div><span class="subscription-history-row--date">{{ subscription.period_started_at }}</span>
                </div>
                <div class="subscription-history-row">
                    <p class="subscription-history-row--label">Subscription started</p><div class="dots"></div><span class="subscription-history-row--date">{{ subscription.term_started_at }}</span>
                </div>
                <div class="subscription-history-row">
                    <p class="subscription-history-row--label">Subscription status</p><div class="dots"></div><span class="subscription-history-row--date">active</span>
                </div>
            </slot>
        </Modal>
    </div>
</template>

<script>
import getSubscriptionService from '../../../app/utils/subscription-service'
import {mapActions, mapGetters, mapState} from 'vuex'
import RecurlyModal from './RecurlyModal.vue'
import Modal from '../Modal.vue'
import subscriptionStorage from '../storage/subscriptions'

export default {
    name: 'ContactsSubscriptions',
    components: {
        RecurlyModal,
        Modal
    },
    data: () => {
        return {
            subscriptions: '',
            subscription: '',
            billing: '',
            shipping: '',
            invoice: '',
            price: '',
            showBillingModal: false,
            showShippingModal: false,
            showHistoryModal: false,
            cc: ''
        }
    },
    computed: {
        ...mapState('contacts/subscriptionView', ['data']),
        ...mapGetters(['recurly_auth_api_key', 'recurly_shipping_address', 'recurly_billing_address'])
    },
    beforeCreate () {
        if (!subscriptionStorage.registered) {
            this.$store.registerModule(['contacts', 'subscriptionView'], subscriptionStorage, { preserveState: !!this.$store.state['contacts/subscriptionView'] })
            subscriptionStorage.registered = true
        }
    },
    created () {
        this.fetchSubscriptionState()
        this.formatSubscription()
        this.fetchBilling()
        this.fetchShipping(window.location.href)
    },
    methods: {
        ...mapActions('contacts/subscriptionView', ['fetchSubscriptionState']),
        formatSubscription () {
            const api_key = this.recurly_auth_api_key
            const uuid = getSubscriptionService({api_key}).getId(window.location.href)
            const subscription = this.data.filter(sub => sub.uuid === uuid)
            subscription.forEach(sub => {
                sub.next_bill_date = this.formatDate(sub.next_bill_date)
                sub.next_shipment_date = this.formatDate(sub.next_shipment_date)
                sub.period_ends_at = this.formatDate(sub.period_ends_at)
                sub.period_started_at = this.formatDate(sub.period_started_at)
            })
            this.subscription = subscription[0]
        },
        formatDate (date) {
            const dateStringFormat = new Date(date)
            const month = dateStringFormat.getMonth() + 1
            const day = dateStringFormat.getDate()
            const year = dateStringFormat.getFullYear()
            const newDate = month + '/' + day + '/' + year
            return newDate
        },
        fetchBilling () {
            const api_key = this.recurly_auth_api_key
            const acctId = this.subscription.account_code
            getSubscriptionService({api_key}).getBilling(acctId).then((response) => {
                this.$store.commit('SET_RECURLY_BILLING', response)
            })
        },
        fetchShipping (link) {
            const api_key = this.recurly_auth_api_key
            getSubscriptionService({api_key}).getShipping(link).then((response) => {
                this.$store.commit('SET_RECURLY_SHIPPING', response)
            })
        },
        fetchInvoice (link) {
            const api_key = this.recurly_auth_api_key
            getSubscriptionService({api_key}).getInvoice(link).then((response) => {
                this.invoice = response
            })
        },
        openModal (type) {
            if (type === 'billing') {
                this.showBillingModal = true
            }
            if (type === 'shipping') {
                this.showShippingModal = true
            }
            if (type === 'history') {
                this.showHistoryModal = true
            }
        }
    }
}
</script>
