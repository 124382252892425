<template>
    <div class="dr-tool-container">
        <div class="dr-tool-row">
            <div class="dr-tool-row_header">
                <h2><b>Find a Location</b></h2>
                <p class="dr-tool-row_p-text">You may search for a provider location by entering your zip code below.
                    All provider locations offer Lensabl+ members a discounted comprehensive eye exam as well as
                    additional discounts for other services.
                </p>
            </div>
            <div class="dr-tool-inputs">
                <div class="dr-tool-inputs_zipcode">
                    <input
                        id="zipcode"
                        v-model="zipcode"
                        placeholder="Enter Zip"
                        required="required"
                        type="text"
                    />
                    <p v-if="showError" class="validation-msg">Zipcode is required and must have 5 digits</p>
                </div>
                <div>
                    <CustomSelect
                        :id="`radius`"
                        :value="radius"
                        :options="rangeOptions(0, 250)"
                        @change="setRadius($event.target.value)"
                    />
                </div>
                <div>
                    <BaseButton
                        :text="`Search`"
                        :disabled="showError"
                        @click.native="submitForm"
                    />
                </div>
            </div>
            <div>
                <div v-if="emptyResult">
                    <h3>I'm sorry, we don't have a location in your area</h3>
                </div>
                <div v-else id="grid-list" class="dr-tool-results">
                    <template v-for="(doctor, index) in getDoctors">
                        <div :key="index" class="dr-tool-results_card">
                            <span class="results-index">{{ index + 1 }}</span>
                            <div class="results-name-address">
                                <p class="results-name">{{ doctor.store_name }}</p>
                                <div class="results-address">
                                    <p>{{ doctor.address_1 }}</p>
                                    <p v-if="doctor.address_2">{{ doctor.address_2 }}</p>
                                    <p>{{ doctor.city }}, {{ doctor.state }} {{ doctor.zipcode }} </p>
                                    <p v-if="doctor.pdf_file"><a :href="`/media/location/${doctor.pdf_file}`" download>See Available Discounts</a></p>
                                </div>
                            </div>
                            <!--                            <span class="results-phone">{{ doctor.phone }}</span>-->
                        </div>
                    </template>
                </div>
            </div>
            <div v-if="loading">
                <Loader/>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import doctors from '../storage/lensablplus/doctors'
import BaseButton from '../../../components/base/BaseButton.vue'
import CustomSelect from '../../base/Select.vue'
import Loader from '../../loaders/Loader.vue'
import _size from 'lodash/size'

export default {
    name: 'DoctorsTool',
    components: {
        BaseButton,
        CustomSelect,
        Loader
    },
    data: () => ({
        showError: false,
        zipcode: null,
        radius: null,
        loading: false,
    }),
    watch: {
        zipcode: function (newVal, oldVal) {
            this.showError = false
            if (isNaN(newVal) || newVal.length !== 5) {
                this.showError = true
            }
        }
    },
    computed: {
        ...mapGetters('doctors', ['getDoctors']),
        emptyResult(){
            return !this.getDoctors.length && !this.loading && this.zipcode !== null
        }
    },
    beforeCreate() {
        if (!this.$store._modulesNamespaceMap['doctors/']) {
            this.$store.registerModule('doctors', doctors(this.$store))
        }
    },
    created() {
        this.$store.dispatch('doctors/getCustomerData').then(({data, status}) => {
            if (data.zipcode) {
                this.zipcode = data.zipcode
                if (data.radius){
                    this.radius = data.radius
                }
                this.submitForm()
            }
        })

    },
    mounted() {
        window.addEventListener('scroll', this.loadNext)
    },
    methods: {
        ...mapActions('doctors', ['getDoctorsBy']),
        rangeOptions(start, end, step = 1) {
            const options = [{value: null, label: 'Radius (miles)'}]
            const len = Math.floor((end - start) / step) + 1
            Array(len).fill().map((_, idx) => options[idx + 1] = {
                'value': start + (idx * step),
                'label': '+' + (start + (idx * step))
            })
            return options
        },
        submitForm() {
            if (this.zipcode === null) {
                this.showError = true
                return
            }
            this.$store.commit('doctors/SET_PAGE', 0)
            this.$store.commit('doctors/SET_DOCTORS', [])
            this.loadDoctors()
        },
        loadDoctors() {
            if (this.loading) {
                return
            }
            this.loading = true
            this.getDoctorsBy({
                zipcode: this.zipcode,
                radius: this.radius
            }).then(() => this.loading = false)
        },
        loadNext() {
            if (this.emptyResult || this.zipcode === null) {
                return
            }
            const el = document.getElementById('grid-list')
            if (el && _size(this.getDoctors)) {
                const heightEl = Math.max(el.scrollHeight, el.clientHeight)
                if (heightEl <= (window.innerHeight + window.scrollY)) {
                    this.loadDoctors()
                }
            }
        },
        setRadius(val) {
            this.radius = val
        }
    }
}
</script>
