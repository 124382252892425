var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category-products", class: _vm.getClass }, [
    _c(
      "ul",
      { staticClass: "products-grid", class: _vm.getClass },
      _vm._l(_vm.categories, function(category, index) {
        return _c(
          "li",
          { key: index, staticClass: "item spectacles spectacles-category" },
          [_c("CategoryItem", { attrs: { category: category } })],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }