<template>
    <li :class="{active: isActive, 'before-active': beforeActive, 'after-active': afterActive}" class="option">
        <div :class="{'has-thumbnail': option.thumbnail, 'has-price': option.price}" class="option-container" @click="optionSelect">
            <div
                v-if="option.thumbnail && option.component_extra_options && !isActive || option.thumbnail && !option.component_extra_options"
                :class="{extraOptions: option.component_extra_options}"
                class="option-thumbnail"
            >
                <img :src="option.thumbnail" />
            </div>
            <div class="option-descriptions">
                <div class="option-label">
                    <span v-dompurify-html="option.label" class="label-text"></span>
                    <span v-if="option.id === 'upload'" v-dompurify-html="option.component_extra_options.label_extra" class="upload-label-extra"></span>
                    <component :is="option.component_help.name" v-if="componentHelpShow && option.component_help" :data="option.component_help" />
                    <span v-if="manufacturerShow && option.manufacturer" class="manufacturer-container">
                        <span v-dompurify-html="option.manufacturer"></span>
                    </span>
                    <div v-if="option.popular" class="popular-container">
                        <span class="option.popular">(Most Popular)</span>
                    </div>

                    <span v-if="option.id === 'single_vision_premium'">
                        <HelpBubble :data="option.component_help" :placement="'bottom'" :classes="'lens_option_help_bubble'" />
                    </span>
                </div>
                <picture>
                    <source srcset="/media/wysiwyg/transitions-certified-logo.webp" type="image/webp" />
                    <source srcset="/media/wysiwyg/transitions-certified-logo.png" type="image/png" />
                    <img v-if="option.id === 'transitions_lenses' && isActive" src="/media/wysiwyg/transitions-certified-logo.webp" class="option-transitions-cert-logo" alt="Transitions Certified Logo" />
                </picture>
                <div v-dompurify-html="getOptionDescription" :class="{'small-description': option.small_description}" class="option-description"></div>
                <div v-if="showTempoDisclaimer && isActive && isBoseLanding" class="option-description">
                    <span><small><i>*Not available with Tempo frame</i></small></span>
                </div>
                <div v-if="showWillowsDisclaimer && isActive && isWillowsPage" class="single-vision-subtext bifocal-subtext">
                    <span :style="{ fontWeight: 'bold'}">Disclaimer: This frame can only accommodate prescriptions with up to {{ willowsSubText }} SPH. Lensabl will not be able to fulfill orders with stronger prescriptions. Please contact <a href="mailto:support@lensabl.com">support@lensabl.com</a> with any questions.</span>
                </div>
                <div
                    v-if="showBiFocalDisclaimer  && values.frames_name ==='James Oro Frames - Caviar'"
                    class="single-vision-subtext">
                            <span :style="{ fontWeight: 'bold'}">Disclaimer: This frame cannot accommodate any prescription with a power greater than +/- 4.00 SPH. Please contact <a
                                href="mailto:support@lensabl.com">support@lensabl.com</a> with any questions.</span>
                </div>
                <div
                    v-if="showBiFocalDisclaimer  && values.frames_name ==='James Oro Frames - Amour'"
                    class="single-vision-subtext">
                            <span :style="{ fontWeight: 'bold'}">Disclaimer: This frame cannot accommodate any prescription with a power greater than +/- 5.00 SPH. Please contact <a
                                href="mailto:support@lensabl.com">support@lensabl.com</a> with any questions.</span>
                </div>
                <HelpBubble v-if="componentHelpShow && (option.block_prescription_id === 'progressive' || option.block_prescription_id === 'single_vision')" :data="option.component_help" />
            </div>
            <div v-if="option.price" :class="option.checkout_value" class="option-price-container">
                <div v-if="option.id !== '167'" v-dompurify-html="option.price" class="option-price"></div>
            </div>

            <div :class="{active: (isActive || isActiveParentOption)}" class="option-checkbox-container">
                <div class="option-checkbox"></div>
            </div>
        </div>
        <div v-if="option.component_extra_options && isActive" class="extra-component-container">
            <component :is="option.component_extra_options.name" :id="id" :data="option.component_extra_options" :class="['extra-component ' + option.component_extra_options.name]" />
        </div>
    </li>
</template>

<script>
import {mapGetters} from 'vuex'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import SelectorColor from '../color/SelectorColor.vue'
import PrescriptionEmailItLater from '../../prescription/email_it_later/PrescriptionEmailItLater.vue'
import PrescriptionUpload from '../../prescription/upload/PrescriptionUpload.vue'
import SelectorPrescription from '../prescription/SelectorPrescription.vue'
import SavedPrescriptions from '../../prescriptions/SavedPrescriptions.vue'
import UsedSavedRx from '../../saved_prescriptions/UsedSavedRx'
import DoctorDetails from '../../contacts/prescription/DoctorDetails.vue'
import ManualPrescription from '../../contacts/prescription/ManualPrescription.vue'
import {
    STEP_DESCRIPTION
} from '../../../app/utils/constants'

export default {
    name: 'DefaultOption',
    components: {
        HelpBubble,
        SelectorColor,
        PrescriptionEmailItLater,
        PrescriptionUpload,
        SelectorPrescription,
        SavedPrescriptions,
        UsedSavedRx,
        ManualPrescription,
        DoctorDetails
    },
    props: {
        id: { // Step Id
            type: String,
            default: ''
        },
        option: {
            type: Object,
            default: () => {},
            required: true
        },
        manufacturerShow: {
            type: Boolean,
            default: false
        },
        componentHelpShow:{
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('productView', ['isBoseLanding', 'isWillowsPage', 'product_sku']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow', 'glassesType']),
        ...mapGetters('values', ['values',]),
        isActive () {
            return this.$parent.isActive(this.option)
        },
        isActiveParentOption () {
            return this.$parent.isParentActive(this.option)
        },
        showTempoDisclaimer () {
            const options = ['bi_focal', '167', '174']
            return options.includes(this.option.id)
        },
        beforeActive () {
            return this.$parent.beforeActive(this.option)
        },
        afterActive () {
            return this.$parent.afterActive(this.option)
        },
        getOptionDescription () {
            if (this.isRedeemFlow) {
                const optionId = this.option.id
                return STEP_DESCRIPTION[optionId] ? STEP_DESCRIPTION[optionId][this.glassesType] : this.option.description
            }
            return this.option.description
        },
        showWillowsDisclaimer () {
            if (this.option.id === 'bi_focal') {
                return true
            } else {
                return false
            }
        },
        showBiFocalDisclaimer(){
            if (this.option.id === 'bi_focal') {
                return true
            } else {
                return false
            }
        },
        willowsSubText() {
            const currentSku = this.product_sku

            if (currentSku === 'hearts-of-yang' || currentSku === 'hearts-of-yin') {
                return '+2.00/-4.00'
            } else if (currentSku === 'peace-&-love') {
                return '+2.50/-4.00'
            } else if (currentSku === 'make-it-blue') {
                return '+4.50/-4.50'
            } else if (currentSku === 'mav') {
                return '+3.00/-3.00'
            } else {
                return '+3.50/-3.50'
            }
        }
    },
    methods: {
        optionSelect () {
            this.$emit('option-select', this.option)
        }
    }
}
</script>
