<template>
    <div>
        <div class="shopframes-seo-prescription-container">
            <div class="shopframes-seo-row seo-prescription">
                <div class="shopframes-seo-text">
                    <h2>Prescription Glasses Online</h2>
                    <p>Ordering prescription glasses online has never been easier. With Lensabl, we help simplify the process so you can select the frames and lenses you need and get them delivered right to your door, fast. With Lensabl, you'll have access to an amazing selection of high-quality lenses and frames with or without a prescription.</p>
                </div>
                <div class="shopframes-seo-img">
                </div>
            </div>
        </div>

        <div class="shopframes-seo-frames-container">
            <div class="shopframes-seo-row seo-frames">
                <div class="shopframes-seo-frames-img">
                </div>
                <div class="shopframes-seo-text">
                    <h2>Quality Prescription Frames</h2>
                    <p>Your eyeglasses are one of your most important tools for everyday life. Shop with Lensabl for the ultimate convenience and dependable frames you can count on for years.</p>
                </div>
            </div>
        </div>

        <div class="shopframes-seo-designer-container">
            <div class="shopframes-seo-row seo-designer">
                <div class="shopframes-seo-text">
                    <h2>Designer Prescription Glasses</h2>
                    <p>
                        At Lensabl, we recognize our customers prioritize style just as much as function. Order designer prescription glasses online from Lensabl and choose from a wide selection of designer frames that accentuate your personal style.<br /><br />
                        Place an order through Lensabl's online store for a competitive rate on designer eyeglass frames and sunglasses frames with rapid delivery. Browse our offerings for womens and mens designer brands, including:
                    </p>
                </div>
                <ul class="shopframes-seo-designer-list">
                    <li>Arnette</li>
                    <li>Kate Spade</li>
                    <li>Bose</li>
                    <li>Marc Jacobs</li>
                    <!-- <li>Brooks Brothers</li> -->
                    <li>Oakley</li>
                    <li>Carrera</li>
                    <li>Ray-Ban</li>
                    <li>Everyday Eyewear</li>
                    <li>Vogue</li>
                    <li>Hugo Boss</li>
                    <li>HyperX</li>
                    <li>and more...</li>
                </ul>
            </div>
        </div>

        <div class="shopframes-seo-lenses-container">
            <div class="shopframes-seo-row seo-lenses">
                <div class="shopframes-seo-text seo-lenses-text">
                    <h2>Prescription Lenses</h2>
                    <p>Everyone has different vision needs, and the Lensabl online store features glasses for astigmatism and other corrective prescription lenses. Send us your prescription information or <a href="/online-vision-prescription" title="Online Vision Prescription"><span class="seo-vision-link">take an online vision test</span></a> to ensure your new lenses serve their purpose. Our <a href="/our-lenses" title="Our Lenses"><span class="seo-vision-link">prescription glasses lens options</span></a> include:</p>
                </div>

                <ul class="shopframes-seo-lenses-list">
                    <li>Single Vision Lenses</li>
                    <li>Transitions® Light Intelligent Lenses™</li>
                    <li>Reading Lenses</li>
                    <li>Digital HD Progressive Lenses</li>
                    <li>High Index Lenses</li>
                    <li>Sunglass Lenses</li>
                    <li>Blue Light Computer Lenses</li>
                    <li>Bifocal Lenses</li>
                    <li>Polarized Sunglass Lenses</li>
                </ul>
            </div>
        </div>

        <div class="shopframes-seo-eyeglass-container">
            <div class="shopframes-seo-row seo-eyeglass">
                <div class="shopframes-seo-text seo-eyeglass-text">
                    <h2>Get Quality Eyeglass Frames With Lensabl</h2>
                    <p>Ordering high-quality eyeglass frames to your door is easier and faster than ever with Lensabl. We offer free shipping, 30-day risk-free returns and a 12-month warranty to ensure complete customer satisfaction with every order of frames. For more on our frames, lenses and rates, <a href="/contact" title="Contact"><span class="seo-contact-link">contact Lensabl online</span></a> today!</p>
                </div>
                <a href="/glasses-frames" title="Glasses Frames">
                    <div class="button button--copy-solid">
                        <div class="button__container">GET NEW FRAMES</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShopFramesSeo',
    props: {
        categoryClass: {
            type: [String],
            default: ''
        }
    },
}
</script>
