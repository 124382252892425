<template>
    <vue-ladda :id="idName" :ref="nodeRef" :type="type" :button-class="`action primary`" :disabled="disabled" :loading="loader">
        <slot>{{ text }}</slot>
    </vue-ladda>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        nodeRef: {
            type: String,
            default: 'ladda'
        },
        type: {
            type: String,
            default: 'button'
        },
        dataStyle: {
            type: String,
            default: 'zoom-in'
        },
        text: {
            type: String,
            default: '',
            required: false
        },
        loader: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        idName: {
            type: String,
            default: null,
            required: false
        }
    },
    watch: {
        loader: {
            handler (loader) {
                loader ? this.$refs[this.nodeRef].ladda.start() : this.$refs[this.nodeRef].ladda.stop()
                this.$nextTick().then(() => {
                    // this.$refs[this.nodeRef].ladda.disable()
                    this.$refs[this.nodeRef].$el.disabled = this.disabled
                })
            },
            deep: true
        }
    }
}
</script>

<style scoped>
    @media screen and (max-width: 499px) {
        .action.primary {
            width: unset;
        }
    }
</style>
