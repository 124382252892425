var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.affirmPrice
    ? _c("div", { staticClass: "affirm-info" }, [
        _c("div", { staticClass: "starting" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.startingTitle) +
              _vm._s(_vm.affirmPrice) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "affirm-info" }, [
          _vm._v(
            "\n        or 3 payments of $" +
              _vm._s(_vm.affirmPayment) +
              " with\n        "
          ),
          _c(
            "span",
            {
              staticClass:
                "__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed"
            },
            [_vm._v("Affirm")]
          )
        ]),
        _vm._v(" "),
        _vm._m(0)
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "accrue-frames-section" }, [
      _c("span", [
        _vm._v("or save up and earn "),
        _c("span", { staticClass: "accrue-percentage" }, [_vm._v("15%")]),
        _vm._v(" in cash rewards with "),
        _c("span", [
          _c("img", {
            staticClass: "accrue-logo-black-frames-img",
            attrs: { src: "/media/wysiwyg/accrue-logo-black.png" }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }