export const STEPS_CHAIN = [
    {
        'id': 'lens_category',
        'next': 'lens'
    },
    {
        'id': 'lens',
        'next': {
            'lens': {
                'prescription': 'prescription_type',
                'reading': 'lens_power',
                'nonprescription': 'nonprescription'
            }
        }
    },
    {
        'id': 'nonprescription',
        'next': {
            'nonprescription': {

                'sunglasses': 'step_antireflective',
                'computer': 'step_antireflective',
                'plano': 'step_antireflective',
                'transitions': 'step_antireflective'
            }
        }
    },
    {
        'id': 'prescription_type',
        'next': 'lens_type'
    },
    {
        'id': 'lens_power',
        'next': 'lens_type'
    },
    {
        'id': 'lens_type',
        'next': 'lens_options'
    },
    {
        'id': 'lens_options',
        'next': 'step_antireflective'
    },
    {
        'id': 'step_antireflective',
        'next': {
            'lens': {
                'prescription': {
                    'prescription_type': {
                        'single_vision_distance': 'single_vision_distance',
                        'single_vision_reading': 'single_vision_reading',
                        'single_vision': 'single_vision',
                        'progressive_standard': 'progressive_standard',
                        'progressive_wide': 'progressive_wide',
                        'progressive_premium': 'progressive_premium',
                        'bi_focal': 'bi_focal'
                    }
                },
                'reading': 'notes',
                'sunglasses': 'notes',
                'transitions': 'notes',
                'nonprescription': 'notes',
                'computer': 'notes'
            }
        }
    },
    {
        'id': 'single_vision_distance',
        'next': 'notes'
    },
    {
        'id': 'single_vision_reading',
        'next': 'notes'
    },
    {
        'id': 'single_vision',
        'next': 'notes'
    },
    {
        'id': 'progressive_standard',
        'next': 'notes'
    },
    {
        'id': 'progressive_wide',
        'next': 'notes'
    },
    {
        'id': 'progressive_premium',
        'next': 'notes'
    },
    {
        'id': 'bi_focal',
        'next': 'notes'
    },
    {
        'id': 'notes',
        'next': false
    }
]
