var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-modaltor",
        {
          staticClass: "redeem-modal",
          class: { wrapper: _vm.isModalOpen, collect: _vm.collect },
          attrs: {
            visible: _vm.isModalOpen,
            "default-width": "100%",
            "bg-overlay": "",
            "bg-panel": "",
            "close-scroll": false
          },
          on: { hide: _vm.hideModal }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.finished,
                  expression: "!finished"
                }
              ],
              staticClass: "redeem-img-container"
            },
            [
              _c("img", {
                staticClass: "redeem-img",
                attrs: {
                  src:
                    "/skin/frontend/lensabl/default/images/lensplus_giftcard_background.webp",
                  alt: "redeem image"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "redeem-container" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.collect && !_vm.finished,
                    expression: "collect && !finished"
                  }
                ],
                staticClass: "collect-reward"
              },
              [
                _vm._l(149, function(n) {
                  return _c("div", {
                    key: "confetti-" + n,
                    class: "confetti-" + n
                  })
                }),
                _vm._v(" "),
                _c("h2", [_vm._v("Choose Your Reward!")]),
                _vm._v(" "),
                _c("h5", { staticClass: "subtitle" }, [
                  _vm._v(
                    "Choose spending dollars towards new lenses, glasses, or contacts through Lensabl or a gift card to spend elsewhere - make your choice below!"
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "rewards-container" }, [
                  _c(
                    "div",
                    { staticClass: "lensabl-credit rewards-card" },
                    [
                      _c("h5", [
                        _c("strong", [
                          _vm._v(
                            "$" +
                              _vm._s(_vm.giftcardConfigs.lensabl_value) +
                              " Lensabl Credit"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "rewards-instruction" }, [
                        _vm._v(
                          "(Digital gift card for any future purchase delivered via email)"
                        )
                      ]),
                      _vm._v(" "),
                      _c("BaseButton", {
                        attrs: {
                          type: "submit",
                          text: "CHOOSE",
                          tabindex: "3"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.collectRewardLensablGiftCard($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gift-card rewards-card" },
                    [
                      _c("h5", [
                        _c("strong", [
                          _vm._v(
                            "$" +
                              _vm._s(_vm.giftcardConfigs.tremendous_value) +
                              " Digital Reward"
                          ),
                          _c("br"),
                          _vm._v("(i.e. Amazon Gift Card, Visa Gift Card, etc)")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "rewards-instruction" }, [
                        _vm._v("(Takes you to an external site)")
                      ]),
                      _vm._v(" "),
                      _c("BaseButton", {
                        attrs: {
                          type: "submit",
                          text: "CHOOSE",
                          tabindex: "3"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.collectRewardTremendous($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.finished,
                    expression: "finished"
                  }
                ]
              },
              [
                _vm._l(20, function(n) {
                  return _c("div", {
                    key: "confetti-" + n,
                    class: "confetti-" + n
                  })
                }),
                _vm._v(" "),
                _c("h2", [
                  _vm._v("You will receive a Lensabl Gift Card in your email.")
                ])
              ],
              2
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }