<template>
    <LensablPlusFree v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import LensablPlusFree from '../../components/lensablplus/LensablPlusFree.vue'

export default {
    name: 'RouterLensablPlusFree',
    components: {
        LensablPlusFree
    },
    extends: App
}
</script>
