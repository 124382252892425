var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scg-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "scg-container-colors-container" },
      [
        _c(
          "div",
          { staticClass: "scg-container-colors scg-color-standard" },
          [
            _c("h2", { staticClass: "scg-colors-header" }, [
              _vm._v("Standards Colors")
            ]),
            _vm._v(" "),
            _vm._l(_vm.standardColors, function(color) {
              return [
                _c(
                  "section",
                  {
                    key: color.color,
                    class: "scg-colors-row scg-" + color.color + "-row"
                  },
                  [
                    _c("div", { staticClass: "scg-colors-row_title-row" }, [
                      _c("h2", { staticClass: "scg-colors-header" }, [
                        _vm._v(_vm._s(color.color))
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "scg-title-img",
                        attrs: { src: color.img, alt: color.alt }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class:
                          "scg-colors-row_lens-img-row " +
                          color.color +
                          "-lens-img-row"
                      },
                      _vm._l(color.images, function(image, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "scg-lens-item" },
                          [
                            _c("img", {
                              staticClass: "scg-lens-img",
                              attrs: { src: image.src, alt: image.alt },
                              on: { click: _vm.toggleModal }
                            }),
                            _vm._v(" "),
                            _c("p", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: image.spanTitle,
                                  expression: "image.spanTitle"
                                }
                              ]
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "scg-container-colors scg-color-fashion" },
          [
            _c("h2", { staticClass: "scg-colors-header" }, [
              _vm._v("Fashion Colors")
            ]),
            _vm._v(" "),
            _vm._l(_vm.fashionColors, function(color) {
              return [
                _c(
                  "section",
                  {
                    key: color.color,
                    class: "scg-colors-row scg-" + color.color + "-row"
                  },
                  [
                    _c("div", { staticClass: "scg-colors-row_title-row" }, [
                      _c("h2", { staticClass: "scg-colors-header" }, [
                        _vm._v(_vm._s(color.color))
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "scg-title-img",
                        attrs: { src: color.img, alt: color.alt }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "scg-colors-row_lens-img-row fashion-lens-img-row"
                      },
                      _vm._l(color.images, function(image, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "scg-lens-item" },
                          [
                            _c("img", {
                              staticClass: "scg-lens-img",
                              attrs: { src: image.src, alt: image.alt },
                              on: { click: _vm.toggleModal }
                            }),
                            _vm._v(" "),
                            _c("p", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: image.spanTitle,
                                  expression: "image.spanTitle"
                                }
                              ]
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "scg-container-colors scg-container-mirror" },
          [
            _c("h2", { staticClass: "scg-colors-header" }, [_vm._v("Mirror")]),
            _vm._v(" "),
            _vm._l(_vm.mirrorColors, function(color) {
              return [
                _c(
                  "section",
                  {
                    key: color.color,
                    staticClass: "scg-colors-row scg-mirror-row"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "scg-colors-row_lens-img-row" },
                      _vm._l(color.images, function(image, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "scg-lens-item" },
                          [
                            _c("img", {
                              staticClass: "scg-lens-img",
                              attrs: { src: image.src, alt: image.alt },
                              on: { click: _vm.toggleModal }
                            }),
                            _vm._v(" "),
                            _c("p", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: image.spanTitle,
                                  expression: "image.spanTitle"
                                }
                              ]
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "scg-container-colors scg-container-transitions" },
          [
            _c("h2", { staticClass: "scg-colors-header" }, [
              _vm._v("Transitions")
            ]),
            _vm._v(" "),
            _vm._l(_vm.transitionsColors, function(color) {
              return [
                _c(
                  "section",
                  {
                    key: color.color,
                    staticClass: "scg-colors-row scg-transitions-row"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "scg-colors-row_lens-img-row" },
                      _vm._l(color.images, function(image, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "scg-lens-item" },
                          [
                            _c("img", {
                              staticClass: "scg-lens-img",
                              attrs: { src: image.src, alt: image.alt },
                              on: { click: _vm.toggleModal }
                            }),
                            _vm._v(" "),
                            _c("p", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: image.spanTitle,
                                  expression: "image.spanTitle"
                                }
                              ]
                            })
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.showModal
          ? _c(
              "Modal",
              {
                on: {
                  close: function($event) {
                    _vm.showModal = false
                  }
                }
              },
              [
                _c("div", { staticClass: "scg-modal-content" }, [
                  _c("img", {
                    staticClass: "scg-lens-img",
                    attrs: { src: _vm.selectedModalImage.src }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.selectedModalImage.title))])
                ])
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "scg-container-banner" }, [
      _c("div", { staticClass: "scg-container-banner-content" }, [
        _c("h1", [_vm._v("Lens Color Guide")]),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "/choose-my-lenses", title: "Lens Replacement" } },
          [
            _c(
              "div",
              { staticClass: "button button--copy-solid scg-shopnow-btn" },
              [
                _c("div", { staticClass: "button__container" }, [
                  _vm._v("SHOP NOW")
                ])
              ]
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }