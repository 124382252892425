var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.memberShipEdited
    ? _c("div", [
        _vm.redeemedItems.length
          ? _c(
              "table",
              {
                staticClass: "form-list redeem-items",
                attrs: { cellspacing: "0" }
              },
              [
                _c(
                  "tbody",
                  _vm._l(_vm.redeemedItems, function(item, index) {
                    return _c(
                      "tr",
                      {
                        key: "order-" + item.type + "-" + index,
                        staticClass: "tr-line",
                        class: item.type
                      },
                      [
                        _c("td", {
                          staticClass: "label",
                          domProps: { textContent: _vm._s(item.label) }
                        }),
                        _vm._v(" "),
                        _c("td", { staticClass: "scope-label" }, [
                          item.hasOwnProperty("redeemed_date")
                            ? _c("strong", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.formatDate(item.redeemed_date)
                                  )
                                }
                              })
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "scope-label" }, [
                          item.hasOwnProperty("grand_total")
                            ? _c("strong", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.formatPrice(item.grand_total)
                                  )
                                }
                              })
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "scope-label" }, [
                          _c(
                            "a",
                            {
                              staticClass: "form-button",
                              attrs: { href: item.order_url, target: "_blank" }
                            },
                            [_vm._v("View Order")]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          : _c("strong", [_vm._v("No Orders")])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }