import {mapState} from 'vuex'
import _map from 'lodash/map'

export default {
    name: 'contactsPrescriptionModel',
    data: () => ({
        errorText: ''
    }),
    computed: {
        ...mapState('contacts', ['eyeData', 'originalEyeData'])
    },
    methods: {
        powerOptions (index) {
            const power = this.eyeData[index].rx_dropdown.power
            if (power) {
                const sortedArray = this.sortValues(power)
                return _map(sortedArray, value => parseFloat(value).toFixed(2))
            }
        },
        cylOptions (index) {
            const cyl = this.eyeData[index].rx_dropdown.cyl
            if (cyl) {
                return this.sortValues(cyl)
            }
        },
        axisOptions (index) {
            const axis = this.eyeData[index].rx_dropdown.axis
            if (axis) {
                return this.sortValues(axis)
            }
        },
        bcOptions (index) {
            const bc = this.eyeData[index].rx_dropdown.bc
            if (bc) {
                const sortedArray = this.sortValues(bc)
                return sortedArray.map(value => parseFloat(value).toFixed(1))
            }
        },
        addOptions (index) {
            const add = this.eyeData[index].rx_dropdown.add
            if (add) {
                return this.sortValues(add)
            }
        },
        colorOptions (index) {
            const color = this.originalEyeData[index].color_options
            if (color && color.length && color[0]) {
                return color
            }
            return []
        },
        sortValues (rxType) {
            const array = Array.isArray(rxType) ? rxType : rxType.split(',')
            array.splice(array.length - 1)
            const positiveArray = array.filter(val => val >= 0)
            const negativeArray = array.filter(val => val < 0)
            const sortNegative = negativeArray.sort((a, b) => b - a)
            const sortPositive = positiveArray.sort((a, b) => a - b)
            return sortNegative.concat(sortPositive)
        }
    }
}
