<template>
    <ShippingAddressUpdate v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import ShippingAddressUpdate from '../../../components/shipping/address/Update.vue'

export default {
    name: 'RouterShippingAddressUpdate',
    components: {
        ShippingAddressUpdate
    },
    extends: App
}
</script>
