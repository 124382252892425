var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isPPECategory
      ? _c("div", { staticClass: "ppe-frame-description" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "Side and top shields provide an added layer of protection from harmful bacteria, particles, and irritants. These frames are perfect for essential workers, healthcare professionals, and anyone in need of a little extra peace of mind while still looking fashionably chic.\n        "
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isBoseCategory
      ? _c("div", [_c("BoseProductList"), _vm._v(" "), _c("BoseSeo")], 1)
      : _vm.isJamesOroCategory
      ? _c("div", [_c("JamesOroProductList")], 1)
      : _vm.isGoodrCategory
      ? _c("div", [_c("GoodrProductList")], 1)
      : _vm.isAeCategory
      ? _c("div", [_c("AeProductList")], 1)
      : _vm.isWhCategory
      ? _c("div", [_c("WhProductList")], 1)
      : _vm.isRayBanMetaCategory
      ? _c("div", [_c("RayBanMetaProductList")], 1)
      : _c(
          "div",
          [
            _c("CatalogProductList", {
              staticClass: "grid products-grid",
              class: { "ppe-products-wrapper": _vm.isPPECategory },
              attrs: { products: _vm.leSpecsProducts }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("strong", [_vm._v("Essential Protection, Everyday Style")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }