export default {
    namespaced: true,
    state: () => ({

    }),
    getters: {

    },
    modules: {
    }
}
