<template>
    <ul>
        <template v-for="step in stepsList">
            <li v-if="step.summary_label" :key="step.id" :class="step.id" class="item">
                <div class="label">
                    {{ framesPartnerPage && step.summary_partner_label ? step.summary_partner_label + ':' : step.summary_label + ':' }}
                </div>
                <div v-if="step.id === 'customShippingLabel'" class="renderer-options summary-value"><b>Free</b> <span class="option-value free-shipping-label"><HelpBubble :placement="placement" :data="component_help" /></span></div>
                <Renderer v-else :id="step.id" :name="step.value_renderer" :place="place" :class="'summary-value'" />
            </li>
        </template>
    </ul>
</template>

<script>
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _cloneDeep from 'lodash/cloneDeep'

import {mapGetters} from 'vuex'
import Renderer from '../renderer/Renderer.vue'

import {
    PRESCRIPTION_TYPE_ONLINE,
    PARTNER_WITH_SIZE,
    FRAMES_STEP,
    FRAMES_STEP_WITH_SIZE,
    FRAMES_STEP_WITH_COLOR,
    FRAMES_STEP_WITH_ADDON
} from '../../app/utils/constants'
import product from '../catalog/product/storage/product/view'
import HelpBubble from '../help_bubble/HelpBubble.vue'

export default {
    name: 'SummaryTotalsInfo',
    components: {
        Renderer,
        HelpBubble
    },
    props: {
        place: {
            type: [String],
            default: ''
        },
        customSummary: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            component_help: {
                name: 'help-bubble',
                params: {
                    'text': 'Standard: 1 - 5 business days each way.<br>Priority: 1 - 3 business days each way.<br>Overnight: 1 business day each way.<br><span style="font-size:11px">**Lensabl is based in Los Angeles, CA</span>'
                }
            },
            placement: 'bottom'
        }
    },
    computed: {
        ...mapGetters('configurator', ['isSpectacles']),
        ...mapGetters('productView', ['productView', 'colorItem', 'isClipOnType', 'isSpectaclesGen']),
        ...mapGetters('configurator/steps', ['visibleList']),
        ...mapGetters('values', ['values', 'checkoutData', 'framesPartnerPage']),
        stepsList () {
            let steps = []
            for (const step of this.visibleList) {
                if (this.canAddStep(step)) {
                    steps.push(step)
                }

                const fakeColorStep = this.getFakeColorStep(step)
                if (fakeColorStep) {
                    if (this.isSpectacles) {
                        const lensId = _findIndex(steps, {'id': 'lens'})
                        steps.splice(lensId + 1, 0, fakeColorStep)
                    } else if (!_find(steps, s => s.id === fakeColorStep.id)) {
                        steps.push(fakeColorStep)
                    }
                }

                if (this.isPrescriptionStep(step) && this.hasPrescriptionName()) {
                    const fakePrescriptionStep = this.getFakePrescriptionStep()
                    steps.push(fakePrescriptionStep)
                }
            }

            if (this.framesPartnerPage && !this.isSpectacles) {
                let fakeFramesStep
                if (PARTNER_WITH_SIZE.indexOf(this.framesPartnerPage) !== -1) {
                    fakeFramesStep = _cloneDeep(FRAMES_STEP_WITH_SIZE)
                } else if (this.isClipOnType) {
                    fakeFramesStep = _cloneDeep(FRAMES_STEP_WITH_ADDON)
                } else if (this.isSpectaclesGen) {
                    fakeFramesStep = _cloneDeep(FRAMES_STEP)
                } else {
                    fakeFramesStep = _cloneDeep(FRAMES_STEP_WITH_COLOR)
                }
                steps = fakeFramesStep.concat(steps)
            }

            if (this.customSummary) {
                const customShippingLabel = {
                    id: 'customShippingLabel',
                    summary_label: 'Shipping',
                    value_renderer: 'renderer-options'
                }

                steps.push(customShippingLabel)
            }

            return steps
        }
    },
    created () {
        this.$store.dispatch('configurator/steps/updateVisibleList')
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['productView/']) {
            this.$store.registerModule('productView', product(this.$store))
        }

        this.$store.dispatch('productView/fetchDefaultData')
    },
    methods: {
        canAddStep (step) {
            if (step.no_summary) {
                return false
            }

            if (this.isBoxCodeStep(step) && this.isBoxUndefined()) {
                return false
            }

            if (this.isLensTypeStep(step) && this.isSunglassesProduct()) {
                return false
            }
            return true
        },
        isBoxCodeStep (step) {
            return step.id === 'box'
        },
        isBoxUndefined () {
            return !this.values.box.code || !this.values.box.balance
        },
        isLensTypeStep (step) {
            return step.id === 'lens_type'
        },
        isSunglassesProduct () {
            return this.checkoutData.product === 'sunglass_lens'
        },
        isPrescriptionStep (step) {
            const prescriptionIds = [
                'single_vision',
                'progressive'
            ]
            return prescriptionIds.indexOf(step.id) !== -1
        },
        hasPrescriptionName () {
            const values = this.values
            const prescription_type = values.prescription_type
            const prescription = values.prescriptionValues
            if (!values[prescription_type]) {
                return false
            }
            const prescriptionValue = values[prescription_type]()
            return Boolean(prescription.name) && (prescriptionValue === PRESCRIPTION_TYPE_ONLINE)
        },
        getFakePrescriptionStep () {
            const fakePrescriptionNameStep = {
                'id': 'prescription_name',
                'summary_label': 'Rx Name',
                'value_renderer': 'renderer-prescription-name'
            }
            return fakePrescriptionNameStep
        },
        getFakeColorStep (step) {
            const stepId = step.id

            if ((['lens_type', 'sunglass_lens', 'nonprescription'].indexOf(stepId) === -1 && stepId.indexOf('spectacles') === -1)) {
                return false
            }

            const checkoutData = this.checkoutData
            if (!checkoutData) {
                return false
            }

            let lensType

            if (this.framesPartnerPage === 'spectacles') {
                lensType = this.colorItem
            } else {
                lensType = checkoutData.lens_type
            }

            if (!lensType) {
                return false
            }

            if (!lensType.color) {
                return false
            }

            return {
                'id': 'color',
                'summary_label': 'Color',
                'value_renderer': 'renderer-color'
            }
        }
    }
}
</script>
