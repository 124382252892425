var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [_vm.brand + "-seo-parent-container"] }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-prescription-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-prescription" }, [
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Kate Spade Glasses")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Your prescription glasses should fill you with confidence, which is why you should order a set of fashionable Kate Spade eyeglasses from Lensabl. We have the largest selection of popular Kate Spade styles, with lenses that meet your prescription needs. Browse Lensabl's Kate Spade eyeglass inventory today."
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-img" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-frames-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-frames" }, [
        _c("div", { staticClass: "shopframes-seo-frames-img" }),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Premium Kate Spade Eyeglass Frames")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Kate Spade leads the industry in women's eyewear with fashionable, durable frames. Our Kate Spade frames are available in materials like acetate, titanium, steel, plastic and more. We carry a wide selection of large, medium and small sizes — use our size finder tool to narrow your selection by the best fit."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "Have a style in mind? Order Kate Spade ladies' glasses through Lensabl for popular choices like Payton, Bendall, Rainey and more. We have Kate Spade frames to complement all your fashion preferences. We carry various color options, too, meaning you can find black, navy, tortoise or pink Kate Spade glasses online and have them delivered in day"
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-designer-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-designer" }, [
        _c("div", { staticClass: "shopframes-seo-text" }, [
          _c("h2", [_vm._v("Kate Spade Glasses That Fill Your Prescription")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Pair your Kate Spade frames with a lens that meets your precise optical needs. Lensabl has single vision, progressive and bifocal lenses for Kate Spade frames. You'll look and feel your best wearing corrective eyeglasses that complement your style."
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "Buying Kate Spade frames for your prescription is easy thanks to Lensabl's convenient online services. "
            ),
            _c("a", { attrs: { href: "/online-vision-prescription" } }, [
              _c("span", { staticClass: "seo-contact-link" }, [
                _vm._v("Renew your prescription")
              ])
            ]),
            _vm._v(" through our website with a virtual appointment or "),
            _c("a", { attrs: { href: "/prescription-scanner-app/qr" } }, [
              _c("span", { staticClass: "seo-contact-link" }, [
                _vm._v("scan your current glasses")
              ])
            ]),
            _vm._v(" for the most accurate online shopping experience.")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shopframes-seo-designer-img" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "shopframes-seo-eyeglass-container" }, [
      _c("div", { staticClass: "shopframes-seo-row seo-eyeglass" }, [
        _c("div", { staticClass: "shopframes-seo-text seo-eyeglass-text" }, [
          _c("h2", [_vm._v("Order Kate Spade Glasses Online")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Ready for glasses that give amazing style and vision? Kate Spade eyeglasses are a favorite among women's prescription eyewear, and you can order them online through Lensabl. Buy Kate Spade prescription glasses from Lensabl today and benefit from free standard shipping, risk-free returns, a 12-month warranty and more."
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "/glasses-frames", title: "Glasses Frames" } },
          [
            _c("div", { staticClass: "button button--copy-solid" }, [
              _c("div", { staticClass: "button__container" }, [
                _vm._v("GET NEW FRAMES")
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }