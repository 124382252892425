import axios from 'axios'

let subscriptionService = null

class SubscriptionService {
    constructor ({api_key}) {
        this.api_key = api_key
        this.cors_url = window.location.hostname !== 'www.lensabl.com' ? 'https://cors-anywhere.herokuapp.com/' : ''
    }

    getHeaders () {
        const options = {
            headers: {
                'Authorization': this.api_key,
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/xml',
                'X-Api-Version': '2.5',
                'Content-Type': 'application/xml; charset=utf-8'
            }
        }
        return options
    }

    getSubscriptions () {
        const path = `${this.cors_url}https://lensabl.recurly.com/v2/accounts/Test%20customer/subscriptions`

        return new Promise((resolve, reject) => {
            axios.get(path, this.getHeaders()).then((response) => {
                const result = this.xmlParse(response.data)
                console.log(response)
                resolve(result)
            }, (error) => {
                console.log(error)
                reject(error)
            }).catch(e => reject(e))
        })
    }

    getSubscription () {
        const path = `${this.cors_url}https://lensabl.recurly.com/v2/subscriptions/51cd775e4a10674b9ecf3a42baad8e55`

        return new Promise((resolve, reject) => {
            axios.get(path, this.getHeaders()).then((response) => {
                const result = this.xmlParse(response.data)
                console.log(response)
                resolve(result)
            }, (error) => {
                console.log(error)
                reject(error)
            }).catch(e => reject(e))
        })
    }

    getBilling (acctId) {
        const path = `${this.cors_url}https://lensabl.recurly.com/v2/accounts/${acctId}/billing_info`

        return new Promise((resolve, reject) => {
            axios.get(path, this.getHeaders()).then((response) => {
                const result = this.xmlParse(response.data)
                console.log(response)
                resolve(result)
            }, (error) => {
                console.log(error)
                reject(error)
            }).catch(e => reject(e))
        })
    }

    updateBilling (xml, acctId) {
        const path = `${this.cors_url}https://lensabl.recurly.com/v2/accounts/${acctId}/billing_info`

        return new Promise((resolve, reject) => {
            axios.put(path, xml, this.getHeaders()).then((response) => {
                const result = this.xmlParse(response.data)
                console.log(response)
                resolve(result)
            }, (error) => {
                console.log(error)
                reject(error)
            }).catch(e => reject(e))
        })
    }

    updateShipping (xml) {
        // need to get Shipping Address ID associated with subscription from
        const path = `${this.cors_url}https://lensabl.recurly.com/v2/accounts/12345/shipping_addresses/2912883933924026411`

        return new Promise((resolve, reject) => {
            axios.put(path, xml, this.getHeaders()).then((response) => {
                const result = this.xmlParse(response.data)
                console.log(response)
                resolve(result)
            }, (error) => {
                console.log(error)
                reject(error)
            }).catch(e => reject(e))
        })
    }

    getShipping (link) {
        // const id = this.getId(link)
        const path = `${this.cors_url}https://lensabl.recurly.com/v2/accounts/12345/shipping_addresses/2912883933924026411`

        return new Promise((resolve, reject) => {
            axios.get(path, this.getHeaders()).then((response) => {
                const result = this.xmlParse(response.data)
                resolve(result)
            }, (error) => {
                console.log(error)
                reject(error)
            }).catch(e => reject(e))
        })
    }

    getInvoice (link) {
        const id = this.getId(link)
        const path = `${this.cors_url}https://lensabl.recurly.com/v2/invoices/${id}`

        return new Promise((resolve, reject) => {
            axios.get(path, this.getHeaders()).then((response) => {
                const result = this.xmlParse(response.data)
                resolve(result)
            }, (error) => {
                console.log(error)
                reject(error)
            }).catch(e => reject(e))
        })
    }

    getId (url) {
        const link = url
        const urlArray = link.split('/')
        return urlArray[urlArray.length - 1]
    }

    xmlParse (data) {
        const parser = new DOMParser() // initialize dom parser
        const srcDOM = parser.parseFromString(data, 'application/xml')
        return this.xml2json(srcDOM)
    }

    xml2json (srcDOM) {
        const children = [...srcDOM.children]
        // base case for recursion.
        if (!children.length) {
            if (srcDOM.getAttribute('href')) {
                const attrs = srcDOM.attributes
                const output = {}
                for (let i = attrs.length - 1; i >= 0; i--) {
                    output[attrs[i].name] = attrs[i].value
                }
                output.value = srcDOM.innerHTML
                return output
            } else {
                return srcDOM.innerHTML
            }
        }

        // initializing object to be returned.
        const jsonResult = {}
        for (const child of children) {
            // checking is child has siblings of same name.
            const childIsArray = children.filter(eachChild => eachChild.nodeName === child.nodeName).length > 1
            // if child is array, save the values as array, else as strings.
            if (childIsArray) {
                if (jsonResult[child.nodeName] === undefined) {
                    jsonResult[child.nodeName] = [this.xml2json(child)]
                } else {
                    jsonResult[child.nodeName].push(this.xml2json(child))
                }
            } else {
                jsonResult[child.nodeName] = this.xml2json(child)
            }
        }
        return jsonResult
    }

    url () {
        return 'https://:lensabl.recurly.com/v2'
    }
}

export default function getSubscriptionService (options = {}) {
    if (!subscriptionService) {
        subscriptionService = new SubscriptionService(options)
    }
    return subscriptionService
}
