var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "option",
      class: {
        active: _vm.isActive,
        "before-active": _vm.beforeActive,
        "after-active": _vm.afterActive
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "option-container",
          class: {
            "has-thumbnail": _vm.option.thumbnail,
            "has-price": _vm.option.price
          },
          on: { click: _vm.optionSelect }
        },
        [
          (_vm.option.thumbnail &&
            _vm.option.component_extra_options &&
            !_vm.isActive) ||
          (_vm.option.thumbnail && !_vm.option.component_extra_options)
            ? _c(
                "div",
                {
                  staticClass: "option-thumbnail",
                  class: { extraOptions: _vm.option.component_extra_options }
                },
                [_c("img", { attrs: { src: _vm.option.thumbnail } })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "option-descriptions" },
            [
              _c(
                "div",
                { staticClass: "option-label" },
                [
                  _c("span", {
                    directives: [
                      {
                        name: "dompurify-html",
                        rawName: "v-dompurify-html",
                        value: _vm.option.label,
                        expression: "option.label"
                      }
                    ],
                    staticClass: "label-text"
                  }),
                  _vm._v(" "),
                  _vm.option.id === "upload"
                    ? _c("span", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value:
                              _vm.option.component_extra_options.label_extra,
                            expression:
                              "option.component_extra_options.label_extra"
                          }
                        ],
                        staticClass: "upload-label-extra"
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.componentHelpShow && _vm.option.component_help
                    ? _c(_vm.option.component_help.name, {
                        tag: "component",
                        attrs: { data: _vm.option.component_help }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.manufacturerShow && _vm.option.manufacturer
                    ? _c("span", { staticClass: "manufacturer-container" }, [
                        _c("span", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.option.manufacturer,
                              expression: "option.manufacturer"
                            }
                          ]
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.option.popular
                    ? _c("div", { staticClass: "popular-container" }, [
                        _c("span", { staticClass: "option.popular" }, [
                          _vm._v("(Most Popular)")
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.option.id === "single_vision_premium"
                    ? _c(
                        "span",
                        [
                          _c("HelpBubble", {
                            attrs: {
                              data: _vm.option.component_help,
                              placement: "bottom",
                              classes: "lens_option_help_bubble"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("picture", [
                _c("source", {
                  attrs: {
                    srcset: "/media/wysiwyg/transitions-certified-logo.webp",
                    type: "image/webp"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    srcset: "/media/wysiwyg/transitions-certified-logo.png",
                    type: "image/png"
                  }
                }),
                _vm._v(" "),
                _vm.option.id === "transitions_lenses" && _vm.isActive
                  ? _c("img", {
                      staticClass: "option-transitions-cert-logo",
                      attrs: {
                        src: "/media/wysiwyg/transitions-certified-logo.webp",
                        alt: "Transitions Certified Logo"
                      }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: _vm.getOptionDescription,
                    expression: "getOptionDescription"
                  }
                ],
                staticClass: "option-description",
                class: { "small-description": _vm.option.small_description }
              }),
              _vm._v(" "),
              _vm.showTempoDisclaimer && _vm.isActive && _vm.isBoseLanding
                ? _c("div", { staticClass: "option-description" }, [_vm._m(0)])
                : _vm._e(),
              _vm._v(" "),
              _vm.showWillowsDisclaimer && _vm.isActive && _vm.isWillowsPage
                ? _c(
                    "div",
                    { staticClass: "single-vision-subtext bifocal-subtext" },
                    [
                      _c("span", { style: { fontWeight: "bold" } }, [
                        _vm._v(
                          "Disclaimer: This frame can only accommodate prescriptions with up to " +
                            _vm._s(_vm.willowsSubText) +
                            " SPH. Lensabl will not be able to fulfill orders with stronger prescriptions. Please contact "
                        ),
                        _c(
                          "a",
                          { attrs: { href: "mailto:support@lensabl.com" } },
                          [_vm._v("support@lensabl.com")]
                        ),
                        _vm._v(" with any questions.")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showBiFocalDisclaimer &&
              _vm.values.frames_name === "James Oro Frames - Caviar"
                ? _c("div", { staticClass: "single-vision-subtext" }, [
                    _c("span", { style: { fontWeight: "bold" } }, [
                      _vm._v(
                        "Disclaimer: This frame cannot accommodate any prescription with a power greater than +/- 4.00 SPH. Please contact "
                      ),
                      _c(
                        "a",
                        { attrs: { href: "mailto:support@lensabl.com" } },
                        [_vm._v("support@lensabl.com")]
                      ),
                      _vm._v(" with any questions.")
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showBiFocalDisclaimer &&
              _vm.values.frames_name === "James Oro Frames - Amour"
                ? _c("div", { staticClass: "single-vision-subtext" }, [
                    _c("span", { style: { fontWeight: "bold" } }, [
                      _vm._v(
                        "Disclaimer: This frame cannot accommodate any prescription with a power greater than +/- 5.00 SPH. Please contact "
                      ),
                      _c(
                        "a",
                        { attrs: { href: "mailto:support@lensabl.com" } },
                        [_vm._v("support@lensabl.com")]
                      ),
                      _vm._v(" with any questions.")
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.componentHelpShow &&
              (_vm.option.block_prescription_id === "progressive" ||
                _vm.option.block_prescription_id === "single_vision")
                ? _c("HelpBubble", {
                    attrs: { data: _vm.option.component_help }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.option.price
            ? _c(
                "div",
                {
                  staticClass: "option-price-container",
                  class: _vm.option.checkout_value
                },
                [
                  _vm.option.id !== "167"
                    ? _c("div", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: _vm.option.price,
                            expression: "option.price"
                          }
                        ],
                        staticClass: "option-price"
                      })
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "option-checkbox-container",
              class: { active: _vm.isActive || _vm.isActiveParentOption }
            },
            [_c("div", { staticClass: "option-checkbox" })]
          )
        ]
      ),
      _vm._v(" "),
      _vm.option.component_extra_options && _vm.isActive
        ? _c(
            "div",
            { staticClass: "extra-component-container" },
            [
              _c(_vm.option.component_extra_options.name, {
                tag: "component",
                class: [
                  "extra-component " + _vm.option.component_extra_options.name
                ],
                attrs: { id: _vm.id, data: _vm.option.component_extra_options }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("small", [_c("i", [_vm._v("*Not available with Tempo frame")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }