<template>
    <div :class="productCategoryName">
        <div
            v-show="productView.productInfoShow"
            class="product-configurator-configuration product-details-holder row lensabl-wrapper frames-wrapper bose-container"
        >
            <div class="category-left">
                <div class="bose-header mobile">
                    <h1 class="product-name-mobile"><span>{{ productName }}</span></h1>
                </div>

                <Gallery :class="{'vertical-thumbs': isDesktop}" />
            </div>
            <div class="look-info category-left">
                <div class="product-name">
                    <div class="bose-header">
                        <h1 class="product-name-desktop"><span>{{ productName }}</span></h1>
                    </div>
                    <div v-dompurify-html="productDescription" class="bose-description"></div>
                </div>

                <div class="lensabl-colors instructions">
                    <div class="swatch-opt hyper-x">
                        <div v-if="product_name.toLowerCase() === 'duo bells' || product_name.toLowerCase() === 'duo lennox'" class="hobie-additional">
                            <p class="hobie-clipons">Select more than one color below to add-on additional clip-ons ($40 each):</p>
                        </div>
                        <ColorSwatches
                            :colors="colors"
                            :checked-index="parseInt(checkedIndex)"
                            :option-item-class="'decorate bigger img'"
                            :data-swatch-product="product_name"
                            @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor"
                        />
                    </div>

                    <div class="hobie-additional">
                        <AdditionalAttributes :data-attributes="additionalAttributes" class="inline" />
                    </div>

                    <p v-dompurify-html="instructionHtml" class="p-instruction"></p>
                    <p v-dompurify-html="affirmHtml" class="affirm-shop-frames"></p>
                    <div class="accrue-frames-section" :style="{ 'margin-bottom': '0.5rem', 'font-size': '15px' }">
                        <span>or save up and earn <span class="accrue-percentage">15%</span> in cash rewards with <span><img src="/media/wysiwyg/accrue-logo-black.png" class="accrue-logo-black-frames-img" /></span></span>
                    </div>

                    <div v-if="isAvailable && !isDesktop" class="product-options-bottom">
                        <div class="actions">
                            <BaseButton :text="'Choose Your Lenses'" @click.native="isMobile ? mobileSwitcher() : goToConfigurator()">
                                SELECT LENSES
                            </BaseButton>
                        </div>
                    </div>

                    <div v-if="!isAvailable" class="look-params">
                        <div class="look-item">
                            <span class="look-item-info" style="color:red"> This item is sold out</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AnagramAdBar />
        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator />
        </div>
    </div>
</template>

<script>
import CatalogProductView from './CatalogProductView.vue'
import BaseButton from '../../base/BaseButton.vue'
import ResetService from '../../../app/mixins/reset-service'
import {mapGetters} from 'vuex'
import _map from 'lodash/map'
import getSizeHelper from './lensabl/constants/size'
import AdditionalAttributes from './view/AdditionalAttributes.vue'
import AnagramAdBar from '../../anagram/AnagramAdBar.vue'

export default {
    name: 'CatalogProductBoseView',
    components: {
        BaseButton,
        AdditionalAttributes,
        AnagramAdBar
    },
    extends: CatalogProductView,
    mixins: [ResetService],
    data: () => {
        return {
            instructionHtml: '',
            affirmHtml: '',
            sizeIndex: '',
            frameSize: 0
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isDesktop', 'isMobile']),
        ...mapGetters('productView', ['productView', 'frame_price', 'images', 'productCategoryName', 'colorItem', 'getCurrentProductAttribute']),
        additionalAttributes () {
            return [
                {
                    label: 'Size',
                    value: this.getCurrentProductAttribute('size')
                },
                {
                    label: 'Material',
                    value: this.getCurrentProductAttribute('material')
                }
            ]
        },
        productName () {
            return String(this.product_name).replace(/&#39;/g, "'")
        },
        hasSimpleDescription() {
            return this.currentImage.description ? true : false
        },
        productDescription(){
            let desc;
            if (!this.hasSimpleDescription){
                desc = this.product_description
            }else{
                desc = this.currentImage.description
            }
           return this.formattedProductDescription(desc)
        }
    },
    watch: {
        frame_price () {
            this.setInstructionText(this.frame_price)
            this.setAffirmPrice(this.frame_price)
        }
    },
    mounted () {
        this.setInstructionText(this.frame_price)
        this.setAffirmPrice(this.frame_price)
        this.getSizeFromUrl()
        this.updateSize()
    },
    created () {
        const {
            size_helpComponent
        } = getSizeHelper({assets_url: this.$store.getters['storeView/assets_url']})
        this.size_components = size_helpComponent
        this.sunIndex = 0
    },
    methods: {
        changeCurrentColor (index) {
            this.setCheckedIndex(index)
            this.frameSize = index
        },
        updateSize () {
            this.setValuesService(this.colors[this.frameSize])
            this.changeCurrentColor(this.frameSize)
        },
        setInstructionText (frame_price, delPrice = false) {
            this.instructionHtml = 'Starting from '
            if (delPrice) {
                this.instructionHtml += '<del><span class="amount">$' + delPrice + '</span></del>'
            }

            this.instructionHtml += '<span>$' + frame_price + '</span>'
        },
        setAffirmPrice (frame_price) {
            const dividedPrice = parseFloat(frame_price) / 3
            const affirmPayment = '$' + Math.round(dividedPrice)
            this.affirmHtml = 'or 3 payments of ' + affirmPayment + ' with <span class="__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed">Affirm</span>'
        },
        goToConfigurator () {
            this.scrollToStepHead(this.visibleFirstStep.id)
        },
        getSizeFromUrl () {
            const getUrlParams = url => {
                const urlWithParams = `${url}`.split('?')
                if (urlWithParams.length > 1) {
                    return urlWithParams[1].split('&').reduce(
                        (params, pair) => ((key, val) => key ? {
                            ...params,
                            [key]: val
                        } : params)(...`${pair}=`.split('=').map(decodeURIComponent)),
                        {}
                    )
                }
            }
            const query = getUrlParams(window.location)
            let indexSize = 0
            const sizes = _map(this.colors, item => (item.color).replace(/\s/g, '_').replace(/-/g, '').toLowerCase())

            Object.keys(sizes).forEach(key => {
                if (query && query.hasOwnProperty(sizes[key])) {
                    indexSize = key
                }
            })

            window.onload = () => this.setCheckedIndex(indexSize.toString())
        }
    }
}
</script>
