var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ],
      class: _vm.id
    },
    [
      _vm.config.title
        ? _c("h2", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.title,
                expression: "config.title"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h3", {
        directives: [
          {
            name: "dompurify-html",
            rawName: "v-dompurify-html",
            value: _vm.config.description,
            expression: "config.description"
          }
        ],
        staticClass: "description"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "actions-toolbar" }, [
        _c(
          "div",
          { staticClass: "primary" },
          [
            _c("BaseButton", {
              staticClass: "button--copy-solid-white-blue button--small",
              attrs: { text: "Return to Lensabl Home" },
              nativeOn: {
                click: function($event) {
                  return _vm.continueNxt($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }