export const ANTIREFLECTIVE_INFO = {
    'label': 'Anti-Reflective Coating',
    'desc': 'Standard AR is included ',
    'image': '<span class="icon anti-reflective"></span>',
    'component_help': {
        'name': 'help-bubble',
        'params': {
            'text': 'AR'
        }
    }
}

/**
 * Objects in ANTIREFLECTIVE_STEP array can have an optional 'disclaimer' property if necessary
 * @property {string} [disclaimer] - optional
 */
export const ANTIREFLECTIVE_STEP = [
    {
        'price': 'Free',
        'desc': 'Hydrophobic Coating (repels water), Scratch Resistance, 100% UV Protection',
        'checked': true,
        'redeemable': true,
        'value': 'Standard AR',
        'alias': 'Standard Lensabl AR',
        'show': true,
        'id': 'standard_ar',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Hydrophobic Coating (repels water), Scratch Resistance, 100% UV Protection</p> '
            }
        }
    },
    {
        'price': 55,
        'desc': 'Super Hydrophobic Coating (repels water, dust, and smudges), Superior Scratch Resistance, Smudge Resistant, 100% UV Protection',
        'checked': false,
        'value': 'Empire Green Plus AR',
        'alias': 'Premium Lensabl AR',
        'show': true,
        'id': 'empire_green_plus_ar',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Super Hydrophobic Coating (repels water, dust, and smudges), Superior Scratch Resistance, Smudge Resistant, 100% UV Protection</p> '
            }
        }
    },
    {
        'price': 80,
        'desc': 'Backside only Anti-Fog, with frontside only AR Super Hydrophobic Coating (repels water, dust, and smudges), Superior Scratch Resistance, Smudge Resistant, 100% UV Protection',
        'checked': false,
        'value': 'Essilor Anti Fog AR',
        'alias': 'Premium Lensabl AR with Anti-Fog',
        'show': true,
        'id': 'essilor_anti_fog_ar',
        'disclaimer': 'Please note: Anti-fog may take up to an additional 1-2 weeks. Due to the backside having an anti-fog coating, it will not have the hydrophobic which makes the lenses very slick and smooth to clean.',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Backside only Anti-Fog, with frontside only AR Super Hydrophobic Coating (repels water, dust, and smudges), Superior Scratch Resistance, Smudge Resistant, 100% UV Protection</p> '
            }
        }
    },
    {
        'price': 125,
        'desc': 'Super Hydrophobic Coating (repels water, dust, and smudges), Superior Scratch Resistance, Blue-light Protection, Smudge Resistant, 100% UV Protection',
        'checked': false,
        'value': 'Crizal Prevencia AR',
        'alias': 'Crizal Prevencia AR',
        'show': true,
        'id': 'crizal_prevencia_ar',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Super Hydrophobic Coating (repels water, dust, and smudges), Superior Scratch Resistance, Blue-light Protection, Smudge Resistant, 100% UV Protection</p> '
            }
        }
    },
    {
        'price': 25,
        'desc': 'Hydrophobic Coating (repels water), Scratch Resistance, Superior Blue-light Protection, 100% UV Protection',
        'checked': false,
        'value': 'Blue-Light Blocking AR',
        'alias': 'Blue-Light Blocking AR',
        'show': true,
        'id': 'blue_light_blocking_ar',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Hydrophobic Coating (repels water), Scratch Resistance, Superior Blue-light Protection, 100% UV Protection</p> '
            }
        }
    }
]

export const DISABLED_LENS = {
    'prescription': []
}

const ENABLED_AR_PRESCRIPTION_LENS = {
    'Clear': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR'],
    'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR'],
    //sun
    'Sun Tinted': ['Standard AR',],
    'Sun Tinted Gradient': ['Standard AR',],
    'Sun Tinted Match Color': ['Standard AR',],
    'Sun Tinted Match Color Gradient': ['Standard AR',],
    'Sun Polarized': ['Standard AR',],
    'Sun Polarized Gradient':['Standard AR',],
    'Sun Mirrored Polarized': ['Standard AR',],
    //transition
    'Transition Non-Polarized': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR'],
    'Transition Polarized': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR'],
    'Transition XTRActive': {
        'Mirror Color': ['Standard AR'],
        'Default': ['Standard AR', 'Empire Green Plus AR',],
    },
    'Transition DriveWear': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR'],
}

/**
 * @param {Object} DISABLED_PRESCRIPTION_LENS Selects which AR Upgrade options to exclude from specific products
 * @param {Object} DISABLED_PRESCRIPTION_LENS.product The product to modify
 * @param {Array} DISABLED_PRESCRIPTION_LENS.product.lensType product's AR options to exclude
 * @param {Object} DISABLED_PRESCRIPTION_LENS.product['Transition XTRActive'] Transition XTRActive product
 * @param {Array} DISABLED_PRESCRIPTION_LENS.product['Transition XTRActive'].options Specific options for XTRActive colored mirrors
 */
export const ENABLE_PRESCRIPTION_LENS = {
    'single_vision_distance_premium': {...ENABLED_AR_PRESCRIPTION_LENS},
    'single_vision_distance': {...ENABLED_AR_PRESCRIPTION_LENS},
    'single_vision_reading_premium': {...ENABLED_AR_PRESCRIPTION_LENS},
    'single_vision_reading': {...ENABLED_AR_PRESCRIPTION_LENS},
    'progressive_standard': {...ENABLED_AR_PRESCRIPTION_LENS},
    'progressive_wide': {...ENABLED_AR_PRESCRIPTION_LENS},
    'progressive_premium': {...ENABLED_AR_PRESCRIPTION_LENS,...{'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']}},
    'bi_focal': {...ENABLED_AR_PRESCRIPTION_LENS},
    // non prescription
    'reading_lens': {...ENABLED_AR_PRESCRIPTION_LENS},
    'sunglass_lens': {
        'Sun Tinted': ['Standard AR',],
        'Sun Tinted Gradient': ['Standard AR',],
        'Sun Tinted Match Color': ['Standard AR',],
        'Sun Tinted Match Color Gradient': ['Standard AR',],
        'Sun Polarized': ['Standard AR',],
        'Sun Polarized Gradient': ['Standard AR',],
        'Sun Mirrored Polarized': ['Standard AR',],
    },
    'transition_lens': {
        'Transition Non-Polarized': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR'],
        'Transition Polarized': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR'],
        'Transition XTRActive': {
            'Mirror Color': ['Standard AR'],
            'Default': ['Standard AR', 'Empire Green Plus AR',],
        },
        'Transition DriveWear': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR'],
    },
    'plano_lens' : {
        'Clear': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR'],
    },
    'computer_lens' :{
        'Clear': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR'],
    }
}
export const DISABLED_LENS_FRAMES = ['glass']

export const STEP_ANTIREFLECTIVE = 'step_antireflective'

