var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showVTO()
    ? _c(
        "div",
        { staticClass: "virtual-try-on" },
        [
          _c(
            "vue-modaltor",
            {
              attrs: {
                visible: _vm.isModalOpen,
                "default-width": "100%",
                "bg-overlay": "",
                "bg-panel": "",
                "close-scroll": false
              },
              on: { hide: _vm.hideModal }
            },
            [
              _c("div", { staticClass: "modal-vue-title" }, [
                _vm._v("Virtual Try-On")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "fitmix-container",
                  attrs: { id: "fitmix-container" }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("./assets/WEBSITE_Fit-Mix-Compatability.jpg"),
                      alt: ""
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "swatch-actions" }, [
                _vm.frameColors.length > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "swatch-opt",
                        class: { "transition-height": _vm.showColors }
                      },
                      [
                        _c("ColorSwatches", {
                          staticClass: "vertical",
                          attrs: {
                            colors: _vm.frameColors,
                            "checked-index": _vm.checkedIndex,
                            "attribute-title":
                              _vm.lensColors.length > 1 ? _vm.framesLabel : "",
                            "option-item-class": "decorate bigger img",
                            "add-label": _vm.addLabel
                          },
                          on: _vm._d({}, [
                            _vm.EVENT_CHANGE_CURRENT_COLOR,
                            _vm.changeColor
                          ])
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lensColors.length > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "swatch-opt",
                        class: { "transition-width": _vm.showColors }
                      },
                      [
                        _c("ColorSwatches", {
                          staticClass: "horizontal",
                          attrs: {
                            colors: _vm.lensColors,
                            "checked-index": _vm.checkedLensIndex,
                            "attribute-title": "Lens Colors",
                            "option-item-class": "decorate bigger img"
                          },
                          on: _vm._d({}, [
                            _vm.EVENT_CHANGE_CURRENT_COLOR,
                            _vm.changeLensColor
                          ])
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lensColors.length > 1 || _vm.frameColors.length > 1
                  ? _c(
                      "div",
                      {
                        staticClass: "swatch-color",
                        class: { active: _vm.showColors },
                        on: {
                          click: function($event) {
                            _vm.showColors = !_vm.showColors
                          }
                        }
                      },
                      [
                        _c("SwatchColorIcon", {
                          staticClass: "icon",
                          attrs: {
                            width: "40",
                            height: "40",
                            "view-box": "0 0 30 30"
                          }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "label",
                          domProps: {
                            textContent: _vm._s(
                              _vm.productCategoryName !== "sun"
                                ? _vm.framesLabel
                                : "Customize"
                            )
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "BaseButton",
            {
              class: {
                "button--copy-solid-dark": _vm.isDesktop,
                "button--copy-solid-white": !_vm.isDesktop
              },
              attrs: { text: "Virtual try-on" },
              nativeOn: {
                click: function($event) {
                  return _vm.showModal()
                }
              }
            },
            [
              _c("span", { staticClass: "icon-eye" }),
              _vm._v(" "),
              _c("span", [_vm._v("virtual try-on")])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }