<template>
    <div v-if="statusApi">
        <ContactsSubscriptionsDetail />
    </div>
</template>

<script>
import App from '../../../App.vue'
import ContactsSubscriptionsDetail from '../../../components/contacts/myaccount/SubscriptionsDetail.vue'

export default {
    name: 'RouterContactsSubscriptionsDetail',
    components: {
        ContactsSubscriptionsDetail
    },
    extends: App
}
</script>
