import {mapGetters, mapMutations, mapActions} from 'vuex'
import CommonPrescriptionModel from '../../../app/common/prescription_model'

export default {
    name: 'ParamsAbstractViewModel',
    props: ['id', 'data'],
    extends: CommonPrescriptionModel,
    computed: {
        ...mapGetters('configurator/steps', ['getStepObject', 'currentStep']),
        step () {
            return this.getStepObject(this.id)
        },
        value () {
            return this.step.value
        },
        help () {
            return this.data.help_components
        },
        showError () {
            return this.validateOnlinePrescriptionData
        },
        showEditVersion () {
            return this.isPrescriptionEditing(this.prescriptionData)
        },
        showAdd () {
            return this.showAddOptions(this.prescriptionData.prescription_type)
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.setStepDisabled(true)
        })
    },
    methods: {
        ...mapMutations('values', ['setDataToValues']),
        ...mapActions('configurator/steps', ['updateStep']),
        setStepDisabled (disabled) {
            this.updateStep({
                id: this.id,
                data: {disabled: disabled}
            })
        }
    },
    watch: {
        showError (show) {
            this.setStepDisabled(show)
        }
    }
}
