<template>
    <vue-modaltor
        v-if="history"
        :visible="history"
        :default-width="'350px'"
        :bg-overlay="''"
        :bg-panel="''"
        :close-scroll="false"
        class="prescription-history-container small"
        @hide="hideModal"
    >
        <h5>{{ name }}</h5>
        <p>Prescription Type: {{ type }}</p>
        <div class="edit-history">
            <ul>
                <li class="title">Edited by</li>
                <li v-for="(rxName, index) in rxHistory" :key="`${index}-${rxName.edited_by}`">{{ rxName.edited_by }}</li>
            </ul>
            <ul>
                <li class="title">When</li>
                <li v-for="(when, index) in rxHistory" :key="`${index}-${when.when}`">{{ when.when }}</li>
            </ul>
        </div>
    </vue-modaltor>
</template>

<script>
export default {
    name: 'History',
    props: {
        history: {
            type: Boolean,
            default: false,
            required: true
        },
        name: {
            type: String,
            default: '',
            required: true
        },
        type: {
            type: String,
            default: '',
            required: true
        },
        rxHistory: {
            type: Array,
            default: () => [],
            required: true
        }
    },
    data: () => ({}),
    methods: {
        hideModal () {
            this.$emit('close', false)
        }
    }
}
</script>

<style scoped>

</style>
