<template>
    <CatalogProductHobieView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogProductHobieView from '../../../components/catalog/product/CatalogProductHobieView.vue'

export default {
    name: 'RouterCatalogProductHobie',
    components: {
        CatalogProductHobieView
    },
    extends: App
}
</script>
