import _keys from 'lodash/keys'
import _first from 'lodash/first'
import * as abstract from '../../../../storage/abstract'

export const state = () => ({
    ...abstract.state(),
    product_config: {},
    sunglasses_sections: [],
    transitions_sections: [],
    upgrades_section: {},
    warranty_section: {},
    antireflective_sections: {},
    enable_subscription: '',
    configuratorShow: true,
    movingToCheckout: false,
    animationEnabled: false
})

export const getters = {
    sunglasses_sections: state => state.sunglasses_sections,
    transitions_sections: state => state.transitions_sections,
    upgrades_section: state => state.upgrades_section,
    warranty_section: state => state.warranty_section,
    antireflective_sections: state => state.antireflective_sections,
    antireflective_price: (state, getters) => getters.antireflective_sections.antireflective_price,
    product_config: state => state.product_config,
    enable_subscription: state => state.enable_subscription,
    productId: (state, getters, rootState, rootGetters) => rootGetters['values/checkoutData'].product,
    currentProductConfig: (state, getters) => getters.product_config[getters.productId],
    productOptionConfig: (state, getters) => {
        const productConfig = getters.currentProductConfig || {}
        return productConfig.config || productConfig
    },

    isSpectacles: (state, getters) => {
        const keys = _keys(getters.product_config)
        const firstKey = _first(keys) || []
        return firstKey.indexOf('spectacles') !== -1
    },
    isContactDetailsPage: (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState.key === 'contacts'
    },
    configuratorShow: state => state.configuratorShow
}

export const actions = {
    ...abstract.actions
}

export const mutations = {
    ...abstract.mutations,
    SET_CONFIGURATOR_SHOW: (state, show = true) => state.configuratorShow = show,
    SET_MOVING_TO_CHECKOUT: (state, movingToCheckout = true) => state.movingToCheckout = movingToCheckout,
    SET_ANIMATION_SHOW: (state, animationEnabled = true) => state.animationEnabled = animationEnabled,
    SET_PRODUCT_CONFIG: (state, payload) => state.product_config = payload
}

export default () => ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
