import axios from 'axios'
import * as abstract from '../abstract'

export const state = () => ({
    ...abstract.state(),
    form_key: '',
    back_url: '',
    base_url: window.location.origin + '/' || '',
    media_url: '',
    assets_url: '/js/lensconfigurator/assets/',
    store_code: '',
    fitmix_api_key: '',
    recurly_auth_api_key: '',
    billing_address: '',
    shipping_address: '',
    saved_billing_address: '',
    saved_shipping_address: '',
    recurly_shipping_address: '',
    recurly_billing_address: '',
    country_code: 'US',
    regions_data: [],
    regionsWithCode: [],
    dropdownLinks: [],
    customerIsLoggedIn: false,
    api: {
        urls: {
            getCountryRegionsUrl: '/contact-lenses/directory/getRegions',
            getCountryRegionsWithCodeUrl: 'opternative/index/getRegions'
        }
    }
})

export const getters = {
    form_key: (state, getters, rootState, rootGetters) => {
        if (!state.form_key) {
            const form_key = document.getElementsByName('form_key')
            state.form_key = rootGetters.rootState.form_key || (form_key.length && form_key[0] ? form_key[0].value : '')
        }
        return state.form_key
    },
    back_url: state => state.back_url,
    base_url: state => state.base_url,
    media_url: state => state.media_url,
    assets_url: state => state.assets_url,
    dropdownLinks: state => state.dropdownLinks,
    store_code: state => state.store_code,
    fitmixApiKey: state => state.fitmix_api_key,
    recurly_auth_api_key: state => state.recurly_auth_api_key,
    billing_address: state => state.billing_address,
    saved_billing_address: state => state.saved_billing_address,
    shipping_address: state => state.shipping_address,
    saved_shipping_address: state => state.saved_shipping_address,
    recurly_shipping_address: state => state.recurly_shipping_address,
    recurly_billing_address: state => state.recurly_billing_address,
    country_code: state => state.country_code,
    regions_data: state => state.regions_data,
    regionsWithCode: state => state.regionsWithCode
}

export const mutations = {
    ...abstract.mutations,
    SET_COUNTRY_CODE: (state, country_code) => state.country_code = country_code,
    SET_REGIONS_DATA: (state, regions_data) => state.regions_data = regions_data,
    SET_REGIONS_WITH_CODE_DATA: (state, regionsWithCode=[]) => state.regionsWithCode = regionsWithCode,
    SET_RECURLY_SHIPPING: (state, shipping) => state.recurly_shipping_address = shipping,
    SET_RECURLY_BILLING: (state, billing) => state.recurly_billing_address = billing
}

export const actions = {
    ...abstract.actions,
    fetchCountryRegions: ({state, commit}) => {
        return axios.get(state.api.urls.getCountryRegionsUrl).then(({data, status}) => {
            if (status === 200) {
                commit('SET_REGIONS_DATA', data)
            }
            return {data, status}
        })
    },
    fetchCountryRegionsWithCode: ({state, getters, commit}) => {
        const url = state.api.urls.getCountryRegionsWithCodeUrl + `?code=${getters.country_code}`
        return axios.get(url).then(({data, status}) => {
            if (status === 200) {
                commit('SET_REGIONS_WITH_CODE_DATA', data)
            }
            return {data, status}
        })
    }
}

export default () => ({
    namespaced: true,
    state: state(),
    getters,
    mutations,
    actions
})
