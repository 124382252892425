var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "category-frames-wrapper frames-wrapper" }, [
    _c("div", { staticClass: "category-frames-included lenses-section" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm.isShow("blue-light")
        ? _c("div", { staticClass: "lens-tab" }, [_vm._m(1)])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShow("ar")
        ? _c("div", { staticClass: "lens-tab" }, [_vm._m(2)])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShow("scratch")
        ? _c("div", { staticClass: "lens-tab" }, [_vm._m(3)])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShow("uv")
        ? _c("div", { staticClass: "lens-tab" }, [_vm._m(4)])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lens-tab" }, [
      _c("span", [_vm._v("lenses include:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon blue-light" }, [
      _c("span", [
        _c("span", { staticClass: "desktop" }, [_vm._v("Premium")]),
        _vm._v(" Blue-Light Protection")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon ar" }, [
      _c("span", [_vm._v("Anti-Reflective Coating")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon  scratch" }, [
      _c("span", [_vm._v("Anti-Scratch Coating")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon uv" }, [
      _c("span", [_vm._v("100% UV Protection")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }