var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plus-account_container plusdiscount-container" },
    [
      _vm.isMobile
        ? _c(
            "h4",
            { class: { active: _vm.isShow }, on: { click: _vm.toggleContent } },
            [_vm._v("Member Discount")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        !_vm.isMobile || (_vm.isShow && _vm.isMobile)
          ? _c("div", { staticClass: "plus-content_container" }, [
              _c("div", [
                _c("img", {
                  attrs: {
                    src: "/js/lensconfigurator/assets/save_money_desktop.svg",
                    alt: "contact lenses"
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "redeem-description" },
                [
                  _c("span", { staticClass: "description" }, [
                    _vm._v("Up to "),
                    _c("span", [_vm._v(_vm._s(_vm.lensPercent) + "%")]),
                    _vm._v(" discount on additional Lensabl purchases")
                  ]),
                  _vm._v(" "),
                  _c("HelpBubble", {
                    attrs: { data: _vm.getHelpComponent["discount"] }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "secondary" },
                [
                  _c("BaseButton", {
                    staticClass: "button button--copy-solid",
                    class: { disable: !_vm.previousvisit },
                    attrs: {
                      disabled: !_vm.paidUp,
                      text: _vm.btnText,
                      "data-size": "xs"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.continueToShop($event)
                      }
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }