<template>
    <div class="save-up">
        <div v-show="saveUpPrice" class="learn">
            <strong v-dompurify-html="`Save up to $${saveUpPrice.toFixed(2)}`"></strong> out-of-pocket when compared to other Vision Plans
            <span :class="{'active': descriptionShow}" class="learn-more" @click="toggleInfo">Learn More</span>
            <span v-if="descriptionShow" @click="toggleInfo"><svg id="Layer_1" enable-background="new 0 0 100 100" version="1.1" viewBox="0 0 100 100" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><polygon fill="#010101" points="77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2   51.5,51.1 79.6,23 " /></svg></span>
        </div>
        <div v-show="descriptionShow" class="description">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Other Vision Plans</th>
                        <th class="lens-plus">Lensabl+</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th v-text="annualPremiumPrice > 0 ? 'Annual Premium' : ''"></th>
                        <td v-text="annualPremiumPrice > 0 ? `$${formatPrice(parseFloat(annualPremiumPrice).toFixed(2))}` : ''"></td>
                        <td class="lens-plus">included</td>
                    </tr>
                    <tr v-if="examPrice > 0">
                        <th>Exam</th>
                        <td v-text="`$${formatPrice(parseFloat(examPrice).toFixed(2))}`"></td>
                        <td class="lens-plus">included</td>
                    </tr>
                    <tr v-if="frameLensPrice > 1">
                        <th>Frame/Lens</th>
                        <td v-text="`$${formatPrice(parseFloat(frameLensPrice).toFixed(2))}`"></td>
                        <td class="lens-plus">included</td>
                    </tr>
                    <tr v-if="contactsPrice > 0">
                        <th>Contacts</th>
                        <td v-text="`$${formatPrice(parseFloat(contactsPrice).toFixed(2))}`"></td>
                        <td class="lens-plus">included</td>
                    </tr>
                    <tr v-if="allowancePriceShow && (Math.abs(allowancePrice) > 0)">
                        <th>Allowance</th>
                        <td v-text="`($${Math.abs(allowancePrice)})`"></td>
                        <td class="lens-plus">included</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total Out-of-Pocket Cost</th>
                        <td v-text="`$${formatPrice(parseFloat(totalOutOfPrice).toFixed(2))}`"></td>
                        <td class="total-price" v-text="`$${formatPrice(parseFloat(totalPrice).toFixed(2))}`"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import _map from 'lodash/map'
import _filter from 'lodash/filter'

export default {
    name: 'SaveUp',
    data: () => ({
        descriptionShow: false
    }),
    computed: {
        ...mapGetters('lensablPlus', ['currentPlan', 'computePriceForContacts', 'isProgressiveLenses', 'checkoutData']),
        saveUpPrice () {
            return Math.abs(this.totalOutOfPrice - this.totalPrice)
        },
        totalOutOfPrice () {
            return this.annualPremiumPrice + this.examPrice + this.frameLensPrice + this.contactsPrice
        },
        totalPrice () {
            return this.checkoutData.totalPrice
        },
        annualPremiumPrice () {
            return parseFloat(this.pricingInfo['annual'] || 0)
        },
        framesPrice () {
            let price = parseFloat(this.pricingInfo['new_frames'])
            if (!this.includeContacts) {
                price += this.allowancePrice
            }
            return price
        },
        lensPrice () {
            let price = 0
            _map(this.pricingInfo['lenses'] || {}, (value, index) => {
                price += parseFloat(value)
                if (this.includeContacts && this.isProgressiveLenses && (index === 'protection')) {
                    price -= parseFloat(value)
                }
            })
            return price
        },
        frameLensPrice () {
            return this.framesPrice + this.lensPrice
        },
        examPrice () {
            return parseFloat(this.pricingInfo['exam'] || 0)
        },

        contactsPrice () {
            let price = 0
            if (this.includeContacts) {
                price = this.computePriceForContacts * 1.2 + parseFloat(this.allowancePrice)
            }
            return price
        },
        allowancePrice () {
            return parseFloat(this.pricingInfo['allowance'] || 0)
        },
        pricingInfo () {
            return this.currentPlan['pricing_information'] || {}
        },
        includeContacts () {
            return this.computePriceForContacts > 0
        },
        allowancePriceShow () {
            return _filter(this.checkoutData.data, eye => Boolean(eye.id)).length
        }
    },
    methods: {
        toggleInfo () {
            this.descriptionShow = !this.descriptionShow
        },
        formatPrice (price) {
            return price > 999 ? price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : price
        }
    }
}
</script>
