var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.memberShipEdited
    ? _c("table", { staticClass: "form-list", attrs: { cellspacing: "0" } }, [
        _c("tbody", [
          _c("tr", [
            _vm._m(0),
            _vm._v(" "),
            _c("td", { staticClass: "value" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customerData.group_id,
                      expression: "customerData.group_id"
                    }
                  ],
                  staticClass: "required-entry select",
                  attrs: {
                    id: "group_id",
                    disabled: _vm.customer.customerExists
                  },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.customerData,
                        "group_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _vm._l(_vm.groupsData, function(option) {
                    return [
                      _c("option", {
                        directives: [
                          {
                            name: "dompurify-html",
                            rawName: "v-dompurify-html",
                            value: option.label,
                            expression: "option.label"
                          }
                        ],
                        key: option.value,
                        domProps: { value: option.value }
                      })
                    ]
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "value" },
              [
                _c("v-text-field", {
                  staticClass: "validate-email input-text default",
                  class: { "validation-failed": _vm.validateField("email") },
                  attrs: { id: "email", rules: _vm.emailRules },
                  model: {
                    value: _vm.customerData.email,
                    callback: function($$v) {
                      _vm.$set(_vm.customerData, "email", $$v)
                    },
                    expression: "customerData.email"
                  }
                }),
                _vm._v(" "),
                _vm.validateField("email")
                  ? _c(
                      "div",
                      {
                        staticClass: "validation-advice",
                        attrs: { id: "advice-required-entry-" + "email" }
                      },
                      [_vm._v("This is a required field.")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.customer.isPlusCustomer
                  ? _c("div", { staticClass: "error-text-rx" }, [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.isPlusCustomerErrorText)
                        }
                      })
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "label" }, [
      _c("label", { attrs: { for: "group_id" } }, [_vm._v("Group")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "label" }, [
      _c("label", { attrs: { for: "email" } }, [_vm._v("Email")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }