<!--suppress JSAnnotator -->
<template>
    <div v-if="media && media.length" class="gallery-placeholder">
        <div class="gallery--wrap">
            <div class="gallery-stage">
                <template v-for="(img, index) in media">
                    <template v-if="!galleryThumbsShow">
                        <div
                            :key="img.inline+index"
                            :class="{'current': currentImageIndex === index, 'current-prev': prevImageIndex === index, 'current-next': nextImageIndex === index}"
                            class="gallery-stage-item jjjjj"
                        >
                            <figure>
                                <span v-if="productView.frames_name === 'Spectacles Avalon' || productView.frames_name === 'Spectacles Matador'" class="specgen-banner-text">{{ productView.frames_name }}</span>
                                <img v-else :src="getImageUrl(img)" :alt="image_title" />
                            </figure>
                            <span v-if="productView.frames_name === 'Spectacles Avalon' || productView.frames_name === 'Spectacles Matador'" class="shopnshop-time-text">High index lenses recommended for these prescriptions inserts</span>
                        </div>
                    </template>
                    <template v-else-if="productView.frames_name === 'lensabl frames' && img.label === productView.lensabl_category">
                        <div
                            :key="img.inline+index"
                            v-touch:swipe.left="nextMainImage"
                            v-touch:swipe.right="prevMainImage"
                            :class="{'current': currentImageIndex === index, 'current-prev': prevImageIndex === index, 'current-next': nextImageIndex === index}"
                            :data-slide-effect="isMobile? 'slide' : 'fade'"
                            class="gallery-stage-item"
                            @click="popUpShow()"
                        >
                            <figure>
                                <img :src="getImageUrl(img)" :alt="image_title" />
                            </figure>
                        </div>
                    </template>
                    <template v-else-if="productView.frames_name !== 'lensabl frames'">
                        <div
                            :key="img.inline+index"
                            v-touch:swipe.left="nextMainImage"
                            v-touch:swipe.right="prevMainImage"
                            :class="{'current': currentImageIndex === index, 'current-prev': prevImageIndex === index, 'current-next': nextImageIndex === index}"
                            :data-slide-effect="isMobile && productView.frames_name !== 'kiaura' ? 'slide' : 'fade'"
                            class="gallery-stage-item"
                            @click="popUpShow()"
                        >
                            <figure>
                                <img :src="getImageUrl(img)" :alt="image_title" />
                            </figure>
                        </div>
                    </template>
                </template>
            </div>
            <div v-if="galleryThumbsShow" class="gallery-thumbs-wrapper">
                <div class="gallery-thumbs">
                    <ul class="gallery-thumbs-list" role="tablist">
                        <template v-for="(img, index) in media">
                            <template v-if="productView.frames_name === 'lensabl frames' && img.label === productView.lensabl_category">
                                <li
                                    :key="img.inline+index"
                                    :class="{
                                        'gallery-thumbs-item': !isMobile,
                                        'gallery-thumbs-dot': isMobile,
                                        'current': currentImageIndex === index,
                                        'current-prev': prevImageIndex === index,
                                        'current-next': nextImageIndex === index
                                    }"
                                    role="presentation"
                                    @click.stop.prevent="changeMainImage(index)"
                                >
                                    <a v-if="!isMobile" :tabindex="index" :href="img.inline" target="_image" role="tab">
                                        <img :src="img.inline" :alt="image_title" />
                                    </a>
                                    <a v-else :tabindex="index" :href="img.inline" role="tab">
                                        {{ index }}
                                    </a>
                                </li>
                            </template>
                            <template v-if="productView.frames_name !== 'lensabl frames'">
                                <li
                                    :key="img.inline+index"
                                    :class="{
                                        'gallery-thumbs-item': !isMobile,
                                        'gallery-thumbs-dot': isMobile,
                                        'current': currentImageIndex === index,
                                        'current-prev': prevImageIndex === index,
                                        'current-next': nextImageIndex === index
                                    }"
                                    role="presentation"
                                    @click.stop.prevent="changeMainImage(index)"
                                >
                                    <a v-if="!isMobile" :tabindex="index" :href="img.inline" target="_image" role="tab">
                                        <img :src="img.inline" :alt="image_title" />
                                    </a>
                                    <a v-else :tabindex="index" :href="img.inline" role="tab">
                                        {{ index }}
                                    </a>
                                </li>
                            </template>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="isPopUpShow" class="gallery--fullscreen">
            <div class="gallery-stage">
                <template v-for="(img, index) in media">
                    <div
                        :key="img.inline+index"
                        v-touch:swipe.left="nextMainImage"
                        v-touch:swipe.right="prevMainImage"
                        :class="{'current': currentImageIndex === index, 'current-prev': prevImageIndex === index, 'current-next': nextImageIndex === index}"
                        class="gallery-stage-item"
                        data-slide-effect="slide"
                    >
                        <figure>
                            <img :src="img.gallery" :alt="image_title" />
                        </figure>
                    </div>
                </template>
            </div>
            <div class="gallery-thumbs-wrapper">
                <div class="gallery-thumbs">
                    <ul class="gallery-thumbs-list" role="tablist">
                        <template v-for="(img, index) in media">
                            <li
                                :key="img.inline+index"
                                :class="{'current': currentImageIndex === index, 'current-prev': prevImageIndex === index, 'current-next': nextImageIndex === index}"
                                class="gallery-thumbs-dot"
                                @click.stop.prevent="changeMainImage(index)"
                            >
                                <a :tabindex="index" :href="img.inline" role="tab">
                                    {{ index }}
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="gallery-actions">
                <a href="javascript:void(0)" role="button" aria-label="Close" @click="popUpClose">
                    <span aria-hidden="true" class="gallery--fullscreen-close">&times;</span>
                </a>
                <a href="javascript:void(0)" role="button" class="gallery-actions--prev" aria-label="Prev" @click="prevMainImage">
                    <span aria-hidden="true">&#8249;</span>
                </a>
                <a href="javascript:void(0)" role="button" class="gallery-actions--next" aria-label="Next" @click="nextMainImage">
                    <span aria-hidden="true">&#8250;</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import {mapGetters, mapMutations} from 'vuex'

Vue.use(Vue2TouchEvents)

export default {
    name: 'Gallery',
    props: {
        galleryThumbsShow: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        isPopUpShow: false
    }),
    computed: {
        ...mapGetters('productView', ['productView', 'image_title', 'mainImageUrl', 'getImageUrl', 'media']),
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('values', ['values']),
        currentImageIndex () {
            return this.productView.currentImageIndex
        },
        prevImageIndex () {
            let index = this.currentImageIndex - 1
            const countImages = this.media.length
            if (index < 0) {
                index = countImages - 1
            }
            return index
        },
        nextImageIndex () {
            let index = this.currentImageIndex + 1
            const countImages = this.media.length
            if (index >= countImages) {
                index = 0
            }
            return index
        }
    },
    mounted () {
        this.getEEImageIndex()
    },
    methods: {
        ...mapMutations('productView', ['setCurrentImageIndex']),
        changeMainImage (index) {
            this.setCurrentImageIndex(index)
        },
        popUpShow () {
            this.isPopUpShow = true
        },
        popUpClose () {
            this.isPopUpShow = false
        },
        prevMainImage () {
            this.changeMainImage(this.prevImageIndex)
        },
        nextMainImage () {
            this.changeMainImage(this.nextImageIndex)
        },
        getEEImageIndex () {
            if (this.productView.frames_name === 'lensabl frames') {
                this.media.every((image, index) => {
                    if (image.label === this.productView.lensabl_category) {
                        this.setCurrentImageIndex(index)
                        return false
                    } else {
                        return true
                    }
                })
            }
        }
    }
}
</script>

<style lang="less">
    .specgen-banner-text {
        font-size: 1.5rem;
        font-weight: bold;
        font-size: 2rem;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 375px) {
            font-size: 2rem;
        }
        @media screen and (min-width: 640px) {
            font-size: 3rem;
            height: 280px;
        }
        @media screen and (min-width: 768px) {
            font-size: 3rem;
            height: 300px;
        }
        @media screen and (min-width: 1024px) {
            font-size: 3.5rem;
            height: 400px;
        }
    }
</style>