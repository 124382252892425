<template>
    <div class="prescription-table-wrapper">
        <div>
            <div class="different-eye_container">
                <span class="rx-q">Are you prescribed the same contacts brand for both eyes?</span>
                <label>
                    <input v-model="different" :value="false" name="type" type="radio" />
                    <span>Yes</span>
                </label>
                <label>
                    <input v-model="different" :value="true" name="type" type="radio" />
                    <span>No</span>
                </label>
            </div>
            <div :class="{different: different}" class="select-brand_wrapper">
                <span>Search for your contacts brand</span>
                <div v-if="different" class="select-brand_container">
                    <Search id="right_eye" :search_in="PRODUCTS" :holder="'Right Eye'" :options_rx.sync="options_rx" :eyes="['right_eye']" />
                    <Search id="left_eye" :search_in="PRODUCTS" :holder="'Left Eye'" :options_rx.sync="options_rx" :eyes="['left_eye']" />
                    <div v-if="validate && !isChosenProduct" class="error-text-rx">
                        <span>Right and Left contacts must be selected.</span>
                    </div>
                </div>
                <div v-else class="select-brand_container">
                    <Search id="both" :search_in="PRODUCTS" :holder="''" :options_rx.sync="options_rx" :eyes="['left_eye', 'right_eye']" />
                    <div v-if="validate && !isChosenProduct" class="error-text-rx">
                        <span>Contacts must be selected.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="rx-table_wrappper">
            <div v-for="(eye, index) in prescriptions" :key="index" :class="index">
                <div class="edit-section">
                    <h4>{{ getTitle(index) }}</h4>
                    <span @click="removeProduct(index)"></span>
                </div>
                <div>
                    <div class="contacts_rx-values">
                        <div v-for="(value, key) in eye" :key="`contacts_rx-values${key}`">
                            <div v-if="key !== 'product_key'">
                                <div v-if="text_keys.indexOf(key) !== -1 && choosing_value[index][key].length === 2 ">
                                    <div class="contacts_rx_set">
                                        <span>{{ getTitle(key) }}</span>
                                        <span>{{ value }}</span>
                                    </div>
                                </div>
                                <div v-else-if="choosing_value[index][key].length > 1 || show[index]" class="saved-rx-field">
                                    <CustomSelect
                                        :id="key + index"
                                        :value="value"
                                        :options="choosing_value[index][key]"
                                        @change="setPrescriptionValue($event.target.value, index, key)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="validate && isChosenProduct && validateOnlinePrescriptionData[index]" class="error-text-rx">
                            <span>You didn’t enter all of the values neccesary for {{ getTitle(index) }}.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-action bottom">
            <BaseButton :text="'Back'" @click.native="closePrescription" />
            <BaseButton :text="'Continue'" :disabled="!continueEnable" class="next eyeglass-button" @click.native="addPrescription" />
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import _clone from 'lodash/clone'
import _filter from 'lodash/filter'
import getHelper from '../../../configurator/storage/constants/helper'
import CommonPrescriptionModel from '../../../../app/common/prescription_model'
import CustomSelect from '../../../base/Select.vue'
import BaseButton from '../../../base/BaseButton.vue'
import Search from './Search.vue'

const DEFAULT_VALUE = {
    _object: {
        sphere: null,
        base_curve: null,
        diameter: null,
        cylinder: null,
        axis: null,
        add: null
    }
}

const RESET_VALUE = {
    _object: {
        sphere: [
            {value: '', label: 'Power/Sphere'}
        ],
        cylinder: [
            {value: '', label: 'CYL'}
        ],
        axis: [
            {value: '', label: 'Axis'}
        ],
        add: [
            {value: '', label: 'ADD'}
        ],
        base_curve: [
            {value: '', label: 'BC'}
        ],
        diameter: [
            {value: '', label: 'DIA'}
        ]
    }
}

export default {
    components: {
        CustomSelect,
        BaseButton,
        Search
    },
    extends: CommonPrescriptionModel,
    props: {
        nextStep: {
            type: Boolean,
            default: false
        },
        completeStep: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            validate: false,
            text_keys: ['base_curve', 'diameter'],
            show: {
                right_eye: true,
                left_eye: true
            },
            prescriptions: {
                right_eye: Object.assign({}, DEFAULT_VALUE._object),
                left_eye: Object.assign({}, DEFAULT_VALUE._object)
            },
            different: false,
            options_rx: {
                right_eye: {},
                left_eye: {}
            },
            choosing_value: {
                right_eye: Object.assign({}, RESET_VALUE._object),
                left_eye: Object.assign({}, RESET_VALUE._object)
            }
        }
    },
    computed: {
        ...mapGetters('onlineExam', ['SIGN_IN', 'TYPE', 'PRODUCTS']),
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        validateOnlinePrescriptionData () {
            return this.validatePrescriptionData(this.prescriptions, this.prescriptionData.prescription_type)
        },
        isChosenProduct () {
            const eyes = _filter(this.options_rx, eye => {
                if (eye && eye.hasOwnProperty('product_key')) {
                    return eye.product_key
                }
            }) || {}
            return Object.keys(this.options_rx).length === Object.keys(eyes).length
        },
        continueEnable () {
            return !this.validate || (this.isChosenProduct && !this.validateOnlinePrescriptionData['left_eye'] && !this.validateOnlinePrescriptionData['right_eye'])
        },
        help () {
            const {help_components} = getHelper(this.$store.getters)
            return help_components
        }
    },
    watch: {
        options_rx: 'addOptions',
        different: 'resetOptions'
    },
    mounted () {
        this.$store.commit('onlineExam/SET_LOADING', false)
        this.$store.dispatch('onlineExam/GET_PRODUCTS')
    },
    methods: {
        ...mapActions('onlineExam', ['GET_PRODUCTS']),
        validatePrescriptionData (prescription, prescription_type) {
            const show = {
                right_eye: false,
                left_eye: false
            }
            const prescriptionType = _clone(prescription_type)

            if (!prescription || !prescriptionType || !this.validate) {
                return show
            }

            // Right Eye
            let rightSelected = true
            for (const key in prescription.right_eye) {
                rightSelected = rightSelected && (prescription.right_eye[key] !== null) && (prescription.right_eye[key] !== '')
            }
            show['right_eye'] = !rightSelected

            // Left Eye
            let leftSelected = true
            for (const key in prescription.left_eye) {
                leftSelected = leftSelected && (prescription.left_eye[key] !== null) && (prescription.left_eye[key] !== '')
            }
            show['left_eye'] = !leftSelected

            return show
        },
        resetOptions () {
            Object.keys(this.choosing_value).forEach((index) => {
                this.choosing_value[index] = Object.assign({}, RESET_VALUE._object)
                this.prescriptions[index] = Object.assign({}, DEFAULT_VALUE._object)
            })
            this.show = {
                right_eye: true,
                left_eye: true
            }
        },
        addOptions (options) {
            Object.keys(options || {}).forEach(key => {
                this.options_rx[key] = options[key] ? options[key] : []
                const optionsByEye = options[key] ? options[key].options : []
                this.prescriptions[key]['product_key'] = this.options_rx[key].product_key
                Object.keys(optionsByEye || {}).forEach((index) => {
                    const optionsValues = this.getOptions(optionsByEye[index], index)
                    if (this.text_keys.indexOf(index) !== -1 && optionsValues.length === 2) {
                        this.prescriptions[key][index] = optionsValues[1].value
                    }
                    this.choosing_value[key][index] = optionsValues
                })
                this.show[key] = false
            })
        },
        addPrescription () {
            const prescriptions = {}
            prescriptions['type'] = this.TYPE
            prescriptions['verification'] = 'unverified'
            let i = 0
            for (const [key, value] of Object.entries(this.prescriptions)) {
                if (value['sphere'] || value['cylinder'] || value['axis'] || value['diameter']) {
                    prescriptions[key] = value
                    i++
                } else {
                    prescriptions[key] = null
                }
            }
            if (i > 0) {
                this.$store.commit('onlineExam/SET_PRESCRIPTIONS', [prescriptions])
            }
            this.validate = true

            if (this.continueEnable) {
                this.$store.commit('onlineExam/SET_LOADING', true)
                this.$emit('update:nextStep', true)
                this.$emit('update:completeStep', true)
            }
        },
        closePrescription () {
            this.$store.commit('onlineExam/SET_PRESCRIPTIONS', [])
            this.$emit('update:nextStep', true)
            this.$store.commit('onlineExam/SET_SHOW_NAV', true)
        },
        getOptions (array, key) {
            const positiveArray = array.filter(val => val >= 0)
            const negativeArray = array.filter(val => val < 0)
            const sortNegative = negativeArray.sort((a, b) => a - b)
            const sortPositive = positiveArray.sort((a, b) => a - b)
            const options = sortNegative.concat(sortPositive)
            const returnArray = [...RESET_VALUE._object[key]]
            options.forEach(option => {
                if (option !== 0) {
                    returnArray.push({value: option, label: option})
                }
            })
            return returnArray
        },
        getTitle (title) {
            const suffix = title === 'right_eye' ? ' (OD)' : title === 'left_eye' ? ' (OS)' : ''
            title = title.replace('_', ' ')
            if (title === 'diameter') {
                return 'DIA'
            } else if (title === 'base curve') {
                return 'BC'
            } else {
                return title.charAt(0).toUpperCase() + title.slice(1) + ` ${suffix}`
            }
        }
    }
}
</script>
