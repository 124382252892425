var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.totalPrice
    ? _c("div", { staticClass: "price-info" }, [
        _c("div", { staticClass: "price" }, [
          _c("div", { staticClass: "total-value value" }, [
            _c("span", { staticClass: "total-value-currency" }, [
              _vm._v(_vm._s(_vm.currencySymbol))
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "total-value-amount-dollars",
              domProps: { textContent: _vm._s(_vm.formatPrice(_vm.dollars)) }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "total-value-amount-cents",
              domProps: { textContent: _vm._s("." + _vm.cents) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [
            _c("strong", [_vm._v("/per " + _vm._s(_vm.label))])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }