<template>
    <ul class="action-list">
        <li>
            <EnterSh
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                @checked="$emit('checked', $event)"
            />
        </li>
        <li>
            <Upload
                :item="item"
                :prescriptions-type="prescriptionsType"
                :prescription-index="prescriptionIndex"
                :checked="checked"
                option-name="uploaded_sh"
                class="upload-image-online"
                @checked="$emit('checked', $event)"
            />
        </li>
    </ul>
</template>

<script>
import EnterSh from './render/EnterSh.vue'
import Upload from './render/Upload.vue'

export default {
    name: 'SalesOrderViewPrescriptionShOptions',
    components: {
        EnterSh,
        Upload
    },
    props: {
        item: {
            type: [Array, Object],
            default: () => {}
        },
        checked: {
            type: [Boolean, String],
            default: ''
        },
        prescriptionsType: {
            type: String,
            default: ''
        },
        prescriptionIndex: {
            type: Number,
            default: 0
        }
    }
}
</script>
