import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const eventBus = new Vue()

export const RootState = new Vuex.Store({
    state: {
        registry: {}
    },
    getters: {
        registry: state => state.registry,
        getRegistry: (state, getters) => index => getters.registry[index]
    },
    mutations: {
        setRegistry: (state, {index, data}) => index ? state.registry[index] = data : false
    },
    actions: {

    }
})
