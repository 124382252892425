var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "entry-edit" }, [
    _c("div", { staticClass: "entry-edit-head" }, [
      _c("h4", { staticClass: "fieldset-legend", class: _vm.headerCssClass }, [
        _vm._v(_vm._s(_vm.headerText))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "fieldset" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }