<template>
    <div class="dropdown-component">
        <div class="dropdown-container">
            <div class="dropdown-container-main">
                <div class="header">
                    <h4 :class="{active: resultsVisible}" class="dropdown-title" @click="toggleSelected">
                        {{ title }}
                    </h4>
                </div>
                <transition name="slide">
                    <div v-if="resultsVisible" class="results-dropdown" :class="{active: resultsVisible}">
                        <ul id="results">
                            <li v-for="(info, index) in msg" :key="index">
                                <span class="title">{{ index }}</span>
                                <div class="contacts-info">
                                    <img :src="info.image" :alt="info.title" />
                                    <span v-dompurify-html="info.title" class="subtitle"></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'Dropdown',
    props: {
        title: {
            type: String,
            default: '',
            required: true
        },
        msg: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    data: () => {
        return {
            resultsVisible: true
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet'])
    },
    mounted () {
        if (!this.isMobile) {
            this.resultsVisible = true
        }
    },
    methods: {
        toggleSelected () {
            this.resultsVisible = !this.resultsVisible
        }
    }
}
</script>

<style scoped>

</style>
