<template>
    <div>
        <CustomerPrescription v-if="statusApi" />
    </div>
</template>

<script>
import App from '../../../App.vue'
import CustomerPrescription from '../../../components/customer/prescription/RX.vue'

export default {
    name: 'RouterCustomerPrescription',
    components: {
        CustomerPrescription
    },
    extends: App
}
</script>

<style scoped>

</style>
