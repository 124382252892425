<template>
    <div class="my-prescription-form">
        <div class="my-prescription-form__name-wrap">
            <div v-if="showEditVersion" class="my-prescription-form__name-input-container">
                <input
                    id="prescription-form__name"
                    v-model="prescriptionData.name"
                    class="my-prescription-form__name outline"
                    placeholder="Enter Prescription Name here..."
                    required="required"
                />
            </div>
            <div v-else class="my-prescription-form__name-input-container">
                <span v-dompurify-html="prescriptionData.name" class="my-prescription-form__name outline"></span>
            </div>
            <label v-if="showEditVersion" for="prescription-form__name" class="edit-name">edit name</label>
        </div>

        <div class="my-prescription-form-options">
            <div class="my-prescription-form__lens-options-container my-prescription-form__layout-xlrg-left">
                <div v-if="showEditVersion" class="my-prescription-form__lens-type">
                    <p class="my-prescription-form__subheading">Lenses:</p>
                    <ul>
                        <li>
                            <RadioLabel :active="prescriptionData.lens === 'prescription'" :label="`Prescription Lenses`" @click="prescriptionData.lens = 'prescription'" />
                        </li>
                        <li>
                            <RadioLabel :active="prescriptionData.lens === 'reading'" :label="`Reading Lenses`" @click="prescriptionData.lens = 'reading'" />
                        </li>
                    </ul>
                </div>
                <div v-if="prescriptionData.lens === 'prescription' && showEditVersion" class="my-prescription-form__prescription-type prescription-toggle">
                    <p class="my-prescription-form__subheading">Choose Prescription Type:</p>
                    <ul>
                        <li>
                            <RadioLabel
                                :active="prescriptionData.prescription_type === 'single_vision_distance'"
                                :label="`Single Vision Distance`"
                                @click="prescriptionData.prescription_type = 'single_vision_distance'"
                            />
                        </li>
                        <li>
                            <RadioLabel
                                :active="prescriptionData.prescription_type === 'single_vision_reading'"
                                :label="`Single Vision Near`"
                                @click="prescriptionData.prescription_type = 'single_vision_reading'"
                            />
                        </li>
                        <li>
                            <RadioLabel
                                :active="prescriptionData.prescription_type === 'progressive'"
                                :label="`Progressive`"
                                @click="prescriptionData.prescription_type = 'progressive'"
                            />
                        </li>
                        <li>
                            <RadioLabel
                                :active="prescriptionData.prescription_type === 'bi_focal'"
                                :label="`Bi Focal`"
                                @click="prescriptionData.prescription_type = 'bi_focal'"
                            />
                        </li>
                    </ul>
                </div>
                <div v-if="prescriptionData.lens === 'prescription' && !showEditVersion" class="my-prescription-form__prescription-type prescription-toggle">
                    <p class="my-prescription-form__subheading">Prescription Type:</p>
                    <span v-dompurify-html="prescriptionType" class="my-prescription-form__name outline"></span>
                </div>
            </div>
            <div :class="{'not-edit-form': !showEditVersion}" class="my-prescription-form__dropdown-options-wrap my-prescription-form__layout-xlrg-right">
                <div v-if="prescriptionData.lens === 'reading'" class="my-prescription-form__dropdown-options-container reading-toggle">
                    <p class="my-prescription-form__subheading reading-toggle">Select Lens Power:</p>

                    <div class="my-prescription-form__dropdown-row my-prescription-form__dropdown-row--reading">
                        <div class="my-prescription-form__reading-eye">Lens Magnification</div>
                        <div class="my-prescription-form__dropdown my-prescription-form__dropdown--reading">
                            <CustomSelect
                                :value="prescriptionData.value.od = prescriptionData.value.os"
                                :options="eyePowerReadingOptions"
                                @change="prescriptionData.value.od = prescriptionData.value.os = $event.target.value"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="prescriptionData.lens === 'prescription'" class="my-prescription-form__dropdown-options-container prescription-toggle">
                    <p class="my-prescription-form__subheading prescription-toggle">Prescription:</p>

                    <PrescriptionTable :show-edit-version="showEditVersion" :show-add="showAdd" :value="prescriptionData.value" :help="{}" />
                </div>
                <div v-if="prescriptionData.source_pdf" class="my-prescription-form__pupillary-distance">
                    <p>Please contact <a href="mailto:support@lensabl.com?subject=Eye%20Exam%20Prescription%20Request" title="Email Customer Support for your Prescription"> customer support</a> to receive a copy of your prescription.</p>
                </div>
            </div>
            <div class="my-prescription-form__pd-prism__container">
                <div v-if="prescriptionData.lens === 'prescription'" class="my-prescription-form__pupillary-distance">
                    <CustomerPrescriptionPupillaryDistance :show-edit-version="showEditVersion" :show-add="showAdd" :value="prescriptionData.value" :help="{}" />
                </div>
                <div class="my-prescription-form__prism">
                    <div v-if="prescriptionData.value.prism" class="my-prescription-form__dropdown-pupillary">
                        <div class="two-pupillary-numbers">
                            <div class="my-prescription-form__dropdown-label pupillary">
                                Prism
                            </div>
                        </div>
                    </div>
                    <div v-else class="my-prescription-form__checkbox-pupillary my-prescription-form__checkbox-prism">
                        If prescription has a prism correction, please <a href="mailto:support@lensabl.com">contact us</a> to update.
                    </div>
                </div>
            </div>
        </div>

        <div class="my-prescription-form__bottom">
            <div v-if="showError" class="error-text-rx">
                <span v-text="errorText"></span>
            </div>
            <div class="actions-toolbar">
                <div v-if="prescriptionId && showEditVersion" class="primary">
                    <BaseButton :disabled="disabled" text="Delete" class="button--copy-solid-white button--copy-border-sm delete" data-size="xs" @click.native="deleteConfirmation = true" />
                </div>

                <div class="secondary">
                    <BaseButton v-if="showEditVersion" :loader="saving" :disabled="disabled" text="Save" class="button--copy-solid-hover-dark" @click.native="saveForm" />
                </div>

                <div v-if="canAddToOrder" class="secondary">
                    <BaseButton :loader="ordering" :disabled="disabled" text="Order" class="button--copy-solid-opposite button--copy-solid-hover-dark" @click.native="orderGlasses" />
                </div>
            </div>
        </div>

        <div v-if="deleteConfirmation" class="my-prescription-delete">
            <div class="my-prescription-delete__inner">
                <p>Are you sure you want to delete this prescription?</p>
                <div class="actions-toolbar">
                    <div class="secondary">
                        <BaseButton :loader="deleting" :disabled="disabled" data-size="xs" text="Yes, Delete" class="button--copy-solid-white button--copy-border-sm delete" @click.native="deletePrescription" />
                    </div>
                    <div class="secondary">
                        <BaseButton :disabled="disabled" data-size="xs" text="No, Keep" class="button--copy-solid-white button--copy-border-sm keep" @click.native="deleteConfirmation = false" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import prescriptions from '../../prescriptions/storage/prescriptions'
import prescriptionsCurrent from '../../prescriptions/storage/prescriptions/current'

import CommonPrescriptionModel from '../../../app/common/prescription_model'
import PrescriptionTable from '../../../components/prescription/PrescriptionTable.vue'
import CustomerPrescriptionPupillaryDistance from './CustomerPrescriptionPupillaryDistance.vue'
import CustomSelect from '../../../components/base/Select.vue'
import BaseButton from '../../../components/base/BaseButton.vue'
import RadioLabel from '../../../components/base/RadioLabel.vue'

export default {
    name: 'CustomerPrescriptionEdit',
    components: {
        CustomSelect,
        BaseButton,
        RadioLabel,
        PrescriptionTable,
        CustomerPrescriptionPupillaryDistance
    },
    extends: CommonPrescriptionModel,
    data: () => ({
        saving: false,
        ordering: false,
        deleting: false,
        deleteConfirmation: false
    }),
    computed: {
        ...mapGetters('values', ['values']),
        ...mapGetters('prescriptions', ['prescriptionService']),
        ...mapGetters('prescriptions/current', ['prescriptionId', 'prescriptionData', 'order_url', 'status_4', 'canAddToOrder']),
        ...mapGetters('storeView', ['base_url', 'back_url']),
        disabled () {
            return this.saving || this.ordering || this.deleting
        },
        showError () {
            return this.validatePrescriptionData(this.prescriptionData.value, this.prescriptionData.prescription_type)
        },
        showEditVersion () {
            return this.isPrescriptionEditing(this.prescriptionData, this.status_4)
        },
        showAdd () {
            return this.showAddOptions(this.prescriptionData.prescription_type)
        },
        prescriptionType () {
            return this.prescriptionData.prescription_type ? this.prescriptionData.prescription_type.split('_').join(' ') : null
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['prescriptions/current/']) {
            this.$store.registerModule(['prescriptions', 'current'], prescriptionsCurrent(this.$store))
        }
    },
    methods: {
        saveForm () {
            const data = this.cleanDataForSaving()
            const promise = this.prescriptionId ? this.prescriptionService.update(this.prescriptionId, data) : this.prescriptionService.create(data)
            this.saving = true

            promise
                .then(() => {
                    this.saving = false
                    document.location = this.back_url
                })
                .catch((error) => {
                    this.saving = false
                    console.error(error)
                })
        },
        cleanDataForSaving () {
            const values = this.prescriptionData.value
            Object.entries(values).map(([key, value]) => {
                values[key] = value === 0 && typeof value !== 'boolean' ? '' : typeof value === 'string' ? value.trim() : value

                if ((key === 'os_pd' || key === 'od_pd') && value.length) {
                    if (isNaN(Number(value))) {
                        values['os_pd'] = ''
                        values['od_pd'] = ''
                    }
                    values['separate_pd'] = true
                    values['pd'] = ''
                }

                if (key === 'pd' && value.length) {
                    if (isNaN(Number(value))) {
                        values['pd'] = ''
                    }
                    values['separate_pd'] = false
                    values['os_pd'] = ''
                    values['od_pd'] = ''
                }
            })
            this.$set(this.prescriptionData, 'value', values)

            return this.prescriptionData
        },
        orderGlasses () {
            const promise = this.prescriptionId ? this.prescriptionService.update(this.prescriptionId, this.prescriptionData) : this.prescriptionService.create(this.prescriptionData)
            this.ordering = true

            promise.then((data) => {
                document.location = this.order_url.replace('{{id}}', data.id)
            }).catch((error) => {
                this.ordering = false
                console.error(error)
            })
        },
        deletePrescription () {
            if (!this.prescriptionId) {
                return false
            }
            this.deleting = true

            this.prescriptionService.remove(this.prescriptionId)
                .then(() => {
                    document.location = this.back_url
                })
                .catch((error) => {
                    this.deleting = false
                    console.error(error)
                })
        }
    }
}
</script>
