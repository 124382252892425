<template>
    <div>
        <div v-if="isPPEProduct && productView.productInfoShow" class="lensablFrames-breadcrumbs frames-breadcrumbs vue-breadcrumbs">
            <div class="breadcrumbs">
                <ul>
                    <li class="lensabl-frames">
                        <a href="/shop-frames/" title="Go to Lensabl Shop">Lensabl Shop</a>
                        <span> &gt; </span>
                    </li>
                    <li class="lensabl-category">
                        <a href="/shop-frames/?category=everyday-eyewear" title="Everyday Eyewear">Everyday Eyewear</a>
                        <span> &gt; </span>
                    </li>
                    <li class="lensabl-product">
                        <strong>{{ product_name }}</strong>
                    </li>
                </ul>
            </div>
        </div>
        <div v-show="productView.productInfoShow" :class="{'product-layout-2columns': isDesktop}" class="product-details-holder lensabl-frames-view frames-wrapper">
            <div class="product media">
                <Gallery :class="{'vertical-thumbs': isDesktop}" />
                <VirtualTryOn
                    :current-color-item="colorItem"
                    :product-category-name="productCategoryName"
                    :frame-colors="colors"
                    :lens-colors="productCategoryName === 'sun' ? sunColors : {}"
                    :checked-index="checkedIndex"
                    :checked-lens-index="sunIndex"
                    @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor"
                    @[EVENT_CHANGE_CURRENT_COLOR_LENS]="changeCurrentLensColor"
                />
            </div>

            <div class="product-info-main">
                <div class="page-title-wrapper product">
                    <h1 v-dompurify-html="product_name" :class="product_name" class="page-title" itemprop="name"></h1>
                </div>

                <template v-if="colors.length">
                    <Affirm v-if="!isRedeemFlow" />

                    <div class="swatch-opt">
                        <ColorSwatches
                            :colors="colors"
                            :checked-index="checkedIndex"
                            :option-item-class="'decorate bigger img'"
                            :product-name="product_name.toLowerCase()"
                            @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor"
                        />
                    </div>

                    <AdditionalAttributes :class="{'inline': !isDesktop && !isMobile, 'grid': isMobile}" :data-attributes="additionalAttributes" />

                    <div v-if="isAvailable && !isDesktop" class="product-options-bottom">
                        <div class="actions">
                            <BaseButton :text="'Choose Your Lenses'" @click.native="isMobile ? mobileSwitcher() : goToConfigurator()">
                                Choose Your Lenses
                            </BaseButton>
                        </div>
                    </div>
                    <p v-if="!stockStatus" class="unavailable stock" title="Availability">
                        <span style="color:red">This item is sold out</span>
                    </p>
                </template>
            </div>
        </div>

        <div class="product-border-divider"></div>
        <AnagramAdBar />

        <ImageHelpBubble v-if="!plusMember" :ad-data="getBannerText" />

        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator :custom-summary="shopFramesSummary" />
        </div>

        <ReturnsInfo v-if="productView.productInfoShow" />
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import CatalogProductView from '../CatalogProductView.vue'
import ResetService from '../../../../app/mixins/reset-service'
import VirtualTryOn from '../view/virtual_try_on/VirtualTryOn.vue'
import Affirm from '../view/ProductAffirmInfo.vue'
import ReturnsInfo from '../../cms_info/ReturnsInfo.vue'
import getSizeHelper from './constants/size'
import ImageHelpBubble from '../../../help_bubble/ImageHelpBubble.vue'
import AnagramAdBar from '../../../anagram/AnagramAdBar.vue'
import {
    SHOP_FRAMES_BRANDS,
    EVENT_CHANGE_CURRENT_COLOR_LENS
} from '../../../../app/utils/constants'
import {TEXT_PLUS_BANNER} from "./constants/banners";

export default {
    name: 'CatalogProductLensablView',
    components: {
        VirtualTryOn,
        Affirm,
        ReturnsInfo,
        ImageHelpBubble,
        AnagramAdBar
    },
    extends: CatalogProductView,
    mixins: [ResetService],
    data: () => ({
        EVENT_CHANGE_CURRENT_COLOR_LENS: EVENT_CHANGE_CURRENT_COLOR_LENS
    }),
    computed: {
        ...mapGetters('productView', ['productCategoryName']),
        ...mapGetters('deviceProperty', ['isDesktop']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow', 'plusMember']),
        ...mapGetters('storeView', ['assets_url']),
        getBannerText(){
            return this.isShopFramesBrand ? TEXT_PLUS_BANNER.shop_frames : TEXT_PLUS_BANNER.everyday
        },
        size_help () {
            const product = this.product_name
            const catName = this.productCategoryName.charAt(0).toUpperCase() + this.productCategoryName.slice(1)
            const productName = product.replace(catName, '')
            if (this.size_components[productName.trim()]) {
                return this.size_components[productName.trim()]
            } else if (this.getCurrentProductAttribute('size')) {
                const sizeImgList = ['49-19-140', '50-19-135', '50-19-140', '51-18-135', '51-20-145', '52-18-140', '57-18-140', '54-17-140', '52-16-140', '50-18-145', '48-20-145', '47-19-150','47-24-145','49-20-150','50-16-135','51-18-140','54-16-145','55-16-140','55-16-145','55-19-150','56-16-145','56-17-145','56-18-145','57-19-150','58-18-150']

                if (sizeImgList.includes(this.getCurrentProductAttribute('size'))) {
                    const url = this.assets_url + this.getCurrentProductAttribute('size') + '.jpg'
                    this.imgExist(url)
                    return {
                        'name': 'help-bubble',
                        'params': {
                            'text': '<img src="' + url + '" />'
                        }
                    }
                }
            }
            return {}
        },
        additionalAttributes () {
            return [
                {
                    label: this.isDesktop ? 'Frame Color' : 'Color',
                    value: this.colorName.toLowerCase()
                },
                {
                    label: 'Material',
                    value: this.getCurrentProductAttribute('material')
                },
                {
                    label: 'Size',
                    value: this.getCurrentProductAttribute('frame_measurement'),
                    component_help: this.size_help,
                    help_placement: this.isDesktop ? 'left-center' : 'top'
                },
                {
                    label: 'Gender',
                    value: this.getCurrentProductAttribute('material') ? 'Unisex' : ''
                },
                {
                    label: 'Lens Color As Shown',
                    value: this.productCategoryName === 'sun' ? 'Grey' : '',
                    itemClassName: 'item--sun'
                }
            ]
        },
        isShopFramesBrand () {
            const currentProductCategory = this.productCategoryName.toLowerCase()
            return Boolean(SHOP_FRAMES_BRANDS.includes(currentProductCategory))
        },
        shopFramesSummary () {
            const shopFramesBrands = [...SHOP_FRAMES_BRANDS, 'optical', 'night', 'sun']
            const currentProductCategory = this.productCategoryName.toLowerCase()

            return Boolean(shopFramesBrands.includes(currentProductCategory))
        },
        isPPEProduct () {
            if (this.productCategoryName === 'personal protective eyewear') {
                // const ppeProductCategoryName = this.productCategoryName;
                return true
            }
            return false
        }
    },
    watch: {
        sunIndex (index) {
            const sunColor = this.sunColors[index]
            if (sunColor.id) {
                this.setDataToValues({
                    color: {
                        value: sunColor.id
                    }
                })
            }
        }
    },
    created () {
        const {size_helpComponent, SUN_COLORS} = getSizeHelper({assets_url: this.$store.getters['storeView/assets_url']})
        this.size_components = size_helpComponent
        this.sunColors = SUN_COLORS

        this.sunIndex = 0
        this.$store.dispatch('lensablplus_customer/checkRedeemablePath')
    },
    methods: {
        ...mapMutations('values', ['setDataToValues']),
        ...mapActions('lensablplus_customer', ['checkRedeemablePath']),
        goToConfigurator () {
            this.scrollToStepHead(this.visibleFirstStep.id)
        },
        imgExist (url) {
            const http = new XMLHttpRequest()
            http.open('HEAD', url, false)
            http.send()
            return http.status !== 404
        }
    }
}
</script>

<style scoped>
.vue-breadcrumbs {
    margin: 1em 0 0 0 !important;
}
</style>
