var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rx-seo-container" }, [
      _c("div", { staticClass: "shopframes-seo-prescription-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-prescription" }, [
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Sunglasses and Sunglass Lenses")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Shop with Lensabl for an extensive selection of sunglasses with prescription lenses for sale. Lensabl carries prescription sunglasses online for any patient's optic needs. We have designer sunglasses for men and women from numerous designer brands."
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "shopframes-seo-img" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-designer-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-designer" }, [
          _c("div", { staticClass: "shopframes-seo-designer-img" }),
          _vm._v(" "),
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Prescription Lenses for Sunglasses ")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Lensabl makes it possible to find sunglasses that meet both your vision and lifestyle needs. You'll find polarized prescription sunglasses for sale that improve your vision for reading or distance. Lensabl also carries solid, gradient and mirrored prescription sunglasses with options like 100% ultraviolet (UV) protection, blue-light protection and anti-reflective or anti-scratch coatings. Turn to Lensabl for easy, online prescription sunglass lens replacement. We will also ship your new lenses to you for free!"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-sunglass-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-sunglass" }, [
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Durable Frames for Sunglasses")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Order sunglass frames through Lensabl to find an option that meets your lifestyle needs. Choose our acetate frames for a lightweight, eco-friendly option that fits comfortably throughout the entire day. Our metal sunglass frames are the most durable option available in styles that complement any wardrobe choice."
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-brands-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-brands" }, [
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Designer Sunglasses for Your Prescription")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Prescription sunglasses from Lensabl fit into durable frames from many of the most prominent designer brands. Shop with Lensabl to pair your prescription sunglasses with a frame from dependable designers like:"
              )
            ])
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "seo-brands-list" }, [
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/arnette" } }, [
                _vm._v("Arnette")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/kate-spade" } }, [
                _vm._v("Kate Spade")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/brooks-brothers" } }, [
                _vm._v("Brooks Brothers")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/marc-jacobs" } }, [
                _vm._v("Marc Jacobs")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/carrera" } }, [
                _vm._v("Carrera")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/oakley" } }, [
                _vm._v("Oakley")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/everyday-eyewear" } }, [
                _vm._v("Everyday Eyewear")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/ray-ban" } }, [
                _vm._v("Ray-Ban")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/hugo-boss" } }, [
                _vm._v("Hugo Boss")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "/shop-frames/vogue" } }, [
                _vm._v("Vogue")
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-eyeglass-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-eyeglass" }, [
          _c("div", { staticClass: "shopframes-seo-text seo-eyeglass-text" }, [
            _c("h2", [
              _vm._v("Buy Prescription Sunglasses Online With Lensabl")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Lensabl can help you find comfortable, durable sunglasses that fit your prescription. Order prescription sunglasses online for one of the most extensive selections of visual accommodations, styles and sizes."
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                "Lensabl customers benefit from free delivery, 30-day risk-free returns and a 12-month warranty with every order. You can experience additional benefits when you "
              ),
              _c("a", { attrs: { href: "/plus", title: "plus" } }, [
                _c("span", { staticClass: "rx-seo-link" }, [
                  _vm._v("become a Lensabl+ member")
                ])
              ]),
              _vm._v(", with monthly plans starting at $7.99."),
              _c("br"),
              _c("br")
            ])
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: "/prescription-sunglass-lenses" } }, [
            _c("div", { staticClass: "button button--copy-solid" }, [
              _c("div", { staticClass: "button__container" }, [
                _vm._v("Place your prescription sunglass order online today!")
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }