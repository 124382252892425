<template>
    <div>
        <div v-if="showEditVersion" class="selector-progressive-container">
            <div class="selector-prescription">
                <PrescriptionTable :show-edit-version="showEditVersion" :show-add="showAdd" :value="value" :help="help" />
                <div v-if="showError" class="error-text-rx">
                    <span v-dompurify-html="errorText"></span>
                </div>
            </div>

            <PrescriptionPupillaryDistance :show-edit-version="showEditVersion" :show-add="showAdd" :value="value" :help="help" />

            <Prism class="prism-component" />
        </div>
        <div v-else>
            <ParamsNotEdit :help="help" class="selector-progressive-container" />
        </div>
    </div>
</template>

<script>
import ParamsAbstractViewModel from '../abstract/ParamsAbstractViewModel'
import PrescriptionTable from '../../../components/prescription/PrescriptionTable.vue'
import PrescriptionPupillaryDistance from '../../../components/prescription/PrescriptionPupillaryDistance.vue'
import ParamsNotEdit from '../not_edit/ParamsNotEdit.vue'
import Prism from '../../../app/common/prism/Prism.vue'

import {mapGetters} from 'vuex'

export default {
    name: 'ParamsPrescription',
    components: {
        PrescriptionTable,
        PrescriptionPupillaryDistance,
        ParamsNotEdit,
        Prism
    },
    extends: ParamsAbstractViewModel,
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        value () {
            return this.values.prescriptionValues[this.id]
        }
    },
    beforeCreate () {
        if (!this.optionsValidate) {
            this.$store.dispatch('prescriptions/optionsValidate', true)
        }
    },
    beforeDestroy () {
        this.$store.commit('prescriptions/OPTIONS_VALIDATE', false)
    },
    methods: {
        setPrescriptionValue (val, key) {
            this.$set(this.value, key, val)
        }
    }
}
</script>
