<template>
    <div>
        <div id="eyeglass-container" class="prescription-container_eyeglass">
            <div v-if="prescriptions.length !== 0">
                <paginate v-if="type === 'lens'" ref="paginator" :list="items" :per="3" name="items" class="paginate-list">
                    <li v-for="item in paginated('items')" :key="item.data.prescription_id">
                        <RXTable
                            :id="item.data.prescription_id"
                            :key="item.data.prescription_id"
                            :prescription="item.data"
                            :all_values="item.info"
                            :rx-history="item.history"
                            @clicked="enableAdd"
                        />
                    </li>
                </paginate>
                <paginate v-else ref="paginator" :per="3" :list="items" name="items" class="paginate-list">
                    <li v-for="item in paginated('items')" :key="item.prescription_id">
                        <ContactsList
                            :id="item.prescription_id"
                            :key="item.prescription_id"
                            :prescription="item"
                            :items="item.items"
                        />
                    </li>
                </paginate>
                <div class="page-number-display">
                    <span>Page</span>
                    <paginate-links for="items" @change="onPageChange" />
                </div>
            </div>
            <div v-else>
                <p class="empty-rx">
                    No saved prescriptions
                </p>
            </div>
        </div>

        <div v-if="type === 'lens'" :class="{ new: newRx }" class="add-new-rx_lens" @click="addRx">
            <div class="button button--copy-icon account">
                <div class="button__icon">+</div>
                <div class="button__copy">Add new prescription</div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import $ from 'jquery'
import Paginate from 'vue-paginate'
import RXTable from './RxTable.vue'
import ContactsList from './ContactsList.vue'

Vue.use(Paginate)

export default {
    name: 'List',
    components: {
        RXTable,
        ContactsList
    },
    props: {
        'prescriptions': {
            type: Array,
            default: () => [],
            required: true
        },
        'type': {
            type: String,
            default: '',
            required: true
        }
    },
    data: () => {
        return {
            items: [],
            newRx: false,
            paginate: ['items'],
            shown: true,
            blankRX: {
                'name': '',
                'order_id': null,
                'prescription_id': null,
                'store_id': 1,
                'item_id': null,
                'customer_id': null,
                'value': '',
                'lens': 'prescription'
            },
            prescription_data: {
                'od_sphere': 'PL/0',
                'od_cylinder': 'SPH/Blank/0',
                'od_axis': 'blank/0',
                'od_add': '',
                'od_prism': '',
                'od_pd': '',
                'os_sphere': 'PL/0',
                'os_cylinder': 'SPH/Blank/0',
                'os_axis': 'blank/0',
                'os_add': '',
                'os_prism': '',
                'os_pd': '',
                'prism': false,
                'separate_pd': false,
                'os_prism_horizontal': '',
                'os_prism_vertical': '',
                'os_prism_vertical_direction': '',
                'os_prism_horizontal_direction': '',
                'pd': ''
            }
        }
    },
    beforeCreate () {

    },
    mounted () {
        this.items = this.prescriptions
    },
    methods: {
        enableAdd (value) {
            this.newRx = value
        },
        addRx () {
            if (this.newRx) {
                return
            }
            this.newRx = true
            const data = this.blankRX
            data['value'] = this.prescription_data

            const blankRx = {
                data: data,
                history: [],
                info: this.prescription_data
            }
            this.items.push(blankRx)

            if (this.$refs.paginator) {
                this.$refs.paginator.goToPage(this.$refs.paginator.lastPage)
            }
        },
        onPageChange () {
            const height = ($('#returns').offset().top) - 200
            $('html, body').scrollTop(height)
        }
    }
}
</script>

<style scoped>

</style>
