import items from './items'
import view from './view'

export default {
    namespaced: true,
    state: () => ({
        orderIsSentToTheServer: false,
        orderSaveError: false
    }),
    mutations: {
        SENDING_TO_THE_SERVER: (state, flag = false) => state.orderSaveError = !!flag,
        SAVE_ERROR_FLAG: (state, flag = false) => state.orderSaveError = !!flag
    },
    modules: {
        items,
        view
    }
}
