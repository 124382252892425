<template>
    <div class="category-frames-wrapper frames-wrapper">
        <div class="category-frames-included lenses-section">
            <div class="lens-tab">
                <span>lenses include:</span>
            </div>
            <div v-if="isShow('blue-light')" class="lens-tab">
                <div class="icon blue-light"><span><span class="desktop">Premium</span> Blue-Light Protection</span></div>
            </div>
            <div v-if="isShow('ar')" class="lens-tab">
                <div class="icon ar"><span>Anti-Reflective Coating</span></div>
            </div>
            <div v-if="isShow('scratch')" class="lens-tab">
                <div class="icon  scratch"><span>Anti-Scratch Coating</span></div>
            </div>
            <div v-if="isShow('uv')" class="lens-tab">
                <div class="icon uv"><span>100% UV Protection</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'LensesInclude',
    props: {
        unavailable: {
            type: [Object, Array],
            default: () => []
        }
    },
    computed: mapGetters('deviceProperty', ['isMobile']),
    methods: {
        isShow (type) {
            return this.unavailable.indexOf(type) === -1
        }
    }

}
</script>
