<template>
    <section id="sidebar" class="sidebar-filter-component">
        <div class="sidebar-container">
            <span class="close-filter" @click="closeFilters()">×</span>
            <div v-for="(subcategory, index) in categories" :key="subcategories[index]">
                <h4>{{ subcategories[index] }}</h4>
                <ul>
                    <li v-for="category in subcategory" :key="category.id" :class="[category.name, { active: category.id === chosenId}]" class="cateogry-links">
                        <a @click="choseCategory(category.id, index)">
                            <span>{{ category.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'
import _find from 'lodash/find'

export default {
    name: 'SideBar',
    data: () => ({
        chosenId: ''
    }),
    computed: {
        ...mapGetters('storeView', ['base_url']),
        ...mapGetters('category', ['categoryService', 'categories', 'subcategories', 'currentCategoryId'])
    },
    created () {
        this.chosenId = this.currentCategoryId
    },
    methods: {
        ...mapMutations([]),
        closeFilters () {
            const element = document.getElementById('sidebar')
            element.removeClassName('open')
            const overlay = document.getElementById('product_overlay')
            overlay.removeClassName('open')
        },
        choseCategory (id, index) {
            this.chosenId = id
            const category = _find(this.categories[index], {id: id})
            history.pushState({}, null, category.url)
            this.getProducts(id)
        },
        getProducts (category_id) {
            const element = document.getElementById('sidebar')
            element.removeClassName('open')
            const overlay = document.getElementById('product_overlay')
            overlay.removeClassName('open')
            this.$store.commit('contacts/UPDATE_RUNNER', true)
            this.$store.commit('contacts/UPDATE_PRODUCTS', '')
            this.$store.commit('contacts/UPDATE_SEARCHED', '')
            this.categoryService.getContactsProductsCollection(category_id)
                .then(({status, data}) => {
                    if (status === 200 && !data.error) {
                        this.$store.commit('contacts/UPDATE_PRODUCTS', data.products)
                    }
                    this.$store.commit('contacts/UPDATE_RUNNER', false)
                    this.$store.commit('contacts/UPDATE_VIEW_ALL', true)
                })
                .catch(error => {
                    if (error) {
                        console.error(error)
                    }
                    this.$store.commit('contacts/UPDATE_RUNNER', false)
                })
        }
    }
}
</script>

<style scoped>

</style>
