<template>
    <div>
        <div class="selector-lens-power">
            <CustomSelect
                :id="`eye_power_selector`"
                :label-title="`Lens Magnification`"
                :value="value.od = value.os"
                :options="eyePowerReadingOptions"
                class="eye-power-container"
                @change="setParamsLensPower($event.target.value)"
            />
        </div>
    </div>
</template>

<script>
import PreselectService from '../../../app/mixins/preselect-service'
import ParamsAbstractViewModel from '../abstract/ParamsAbstractViewModel'
import CustomSelect from '../../../components/base/Select.vue'
import {mapActions} from 'vuex'

export default {
    name: 'ParamsLensPower',
    components: {
        CustomSelect
    },
    extends: ParamsAbstractViewModel,
    mixins: [PreselectService],
    computed: {
        value () {
            return this.setPreSelectReadingType(this.values.prescriptionValues.lens_power)
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.setStepDisabled()
        })
    },
    methods: {
        ...mapActions('configurator/steps', ['updateStep']),
        setStepDisabled (disabled = true) {
            this.updateStep({
                id: this.id,
                data: {disabled: disabled}
            })
        },
        setParamsLensPower (val) {
            this.setStepDisabled()
            this.setDataToValues({
                prescriptionValues: {
                    lens_power: {
                        ...this.value,
                        od: val,
                        os: val
                    }
                }
            })

            if (val !== '0') {
                this.setStepDisabled(false)
            }
        }
    }
}
</script>
