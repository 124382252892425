var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-component" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasNoFile,
            expression: "hasNoFile"
          }
        ],
        staticClass: "file-input-box",
        class: { "invalid-form": _vm.showInvalidFormBorder }
      },
      [
        _c("input", {
          staticClass: "file-input",
          attrs: {
            type: "text",
            disabled: "disabled",
            placeholder: "No file chosen"
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "action primary file-input-button",
            attrs: { type: "button", title: "Continue" }
          },
          [
            _c("span", [_vm._v("Browse...")]),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "file",
                name: "prescription",
                accept: "image/*,application/pdf"
              },
              on: { change: _vm.previewFiles }
            })
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm.showError
      ? _c("div", { staticClass: "error-hint" }, [
          _vm.showError
            ? _c("div", { staticClass: "error-hint" }, [
                _c("span", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.errorHint,
                      expression: "errorHint"
                    }
                  ]
                })
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasFile
      ? _c("div", { staticClass: "uploaded-file" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.fileData.fileName,
                expression: "fileData.fileName"
              }
            ]
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "remove-link",
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.removeFile }
            },
            [_vm._v("Remove")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isUploading
      ? _c("div", [
          _c("div", { staticClass: "uploading-progress" }, [
            _vm._v("Uploading...")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "option-checkbox-container active" }, [
      _c("div", { staticClass: "option-checkbox" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }