<template>
    <div class="catalog-category-view categorypath-jamesoro-eyewear">
        <div class="rbm-banner-container">
            <div class="rbm-banner-row-container">
                <div class="rbm-banner-row">
                    <div class="rbm-banner-logo-mobile-container"></div>
                    <div class="rbm-banner-logo-container">
                        <img src="/media/catalog/category/ray-ban-meta/ray-ban-meta-logo.webp" class="rbm-banner-logo" />
                        <span class="rbm-banner-text">Smart Glasses</span>
                    </div>
                </div>
            </div>
            <video playsinline autoplay muted loop>
                <source src="/media/catalog/category/ray-ban-meta/ray-ban-meta-video.webm" type="video/webm">
                <source src="/media/catalog/category/ray-ban-meta/ray-ban-meta-video.mp4" type="video/mp4">
            </video>
        </div>
        <div v-dompurify-html="breadcrumbsHtml" class="frames-breadcrumbs"></div>
        <div class="category-products frames-products">
            <PageLoader v-if="categoryLoader" :load="categoryLoader"/>
            <template v-else>
                <div>
                    <div class="products wrapper grid products-grid">
                        <ul class="products list items product-items">
                            <li v-for="product in getProducts" :key="product.url" class="item product product-item">
                                <ProductItem
                                    :product="product"
                                    :showSwatcher="true"
                                    :showProductPrice="true"
                                    :show-category-name="true"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </div>
        <TechInfo />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import PageLoader from '../../../loaders/PageLoader.vue'
import ProductItem from './ProductItem.vue'
import TechInfo from './TechInfo.vue'

export default {
    name: 'ProductList',
    components: {
        PageLoader,
        ProductItem,
        TechInfo
    },
    computed: {
        ...mapGetters('category', [
            'breadcrumbsHtml',
            'categoryLoader',
            'categoryImage',
            'categoryMobileImage',
            'getProducts',
            'currentCategory'
        ]),
        ...mapGetters('storeView', ['base_url']),
        categoryName() {
            return this.currentCategory.name || false
        },
        categoryClass() {
            const name = this.categoryName
            const nameArr = name.split(' ')
            return nameArr.join('').toLowerCase()
        },
    },
    created() {
        this.$store.dispatch('category/fetchDefaultData')
        this.$store.dispatch('category/fetchCategoryProducts')
    },
}
</script>

<style scoped>

</style>
