import LandingPage from './LandingPage.vue'
import RouterCatalogCategoryView from './catalog/category/RouterCatalogCategoryView.vue'
import RouterCatalogProductView from './catalog/product/RouterCatalogProductView.vue'
import RouterAeCatalogProductView from './catalog/product/RouterAeCatalogProductView.vue'
import RouterWhCatalogProductView from './catalog/product/RouterWhCatalogProductView.vue'
import RouterGoodrCatalogProductView from './catalog/product/RouterGoodrCatalogProductView.vue'
import RouterJamesOroCatalogProductBlockView from './catalog/product/RouterJamesOroCatalogProductBlockView.vue'
import RouterRayBanMetaCatalogProductBlockView from './catalog/product/RouterRayBanMetaCatalogProductBlockView.vue'
import RouterCatalogProductBose from './catalog/product/RouterCatalogProductBose.vue'
import RouterCatalogProductHyperx from './catalog/product/RouterCatalogProductHyperx.vue'
import RouterCatalogProductLeSpecs from './catalog/product/RouterCatalogProductLeSpecs.vue'
import RouterCatalogProductHobie from './catalog/product/RouterCatalogProductHobie.vue'
import RouterCatalogProductRheos from './catalog/product/RouterCatalogProductRheos.vue'
import RouterCatalogProductGamerAdvantage from './catalog/product/RouterCatalogProductGamerAdvantage.vue'
import RouterCatalogProductKiaura from './catalog/product/RouterCatalogProductKiaura.vue'
import RouterCatalogProductClipOn from './catalog/product/RouterCatalogProduct.vue'
import RouterCatalogProductSpectacles from './catalog/product/RouterCatalogProductSpectacles.vue'
import RouterCatalogProductSpectaclesGen from './catalog/product/RouterCatalogProductSpectaclesGen.vue'
import RouterCatalogCategoryLensablView from './catalog/category/lensabl/RouterCatalogCategoryLensablView.vue'
import RouterCatalogCategoryFreeLensablView from './catalog/category/lensabl/RouterCatalogCategoryFreeLensablView.vue'
import RouterCategory from './catalog/category/RouterCategory.vue'
import RouterCatalogProductLensablView from './catalog/product/lensabl/RouterCatalogProductLensablView.vue'
import RouterCustomerAccount from './customer/RouterCustomerAccount.vue'
import RouterCustomerHeaderLinks from './customer/header/Links.vue'
import RouterCustomerPrescriptionNew from './customer/prescription/RouterCustomerPrescriptionNew.vue'
import RouterCustomerPrescriptionEdit from './customer/prescription/RouterCustomerPrescriptionEdit.vue'
import RouterShippingDownloadLabel from './shipping/download/RouterShippingDownloadLabel.vue'
import RouterShippingAddressUpdate from './shipping/address/RouterShippingAddressUpdate.vue'
import RouterSalesOrderViewPrescription from './sales/order/view/RouterSalesOrderViewPrescription.vue'
import RouterContactsCategory from './contacts/Category.vue'
import RouterContactsProduct from './contacts/RouterContactsProductView.vue'
import RouterContactsHomepageSlider from './contacts/HomepageSlider.vue'
import RouterWhyLensablSlider from './WhyLensablSlider.vue'
import RouterContactsSalesOrderEyeFormData from './contacts/RouterContactsSalesOrderEyeFormData.vue'
import RouterCustomerPrescription from './customer/prescription/RouterCustomerPrescription.vue'
import RouterCustomerOrder from './customer/order/RouterCustomerOrder.vue'
import RouterContactsSubscriptions from './contacts/myaccount/Subscriptions.vue'
import RouterLensablplus from './lensablplus/RouterLensabalplus.vue'
import RouterLensablplusContacts from './lensablplus/RouterLensblplusContacts.vue'
import RouterLensablPlusCustomerAccount from './customer/RouterLensablPlusCustomerAccount.vue'
import RouterLensablplusPlanChoice from './lensablplus/RouterLensablplusPlanChoice.vue'
import RouterLensablplusBusinessPlanChoice from './lensablplus/RouterLensablplusBusiness.vue'
import RouterLensablplusPlanChoiceHeader from './lensablplus/RouterLensablplusPlanChoiceHeader.vue'
import RouterLensabalplusMembershipView from './lensablplus/RouterLensabalplusMembershipView.vue'
import RouterLensabalplusMembershipCreate from './lensablplus/RouterLensabalplusMembershipCreate.vue'
import RouterWhyPlus from './lensablplus/RouterWhyPlus.vue'
import RouterEmployeeSign from './lensablplus/RouterEmployeeSignup.vue'
import RouterContactsLensesDetails from './contacts/RouterContactsLensesDetails.vue'
import RouterContactsSubscriptionsDetail from './contacts/myaccount/SubscriptionsDetail.vue'
import RouterGoVisibly from './visibly/govisibly/RouterGoVisibly.vue'
import RouterOnlineVision from './visibly/RouterOnlineVision.vue'
import RouterRxReaderQr from './rxreader/RouterRxReader.vue'
import RouterCategoryProductList from './catalog/category/RouterProductList.vue'
import RouterCheckoutLPlus from './checkout/RouterCheckoutLPlus.vue'
import RouterFeaturedFramesView from './catalog/category/RouterFeaturedFramesView.vue'
import RouterLensablPlusFree from './lensablplus/RouterLensablPlusFree.vue'
import RouterProvidersPage from './insurances/RouterProvidersPage.vue'
import RouterInsuranceAnagram from './insurances/RouterInsuranceAnagram.vue'
import RouterAdminhtmlInsuranceAnagram from './insurances/adminhtml/RouterInsuranceAnagram.vue'
import RouterSunglassColorGuide from './sunglasscolorguide/RouterSunglassColorGuide.vue'
import RouterGamingGlasses from './gaming_glasses/RouterGamingGlasses.vue'
import RouterAffirmConfirm from './checkout/payments/affirm/RouterAffirmConfirm.vue'
import RouterLensablPlusBusinessDoctors from './lensablplus/RouterLensablPlusBusinessDoctors.vue'
import RouterAnagramAdBar from './anagram/RouterAnagramAdBar.vue'
import RouterMembershipSignup from './membership/RouterMembershipSignup.vue'
import RouterMembershipAccount from './membership/RouterMembershipAccount.vue'

export const Routes = {
    'landing': LandingPage,
    'frames': RouterCatalogCategoryView,
    'frames/product': RouterCatalogProductView,
    'frames/aeProductBlock': RouterAeCatalogProductView,
    'frames/whProductBlock': RouterWhCatalogProductView,
    'frames/goodrProductBlock': RouterGoodrCatalogProductView,
    'frames/JamesOroProductBlock': RouterJamesOroCatalogProductBlockView,
    'frames/RayBanMetaProductBlock': RouterRayBanMetaCatalogProductBlockView,
    'frames-product-list': RouterCategoryProductList,
    'shop-frames': RouterCatalogCategoryLensablView,
    'shop-free-frames': RouterCatalogCategoryFreeLensablView,
    'shop-frames-hobie': RouterCategory,
    'shop-frames/product': RouterCatalogProductLensablView,
    'shop-frames-bose/product': RouterCatalogProductBose,
    'shop-frames-hyperx/product': RouterCatalogProductHyperx,
    'shop-frames-le-specs/product': RouterCatalogProductLeSpecs,
    'shop-frames-hobie/product': RouterCatalogProductHobie,
    'shop-frames-rheos/product': RouterCatalogProductRheos,
    'shop-frames-gamer-advantage/product': RouterCatalogProductGamerAdvantage,
    'shop-frames-kiaura/product': RouterCatalogProductKiaura,
    'shop-frames-clipon/product': RouterCatalogProductClipOn,
    'shop-frames-spectacles/product': RouterCatalogProductSpectacles,
    'shop-frames-spectacles-gen/product': RouterCatalogProductSpectaclesGen,
    'customer/account': RouterCustomerAccount,
    'customer/orders': RouterCustomerOrder,
    'customer/prescription': RouterCustomerPrescription,
    'customer/header/links': RouterCustomerHeaderLinks,
    'customer/prescription/new': RouterCustomerPrescriptionNew,
    'customer/prescription/edit/': RouterCustomerPrescriptionEdit,
    'shipping/download/label': RouterShippingDownloadLabel,
    'shipping/address/update': RouterShippingAddressUpdate,
    'sales/order/view/prescription': RouterSalesOrderViewPrescription,
    'contacts-lenses': RouterContactsCategory,
    'contacts-lenses/product': RouterContactsProduct,
    'contacts-lenses/details': RouterContactsLensesDetails,
    'plus/contacts-lenses-details': RouterLensablplusContacts,
    'why-plus': RouterWhyPlus,
    'plus': RouterLensablplus,
    'employee-signup': RouterEmployeeSign,
    'contacts-lenses/homepage-slider': RouterContactsHomepageSlider,
    'lensablplus/planchoice': RouterLensablplusPlanChoice,
    'lensablplus/businessplanchoice': RouterLensablplusBusinessPlanChoice,
    'lensablplus/planchoice/header': RouterLensablplusPlanChoiceHeader,
    'lensablplus/membership/view': RouterLensabalplusMembershipView,
    'lensablplus/membership/create': RouterLensabalplusMembershipCreate,
    'contacts-lenses/subscriptions': RouterContactsSubscriptions,
    'contacts-lenses/subscriptions-detail':  RouterContactsSubscriptionsDetail,
    'contacts-lenses/order-eye-form-data': RouterContactsSalesOrderEyeFormData,
    'why-lensabl': RouterWhyLensablSlider,
    'goVisibly': RouterGoVisibly,
    'online-vision': RouterOnlineVision,
    'lensablplus-myaccount': RouterLensablPlusCustomerAccount,
    'lplus-checkout': RouterCheckoutLPlus,
    'rxreader/qr': RouterRxReaderQr,
    'featuredframes': RouterFeaturedFramesView,
    'lensablplus-free': RouterLensablPlusFree,
    'providers-page': RouterProvidersPage,
    'insurance-anagram': RouterInsuranceAnagram,
    'insurance-adminhtml-anagram': RouterAdminhtmlInsuranceAnagram,
    'sunglass-color-guide': RouterSunglassColorGuide,
    'gaming-glasses': RouterGamingGlasses,
    'payments-affirm-confirm': RouterAffirmConfirm,
    'business-plus-doctors': RouterLensablPlusBusinessDoctors,
    'anagram-ad-bar': RouterAnagramAdBar,
    'membership-signup': RouterMembershipSignup,
    'member-program-myaccount': RouterMembershipAccount,
}
