<template>
    <div v-show="isCurrent" :class="id">
        <h2 v-if="config.title" v-dompurify-html="config.title" class="title"></h2>
        <h3 class="description">
            Select which plans that you would like to offer your employees
            <a href="/js/lensconfigurator/assets/plans/group_plans.pdf" download>
                Download PDF to view all plan details.
            </a>
        </h3>

        <div class="plans_container">
            <label v-for="(plan, index) in plans" :key="`${plan.id}-${index}`" :class="{active: selected[plan.sku]}" :for="plan.id" class="plan-option">
                <template v-if="plan.label !== 'exam only'">
                    <input :id="plan.id" :title="plan.name" type="checkbox" name="plans" @input="togglePlans(plan.sku)" />
                    <span class="plan-container">
                        <div class="plan-label">
                            <span><strong :style="{ color: plan.color }" class="plan-name">2021 {{ plan.label }}
                            </strong></span>
                            <span> ${{ plan.price }} per month</span>
                        </div>
                    </span>
                </template>
            </label>
        </div>
        <div v-if="error" class="validation-advice" style="">Please choose a plan.</div>

        <h5>Who is going to pay?</h5>
        <div class="pay-container">
            <label v-for="(option, index) in payOptions" :key="index" :class="{active: payer === option}" :for="option" class="plan-option">
                <input :id="option" :title="option" type="radio" name="payer" @click="togglePayer(option)" />
                <span class="plan-container">
                    {{ option }}
                </span>
            </label>
        </div>
        <div v-if="payerError" class="validation-advice" style="">Please choose who is responsible for payment.</div>

        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton
                    class="button--copy-solid-white-blue button--small"
                    text="Continue"
                    @click.native="continueNxt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseButton from '../../base/BaseButton.vue'
import _map from 'lodash/map'

export default {
    name: 'BusinessPlans',
    components: {
        BaseButton
    },
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        error: false,
        payerError: false,
        payOptions: ['Company', 'Employee']
    }),
    computed: {
        ...mapGetters('lensablplus_business', ['getObjectConfig', 'currentComponent', 'plans', 'selected', 'payer']),
        config () {
            return this.getObjectConfig(this.id)
        },
        options () {
            return this.config.component.options
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        }
    },
    methods: {
        setValue (option) {
            this.activeIndex = option.value
        },
        continueNxt () {
            let error = false
            _map(this.selected, (val, key) => {
                if (val === true) {
                    error = true
                }
            })

            if (this.payer.length === 0) {
                this.payerError = true
            } else {
                this.payerError = false
            }

            if (!error || this.payerError) {
                this.error = !error
            } else {
                // create new company and send email
                const options = {}
                options['selected'] = this.selected
                options['payer'] = this.payer

                if (this.payer === 'Employee') {
                    this.$store.commit('lensablplus_business/LOAD', true)
                    this.$store.commit('lensablplus_business/COMPONENT_DATA', {id: this.id, value: options, isValidValue: true})
                    this.$store.dispatch('lensablplus_business/submitBusiness')
                } else {
                    this.$store.commit('lensablplus_business/COMPONENT_DATA', {id: this.id, value: options, isValidValue: true})
                }
            }
        },
        togglePlans (sku) {
            this.$store.commit('lensablplus_business/UPDATE_SELECTED', sku)
        },
        togglePayer (payer) {
            this.$store.commit('lensablplus_business/UPDATE_PAYER', payer)
        }
    }
}
</script>

<style scoped>

</style>
