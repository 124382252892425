<template>
    <div class="bottom-disclaimer">
        <p>
            <strong>Need Help?</strong>
            <span v-if="isGoodrDisclaimerText">
                Selecting the best material for your lenses can depend on your prescription strength and the frame being purchased. If you'd like assistance, feel free to reach out to us via <a href="/contact" target="_blank">support</a> or live chat! 
            </span>
            <span v-else>
                Selecting the best material for your lenses can depend on your prescription strength, as well as the size/shape of your frames.
                If you'd like assistance, feel free to reach out to us via <a href="/contact" target="_blank">support</a> or live chat!
            </span>
        </p>
    </div>
</template>

<script>
export default {
    name: 'Disclaimer',
    props: {
        category: {
            type: String,
            default: ''
        },
    },
    computed: {
        isGoodrDisclaimerText() {
            return this.category.toLowerCase().includes('goodr')
        }
    }
}
</script>

<style scoped>

</style>
