var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "prescription-container_eyeglass",
        attrs: { id: "eyeglass-container" }
      },
      [
        _vm.prescriptions.length !== 0
          ? _c(
              "div",
              [
                _vm.type === "lens"
                  ? _c(
                      "paginate",
                      {
                        ref: "paginator",
                        staticClass: "paginate-list",
                        attrs: { list: _vm.items, per: 3, name: "items" }
                      },
                      _vm._l(_vm.paginated("items"), function(item) {
                        return _c(
                          "li",
                          { key: item.data.prescription_id },
                          [
                            _c("RXTable", {
                              key: item.data.prescription_id,
                              attrs: {
                                id: item.data.prescription_id,
                                prescription: item.data,
                                all_values: item.info,
                                "rx-history": item.history
                              },
                              on: { clicked: _vm.enableAdd }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c(
                      "paginate",
                      {
                        ref: "paginator",
                        staticClass: "paginate-list",
                        attrs: { per: 3, list: _vm.items, name: "items" }
                      },
                      _vm._l(_vm.paginated("items"), function(item) {
                        return _c(
                          "li",
                          { key: item.prescription_id },
                          [
                            _c("ContactsList", {
                              key: item.prescription_id,
                              attrs: {
                                id: item.prescription_id,
                                prescription: item,
                                items: item.items
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-number-display" },
                  [
                    _c("span", [_vm._v("Page")]),
                    _vm._v(" "),
                    _c("paginate-links", {
                      attrs: { for: "items" },
                      on: { change: _vm.onPageChange }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _c("div", [
              _c("p", { staticClass: "empty-rx" }, [
                _vm._v("\n                No saved prescriptions\n            ")
              ])
            ])
      ]
    ),
    _vm._v(" "),
    _vm.type === "lens"
      ? _c(
          "div",
          {
            staticClass: "add-new-rx_lens",
            class: { new: _vm.newRx },
            on: { click: _vm.addRx }
          },
          [_vm._m(0)]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button button--copy-icon account" }, [
      _c("div", { staticClass: "button__icon" }, [_vm._v("+")]),
      _vm._v(" "),
      _c("div", { staticClass: "button__copy" }, [
        _vm._v("Add new prescription")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }