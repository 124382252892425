var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "struct-parallax-bg",
      staticStyle: { "padding-bottom": "0" }
    },
    [
      _c("AnagramProvider"),
      _vm._v(" "),
      _c("Providers"),
      _vm._v(" "),
      _c("LinneoProvider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }