var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c-subscribe" },
    [
      !_vm.isRedeemFlow || (_vm.isBusinessMember && _vm.isLensablPlus)
        ? _c(
            "label",
            {
              staticClass: "c-subscribe-label",
              class: { active: _vm.getSubscribed === "yes" }
            },
            [
              !_vm.isRedeemFlow || (_vm.isBusinessMember && _vm.isLensablPlus)
                ? _c("div", { staticClass: "c-subscribe-container" }, [
                    _c("div", { staticClass: "c-subscribe-checkbox" }, [
                      _c("input", {
                        staticClass: "c-subscribe-input",
                        attrs: {
                          type: "checkbox",
                          title: "Subscribe to save 6% off every order."
                        },
                        on: { click: _vm.toggleSubscribe }
                      })
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "c-subscribe-text" }, [
                _vm._v("Subscribe "),
                !_vm.isLensablPlus && !_vm.hasCustomerMembership
                  ? _c("span", [_vm._v("to save 6% off every order.")])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "c-subscribe-text--more",
                    attrs: { href: "#" },
                    on: { click: _vm.showSubscribed }
                  },
                  [_vm._v("Learn More")]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.subscribedModal
        ? _c(
            "Modal",
            {
              on: {
                close: function($event) {
                  _vm.subscribedModal = false
                }
              }
            },
            [
              _c("span", { staticClass: "modal-header" }, [
                _vm._v("Subscribe & Save")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-product-button-container" }, [
                _c("ul", [
                  _c("li", { staticClass: "save-money-img" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "/skin/frontend/lensabl/default/images/save-money.svg",
                        alt: "3 green dollar signs"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", [_vm._v("Save 6% on each order")])
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "free-ship-img" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "/skin/frontend/lensabl/default/images/free-shipping.svg",
                        alt: "green truck"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", [_vm._v("Free shipping")])
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "cancel-img" }, [
                    _c("img", {
                      attrs: {
                        src: "/skin/frontend/lensabl/default/images/cancel.svg",
                        alt: "green stop sign"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", [_vm._v("Cancel any time")])
                  ])
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }