import {WARRANTY_STEP} from '../../../app/common/constants/warranty'
import _find from 'lodash/find'
import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererUpgrades',
    extends: RendererOptions,
    computed: {
        renderer () {
            let title = ''
            const currentStep = this.step.isCurrent
            const readyStep = this.step.isReady
            if (!currentStep && !readyStep) {
                return title
            }
            const configs = [...WARRANTY_STEP]
            const warranty = this.values.warranty

            const keysObject = Object.keys(warranty)
            keysObject.map(key => {
                const aliasObject = _find(configs, {'value': key})
                if (!aliasObject) {
                    return title
                }
                title += aliasObject.hasOwnProperty('label') ? ' + ' + aliasObject.label : ''
            })

            return title
        }
    }
}
