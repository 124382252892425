var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { "sticky-container": "" } }, [
    _c("div", { staticClass: "summary_block" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "sticky",
              rawName: "v-sticky",
              value: _vm.shouldStick,
              expression: "shouldStick"
            }
          ],
          staticClass: "summary_block-content",
          attrs: { "sticky-offset": _vm.stickyOffset }
        },
        [
          _c("SummaryTotalsInfo", {
            staticClass: "totals-info",
            attrs: { place: _vm.place }
          }),
          _vm._v(" "),
          _c("SummaryShipping", { staticClass: "standard-shipping-container" }),
          _vm._v(" "),
          _vm.hasDiscount ? _c("SummaryTotalsDiscount") : _vm._e(),
          _vm._v(" "),
          !_vm.showFramesPrice && !_vm.isLensablPlus
            ? _c("SummaryTotalsPrice", { staticClass: "totals-price" })
            : _vm._e(),
          _vm._v(" "),
          _vm.isLensablPlus
            ? _c("SummaryRedeem", { staticClass: "totals-price" })
            : _vm._e(),
          _vm._v(" "),
          _vm.showFramesPrice || _vm.isLensablPlus
            ? _c("SummaryTotalsPrice", { staticClass: "totals-price" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isRedeemFlow
            ? _c("SummaryAffirm", { staticClass: "affirm-choose-my-lenses" })
            : _vm._e(),
          _vm._v(" "),
          _c("SummaryTermsInfo"),
          _vm._v(" "),
          _c("SummaryLensesInclude", {
            class: {
              "lensreplacement-summary-lensesinclude-container":
                _vm.isLensReplacementPage
            },
            attrs: { "is-lens-replacement-page": _vm.isLensReplacementPage }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "summary_block-header" }, [
      _c("span", [_vm._v("Summary")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }