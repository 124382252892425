<template>
    <div class="prescription-type visibly-prescription-type" style="margin: 2rem">
        <header class="prescription-type-header form-access__header">
            <h3 v-if="!showForm">Do you have an existing prescription?</h3>
            <h3 v-else>Provide Your Rx</h3>
        </header>
        <div v-if="!showForm && false" class="prescription-type-content">
            <BaseButton :text="'Yes'" @click.native="showRxForm" />
            <BaseButton :text="'No'" class="eyeglass-button" @click.native="enableNextStep" />
        </div>
        <div v-if="!showForm && !showChoices" class="prescription-type-content" style="max-width: 150px">
            <label>
                <input :id="'yes'" :value="'yes'" name="hasprescription" type="radio" @change="showPrescriptionTypes" />
                <span>Yes</span>
            </label>
            <label>
                <input :id="'no'" :value="'no'" name="hasprescription" type="radio" @change="enableNextStep" />
                <span>No</span>
            </label>
        </div>
        <div v-if="!showForm && showChoices" class="prescription-type">
            <h4 style="color: #8d8d8d; margin: 1em 0 0 0;font-weight: bold;">What kind of prescription do you have?</h4>
            <div class="prescription-type-content">
                <label disabled>
                    <input id="single_vision" value="single_vision" name="glasses" type="radio" @click="showRxForm" />
                    <span style="font-weight: normal;color: #8d8d8d;">Single Vision</span>
                </label>
                <label>
                    <input id="progressive" value="progressive" name="glasses" type="radio" @click="showRxForm" />
                    <span style="font-weight: normal;color: #8d8d8d;">Progressive</span>
                </label>
            </div>
        </div>
        <div v-if="showForm">
            <div v-if="TYPE !== defaultType">
                <Glasses
                    :next-step.sync="nextStep"
                    :complete-step.sync="completeStep"
                    :rxtype="rxtype"
                    @updaterxchoice="updateChoice"
                />
            </div>
            <div v-else>
                <Contacts
                    :next-step.sync="nextStep"
                    :complete-step.sync="completeStep"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseButton from '../../base/BaseButton.vue'
import Glasses from './types/Glasses.vue'
import Contacts from './types/Contacts.vue'

export default {
    components: {
        BaseButton,
        Glasses,
        Contacts
    },
    props: {
        currentStep: {
            type: [Array, Object],
            default: () => {}
        }
    },
    data () {
        return {
            showForm: false,
            showChoices: false,
            nextStep: false,
            completeStep: false,
            defaultType: 'contacts',
            rxtype: ''
        }
    },
    computed: {
        ...mapGetters('onlineExam', ['TYPE', 'SIGN_IN'])
    },
    watch: {
        nextStep: 'canContinue',
        completeStep: 'completedStep',
        rxtype (type) {
            this.$store.commit('values/setDataToValues', {
                lens: 'prescription',
                prescription_type: type
            })
        }
    },
    created () {
        this.$store.commit('values/setDataToValues', {'lens': 'prescription'})
    },
    activated () {

    },
    methods: {
        canContinue (payload) {
            if (payload) {
                this.showForm = !payload
            }
            this.$emit('can-continue', {value: payload})
        },
        completedStep (payload) {
            if (payload) {
                this.$emit('active-next')
            }
        },
        showPrescriptionTypes () {
            if (this.TYPE !== 'contacts') {
                this.showChoices = true
            } else {
                this.showRxForm()
            }
        },
        showRxForm (event) {
            this.rxtype = event ? event.target.value : 'contacts'
            this.showForm = true
            this.nextStep = false
            this.$store.commit('onlineExam/SET_SHOW_NAV', false)
        },
        enableNextStep () {
            this.nextStep = true
        },
        updateChoice () {
            this.showChoices = false
        }
    }
}
</script>
