<template>
    <div class="c-subscribe">
        <label v-if="!isRedeemFlow || (isBusinessMember && isLensablPlus)" :class="{active: getSubscribed === 'yes'}" class="c-subscribe-label">
            <div v-if="!isRedeemFlow || (isBusinessMember && isLensablPlus )" class="c-subscribe-container">
                <div class="c-subscribe-checkbox">
                    <input class="c-subscribe-input" type="checkbox" title="Subscribe to save 6% off every order." @click="toggleSubscribe" />
                </div>
            </div>
            <span class="c-subscribe-text">Subscribe <span v-if="!isLensablPlus && !hasCustomerMembership">to save 6% off every order.</span> <a href="#" class="c-subscribe-text--more" @click="showSubscribed">Learn More</a></span>
        </label>
        <!-- Modal -->
        <Modal v-if="subscribedModal" @close="subscribedModal = false">
            <span class="modal-header">Subscribe & Save</span>
            <div class="modal-product-button-container">
                <ul>
                    <li class="save-money-img">
                        <img src="/skin/frontend/lensabl/default/images/save-money.svg" alt="3 green dollar signs" />
                        <p>Save 6% on each order</p>
                    </li>
                    <li class="free-ship-img">
                        <img src="/skin/frontend/lensabl/default/images/free-shipping.svg" alt="green truck" />
                        <p>Free shipping</p>
                    </li>
                    <li class="cancel-img">
                        <img src="/skin/frontend/lensabl/default/images/cancel.svg" alt="green stop sign" />
                        <p>Cancel any time</p>
                    </li>
                </ul>
            </div>
        </Modal>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Modal from './Modal.vue'

export default {
    name: 'ContactsSubscribe',
    components: {
        Modal
    },
    props: {
        boxes: {
            type: [String, Number, Boolean],
            default: ''
        },
        active_number: {
            type: [Object],
            default: () => {}
        },
        supply_amount: {
            type: [Object],
            default: () => {}
        }
    },
    data: () => ({
        subscribedModal: false
    }),
    computed: {
        ...mapGetters('contacts', ['isContactDetailsPage', 'getSubscribed', 'isContactProductPage', 'getLeftEyeConfirmed', 'getRightEyeConfirmed', 'getRightEyeChecked', 'getLeftEyeChecked', 'getOriginalBoxQuantity', 'isContactProductPage']),
        ...mapState('contacts/productView', ['default_sub_box_quantity']),
        ...mapGetters('lensablplus_customer', ['isLensablPlus', 'isRedeemFlow', 'isBusinessMember']),
        ...mapGetters('membershipProgram', ['hasCustomerMembership', 'discountTotalByAction', 'isMemberBenefitGreater']),
    },
    mounted () {
        this.setSubscribed()
        this.updateBoxQuantity()
        if (this.isRedeemFlow && !this.isBusinessMember) {
            this.$store.commit('contacts/SET_SUBSCRIBE', true)
        }
    },
    methods: {
        updateBoxQuantity () {
            if (this.getSubscribed === 'yes' && this.isContactProductPage) {
                if (!this.getRightEyeConfirmed && this.getRightEyeChecked) {
                    document.getElementById('right').value = this.default_sub_box_quantity
                    document.getElementById('right').disabled = true
                }
                if (!this.getLeftEyeConfirmed && this.getLeftEyeChecked) {
                    document.getElementById('left').value = this.default_sub_box_quantity
                    document.getElementById('left').disabled = true
                }
            } else if (this.getSubscribed === 'no' && this.isContactProductPage) {
                if (!this.getRightEyeConfirmed) {
                    document.getElementById('right').disabled = false
                }
                if (!this.getLeftEyeConfirmed) {
                    document.getElementById('left').disabled = false
                }
                const exist = Object.values(this.supply_amount).includes(parseInt(this.active_number.original))

                if (this.getLeftEyeChecked && this.getRightEyeChecked && exist) {
                    if (document.getElementById('left').value === document.getElementById('right').value) {
                        this.$emit('clicked', parseInt(this.active_number.original))
                        document.getElementById(this.active_number.original).checked = true
                    }
                } else if (this.getLeftEyeChecked && !this.getRightEyeChecked && exist) {
                    this.$emit('clicked', parseInt(this.active_number.original))
                    document.getElementById(this.active_number.original).checked = true
                } else if (this.getRightEyeChecked && !this.getLeftEyeChecked && exist) {
                    this.$emit('clicked', parseInt(this.active_number.original))
                    document.getElementById(this.active_number.original).checked = true
                }
            }
        },
        setOriginalBoxQuantity () {
            if (this.getSubscribed === 'no' && this.isContactProductPage) {
                if (!this.getRightEyeConfirmed) {
                    document.getElementById('right').value = this.getOriginalBoxQuantity('right')
                }
                if (!this.getLeftEyeConfirmed) {
                    document.getElementById('left').value = this.getOriginalBoxQuantity('left')
                }
            }
        },
        toggleSubscribe () {
            if (this.getSubscribed === 'no' && this.isContactProductPage) {
                const payload = {
                    right: document.getElementById('right').value,
                    left:  document.getElementById('left').value
                }
                this.$store.commit('contacts/UPDATE_ORIGINAL_BOX_QUANTITY', payload)
            }

            this.$store.commit('contacts/UPDATE_SUBSCRIBE')

            if (this.getSubscribed === 'yes') {
                this.$emit('clicked', 0)
                const radList = document.getElementsByName('supply_amount')
                for (let i = 0; i < radList.length; i++) {
                    if (radList[i].checked) document.getElementById(radList[i].id).checked = false
                }
            }
            this.setOriginalBoxQuantity()
            this.updateBoxQuantity()
        },
        setSubscribed () {
            if (!this.getLeftEyeConfirmed && !this.getRightEyeConfirmed) {
                this.$store.commit('contacts/SET_SUBSCRIBE', false)
            }
        },
        showSubscribed () {
            this.subscribedModal = true
        }
    }
}
</script>
