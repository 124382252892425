<template>
    <div class="whyplus_page">
        <section class="home__hero">
            <div class="hero-video-button">
                <div class="hero-why-container">
                    <div class="hero-video-button__row">
                        <div class="hero-video-button__copy">
                            <h1>Lensabl +</h1>
                            <h2>Vision Benefits Made Simple</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="struct-parallax-bg vision-benefits">
            <div class="vision-container">
                <div class="vision-benefits-description">
                    <h3>You're probably used to seeing something like this...</h3>
                    <p>Very lengthy. Lots of caveats. Unclear what's actually covered <img width="25px" src="/js/lensconfigurator/images/emoji-exploding-head.png" /></p>
                    <a v-if="isMarketingPage" href="/plus/vision_plan">
                        <div class="hero-video-button__button-primary">
                            <div class="button button--copy-solid-dark">
                                <div class="button__container">CHOOSE YOUR PLAN</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="vision-benefits-chart">
                    <div class="vision-benefits-table">
                        <img id="desktop" src="/js/lensconfigurator/assets/example-competition-table.png" />
                        <img id="smaller_device" src="/js/lensconfigurator/assets/example-competition-table.png" />
                    </div>
                </div>
            </div>
        </section>
        <section class="custom-callout custom-callout__background plus-plan-benefits" style="background-image: url('https://www.lensabl.com/media/wysiwyg/lensabl-background-snellentest.jpg')">
            <div class="row">
                <div class="large-9 columns">
                    <div class="lensabl-plus-content">
                        <div class="plans-list-wrapper">
                            <div class="border-1"></div>
                            <div class="border-2"></div>
                            <div class="plans-list-container">
                                <ul class="plans-list">
                                    <li v-for="(plan, index) in plansInfo" :key="index" :class="plan.id">
                                        <div v-if="typeof plan.thumbnail === 'string'" class="plan-info-container">
                                            <div class="thumb" @click="setActiveIndex(index)">
                                                <img v-if="typeof plan.thumbnail === 'string'" :src="plan.thumbnail" />
                                                <template v-else>
                                                    <img v-for="(thumbnail, thumbIndex) in plan.thumbnail" :key="`thumbnail-${thumbIndex}`" :src="thumbnail" :class="`${plan.id}-${thumbIndex}`" />
                                                </template>
                                            </div>
                                            <div class="content">
                                                <h3 v-dompurify-html="plan.title" :class="{'has-description': plan.description || plan.contactsInfo(eyeData.left, eyeData.right), 'active': activeIndex === index}" class="tile" @click="setActiveIndex(index)"></h3>
                                                <div v-show="activeIndex === index">
                                                    <div v-if="plan.description" v-dompurify-html="plan.description" class="description"></div>
                                                    <HelpBubble v-if="plan.helpBubble" :data="plan.helpBubbleParams" :target-classes="plan.helpBubbleParams.targetClasses" />
                                                    <div v-if="plan.contactsInfo(eyeData.left, eyeData.right)" v-dompurify-html="plan.contactsInfo(eyeData.left, eyeData.right)" class="description"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="plan-info-container dual-options top-option">
                                                <div class="thumb" @click="setActiveIndex(index)">
                                                    <img :src="plan.thumbnail[0]" :class="`${plan.id}-0}`" />
                                                </div>
                                                <div class="content">
                                                    <h3
                                                        v-dompurify-html="plan.title[0]"
                                                        :class="{'has-description': plan.description, 'active': activeIndex === index}"
                                                        class="tile"
                                                        @click="setActiveIndex(index)"
                                                    >
                                                    </h3>
                                                    <div v-show="activeIndex === index">
                                                        <div v-if="plan.description[0] !== ''" v-dompurify-html="plan.description[0]" class="description"></div>
                                                        <div v-else class="description">
                                                            See any licensed eye care provider you want for an eye exam or contact fitting. Simply upload a valid receipt and receive a $60 Lensabl credit.
                                                            <HelpBubble v-if="plan.helpBubble" :data="plan.helpBubbleParams" :target-classes="plan.helpBubbleParams.targetClasses" />
                                                            When doctors don't need to chase reimbursements from the insurance company, they often give discounts.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <strong><p>-or-</p></strong>
                                            <div class="plan-info-container dual-options bottom-option">
                                                <div class="thumb" @click="setActiveIndex(index)">
                                                    <img :src="plan.thumbnail[1]" :class="`${plan.id}-1}`" />
                                                </div>
                                                <div class="content">
                                                    <h3
                                                        v-dompurify-html="plan.title[1]"
                                                        :class="{'has-description': plan.description, 'active': activeIndex === index}"
                                                        class="tile second-option"
                                                        @click="setActiveIndex(index)"
                                                    ></h3>
                                                    <div v-show="activeIndex === index">
                                                        <div v-dompurify-html="plan.description[1]" class="description"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="large-9 columns">
                    <div class="plan-benefits-description">
                        <h3>What You See Is What You Get</h3>
                        <p>Lensabl+ plans include only the things you need, so you’re not paying for things you don’t.</p>
                        <p class="price"><strong>Plans as low as $7.99/month</strong></p>
                        <a href="/plus/vision_plan">
                            <div class="hero-video-button__button-primary">
                                <div class="button button--copy-solid-dark">
                                    <div class="button__container">CHOOSE YOUR PLAN</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="plus-plan-costs">
            <div class="plus-plan-comparison">
                <div class="price-table">
                    <h3>No Math Degree Required</h3>
                    <p>Traditional vision plans make it hard to understand true out-of-pocket costs. With Lensabl+, you don’t have to calculate the amount you’ll actually end up paying, we’ll do it for you.</p>
                    <a href="/plus/vision_plan">
                        <div class="hero-video-button__button-primary">
                            <div class="button button--copy-solid-dark">
                                <div class="button__container">GET STARTED</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="plus-description">
                    <div>
                        <img class="plus-calculator" src="/js/lensconfigurator/images/calculator.svg" alt="crossed out calculator" />
                    </div>
                </div>
            </div>
        </section>
        <section class="struct-parallax-bg">
            <div class="vision-benefits-section">
                <div class="">
                    <img src="/js/lensconfigurator/images/plus-homepage/all-items.png" />
                </div>
                <div class="custom-vision-plan">
                    <h3>Build a custom vision plan in under 2 minutes</h3>
                    <a href="/plus/vision_plan">
                        <div class="hero-video-button__button-primary">
                            <div class="button button--copy-solid-dark">
                                <div class="button__container">GET STARTED</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import getPlansInfo from './storage/constants/plans'
import {mapGetters} from 'vuex'
import HelpBubble from '../help_bubble/HelpBubble.vue'

import {gsap, TimelineMax} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

gsap.timeline()
const drawtl = new TimelineMax({delay: 2})

export default {
    name: 'WhyPlus',
    components: {
        HelpBubble
    },
    data: () => ({
        plansInfo: [],
        activeIndex: null,
        eyeData: {
            left: {
                name: 'Biofinity',
                boxes: 2,
                frequency: 2,
                sku: 'B6-2080'
            }
        }
    }),
    computed: {
        ...mapGetters('storeView', ['assets_url']),
        isMarketingPage () {
            return window.location.pathname === '/why-plus/a'
        }
    },
    mounted () {
        const params = {
            assets_url: this.assets_url,
            type: '',
            contacts: 'We will ship you a 1 year supply of contacts throughout the year.'
        }
        this.plansInfo = getPlansInfo(params)
        if (window.innerWidth >= 901) {
            const panel1 = gsap.timeline({
                scrollTrigger:{
                    trigger: '.vision-benefits-table',
                    start: 'top 30%'
                }
            })
            panel1.to('.vision-benefits-table #desktop', {
                yPercent: -100,
                ease: 'none',
                scrollTrigger: {
                    trigger: '.pSection',
                    scrub: true
                }
            }, '-=.5')
        }

        const panel3 = gsap.timeline({
            scrollTrigger:{
                trigger: '.price-table',
                start: 'top 80%'
            }
        })
        panel3.from('.plus-calculator', 1, {autoAlpha:0, y: 100}, '<')
    },
    methods: {
        setActiveIndex (index) {
            this.activeIndex = (this.activeIndex !== index) ? index : null
        }
    }
}
</script>

<style lang="less" scoped>
    .desktop {
        .st0{enable-background:new    ;}
        .st1{fill:#303949;}
        .st2{fill:#8D8D8D;}
        .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#F1F1F2;}
        .st4{fill:none;stroke:#000000;stroke-width:6;stroke-linecap:round;}
        .st5{fill:none;stroke:#8D8D8D;stroke-width:3;stroke-linecap:round;stroke-dasharray:20,15;}
        .st6{fill-rule:evenodd;clip-rule:evenodd;fill:#D8D8D8;}
        .st7{fill-rule:evenodd;clip-rule:evenodd;fill:#9BDBDA;}
    }
    @media screen and (min-width: 901px) {
        #smaller_device {
            display: none;
        }
    }
    @media screen and (max-width: 900px) {
        #desktop {
            display: none;
        }
    }

</style>
