var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "top" }, [
      _c("div", {
        staticClass: "divider-line",
        style: {
          width: (100 / _vm.steps.length) * (_vm.steps.length - 1) - 10 + "%"
        }
      }),
      _vm._v(" "),
      _vm.showWrapper
        ? _c(
            "div",
            { staticClass: "steps-wrapper" },
            [
              _vm.topButtons
                ? [
                    _vm.currentStep.index > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "stepper-button-top previous",
                            on: {
                              click: function($event) {
                                return _vm.backStep()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("keyboard_arrow_left")
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.steps, function(step, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      class: ["step", _vm.isStepActive(index, step)],
                      style: { width: 100 / _vm.steps.length + "%" }
                    },
                    [
                      _c("div", { staticClass: "circle" }, [
                        _c("i", { staticClass: "material-icons md-18" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(step.completed ? "done" : step.icon) +
                              "\n                        "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "step-title" }, [
                        _c("h4", [_vm._v(_vm._s(step.title))]),
                        _vm._v(" "),
                        _c("h5", { staticClass: "step-subtitle" }, [
                          _vm._v(_vm._s(step.subtitle))
                        ])
                      ])
                    ]
                  )
                ]
              }),
              _vm._v(" "),
              _vm.topButtons
                ? _c(
                    "div",
                    {
                      class: [
                        "stepper-button-top next",
                        !_vm.canContinue ? "deactivated" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.nextStep()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("keyboard_arrow_right")
                      ])
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "transition",
          {
            attrs: {
              "enter-active-class": _vm.enterAnimation,
              "leave-active-class": _vm.leaveAnimation,
              mode: "out-in"
            }
          },
          [
            _vm.keepAliveData
              ? _c(
                  "keep-alive",
                  [
                    _c(_vm.steps[_vm.currentStep.index].component, {
                      tag: "component",
                      attrs: {
                        "clicked-next": _vm.nextButton[_vm.currentStep.name],
                        "current-step": _vm.currentStep
                      },
                      on: {
                        "can-continue": _vm.proceed,
                        "active-next": _vm.activateNextStep,
                        "change-next": _vm.changeNextBtnValue
                      }
                    })
                  ],
                  1
                )
              : _c(_vm.steps[_vm.currentStep.index].component, {
                  tag: "component",
                  attrs: {
                    "clicked-next": _vm.nextButton[_vm.currentStep.name],
                    "current-step": _vm.currentStep
                  },
                  on: {
                    "can-continue": _vm.proceed,
                    "change-next": _vm.changeNextBtnValue,
                    "active-next": _vm.activateNextStep
                  }
                })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    !_vm.finalStep && _vm.SHOW_NAV
      ? _c(
          "div",
          {
            staticClass: "form-action",
            class: ["bottom", _vm.currentStep.index > 0 ? "" : "only-next"]
          },
          [
            _vm.currentStep.index > 0
              ? _c(
                  "button",
                  {
                    staticClass:
                      "button button--copy-solid-dark stepper-button previous",
                    on: {
                      click: function($event) {
                        return _vm.backStep()
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("translate")("back", _vm.locale)))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button--copy-solid-dark",
                class: ["stepper-button next"],
                on: {
                  click: function($event) {
                    return _vm.nextStepWithValid()
                  }
                }
              },
              [
                _vm._v("\n            Continue\n            "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        _vm.finalStep ? "finish" : "",
                        _vm.locale
                      )
                    )
                  )
                ])
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }