<template>
    <div>
        <div class="saved-prescriptions">
            <CustomSelect :disabled="loading" :value="selectedValue" :options="selectableList" class="select-container" @change="selectedValue = $event.target.value" />

            <div v-if="showSignInBlock" class="select-container div-sing-in">
                <span><span class="span-sing-in" @click="isModalOpen = !isModalOpen">Sign in</span> to use a saved Rx</span>
            </div>
        </div>

        <vue-modaltor
            v-if="showSignInBlock"
            :class="{'loading': loading}"
            :visible="isModalOpen"
            :default-width="'350px'"
            :bg-overlay="''"
            :bg-panel="''"
            :close-scroll="false"
            class="small sign-in"
            @hide="hideModal"
        >
            <BaseLoader v-if="loading" />

            <form v-if="!loading" @submit="signInCustomer">
                <h4>Sign In</h4>
                <ul class="form-list">
                    <li>
                        <input v-model="customerData.email" class="input-text" type="text" placeholder="Email Address" tabindex="1" />
                    </li>
                    <li>
                        <input v-model="customerData.password" class="input-text" type="password" placeholder="Password" tabindex="2" />
                    </li>
                </ul>
                <div v-if="showError">
                    <p class="error-text-rx">Please enter a valid email and password!</p>
                </div>
                <BaseButton type="submit" text="Sign In" tabindex="3" @click.native="signInCustomer()" />
            </form>
        </vue-modaltor>
    </div>
</template>

<script>
import _map from 'lodash/map'
import _find from 'lodash/find'
import {mapGetters, mapActions} from 'vuex'
import prescriptions from '../prescriptions/storage/prescriptions'
import BaseButton from '../base/BaseButton.vue'
import CustomSelect from '../base/Select.vue'
import BaseLoader from '../base/BaseLoader.vue'
import CommonPrescriptionModel from '../../app/common/prescription_model'
import {
    PRESCRIPTION_TYPE_ONLINE
} from '../../app/utils/constants'

export default {
    name: 'SavedPrescriptions',
    components: {
        CustomSelect,
        BaseButton,
        BaseLoader
    },
    extends: CommonPrescriptionModel,
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        saving: false,
        loading: false,
        isModalOpen: false,
        showSignInBlock: false,
        showError: false,
        selectedValue: null,
        prescriptions: [],
        customerData: {
            email: '',
            password: '',
            form_key: ''
        }
    }),
    computed: {
        ...mapGetters('configurator/steps', ['getStepObject']),
        ...mapGetters('prescriptions', ['prescriptionService']),
        step () {
            return this.getStepObject(this.id)
        },
        prescription () {
            return this.values.prescriptionValues
        },
        selectableList () {
            const list = []
            list.push({
                'value': null,
                'label': 'Select saved Rx...'
            })

            for (const prescription of this.prescriptions) {
                list.push({
                    'value': prescription.id,
                    'label': prescription.name
                })
            }
            return list
        },
        prescription_type () {
            return this.id
        }
    },
    watch: {
        prescriptions: {
            handler (prescriptions) {
                if (!prescriptions.length) {
                    let disabled = true
                    if (this.step.value === PRESCRIPTION_TYPE_ONLINE) {
                        disabled = this.validateOnlinePrescriptionData
                    }
                    this.setStepDisabled(disabled)
                }
            },
            deep: true
        },
        selectedValue (prescription_id) {
            this.changeSelect(prescription_id)
        }
    },
    created () {
        this.setStepDisabled(!this.prescriptions.length)
        this.loadPrescriptions()

        this.prescriptionService.isSingIn()
            .then(response => {
                const data = response.data || {}

                if (!data.is_customer_auth) {
                    this.showSignInBlock = true
                    this.customerData.form_key = data.formkey
                }
            })
            .catch(err => console.error(err))
    },
    beforeDestroy () {
        this.setStepDisabled(false)
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
        this.$store.dispatch('prescriptions/optionsValidate', true)
    },
    methods: {
        ...mapActions('values', ['setPrescription']),
        ...mapActions('configurator/steps', ['updateStep']),
        updateStepData (data = {}) {
            this.updateStep({
                id: this.id,
                data
            })
        },
        setStepDisabled (disabled) {
            this.updateStepData({disabled})
        },
        setStepLoading (loading) {
            this.loading = loading
            this.updateStepData({loading})
        },
        cachedList () {
            return this.prescriptionService.cachedList(this.prescription_type)
        },
        loadPrescriptions (data = null) {
            return new Promise((resolve, reject) => {
                this.setStepLoading(true)
                this.cachedList().then(list => {
                    _map(list.data, (item) => {
                        item.prescription_type = this.prescription_type
                    })
                    this.prescriptions = list.data
                    if (this.prescription.id) {
                        this.selectedValue = this.prescription.id
                    }
                    this.setStepLoading(false)
                    resolve(data)
                }).catch(error => {
                    console.error(error)
                    this.setStepLoading(false)
                    reject(error)
                })
            })
        },
        changeSelect (prescription_id) {
            if (this.loading) {
                return
            }
            this.setStepDisabled(false)

            this.setPrescription(_find(this.prescriptions, {'id': prescription_id}))
        },
        savePrescription () {
            this.saving = true
            this.$store.dispatch('values/savePrescription')
                .then(response => {
                    const data = response.data || {}
                    return this.loadPrescriptions(data)
                }).then(response => {
                    const data = response.data || {}
                    this.saving = false
                    this.selectedValue = data.id
                })
                .catch((err) => {
                    console.error(err)
                    this.saving = false
                })
        },
        signInCustomer () {
            if (this.customerData.email !== '') {
                this.setStepLoading(true)
                this.prescriptionService.customerSingIn(this.customerData)
                    .then(response => {
                        const data = response.data || {}
                        const errorResponse = data.error
                        if (errorResponse) {
                            this.showError = true
                        }
                        if (data === 1) {
                            this.showSignInBlock = false
                            this.isModalOpen = false
                            this.loadPrescriptions()
                        }
                    }).catch((err) => {
                        console.error(err)
                        this.showError = true
                    })
                    .finally(() => this.setStepLoading(false))
            } else {
                this.showError = true
            }
        },
        hideModal () {
            this.isModalOpen = false
        }
    }
}
</script>
