<template>
    <OakleySeo v-if="brand === 'oakley'" :brand="brand"/>
    <RayBanSeo v-else-if="brand === 'ray-ban'" :brand="brand"/>
    <KateSpadeSeo v-else-if="brand === 'katespade'" :brand="brand"/>
    <HugoBossSeo v-else-if="brand === 'hugoboss'" :brand="brand"/>
    <HobieSeo v-else-if="brand === 'hobieeyewear'" :brand="brand"/>
    <ShopFramesSeo v-else />
</template>

<script>
import ShopFramesSeo from './brands/ShopFramesSeo.vue'
import OakleySeo from './brands/OakleySeo.vue'
import RayBanSeo from './brands/RayBanSeo.vue'
import KateSpadeSeo from './brands/KateSpadeSeo.vue'
import HugoBossSeo from './brands/HugoBossSeo.vue'
import HobieSeo from './brands/HobieSeo.vue'

export default {
    name: 'FramesPageSeo',
    components: {
        ShopFramesSeo,
        OakleySeo,
        RayBanSeo,
        KateSpadeSeo,
        HugoBossSeo,
        HobieSeo
    },
    props: {
        brand: {
            type: [String],
            default: ''
        }
    },
}
</script>
