<template>
    <div class="not-edit">
        <div class="selector-prescription">
            To do!!!!
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'ParamsNotEdit',
    props: {
        help: {
            type: [Array, Object],
            default: () => {}
        }
    },
    computed: {
        ...mapGetters('values', ['prescriptionData']),
        value () {
            const {value} = this.prescriptionData
            return value
        },
        isProgressive () {
            const {prescription_type} = this.prescriptionData
            return prescription_type === 'progressive'
        }
    }
}
</script>
