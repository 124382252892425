<template>
    <vue-modaltor
        :visible="isModalOpen"
        :default-width="'100%'"
        :bg-overlay="''"
        :bg-panel="''"
        :close-scroll="false"
        class="redeem-modal"
        @hide="hideModal"
    >
        <div class="redeem-container">
            <h2>Redeeming your</h2>
            <h3 v-text="subtitle"></h3>
            <div v-if="Array.isArray(requirements) && requirements.length" class="requirements-container">
                <h4 v-text="instruction"></h4>
                <ol>
                    <li v-for="(requirement, index) in requirements" :key="index">
                        <div><span class="requirement-number">{{ index + 1 }}</span><p>{{ requirement }}</p></div>
                    </li>
                </ol>
            </div>
            <div v-else>
                <h4 v-text="instruction"></h4>
            </div>
            <BaseButton :loader="loader" type="submit" text="CONTINUE" tabindex="3" @click.native="continueTo" />
        </div>
    </vue-modaltor>
</template>

<script>
import BaseButton from '../../../components/base/BaseButton.vue'

export default {
    name: 'Redeem',
    components: {
        BaseButton
    },
    props: {
        subtitle: {
            type: String,
            default: '',
            required: true
        },
        instruction: {
            type: String,
            default: '',
            required: true
        },
        isModalOpen: {
            type: Boolean,
            default: false,
            required: true
        },
        requirements: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
        loader:false
    }),
    methods: {
        hideModal () {
            this.$emit('update', false)
        },
        continueTo () {
            this.loader = true
            this.$store.dispatch('lensablplus_customer/cleanCart').then(() => {
                this.$emit('continue')
                this.loader = false
            })
        }
    }
}
</script>

<style scoped>

</style>
