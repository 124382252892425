<template>
    <v-form ref="form" v-model="isValid" class="control-container input-container sub-doctor-details">
        <v-combobox
            :items="[]"
            :value="doctorDetails.firstName"
            :return-object="false"
            label="Doctor's First Name"
            item-text="doctor_first_name"
            outlined
            @input.native="updateDoctorData($event.target.value, 'firstName')"
        />
        <v-combobox
            :rules="[() => !!doctorDetails.lastName || 'Last Name Required']"
            :items="[]"
            :value="doctorDetails.lastName"
            :return-object="false"
            label="Doctor's Last Name*"
            item-text="doctor_last_name"
            outlined
            @input.native="updateDoctorData($event.target.value, 'lastName')"
        />
        <v-combobox
            :items="[]"
            :value="doctorDetails.city"
            :return-object="false"
            label="City"
            outlined
            @input.native="updateDoctorData($event.target.value, 'city')"
        />
        <v-select
            :label="doctorDetails.clinicState? `` : `State*`"
            :rules="[v => !!v || 'State Required']"
            :items="regions_data"
            :value="doctorDetails.clinicState"
            item-text="label"
            item-value="value"
            outlined
            @change="updateDoctorData($event, 'clinicState')"
        />
        <v-combobox
            :items="[]"
            :value="doctorDetails.clinic"
            :return-object="false"
            label="Clinic"
            outlined
            @input.native="updateDoctorData($event.target.value, 'clinic')"
        />
        <v-combobox
            :rules="[
                () => checkIfNumber && !!doctorDetails.clinicPhoneNumber || 'Enter 10 digit number',
                () => !!doctorDetails.clinicPhoneNumber || 'Clinic Number Required'
            ]"
            :items="[]"
            :value="doctorDetails.clinicPhoneNumber"
            :return-object="false"
            :maxlength="10"
            label="Clinic Phone Number (Exclude Dashes)*"
            outlined
            @input.native="updateDoctorData($event.target.value, 'clinicPhoneNumber')"
        />
        <p>*Required Fields</p>
    </v-form>
</template>

<script>
import _find from 'lodash/find'
import _map from 'lodash/map'
import {mapGetters, mapState, mapActions} from 'vuex'
import prescriptions from '../../prescriptions/storage/prescriptions'

import {
    PRESCRIPTION_TYPE_DOCTOR_DETAILS,
    MESSAGE_VALIDATE_DOCTOR_DETAILS
} from '../../../app/utils/constants'

export default {
    name: 'DoctorDetailsForm',
    props: {
        id: {
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        loggedIn: false,
        isValid: false
    }),
    computed: {
        ...mapState(['reset']),
        ...mapState('prescriptions', ['doctorDetails', 'previousDoctors']),
        ...mapGetters('values', ['values']),
        ...mapGetters('configurator/steps', ['getStepObject']),
        ...mapGetters('prescriptions', ['doctorInfo']),
        ...mapGetters('storeView', ['country_code', 'regions_data']),
        step () {
            return this.getStepObject(this.id)
        },
        doctorName () {
            return `${this.doctorDetails.firstName} ${this.doctorDetails.lastName}`
        },
        checkIfNumber () {
            return /^\d+$/.test(this.doctorDetails.clinicPhoneNumber) && this.doctorDetails.clinicPhoneNumber.length === 10
        }
    },
    watch: {
        doctorDetails: {
            handler (data) {
                _map(data, (value, key) => data[key] = window.DOMPurify.sanitize(value))
                this.$store.commit('values/setDataToValues', {
                    [PRESCRIPTION_TYPE_DOCTOR_DETAILS]: data
                })

                const selectedDoctor = _find(this.previousDoctors, doctor => doctor.doctor_first_name === data.firstName && doctor.doctor_last_name === data.lastName)
                const selectedDoctorId = selectedDoctor ? selectedDoctor.doctor_id : null
                this.$store.commit('prescriptions/UPDATE_SELECTED_DOCTOR_ID', selectedDoctorId)
            },
            deep: true
        },
        // doctorName (name) {
        //     this.$set(this.step, 'value', name)
        // },
        isValid (val) {
            this.updateStepData({disabled: !val})
        },
        reset () {
            this.$refs.form.reset()
            this.resetDoctorDetails()
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
    },
    created () {
        this.$bus.$on(MESSAGE_VALIDATE_DOCTOR_DETAILS, this._validateUpload)
        this.$store.dispatch('storeView/fetchCountryRegions', this.country_code)
    },
    beforeDestroy () {
        this.$bus.$off(MESSAGE_VALIDATE_DOCTOR_DETAILS, this._validateUpload)
    },
    methods: {
        ...mapActions('configurator/steps', ['updateStep']),
        ...mapActions('prescriptions', ['resetDoctorDetails', 'saveDoctor']),
        updateStepData (data = {}) {
            this.updateStep({id: this.id, data: data})
        },
        validate (resolve, reject) {
            this.isValid = this.$refs.form.validate()
            if (this.isValid) {
                this.saveDoctor()
                resolve()
            } else {
                reject()
            }
        },
        updateDoctorData (value, key) {
            const data = {}
            data[key] = value
            this.$store.commit('prescriptions/UPDATE_DOCTOR', data)
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },

        _validateUpload (promise, resolve, reject) {
            this.validate(resolve, reject)
        }
    }
}
</script>

<style scoped>

</style>
