<template>
    <div>
        <BusinessPlanChoice v-if="statusApi" />
    </div>
</template>

<script>
import App from '../../App.vue'
import BusinessPlanChoice from '../../components/lensablplus/ordertool/BusinessPlanChoice.vue'

export default {
    name: 'RouterLensablplusBusiness',
    components: {
        BusinessPlanChoice
    },
    extends: App
}
</script>

<style scoped>

</style>
