import _size from 'lodash/size'
export default () => ({
    namespaced: true,
    state: () => ({
        featuredProduct: []
    }),
    getters: {
        featuredProducts: state => state.featuredProduct
    },
    actions: {
        fetchFeaturedProducts: ({commit, rootGetters}) => {
            if (_size(rootGetters.rootState.featuredProduct)) {
                commit('FEATURED_PRODUCTS', rootGetters.rootState.featuredProduct)
            }
        }
    },
    mutations: {
        FEATURED_PRODUCTS: (state, products = []) => state.featuredProduct = products
    }
})
