var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "whyplus_page" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("section", { staticClass: "struct-parallax-bg vision-benefits" }, [
      _c("div", { staticClass: "vision-container" }, [
        _c("div", { staticClass: "vision-benefits-description" }, [
          _c("h3", [
            _vm._v("You're probably used to seeing something like this...")
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm.isMarketingPage
            ? _c("a", { attrs: { href: "/plus/vision_plan" } }, [_vm._m(2)])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._m(3)
      ])
    ]),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass:
          "custom-callout custom-callout__background plus-plan-benefits",
        staticStyle: {
          "background-image":
            "url('https://www.lensabl.com/media/wysiwyg/lensabl-background-snellentest.jpg')"
        }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "large-9 columns" }, [
            _c("div", { staticClass: "lensabl-plus-content" }, [
              _c("div", { staticClass: "plans-list-wrapper" }, [
                _c("div", { staticClass: "border-1" }),
                _vm._v(" "),
                _c("div", { staticClass: "border-2" }),
                _vm._v(" "),
                _c("div", { staticClass: "plans-list-container" }, [
                  _c(
                    "ul",
                    { staticClass: "plans-list" },
                    _vm._l(_vm.plansInfo, function(plan, index) {
                      return _c("li", { key: index, class: plan.id }, [
                        typeof plan.thumbnail === "string"
                          ? _c("div", { staticClass: "plan-info-container" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "thumb",
                                  on: {
                                    click: function($event) {
                                      return _vm.setActiveIndex(index)
                                    }
                                  }
                                },
                                [
                                  typeof plan.thumbnail === "string"
                                    ? _c("img", {
                                        attrs: { src: plan.thumbnail }
                                      })
                                    : _vm._l(plan.thumbnail, function(
                                        thumbnail,
                                        thumbIndex
                                      ) {
                                        return _c("img", {
                                          key: "thumbnail-" + thumbIndex,
                                          class: plan.id + "-" + thumbIndex,
                                          attrs: { src: thumbnail }
                                        })
                                      })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "content" }, [
                                _c("h3", {
                                  directives: [
                                    {
                                      name: "dompurify-html",
                                      rawName: "v-dompurify-html",
                                      value: plan.title,
                                      expression: "plan.title"
                                    }
                                  ],
                                  staticClass: "tile",
                                  class: {
                                    "has-description":
                                      plan.description ||
                                      plan.contactsInfo(
                                        _vm.eyeData.left,
                                        _vm.eyeData.right
                                      ),
                                    active: _vm.activeIndex === index
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setActiveIndex(index)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.activeIndex === index,
                                        expression: "activeIndex === index"
                                      }
                                    ]
                                  },
                                  [
                                    plan.description
                                      ? _c("div", {
                                          directives: [
                                            {
                                              name: "dompurify-html",
                                              rawName: "v-dompurify-html",
                                              value: plan.description,
                                              expression: "plan.description"
                                            }
                                          ],
                                          staticClass: "description"
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    plan.helpBubble
                                      ? _c("HelpBubble", {
                                          attrs: {
                                            data: plan.helpBubbleParams,
                                            "target-classes":
                                              plan.helpBubbleParams
                                                .targetClasses
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    plan.contactsInfo(
                                      _vm.eyeData.left,
                                      _vm.eyeData.right
                                    )
                                      ? _c("div", {
                                          directives: [
                                            {
                                              name: "dompurify-html",
                                              rawName: "v-dompurify-html",
                                              value: plan.contactsInfo(
                                                _vm.eyeData.left,
                                                _vm.eyeData.right
                                              ),
                                              expression:
                                                "plan.contactsInfo(eyeData.left, eyeData.right)"
                                            }
                                          ],
                                          staticClass: "description"
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ])
                          : _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "plan-info-container dual-options top-option"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "thumb",
                                      on: {
                                        click: function($event) {
                                          return _vm.setActiveIndex(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        class: plan.id + "-0}",
                                        attrs: { src: plan.thumbnail[0] }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "content" }, [
                                    _c("h3", {
                                      directives: [
                                        {
                                          name: "dompurify-html",
                                          rawName: "v-dompurify-html",
                                          value: plan.title[0],
                                          expression: "plan.title[0]"
                                        }
                                      ],
                                      staticClass: "tile",
                                      class: {
                                        "has-description": plan.description,
                                        active: _vm.activeIndex === index
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.setActiveIndex(index)
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.activeIndex === index,
                                            expression: "activeIndex === index"
                                          }
                                        ]
                                      },
                                      [
                                        plan.description[0] !== ""
                                          ? _c("div", {
                                              directives: [
                                                {
                                                  name: "dompurify-html",
                                                  rawName: "v-dompurify-html",
                                                  value: plan.description[0],
                                                  expression:
                                                    "plan.description[0]"
                                                }
                                              ],
                                              staticClass: "description"
                                            })
                                          : _c(
                                              "div",
                                              { staticClass: "description" },
                                              [
                                                _vm._v(
                                                  "\n                                                        See any licensed eye care provider you want for an eye exam or contact fitting. Simply upload a valid receipt and receive a $60 Lensabl credit.\n                                                        "
                                                ),
                                                plan.helpBubble
                                                  ? _c("HelpBubble", {
                                                      attrs: {
                                                        data:
                                                          plan.helpBubbleParams,
                                                        "target-classes":
                                                          plan.helpBubbleParams
                                                            .targetClasses
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                                                        When doctors don't need to chase reimbursements from the insurance company, they often give discounts.\n                                                    "
                                                )
                                              ],
                                              1
                                            )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(4, true),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "plan-info-container dual-options bottom-option"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "thumb",
                                      on: {
                                        click: function($event) {
                                          return _vm.setActiveIndex(index)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        class: plan.id + "-1}",
                                        attrs: { src: plan.thumbnail[1] }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "content" }, [
                                    _c("h3", {
                                      directives: [
                                        {
                                          name: "dompurify-html",
                                          rawName: "v-dompurify-html",
                                          value: plan.title[1],
                                          expression: "plan.title[1]"
                                        }
                                      ],
                                      staticClass: "tile second-option",
                                      class: {
                                        "has-description": plan.description,
                                        active: _vm.activeIndex === index
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.setActiveIndex(index)
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.activeIndex === index,
                                            expression: "activeIndex === index"
                                          }
                                        ]
                                      },
                                      [
                                        _c("div", {
                                          directives: [
                                            {
                                              name: "dompurify-html",
                                              rawName: "v-dompurify-html",
                                              value: plan.description[1],
                                              expression: "plan.description[1]"
                                            }
                                          ],
                                          staticClass: "description"
                                        })
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                      ])
                    }),
                    0
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(5)
        ])
      ]
    ),
    _vm._v(" "),
    _vm._m(6),
    _vm._v(" "),
    _vm._m(7)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "home__hero" }, [
      _c("div", { staticClass: "hero-video-button" }, [
        _c("div", { staticClass: "hero-why-container" }, [
          _c("div", { staticClass: "hero-video-button__row" }, [
            _c("div", { staticClass: "hero-video-button__copy" }, [
              _c("h1", [_vm._v("Lensabl +")]),
              _vm._v(" "),
              _c("h2", [_vm._v("Vision Benefits Made Simple")])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Very lengthy. Lots of caveats. Unclear what's actually covered "),
      _c("img", {
        attrs: {
          width: "25px",
          src: "/js/lensconfigurator/images/emoji-exploding-head.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-video-button__button-primary" }, [
      _c("div", { staticClass: "button button--copy-solid-dark" }, [
        _c("div", { staticClass: "button__container" }, [
          _vm._v("CHOOSE YOUR PLAN")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vision-benefits-chart" }, [
      _c("div", { staticClass: "vision-benefits-table" }, [
        _c("img", {
          attrs: {
            id: "desktop",
            src: "/js/lensconfigurator/assets/example-competition-table.png"
          }
        }),
        _vm._v(" "),
        _c("img", {
          attrs: {
            id: "smaller_device",
            src: "/js/lensconfigurator/assets/example-competition-table.png"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [_c("p", [_vm._v("-or-")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "large-9 columns" }, [
      _c("div", { staticClass: "plan-benefits-description" }, [
        _c("h3", [_vm._v("What You See Is What You Get")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Lensabl+ plans include only the things you need, so you’re not paying for things you don’t."
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "price" }, [
          _c("strong", [_vm._v("Plans as low as $7.99/month")])
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "/plus/vision_plan" } }, [
          _c("div", { staticClass: "hero-video-button__button-primary" }, [
            _c("div", { staticClass: "button button--copy-solid-dark" }, [
              _c("div", { staticClass: "button__container" }, [
                _vm._v("CHOOSE YOUR PLAN")
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "plus-plan-costs" }, [
      _c("div", { staticClass: "plus-plan-comparison" }, [
        _c("div", { staticClass: "price-table" }, [
          _c("h3", [_vm._v("No Math Degree Required")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Traditional vision plans make it hard to understand true out-of-pocket costs. With Lensabl+, you don’t have to calculate the amount you’ll actually end up paying, we’ll do it for you."
            )
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: "/plus/vision_plan" } }, [
            _c("div", { staticClass: "hero-video-button__button-primary" }, [
              _c("div", { staticClass: "button button--copy-solid-dark" }, [
                _c("div", { staticClass: "button__container" }, [
                  _vm._v("GET STARTED")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "plus-description" }, [
          _c("div", [
            _c("img", {
              staticClass: "plus-calculator",
              attrs: {
                src: "/js/lensconfigurator/images/calculator.svg",
                alt: "crossed out calculator"
              }
            })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "struct-parallax-bg" }, [
      _c("div", { staticClass: "vision-benefits-section" }, [
        _c("div", {}, [
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/images/plus-homepage/all-items.png"
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-vision-plan" }, [
          _c("h3", [_vm._v("Build a custom vision plan in under 2 minutes")]),
          _vm._v(" "),
          _c("a", { attrs: { href: "/plus/vision_plan" } }, [
            _c("div", { staticClass: "hero-video-button__button-primary" }, [
              _c("div", { staticClass: "button button--copy-solid-dark" }, [
                _c("div", { staticClass: "button__container" }, [
                  _vm._v("GET STARTED")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }