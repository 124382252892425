<template>
    <div v-show="isCurrent" :class="id">
        <h3 v-if="config.title" v-dompurify-html="config.title" class="title"></h3>

        <div id="company_address_fields" class="company-address">
            <div class="content">
                <div class="hor-scroll">
                    <form ref="business_payment">
                        <table cellspacing="0" class="form-list">
                            <tbody>
                                <tr>
                                    <td colspan="2">
                                        <div id="stripe-el">
                                            <div id="card-element"></div>
                                        </div>
                                        <div id="card-errors" role="alert"></div>
                                        <input type="hidden" name="payment[stripe_token]" id="stripe-token">
                                        <div v-if="paymentError" :id="`advice-required-entry-${paymentError}`" class="validation-advice">This is a required field.</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td :key="`row-${addressTemplateData[0].name}`" colspan="1">
                                        <label :for="`company-billing_address_${ addressTemplateData[0].name }`">
                                            {{ addressTemplateData[0].label }}:
                                        </label>
                                        <template>
                                            <input
                                                v-if="addressTemplateData[0].type === 'text'"
                                                :id="`company-billing_address_${ addressTemplateData[0].name }`"
                                                v-model="values[addressTemplateData[0].name]"
                                                :class="{'required-entry': addressTemplateData[0].required, 'validation-failed': errors[addressTemplateData[0].name]}"
                                                :data-recurly="addressTemplateData[0].name"
                                                class="input-text"
                                                type="text"
                                                @blur="validBlurField(addressTemplateData[0].name)"
                                            />
                                        </template>
                                        <div v-if="errors[addressTemplateData[0].name]" :id="`advice-required-entry-${addressTemplateData[0].name}`" class="validation-advice" style="">This is a required field.</div>
                                    </td>
                                    <td :key="`row-${addressTemplateData[1].name}`" colspan="1">
                                        <label :for="`company-billing_address_${ addressTemplateData[1].name }`">
                                            {{ addressTemplateData[1].label }}:
                                        </label>
                                        <template>
                                            <input
                                                v-if="addressTemplateData[1].type === 'text'"
                                                :id="`company-billing_address_${ addressTemplateData[1].name }`"
                                                v-model="values[addressTemplateData[1].name]"
                                                :class="{'required-entry': addressTemplateData[1].required, 'validation-failed': errors[addressTemplateData[1].name]}"
                                                :data-recurly="addressTemplateData[1].name"
                                                class="input-text"
                                                type="text"
                                                @blur="validBlurField(addressTemplateData[1].name)"
                                            />
                                        </template>
                                        <div v-if="errors[addressTemplateData[1].name]" :id="`advice-required-entry-${addressTemplateData[1].name}`" class="validation-advice" style="">This is a required field.</div>
                                    </td>
                                </tr>
                                <tr v-for="(template, index) in addressTemplateData[2].options" :key="`row-${template.label}`">
                                    <td colspan="2">
                                        <label :for="`membership-billing_address_${template.label}`">
                                            {{ template.label }}:
                                        </label>
                                        <input
                                            :id="`membership-billing_address_${template.label}`"
                                            v-model="values.street[index]"
                                            :class="{'required-entry': template.required, 'validation-failed': index === 0 && errors['street']}"
                                            :data-recurly="template.name"
                                            class="input-text"
                                            type="text"
                                            @blur="index === 0 ? validBlurField('street') : ''"
                                        />
                                        <div v-if="index === 0 && errors['street']" :id="`advice-required-entry-${template.label}`" class="validation-advice" style="">This is a required field.</div>
                                    </td>
                                </tr>
                                <tr>
                                    <template v-for="(template, index) in addressTemplateData">
                                        <td v-if="index > 2 && index < 5" :key="`row-${template.name}`">
                                            <label :for="`membership-billing_address_${template.name}`">
                                                {{ template.label }}:
                                            </label>
                                            <template>
                                                <input
                                                    v-if="template.type === 'text'"
                                                    :id="`membership-billing_address_${template.name}`"
                                                    v-model="values[template.name]"
                                                    :class="{'required-entry': template.required, 'validation-failed': errors[template.name]}"
                                                    :readonly="template.disabled"
                                                    :data-recurly="template.name"
                                                    class="input-text"
                                                    type="text"
                                                    @blur="validBlurField(template.name)"
                                                />
                                            </template>
                                            <div v-if="errors[template.name]" :id="`advice-required-entry-${template.name}`" class="validation-advice" style="">This is a required field.</div>
                                        </td>
                                    </template>
                                </tr>
                                <tr>
                                    <template v-for="(template, index) in addressTemplateData">
                                        <td v-if="index >= 5 && index < 7" :key="`row-${template.name}`">
                                            <label :for="`membership-billing_address_${template.name}`">
                                                {{ template.label }}:
                                            </label>

                                            <template v-if="template.options">
                                                <select
                                                    :id="`membership-billing_address_${template.name}`"
                                                    v-model="values.state"
                                                    :class="{'required-entry': template.required, 'validation-failed': errors[template.name]}"
                                                    :data-recurly="template.name"
                                                    class="select"
                                                    @change="validBlurField(template.name)"
                                                >
                                                    <template v-for="region in template.options">
                                                        <option :key="`${region.name}-option-${region.value}`" :value="region.value" v-text="region.label"></option>
                                                    </template>
                                                </select>
                                            </template>

                                            <template v-else>
                                                <input
                                                    v-if="template.type === 'text'"
                                                    :id="`membership-billing_address_${template.name}`"
                                                    v-model="values[template.name]"
                                                    :class="{'required-entry': template.required, 'validation-failed': errors[template.name]}"
                                                    :data-recurly="template.name"
                                                    class="input-text"
                                                    type="text"
                                                    @blur="validBlurField(template.name)"
                                                />
                                            </template>

                                            <div v-if="errors[template.name]" :id="`advice-required-entry-${template.name}`" class="validation-advice" style="">This is a required field.</div>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>

        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton
                    class="button--copy-solid-white-blue button--small"
                    text="Sign Up"
                    @click.native="continueNxt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseButton from '../../../base/BaseButton.vue'
import {validationMixin} from 'vuelidate'
import _map from 'lodash/map'
import {required, minLength} from 'vuelidate/lib/validators'

export default {
    name: 'DashboardPayment',
    components: {
        BaseButton
    },
    mixins: [validationMixin],
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        errors: {
            first_name: false,
            last_name: false,
            city: false,
            country: false,
            postal_code: false,
            state: false,
            street: false
        },
        values: {
            first_name: '',
            last_name: '',
            city: '',
            country: 'United States',
            postal_code: '',
            state: '',
            street: ['', '']
        },
        paymentError: false
    }),
    validations: {
        values: {
            first_name: {
                required
            },
            last_name: {
                required
            },
            street: {
                0: {
                    required
                }
            },
            city: {
                required
            },
            postal_code: {
                required,
                minLength: minLength(5)
            },
            state: {
                required
            },
            country: {
                required
            }
        }
    },
    computed: {
        ...mapGetters('lensablplus_business', ['stripePubKey','region_data', 'addressTemplateData', 'RECURLY_PUBLIC_KEY', 'cardToken']),
        ...mapGetters('lensablplus_customer', ['getObjectConfig', 'currentComponent']),
        config () {
            return this.getObjectConfig(this.id)
        },
        options () {
            return this.config.component.options
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        },
        addressInvalidFields () {
            const invalidFields = []
            _map(this.values, (val, key) => {
                if (this.$v.values.hasOwnProperty(key) && this.$v.values[key].$invalid) {
                    invalidFields.push(key)
                }
            })
            return invalidFields
        },
        addressIsValid () {
            return !this.addressInvalidFields.length
        }
    },
    mounted () {
        this.$store.dispatch('lensablplus_business/fetchCountryRegions', 'US')
        this.initiateStripe()
    },
    methods: {
        continueNxt () {
            // adds employee payment information
            if (this.addressIsValid) {
                this.submitBusinessStripe()
            } else {
                _map(this.values, (value, key) => {
                    this.validBlurField(key)
                })
            }
        },
        submitBusinessStripe: async function () {
            const billingAddress = {
                name: `${this.values['first_name']} ${this.values['last_name']}`,
                address_line1: this.values['street'][0],
                address_line2: this.values['street'][1],
                address_country: this.values['country'],
                address_city: this.values['city'],
                address_state: this.values['state'],
                address_zip: this.values['postal_code'],
            }
            const {token, error} = await window.loadedStipe.createToken(window.stripeCard, billingAddress)
            if (error) {
                console.error(error)
                this.paymentError = error.message
            }
            if (token) {
                this.$store.commit('lensablplus_business/CARD', token.id)
                this.$store.commit('lensablplus_business/LOAD', true)
                this.$store.commit('lensablplus_customer/COMPONENT_DATA', {id: this.id, value: this.values, isValidValue: true})
                this.$store.dispatch('lensablplus_customer/setEmployeePlan')
            }
        },
        submitBusinessRecurly() {
            const paymentForm = this.$refs.business_payment
            const payment = ['number', 'month', 'year', 'cvv']
            window.recurly.token(paymentForm, (err, token) => {
                if (err) {
                    console.log(err)
                    this.paymentError = err['fields'].some(r => payment.includes(r))
                } else {
                    this.$store.commit('lensablplus_business/CARD', token.id)
                    this.$store.commit('lensablplus_business/LOAD', true)
                    this.$store.commit('lensablplus_customer/COMPONENT_DATA', {id: this.id, value: this.values, isValidValue: true})
                    this.$store.dispatch('lensablplus_customer/setEmployeePlan')
                }
            })
        },
        validBlurField (val) {
            this.errors[val] = this.$v.values[val].$invalid
        },
        initiateRecurly () {
            window.recurly.configure({
                publicKey: this.RECURLY_PUBLIC_KEY,
                required: ['cvv']
            })
            window.cc_type = ''
            window.recurly.on('change', (state) => {
                window.cc_type = state.fields.card.brand
            })
        },
        initiateStripe() {
            window.loadedStipe = window.Stripe(this.stripePubKey, {locale: 'us'})
            const style = {
                base: {
                    fontSize: '16px',
                    color: '#000000',
                },
            };
            const elements = window.loadedStipe.elements();
            window.stripeCard = elements.create('card', {
                hidePostalCode: true,
                style: style
            });
            window.stripeCard.mount('#card-element');
        }
    }
}
</script>

<style scoped>

</style>
