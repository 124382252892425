import getLensOptionHelper, {
    LENS_OPTION_CODE_167,
    LENS_OPTION_CODE_174,
    LENS_OPTION_CODE_CR39, LENS_OPTION_CODE_GLASS,
    LENS_OPTION_CODE_POLY,
    LENS_OPTION_CODE_TRIVEX
} from '../lens_options'
import getHelper from "../helper";

export const framesTypeCustomize =  {
    'id': 'frames_customize',
    'label': 'Add Prescription or Customize Lenses',
    'summary_label': 'Customize lenses',
    'description': 'Available in Single Vision, Progressive, Bifocal, or Non-Prescription',
    'thumbnail': '',
    'component_help': {
        'name': 'help-bubble',
        'params': {
            'text': '<p>Available in Single Vision, Progressive, Bifocal, or Non-Prescription</p>'
        }
    }
}
export const framesTypeStep = {
    'id': 'frames_type',
    'label': 'Choose Lenses',
    'summary_label': 'Choose Lenses',
    'no_summary': true,
    'value_renderer': 'renderer-options',
    'default_value': 'frames_customize',
    'component': {
        'name': 'selector-options',
        'options': [
            framesTypeCustomize,
            {
                'id': 'frames_standard',
                'label': 'Purchase Frames With Lenses as Shown',
                'summary_label': 'Lenses as shown',
                'description': 'Non-prescription: Original lenses from manufacturer as pictured',
                'thumbnail': '',
                'component_help': {
                    'name': 'help-bubble',
                    'params': {
                        'text': '<p>Non-prescription: Original lenses from manufacturer as pictured</p>'
                    }
                }
            }
        ]
    }
}


export const defaultOptions = (params,  standardType = true) => {
    const {
        assets_url,
        sunglasses_sections,
        antireflective_price,
        upgrades_section,
        warranty_section,
        transitions_sections
    } = params
    const {help_components} = getHelper(params)

    function assetUrl(filename) {
        return assets_url + filename
    }

    const lensPower = {
        'id': 'lens_power',
        'label': 'Select Lens Power',
        'summary_label': 'Lens Power',
        'value_renderer': 'renderer-readable-prescription',
        'no_summary': true,
        'component': {
            'name': 'selector-prescription',
            'options': {
                'params_component': 'params-lens-power',
                'help_components': help_components
            }
        }
    }

    const planoLens = {
        'id': 'plano',
        'label': 'Clear Lenses',
        'summary_label': 'Clear Lenses',
        'description': 'Everyday clear lenses without a prescription.',
        'thumbnail': assetUrl('product-configuration-chooselenses-plano.jpg'),
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Everyday clear lenses without a prescription.</p> '
            }
        }
    }

    const blueLightLens = {
        'id': 'computer_lenses',
        'label': 'Blue-Light Blocking Lenses*',
        'summary_label': 'Blue-Light Lenses',
        'description': 'Blocks the blue light emitted by digital screens, providing comfort to your eyes and protection from harmful rays. Virtually clear with a nearly imperceptible yellow hue.',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Blocks the blue light emitted by digital screens, providing comfort to your eyes and protection from harmful rays. Virtually clear with a nearly imperceptible yellow hue.</p>'
            }
        },
        'checkout_value': 'Blue-Light Blocking',
        'thumbnail': assetUrl('product-configuration-chooselenses-computer.jpg')
    }

    const sunglassesLens = {
        'id': 'sunglasses_lenses',
        'label': 'Sunglass Lenses',
        'summary_label': 'Sunglass Lenses',
        'description': 'Tinted and Mirrored lenses available in a variety of colors.',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Tinted and Mirrored lenses available in a variety of colors.</p>'
            }
        },
        'component_extra_options': {
            'name': 'selector-color',
            'params': {
                'assets_url': assets_url,
                'sections': sunglasses_sections,
                'prefix': 'sunglasses'
            }
        },
        'thumbnail': assetUrl('product-configuration-lenstype-sunglasses.jpg')
    }
    const transitionLens = {
        'id': 'transitions_lenses',
        'label': 'Transitions® Light Intelligent Lenses™',
        'summary_label': 'Transitions® Light Intelligent Lenses™',
        'description': 'Transitions® Light Intelligent Lenses™ automatically adapt to changing light helping you to protect your eyes and see better. All Transitions lenses block 100% of UV light and help protect against harmful blue light.',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Transitions® Light Intelligent Lenses™ automatically adapt to changing light helping you to protect your eyes and see better. All Transitions lenses block 100% of UV light and help protect against harmful blue light.</p>'
            }
        },
        'component_extra_options': {
            'name': 'selector-color',
            'params': {
                'assets_url': assets_url,
                'sections': transitions_sections,
                'prefix': 'transitions'
            }
        },
        'thumbnail': assetUrl('product-configuration-lenstype-transitions.gif')
    }


    const computerLens = {
        'id': 'clear_lenses',
        'label': 'Clear Lenses',
        'summary_label': 'Clear Lenses',
        'description': 'Your everyday clear lenses.',
        'checkout_value': 'Clear',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Your everyday clear lenses.</p>'
            }
        },
        'thumbnail': assetUrl('product-configuration-lenstype-standardclear.jpg'),
        'redeemable': true
    }

    let prescriptionType, nonPrescriptionType;
    if (standardType) {
        prescriptionType = {
            'id': 'prescription',
            'label': 'Prescription Lenses',
            'summary_label': 'Prescription',
            'description': 'Available in clear, sunglass, Transitions<sup>®</sup> and blue light blocking lenses.',
            'thumbnail': assetUrl('product-configuration-chooselenses-prescription.jpg'),
            'redeemable': true,
            'component_help': {
                'name': 'help-bubble',
                'params': {
                    'text': '<p>Available in clear, sunglass, Transitions<sup>®</sup> and blue light blocking lenses.</p> '
                }
            }
        }

        nonPrescriptionType = {
            'id': 'nonprescription',
            'label': 'Non-Prescription',
            'summary_label': 'Non-Prescription',
            'description': 'Available in clear, sunglass, Transitions<sup>®</sup> and blue light blocking lenses. All lenses are polycarbonate.',
            'thumbnail': assetUrl('product-configuration-chooselenses-sunglasses.jpg'),
            'component_help': {
                'name': 'help-bubble',
                'params': {
                    'text': '<p>Available in clear, sunglass, Transitions<sup>®</sup> and blue light blocking lenses. All lenses are polycarbonate.</p> '
                }
            }
        }
    } else {
        prescriptionType = {
            'id': 'prescription',
            'label': 'Prescription Lenses',
            'summary_label': 'Prescription',
            'description': 'Available in Single Vision or Progressive',
            'thumbnail': assetUrl('product-configuration-chooselenses-prescription.jpg')
        }

        nonPrescriptionType = {
            'id': 'nonprescription',
            'label': 'Non-Prescription',
            'summary_label': 'Non-Prescription',
            'description': 'Available in clear, sunglass, Transitions<sup>®</sup> and blue light blocking lenses. All lenses are polycarbonate.',
            'thumbnail': assetUrl('product-configuration-chooselenses-sunglasses.jpg')
        }
    }

    const readingType = {
        'id': 'reading',
        'label': 'Reading Lenses',
        'summary_label': 'Reading',
        'description': 'Traditional over-the-counter reading lenses with magnification intended for concentrating on materials close-up.',
        'thumbnail': assetUrl('product-configuration-chooselenses-reading.jpg'),
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Traditional over-the-counter reading lenses with magnification intended for concentrating on materials close-up.</p> '
            }
        }
    }
    const antiRefOption = {
        'id': 'step_antireflective',
        'label': 'Select Upgrades',
        'partner_label': 'Anti-Reflective Coating',
        'summary_label': 'Select Upgrades',
        'summary_partner_label': 'Anti-Reflective Coating',
        'value_renderer': 'renderer-antireflective',
        'continue_label': 'Continue',
        'default_value': null,
        'component': {
            'name': 'selector-antireflective',
            'params': {
                'antireflective_price': antireflective_price,
                'upgrades_section': upgrades_section,
                'assets_url': assets_url
            }
        }
    }
    const warrantyOption = {
        'id': 'step_warranty',
        'label': 'Warranty',
        'summary_label': 'Warranty',
        'value_renderer': 'renderer-warranty',
        'continue_label': 'Continue',
        'default_value': null,
        'component': {
            'name': 'selector-warranty',
            'params': {
                'assets_url': assets_url,
                'warranty_section': warranty_section
            }
        }
    }
    const upgradesOption = {
        'id': 'step_upgrades',
        'label': 'Upgrades',
        'summary_label': 'Upgrades',
        'value_renderer': 'renderer-upgrades',
        'continue_label': 'Continue',
        'default_value': null,
        'component': {
            'name': 'selector-upgrades',
            'params': {
                'assets_url': assets_url,
                'upgrades_section': upgrades_section
            }
        }
    }

    const notesOption = {
        'id': 'notes',
        'label': 'Frame Information',
        'no_summary': true,
        'summary_label': 'Comments',
        'value_renderer': 'renderer-notes',
        'continue_label': 'Continue',
        'default_value': null,
        'component': {
            'name': 'selector-notes'
        }
    }
    const glassLensOption = {
        'id': LENS_OPTION_CODE_GLASS,
        'label': 'Glass Lenses',
        'summary_label': 'Glass Lenses',
        'checkout_value': 'Glass',
        'description': getLensOptionHelper(LENS_OPTION_CODE_GLASS, 'title'),
        'popular': false,
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': getLensOptionHelper(LENS_OPTION_CODE_GLASS, 'hint')
            }
        },
        'small_description': true
    }
    const hi167LensOption = {
        'id': LENS_OPTION_CODE_167,
        'label': '1.67 High-Index Lens',
        'summary_label': '1.67 High-Index Lens',
        'checkout_value': '167',
        'description': getLensOptionHelper(LENS_OPTION_CODE_167, 'title'),
        'popular': false,
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': getLensOptionHelper(LENS_OPTION_CODE_167, 'hint')
            }
        },
        'small_description': true
    }
    const hi174LensOption = {
        'id': LENS_OPTION_CODE_174,
        'label': '1.74 High-Index Lens',
        'summary_label': '1.74 High-Index',
        'checkout_value': '174',
        'description': getLensOptionHelper(LENS_OPTION_CODE_174, 'title'),
        'popular': false,
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': getLensOptionHelper(LENS_OPTION_CODE_174, 'hint')
            }
        },
        'small_description': true
    }
    const cr39LensOption = {
        'id': LENS_OPTION_CODE_CR39,
        'label': 'CR39 Lens',
        'summary_label': 'CR39',
        'checkout_value': 'CR39',
        'description': getLensOptionHelper(LENS_OPTION_CODE_CR39, 'title'),
        'popular': false,
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': getLensOptionHelper(LENS_OPTION_CODE_CR39, 'hint')
            }
        },
        'small_description': true
    }
    const polyLensOption = {
        'id': LENS_OPTION_CODE_POLY,
        'label': 'Polycarbonate Lens',
        'summary_label': 'Polycarbonate',
        'checkout_value': 'Poly',
        'description': 'Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.',
        'popular': true,
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Impact/Shatter resistant lenses for active lifestyles, children and safety. Best lenses for everyday use.</p>'
            }
        },
        'small_description': true
    }

    const trivexLensOptions = {
        'id': LENS_OPTION_CODE_TRIVEX,
        'label': 'Trivex Lenses',
        'summary_label': 'Trivex Lenses',
        'checkout_value': 'Trivex',
        'description': getLensOptionHelper('trivex', 'title'),
        'popular': false,
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Impact/Shatter resistant lenses that are lighter than polycarbonate, with greater optical clarity</p>'
            }
        },
        'small_description': true
    }


    const singleVisionStandardPrescription = [
        {
            'id': 'single_vision_distance',
            'label': 'For Distance',
            'summary_label': 'Single Vision for Distance',
            'description': 'Intended for distance correction. ',
            'block_prescription_id': 'single_vision',
            'child_block_prescription_id': 'single_vision_standard',
            'manufacturer': false,
            'component_help': {
                'name': 'help-bubble',
                'params': {
                    'text': '<p>Intended for distance correction. </p>'
                }
            }
        },
        {
            'id': 'single_vision_reading',
            'label': 'For Reading',
            'summary_label': 'Single Vision for Reading',
            'description': 'Intended for close-up vision correction. ',
            'block_prescription_id': 'single_vision',
            'child_block_prescription_id': 'single_vision_standard',
            'manufacturer': false,
            'component_help': {
                'name': 'help-bubble',
                'params': {
                    'text': '<p>Intended for close-up vision correction. </p>'
                }
            }
        },
    ]


    const singleVisionPremiumPrescription = [
        {
            'id': 'single_vision_distance_premium',
            'label': 'For Distance',
            'summary_label': 'Single Vision Premium for Distance',
            'description': 'Intended for distance correction. ',
            'block_prescription_id': 'single_vision',
            'child_block_prescription_id': 'single_vision_premium',
            'manufacturer': false,
            'component_help': {
                'name': 'help-bubble',
                'params': {
                    'text': '<p>Intended for distance correction. </p>'
                }
            }
        },
        {
            'id': 'single_vision_reading_premium',
            'label': 'For Reading',
            'summary_label': 'Single Vision Premium for Reading',
            'description': 'Intended for close-up vision correction. ',
            'block_prescription_id': 'single_vision',
            'child_block_prescription_id': 'single_vision_premium',
            'manufacturer': false,
            'component_help': {
                'name': 'help-bubble',
                'params': {
                    'text': '<p>Intended for close-up vision correction. </p>'
                }
            }
        },
    ]

    const progressiveStandard  = {
        'id': 'progressive_standard',
        'label': 'Standard Progressive',
        'summary_label': 'Standard Progressive',
        'block_prescription_id': 'progressive',
        'manufacturer': '(Made by Essilor)',
        'description': 'Our in-house free-form Digital HD lenses',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Our in-house free-form Digital HD lenses made by Essilor.</p>'
            }
        }
    }

    const progressivePremium = {
        'id': 'progressive_premium',
        'label': 'Premium Progressive',
        'summary_label': 'Premium Progressive',
        'block_prescription_id': 'progressive',
        'manufacturer': '(Shamir Autograph II+ lenses)',
        'description': 'Digital HD lenses with wider intermediate and reading corridors for more comfort',
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Digital HD lenses with wider intermediate and reading corridors for more comfort. Shamir Autograph II+ lenses.</p>'
            }
        }
    }

    const progressivePrescription = [
        progressiveStandard,
        progressivePremium
    ]

    const bifocalPrescription = [
        {
            'id': 'bi_focal',
            'label': 'Bifocal',
            'summary_label': 'Bifocal',
            'checkout_value': 'Bifocal',
            'manufacturer': false,
            'description': 'Traditional bifocal lens with near and far distance separated by a visible line.',
            'component_help': {
                'name': 'help-bubble',
                'params': {
                    'text': '<p>Traditional bifocal lens with near and far distance separated by a visible line.</p> '
                }
            }
        }
    ]

    const sunglassesNonPrescription = {
        'id': 'sunglasses',
        'label': 'Sunglass Lenses',
        'summary_label': 'Sunglasses',
        'description': 'Replacement lenses without a prescription',
        'thumbnail': assetUrl('product-configuration-chooselenses-sunglasses.jpg'),
        'component_extra_options': {
            'name': 'selector-color',
            'params': {
                'assets_url': assets_url,
                'sections': sunglasses_sections,
                'prefix': 'sunglasses'
            }
        },
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Replacement lenses without a prescription</p> '
            }
        }
    }

    const planoNonPrescription = {
        'id': 'plano',
        'label': 'Clear Lenses',
        'summary_label': 'Clear Lenses',
        'description': 'Everyday clear lenses without a prescription.',
        'thumbnail': assetUrl('product-configuration-chooselenses-plano.jpg'),
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Everyday clear lenses without a prescription.</p> '
            }
        }
    }

    const computerNonPrescription = {
        'id': 'computer',
        'label': 'Blue-Light Blocking Lenses',
        'summary_label': 'Blue-Light Lenses',
        'description': 'Reduce glare and eyestrain caused by digital screens and artificial light. Virtually clear with a nearly imperceptible yellow hue.',
        'thumbnail': assetUrl('product-configuration-chooselenses-computer.jpg'),
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Reduce glare and eyestrain caused by digital screens and artificial light. Virtually clear with a nearly imperceptible yellow hue.</p> '
            }
        }
    }

    const transitionNonPrescription =  {
        'id': 'transitions',
        'label': 'Transitions Lenses',
        'summary_label': 'Transitions',
        'description': 'Replacement lenses without a prescription',
        'thumbnail': assetUrl('product-configuration-lenstype-transitions.gif'),
        'component_extra_options': {
            'name': 'selector-color',
            'params': {
                'assets_url': assets_url,
                'sections': transitions_sections,
                'prefix': 'transitions'
            }
        },
        'component_help': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>Replacement lenses without a prescription</p> '
            }
        }
    }

    return {
        lensOptions: {
            polyLensOption,
            trivexLensOptions,
            cr39LensOption,
            hi174LensOption,
            hi167LensOption,
            glassLensOption
        },
        lensType: {
            blueLightLens,
            sunglassesLens,
            transitionLens,
            computerLens,
            planoLens
        },
        antiRefOption,
        upgradesOption,
        notesOption,
        prescriptionTypes: {
            singleVisionStandardPrescription,
            singleVisionPremiumPrescription,
            progressivePrescription,
            bifocalPrescription,
            progressiveStandard,
            progressivePremium
        },
        nonPrescriptionTypes: {
            sunglassesNonPrescription,
            computerNonPrescription,
            transitionNonPrescription,
            planoNonPrescription
        },
        chooseLens: {
            prescriptionType,
            readingType,
            nonPrescriptionType
        },
        warrantyOption,
        lensPower
    }

}
