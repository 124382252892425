<template>
    <LensablPlusCustomerAccount v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import LensablPlusCustomerAccount from '../../components/customer/LensablPlusCustomerAccount.vue'
export default {
    name: 'RouterLensablPlusCustomerAccount',
    components: {
        LensablPlusCustomerAccount
    },
    extends: App
}
</script>

<style scoped>

</style>
