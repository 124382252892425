<template>
    <div v-if="isStepCurrent">
        <SavedPrescriptions v-if="showSavedPrescriptions" :id="id" class="saved-prescriptions-container" />
        <div v-else class="step-description">
            Select Magnification.
        </div>
        <component :is="options.params_component" :id="id" :data="options" :class="[options.params_component + '-component']" class="enter-prescription-block" />
    </div>
</template>

<script>
import PreselectService from '../../../app/mixins/preselect-service'
import ParamsLensPower from '../../params/lens_power/ParamsLensPower.vue'
import ParamsPrescription from '../../params/prescription/ParamsPrescription.vue'
import SavedPrescriptions from '../../prescriptions/SavedPrescriptions.vue'

import {mapGetters} from 'vuex'

export default {
    name: 'SelectorPrescription',
    components: {
        ParamsLensPower,
        ParamsPrescription,
        SavedPrescriptions
    },
    mixins: [PreselectService],
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        data: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        loading: false,
        openDialog: false
    }),
    computed: {
        ...mapGetters('values', ['values', 'checkoutData']),
        ...mapGetters('configurator/steps', ['getStepObject']),
        step () {
            return this.getStepObject(this.id)
        },
        isStepCurrent () {
            return this.step.isCurrent
        },
        options () {
            return this.data ? this.data.params.options : this.step.component.options
        },
        prescription () {
            return this.values.prescriptionValues
        },
        isSaveDialogOpened () {
            return this.openDialog || (this.prescription && this.prescription.id)
        },
        showSavedPrescriptions () {
            const data = this.checkoutData
            if (!data) {
                return false
            }
            return ['reading_lens', 'computer_lens'].indexOf(data.product) === -1
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.setPreSelectRx(this.prescription)
        })
    }
}
</script>
