var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.load
    ? _c(
        "div",
        { staticClass: "loader-circle" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                id: "L4",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                x: "0px",
                y: "0px",
                viewBox: "0 0 100 100",
                "enable-background": "new 0 0 0 0",
                "xml:space": "preserve"
              }
            },
            [
              _c(
                "circle",
                {
                  attrs: {
                    fill: "#9cdbd9",
                    stroke: "none",
                    cx: "40",
                    cy: "40",
                    r: "20"
                  }
                },
                [
                  _c("animate", {
                    attrs: {
                      attributeName: "opacity",
                      dur: "1s",
                      values: "0;1;0",
                      repeatCount: "indefinite",
                      begin: "0.1"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                id: "L4",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                x: "0px",
                y: "0px",
                viewBox: "0 0 100 100",
                "enable-background": "new 0 0 0 0",
                "xml:space": "preserve"
              }
            },
            [
              _c(
                "circle",
                {
                  attrs: {
                    fill: "#9cdbd9",
                    stroke: "none",
                    cx: "40",
                    cy: "40",
                    r: "20"
                  }
                },
                [
                  _c("animate", {
                    attrs: {
                      attributeName: "opacity",
                      dur: "1s",
                      values: "0;1;0",
                      repeatCount: "indefinite",
                      begin: "0.1"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                id: "L4",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                x: "0px",
                y: "0px",
                viewBox: "0 0 100 100",
                "enable-background": "new 0 0 0 0",
                "xml:space": "preserve"
              }
            },
            [
              _c(
                "circle",
                {
                  attrs: {
                    fill: "#9cdbd9",
                    stroke: "none",
                    cx: "40",
                    cy: "40",
                    r: "20"
                  }
                },
                [
                  _c("animate", {
                    attrs: {
                      attributeName: "opacity",
                      dur: "1s",
                      values: "0;1;0",
                      repeatCount: "indefinite",
                      begin: "0.1"
                    }
                  })
                ]
              )
            ]
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }