import axios from 'axios'
import _size from 'lodash/size'

const CONTROLLER = 'lensablall/api_linneo'
const getDefaultState = () => {
    return {
        response_data: [],
        providers: []
    }
}

const state = getDefaultState()
const mutations = {
    resetState: (state) => Object.assign(state, getDefaultState()),
    SET_RESPONSE_DATA: (state, response_data = []) => state.response_data = response_data,
    FEATURED_PROVIDERS: (state, providers = []) => state.providers = providers

}
const actions = {
    resetInsuranceState: ({commit}) => {
        commit('resetState')
    },
    sendToLinneo: ({rootGetters, commit}, params = {}) => {
        const url = rootGetters['storeView/base_url'] + CONTROLLER
        return axios.post(url, params).then(({data, status}) => {
            if (status === 200) {
                commit('SET_RESPONSE_DATA', data)
                setTimeout(() => {
                    document.location = rootGetters['storeView/base_url'] + 'onepage'
                }, 3 * 1000)
            }
        }).catch(error => {
            const responseData = error.response
            const data = responseData.data
            commit('SET_RESPONSE_DATA', data)
        })
    },
    fetchProviders: ({commit, rootGetters}) => {
        if (_size(rootGetters.rootState.providers)) {
            commit('FEATURED_PROVIDERS', rootGetters.rootState.providers)
        }
    }
}
const getters = {
    getResponseData: state => state.response_data,
    getProviders: state => state.providers
}

export default store => {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    }
}
