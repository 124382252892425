<template>
    <div>
        <div :class="optionName === 'uploaded_pd' ? 'pd-label-parent' : ''">
            <RadioLabel v-if="!prescriptionData.status_4" :active="isActive" :label="title" :value="index" @click="$emit('checked', index)" />
            <span v-if="optionName === 'uploaded_pd'"><a href="/required-measurements" target="new">Instructions on how to take a picture</a></span>
        </div>
        <div
            v-show="isActive && prescriptionData.type === prescriptionData.isPD && (fittingbox_status === 'taken' || fittingbox_status === 'retaken')"
            class="fittingbox-warning-upload"
        >
            <br />We have your previous photo which we will process soon.
            <br />You can upload another if you like, which will override it.
        </div>

        <div v-if="isActive" class="upload-prescription">
            <div class="upload-component">
                <div v-if="hasNoFile" :class="{'invalid-form': showInvalidFormBorder}" class="file-input-box">
                    <input type="text" disabled="disabled" class="file-input" placeholder="No file chosen" />
                    <button type="button" title="Continue" class="action primary file-input-button">
                        <span>Browse...</span>
                        <input type="file" name="prescription" accept="image/*,application/pdf" @change="previewFiles" />
                    </button>
                </div>

                <div v-if="showError" class="error-hint">
                    <span>
                        The image you’ve attempted to upload is too large, please retry with a smaller file size. <br />
                        You can also contact <a href="mailto: sales@lensabl.com">sales@lensabl.com</a> with your photo and order number.
                    </span>
                </div>

                <div v-if="hasFile">
                    <div class="uploaded-file">
                        <span v-text="`✓ ${fileName}`"></span> <a href="javascript:void(0)" class="remove-link" @click="removeFile">Remove</a>
                    </div>
                </div>

                <div v-if="isUploading">
                    <div class="uploading-progress">Uploading...</div>
                </div>
            </div>
        </div>

        <Pdinrx v-show="isActive && !prescriptionData.isPD" :index="indexSave" :fittingbox_status="prescriptionData.fittingbox_status" />
    </div>
</template>
<script>
import axios from 'axios'
import _merge from 'lodash/merge'
import {mapState} from 'vuex'
import PrescriptionUpload from '../../../../../../components/prescription/upload/PrescriptionUpload.vue'
import SalesOrderViewPrescriptionRenderAbstract from './abstract'
import {
    PRESCRIPTION_TYPE_UPLOAD
} from '../../../../../../app/utils/constants'

const optionKeyHasPD = 'pd_in_rx'
const uploadURL = 'lensabl/upload'

export default {
    name: 'SalesOrderViewPrescriptionRenderUpload',
    extends: PrescriptionUpload,
    mixins: [SalesOrderViewPrescriptionRenderAbstract],
    props: {
        optionName: {
            type: [String],
            default: ''
        },
        title: {
            type: [Boolean, String],
            default: 'Upload a Photo'
        }
    },
    computed: {
        ...mapState('sales/order/', ['orderSaveError']),
        prescription () {
            return this.values.prescriptionValues[PRESCRIPTION_TYPE_UPLOAD]
        },
        fittingbox_status () {
            return this.item.prescriptions[this.prescriptionsType].fittingbox_status
        }
    },
    watch: {
        dataURL () {
            this.saveValueOptions()
        },
        orderSaveError (error) {
            this.isValidationFired = error
        },
        hasFile () {
            this.validateData()
        }
    },
    created () {
        if (this.item.upload && this.item.upload.data_url && this.item.upload.file_name) {
            this.updateDataUrl(this.item.upload.data_url)
            this.updateFileName(this.item.upload.file_name)
        }

        if (this.prescriptionData) {
            this.$set(this.item.prescriptions, this.prescriptionsType, _merge({
                type: this.prescriptionsType,
                rx_type: 'single_vision_distance',
                index: 0,
                prescription: {},
                status_4: false,
                fittingbox_status: false,
                isPD: false
            }, this.prescriptionData))
        }

        this.setDataToValues({
            prescriptionValues: {
                [PRESCRIPTION_TYPE_UPLOAD]: _merge(this.prescription, this.prescriptionData.prescription)
            }
        })

        this.validateData()
    },
    methods: {
        sendToServer (object) {
            this.isUploading = true

            axios.post(this.base_url + uploadURL, object, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    const {name} = response.data
                    this.updateDataUrl(object.data)
                    this.updateFileName(name)
                    this.isUploading = false
                })
                .catch((error) => {
                    this.showError = true
                    console.error(error)
                })
        },
        saveValueOptions () {
            const data = {}
            const index = this.indexSave
            data[this.optionName] = {'title': this.optionName, 'image_data': this.dataURL}
            this.setValueOptions({index, data})
            if (this.optionName.indexOf('rx') !== -1) {
                const dataHasPD = {}
                dataHasPD[optionKeyHasPD] = 0
                this.setValueOptions({
                    index,
                    data: dataHasPD
                })
            }
        },
        validateData () {
            if (this.isActive) {
                this.$store.commit('prescriptions/OPTIONS_ARE_VALID', {
                    left: this.hasFile,
                    right: this.hasFile
                })
            }
        }
    }
}
</script>


<style lang="less" scoped>
.pd-label-parent {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 580px) {
        flex-direction: row;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: column;
    }
    @media screen and (min-width: 1200px) {
        flex-direction: row;
    }

    span  {
        margin-top: -15px;
        margin-left: 33px;

        @media screen and (min-width: 580px) {
            margin-top: 0;
            margin-left: 10px;
        }

        @media screen and (min-width: 701px) {
            margin-top: 15px;
        }

        @media screen and (min-width: 1024px) and (max-width: 1199px) {
            margin-top: 3px;
            margin-left: 33px
        }

        a {
            text-decoration: underline;
        }
    }
}
</style>