<template>
    <div v-show="isCurrent">
        <h2 v-if="config.title" v-dompurify-html="config.title" class="title"></h2>

        <v-flex class="contacts-switcher" :class="{'two-col': !sameContactsInBothEyes}">
            <v-col>
                <SearchContacts :label="searchContactsRightLabel" @[event_contact_lenses_found]="setServiceMessageShown" @[event_add_contacts_to_eye]="addContactsRight" />
            </v-col>
            <v-col v-if="!sameContactsInBothEyes">
                <SearchContacts :label="searchContactsLeftLabel" @[event_contact_lenses_found]="setServiceMessageShown" @[event_add_contacts_to_eye]="addContactsLeft" />
            </v-col>
        </v-flex>

        <div v-if="serviceMessageShow" class="customer-service-container">
            <p>
                <span>Don't see your brand of contacts?</span>
                <a class="customer-service" href="/contact">Contact customer service</a>
            </p>

            <div class="actions-toolbar">
                <div class="primary">
                    <BaseButton
                        class="button--copy-solid-white-blue button--small"
                        text="Continue"
                        @click.native="goNext"
                    />
                </div>

                <p v-if="errorMessageShow" class="error-text-rx" v-text="config.errorMessage"></p>
            </div>
        </div>
    </div>
</template>

<script>
import {
    EVENT_ADD_CONTACTS_TO_EYE,
    EVENT_CONTACT_LENSES_FOUND
} from '../storage/lensablplus'
import BaseButton from '../../base/BaseButton.vue'
import SearchContacts from './SearchContacts.vue'
import {mapGetters} from 'vuex'
import _find from 'lodash/find'
import $ from 'jquery'

export default {
    name: 'SearchPrescribedContacts',
    components: {
        BaseButton,
        SearchContacts
    },
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        sameContactsInBothEyes: false,
        leftSku: '',
        rightSku: '',
        event_add_contacts_to_eye: EVENT_ADD_CONTACTS_TO_EYE,
        event_contact_lenses_found: EVENT_CONTACT_LENSES_FOUND,
        serviceMessageShow: false,
        errorMessageShow: false
    }),
    computed: {
        ...mapGetters('lensablPlus', ['getObjectConfig', 'currentComponent', 'products', 'productsData', 'checkoutData']),
        config () {
            return this.getObjectConfig(this.id) || {}
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        },
        eyeProducts () {
            return {
                left: _find(this.productsData, product => product.sku === this.leftSku),
                right: _find(this.productsData, product => product.sku === this.rightSku)
            }
        },
        searchContactsRightLabel () {
            let label = ''
            if (!this.sameContactsInBothEyes) {
                label = 'Right Eye'
            }
            return label
        },
        searchContactsLeftLabel () {
            let label = ''
            if (!this.sameContactsInBothEyes) {
                label = 'Left Eye'
            }
            return label
        }
    },
    watch: {
        eyeProducts: {
            handler (data) {
                if (data.left && data.right) {
                    this.errorMessageShow = false
                }
            },
            deep: true
        }
    },
    methods: {
        toTop () {
            const height = ($('#back-button-container').offset().top) - 200
            $('html, body').scrollTop(height)
        },
        addContactsLeft (product) {
            this.leftSku = product.sku
            this.toTop()
        },
        addContactsRight (product) {
            this.rightSku = product.sku
            if (this.sameContactsInBothEyes) {
                this.leftSku = product.sku
            }
            this.toTop()
        },
        addProduct (product) {
            const leftSku = String(this.leftSku || product.sku)
            const rightSku = String(this.rightSku || product.sku)
            this.$store.commit('lensablPlus/COMPONENT_DATA', {
                id: this.id,
                value: {
                    left: leftSku,
                    right: rightSku
                },
                isValidValue: true
            })
        },
        setServiceMessageShown (show) {
            this.serviceMessageShow = show
        },
        goNext () {
            this.errorMessageShow = !this.eyeProducts.left || !this.eyeProducts.right
            if (!this.errorMessageShow) {
                this.addProduct()
            }
        }
    }
}
</script>

<style lang="less" scoped>

</style>
