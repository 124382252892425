<template>
    <div v-if="isStepCurrent">
        <div class="step-description">Choose one from the options below.</div>
        <div class="options_container">
            <ul class="options">
                <template v-for="group in groupedOptionsRenderer">
                    <li :key="group.label" :class="{'active-option': isGroupActive(group)}" :for="group.label"
                        class="option" @click="changeLensType(group)">
                        <div :class="{active: isGroupActive(group)}" class="prescription-type-descriptions">
                            <span class="option-label">
                                <span class="label-text">{{ group.label }}</span>
                                <HelpBubble v-if="isMobile && group.id === 'progressive'" :data="component_help"
                                            :style="{ textTransform: 'none'}"/>
                            </span>
                            <div v-if="group.description" v-dompurify-html="group.description"
                                 class="option-description"></div>
                            <div :class="{active: isGroupActive(group)}" class="option-checkbox-container">
                                <div class="option-checkbox"></div>
                            </div>
                        </div>
                        <div v-if="isGroupActive(group)" class="chosen-container">
                            <div
                                v-if="groupedChildOptionsRenderer[group.id] &&
                                Object.keys(groupedChildOptionsRenderer[group.id]).length > 0">
                                <div class="prescription-type-descriptions transform-text-usual"
                                     v-for="(groupedChild, key) in groupedChildOptionsRenderer[group.id]">
                                    <ul>
                                        <Option :key="key"
                                                :option="groupedChild"
                                                :manufacturer-show="true"
                                                :component-help-show="false"
                                                class="prescription-type-option"
                                                @option-select="selectFirstChild"/>
                                    </ul>
                                    <div class="prescription-type-descriptions child transform-text-usual" v-if="isParentActive(groupedChild)">
                                        <ul v-for="option in groupedChild.options">
                                            <Option :key="option.id"
                                                    :option="option"
                                                    :manufacturer-show="true"
                                                    :component-help-show="false"
                                                    class="prescription-type-option"
                                                    @option-select="optionSelect"/>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <ul>
                                    <template v-for="option in group.options">
                                        <Option :key="option.id" :option="option" :manufacturer-show="true"
                                                :component-help-show="false" class="prescription-type-option"
                                                @option-select="optionSelect"/>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div
                            v-if="subtextShow(group) && isGroupActive(group) && (group.id === 'single_vision' || group.id === 'progressive') && values.frames_name ==='rheos'"
                            class="single-vision-subtext">
                            <span :style="{ fontWeight: 'bold'}">Disclaimer: Lensabl cannot guarantee that any prescription with a power greater than +/-4.00 will float in water. Please contact <a
                                href="mailto:support@lensabl.com">support@lensabl.com</a> with any questions.</span>
                        </div>
                        <!-- WILLOWS -->
                        <div
                            v-if="subtextShow(group) && isGroupActive(group) && (group.id === 'single_vision' || group.id === 'progressive' || group.id === 'progressive') && values.frames_name ==='willows'"
                            class="single-vision-subtext">
                            <span :style="{ fontWeight: 'bold'}">Disclaimer: This frame can only accommodate prescriptions with up to {{ willowsSubText }} SPH. Lensabl will not be able to fulfill orders with stronger prescriptions. Please contact <a
                                href="mailto:support@lensabl.com">support@lensabl.com</a> with any questions.</span>
                        </div>
                        <!-- END WILLOWS -->
                        <div
                            v-if="subtextShow(group) && isGroupActive(group)  && values.frames_name ==='James Oro Frames - Caviar'"
                            class="single-vision-subtext">
                            <span :style="{ fontWeight: 'bold'}">Disclaimer: This frame cannot accommodate any prescription with a power greater than +/- 4.00 SPH. Please contact <a
                                href="mailto:support@lensabl.com">support@lensabl.com</a> with any questions.</span>
                        </div>
                        <div
                            v-if="subtextShow(group) && isGroupActive(group)  && values.frames_name ==='James Oro Frames - Amour'"
                            class="single-vision-subtext">
                            <span :style="{ fontWeight: 'bold'}">Disclaimer: This frame cannot accommodate any prescription with a power greater than +/- 5.00 SPH. Please contact <a
                                href="mailto:support@lensabl.com">support@lensabl.com</a> with any questions.</span>
                        </div>
                        <div v-if="subtextShow(group) && isGroupActive(group) && group.id === 'single_vision'" class="single-vision-subtext">
                            For intermediate and computer lenses, please click <a class="single-vision-link" href="javascript:void(0)" @click="contactUsModalShow = true">here</a> or
                            <a class="single-vision-link" href="/contact">contact us</a>

                            <vue-modaltor
                                :visible="contactUsModalShow"
                                :default-width="'50%'"
                                :bg-overlay="''"
                                :bg-panel="''"
                                :close-scroll="false"
                                :animation-panel="'modal-slide-top'"
                                class="small"
                                @hide="contactUsModalHide"
                            >
                                <div>
                                    If you are looking to use these lenses for <b>intermediate/computer distance</b>, please make sure you have a specific prescription for this. This prescription is <b>different from a single vision/multifocal</b> prescription as it is tailored for viewing a computer screen anywhere from 20-40 inches away (your doctor should be able to provide this to you). <br/><br/>Once you confirm you have the correct prescription, please select single vision for distance. <br/><br/><b>Once you get to step 6- Enter RX: Please either upload a photo copy of your intermediate distance RX or you can manually enter it into the RX grid.</b>
                                </div>
                            </vue-modaltor>
                        </div>
                    </li>
                </template>
            </ul>
            <ul class="options">
                <template v-for="option in options">
                    <Option :key="option.id" :option="option" class="step-configuration-content"
                            @option-select="optionSelect"/>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _findIndex from 'lodash/findIndex'
import {
    LENS_TYPE_DESCRIPTION,
    CHILD_BLOCK_DESCRIPTION
} from '../../../../app/common/constants/lens_type'

import SelectorOptions from '../../options/SelectorOptions.vue'
import Option from '../../options/Option.vue'
import {mapGetters} from 'vuex'

import HelpBubble from '../../../help_bubble/HelpBubble.vue'

export default {
    name: 'SelectorPrescriptionType',
    components: {
        Option,
        HelpBubble
    },
    extends: SelectorOptions,
    data: () => ({
        blockIds: [],
        chosenLensType: false,
        groupedOptionsRenderer: [],
        groupedChildOptionsRenderer: [],
        contactUsModalShow: false,
        component_help: {
            name: 'help-bubble',
            params: {
                text: '<p>Multi-focal lenses with no visible line that give a seamless progression for distance, intermediate, and near vision correction.</p>'
            }
        }
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        willowsSubText() {
            const currentSku = this.values.product_sku

            if (currentSku === 'hearts-of-yang' || currentSku === 'hearts-of-yin') {
                return '+2.00/-4.00'
            } else if (currentSku === 'peace-&-love') {
                return '+2.50/-4.00'
            } else if (currentSku === 'make-it-blue') {
                return '+4.50/-4.50'
            } else if (currentSku === 'mav') {
                return '+3.00/-3.00'
            } else {
                return '+3.50/-3.50'
            }
        }
    },
    watch: {
        value(value) {
            this.setChoseBlock(value)
        },
        isStepCurrent(current) {
            if (current) {
                this.groupedOptionsUpdate()
                if (!this.chosenLensType) {
                    const currentGroup = _find(this.groupedOptionsRenderer, group => {
                        return _find(group.options, option => option.id === this.step.value)
                    })
                    if (currentGroup) {
                        this.chosenLensType = currentGroup.id
                    }
                }
            }
        }
    },
    created() {
        this.groupedOptionsUpdate()
        this.groupedChildOptionsUpdate()
    },
    methods: {
        isParentActive(option) {
            if (!this.chosenLensType) return false
            if (!this.groupedChildOptionsRenderer[this.chosenLensType]) return false
            const groupedChildOptions = Object.values(this.groupedChildOptionsRenderer[this.chosenLensType])
            let currentOption;
            for (const groupedChildOption of groupedChildOptions) {
                currentOption = _find(groupedChildOption.options, {id: this.step.value})
                if (currentOption) break
            }
            if (!currentOption) return false
            return option.id === currentOption.child_block_prescription_id
        },
        groupedChildOptionsUpdate() {
            if (this.groupedOptionsRenderer.length > 0) {
                for (const groupedOption of this.groupedOptionsRenderer) {
                    const options = groupedOption.options || []
                    this.groupedChildOptionsRenderer[groupedOption.id] = {}
                    options.forEach(option => {
                        if (option.child_block_prescription_id) {
                            if (!this.groupedChildOptionsRenderer[groupedOption.id][option.child_block_prescription_id]) {
                                this.groupedChildOptionsRenderer[groupedOption.id][option.child_block_prescription_id] = {
                                    ...CHILD_BLOCK_DESCRIPTION[option.child_block_prescription_id],
                                    options: []
                                }
                            }
                            this.groupedChildOptionsRenderer[groupedOption.id][option.child_block_prescription_id]['options'].push(option)
                        }
                    })
                }
            }
        },
        groupedOptionsUpdate() {
            const groupedOptions = []
            const blockIds = []
            this.options = _filter(this.options || [], option => {
                if (option.block_prescription_id) {
                    const id = option.block_prescription_id
                    blockIds.push(id)
                    const index = _findIndex(groupedOptions, {'id': id})
                    if (index === -1) {
                        const label = id.split('_').join(' ')
                        const description = LENS_TYPE_DESCRIPTION[id] || false
                        groupedOptions.push({
                            'label': label,
                            'description': description,
                            'id': id,
                            'options': [option]
                        })
                    } else {
                        groupedOptions[index].options.push(option)
                    }
                } else {
                    return option
                }
            })
            this.blockIds = blockIds
            this.groupedOptionsRenderer = groupedOptions
        },
        isGroupActive(group) {
            return this.chosenLensType === group.id
        },
        setChoseBlock(data) {
            this.blockIds.map(item => {
                if (data.indexOf(item) !== -1) {
                    this.chosenLensType = item
                }
            })
        },
        changeLensType(data) {
            const choseLensType = this.chosenLensType
            this.chosenLensType = data.id
            if (!choseLensType || choseLensType !== data.id) {
                if (data.options[0]) {
                    this.optionSelect(data.options[0])
                }
            }
        },
        optionSelect(option) {
            const value = option.id || option
            if (this.step.value !== value) {
                this.value = value
                this.chosenLensType = option.block_prescription_id || false
            }
        },
        selectFirstChild(option){
            const options = option.options || []
            if (options.length){
                this.optionSelect(options[0])
            }
        },
        subtextShow(group) {
            const key = group.id + '_subtext'
            const currentOption = _find(group.options, option => option.id === this.step.value)
            if (currentOption && currentOption.hasOwnProperty(key)) {
                return currentOption[key]
            }
            return true
        },
        contactUsModalHide() {
            this.contactUsModalShow = false
        }
    }
}
</script>
