<template>
    <div v-if="showForm && prescriptionsType" class="my-prescription-form">
        <div class="my-prescription-form-wrapper">
            <form :id="`data_order_form${index}`" :name="`data-order-form${index}`">
                <component
                    :is="`${prescriptionsType}-options`"
                    :item="orderItem"
                    :checked="checked"
                    :prescriptions-type="prescriptionsType"
                    :prescription-index="prescriptionIndex"
                    :class="[`${prescriptionsType}-list`]"
                    @hide-form="hideForm"
                    @checked="selectOption"
                    @open-fitting-box="openFittingBox"
                />

                <div class="actions-toolbar">
                    <div v-if="orderSaveError" class="primary">
                        <p class="error-text-rx">Please choose options!</p>
                    </div>
                    <div class="secondary">
                        <BaseButton class="button--copy-solid-white button--copy-border-sm" text="Save" data-size="xs" @click.native="save" />
                    </div>
                </div>
            </form>
        </div>
        <div v-if="orderIsSentToTheServer" class="loader-container">
            <div class="loader-el"></div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import salesStorage from '../storage/modules/sales'
import prescriptions from '../../../prescriptions/storage/prescriptions'
import BaseButton from '../../../base/BaseButton.vue'
import CustomSelect from '../../../base/Select.vue'
import RxOptions from './prescription/RxOptions.vue'
import PdOptions from './prescription/PdOptions.vue'
import OchOptions from './prescription/OchOptions.vue'
import ShOptions from './prescription/ShOptions.vue'

export default {
    name: 'SalesOrderViewPrescription',
    components: {
        BaseButton,
        CustomSelect,
        RxOptions,
        PdOptions,
        OchOptions,
        ShOptions
    },
    props: {
        orderId: {
            type: [Number, String],
            default: ''
        },
        itemId: {
            type: [Number, String],
            default: ''
        },
        prescriptionsType: {
            type: String,
            default: ''
        },
        prescriptionIndex: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        showForm: true,
        checked: '',
        fittingBoxStatus : null
    }),
    computed: {
        ...mapState('prescriptions', ['optionsValidate', 'optionsAreValid']),
        ...mapState('sales/order', ['orderSaveError', 'orderIsSentToTheServer']),
        ...mapGetters('sales/order/items', ['getItem', 'getItemPrescription']),
        index () {
            return `${this.orderId}_${this.itemId}_${this.prescriptionsType}${this.prescriptionIndex}`
        },
        orderItem () {
            return this.getItem(this.itemId)
        },
        itemPrescription () {
            return this.getItemPrescription({item_id: this.itemId, prescriptions_type: this.prescriptionsType})
        },
        fittingbox_status () {
            if (this.fittingBoxStatus){
                return this.fittingBoxStatus
            }
            return this.orderItem.prescriptions[this.prescriptionsType].fittingbox_status
        },
        prescriptionOptionsAreValid () {
            return this.optionsAreValid.left && this.optionsAreValid.right
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['sales/']) {
            this.$store.registerModule('sales', salesStorage(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }

        this.$store.dispatch('values/fetchDefaultData')
        this.$store.dispatch('sales/order/items/fetchDefaultData')
    },
    created () {
        this.$nextTick(() => {
            this.checked = `enter_online${this.index}`

            if (this.prescriptionsType === 'sh') {
                this.checked = `enter_segmentation_height${this.index}`
            } else if (this.prescriptionsType === 'och') {
                this.checked = `enter_ocular_center_height${this.index}`
            } else {
                this.checked = `enter_it_online${this.index}`
            }
        })
    },
    beforeDestroy () {
        this.$store.commit('prescriptions/OPTIONS_VALIDATE', false)
    },
    methods: {
        ...mapActions('prescriptions', ['saveOptions']),
        save () {
            this.$store.commit('sales/order/SENDING_TO_THE_SERVER', true)
            this.$store.commit('sales/order/SAVE_ERROR_FLAG', false)

            this.$store.dispatch('prescriptions/optionsValidate', true).then(() => {
                if (this.prescriptionOptionsAreValid) {
                    this.saveOptions({
                        index: this.index,
                        data: {
                            'item_id': this.itemId,
                            'order_id': this.orderId
                        }
                    })
                        .then(({status, request}) => {
                            if (status === 200) {
                                location.reload()
                            }
                        })
                        .catch(error => {
                            this.$store.commit('sales/order/SENDING_TO_THE_SERVER', false)
                            this.$store.commit('sales/order/SAVE_ERROR_FLAG', true)
                            console.error(error)
                        })
                } else {
                    this.$store.commit('sales/order/SENDING_TO_THE_SERVER', false)
                    this.$store.commit('sales/order/SAVE_ERROR_FLAG', true)
                }
            })
        },
        hideForm (show) {
            this.showForm = !show
        },
        selectOption (index) {
            this.checked = index
        },
        openFittingBox (item_id) {
            const openFittingBox = window.openFittingBox
            this.prepareForCompletion()

            if (typeof openFittingBox === 'function') {
                openFittingBox(item_id)
            }
        },
        prepareForCompletion () {
            window.addEventListener('message', function finalizeCallback (event) {
                if (event.origin.indexOf('fitmetrix') > -1 && event.data === 'finalized') {
                    if (this.fittingBoxStatus === 'taken') {
                        this.fittingBoxStatus = 'retaken'
                    } else {
                        this.fittingBoxStatus = 'taken'
                    }
                    window.removeEventListener('message', finalizeCallback)
                }
            }.bind(this))
        }
    }
}
</script>
