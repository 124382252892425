import { render, staticRenderFns } from "./RouterLensblplusContacts.vue?vue&type=template&id=43d5e1de&scoped=true&"
import script from "./RouterLensblplusContacts.vue?vue&type=script&lang=js&"
export * from "./RouterLensblplusContacts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43d5e1de",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/tkim/projects/lensabl/lensconfigurator/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43d5e1de')) {
      api.createRecord('43d5e1de', component.options)
    } else {
      api.reload('43d5e1de', component.options)
    }
    module.hot.accept("./RouterLensblplusContacts.vue?vue&type=template&id=43d5e1de&scoped=true&", function () {
      api.rerender('43d5e1de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/routes/lensablplus/RouterLensblplusContacts.vue"
export default component.exports