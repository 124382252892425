<template>
    <div>
        <div v-show="productView.productInfoShow" ref="spectacles-wrapper" class="product-configurator-configuration product-details-holder row spectacles-wrapper">
            <div class="product-name negative-bottom">
                <h1 v-dompurify-html="product_name" :class="product_name" class="spectacles snapchat" itemprop="name"></h1>
            </div>

            <Gallery />
            <div v-if="productView.colors.length > 1" class="spectacles-radio">
                <div class="step-configuration-header status-current clearfix">
                    <div class="step-title" style="margin-left: 0; position: relative; text-align: left; margin-top: 0; font-size: 1rem; line-height: 1.25rem; left: -4px;">
                        Change Lens Color
                    </div>
                </div>
                <ul id="colors-ul">
                    <li v-for="(color, index) in productView.colors" :key="`color-${color.color}-${index}`">
                        <label>
                            <input :id="color.color" :key="color.color" :value="index" :checked="index === checkedIndex" name="color" type="radio" @change="changeCurrentColor(index)" />
                            <span v-dompurify-html="!valueAntiReflective ? color.color : color.color + ' Polarized'" :class="color.color.toLowerCase()"></span>
                        </label>
                    </li>
                </ul>
                <div class="spectacles-polarized" @click="toggleAntiReflective()">
                    <input :checked="valueAntiReflective" name="polorized" type="checkbox" /><span>Add Polarized</span>
                </div>
            </div>

            <div v-if="category.indexOf('Original') === -1 && category.indexOf('Spectacles 3') === -1" class="spectacles-poarized" @click="toggleAntiReflective()">
                <p>Spectacles 2 {{ colorName }} are designed to have polarized lenses.</p>
                <input :checked="valueAntiReflective" name="polorized" type="checkbox" /><span>Add Polarized (Recommended)</span>
            </div>

            <div v-if="category === 'Spectacles 3'" class="spectacles-poarized" @click="toggleAntiReflective()"></div>

            <div v-if="isMobile && !isDesktop" class="product-options-bottom look-params">
                <div class="actions">
                    <BaseButton :text="'Select lenses'" @click.native="mobileSwitcher()">
                        <span v-text="isMobile? 'Select' : 'Select lenses'"></span>
                    </BaseButton>
                </div>
            </div>
        </div>
        <p v-if="!isAvailable" class="unavailable stock" title="Availability">
            <span style="color:red">This item is sold out</span>
        </p>
        <br />
        <AnagramAdBar />
        <div>
            <div class="product-configurator-progress spectacles-configurator">
                <Configurator v-show="productView.productConfiguratorShow" />
            </div>
        </div>
        <div v-if="!showApp" class="loader-container">
            <div class="loader-el"></div>
            <div class="spectacles-terms"></div>
        </div>
    </div>
</template>

<script>
import {EVENT_CONFIGURATOR_RESET} from '../../../app/utils/constants'
import getSpectaclesService from '../../../app/utils/spectacles'
import product from './storage/product/view'
import steps from '../../configurator/storage/configurator/steps'
import $ from 'jquery'
import {mapState, mapGetters, mapMutations} from 'vuex'
import Configurator from '../../configurator/Configurator.vue'
import Gallery from './view/Gallery.vue'
import BaseButton from '../../base/BaseButton.vue'
import AnagramAdBar from '../../anagram/AnagramAdBar.vue'

export default {
    name: 'CatalogProductSpectaclesView',
    components: {
        Gallery,
        Configurator,
        BaseButton,
        AnagramAdBar
    },
    data: () => ({
        configApp: '',
        showApp: false,
        colors: {},
        valueAntiReflective: false
    }),
    computed: {
        ...mapState('values', ['values']),
        ...mapGetters('steps', ['chain', 'visibleList']),
        ...mapState('productView', ['productView', 'checkedIndex']),
        ...mapGetters('values', ['checkoutData']),
        ...mapGetters('productView', ['productView', 'product_name', 'frames_name', 'frame_price', 'category', 'product_description', 'product_id', 'default_antireflective', 'colorItem', 'colorName', 'getCurrentProductAttribute', 'productInfoShow']),
        ...mapGetters('storeView', ['assets_url']),
        ...mapGetters('configurator', ['product_config']),
        ...mapGetters('deviceProperty', ['isMobile']),
        checkedIndex () {
            return this.productView.checkedIndex
        },
        isAvailable () {
            return this.colorItem ? this.colorItem.qty : 0
        },
        additionalAttributes () {
            return [
                {
                    label: 'Size',
                    value: this.getCurrentProductAttribute('size')
                },
                {
                    label: 'Material',
                    value: this.getCurrentProductAttribute('material')
                },
                {
                    label: 'Color',
                    value: this.colorName.toLowerCase()
                }
            ]
        }
    },
    watch: {
        colorItem (item) {
            this.setValuesService(item)
        },
        isMobile (mobile) {
            this.setProductInfoShow(true)
            this.setProductConfiguratorShow(!mobile)
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['productView/']) {
            this.$store.registerModule('productView', product(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['steps/']) {
            this.$store.registerModule('steps', steps(this.$store))
        }

        this.$store.dispatch('productView/fetchDefaultData')
    },
    created () {
        this.valueAntiReflective = this.default_antireflective
        this.$bus.$on(EVENT_CONFIGURATOR_RESET, () => {
            if (this.isMobile) {
                this.setProductInfoShow(true)
                this.setProductConfiguratorShow(!this.isMobile)
                window.scrollTo(0, 0)
            }
        })

        this.setProductConfiguratorShow(!this.isMobile)
        this.fetchSpectacles()
    },
    mounted () {
        this.$store.commit('values/setDataToValues', {
            anti_reflective: this.default_antireflective
        })
    },
    methods: {
        ...mapMutations('productView', ['setCheckedIndex', 'setProductInfoShow', 'setProductConfiguratorShow']),
        ...mapMutations('values', ['setDataToValues']),
        fetchSpectacles: async function () {
            await getSpectaclesService()
                .getColors(this.product_id)
                .then(res => {
                    if (res.data) {
                        this.$store.commit('productView/UPDATE_STATE_DATA', {
                            colors: res.data.data
                        })
                    }
                }).then(() => this.setProductConfiguratorShow(this.isAvailable))

            await getSpectaclesService().getConfig(this.product_id).then(res => {
                if (res) {
                    this.$store.commit('productView/UPDATE_STATE_DATA', {
                        frame_value: this.productView.product_name,
                        sku: `product${this.productView.product_id}`,
                        anti_reflective_by_default: false
                    })
                }
            }).then(() => this.showApp = true)
        },
        changeCurrentColor (index) {
            this.setCheckedIndex(index)
        },
        toggleAntiReflective () {
            this.valueAntiReflective = !this.valueAntiReflective
            this.$store.commit('values/setDataToValues', {
                anti_reflective: this.valueAntiReflective
            })
        },
        setValuesService (item) {
            const values = this.checkoutData
            const anti_reflective = this.valueAntiReflective

            if (values) {
                const lens_label = item.lens_type
                if (typeof values['anti_reflective'] === 'function') {
                    values['anti_reflective'](anti_reflective)
                } else {
                    values['anti_reflective'] = anti_reflective
                }
                if (typeof values['lens_type'] === 'function') {
                    const lens_type = {
                        'name' : lens_label,
                        'color' : item.color
                    }
                    values['lens_type'](lens_type)
                } else if (typeof values['lens_type'] === 'object') {
                    values['lens_type'].color = item.color
                    values['lens_type'].name = lens_label
                    this.$store.commit('productView/UPDATE_STATE_DATA', {
                        frames_SKU: item.frames_SKU
                    })
                }
            }
        },
        mobileSwitcher () {
            this.setProductInfoShow(false)
            this.setProductConfiguratorShow(true)
            $('.spectacles-wrapper').hide()
            $('.product-configurator-spectacles-mobile').show()
            $('.spectacles-banner').hide()
            $('.sb-inner-wrapper').hide()
            $('.frames-breadcrumbs').hide()
            $('.product-configurator-progress .look-params').hide()
            $('.product-view-wrapper-spectacles').css('width', '100%')
        }
    }
}
</script>
