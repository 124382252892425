var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "open-order" },
    [
      _c(
        "paginate",
        {
          ref: "paginator",
          staticClass: "paginate-list",
          attrs: { list: _vm.orders, per: 4, name: "orders" }
        },
        _vm._l(_vm.paginated("orders"), function(order) {
          return _c("div", { key: order.incrementId }, [
            _vm.hasOrders
              ? _c("div", [
                  _c("div", { staticClass: "open-order_container" }, [
                    _c("div", { staticClass: "open-order_header" }, [
                      _c("div", { staticClass: "open-order__number" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "order-date open-order_header_item date order-number"
                          },
                          [
                            _c("span", [_vm._v(_vm._s(order.orderDate))]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { attrs: { id: "order-number" } }, [
                              _vm._v("# " + _vm._s(order.incrementId))
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "open-order__items" }, [
                        _c(
                          "div",
                          { staticClass: "open-order_header_item details" },
                          [
                            _c(
                              "ul",
                              _vm._l(order.items, function(item, index) {
                                return _c(
                                  "li",
                                  { key: "key-" + item.name + "-" + index },
                                  [
                                    _c("ul", [
                                      _c(
                                        "li",
                                        {
                                          staticClass:
                                            "open-orders-single-view__bold-item"
                                        },
                                        [
                                          _c("span", {
                                            directives: [
                                              {
                                                name: "dompurify-html",
                                                rawName: "v-dompurify-html",
                                                value:
                                                  item.name ===
                                                  "goodr Frames - Mick And Keith Midnight Ramble"
                                                    ? "goodr Frames - Mick & Keith Midnight Ramble"
                                                    : item.name,
                                                expression:
                                                  "item.name === 'goodr Frames - Mick And Keith Midnight Ramble' ? 'goodr Frames - Mick & Keith Midnight Ramble' : item.name"
                                              }
                                            ]
                                          })
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "open-order__status" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "order-status open-order_header_item message"
                          },
                          [
                            order.displayMissingMessage ||
                            order.orderMessage === "complete_3_missing"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "status-missing-rx" },
                                      [_vm._v("Missing Information ")]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(order.missing, function(
                                      missing,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key:
                                            order.incrementId +
                                            "-" +
                                            missing +
                                            "-" +
                                            index
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "status-missing" },
                                            [
                                              _vm._v(
                                                " - " +
                                                  _vm._s(missing) +
                                                  " is required to process your order"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "status-missing-rx--detail"
                                      },
                                      [
                                        _vm._v(
                                          "Please click the View button and update your prescription"
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              : order.isPol && order.status === "complete_1"
                              ? _c("div", [
                                  _c("span", [
                                    _vm._v("Please print your label")
                                  ])
                                ])
                              : _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(order.orderMessage))
                                  ])
                                ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "open-order__view" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "order-number open-order_header_item view-btn"
                          },
                          [
                            _c("ul", { staticClass: "header-menu-list" }, [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: order.viewUrl,
                                      title: "View Order Details"
                                    }
                                  },
                                  [_vm._v("View")]
                                )
                              ])
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              : _c("div", [
                  _c("div", { staticClass: "overview-no-data" }, [
                    _c("p", { staticClass: "overview-no-data__copy" }, [
                      _vm._v("You Do Not Have Any Orders.")
                    ]),
                    _vm._v(" "),
                    _c("a", { attrs: { href: "/choose-my-lenses" } }, [
                      _c("div", { staticClass: "button button--copy-solid" }, [
                        _c("div", { staticClass: "button__container" }, [
                          _vm._v("shop our lenses now")
                        ])
                      ])
                    ])
                  ])
                ])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-number-display" },
        [
          _c("span", [_vm._v("Page")]),
          _vm._v(" "),
          _c("paginate-links", { attrs: { for: "orders" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }