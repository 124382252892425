<template>
    <div class="summary-termsinfo-container">
        <span>30 Day Risk Free Returns*</span>
        <span>12 Month warranty included**</span>
    </div>
</template>

<script>
export default {
    name: 'TermsInfo'
}
</script>
