<template>
    <div>
        <nav class="lensabl-plus-nav">
            <div class="nav-container">
                <a class="nav__logo" href="/" aria-label="Link to the homepage">
                    <img class="plus-logo" src="/js/lensconfigurator/assets/lensabl-plus-wordmark.svg" />
                </a>
                <ul class="business_link">
                    <li class="nav__main-li">
                        <span>GROUP PLANS</span>
                    </li>
                </ul>
            </div>
        </nav>
        <div v-if="componentsCount && show" v-show="!getLoad" class="lensabl-plus-progress tabs-header-wrapper business-progress">
            <ul ref="progress" class="tabs-header">
                <template v-for="(tab, index) in businessStepsList">
                    <li v-if="tab" :key="`tab-${index}`" :class="[tab.state]" class="tab group-plan-tab">
                        <span @click="setActiveStep(index)" v-text="tab.label"></span>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import lensablPlus from '../storage/lensablplus/business'

export default {
    name: 'ProgressBarBusiness',
    computed: {
        ...mapGetters('lensablplus_business', ['businessStepsList', 'componentsCount', 'getLoad', 'currentComponent', 'payer', 'fullList']),
        show () {
            return parseInt(this.currentComponent.stepId) !== 5
        }
    },
    watch: {
        payer: {
            handler () {
                this.$store.commit('lensablplus_business/STEPS_LIST', this.fullList)
            },
            deep: true
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablplus_business/']) {
            this.$store.registerModule('lensablplus_business', lensablPlus(this.$store))
        }
        this.$store.dispatch('lensablplus_business/fetchDefaultData')
    },
    methods: {
        ...mapActions('lensablplus_business', ['setActiveStep'])
    }
}
</script>

<style lang="less" scoped>

</style>
