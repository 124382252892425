<template>
    <div
        v-if="!step.isHidden"
        v-touch:swipe.left="swipeNext"
        v-touch:swipe.right="swipePrev"
        :class="{'step-configuration-mobile': isMobileOnly, [siblingsStepState]: siblingsStepState}"
        class="step-configuration"
    >
        <div
            :id="['configurator_step_' + step.id]"
            :class="{editable: step.canEdit, hidden: step.isHidden, ['status-' + step.stepState]: step.stepState, 'step-configuration-mobile-header': isMobileOnly }"
            :title="['Select ' + step.label]"
            class="step-configuration-header"
            @click="edit"
        >
            <span :class="[step.stepState]" class="badge">
                <span v-if="isMobileOnly" class="label">Step</span>
                <span class="number">{{ step.index }}</span>
            </span>
            <span v-dompurify-html="step.label" class="step-title"></span>
            <Renderer v-if="step.canEdit" :id="step.id" :name="step.value_renderer" :class="'step-selection--value'"/>

            <span v-if="step.id === 'lens_options' && siblingsStepState === 'ready-prev wait-next' && !isMobileOnly">
                <HelpBubble :data="component_help" :placement="'bottom'" :classes="'lens_option_help_bubble'"/>
            </span>

            <span v-if="step.canEdit" class="step-edit-button-container">
                <button class="step-edit-button"><span>Edit</span></button>
            </span>
        </div>
        <div v-show="step.showControls || isMobileOnly">
            <div v-if="step.component" :class="[step.id]" class="step-configuration-content-wrapper">
                <component :is="step.component.name" :id="id"
                           :class="['step-configuration-content ' + step.component.name]"/>
            </div>
            <div v-if="step.id === 'lens_type' && values.frames_name ==='bose'" class="bose-note">*Disclaimer: While
                Bose Frames support prescription lenses, the product has been designed as a sunglass product, not an
                all-day prescription eyewear product.
            </div>
            <div v-if="step.showControls || isMobileOnly" class="step-actions">
                <BaseButton
                    v-if="isMobileOnly && backButtonShow"
                    :text="'Back'"
                    class="button--copy-solid-grey next-step-button ladda-button"
                    @click.native="prev"
                />
                <BaseButton
                    :loader="step.nextStepLoading"
                    :text="step.continueLabel"
                    :disabled="step.disabled"
                    class="next-step-button ladda-button"
                    @click.native="next"
                />
            </div>
        </div>
    </div>
</template>
<script>
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _first from 'lodash/first'
import _last from 'lodash/last'
import _cloneDeep from 'lodash/cloneDeep'
import _isFunction from 'lodash/isFunction'
import _keys from 'lodash/keys'
import _size from 'lodash/size'
import {
    EVENT_STEP_NEXT,
    EVENT_STEP_PREVIOUS,
    EVENT_STEP_EDIT,
    EVENT_STEP_UPDATE,
    STATE_CURRENT
} from '../../app/utils/constants'

import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import {mapGetters, mapActions} from 'vuex'
import BaseButton from '../base/BaseButton.vue'
import Renderer from '../renderer/Renderer.vue'
import SelectorOptions from '../selector/options/SelectorOptions.vue'
import SelectorLensCategory from '../selector/options/SelectorLensCategory'
import SelectorOptionsLensType from '../selector/options/lens_type/SelectorOptionsLensType'
import SelectorOptionsLensOption from '../selector/options/lens_option/SelectorOptionsLensOption'
import SelectorPrescription from '../selector/prescription/SelectorPrescription.vue'
import SelectorPrescriptionType from '../selector/prescription/type/SelectorPrescription.vue'
import SelectorAntireflective from '../selector/antireflective/SelectorAntireflective.vue'
import SelectorNotes from '../selector/notes/SelectorNotes.vue'
import PrescriptionOwner from '../contacts/prescription/PrescriptionOwner.vue'
import HelpBubble from '../help_bubble/HelpBubble.vue'
import { STEP_ANTIREFLECTIVE } from '../../app/common/constants/antireflective'

Vue.use(Vue2TouchEvents)

export default {
    name: 'Step',
    components: {
        BaseButton,
        Renderer,
        SelectorOptions,
        SelectorLensCategory,
        SelectorOptionsLensType,
        SelectorOptionsLensOption,
        SelectorPrescription,
        SelectorPrescriptionType,
        SelectorAntireflective,
        SelectorNotes,
        PrescriptionOwner,
        HelpBubble
    },
    props: {
        id: { // Step Id
            type: [String, Number],
            default: ''
        },
        index: {
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        siblingsStepState: '',
        component_help: {
            name: 'help-bubble',
            params: {
                text: '<img src="/js/lensconfigurator/assets/lens-table.jpg" />'
            }
        }
    }),
    computed: {
        ...mapGetters('values', ['values', 'wasPrescriptionChanged']),
        ...mapGetters(['promiseService']),
        ...mapGetters('deviceProperty', ['isMobileOnly']),
        ...mapGetters('configurator', ['product_config', 'antireflective_sections']),
        ...mapGetters('configurator/steps', [
            'getLeftAndRightStepIds',
            'getStepObject',
            'limiters',
            'labels',
            'hasExtraOptionSections',
            'getCurrentExtraOptionSection',
            'getLimitersPreDefinedByFrames'
        ]),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow']),
        step() {
            const step = this.getStepObject(this.id)
            if (!step) {
                this.fetchStep(this.id)
            }
            return step || this.getStepObject(this.id)
        },
        isStepCurrent() {
            return this.step.isCurrent
        },
        options() {
            const options = this.step && this.step.component ? this.step.component.options : false
            if (!options) {
                return false
            }

            return this._filterOptions(options)
        },
        isHidden() {
            if (this.options) {
                const limiterKeys = _keys(this.limiters)

                if (this.step.always_visible && (limiterKeys.indexOf(this.id) === -1)) {
                    return false
                }

                if (this.options.length === 1) {
                    if (this.id === 'lens_options') {
                        return !this.antireflective_sections['anti_reflective_option']
                    }

                    const option = this.options[0]
                    const hasExtraComponent = Boolean(option['component_extra_options'])

                    return !hasExtraComponent
                }
            } else if (this.step.always_visible == false) {
                if(this.step.id == STEP_ANTIREFLECTIVE) {
                    if(this.values.frames_SKU == 'spec-gen-1') {
                        return !this.antireflective_sections['anti_reflective_option']
                    }
                }
            }
            return false
        },
        getPreSelectedValue() {
            const stepId = this.step.id
            const predefinedObject = this.getLimitersPreDefinedByFrames(stepId)
            if (Object.keys(predefinedObject).length) {
                return predefinedObject[stepId]
            }
            return false
        },
        defaultValue() {
            if (this.getPreSelectedValue) {
                const option = _find(this.options, option => option.id === this.getPreSelectedValue)
                if (option) {
                    return option.id
                }
            }
            const option = _first(this.options)
            if (!option) {
                return false
            }
            return option.id
        },
        hasOneOnlyOption() {
            const options = this.options
            if (!options) {
                return false
            }
            return options.length === 1
        },
        isRedeemable() {
            const options = this.options
            const value = this.step.value
            const selectedValue = _find(options, {'id': value})
            if (!selectedValue) {
                return false
            }
            return selectedValue.redeemable ? selectedValue.redeemable : false
        },
        continueLabel() {
            const continue_label = this.step.continue_label
            return (continue_label && this.wasPrescriptionChanged) ? continue_label : 'Continue'
        },
        summaryLabel() {
            return this._renderLabel('summary_label', ' ', 'label')
        },
        stepHeaderLabel() {
            return this._renderLabel('label')
        },
        siblingsSteps() {
            const [lefts, rights] = this.getLeftAndRightStepIds(this.id)

            return [this.getStepObject(_last(lefts)), this.getStepObject(_first(rights))]
        },
        backButtonShow() {
            const [lefts] = this.getLeftAndRightStepIds(this.id)

            return !!_last(lefts)
        }
    },
    created() {
        this.updateStepData({
            value: this.defaultValue
        })
        this._updateStep(this.id)
        this.$bus.$on(EVENT_STEP_UPDATE, this._updateStep)
    },
    beforeDestroy() {
        this.$bus.$off(EVENT_STEP_UPDATE, this._updateStep)
    },
    updated() {
        if (this.step) {
            this.$nextTick(() => {
                if (this.isStepCurrent && this.step.isHidden) {
                    this.next()
                }
            })
        }
    },
    methods: {
        ...mapActions('configurator/steps', ['activateStep', 'fetchStep', 'updateStep', 'updateNextStepsValues', 'updateVisibleList']),
        updateStepData(data = {}) {
            this.updateStep({id: this.id, data: data})
            this.updateVisibleList()
        },
        edit() {
            this.$bus.$emit(EVENT_STEP_EDIT, this.step)
        },
        prev() {
            this.$bus.$emit(EVENT_STEP_PREVIOUS, this.step)
        },
        next() {
            this.$bus.$emit(EVENT_STEP_NEXT, this.step)
        },
        swipePrev() {
            if (this.isMobileOnly) {
                this.prev()
            }
        },
        swipeNext() {
            if (this.isMobileOnly) {
                this.next()
            }
        },

        _filterOptions(options) {
            const stepId = this.id
            const product_config = this.product_config
            const labels = this.labels
            const limiters = this.limiters
            let filteredOptions = options

            if (!product_config) {
                return filteredOptions
            }

            if (stepId === 'lens_type') {
                filteredOptions = this._getLensTypeOptions(filteredOptions)
            } else if (stepId === 'lens_options') {
                filteredOptions = this._getLensOptionOptions(filteredOptions)
            }

            if (limiters) {
                filteredOptions = _filter(filteredOptions, option => {
                    const optionId = option.id
                    const optionLimiters = limiters[stepId]
                    if (!optionLimiters) {
                        return true
                    }
                    return optionLimiters.indexOf(optionId) !== -1
                })
            }

            if (!_size(labels)) {
                return filteredOptions
            }

            return _filter(filteredOptions, option => {
                const optionId = option.id
                if (!optionId) {
                    return false
                }

                const step = labels[stepId]
                if (!step) {
                    return true
                }

                const optionSettings = step[optionId]
                if (!optionSettings) {
                    return true
                }

                return labels[stepId][optionId].enabled
            })
        },
        _getLensTypeOptions(options) {
            const productConfig = this._getProductConfig()
            const availableLabels = _map(productConfig, option => option.label)

            const optionsWithAvailableSections = _map(options, option => {
                const {component_extra_options} = option
                if (!component_extra_options) {
                    return _cloneDeep(option)
                }

                const {params} = component_extra_options
                if (!params) {
                    return _cloneDeep(option)
                }

                let {sections} = params
                if (!sections) {
                    return _cloneDeep(option)
                }

                sections = _filter(sections, (section) => {
                    return availableLabels.indexOf(section.checkout_value) > -1
                })

                const clonedOption = _cloneDeep(option)
                clonedOption.component_extra_options.params.sections = _cloneDeep(sections)
                return clonedOption
            })

            return _filter(optionsWithAvailableSections, option => {
                const {component_extra_options} = option
                if (component_extra_options) {
                    const {params} = component_extra_options
                    if (!params) {
                        return false
                    }

                    const {sections} = params
                    if (!sections) {
                        return false
                    }
                    return sections.length > 0
                } else {
                    const {checkout_value} = option
                    return availableLabels.indexOf(checkout_value) > -1
                }
            })
        },
        _getLensOptionOptions(options) {
            const productConfig = this._getProductConfig()
            const {lens_type} = this.values

            if (!lens_type) {
                return options
            }

            const lensTypeStep = this.getStepObject('lens_type')
            if (!lensTypeStep) {
                return options
            }
            const lensTypeCheckoutValue = this._getCheckoutValue(lens_type, lensTypeStep.component.options)
            if (!lensTypeCheckoutValue) {
                return options
            }

            const lensTypeConfig = _find(productConfig, (option) => {
                return option.label === lensTypeCheckoutValue
            })

            if (!lensTypeConfig) {
                return options
            }

            const lensTypeOptions = lensTypeConfig.options
            if (!lensTypeOptions) {
                return options
            }

            const lensTypeOptionLabels = _map(lensTypeOptions, option => option.label)

            return _filter(options, option => {
                return lensTypeOptionLabels.indexOf(option.checkout_value) > -1
            })
        },
        _getProductConfig() {
            const productKey = this._getProductKey()
            const product_config = this.product_config
            let optionConfig = product_config[productKey] || {}
            optionConfig = optionConfig.config ? optionConfig.config : optionConfig
            return optionConfig
        },
        _getProductKey() {
            const {lens, prescription_type} = this.values

            if (['reading', 'computer'].indexOf(lens) > -1) {
                return `${lens}_lens`
            } else if (lens === 'sunglasses') {
                return 'sunglass_lens'
            } else if (lens === 'transitions') {
                return 'transition_lens'
            } else {
                return prescription_type || this._getFirstOptionId('prescription_type')
            }
        },
        _getFirstOptionId(stepId) {
            const step = this.getStepObject(stepId)
            const ids = _map(step.component.options, option => option.id)
            return _first(ids)
        },
        _getOptionOrExtraSections(option){
            const {component_extra_options} = option
            if (component_extra_options) {
                const {params} = component_extra_options
                if (!params) {
                    return option
                }

                const {sections} = params
                if (!sections) {
                    return option
                }
                return sections

            }else{
                return option
            }
        },
        _getCheckoutValue(value, options) {
            let checkoutValue = false
            for (const option of options) {
                const optionOrSection = this._getOptionOrExtraSections(option)
                if (optionOrSection.id){
                    if (optionOrSection.id === value) {
                        checkoutValue= optionOrSection.checkout_value
                    }
                }else{
                    for (const section of optionOrSection) {
                        if (section.id === value) {
                            checkoutValue = section.checkout_value
                        }
                    }
                }
            }
            if (!checkoutValue){
                const firstOption = _first(options)
                const firstOptionOrSection = this._getOptionOrExtraSections(firstOption)
                if (firstOptionOrSection.id){
                    return firstOptionOrSection.checkout_value
                }else{
                    const firstSection = _first(firstOptionOrSection)
                    return firstSection.checkout_value
                }
            }else{
                return checkoutValue
            }
        },
        _renderLabel(labelField, defaultValue = '', fallbackField = false) {
            let value = this.step.value

            if (!value) {
                return ''
            }

            if (_isFunction(value)) {
                value = value()
            }

            const {component} = this.step
            if (!component) {
                return defaultValue
            }

            const {options} = component
            if (options && Array.isArray(options)) {
                const option = _find(options, option => option.id === value)
                if (option) {
                    value = option[labelField]
                    if (fallbackField && !value) {
                        value = option[fallbackField]
                    }
                }
            }

            return value || defaultValue
        },
        _siblingsState() {
            const [prevStep, nextStep] = this.siblingsSteps
            let siblingsState = ''

            if (typeof prevStep !== 'undefined') {
                siblingsState += prevStep.stepState + '-prev'
            }
            if (typeof nextStep !== 'undefined') {
                siblingsState += ' ' + nextStep.stepState + '-next'
            }
            return siblingsState
        },
        _updateStep: function (id) {
            if (id === this.id) {
                const step = this.getStepObject(id)
                this.siblingsStepState = this._siblingsState()

                this.updateStepData({
                    options: this.options,
                    isHidden: this.isHidden,
                    defaultValue: this.defaultValue,
                    default_value: this.defaultValue,
                    hasOneOnlyOption: this.hasOneOnlyOption,
                    continueLabel: this.continueLabel,
                    summaryLabel: this.summaryLabel,
                    stepHeaderLabel: this.stepHeaderLabel,
                    redeemable: this.isRedeemable,
                })

                if (this.options && !_find(step.options, option => option.id === step.value)) {
                    this.updateStepData({
                        value: this.defaultValue
                    })
                    this.updateStepData({
                        summaryLabel: this.summaryLabel,
                        stepHeaderLabel: this.stepHeaderLabel
                    })
                    this.$store.commit('values/setDataToValues', {[this.step.id]: this.defaultValue})
                }

                if (_size(this.step.options) === 1 && _first(this.step.options).id === this.defaultValue && this.values[this.step.id] !== this.defaultValue) {
                    this.$store.commit('values/setDataToValues', {[this.step.id]: this.defaultValue})
                    this.$bus.$emit(EVENT_STEP_UPDATE, this.id)
                }

                if (this.hasExtraOptionSections(this.step)) {
                    this.$nextTick(() => {
                        const section = this.getCurrentExtraOptionSection(this.step)
                        this.$store.commit('values/setDataToValues', {[this.step.id + '_name']: (section || {}).id})
                    })
                }

                if (step.stepState === STATE_CURRENT) {
                    this.activateStep(id)
                    this.updateNextStepsValues(id)
                }
            }
        }
    }
}
</script>
