<template>
   <div :class="[brand + '-seo-parent-container']">
        <div class="shopframes-seo-prescription-container">
            <div class="shopframes-seo-row seo-prescription">
                <div class="shopframes-seo-text">
                    <h2>Hobie Eyewear</h2>
                    <p>You live an active life, so your glasses should be able to meet every challenge as well. Hobie Eyewear produces some of the most durable glasses and sunglasses for people who love to get out. You'll find a wide selection of men's Hobie eyeglasses through Lensabl's online store. Order your new frames today for free standard delivery right to your door.</p>
                </div>
                <div class="shopframes-seo-img">
                </div>
            </div>
        </div>

        <div class="shopframes-seo-frames-container">
            <div class="shopframes-seo-row seo-frames">
                <div class="shopframes-seo-frames-img">
                </div>
                <div class="shopframes-seo-text">
                    <h2>Durable Hobie Frames for Active Lifestyles</h2>
                    <p>Hobie glasses are available in all your favorite styles. Shop online with Lensabl to find durable frames that look great on any occasion. <br /><br />We carry rectangular, square and round Hobie Optical frames that feature rugged materials like Memory Metal and acetate. Our selection of Hobie Sunglasses features the brand's most loved editions, like Baja, Dunes, Cabo and Broad. Each Hobie frame is lightweight and stylish for a pair of glasses that look and feel good to wear all day long.<br /><br />Unsure which size is right for you? We invite you to find your fit using our site's size finder tool before ordering your new Hobie frames.</p>
                </div>
            </div>
        </div>

        <div class="shopframes-seo-designer-container">
            <div class="shopframes-seo-row seo-designer">
                <div class="shopframes-seo-text">
                    <h2>Custom Lenses for Hobie Eyeglasses</h2>
                    <p>Lensabl has a lens for any occasion to fill your Hobie frames. Our prescription lenses are available for single vision, progressive and bifocal correction. Choose a standard clear lens or opt for a blue-light-blocking or Transitions® coating. We also sell Hobie polarized and mirror sunglasses for your prescription. Order Hobie glasses through Lensabl for dependable vision correction and eye protection.</p>
                </div>
            <div class="shopframes-seo-designer-img">
            </div>
            </div>
        </div>

        <div class="shopframes-seo-eyeglass-container">
            <div class="shopframes-seo-row seo-eyeglass">
                <div class="shopframes-seo-text seo-eyeglass-text">
                    <h2>Shop Lensabl for Hobie Eyewear</h2>
                    <p>Are you looking for durable glasses you can trust out on the water for optical correction and glare prevention? A set of Hobie glasses is the perfect fit for your active lifestyle. Buy Hobie eyewear online through Lensabl for free standard delivery and other great perks.</p>
                </div>
                <a href="/glasses-frames" title="Glasses Frames">
                    <div class="button button--copy-solid">
                        <div class="button__container">GET NEW FRAMES</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HobieSeo',
    props: {
        brand: {
            type: [String],
            default: ''
        }
    },
}
</script>
