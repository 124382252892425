<template>
    <div>
        <header>
            <div class="form-access__header" style="margin: 2em;">
                <h3>What is your date of birth?</h3>
                <p class="a-center">
                    To qualify you must be at least 18 years of age.
                </p>
            </div>
        </header>
        <div v-if="BIRTHDAY !== ''">
            <span>You selected:<span>{{ formatBrth(BIRTHDAY, 'DD.MM.YYYY') }}</span></span>
        </div>
        <div class="brth-selects">
            <select v-model="date.day">
                <template v-for="(day, index) in getDayRange">
                    <option :key="index" :value="day" :selected="index === 0">
                        {{ day }}
                    </option>
                </template>
            </select>
            <select v-model="date.month">
                <template v-for="(month, index) in getMonthRange">
                    <option :key="index" :value="index" :selected="index === 0">
                        {{ month }}
                    </option>
                </template>
            </select>
            <select v-model="date.year">
                <template v-for="(year, index) in getYearRange">
                    <option :key="index" :value="year" :selected="index === 0">
                        {{ year }}
                    </option>
                </template>
            </select>
        </div>
        <p v-if="$v.date.$error" class="help is-danger">Date is invalid</p>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
import * as moment from 'moment'

const initialState = () => {
    return {
        date: {
            year: null,
            month: null,
            day: null
        }
    }
}

export default {
    mixins: [validationMixin],
    props: {
        currentStep: {
            type: [Array, Object],
            default: () => {
            }
        }
    },
    data () {
        return initialState()
    },
    validations: {
        date: {
            day: {
                required
            },
            month: {
                required
            },
            year: {
                required
            }
        }
    },
    computed: {
        ...mapGetters('onlineExam', ['BIRTHDAY']),
        getYearRange () {
            const fromYear = moment().subtract(18, 'years')
            const toYear = moment().subtract(80, 'years')
            return this.rangeArray(fromYear.year(), toYear.year(), -1)
        },
        getMonthRange () {
            return moment.months()
        },
        getDayRange () {
            let daysCount = 31
            if (this.date.year && this.date.month) {
                const monthIndex = this.date.month + 1
                daysCount = moment(`${this.date.year}-${monthIndex}`, 'YYYY-MM').daysInMonth()
                if (daysCount < this.date.day) {
                    this.resetData()
                }
            }
            return this.rangeArray(1, daysCount)
        }
    },
    watch: {
        $v: {
            handler: function (val) {
                if (!val.date.$invalid) {
                    this.$emit('can-continue', {value: true})
                } else {
                    this.$emit('can-continue', {value: false})
                    setTimeout(() => {
                        this.$emit('change-next', {nextBtnValue: false})
                    }, 3000)
                }
                this.$store.commit('onlineExam/SET_BIRTHDAY', this.getSelectedBrth())
                this.$store.commit('onlineExam/SET_VALID', !val.date.$invalid)
            },
            deep: true
        }
    },
    activated () {
        this.setCustomerBrth()
        // check mounted data
        if (!this.$v.date.$invalid) {
            this.$emit('can-continue', {value: true})
        } else {
            this.$emit('can-continue', {value: false})
        }
    },
    methods: {
        resetData () {
            Object.assign(this.$data, initialState())
        },
        setCustomerBrth () {
            if (this.BIRTHDAY === '') {
                return
            }
            const date = this.getMomentObject(this.BIRTHDAY)
            this.date.year = date.year()
            this.date.month = date.month()
            this.date.day = date.date()
        },
        rangeArray (start, stop, step = 1) {
            const range = (start, stop, step) => Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step))
            return range(start, stop, step)
        },
        getSelectedBrth (separator = '-') {
            if (!this.$v.date.$invalid) {
                const strBrth = [this.date.year, this.addZero(this.date.month + 1), this.addZero(this.date.day)].join(separator)
                return this.formatBrth(strBrth)
            } else {
                return ''
            }
        },
        getMomentObject (strBrth) {
            return moment(strBrth)
        },
        formatBrth (strBrth, format = 'YYYY-MM-DD') {
            const selectedBrth = this.getMomentObject(strBrth)
            return selectedBrth.format(format)
        },
        addZero (str) {
            return str.length > 1 ? str : '0' + str
        }
    }
}
</script>
