import {UPGRADES_STEP} from '../../../app/common/constants/upgrades'
import _find from 'lodash/find'
import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererUpgrades',
    extends: RendererOptions,
    computed: {
        renderer () {
            let title = ''
            const currentStep = this.step.isCurrent
            const readyStep = this.step.isReady
            if (!currentStep && !readyStep) {
                return title
            }
            const configs = [...UPGRADES_STEP]
            const upgrades = this.values.upgrades

            const keysObject = Object.keys(upgrades)
            keysObject.map(key => {
                const aliasObject = _find(configs, {'value': key})
                if (!aliasObject) {
                    return title
                }
                title += aliasObject.hasOwnProperty('alias') ? ' + ' + aliasObject.alias : ''
            })

            return title
        }
    }
}
