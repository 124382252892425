var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sb-inner-wrapper" }, [
      _c("div", { staticClass: "sb-inline" }, [
        _vm.category.description
          ? _c("h1", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.category.description,
                  expression: "category.description"
                }
              ]
            })
          : _c("h1", { domProps: { textContent: _vm._s(_vm.category.name) } })
      ]),
      _vm._v(" "),
      _vm.category.image
        ? _c(
            "div",
            {
              staticClass: "sb-inline sb-inner-img",
              class: ["sb-inner-img" + _vm.categoryClass]
            },
            [
              _c("img", {
                attrs: {
                  id: "category-img",
                  alt: _vm.category.name,
                  src: _vm.category.image
                }
              })
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }