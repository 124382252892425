import formatLensValue from './lens-formatter'

export default function generateOptions (
    from = -4,
    to = 3,
    step = 0.25,
    precision = 2,
    firstDash = false,
    needSign = true,
    zeroFill = false,
    noneValue = false,
    type = false,
    additionalOptionFlag = false
) {
    function * options () {
        let current = from - step
        while (current < to) {
            current = current + step
            yield current
        }
    }

    const generatedOptions = Array.from(options(), (option) => {
        if (typeof type === 'object' && type.__proto__ === Array.prototype) {
            if (type.length >= 2) {
                additionalOptionFlag = true
                type = type[0]
            }
        }

        const formattedOption = formatLensValue(option, precision, needSign, zeroFill, noneValue, type)
        return {
            'value': formattedOption,
            'label': formattedOption
        }
    })

    if (additionalOptionFlag) {
        generatedOptions.push({
            'value': 'BAL/BALANCE',
            'label': 'BAL/BALANCE'
        })
    }

    if (firstDash) {
        generatedOptions.unshift({
            'value': noneValue || null,
            'label': noneValue || '--'
        })
    }

    return generatedOptions
}
