<template>
    <div class="qualifications online-optometry">
        <!--<Loader :loading="LOADING" />-->
        <section :if="!LOADING" class="section text-center">
            <div v-if="!finishedStep" class="row progress-header-form">
                <div class="progress-header">
                    <div class="progress-bg">
                        <div :style="{ width: width}" class="progress-bar"></div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div>
                    <div>
                        <Loader :loading="LOADING" />
                        <Stepper :steps="steps" @completed-step="completedStep" @active-step="isStepActive" @stepper-finished="finish" />
                        <p v-if="ERROR" class="help is-danger">Please check entered information</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Stepper from './stepper/Stepper.vue'
import StepEmail from './steps/StepEmail.vue'
import StepType from './steps/StepType.vue'
import StepBrth from './steps/StepBrth.vue'
import StepRegion from './steps/StepRegion.vue'
import StepPrescriptions from './steps/StepPrescriptions.vue'
import StepQualification from './steps/StepQualification.vue'
import Loader from './Loader.vue'
import _findIndex from 'lodash/findIndex'
import valueExamService from './storage/valueExam'
import products from '../lensablplus/storage/lensablplus/customer'

export default {
    name: 'GoVisibly',
    components: {
        Stepper,
        Loader
    },
    data: () => {
        return {
            steps: [
                {
                    name: 'step-email',
                    component: StepEmail,
                    completed: false
                },
                {
                    name: 'step-type',
                    component: StepType,
                    completed: false
                },
                {
                    name: 'step-brth',
                    component: StepBrth,
                    completed: false
                },
                {
                    name: 'step-region',
                    component: StepRegion,
                    completed: false
                },
                {
                    name: 'step-prescriptions',
                    component: StepPrescriptions,
                    completed: false
                },
                {
                    name: 'step-qualification',
                    component: StepQualification,
                    completed: false
                }
            ],
            disabledSteps: {
                sign_in: ['step-email']
            },
            width: 0,
            finishedStep: false
        }
    },
    computed: {
        ...mapGetters('onlineExam', [
            'SIGN_IN', 'LOADING', 'ERROR', 'VALID'
        ]),
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow'])
    },
    watch: {
        // SIGN_IN: 'disabledStepByType',
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['onlineExam/']) {
            this.$store.registerModule('onlineExam', valueExamService(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['lensablplus_customer/']) {
            this.$store.registerModule(['lensablplus_customer'], products(this.$store), { preserveState: !!this.$store.state['lensablplus_customer'] })
        }
    },
    mounted () {
        this.$store.dispatch('onlineExam/CHECK_SIGN_IN')
        this.$store.dispatch('onlineExam/getProductValue')
        this.$store.dispatch('lensablplus_customer/checkRedeemablePath')
    },
    methods: {
        ...mapActions('onlineExam', ['CHECK_SIGN_IN']),
        ...mapActions('lensablplus_customer', ['checkRedeemablePath']),
        // Executed when SIGN_IN is changed
        disabledStepByType (type) {
            if (type) {
                this.disabledSteps['sign_in'].forEach((disabledStep) => {
                    const index = _findIndex(this.steps, {name : disabledStep})
                    if (index >= 0) {
                        this.steps.splice(index, 1)
                    }
                })
            }
        },
        // Executed when @completed-step event is triggered
        completedStep (payload) {
            this.progressBarWidth(payload)
            this.steps.forEach((step) => {
                if (step.name === payload.name) {
                    step.completed = true
                }
                if (payload.name === 'step-prescriptions') {
                    this.finishedStep = true
                }
            })
        },
        // Executed when @active-step event is triggered
        isStepActive (payload) {
            this.steps.forEach((step) => {
                if (step.name === payload.name) {
                    if (step.completed === true) {
                        step.completed = false
                    }
                }
            })
        },
        // Executed when @stepper-finished event is triggered
        finish (payload) {

        },
        progressBarWidth (payload) {
            this.width = ((payload.index + 1) / (this.steps.length - 1)) * 100 + '%'
        }
    }
}
</script>
