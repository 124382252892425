<template>
    <CustomerPrescriptionEdit v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CustomerPrescriptionEdit from '../../../components/customer/prescription/Edit.vue'

export default {
    name: 'RouterCustomerPrescriptionEdit',
    components: {
        CustomerPrescriptionEdit
    },
    extends: App
}
</script>
