var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "prescription-list-container" }, [
      _c(
        "div",
        { staticClass: "my-prescription-list" },
        [
          _c("div", { staticClass: "my-prescription-list__copy" }, [
            _c("p", { staticClass: "my-prescription-name" }, [
              _vm._v(_vm._s(_vm.prescription.prescription_owner))
            ]),
            _vm._v(" "),
            _c("ul", [
              _vm.expiration_date
                ? _c("li", [
                    _c("span", [_vm._v("Expiration Date:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v("00/00/00")])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.prescription.doctor_name
                ? _c("li", [
                    _c("span", [_vm._v("Doctor:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.prescription.doctor_name))])
                  ])
                : _c("li", [_c("span", [_vm._v("Photo Verification")])]),
              _vm._v(" "),
              _c("div", { staticClass: "order-group-rx" }, [
                _vm.prescription.last_order_date
                  ? _c("li", [
                      _c("span", [_vm._v("Last Ordered:")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.prescription.last_order_date))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.prescription.order_id
                  ? _c("li", [
                      _c("span", [_vm._v("Order ID:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.prescription.order_id))])
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "prescription-snapshot" }, [
            _c("div", { staticClass: "p", attrs: { id: "poupmaindiv" } }, [
              !_vm.isMobile && _vm.lengthRight !== 0
                ? _c(
                    "table",
                    {
                      staticClass: "prescription-data rx-data_my-account",
                      attrs: { border: "0" }
                    },
                    [
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass:
                              "prescription-cell prescription-header",
                            attrs: { rowspan: "2" }
                          },
                          [_vm._v("OD (Right)")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("Power/SPH")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("BC")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("DIA")]
                        ),
                        _vm._v(" "),
                        _vm.right.cyl
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("CYL")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.right.axis
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("Axis")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.right.add
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("Add")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c("td", { staticClass: "prescription-cell value" }, [
                          _c(
                            "span",
                            {
                              staticClass: "prescription-input",
                              attrs: {
                                id: "odpower",
                                value: _vm.right.power,
                                type: "text",
                                name: "od_power"
                              }
                            },
                            [
                              _vm.right.power > 0
                                ? _c("span", [_vm._v("+")])
                                : _vm._e(),
                              _vm._v(_vm._s(_vm.right.power) + " ")
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "prescription-cell value" }, [
                          _c(
                            "span",
                            {
                              staticClass: "prescription-input",
                              attrs: {
                                id: "odbc",
                                value: _vm.right.bc,
                                type: "text",
                                name: "od_bc"
                              }
                            },
                            [_vm._v(_vm._s(_vm.right.bc))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "prescription-cell value" }, [
                          _c(
                            "span",
                            {
                              staticClass: "prescription-input",
                              attrs: {
                                id: "oddia",
                                value: _vm.right.dia,
                                type: "text",
                                name: "od_dia"
                              }
                            },
                            [_vm._v(_vm._s(_vm.right.dia))]
                          )
                        ]),
                        _vm._v(" "),
                        _vm.right.cyl
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      id: "odcyl",
                                      value: _vm.right.cyl,
                                      type: "text",
                                      name: "od_cyl"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.cyl))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.right.axis
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      id: "odaxis",
                                      value: _vm.right.axis,
                                      type: "text",
                                      name: "od_axis"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.axis))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.right.add
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      id: "odadd",
                                      value: _vm.right.add,
                                      type: "text",
                                      name: "od_axis"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.add))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.lengthRight !== 0
                        ? _c("tr", { staticClass: "prescription-row" }, [
                            _c("td", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: _vm.right.brand,
                                  expression: "right.brand"
                                }
                              ],
                              staticClass: "prescription-cell value",
                              attrs: {
                                colspan: _vm.prescription.doctor_name
                                  ? Object.keys(_vm.right).length - 3
                                  : Object.keys(_vm.right).length - 2
                              }
                            })
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isMobile && _vm.lengthLeft !== 0
                ? _c(
                    "table",
                    {
                      staticClass: "prescription-data rx-data_my-account",
                      attrs: { border: "0" }
                    },
                    [
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass:
                              "prescription-cell prescription-header",
                            attrs: { rowspan: "2" }
                          },
                          [_vm._v("OS (Left)")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("Power/SPH")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("BC")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("DIA")]
                        ),
                        _vm._v(" "),
                        _vm.left.cyl
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("CYL")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.left.axis
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("Axis")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.left.add
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("Add")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c("td", { staticClass: "prescription-cell value" }, [
                          _c(
                            "span",
                            {
                              staticClass: "prescription-input",
                              attrs: {
                                id: "ospower",
                                value: _vm.left.power,
                                type: "text",
                                name: "od_power"
                              }
                            },
                            [
                              _vm.left.power > 0
                                ? _c("span", [_vm._v("+")])
                                : _vm._e(),
                              _vm._v(_vm._s(_vm.left.power))
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "prescription-cell value" }, [
                          _c(
                            "span",
                            {
                              staticClass: "prescription-input",
                              attrs: {
                                id: "osbc",
                                value: _vm.left.bc,
                                type: "text",
                                name: "od_bc"
                              }
                            },
                            [_vm._v(_vm._s(_vm.left.bc))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "prescription-cell value" }, [
                          _c(
                            "span",
                            {
                              staticClass: "prescription-input",
                              attrs: {
                                id: "osdia",
                                value: _vm.left.dia,
                                type: "text",
                                name: "od_dia"
                              }
                            },
                            [_vm._v(_vm._s(_vm.left.dia))]
                          )
                        ]),
                        _vm._v(" "),
                        _vm.left.cyl
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      id: "oscyl",
                                      value: _vm.left.cyl,
                                      type: "text",
                                      name: "od_cyl"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.cyl))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.left.axis
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      id: "osaxis",
                                      value: _vm.left.axis,
                                      type: "text",
                                      name: "od_axis"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.axis))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.left.add
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      id: "osadd",
                                      value: _vm.left.add,
                                      type: "text",
                                      name: "od_axis"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.add))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c("td", {
                          directives: [
                            {
                              name: "dompurify-html",
                              rawName: "v-dompurify-html",
                              value: _vm.left.brand,
                              expression: "left.brand"
                            }
                          ],
                          staticClass: "prescription-cell value",
                          attrs: {
                            colspan: _vm.prescription.doctor_name
                              ? Object.keys(_vm.left).length - 3
                              : Object.keys(_vm.left).length - 2
                          }
                        })
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isMobile
                ? _c(
                    "table",
                    {
                      staticClass: "prescription-data rx-data_my-account",
                      attrs: { border: "0" }
                    },
                    [
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c("td", {
                          staticClass: "prescription-cell prescription-header"
                        }),
                        _vm._v(" "),
                        _vm.lengthRight !== 0
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("OD (Right)")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lengthLeft !== 0
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "prescription-cell prescription-header"
                              },
                              [_vm._v("OD (Left)")]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("POWER/SPH")]
                        ),
                        _vm._v(" "),
                        _vm.lengthRight !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.right.power,
                                      type: "text",
                                      name: "od_power"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.power))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lengthLeft !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.left.power,
                                      type: "text",
                                      name: "os_power"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.power))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("BC")]
                        ),
                        _vm._v(" "),
                        _vm.lengthRight !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.right.bc,
                                      type: "text",
                                      name: "od_bc"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.bc))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.left.length !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.left.bc,
                                      type: "text",
                                      name: "os_bc"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.bc))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("DIA")]
                        ),
                        _vm._v(" "),
                        _vm.lengthRight !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.right.dia,
                                      type: "text",
                                      name: "od_dia"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.dia))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lengthLeft !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.left.dia,
                                      type: "text",
                                      name: "os_dia"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.dia))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("CYL")]
                        ),
                        _vm._v(" "),
                        _vm.right.cyl
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.right.cyl,
                                      type: "text",
                                      name: "od_cyl"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.cyl))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.left.cyl
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.left.cyl,
                                      type: "text",
                                      name: "os_cyl"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.cyl))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("Axis")]
                        ),
                        _vm._v(" "),
                        _vm.right.axis.length !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.right.axis,
                                      type: "text",
                                      name: "od_axis"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.axis))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.left.axis.length !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.left.axis,
                                      type: "text",
                                      name: "os_axis"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.axis))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("Axis")]
                        ),
                        _vm._v(" "),
                        _vm.right.add
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.right.add,
                                      type: "text",
                                      name: "od_axis"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.right.add))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.left.add
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "prescription-input",
                                    attrs: {
                                      value: _vm.left.add,
                                      type: "text",
                                      name: "os_axis"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.left.add))]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("tr", { staticClass: "prescription-row" }, [
                        _c(
                          "td",
                          {
                            staticClass: "prescription-cell prescription-header"
                          },
                          [_vm._v("Brands")]
                        ),
                        _vm._v(" "),
                        _vm.lengthRight !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [_c("span", [_vm._v(_vm._s(_vm.right.brand))])]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lengthLeft !== 0
                          ? _c(
                              "td",
                              { staticClass: "prescription-cell value" },
                              [_c("span", [_vm._v(_vm._s(_vm.left.brand))])]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "my-prescription-list__actions" }, [
            _c("ul", { staticClass: "my-prescription-list__actions-list" }, [
              _vm.prescription.order_id
                ? _c("li", { staticClass: "my-prescription-edit-button" }, [
                    _c(
                      "div",
                      { staticClass: "button button--copy-border-sm edit" },
                      [
                        _c("BaseButton", {
                          staticClass: "button__copy button-edit",
                          attrs: { loader: _vm.reorderLoader, text: "Reorder" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.reOrder($event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.message
                      ? _c("p", [_vm._v(_vm._s(_vm.message))])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.prescription.order_id
                ? _c("li", { staticClass: "my-prescription-edit-button" }, [
                    _c(
                      "div",
                      { staticClass: "button button--copy-border-sm edit" },
                      [
                        _c("BaseButton", {
                          staticClass: "button__copy button-edit",
                          attrs: { text: "Add To Cart" },
                          nativeOn: {
                            click: function($event) {
                              _vm.quantityModal = true
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.message
                      ? _c("p", [_vm._v(_vm._s(_vm.message))])
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.quantityModal
            ? _c(
                "Modal",
                {
                  on: {
                    close: function($event) {
                      _vm.quantityModal = false
                    }
                  }
                },
                [
                  _c("span", { staticClass: "modal-header" }, [
                    _vm._v("How many boxes?")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("br"),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boxAmount,
                            expression: "boxAmount"
                          }
                        ],
                        attrs: {
                          id: "orderAmount",
                          type: "text",
                          name: "orderAmount"
                        },
                        domProps: { value: _vm.boxAmount },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.boxAmount = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("BaseButton", {
                        staticClass: "button__copy button-edit",
                        attrs: {
                          loader: _vm.reorderLoader,
                          text: "Add To Cart"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.addNewContacts($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }