<template>
    <div v-if="statusApi">
        <LensesPageSeo v-if="includeSeoSection && isMobile" />
        <Configurator />
        <LensesPageSeo v-if="includeSeoSection && !isMobile" />
    </div>
</template>

<script>
import App from '../App.vue'
import Configurator from '../components/configurator/Configurator.vue'
import LensesPageSeo from '../components/seo/LensesPageSeo.vue'
import BlueLightSeo from '../components/seo/lenses/BlueLightSeo.vue'
import products from '../components/lensablplus/storage/lensablplus/customer'
import {mapActions} from 'vuex'

export default {
    name: 'LandingPage',
    components: {
        Configurator,
        LensesPageSeo,
        BlueLightSeo
    },
    extends: App,
    computed: {
        includeSeoSection() {
            let currentUrl = window.location.pathname
            if (currentUrl === '/prescription-sunglass-lenses' || currentUrl === '/choose-my-lenses' || currentUrl === '/computer-blue-light-blocking-lenses') {
                return true
            } else {
                return false
            }
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablplus_customer/']) {
            this.$store.registerModule(['lensablplus_customer'], products(this.$store), { preserveState: !!this.$store.state['lensablplus_customer'] })
        }
    },
    created () {
        this.$store.dispatch('lensablplus_customer/checkRedeemablePath')
    },
    methods: {
        ...mapActions('lensablplus_customer', ['checkRedeemablePath'])
    }
}
</script>
