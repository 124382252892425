import {mapGetters, mapMutations} from 'vuex'
import preselectService from './storage/preselect-service'

export default {
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['configurator/preselect-service/']) {
            this.$store.registerModule(['configurator', 'preselect-service'], preselectService(this.$store))
        }

        this.$store.dispatch('configurator/preselect-service/fetchDefaultData')
    },
    computed: {
        ...mapGetters('configurator/preselect-service', ['getPreSelectValue']),
        ...mapGetters('values', ['values', 'checkoutData']),
        ...mapGetters('configurator/steps', ['currentStep'])
    },
    methods: {
        ...mapMutations('configurator/preselect-service', ['setPreSelectValue']),
        setPreSelectReadingType (value) {
            if (this.getPreSelectValue && this.getPreSelectValue.lens) {
                for (const key in value) {
                    value[key](this.getPreSelectValue.value[key])
                }
                this.triggerClickPreSelect()
            }
            return value
        },
        setPreSelectRx (prescription) {
            if (this.getPreSelectValue && this.getPreSelectValue.lens) {
                const data = this.checkoutData
                const type = this.getPreSelectValue.prescription_type
                if (data.product.indexOf(type) === -1) {
                    return prescription
                }
                prescription.name = this.getPreSelectValue.name
                prescription.id = this.getPreSelectValue.prescription_id
                for (const key in prescription[data.product]) {
                    prescription[data.product][key](this.getPreSelectValue.value[key])
                }
            }
            return prescription
        },
        triggerClickPreSelect () {
            this.$emit('next-step', this.currentStep)
        }
    }
}
