export const TEXT_PLUS_BANNER = {
    everyday: {
        containerClassName: 'product-lplus-ad-block',
        adImgUrl: '/media/wysiwyg/lensabl-plus-ee-ad.webp',
        adImgUrlMobile: '/media/wysiwyg/lensabl-plus-ee-ad-mobile.jpg',
        adhoverTextHtml: '<p>A new, better alternative to traditional vision insurance plans, starting as low as <b>$7.99/month.</b></p><p>Lensabl+ plans include a FREE Everyday Eyewear frame with premium Rx lenses, a reward for getting an eye exam, and up to 25% off all other Lensabl purchases. Save hundreds of dollars out-of-pocket per year on your vision care needs. <span class="c-help-bubble-link"><a href="/plus/vision_plan">Customize your plan now</a></span></p>'
    },
    shop_frames: {
        containerClassName: 'product-lplus-ad-block',
        adImgUrl: '/media/wysiwyg/lensabl-plus-shopframes-ad.webp',
        adImgUrlMobile: '/media/wysiwyg/lensabl-plus-shopframes-ad.webp',
        adhoverTextHtml: '<p>A new, better alternative to traditional vision insurance plans, starting as low as <b>$7.99/month.</b></p><p>Lensabl+ plans include an annual supply of contact lenses, big discounts on designer frames and premium Rx lenses, and a reward for getting an eye exam. Save hundreds of dollars out-of-pocket per year on your vision care needs. <span class="c-help-bubble-link"><a href="/plus/vision_plan">Customize your plan now</a></span></p>'
    },
}
