var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isSummary
      ? _c("span", {
          directives: [
            {
              name: "dompurify-html",
              rawName: "v-dompurify-html",
              value: _vm.prescriptionName,
              expression: "prescriptionName"
            }
          ],
          staticClass: "option-value",
          class: { fulfilled: _vm.hasPrescriptionName }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.isStepHeader
      ? _c("div", { staticClass: "header-reading-value" }, [
          _c("div", { staticClass: "reading-data" }, [
            _c("div", { staticClass: "reading-data-header" }, [_vm._v("Left")]),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.osLabel,
                  expression: "osLabel"
                }
              ],
              staticClass: "reading-data-value"
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "reading-data" }, [
            _c("div", { staticClass: "reading-data-header" }, [
              _vm._v("Right")
            ]),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.odLabel,
                  expression: "odLabel"
                }
              ],
              staticClass: "reading-data-value"
            })
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }