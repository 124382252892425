<template>
    <ScrollToFixed :data="{ref: 'membership-page-create-header', options: {baseClassName: 'content-header-floating'}}">
        <div class="content-header">
            <h3 class="icon-head head-adminhtml-plus-membership">Membership ID </h3>
            <p class="form-buttons">
                <button title="Back" type="button" class="scalable back" @click="goBack"><span><span><span>Back</span></span></span></button>
                <button title="Save" type="button" class="scalable" @click="save"><span><span><span>Save</span></span></span></button>
                <button title="Reset" type="button" class="scalable" onclick="setLocation(window.location.href)" style=""><span><span><span>Reset</span></span></span></button>
            </p>
        </div>
    </ScrollToFixed>
</template>

<script>
import {mapState} from 'vuex'
import ScrollToFixed from '../../../base/ScrollToFixed.vue'

export default {
    name: 'LensabalplusMembershipCreateContentHeader',
    components: {
        ScrollToFixed
    },
    computed: {
        ...mapState('lensablPlus/membership', ['backUrl'])
    },
    methods: {
        save (e) {
            this.$emit('save', e)
        },
        goBack () {
            window.location.href = this.backUrl
        }
    }
}
</script>

<style lang="less" scoped>
    .content-header-floating {
        display: block;
        width: 100% !important;
        left: 0 !important;
        right: 0 !important;
    }
</style>
