<template>
    <div class="spinner">
        <LoaderIcon />
    </div>
</template>

<script>
import LoaderIcon from '../../../assets/loader.svg'

export default {
    name: 'BaseLoader',
    components: {
        LoaderIcon
    }
}
</script>
