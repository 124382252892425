var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stepsCount
    ? _c(
        "div",
        { staticClass: "configuration-sequence" },
        [
          _vm._l(_vm.visibleList, function(step) {
            return [
              _c("Step", {
                key: step.id,
                staticClass: "product-configurator__steps-item",
                class: [step.id, step.stepState],
                attrs: { id: step.id, index: step.index },
                on: {
                  "next-step": _vm.nextStep,
                  "previous-step": _vm.previousStep
                }
              })
            ]
          }),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }