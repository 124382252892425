import {LENS_TYPE_ID, LENS_TYPE_SECTION_ID, NON_PRESCRIPTION_ID} from "../../../../../app/common/constants/lens_type";
import { LENS_OPTIONS_CODE, LENS_OPTION_CODE_167, LENS_OPTION_CODE_174, LENS_OPTION_CODE_CR39, LENS_OPTION_CODE_POLY, LENS_OPTION_CODE_TRIVEX } from "../lens_options";

const sunglassesGrey = {
    [LENS_TYPE_ID]: {
        [LENS_TYPE_ID]: 'sunglasses_lenses',
        [LENS_TYPE_SECTION_ID]: 'solid',
        color: {
            value: 'grey',
            level: 3
        }
    },
    [NON_PRESCRIPTION_ID]: {
        [NON_PRESCRIPTION_ID] : 'sunglasses'
    },
}

const sunglassesBrown = {
    [LENS_TYPE_ID]: {
        [LENS_TYPE_ID]: 'sunglasses_lenses',
        [LENS_TYPE_SECTION_ID]: 'gradient',
        color: {
            value: 'brown-gradient',
            level: 3
        }
    },
    [NON_PRESCRIPTION_ID]: {
        [NON_PRESCRIPTION_ID] : 'sunglasses'
    },
}

const sunglassesBlue = {
    [LENS_TYPE_ID]: {
        [LENS_TYPE_ID]: 'sunglasses_lenses',
        [LENS_TYPE_SECTION_ID]: 'solid',
        color: {
            value: 'blue',
            level: 1
        }
    },
    [NON_PRESCRIPTION_ID]: {
        [NON_PRESCRIPTION_ID] : 'sunglasses'
    },
}

const clearLens = {
    [LENS_TYPE_ID]: {
        [LENS_TYPE_ID]: 'clear_lenses',
    },
    [NON_PRESCRIPTION_ID]: {
        [NON_PRESCRIPTION_ID] : 'plano'
    },
}

const jamesOroDisabledLensOption = {
    [LENS_OPTIONS_CODE] : [
        LENS_OPTION_CODE_CR39
    ]
}

export const LIMITERS_JAMES_ORO = {
    preDefined: {
        'BLK-ACE': sunglassesGrey,
        'CLR-GLD-AUT': clearLens,
        'BLK-GLD-AUT': sunglassesGrey,
        'BLU-GLD-AUT': sunglassesBlue,
        'BRO-GLD-AUT': sunglassesBrown,
        'BLK-TITAN': sunglassesGrey,
        'BLK-AMO': sunglassesGrey,
        'CLR-SRP-AUT': clearLens,
        'BLK-HUN-2': sunglassesGrey,
        'CLR-GLD-AUT-XL': clearLens,
        'CLR-SLV-AUT': clearLens,
        'BLK-ARCH': sunglassesGrey,
        'BLK-DUN': sunglassesGrey,
        'GRL-BLK': sunglassesGrey,
        'CLR-PHN-AUT': clearLens,
        'BLU-PHN-AUT': sunglassesBlue,
        'BLK-PHN-AUT': sunglassesGrey,
        'BRWN-PHN-AUT': sunglassesBrown,
        'CLR-ARCH': clearLens,
        'CLR-GLD-BRN-AUT': clearLens,
        'BLK-CPO': sunglassesGrey,
        'BLK-BRI': sunglassesGrey,
        'BLK-BAL': sunglassesGrey,
        'CLR-RVL-STAR': clearLens,
        'CLR-KX-STAR': clearLens
    },
    remove_options_by_sku: {
        'CLR-GLD-AUT': jamesOroDisabledLensOption,
        'BLK-GLD-AUT': jamesOroDisabledLensOption,
        'BLU-GLD-AUT': jamesOroDisabledLensOption,
        'BRO-GLD-AUT': jamesOroDisabledLensOption,
        'CLR-GLD-AUT-XL': jamesOroDisabledLensOption,
        'CLR-SLV-AUT': jamesOroDisabledLensOption,
        'GRL-BLK': jamesOroDisabledLensOption,
        'CLR-PHN-AUT': jamesOroDisabledLensOption,
        'BLU-PHN-AUT': jamesOroDisabledLensOption,
        'BLK-PHN-AUT': jamesOroDisabledLensOption,
        'BRWN-PHN-AUT': jamesOroDisabledLensOption,
        'CLR-GLD-BRN-AUT': jamesOroDisabledLensOption,
        'CLR-GLD-BRN-GRL': jamesOroDisabledLensOption
    }
}
