var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        transition: "scale-transition",
        "offset-y": ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    attrs: {
                      readonly: "",
                      value: _vm.computedDateFormatted,
                      rules: [
                        function(v) {
                          return !!v || _vm.errText
                        }
                      ],
                      flat: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c("div", {
                                directives: [
                                  {
                                    name: "dompurify-html",
                                    rawName: "v-dompurify-html",
                                    value: _vm.label,
                                    expression: "label"
                                  }
                                ]
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.openCalendar,
        callback: function($$v) {
          _vm.openCalendar = $$v
        },
        expression: "openCalendar"
      }
    },
    [
      _vm._v(" "),
      _c("v-date-picker", {
        attrs: { locale: "en-in", max: _vm.maxDate, "no-title": "" },
        on: {
          input: function($event) {
            _vm.openCalendar = false
          }
        },
        model: {
          value: _vm.formModel[_vm.formKey],
          callback: function($$v) {
            _vm.$set(_vm.formModel, _vm.formKey, $$v)
          },
          expression: "formModel[formKey]"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }