var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rxreader-wrapper" },
    [
      _c("section", { staticClass: "section text-center" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "transition",
              {
                attrs: { appear: "", name: "slide-fade", mode: "out-in" },
                on: { "after-leave": _vm.afterLeave }
              },
              [
                true
                  ? _c(
                      "BaseLoader",
                      { key: "loading", attrs: { load: true } },
                      [
                        _c("h1", [_vm._v("Oops!")]),
                        _vm._v(" "),
                        _c("p", [_vm._v("This feature is no longer available")])
                      ]
                    )
                  : _c("div", { key: "loaded" }, [
                      _c("div", { attrs: { id: "glasseson" } })
                    ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "vue-modaltor",
        {
          staticClass: "glasseson-modal small",
          attrs: {
            visible: _vm.isModalOpen,
            "default-width": "350px",
            "bg-overlay": "",
            "bg-panel": "",
            "close-scroll": false,
            "animation-panel": "modal-slide-right"
          },
          on: { hide: _vm.hideModal }
        },
        [
          _c("p", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.modalMessage,
                expression: "modalMessage"
              }
            ],
            staticClass: "error-text-rx"
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }