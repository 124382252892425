var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.productView.productInfoShow,
              expression: "productView.productInfoShow"
            }
          ],
          ref: "spectacles-wrapper",
          staticClass:
            "product-configurator-configuration product-details-holder row spectacles-wrapper"
        },
        [
          _c("div", { staticClass: "product-name negative-bottom" }, [
            _c("h1", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.product_name,
                  expression: "product_name"
                }
              ],
              staticClass: "spectacles snapchat",
              class: _vm.product_name,
              attrs: { itemprop: "name" }
            })
          ]),
          _vm._v(" "),
          _c("Gallery"),
          _vm._v(" "),
          _vm.productView.colors.length > 1
            ? _c("div", { staticClass: "spectacles-radio" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "ul",
                  { attrs: { id: "colors-ul" } },
                  _vm._l(_vm.productView.colors, function(color, index) {
                    return _c(
                      "li",
                      { key: "color-" + color.color + "-" + index },
                      [
                        _c("label", [
                          _c("input", {
                            key: color.color,
                            attrs: {
                              id: color.color,
                              name: "color",
                              type: "radio"
                            },
                            domProps: {
                              value: index,
                              checked: index === _vm.checkedIndex
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeCurrentColor(index)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            directives: [
                              {
                                name: "dompurify-html",
                                rawName: "v-dompurify-html",
                                value: !_vm.valueAntiReflective
                                  ? color.color
                                  : color.color + " Polarized",
                                expression:
                                  "!valueAntiReflective ? color.color : color.color + ' Polarized'"
                              }
                            ],
                            class: color.color.toLowerCase()
                          })
                        ])
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "spectacles-polarized",
                    on: {
                      click: function($event) {
                        return _vm.toggleAntiReflective()
                      }
                    }
                  },
                  [
                    _c("input", {
                      attrs: { name: "polorized", type: "checkbox" },
                      domProps: { checked: _vm.valueAntiReflective }
                    }),
                    _c("span", [_vm._v("Add Polarized")])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.category.indexOf("Original") === -1 &&
          _vm.category.indexOf("Spectacles 3") === -1
            ? _c(
                "div",
                {
                  staticClass: "spectacles-poarized",
                  on: {
                    click: function($event) {
                      return _vm.toggleAntiReflective()
                    }
                  }
                },
                [
                  _c("p", [
                    _vm._v(
                      "Spectacles 2 " +
                        _vm._s(_vm.colorName) +
                        " are designed to have polarized lenses."
                    )
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: { name: "polorized", type: "checkbox" },
                    domProps: { checked: _vm.valueAntiReflective }
                  }),
                  _c("span", [_vm._v("Add Polarized (Recommended)")])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.category === "Spectacles 3"
            ? _c("div", {
                staticClass: "spectacles-poarized",
                on: {
                  click: function($event) {
                    return _vm.toggleAntiReflective()
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isMobile && !_vm.isDesktop
            ? _c("div", { staticClass: "product-options-bottom look-params" }, [
                _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { text: "Select lenses" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.mobileSwitcher()
                          }
                        }
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.isMobile ? "Select" : "Select lenses"
                            )
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isAvailable
        ? _c(
            "p",
            {
              staticClass: "unavailable stock",
              attrs: { title: "Availability" }
            },
            [
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v("This item is sold out")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("AnagramAdBar"),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "product-configurator-progress spectacles-configurator"
          },
          [
            _c("Configurator", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.productView.productConfiguratorShow,
                  expression: "productView.productConfiguratorShow"
                }
              ]
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      !_vm.showApp
        ? _c("div", { staticClass: "loader-container" }, [
            _c("div", { staticClass: "loader-el" }),
            _vm._v(" "),
            _c("div", { staticClass: "spectacles-terms" })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "step-configuration-header status-current clearfix" },
      [
        _c(
          "div",
          {
            staticClass: "step-title",
            staticStyle: {
              "margin-left": "0",
              position: "relative",
              "text-align": "left",
              "margin-top": "0",
              "font-size": "1rem",
              "line-height": "1.25rem",
              left: "-4px"
            }
          },
          [_vm._v("\n                    Change Lens Color\n                ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }