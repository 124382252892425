var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    { key: "row-" + _vm.field.name },
    [
      _c("label", { attrs: { for: "membership-" + _vm.field.name } }, [
        _vm._v("\n        " + _vm._s(_vm.field.label) + ":\n    ")
      ]),
      _vm._v(" "),
      [
        _c("input", {
          staticClass: "input-text",
          class: {
            "required-entry": _vm.field.required,
            "validation-failed": _vm.error
          },
          attrs: {
            id: "membership-" + _vm.field.name,
            readonly: _vm.field.disabled,
            maxlength: _vm.field.length,
            type: _vm.field.type
          },
          domProps: { value: _vm.value },
          on: {
            blur: function($event) {
              return _vm.validBlurField(_vm.field.name)
            },
            keyup: function($event) {
              return _vm.$emit("update", $event, _vm.field.name)
            }
          }
        })
      ],
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass: "validation-advice",
              attrs: { id: "advice-required-entry-" + _vm.field.name }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.field.message
                      ? _vm.field.message
                      : "This is a required field."
                  ) +
                  "\n    "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.passwordError
        ? _c(
            "div",
            {
              staticClass: "validation-advice",
              class: [_vm.confirmPwValidation, _vm.confirmPwIdentical]
            },
            [
              _c("p", { staticStyle: { "text-align": "left", margin: "0" } }, [
                _vm._v(
                  "Password must be at least 8 characters\n            and contain:"
                )
              ]),
              _vm._v(" "),
              _vm._m(0)
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticStyle: {
          "text-align": "left",
          "list-style": "circle",
          "list-style-position": "inside"
        }
      },
      [
        _c("li", [_vm._v("at least 1 number")]),
        _vm._v(" "),
        _c("li", [_vm._v("at least 1 lowercase letter")]),
        _vm._v(" "),
        _c("li", [_vm._v("at least 1 uppercase letter")]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "at least 1 special characters _@!#$^%&*()+=-[ ]';,./{ }\\|\":<>?"
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }