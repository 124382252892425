<template>
    <div class="pd_selector">
        <div class="pd-parameters">
            <div class="pd-label">
                <span>
                    <span>Pupillary Distance (PD)&nbsp;</span>
                    <HelpBubble :data="help.enter_pd" />
                </span>
            </div>

            <div v-if="!value.separate_pd" class="pd-input-container">
                <CustomSelect v-if="showEditVersion" :value="value.pd" :options="eyePDOptions" class="pd-input" @change="setPrescriptionValue($event.target.value, 'pd')" />
                <div v-else class="pd-input">
                    <span v-text="value.pd"></span>
                </div>
            </div>

            <div v-else class="pd-input-container separate-pd-values">
                <CustomSelect v-if="showEditVersion" :value="value.od_pd" :options="eyeODPDOptions" class="pd-input" @change="setPrescriptionValue($event.target.value, 'od_pd')" />
                <div v-else class="pd-input">
                    <label>RIGHT (OD)</label>
                    <span v-text="value.od_pd"></span>
                </div>

                <CustomSelect v-if="showEditVersion" :value="value.os_pd" :options="eyeOSPDOptions" class="pd-input" @change="setPrescriptionValue($event.target.value, 'os_pd')" />
                <div v-else class="pd-input">
                    <label>LEFT (OS)</label>
                    <span v-text="value.os_pd"></span>
                </div>
            </div>
        </div>
        <div v-if="showEditVersion" class="pd_checkbox enter-it-online">
            <RadioLabel
                :active="value.separate_pd"
                label="I have two PD numbers"
                @click="value.separate_pd = !value.separate_pd"
            />
        </div>
    </div>
</template>

<script>
import PrescriptionTable from './PrescriptionTable.vue'
import RadioLabel from '../../components/base/RadioLabel.vue'

export default {
    name: 'PrescriptionPupillaryDistance',
    components: {
        RadioLabel
    },
    extends: PrescriptionTable
}
</script>
