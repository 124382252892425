<template>
    <div class="search-component">
        <div class="search-container">
            <div class="search-container-main">
                <div>
                    <input
                        id="category-search"
                        ref="search"
                        v-model="searchBrand"
                        type="text"
                        placeholder="Enter Brand"
                        @focus="searchResultsVisible = true"
                        @keydown.esc="blur"
                        @input="softRest"
                        @keyup="performSearch"
                        @blur="setHighlightIndex(), searchResultsVisible = false"
                        @keydown.up.prevent="highlightPrevious"
                        @keydown.down.prevent="highlightNext"
                        @keydown.enter="goToLink"
                    />
                </div>
                <div v-if="searchBrand.length > 0 && searchResultsVisible" class="results-dropdown">
                    <ul id="results">
                        <a
                            v-for="(product, index) in searchResults"
                            :key="index"
                            :class="{active: index === highlightedIndex}"
                            @mousedown.prevent="searchResultsVisible = true"
                            @mouseover="hover(index)"
                            @click="chooseBrand(product)"
                        >
                            <li v-dompurify-html="collection[index]"></li>
                        </a>
                        <li v-if="searchResults.length === 0" class="no-results">
                            No results for <strong>{{ searchBrand }}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainSearch from '../../../contacts/Search.vue'
import Fuse from 'fuse.js'
import category from '../../../catalog/category/storage/category/view.js'

export default {
    extends: MainSearch,
    props: {
        search_in: {
            type: [Array],
            default: () => []
        },
        options_rx: {
            type: [Object],
            default: () => {}
        },
        eyes: {
            type: [Array],
            default: () => []
        },
        holder: {
            type: [String],
            default: () => ''
        }
    },
    watch: {
        searchBrand (name) {
            if (!name) {
                const options = Object.assign({}, this.options_rx)
                this.eyes.forEach(key => {
                    options[key] = {}
                })
                this.$emit('update:options_rx', options)
            }
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['category/']) {
            this.$store.registerModule('category', category(this.$store))
        }
        this.$store.dispatch('category/fetchDefaultData')
    },
    mounted () {
        this.displayProducts()
    },
    methods: {
        displayProducts () {
            this.searchBrand = ''
            this.blur()
        },
        performSearch () {
            const collection = []
            this.collection = []
            const fuse = new Fuse(this.search_in, this.options)
            const results = fuse.search(this.searchBrand)
            results.forEach(item => {
                let text = item.matches[0].value
                text = this.removeEntityCode(text)
                if (text) {
                    let offset = 0
                    item.matches[0].indices.forEach(index => {
                        text = this.highlightText(text, index[0] + offset, index[1] + offset)
                        offset += 31
                    })
                    this.collection.push(text)
                }
                collection.push(item.item)
            })
            this.searchResults = collection
        },
        removeEntityCode (product) {
            let productName = product

            if (productName.includes('&reg;')) {
                productName = productName.replace(/&reg;/g, '')
            }

            if (productName.includes('&trade;')) {
                productName = productName.replace(/&trade;/g, '')
            }

            return productName
        },
        chooseBrand (item) {
            const options = Object.assign({}, this.options_rx)
            this.$emit('update:options_rx', options)
            this.eyes.forEach(key => {
                options[key] = item
            })
            this.$emit('update:options_rx', options)
            this.displayProducts()
            let productName = item.name

            if (productName.includes('&reg;')) {
                productName = productName.replace(/&reg;/g, '')
            }

            if (productName.includes('&trade;')) {
                productName = productName.replace(/&trade;/g, '')
            }

            this.searchBrand = productName

            this.searchResultsVisible = false
        }
    }
}
</script>
