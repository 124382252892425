<template>
    <div class="onestepcheckout-coupons discount-block">
        <div class="clearfix">
            <div id="coupon-notice" style="display: none;"></div>
            <div v-if="getOpenForm">
                <span class="gift-card-question">Have a promo code?</span>
                <span class="gift-card-here" @click="showInput = true">Click here</span>
            </div>
            <div v-else id="coupon_block" class="collapse-block">
                <span class="discount-label">Enter Promo Code</span>
                <input
                    v-model="coupon"
                    type="text"
                    name="onestepcheckout-couponcode"
                    autocomplete="off"
                    class="onestepcheckout-couponcode input-text"
                />
                <button
                    id="onestepcheckout-coupon-add"
                    class="button apply-coupon"
                    type="button"
                    @click="applyCoupon"
                >
                    <span><span>Apply</span></span>
                </button>
            </div>
            <p
                v-for="(coupon_code, index) in COUPONS"
                :key="index"
                class="coupon-added"
            >
                <span v-text="coupon_code"></span>
                <a class="coupon-remove" @click="() => removeCoupon(coupon_code)">Remove</a>
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import _filter from 'lodash/filter'
import _size from 'lodash/size'

export default {
    name: 'Coupon',
    data () {
        return {
            coupon: '',
            showInput: false
        }
    },
    computed: {
        ...mapGetters('checkoutPlus', ['COUPONS', 'IS_QUARTERLY_PAY']),
        getOpenForm () {
            return !_size(this.COUPONS) && !this.showInput
        }

    },
    watch: {
        IS_QUARTERLY_PAY (newVal) {
            if (!newVal) {
                [...this.COUPONS].forEach(code => {
                    this.removeCoupon(code, false)
                })
            }
        }
    },
    mounted () {
        this.$store.dispatch('checkoutPlus/GET_COUPONS')
    },
    methods: {
        ...mapActions('checkoutPlus', ['GET_COUPONS', 'ADD_COUPON']),
        applyCoupon () {
            const setObject = {'code': this.coupon.trim()}
            this.$store.dispatch('checkoutPlus/ADD_COUPON', {setObject})
            this.coupon = ''
        },
        removeCoupon (coupon_code, alertMsg = true) {
            this.coupon = ''
            const newCoupons = _filter([...this.COUPONS], (coupon) => coupon !== coupon_code)
            const setObject = {'code': newCoupons.join(',')}
            this.$store.dispatch('checkoutPlus/ADD_COUPON', {setObject, alertMsg})
        }
    }
}
</script>
