import * as abstract from '../../../storage/abstract'
import {DEFAULT_VALUES} from "./constants";
import axios from "axios";


export const state = store => ({
    ...abstract.state(),
    values: DEFAULT_VALUES,
    final: DEFAULT_VALUES,
    membershipData: {
        logo: '',
        name: ''
    },
    api: {
        urls: {
            submitCustomer: '/membership/index/submitCustomer',
            membershipData: '/membership/index/getMembershipData',
            membershipDiscountData: '/membership/acount/getMembershipDiscountData',
        }
    }
})

export const getters = {
    values: state => state.values,
    final: state => state.final,
    membershipData: state => state.membershipData,
    token:  (state, getters, rootState, rootGetters) => {
        return rootGetters.rootState ? rootGetters.rootState.token : ''
    }

}

export const actions = {
    ...abstract.actions,
    getMembershipData: ({state, getters, commit}) => {
        return axios.post(state.api.urls.membershipData, {token: getters.token}).then(({data, status}) => {
            commit('SET_MEMBERSHIP_DATA', data)
        })
    },
    submitCustomer: ({state, getters}) => {
        const params = {}
        params['token'] = getters.token
        params ['customer'] = getters.final
        return axios.post(state.api.urls.submitCustomer, params)
            .then(({data, status}) => {
                if (status === 201) {
                    window.location.href = '/membership/account'
                }
            })

    },
}

export const mutations = {
    ...abstract.mutations,
    SET_VALUES: (state, payload = {}) => state.values = payload,
    SET_FINAL: (state, payload = {}) => state.final = payload,
    SET_MEMBERSHIP_DATA: (state, payload = {}) => state.membershipData = payload,
}

export default (store = {}) => ({
    namespaced: true,
    state: state(store),
    getters,
    actions,
    mutations
})
