<template>
    <div class="bluelight-seo-container">
        <section class="bluelight-seo-section bluelight-seo-top-section">
            <div class="bluelight-seo-row bluelight-seo-top-row">
                <div class="bluelight-seo-text bluelight-seo-top-text">
                    <h2>Blue-Light-Blocking Lenses</h2>
                    <p>Screens like your computer monitor, tablet or TV screen emit blue light that can leave you feeling discomfort and cause long-term damage. Blue light lenses can protect your eyes during long hours in front of a digital screen.<br /><br />Lensabl carries a <a href="/choose-my-lenses" title="Lens Replacement"><span class="bluelight-seo-link">wide selection of lenses</span></a> and <a href="/shop-frames" title="Shop Frames"><span class="bluelight-seo-link">stylish frames</span></a> for men and women with or without a prescription so you can order custom blue light lenses online today. Customers with existing prescriptions can <a href="/online-vision-prescription" title="Online Vision Prescription"><span class="bluelight-seo-link">take an online vision eye exam</span></a> for an accurate update or renewal.</p>
                </div>
                <div class="bluelight-seo-img">
                </div>
            </div>
        </section>

        <section class="bluelight-seo-frames-container bluelight-seo-section">
            <div class="bluelight-seo-row bluelight-seo-frames-row">
                <div class="bluelight-seo-frames-img">
                </div>
                <div class="bluelight-seo-text bluelight-seo-frames-text">
                    <h2>Purchase Custom Blue Light Lenses Online</h2>
                    <p>Blue light lenses filter out blue light before it can reach your eyes. Lensabl's blue light lens options expand on blue light filtration with an anti-reflective coating for optimal eye protection for wearers with or without prescriptions.<br /><br />Browse our inventory to find prescription, non-prescription and blue-light <a href="/reading-lenses" title="Reading Lenses"><span class="bluelight-seo-link">reading glasses online</span></a>. Customers can match their blue light lenses with a fashionable frame from <a href="/glasses-frames" title="Glasses Frames"><span class="bluelight-seo-link">top designers</span></a> like <a href="/shop-frames?category=ray-ban" title="Ray-Ban"><span class="bluelight-seo-link">Ray-Ban</span></a>, <a href="/shop-frames?category=kate-spade" title="Kate Spade"><span class="bluelight-seo-link">Kate Spade</span></a> or <a href="/shop-frames?category=oakley" title="Oakley"><span class="bluelight-seo-link">Oakley</span></a>.<br /><br />Lensabl makes it easy to <a href="/computer-blue-light-blocking-lenses" title="Blue Light Blocking Lenses"><span class="bluelight-seo-link">order blue light lenses online</span></a> — choose the lenses you want and set your prescription type if applicable. Our blue light lenses are available in numerous thicknesses and power levels. Your eye health matters, so buy blue-light-blocking lenses to prevent damage.</p>
                </div>
            </div>
        </section>

        <section class="bluelight-seo-benefit-container bluelight-seo-section">
            <div class="bluelight-seo-row bluelight-seo-benefit-row">
                <div class="bluelight-seo-text bluelight-seo-benefit-text">
                    <h2>How Can Blue Light Glasses Benefit You? </h2>
                    <p>Blue light glasses reduce the symptoms you experience while working in front of screens for long hours and after you power down for the day. Wear blue light lenses to prevent:</p>
                </div>
                <ul class="bluelight-seo-benefit-list">
                    <li>Headaches</li>
                    <li>Blurry vision</li>
                    <li>Eyestrain</li>
                    <li>Sleep loss</li>
                    <li>Dryness</li>
                </ul>
            </div>
        </section>

        <section class="bluelight-seo-purchase-container bluelight-seo-section">
            <div class="bluelight-seo-row bluelight-seo-purchase-row">
                <div class="bluelight-seo-text bluelight-seo-purchase-text">
                    <h2>Purchase Blue Light Glasses From Lensabl</h2>
                    <p>Order blue light prescription glasses for men and women online through Lensabl. You'll receive fast delivery, risk-free returns and a 12-month warranty. You can experience even more benefits when you <a href="/plus" title="plus"><span class="bluelight-seo-link">register for Lensabl+</span></a>, our online vision plan that provides savings on glasses and personalized attention from real doctors.</p>
                </div>
                <div class="bluelight-seo-btns">
                    <a href="/choose-my-lenses" title="Lens Replacement">
                        <div class="button button--copy-solid">
                            <div class="button__container seo-btn-lr">SHOP LENS REPLACEMENT</div>
                        </div>
                    </a>
                    <a href="/shop-frames" title="Shop Frames">
                        <div class="button button--copy-solid">
                            <div class="button__container">SHOP FRAMES</div>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'BlueLightSeo'
}
</script>
