<template>
    <div class="options_container upgrades warranty">
        <div class="option-upgrades">
            <div v-if="info.label">
                <div :class="{'has-thumbnail': !!info.image}" class="option-container upgrades-container">
                    <div v-if="info.image" class="option-thumbnail">
                        <img :src="assets_url + info.image" class="warranty-thumbnail" />
                    </div>
                    <div class="option-descriptions title">
                        <span class="option-label">
                            <span v-dompurify-html="info.label" class="label-text"></span>
                            <span class="label-optional">(optional)</span>
                        </span>
                    </div>
                    <div class="extra-component-container">
                        <div class="extra-component">
                            <span v-dompurify-html="info.desc"></span>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="options">
                <template v-for="option in steps_warranty">
                    <Option :key="option.id" :component-help-show="isMobile" :option="option" class="step-configuration-content" />
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import Option from './Option'
import {WARRANTY_INFO, WARRANTY_STEP, WARRANTY_PRICE_CHANGE} from '../../../app/common/constants/warranty'

export default {
    name: 'SelectorWarranty',
    components: {
        Option
    },
    props: {
        id: { // Step Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        configs: [...WARRANTY_STEP],
        info: {...WARRANTY_INFO},
        steps_warranty: []
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('values', ['values']),
        ...mapGetters('configurator', ['warranty_section']),
        ...mapGetters('storeView', ['assets_url']),
        ...mapGetters('configurator/steps', ['getStepObject']),
        ...mapGetters('values', ['checkoutData', 'productType']),
        ...mapGetters('summary', ['computePriceFromConfig']),

        isStepCurrent () {
            return this.step.isCurrent
        },
        step () {
            return this.getStepObject('step_antireflective')
        }
    },
    watch: {
        isStepCurrent (current) {
            if (current) {
                this.optionsUpdate()
            }
        }
    },
    created () {
        this.updateCheckedValue()
        this.steps_warranty = this.configs
    },
    methods: {
        ...mapMutations('values', ['resetDataToValues', 'setDataToValues']),
        updateCheckedValue () {
            const warranty = this.values.warranty
            this.configs.map(config => {
                this.$set(config, 'checked', !!warranty[config.value])
                config.price = this.warranty_section[config.value] ? this.warranty_section[config.value] : config.price
            })
        },
        addWarrantyToCart (object) {
            const checked = object.checked
            const newChecked = !checked
            const warranty = {}
            this.resetDataToValues({warranty})
            if (newChecked) {
                this.steps_warranty.map(item => {
                    item.checked = false
                })
                warranty[object.value] = object.price
            }
            object.checked = newChecked
            this.setDataToValues({warranty})
        },
        optionsUpdate () {
            this.changeValueStepVisible()
        },
        changeValueStepVisible () {
            const {product, lens_options} = this.checkoutData
            const prescriptionAR = WARRANTY_PRICE_CHANGE[product] || false

            const lensOptions = lens_options['name'] ? lens_options['name'].replace(' + Standard AR', '') : false

            if (!prescriptionAR) {
                return false
            }

            if (!lensOptions) {
                return false
            }

            const lensAR = prescriptionAR[lensOptions] || false

            if (!lensAR) {
                return false
            }

            this.steps_warranty[0].price = lensAR.price
            this.steps_warranty[0].value = lensAR.sku

            const warranty = {}
            warranty[lensAR.sku] = lensAR.price

            if (this.steps_warranty[0].checked) {
                this.resetDataToValues({warranty})
            }
        }
    }
}
</script>
