import axios from 'axios'
import * as abstract from '../../../../storage/abstract'

export const state = () => ({
    ...abstract.state(),
    api: {
        urls: {
            uploadUrl: '/lensabl/upload',
            updateReceiptRewardsUrl: '/plus/account/updateReceiptRewards'
        }
    }
})

export const getters = {
    uploadUrl: state => state.api.urls.uploadUrl,
    dataURL: (state, getters) => (getters.valuesUpload || {}).data_url,
    fileName: (state, getters) => (getters.valuesUpload || {}).file_name,
    valuesUpload: (state, getters, rootState, rootGetters) => {
        const values = rootGetters['values/values']
        return getters.isContactDetailsPage ? values.upload : values.prescriptionValues.upload
    },
    isContactDetailsPage: (state, getters, rootState, rootGetters) => rootGetters.rootState.key === 'contacts'
}

export const actions = {
    ...abstract.actions,
    updateDataUrl: ({getters, commit}, url = '') => {
        if (getters.isContactDetailsPage) {
            commit('values/setDataToValues', {
                upload: {
                    data_url: url
                }
            }, { root: true })
        } else {
            commit('values/setDataToValues', {
                prescriptionValues: {
                    upload: {
                        data_url: url
                    }
                }
            }, { root: true })
        }
    },
    updateFileName: ({getters, commit}, name = '') => {
        if (getters.isContactDetailsPage) {
            commit('values/setDataToValues', {
                upload: {
                    file_name: name
                }
            }, { root: true })
        } else {
            commit('values/setDataToValues', {
                prescriptionValues: {
                    upload: {
                        file_name: name
                    }
                }
            }, { root: true })
        }
    },
    updateReceiptRewards: ({state, commit, rootGetters}, data) => { // Vision Exam Benefit
        return axios.post(state.api.urls.updateReceiptRewardsUrl, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}

export const mutations = {
    ...abstract.mutations
}

export default () => ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
