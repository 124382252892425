var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.showFramesPrice
      ? _c("div", { staticClass: "notice" }, [
          _vm._v(
            "\n        All lenses include specific coatings for:\n        "
          ),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1)
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isSpectacles
      ? _c("div", { staticClass: "notice" }, [
          _vm._v("Don't need a prescription?"),
          _c("br"),
          _c("a", { attrs: { href: "https://www.spectacles.com" } }, [
            _vm._v("Return to Spectacles")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "lens-icon-list" }, [
      _c("li", { staticClass: "icon anti-reflective" }, [
        _c("span", [_vm._v("Anti"), _c("br"), _vm._v("Reflection")])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "icon ultraviolet" }, [
        _c("span", [_vm._v("100% UV"), _c("br"), _vm._v("Protection")])
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "icon scratch-resistant" }, [
        _c("span", [_vm._v("Scratch"), _c("br"), _vm._v("Resistance")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _vm._v("We include a Case and Cleaning Kit"),
        _c("br"),
        _vm._v("with all orders.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }