var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isCurrent,
          expression: "isCurrent"
        }
      ],
      class: _vm.id
    },
    [
      _vm.config.title
        ? _c("h2", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.config.title,
                expression: "config.title"
              }
            ],
            staticClass: "title"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "plans_container" },
        _vm._l(_vm.plans, function(plan, index) {
          return _c(
            "label",
            {
              key: plan.id + "-" + index,
              staticClass: "plan-option",
              class: { active: _vm.selected[plan.sku] },
              attrs: { for: plan.id }
            },
            [
              plan.label !== "exam only"
                ? [
                    _c("input", {
                      attrs: {
                        id: plan.id,
                        title: plan.name,
                        type: "checkbox",
                        name: "plans"
                      },
                      on: {
                        input: function($event) {
                          return _vm.togglePlans(plan.sku)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "plan-container" }, [
                      _c("div", { staticClass: "plan-label" }, [
                        _c("span", [
                          _c(
                            "strong",
                            {
                              staticClass: "plan-name",
                              style: { color: plan.color }
                            },
                            [
                              _vm._v(
                                "2021 " +
                                  _vm._s(plan.label) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(" $" + _vm._s(plan.price) + " per month")
                        ])
                      ])
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "validation-advice" }, [
            _vm._v("Please choose a plan.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h5", [_vm._v("Who is going to pay?")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pay-container" },
        _vm._l(_vm.payOptions, function(option, index) {
          return _c(
            "label",
            {
              key: index,
              staticClass: "plan-option",
              class: { active: _vm.payer === option },
              attrs: { for: option }
            },
            [
              _c("input", {
                attrs: {
                  id: option,
                  title: option,
                  type: "radio",
                  name: "payer"
                },
                on: {
                  click: function($event) {
                    return _vm.togglePayer(option)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "plan-container" }, [
                _vm._v("\n                " + _vm._s(option) + "\n            ")
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.payerError
        ? _c("div", { staticClass: "validation-advice" }, [
            _vm._v("Please choose who is responsible for payment.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "actions-toolbar" }, [
        _c(
          "div",
          { staticClass: "primary" },
          [
            _c("BaseButton", {
              staticClass: "button--copy-solid-white-blue button--small",
              attrs: { text: "Continue" },
              nativeOn: {
                click: function($event) {
                  return _vm.continueNxt($event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "description" }, [
      _vm._v(
        "\n        Select which plans that you would like to offer your employees\n        "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "/js/lensconfigurator/assets/plans/group_plans.pdf",
            download: ""
          }
        },
        [
          _vm._v(
            "\n            Download PDF to view all plan details.\n        "
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }