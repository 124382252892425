<template>
    <div>
        <div class="return-button-container">
            <button class="return-button selected" @click="toggleReturn(returns, $event)">Eyeglasses</button>
            <button v-if="!hideContactsTab" class="return-button" @click="toggleReturn(everyday, $event)">Contacts</button>
        </div>
        <div id="returns" class="return-section">
            <List :prescriptions="lens_prescriptions" :type="lens" />
        </div>
        <div id="everyday" class="return-section">
            <List :prescriptions="contacts_prescriptions" :type="contacts" />
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import prescriptions from '../../prescriptions/storage/prescriptions'
import prescriptionsCurrent from '../../prescriptions/storage/prescriptions/current'
import allPrescriptions from '../../prescriptions/storage/prescriptions/prescription'
import List from './List.vue'

export default {
    name: 'CustomerPrescription',
    components: {
        List
    },
    data: () => ({
        returns: 'returns',
        everyday: 'everyday',
        lens: 'lens',
        contacts: 'contacts'
    }),
    computed: {
        ...mapGetters('prescriptions/prescription/', ['lens_prescriptions', 'contacts_prescriptions', 'hideContactsTab'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['prescriptions/current/']) {
            this.$store.registerModule(['prescriptions', 'current'], prescriptionsCurrent(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['prescriptions/prescription/']) {
            this.$store.registerModule(['prescriptions', 'prescription'], allPrescriptions(this.$store))
        }
    },
    methods: {
        toggleReturn (pageName, event) {
            const element = event.target
            const page = document.getElementById(pageName)
            const tabcontent = document.getElementsByClassName('return-section')
            const tablinks = document.getElementsByClassName('return-button')
            for (let i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = 'none'
                tablinks[i].classList.remove('selected')
            }
            page.style.display = 'block'
            element.classList.add('selected')
        },
        editRX (id) {
            document.getElementById(id).classList.add('edit')
        }
    }
}
</script>

<style scoped>

</style>
