<template>
   <div :class="[brand + '-seo-parent-container']">
        <div class="shopframes-seo-prescription-container">
            <div class="shopframes-seo-row seo-prescription">
                <div class="shopframes-seo-text">
                    <h2>Hugo Boss Glasses</h2>
                    <p>You can find many of the most popular Hugo Boss glasses online through Lensabl. Browse our Hugo Boss selection to order a luxury frame that meets your fashion and lifestyle needs.</p>
                </div>
                <div class="shopframes-seo-img">
                </div>
            </div>
        </div>

        <div class="shopframes-seo-frames-container">
            <div class="shopframes-seo-row seo-frames">
                <div class="shopframes-seo-frames-img">
                </div>
                <div class="shopframes-seo-text">
                    <h2>Hugo Boss Frames Online</h2>
                    <p>Lensabl understands you want luxury frames that are as durable as they are stylish. That's why we carry Hugo Boss frames that give the best of both worlds. You can choose from signature Hugo Boss men's and women's eyeglass styles for a frame that matches your entire ensemble.<br /><br />Beyond style, our Hugo Boss frames are comfortable and durable. They feature strong, lightweight metal and plastic materials. Use our size finder tool to buy the perfect Hugo Boss frames to fit your face. Ready for a pair of luxury glasses that you'll look and feel good wearing? Buy Hugo Boss glasses from Lensabl.</p>
                </div>
            </div>
        </div>

        <div class="shopframes-seo-designer-container">
            <div class="shopframes-seo-row seo-designer">
                <div class="shopframes-seo-text">
                    <h2>Hugo Boss Prescription Glasses</h2>
                    <p>Lensabl is here to make sure your fashion-forward glasses are equal parts functional and stylish. We can fill your Hugo Boss frames with prescription lenses that match your needs down to the finest detail. Choose either a single vision, bifocal or progressive lens for up-close or long-distance optical correction. We also have a variety of clear, sunglass and Transitions® lenses for Hugo Boss frames.<br /><br />You can <a href="/prescription-scanner-app/qr"><span class="seo-contact-link">scan your glasses</span></a> to input your prescription information automatically. If it's time to renew your prescription, we have you covered. We invite you to <a href="/online-vision-prescription"><span class="seo-contact-link">take an online eye exam</span></a> for total confidence while you shop.</p>
                </div>
            <div class="shopframes-seo-designer-img">
            </div>
            </div>
        </div>

        <div class="shopframes-seo-eyeglass-container">
            <div class="shopframes-seo-row seo-eyeglass">
                <div class="shopframes-seo-text seo-eyeglass-text">
                    <h2>Buy Hugo Boss Frames Online Through Lensabl</h2>
                    <p>Are you in the market for luxury glasses you can wear in any setting? Lensabl makes it easy to shop for Hugo Boss prescription glasses from your home. Order your Hugo Boss frames online today!</p>
                </div>
                <a href="/glasses-frames" title="Glasses Frames">
                    <div class="button button--copy-solid">
                        <div class="button__container">GET NEW FRAMES</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HugoBossSeo',
    props: {
        brand: {
            type: [String],
            default: ''
        }
    },
}
</script>
