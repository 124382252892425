var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-modaltor",
    {
      staticClass: "redeem-modal",
      attrs: {
        visible: _vm.isModalOpen,
        "default-width": "100%",
        "bg-overlay": "",
        "bg-panel": "",
        "close-scroll": false
      },
      on: { hide: _vm.hideModal }
    },
    [
      _c(
        "div",
        { staticClass: "redeem-container" },
        [
          _c("h2", [_vm._v("Redeeming your")]),
          _vm._v(" "),
          _c("h3", { domProps: { textContent: _vm._s(_vm.subtitle) } }),
          _vm._v(" "),
          Array.isArray(_vm.requirements) && _vm.requirements.length
            ? _c("div", { staticClass: "requirements-container" }, [
                _c("h4", {
                  domProps: { textContent: _vm._s(_vm.instruction) }
                }),
                _vm._v(" "),
                _c(
                  "ol",
                  _vm._l(_vm.requirements, function(requirement, index) {
                    return _c("li", { key: index }, [
                      _c("div", [
                        _c("span", { staticClass: "requirement-number" }, [
                          _vm._v(_vm._s(index + 1))
                        ]),
                        _c("p", [_vm._v(_vm._s(requirement))])
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _c("div", [
                _c("h4", { domProps: { textContent: _vm._s(_vm.instruction) } })
              ]),
          _vm._v(" "),
          _c("BaseButton", {
            attrs: {
              loader: _vm.loader,
              type: "submit",
              text: "CONTINUE",
              tabindex: "3"
            },
            nativeOn: {
              click: function($event) {
                return _vm.continueTo($event)
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }