var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plus-totals-summary" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.ITEMS, function(item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("div", { staticClass: "plus-total-container" }, [
              _c("div", { staticClass: "plus-total" }, [
                _vm._m(1, true),
                _c("span", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: item.full_price_html,
                      expression: "item.full_price_html"
                    }
                  ]
                })
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "price-label" }, [
                _vm._v("\n                Starting at $"),
                _c("span", {
                  directives: [
                    {
                      name: "dompurify-html",
                      rawName: "v-dompurify-html",
                      value: _vm.getAffirmPrice,
                      expression: "getAffirmPrice"
                    }
                  ]
                }),
                _vm._v("/mo with\n                "),
                _c(
                  "span",
                  {
                    staticClass:
                      "__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed"
                  },
                  [_vm._v("Affirm")]
                ),
                _vm._v(".\n                "),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.affirmModalShow()
                      }
                    }
                  },
                  [_vm._v("Learn more")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("Coupon")
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "totals" }, [
        _c("div", { staticClass: "summary-totals" }, [
          _c("div", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.TOTALS_HTML.total,
                expression: "TOTALS_HTML.total"
              }
            ]
          }),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "dompurify-html",
                rawName: "v-dompurify-html",
                value: _vm.TOTALS_HTML.total_footer,
                expression: "TOTALS_HTML.total_footer"
              }
            ]
          })
        ])
      ]),
      _vm._v(" "),
      _c("BaseButton", {
        attrs: {
          text: "Place order",
          idName: "place-order",
          disabled: _vm.isPlaceOrderDisabled,
          loader: _vm.isLoadButton
        },
        nativeOn: {
          click: function($event) {
            return _vm.prepareCheckoutForm($event)
          }
        }
      }),
      _vm._v(" "),
      _vm._m(2)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "summary-block-header" }, [
      _c("h3", [_vm._v("Order Summary")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("Total Vision Plan Cost"),
      _c("span", { staticClass: "desktop" }, [_vm._v(" (Per Year)")]),
      _vm._v(": ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "plus-disclaimer" }, [
      _vm._v("By checking out you are agreeing to our "),
      _c("a", { attrs: { href: "/returns" } }, [
        _vm._v("terms & conditions and\n        cancellation policy")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }