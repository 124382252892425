<template>
    <CatalogProductSpectaclesView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogProductSpectaclesView from '../../../components/catalog/product/CatalogProductSpectaclesView.vue'

export default {
    name: 'RouterCatalogProductSpectaclesView',
    components: {
        CatalogProductSpectaclesView
    },
    extends: App
}
</script>
