<template>
    <div>
        <div v-if="memberShipEdited">
            <div id="membership-billing_address_choice" class="membership-choose-address">
                Select from existing customer addresses:<br />
                <select id="membership-billing_address_customer_address_id" v-model="address_id" class="select">
                    <option value="">Add New Address</option>
                    <template v-for="address in addresses">
                        <option :key="address.entity_id" :value="address.entity_id" v-text="addressStringify(address)"></option>
                    </template>
                </select>
            </div>

            <div id="membership-billing_address_fields" class="membership-address">
                <div class="content">
                    <div class="hor-scroll">
                        <table cellspacing="0" class="form-list">
                            <tbody>
                                <tr v-for="template in addressTemplateData" :key="`row-${template.name}`">
                                    <td class="label">
                                        <label :for="`membership-billing_address_${template.name}`">
                                            {{ template.label }} <span v-if="template.required" class="required">*</span>
                                        </label>
                                    </td>
                                    <td class="value">
                                        <template v-if="template.options">
                                            <template v-if="template.type === 'text'">
                                                <div v-for="(option, index) in template.options" :key="`${template.name}-option-${index}`" class="multi-input">
                                                    <input
                                                        v-if="option.type === 'text'"
                                                        :id="`membership-billing_address_${template.name}-${index}`"
                                                        v-model="currentBillingAddress[template.name][index]"
                                                        :name="`billing[${template.name}]`"
                                                        :data-recurly="option.dataRecurly"
                                                        :class="{'required-entry': option.required, 'validation-failed': validateField(template.name)}"
                                                        class="input-text"
                                                        type="text"
                                                    />
                                                </div>
                                            </template>
                                            <template v-if="template.type === 'select'">
                                                <select
                                                    :id="`membership-billing_address_${template.name}`"
                                                    v-model="currentBillingAddress[template.name]"
                                                    :name="`billing[${template.name}]`"
                                                    :data-recurly="template.dataRecurly"
                                                    :class="{'required-entry': template.required, 'validation-failed': validateField(template.name)}"
                                                    class="select"
                                                >
                                                    <template v-if="template.options.length">
                                                        <template v-for="option in template.options">
                                                            <option v-if="option.value" :key="`${template.name}-option-${option.value}`" :value="option.value" v-text="option.label"></option>
                                                        </template>
                                                    </template>
                                                </select>
                                                <template v-if="template.textValue">
                                                    <input
                                                        v-model="currentBillingAddress[template.textValue.name]"
                                                        :name="`billing[${template.textValue.name}]`"
                                                        :data-recurly="template.textValue.dataRecurly"
                                                        :type="template.textValue.type"
                                                    />
                                                </template>
                                            </template>
                                        </template>

                                        <template v-else>
                                            <input
                                                v-if="template.type === 'text'"
                                                :id="`membership-billing_address_${template.name}`"
                                                v-model="currentBillingAddress[template.name]"
                                                :name="`billing[${template.name}]`"
                                                :data-recurly="template.dataRecurly"
                                                :class="{'required-entry': template.required, 'validation-failed': validateField(template.name)}"
                                                class="input-text"
                                                type="text"
                                            />
                                        </template>
                                        <div v-if="validateField(template.name)" :id="`advice-required-entry-${template.name}`" class="validation-advice" style="">This is a required field.</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-show="0" class="membership-save-in-address-book">
                    <label><input v-model="currentBillingAddress.save_in_address_book" type="checkbox" /> Save in address book</label>
                </div>
            </div>
            <div id="address-billing-overlay" class="overlay" style="display:none;"><span>Shipping address selection is not applicable</span></div>
        </div>
        <address v-else v-dompurify-html="currentMemberShipData.formated_billing_address"></address>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import _cloneDeep from 'lodash/cloneDeep'
import _isArray from 'lodash/isArray'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import {
    ADDRESS_VALIDATION_DATA,
    EVENT_SET_CUSTOMER_EXISTS
} from '../../storage/lensablplus/membership'

export default {
    name: 'LensabalplusMembershipFormAddress',
    mixins: [validationMixin],
    data: () => ({
        address_id: ''
    }),
    validations: {
        currentBillingAddress: {
            ...ADDRESS_VALIDATION_DATA
        }
    },
    computed: {
        ...mapState('lensablPlus/membership', ['accountFormData', 'customer']),
        ...mapGetters('lensablPlus/membership', ['memberShipEdited', 'currentMemberShipData', 'currentBillingAddress', 'addressTemplateData']),
        addresses () {
            return this.customer.billing_address || {}
        },

        addressInvalidFields () {
            const invalidFields = []
            _map(this.currentBillingAddress, (value, key) => {
                if (this.$v.currentBillingAddress.hasOwnProperty(key) && this.$v.currentBillingAddress[key].$invalid) {
                    invalidFields.push(key)
                }
            })
            return invalidFields
        },

        addressIsValid () {
            return !this.addressInvalidFields.length
        }
    },
    watch: {
        address_id: {
            handler (id) {
                this.$store.dispatch('lensablPlus/membership/currentBillingAddressUpdate', _cloneDeep(this.addresses[id] || {}))
            },
            deep: true
        },
        addressInvalidFields: {
            handler (invalidFields) {
                this.$store.dispatch('lensablPlus/membership/currentBillingAddressUpdate', {
                    invalid: Boolean(invalidFields.length)
                })
            },
            deep: true
        },
        currentBillingAddress: {
            handler (data) {
                _map(this.addressTemplateData, template => {
                    if (template.type === 'select' && template.textValue) {
                        data[template.textValue.name] = this.getOption(data[template.name], template.options).label
                    }
                })
                this.$nextTick(() => {
                    if (this.addressIsValid) {
                        if (this.address_id) {
                            const address = this.addresses[this.address_id]
                            if (!address) {
                                this.address_id = ''
                            } else {
                                _map(data, (value, key) => {
                                    if (this.address_id && address.hasOwnProperty(key) && !_isEqual(value, address[key])) {
                                        this.address_id = ''
                                        this.$store.dispatch('lensablPlus/membership/currentBillingAddressUpdate', {
                                            entity_id: ''
                                        })
                                    }
                                })
                            }
                        }

                        this.$store.dispatch('lensablPlus/membership/updateQuote', {
                            customer_id: this.customer.data.entity_id || false,
                            order: {
                                account: {
                                    group_id: this.customer.group_id,
                                    email: this.customer.email
                                },
                                billing_address: {
                                    ...data,
                                    customer_address_id: this.address_id
                                }
                            }
                        })
                    }
                })
            },
            deep: true
        }
    },
    created () {
        this.$bus.$on(EVENT_SET_CUSTOMER_EXISTS, this.updateCustomerAddress)
    },
    beforeDestroy () {
        this.$bus.$off(EVENT_SET_CUSTOMER_EXISTS, this.updateCustomerAddress)
    },
    methods: {
        updateCustomerAddress () {
            this.address_id = this.customer.data.default_billing ? this.customer.data.default_billing : ''
        },
        addressStringify (address) {
            const street = _isArray(address.street) ? address.street.join(' ') : address.street
            return `${address.firstname} ${address.lastname}, ${street}, ${address.city}, ${address.region} ${address.postcode}`
        },
        validateField (id) {
            if (this.customer.validate) {
                return this.addressInvalidFields.includes(id)
            }
            return false
        },
        getOption (value, options) {
            return _find(options, option => option.value === value) || {}
        }
    }
}
</script>
