var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.brand === "oakley"
    ? _c("OakleySeo", { attrs: { brand: _vm.brand } })
    : _vm.brand === "ray-ban"
    ? _c("RayBanSeo", { attrs: { brand: _vm.brand } })
    : _vm.brand === "katespade"
    ? _c("KateSpadeSeo", { attrs: { brand: _vm.brand } })
    : _vm.brand === "hugoboss"
    ? _c("HugoBossSeo", { attrs: { brand: _vm.brand } })
    : _vm.brand === "hobieeyewear"
    ? _c("HobieSeo", { attrs: { brand: _vm.brand } })
    : _c("ShopFramesSeo")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }