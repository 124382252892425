<template>
    <div v-if="isMobile || isTablet || isProductConfigured"
        :class="{ 'step-configuration-mobile': isMobileOnly, 'current': isProductConfigured, 'wait': !isProductConfigured }"
        class="step-configuration configuration-finish-container">
        <div class="step-configuration-content-wrapper finish">
            <template v-if="isProductConfigured">
                <span class="finish-label">Finished!</span>
                <span class="finish-ready-caption">Ready To Order?</span>
                <span v-if="errorMessage" style="color: red">{{ errorMessage }}</span>
            </template>

            <slot name="content"></slot>

            <template v-if="isProductConfigured">
                <div class="finish-actions">
                    <BaseButton :text="orderPairButtonText" :loader="loadingRestart"
                        class="restart-configurator final-button button--copy-solid-grey final-add-to-cart-button"
                        @click.native="restart" />

                    <BaseButton :loader="loadingCheckout" :text="proceedToCheckoutButtonText" :disabled="loadingCheckout"
                        class="checkout final-button final-add-to-cart-button" @click.native="checkout" />
                </div>
            </template>
        </div>
        <div v-if="isProductConfigured" class="finish-reset-link-container">
            <a class="finish-reset-link" @click="reset()">Reset</a>
        </div>
    </div>
</template>

<script>
import ResetService from '../../app/mixins/reset-service'
import BaseButton from '../base/BaseButton.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Finish',
    components: {
        BaseButton
    },
    mixins: [ResetService],
    props: {
        orderPairButtonText: {
            type: String,
            default: 'Continue Shopping',
            required: false
        },
        proceedToCheckoutButtonText: {
            type: String,
            default: 'Proceed to checkout',
            required: false
        }
    },
    data: () => ({
        loadingCheckout: false,
        loadingRestart: false,
        errorMessage: null
    }),
    computed: {
        ...mapGetters('storeView', ['base_url']),
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('configurator/steps', ['isProductConfigured']),
        ...mapGetters('summary', ['cartService', 'totalSummary']),
        ...mapGetters('values', ['checkoutData']),
        ...mapGetters('lensablplus_customer', ['preparePlusDataCheckout', 'prepareMemberDiscountCheckout', 'isRedeemFlow', 'membershipId', 'glassesType']),
        ...mapGetters('productView', ['isSpectaclesGen', 'productView', 'mainImageUrl', 'media'])
    },
    watch: {
        isProductConfigured() {
            this.$nextTick(() => {
                this.scrollToStepHead('final')
            })
        }
    },
    methods: {
        ...mapActions('configurator/steps', ['setAnimationLock', 'activateStep', 'scrollToStepHead']),
        ...mapActions('lensablplus_customer', ['preparePlusFramesPrice']),
        restart() {
            this.loadingRestart = true
            this.errorMessage = null
            this._addProductToCart()
                .then(() => {
                    this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', false)
                    this.loadingRestart = false
                    this._restartSteps()
                    this._decorateCartIcon()
                })
                .catch((error) => {
                    const response = error.response
                    if (response.status === 400) {
                        this.errorMessage = response.data.message
                    } else {
                        console.error(error)
                    }
                    this.loadingRestart = false
                })
        },
        checkout() {
            this.loadingCheckout = true
            this.errorMessage = null
            this._addProductToCart()
                .then(() => {
                    this.$store.commit('lensablplus_customer/UPDATE_REDEEMED_FLOW', false)
                    document.location = this.base_url + 'onepage'
                    this.$store.commit('configurator/SET_MOVING_TO_CHECKOUT', true)
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            this.$store.commit('configurator/SET_ANIMATION_SHOW', true)
                            this.loadingCheckout = false
                            resolve()
                        }, 50)
                    })
                })
                .then(() => this.$store.dispatch('values/resetCheckout'))
                .catch((error) => {
                    const response = error.response
                    if (response.status === 400) {
                        this.errorMessage = response.data.message
                    } else {
                        console.error(error)
                    }
                    this.loadingCheckout = false
                })
        },
        reset() {
            this.loadingCheckout = false
            this._configuratorReset()
            this.errorMessage = null
        },

        _decorateCartIcon() {

        },
        _addProductToCart() {
            let checkoutData = { ...this.checkoutData }

            if (this.isRedeemFlow && this.membershipId) {
                checkoutData = this._addPlusInfo(checkoutData)
            }
            if (this.membershipId) {
                this.preparePlusFramesPrice()
            }
            checkoutData.member_program = this.prepareMemberDiscountCheckout

            this.addRetentionData()
            return this.cartService.addProduct(checkoutData)
        },
        _addPlusInfo(checkoutData) {
            const plusItem = this.preparePlusDataCheckout
            if (Object.keys(plusItem).length === 0) {
                return checkoutData
            }
            checkoutData.plus_item = plusItem

            return checkoutData
        },
        addRetentionData() {
            let cart = {}

            if (window.location.pathname.indexOf('lenses') !== -1) {
                let item = { ...this.checkoutData }

                let name_summary = this.values.lens === 'prescription' ? this.visibleList[1].options.filter(obj => obj.id === item.product).map(product => product.summary_label)[0] : this.visibleList[0].options.filter(obj => obj.id === this.values.lens).map(product => product.summary_label)[0]


                Object.assign(cart, {
                    ImageURL: this.visibleList[0].options[0].thumbnail,
                    Name: name_summary,
                    Price: this.totalSummary,
                    ProductID: item.product_id ? item.product_id : '',
                    Quantity: 1,
                    URL: document.URL,
                });

            } else {
                let productView = { ...this.productView }

                Object.assign(cart, {
                    ImageURL: this.media[0].thumbnail,
                    Name: productView.product_name,
                    Price: productView.frame_price,
                    ProductID: productView.product_id,
                    Quantity: 1,
                    URL: document.URL,
                })
            }

            if (!window.location.hostname.includes('james') && 
            !window.location.hostname.includes('goodr') && 
            !window.location.hostname.includes('ae') &&
            !window.location.hostname.includes('walterhill')
            ) {
                var _learnq = _learnq || [];
                window._learnq.push(['track', 'Added to Cart', cart]);
            }
            window.geq.addToCart(cart)
        }
    }
}
</script>
