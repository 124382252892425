var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown-component" }, [
    _c("div", { staticClass: "dropdown-container" }, [
      _c(
        "div",
        { staticClass: "dropdown-container-main" },
        [
          _c("div", { staticClass: "header" }, [
            _c(
              "h4",
              {
                staticClass: "dropdown-title",
                class: { active: _vm.resultsVisible },
                on: { click: _vm.toggleSelected }
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.title) +
                    "\n                "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "slide" } }, [
            _vm.resultsVisible
              ? _c(
                  "div",
                  {
                    staticClass: "results-dropdown",
                    class: { active: _vm.resultsVisible }
                  },
                  [
                    _c(
                      "ul",
                      { attrs: { id: "results" } },
                      _vm._l(_vm.msg, function(info, index) {
                        return _c("li", { key: index }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(_vm._s(index))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "contacts-info" }, [
                            _c("img", {
                              attrs: { src: info.image, alt: info.title }
                            }),
                            _vm._v(" "),
                            _c("span", {
                              directives: [
                                {
                                  name: "dompurify-html",
                                  rawName: "v-dompurify-html",
                                  value: info.title,
                                  expression: "info.title"
                                }
                              ],
                              staticClass: "subtitle"
                            })
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                )
              : _vm._e()
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }