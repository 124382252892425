<template>
    <div>
        <div class="color_selectors">
            <ul class="color-selections">
                <template v-for="section in sections">
                    <li :key="section.id" :class="[section.id + '-li']" class="section-btn">
                        <div
                            :class="{[section.id]: section.id, active: section.id === chosenSection}"
                            :data-name="section.label"
                            class="button button--section button--copy-solid-grey"
                            @click="chooseSectionDefault(section)"
                        >
                            <div :class="section.label" class="button__container">
                                <span v-dompurify-html="section.label"></span>
                            </div>
                        </div>
                    </li>
                </template>
            </ul>

            <component
                :is="currentSectionColorComponent"
                :id="id"
                :sections="sections"
                :current-section="currentSection"
                :lens-prefix="lensPrefix"
                :class="currentSection.id"
            />
        </div>
    </div>
</template>

<script>
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _first from 'lodash/first'
import _map from 'lodash/map'
import _size from 'lodash/size'

import {
    COLOR_SECTION_INTERFACE,
    HELPER_COLORS,
} from './constants'
import {
    GRADIENT_LENS_NAME,
    MATCH_COLOR_LABEL
} from '../../../app/common/gradients_constants'

import ColorViewAbstract from './view/abstract'
import HelpBubble from '../../help_bubble/HelpBubble.vue'
import Standard from './view/Standard.vue'
import ColorFilter from './view/ColorFilter.vue'
import {mapGetters} from 'vuex'
import {LENS_TYPE_ID, LENS_TYPE_SECTION_ID} from "../../../app/common/constants/lens_type";
import {EVENT_STEP_UPDATE} from "../../../app/utils/constants";

export default {
    name: 'SelectorColor',
    components: {
        Standard,
        ColorFilter,
        HelpBubble
    },
    mixins: [ColorViewAbstract],
    props: {
        data: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    params: {
                        assets_url: '',
                        prefix: '',
                        sections: [],
                        value: false
                    }
                }
            },
            required: true
        }
    },
    data: () => ({
        lens_type: false
    }),
    computed: {
        ...mapGetters('values', ['checkoutData', 'framesPartnerPage']),
        ...mapGetters('configurator', ['productId', 'productOptionConfig']),
        ...mapGetters('configurator/steps', ['limiters', 'getLimitersDisabled']),
        currentSectionColorComponent() {
            return this.currentSection.component || 'standard'
        },
        defaultColor() {
            const sections = this.sections
            const colors = sections.length ? _first(sections).colors : undefined
            return colors ? _first(colors).id : undefined
        },
        limitersDisabled() {
            return this.getLimitersDisabled(this.id)
        },
        sections() {
            const configLabels = this.productOptionConfig.map(option => option.label)
            return _filter(this.data.params.sections, section => {
                this._filterLensOptionsByColor(section)
                this._colorsFilteredByProduct(section)
                this._addHelperToColor(section)
                return configLabels.indexOf(section.checkout_value) !== -1 && !this.limitersDisabled.includes(section.id)
            })
        },
        currentSection() {
            const colorId = this.color.value
            for (const section of this.sections) {
                const colorObject = _find(section.colors, color => color.id === colorId)
                if (colorObject) {
                    return section
                }
            }
            return COLOR_SECTION_INTERFACE
        },
        lensPrefix() {
            return (this.data && this.data.params) ? this.data.params.prefix : ''
        }
    },
    watch: {
        color: {
            handler(color) {
                const colorId = color.value
                if ((!colorId || !this.getColorObject(colorId)) && this.defaultColor) {
                    this.$store.commit('values/setDataToValues', {
                        color: {
                            value: this.defaultColor
                        }
                    })
                }
            },
            deep: true
        },
        currentSection: {
            handler(data) {
                if (_size(data)) {
                    this.lens_type = this.cleanLensType(data.checkout_value)
                }
            },
            deep: true
        }
    },
    created() {
        const values = this.checkoutData
        this.lens_type = values.lens_type.name || false
        this.lens_color = values.lens_type.color || false
        this.preDefinedSection()
    },
    methods: {
        cleanLens(lens_type) {
            lens_type = lens_type.replace(MATCH_COLOR_LABEL, '')
            lens_type = lens_type.replace(GRADIENT_LENS_NAME, ' L')
            return lens_type
        },
        cleanLensType(value) {
            const chosen = _find(this.colors, {'label': this.lens_color})
            return chosen && chosen.id.includes('polarized-gradient') ? 'Sun Polarized Gradient' : value
        },

        disabledLensOptions(disabledTypes) {
            const optionsLens = _find(this.productOptionConfig, {'label': this.lens_type})

            if (!optionsLens) {
                return
            }
            const options = optionsLens.options ? optionsLens.options : []

            _map(options, option => {
                delete option.hidden
                const label = option.label || ''
                if (label) {
                    disabledTypes.map(type => {
                        if (label && label.indexOf(type) !== -1) {
                            option.hidden = true
                        }
                    })
                }
            })
        },
        _filterLensOptionsByColor(section) {
            if (this.lens_color) {
                let disabledTypes = []
                this.lens_color = this.cleanLens(this.lens_color)
                const color = _find(section.colors, {'label': this.lens_color})
                const keys = Object.keys(this.limiters.disabled_lens_types)
                const productId = this.productId

                if (productId && keys.indexOf(productId) !== -1) {
                    if (color) {
                        disabledTypes = this.limiters.disabled_lens_types[productId][color.id] || []
                        this.disabledLensOptions(disabledTypes)
                    }
                }
            }
        },
        _colorsFilteredByProduct(section) {
            const keys = Object.keys(this.limiters.disabled_colors)
            const productId = this.productId
            if (productId && keys.indexOf(productId) !== -1) {
                const disabledColors = this.limiters.disabled_colors[productId] || []
                const enabledColors = this.limiters.enabled_colors[productId] || []
                const sectionLabel = section.checkout_value

                if (Object.keys(disabledColors).indexOf(sectionLabel) !== -1) {
                    const disabledColorsByType = disabledColors[sectionLabel]
                    for (const disabledColor of disabledColorsByType) {
                        if (enabledColors[sectionLabel] && enabledColors[sectionLabel].indexOf(disabledColor) !== -1) continue;
                        const color = _find(section.colors, {'id': disabledColor})
                        if (color) {
                            color.hidden = true
                        }
                    }
                }
            } else {
                const colors = section.colors
                for (const color of colors) {
                    delete color.hidden
                }
            }
        },
        _addHelperToColor(section = {}) {
            _map(section.colors, color => {
                const colorHelper = HELPER_COLORS[color.id] ? HELPER_COLORS[color.id] : false
                color.component_help = false
                if (colorHelper) {
                    color.component_help = colorHelper
                }
            })
        },
        preDefinedSection() {
            const sections = this.data.params.sections
            const predefinedObject = this.getLimitersPreDefinedByFrames(LENS_TYPE_ID)
            let predefinedSection = null;
            if (Object.keys(predefinedObject).length) {
                predefinedSection = predefinedObject[LENS_TYPE_SECTION_ID] || null
            }
            const section = _find(sections, section => section.id === predefinedSection) || _first(sections)
            this.chooseSectionDefault(section)
        },
    }
}
</script>
