import _map from 'lodash/map'
import _find from 'lodash/find'
import {
    EVENT_SWITCH_STEP,
    EVENT_SWITCH_OPTION
} from '../../../app/utils/constants'
import SelectorOptions from './SelectorOptions.vue'
import {mapGetters} from 'vuex'

export default {
    name: 'SelectorLensCategory',
    extends: SelectorOptions,
    mounted () {
        const option = _find(this.options, {id :this.lensabl_category})
        this.optionSelect(option)
    },
    computed: {
        ...mapGetters('productView', ['lensabl_category'])
    },
    methods: {
        optionsUpdate () {
            this.options = _map(this.step.options, option => Object.assign({}, this.defaultOption, option))

            for (const option of this.options) {
                if (option.component_extra_options) {
                    if (!option.component_extra_options.params) {
                        this.$set(option.component_extra_options, 'params', {})
                    }
                    this.$set(option.component_extra_options.params, 'value', false)
                }
                if (this.framesPartnerPage && this.framesPartnerPage !== 'spectacles') {
                    option.thumbnail = false
                }
            }
            this._initValue(this.step.value)

            this.updateStep({
                id: this.id,
                data: {defaultValue: this.value}
            })
        },
        _updateValue (value) {
            this.$store.commit('productView/setLensablCategory', value)
            this.$store.commit('productView/setFramesName', value)
            this.$store.commit('productView/setProductCategoryName', value)
            this.$store.commit('values/setFramesName', value)

            this.$store.dispatch('productView/UPDATE_PRODUCT_CONFIG')

            this.$set(this.step, 'value', value)
            this.$set(this.values, this.id, value)
            this._checkIfWeNeedToShowContinueButton()
            this._changeStepVisible()
            this.updateVisibleList()
            this.$bus.$emit(EVENT_SWITCH_OPTION)
            this.$bus.$emit(EVENT_SWITCH_STEP, this.step)
        }
    }
}
