var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentPage === "/choose-my-lenses" && _vm.isDefaultStore
    ? _c("LensReplacementSeo", { attrs: { lensType: "lr" } })
    : _vm.currentPage === "/computer-blue-light-blocking-lenses"
    ? _c("BlueLighttSeo", { attrs: { lensType: "blue" } })
    : _vm.currentPage === "/prescription-sunglass-lenses"
    ? _c("RxSunglassSeo", { attrs: { lensType: "rx" } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }