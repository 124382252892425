var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CatalogCategoryViewBanner", {
        staticClass: "spectacles-banner frames-banner",
        class: [_vm.categoryClass + "-banner"],
        attrs: {
          category: _vm.currentCategory,
          "category-class": _vm.categoryClass
        }
      }),
      _vm._v(" "),
      _c("LensesInclude"),
      _vm._v(" "),
      _c("TermsInformation"),
      _vm._v(" "),
      _c("CatalogNavigationCategories", {
        staticClass: "category-frames-tabs",
        attrs: {
          "list-title": "Filter:",
          categories: _vm.categories,
          "current-id": _vm.currentCategoryId
        },
        on: _vm._d({}, [_vm.EVENT_CATEGORY_SELECTED, _vm.setCurrentCategory])
      }),
      _vm._v(" "),
      _c("CatalogProductList", {
        staticClass: "grid products-grid",
        attrs: {
          load: _vm.categoryLoader,
          products: _vm.products[_vm.currentCategory.name]
        }
      }),
      _vm._v(" "),
      _c("ReturnsInfo")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }