<template>
    <div>
        <div v-if="sameProduct">
            <div class="product-details">
                <h2 v-dompurify-html="sameName"></h2>
                <h3>{{ sameSecond_Name }}</h3>
                <img :src="sameImage" :alt="sameName" />
                <p v-if="sameImage" class="rx-instrutions">Your prescription is written on the side of your box.</p>
            </div>
        </div>
        <div v-if="!sameProduct" class="product-details-container">
            <div v-for="(products, index) in eyeData" :key="index" :class="index" class="product-details">
                <h2 v-dompurify-html="products.brand"></h2>
                <h3>{{ products.second_name }}</h3>
                <img :src="products.image" :alt="products.brand" />
                <p v-if="products.image" class="rx-instrutions">
                    Your prescription is written on the side of your box.
                </p>
            </div>
        </div>
        <div class="contacts_rx-container">
            <div v-for="(products, index) in eyeData" :key="index">
                <div class="edit-section">
                    <h4>{{ products.label }}</h4>
                    <span v-if="!isRedeemFlow" @click="removeProduct(index)">Change Brand</span>
                </div>
                <div :id="'addValue-' + index" :class="index" class="rx-container">
                    <div class="contacts_rx-values">
                        <li v-if="showColorOptions(index)">
                            <PrescriptionDropdown
                                :index="index"
                                :values="colorOptions"
                                :value="products.color"
                                :products="products"
                                type="color"
                                placeholder="Select Color"
                            />
                        </li>
                        <li>
                            <PrescriptionDropdown
                                :index="index"
                                :values="powerOptions"
                                :value="products.rx.power"
                                :products="products"
                                type="power"
                                placeholder="Select Power/Sphere"
                            />
                        </li>
                        <li>
                            <div v-if="!isDropdown(products.rx_dropdown.bc, index, 'bc')" class="contacts_rx_set">
                                <span>BC</span>
                                <span>{{ products.rx.bc ? products.rx.bc.slice(0, -1) : products.rx.bc }}</span>
                            </div>
                            <PrescriptionDropdown
                                v-if="isDropdown(products.rx_dropdown.bc)"
                                :index="index"
                                :values="bcOptions"
                                :value="products.rx.bc"
                                :products="products"
                                :available-values="contactsRXData[index].bc"
                                type="bc"
                                placeholder="BC"
                            />
                        </li>
                        <li v-if="!isDropdown(products.rx_dropdown.dia, index, 'dia')">
                            <div class="contacts_rx_set">
                                <span>DIA</span>
                                <span>{{ products.rx.dia ? products.rx.dia.slice(0, -1) : products.rx.dia }}</span>
                            </div>
                        </li>
                        <li v-if="products.rx_dropdown.cyl && checkSum(products.rx_dropdown.cyl)">
                            <PrescriptionDropdown
                                :index="index"
                                :values="cylOptions"
                                :value="products.rx.cyl"
                                :products="products"
                                :available-values="contactsRXData[index].cyl"
                                placeholder="CYL"
                                type="cyl"
                            />
                        </li>
                        <li v-if="products.rx_dropdown.axis && checkSum(products.rx_dropdown.axis)">
                            <PrescriptionDropdown
                                :index="index"
                                :values="axisOptions"
                                :value="products.rx.axis"
                                :products="products"
                                :available-values="contactsRXData[index].axis"
                                placeholder="Axis"
                                type="axis"
                            />
                        </li>
                        <li v-if="products.rx_dropdown.add && checkSum(products.rx_dropdown.add)">
                            <PrescriptionDropdown
                                :index="index"
                                :values="addOptions"
                                :value="products.rx.add"
                                :products="products"
                                :available-values="contactsRXData[index].add"
                                placeholder="Add Power"
                                type="add"
                            />
                        </li>
                    </div>
                </div>
                <div :id="'add-image-' + index" @click="goToCatPage(index)">
                    <img alt="edit button" src="/js/lensconfigurator/assets/add.svg" />
                    <p>Add {{ index }} Eye</p>
                </div>
                <!-- Modal-->
                <div v-if="showModal" class="manual-modal modal-vue">
                    <div class="modal-vue-overlay"></div>
                    <div class="modal-exit">
                        <div class="modal-close-contacts">
                            <img
                                class="modal-close-icon"
                                src="/js/lensconfigurator/assets/close-icon.svg"
                                @click="closeModal"
                            />
                        </div>
                        <span>Are you sure?</span>
                        <p>If you delete this product you will lose any Rx that you entered.</p>
                        <div class="rx-button_container">
                            <button class="button button--copy-icon" @click="addNewProduct(current)">Yes</button>
                            <button class="button button--copy-solid" @click="closeModal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- validation error message -->
        <div v-if="isNotAvailableValue" class="contacts_rx-error-container">
            <p class="contacts_rx-error-msg">**Warning: The selected configuration is not available.**</p>
        </div>
        <div v-if="showErrorMessage" class="contacts_rx-error-container">
            <p class="contacts_rx-error-msg">
                **Warning: You didn’t enter all of the values neccesary to complete your order. Please try again.**
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import _sum from 'lodash/sum'
import {
    CONTACTS_EYE_DATA_INTERFACE
} from '../storage/contacts'
import contactsPrescriptionModel from '../../../app/common/constants/contacts'
import {MESSAGE_VALIDATE_MANUAL_RX} from '../../../app/utils/constants'
import PrescriptionDropdown from './PrescriptionDropdown.vue'

export default {
    name: 'ManualPrescription',
    components: {
        PrescriptionDropdown
    },
    extends: contactsPrescriptionModel,
    data: () => {
        return {
            sameProduct: false,
            showModal: false,
            current: '',
            sameName: '',
            sameSecond_Name: '',
            sameImage: '',
            showErrorMessage: false,
            showError: false,
            missing: true,
            isValidationFired: false
        }
    },
    computed: {
        ...mapState('contacts', ['isNotAvailableValue', 'contactsRXData', 'isContactsRXDataAjaxLoading']),
        ...mapGetters('values', ['values']),
        ...mapGetters('contacts', ['isContactDetailsPage', 'getLeftEyeConfirmed', 'getRightEyeConfirmed']),
        ...mapGetters('lensablplus_customer', ['isRedeemFlow']),
        isValidForm () {
            this.hasValue()
            return this.missing
        }
    },
    watch: {
        showError () {
            this.getError()
        },
        'eyeData.left': {
            handler: async function (data) {
                await this.filterRXData(data, 'left')
            },
            deep: true
        },
        'eyeData.right': {
            handler: async function (data) {
                await this.filterRXData(data, 'right')
            },
            deep: true
        }
    },
    created () {
        Object.keys(this.contactsRXData).forEach(eye => {
            Object.keys(this.contactsRXData[eye]).forEach(rx => {
                this.$store.commit('contacts/UPDATE_CONTACTS_RX_DATA', {
                    eye :eye,
                    rx: []
                })
            })
        })
        this.$bus.$on(MESSAGE_VALIDATE_MANUAL_RX, this._validateUpload)
    },
    mounted () {
        this.isSameProduct()
        const right = document.getElementById('add-image-right')
        const left = document.getElementById('add-image-left')
        const rightValue = document.getElementById('addValue-right')
        const leftValue = document.getElementById('addValue-left')
        if (this.eyeData['right'].brand.length === 0) {
            rightValue.style.display = 'none'
        } else {
            right.style.display = 'none'
        }

        if (this.eyeData['left'].brand.length === 0) {
            leftValue.style.display = 'none'
        } else {
            left.style.display = 'none'
        }
    },
    beforeDestroy () {
        this.$bus.$off(MESSAGE_VALIDATE_MANUAL_RX, this._validateUpload)
    },
    methods: {
        showColorOptions (eye) {
            return !this.originalEyeData[eye].color && this.colorOptions(eye).length
        },
        filterRXData: function (eyeData, eye) {
            const rx = eyeData.rx
            const sku = eyeData.product_sku
            if (sku) {
                this.$store.dispatch('contacts/getContactsRXData', {sku: [sku], rx: rx, index: eye})
            }
        },
        updateRxValue (value, index, rx_type, event) {
            this.$store.commit('contacts/SET_EYE_RX_CHECKED', {
                eye: index,
                rx: {
                    [rx_type]: value
                }
            })
            if (event.target.parentElement.style.display === 'none') {
                event.target.parentElement.style.display = 'flex'
            } else {
                event.target.parentElement.style.display = 'none'
            }
        },
        updateDropdown (event) {
            if (event.target.nextElementSibling.style.display === 'none') {
                event.target.nextElementSibling.style.display = 'flex'
            } else {
                event.target.nextElementSibling.style.display = 'none'
            }
        },
        isDropdown (val = '', index = '', option = '') {
            const value = val ? val.split(',') : []
            if (value.length > 2) {
                return true
            } else {
                if (option === 'bc' || option === 'dia') {
                    this.$store.commit('contacts/SET_EYE_RX_CHECKED', {
                        eye: index,
                        rx: {
                            [option]: val
                        }
                    })
                }
                return false
            }
        },
        isSameProduct () {
            if (this.eyeData['left'].brand === this.eyeData['right'].brand) {
                this.sameProduct = true
                const brand = this.eyeData['left'].brand
                const image = this.eyeData['left'].image
                const second_name = this.eyeData['left'].second_name
                this.sameName = brand
                this.sameImage = image
                this.sameSecond_Name = second_name
            }
        },
        removeProduct (index) {
            this.showModal = true
            this.current = index
        },
        addNewProduct (index) {
            if (index === 'right') {
                const right = document.getElementById('add-image-right')
                const rightValue = document.getElementById('addValue-right')
                this.$store.commit('contacts/RIGHT_EYE_REMOVED')
                this.$store.commit('contacts/SET_EYE_DATA', this.setEyeData('right'))
                this.showModal = false
                rightValue.style.display = 'none'
                right.style.display = 'block'
                this.isSameProduct()
                this.$store.commit('contacts/UPDATE_DETAILS_STEP')
            } else {
                const left = document.getElementById('add-image-left')
                const leftValue = document.getElementById('addValue-left')
                this.$store.commit('contacts/LEFT_EYE_REMOVED')
                this.$store.commit('contacts/SET_EYE_DATA', this.setEyeData('left'))
                this.showModal = false
                leftValue.style.display = 'none'
                left.style.display = 'block'
                this.isSameProduct()
                this.$store.commit('contacts/UPDATE_DETAILS_STEP')
            }
        },
        setEyeData (eye) {
            return {
                ...CONTACTS_EYE_DATA_INTERFACE,
                eye: eye
            }
        },
        goToCatPage (index) {
            window.location.href = '/shop-contacts/brands'
        },
        clearThenCatPage (index) {
            if (index === 'right') {
                this.$store.commit('contacts/RIGHT_EYE_REMOVED')
                this.$store.commit('contacts/SET_EYE_DATA', this.setEyeData('right'))
                this.isSameProduct()
                this.$store.commit('contacts/UPDATE_DETAILS_STEP')
            } else {
                this.$store.commit('contacts/LEFT_EYE_REMOVED')
                this.$store.commit('contacts/SET_EYE_DATA', this.setEyeData('left'))
                this.isSameProduct()
                this.$store.commit('contacts/UPDATE_DETAILS_STEP')
            }
            window.location.href = '/shop-contacts/brands'
        },
        closeModal () {
            this.showModal = false
        },
        validate (resolve, reject) {
            if (this.isValidForm) {
                resolve()
            } else {
                reject()
            }
        },
        hasValue () {
            const arr = []
            Object.keys(this.eyeData).forEach(index => {
                const eye = this.eyeData[index]
                if ((index === 'right' && this.getRightEyeConfirmed) || (index === 'left' && this.getLeftEyeConfirmed)) {
                    if (eye.rx.power === '') {
                        arr.push(1)
                    }
                    if (eye.rx.dia === '') {
                        arr.push(1)
                    }
                    if (eye.rx_dropdown.bc && eye.rx.bc === '') {
                        arr.push(1)
                    }
                    if (eye.rx_dropdown.cyl && eye.rx.cyl === '') {
                        arr.push(1)
                    }
                    if (eye.rx_dropdown.axis && eye.rx.axis === '') {
                        arr.push(1)
                    }
                    if (eye.rx_dropdown.add && eye.rx.add === '') {
                        arr.push(1)
                    }
                    if (this.colorOptions(index).length && !eye.color) {
                        arr.push(1)
                    }
                }
            })

            if (arr.length) {
                this.missing = false
                this.showErrorMessage = true
            } else {
                this.missing = true
                this.showErrorMessage = false
            }
        },
        getError () {
            const arr = []
            Object.keys(this.eyeData).forEach((products) => {
                const eye = this.eyeData[products]
                if (eye.rx.power === '' || eye.rx.dia === '' || (eye.rx_dropdown.bc && eye.rx.bc === '') || (eye.rx_dropdown.cyl && eye.rx.cyl === '') || (eye.rx_dropdown.axis && eye.rx.axis === '')) {
                    this.showErrorMessage = true
                    arr.push(1)
                }
            })

            if (arr.length) {
                this.missing = false
            }
        },
        _validateUpload (promise, resolve, reject) {
            this.isValidationFired = true
            this.showError = true
            this.validate(resolve, reject)
        },
        checkSum (options) {
            return _sum(options).slice(0, -1) !== 0
        }
    }
}
</script>

<style scoped>

</style>
