var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CatalogCategoryViewBanner", {
        staticClass: "spectacles-banner frames-banner lensabl-frames-banner",
        class: [_vm.categoryClass + "-banner", _vm.currentCategory.url_key],
        attrs: {
          category: _vm.categories[0],
          "category-class": _vm.categoryClass
        }
      }),
      _vm._v(" "),
      _c("LensesInclude"),
      _vm._v(" "),
      _c("TermsInformation"),
      _vm._v(" "),
      _c("CatalogNavigationCategories", {
        staticClass: "category-frames-tabs",
        attrs: {
          "list-title": "Filters",
          categories: _vm.categories,
          "current-id": _vm.currentCategoryId
        },
        on: _vm._d({}, [
          _vm.EVENT_CATEGORY_SELECTED,
          _vm.updateFilteredProducts
        ])
      }),
      _vm._v(" "),
      _vm.productsCount
        ? [
            _vm._l(_vm.getCatProducts, function(
              categoryProducts,
              categoryName
            ) {
              return [
                _vm.parentCategory && false
                  ? _c("CatalogCategoryLensablViewSubCategoryBanner", {
                      key: "banner-" + categoryName,
                      staticClass: "subcategory-banner",
                      attrs: { category: _vm.getCategoryByName(categoryName) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("CatalogProductList", {
                  key: "products-" + categoryName,
                  staticClass: "grid products-grid shop-frames-cat",
                  attrs: {
                    load: _vm.categoryLoader,
                    products: categoryProducts
                  }
                })
              ]
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.categoryLoader
        ? _c("PageLoader", { attrs: { load: _vm.categoryLoader } })
        : _vm._e(),
      _vm._v(" "),
      _vm.parentCategory
        ? _c("section", { staticClass: "landing-page_ad-block" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("ReturnsInfo", [
        !_vm.parentCategory && _vm.products
          ? _c("div", { staticClass: "returns-frame-container" }, [
              _c("h2", [
                _vm._v(
                  "Everyday Eyewear includes premium lenses made by Essilor"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "returns-included" }, [
                _c("div", { staticClass: "returns-icon blue-light" }, [
                  _c("div", [
                    _c("h3", [_vm._v("Premium Blue-Light Protection")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "Protects your eyes from screen-emitted rays that cause eye strain and headaches."
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "returns-icon ar" }, [
                  _c("h3", [_vm._v("Anti-Reflective Coating")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "Blocks reflections and glare that can strain your eyes."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "returns-icon scratch" }, [
                  _c("h3", [_vm._v("Anti-Scratch Coating")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "Strengthens your lenses to protect from scratches or other damage."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "returns-icon uv" }, [
                  _c("h3", [_vm._v("100% UV Protection")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "Acts as a sunscreen for your eyes blocking UV rays from passing through lenses. "
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ad-block_container" }, [
      _c("a", { attrs: { href: "/shop-contacts" } }, [
        _c("img", {
          attrs: {
            src:
              "https://www.lensabl.com/media/wysiwyg/email/images/EMAIL_Contacts-Ad-update.jpg",
            alt: "Shop Now button with text and woman holding contacts"
          }
        })
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "/choose-my-lenses" } }, [
        _c("img", {
          attrs: {
            src:
              "https://www.lensabl.com/media/wysiwyg/email/images/EMAIL_Lens-Replacement-Ad-update.jpg",
            alt: "Shop Now button with woman holding a pair of sunglasses"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }