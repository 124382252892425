var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isStepCurrent
    ? _c("div", [
        _vm.step.step_description
          ? _c("div", { staticClass: "step-description" }, [
              _vm._v(_vm._s(_vm.step.step_description))
            ])
          : !_vm.step.step_description
          ? _c("div", { staticClass: "step-description" })
          : _c("div", { staticClass: "step-description" }, [
              _vm._v("Choose one from the options below.")
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "options_container" }, [
          _c(
            "ul",
            { staticClass: "options" },
            [
              _vm._l(_vm.options, function(option) {
                return [
                  _c("Option", {
                    key: option.id,
                    staticClass: "step-configuration-content",
                    attrs: {
                      id: _vm.id,
                      "component-help-show": _vm.isMobile,
                      option: option
                    },
                    on: { "option-select": _vm.optionSelect }
                  })
                ]
              })
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }