import AntireflectiveOption from '../antireflective/Option.vue'

export default {
    name: 'WarrantyOption',
    extends: AntireflectiveOption,
    methods: {
        optionSelect () {
            return this.$parent.addWarrantyToCart(this.option)
        }
    }
}
