<template>
    <div class="membership-page-view">
        <v-flex>
            <v-col>
                <MembershipAbstract :header-text="`Membership ID ${currentMemberShipIncrementId}`">
                    <BlockLoader :loading="!loaded">
                        <MembershipData/>
                    </BlockLoader>
                </MembershipAbstract>
                <MembershipAbstract header-text="Payment Information">
                    <BlockLoader :loading="!loaded">
                        <MembershipPayment/>
                    </BlockLoader>
                </MembershipAbstract>
            </v-col>
            <v-col>
                <MembershipAbstract header-text="Billing Address">
                    <BlockLoader :loading="!loaded">
                        <MembershipFormAddress/>
                    </BlockLoader>
                </MembershipAbstract>
                <MembershipAbstract header-text="Recent Orders">
                    <BlockLoader :loading="!loaded">
                        <MembershipRecentOrders/>
                    </BlockLoader>
                </MembershipAbstract>
            </v-col>
        </v-flex>
        <v-flex>
            <v-col>
                <MembershipAbstract header-text="Contact Lens Benefit">
                    <MembershipContactLens :data-loaded="loaded"/>
                </MembershipAbstract>
                <MembershipAbstract header-text="Eye Exam Benefit">
                    <MembershipEyeExam :redeem-item-type="getRedeemTypeWithPrefix('exam')" :data-loaded="loaded"/>
                </MembershipAbstract>
            </v-col>
            <v-col v-if="isBusinessMember">
                <MembershipAbstract header-text="Lensabl+ Group Member">
                    <BusinessLensabalplusMembershipContactLens :redeem-item-type="getRedeemTypeWithPrefix('frames')"
                                                               :data-loaded="loaded"/>
                    <BusinessNonLensabl :redeem-item-type="getRedeemTypeWithPrefix('digital_voucher')"
                                        :data-loaded="loaded"/>
                </MembershipAbstract>
            </v-col>
            <v-col v-else>
                <MembershipAbstract header-text="Frame or Lens Replacement Benefit">
                    <MembershipFrameOrLensReplacement :redeem-item-type="getRedeemTypeWithPrefix('frames')"
                                                      :data-loaded="loaded"/>
                </MembershipAbstract>
            </v-col>
        </v-flex>
<!--        <v-flex>-->
<!--            <v-col></v-col>-->
<!--            <v-col v-if="isBusinessMember">-->
<!--                <MembershipAbstract header-text="Redeems History">-->
<!--                    <RedeemsHistory/>-->
<!--                </MembershipAbstract>-->
<!--            </v-col>-->
<!--        </v-flex>-->
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import lensablPlus from '../storage/lensablplus'
import membership from '../storage/lensablplus/membership'
import MembershipAbstract from './Abstract.vue'
import MembershipData from './form/Data.vue'
import RedeemsHistory from './form/RedeemsHistory.vue'
import MembershipFormAddress from './form/Address.vue'
import MembershipPayment from './form/Payment.vue'
import MembershipRecentOrders from './form/RecentOrders.vue'
import MembershipContactLens from './form/ContactLens.vue'
import MembershipFrameOrLensReplacement from './form/FrameOrLensReplacement.vue'
import BusinessLensabalplusMembershipContactLens from './form/BusinessFrameOrLensReplacement.vue'
import BusinessNonLensabl from './form/BusinessNonLensabl.vue'
import MembershipEyeExam from './form/EyeExam.vue'
import BlockLoader from '../../base/BlockLoader.vue'

export default {
    name: 'LensabalplusMembershipView',
    components: {
        BlockLoader,
        MembershipAbstract,
        MembershipFormAddress,
        MembershipPayment,
        MembershipRecentOrders,
        MembershipContactLens,
        MembershipFrameOrLensReplacement,
        MembershipEyeExam,
        MembershipData,
        BusinessLensabalplusMembershipContactLens,
        BusinessNonLensabl,
        RedeemsHistory
    },
    data: () => ({
        loaded: false
    }),
    computed: {
        ...mapGetters('lensablPlus/membership', ['currentMemberShipId', 'currentMemberShipIncrementId']),
        ...mapGetters('lensablplus_customer', ['getPrefixRedeem', 'isBusinessMember'])
    },
    beforeCreate() {
        if (!this.$store._modulesNamespaceMap['lensablPlus/']) {
            this.$store.registerModule('lensablPlus', lensablPlus(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['lensablPlus/membership']) {
            this.$store.registerModule(['lensablPlus', 'membership'], membership(this.$store))
        }
        this.$store.dispatch('lensablPlus/membership/fetchDefaultData')

        this.$store.dispatch('lensablPlus/fetchDefaultData')
        this.$store.dispatch('lensablPlus/membership/fetchDefaultData')
        // this.$store.dispatch('lensablPlus/membership/fetchRedeemsHistoryData')
        this.$store.dispatch('lensablPlus/membership/fetchMemberShipData', this.currentMemberShipId).then(() => {
            this.loaded = true
        })
        this.$store.commit('lensablPlus/membership/MEMBER_SHIP_EDITED', false)

    },
    methods: {
        getRedeemTypeWithPrefix(type) {
            return this.getPrefixRedeem + type
        }
    }
}
</script>

<style lang="less" scoped>
.flex {
    .col {
        margin: 0 5px;
    }
}
</style>
