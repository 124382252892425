<template>
    <div>
        <h2>Cuctomer Account Section</h2>
    </div>
</template>

<script>
export default {
    name: 'CustomerAccount'
}
</script>
