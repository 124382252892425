<template>
    <div>
        <vue-modaltor
            :visible="isModalOpen"
            :default-width="'100%'"
            :bg-overlay="''"
            :bg-panel="''"
            :close-scroll="false"
            class="redeem-modal"
            :class="{'wrapper': isModalOpen, 'collect': collect}"
            @hide="hideModal"
        >
            <div v-show="!finished" class="redeem-img-container">
                <img src="/skin/frontend/lensabl/default/images/lensplus_giftcard_background.webp" class="redeem-img" alt="redeem image" />
            </div>
            <div class="redeem-container">
                <div v-show="collect && !finished" class="collect-reward">
                    <div v-for="n in 149" :key="`confetti-${n}`" :class="'confetti-' + n"></div>
                    <h2>Choose Your Reward!</h2>
                    <h5 class="subtitle">Choose spending dollars towards new lenses, glasses, or contacts through Lensabl or a gift card to spend elsewhere - make your choice below!</h5>
                    <div class="rewards-container">
                        <div class="lensabl-credit rewards-card">
                            <h5><strong>${{ giftcardConfigs.lensabl_value }} Lensabl Credit</strong></h5>
                            <span class="rewards-instruction">(Digital gift card for any future purchase delivered via email)</span>
                            <BaseButton type="submit" text="CHOOSE" tabindex="3" @click.native="collectRewardLensablGiftCard" />
                        </div>
                        <div class="gift-card rewards-card">
                            <h5><strong>${{ giftcardConfigs.tremendous_value }} Digital Reward<br />(i.e. Amazon Gift Card, Visa Gift Card, etc)</strong></h5>
                            <span class="rewards-instruction">(Takes you to an external site)</span>
                            <BaseButton type="submit" text="CHOOSE" tabindex="3" @click.native="collectRewardTremendous" />
                        </div>
                    </div>
                </div>
                <div v-show="finished">
                    <div v-for="n in 20" :key="`confetti-${n}`" :class="'confetti-' + n"></div>
                    <h2>You will receive a Lensabl Gift Card in your email.</h2>
                </div>
            </div>
        </vue-modaltor>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from 'vue'
import VueModalTor from 'vue-modaltor'
import BaseButton from '../../../components/base/BaseButton.vue'

Vue.use(VueModalTor)
export default {
    name: 'GiftCreditRedeem',
    components: {
        BaseButton
    },
    props: {
        redeemItemType: {
            type: String,
            default: '',
            required: true
        },
        isModalOpen: {
            type: Boolean,
            default: false,
            required: true
        },
        collect: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data: () => ({
        finished: false
    }),
    computed: {
        ...mapGetters('lensablplus_customer', ['getGiftcardConfigs']),
        giftcardConfigs () {
            return this.getGiftcardConfigs(this.redeemItemType)
        }
    },
    methods: {
        ...mapActions('lensablplus_customer', ['createRewardTremendous', 'createRewardLensabl', 'fetchData']),
        hideModal () {
            this.$emit('update', false)
        },
        collectRewardLensablGiftCard () {
            this.finished = true
            this.$emit('update:collect', false)
            this.createRewardLensabl({
                value: this.giftcardConfigs.lensabl_value,
                type: this.redeemItemType
            }).then(() => {
                setTimeout(() => this.$emit('update', false), 5000)
            })
        },
        collectRewardTremendous () {
            this.$store.dispatch('lensablplus_customer/createRewardTremendous', {
                value: this.giftcardConfigs.tremendous_value,
                type: this.redeemItemType
            })
        }
    }
}
</script>

<style scoped lang="scss">
body {
    margin: 0;
    overflow: hidden;
}

.wrapper {
    position: relative;
    min-height: 100vh;
}

[class|="confetti"] {
    position: absolute;
}

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 150 {
    $w: random(8);
    $l: random(100);
    .confetti-#{$i} {
        width: #{$w}px;
        height: #{$w*0.4}px;
        background-color: nth($colors, random(3));
        top: -10%;
        left: unquote($l+"%");
        opacity: random() + 0.5;
        transform: rotate(#{random()*360}deg);
        animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") infinite;
    }

    @keyframes drop-#{$i} {
        100% {
            top: 110%;
            left: unquote($l+random(15)+"%");
        }
    }
}

.reward-loader {
    background-color: #f6f6f6;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    overflow: hidden;
}

.reward-loader .loader-2 {
    position: absolute;
    top: 50%;
    left: 50%;
}
</style>
