<template>
    <div class="catalog-category-view categorypath-jamesoro-eyewear">
        <div class="spectacles-banner frames-banner" :class="[categoryClass + `-banner`]">
            <div class="sb-inner-wrapper">
                <div class="sb-inline">
                    <div class="sb-inner" :class="[categoryClass]">
                        <a :href="base_url">
                            <h1 :class="[categoryClass + `-home`]">{{categoryName}}</h1>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="product-border-divider">
            <p>Already have the frames and just need new lenses? <a href="/choose-my-lenses">Click Here</a></p>
        </div>
        <div v-dompurify-html="breadcrumbsHtml" class="frames-breadcrumbs"></div>
        <div class="category-products frames-products">
            <PageLoader v-if="categoryLoader" :load="categoryLoader"/>
            <template v-else>
                <div>
                    <div class="products wrapper grid products-grid">
                        <ul class="products list items product-items">
                            <li v-for="product in getProducts" :key="product.url" class="item product product-item">
                                <CatalogCategoryListItem
                                    :product="product"
                                    :showSwatcher="true"
                                    :showProductPrice="true"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import _filter from 'lodash/filter'
import PageLoader from '../../../loaders/PageLoader.vue'
import CatalogCategoryListItem from '../../product/list/Item.vue'

export default {
    name: 'JamesOroProductList',
    components: {
        PageLoader,
        CatalogCategoryListItem
    },
    computed: {
        ...mapGetters('category', [
            'breadcrumbsHtml',
            'categoryLoader',
            'categoryImage',
            'categoryMobileImage',
            'getProducts',
            'currentCategory'
        ]),
        ...mapGetters('storeView', ['base_url']),
        categoryName() {
            return this.currentCategory.name || false
        },
        categoryClass() {
            const name = this.categoryName
            const nameArr = name.split(' ')
            return nameArr.join('').toLowerCase()
        },
        getCategoryImage() {
            if (this.isMobile) {
                return this.categoryMobileImage
            }
            return this.categoryImage
        }
    },
    created() {
        this.$store.dispatch('category/fetchDefaultData')
        this.$store.dispatch('category/fetchCategoryProducts')
    },
    methods: {}
}
</script>

<style scoped>

</style>
