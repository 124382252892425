var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "shopframes-seo-prescription-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-prescription" }, [
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Prescription Glasses Online")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Ordering prescription glasses online has never been easier. With Lensabl, we help simplify the process so you can select the frames and lenses you need and get them delivered right to your door, fast. With Lensabl, you'll have access to an amazing selection of high-quality lenses and frames with or without a prescription."
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "shopframes-seo-img" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-frames-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-frames" }, [
          _c("div", { staticClass: "shopframes-seo-frames-img" }),
          _vm._v(" "),
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Quality Prescription Frames")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Your eyeglasses are one of your most important tools for everyday life. Shop with Lensabl for the ultimate convenience and dependable frames you can count on for years."
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-designer-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-designer" }, [
          _c("div", { staticClass: "shopframes-seo-text" }, [
            _c("h2", [_vm._v("Designer Prescription Glasses")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                    At Lensabl, we recognize our customers prioritize style just as much as function. Order designer prescription glasses online from Lensabl and choose from a wide selection of designer frames that accentuate your personal style."
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                "\n                    Place an order through Lensabl's online store for a competitive rate on designer eyeglass frames and sunglasses frames with rapid delivery. Browse our offerings for womens and mens designer brands, including:\n                "
              )
            ])
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "shopframes-seo-designer-list" }, [
            _c("li", [_vm._v("Arnette")]),
            _vm._v(" "),
            _c("li", [_vm._v("Kate Spade")]),
            _vm._v(" "),
            _c("li", [_vm._v("Bose")]),
            _vm._v(" "),
            _c("li", [_vm._v("Marc Jacobs")]),
            _vm._v(" "),
            _c("li", [_vm._v("Oakley")]),
            _vm._v(" "),
            _c("li", [_vm._v("Carrera")]),
            _vm._v(" "),
            _c("li", [_vm._v("Ray-Ban")]),
            _vm._v(" "),
            _c("li", [_vm._v("Everyday Eyewear")]),
            _vm._v(" "),
            _c("li", [_vm._v("Vogue")]),
            _vm._v(" "),
            _c("li", [_vm._v("Hugo Boss")]),
            _vm._v(" "),
            _c("li", [_vm._v("HyperX")]),
            _vm._v(" "),
            _c("li", [_vm._v("and more...")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-lenses-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-lenses" }, [
          _c("div", { staticClass: "shopframes-seo-text seo-lenses-text" }, [
            _c("h2", [_vm._v("Prescription Lenses")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Everyone has different vision needs, and the Lensabl online store features glasses for astigmatism and other corrective prescription lenses. Send us your prescription information or "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "/online-vision-prescription",
                    title: "Online Vision Prescription"
                  }
                },
                [
                  _c("span", { staticClass: "seo-vision-link" }, [
                    _vm._v("take an online vision test")
                  ])
                ]
              ),
              _vm._v(" to ensure your new lenses serve their purpose. Our "),
              _c("a", { attrs: { href: "/our-lenses", title: "Our Lenses" } }, [
                _c("span", { staticClass: "seo-vision-link" }, [
                  _vm._v("prescription glasses lens options")
                ])
              ]),
              _vm._v(" include:")
            ])
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "shopframes-seo-lenses-list" }, [
            _c("li", [_vm._v("Single Vision Lenses")]),
            _vm._v(" "),
            _c("li", [_vm._v("Transitions® Light Intelligent Lenses™")]),
            _vm._v(" "),
            _c("li", [_vm._v("Reading Lenses")]),
            _vm._v(" "),
            _c("li", [_vm._v("Digital HD Progressive Lenses")]),
            _vm._v(" "),
            _c("li", [_vm._v("High Index Lenses")]),
            _vm._v(" "),
            _c("li", [_vm._v("Sunglass Lenses")]),
            _vm._v(" "),
            _c("li", [_vm._v("Blue Light Computer Lenses")]),
            _vm._v(" "),
            _c("li", [_vm._v("Bifocal Lenses")]),
            _vm._v(" "),
            _c("li", [_vm._v("Polarized Sunglass Lenses")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shopframes-seo-eyeglass-container" }, [
        _c("div", { staticClass: "shopframes-seo-row seo-eyeglass" }, [
          _c("div", { staticClass: "shopframes-seo-text seo-eyeglass-text" }, [
            _c("h2", [_vm._v("Get Quality Eyeglass Frames With Lensabl")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Ordering high-quality eyeglass frames to your door is easier and faster than ever with Lensabl. We offer free shipping, 30-day risk-free returns and a 12-month warranty to ensure complete customer satisfaction with every order of frames. For more on our frames, lenses and rates, "
              ),
              _c("a", { attrs: { href: "/contact", title: "Contact" } }, [
                _c("span", { staticClass: "seo-contact-link" }, [
                  _vm._v("contact Lensabl online")
                ])
              ]),
              _vm._v(" today!")
            ])
          ]),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: "/glasses-frames", title: "Glasses Frames" } },
            [
              _c("div", { staticClass: "button button--copy-solid" }, [
                _c("div", { staticClass: "button__container" }, [
                  _vm._v("GET NEW FRAMES")
                ])
              ])
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }