var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "products wrapper",
      class: _vm.grid ? " grid products-grid" : ""
    },
    [
      _vm.load
        ? _c("PageLoader", { attrs: { load: _vm.load } })
        : [
            _vm.productsCount
              ? _c(
                  "ul",
                  {
                    staticClass: "products list items product-items",
                    class: _vm.categoryClass
                  },
                  [
                    _vm._l(_vm.products, function(product, index) {
                      return [
                        _c(
                          "li",
                          {
                            key: product.url + index,
                            staticClass: "item product product-item"
                          },
                          [
                            _c("CatalogCategoryListItem", {
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      ]
                    })
                  ],
                  2
                )
              : _c("div", { staticClass: "sorry" }, [
                  _c("p", [
                    _vm._v(
                      "Sorry... there are no products matching your selection."
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Please try again.")])
                ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }