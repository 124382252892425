<template>
    <div v-if="stepsCount" class="progress-bar">
        <div v-sticky="shouldStick" :sticky-offset="stickyOffset" :sticky-z-index="100" class="steps" ref="stickyElement">
            <div :class="{'steps-bar-mobile': isMobile}" class="steps-bar">
                <div v-if="isMobile" class="steps-bar-mobile-summary">
                    <SummaryTotalsPrice class="totals-price" />
                    <a :class="{active: mobileNavShow}" href="javascript:void(0)" class="action product-configurator--nav-link" @click="mobileNavToggle()"><span>Order Details</span></a>
                </div>

                <template v-if="stepsCount > 1">
                    <div v-if="isDesktop || isMobile" :class="{'steps-bar-mobile-items': isMobile}" class="steps-bar-items">
                        <template v-for="step in visibleList">
                            <div v-if="!step.isHidden" :key="step.id" :class="{highlight: step.shouldHighlight, ready: step.isReady, current: step.isCurrent, editable: step.canEdit, [step.id]: step.id }" class="item" @click="editStep(step)">
                                <span class="item-bage">{{ step.index }}</span>
                                <span class="item-label">{{ step.summary_label }}</span>
                            </div>
                        </template>
                    </div>

                    <div v-if="isTablet && currentStep && !currentStep.isHidden" class="steps-bar-items">
                        <div :class="{highlight: currentStep.shouldHighlight, ready: currentStep.isReady, current: currentStep.isCurrent, editable: currentStep.canEdit, [currentStep.id]: currentStep.id }" class="item">
                            <span class="arrow prev" role="navigation" aria-label="Edit Previous Step" title="Edit Previous Step" @click="previousStep(currentStep)">Prev</span>

                            <span class="item-bage">{{ currentStep.index }}</span>
                            <span class="item-label">{{ currentStep.summary_label }}</span>

                            <span class="arrow next" role="navigation" aria-label="Next Step" title="Next Step" @click="nextStep(currentStep)">Next</span>
                        </div>
                    </div>
                </template>

                <template v-if="isMobile && !isMobileOnly">
                    <template v-if="isProductConfigured">
                        <Finish :proceed-to-checkout-button-text="'Checkout'" :order-pair-button-text="'Another Pair?'" class="steps-bar-finish" />
                    </template>
                    <template v-else>
                        <div v-if="mobileNavShow" class="summary_block">
                            <div class="summary_block-header">
                                <span>Summary</span>
                            </div>
                            <div class="summary_block-content">
                                <SummaryTotalsInfo :place="place" :custom-summary="customSummary" class="totals-info" />
                            </div>
                        </div>
                    </template>
                </template>
            </div>
            <div v-if="isMobileOnly && mobileNavShow" class="steps-bar-order-details">
                <a :class="{active: mobileNavShow}" href="javascript:void(0)" class="action product-configurator--nav-toggle steps-bar-order-details-close" @click="mobileNavToggle()"><span>Close</span></a>
                <div class="steps-bar-order-details-header">
                    <span>Order Details</span>
                </div>
                <div class="summary_block steps-bar-order-details-summary">
                    <div class="summary_block-content">
                        <SummaryContactsInfo />
                        <SummaryTotalsInfo :place="place" class="totals-info" />
                        <SummaryTotalsDiscount v-if="hasDiscount" />
                        <SummaryRedeem v-if="isLensablPlus" class="totals-price" />
                        <SummaryTotalsPrice v-if="!showFramesPrice" class="totals-price" />
                        <SummaryAffirm class="affirm-choose-my-lenses" />
                        <SummaryTotalsPrice v-if="showFramesPrice" class="totals-price" />
                    </div>
                </div>
                <SummaryShipping class="standard-shipping-container" />
            </div>
        </div>
    </div>
</template>

<script>
import {
    PLACE_SUMMARY,
    EVENT_STEP_NEXT,
    EVENT_STEP_PREVIOUS,
    EVENT_STEP_EDIT
} from '../../app/utils/constants'

import {mapGetters, mapActions} from 'vuex'
import configurator from './storage/configurator'
import steps from './storage/configurator/steps'
import Sticky from 'vue-sticky-directive'

import SummaryTotalsInfo from '../summary/TotalsInfo.vue'
import SummaryTotalsDiscount from '../summary/TotalsDiscount.vue'
import SummaryTotalsPrice from '../summary/TotalsPrice.vue'
import SummaryAffirm from '../summary/Affirm.vue'
import SummaryShipping from '../summary/Shipping.vue'
import SummaryContactsInfo from '../contacts/summary/ContactsInfo.vue'
import Finish from './Finish.vue'
import SummaryRedeem from '../summary/RedeemTotals.vue'

export default {
    name: 'ProgressBar',
    directives: {Sticky},
    components: {
        SummaryContactsInfo,
        SummaryTotalsInfo,
        SummaryTotalsDiscount,
        SummaryTotalsPrice,
        SummaryAffirm,
        SummaryShipping,
        Finish,
        SummaryRedeem
    },
    props: {
        customSummary: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        place: PLACE_SUMMARY,
        mobileNavShow: false,
        styleObject: {
            border: '1px solid red',
        },
    }),
    computed: {
        ...mapGetters('summary', ['showFramesPrice', 'hasDiscount']),
        ...mapGetters('configurator/steps', ['isProductConfigured', 'visibleList', 'stepsCount', 'currentStep']),
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet', 'isDesktop']),
        ...mapGetters('contacts', ['getSubscribed']),
        ...mapGetters('lensablplus_customer', ['isLensablPlus']),
        shouldStick () {
            return this.isMobile
        },
        stickyOffset () {
            if (!this.isMobileOnly) {
                return `{top: 50, bottom: -1000}`
            }else{
                return `{top: 0, bottom: 45}`
            }
        }
    },
    watch: {
        mobileNavShow (show) {
            if (show) {
                document.body.classList.add('configurator-nav-before-open')
            } else {
                document.body.classList.remove('configurator-nav-before-open')
            }
        }
    },
    beforeCreate:  function () {
        if (!this.$store._modulesNamespaceMap['configurator/']) {
            this.$store.registerModule('configurator', configurator(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['configurator/steps/']) {
            this.$store.registerModule(['configurator', 'steps'], steps(this.$store))
        }

        this.$store.dispatch('configurator/fetchDefaultData')
    },
    created () {
        this.$store.dispatch('configurator/steps/fetchDefaultData')
        this.$store.dispatch('configurator/steps/updateVisibleList')
    },
    methods: {
        ...mapActions('configurator/steps', ['setAnimationLock']),
        previousStep (step) {
            this.setAnimationLock(true)
            this.$bus.$emit(EVENT_STEP_PREVIOUS, step)
            this.$nextTick(() => {
                this.setAnimationLock(false)
            })
        },
        nextStep (step) {
            this.setAnimationLock(true)
            this.$bus.$emit(EVENT_STEP_NEXT, step)
            this.$nextTick(() => {
                this.setAnimationLock(false)
            })
        },
        editStep (step) {
            this.$bus.$emit(EVENT_STEP_EDIT, step)
        },
        mobileNavToggle () {
            this.mobileNavShow = !this.mobileNavShow
        }
    },
}
</script>
