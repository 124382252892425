<template>
    <div :class="productCategoryName">
        <div class="product-configurator-configuration product-details-holder row lensabl-wrapper frames-wrapper bose-container">
            <div class="category-left">
                <div class="bose-header mobile">
                    <h1 class="product-name-mobile"><span>{{ productName }}</span></h1>
                </div>
                <Gallery :class="{'vertical-thumbs': isDesktop}" />
            </div>
            <div class="look-info category-left">
                <div class="product-name">
                    <div class="bose-header">
                        <h1 class="product-name-desktop"><span>{{ productName }}</span></h1>
                    </div>
                    <div v-dompurify-html="productDescription" class="bose-description"></div>
                </div>

                <div class="lensabl-colors instructions">
                    <div class="swatch-opt" v-if="showColors">
                        <ColorSwatches :colors="colors" :checked-index="checkedIndex" @[EVENT_CHANGE_CURRENT_COLOR]="changeCurrentColor" />
                    </div>
                    <div v-else>
                        <DropDown :options="colors" :current-option="checkedIndex" @[EVENT_CHANGE_CURRENT_OPTION]="changeCurrentColor"/>
                    </div>
                    <div v-if="getCurrentProductAttribute('size')" class="hobie-additional">
                        <AdditionalAttributes :data-attributes="additionalAttributes" class="inline" />
                    </div>
                    <p v-dompurify-html="instructionHtml" class="p-instruction"></p>
                    <p v-dompurify-html="affirmHtml" class="affirm-shop-frames"></p>
                    <div class="accrue-frames-section" :style="{ 'margin-bottom': '0.5rem', 'font-size': '15px' }">
                        <span>or save up and earn <span class="accrue-percentage">15%</span> in cash rewards with <span><img src="/media/wysiwyg/accrue-logo-black.png" class="accrue-logo-black-frames-img" /></span></span>
                    </div>

                    <div v-if="isAvailable && !isDesktop" class="product-options-bottom">
                        <div class="actions">
                            <BaseButton :text="'Choose Your Lenses'" @click.native="isMobile ? mobileSwitcher() : goToConfigurator()">
                                <span>
                                    SELECT LENSES
                                </span>
                            </BaseButton>
                        </div>
                    </div>
                    <div v-if="!isAvailable" class="look-params">
                        <div class="look-item">
                            <span class="look-item-info" style="color:red"> This item is sold out</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LensesInclude :unavailable="['blue-light']" />
        <br />
        <AnagramAdBar />
        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator :show-progress-bar="true" />
        </div>
    </div>
</template>

<script>
import CatalogProductView from './CatalogProductView.vue'
import BaseButton from '../../base/BaseButton.vue'
import ResetService from '../../../app/mixins/reset-service'
import LensesInclude from '../cms_info/LensesInclude.vue'
import AdditionalAttributes from './view/AdditionalAttributes.vue'
import {mapGetters} from 'vuex'
import DropDown from './view/DropDown.vue'
import AnagramAdBar from '../../anagram/AnagramAdBar.vue'

export default {
    name: 'CatalogProductRheosView',
    components: {
        DropDown,
        BaseButton,
        LensesInclude,
        AdditionalAttributes,
        AnagramAdBar
    },
    extends: CatalogProductView,
    mixins: [ResetService],
    data: () => {
        return {
            instructionHtml: '',
            affirmHtml: ''
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isDesktop', 'isMobile']),
        ...mapGetters('productView', ['productView', 'product_description', 'media', 'frame_price', 'images', 'productCategoryName', 'colorItem', 'getCurrentProductAttribute']),
        productName () {
            return String(this.product_name).replace(/&#39;/g, "'")
        },
        productDescription () {
            return String(this.product_description).replace(/['"]+/g, '')
        },
        additionalAttributes () {
            return [
                {
                    label: 'Size',
                    value: this.getCurrentProductAttribute('size')
                }
            ]
        },
        showColors(){
            return this.productCategoryName.indexOf('willows') === -1
        }
    },
    watch: {
        frame_price () {
            this.setInstructionText(this.frame_price)
            this.setAffirmPrice(this.frame_price)
        }
    },
    mounted () {
        this.setInstructionText(this.frame_price)
        this.setAffirmPrice(this.frame_price)
    },
    methods: {
        setInstructionText (frame_price, delPrice = false) {
            this.instructionHtml = 'Starting from '
            if (delPrice) {
                this.instructionHtml += '<del><span class="amount">$' + delPrice + '</span></del>'
            }

            this.instructionHtml += '<span>$' + frame_price + '</span>'
        },
        setAffirmPrice (frame_price) {
            const dividedPrice = parseFloat(frame_price) / 3
            const affirmPayment = '$' + Math.round(dividedPrice)
            this.affirmHtml = 'or 3 payments of ' + affirmPayment + ' with <span class="__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed">Affirm</span>'
        },
        goToConfigurator () {
            this.scrollToStepHead(this.visibleFirstStep.id)
        }
    }
}
</script>
