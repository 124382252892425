<template>
    <div :class="productCategoryName">
        <div
            v-show="productView.productInfoShow"
            class="product-configurator-configuration product-details-holder row lensabl-wrapper frames-wrapper bose-container"
        >
            <div class="category-left">
                <div class="bose-header mobile">
                    <h1 class="product-name-mobile">{{ product_name }}</h1>
                </div>
                <Gallery :class="{'vertical-thumbs': isDesktop}" />
            </div>
            <div class="look-info category-left">
                <div class="product-name">
                    <div class="bose-header">
                        <h1 class="product-name-desktop">{{ product_name }}</h1>
                    </div>
                    <div v-dompurify-html="formattedProductDescription(product_description)" class="bose-description"></div>
                </div>
                <div class="lensabl-colors instructions">
                    <div class="swatch-opt hyper-x">
                        <div v-if="product_name.toLowerCase() === 'duo bells' || product_name.toLowerCase() === 'duo lennox'" class="hobie-additional">
                            <p class="hobie-clipons">Select more than one color below to add-on additional clip-ons ($40 each):</p>
                        </div>
                        <ColorSwatchesMulti
                            :colors="colors"
                            :checked-index="checkedColors"
                            :option-item-class="'decorate bigger img'"
                            @[EVENT_CHANGE_CURRENT_COLOR]="addColor"
                        />
                    </div>
                    <p v-dompurify-html="instructionHtml" class="p-instruction"></p>
                    <p v-dompurify-html="affirmHtml" class="affirm-shop-frames"></p>
                    <div class="accrue-frames-section" :style="{ 'margin-bottom': '0.5rem', 'font-size': '15px' }">
                        <span>or save up and earn <span class="accrue-percentage">15%</span> in cash rewards with <span><img src="/media/wysiwyg/accrue-logo-black.png" class="accrue-logo-black-frames-img" /></span></span>
                    </div>
                    <div v-if="isAvailable && !isDesktop" class="product-options-bottom">
                        <div class="actions">
                            <BaseButton
                                :text="'Choose Your Lenses'"
                                @click.native="isMobile ? mobileSwitcher() : goToConfigurator()"
                            >
                                <span v-if="productCategoryName === 'bose'">
                                    Choose Your Lenses
                                </span>
                                <span v-else>
                                    SELECT LENSES
                                </span>
                            </BaseButton>
                        </div>
                    </div>
                    <div v-if="!isAvailable" class="look-params">
                        <div class="look-item">
                            <span class="look-item-info" style="color:red"> This item is sold out</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="productView.productInfoShow" class="category-frames-wrapper frames-wrapper">
            <div class="category-frames-included lenses-section">
                <div class="lens-tab">
                    <span>lenses include:</span>
                </div>
                <div class="lens-tab">
                    <div class="icon ar"><span>Anti-Reflective Coating</span></div>
                </div>
                <div class="lens-tab">
                    <div class="icon  scratch"><span>Anti-Scratch Coating</span></div>
                </div>
                <div class="lens-tab">
                    <div class="icon uv"><span>100% UV Protection</span></div>
                </div>
            </div>
        </div>
        <br />
        <AnagramAdBar />
        <div v-show="isAvailable && productView.productConfiguratorShow" class="product-configurator-wrapper">
            <Configurator />
        </div>
    </div>
</template>

<script>
import CatalogProductView from './CatalogProductView.vue'
import BaseButton from '../../base/BaseButton.vue'
import ResetService from '../../../app/mixins/reset-service'
import ColorSwatchesMulti from './view/ColorSwatchesMulti.vue'
import Affirm from './view/Affirm.vue'
import _size from 'lodash/size'
import {mapGetters} from 'vuex'
import AnagramAdBar from '../../anagram/AnagramAdBar.vue'

export default {
    name: 'CatalogProductClipOnView',
    components: {
        BaseButton,
        ColorSwatchesMulti,
        Affirm,
        AnagramAdBar
    },
    extends: CatalogProductView,
    mixins: [ResetService],
    data: () => {
        return {
            instructionHtml: '',
            checkedColors: [],
            affirmHtml: '',
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isDesktop', 'isMobile']),
        ...mapGetters('productView', ['productView', 'frame_price', 'images', 'productCategoryName', 'colorItem', 'getClipOnPrice'])
    },
    watch: {
        frame_price () {
            this.setInstructionText(this.frame_price)
            this.setAffirmPrice(this.frame_price)
        }
    },
    mounted () {
        this.addColor(this.checkedIndex),
        this.setInstructionText(this.frame_price),
        this.setAffirmPrice(this.frame_price)
    },
    methods: {
        addColor (index) {
            const arrIndex = this.checkedColors.indexOf(index)
            if (arrIndex > -1) {
                if (_size(this.checkedColors) > 1) {
                    this.checkedColors.splice(arrIndex, 1)
                    this.changeFramePrice('-')
                    this.changeFramesColor()
                }
            } else {
                this.checkedColors.push(index)
                this.changeFramePrice('+')
                this.changeFramesColor()
            }
        },
        changeFramePrice (sign) {
            let framesPrice = parseFloat(this.frame_price)

            if (this.checkedColors.length < 2) {
                framesPrice = 120
                this.setFramePrice(String(framesPrice))
                this.setDataToValues({
                    frame_price: framesPrice
                })
            } else {
                framesPrice += Math.sign(sign + this.getClipOnPrice) * this.getClipOnPrice
                this.setFramePrice(String(framesPrice))
                this.setDataToValues({
                    frame_price: framesPrice
                })
            }

        },
        changeFramesColor () {
            const colorsArr = []
            let item
            this.checkedColors.map((index) => {
                item = this.colors[index]
                colorsArr.push(item.color)
            })
            this.setDataToValues({
                frames_value: colorsArr.join(' w/ '),
                frames_SKU: item.frames_SKU
            })
        },
        goToConfigurator () {
            this.scrollToStepHead(this.visibleFirstStep.id)
        },
        setAffirmPrice (frame_price) {
            const dividedPrice = parseFloat(frame_price) / 3
            const affirmPayment = '$' + Math.round(dividedPrice)
            this.affirmHtml = 'or 3 payments of ' + affirmPayment + ' with <span class="__affirm-logo __affirm-logo-blue __ligature__affirm_full_logo__ __processed">Affirm</span>'
        },
        setInstructionText (frame_price, delPrice = false) {
            this.instructionHtml = 'Starting from '
            if (delPrice) {
                this.instructionHtml += '<del><span class="amount">$' + delPrice + '</span></del>'
            }

            this.instructionHtml += '<span>$' + frame_price + '</span>'
        },
    }
}
</script>
