var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plus-homepage" }, [
    _c("section", { staticClass: "home__hero" }, [
      _c("div", { staticClass: "hero-video-button" }, [
        _c(
          "div",
          {
            staticClass: "hero-video-button__container",
            attrs: { "data-hero-video-button": "data-hero-video-button" }
          },
          [
            _c(
              "video",
              {
                staticClass: "hero-video-button__video",
                attrs: {
                  id: "home-video",
                  "data-video-autoplay-delay": "data-video-autoplay-delay",
                  height: "auto",
                  width: "100%",
                  preload: "auto",
                  loop: "loop",
                  "delay-time": "0",
                  autoplay: "",
                  muted: ""
                },
                domProps: { muted: true }
              },
              [
                _c("source", {
                  attrs: {
                    src: "/media/videos/lensabl-widescreen-homepage.webm",
                    type: "video/webm"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    src: "/media/videos/lensabl-widescreen-homepage.mp4",
                    type: "video/mp4"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "hero-video-button__background homebackground"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "hero-video-button__row" }, [
              _c("div", { staticClass: "hero-video-button__layout" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "button-container" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.isMarketingPage ? "/why-plus/a" : "/why-plus"
                      }
                    },
                    [_vm._m(1)]
                  ),
                  _vm._v(" "),
                  _vm._m(2)
                ]),
                _vm._v(" "),
                _vm._m(3)
              ])
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    !_vm.loggedIn
      ? _c("section", { staticClass: "login" }, [
          _vm._m(4),
          _vm._v(" "),
          _vm._m(5)
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(6),
    _vm._v(" "),
    _vm._m(7),
    _vm._v(" "),
    _vm._m(8),
    _vm._v(" "),
    _c("section", [
      _c("div", { staticClass: "row included-benefits-section" }, [
        _c(
          "div",
          { staticClass: "small-18 medium-18 large-11 columns price-table" },
          [
            _c(
              "svg",
              {
                staticClass: "desktop",
                staticStyle: { "enable-background": "new 0 0 1554 694" },
                attrs: {
                  id: "Layer_1",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  viewBox: "0 0 1554 694",
                  "xml:space": "preserve"
                }
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Traditional-block",
                      transform: "translate(1094.400000, 72.000000)"
                    }
                  },
                  [
                    _c("g", { attrs: { id: "Rectangle" } }, [
                      _c("g", { attrs: { id: "Mask" } }, [
                        _c("path", {
                          staticClass: "st0",
                          attrs: {
                            id: "path-1_1_",
                            d:
                              "M7.2,2h441.9c4,0,7.2,3.2,7.2,7.2v588.6c0,4-3.2,7.2-7.2,7.2H7.2c-4,0-7.2-3.2-7.2-7.2V9.2 C0,5.2,3.2,2,7.2,2z"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st1",
                        attrs: {
                          d:
                            "M446.3,605H10c-5.5,0-10-4.5-10-10V110.9h456.3V595C456.3,600.5,451.8,605,446.3,605z"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("g", { staticClass: "st2" }, [
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M106.3,47.1h-7.9V42h21.5v5.1H112V69h-5.8V47.1H106.3z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M133.6,59.3h-4.2V69h-5.8V42h12.6c5.6,0,9.1,3.7,9.1,8.7c0,4.8-3,7.3-5.9,8l6.1,10.3h-6.6L133.6,59.3z M135.4,46.9h-6v7.5h6c2.3,0,4-1.5,4-3.7C139.5,48.4,137.7,46.9,135.4,46.9z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M166.9,64.3h-12.1l-1.7,4.7h-6.3l10.4-27h7.2l10.4,27h-6.3L166.9,64.3z M156.3,59.4h8.9L160.8,47L156.3,59.4z "
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M177.4,42H188c8.5,0,14.3,5.4,14.3,13.5S196.5,69,188,69h-10.7L177.4,42L177.4,42z M188,63.9 c5.3,0,8.5-3.8,8.5-8.4c0-4.8-2.9-8.5-8.5-8.5h-4.9v16.9H188z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: { d: "M206.4,42h5.8v27h-5.8V42z" }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M223.8,47.1h-7.9V42h21.5v5.1h-7.9V69h-5.8L223.8,47.1L223.8,47.1z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: { d: "M241.1,42h5.8v27h-5.8V42z" }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M265,41.5c8.2,0,14.1,5.8,14.1,14s-6,14-14.1,14s-14.1-5.8-14.1-14S256.9,41.5,265,41.5z M265,46.6 c-5,0-8.2,3.8-8.2,8.9c0,5,3.2,8.9,8.2,8.9s8.2-3.8,8.2-8.9C273.2,50.5,270,46.6,265,46.6z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M288.9,50.9V69h-5.8V42h5.9l12.5,17.5V42h5.8v27h-5.5L288.9,50.9z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M329.9,64.3h-12.1l-1.7,4.7h-6.3l10.4-27h7.2l10.4,27h-6.3L329.9,64.3z M319.4,59.4h9L323.9,47L319.4,59.4z"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "st3",
                        attrs: {
                          d:
                            "M340.4,42h5.8v21.9h11.4V69h-17.2L340.4,42L340.4,42z"
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Rectangle_1_",
                      transform: "translate(554.400000, 0.000000)"
                    }
                  },
                  [
                    _c("g", { attrs: { id: "Mask_2_" } }, [
                      _c("path", {
                        staticClass: "st4",
                        attrs: {
                          id: "path-5_1_",
                          d:
                            "M7.2,2h524.7c4,0,7.2,3.2,7.2,7.2v660.6c0,4-3.2,7.2-7.2,7.2H7.2c-4,0-7.2-3.2-7.2-7.2V9.2 C0,5.2,3.2,2,7.2,2z"
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "g",
                  {
                    attrs: {
                      id: "lensabl-plus-wordmark-white",
                      transform: "translate(605.049398, 39.600000)"
                    }
                  },
                  [
                    _c("path", {
                      staticClass: "st5",
                      attrs: {
                        id: "Fill-1",
                        d:
                          "M201.3,75.6c1.2-2.1,1.8-4.8,1.8-7.8c0-2.7-0.5-5-1.4-6.8s-2.2-3.4-3.8-4.6 c-1.6-1.2-3.6-2.2-5.8-3s-4.6-1.4-7.2-1.9c-1.9-0.3-3.8-0.7-5.6-1.3c-1.8-0.5-3.5-1.1-4.9-1.8c-1.5-0.7-2.7-1.6-3.6-2.5 c-1-1.1-1.5-2.4-1.5-3.9s0.4-2.9,1.2-4.1c0.8-1.1,1.8-2.1,3-2.8s2.5-1.3,4-1.6c1.4-0.3,2.9-0.5,4.3-0.5c3.1,0,5.8,0.7,7.9,2.1 c1.3,0.8,2.5,1.9,3.5,3c0.7,0.8,1.8,0.9,2.6,0.4l3.1-2c0.5-0.3,0.8-0.8,0.9-1.4c0.1-0.5,0-1.1-0.4-1.5c-1.6-2.2-3.7-3.8-6.1-4.9 c-3.4-1.6-7.1-2.4-11-2.4c-2.6,0-5.2,0.4-7.6,1.1c-2.4,0.7-4.7,1.7-6.6,3.1c-1.9,1.3-3.5,3-4.6,4.9c-1.1,2-1.7,4.3-1.7,6.9 c0,2.8,0.5,5,1.5,6.7s2.4,3.2,4.1,4.3c1.8,1.1,3.9,2.1,6.2,2.8c2.5,0.7,5.1,1.4,7.7,2c1.6,0.3,3.2,0.7,4.6,1.1c1.6,0.4,3,1,4.2,1.8 c1.3,0.8,2.3,1.7,3.1,2.8c0.8,1.2,1.3,2.7,1.3,4.4c0,1.8-0.4,3.3-1.3,4.6c-0.8,1.2-1.9,2.3-3.3,3.1c-1.3,0.8-2.8,1.4-4.5,1.7 c-1.6,0.3-3.2,0.5-4.7,0.5c-3.7,0-6.7-0.8-9.2-2.3c-1.7-1-3.2-2.3-4.7-3.8c-0.7-0.7-1.8-0.8-2.6-0.2l-2.9,2.3 c-0.4,0.3-0.7,0.8-0.8,1.4s0.1,1.1,0.5,1.6c2.2,2.6,4.7,4.4,7.3,5.6c3.4,1.5,7.6,2.2,12.4,2.2c2.8,0,5.6-0.3,8.2-1 c2.6-0.6,5-1.6,7.1-3C198.4,79.5,200.1,77.8,201.3,75.6"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "st5",
                      attrs: {
                        id: "Fill-2",
                        d:
                          "M71.2,51.4H39.3l0.2-1.2c0.7-5.2,2.8-9.4,6.3-12.5c3.5-3.1,8.1-4.7,13.7-4.7s10.1,1.6,13.3,4.7 c3.2,3.1,5,7.4,5.1,12.5v1.1L71.2,51.4z M85.6,56.8c0.4-0.4,0.6-0.9,0.6-1.4c-0.1-5.2-0.7-11.4-4-16.9L82,38.2 c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.4-0.6l-0.1-0.2c-0.9-1.3-1.7-2.4-2.5-3.3c-2.3-2.5-5.1-4.4-8.3-5.7 c-3.2-1.3-6.8-2-10.7-2c-4.1,0-8,0.7-11.4,2.1c-3.5,1.4-6.5,3.4-9,6c-2.5,2.6-4.5,5.7-5.9,9.3s-2.1,7.6-2.1,11.9 c0,4,0.7,7.9,2,11.5c1.3,3.5,3.2,6.7,5.6,9.3c2.4,2.6,5.4,4.7,8.8,6.2c3.5,1.5,7.4,2.3,11.8,2.3c5.3,0,9.9-0.9,13.9-2.6 c3.1-1.3,6.1-3.5,8.8-6.4c0.4-0.4,0.6-1,0.6-1.6s-0.3-1.1-0.8-1.4l-2.5-1.9c-0.8-0.6-2-0.5-2.7,0.2c-1.9,1.9-4,3.5-6.2,4.6 c-3,1.6-6.8,2.3-11.1,2.3c-2.8,0-5.4-0.5-7.7-1.6c-2.3-1-4.4-2.5-6.1-4.2c-1.7-1.8-3.1-3.9-4.2-6.2c-1.1-2.4-1.7-4.9-1.9-7.5 l-0.1-1.1h44.7C84.7,57.4,85.2,57.2,85.6,56.8L85.6,56.8z"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group-7",
                          transform: "translate(0.650602, 0.000000)"
                        }
                      },
                      [
                        _c("g", { attrs: { id: "Clip-4" } }),
                        _vm._v(" "),
                        _c("path", {
                          staticClass: "st5",
                          attrs: {
                            id: "Fill-3",
                            d:
                              "M265.9,57.5c-1,10.2-9.2,18.4-19.4,19.4c-0.7,0.1-1.5,0.1-2.2,0.1c-5.8,0-11.3-2.3-15.4-6.4 c-4.6-4.6-6.9-11-6.2-17.5c1-10.2,9.2-18.3,19.4-19.4c6.6-0.7,13,1.6,17.6,6.3C264.3,44.6,266.6,51,265.9,57.5z M298.4,53.1 c1-10.2,9.2-18.3,19.4-19.4c6.6-0.7,13,1.6,17.6,6.3c4.6,4.6,6.9,11,6.2,17.5c-1,10.2-9.2,18.4-19.4,19.4 c-0.7,0.1-1.5,0.1-2.2,0.1c-5.8,0-11.3-2.3-15.4-6.4C300,66,297.7,59.7,298.4,53.1z M268.3,84.8h3.5c1.1,0,2-0.9,2-2V61.2 c0.1-4.8,3.8-8.6,8.5-8.6c4.3,0,7.5,2.7,8.5,7.3l0,0l0.2,0.7c2.6,14,14.8,24.1,29,24.1c15,0,27.6-11.2,29.3-26l0.1-0.9h0.9 c2.3-0.1,4.1-2,4.1-4.3c0-2.4-2-4.3-4.3-4.3c-0.1,0-0.2,0-0.3,0l-0.9,0.1l-0.1-0.3c-3.1-12.8-13.7-22-26.4-23 c-8.3-0.7-16.5,2.2-22.6,7.9l-1.8,1.7V4.3c0-1.1-0.9-2-2-2h-3.5c-1.1,0-2,0.9-2,2v43.4L289,47c-4.7-2.2-10.3-1.9-14.7,0.8 l-1.2,0.7l-0.4-1.3c-3.6-12.5-15.3-21.3-28.4-21.3c-13.7,0-25.5,9.3-28.7,22.6l-0.2,1l-1-0.2c-0.3,0-0.5-0.1-0.7-0.1 c-2.4,0-4.3,1.9-4.3,4.3c0,2.4,1.9,4.3,4.3,4.3h0.2h1l0.1,1c1.8,14.8,14.4,25.9,29.3,25.9c7.5,0,14.7-2.8,20.2-8l1.8-1.7v7.8 C266.3,83.9,267.2,84.8,268.3,84.8L268.3,84.8z"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          staticClass: "st5",
                          attrs: {
                            id: "Fill-5",
                            d:
                              "M5.1,2.3H2c-1.1,0-2,0.9-2,2v3.5c0,1.1,0.9,2,2,2h3.1c2.4,0,4.3,1.9,4.3,4.3v68.7 c0,1.1,0.9,2,2,2h3.5c1.1,0,2-0.9,2-2V14.1C16.9,7.6,11.6,2.3,5.1,2.3"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          staticClass: "st5",
                          attrs: {
                            id: "Fill-6",
                            d:
                              "M380.4,77.3h-3.1c-2.4,0-4.3-1.9-4.3-4.3V4.1c0-1.1-0.9-2-2-2h-3.5c-1.1,0-2,0.9-2,2V73 c0,6.5,5.3,11.8,11.8,11.8h3.1c1.1,0,2-0.9,2-2v-3.5C382.4,78.2,381.5,77.3,380.4,77.3"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "st5",
                      attrs: {
                        id: "Fill-8",
                        d:
                          "M231.5,59.6c-0.4,0-0.9-0.3-1-0.8c-0.5-2-0.5-4.2,0-6.4c1.2-5.6,5.7-10.1,11.3-11.3 c1.1-0.2,2.2-0.4,3.2-0.4c1.1,0,2.2,0.1,3.2,0.4c0.9,0.2,1.1,1.3,0.3,1.8c-2.7,1.8-5.9,4.2-9.1,7.4c-3.1,3.1-5.4,6.2-7.2,8.9 C232.1,59.5,231.8,59.6,231.5,59.6"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "st5",
                      attrs: {
                        id: "Fill-9",
                        d:
                          "M334.1,59.6c-0.3,0-0.6-0.1-0.8-0.5c-1.8-2.7-4.1-5.7-7.2-8.9c-3.2-3.2-6.3-5.6-9.1-7.4 c-0.7-0.5-0.5-1.6,0.3-1.8c1-0.2,2.1-0.4,3.2-0.4s2.1,0.1,3.2,0.4c5.6,1.2,10.1,5.7,11.3,11.3c0.5,2.2,0.5,4.4,0,6.4 C335,59.3,334.5,59.6,334.1,59.6"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "st5",
                      attrs: {
                        id: "Fill-10",
                        d:
                          "M141.8,84.8h3.5c1.1,0,2-0.9,2-2V50.6c0-3.3-0.4-6.4-1.3-9.2c-0.8-2.7-2.1-5.1-3.8-7.1 c-1.7-1.9-3.9-3.4-6.5-4.5s-5.8-1.6-9.5-1.6c-8.2,0-16,3.2-20.2,8.5l-0.5,0.7v-9c0-1.1-0.9-2-2-2h-2.7c-1.1,0-2,0.9-2,2v54.5 c0,1.1,0.9,2,2,2h3.5c1.1,0,2-0.9,2-2V58.1c0-3.4,0.4-6.5,1.1-9.3s1.9-5.3,3.4-7.4c1.5-2.1,3.6-3.8,6-4.9c2.4-1.1,5.3-1.7,8.6-1.7 c4.9,0,8.6,1.6,10.9,4.6s3.5,7.1,3.5,12.1v31.2C139.8,83.9,140.7,84.8,141.8,84.8"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "st5",
                      attrs: {
                        id: "Fill-11",
                        d:
                          "M434.2,40.1h-15.4V24.7c0-1.1-0.9-2-2-2h-3.4c-1.1,0-2,0.9-2,2v15.4H396c-1.1,0-2,0.9-2,2v3.4 c0,1.1,0.9,2,2,2h15.4V63c0,1.1,0.9,2,2,2h3.4c1.1,0,2-0.9,2-2V47.6h15.4c1.1,0,2-0.9,2-2v-3.4C436.3,41,435.3,40.1,434.2,40.1"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M150.2,249.2h8.4c4.2,0,6.6,2.9,6.6,6.3c0,3.4-2.4,6.3-6.6,6.3h-5.8v8.3h-2.6L150.2,249.2L150.2,249.2z M158.3,251.5h-5.5v8h5.5c2.5,0,4.2-1.6,4.2-4C162.5,253.2,160.8,251.5,158.3,251.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M168.6,255h2.4v2.5c1.2-1.6,3-2.8,5.1-2.8v2.4c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0-3.4,1.2-4.1,2.4v10.8h-2.4V255 H168.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M185.6,254.6c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C178.1,258.2,181.3,254.6,185.6,254.6z M180.6,261.6h10c0-2.3-1.5-5.1-5-5.1 C182.3,256.6,180.7,259.3,180.6,261.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M196.6,266.5c1,1.2,2.9,2.2,5,2.2c2.3,0,3.6-1,3.6-2.5c0-1.7-1.9-2.2-3.9-2.7c-2.6-0.6-5.5-1.3-5.5-4.4 c0-2.4,2-4.4,5.7-4.4c2.6,0,4.4,1,5.6,2.2l-1.1,1.6c-0.9-1.1-2.6-1.9-4.5-1.9c-2.1,0-3.4,0.9-3.4,2.3c0,1.5,1.7,2,3.7,2.4 c2.6,0.6,5.7,1.4,5.7,4.7c0,2.6-2,4.6-6,4.6c-2.5,0-4.6-0.8-6.1-2.4L196.6,266.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M218.1,254.6c2.8,0,4.4,1.1,5.6,2.6l-1.6,1.4c-1-1.4-2.3-2-3.9-2c-3.2,0-5.3,2.5-5.3,5.9s2,5.9,5.3,5.9 c1.6,0,2.9-0.6,3.9-2l1.6,1.4c-1.2,1.5-2.8,2.6-5.6,2.6c-4.5,0-7.6-3.5-7.6-8C210.5,258.1,213.6,254.6,218.1,254.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M227,255h2.4v2.5c1.2-1.6,3-2.8,5.1-2.8v2.4c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0-3.4,1.2-4.1,2.4v10.8H227V255z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M237,250.8c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6C237.7,252.4,237,251.7,237,250.8z M237.4,255h2.4v15.2h-2.4V255z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M244.5,276v-21h2.4v2.3c1.1-1.5,3-2.6,5.1-2.6c4,0,6.8,3,6.8,8c0,4.9-2.8,8-6.8,8c-2.1,0-3.9-1-5.1-2.7v8.1 L244.5,276L244.5,276z M251.4,256.7c-1.9,0-3.7,1.1-4.5,2.4v6.9c0.8,1.3,2.7,2.5,4.5,2.5c3.1,0,4.9-2.5,4.9-5.9 C256.2,259.2,254.4,256.7,251.4,256.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M263,267v-10h-2.5v-2h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6l0.7,1.8 c-0.6,0.6-1.4,1-2.8,1C264.1,270.6,263,269.3,263,267z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M271.4,250.8c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6C272.1,252.4,271.4,251.7,271.4,250.8z M271.8,255h2.4v15.2h-2.4V255z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M278,262.6c0-4.4,2.9-8,7.5-8s7.5,3.6,7.5,8s-2.9,8-7.5,8S278,267,278,262.6z M290.6,262.6 c0-3.1-1.8-5.9-5-5.9s-5.1,2.8-5.1,5.9s1.8,5.9,5.1,5.9S290.6,265.7,290.6,262.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M307.2,260.2c0-2.7-1.4-3.5-3.4-3.5c-1.9,0-3.6,1.1-4.5,2.4v11.1h-2.4V255h2.4v2.2c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.9,1.6,4.9,4.9v10.7h-2.4V260.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M322.5,249.2h13.8v2.3h-11.1v6.8H336v2.3h-10.9v9.6h-2.6V249.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M339.7,255h2.4v2.5c1.2-1.6,3-2.8,5.1-2.8v2.4c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0-3.4,1.2-4.1,2.4v10.8h-2.4V255 H339.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M359.6,268.5c-1.3,1.4-3,2.1-5,2.1c-2.6,0-5.3-1.7-5.3-5c0-3.4,2.7-5,5.3-5c2.1,0,3.8,0.7,5,2.1V260 c0-2-1.6-3.2-3.8-3.2c-1.8,0-3.3,0.7-4.7,2.1l-1.1-1.6c1.6-1.7,3.6-2.5,6.1-2.5c3.2,0,5.9,1.4,5.9,5.1v10.5h-2.4V268.5z M359.6,264.1c-0.9-1.3-2.5-1.9-4.2-1.9c-2.2,0-3.7,1.4-3.7,3.3s1.5,3.3,3.7,3.3c1.7,0,3.3-0.6,4.2-1.9V264.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M385.1,259.9c0-1.9-0.9-3.1-2.8-3.1c-1.6,0-3.2,1.1-4,2.3v11.1h-2.4v-10.3c0-1.9-0.8-3.1-2.8-3.1 c-1.5,0-3.2,1.1-4,2.4v11.1h-2.4V255h2.4v2.2c0.6-1,2.7-2.6,4.9-2.6c2.3,0,3.7,1.3,4.1,2.8c0.9-1.4,2.9-2.8,5.1-2.8 c2.8,0,4.3,1.5,4.3,4.6v11h-2.4L385.1,259.9L385.1,259.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M398.8,254.6c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C391.3,258.2,394.4,254.6,398.8,254.6z M393.8,261.6h10c0-2.3-1.5-5.1-5-5.1 C395.5,256.6,393.9,259.3,393.8,261.6z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("text", {
                  staticClass: "st3 st6 st7",
                  attrs: { transform: "matrix(1 0 0 1 407.5353 270.2)" }
                }),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M172.4,323.6c0-6.5,4.8-10.9,10.7-10.9c3.8,0,6.4,1.8,8.1,4.2l-2.2,1.2c-1.2-1.8-3.4-3.1-5.9-3.1 c-4.5,0-8,3.5-8,8.5s3.5,8.5,8,8.5c2.5,0,4.7-1.3,5.9-3.1l2.2,1.2c-1.8,2.4-4.3,4.2-8.1,4.2C177.2,334.5,172.4,330.1,172.4,323.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M193.6,326.5c0-4.4,2.9-8,7.5-8s7.5,3.6,7.5,8s-2.9,8-7.5,8S193.6,330.9,193.6,326.5z M206.1,326.5 c0-3.1-1.8-5.9-5-5.9s-5.1,2.8-5.1,5.9s1.8,5.9,5.1,5.9C204.3,332.4,206.1,329.6,206.1,326.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M222.7,324.1c0-2.7-1.4-3.5-3.4-3.5c-1.9,0-3.6,1.1-4.5,2.4v11.1h-2.4v-15.2h2.4v2.2c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.8,1.6,4.8,4.9v10.7h-2.4L222.7,324.1L222.7,324.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M230.3,330.9v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C231.4,334.5,230.3,333.2,230.3,330.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M248.6,332.4c-1.3,1.4-3,2.1-5,2.1c-2.6,0-5.3-1.7-5.3-5c0-3.4,2.7-5,5.3-5c2.1,0,3.8,0.7,5,2.1v-2.7 c0-2-1.6-3.2-3.8-3.2c-1.8,0-3.3,0.7-4.7,2.1L239,321c1.6-1.7,3.6-2.5,6.1-2.5c3.2,0,5.9,1.4,5.9,5.1v10.5h-2.4V332.4z M248.6,328 c-0.9-1.3-2.5-1.9-4.2-1.9c-2.2,0-3.7,1.4-3.7,3.3s1.5,3.3,3.7,3.3c1.7,0,3.3-0.6,4.2-1.9V328z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M262.4,318.5c2.8,0,4.4,1.1,5.6,2.6l-1.6,1.4c-1-1.4-2.3-2-3.9-2c-3.2,0-5.3,2.5-5.3,5.9s2,5.9,5.3,5.9 c1.6,0,2.9-0.6,3.9-2l1.6,1.4c-1.2,1.5-2.8,2.6-5.6,2.6c-4.5,0-7.6-3.5-7.6-8C254.8,322,257.9,318.5,262.4,318.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M271.8,330.9v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C272.9,334.5,271.8,333.2,271.8,330.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: { d: "M288.8,313.1h2.6v18.7h9.8v2.3h-12.4V313.1z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M310.7,318.5c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C303.2,322.1,306.3,318.5,310.7,318.5z M305.7,325.5h10c0-2.3-1.5-5.1-5-5.1 C307.4,320.5,305.8,323.2,305.7,325.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M332.1,324.1c0-2.7-1.4-3.5-3.4-3.5c-1.9,0-3.6,1.1-4.5,2.4v11.1h-2.4v-15.2h2.4v2.2c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.8,1.6,4.8,4.9v10.7H332v-10H332.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M339.1,330.4c1,1.2,2.9,2.2,5,2.2c2.3,0,3.6-1,3.6-2.5c0-1.7-1.9-2.2-3.9-2.7c-2.6-0.6-5.5-1.3-5.5-4.4 c0-2.4,2-4.4,5.7-4.4c2.6,0,4.4,1,5.6,2.2l-1.1,1.6c-0.9-1.1-2.6-1.9-4.5-1.9c-2.1,0-3.4,0.9-3.4,2.3c0,1.5,1.7,2,3.7,2.4 c2.6,0.6,5.7,1.4,5.7,4.7c0,2.6-2,4.6-6,4.6c-2.5,0-4.6-0.8-6.1-2.4L339.1,330.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M360.4,318.5c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C352.9,322.1,356.1,318.5,360.4,318.5z M355.4,325.5h10c0-2.3-1.5-5.1-5-5.1 C357.2,320.5,355.5,323.2,355.4,325.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M371.5,330.4c1,1.2,2.9,2.2,5,2.2c2.3,0,3.6-1,3.6-2.5c0-1.7-1.9-2.2-3.9-2.7c-2.6-0.6-5.5-1.3-5.5-4.4 c0-2.4,2-4.4,5.7-4.4c2.6,0,4.4,1,5.6,2.2l-1.1,1.6c-0.9-1.1-2.6-1.9-4.5-1.9c-2.1,0-3.4,0.9-3.4,2.3c0,1.5,1.7,2,3.7,2.4 c2.6,0.6,5.7,1.4,5.7,4.7c0,2.6-2,4.6-6,4.6c-2.5,0-4.6-0.8-6.1-2.4L371.5,330.4z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("text", {
                  staticClass: "st3 st6 st7",
                  attrs: { transform: "matrix(1 0 0 1 383.8336 334.1)" }
                }),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st3",
                    attrs: { d: "M166.9,377h2.6v21h-2.6V377z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M192.7,387.7c0-1.9-0.9-3.1-2.8-3.1c-1.6,0-3.2,1.1-4,2.3V398h-2.4v-10.3c0-1.9-0.8-3.1-2.8-3.1 c-1.5,0-3.2,1.1-4,2.4v11h-2.4v-15.2h2.4v2.2c0.6-1,2.7-2.6,4.9-2.6c2.3,0,3.7,1.3,4.1,2.8c0.9-1.4,2.9-2.8,5.1-2.8 c2.8,0,4.3,1.5,4.3,4.6v11h-2.4L192.7,387.7L192.7,387.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M199.8,403.8v-21h2.4v2.3c1.1-1.5,3-2.6,5.1-2.6c4,0,6.8,3,6.8,8c0,4.9-2.8,8-6.8,8c-2.1,0-3.9-1-5.1-2.7v8.1 L199.8,403.8L199.8,403.8z M206.7,384.5c-1.9,0-3.7,1.1-4.5,2.4v6.9c0.8,1.3,2.7,2.5,4.5,2.5c3.1,0,4.9-2.5,4.9-5.9 C211.6,387,209.7,384.5,206.7,384.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M227.4,396.3c-1.3,1.4-3,2.1-5,2.1c-2.6,0-5.3-1.7-5.3-5c0-3.4,2.7-5,5.3-5c2.1,0,3.8,0.7,5,2.1v-2.7 c0-2-1.6-3.2-3.8-3.2c-1.8,0-3.3,0.7-4.7,2.1l-1.1-1.6c1.6-1.7,3.6-2.5,6.1-2.5c3.2,0,5.9,1.4,5.9,5.1V398h-2.4V396.3z M227.4,391.9c-0.9-1.3-2.5-1.9-4.2-1.9c-2.2,0-3.7,1.4-3.7,3.3s1.5,3.3,3.7,3.3c1.7,0,3.3-0.6,4.2-1.9V391.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M241.2,382.4c2.8,0,4.4,1.1,5.6,2.6l-1.6,1.4c-1-1.4-2.3-2-3.9-2c-3.2,0-5.3,2.5-5.3,5.9s2,5.9,5.3,5.9 c1.6,0,2.9-0.6,3.9-2l1.6,1.4c-1.2,1.5-2.8,2.6-5.6,2.6c-4.5,0-7.6-3.5-7.6-8C233.6,385.9,236.7,382.4,241.2,382.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M250.5,394.8v-10H248v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C251.7,398.4,250.5,397.1,250.5,394.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M274.4,389.7h-4.2v8.3h-2.6v-21h8.4c3.8,0,6.6,2.5,6.6,6.3s-2.6,5.9-5.4,6.1l5.7,8.6h-3.1L274.4,389.7z M275.7,379.3h-5.5v8h5.5c2.5,0,4.2-1.7,4.2-4C279.9,381,278.2,379.3,275.7,379.3z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M292.3,382.4c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C284.8,386,287.9,382.4,292.3,382.4z M287.3,389.4h10c0-2.3-1.5-5.1-5-5.1 C289,384.4,287.4,387.1,287.3,389.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M303.3,394.3c1,1.2,2.9,2.2,5,2.2c2.3,0,3.6-1,3.6-2.5c0-1.7-1.9-2.2-3.9-2.7c-2.6-0.6-5.5-1.3-5.5-4.4 c0-2.4,2-4.4,5.7-4.4c2.6,0,4.4,1,5.6,2.2l-1.1,1.6c-0.9-1.1-2.6-1.9-4.5-1.9c-2.1,0-3.4,0.9-3.4,2.3c0,1.5,1.7,2,3.7,2.4 c2.6,0.6,5.7,1.4,5.7,4.7c0,2.6-2,4.6-6,4.6c-2.5,0-4.6-0.8-6.1-2.4L303.3,394.3z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M317.6,378.6c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6C318.4,380.2,317.6,379.5,317.6,378.6z M318,382.8h2.4V398H318V382.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M325,394.3c1,1.2,2.9,2.2,5,2.2c2.3,0,3.6-1,3.6-2.5c0-1.7-1.9-2.2-3.9-2.7c-2.6-0.6-5.5-1.3-5.5-4.4 c0-2.4,2-4.4,5.7-4.4c2.6,0,4.4,1,5.6,2.2l-1.1,1.6c-0.9-1.1-2.6-1.9-4.5-1.9c-2.1,0-3.4,0.9-3.4,2.3c0,1.5,1.7,2,3.7,2.4 c2.6,0.6,5.7,1.4,5.7,4.7c0,2.6-2,4.6-6,4.6c-2.5,0-4.6-0.8-6.1-2.4L325,394.3z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M340.3,394.8v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C341.4,398.4,340.3,397.1,340.3,394.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M358.6,396.3c-1.3,1.4-3,2.1-5,2.1c-2.6,0-5.3-1.7-5.3-5c0-3.4,2.7-5,5.3-5c2.1,0,3.8,0.7,5,2.1v-2.7 c0-2-1.6-3.2-3.8-3.2c-1.8,0-3.3,0.7-4.7,2.1l-1.1-1.6c1.6-1.7,3.6-2.5,6.1-2.5c3.2,0,5.9,1.4,5.9,5.1V398h-2.4V396.3z M358.6,391.9c-0.9-1.3-2.5-1.9-4.2-1.9c-2.2,0-3.7,1.4-3.7,3.3s1.5,3.3,3.7,3.3c1.7,0,3.3-0.6,4.2-1.9V391.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M375.9,388c0-2.7-1.4-3.5-3.4-3.5c-1.9,0-3.6,1.1-4.5,2.4V398h-2.4v-15.2h2.4v2.2c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.9,1.6,4.9,4.9V398h-2.4V388z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M383.5,394.8v-10H381v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C384.6,398.4,383.5,397.1,383.5,394.8z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("text", {
                  staticClass: "st3 st6 st7",
                  attrs: { transform: "matrix(1 0 0 1 389.8969 398)" }
                }),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M226.4,457.2h-10.6l-1.9,4.7H211l8.4-21h3.2l8.5,21h-2.9L226.4,457.2z M216.6,454.9h9.1l-4.5-11.7L216.6,454.9 z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M244.1,451.9c0-2.7-1.4-3.5-3.4-3.5c-1.9,0-3.6,1.1-4.5,2.4v11.1h-2.4v-15.2h2.4v2.2c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.9,1.6,4.9,4.9v10.7h-2.4V451.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M251.7,458.7v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1H254v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C252.8,462.3,251.7,461,251.7,458.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M260.1,442.5c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6C260.8,444.1,260.1,443.4,260.1,442.5z M260.5,446.7h2.4v15.2h-2.4V446.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: { d: "M266.1,453.2h7.6v2.1h-7.6V453.2z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M278.2,464c1.3,1.5,2.8,2.1,4.9,2.1c2.5,0,4.9-1.2,4.9-4.6v-2.2c-1.1,1.5-3,2.7-5.1,2.7c-4,0-6.8-3-6.8-7.9 s2.8-7.9,6.8-7.9c2,0,3.9,1,5.1,2.7v-2.3h2.4v14.8c0,5-3.5,6.6-7.2,6.6c-2.6,0-4.3-0.5-6.1-2.3L278.2,464z M288,450.9 c-0.8-1.3-2.6-2.5-4.5-2.5c-3.1,0-4.9,2.4-4.9,5.8c0,3.3,1.8,5.8,4.9,5.8c1.9,0,3.7-1.2,4.5-2.5V450.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: { d: "M295.1,440.9h2.4v21h-2.4V440.9z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M311.7,460.2c-1.3,1.4-3,2.1-5,2.1c-2.6,0-5.3-1.7-5.3-5c0-3.4,2.7-5,5.3-5c2.1,0,3.8,0.7,5,2.1v-2.7 c0-2-1.6-3.2-3.8-3.2c-1.8,0-3.3,0.7-4.7,2.1l-1.1-1.6c1.6-1.7,3.6-2.5,6.1-2.5c3.2,0,5.9,1.4,5.9,5.1v10.5h-2.4V460.2z M311.7,455.8c-0.9-1.3-2.5-1.9-4.2-1.9c-2.2,0-3.7,1.4-3.7,3.3s1.5,3.3,3.7,3.3c1.7,0,3.3-0.6,4.2-1.9V455.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M318.8,446.7h2.4v2.5c1.2-1.6,3-2.8,5.1-2.8v2.4c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0-3.4,1.2-4.1,2.4v10.8h-2.4 v-15.2H318.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M335.8,446.3c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C328.3,449.9,331.4,446.3,335.8,446.3z M330.8,453.3h10c0-2.3-1.5-5.1-5-5.1 C332.5,448.3,330.9,451,330.8,453.3z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("text", {
                  staticClass: "st3 st6 st7",
                  attrs: { transform: "matrix(1 0 0 1 344.5372 461.9)" }
                }),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M140.3,504.8h9.4c3.7,0,5.9,2.2,5.9,5.4c0,2.6-1.8,4.4-3.7,4.8c2.2,0.3,4.1,2.6,4.1,5.2c0,3.4-2.2,5.7-6.1,5.7 h-9.6L140.3,504.8L140.3,504.8z M149.2,513.9c2.4,0,3.6-1.4,3.6-3.4c0-1.9-1.3-3.4-3.6-3.4h-6.3v6.8H149.2z M149.3,523.5 c2.5,0,3.9-1.4,3.9-3.6c0-1.9-1.3-3.6-3.9-3.6h-6.4v7.2L149.3,523.5L149.3,523.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: { d: "M160,504.8h2.4v21H160V504.8z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M177.3,523.7c-1.2,1.3-3.1,2.5-5.4,2.5c-3.2,0-4.8-1.5-4.8-4.9v-10.7h2.4v10c0,2.7,1.4,3.5,3.4,3.5 c1.9,0,3.6-1.1,4.5-2.3v-11.2h2.4v15.2h-2.4L177.3,523.7L177.3,523.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M191,510.2c4.6,0,7.3,3.6,7.3,8.2v0.6H186c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C183.5,513.8,186.7,510.2,191,510.2z M186,517.2h10c0-2.3-1.5-5.1-5-5.1 C187.8,512.2,186.2,514.9,186,517.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: { d: "M200.7,517.1h7.6v2.1h-7.6V517.1z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: { d: "M211.6,504.8h2.4v21h-2.4V504.8z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M218.3,506.4c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6S218.3,507.3,218.3,506.4z M218.7,510.6h2.4v15.2h-2.4V510.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M226.9,527.9c1.3,1.5,2.8,2.1,4.9,2.1c2.5,0,4.9-1.2,4.9-4.6v-2.2c-1.1,1.5-3,2.7-5.1,2.7c-4,0-6.8-3-6.8-7.9 s2.8-7.9,6.8-7.9c2,0,3.9,1,5.1,2.7v-2.3h2.4v14.8c0,5-3.5,6.6-7.2,6.6c-2.6,0-4.3-0.5-6.1-2.3L226.9,527.9z M236.8,514.8 c-0.8-1.3-2.6-2.5-4.5-2.5c-3.1,0-4.9,2.4-4.9,5.8c0,3.3,1.8,5.8,4.9,5.8c1.9,0,3.7-1.2,4.5-2.5V514.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M254.2,515.8c0-2.7-1.4-3.5-3.4-3.5c-1.8,0-3.6,1.1-4.5,2.4v11.1h-2.4v-21h2.4v8c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.9,1.5,4.9,4.9v10.7h-2.4L254.2,515.8L254.2,515.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M261.7,522.6v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C262.9,526.2,261.7,524.9,261.7,522.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M278.7,504.8h8.4c4.2,0,6.6,2.9,6.6,6.3c0,3.4-2.4,6.3-6.6,6.3h-5.8v8.3h-2.6V504.8z M286.9,507.1h-5.5v8h5.5 c2.5,0,4.2-1.6,4.2-4C291,508.8,289.3,507.1,286.9,507.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M297.1,510.6h2.4v2.5c1.2-1.6,3-2.8,5.1-2.8v2.4c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0-3.4,1.2-4.1,2.4v10.8h-2.4 v-15.2H297.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M306.6,518.2c0-4.4,2.9-8,7.5-8s7.5,3.6,7.5,8s-2.9,8-7.5,8S306.6,522.6,306.6,518.2z M319.2,518.2 c0-3.1-1.8-5.9-5-5.9s-5.1,2.8-5.1,5.9c0,3.1,1.8,5.9,5.1,5.9S319.2,521.3,319.2,518.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M326,522.6v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6l0.7,1.8 c-0.6,0.6-1.4,1-2.8,1C327.1,526.2,326,524.9,326,522.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M341.4,510.2c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C333.9,513.8,337.1,510.2,341.4,510.2z M336.4,517.2h10c0-2.3-1.5-5.1-5-5.1 C338.1,512.2,336.5,514.9,336.4,517.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M359.2,510.2c2.8,0,4.4,1.1,5.6,2.6l-1.6,1.4c-1-1.4-2.3-2-3.9-2c-3.2,0-5.3,2.5-5.3,5.9s2,5.9,5.3,5.9 c1.6,0,2.9-0.6,3.9-2l1.6,1.4c-1.2,1.5-2.8,2.6-5.6,2.6c-4.5,0-7.6-3.5-7.6-8C351.7,513.7,354.7,510.2,359.2,510.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M368.6,522.6v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1H371v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C369.7,526.2,368.6,524.9,368.6,522.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M377,506.4c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6S377,507.3,377,506.4z M377.4,510.6h2.4 v15.2h-2.4V510.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M383.6,518.2c0-4.4,2.9-8,7.5-8s7.5,3.6,7.5,8s-2.9,8-7.5,8S383.6,522.6,383.6,518.2z M396.2,518.2 c0-3.1-1.8-5.9-5-5.9s-5.1,2.8-5.1,5.9c0,3.1,1.8,5.9,5.1,5.9S396.2,521.3,396.2,518.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M412.8,515.8c0-2.7-1.4-3.5-3.4-3.5c-1.9,0-3.6,1.1-4.5,2.4v11.1h-2.4v-15.2h2.4v2.2c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.9,1.6,4.9,4.9v10.7h-2.4V515.8z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("text", {
                  staticClass: "st3 st6 st7",
                  attrs: { transform: "matrix(1 0 0 1 417.4884 525.8)" }
                }),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M161.8,584.8c1.4,1.5,3.7,3,6.6,3c3.7,0,4.9-2,4.9-3.6c0-2.5-2.6-3.2-5.3-4c-3.4-0.9-7.1-1.8-7.1-6 c0-3.5,3.1-5.8,7.2-5.8c3.2,0,5.7,1.1,7.5,2.9l-1.6,1.9c-1.6-1.7-3.8-2.5-6.1-2.5c-2.5,0-4.3,1.4-4.3,3.3c0,2.1,2.4,2.7,5.1,3.5 c3.4,0.9,7.3,2,7.3,6.4c0,3.1-2.1,6.2-7.7,6.2c-3.7,0-6.3-1.4-8.1-3.3L161.8,584.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M186.6,574.1c2.8,0,4.4,1.1,5.6,2.6l-1.6,1.4c-1-1.4-2.3-2-3.9-2c-3.2,0-5.3,2.5-5.3,5.9s2,5.9,5.3,5.9 c1.6,0,2.9-0.6,3.9-2l1.6,1.4c-1.2,1.5-2.8,2.6-5.6,2.6c-4.5,0-7.6-3.5-7.6-8C179,577.6,182.1,574.1,186.6,574.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M195.5,574.5h2.4v2.5c1.2-1.6,3-2.8,5.1-2.8v2.4c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0-3.4,1.2-4.1,2.4v10.8h-2.4 v-15.2H195.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M215.4,588c-1.3,1.4-3,2.1-5,2.1c-2.6,0-5.3-1.7-5.3-5c0-3.4,2.7-5,5.3-5c2.1,0,3.8,0.7,5,2.1v-2.7 c0-2-1.6-3.2-3.8-3.2c-1.8,0-3.3,0.7-4.7,2.1l-1.1-1.6c1.6-1.7,3.6-2.5,6.1-2.5c3.2,0,5.9,1.4,5.9,5.1v10.5h-2.4V588z M215.4,583.6 c-0.9-1.3-2.5-1.9-4.2-1.9c-2.2,0-3.7,1.4-3.7,3.3c0,1.9,1.5,3.3,3.7,3.3c1.7,0,3.3-0.6,4.2-1.9V583.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M222.9,586.5v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C224.1,590.1,222.9,588.8,222.9,586.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M238.4,574.1c2.8,0,4.4,1.1,5.6,2.6l-1.6,1.4c-1-1.4-2.3-2-3.9-2c-3.2,0-5.3,2.5-5.3,5.9s2,5.9,5.3,5.9 c1.6,0,2.9-0.6,3.9-2l1.6,1.4c-1.2,1.5-2.8,2.6-5.6,2.6c-4.5,0-7.6-3.5-7.6-8C230.9,577.6,233.9,574.1,238.4,574.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M257.6,579.7c0-2.7-1.4-3.5-3.4-3.5c-1.8,0-3.6,1.1-4.5,2.4v11.1h-2.4v-21h2.4v8c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.8,1.5,4.8,4.9v10.7h-2.4v-10H257.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M279.7,581.4h-4.2v8.3h-2.6v-21h8.4c3.8,0,6.6,2.5,6.6,6.3s-2.6,5.9-5.4,6.1l5.7,8.6h-3.1L279.7,581.4z M281.1,571h-5.5v8h5.5c2.5,0,4.2-1.7,4.2-4C285.3,572.7,283.6,571,281.1,571z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M297.7,574.1c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C290.2,577.7,293.3,574.1,297.7,574.1z M292.7,581.1h10c0-2.3-1.5-5.1-5-5.1 C294.4,576.1,292.8,578.8,292.7,581.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M308.7,586c1,1.2,2.9,2.2,5,2.2c2.3,0,3.6-1,3.6-2.5c0-1.7-1.9-2.2-3.9-2.7c-2.6-0.6-5.5-1.3-5.5-4.4 c0-2.4,2-4.4,5.7-4.4c2.6,0,4.4,1,5.6,2.2l-1.1,1.6c-0.9-1.1-2.6-1.9-4.5-1.9c-2.1,0-3.4,0.9-3.4,2.3c0,1.5,1.7,2,3.7,2.4 c2.6,0.6,5.7,1.4,5.7,4.7c0,2.6-2,4.6-6,4.6c-2.5,0-4.6-0.8-6.1-2.4L308.7,586z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M323,570.3c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6C323.7,571.9,323,571.2,323,570.3z M323.4,574.5h2.4v15.2h-2.4V574.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M330.4,586c1,1.2,2.9,2.2,5,2.2c2.3,0,3.6-1,3.6-2.5c0-1.7-1.9-2.2-3.9-2.7c-2.6-0.6-5.5-1.3-5.5-4.4 c0-2.4,2-4.4,5.7-4.4c2.6,0,4.4,1,5.6,2.2l-1.1,1.6c-0.9-1.1-2.6-1.9-4.5-1.9c-2.1,0-3.4,0.9-3.4,2.3c0,1.5,1.7,2,3.7,2.4 c2.6,0.6,5.7,1.4,5.7,4.7c0,2.6-2,4.6-6,4.6c-2.5,0-4.6-0.8-6.1-2.4L330.4,586z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M345.6,586.5v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1H348v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C346.8,590.1,345.6,588.8,345.6,586.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M363.9,588c-1.3,1.4-3,2.1-5,2.1c-2.6,0-5.3-1.7-5.3-5c0-3.4,2.7-5,5.3-5c2.1,0,3.8,0.7,5,2.1v-2.7 c0-2-1.6-3.2-3.8-3.2c-1.8,0-3.3,0.7-4.7,2.1l-1.1-1.6c1.6-1.7,3.6-2.5,6.1-2.5c3.2,0,5.9,1.4,5.9,5.1v10.5h-2.4V588z M363.9,583.6 c-0.9-1.3-2.5-1.9-4.2-1.9c-2.2,0-3.7,1.4-3.7,3.3c0,1.9,1.5,3.3,3.7,3.3c1.7,0,3.3-0.6,4.2-1.9V583.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M381.3,579.7c0-2.7-1.4-3.5-3.4-3.5c-1.9,0-3.6,1.1-4.5,2.4v11.1H371v-15.2h2.4v2.2c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.9,1.6,4.9,4.9v10.7h-2.4V579.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M388.9,586.5v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C390,590.1,388.9,588.8,388.9,586.5z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("text", {
                  staticClass: "st3 st6 st7",
                  attrs: { transform: "matrix(1 0 0 1 395.2827 589.7)" }
                }),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M184.3,632.6h2.6v12.8c0,3.8,2.1,6.3,6,6.3s6-2.4,6-6.3v-12.8h2.6v12.8c0,5.2-2.9,8.6-8.6,8.6 s-8.6-3.4-8.6-8.5V632.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M204.1,632.6h3l7.1,18.1l7.1-18.1h3l-8.5,21h-3.2L204.1,632.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M235.1,659.4v-21h2.4v2.3c1.1-1.5,3-2.6,5.1-2.6c4,0,6.8,3,6.8,8c0,4.9-2.8,8-6.8,8c-2.1,0-3.9-1-5.1-2.7v8.1 L235.1,659.4L235.1,659.4z M242,640.1c-1.9,0-3.7,1.1-4.5,2.4v6.9c0.8,1.3,2.7,2.5,4.5,2.5c3.1,0,4.9-2.5,4.9-5.9 C246.8,642.6,245,640.1,242,640.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M253.1,638.4h2.4v2.5c1.2-1.6,3-2.8,5.1-2.8v2.4c-0.3-0.1-0.6-0.1-0.9-0.1c-1.4,0-3.4,1.2-4.1,2.4v10.8h-2.4 v-15.2H253.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M262.7,646c0-4.4,2.9-8,7.5-8s7.5,3.6,7.5,8s-2.9,8-7.5,8S262.7,650.4,262.7,646z M275.2,646 c0-3.1-1.8-5.9-5-5.9s-5.1,2.8-5.1,5.9c0,3.1,1.8,5.9,5.1,5.9S275.2,649.1,275.2,646z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M282,650.4v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1h-3.1v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6l0.7,1.8 c-0.6,0.6-1.4,1-2.8,1C283.2,654,282,652.7,282,650.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M297.4,638c4.6,0,7.3,3.6,7.3,8.2v0.6h-12.3c0.2,2.9,2.2,5.3,5.5,5.3c1.7,0,3.5-0.7,4.7-1.9l1.1,1.5 c-1.5,1.5-3.6,2.3-6,2.3c-4.5,0-7.7-3.2-7.7-8C289.9,641.6,293.1,638,297.4,638z M292.4,645h10c0-2.3-1.5-5.1-5-5.1 C294.2,640,292.5,642.7,292.4,645z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M315.3,638c2.8,0,4.4,1.1,5.6,2.6l-1.6,1.4c-1-1.4-2.3-2-3.9-2c-3.2,0-5.3,2.5-5.3,5.9s2,5.9,5.3,5.9 c1.6,0,2.9-0.6,3.9-2l1.6,1.4c-1.2,1.5-2.8,2.6-5.6,2.6c-4.5,0-7.6-3.5-7.6-8C307.7,641.5,310.7,638,315.3,638z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M324.6,650.4v-10h-2.5v-2.1h2.5v-4.2h2.4v4.2h3.1v2.1H327v9.4c0,1.1,0.5,2,1.5,2c0.7,0,1.3-0.3,1.6-0.6 l0.7,1.8c-0.6,0.6-1.4,1-2.8,1C325.7,654,324.6,652.7,324.6,650.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M333,634.2c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6S333,635.1,333,634.2z M333.4,638.4h2.4 v15.2h-2.4V638.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M339.6,646c0-4.4,2.9-8,7.5-8s7.5,3.6,7.5,8s-2.9,8-7.5,8S339.6,650.4,339.6,646z M352.2,646 c0-3.1-1.8-5.9-5-5.9s-5.1,2.8-5.1,5.9c0,3.1,1.8,5.9,5.1,5.9S352.2,649.1,352.2,646z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M368.8,643.6c0-2.7-1.4-3.5-3.4-3.5c-1.9,0-3.6,1.1-4.5,2.4v11.1h-2.4v-15.2h2.4v2.2c1.1-1.3,3.1-2.6,5.4-2.6 c3.2,0,4.9,1.6,4.9,4.9v10.7h-2.4V643.6z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st3",
                    attrs: {
                      d:
                        "M284.2,301.3h-2.3c-0.5-0.4-1-1-1.6-1.6c-1.1,1.1-2.5,1.8-4.3,1.8c-2.6,0-4.7-1.4-4.7-4.2 c0-2.3,1.6-3.5,3.3-4.5c-0.7-1.1-1.1-2.3-1.1-3.4c0-2,1.6-3.5,3.8-3.5c1.9,0,3.4,1.1,3.4,2.9c0,2.3-1.9,3.2-3.8,4.2 c0.6,0.8,1.2,1.6,1.7,2.1c0.6,0.7,1.2,1.3,1.8,2c0.8-1.2,1.4-2.6,1.6-3.4l1.4,0.6c-0.5,1.2-1.1,2.6-2,3.9 C282.2,299.4,283.1,300.3,284.2,301.3z M279.2,298.7c-0.9-0.9-1.7-1.8-2.1-2.3c-0.6-0.7-1.3-1.5-1.8-2.4c-1.3,0.8-2.2,1.7-2.2,3.2 c0,1.9,1.4,2.9,3,2.9C277.3,300.2,278.4,299.5,279.2,298.7z M276,292.2c1.6-0.8,3-1.6,3-3.1c0-1.1-0.8-1.7-1.8-1.7 c-1.2,0-2.1,0.9-2.1,2.2C275.1,290.4,275.5,291.3,276,292.2z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st8",
                    attrs: { d: "M747.9,169.3h4.5v21h-4.5V169.3z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st8",
                    attrs: {
                      d:
                        "M761,176.2v14.1h-4.5v-21h4.6l9.7,13.6v-13.6h4.5v21H771L761,176.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st8",
                    attrs: {
                      d:
                        "M778.5,179.8c0-6.5,4.9-10.9,11-10.9c4.8,0,7.5,2.5,8.9,5.1l-3.8,1.9c-0.9-1.7-2.8-3.1-5.1-3.1 c-3.7,0-6.4,2.9-6.4,6.9s2.8,6.9,6.4,6.9c2.3,0,4.2-1.3,5.1-3.1l3.8,1.9c-1.5,2.6-4.2,5.2-8.9,5.2 C783.4,190.7,778.5,186.3,778.5,179.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st8",
                    attrs: { d: "M801.1,169.3h4.5v17.1h8.9v3.9h-13.4V169.3z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st8",
                    attrs: {
                      d:
                        "M816.9,169.3h4.5v12.5c0,2.9,1.6,4.9,4.9,4.9c3.2,0,4.8-2,4.8-4.9v-12.5h4.5v12.6c0,5.2-3,8.8-9.4,8.8 s-9.4-3.6-9.4-8.8v-12.6H816.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st8",
                    attrs: {
                      d:
                        "M839.8,169.3h8.3c6.6,0,11.1,4.2,11.1,10.5s-4.6,10.5-11.1,10.5h-8.3V169.3z M848.1,186.4c4.2,0,6.6-3,6.6-6.6 c0-3.7-2.3-6.6-6.6-6.6h-3.8v13.1h3.8V186.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st8",
                    attrs: {
                      d:
                        "M862.4,169.3h14.9v3.8h-10.4v4.6h10.2v3.8h-10.2v4.9h10.4v3.9h-14.9V169.3z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st8",
                    attrs: {
                      d:
                        "M880.8,169.3h8.3c6.6,0,11.1,4.2,11.1,10.5s-4.6,10.5-11.1,10.5h-8.3V169.3z M889.1,186.4c4.2,0,6.6-3,6.6-6.6 c0-3.7-2.3-6.6-6.6-6.6h-3.8v13.1h3.8V186.4z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1155.1,290.5v2.9h-3v-2.8c-3.3-0.3-5.7-1.5-7.4-3.3l2.4-3.4c1.2,1.2,2.9,2.3,5,2.7v-4.9 c-3.2-0.8-6.8-2-6.8-6.3c0-3.2,2.6-5.9,6.8-6.3V266h3v3c2.6,0.3,4.7,1.3,6.4,2.8l-2.5,3.3c-1.1-1-2.5-1.7-3.9-2v4.4 c3.3,0.9,6.9,2.1,6.9,6.4C1162,287.3,1159.7,290,1155.1,290.5z M1152.4,276.8v-4c-1.6,0.2-2.5,1-2.5,2.1 C1150,275.9,1151,276.4,1152.4,276.8z M1157.4,284.4c0-1.2-1.1-1.7-2.6-2.1v4.4C1156.6,286.4,1157.4,285.4,1157.4,284.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1169.5,275l-3.5,3.6l-2.6-2.7l6.6-6.6h3.9v21h-4.5L1169.5,275L1169.5,275z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1180,284.4c1.4,1.5,3.4,2.3,5.6,2.3c2.5,0,3.9-1.4,3.9-3.1c0-1.9-1.4-3.2-3.8-3.2c-1.7,0-3.1,0.5-4.4,1.7 l-3.1-0.9v-12h14.4v3.8h-10v5.3c1.1-1,2.8-1.8,4.7-1.8c3.6,0,6.7,2.6,6.7,6.8c0,4.4-3.3,7.2-8.4,7.2c-3.7,0-6.3-1.2-8.1-3.1 L1180,284.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1204.9,268.9c6,0,8.7,5.4,8.7,10.8s-2.7,10.9-8.7,10.9s-8.7-5.4-8.7-10.9S1198.9,268.9,1204.9,268.9z M1204.9,272.9c-3,0-4.2,3-4.2,6.9s1.1,6.9,4.2,6.9c3,0,4.1-3,4.1-6.9S1207.9,272.9,1204.9,272.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1233.9,288.6c-1,1.2-2.8,2-4.8,2c-2.4,0-5.3-1.6-5.3-5c0-3.6,2.9-4.9,5.3-4.9c2,0,3.8,0.6,4.8,1.9v-2.1 c0-1.5-1.3-2.6-3.3-2.6c-1.6,0-3.1,0.6-4.4,1.8l-1.5-2.7c1.9-1.6,4.3-2.4,6.6-2.4c3.5,0,6.6,1.4,6.6,5.8v9.8h-4L1233.9,288.6 L1233.9,288.6z M1233.9,284.7c-0.7-0.9-1.9-1.3-3.2-1.3c-1.6,0-2.9,0.8-2.9,2.3c0,1.4,1.3,2.2,2.9,2.2c1.3,0,2.6-0.4,3.2-1.3V284.7 z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: { d: "M1241.9,269.2h4v21h-4V269.2z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: { d: "M1249.8,269.2h4v21h-4V269.2z" }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1256.8,282.6c0-4.3,3-8,8-8s8.1,3.7,8.1,8s-3,8-8.1,8C1259.9,290.6,1256.8,286.9,1256.8,282.6z M1268.8,282.6 c0-2.4-1.4-4.4-3.9-4.4s-3.9,2-3.9,4.4s1.4,4.4,3.9,4.4S1268.8,285,1268.8,282.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1286,279.9l-3.2,10.4h-4.3l-4.6-15.2h4.2l2.8,10.2l3.3-10.2h3.6l3.3,10.2l2.8-10.2h4.2l-4.6,15.2h-4.3 L1286,279.9z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1309.2,288.6c-1,1.2-2.8,2-4.8,2c-2.4,0-5.3-1.6-5.3-5c0-3.6,2.9-4.9,5.3-4.9c2,0,3.8,0.6,4.8,1.9v-2.1 c0-1.5-1.3-2.6-3.3-2.6c-1.6,0-3.1,0.6-4.4,1.8l-1.5-2.7c1.9-1.6,4.3-2.4,6.6-2.4c3.5,0,6.6,1.4,6.6,5.8v9.8h-4L1309.2,288.6 L1309.2,288.6z M1309.2,284.7c-0.7-0.9-1.9-1.3-3.2-1.3c-1.6,0-2.9,0.8-2.9,2.3c0,1.4,1.3,2.2,2.9,2.2c1.3,0,2.6-0.4,3.2-1.3V284.7 z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1327.5,281c0-2.1-1.1-2.8-2.8-2.8c-1.6,0-2.8,0.9-3.5,1.8v10.2h-4V275h4v2c1-1.2,2.8-2.4,5.3-2.4 c3.3,0,4.9,1.9,4.9,4.9v10.7h-4L1327.5,281L1327.5,281z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1342.5,274.6c3.1,0,5,1.4,6,2.7l-2.6,2.5c-0.7-1.1-1.8-1.6-3.2-1.6c-2.4,0-4.1,1.8-4.1,4.4s1.7,4.4,4.1,4.4 c1.4,0,2.5-0.6,3.2-1.6l2.6,2.4c-1,1.4-2.9,2.8-6,2.8c-4.7,0-8.1-3.3-8.1-8C1334.5,277.9,1337.8,274.6,1342.5,274.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1358.1,274.6c4.5,0,7.6,3.4,7.6,8.4v0.9h-11.3c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1350.2,278.2,1353.4,274.6,1358.1,274.6z M1354.3,281.2h7.5 c-0.1-1.4-1.1-3.4-3.7-3.4C1355.6,277.8,1354.5,279.7,1354.3,281.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1377.5,278.5h-2.5V275h2.5v-0.8c0-3.3,2.1-5.3,5.1-5.3c1.1,0,2.2,0.3,2.9,0.6l-0.8,2.8 c-0.3-0.2-0.8-0.3-1.3-0.3c-1.1,0-1.9,0.8-1.9,2.2v0.8h3.1v3.5h-3.1v11.7h-4L1377.5,278.5L1377.5,278.5z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1386,282.6c0-4.3,3-8,8-8s8.1,3.7,8.1,8s-3,8-8.1,8C1389,290.6,1386,286.9,1386,282.6z M1397.9,282.6 c0-2.4-1.4-4.4-3.9-4.4s-3.9,2-3.9,4.4s1.4,4.4,3.9,4.4S1397.9,285,1397.9,282.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1405,275h4v2.1c1.1-1.4,3-2.4,4.9-2.4v3.9c-0.3-0.1-0.7-0.1-1.1-0.1c-1.3,0-3.1,0.8-3.7,1.7v10h-4V275H1405z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1423.5,282.6c0-4.3,3-8,8-8s8.1,3.7,8.1,8s-3,8-8.1,8C1426.5,290.6,1423.5,286.9,1423.5,282.6z M1435.4,282.6 c0-2.4-1.4-4.4-3.9-4.4s-3.9,2-3.9,4.4s1.4,4.4,3.9,4.4S1435.4,285,1435.4,282.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1452.8,281c0-2.1-1.1-2.8-2.8-2.8c-1.6,0-2.8,0.9-3.5,1.8v10.2h-4V275h4v2c1-1.2,2.8-2.4,5.3-2.4 c3.3,0,4.9,1.9,4.9,4.9v10.7h-4L1452.8,281L1452.8,281z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1467.7,274.6c4.5,0,7.6,3.4,7.6,8.4v0.9H1464c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1459.8,278.2,1463,274.6,1467.7,274.6z M1463.9,281.2h7.5 c-0.1-1.4-1.1-3.4-3.7-3.4C1465.2,277.8,1464.1,279.7,1463.9,281.2z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1227.2,316.8c0-4.3,3-8,8-8s8.1,3.7,8.1,8s-3,8-8.1,8C1230.2,324.8,1227.2,321.1,1227.2,316.8z M1239.1,316.8 c0-2.4-1.4-4.4-3.9-4.4s-3.9,2-3.9,4.4s1.4,4.4,3.9,4.4C1237.7,321.2,1239.1,319.2,1239.1,316.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1246.2,309.2h4v2.1c1.1-1.4,3-2.4,4.8-2.4v3.9c-0.3-0.1-0.7-0.1-1.1-0.1c-1.3,0-3.1,0.8-3.7,1.7v10h-4 L1246.2,309.2L1246.2,309.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1266.4,320.6v-7.9h-2.5v-3.5h2.5V305h4v4.2h3.1v3.5h-3.1v6.9c0,0.9,0.5,1.7,1.4,1.7c0.6,0,1.2-0.2,1.4-0.4 l0.9,3c-0.6,0.5-1.7,1-3.3,1C1267.9,324.8,1266.4,323.3,1266.4,320.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1286.6,315.2c0-2.1-1.1-2.8-2.8-2.8c-1.5,0-2.8,0.9-3.5,1.8v10.2h-4v-21h4v7.8c1-1.2,2.9-2.4,5.4-2.4 c3.3,0,4.9,1.8,4.9,4.8v10.8h-4V315.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1301.4,308.8c4.5,0,7.6,3.4,7.6,8.4v0.9h-11.3c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1293.6,312.4,1296.8,308.8,1301.4,308.8z M1297.7,315.4h7.5 c-0.1-1.4-1.1-3.4-3.7-3.4S1297.9,313.9,1297.7,315.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1319,316.8c0-4.3,3-8,8-8s8.1,3.7,8.1,8s-3,8-8.1,8C1322.1,324.8,1319,321.1,1319,316.8z M1331,316.8 c0-2.4-1.4-4.4-3.9-4.4s-3.9,2-3.9,4.4s1.4,4.4,3.9,4.4C1329.6,321.2,1331,319.2,1331,316.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1338.9,320.6v-7.9h-2.5v-3.5h2.5V305h4v4.2h3.1v3.5h-3.1v6.9c0,0.9,0.5,1.7,1.4,1.7c0.6,0,1.2-0.2,1.4-0.4 l0.9,3c-0.6,0.5-1.7,1-3.3,1C1340.4,324.8,1338.9,323.3,1338.9,320.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1359,315.2c0-2.1-1.1-2.8-2.8-2.8c-1.5,0-2.8,0.9-3.5,1.8v10.2h-4v-21h4v7.8c1-1.2,2.9-2.4,5.4-2.4 c3.3,0,4.9,1.8,4.9,4.8v10.8h-4V315.2z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1373.9,308.8c4.5,0,7.6,3.4,7.6,8.4v0.9h-11.3c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1366,312.4,1369.3,308.8,1373.9,308.8z M1370.2,315.4h7.5 c-0.1-1.4-1.1-3.4-3.7-3.4S1370.4,313.9,1370.2,315.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1384.4,309.2h4v2.1c1.1-1.4,3-2.4,4.8-2.4v3.9c-0.3-0.1-0.7-0.1-1.1-0.1c-1.3,0-3.1,0.8-3.7,1.7v10h-4 L1384.4,309.2L1384.4,309.2z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1254.4,377.7h4.5v12.5c0,2.9,1.6,4.9,4.9,4.9c3.2,0,4.8-2,4.8-4.9v-12.5h4.5v12.6c0,5.2-3,8.8-9.4,8.8 s-9.4-3.6-9.4-8.8L1254.4,377.7L1254.4,377.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1277.2,404.5v-21h4v1.9c1.2-1.5,2.8-2.3,4.7-2.3c3.9,0,6.7,2.9,6.7,8s-2.8,8-6.7,8c-1.8,0-3.5-0.8-4.7-2.3 v7.7L1277.2,404.5L1277.2,404.5z M1284.6,386.7c-1.3,0-2.8,0.8-3.4,1.7v5.4c0.7,0.9,2.1,1.7,3.4,1.7c2.3,0,3.8-1.8,3.8-4.4 C1288.5,388.5,1286.9,386.7,1284.6,386.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1302.7,383.1c3.1,0,5,1.4,6,2.7l-2.6,2.5c-0.7-1.1-1.8-1.6-3.2-1.6c-2.4,0-4.1,1.8-4.1,4.4s1.7,4.4,4.1,4.4 c1.4,0,2.5-0.6,3.2-1.6l2.6,2.4c-1,1.4-2.9,2.8-6,2.8c-4.7,0-8.1-3.3-8.1-8C1294.6,386.4,1298,383.1,1302.7,383.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1321.6,389.4c0-2.1-1.1-2.8-2.8-2.8c-1.5,0-2.8,0.9-3.5,1.8v10.2h-4v-21h4v7.8c1-1.2,2.9-2.4,5.4-2.4 c3.3,0,4.9,1.8,4.9,4.8v10.8h-4V389.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1338.7,397.1c-1,1.2-2.8,2-4.8,2c-2.4,0-5.3-1.6-5.3-5c0-3.6,2.9-4.9,5.3-4.9c2,0,3.8,0.6,4.8,1.9V389 c0-1.5-1.3-2.6-3.3-2.6c-1.6,0-3.1,0.6-4.4,1.8l-1.5-2.7c1.9-1.6,4.3-2.4,6.6-2.4c3.5,0,6.6,1.4,6.6,5.8v9.8h-4L1338.7,397.1 L1338.7,397.1z M1338.7,393.2c-0.7-0.9-1.9-1.3-3.2-1.3c-1.6,0-2.9,0.8-2.9,2.3c0,1.4,1.3,2.2,2.9,2.2c1.3,0,2.6-0.4,3.2-1.3V393.2 z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1346.7,383.5h4v2.1c1.1-1.4,3-2.4,4.9-2.4v3.9c-0.3-0.1-0.7-0.1-1.1-0.1c-1.3,0-3.1,0.8-3.7,1.7v10h-4v-15.2 H1346.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1359.5,399.7c1.2,1.4,2.8,1.9,4.8,1.9c1.9,0,4.2-0.9,4.2-3.8v-1.5c-1.2,1.5-2.8,2.4-4.7,2.4 c-3.8,0-6.7-2.7-6.7-7.7s2.9-7.7,6.7-7.7c1.8,0,3.5,0.8,4.7,2.3v-2h4v14.2c0,5.8-4.5,7.2-8.2,7.2c-2.6,0-4.6-0.6-6.6-2.3 L1359.5,399.7z M1368.5,388.4c-0.7-1-2.1-1.7-3.4-1.7c-2.3,0-3.8,1.6-3.8,4.2s1.6,4.2,3.8,4.2c1.3,0,2.8-0.8,3.4-1.7V388.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1383.4,383.1c4.5,0,7.6,3.4,7.6,8.4v0.9h-11.3c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1375.5,386.7,1378.8,383.1,1383.4,383.1z M1379.6,389.7h7.5 c-0.1-1.4-1.1-3.4-3.7-3.4C1380.9,386.3,1379.8,388.1,1379.6,389.7z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1254.4,440.7h4.5v12.5c0,2.9,1.6,4.9,4.9,4.9c3.2,0,4.8-2,4.8-4.9v-12.5h4.5v12.6c0,5.2-3,8.8-9.4,8.8 s-9.4-3.6-9.4-8.8L1254.4,440.7L1254.4,440.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1277.2,467.5v-21h4v1.9c1.2-1.5,2.8-2.3,4.7-2.3c3.9,0,6.7,2.9,6.7,8s-2.8,8-6.7,8c-1.8,0-3.5-0.8-4.7-2.3 v7.7L1277.2,467.5L1277.2,467.5z M1284.6,449.7c-1.3,0-2.8,0.8-3.4,1.7v5.4c0.7,0.9,2.1,1.7,3.4,1.7c2.3,0,3.8-1.8,3.8-4.4 C1288.5,451.5,1286.9,449.7,1284.6,449.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1302.7,446.1c3.1,0,5,1.4,6,2.7l-2.6,2.5c-0.7-1.1-1.8-1.6-3.2-1.6c-2.4,0-4.1,1.8-4.1,4.4s1.7,4.4,4.1,4.4 c1.4,0,2.5-0.6,3.2-1.6l2.6,2.4c-1,1.4-2.9,2.8-6,2.8c-4.7,0-8.1-3.3-8.1-8C1294.6,449.4,1298,446.1,1302.7,446.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1321.6,452.4c0-2.1-1.1-2.8-2.8-2.8c-1.5,0-2.8,0.9-3.5,1.8v10.2h-4v-21h4v7.8c1-1.2,2.9-2.4,5.4-2.4 c3.3,0,4.9,1.8,4.9,4.8v10.8h-4V452.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1338.7,460.1c-1,1.2-2.8,2-4.8,2c-2.4,0-5.3-1.6-5.3-5c0-3.6,2.9-4.9,5.3-4.9c2,0,3.8,0.6,4.8,1.9V452 c0-1.5-1.3-2.6-3.3-2.6c-1.6,0-3.1,0.6-4.4,1.8l-1.5-2.7c1.9-1.6,4.3-2.4,6.6-2.4c3.5,0,6.6,1.4,6.6,5.8v9.8h-4L1338.7,460.1 L1338.7,460.1z M1338.7,456.2c-0.7-0.9-1.9-1.3-3.2-1.3c-1.6,0-2.9,0.8-2.9,2.3c0,1.4,1.3,2.2,2.9,2.2c1.3,0,2.6-0.4,3.2-1.3V456.2 z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1346.7,446.5h4v2.1c1.1-1.4,3-2.4,4.9-2.4v3.9c-0.3-0.1-0.7-0.1-1.1-0.1c-1.3,0-3.1,0.8-3.7,1.7v10h-4v-15.2 H1346.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1359.5,462.7c1.2,1.4,2.8,1.9,4.8,1.9c1.9,0,4.2-0.9,4.2-3.8v-1.5c-1.2,1.5-2.8,2.4-4.7,2.4 c-3.8,0-6.7-2.7-6.7-7.7s2.9-7.7,6.7-7.7c1.8,0,3.5,0.8,4.7,2.3v-2h4v14.2c0,5.8-4.5,7.2-8.2,7.2c-2.6,0-4.6-0.6-6.6-2.3 L1359.5,462.7z M1368.5,451.4c-0.7-1-2.1-1.7-3.4-1.7c-2.3,0-3.8,1.6-3.8,4.2s1.6,4.2,3.8,4.2c1.3,0,2.8-0.8,3.4-1.7V451.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1383.4,446.1c4.5,0,7.6,3.4,7.6,8.4v0.9h-11.3c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1375.5,449.7,1378.8,446.1,1383.4,446.1z M1379.6,452.7h7.5 c-0.1-1.4-1.1-3.4-3.7-3.4C1380.9,449.3,1379.8,451.1,1379.6,452.7z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1254.4,504.6h4.5v12.5c0,2.9,1.6,4.9,4.9,4.9c3.2,0,4.8-2,4.8-4.9v-12.5h4.5v12.6c0,5.2-3,8.8-9.4,8.8 s-9.4-3.6-9.4-8.8L1254.4,504.6L1254.4,504.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1277.2,531.4v-21h4v1.9c1.2-1.5,2.8-2.3,4.7-2.3c3.9,0,6.7,2.9,6.7,8s-2.8,8-6.7,8c-1.8,0-3.5-0.8-4.7-2.3 v7.7L1277.2,531.4L1277.2,531.4z M1284.6,513.6c-1.3,0-2.8,0.8-3.4,1.7v5.4c0.7,0.9,2.1,1.7,3.4,1.7c2.3,0,3.8-1.8,3.8-4.4 C1288.5,515.4,1286.9,513.6,1284.6,513.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1302.7,510c3.1,0,5,1.4,6,2.7l-2.6,2.5c-0.7-1.1-1.8-1.6-3.2-1.6c-2.4,0-4.1,1.8-4.1,4.4 c0,2.6,1.7,4.4,4.1,4.4c1.4,0,2.5-0.6,3.2-1.6l2.6,2.4c-1,1.4-2.9,2.8-6,2.8c-4.7,0-8.1-3.3-8.1-8 C1294.6,513.3,1298,510,1302.7,510z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1321.6,516.3c0-2.1-1.1-2.8-2.8-2.8c-1.5,0-2.8,0.9-3.5,1.8v10.2h-4v-21h4v7.8c1-1.2,2.9-2.4,5.4-2.4 c3.3,0,4.9,1.8,4.9,4.8v10.8h-4V516.3z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1338.7,524c-1,1.2-2.8,2-4.8,2c-2.4,0-5.3-1.6-5.3-5c0-3.6,2.9-4.9,5.3-4.9c2,0,3.8,0.6,4.8,1.9v-2.1 c0-1.5-1.3-2.6-3.3-2.6c-1.6,0-3.1,0.6-4.4,1.8l-1.5-2.7c1.9-1.6,4.3-2.4,6.6-2.4c3.5,0,6.6,1.4,6.6,5.8v9.8h-4L1338.7,524 L1338.7,524z M1338.7,520.1c-0.7-0.9-1.9-1.3-3.2-1.3c-1.6,0-2.9,0.8-2.9,2.3c0,1.4,1.3,2.2,2.9,2.2c1.3,0,2.6-0.4,3.2-1.3V520.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1346.7,510.4h4v2.1c1.1-1.4,3-2.4,4.9-2.4v3.9c-0.3-0.1-0.7-0.1-1.1-0.1c-1.3,0-3.1,0.8-3.7,1.7v10h-4v-15.2 H1346.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1359.5,526.6c1.2,1.4,2.8,1.9,4.8,1.9c1.9,0,4.2-0.9,4.2-3.8v-1.5c-1.2,1.5-2.8,2.4-4.7,2.4 c-3.8,0-6.7-2.7-6.7-7.7s2.9-7.7,6.7-7.7c1.8,0,3.5,0.8,4.7,2.3v-2h4v14.2c0,5.8-4.5,7.2-8.2,7.2c-2.6,0-4.6-0.6-6.6-2.3 L1359.5,526.6z M1368.5,515.3c-0.7-1-2.1-1.7-3.4-1.7c-2.3,0-3.8,1.6-3.8,4.2c0,2.6,1.6,4.2,3.8,4.2c1.3,0,2.8-0.8,3.4-1.7V515.3z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1383.4,510c4.5,0,7.6,3.4,7.6,8.4v0.9h-11.3c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1375.5,513.6,1378.8,510,1383.4,510z M1379.6,516.6h7.5c-0.1-1.4-1.1-3.4-3.7-3.4 C1380.9,513.2,1379.8,515,1379.6,516.6z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1254.4,567.6h4.5v12.5c0,2.9,1.6,4.9,4.9,4.9c3.2,0,4.8-2,4.8-4.9v-12.5h4.5v12.6c0,5.2-3,8.8-9.4,8.8 s-9.4-3.6-9.4-8.8L1254.4,567.6L1254.4,567.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1277.2,594.4v-21h4v1.9c1.2-1.5,2.8-2.3,4.7-2.3c3.9,0,6.7,2.9,6.7,8s-2.8,8-6.7,8c-1.8,0-3.5-0.8-4.7-2.3 v7.7L1277.2,594.4L1277.2,594.4z M1284.6,576.6c-1.3,0-2.8,0.8-3.4,1.7v5.4c0.7,0.9,2.1,1.7,3.4,1.7c2.3,0,3.8-1.8,3.8-4.4 C1288.5,578.4,1286.9,576.6,1284.6,576.6z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1302.7,573c3.1,0,5,1.4,6,2.7l-2.6,2.5c-0.7-1.1-1.8-1.6-3.2-1.6c-2.4,0-4.1,1.8-4.1,4.4 c0,2.6,1.7,4.4,4.1,4.4c1.4,0,2.5-0.6,3.2-1.6l2.6,2.4c-1,1.4-2.9,2.8-6,2.8c-4.7,0-8.1-3.3-8.1-8 C1294.6,576.3,1298,573,1302.7,573z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1321.6,579.3c0-2.1-1.1-2.8-2.8-2.8c-1.5,0-2.8,0.9-3.5,1.8v10.2h-4v-21h4v7.8c1-1.2,2.9-2.4,5.4-2.4 c3.3,0,4.9,1.8,4.9,4.8v10.8h-4V579.3z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1338.7,587c-1,1.2-2.8,2-4.8,2c-2.4,0-5.3-1.6-5.3-5c0-3.6,2.9-4.9,5.3-4.9c2,0,3.8,0.6,4.8,1.9v-2.1 c0-1.5-1.3-2.6-3.3-2.6c-1.6,0-3.1,0.6-4.4,1.8l-1.5-2.7c1.9-1.6,4.3-2.4,6.6-2.4c3.5,0,6.6,1.4,6.6,5.8v9.8h-4L1338.7,587 L1338.7,587z M1338.7,583.1c-0.7-0.9-1.9-1.3-3.2-1.3c-1.6,0-2.9,0.8-2.9,2.3c0,1.4,1.3,2.2,2.9,2.2c1.3,0,2.6-0.4,3.2-1.3V583.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1346.7,573.4h4v2.1c1.1-1.4,3-2.4,4.9-2.4v3.9c-0.3-0.1-0.7-0.1-1.1-0.1c-1.3,0-3.1,0.8-3.7,1.7v10h-4v-15.2 H1346.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1359.5,589.6c1.2,1.4,2.8,1.9,4.8,1.9c1.9,0,4.2-0.9,4.2-3.8v-1.5c-1.2,1.5-2.8,2.4-4.7,2.4 c-3.8,0-6.7-2.7-6.7-7.7s2.9-7.7,6.7-7.7c1.8,0,3.5,0.8,4.7,2.3v-2h4v14.2c0,5.8-4.5,7.2-8.2,7.2c-2.6,0-4.6-0.6-6.6-2.3 L1359.5,589.6z M1368.5,578.3c-0.7-1-2.1-1.7-3.4-1.7c-2.3,0-3.8,1.6-3.8,4.2c0,2.6,1.6,4.2,3.8,4.2c1.3,0,2.8-0.8,3.4-1.7V578.3z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1383.4,573c4.5,0,7.6,3.4,7.6,8.4v0.9h-11.3c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1375.5,576.6,1378.8,573,1383.4,573z M1379.6,579.6h7.5c-0.1-1.4-1.1-3.4-3.7-3.4 C1380.9,576.2,1379.8,578,1379.6,579.6z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "st2" }, [
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1254.4,632.4h4.5v12.5c0,2.9,1.6,4.9,4.9,4.9c3.2,0,4.8-2,4.8-4.9v-12.5h4.5V645c0,5.2-3,8.8-9.4,8.8 s-9.4-3.6-9.4-8.8L1254.4,632.4L1254.4,632.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1277.2,659.2v-21h4v1.9c1.2-1.5,2.8-2.3,4.7-2.3c3.9,0,6.7,2.9,6.7,8s-2.8,8-6.7,8c-1.8,0-3.5-0.8-4.7-2.3 v7.7L1277.2,659.2L1277.2,659.2z M1284.6,641.4c-1.3,0-2.8,0.8-3.4,1.7v5.4c0.7,0.9,2.1,1.7,3.4,1.7c2.3,0,3.8-1.8,3.8-4.4 C1288.5,643.2,1286.9,641.4,1284.6,641.4z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1302.7,637.8c3.1,0,5,1.4,6,2.7l-2.6,2.5c-0.7-1.1-1.8-1.6-3.2-1.6c-2.4,0-4.1,1.8-4.1,4.4 c0,2.6,1.7,4.4,4.1,4.4c1.4,0,2.5-0.6,3.2-1.6l2.6,2.4c-1,1.4-2.9,2.8-6,2.8c-4.7,0-8.1-3.3-8.1-8 C1294.6,641.1,1298,637.8,1302.7,637.8z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1321.6,644.1c0-2.1-1.1-2.8-2.8-2.8c-1.5,0-2.8,0.9-3.5,1.8v10.2h-4v-21h4v7.8c1-1.2,2.9-2.4,5.4-2.4 c3.3,0,4.9,1.8,4.9,4.8v10.8h-4V644.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1338.7,651.8c-1,1.2-2.8,2-4.8,2c-2.4,0-5.3-1.6-5.3-5c0-3.6,2.9-4.9,5.3-4.9c2,0,3.8,0.6,4.8,1.9v-2.1 c0-1.5-1.3-2.6-3.3-2.6c-1.6,0-3.1,0.6-4.4,1.8l-1.5-2.7c1.9-1.6,4.3-2.4,6.6-2.4c3.5,0,6.6,1.4,6.6,5.8v9.8h-4L1338.7,651.8 L1338.7,651.8z M1338.7,647.9c-0.7-0.9-1.9-1.3-3.2-1.3c-1.6,0-2.9,0.8-2.9,2.3c0,1.4,1.3,2.2,2.9,2.2c1.3,0,2.6-0.4,3.2-1.3V647.9 z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1346.7,638.2h4v2.1c1.1-1.4,3-2.4,4.9-2.4v3.9c-0.3-0.1-0.7-0.1-1.1-0.1c-1.3,0-3.1,0.8-3.7,1.7v10h-4v-15.2 H1346.7z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1359.5,654.4c1.2,1.4,2.8,1.9,4.8,1.9c1.9,0,4.2-0.9,4.2-3.8V651c-1.2,1.5-2.8,2.4-4.7,2.4 c-3.8,0-6.7-2.7-6.7-7.7s2.9-7.7,6.7-7.7c1.8,0,3.5,0.8,4.7,2.3v-2h4v14.2c0,5.8-4.5,7.2-8.2,7.2c-2.6,0-4.6-0.6-6.6-2.3 L1359.5,654.4z M1368.5,643.1c-0.7-1-2.1-1.7-3.4-1.7c-2.3,0-3.8,1.6-3.8,4.2c0,2.6,1.6,4.2,3.8,4.2c1.3,0,2.8-0.8,3.4-1.7V643.1z"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "st9",
                    attrs: {
                      d:
                        "M1383.4,637.8c4.5,0,7.6,3.4,7.6,8.4v0.9h-11.3c0.3,1.9,1.8,3.5,4.4,3.5c1.4,0,3.1-0.6,4.1-1.5l1.8,2.6 c-1.5,1.4-3.9,2.1-6.3,2.1c-4.6,0-8.1-3.1-8.1-8C1375.5,641.4,1378.8,637.8,1383.4,637.8z M1379.6,644.4h7.5 c-0.1-1.4-1.1-3.4-3.7-3.4C1380.9,641,1379.8,642.8,1379.6,644.4z"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("line", {
                  staticClass: "st10",
                  attrs: {
                    id: "Line-4-Copy",
                    x1: "108.6",
                    y1: "355.7",
                    x2: "1514.1",
                    y2: "355.7"
                  }
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "st10",
                  attrs: {
                    id: "Line-4-Copy-4",
                    x1: "108.6",
                    y1: "546.5",
                    x2: "1514.1",
                    y2: "546.5"
                  }
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "st10",
                  attrs: {
                    id: "Line-4-Copy-5",
                    x1: "108.6",
                    y1: "610.4",
                    x2: "1514.1",
                    y2: "610.4"
                  }
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "st10",
                  attrs: {
                    id: "Line-4-Copy-2",
                    x1: "108.6",
                    y1: "418.7",
                    x2: "1514.1",
                    y2: "418.7"
                  }
                }),
                _vm._v(" "),
                _c("line", {
                  staticClass: "st10",
                  attrs: {
                    id: "Line-4-Copy-3",
                    x1: "108.6",
                    y1: "482.6",
                    x2: "1514.1",
                    y2: "482.6"
                  }
                }),
                _vm._v(" "),
                _c("g", { staticClass: "one" }, [
                  _c("circle", {
                    staticClass: "st11",
                    attrs: { cx: "823.5", cy: "254.6", r: "22.7" }
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "st12",
                    attrs: { points: "814.1,255.6 822,261.4 832.9,245.9" }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "two" }, [
                  _c("circle", {
                    staticClass: "st11",
                    attrs: { cx: "823.5", cy: "320.6", r: "22.7" }
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "st12",
                    attrs: { points: "814.1,321.6 822,327.4 832.9,311.9 " }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "three" }, [
                  _c("circle", {
                    staticClass: "st11",
                    attrs: { cx: "823.5", cy: "387.4", r: "22.7" }
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "st12",
                    attrs: { points: "814.1,388.4 822,394.2 832.9,378.7 " }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "four" }, [
                  _c("circle", {
                    staticClass: "st11",
                    attrs: { cx: "823.5", cy: "451.2", r: "22.7" }
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "st12",
                    attrs: { points: "814.1,452.2 822,458 832.9,442.5 " }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "five" }, [
                  _c("circle", {
                    staticClass: "st11",
                    attrs: { cx: "823.5", cy: "515.3", r: "22.7" }
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "st12",
                    attrs: { points: "814.1,516.3 822,522.1 832.9,506.6 " }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "six" }, [
                  _c("circle", {
                    staticClass: "st11",
                    attrs: { cx: "823.5", cy: "578.6", r: "22.7" }
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "st12",
                    attrs: { points: "814.1,579.6 822,585.4 832.9,569.9 " }
                  })
                ]),
                _vm._v(" "),
                _c("g", { staticClass: "seven" }, [
                  _c("circle", {
                    staticClass: "st11",
                    attrs: { cx: "823.5", cy: "644.1", r: "22.7" }
                  }),
                  _vm._v(" "),
                  _c("polyline", {
                    staticClass: "st12",
                    attrs: { points: "814.1,645.1 822,650.9 832.9,635.4 " }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("img", {
              staticClass: "mobile",
              attrs: {
                src:
                  "/js/lensconfigurator/images/plus-homepage/lensabl-table-mobile.svg",
                alt: "Lensabl Price Table"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "small-18 medium-18 large-7 columns plus-description"
          },
          [
            _c("h3", [_vm._v("All-inclusive plans. Less out-of-pocket.")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We believe that your eyes deserve the best, so we include all the coatings and features that other plans upcharge for."
              )
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.isMarketingPage ? "/why-plus/a" : "/why-plus"
                }
              },
              [_vm._m(9)]
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(10)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-video-button__copy" }, [
      _c("h1", [
        _vm._v("Benefits as Clear "),
        _c("br"),
        _vm._v("as Your Vision")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Annual vision benefit plans, personalized for you, with savings of up to 70%."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-video-button__buttons" }, [
      _c("div", { staticClass: "button button--copy" }, [
        _c("div", { staticClass: "button__container" }, [_vm._v("LEARN MORE")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/plus/vision_plan" } }, [
      _c("div", { staticClass: "hero-video-button__buttons" }, [
        _c("div", { staticClass: "button button--copy-solid" }, [
          _vm._v("GET STARTED")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "business-link" }, [
      _vm._v(
        "\n                            Are you an employer or broker?\n                            "
      ),
      _c("a", { attrs: { href: "/plus/group-plans" } }, [
        _vm._v(
          "\n                                Check out our group plans\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Already a Lensabl+ member?")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/customer/account/login/" } }, [
      _c("strong", [_c("span", [_vm._v("Sign In")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "struct-parallax-bg" }, [
      _c("div", { staticClass: "row shop-now-section" }, [
        _c(
          "div",
          {
            staticClass:
              "small-18 medium-6 large-6 columns shop-now-container plan-item"
          },
          [
            _c("div", {
              staticClass: "plus-plan-item",
              attrs: { id: "plus-plan-item1" }
            }),
            _vm._v(" "),
            _c("div", [
              _c("h3", [_vm._v("Everything you need, nothing you don’t")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Personalized plans for glasses and contacts wearers.")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "small-18 medium-6 large-6 columns shop-now-container benefits-item"
          },
          [
            _c("div", {
              staticClass: "plus-plan-item",
              attrs: { id: "plus-plan-item2" }
            }),
            _vm._v(" "),
            _c("div", [
              _c("h3", [_vm._v("Transparent benefits and pricing")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Easy to understand benefits so you know what you are getting. Pricing is all inclusive, no surprise co-pays or extra out-of-pocket costs."
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "small-18 medium-6 large-6 columns shop-now-container doctor-item"
          },
          [
            _c("div", {
              staticClass: "plus-plan-item",
              attrs: { id: "plus-plan-item3" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "home__our-story-section" }, [
              _c("h3", [_vm._v("Keep your favorite eye doctor")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "You won’t hear “out of network” with Lensabl+. See any eye doctor you want, you’re still covered."
                )
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticClass:
          "custom-callout custom-callout__background plus-plan-section",
        staticStyle: {
          "background-image":
            'url("https://www.lensabl.com/media/wysiwyg/lensabl-background-snellentest.jpg")',
          "background-position": "50% 56.6998%"
        }
      },
      [
        _c("div", [
          _c("div", { staticClass: "plan-cards-section" }, [
            _c("div", { staticClass: "plan-card glasses" }, [
              _c("div", { staticClass: "plus-plan-top" }, [
                _c("img", {
                  attrs: {
                    src:
                      "/js/lensconfigurator/images/plus-homepage/plan-card-glasses.svg"
                  }
                }),
                _c("h3", [_vm._v("Glasses Plans")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "plus-plan-bottom" }, [
                _c("div", { staticClass: "price-container" }, [
                  _c("span", { staticClass: "total-value-starting" }, [
                    _vm._v("starting at")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "price-label-container" }, [
                    _c("div", { staticClass: "plan-single-price" }, [
                      _c(
                        "span",
                        { staticClass: "total-value-amount-dollars" },
                        [_vm._v("$7")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "total-value-amount-cents" }, [
                        _vm._v(".99")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "total-value-amount-label" }, [
                      _vm._v("/per month")
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "plan-card glasses-contacts" }, [
              _c("div", { staticClass: "plus-plan-top" }, [
                _c("img", {
                  attrs: {
                    src:
                      "/js/lensconfigurator/images/plus-homepage/glasses-and-contacts.svg"
                  }
                }),
                _c("h3", [_vm._v("Glasses & Contacts Plans")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "plus-plan-bottom" }, [
                _c("div", { staticClass: "price-container" }, [
                  _c("span", { staticClass: "total-value-starting" }, [
                    _vm._v("starting at")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "price-label-container" }, [
                    _c("div", { staticClass: "plan-progressive-price" }, [
                      _c(
                        "span",
                        { staticClass: "total-value-amount-dollars" },
                        [_vm._v("$21")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "total-value-amount-cents" }, [
                        _vm._v(".99")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "total-value-amount-label" }, [
                      _vm._v("/per month")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "hero-video-button__buttons" }, [
          _c("strong", [
            _c("span", [_vm._v("All plans include vision exam benefit")])
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: "/plus/vision_plan" } }, [
            _c("div", { staticClass: "hero-video-button__button-primary" }, [
              _c("div", { staticClass: "button button--copy-solid-dark" }, [
                _c("div", { staticClass: "button__container" }, [
                  _vm._v("GET STARTED")
                ])
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "branded-frames-ad-section" }, [
      _c("div", { staticClass: "row" }, [
        _c("h2", [
          _vm._v("Get an extra 25% off "),
          _c("span", { staticClass: "br-1" }, [_vm._v("frames from")]),
          _vm._v(" "),
          _c("span", { staticClass: "br-2" }, [_vm._v("brands like")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "branded-frames-ad-img-section" }, [
          _c("ul", [
            _c("li", [
              _c("img", {
                attrs: {
                  src: "/js/lensconfigurator/images/plus-homepage/arnette.webp"
                }
              })
            ]),
            _vm._v(" "),
            _c("li", [
              _c("img", {
                attrs: {
                  src: "/js/lensconfigurator/images/plus-homepage/ray-ban.webp"
                }
              })
            ]),
            _vm._v(" "),
            _c("li", [
              _c("img", {
                attrs: {
                  src:
                    "/js/lensconfigurator/images/plus-homepage/marc-jacobs.webp"
                }
              })
            ]),
            _vm._v(" "),
            _c("li", [
              _c("img", {
                attrs: {
                  src:
                    "/js/lensconfigurator/images/plus-homepage/kate-spade.webp"
                }
              })
            ]),
            _vm._v(" "),
            _c("li", [
              _c("img", {
                attrs: {
                  src: "/js/lensconfigurator/images/plus-homepage/oakley.webp"
                }
              })
            ]),
            _vm._v(" "),
            _c("li", [
              _c("img", {
                attrs: {
                  src: "/js/lensconfigurator/images/plus-homepage/and-more.webp"
                }
              })
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hero-video-button__button-primary" }, [
      _c("div", { staticClass: "button button--copy-solid-dark" }, [
        _c("div", { staticClass: "button__container" }, [_vm._v("LEARN MORE")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "struct-parallax-bg" }, [
      _c("div", { staticClass: "row vision-benefits-section" }, [
        _c(
          "div",
          {
            staticClass: "small-18 medium-18 large-8 columns custom-vision-plan"
          },
          [
            _c("h3", [_vm._v("Build a custom vision plan in under 2 minutes")]),
            _vm._v(" "),
            _c("a", { attrs: { href: "/plus/vision_plan" } }, [
              _c("div", { staticClass: "hero-video-button__button-primary" }, [
                _c("div", { staticClass: "button button--copy-solid-dark" }, [
                  _c("div", { staticClass: "button__container" }, [
                    _vm._v("GET STARTED")
                  ])
                ])
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "small-18 medium-18 large-10 columns" }, [
          _c("img", {
            attrs: {
              src: "/js/lensconfigurator/images/plus-homepage/all-items.png"
            }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }