<template>
    <ProductList v-if="statusApi" />
</template>

<script>
import ProductList from '../../../components/catalog/category/ProductList.vue'
import App from '../../../App.vue'

export default {
    name: 'RouterProductList',
    components: {
        ProductList
    },
    extends: App
}
</script>

<style scoped>

</style>
