<template>
    <div>
        <section class="c-product-slider struct-parallax-bg">
            <h2 class="c-product-slider-header">All major contact lens brands are available</h2>
            <p class="c-product-slider-subheader">Including spherical contacts, toric lenses for astigmatism and multifocal lenses for presbyopia.</p>
            <div class="c-product-slider-container">
                <carousel
                    :per-page="3"
                    :per-page-custom="[[1, 1], [768, 3]]"
                    :mouse-drag="false"
                    :touch-drag="true"
                    :navigation-enabled="true"
                    navigation-prev-label="‹"
                    navigation-next-label="›"
                    class="c-homepage-slider"
                >
                    <slide v-for="product in featuredProduct" :key="product.index" class="c-homepage-slider-slide">
                        <a :href="product.url" :alt="product.name">
                            <img :src="product.image" :alt="product.name" />
                            <p v-dompurify-html="product.name" class="c-product-slider-name"></p>
                            <p class="c-product-slider-description">{{ product.description }}</p>
                        </a>
                    </slide>
                </carousel>
            </div>
            <a href="/shop-contacts/brands" alt="Find Your Brand">
                <div class="c-product-slider-button button button--copy-solid">
                    <div class="button__container">View All</div>
                </div>
            </a>
        </section>
        <section v-if="!plusMember" class="c-lplus-ad-block">
            <div class="lplus-ad-block_container">
                <a>
                    <img
                        v-if="isMobile"
                        src="/media/wysiwyg/lensabl-plus-ad-mobile.jpg"
                        alt="Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                        class="lplus-ad-block_img"
                    />
                    <img
                        v-else
                        src="/media/wysiwyg/lensabl-plus-ad.webp"
                        alt="Customize Your Plan button for Lensabl+ Vision Plan with frames and text"
                        class="lplus-ad-block_img"
                    />
                    <span class="help-bubble-container lplus-help-bubble-container">
                        <div class="help-component">
                            <div id="help_bubble_text" class="help-bubble lplus-help-bubble">
                                <span>
                                    <p>A new, better alternative to traditional vision insurance plans, starting as low as <b>$7.99/month.</b></p>
                                    <p>Lensabl+ plans include an annual supply of contacts, a FREE frame with Rx lenses or lens replacement, and a reward for getting an eye exam. Save hundreds of dollars out-of-pocket per year on your vision care needs. <span class="c-help-bubble-link">Customize your plan now</span></p>
                                </span>
                                <span class="close-button">
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                                        <path id="svg_1" d="m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z" fill="rgb(102, 102, 102)" fill-rule="evenodd" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </span>
                </a>
            </div>
        </section>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
import featuredProducts from './storage/featured-products'

export default {
    name: 'ContactsHomepageSlider',
    components: {
        Carousel,
        Slide
    },
    data: () => {
        return {
            slickOptions: {
                slidesToShow: 4,
                arrows: true,
                dots: true,
                draggable: true,
                edgeFriction: 0.30,
                swipe: true
            }
        }
    },
    computed: {
        ...mapState('featured_products', ['featuredProduct']),
        ...mapGetters('lensablplus_customer', ['plusMember']),
        ...mapGetters('deviceProperty', ['isMobile'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['featured_products/']) {
            this.$store.registerModule(['featured_products'], featuredProducts(this.$store))
        }
    },
    created () {
        this.$store.dispatch('featured_products/fetchFeaturedProducts')
    }
}
</script>

<style scoped>
@media screen and (max-width: 639px) {
    .c-lplus-ad-block {
        padding: 2em 1em;
    }
}
</style>
