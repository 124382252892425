var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getAffirmPrice
    ? _c("div", { staticClass: "affirm-shop-frames" }, [
        _c(
          "div",
          {
            staticClass: "affirm-as-low-as",
            attrs: {
              "data-amount": _vm.getAffirmPrice,
              "data-page-type": "product"
            }
          },
          [
            _c("span", [_vm._v("Starting at")]),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "dompurify-html",
                  rawName: "v-dompurify-html",
                  value: _vm.getAffirmPrice,
                  expression: "getAffirmPrice"
                }
              ],
              staticClass: "affirm-ala-price"
            }),
            _vm._v("/mo with "),
            _c("span", { staticClass: "__affirm-logo" }, [_vm._v("Affirm")]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.affirmModalShow()
                  }
                }
              },
              [_vm._v("Learn more")]
            )
          ]
        ),
        _vm._v(" "),
        _vm._m(0)
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "accrue-section" }, [
      _c("span", [
        _vm._v("or save up and earn "),
        _c("span", { staticClass: "accrue-percentage-summary" }, [
          _vm._v("15%")
        ]),
        _vm._v(" in cash rewards with "),
        _c("span", [
          _c("img", {
            staticClass: "accrue-logo-black-img",
            attrs: { src: "/media/wysiwyg/accrue-logo-black.png" }
          })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }