import getHelper from '../helper'
import {
    LENS_OPTIONS_CODE,
    LENS_OPTION_CODE_POLY,
    LENS_OPTION_CODE_167
} from '../lens_options'
import {
    LENS_TYPE_ID,
    LENS_TYPE_SECTION_ID
} from '../../../../../app/common/constants/lens_type'
import {defaultOptions, framesTypeCustomize} from "./default";

const ENABLED_LIMITERS_COLOR = {
    'gen-8-grey': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_POLY,
            LENS_OPTION_CODE_167
        ]
    }
}

const ENABLED_LIMITERS_LENS_TYPE = {
    'transitions-xtractive': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_POLY,
            LENS_OPTION_CODE_167
        ]
    }
}

const DISABLED_LIMITERS_LENS_TYPE = {
    'transitions-xtractive': {
        [LENS_OPTIONS_CODE]: [
            LENS_OPTION_CODE_167
        ]
    }
}

export const LIMITERS_DEFAULT_OPTICAL = {
    options_disable: {
        progressive_standard: {
            [LENS_TYPE_SECTION_ID]: DISABLED_LIMITERS_LENS_TYPE
        }
    },
    options_enable: {
        single_vision_distance: {
            color: ENABLED_LIMITERS_COLOR,
        },
        single_vision_distance_premium: {
            color: ENABLED_LIMITERS_COLOR,
        },
        single_vision_reading: {
            color: ENABLED_LIMITERS_COLOR,
        },
        single_vision_reading_premium: {
            color: ENABLED_LIMITERS_COLOR,
        },
        progressive_standard: {
            color: ENABLED_LIMITERS_COLOR,
            [LENS_TYPE_SECTION_ID]: ENABLED_LIMITERS_LENS_TYPE
        },
        progressive_premium: {
            color: ENABLED_LIMITERS_COLOR,
            [LENS_TYPE_SECTION_ID]: ENABLED_LIMITERS_LENS_TYPE
        }
    },
    anti_reflective_enable: {
        'single_vision_distance_premium': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']
        },
        'single_vision_distance': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']
        },
        'single_vision_reading_premium': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']
        },
        'single_vision_reading': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']
        },
        'progressive_standard': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']
        },
        'progressive_premium': {
            'Transition Polarized': ['Standard AR', 'Empire Green Plus AR']
        },
        'plano_lens': {
            'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR', 'Essilor Anti Fog AR', 'Crizal Prevencia AR', 'Blue-Light Blocking AR']
        },
        'computer_lens' : {
            'Blue-Light Blocking': ['Standard AR', 'Empire Green Plus AR'],
        }
    }
}

export default function getOpticalStepList (params) {
    const defaultStepOptions = defaultOptions(params)
    const {product_config} = params
    const {DEFAULT_LAST_STEP} = getHelper(params)


    return [
        defaultStepOptions.notesOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.chooseLens.prescriptionType,
                    defaultStepOptions.chooseLens.nonPrescriptionType
                ]
            }
        },
        {
            'id': 'nonprescription',
            'label': 'Choose Non-Prescription',
            'summary_label': 'Non-Prescription',
            'value_renderer': 'renderer-options',
            'default_value': 'plano',
            'always_visible': true,
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.nonPrescriptionTypes.planoNonPrescription,
                    defaultStepOptions.nonPrescriptionTypes.computerNonPrescription,
                ]
            }
        },

        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    ...defaultStepOptions.prescriptionTypes.progressivePrescription
                ]
            }
        },
        {
            'id': 'frames_type',
            'label': 'Choose Lenses',
            'summary_label': 'Choose Lenses',
            'no_summary': true,
            'value_renderer': 'renderer-options',
            'default_value': 'frames_customize',
            'component': {
                'name': 'selector-options',
                'options': [
                    framesTypeCustomize
                ]
            }
        },
        Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_reading'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_reading_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP}),
        {
            'id': LENS_TYPE_ID,
            'label': 'Select Lens Type',
            'summary_label': 'Lens Type',
            'value_renderer': 'renderer-options',
            // 'hide_for_product': ['computer_lens'],
            'component': {
                'name': 'selector-options-lens-type',
                'params': {
                    'product_config': product_config
                },
                'options': [
                    defaultStepOptions.lensType.computerLens,
                    defaultStepOptions.lensType.blueLightLens,
                    defaultStepOptions.lensType.transitionLens,
                ]
            }
        },
        {
            'id': LENS_OPTIONS_CODE,
            'label': 'Select Lens Material',
            'summary_label': 'Lens Option',
            'value_renderer': 'renderer-options',
            'hide_for_product': ['sunglass_lens', 'transition_lens'],
            'always_visible': true,
            'component': {
                'name': 'selector-options-lens-option',
                'params': {
                    'product_config': product_config
                },
                'options': [
                    defaultStepOptions.lensOptions.polyLensOption,
                    defaultStepOptions.lensOptions.hi167LensOption,
                ]
            }
        },
        {
            'id': 'lens_category',
            'label': 'Choose Lens Type',
            'summary_label': 'Lens Type',
            'value_renderer': 'renderer-options',
            'default_value': null,
            'component': {
                'name': 'selector-lens-category',
                'params': {
                    'product_config': product_config
                },
                'options': [
                    {
                        'id': 'optical',
                        'label': 'Optical',
                        'summary_label': 'Optical Lenses',
                        'description': 'Clear lenses with blue-light blocking protection',
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Clear lenses with blue-light blocking protection</p>'
                            }
                        }
                    },
                    {
                        'id': 'sun',
                        'label': 'Sun',
                        'summary_label': 'Sun Lenses',
                        'description': 'Tinted and Mirrored lenses available in a variety of colors',
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>Tinted and Mirrored lenses available in a variety of colors</p>'
                            }
                        }
                    },
                    {
                        'id': 'night',
                        'label': 'Night',
                        'summary_label': 'Night Lenses',
                        'description': '50% orange tinted lenses with blue light protection to block the HEV rays emitted from digital screens and increase melatonin naturally before bed',
                        'component_help': {
                            'name': 'help-bubble',
                            'params': {
                                'text': '<p>50% orange tinted lenses with blue light protection to block the HEV rays emitted from digital screens and increase melatonin naturally before bed</p>'
                            }
                        }
                    }
                ]
            }
        }
    ]
}
