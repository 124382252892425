<template>
   <div :class="[brand + '-seo-parent-container']">
        <div class="shopframes-seo-prescription-container">
            <div class="shopframes-seo-row seo-prescription">
                <div class="shopframes-seo-text">
                    <h2>Oakley Glasses</h2>
                    <p>Lensabl carries men's and women's Oakley sports glasses that work and play as hard as you do. Purchase Oakley prescription glasses for free standard delivery and other perks.</p>
                </div>
                <div class="shopframes-seo-img">
                </div>
            </div>
        </div>

        <div class="shopframes-seo-frames-container">
            <div class="shopframes-seo-row seo-frames">
                <div class="shopframes-seo-frames-img">
                </div>
                <div class="shopframes-seo-text">
                    <h2>Oakley Eyeglasses for Your Prescription</h2>
                    <p>You live an active life and need eyeglasses that match your pace. We carry Oakley prescription glasses for everyday use and sunglasses that offer superb eye protection alongside vision correction.<br /><br />Browse our inventory to find Oakley prescription shades or clear glasses you can wear everywhere. You can fit your Oakleys with single vision, bifocal or progressive lenses. If your eyes require more assistance than most, you can get high-index lenses for the strongest prescriptions. We encourage you to <a href="/prescription-scanner-app/qr"><span class="seo-contact-link">use our prescription reader</span></a> for the most accurate lens readings — or <a href="/online-vision-prescription"><span class="seo-contact-link">renew your prescription online</span></a> with Lensabl.</p>
                </div>
            </div>
        </div>

        <div class="shopframes-seo-designer-container">
            <div class="shopframes-seo-row seo-designer">
                <div class="shopframes-seo-text">
                    <h2>Fashionable Oakley Frames for All Styles</h2>
                    <p>
                       Lensabl has an Oakley frame to fit everyone's style. Shop our inventory to find men's and women's Oakley eyeglasses from the brand's most prominent lines, including Airdrop, Gascan, Feedback, Pitchman, Fuller and more. Oakley uses high-quality materials like acetate, plastic, steel, titanium and propionate for durable eyewear that looks great in all settings.<br /><br />You can choose from many frame colors when you shop for Oakley glasses on Lensabl. Check out our inventory to find everything from bold, attention-grabbing Oakley frames to more subtle styles. A stylish set of sunglasses or clear glasses can give you confidence on the go.
                    </p>
                </div>
            <div class="shopframes-seo-designer-img">
            </div>
            </div>
        </div>

        <div class="shopframes-seo-eyeglass-container">
            <div class="shopframes-seo-row seo-eyeglass">
                <div class="shopframes-seo-text seo-eyeglass-text">
                    <h2>Order Oakley Prescription Frames Through Lensabl</h2>
                    <p>As a Lensabl customer, you'll receive free standard shipping, a 12-month warranty and a 30-day return period with every order. Buy Oakley eyewear online today to experience these perks! You'll enjoy even more benefits if you join Lensabl+. <a href="/plus/vision_plan"><span class="seo-contact-link">Enroll in a plan</span></a> that meets your needs to get clear vision and so much more.</p>
                </div>
                <a href="/glasses-frames" title="Glasses Frames">
                    <div class="button button--copy-solid">
                        <div class="button__container">GET NEW FRAMES</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OakleySeo',
    props: {
        brand: {
            type: [String],
            default: ''
        }
    },
}
</script>
