<template>
    <div id="grid-list">
        <PageLoader v-if="getLoading" :load="getLoading" />
        <template v-else>
            <CatalogCategoryViewBanner
                :class="[getClass + '-banner', currentCategory.url_key, getClass === 'ic!berlin' ? 'icberlin-banner' : '']"
                :category="currentCategory"
                :category-class="getClass"
                class="spectacles-banner frames-banner lensabl-frames-banner"
            />

            <LensesInclude />
            <TermsInformation />
            <NavigationFilters
                :list-title="`Filters`"
                :load="getLoadingProducts"
                :categories="categoriesList"
                :filters-material="getFiltersList['frames_material']"
                :filters-size="getFiltersList['frames_size']"
                :selected-filters="getSelectedFilters"
                :current-id="currentCategoryId"
                class="category-frames-tabs"
                @[EVENT_CATEGORY_SELECTED]="updateFilteredProducts"
            />
            <div id="pagination-grid">
                <!-- for :products prop below, switch modifiedEEProductList back to getProductsList if we get the filtered EE Sun and Night skus -->
                <CatalogProductList
                    :key="`products-${getClass}`"
                    :load="getLoadingProducts"
                    :products="modifiedEEProductList"
                    class="grid products-grid shop-frames-cat"
                />
            </div>
            <div v-if="getLoadingBatch">
                <Loader />
            </div>
            <section class="landing-page_ad-block">
                <div class="ad-block_container">
                    <a href="/shop-contacts">
                        <img
                            src="https://www.lensabl.com/media/wysiwyg/email/images/EMAIL_Contacts-Ad-update.jpg"
                            alt="Shop Now button with text and woman holding contacts"
                        />
                    </a>
                    <a href="/choose-my-lenses">
                        <img
                            src="https://www.lensabl.com/media/wysiwyg/email/images/EMAIL_Lens-Replacement-Ad-update.jpg"
                            alt="Shop Now button with woman holding a pair of sunglasses"
                        />
                    </a>
                </div>
            </section>
            <FramesPageSeo :brand="getClass"/>
        </template>
    </div>
</template>

<script>
import _size from 'lodash/size'
import _cloneDeep from 'lodash/cloneDeep'
import {mapState, mapActions, mapGetters} from 'vuex'
import CatalogCategoryViewBanner from '../view/CatalogCategoryViewBanner.vue'
import PageLoader from '../../../loaders/PageLoader.vue'
import Loader from '../../../loaders/Loader.vue'
import TermsInformation from '../../cms_info/TermsInformation.vue'
import LensesInclude from '../../cms_info/LensesInclude.vue'
import NavigationFilters from '../../navigation/NavigationFilters.vue'
import CatalogProductList from '../../product/CatalogProductList.vue'
import category from '../storage/category/view'
import FramesPageSeo from '../../../seo/FramesPageSeo.vue'
import {
    EVENT_CATEGORY_SELECTED
} from '../../../../app/utils/constants'

export default {
    name: 'CategoryLensabl',
    components: {
        CatalogCategoryViewBanner,
        PageLoader,
        Loader,
        LensesInclude,
        TermsInformation,
        NavigationFilters,
        CatalogProductList,
        FramesPageSeo
    },
    data: () => ({
        categoriesList: [],
        EVENT_CATEGORY_SELECTED: EVENT_CATEGORY_SELECTED
    }),
    computed: {
        ...mapState('partner', ['currentCategory']),
        ...mapGetters('partner', [
            'getLoading',
            'getClass',
            'currentCategoryId',
            'getCategoriesList',
            'getFiltersList',
            'getSelectedFilters',
            'getProductsList',
            'getLoadingBatch',
            'getLoadingProducts'
        ]),
        ...mapGetters('category', ['hasFilters']),
        productsCount () {
            return _size(this.getProductsList)
        },
        modifiedEEProductList() {
            // TEMPORARY - currently do not have images for these EE skus, so will need to filter them out from shop frames Night and Sun store
            let updatedProductList = this.getProductsList.filter(product => product.name !== 'Agnes Sun' && product.name !== 'Beck Sun' && product.name !== 'Billie Sun' && product.name !== 'Buckley Sun' && product.name !== 'Catalina Sun' && product.name !== 'Charlie Sun' && product.name !== 'Ellison Sun' && product.name !== 'Florence Sun' && product.name !== 'Grant Sun' && product.name !== 'Logan Sun' && product.name !== 'Peggy Sun' && product.name !== 'Reese Sun' && product.name !== 'Rhodes Sun' && product.name !== 'Thompson Sun' && product.name !== 'Agnes Night' && product.name !== 'Beck Night' && product.name !== 'Billie Night' && product.name !== 'Buckley Night' && product.name !== 'Catalina Night' && product.name !== 'Charlie Night' && product.name !== 'Ellison Night' && product.name !== 'Florence Night' && product.name !== 'Grant Night' && product.name
             !== 'Logan Night' && product.name !== 'Peggy Night' && product.name !== 'Reese Night' && product.name !== 'Rhodes Night' && product.name !== 'Thompson Night')

             if (this.getClass === "shopframes") {
                let filterRayBanMetaList = updatedProductList.filter(product => product.category !== 'Ray-Ban Meta')
                return filterRayBanMetaList
            } else {
                return updatedProductList
            }
        }
    },
    watch: {
        getCategoriesList: {
            handler (list) {
                this.categoriesList = _cloneDeep(list)
            },
            deep: true
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['category/']) {
            this.$store.registerModule('category', category(this.$store))
        }
        this.$store.dispatch('category/fetchDefaultData')
    },
    mounted () {
        window.addEventListener('wheel', this.loadNextProducts)
        window.addEventListener('touchmove', this.loadNextProducts)
    },
    destroy () {
        window.removeEventListener('wheel', this.loadNextProducts)
        window.removeEventListener('touchmove', this.loadNextProducts)
    },
    created () {
        this.$store.commit('partner/setLoadProduct', 1)
        this.$store.commit('partner/setIsTitle', true)
        this.$store.commit('partner/setLoading', true)
        this.$store.commit('partner/setPagination', true)
        this.$store.dispatch('partner/fetchDefaultData')

        if (this.hasFilters) {
            this.fetchFilterFromUrl()
        }
        this.fetchCategoriesList()
        this.checkRedeemablePathCategory()
    },
    methods: {
        ...mapActions('partner', ['getCategories', 'getNextProducts', 'fetchCategoriesList', 'addFiltersToHistory']),
        ...mapActions('category', ['fetchFilterFromUrl']),
        ...mapActions('lensablplus_customer', ['checkRedeemablePath', 'leaving']),
        updateFilteredProducts (id, key = []) {
            if (key.includes('willows')) {
                window.location.href = '/willows'
            } else {
                this.$store.commit('partner/setProductsList')
                this.$store.commit('partner/setLoadingProducts', this.currentCategory.id || true)
                this.$store.commit('partner/setPage', 1)
                this.getCategories()
                this.addFiltersToHistory()

            }
        },
        loadNextProducts () {
            const el = document.getElementById('pagination-grid')
            if (el && _size(this.getProductsList)) {
                if (!this.getLoadingBatch) {
                    this.getNextProducts()
                }
            }
        },
        checkRedeemablePathCategory () {
            this.checkRedeemablePath()
            window.addEventListener('pagehide', this.leaving, true)
        }
    }
}
</script>
