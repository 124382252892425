<template>
    <LensReplacementSeo v-if="currentPage === '/choose-my-lenses' && isDefaultStore" :lensType="'lr'"/>
    <BlueLighttSeo v-else-if="currentPage === '/computer-blue-light-blocking-lenses'" :lensType="'blue'"/>
    <RxSunglassSeo v-else-if="currentPage === '/prescription-sunglass-lenses'" :lensType="'rx'"/>
</template>

<script>
import LensReplacementSeo from './lenses/LensReplacementSeo.vue'
import BlueLighttSeo from './lenses/BlueLightSeo.vue'
import RxSunglassSeo from './lenses/RxSunglassSeo.vue'
import {mapGetters} from 'vuex'

export default {
    name: 'LensesPageSeo',
    components: {
        LensReplacementSeo,
        BlueLighttSeo,
        RxSunglassSeo
    },
    computed: {
        currentPage() {
            return window.location.pathname
        },
        ...mapGetters('storeView', ['store_code']),
        isDefaultStore() {
            return this.store_code === 'default'
        }
    }
}
</script>
