export const TRANSITIONS_SECTION_ID_SIGNATURE = 'transitions-signature'
export const TRANSITIONS_SECTION_ID_XTRACTIVE = 'transitions-xtractive'
export const TRANSITIONS_SECTION_ID_DRIVEWEAR = 'transitions-drivewear'

export const TRANSITIONS_SECTIONS = [
    {
        'id': 'standard',
        'label': 'Transitions&reg; Signature&reg; - Non-Polarized Lenses',
        'checkout_value': 'Transition Non-P',
        'component_help': {
            'name': 'help-bubble',
            'params': {'text': 'Best for everyday use. Signature lenses are are fully clear indoors, and lightly tinted when fully activated. Signature is the fastest and most responsive lens in all light and temperature conditions.'}
        },
        'colors': [
            {
                'id': 'grey',
                'ext': 'gif',
                'label': 'Grey',
                'angled': {
                    'thumbnail': 'transitions-angled-grey.gif',
                    'high_resolution': 'transitions-angled-grey.gif'
                },
                'front': {
                    'thumbnail': 'transitions-front-grey.gif',
                    'high_resolution': 'transitions-front-grey.gif'
                }
            },
            {
                'id': 'brown',
                'ext': 'gif',
                'label': 'Brown',
                'angled': {
                    'thumbnail': 'transitions-angled-brown.gif',
                    'high_resolution': 'transitions-angled-brown.gif'
                },
                'front': {
                    'thumbnail': 'transitions-front-brown.gif',
                    'high_resolution': 'transitions-front-brown.gif'
                }
            },
            {
                'id': 'g15',
                'ext': 'gif',
                'label': 'Green/Grey',
                'angled': {
                    'thumbnail': 'transitions-angled-g15.gif',
                    'high_resolution': 'transitions-angled-g15.gif'
                },
                'front': {
                    'thumbnail': 'transitions-front-g15.gif',
                    'high_resolution': 'transitions-front-g15.gif'
                }
            }
        ]
    },
    {
        'id': 'xtractive',
        'label': 'Transitions&reg; XTRActive&reg; - Non-Polarized Lenses',
        'checkout_value': 'Transition XTRActive',
        'component_help': {
            'name': 'help-bubble',
            'params': {'text': 'Best for extra protection, and will also activate in the car. XTRActive lenses are slightly tinted indoors, and have the darkest tint when fully activated. They are uniquely designed to protect your eyes from the brightest sun outdoors, and harsh artificial light indoors.'}
        },
        'colors': [
            {
                'id': 'x-grey',
                'ext': 'gif',
                'label': 'XTRActive&reg; Grey',
                'angled': {
                    'thumbnail': 'transitions-angled-x-grey.gif',
                    'high_resolution': 'transitions-angled-x-grey.gif'
                },
                'front': {
                    'thumbnail': 'transitions-front-x-grey.gif',
                    'high_resolution': 'transitions-front-x-grey.gif'
                }
            },
            {
                'id': 'x-brown',
                'ext': 'gif',
                'label': 'XTRActive&reg; Brown',
                'angled': {
                    'thumbnail': 'transitions-angled-x-brown.gif',
                    'high_resolution': 'transitions-angled-x-brown.gif'
                },
                'front': {
                    'thumbnail': 'transitions-front-x-brown.gif',
                    'high_resolution': 'transitions-front-x-brown.gif'
                }
            }
        ]
    },
    {
        'id': 'polarized',
        'label': 'Transitions&reg; Vantage&reg; - Polarized Lenses',
        'checkout_value': 'Transition P',
        'component_help': {
            'name': 'help-bubble',
            'params': {'text': 'Best for glare reduction outdoors. Vantage lenses are slightly tinted indoors, and have a dark tint when fully activated. These lenses are also variably polarized -- meaning that the level of polarization adjusts to match the level of outdoor glare.'}
        },
        'colors': [
            {
                'id': 'polarized-grey',
                'ext': 'gif',
                'label': 'Polarized Grey',
                'angled': {
                    'thumbnail': 'transitions-angled-polarized-grey.gif',
                    'high_resolution': 'transitions-angled-polarized-grey.gif'
                },
                'front': {
                    'thumbnail': 'transitions-front-polarized-grey.gif',
                    'high_resolution': 'transitions-front-polarized-grey.gif'
                }
            }
        ]
    },
    {
        'id': 'drivewear',
        'label': 'Transitions DriveWear',
        'checkout_value': 'Transition DriveWear',
        'component_help': {
            'name': 'help-bubble',
            'params': {'text': 'Best for behind the wheel. Drivewear lenses are not recommended for indoor use. They change from a shade of green/yellow, to a copper shade, to a dark reddish-brown color depending on the amount of light \u2014 helping remove glare off the road and car hood for a picture-perfect drive.'}
        },
        'colors': [
            {
                'id': 'drivewear',
                'ext': 'gif',
                'label': 'DriveWear',
                'angled': {
                    'thumbnail': 'transitions-angled-drivewear.gif',
                    'high_resolution': 'transitions-angled-drivewear.gif'
                },
                'front': {
                    'thumbnail': 'transitions-front-drivewear.gif',
                    'high_resolution': 'transitions-front-drivewear.gif'
                }
            }
        ]
    }
]
