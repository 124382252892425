import _defaults from 'lodash/defaults'
import _map from 'lodash/map'
import _cloneDeep from 'lodash/cloneDeep'
import _size from 'lodash/size'
import _union from 'lodash/union'
import axios from 'axios'
import {
    PRESCRIPTION_DEFAULT_VALUES
} from '../../../app/utils/constants'
import * as abstract from '../../../storage/abstract'

export const URL_KEY_LENS_TYPE = 'lens-type'
export const URL_KEY_LENS_TYPE_DAILY = 'daily'
export const URL_KEY_LENS_TYPE_BI_WEEKLY = 'bi-weekly'
export const URL_KEY_LENS_TYPE_MONTHLY = 'monthly'
export const URL_KEY_LENS_TYPE_TORIC = 'toric-astigmatism'
export const URL_KEY_LENS_TYPE_MULTIFOCAL = 'multifocal-presbyopia'
export const CONTACTS_RX_KEYS_INTERFACE = {
    bc: '',
    dia: '',
    power: '',
    cyl: '',
    axis: '',
    add: ''
}
export const CONTACTS_EYE_DATA_INTERFACE = {
    boxes: 1,
    brand: '',
    second_name: '',
    image: '',
    regPrice: '',
    subPrice: '',
    rx: {
        ...CONTACTS_RX_KEYS_INTERFACE
    },
    rx_dropdown: {},
    color_options: [],
    product_sku: '',
    default_box_quantity: '',
    default_sub_box_quantity: '',
    original_box_quantity: '',
    color: false,
    id: null
}

const right = {
    ...CONTACTS_EYE_DATA_INTERFACE,
    label: 'Right Eye (OD)'
}
const left = {
    ...CONTACTS_EYE_DATA_INTERFACE,
    label: 'Left Eye (OS)'
}

export const state = () => ({
    ...abstract.state(),
    adminHtml: false,
    manufacturers: '',
    brands: '',
    lens_type: '',
    product_info: '',
    rightEyeConfirmed: false,
    leftEyeConfirmed: false,
    rightEyeChecked: true,
    leftEyeChecked: true,
    productDetailsStep: false,
    productAddedToCart: false,
    products: [],
    allProducts: [],
    runLoader: true,
    viewAll: true,
    subscribed: 'no',
    subscriptionType: '',
    contactsPrice: '',
    contactsSubscriptionPrice: '',
    enable_subscription: '',
    isNotAvailableValue: false,
    eyeData: {
        right: _cloneDeep(right),
        left: _cloneDeep(left)
    },
    originalEyeData: {
        right: _cloneDeep(right),
        left: _cloneDeep(left)
    },
    searched: '',
    contactsRXData: {
        left: _cloneDeep(CONTACTS_RX_KEYS_INTERFACE),
        right: _cloneDeep(CONTACTS_RX_KEYS_INTERFACE)
    },
    isContactsRXDataAjaxLoading: false,
    api: {
        urls: {
            getContactsRXData: '/lensabl-contacts/product/getContactsRXData'
        }
    }
})

export const getters = {
    getLeftEyeChecked: state => state.leftEyeChecked,
    getRightEyeChecked: state => state.rightEyeChecked,
    getLeftEyeConfirmed: state => state.leftEyeConfirmed,
    getRightEyeConfirmed: state => state.rightEyeConfirmed,
    getProducts: state => state.products,
    getViewAll: state => state.viewAll,
    getLoader: state => state.runLoader,
    getSubscribed: state => state.subscribed,
    getSubscribedEnabled: state => state.enable_subscription,
    getSubscriptionType: state => state.subscriptionType,
    getEyeData: state => {
        return state.eyeData
    },
    getOriginalEyeData: state => state.originalEyeData,
    getOriginalBoxQuantity: state => eye => state.eyeData[eye].original_box_quantity,
    getAllProducts: state => state.allProducts,
    getProductAddedToCart: state => state.productAddedToCart,
    getConfigKey: (state, getters, rootState, rootGetters) => rootGetters.rootState.key,
    isContactDetailsPage: (state, getters, rootState, rootGetters) => rootGetters.rootState.key === 'contacts',
    isContactProductPage: (state, getters, rootState) => rootState.currentRoute === 'contacts-lenses/product',
    getContactsPrice: state => state.contactsPrice,
    getContactsSubscriptionPrice: state => state.contactsSubscriptionPrice,
    getCommonData: state => {
        let type = ''
        if (state.leftEyeChecked) {
            type = 'left'
        } else if (state.rightEyeChecked) {
            type = 'right'
        }
        return state.eyeData[type]
    },
    getProductSku: (state, getters) => {
        const commonData = getters.getCommonData
        return commonData ? commonData.product_sku : ''
    },
    getSearched: state => state.searched
}

export const actions = {
    ...abstract.actions,
    getContactsRXData: ({state, getters, commit, rootGetters}, {sku = [], rx = {}, index}) => {
        if (!sku.length) return
        const color = state.eyeData[index].color
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }
        state.isNotAvailableValue = false
        axios.post(state.api.urls.getContactsRXData, {sku, rx, color}, axiosConfig).then(({data, status}) => {
            if (status === 200 && _size(data)) {
                sku.map(skuItem => {
                    const colorOptions = data.color_options[skuItem]
                    if (_size(colorOptions)) {
                        commit('SET_ORIGINAL_DATA', {
                            eye: index,
                            color_options: _union(colorOptions)
                        })
                    }
                    const dataSkuItem = data.rx[skuItem]
                    if (!_size(dataSkuItem)) {
                        state.isNotAvailableValue = true
                        const emptyRx = state.originalEyeData[index].rx
                        emptyRx.power = rx.power
                        commit('SET_EYE_RX_CHECKED', {
                            eye: index,
                            rx: {...emptyRx}
                        })
                    } else {
                        state.isNotAvailableValue = false
                        commit('UPDATE_CONTACTS_RX_DATA', {
                            eye: index,
                            rx: dataSkuItem
                        })
                    }
                })
            }
        }).catch(error => {
            console.error(error)
        })
    },
    toggleCheckedEye: ({commit}, value) => value === 'right' ? commit('RIGHT_EYE_TOGGLED') : commit('LEFT_EYE_TOGGLED'),
    setCheckedEye: ({commit}, value) => {
        if (value === 'right') {
            commit('RIGHT_EYE_CHECKED')
        }
        if (value === 'left') {
            commit('LEFT_EYE_CHECKED')
        }
    },
    updateConfirmedEye: ({state, commit}) => {
        if (state.leftEyeChecked) {
            commit('LEFT_EYE_CONFIRMED')
        }
        if (state.rightEyeChecked) {
            commit('RIGHT_EYE_CONFIRMED')
        }
    },
    checkProductDetailsStep: ({commit}) => {
        commit('RESET_EYE_DATA')
        commit('UPDATE_DETAILS_STEP')
    },
    clearProductAddToCart: ({state, commit}) => {
        if (state.productAddedToCart) {
            commit('RESET_EYE_DATA')
            commit('UPDATE_PRODUCT_ATC')
        }
    },
    getEyeChecked: ({state}, eye) => {
        const prop = eye + 'EyeChecked'
        return state.hasOwnProperty(prop) && state[prop]
    },
    setPrescription: ({commit, dispatch}, value = {}) => {
        _map(['left', 'right'], eye => {
            if (dispatch('getEyeChecked', eye)) {
                const newValues = {
                    'eye': eye,
                    'rx': _defaults(value, PRESCRIPTION_DEFAULT_VALUES)
                }
                commit('SET_EYE_RX_CHECKED', newValues)
            }
        })
    }
}

export const mutations = {
    ...abstract.mutations,
    UPDATE_PRODUCTS: (state, products) => state.products = products,
    UPDATE_VIEW_ALL: (state, viewAll) => state.viewAll = viewAll,
    UPDATE_RUNNER: (state, runLoader) => state.runLoader = runLoader,
    RIGHT_EYE_TOGGLED: state => state.rightEyeChecked = !state.rightEyeChecked,
    LEFT_EYE_TOGGLED: state => state.leftEyeChecked = !state.leftEyeChecked,
    RIGHT_EYE_CHECKED: state => state.rightEyeChecked = true,
    LEFT_EYE_CHECKED: state => state.leftEyeChecked = true,
    RIGHT_EYE_CONFIRMED: state => state.rightEyeConfirmed = true,
    LEFT_EYE_CONFIRMED: state => state.leftEyeConfirmed = true,
    UPDATE_CONTACTS_RX_DATA: (state, payload = {}) => state.contactsRXData[payload.eye] = payload.rx,
    RIGHT_EYE_REMOVED: state => {
        state.rightEyeConfirmed = false
        state.rightEyeChecked = true
    },
    LEFT_EYE_REMOVED: state => {
        state.leftEyeConfirmed = false
        state.leftEyeChecked = true
    },
    RESET_EYE_DATA: state => {
        state.leftEyeConfirmed = false
        state.rightEyeConfirmed = false
        state.rightEyeChecked = true
        state.leftEyeChecked = true
        state.subscribed = false
        state.eyeData['right'] = right
        state.eyeData['left'] = left
    },
    SET_ORIGINAL_DATA: (state, payload = {}) => {
        const eye = payload.eye
        _map(_cloneDeep(payload), (value, key) => state.originalEyeData[eye][key] = value)
    },
    RESET_ORIGINAL_DATA: (state, payload = {}) => {
        Object.keys(payload).forEach(key => {
            state.eyeData[key].rx = payload[key].rx
        })
    },
    SET_EYE_RX_CHECKED: (state, payload = {}) => {
        const eye = payload.eye
        _map(_cloneDeep(payload.rx), (value, key) => state.eyeData[eye].rx[key] = value)
    },
    SET_EYE_DATA: (state, payload = {}) => {
        const eye = payload.eye
        _map(_cloneDeep(payload), (value, key) => state.eyeData[eye][key] = value)
    },
    UPDATE_DETAILS_STEP: state => state.productDetailsStep = !state.productDetailsStep,
    UPDATE_ALL_PRODUCTS: (state, products) => state.allProducts = products,
    UPDATE_SUBSCRIBE: state => state.subscribed === 'yes' ? state.subscribed = 'no' : state.subscribed = 'yes',
    SET_SUBSCRIBE: (state, subscribed) => subscribed ? state.subscribed = 'yes' : state.subscribed = 'no',
    UPDATE_PRODUCT_ATC: state => state.productAddedToCart = !state.productAddedToCart,
    UPDATE_PRODUCT_PRICE: (state, price) => state.contactsPrice = price,
    UPDATE_PRODUCT_SUBSCRIPTION_PRICE: (state, payload) => {
        const regPrice = payload.regPrice
        const percentDecimal = payload.subscriptionPercent / 100
        const dollarDiscounted = regPrice * percentDecimal
        state.contactsSubscriptionPrice = (regPrice - dollarDiscounted).toFixed(2)
    },
    UPDATE_ORIGINAL_BOX_QUANTITY: (state, payload) => {
        state.eyeData.left.original_box_quantity = payload.left
        state.eyeData.right.original_box_quantity = payload.right
    },
    UPDATE_SEARCHED: (state, searched) => state.searched = searched
}

export default () => ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
