<template>
    <div>
        <ProgressBarBusiness v-if="isBusinessOrderTool && statusApi" />
        <ProgressBar v-if="!isBusinessOrderTool && statusApi" />
    </div>
</template>

<script>
import App from '../../App.vue'
import ProgressBar from '../../components/lensablplus/ordertool/ProgressBar.vue'
import ProgressBarBusiness from '../../components/lensablplus/ordertool/ProgressBarBusiness.vue'

export default {
    name: 'RouterLensablplusPlanChoiceHeader',
    components: {
        ProgressBar,
        ProgressBarBusiness
    },
    extends: App,
    computed: {
        isBusinessOrderTool () {
            return window.location.pathname === '/plus/group'
        }
    }
}
</script>
