import RendererOptions from '../options/RendererOptions.vue'

export default {
    name: 'RendererFramesColor',
    extends: RendererOptions,
    computed: {
        renderer () {
            const data = this.checkoutData
            if (data.custom_options.frames_color) {
                return data.custom_options.frames_color
            } else {
                return data.custom_options.frames_size
            }
        }
    }
}
