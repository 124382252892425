var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bluelight-seo-container" }, [
      _c(
        "section",
        { staticClass: "bluelight-seo-section bluelight-seo-top-section" },
        [
          _c(
            "div",
            { staticClass: "bluelight-seo-row bluelight-seo-top-row" },
            [
              _c(
                "div",
                { staticClass: "bluelight-seo-text bluelight-seo-top-text" },
                [
                  _c("h2", [_vm._v("Blue-Light-Blocking Lenses")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Screens like your computer monitor, tablet or TV screen emit blue light that can leave you feeling discomfort and cause long-term damage. Blue light lenses can protect your eyes during long hours in front of a digital screen."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v("Lensabl carries a "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/choose-my-lenses",
                          title: "Lens Replacement"
                        }
                      },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("wide selection of lenses")
                        ])
                      ]
                    ),
                    _vm._v(" and "),
                    _c(
                      "a",
                      { attrs: { href: "/shop-frames", title: "Shop Frames" } },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("stylish frames")
                        ])
                      ]
                    ),
                    _vm._v(
                      " for men and women with or without a prescription so you can order custom blue light lenses online today. Customers with existing prescriptions can "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/online-vision-prescription",
                          title: "Online Vision Prescription"
                        }
                      },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("take an online vision eye exam")
                        ])
                      ]
                    ),
                    _vm._v(" for an accurate update or renewal.")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bluelight-seo-img" })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "bluelight-seo-frames-container bluelight-seo-section" },
        [
          _c(
            "div",
            { staticClass: "bluelight-seo-row bluelight-seo-frames-row" },
            [
              _c("div", { staticClass: "bluelight-seo-frames-img" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bluelight-seo-text bluelight-seo-frames-text" },
                [
                  _c("h2", [
                    _vm._v("Purchase Custom Blue Light Lenses Online")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Blue light lenses filter out blue light before it can reach your eyes. Lensabl's blue light lens options expand on blue light filtration with an anti-reflective coating for optimal eye protection for wearers with or without prescriptions."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "Browse our inventory to find prescription, non-prescription and blue-light "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/reading-lenses",
                          title: "Reading Lenses"
                        }
                      },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("reading glasses online")
                        ])
                      ]
                    ),
                    _vm._v(
                      ". Customers can match their blue light lenses with a fashionable frame from "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/glasses-frames",
                          title: "Glasses Frames"
                        }
                      },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("top designers")
                        ])
                      ]
                    ),
                    _vm._v(" like "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/shop-frames?category=ray-ban",
                          title: "Ray-Ban"
                        }
                      },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("Ray-Ban")
                        ])
                      ]
                    ),
                    _vm._v(", "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/shop-frames?category=kate-spade",
                          title: "Kate Spade"
                        }
                      },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("Kate Spade")
                        ])
                      ]
                    ),
                    _vm._v(" or "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/shop-frames?category=oakley",
                          title: "Oakley"
                        }
                      },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("Oakley")
                        ])
                      ]
                    ),
                    _vm._v("."),
                    _c("br"),
                    _c("br"),
                    _vm._v("Lensabl makes it easy to "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/computer-blue-light-blocking-lenses",
                          title: "Blue Light Blocking Lenses"
                        }
                      },
                      [
                        _c("span", { staticClass: "bluelight-seo-link" }, [
                          _vm._v("order blue light lenses online")
                        ])
                      ]
                    ),
                    _vm._v(
                      " — choose the lenses you want and set your prescription type if applicable. Our blue light lenses are available in numerous thicknesses and power levels. Your eye health matters, so buy blue-light-blocking lenses to prevent damage."
                    )
                  ])
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "bluelight-seo-benefit-container bluelight-seo-section"
        },
        [
          _c(
            "div",
            { staticClass: "bluelight-seo-row bluelight-seo-benefit-row" },
            [
              _c(
                "div",
                {
                  staticClass: "bluelight-seo-text bluelight-seo-benefit-text"
                },
                [
                  _c("h2", [
                    _vm._v("How Can Blue Light Glasses Benefit You? ")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Blue light glasses reduce the symptoms you experience while working in front of screens for long hours and after you power down for the day. Wear blue light lenses to prevent:"
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("ul", { staticClass: "bluelight-seo-benefit-list" }, [
                _c("li", [_vm._v("Headaches")]),
                _vm._v(" "),
                _c("li", [_vm._v("Blurry vision")]),
                _vm._v(" "),
                _c("li", [_vm._v("Eyestrain")]),
                _vm._v(" "),
                _c("li", [_vm._v("Sleep loss")]),
                _vm._v(" "),
                _c("li", [_vm._v("Dryness")])
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "bluelight-seo-purchase-container bluelight-seo-section"
        },
        [
          _c(
            "div",
            { staticClass: "bluelight-seo-row bluelight-seo-purchase-row" },
            [
              _c(
                "div",
                {
                  staticClass: "bluelight-seo-text bluelight-seo-purchase-text"
                },
                [
                  _c("h2", [
                    _vm._v("Purchase Blue Light Glasses From Lensabl")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Order blue light prescription glasses for men and women online through Lensabl. You'll receive fast delivery, risk-free returns and a 12-month warranty. You can experience even more benefits when you "
                    ),
                    _c("a", { attrs: { href: "/plus", title: "plus" } }, [
                      _c("span", { staticClass: "bluelight-seo-link" }, [
                        _vm._v("register for Lensabl+")
                      ])
                    ]),
                    _vm._v(
                      ", our online vision plan that provides savings on glasses and personalized attention from real doctors."
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bluelight-seo-btns" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/choose-my-lenses",
                      title: "Lens Replacement"
                    }
                  },
                  [
                    _c("div", { staticClass: "button button--copy-solid" }, [
                      _c(
                        "div",
                        { staticClass: "button__container seo-btn-lr" },
                        [_vm._v("SHOP LENS REPLACEMENT")]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { attrs: { href: "/shop-frames", title: "Shop Frames" } },
                  [
                    _c("div", { staticClass: "button button--copy-solid" }, [
                      _c("div", { staticClass: "button__container" }, [
                        _vm._v("SHOP FRAMES")
                      ])
                    ])
                  ]
                )
              ])
            ]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }