<template>
    <div>
        <div v-if="isPPECategory" class="ppe-frame-description">
            <h2><strong>Essential Protection, Everyday Style</strong></h2>
            <span>Side and top shields provide an added layer of protection from harmful bacteria, particles, and irritants. These frames are perfect for essential workers, healthcare professionals, and anyone in need of a little extra peace of mind while still looking fashionably chic.
            </span>
        </div>
        <div v-if="isBoseCategory">
            <BoseProductList />
            <BoseSeo />
        </div>
        <div v-else-if="isJamesOroCategory">
            <JamesOroProductList />
        </div>
        <div v-else-if="isGoodrCategory">
            <GoodrProductList />
        </div>
        <div v-else-if="isAeCategory">
            <AeProductList />
        </div>
        <div v-else-if="isWhCategory">
            <WhProductList />
        </div>
        <div v-else-if="isRayBanMetaCategory">
            <RayBanMetaProductList />
        </div>
        <div v-else>
            <CatalogProductList
                class="grid products-grid"
                :class="{'ppe-products-wrapper': isPPECategory }"
                :products="leSpecsProducts"
            />
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import CatalogProductList from '../../../components/catalog/product/CatalogProductList.vue'
import BoseProductList from './bose/BoseProductList.vue'
import JamesOroProductList from './JamesOro/JamesOroProductList.vue'
import RayBanMetaProductList from './RayBanMeta/ProductList.vue'
import GoodrProductList from './Goodr/ProductList.vue'
import AeProductList from './Ae/ProductList.vue'
import WhProductList from './Wh/ProductList.vue'
import products from '../../../components/catalog/category/storage/category/lespecs-products'
import category from './storage/category/view'
import BoseSeo from '../../seo/brands/BoseSeo.vue'
import {
    EXCEPT_LOAD_FRAMES,
    BOSE_FRAMES
} from './storage/category/constants'

export default {
    name: 'ProductList',
    components: {
        CatalogProductList,
        BoseProductList,
        JamesOroProductList,
        GoodrProductList,
        AeProductList,
        WhProductList,
        RayBanMetaProductList,
        BoseSeo
    },
    computed: {
        ...mapState('lespecs_products', ['leSpecsProducts']),
        ...mapGetters('category', ['currentCategory']),
        isBoseCategory () {
            return this.currentCategory.name.toLocaleLowerCase() === BOSE_FRAMES
        },
        isJamesOroCategory () {
            return this.currentCategory.name.toLocaleLowerCase().includes('james oro')
        },
        isGoodrCategory () {
            return this.currentCategory.name.toLocaleLowerCase().includes('goodr')
        },
        isAeCategory () {
            return this.currentCategory.name.toLocaleLowerCase().includes('accepted and excepted')
        },
        isWhCategory () {
            return this.currentCategory.name.toLocaleLowerCase().includes('walter hill')
        },
        isRayBanMetaCategory () {
            return this.currentCategory.name.toLocaleLowerCase().includes('meta')
        },
        isPPECategory () {
            return this.currentCategory.name.indexOf('Personal') !== -1
        }
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lespecs_products/']) {
            this.$store.registerModule(['lespecs_products'], products(this.$store))
        }

        if (!this.$store._modulesNamespaceMap['category/']) {
            this.$store.registerModule(['category'], category(this.$store))
        }
    },
    created () {
        if (EXCEPT_LOAD_FRAMES.indexOf(this.currentCategory.name.toLocaleLowerCase()) === -1) {
            this.$store.dispatch('lespecs_products/fetchLeSpecsProducts')
        }
    }
}
</script>

<style scoped>

</style>
