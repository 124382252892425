<template>
    <div v-if="!loading" class="loader-container">
        <div class="loader-section">
            <slot></slot>
        </div>
    </div>
</template>
<script>
// This loader will add an overlay with the text of 'Loading...'
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
