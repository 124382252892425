var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "catalog-category-view categorypath-jamesoro-eyewear" },
    [
      _c("div", { staticClass: "rbm-banner-container" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "video",
          {
            attrs: { playsinline: "", autoplay: "", muted: "", loop: "" },
            domProps: { muted: true }
          },
          [
            _c("source", {
              attrs: {
                src:
                  "/media/catalog/category/ray-ban-meta/ray-ban-meta-video.webm",
                type: "video/webm"
              }
            }),
            _vm._v(" "),
            _c("source", {
              attrs: {
                src:
                  "/media/catalog/category/ray-ban-meta/ray-ban-meta-video.mp4",
                type: "video/mp4"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "dompurify-html",
            rawName: "v-dompurify-html",
            value: _vm.breadcrumbsHtml,
            expression: "breadcrumbsHtml"
          }
        ],
        staticClass: "frames-breadcrumbs"
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "category-products frames-products" },
        [
          _vm.categoryLoader
            ? _c("PageLoader", { attrs: { load: _vm.categoryLoader } })
            : [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "products wrapper grid products-grid" },
                    [
                      _c(
                        "ul",
                        { staticClass: "products list items product-items" },
                        _vm._l(_vm.getProducts, function(product) {
                          return _c(
                            "li",
                            {
                              key: product.url,
                              staticClass: "item product product-item"
                            },
                            [
                              _c("ProductItem", {
                                attrs: {
                                  product: product,
                                  showSwatcher: true,
                                  showProductPrice: true,
                                  "show-category-name": true
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                ])
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c("TechInfo")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rbm-banner-row-container" }, [
      _c("div", { staticClass: "rbm-banner-row" }, [
        _c("div", { staticClass: "rbm-banner-logo-mobile-container" }),
        _vm._v(" "),
        _c("div", { staticClass: "rbm-banner-logo-container" }, [
          _c("img", {
            staticClass: "rbm-banner-logo",
            attrs: {
              src: "/media/catalog/category/ray-ban-meta/ray-ban-meta-logo.webp"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "rbm-banner-text" }, [
            _vm._v("Smart Glasses")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }