import Vue from 'vue'
import _size from 'lodash/size'
import _find from 'lodash/find'
import getCategoryService from '../../../../../app/utils/category'
import * as abstract from '../../../../../storage/abstract'

export const CATEGORY_META_DATA_INTERFACE = {
    title: '',
    description: '',
    keywords: ''
}

export const CATEGORY_INTERFACE = {
    id: '',
    image: '',
    name: '',
    url: '',
    description: '',
    products: [],
    meta_data: {
        ...CATEGORY_META_DATA_INTERFACE
    }
}

export const state = () => ({
    loader: false,
    current_id: '',
    parent_category: [],
    categories: [],
    subcategories: [],
    customer_id: '',
    customer_logged_in: false,
    categoryService: null,
    breadcrumbsHtml: '',
    category_image: null,
    category_image_mobile: null,
    products: [],
    filter: {
        category: [],
        lens_type: [],
        frame_size: [],
        material: []
    },
    allProducts: [],
    filtered: false
})

export const getters = {
    getCatProducts: state => state.allProducts,
    categoryLoader: state => state.loader,
    getFiltered: state => state.filter,
    hasFilters: (state, getters) => {
        state.filtered = false
        const categoriesArray = getters.getUrlPathnameArray
        if (categoriesArray.length >= 2) {
            state.filtered = true
        }
        if (window.location.search.length > 0) {
            const urlParams = new URLSearchParams(window.location.search)

            const keys = Object.keys(getters.getFiltered)
            for (const key of keys) {
                if (urlParams.get(key)) {
                    state.filtered = true
                }
            }
        }
        return state.filtered
    },
    getUrlPathnameArray: () => {
        const parsedUrl = new URL(window.location)
        let urlPath = parsedUrl.pathname
        if (urlPath === '') return []
        const splitUrl = urlPath.split('/')
        const newUrlArray = []
        splitUrl.map(el => {
            if (el !== ''){
                newUrlArray.push(el.trim())
            }
        })
        return newUrlArray
    },
    currentCategoryId: state => state.current_id,
    categories: state => state.categories,
    subcategories: state => state.subcategories,
    currentCategory: (state, getters, rootState, rootGetters) => {
        const category = _find(getters.categories, {id: getters.currentCategoryId})
        if (!category) {
            return {
                ...CATEGORY_INTERFACE,
                description: rootGetters.rootState.description,
                id: getters.currentCategoryId || rootGetters.rootState.current_id,
                image: rootGetters.rootState.hasOwnProperty('category_image') ? rootGetters.rootState.category_image.toString() : undefined,
                name: rootGetters.rootState.hasOwnProperty('category_name') ? rootGetters.rootState.category_name : ''
            }
        }
        return category || getters.parentCategory
    },
    parentCategory: state => state.parent_category,
    categoryService: state => state.categoryService,
    breadcrumbsHtml: state => state.breadcrumbsHtml,
    categoryImage: state => state.category_image,
    categoryMobileImage: state => state.category_image_mobile,
    getProducts: state => state.products,
    getCustomerId: state => state.customer_id
}

export const actions = {
    fetchDefaultData: ({commit, rootGetters}) => {
        const data = rootGetters.rootState || {}
        if (!_size(data.categoryService)) {
            data.categoryService = getCategoryService({base_url: rootGetters['storeView/base_url']})
        }
        commit('UPDATE_STATE_DATA', data)
    },
    fetchFilterFromUrl: ({getters, commit}) => {
        if (getters.hasFilters) {
            const categoriesArray = getters.getUrlPathnameArray
            if (categoriesArray.length >= 2) {
                const lastCategory = categoriesArray[categoriesArray.length - 1]
                commit('updatedFiltered', {key: 'category', info: [lastCategory]})
            }
            if (window.location.search.length > 0){
                const urlParams = new URLSearchParams(window.location.search)
                const keys = Object.keys(getters.getFiltered)
                for (const key of keys) {
                    if (urlParams.get(key)) {
                        const info = []
                        urlParams.get(key).split(',').forEach(element => {
                            const separate = element.split(' ')
                            let search = ''
                            separate.forEach(term => {
                                search = search.concat(term[0] + term.slice(1) + ' ')
                            })
                            info.push(search.trim())
                        })
                        commit('updatedFiltered', {key, info})
                    }
                }
            }
        }
    },
    fetchCategoryProducts: ({getters, commit, dispatch}, category = {}) => {
        commit('setLoader', true)

        const id = category.id ? category.id : getters['currentCategory'].id
        getters.categoryService.getProductsCollection(id)
            .then(({status, request}) => {
                if (status === 200) {
                    const response = JSON.parse(request.response)
                    const products = response.products

                    commit('updateCategoryProducts', {id, products})
                    commit('SET_PRODUCTS', products)

                    if (getters.hasFilters) {
                        dispatch('fetchFilterFromUrl', getters.getFiltered)
                        dispatch('fetchFilteredProducts', getters.getFiltered)
                    }
                }
            })
            .catch(error => console.error(error))
            .finally(() => commit('setLoader', false))
    },
    fetchFilteredProducts: ({getters, commit, rootGetters}, filters = {}) => {
        const products = getters.currentCategory.products
        const category = Object.keys(products)[0]
        const filtered = []
        const materials = []
        const size = []
        const type = []
        let allProducts = products[category]
        let url = getters.currentCategory.url + '?'

        // category
        if (filters.category.length > 0) {
            filters.category.forEach(element => {
                allProducts.filter(function (product) {
                    if (element === 'All') {
                        filtered.push(products)
                    } else if (element === product.category) {
                        filtered.push(product)
                    }
                })
            })

            allProducts = filtered
        }

        // material
        if (filters.material.length > 0) {
            filters.material.forEach(element => {
                allProducts.filter(function (product) {
                    if (product.material.includes(element)) {
                        materials.push(product)
                    }
                })
            })
            allProducts = materials
        }

        // frame size
        if (filters.frame_size.length > 0) {
            filters.frame_size.forEach(element => {
                allProducts.filter(function (product) {
                    if (product.frame_size.includes(element)) {
                        size.push(product)
                    }
                })
            })
            allProducts = size
        }

        // lens type
        if (filters.lens_type.length > 0) {
            filters.lens_type.forEach(element => {
                allProducts.filter(function (product) {
                    if (element === product.lens_type) {
                        type.push(product)
                    }
                })
            })
            allProducts = type
        }
        const keys = Object.keys(filters)
        for (const key of keys) {
            if (filters[key].length) {
                const part = key + '=' + filters[key] + '&'
                url = url.concat(part)
            }
        }

        commit('updateAllProducts', {allProducts})

        history.pushState({}, null, url.substring(0, url.length - 1))
    },
    resetFilters: ({state, commit}) => {
        const info = []
        const keys = Object.keys(state.filter)
        for (const key of keys) {
            commit('updatedFiltered', {key, info})
        }
    }
}

export const mutations = {
    ...abstract.mutations,
    setCurrentCategoryId: (state, id = '') => state.current_id = id,
    setLoader: (state, flag = false) => state.loader = flag,
    updateCategoryProducts: (state, {id, products}) => {
        const category = _find(state.categories, {id: id}) || {}
        Vue.set(category, 'products', products)
    },
    // Look at this post deployment if it doesn't work comma, or curly bracket
    SET_PRODUCTS: (state, products = []) => state.products = products,
    updateAllProducts: (state, products) => state.allProducts = products,
    updatedFiltered: (state, {key, info}) => state.filter[key] = info,
    UPDATE_FRAME_SIZE: (state, frame_size) => state.filter['frame_size'] = frame_size
}

export default () => ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
})
