import {
    PRESCRIPTION_TYPE_UPLOAD,
    PRESCRIPTION_TYPE_MANUAL,
    PRESCRIPTION_TYPE_DOCTOR_DETAILS
} from '../../../../../app/utils/constants'

export default function getContactsStepList () {
    const step_list = [
        {
            'id': 'prescription',
            'label': 'Provide your Prescription',
            'value_renderer': 'renderer-options',
            'progress_label': 'Provide Rx',
            'default_value': PRESCRIPTION_TYPE_MANUAL,
            'step_description': false,
            'component': {
                'name': 'selector-options',
                'options': [
                    {
                        'id': PRESCRIPTION_TYPE_MANUAL,
                        'label': 'Enter Your Prescription',
                        'summary_label': 'Enter Your Prescription',
                        'description': '',
                        'continue_promise': 'validateManualRx',
                        'component_extra_options': {
                            'name': 'manual-prescription'
                        }
                    }
                ]
            }
        },
        {
            'id': 'verify',
            'progress_label': 'Verify your prescription',
            'label': 'Verify your prescription',
            'step_description': 'We need to confirm your contacts prescription in order to ship your product. Choose from one of the options below.',
            'summary_label': 'Provide Rx',
            'value_renderer': 'renderer-options',
            'default_value': PRESCRIPTION_TYPE_UPLOAD,
            'component': {
                'name': 'selector-options',
                'options': [
                    {
                        'id': PRESCRIPTION_TYPE_UPLOAD,
                        'label': 'Upload Prescription',
                        'summary_label': 'Upload Prescription',
                        'description': '',
                        'continue_promise': 'validateUpload',
                        'component_extra_options': {
                            'name': 'prescription-upload'
                        }
                    },
                    {
                        'id': PRESCRIPTION_TYPE_DOCTOR_DETAILS,
                        'label': 'Call My Doctor',
                        'summary_label': 'Contact Doctor',
                        'description': '',
                        'value_renderer': 'renderer-options',
                        'continue_promise': 'validateDoctorDetails',
                        'component_extra_options': {
                            'name': 'doctor-details'
                        }
                    }
                ]
            }
        },
        {
            'id': 'prescription_owner',
            'label': 'Who is this order for?',
            'hide_for_redeem': true,
            'summary_label': 'Who is this order for?',
            'progress_label': 'Who is this order for?',
            'value_renderer': 'renderer-options',
            'continue_promise': 'validateOwner',
            'component': {
                'name': 'prescription-owner'
            },
            'component_help': {
                'name': 'help-bubble',
                'params': {
                    'text': 'Who is this for?'
                }
            }
        },
        {
            'id': 'prescription_type',
            'default_value': 'contacts'
        },
        {
            'id': 'lens',
            'default_value': 'prescription'
        }
        // {
        //   'id': 'lens_type',
        //   'default_value': ''
        // },
        // {
        //   'id': 'lens_options',
        //   'default_value': ''
        // },
        // {
        //   'id': 'anti_reflective',
        //   'default_value': false
        // },

    ]
    return step_list
}
