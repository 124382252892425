<template>
    <div class="anagram-ad-container">
        <section class="anagram-ad-img-section">
            <img src="/media/wysiwyg/davis-vision-logo-white.png" class="anagram-img davis-vision-img" />
            <img src="/media/wysiwyg/eye-med-logo-white.png" class="anagram-img eye-med-img" />
            <img src="/media/wysiwyg/spectera-logo-white.png" class="anagram-img spectera-img" />
            <img src="/media/wysiwyg/superior-vision-logo-white.png" class="anagram-img superior-vision-img" />
            <img src="/media/wysiwyg/vsp-logo-white.png" class="anagram-img vsp-img" />
        </section>
        <div class="c-product-lplus-ad-block anagram-ad-info-section">
            <p>Automatically claim up to $150 in out-of-network reimbursement
                <span class="lplus-ad-block_container" @click="openPlusAdModal">
                    <span class="c-product-lplus-ad-link anagram-tooltip">Learn More</span>
                    <div class="help-bubble-container lplus-help-bubble-container">
                        <div class="help-component">
                            <div id="help_bubble_text" :class="showModalAd ? 'open' : ''" class="help-bubble lplus-help-bubble">
                                <p><b>How to claim your out-of-network benefit</b></p>
                                <ol class="custom-counter">
                                    <li>Add products to your cart.</li>
                                    <li>Complete checkout.</li>
                                    <li>Fill out form on confirmation page.</li>
                                    <li>Automatically claim up to $150 in out-of-network reimbursements.</li>
                                </ol>
                                <span class="close-button" @click.stop="closeModal">
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                                        <path id="svg_1" d="m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z" fill="rgb(102, 102, 102)" fill-rule="evenodd" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnagramAdBar',
    data: () => ({
        showModalAd: false,
    }),
    methods: {
        openPlusAdModal () {
            if (window.innerWidth < 768) {
                this.showModalAd = true
            } else {
                this.showModalAd = false
            }
        },
        closeModal () {
            this.showModalAd = false;
        }
    }
}
</script>
