import axios from 'axios'

let cartService = null

class CartService {
    constructor ({base_url}) {
        this.base_url = base_url
    }

    addProduct (data) {
        const url = this.url()
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    addContactsProduct (data) {
        const url = this.base_url + 'contact-lenses/ajax/addProduct'
        return axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    getInfo () {
        const url = this.url()
        return axios.get(url)
    }

    url () {
        const path = 'lensabl/cart'
        return this.base_url + path
    }
}

export default function getCartService (options = {}) {
    if (!cartService) {
        cartService = new CartService(options)
    }
    return cartService
}
