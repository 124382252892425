import {
    updateStateData
} from '../app/utils/buildData'
import _cloneDeep from 'lodash/cloneDeep'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'

export const state = () => ({
    api: {
        urls: {}
    }
})

export const mutations = {
    UPDATE_STATE_DATA: (state, data = {}) => updateStateData(state, data)
}

export const actions = {
    fetchDefaultDataBefore: ({}, defaultData = {}) => {},
    fetchDefaultDataAfter: ({}, defaultData = {}) => {},
    fetchDefaultData: ({state, commit, dispatch, rootGetters}, defaultData = {}) => {
        dispatch('fetchDefaultDataBefore', defaultData)

        const data = Object.assign({}, {api: {urls: {}}}, rootGetters.rootState || {}, defaultData)
        const adminHtml = data.hasOwnProperty('adminHtml') ? data.adminHtml : false
        const dataUrls = data.api.urls
        const stateUrls = Object.assign({}, state.api.urls)
        const urls = {}

        for (const key in stateUrls) {
            if (dataUrls.hasOwnProperty(key) && dataUrls[key]) {
                urls[key] = dataUrls[key]
            } else {
                urls[key] = stateUrls[key]
            }
            if (urls[key].startsWith('http') || adminHtml) continue

            const url = (urls[key] || '').replace(/^\/+/, '')
            urls[key] = `${rootGetters['storeView/base_url']}${url}`
        }

        commit('UPDATE_STATE_DATA', {
            ...data,
            api: {
                urls: {
                    ...urls
                }
            }
        })

        dispatch('fetchDefaultDataAfter', defaultData)
    }
}
