<template>
    <section class="home-insurance-block" style="text-align: center">
        <div v-if="showModal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header">
                            <a class="modal-close-button" @click="stateModal"></a>
                        </div>
                        <div class="modal-body">
                            <slot name="body">
                                <img src="/js/lensconfigurator/assets/lineo-logo.png" width="250px"/>
                                <div class="input-container">
                                    <label>FIRST NAME:</label>
                                    <input v-model="linneo_member.firstName" type="text"
                                           @blur="validBlurField('firstName')"/>
                                    <div v-if="errors.firstName" class="validation-advice" style="">This is a required
                                        field.
                                    </div>
                                </div>
                                <div class="input-container">
                                    <label>LAST NAME:</label>
                                    <input v-model="linneo_member.lastName" type="text"
                                           @blur="validBlurField('lastName')"/>
                                    <div v-if="errors.lastName" class="validation-advice" style="">This is a required
                                        field.
                                    </div>
                                </div>
                                <div class="input-container">
                                    <label>DATE OF BIRTH:</label>
                                    <input v-model="linneo_member.dateOfBirth" type="date"
                                           @blur="validBlurField('dateOfBirth')"/>
                                    <div v-if="errors.dateOfBirth" class="validation-advice" style="">This is a required
                                        field.
                                    </div>
                                </div>
                                <div class="input-container">
                                    <label>ZIP CODE:</label>
                                    <input v-model="linneo_member.zip" type="text" @blur="validBlurField('zip')"/>
                                    <div v-if="errors.zip" class="validation-advice" style="">This is a required
                                        field.
                                    </div>
                                </div>
                                <div v-if="additionalInfo">
                                    <div class="input-container">
                                        <label>SUBSCRIBED ID:</label>
                                        <input v-model="linneo_member_addition_info.subscriberId" type="text"
                                               @blur="validBlurField('subscriberId', 'linneo_member_addition_info')"/>
                                        <div v-if="errors.subscriberId" class="validation-advice" style="">This is a
                                            required field.
                                        </div>
                                    </div>
                                    <div class="input-container">
                                        <label>MEMBER ID:</label>
                                        <input v-model="linneo_member_addition_info.memberId" type="text"
                                               @blur="validBlurField('memberId', 'linneo_member_addition_info')"/>
                                        <div v-if="errors.memberId" class="validation-advice" style="">This is a
                                            required field.
                                        </div>
                                    </div>
                                </div>
                            </slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer">
                                <div v-if="showError" :class="{'validation-advice' : showError}"
                                     class="linneo-response">
                                    {{ getResponseData.message }}
                                </div>
                                <div v-else class="linneo-response">
                                    <div><span>Order Total</span><span>${{ toFixedNumber(getData.order_total) }}</span>
                                    </div>
                                    <div><span>Benefit Total</span><span>-${{ toFixedNumber(getData.benefits) }}</span>
                                    </div>
                                    <div><span>Total Remaining</span><span>${{ toFixedNumber(totalRemaining) }}</span>
                                    </div>
                                </div>
                                <BaseButton text="LOGIN" :disabled="disabledButton" :loader="loadButton"
                                            @click.native="loginLinneo"/>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="linneo-desc-container">
            <p class="linneo-desc" style="color: #8d8d8d;">Lensabl now accepts Linneo insurance payments online!
                Checkout with your Linneo credentials below to start using your benefits.</p>
            <BaseButton text="Checkout with Linneo" class="checkout-button-linneo" @click.native="stateModal"/>
        </div>
    </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import storageInsurance from './storage/insurance'
import BaseButton from '../base/BaseButton.vue'
import {validationMixin} from 'vuelidate'
import {required, maxLength} from 'vuelidate/lib/validators'
import _formatPrice from '../../app/utils/formatPrice'

export default {
    name: 'LinneoProvider',
    components: {
        BaseButton,
    },
    mixins: [validationMixin],
    data: () => {
        return {
            showModal: false,
            linneo_member: {
                firstName: null,
                lastName: null,
                zip: null,
                dateOfBirth: null
            },
            linneo_member_addition_info: {
                memberId: null,
                subscriberId: null
            },
            errors: {
                memberId: false,
                subscriberId: false,
                dateOfBirth: false,
                firstName: false,
                lastName: false,
                zip: false
            },
            disabledButton: false,
            loadButton: false,
            addInfo: false
        }
    },
    validations: {
        linneo_member: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            dateOfBirth: {
                required
            },
            zip: {
                required,
                maxLength: maxLength(5)
            }
        },
        linneo_member_addition_info: {
            memberId: {
                required
            },
            subscriberId: {
                required
            }
        }
    },
    computed: {
        ...mapGetters('insurance', ['getResponseData']),
        showError() {
            const successRequest = (this.getResponseData.success === 'true')
            if (successRequest) {
                return false
            }
            return true
        },
        getData() {
            return this.getResponseData.data || []
        },
        additionalInfo() {
            return this.getAdditionalInfo()
        },
        totalRemaining() {
            return (this.getData.order_total - this.getData.benefits)
        }
    },
    watch: {
        errors: {
            handler(val) {
                this.disabledButton = false
                if (Object.values(val).indexOf(true) !== -1) {
                    this.disabledButton = true
                }
            },
            deep: true
        },
        showModal(val) {
            if (val === false) {
                this.resetData()
            }
        }
    },
    created() {
        this.resetInsuranceState()
        this.fetchProviders()
    },
    methods: {
        ...mapActions('insurance', ['resetInsuranceState', 'sendToLinneo', 'fetchProviders']),
        resetData() {
            Object.assign(this.$data, this.$options.data.apply(this))
            this.resetInsuranceState()
            this.fetchProviders()
        },
        validBlurField(val, key = 'linneo_member') {
            if (!this.$v[key][val]) {
                return
            }
            this.errors[val] = this.$v[key][val].$invalid
        },
        stateModal() {
            this.showModal = !this.showModal
        },
        loginLinneo() {
            for (const key in this.linneo_member) {
                this.validBlurField(key)
            }
            if (this.additionalInfo) {
                for (const key in this.linneo_member_addition_info) {
                    this.validBlurField(key, 'linneo_member_addition_info')
                }
            }
            this.$nextTick(() => {
                if (!this.disabledButton) {
                    this.loadButton = true
                    if (this.additionalInfo) {
                        for (const [key, val] of Object.entries(this.linneo_member_addition_info)) {
                            this.linneo_member[key] = val
                        }
                    }
                    this.sendToLinneo(this.linneo_member).finally(() => this.loadButton = false)
                }
            })
        },
        toFixedNumber(original) {
            return _formatPrice(original)
        },
        getAdditionalInfo() {
            if (!this.addInfo) {
                this.addInfo = this.getData.additionalInfo || false
            }
            return this.addInfo
        }
    }
}
</script>
<style lang="less" scoped>
.linneo-response {
    margin-bottom: 1.5em;

    div {
        display: flex;
        justify-content: space-between;
        line-height: 1;

        &:nth-child(2n-2) {
            margin-bottom: 1em;
        }

        &:not(:last-child) {
            color: #8D8D8D;
        }

        span:first-child {
            font-weight: bold;
        }
    }
}
</style>
