var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "anagram-ad-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "c-product-lplus-ad-block anagram-ad-info-section" },
      [
        _c("p", [
          _vm._v(
            "Automatically claim up to $150 in out-of-network reimbursement\n            "
          ),
          _c(
            "span",
            {
              staticClass: "lplus-ad-block_container",
              on: { click: _vm.openPlusAdModal }
            },
            [
              _c(
                "span",
                { staticClass: "c-product-lplus-ad-link anagram-tooltip" },
                [_vm._v("Learn More")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "help-bubble-container lplus-help-bubble-container"
                },
                [
                  _c("div", { staticClass: "help-component" }, [
                    _c(
                      "div",
                      {
                        staticClass: "help-bubble lplus-help-bubble",
                        class: _vm.showModalAd ? "open" : "",
                        attrs: { id: "help_bubble_text" }
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "close-button",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.closeModal($event)
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "18",
                                  height: "18",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "svg_1",
                                    d:
                                      "m3,5c0,0 4,4 4,4c0,0 -4,4 -4,4c0,0 2,2 2,2c0,0 4,-4 4,-4c0,0 4,4 4,4c0,0 2,-2 2,-2c0,0 -4,-4 -4,-4c0,0 4,-4 4,-4c0,0 -2,-2 -2,-2c0,0 -4,4 -4,4c0,0 -4,-4 -4,-4c0,0 -2,2 -2,2z",
                                    fill: "rgb(102, 102, 102)",
                                    "fill-rule": "evenodd"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "anagram-ad-img-section" }, [
      _c("img", {
        staticClass: "anagram-img davis-vision-img",
        attrs: { src: "/media/wysiwyg/davis-vision-logo-white.png" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "anagram-img eye-med-img",
        attrs: { src: "/media/wysiwyg/eye-med-logo-white.png" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "anagram-img spectera-img",
        attrs: { src: "/media/wysiwyg/spectera-logo-white.png" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "anagram-img superior-vision-img",
        attrs: { src: "/media/wysiwyg/superior-vision-logo-white.png" }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "anagram-img vsp-img",
        attrs: { src: "/media/wysiwyg/vsp-logo-white.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("How to claim your out-of-network benefit")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "custom-counter" }, [
      _c("li", [_vm._v("Add products to your cart.")]),
      _vm._v(" "),
      _c("li", [_vm._v("Complete checkout.")]),
      _vm._v(" "),
      _c("li", [_vm._v("Fill out form on confirmation page.")]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Automatically claim up to $150 in out-of-network reimbursements."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }