<template>
    <CatalogProductGamerAdvantageView v-if="statusApi" />
</template>

<script>
import App from '../../../App.vue'
import CatalogProductGamerAdvantageView from '../../../components/catalog/product/CatalogProductGamerAdvantageView'

export default {
    name: 'RouterCatalogProductGamerAdvantage',
    components: {
        CatalogProductGamerAdvantageView
    },
    extends: App
}
</script>
