<template>
    <div class="contacts_rx-dropdown-container">
        <div :class="{active: value}" class="contacts_rx-dropdown-value" @click="updateDropdown(index, $event)">
            <label v-if="value" class="contacts_rx-dropdown-label">{{ placeholder }}</label>
            <span v-if="type === 'power' && value > 0">+</span>{{ value || placeholder }}
        </div>
        <div class="contacts_rx-dropdown-list">
            <div v-if="type === 'power' && hasPlusMinusValues" class="contacts_rx-dropdown-sign">
                <div class="contacts_rx-dropdown-sign--item">
                    -
                </div>
                <div class="contacts_rx-dropdown-sign--item">
                    +
                </div>
            </div>
            <div class="contacts_rx-dropdown-wrapper">
                <template v-for="option in values(index)">
                    <div v-if="(option === 0)" :key="`0-${option}`" class="contacts_rx-dropdown-item" @click="updateRxValue(option, index, type, $event)">0.00</div>
                </template>
                <div class="contacts_rx-dropdown-row">
                    <div v-if="type === 'power' && hasPlusMinusValues" class="contacts_rx-dropdown-two-col">
                        <template v-for="(option, i) in values(index)">
                            <div
                                v-if="option < 0"
                                :key="`power-${i}-${option}`"
                                :class="{disabled: !checkAvailable(option)}"
                                class="contacts_rx-dropdown-item"
                                @click="updateRxValue(option, index, type, $event)"
                            >
                                {{ option }}
                            </div>
                        </template>
                    </div>
                    <div v-if="type === 'power' && hasPlusMinusValues" class="contacts_rx-dropdown-two-col">
                        <template v-for="(option, i) in values(index)">
                            <div
                                v-if="option > 0"
                                :key="`power-${i}-${option}`"
                                :class="{disabled: !checkAvailable(option)}"
                                class="contacts_rx-dropdown-item"
                                @click="updateRxValue(option, index, type, $event)"
                            >
                                +{{ option }}
                            </div>
                        </template>
                    </div>
                </div>
                <template v-if="type === 'power' && !hasPlusMinusValues">
                    <template v-for="(option, i) in values(index)">
                        <div
                            :key="`power-${i}-${option}`"
                            :class="{disabled: !checkAvailable(option)}"
                            class="contacts_rx-dropdown-item"
                            @click="updateRxValue(option, index, type, $event)"
                        >
                            {{ option }}
                        </div>
                    </template>
                </template>
                <template v-for="(option, i) in values(index)">
                    <div
                        v-if="type !== 'power'"
                        :key="`notpower-${i}`"
                        :class="{disabled: !checkAvailable(option)}"
                        class="contacts_rx-dropdown-item"
                        @click="updateRxValue(option, index, type, $event)"
                    >
                        {{ option }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import _size from 'lodash/size'

export default {
    name: 'PrescriptionDropdown',
    props: {
        index: {
            type: [String, Number],
            default: ''
        },
        values: {
            type: [Object, Array, Function],
            default: () => []
        },
        value: {
            type: [String, Number, Object, Array, Boolean],
            default: () => ''
        },
        products: {
            type: [Object, Array],
            default: () => {}
        },
        availableValues: {
            type: [Array],
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            hasPlusMinusValues: true
        }
    },
    mounted () {
        this.toggleDropdowns()
        this.checkPowerValues()
    },
    methods: {
        checkAvailable (value) {
            const available = true
            if (this.availableValues && _size(this.availableValues)) {
                return this.availableValues.includes(parseFloat(value))
            }
            return available
        },
        checkPowerValues () {
            const power = this.values(this.index)
            const hasPositiveValues = power ? power.some(x => x > 1) : false
            const hasNegativeValues = power ? power.some(x => x < 1) : false
            if (hasPositiveValues && hasNegativeValues) {
                this.hasPlusMinusValues = true
            } else {
                this.hasPlusMinusValues = false
            }
        },
        updateRxValue (value, index, rx_type, event) {
            if (rx_type === 'color') {
                this.$store.commit('contacts/SET_EYE_DATA', {
                    eye: index,
                    [rx_type]: value
                })
            } else {
                this.$store.commit('contacts/SET_EYE_RX_CHECKED', {
                    eye: index,
                    rx: {
                        [rx_type]: value
                    }
                })
            }
            event.target.offsetParent.classList.remove('active')
        },
        updateDropdown (index, event) {
            if (event.target.nextElementSibling) {
                event.target.nextElementSibling.classList.toggle('active')
            }
        },
        toggleDropdowns () {
            const dropdown = document.querySelectorAll('.contacts_rx-dropdown-value')
            dropdown.forEach((element) => {
                element.addEventListener('click', (event) => {
                    const dropdowns = [...document.querySelectorAll('.contacts_rx-dropdown-list')]
                    const otherElements = dropdowns.filter(e => {
                        return event.target.nextElementSibling !== e
                    })
                    otherElements.forEach(item => {
                        item.classList.remove('active')
                    })
                })
            })
        }
    }
}
</script>
