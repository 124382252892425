<template>
    <BlockLoader class="nav__account_links" :class="{open: dropdownOpen}" :loading="loading">
        <div class="nav__account_links-box">
            <a v-if="!loggedIn" :href="signInUrl" class="nav__sign-in">Sign in</a>
            <div v-if="loggedIn" class="nav__sign-in" @click="dropdownOpen = !dropdownOpen">
                <img v-if="plusMember" :src="`${assets_url}lplus-icon.svg`" width="40" />
                <span v-if="plusMember">
                    <span class="navbar-lplus-name" v-text="customerFullName"></span>
                    <span class="navbar-lplus">Lensabl+ Member</span>
                </span>
                <span v-else>My Account</span>

                <div class="nav__icon-dropdown" :class="{'lplus-dropdown': plusMember, open: dropdownOpen}">
                    <svg>
                        <use xlink:href="#lensabl-arrow-small-down" />
                    </svg>
                </div>
            </div>
            <div v-if="loggedIn" class="nav__sub-nav-container nav__right-dropdown" :class="{'lplus-dropdown_container': plusMember, open: dropdownOpen}">
                <ul>
                    <li v-for="(link, index) in dropdownLinks" :key="index">
                        <a :href="link.path" v-text="link.name"></a>
                    </li>
                    <li v-if="businessDependents.length" class="account-dropdown_wrapper">
                        <span class="account-label">SWITCH ACCOUNT:</span>
                        <div class="account-dropdown_container">
                            <div class="account-dropdown">
                                <span class="account-name" v-text="customerFullName"></span>
                                <img class="account-arrow-down" :src="`${assets_url}arrow-down.svg`" />
                            </div>
                            <div class="results-dropdown">
                                <a
                                    v-for="(dependent, i) in businessDependents"
                                    :key="`${dependent.name}-${i}`"
                                    :href="customerFullName === dependent.name && businessEmployee ? businessEmployee.url : dependent.url"
                                >
                                    <span class="account-holder">
                                        {{ customerFullName === dependent.name && businessEmployee ? businessEmployee.name : dependent.name }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </BlockLoader>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import BlockLoader from '../../base/BlockLoader.vue'
import lensablplusBusiness from '../../lensablplus/storage/lensablplus/business'

export default {
    name: 'CustomerHeaderLinks',
    components: {
        BlockLoader
    },
    data: () => ({
        dropdownOpen: false,
        loading: false,
        signInUrl: '/customer/account/login',
    }),
    computed: {
        ...mapGetters('storeView', ['assets_url', 'dropdownLinks']),
        ...mapState('lensablplus_customer', ['plusMember', 'customerFullName', 'businessDependents', 'businessEmployee']),
        ...mapGetters('lensablplus_customer', ['loggedIn']),
        ...mapGetters('lensablplus_business', ['getLoad', 'planSelected'])
    },
    beforeCreate () {
        if (!this.$store._modulesNamespaceMap['lensablplus_business/']) {
            this.$store.registerModule('lensablplus_business', lensablplusBusiness(this.$store))
        }
        this.$store.dispatch('lensablplus_business/fetchDefaultData')
        this.$store.dispatch('membershipProgram/membershipDiscountData')
        this.$store.dispatch('membershipProgram/hasCustomerMembership')
    },
    created: async function () {
        this.loading = true
        this.$store.commit('lensablplus_customer/ACCOUNT_DATA_LOADING')
        await this.$store.dispatch('lensablplus_customer/getMembershipInfo')
        await this.$store.dispatch('lensablplus_customer/fetchData')
        this.loading = false
    }
}
</script>
