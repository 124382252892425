var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showForm && _vm.prescriptionsType
    ? _c("div", { staticClass: "my-prescription-form" }, [
        _c("div", { staticClass: "my-prescription-form-wrapper" }, [
          _c(
            "form",
            {
              attrs: {
                id: "data_order_form" + _vm.index,
                name: "data-order-form" + _vm.index
              }
            },
            [
              _c(_vm.prescriptionsType + "-options", {
                tag: "component",
                class: [_vm.prescriptionsType + "-list"],
                attrs: {
                  item: _vm.orderItem,
                  checked: _vm.checked,
                  "prescriptions-type": _vm.prescriptionsType,
                  "prescription-index": _vm.prescriptionIndex
                },
                on: {
                  "hide-form": _vm.hideForm,
                  checked: _vm.selectOption,
                  "open-fitting-box": _vm.openFittingBox
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "actions-toolbar" }, [
                _vm.orderSaveError
                  ? _c("div", { staticClass: "primary" }, [
                      _c("p", { staticClass: "error-text-rx" }, [
                        _vm._v("Please choose options!")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "secondary" },
                  [
                    _c("BaseButton", {
                      staticClass:
                        "button--copy-solid-white button--copy-border-sm",
                      attrs: { text: "Save", "data-size": "xs" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.save($event)
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.orderIsSentToTheServer
          ? _c("div", { staticClass: "loader-container" }, [
              _c("div", { staticClass: "loader-el" })
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }