<template>
    <div v-if="isStepCurrent">
        <div v-if="step.step_description" class="step-description">{{ step.step_description }}</div>
        <div v-else-if="!step.step_description" class="step-description"></div>
        <div v-else class="step-description">Choose one from the options below.</div>
        <div class="options_container">
            <ul class="options">
                <template v-for="option in options">
                    <Option :id="id" :key="option.id" :component-help-show="isMobile" :option="option" class="step-configuration-content" @option-select="optionSelect" />
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
import _first from 'lodash/first'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _cloneDeep from 'lodash/cloneDeep'
import {
    DISABLED_LENS,
    STEP_ANTIREFLECTIVE
} from '../../../app/common/constants/antireflective'
import {
    PRESCRIPTION_TYPE_ONLINE,
    EVENT_OPTION_BACK_CLICK,
    EVENT_SWITCH_OPTION,
    EVENT_STEP_UPDATE
} from '../../../app/utils/constants'

import {mapGetters, mapActions} from 'vuex'
import PreselectService from '../../../app/mixins/preselect-service'
import Option from './Option.vue'

export default {
    name: 'SelectorOptions',
    components: {
        Option
    },
    mixins: [PreselectService],
    props: {
        id: { // Step Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        value: false,
        options: [],
        defaultOption: {
            'component_extra_options': false,
            'price': false,
            'small_description': false
        }
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('configurator/steps', ['hiddenSteps', 'getStepObject', 'getLimitersDisabled', 'getLimiterDisabledBySku', 'getLimitersEnabledByColor',
            'getLimitersEnabledOptionsByLensType', 'getLimitersEnabled', 'getLimitersEnabledOptions',]),
        ...mapGetters('values', ['values', 'framesPartnerPage', 'productType', 'lensType']),
        step () {
            return this.getStepObject(this.id)
        },
        currentOption () {
            return _find(this.options, option => option.id === this.step.value)
        },
        currentOptionId () {
            const {id = ''} = this.currentOption || {}
            return id ? id.split('_').join('-') : ''
        },
        selectedOptionHasExtraOptions () {
            if (!this.currentOption) {
                return false
            }
            return this.currentOption.component_extra_options
        },
        isStepCurrent () {
            return this.step.isCurrent
        },
        limitersEnabled () {
            return this.getLimitersEnabled(this.id)
        },
        limitersDisabled () {
            return this.getLimitersDisabled(this.id)
        }
    },
    watch: {
        value (value, oldValue) {
            this._updateValue(value, value !== oldValue)
        },
        isStepCurrent (current) {
            if (current) {
                this.optionsUpdate()
            }
        }
    },
    created () {
        this.optionsUpdate()
        this.$bus.$on(EVENT_OPTION_BACK_CLICK, this._optionBackClick)
        this.$bus.$on(EVENT_SWITCH_OPTION, this._switchOption)

        this._setPreSelectOptions(this.options)
    },
    beforeDestroy () {
        this.$bus.$off(EVENT_OPTION_BACK_CLICK, this._optionBackClick)
        this.$bus.$off(EVENT_SWITCH_OPTION, this._switchOption)
    },
    methods: {
        ...mapActions('configurator/steps', ['updateVisibleList', 'updateStep']),
        updateStepData (data = {}) {
            this.updateStep({
                id: this.id,
                data
            })
        },
        optionsUpdate () {
            this.options = _map(_cloneDeep(this.step.options), option => Object.assign({}, this.defaultOption, option)) || {}

            for (const option of this.options) {
                if (option.component_extra_options) {
                    if (!option.component_extra_options.params) {
                        this.$set(option.component_extra_options, 'params', {})
                    }
                    this.$set(option.component_extra_options.params, 'value', false)
                }
                if (this.framesPartnerPage && this.framesPartnerPage !== 'spectacles') {
                    option.thumbnail = false
                }
            }

            this.updateStepData({options: this.options})

            this._initValue(this.step.value)

            if (this.step.isHidden) {
                this.value = this.step.defaultValue
                this.$parent.next()
            } else if (this.options.length === 1) {
                this.value = this.step.defaultValue
                this.updateStepData({showNextStepButton: true})
            }

            return this.options
        },
        isActive (option) {
            return option.id === this.step.value
        },
        isParentActive(id){
            return false
        },
        optionSelect (option = {}) {
            const value = Object.keys(option).length ? option.id || option : ''
            if (!_isEqual(this.step.value, value)) {
                if (_isEqual(this.value, value)) {
                    this._updateValue(value, true)
                } else {
                    this.value = value
                }
            }

            if (!option.component_extra_options || !option.component_extra_options.name) {
                this.updateStepData({disabled: false})
            }
        },
        beforeActive (option) {
            const nextOptionId = this.getNextOptionId(option)
            if (nextOptionId) {
                return nextOptionId === this.step.value
            }
            return false
        },
        afterActive (option) {
            const prevOptionId = this.getPrevOptionId(option)
            if (prevOptionId) {
                return prevOptionId === this.step.value
            }
            return false
        },
        getNextOptionId (option) {
            return this.getOptionIdByOffset(option, 1)
        },
        getPrevOptionId (option) {
            return this.getOptionIdByOffset(option, -1)
        },
        getOptionIdByOffset (option, offset) {
            const id = option.id ? option.id : option
            const ids = _map(this.step.options, option => option.id)
            let index = ids.indexOf(id)
            if (index === -1) {
                return false
            }
            index = index + offset
            return ids[index] || false
        },
        _initValue (value = false) {
            if (value) {
                this.value = value
            }

            if (!this.options || !this.options.length) {
                return true
            }

            if (!this.currentOption) {
                this.value = false
            }
            if (!this.value && this.options) {
                this.value = this.step.defaultValue || _first(this.options).id
            }
        },
        _checkIfWeNeedToShowContinueButton () {
            if (!this.step.showNextStepButton) {
                return
            }

            const option = this.currentOption
            if (!option) {
                return
            }
            this.updateStepData({showNextStepButton: option.component_extra_options})
        },
        _changeStepVisible () {
            const values = this.values
            const {lens, lens_type} = values

            const hiddenSteps = this.hiddenSteps

            const indexStep = hiddenSteps.indexOf(STEP_ANTIREFLECTIVE)

            if (indexStep !== -1) {
                hiddenSteps.splice(indexStep, 1)
                this.$store.commit('HIDDEN_STEPS', hiddenSteps)
            }

            const disabledLensType = DISABLED_LENS[lens] ? DISABLED_LENS[lens] : false
            if (!disabledLensType) {
                return
            }

            const canHiddenStep = disabledLensType.indexOf(lens_type) !== -1
            if (!canHiddenStep) {
                return
            }

            if (hiddenSteps.indexOf(STEP_ANTIREFLECTIVE) === -1) {
                hiddenSteps.push(STEP_ANTIREFLECTIVE)
                this.$store.commit('HIDDEN_STEPS', hiddenSteps)
            }
        },
        _setPreSelectOptions (options) {
            const pre_selectRx = this.getPreSelectValue
            if (pre_selectRx && pre_selectRx.lens) {
                _map(options, value => {
                    if (value.id === pre_selectRx.lens) {
                        this.optionSelect(value)
                        this.triggerClickPreSelect()
                    } else if (pre_selectRx.prescription_type && value.id === pre_selectRx.prescription_type) {
                        this.optionSelect(value)
                        this.triggerClickPreSelect()
                    } else if (value.id === PRESCRIPTION_TYPE_ONLINE) {
                        this.optionSelect(value)
                    }
                })
            }
        },
        _optionBackClick (stepId) {
            if (this.id === stepId) {
                this.value = false
            }
        },
        _switchOption () {
            this.$bus.$emit(EVENT_STEP_UPDATE, this.id)
            this.$store.commit('setValidation', false)
        },
        _updateValue (value, update = false) {
            if (update || !_isEqual(this.step.value, value)) {
                this.updateStepData({value: value})
                this.$store.commit('values/setDataToValues', {[this.id]: value})
                this._checkIfWeNeedToShowContinueButton()
                this._changeStepVisible()
                this.updateVisibleList()
                this.$bus.$emit(EVENT_SWITCH_OPTION)
            }
        }
    }
}
</script>
