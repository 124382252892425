<template>
    <CategoryLensabl v-if="statusApi" />
</template>

<script>
import App from '../../../../App.vue'
import CategoryLensabl from '../../../../components/catalog/category/lensabl/CategoryLensabl.vue'

export default {
    name: 'RouterCatalogCategoryLensablView',
    components: {
        CategoryLensabl
    },
    extends: App
}
</script>
