var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.prescriptionKey
    ? _c(
        "div",
        [
          !_vm.prescriptionData.status_4
            ? _c("RadioLabel", {
                attrs: {
                  active: _vm.isActive,
                  label: _vm.title,
                  value: _vm.index
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("checked", _vm.index)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isActive
            ? _c(
                "div",
                { staticClass: "my-prescription-form__pupillary-distance" },
                [
                  _c("div", { staticClass: "two-pupillary-numbers" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value,
                          expression: "value"
                        }
                      ],
                      staticClass: "prescription-prism-input",
                      domProps: { value: _vm.value },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.value = $event.target.value
                        }
                      }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.prescriptionData.status_4
            ? _c(
                "div",
                { staticClass: "my-prescription-form__pupillary-distance" },
                [
                  _c("div", { staticClass: "two-pupillary-numbers" }, [
                    _c("p", [
                      _vm._v("SH: "),
                      _c("span", {
                        staticClass: "prescription-prism-input",
                        domProps: { textContent: _vm._s(_vm.value) }
                      })
                    ])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }