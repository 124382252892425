<template>
    <div>
        <table cellspacing="0" class="form-list">
            <tbody>
                <tr>
                    <td class="label">
                        <label>Add Coupon Code</label>
                    </td>
                    <td class="value">
                        <input v-model="code" class="input-text" type="text" />
                        <div v-if="isValidCouponCode" class="error-text-rx">
                            <span v-text="errorText"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="label"></td>
                    <td class="value">
                        <button title="Apply" type="button" class="scalable" @click="checkCode">
                            <span><span><span>Apply</span></span></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
    name: 'CouponCode',
    data: () => ({
        code: '',
        errorText: 'Coupon code is invalid'
    }),
    computed: {
        ...mapState('lensablPlus/membership', ['memberShipData']),
        ...mapGetters('lensablPlus', ['checkoutData']),
        isValidCouponCode () {
            return this.memberShipData.formData.isValidCouponCode === false
        }
    },
    methods: {
        checkCode () {
            this.$store.dispatch('lensablPlus/membership/updateMemberShipFormData', {
                coupon_code: this.code
            }).then(() => {
                this.$store.dispatch('lensablPlus/membership/updateQuote')
            })
        }
    }
}
</script>
