<template>
    <div>
        <div class="c-product-description">
            <h2 class="c-product-subheader">Product Description</h2>
            <p v-dompurify-html="description" class="c-product-paragraph-text"></p>
        </div>

        <div v-if="false" class="c-product-details">
            <h2 class="c-product-subheader">Details</h2>
            <table class="c-product-details-list">
                <tr>
                    <td class="c-product-details-list-item"><span class="c-product-third-header">Lens Type:</span></td>
                    <td><span class="c-product-paragraph-text">{{ contacts_lens_type }}</span></td>
                </tr>
                <tr>
                    <td class="c-product-details-list-item"><span class="c-product-third-header">Package Details:</span></td>
                    <td><span class="c-product-paragraph-text">{{ package_details }}</span></td>
                </tr>
                <tr>
                    <td class="c-product-details-list-item"><span class="c-product-third-header">Material and % of content:</span></td>
                    <td><span class="c-product-paragraph-text">{{ material }}</span></td>
                </tr>
                <tr>
                    <td class="c-product-details-list-item"><span class="c-product-third-header">Water % of content</span></td>
                    <td><span class="c-product-paragraph-text">{{ water_content }}</span></td>
                </tr>
                <tr>
                    <td class="c-product-details-list-item"><span class="c-product-third-header">Manufacturer:</span></td>
                    <td><span class="c-product-paragraph-text">{{ manufacturer }}</span></td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'CatalogContactsProductViewDescription',
    computed: {
        ...mapState('contacts/productView', ['description', 'contacts_lens_type', 'package_details', 'material', 'water_content', 'manufacturer'])
    }
}
</script>
