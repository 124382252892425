<template>
    <div v-if="isAvailable" class="product-configurator" sticky-container>
        <ProgressBar v-if="showProgressBar" :custom-summary="customSummary" class="product-configurator__progress-bar" />
        <div :class="{'product-configurator--configurator-mobile': isMobileOnly}" class="product-configurator--configurator">
            <Steps :class="{'product-configurator__steps-mobile': isMobileOnly}" class="product-configurator__steps">
                <Finish v-if="isMobile || isTablet || isProductConfigured" id="configurator_step_final" class="product-configurator__steps-item">
                    <template v-slot:content>
                        <Summary v-if="isTablet" :custom-summary="customSummary" class="product-configurator__summary product-configurator-summary" />
                    </template>
                </Finish>
            </Steps>
            <slot name="summary">
                <Summary v-if="!isMobile && !isTablet" :sticky-available="configuratorShow" :custom-summary="customSummary" class="product-configurator__summary product-configurator-summary" />
            </slot>
        </div>
        <div v-if="movingToCheckout" class="move-to-checkout">
            <div class="overlay-screen" :class="{visible: animationEnabled}">
                <div class="moving-message">
                    <div class="progress">
                        <div class="progress-line"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import prescriptions from '../prescriptions/storage/prescriptions'
import configurator from './storage/configurator'
import steps from './storage/configurator/steps'
import product from '../catalog/product/storage/product/view'

import Steps from './Steps.vue'
import Summary from '../summary/Summary.vue'
import ProgressBar from './ProgressBar.vue'
import Finish from './Finish.vue'

export default {
    name: 'Configurator',
    components: {
        Steps,
        Summary,
        ProgressBar,
        Finish
    },
    props: {
        customSummary: {
            type: Boolean
        },
        showProgressBar: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters('deviceProperty', ['isMobileOnly', 'isMobile', 'isTablet']),
        ...mapGetters('configurator', ['configuratorShow']),
        ...mapState('configurator', ['animationEnabled', 'movingToCheckout']),
        ...mapGetters('configurator/steps', ['isProductConfigured']),
        isAvailable () {
            return true
        }
    },
    beforeCreate:  function () {
        if (!this.$store._modulesNamespaceMap['prescriptions/']) {
            this.$store.registerModule('prescriptions', prescriptions(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['configurator/']) {
            this.$store.registerModule('configurator', configurator(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['productView/']) {
            this.$store.registerModule('productView', product(this.$store))
        }
        if (!this.$store._modulesNamespaceMap['configurator/steps/']) {
            this.$store.registerModule(['configurator', 'steps'], steps(this.$store))
        }
        this.$store.dispatch('productView/fetchDefaultData')
        this.$store.dispatch('configurator/fetchDefaultData')
    },
    created () {
        this.$store.dispatch('lensablplus_customer/getMembershipInfo')
        this.$store.dispatch('lensablplus_customer/getMembershipConfigs')
    }
}
</script>
