import ProductView from './ProductView.vue'

export default {
    name: 'CatalogProductGamerAdvantageView',
    extends: ProductView,
    data: () => {
        return {
            instructionHtml: '',
            affirmHtml: '',
            showSwatcher: false,
            showAttr: false
        }
    }
}
