<template>
    <div v-show="isCurrent" class="lensabl-plus-content business-dashboard">
        <h3>Welcome to Lensabl+!</h3>
        <p class="description">
            Select which plan you would like:
            <br />
            <a :href="groupPlanPdfLink" download>Download PDF to view all plan details</a>
        </p>

        <div class="plans_container">
            <label v-for="(plan, index) in planSelection" :key="plan.id" :class="{ active: selected === plan.id}" :for="plan.id" class="plan-option">
                <input :id="plan.id" :title="plan.name" type="radio" name="plans" @click="togglePlans(plan.id, index)" />
                <span class="plan-container">
                    <div class="plan-label">
                        <span>
                            <strong :style="{ color: plan.color }" class="plan-name">2021 {{ plan.label }}</strong>
                        </span>
                        <span>${{ plan.price }} per month</span>
                    </div>
                </span>
            </label>
        </div>
        <div v-if="error" class="validation-advice" style="">Please choose a plan.</div>

        <div class="actions-toolbar">
            <div class="primary">
                <BaseButton
                    class="button--copy-solid-white-blue button--small"
                    text="Continue"
                    @click.native="continueNxt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '../../../base/BaseButton.vue'
import {mapGetters} from 'vuex'
import _filter from 'lodash/filter'

export default {
    name: 'DashboardPlans',
    components: {
        BaseButton
    },
    props: {
        id: { // Component Id
            type: [String, Number],
            default: ''
        }
    },
    data: () => ({
        selected: '',
        selectedPlan: {},
        error: false
    }),
    computed: {
        ...mapGetters('lensablplus_customer', ['getObjectConfig', 'currentComponent']),
        ...mapGetters('lensablplus_business', ['offeredPlans', 'employeeId', 'plans']),
        config () {
            return this.getObjectConfig(this.id)
        },
        isCurrent () {
            return this.currentComponent.id === this.id
        },
        planSelection () {
            const selected = []

            this.offeredPlans.forEach(plan => {
                _filter(this.plans, obj => {
                    if (obj.sku === plan) {
                        selected.push(obj)
                    }
                })
            })

            this.$store.commit('lensablplus_business/LOAD', false)

            return selected
        },
        groupPlanPdfLink(){
            const firstPlan = this.planSelection[0] || null
            let code = ''
            if (firstPlan){
                const planCode = firstPlan.sku.split('_').last()
                if (planCode !== firstPlan.label){
                    code = '_'+planCode
                }
            }
            return `/js/lensconfigurator/assets/plans/group_plans${code}.pdf`
        }
    },
    methods: {
        continueNxt () {
            if (this.selected.length === 0) {
                this.error = true
            } else {
                // set new employee plan
                this.$store.commit('lensablplus_customer/SELECTED_PLAN', this.selectedPlan)

                this.$store.commit('lensablplus_customer/COMPONENT_DATA', {id: this.id, value: this.selected, isValidValue: true})
            }
        },
        togglePlans (id, index) {
            this.selected = id
            this.selectedPlan = this.planSelection[index]
        }
    }
}
</script>

<style scoped>

</style>
