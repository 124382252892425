var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gg-container" }, [
      _c("section", { staticClass: "gg-banner-section" }, [
        _c("div", { staticClass: "gg-banner-section_row gg-container-row" }, [
          _c("div", { staticClass: "gg-banner-section_row-header" }, [
            _c("h1", [_vm._v("We are experts in Gaming Eyewear")]),
            _vm._v(" "),
            _c("h5", [_vm._v("SHOP FROM OUR RANGE OF POPULAR GAMER FRAMES:")]),
            _vm._v(" "),
            _c("div", { staticClass: "gg-banner-section_row-header-logos" }, [
              _c("a", { attrs: { href: "/gamer-advantage-eyewear" } }, [
                _c("img", {
                  staticClass: "gg-gamer-logo",
                  attrs: {
                    src: "media/wysiwyg/gaming-glasses-gamer-logo.png",
                    alt: "Gamer Advantage Logo"
                  }
                })
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: "/hyperx-eyewear" } }, [
                _c("img", {
                  staticClass: "gg-hyperx-logo",
                  attrs: {
                    src: "media/wysiwyg/gaming-glasses-hyperx-logo.png",
                    alt: "HyperX Logo"
                  }
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gg-banner-section_row-subtext" }, [
            _c("span", [
              _vm._v(
                "You can add custom prescription lenses to any of these frames, but be sure to select Blue-Light lenses or Blue-Light protection coatings."
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "gg-hev-section" }, [
        _c("div", { staticClass: "gg-hev-section_row gg-container-row" }, [
          _c("div", { staticClass: "gg-hev-section_row-header" }, [
            _c("h4", [
              _vm._v(
                "Maintain Your Competitive Edge by Protecting Your Eyes From Blue-Light"
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "HEV (high-energy visible rays) rays emitted from computer, TV, and phone screens that we spend 10 hours (on avg.) a day in front of cause our eyes to work harder and age faster."
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "home__call-to-action-two gg-symptoms-section" },
        [
          _c("div", { staticClass: "blue-light-one" }, [
            _c("div", { staticClass: "offset-container" }, [
              _c(
                "section",
                {
                  staticClass: "home-insurance-block",
                  staticStyle: { "text-align": "center" }
                },
                [
                  _c(
                    "h4",
                    {
                      staticStyle: {
                        color: "#2f3948",
                        "font-weight": "bolder",
                        "text-transform": "uppercase"
                      }
                    },
                    [_vm._v("Symptoms Of Blue Light")]
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "symptoms-container" }, [
                    _c(
                      "li",
                      {
                        staticClass: "symptom",
                        staticStyle: { "border-radius": ".3em" }
                      },
                      [
                        _c("img", {
                          staticClass: "symptoms-img",
                          attrs: {
                            src: "/js/lensconfigurator/assets/eye-strain.svg",
                            alt: "blurry vision"
                          }
                        }),
                        _vm._v(" "),
                        _c("h3", [_vm._v("Eye Strain")])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "symptom",
                        staticStyle: { "border-radius": ".3em" }
                      },
                      [
                        _c("img", {
                          staticClass: "symptoms-img",
                          attrs: {
                            src: "/js/lensconfigurator/assets/headaches.svg",
                            alt: "blurry vision"
                          }
                        }),
                        _vm._v(" "),
                        _c("h3", [_vm._v("Headaches")])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "symptom hard-time",
                        staticStyle: { "border-radius": ".3em" }
                      },
                      [
                        _c("img", {
                          staticClass: "symptoms-img",
                          attrs: {
                            src: "/js/lensconfigurator/assets/no-sleep.svg",
                            alt: "blurry vision"
                          }
                        }),
                        _vm._v(" "),
                        _c("h3", [_vm._v("Hard Time Sleeping")])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "symptom",
                        staticStyle: { "border-radius": ".3em" }
                      },
                      [
                        _c("img", {
                          staticClass: "symptoms-img",
                          attrs: {
                            src:
                              "/js/lensconfigurator/assets/blurry-vision.svg",
                            alt: "blurry vision"
                          }
                        }),
                        _vm._v(" "),
                        _c("h3", [_vm._v("Blurry Vision")])
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "gg-frames-section" }, [
        _c("div", { staticClass: "gg-frames-section_row gg-container-row" }, [
          _c("div", { staticClass: "gg-frames-section_row-header" }, [
            _c("h4", [
              _vm._v(
                "YOU CAN ALSO ADD BLUE-LIGHT PROTECTION TO ANY OF THE FRAMES WE OFFER OR IN FRAMES YOU ALREADY OWN:"
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gg-frames-section_row-header-buttons" }, [
              _c(
                "a",
                { attrs: { href: "/shop-frames", title: "Shop Frames" } },
                [
                  _c("div", { staticClass: "button button--copy-solid" }, [
                    _c("div", { staticClass: "button__container" }, [
                      _vm._v("SHOP FRAMES")
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "/choose-my-lenses",
                    title: "Lens Replacement"
                  }
                },
                [
                  _c("div", { staticClass: "button button--copy-solid" }, [
                    _c("div", { staticClass: "button__container seo-btn-lr" }, [
                      _vm._v("SHOP LENS REPLACEMENT")
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "gg-spectrum-section home-insurance-block",
          staticStyle: { "text-align": "center" }
        },
        [
          _c("div", { staticClass: "gg-spectrum-section_header" }, [
            _c("h4", [_vm._v("Blue-Light Blocking Lenses Vs Coating")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Lenses with Blue-Light blocking built in, protect your eyes from 80+% while lenses with a coating only block up to 20% of HEV rays."
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "h4",
            {
              staticStyle: {
                color: "#2f3948",
                "font-weight": "bolder",
                "text-transform": "uppercase"
              }
            },
            [_vm._v("LIGHT Spectrum")]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "symptoms-container" }, [
            _c("img", {
              staticClass: "hev-spectrum",
              staticStyle: { margin: "1em" },
              attrs: {
                src: "/media/wysiwyg/HEV-spectrum.jpg",
                alt: "hev spectrum"
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "home__call-to-action-two gg-lenses-section" },
        [
          _c(
            "div",
            {
              staticClass: "struct-parallax-bg",
              attrs: {
                "data-parallax": "data-parallax",
                "data-attrs-pos-start": "50% -130%",
                "data-attrs-pos-end": "50% 60%",
                "data-attrs-xlarge-pos-start": "50% -100%",
                "data-attrs-xlarge-pos-end": "50% 0%",
                "data-attrs-trigger-hook": "1"
              }
            },
            [
              _c(
                "section",
                {
                  staticClass: "home-insurance-block gg-lenses-section_row",
                  staticStyle: { "text-align": "center" }
                },
                [
                  _c(
                    "h2",
                    {
                      staticStyle: { color: "#2f3948", "font-weight": "bolder" }
                    },
                    [_vm._v("All Lensabl Lenses Include")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass: "blue-light-coatings gg-blue-light-coatings"
                    },
                    [
                      _c("li", [
                        _c("span", { staticClass: "anti-reflective" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "coating-description" }, [
                          _c("h5", [_vm._v("Anti-Reflective Coating")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "Blocks reflections and glare, i.e. from car headlamps and screens. Included for free on all lenses."
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", { staticClass: "ultraviolet" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "coating-description" }, [
                          _c("h5", [_vm._v("Ultra Violet Blocking Treatment")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "A coating that acts as a sunscreen for your eyes, blocking UV rays from passing through lenses. Included for free on all lenses."
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", { staticClass: "scratch-resistant" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "coating-description" }, [
                          _c("h5", [_vm._v("Scratch Resistance")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "A coating that strengthens your lenses to protect from scratches or other damage. Included for free on all lenses."
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", { staticClass: "hydrophobic" }, [
                          _c("img", {
                            staticClass: "hydrophobic-img",
                            attrs: {
                              src:
                                "/js/lensconfigurator/assets/hydrophobic.svg",
                              alt: "blurry vision"
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "coating-description" }, [
                          _c("h5", [_vm._v("Hydrophobic Coating")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                                Helps repel water, grease, smudges, and static from your lenses, making cleaning easier. Included for free on all lenses.\n                            "
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "gg-footer-section" }, [
        _c("div", { staticClass: "gg-footer-section_row gg-container-row" }, [
          _c("img", {
            attrs: {
              src: "/media/wysiwyg/gaming-glases-footer.jpg",
              alt: "guy on computer"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "gg-footer-section_row-text" }, [
            _c("p", [
              _vm._v(
                "Lensabl also offers a range of affordable frames to meet all of your gaming needs."
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gg-footer-section_row-text-buttons" }, [
              _c(
                "a",
                { attrs: { href: "/shop-frames?category=everyday-eyewear" } },
                [
                  _c("div", { staticClass: "button button--copy-solid" }, [
                    _c("div", { staticClass: "button__container" }, [
                      _vm._v("SHOP EVERYDAY EYEWEAR")
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }