<template>
    <div>
        <div class="returns-frame-section">
            <slot></slot>
            <p>
                *See <a :href="`${base_url}returns`">Return Policy</a> for more details
            </p>
            <p>
                **See <a :href="`${base_url}returns`">Warranty</a> for more details
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'ReturnsInfo',
    computed: mapGetters('storeView', ['base_url'])
}
</script>
