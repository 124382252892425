var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "plus-wrapper",
      class: { "business-redeem-wrapper": _vm.isBusinessMember }
    },
    [
      _c("Notification", { staticClass: "lplus-card notification-mobile" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-card-holder", attrs: { id: "main-cards" } },
        [
          _vm.isBusinessMember
            ? _c("BusinessContactsRedeem", {
                staticClass: "lplus-card first",
                class: { disabled: !_vm.paidUp }
              })
            : _c("Contacts", {
                staticClass: "lplus-card first",
                class: { disabled: !_vm.paidUp }
              }),
          _vm._v(" "),
          _c("p", { attrs: { id: "animate-contacts-msg" } }, [
            _vm._v('To use a reward, click the "Redeem" button')
          ]),
          _vm._v(" "),
          _vm.isBusinessMember
            ? _c("BusinessFramesRedeem", {
                staticClass: "lplus-card rest second",
                class: { disabled: !_vm.paidUp }
              })
            : !_vm.isFreeMembership || _vm.isFreeMembership == 0
            ? _c("Glasses", {
                staticClass: "lplus-card rest second",
                class: { disabled: !_vm.paidUp }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("EyeExam", {
            staticClass: "lplus-card rest third",
            class: { disabled: !_vm.paidUp }
          }),
          _vm._v(" "),
          _c("PlusDiscountRedeem", {
            staticClass: "lplus-card rest last-card",
            class: { disabled: !_vm.paidUp }
          }),
          _vm._v(" "),
          !_vm.isBusinessMember
            ? _c("TrialExpiring", {
                staticClass: "lplus-card rest last-card",
                class: { disabled: !_vm.paidUp }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isBusinessMember
            ? _c("BusinessNonLensablRedeem", {
                staticClass: "lplus-card rest last-card"
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("Notification", { staticClass: "notification-desktop" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }