var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statusApi
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.previousDoctors.length,
                  expression: "previousDoctors.length"
                }
              ]
            },
            [
              _c("p", [_vm._v("Previous Doctor(s)")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  domProps: { value: _vm.selectedDoctorId },
                  on: {
                    change: function($event) {
                      return _vm.setDoctor($event.target.value)
                    }
                  }
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", selected: "" } },
                    [_vm._v("Previous Doctor")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.previousDoctors, function(doctor) {
                    return [
                      _c(
                        "option",
                        {
                          key: doctor.doctor_id,
                          domProps: { value: doctor.doctor_id }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              doctor.doctor_first_name +
                                " " +
                                doctor.doctor_last_name
                            )
                          )
                        ]
                      )
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("p", [_vm._v("or enter new details:")])
            ]
          ),
          _vm._v(" "),
          _c("DoctorDetailsForm", { attrs: { id: _vm.id } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }