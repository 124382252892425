import axios from 'axios'
import reqwest from 'reqwest'
import $ from 'jquery'

let prescriptionService = null

class PrescriptionService {
    constructor (store) {
        this.base_url = store.getters['storeView/base_url']
        this.type = ''
    }

    cachedList (type) {
        return new Promise((resolve, reject) => {
            this.type = type
            this.list(type)
                .then((listData) => {
                    resolve(listData)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    updateTopNav () {
        $('#sign-in').load(document.URL + ' #sign-in')
    }

    list () {
        const url = this.url()
        return new Promise((resolve, reject) => {
            axios.get(url).then((data) => {
                resolve(data)
                this.updateTopNav()
            }).catch((e) => reject(e))
        })
    }

    doctorSignIn () {
        const url = this.base_url + 'opternative/index/isCustomerAuth'
        return this.wrapPromise(reqwest({
            url: url,
            method: 'get',
            contentType: 'application/json'
        }))
    }

    wrapPromise (promise) {
        return new Promise((resolve, reject) => {
            promise.then((data) => resolve(data)).catch((e) => reject(e))
        })
    }

    load (id) {
        return axios.get(this.url(id))
    }

    create (data) {
        return axios.post(this.url(), data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    update (id, data) {
        return axios.put(this.url(id), data)
    }

    isSingIn () {
        return axios.get(this.base_url + 'lensabl/customerAuth')
    }

    customerSingIn (data) {
        return axios.post(this.base_url + 'lensabl/loginPostAjax', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    remove (id) {
        return axios.delete(this.url(id))
    }

    url (id = false) {
        const path = id ? `lensabl/prescription/${id}` : 'lensabl/prescriptions?type=' + this.type
        return this.base_url + path
    }

    reorder (prescription) {
        const url = this.base_url + `lensablall/order/reorder?prescription_ids=${(prescription.prescription_ids).join(',')}`
        return axios.get(url)
    }

    addToCart (data) {
        const dataEncoded = this.encodeDataToURL(data)
        const url = this.base_url + `lensablall/order/addToCart?${dataEncoded}`
        return axios.get(url)
    }

    encodeDataToURL (data) {
        return Object
            .keys(data)
            .map(value => `${value}=${encodeURIComponent(data[value])}`)
            .join('&')
    }

    _requestWithCleanCache (promise) {
        return new Promise((resolve, reject) => {
            promise
                .then((data) => {
                    this.initCache()
                    resolve(data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default function getPrescriptionService (store = {}) {
    if (!prescriptionService) {
        prescriptionService = new PrescriptionService(store)
    }
    return prescriptionService
}
