var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "understanding_measurememt-container vision-seo-accordion" },
    [
      _c("div", { staticClass: "understanding-demo-container" }, [
        _c("div", { staticClass: "accordian-item" }, [
          _c(
            "div",
            {
              staticClass: "accordian-header",
              class: { active: _vm.header1 },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.header1 = !_vm.header1
                }
              }
            },
            [
              _c("h4", [
                _vm._v(
                  "\n                    Do I need a past prescription to take the online eye exam?\n                "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "accordian-content",
              style: _vm.header1 ? "display: block" : "display: none"
            },
            [
              _c("p", [
                _vm._v(
                  "The online eye test from Lensabl helps patients renew or update their existing prescriptions only."
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "accordian-item" }, [
          _c(
            "div",
            {
              staticClass: "accordian-header",
              class: { active: _vm.header2 },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.header2 = !_vm.header2
                }
              }
            },
            [
              _c("h4", [
                _vm._v(
                  "\n                    Who can take the online vision test?\n                "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "accordian-content",
              style: _vm.header2 ? "display: block" : "display: none"
            },
            [
              _c("p", [
                _vm._v(
                  "We provide tests in 37 states for patients between the ages of 18 and 50 who do not have health conditions such as diabetes or glaucoma."
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "accordian-item" }, [
          _c(
            "div",
            {
              staticClass: "accordian-header",
              class: { active: _vm.header3 },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.header3 = !_vm.header3
                }
              }
            },
            [
              _c("h4", [
                _vm._v(
                  "\n                    Will a real eye doctor review my exam?\n                "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "accordian-content",
              style: _vm.header3 ? "display: block" : "display: none"
            },
            [
              _c("p", [
                _vm._v(
                  "A certified ophthalmologist from your state will audit your virtual test and provide a prescription based on the results. "
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "accordian-item" }, [
          _c(
            "div",
            {
              staticClass: "accordian-header",
              class: { active: _vm.header4 },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.header4 = !_vm.header4
                }
              }
            },
            [
              _c("h4", [
                _vm._v(
                  "\n                    Should I wear my contacts or glasses during the test?\n                "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "accordian-content",
              style: _vm.header4 ? "display: block" : "display: none"
            },
            [
              _c("p", [
                _vm._v(
                  "Patients need to remove their glasses or contacts before taking their virtual eye test."
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }