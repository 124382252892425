var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModal && _vm.getLoading
    ? _c("div", [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper lplus-modal-container" }, [
            !_vm.getCreatedSuccess
              ? _c(
                  "div",
                  { staticClass: "modal-container lplus-modal-section" },
                  [
                    _c("div", { staticClass: "modal-header" }, [
                      _c("a", {
                        staticClass: "modal-close-button",
                        on: { click: _vm.stateModal }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body lplus-modal-body" }, [
                      _c("div", { staticClass: "lplus-modal-text" }, [
                        _c("h2", [_vm._v("Claim Your")]),
                        _vm._v(" "),
                        _vm._m(0),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        _vm._m(2),
                        _vm._v(" "),
                        _vm._m(3),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-footer" },
                          [
                            _vm._t("footer", [
                              _c("BaseButton", {
                                attrs: {
                                  "id-name": "lplus-conversion-btn",
                                  loader: _vm.loadButton,
                                  text: "join lensabl+"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.createMembership($event)
                                  }
                                }
                              })
                            ])
                          ],
                          2
                        )
                      ])
                    ])
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "modal-container lplus-congrats-modal-section"
                  },
                  [
                    _vm._l(149, function(n) {
                      return _c("div", {
                        key: "confetti-" + n,
                        class: "confetti-" + n
                      })
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-header" }, [
                      _c("a", {
                        staticClass: "modal-close-button",
                        on: { click: _vm.stateModal }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body lplus-modal-body" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "modal-footer" },
                        [
                          _vm._t("footer", [
                            _c("BaseButton", {
                              attrs: { text: "continue" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.stateModal($event)
                                }
                              }
                            })
                          ])
                        ],
                        2
                      )
                    ])
                  ],
                  2
                )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [_c("span", [_vm._v("FREE")]), _vm._v(" Gift")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "lplus-modal-header-subtext" }, [
      _vm._v("A "),
      _c("b", [_vm._v("FREE six months Lensabl+")]),
      _vm._v(" "),
      _c("span", { staticClass: "lplus-header-span" }, [
        _c("b", [_vm._v("membership")]),
        _vm._v(" that includes:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_c("b", [_vm._v("25% off")]), _vm._v(" lenses and frames")]),
      _vm._v(" "),
      _c("li", [_c("b", [_vm._v("15% off")]), _vm._v(" contact lenses")]),
      _vm._v(" "),
      _c("li", [
        _c("b", [_vm._v("$60 Lensabl gift card")]),
        _vm._v(" for taking an eye exam")
      ]),
      _vm._v(" "),
      _c("li", [_vm._v("Insider access to new frame and lens products")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lplus-membership-text" }, [
      _c("p", [_vm._v("Click the button below to be")]),
      _vm._v(" "),
      _c("p", [_vm._v("automatically enrolled at no charge")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lplus-modal-text" }, [
      _c("h2", [_vm._v("Congratulations! 🎉")]),
      _vm._v(" "),
      _c("p", [_vm._v("You are now a Lensabl+ Member.")]),
      _vm._v(" "),
      _c("p", [_vm._v("You will soon receive an email with further details.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }