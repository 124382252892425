<template>
    <CheckoutLPlus v-if="statusApi" />
</template>

<script>
import App from '../../App.vue'
import CheckoutLPlus from '../../components/checkout/CheckoutLPlus.vue'

export default {
    name: 'RouterCheckoutLPlus',
    components: {
        CheckoutLPlus
    },
    extends: App
}
</script>
