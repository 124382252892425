var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-select" },
    [
      _vm.labelTitle
        ? _c("label", { attrs: { for: _vm.id } }, [
            _vm._v(_vm._s(_vm.labelTitle))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "select",
        {
          ref: _vm.refName,
          class: {
            "required-entry": _vm.required,
            "validation-failed": _vm.validationFailed
          },
          attrs: {
            id: _vm.id,
            title: _vm.title,
            disabled: _vm.disabled,
            error: _vm.error
          },
          domProps: { value: _vm.value },
          on: { change: _vm.change }
        },
        [
          _vm._l(_vm.options, function(option) {
            return [
              _c("option", {
                directives: [
                  {
                    name: "dompurify-html",
                    rawName: "v-dompurify-html",
                    value: option.label,
                    expression: "option.label"
                  }
                ],
                key: option.value,
                domProps: {
                  value: option.value,
                  selected: _vm.currentOption.value === option.value
                }
              })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }