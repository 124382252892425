export default (param = {}) => {
    const {assets_url, type, contacts} = param

    function assetUrl (filename) {
        return assets_url + filename
    }
    return [
        {
            id: 'prescribed-contacts',
            title: 'One (1) year supply of your prescribed contacts',
            contactsInfo: (left = {}, right = {}) => {
                let info = ''
                if (left.product_sku && right.product_sku) {
                    if (left.product_sku === right.product_sku) {
                        info = `We will ship ${left.boxes * 2} boxes of ${left.name} throughout the year.`
                    } else {
                        info = `We will ship ${left.boxes} boxes of ${left.name} throughout the year and ${right.boxes} boxes of ${right.name} throughout the year`
                    }
                }
                return info
            },
            description: contacts,
            helpBubble: 0,
            helpBubbleParams: {},
            thumbnail: assetUrl('desktop_contacts.svg'),
            rules: {
                plan_contacts: 1
            }
        },
        {
            id: 'lens-replacement',
            title: ['One (1) frame including prescription lenses per year', 'One (1) prescription lens replacement'],
            contactsInfo: () => {},
            description: ['Any frame of your choice from our Everyday Eyewear collection. Includes ' + type + ' polycarbonate lenses with Blue-Light Blocking, Anti-Reflective, Anti-Scratch, and 100% UV Protection coatings. All lenses and coatings are made by Essilor.',
                'Use your benefit to replace lenses in an existing frame of any brand you already own. Includes ' + type + ' polycarbonate lenses with Blue-Light Blocking, Anti-Reflective, Anti-Scratch, and 100% UV Protection coatings. All lenses and coatings are made by Essilor.' +
                ' <br>\n<i>* High-index (thin), Transitions, Sunglass lens and other options available for extra charge</i>'],
            helpBubble: 1, // enable help bubble Ex: @ lensconfigurator/src/js/components/lensablplus/summary/TotalsInfo.vue description
            helpBubbleParams: {
                'name': 'help-bubble',
                'targetClasses' : 'help-bubble-text-link',
                'type': 'text', // icon, text
                'params' : {
                    'text' : '<img src="/js/lensconfigurator/assets/brands.jpg" alt="popular brands" width="315px">',
                    'targetContent' : 'popular brands'
                }
            },
            thumbnail: [
                assetUrl('glasses_desktop.svg'),
                assetUrl('lens_desktop.svg')
            ],
            rules: {
                plan_lens: 1
            }
        },
        {
            id: 'eye-exam',
            title: ['Credit for eye exam or contact fitting', 'Online vision test for Rx renewal'],
            contactsInfo: () => {},
            description: ['See any licensed eye care provider you want for an eye exam or contact fitting. Simply upload a valid receipt and receive a $60 Lensabl credit.\n' +
            'When doctors don\'t need to chase reimbursements from the insurance company, they often give discounts.',
            'Take our online vision test to renew your expired prescription from home in under 10 minutes.'],
            helpBubble: 0, // enable help bubble Ex: @ lensconfigurator/src/js/components/lensablplus/summary/TotalsInfo.vue description
            helpBubbleParams: {
                'name': 'help-bubble',
                'targetClasses' : 'help-bubble-text-link',
                'type': 'text', // icon, text
                'params' : {
                    'text' : '$25 cash cards from Visa or gift cards from some of the country\'s leading retailers including Amazon, Best Buy, CVS, iTunes, Zappos, and more.',
                    'targetContent' : 'other rewards'
                }
            },
            thumbnail: [
                assetUrl('eyeexam_desktop.svg'),
                assetUrl('online_exam.svg')
            ],
            rules: {
                plan_exam: 1
            }
        },
        {
            id: 'lens-replacement',
            title: 'Up to 25% discount on any additional purchases',
            contactsInfo: () => {},
            description: 'Save big on extra frames, lens enhancements and contacts not included in your plan.',
            helpBubble: 0,
            helpBubbleParams: {},
            thumbnail: assetUrl('save_money_desktop.svg')
        }
    ]
}
