var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.media && _vm.media.length
    ? _c("div", { staticClass: "gallery-placeholder" }, [
        _c("div", { staticClass: "gallery--wrap" }, [
          _c(
            "div",
            { staticClass: "gallery-stage" },
            [
              _vm._l(_vm.media, function(img, index) {
                return [
                  !_vm.galleryThumbsShow
                    ? [
                        _c(
                          "div",
                          {
                            key: img.inline + index,
                            staticClass: "gallery-stage-item jjjjj",
                            class: {
                              current: _vm.currentImageIndex === index,
                              "current-prev": _vm.prevImageIndex === index,
                              "current-next": _vm.nextImageIndex === index
                            }
                          },
                          [
                            _c("figure", [
                              _vm.productView.frames_name ===
                                "Spectacles Avalon" ||
                              _vm.productView.frames_name ===
                                "Spectacles Matador"
                                ? _c(
                                    "span",
                                    { staticClass: "specgen-banner-text" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.productView.frames_name)
                                      )
                                    ]
                                  )
                                : _c("img", {
                                    attrs: {
                                      src: _vm.getImageUrl(img),
                                      alt: _vm.image_title
                                    }
                                  })
                            ]),
                            _vm._v(" "),
                            _vm.productView.frames_name ===
                              "Spectacles Avalon" ||
                            _vm.productView.frames_name === "Spectacles Matador"
                              ? _c(
                                  "span",
                                  { staticClass: "shopnshop-time-text" },
                                  [
                                    _vm._v(
                                      "High index lenses recommended for these prescriptions inserts"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    : _vm.productView.frames_name === "lensabl frames" &&
                      img.label === _vm.productView.lensabl_category
                    ? [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "touch",
                                rawName: "v-touch:swipe.left",
                                value: _vm.nextMainImage,
                                expression: "nextMainImage",
                                arg: "swipe",
                                modifiers: { left: true }
                              },
                              {
                                name: "touch",
                                rawName: "v-touch:swipe.right",
                                value: _vm.prevMainImage,
                                expression: "prevMainImage",
                                arg: "swipe",
                                modifiers: { right: true }
                              }
                            ],
                            key: img.inline + index,
                            staticClass: "gallery-stage-item",
                            class: {
                              current: _vm.currentImageIndex === index,
                              "current-prev": _vm.prevImageIndex === index,
                              "current-next": _vm.nextImageIndex === index
                            },
                            attrs: {
                              "data-slide-effect": _vm.isMobile
                                ? "slide"
                                : "fade"
                            },
                            on: {
                              click: function($event) {
                                return _vm.popUpShow()
                              }
                            }
                          },
                          [
                            _c("figure", [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImageUrl(img),
                                  alt: _vm.image_title
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    : _vm.productView.frames_name !== "lensabl frames"
                    ? [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "touch",
                                rawName: "v-touch:swipe.left",
                                value: _vm.nextMainImage,
                                expression: "nextMainImage",
                                arg: "swipe",
                                modifiers: { left: true }
                              },
                              {
                                name: "touch",
                                rawName: "v-touch:swipe.right",
                                value: _vm.prevMainImage,
                                expression: "prevMainImage",
                                arg: "swipe",
                                modifiers: { right: true }
                              }
                            ],
                            key: img.inline + index,
                            staticClass: "gallery-stage-item",
                            class: {
                              current: _vm.currentImageIndex === index,
                              "current-prev": _vm.prevImageIndex === index,
                              "current-next": _vm.nextImageIndex === index
                            },
                            attrs: {
                              "data-slide-effect":
                                _vm.isMobile &&
                                _vm.productView.frames_name !== "kiaura"
                                  ? "slide"
                                  : "fade"
                            },
                            on: {
                              click: function($event) {
                                return _vm.popUpShow()
                              }
                            }
                          },
                          [
                            _c("figure", [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImageUrl(img),
                                  alt: _vm.image_title
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    : _vm._e()
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm.galleryThumbsShow
            ? _c("div", { staticClass: "gallery-thumbs-wrapper" }, [
                _c("div", { staticClass: "gallery-thumbs" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "gallery-thumbs-list",
                      attrs: { role: "tablist" }
                    },
                    [
                      _vm._l(_vm.media, function(img, index) {
                        return [
                          _vm.productView.frames_name === "lensabl frames" &&
                          img.label === _vm.productView.lensabl_category
                            ? [
                                _c(
                                  "li",
                                  {
                                    key: img.inline + index,
                                    class: {
                                      "gallery-thumbs-item": !_vm.isMobile,
                                      "gallery-thumbs-dot": _vm.isMobile,
                                      current: _vm.currentImageIndex === index,
                                      "current-prev":
                                        _vm.prevImageIndex === index,
                                      "current-next":
                                        _vm.nextImageIndex === index
                                    },
                                    attrs: { role: "presentation" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.changeMainImage(index)
                                      }
                                    }
                                  },
                                  [
                                    !_vm.isMobile
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              tabindex: index,
                                              href: img.inline,
                                              target: "_image",
                                              role: "tab"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: img.inline,
                                                alt: _vm.image_title
                                              }
                                            })
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            attrs: {
                                              tabindex: index,
                                              href: img.inline,
                                              role: "tab"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(index) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.productView.frames_name !== "lensabl frames"
                            ? [
                                _c(
                                  "li",
                                  {
                                    key: img.inline + index,
                                    class: {
                                      "gallery-thumbs-item": !_vm.isMobile,
                                      "gallery-thumbs-dot": _vm.isMobile,
                                      current: _vm.currentImageIndex === index,
                                      "current-prev":
                                        _vm.prevImageIndex === index,
                                      "current-next":
                                        _vm.nextImageIndex === index
                                    },
                                    attrs: { role: "presentation" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.changeMainImage(index)
                                      }
                                    }
                                  },
                                  [
                                    !_vm.isMobile
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              tabindex: index,
                                              href: img.inline,
                                              target: "_image",
                                              role: "tab"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: img.inline,
                                                alt: _vm.image_title
                                              }
                                            })
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            attrs: {
                                              tabindex: index,
                                              href: img.inline,
                                              role: "tab"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(index) +
                                                "\n                                "
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.isPopUpShow
          ? _c("div", { staticClass: "gallery--fullscreen" }, [
              _c(
                "div",
                { staticClass: "gallery-stage" },
                [
                  _vm._l(_vm.media, function(img, index) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "touch",
                              rawName: "v-touch:swipe.left",
                              value: _vm.nextMainImage,
                              expression: "nextMainImage",
                              arg: "swipe",
                              modifiers: { left: true }
                            },
                            {
                              name: "touch",
                              rawName: "v-touch:swipe.right",
                              value: _vm.prevMainImage,
                              expression: "prevMainImage",
                              arg: "swipe",
                              modifiers: { right: true }
                            }
                          ],
                          key: img.inline + index,
                          staticClass: "gallery-stage-item",
                          class: {
                            current: _vm.currentImageIndex === index,
                            "current-prev": _vm.prevImageIndex === index,
                            "current-next": _vm.nextImageIndex === index
                          },
                          attrs: { "data-slide-effect": "slide" }
                        },
                        [
                          _c("figure", [
                            _c("img", {
                              attrs: { src: img.gallery, alt: _vm.image_title }
                            })
                          ])
                        ]
                      )
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gallery-thumbs-wrapper" }, [
                _c("div", { staticClass: "gallery-thumbs" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "gallery-thumbs-list",
                      attrs: { role: "tablist" }
                    },
                    [
                      _vm._l(_vm.media, function(img, index) {
                        return [
                          _c(
                            "li",
                            {
                              key: img.inline + index,
                              staticClass: "gallery-thumbs-dot",
                              class: {
                                current: _vm.currentImageIndex === index,
                                "current-prev": _vm.prevImageIndex === index,
                                "current-next": _vm.nextImageIndex === index
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.changeMainImage(index)
                                }
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    tabindex: index,
                                    href: img.inline,
                                    role: "tab"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(index) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gallery-actions" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "javascript:void(0)",
                      role: "button",
                      "aria-label": "Close"
                    },
                    on: { click: _vm.popUpClose }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "gallery--fullscreen-close",
                        attrs: { "aria-hidden": "true" }
                      },
                      [_vm._v("×")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "gallery-actions--prev",
                    attrs: {
                      href: "javascript:void(0)",
                      role: "button",
                      "aria-label": "Prev"
                    },
                    on: { click: _vm.prevMainImage }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("‹")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "gallery-actions--next",
                    attrs: {
                      href: "javascript:void(0)",
                      role: "button",
                      "aria-label": "Next"
                    },
                    on: { click: _vm.nextMainImage }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("›")
                    ])
                  ]
                )
              ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }