import getPrescriptionService from '../../../../app/utils/prescription-service'
import getOptionsSaveService from '../../../../app/utils/options_save'
import getOptionsService from '../../../../app/utils/options-service'

import axios from 'axios'
import _clone from 'lodash/clone'
import _size from 'lodash/size'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import sanitizeHtml from 'sanitize-html'

const DEFAULT_PRESCRIPTION_OPTIONS_VALUE = {
    sphere: '',
    cylinder: '',
    axis: '',
    add: ''
}

export const state = store => ({
    firstName: '',
    lastName: '',
    doctorDetails: {
        firstName: '',
        lastName: '',
        city: '',
        clinicState: '',
        clinic: '',
        clinicPhoneNumber: '',
        doctor_id: '',
        clinic_id: ''
    },
    selectedDoctorId: null,
    previousDoctors: [],
    doctorClinicRelations: [],
    clinics: [],

    service: {},
    prescriptionService: getPrescriptionService(store),
    optionsSaveService: getOptionsSaveService(store),
    optionsValidate: false,
    optionsAreValid: {
        left: true,
        right: true
    },
    optionsService: {},
    customerSignIn: false
})

export const getters = {
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    doctorInfo: state => state.doctorDetails,
    selectedDoctorId: state => state.selectedDoctorId,
    prescriptionService: state => state.prescriptionService,
    optionsSaveService: state => state.optionsSaveService,
    optionsService: (state, getters, rootState) => {
        if (!_size(state.optionsService)) {
            state.optionsService = getOptionsService({
                state: rootState,
                getters: getters
            })
        }
        return state.optionsService
    },
    getValidatePrescriptionData: () => (prescription = {}, prescription_type = '', defaultOptions = DEFAULT_PRESCRIPTION_OPTIONS_VALUE) => {
        const valid = {
            left: false,
            right: false
        }
        const values = {
            ...prescription
        }
        const prescriptionType = _clone(prescription_type)
        if (!values || !prescription_type) {
            return valid
        }

        const progressiveProduct = prescriptionType.indexOf('progressive') !== -1 || prescriptionType.indexOf('focal') !== -1

        let leftCompleted = true
        let rightCompleted = true
        const leftData = {...defaultOptions}
        const rightData = {...defaultOptions}

        if (!progressiveProduct) {
            delete leftData['add']
            delete rightData['add']
        }

        for (const key in values) {
            const splitKey = key.split('_')
            const indexKey = splitKey && splitKey.length ? splitKey.last() : key
            if (splitKey.first() === 'os' && leftData.hasOwnProperty(indexKey)) {
                leftData[indexKey] = values[key]
            }
            if (splitKey.first() === 'od' && rightData.hasOwnProperty(indexKey)) {
                rightData[indexKey] = values[key]
            }
        }

        for (const key in leftData) {
            leftCompleted = leftCompleted && (leftData[key] !== null) && ((leftData[key] || '').trim() !== '')
        }

        for (const key in rightData) {
            rightCompleted = rightCompleted && (rightData[key] !== null) && ((rightData[key] || '').trim() !== '')
        }

        valid['left'] = leftCompleted
        valid['right'] = rightCompleted

        return valid
    },

    getValueOptions: (state, getters, rootState, rootGetters) => index => rootGetters.getRegistry(index)
}

export const mutations = {
    UPDATE_FIRST_NAME: (state, name) => state.firstName = sanitizeHtml(name) || '',
    UPDATE_LAST_NAME: (state, name) => state.lastName = sanitizeHtml(name) || '',
    UPDATE_DOCTOR: (state, info = {}) => state.doctorDetails = _merge(state.doctorDetails, info),
    UPDATE_SELECTED_DOCTOR_ID: (state, id = null) => state.selectedDoctorId = id,
    UPDATE_PREVIOUS_DOCTORS: (state, previousDoctors = []) => state.previousDoctors = previousDoctors,
    UPDATE_CLINICS: (state, clinics = []) => state.clinics = clinics,
    UPDATE_DOCTOR_CLINIC_RELATIONS: (state, doctorClinicRelations = []) => state.doctorClinicRelations = doctorClinicRelations,
    RESET_DOCTOR: state => _map(state.doctorDetails, (value, key) => state.doctorDetails[key] = ''),
    CUSTOMER_SIGN_IN: (state, customerSignIn) => state.customerSignIn = customerSignIn,
    OPTIONS_VALIDATE: (state, validate = false) => state.optionsValidate = validate,
    OPTIONS_ARE_VALID: (state, valid = {}) => state.optionsAreValid = valid
}

export const actions = {
    customerSignIn: ({ state, getters, commit }) => {
        return state.customerSignIn || getters.prescriptionService.doctorSignIn()
            .then(({loggin}) => {
                commit('CUSTOMER_SIGN_IN', loggin)
            })
            .catch(err => console.error(err))
    },
    setValueOptions: ({ dispatch }, {index = '', data}) => {
        if (index) {
            dispatch('updateRegistry', {index, data}, {root: true})
        }
    },
    deleteValueOptions: ({ getters, dispatch }, {index = '', name = ''}) => {
        const data = getters.getValueOptions(index)
        if (data && data.hasOwnProperty(name)) {
            delete data[name]
            dispatch('setRegistry', {index, data}, {root: true})
        }
    },
    optionsValidate: ({ commit }, validate) => {
        commit('OPTIONS_VALIDATE', validate)

        return validate
    },
    validatePrescriptionData: ({ commit, getters }, {prescription = {}, prescription_type = '', defaultOptions = DEFAULT_PRESCRIPTION_OPTIONS_VALUE}) => {
        const valid = getters.getValidatePrescriptionData(prescription, prescription_type, defaultOptions)

        commit('OPTIONS_ARE_VALID', valid)

        return valid
    },
    saveOptions: ({ getters }, {index = '', data}) => {
        return new Promise((resolve, reject) => {
            const optionsData = data
            const values = getters.getValueOptions(index)
            if (!values) {
                reject('Values data is empty')
            }

            const newOptions = {}
            const uploadedData = {}

            const entriesData = Object.entries(values)
            _map(entriesData, ([key, value]) => {
                if (typeof value !== 'function') {
                    if (key.indexOf('upload') !== -1) {
                        Object.assign(uploadedData, {...value}, {...data})
                    } else if (key !== 'separate_pd' && (value && (Number.isInteger(value) || String(value).trim().length > 0))) {
                        if (['od_axis', 'os_axis', 'prescription_type'].indexOf(key) !== -1 && Object.keys(newOptions).length) {
                            newOptions[key] = value
                        } else if (key === 'segmentation_height' || key === 'pd_in_rx') {
                            newOptions[key] = value
                        } else if (key !== 'prescription_type') {
                            if (key === 'od_pd' || key === 'os_pd') {
                                if (values['separate_pd'] && values['separate_pd'] === true) {
                                    if (value !== 0) {
                                        newOptions[key] = value
                                    }
                                }
                            } else if (key === 'pd') {
                                if (values['separate_pd'] === false) {
                                    if (value !== 0) {
                                        newOptions[key] = value
                                    }
                                }
                            } else {
                                newOptions[key] = value
                            }
                        }
                    }
                }
            })

            if (Object.keys(newOptions).length) {
                optionsData.options = newOptions
                resolve(getters.optionsSaveService.saveOptions(optionsData))
            }

            if (Object.keys(uploadedData).length) {
                resolve(getters.optionsSaveService.uploadImage(uploadedData))
            }

            reject('Values data is empty')
        })
    },
    resetDoctorDetails: ({ state }) => _map(state.doctorDetails, (value, key) => state.doctorDetails[key] = ''),
    saveDoctor: ({ commit, dispatch, getters, rootGetters }, data) => {
        axios.post(`${rootGetters['storeView/base_url']}/contact-lenses/ajax/saveDoctor`, data || getters.doctorInfo, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(({data, status}) => {
                if (status === 200) {
                    commit('UPDATE_DOCTOR', data)
                    dispatch('fetchPreviousDoctors')
                }
            })
            .catch(error => {
                console.error(error)
            })
    },
    fetchPreviousDoctors: ({ commit, rootGetters }) => {
        axios.get(`${rootGetters['storeView/base_url']}/contact-lenses/ajax/getCustomerDoctors`).then(({data, status}) => {
            if (status === 200) {
                const {doctors = [], doctor_clinic = [], clinics = []} = data
                commit('UPDATE_PREVIOUS_DOCTORS', doctors)
                commit('UPDATE_DOCTOR_CLINIC_RELATIONS', doctor_clinic)
                commit('UPDATE_CLINICS', clinics)
            }
        })
    }
}

export default (store) => ({
    namespaced: true,
    state: state(store),
    getters,
    mutations,
    actions
})
