<template>
    <div class="options_container upgrades">
        <div class="option-upgrades">
            <div v-if="info.label">
                <div :class="{'has-thumbnail': !!info.image}" class="option-container upgrades-container">
                    <div v-if="info.image" class="option-thumbnail">
                        <img :src="assets_url + info.image" />
                    </div>
                    <div class="option-descriptions title">
                        <span class="option-label">
                            <span v-dompurify-html="info.label" class="label-text"></span>
                            <span class="label-optional">(optional)</span>
                        </span>
                    </div>
                    <div class="extra-component-container">
                        <div class="extra-component">
                            <span v-dompurify-html="info.desc"></span>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="options">
                <template v-for="option in steps_upgrades">
                    <Option :key="option.id" :component-help-show="isMobile" :option="option" class="step-configuration-content" />
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
import {
    UPGRADES_STEP,
    UPGRADES_INFO
} from '../../../app/common/constants/upgrades'
import _map from 'lodash/map'
import {mapGetters, mapMutations} from 'vuex'
import Option from './Option'

export default {
    name: 'SelectorUpgrades',
    components: {
        Option
    },
    data: () => ({
        configs: [...UPGRADES_STEP],
        info: {...UPGRADES_INFO},
        steps_upgrades: []
    }),
    computed: {
        ...mapGetters('deviceProperty', ['isMobile']),
        ...mapGetters('values', ['values']),
        ...mapGetters('configurator', ['upgrades_section']),
        ...mapGetters('storeView', ['assets_url'])
    },
    created () {
        this.updateCheckedValue()
        this.steps_upgrades = this.configs
    },
    methods: {
        ...mapMutations('values', ['resetDataToValues', 'setDataToValues']),
        updateCheckedValue () {
            const upgrades = this.values.upgrades
            _map(this.configs, config => {
                this.$set(config, 'checked', !!upgrades[config.value])
                config.price = this.upgrades_section[config.value] || config.price
            })
        },
        addUpgradesToCart (object) {
            const checked = object.checked
            const newChecked = !checked
            const upgrades = {}
            this.resetDataToValues({upgrades})
            if (newChecked) {
                _map(this.steps_upgrades, item => item.checked = false)
                upgrades[object.value] = object.price
            }
            object.checked = newChecked
            this.setDataToValues({upgrades})
        }
    }
}
</script>
