import {
    PRESCRIPTION_TYPE_ONLINE,
    PRESCRIPTION_TYPE_UPLOAD,
    PRESCRIPTION_TYPE_SAVED,
    PRESCRIPTION_TYPE_LATER
} from '../../../../app/utils/constants'

export default function getHelper (param) {
    // const { assets_url } = param

    const help_components = {
        'sphere': {
            'name': 'help-bubble',
            'params': {
                'text': 'This indicates the lens power, measured in diopters (D), prescribed to correct nearsightedness or farsightedness. If the number appearing under this heading has a minus sign (–), you are nearsighted; if the number has a plus sign (+) or has neither a plus or minus sign, you are farsighted.'
            }
        },
        'cylinder': {
            'name': 'help-bubble',
            'params': {
                'text': '<p>This indicates the lens power for astigmatism. If nothing appears in this column, either you have no astigmatism, or your astigmatism is so slight that it is not necessary to correct it.</p> <p>The number in the cylinder column may have a minus sign (for the correction of nearsighted astigmatism) or a plus sign (for farsighted astigmatism). Cylinder power is always after sphere power in an eyeglass prescription.</p>'
            }
        },
        'axis': {
            'name': 'help-bubble',
            'params': {
                'text': 'The axis defines the location of the sphere and cylinder powers on the lens with a number from 1 to 180.'
            }
        },
        'add': {
            'name': 'help-bubble',
            'params': {
                'text': 'This is the additional reading lens power for the lower segment on your progressive lenses.'
            }
        },
        'pd': {
            'name': 'help-bubble',
            'params': {
                'text': '<p style="font-weight: bold; font-size: 14px">Example Prescription (Rx)</p><p style="font-size: 13px">All glasses prescriptions require a Pupillary Distance (PD). If a PD is not listed on your Rx, we will send you instructions on how to submit a photo for us to measure for you.</p> <img src="/js/lensconfigurator/assets/example-pd.png" alt="example pupillary distance" width="421px"> <p style="font-size: 13px">Pupillary Distance measures the space between the pupils of your eyes in millimeters. Knowing this measurement is important to make sure your eyes match up with the optical center of your lenses.</p>'
            }
        },
        'enter_pd': {
            'name': 'help-bubble',
            'params': {
                'text': '<p style="font-weight: bold; font-size: 14px">Example Prescription (Rx)</p><p style="font-size: 13px">All glasses prescriptions require a Pupillary Distance (PD). If a PD is not listed on your Rx, we will send you instructions on how to submit a photo for us to measure for you after you submit your order.</p> <img src="/js/lensconfigurator/assets/example-pd.png" alt="example pd" width="421px"> <p style="font-size: 13px">Pupillary Distance measures the space between the pupils of your eyes in millimeters. Knowing this measurement is important to make sure your eyes match up with the optical center of your lenses.</p>'
            }
        }
    }

    const DEFAULT_LAST_STEP = {
        'label': 'Enter Rx',
        'summary_label': 'Enter Rx',
        'value_renderer': 'renderer-options',
        'continue_label': 'Save & Continue',
        'default_value': PRESCRIPTION_TYPE_UPLOAD,
        'component': {
            'name': 'selector-options',
            'options': [
                {
                    'id': PRESCRIPTION_TYPE_UPLOAD,
                    'label': 'Upload a Photo of Your Rx',
                    'summary_label': 'Upload a Photo of Your Rx',
                    'description': '',
                    'continue_promise': 'validateUpload',
                    'component_extra_options': {
                        'name': 'prescription-upload',
                        'label_extra': '(Recommended For Best Accuracy)',
                        'summary_label_extra': '(Recommended For Best Accuracy)'
                    }
                },
                {
                    'id': PRESCRIPTION_TYPE_ONLINE,
                    'label': 'Enter it Online',
                    'summary_label': 'Enter it Online',
                    'description': '',
                    // 'continue_promise': 'validatePrescriptionParams',
                    'component_extra_options': {
                        'name': 'selector-prescription',
                        'params': {
                            'options': {
                                'params_component': 'params-prescription',
                                'help_components': help_components
                            }
                        }
                    }
                },
                {
                    'id': PRESCRIPTION_TYPE_SAVED,
                    'label': 'Use a Saved Rx',
                    'summary_label': 'Use a Saved Rx',
                    'description': '',
                    'component_extra_options': {
                        'name': 'saved-prescriptions'
                    }
                },
                {
                    'id': PRESCRIPTION_TYPE_LATER,
                    'label': 'Provide It Later',
                    'summary_label': 'Provide It Later',
                    'description': '',
                    'component_extra_options': {
                        'name': 'prescription-email-it-later'
                    }
                }
            ]
        }
    }

    return {
        help_components : help_components,
        DEFAULT_LAST_STEP: DEFAULT_LAST_STEP
    }
}
