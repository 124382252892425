import {
    GLASSES_AND_CONTACTS_VALUE,
    GLASSES_ONLY_VALUE,
    PROGRESSIVE_VALUE,
    SINGLE_VISION_VALUE,
    COMPONENT_ID_GLASSES,
    COMPONENT_ID_PLAN_TYPE,
    COMPONENT_ID_SEARCH,
    COMPONENT_ID_FINISH
} from './objectList'

/**
 * Components Chain
 */
export const OBJECTS_CHAIN = [
    {
        'id': COMPONENT_ID_GLASSES,
        'stepId': '1',
        'next': {
            [GLASSES_AND_CONTACTS_VALUE]: COMPONENT_ID_SEARCH,
            [GLASSES_ONLY_VALUE]: COMPONENT_ID_PLAN_TYPE
        }
    },
    {
        'id': COMPONENT_ID_PLAN_TYPE,
        'stepId': '2',
        'next': {
            [PROGRESSIVE_VALUE]: COMPONENT_ID_FINISH,
            [SINGLE_VISION_VALUE] : COMPONENT_ID_FINISH
        }
    },
    {
        'id': COMPONENT_ID_SEARCH,
        'stepId': '2',
        'next': COMPONENT_ID_PLAN_TYPE
    },
    {
        'id': COMPONENT_ID_FINISH,
        'stepId': '3',
        'next': ''
    }
]
