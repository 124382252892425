import getHelper from '../helper'
import {
    LENS_OPTION_CODE_TRIVEX
} from '../lens_options'
import {
    TRANSITIONS_SECTION_ID_DRIVEWEAR,
    TRANSITIONS_SECTION_ID_XTRACTIVE
} from '../transitions_sections'
import {
    LENS_TYPE_ID,
} from '../../../../../app/common/constants/lens_type'
import {defaultOptions} from "./default";

const disableXTRActiveColors = {
    'Transition XTRActive': ['blue', 'gold', 'green', 'pink', 'red', 'silver']
}

const disableXTRActiveColorsSVPremium = {
    'Transition XTRActive': ['blue', 'gold', 'green', 'pink', 'red', 'silver','grey-polarized', 'graphite-green']
}

const disableSignatureColors = {
    'Transition Non-Polarized': ['gen-8-green', 'gen-8-emerald', 'gen-8-sapphire', 'gen-8-amethyst', 'gen-8-amber']
}

const disableSunPolarizedColors = {
    'Sun Polarized': ['polarized-gradient-brown', 'polarized-gradient-grey']
}

export const LIMITERS_DEFAULT_RHEOS = {
    disabled_colors: {
        single_vision_distance: {...disableXTRActiveColors, ...disableSignatureColors, ...disableXTRActiveColorsSVPremium, ...disableSunPolarizedColors},
        single_vision_reading: {...disableXTRActiveColors, ...disableSignatureColors, ...disableXTRActiveColorsSVPremium, ...disableSunPolarizedColors},
        single_vision_reading_premium:{...disableXTRActiveColorsSVPremium, ...disableSignatureColors, ...disableSunPolarizedColors},
        single_vision_distance_premium: {...disableXTRActiveColorsSVPremium, ...disableSignatureColors, ...disableSunPolarizedColors},
        progressive_premium: {...disableSignatureColors, ...disableSunPolarizedColors}
    },
    lens_type_disable: {
        single_vision_distance: [
            TRANSITIONS_SECTION_ID_DRIVEWEAR,
            'mirror'
        ],
        single_vision_reading: [
            TRANSITIONS_SECTION_ID_DRIVEWEAR,
            'mirror'
        ],
        progressive_standard: [
            'computer_lenses',
            TRANSITIONS_SECTION_ID_XTRACTIVE,
            TRANSITIONS_SECTION_ID_DRIVEWEAR,
            'mirror'
        ],
        progressive_premium: [
            TRANSITIONS_SECTION_ID_XTRACTIVE,
            TRANSITIONS_SECTION_ID_DRIVEWEAR,
            'mirror'
        ],
        single_vision_distance_premium: [
            'mirror'
        ],
        single_vision_reading_premium: [
            'mirror'
        ]
    },
    lens_options_enable: {
        single_vision_distance: [LENS_OPTION_CODE_TRIVEX],
        single_vision_distance_premium: [LENS_OPTION_CODE_TRIVEX],
        single_vision_reading: [LENS_OPTION_CODE_TRIVEX],
        single_vision_reading_premium: [LENS_OPTION_CODE_TRIVEX],
        progressive_standard: [LENS_OPTION_CODE_TRIVEX],
        progressive_premium: [LENS_OPTION_CODE_TRIVEX],
    }
}

export default function getRheosStepList (params) {
    const defaultStepOptions = defaultOptions(params)
    const {product_config} = params
    const {DEFAULT_LAST_STEP} = getHelper(params)

    return [
        defaultStepOptions.notesOption,
        defaultStepOptions.antiRefOption,
        {
            'id': 'lens',
            'label': 'Choose Lenses',
            'summary_label': 'Lens',
            'value_renderer': 'renderer-options',
            'default_value': 'prescription',
            'component': {
                'name': 'selector-options',
                'options': [
                    defaultStepOptions.chooseLens.prescriptionType,
                    defaultStepOptions.chooseLens.nonPrescriptionType
                ]
            }
        },
        {
            'id': 'prescription_type',
            'label': 'Choose Prescription Type',
            'summary_label': 'Rx Type',
            'value_renderer': 'renderer-options',
            'default_value': 'single_vision_distance',
            'component': {
                'name': 'selector-prescription-type',
                'options': [
                    ...defaultStepOptions.prescriptionTypes.singleVisionStandardPrescription,
                    ...defaultStepOptions.prescriptionTypes.singleVisionPremiumPrescription,
                    defaultStepOptions.prescriptionTypes.progressivePremium
                ]
            }
        },
        Object.assign({'id': 'single_vision_distance'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_distance_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_reading'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'single_vision_reading_premium'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'progressive_standard'}, {...DEFAULT_LAST_STEP}),
        Object.assign({'id': 'progressive_premium'}, {...DEFAULT_LAST_STEP}),
        {
            'id': LENS_TYPE_ID,
            'label': 'Select Lens Type',
            'summary_label': 'Lens Type',
            'value_renderer': 'renderer-options',
            'default_value': 'sunglasses_lenses',
            'component': {
                'name': 'selector-options-lens-type',
                'params': {
                    'product_config': product_config
                },
                'options': [
                    defaultStepOptions.lensType.sunglassesLens,
                    defaultStepOptions.lensType.computerLens,
                    defaultStepOptions.lensType.blueLightLens,
                    defaultStepOptions.lensType.transitionLens,
                ]
            }
        },
        {
            'id': 'lens_options',
            'label': 'Select Lens Material',
            'summary_label': 'Lens Option',
            'value_renderer': 'renderer-options',
            'hide_for_product': ['sunglass_lens', 'transition_lens'],
            'always_visible': true,
            'component': {
                'name': 'selector-options-lens-option',
                'params': {
                    'product_config': product_config
                },
                'options': [
                    defaultStepOptions.lensOptions.polyLensOption,
                    defaultStepOptions.lensOptions.trivexLensOptions,
                    defaultStepOptions.lensOptions.hi167LensOption,
                ]
            }
        }
    ]
}
