import _size from 'lodash/size'
export default () => ({
    namespaced: true,
    state: () => ({
        leSpecsProducts: []
    }),
    getters: {
        leSpecsProducts: state => state.leSpecsProducts
    },
    actions: {
        fetchLeSpecsProducts: ({commit, rootGetters}) => {
            if (_size(rootGetters.rootState.leSpecsProducts)) {
                commit('LE_SPECS_PRODUCTS', rootGetters.rootState.leSpecsProducts)
            }
        }
    },
    mutations: {
        LE_SPECS_PRODUCTS: (state, products = []) => state.leSpecsProducts = products
    }
})
